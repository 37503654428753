<div *ngIf="fileData" class="message-container">
	<div class="message-container-inner">
		<div *ngIf="!fileData.error">
			<div class="message-sender">
				<b>{{ 'common.from' | resx }}: </b>
				<span class="message-sender-name">{{ fileData.senderName }} </span>
				<span class="message-sender-email"
					>(<a href="mailto:{{ fileData.senderEmail }}">{{ fileData.senderEmail }}</a
					>)
				</span>
			</div>
			<div class="message-subject">
				<b>{{ 'common.recipients' | resx }}: </b>
				<span class="message-recipient" *ngFor="let recipient of fileData.recipients; index as i">
					<span *ngIf="i > 0">, </span>
					<span class="message-sender-name">{{ recipient.name }} </span>
					<span class="message-sender-email">
						(<a href="mailto:{{ recipient.email }}">{{ recipient.email }}</a
						>)
					</span>
				</span>
			</div>
			<div class="message-subject">
				<b>{{ 'common.topic' | resx }}: </b>
				<span class="message-subject">{{ fileData.subject }} </span>
			</div>
			<br />
			<br />
			<div class="message-body">
				{{ fileData.body }}
			</div>
			<br />
			<br />
			<div [innerHTML]="fileData.bodyHTML"></div>

			<br />
			<br />
			<b *ngIf="fileData.attachments && fileData.attachments.length > 0">{{ 'common.attachments' | resx }}: </b>
			<ul class="attachments">
				<li *ngFor="let attachment of fileData.attachments; index as i">
					<a [href]="attachmentFileToUrl(i, attachment)" target="_blank">
						<img *ngIf="isImage(attachment)" [src]="attachmentFileToUrl(i, attachment)" />
					</a>
				</li>
			</ul>
		</div>
		<div *ngIf="fileData.error"></div>
	</div>
</div>
