/** NS - NameSpace
 * возвращает enum со значениями к которым добавлен префикс
 * @example
 * enum test {
 *  one = 'one'
 * }
 * const ns = 'test';
 * const testNs = enumWithNs(test, 'ns');
 * console.log(testNs); // { one: 'ns/one' }
 */
export const enumWithNs = <T extends { [key: string]: any }>(enumObject: Partial<T>, ns: string) => {
	return Object.entries(enumObject).reduce((acc, [key, val]) => {
		acc[key] = `${ns}/${val}`;
		return acc;
	}, {}) as T;
};
