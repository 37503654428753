import { TabActiveStateService } from '@spa/common/services/tab-active-state.service';
import { getAppTopInjector } from '../injectors';

const specialKey = 'ωPayloadType';

let spaEventChannel: BroadcastChannel;
let tabState: TabActiveStateService;
if (window.top === window) {
	spaEventChannel = new BroadcastChannel('1f-spa-events');
}

const broadcastEvent = (type: string, detail: any) => {
	if (!spaEventChannel) {
		return;
	}
	tabState = tabState ? tabState : getAppTopInjector().get(TabActiveStateService);
	if (!tabState.isMasterTab) {
		return;
	}
	try {
		const data = { type, detail };
		spaEventChannel.postMessage(data);
	} catch (error) {
		console.error(error);
	}
};

export const isDetailsHasTypeEventInfo = (details: Record<any, any>) => Boolean(details && details[specialKey]);

export function spaDispatchEvent(name: string, details?: any, payloadTypeEvent?: string, win?: Window) {
	details = payloadTypeEvent ? { type: payloadTypeEvent, payload: details, [specialKey]: true } : details;
	broadcastEvent(name, details);
	(win || window).dispatchEvent(
		new CustomEvent(name, {
			detail: details,
		})
	);
}
