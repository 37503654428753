<header>
	<h2>{{ group?.name }}</h2>
</header>
<mat-divider></mat-divider>
<section class="group-container" [ngClass]="[group?.viewOptions?.view || groupView.list]">
	<vh-app-card
		*ngFor="let app of apps; trackBy: trackByAppId"
		[vhContextMenu]="menu"
		[vhContextMenuData]="{ app: app }"
		[appInfo]="app"
		[viewType]="cardViewByGroup(group?.viewOptions?.view)"
	></vh-app-card>
</section>

<mat-menu #menu="matMenu">
	<ng-template matMenuContent let-app="app">
		<button mat-menu-item (click)="commandOpenApp(app)">
			<mat-icon>open_in_browser</mat-icon>
			<span>Открыть</span>
		</button>
		<button mat-menu-item (click)="commandAddToQuickApps(app)">
			<mat-icon>bookmark_border</mat-icon>
			<span>Закрепить как QuickLink</span>
		</button>
		<button disabled mat-menu-item (click)="commandAddToFavoritesMenu(app)">
			<mat-icon>star</mat-icon>
			<span>Добавить в избранное</span>
		</button>
		<button disabled mat-menu-item (click)="commandAddToStartupPage(app)">
			<mat-icon>home</mat-icon>
			<span>Сделать начальной страницей</span>
		</button>
	</ng-template>
</mat-menu>
