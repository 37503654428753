<form class="search-form">
	<mat-form-field appearance="outline">
		<mat-icon matPrefix>search</mat-icon>
		<mat-label>Поиск приложений</mat-label>
		<input
			(input)="onSearch($event.target.value)"
			type="search"
			matInput
			autocomplete="off"
			placeholder="Поиск приложений"
		/>
	</mat-form-field>
</form>
<section class="search-results">
	<ng-container *vhSubscribe="searchResult$ as searchResult">
		<ng-container *ngFor="let group of groups$ | async; trackBy: trackByGroupOrder">
			<ng-container *vhSubscribe="filterAppsByGroup(group) as groupApps">
				<vh-apps-search-group
					*ngIf="groupApps?.length"
					[group]="group"
					[apps]="groupApps"
					(contextMenuClick)="onContextMenuClick($event)"
				></vh-apps-search-group>
			</ng-container>
		</ng-container>
	</ng-container>
</section>
