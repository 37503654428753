import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { AlertMessageComponent } from './alert-message.component';

@NgModule({
	imports: [CommonModule, IconModule],
	exports: [AlertMessageComponent],
	declarations: [AlertMessageComponent],
})
export class AlertMessageModule {}
