import { ApplicationRef, Injector } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';

let appTopInjector: Injector;

export function setAppTopInjector(value: Injector) {
	appTopInjector = value;
}

export function getAppTopInjector() {
	return appTopInjector;
}

let appRef: ApplicationRef;
const appRefTick$ = new Subject<void>();
appRefTick$.pipe(debounceTime(50)).subscribe(() => {
	appRef.tick();
});

export function appRefTick() {
	if (!appRef) {
		appRef = getAppTopInjector().get(ApplicationRef);
	}
	appRefTick$.next();
}
