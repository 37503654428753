import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAttachment } from '@spa/data/entities';
import { IFileInfo } from '@spa/data/http';
import { IFileViewer, IFileViewerSettings } from '../../file-viewer.component';

const selector = 'vh-download-viewer';

@Component({
	// tslint:disable-next-line:component-selector
	selector: selector,
	templateUrl: './download-viewer.component.html',
	styleUrls: ['./download-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [],
})
export class DownloadViewerComponent implements IFileViewer {
	@Input()
	public file: Partial<IAttachment>;

	@Input()
	public fileInfo: IFileInfo;

	public isDirty: boolean;
	public settings: IFileViewerSettings;
}
