export const nameOfFactory = <T>() => (name: keyof T) => name;

export function nameOf<T>(selector: keyof T): keyof T;

export function nameOf<T>(selector: (fake: T) => any, fullName?: boolean): keyof T;

export function nameOf(selector: () => any, fullName?: boolean): string;
export function nameOf(selector: any, fullName: any = false): string {
	if (typeof selector === 'function') {
		const s = '' + selector;
		const m = s.match(/return\s+([A-Z$_.]+)/i) || s.match(/.*?(?:=>|function.*?{(?!\s*return))\s*([A-Z$_.]+)/i);
		const name = (m && m[1]) || '';
		return fullName ? name : name.split('.').reverse()[0];
	} else if (typeof selector === 'string') {
		return selector;
	}
}

// export type propertySelector<T, K extends keyof T> = (obj: T) => T[K];

// export const getNameOf: <T, K extends keyof T>(obj: T, selector: propertySelector<T, K>) => string = (
// 	obj,
// 	selector
// ) => {
// 	const property: any = selector(obj);
// 	for (const p in obj) {
// 		if (obj[p] === property) return p;
// 	}
// 	throw new Error(`Property ${property} not found!`);
// };
