import { Injectable, NgZone, isDevMode } from '@angular/core';
import { DataHttpService } from '@spa/data/http';
import { LoggerFactory, UniversalLoaderProvider } from '@valhalla/core';
import {
	Extendable,
	booleanFilter,
	firstLetterLowerCaseMapper,
	jsonTryParse,
	rxSetInterval,
	trimProps,
} from '@valhalla/utils';
import { BehaviorSubject, combineLatest, EMPTY, from, merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, share, take, tap } from 'rxjs/operators';
import { TabActiveStateService } from '@spa/common/services/tab-active-state.service';

import {
	IAddTaskToFavorites,
	ICalendarSignal,
	IChatMessageNotifyData,
	ICommentReactionNotifyData,
	IEditCommentNotifyData,
	IEditTaskTextNotifyData,
	IJitsiSignal,
	INewCommentNotifyData,
	IPreuploadedFileNotifyData,
	IQuestionCommentNotifyData,
	ISignalMessage,
	ISmartActionDialogNotifyData,
	ISmartMessage,
	IUserIsOnlineNotifyData,
	NotifyHubEvents,
	ReadCommentsNotifyData,
	SignalrProvider,
} from './abstract';
import { adjustAttachment } from '@spa/data/entities';

@Injectable()
export class SignalrProviderImpl implements SignalrProvider {
	constructor(
		readonly scriptLoader: UniversalLoaderProvider,
		readonly loggerFactory: LoggerFactory,
		readonly zone: NgZone,
		readonly server: DataHttpService,
		readonly tab: TabActiveStateService
	) {
		this.init();
	}

	private _messages$ = new Subject<ISignalMessage>();
	private _isActivated$ = new BehaviorSubject(false);
	private activating = false;
	private activateNotify$ = new Subject<void>();
	private whenActivated$ = this._isActivated$.pipe(booleanFilter());
	private _isConnectToNotificationHub = false;
	private _logger = this.loggerFactory.createLogger('SignalrProvider');
	readonly events = NotifyHubEvents as any;
	readonly connectionState = {
		connecting: 0,
		connected: 1,
		reconnecting: 2,
		disconnected: 4,
	};
	readonly signalCore$ = from(import('@microsoft/signalr'));
	protected signalCoreConnection;
	readonly serverVersion$ = this.server.config.appSettingsAnonymousConfig$.pipe(
		map(info => {
			const version = info?.VersionInfo;
			const minor = version && Number(version.split('.')[1]);
			return minor;
		})
	);
	readonly useSignalCore$ = this.server.config.appSettingsAnonymousConfig$.pipe(
		map(config => config.AppEngine === 'Uniform')
	);
	readonly useSignalTcCore$ = this.server.config.appSettingsAnonymousConfig$.pipe(
		map(config => config.CustomSettings?.signal?.toLowerCase() === 'tc-core')
	);
	readonly signal$ = this._messages$.asObservable();
	readonly messages$: Observable<ISignalMessage> = this._messages$.pipe(
		filter(_ => this._isActivated$.value),
		filter(message => this.filterSignalByTabActive(message)),
		share()
	);
	readonly chatMessages$: Observable<IChatMessageNotifyData> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.chatMessage),
		map((event: ISignalMessage<IChatMessageNotifyData>) => {
			event.data = trimProps(event.data, firstLetterLowerCaseMapper);
			event.data.__isNormalizeProps = true;
			return event.data;
		}),
		share()
	);

	readonly editTaskText$: Observable<IEditTaskTextNotifyData> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.editTaskText),
		map((event: ISignalMessage<IEditTaskTextNotifyData>) => event.data),
		share()
	);
	readonly readComments$: Observable<ReadCommentsNotifyData> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.readComments),
		map((event: ISignalMessage<ReadCommentsNotifyData>) => event.data),
		share()
	);
	readonly newComments$: Observable<INewCommentNotifyData> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.newComment),
		map((event: ISignalMessage<INewCommentNotifyData>) => event.data),
		share()
	);
	readonly questionComment$: Observable<IQuestionCommentNotifyData> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.questionComment),
		map((event: ISignalMessage<IQuestionCommentNotifyData>) => event.data),
		share()
	);
	readonly updateFavorites$: Observable<any> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.updateFavorites),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);
	readonly refreshMTF$: Observable<any> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.refreshMTF),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);
	readonly deleteComment$: Observable<any> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.deleteComment),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly editComment$: Observable<IEditCommentNotifyData> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.editComment),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly likeComment$: Observable<any> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.likeComment),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly userProfileChanged$: Observable<any> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.userProfileChanged),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly newChatComment$: Observable<any> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.newChatComment),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly impersonate$: Observable<any> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.impersonate),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly stopImpersonation$: Observable<any> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.stopImpersonation),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly changeLanguage$: Observable<any> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.changeLanguage),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly addTaskToFavorites$: Observable<IAddTaskToFavorites> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.addTaskToFavorites),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly changeFavorites$: Observable<any> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.changeFavorites),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly jitsiRoomJoin$: Observable<IJitsiSignal> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.jitsiRoomJoin || event.name === NotifyHubEvents.conferenceRoomJoin),
		map((event: ISignalMessage<IJitsiSignal>) => event.data),
		share()
	);

	readonly jitsiRoomUserJoined$: Observable<IJitsiSignal> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.jitsiRoomUserJoined),
		map((event: ISignalMessage<IJitsiSignal>) => event.data),
		share()
	);

	readonly jitsiRoomInviteDeclined$: Observable<IJitsiSignal> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.jitsiRoomInviteDeclined),
		map((event: ISignalMessage<IJitsiSignal>) => event.data),
		share()
	);

	readonly calendarEventsChanged$: Observable<ICalendarSignal> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.calendar),
		map((event: ISignalMessage<ICalendarSignal>) => event.data),
		share()
	);

	readonly smartMessage$: Observable<ISmartMessage> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.smartMessage),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly commentReaction$: Observable<ICommentReactionNotifyData> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.commentReaction),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly userIsOnline$: Observable<IUserIsOnlineNotifyData> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.userIsOnline),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly smartActionSnackbar$: Observable<Extendable<ISmartActionDialogNotifyData>> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.smartActionSnackbar),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly smartActionDialogBox$: Observable<Extendable<ISmartActionDialogNotifyData>> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.smartActionDialogBox),
		map((event: ISignalMessage<any>) => event.data),
		share()
	);

	readonly preuploadedFile$: Observable<Extendable<IPreuploadedFileNotifyData>> = this.messages$.pipe(
		filter(event => event.name === NotifyHubEvents.preuploadedFile),
		map((event: ISignalMessage<IPreuploadedFileNotifyData>) => {
			const r = event.data;
			adjustAttachment(r.file);
			return r;
		}),
		share()
	);

	get signalRef() {
		// todo: make typed
		try {
			return (<any>window).SJ?.iwc?.SignalR || (<any>window).$.signalR;
		} catch (err) {
			this._logger.error(
				`${String(err)}, signalR bundles not loaded correctly, can't access to global property window.SJ.iwc.SignalR!`
			);
			throw err;
		}
	}

	init() {
		this.activateNotify$
			.pipe(
				filter(() => !(this.activating || this._isActivated$.value)),
				tap(() => (this.activating = true)),
				mergeMap(() =>
					combineLatest({
						useSignalCore: this.useSignalCore$,
						useSignalTcCore: this.useSignalTcCore$,
					}).pipe(take(1))
				),
				mergeMap(({ useSignalCore, useSignalTcCore }) => {
					if (useSignalTcCore || !useSignalCore) {
						return this.scriptLoader.loadSignalR().pipe(
							tap(isLoaded => {
								if (!isLoaded) {
									// tslint:disable-next-line:no-console
									console.warn('SignalR [tc] scripts is not loaded!');
								}
							}),
							map(() => ({ useSignalCore, useSignalTcCore }))
						);
					} else {
						return of({ useSignalCore, useSignalTcCore });
					}
				}),
				mergeMap(config => {
					this.activateIntervalCheckConnectionState(config);
					return this.connectToNotificationHub(config);
				}),
				catchError(err => {
					this.activating = false;
					console.error(err);
					return EMPTY;
				})
			)
			.subscribe(() => {
				this.activating = false;
			});
	}

	activate(): Observable<boolean> {
		this.activateNotify$.next();
		return this.whenActivated$;
	}

	deactivate(): Observable<any> {
		this._isActivated$.next(false);
		// stop signalR
		return of(true);
	}
	getState(): any {
		return;
	}

	connectToNotificationHub({ useSignalCore, useSignalTcCore }) {
		const connect$ =
			useSignalTcCore && useSignalCore
				? merge(this.connectToNotificationHubNew(), this.connectToNotificationHubOld())
				: useSignalCore
				? this.connectToNotificationHubNew()
				: this.connectToNotificationHubOld();
		return connect$.pipe(
			tap(() => {
				this._isActivated$.next(true);
			}),
			mapTo(useSignalCore),
			catchError(err => {
				this._logger.error(`Error connect to notification hub.\n${String(err)}`);
				return throwError(() => err);
			})
		);
	}

	connectToNotificationHubNew() {
		return new Observable(observer => {
			const hubName = 'notificationHub';
			this.signalCore$.pipe(take(1)).subscribe(signalCore => {
				const prevConn = (window as any).signalRconnection;
				if (prevConn) {
					observer.next();
					observer.complete();
					return;
				}
				const connection = new signalCore.HubConnectionBuilder()
					.withUrl(`/${hubName}`, {
						// skipNegotiation: true,
						transport: signalCore.HttpTransportType.WebSockets,
					})
					.withAutomaticReconnect()
					.configureLogging(isDevMode() ? signalCore.LogLevel.Error : signalCore.LogLevel.Critical)
					.build();
				connection.on('notify', this.createHubSubscriber(hubName));
				connection.onreconnecting(err => {
					console.log(`SignalR [core] state=${connection.state}`);
				});
				connection.onreconnected(() => {
					console.log(`SignalR [core] state=${connection.state}`);
				});
				this.storeSignalrGlobalRefs({ connection, signalR: signalCore });
				console.log('SignalR [core] start connecting...');
				connection
					.start()
					.then(() => {
						this._isConnectToNotificationHub = true;
						console.log('SignalR [core] connected');
						observer.next();
						observer.complete();
					})
					.catch(err => {
						this._isConnectToNotificationHub = false;
						observer.error(err);
					});
				this.signalCoreConnection = connection;
			});
		});
	}

	connectToNotificationHubOld() {
		return new Observable(observer => {
			try {
				const hubName = 'notificationHub';
				if (this.signalRef.start && this.signalRef.getHubProxy) {
					this.signalRef.start().then(
						() => {
							this._isConnectToNotificationHub = true;
							observer.next();
							observer.complete();
						},
						error => {
							observer.error(error);
						}
					);
					this.signalRef.getHubProxy(hubName, {
						client: {
							notify: this.createHubSubscriber(hubName),
						},
					});
				} else if (this.signalRef.notificationHub?.client) {
					this.signalRef.notificationHub.client.notify = (event: string, data: string) => {
						this._logger.log(hubName, event, 'payload:');
						const parsed = data && jsonTryParse(data, err => console.error(`${hubName}/${event}`, err, data));
						this._logger.log(parsed);
						this._messages$.next({
							name: event,
							data: parsed,
						});
					};
					this.signalRef.hub.start().then(
						() => {
							this._isConnectToNotificationHub = true;
							observer.next();
							observer.complete();
						},
						error => {
							observer.error(error);
						}
					);
				}
			} catch (error) {
				observer.error(error);
			}
		});
	}

	createHubSubscriber(hubName: string) {
		return (event: string, data: string) => {
			this._logger.log(hubName, event, 'payload:');
			const parsed = data && jsonTryParse(data, err => console.error(`${hubName}/${event}`, err, data));
			this._logger.log(parsed);
			this._messages$.next({
				name: event,
				data: parsed,
			});
		};
	}

	activateIntervalCheckConnectionState({ useSignalCore, useSignalTcCore }) {
		if (useSignalTcCore && useSignalCore) {
			this.activateIntervalCheckConnectionStateNew();
			this.activateIntervalCheckConnectionStateOld();
		} else {
			if (useSignalCore) {
				this.activateIntervalCheckConnectionStateNew();
			} else {
				this.activateIntervalCheckConnectionStateOld();
			}
		}
	}

	activateIntervalCheckConnectionStateNew() {
		const intervalSec = 5 * 1000;
		const check = () => {
			if ('Connecting' === this.signalCoreConnection?.state) {
				return;
			}
			if (['Disconnected'].includes(this.signalCoreConnection?.state) || !this._isConnectToNotificationHub) {
				console.log('SignalR [core] is disconnected, trying connect again...');
				this.signalCoreConnection
					.start()
					.then(() => {
						this._isConnectToNotificationHub = true;
						console.log('SignalR [core] connected');
					})
					.catch(err => {
						this._isConnectToNotificationHub = false;
					})
					.finally(() => {
						setTimeout(check, intervalSec);
					});
			} else {
				setTimeout(check, intervalSec);
			}
		};
		setTimeout(check, intervalSec);
	}

	activateIntervalCheckConnectionStateOld() {
		this.zone.runOutsideAngular(() => {
			rxSetInterval(30000).subscribe(_ => {
				try {
					// check connection state and reconnect
					if (!this._isConnectToNotificationHub) {
						this.connectToNotificationHubOld();
					}
					const connection = (<any>window).$ && (<any>window).$.connection;
					if (!connection) {
						return;
					}
					if (connection.hub.state === this.connectionState.disconnected) {
						this._logger.info('SignalR [tc] seems is disconnected, trying connect again...');
						this.zone.run(() => {
							try {
								connection.hub.start();
							} catch (err) {
								this._logger.error(
									`SignalR [tc] Error in interval checking signalR connection. Start connection hub error.\n${String(
										err
									)}`
								);
							}
						});
					}
				} catch (err) {
					this._logger.error(`Error in interval checking signalR connection.\n${String(err)}`);
				}
			});
		});
	}

	filterSignalByTabActive(message: ISignalMessage<any>) {
		const allTabsSignal: string[] = [
			NotifyHubEvents.jitsiRoomJoin,
			NotifyHubEvents.jitsiRoomUserJoined,
			NotifyHubEvents.jitsiRoomInviteDeclined,
			NotifyHubEvents.conferenceRoomJoin,
			NotifyHubEvents.refreshMTF,
		];
		if (allTabsSignal.includes(message.name)) {
			return true;
		}
		return this.tab.tabVisible;
	}

	protected storeSignalrGlobalRefs({ signalR, connection }) {
		try {
			Object.defineProperties(window, {
				signalR: {
					configurable: false,
					enumerable: false,
					get: () => signalR,
				},
				signalRconnection: {
					configurable: false,
					enumerable: false,
					get: () => connection,
				},
				signalRprovider: {
					configurable: false,
					enumerable: false,
					get: () => this,
				},
			});
		} catch (error) {
			console.error(error);
		}
	}
}
