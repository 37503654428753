import { ChangeDetectionStrategy, Component } from '@angular/core';
import { INavigationMenu, NavigationFeatureProvider } from '@spa/facade/features/navigation';
import { ViewDestroyStreamService } from '@valhalla/core';

@Component({
	selector: 'vh-layout-favorites-comments-panel',
	templateUrl: './favorites-comments.component.html',
	styleUrls: ['./favorites-comments.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class FavoritesCommentsComponent {
	constructor(readonly navigationFeature: NavigationFeatureProvider) {}

	readonly numberRegExp = /\d+/g;
	menuItems: INavigationMenu[] = [];
	blocks: IBlock[] = [];
	expandedBlockId: number;

	readonly favoritesMenu = this.navigationFeature.select(state => state.menus.favorites);
}

interface IBlock {
	id: number;
	isExpand: boolean;
}

export const expandedBlockToken = 'expandedFavoritesBlock';
