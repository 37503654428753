/**
 * Типы общих тикеров в тулбаре
 * 	- системные
 * 	- кастомные
 */
export enum TickerType {
	system = 'system',
	custom = 'custom',
}

export enum TickerClickAction {
	openLink = 'openLink',
	openTasksList = 'openTasksList',
}

export enum TickerIconSet {
	material = 'material',
}

export interface ITickerDto {
	id: number;
	name: string;
	type: TickerType;
	iconSet?: TickerIconSet;
	iconFromSet?: string;
	iconCss?: string;
	url?: string;
	gridUrl?: string;
	clickAction?: TickerClickAction;
	color?: string;
	value?: any;
}

export interface ICustomAssistantTickerDto {
	tickers: ITickerDto[];
	userId: number;
	userName: string;
}

/**@deprecated use  */
export interface ISystemTickersDto {
	DirectorSignaturesCount: number;
	HasChatInvites: boolean;
	Milestones: number;
	MissedCalls: number;
	OverDueTasksCount: number;
	OverdueSigns: number;
	QuestionsCount: number;
	SignaturesCount: number;
	UnreadCommentsCount: number;
	WasOffline: boolean;
	UnreadChatCommentsCount: number;
	favoriteComments: number;
	availableTypes: TickerSystemType[];
	assistantUsers: any[];
	newTasks: number;
	overdueNoPerformers: number;
	overdueSignatures: number;
	todayTasks: number;
	totalTasks: number;
	totalTasksAssistant: number;
}

export interface ISystemTickersDto2 {
	allTasksUserOwns: number;
	allTasksUserPerforms: number;
	allNewTasksUserPerforms: number;
	allNewTasksUserOwns: number;
	unreadCommentsCount: number;
	questionsCount: number;
	overDueTasksCount: number;
	signaturesCount: number;
	directorSignaturesCount: number;
	overdueSigns: number;
	missedCalls: number;
	milestones: number;
	myQuestionsCount: number;
	unreadChatCommentsCount: number;
	badge: number;
	favoriteComments: number;
	availableTypes: TickerSystemType[];
	assistantUsers: any[];
	newTasks: number;
	overdueNoPerformers: number;
	overdueSignatures: number;
	todayTasks: number;
	totalTasks: number;
	totalTasksAssistant: number;
	systemTickersIds: {
		type: TickerSystemType;
		id: number;
	}[];
}

export interface ISystemTickersNamesDto2 {
	unreadCommentsCount: string;
	questionsCount: string;
	overDueTasksCount: string;
	signaturesCount: string;
	directorSignaturesCount: string;
	overdueSigns: string;
	missedCalls: string;
	milestones: string;
	myQuestionsCount: string;
	unreadChatCommentsCount: string;
	badge: string;
	favoriteComments: string;
}

export enum TickerSystemType {
	unread = 'Unread',
	chats = 'Chats',
	missedCalls = 'MissedCalls',
	overdue = 'Overdue',
	signatures = 'Signatures',
	question = 'Question',
	milestones = 'Milestones',
	myQuestions = 'MyQuestions',
	subtasks = 'Subtasks',
	favoriteComments = 'FavoriteComments',
	newTasks = 'NewTasks',
	overdueNoPerformers = 'OverdueNoPerformers',
	overdueSignatures = 'OverdueSignatures',
	todayTasks = 'TodayTasks',
	totalTasks = 'TotalTasks',
	totalTasksAssistant = 'TotalTasksAssistant',
}
