<div
	class="vh-nav-panel-admin-item--name"
	[class.vh-nav-panel-admin-item--name-active]="isActiveRoute"
	[class.no-collapsable]="!isChildren(item)"
	(click)="onClickItem({ e: $event, item: this.item })"
	[vhContextMenu]="{
		menu: needContextMenu && !isChildren(item) ? adminContextMenu : null,
		data: {
			item: item
		}
	}"
>
	<mat-icon
		*ngIf="isChildren(item)"
		class="collapsable-arrow-icon"
		(click)="expandCollapse($event, item)"
		[svgIcon]="isOpen ? 'vh-arrow-v1-S-down-24' : 'vh-arrow-v1-S-right-24'"
	></mat-icon>

	<span>{{ item?.name }}</span>
</div>

<div class="vh-nav-panel-admin-item--children" *ngIf="isOpen && isChildren(item)">
	<div *ngFor="let childItem of item?.children">
		<vh-nav-panel-admin-item
			[item]="childItem"
			[needContextMenu]="needContextMenu"
			(clickItem)="clickItem.emit($event)"
			(contextItemClicked)="onContextMenuClicked(childItem)"
			(clickExpandCollapse)="clickExpandCollapse.emit($event)"
		></vh-nav-panel-admin-item>
	</div>
</div>

<mat-menu #adminContextMenu="matMenu">
	<ng-template matMenuContent let-item="item">
		<button mat-menu-item (click)="onContextMenuClicked(item)">
			<mat-icon svgIcon="vh-tasks-add-24"></mat-icon>
			<span>{{ 'common.create' | resx }}</span>
		</button>
	</ng-template>
</mat-menu>
