import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataHttpCommonService } from '../data-http-common.service';
import { PhoneDataHttpService } from './abstract';

@Injectable()
export class PhoneDataHttpServiceImpl implements PhoneDataHttpService {
	constructor(readonly http: HttpClient, readonly common: DataHttpCommonService) {}

	click2call(phone: string, taskId?: number): Observable<any> {
		const url = this.common.getApiUrl('click-to-call');
		return this.http.post(url, { phone, taskId });
	}
}
