import { IRxStore, StoreManager } from '@valhalla/core';
import { FacadeStoreNames } from '@spa/facade/store-names.enum';

import { LayoutEffectDependencies } from './effects/dependency';
import { reducers } from './reducers';
import { createDefaultLayoutConfig } from './state';
import { merge } from '@valhalla/utils';

export function getOrCreateLayoutStore(
	storeManager: StoreManager,
	dependencies: LayoutEffectDependencies,
	devTools = false,
	middlewares = []
): IRxStore {
	if (storeManager.isStoreExist(FacadeStoreNames.layout)) {
		return storeManager.getStore(FacadeStoreNames.layout);
	}

	const akitaOldStores = JSON.parse(localStorage.getItem('AkitaStores')) || {};
	const facadeLayoutAkitaStore = akitaOldStores['facade_Layout'] || {};
	const initialState = merge(createDefaultLayoutConfig(), facadeLayoutAkitaStore);

	const store = storeManager.createStore({
		name: FacadeStoreNames.layout,
		defaultState: initialState,
		effects: [],
		persistent: {
			migration(state) {
				const s = merge(initialState, state);
				return s;
			},
		},
		dependencies,
		devTools: {
			reduxDevTools: devTools,
			useConsoleLogger: devTools,
			useEventLog: false,
		},
		middlewares,
	});
	return store.addReducers(...reducers);
}
