import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Input,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { UrlProvider } from '@spa/core';
import { IAttachment } from '@spa/data/entities';
import { IFileInfo } from '@spa/data/http';
import { IFileViewerDialogComponentDataOptions } from '../../file-viewer-dialog.component';
import { IFileViewer, IFileViewerSettings } from '../../file-viewer.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { ImgViewerHelper } from '@spa/common/components/file-viewer/extensions/img/img-viewer.helper';

const selector = 'vh-img-viewer';

/**
 * Компонент для рендера файлов изображений
 */
@Component({
	// tslint:disable-next-line:component-selector
	selector: selector,
	templateUrl: './img-viewer.component.html',
	styleUrls: ['./img-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [],
})
export class ImgViewerComponent implements AfterViewInit, IFileViewer {
	constructor(private cdr: ChangeDetectorRef, readonly url: UrlProvider, readonly viewContainerRef: ViewContainerRef) {}

	@Input()
	public file: Partial<IAttachment>;

	@Input()
	public options: IFileViewerDialogComponentDataOptions;

	@Input()
	public fileInfo: IFileInfo;

	@ViewChild('actionPanel')
	public actionPanel: TemplateRef<unknown>;

	@ViewChild('mobileActionPanel')
	public mobileActionPanel: TemplateRef<unknown>;

	@ViewChild('img')
	image: ElementRef<HTMLImageElement>;

	@HostListener('mousewheel', ['$event'])
	scroll(event: any): void {
		let newScale;

		if (event.wheelDelta < 0) {
			newScale = this.currentImageScale - this.imageScaleStep;
		} else {
			newScale = this.currentImageScale + this.imageScaleStep;
		}
		if (newScale <= this.minPossibleScale) {
			newScale = this.minPossibleScale;
		}
		if (newScale >= this.maxPossibleScale) {
			newScale = this.maxPossibleScale;
		}
		this.currentImageScale = newScale;
		this.zoomHelper();
		this.cdr.detectChanges();
	}

	@HostListener('mousedown', ['$event'])
	mousedown(event: any): boolean {
		event.preventDefault();
		this.drag = true;
		this.dragPoint = new Vector(event.clientX, event.clientY);
		this.startDragPoint = this.position.copy();
		return false;
	}

	@HostListener('mouseup', ['$event'])
	mouseup(event: any): boolean {
		event.preventDefault();
		this.drag = false;
		return false;
	}

	@HostListener('mousemove', ['$event'])
	mousemove(event: any): boolean {
		event.preventDefault();

		if (this.drag) {
			this.position = this.startDragPoint.minus(
				this.dragPoint.minus(new Vector(event.clientX, event.clientY)).devide(this.scale)
			);
			this.cdr.detectChanges();
		}

		return false;
	}

	public settings: IFileViewerSettings = {
		actionPanel: null,
		settingsPanel: null,
		mobileActionPanel: null,
	};

	public scale = 0.9;
	public position = new Vector(0, 0);
	currentImageScale = 1;
	imageScaleStep = 0.25;
	minPossibleScale = 0.75;
	maxPossibleScale = 4;
	rotateAngle = 0;

	public isDirty: boolean;
	public drag = false;
	public dragPoint = new Vector(0, 0);
	public startDragPoint = new Vector(0, 0);

	ngAfterViewInit(): void {
		this.settings.actionPanel = new TemplatePortal(this.actionPanel, this.viewContainerRef);
		this.settings.mobileActionPanel = new TemplatePortal(this.mobileActionPanel, this.viewContainerRef);
		ImgViewerHelper.pinchZoom(this.image.nativeElement);
		this.cdr.detectChanges();
	}

	getBlobUrl(blob: Blob): string {
		return URL.createObjectURL(blob);
	}

	getFileMeetingLink(file) {
		return this.url.fileMeetingUrl(file?.key);
	}

	getFileEmailLink(file) {
		return this.url.fileEmailUrl(file?.key);
	}

	rotate(): void {
		this.rotateAngle = this.rotateAngle + 90;
		this.image.nativeElement.style.transform = `rotate(${this.rotateAngle}deg)`;
	}

	zoomOut(): void {
		this.currentImageScale = this.currentImageScale - this.imageScaleStep;
		if (this.currentImageScale <= this.minPossibleScale) {
			this.currentImageScale = this.minPossibleScale;
		}
		if (this.currentImageScale === 2) {
			this.imageScaleStep = 0.5;
		}
		if (this.currentImageScale < 2 && this.currentImageScale >= this.minPossibleScale) {
			this.imageScaleStep = 0.25;
		}
		this.zoomHelper();
	}

	zoomIn(): void {
		this.currentImageScale = this.currentImageScale + this.imageScaleStep;
		if (this.currentImageScale >= 1.5) {
			this.imageScaleStep = 0.5;
		}
		if (this.currentImageScale >= 2) {
			this.imageScaleStep = 1;
		}

		if (this.currentImageScale >= this.maxPossibleScale) {
			this.currentImageScale = this.maxPossibleScale;
		}
		this.zoomHelper();
	}

	zoomHelper(): void {
		this.image.nativeElement.style.transform = `scale(${this.currentImageScale}) rotate(${this.rotateAngle}deg)`;
	}
}

class Vector {
	constructor(public readonly x: number, public readonly y: number) {}

	add(vec: Vector): Vector {
		return new Vector(this.x + vec.x, this.y + vec.y);
	}

	minus(vec: Vector): Vector {
		return new Vector(this.x - vec.x, this.y - vec.y);
	}

	multiply(factor: number): Vector {
		return new Vector(this.x * factor, this.y * factor);
	}

	devide(factor: number): Vector {
		return new Vector(this.x / factor, this.y / factor);
	}

	copy(): Vector {
		return new Vector(this.x, this.y);
	}
}
