import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { AuthService, SessionUser, ViewDestroyStreamService } from '@spa/core';
import { getActualAbsence, IAbsence, isUserOnline, IUserContact, IUserProfile } from '@spa/data/entities';
import { dateFromStringValhalla, isToday, isYearEquals } from '@valhalla/utils';
import { BehaviorSubject, take } from 'rxjs';
import { UserSettingsFacadeProvider } from '@spa/facade/features/user-settings';
import { JitsiService } from '@spa/facade/jitsi.service';
import { DataHttpService } from '@spa/data/http';

@Component({
	selector: 'vh-contacts-search-item',
	templateUrl: './contacts-search-item.component.html',
	styleUrls: ['./contacts-search-item.component.scss'],
})
export class ContactsSearchItemComponent<T extends Partial<IUserContact & IUserProfile>> {
	constructor(
		readonly mobileView: MobileViewService,
		readonly sessionUser: SessionUser,
		protected readonly destroy$: ViewDestroyStreamService,
		protected readonly zone: NgZone,
		protected readonly router: Router,
		protected readonly userSettingsStore: UserSettingsFacadeProvider,
		protected readonly conferenceService: JitsiService,
		protected readonly authService: AuthService,
		readonly server: DataHttpService
	) {}

	@Input() user: T;
	@Input() isLast: boolean;

	@Input()
	hoverMenu = true;

	@Input()
	showDivider = true;

	@Output() contactHovered = new EventEmitter<T>();
	@Output() hideList = new EventEmitter<void>();

	readonly contactHovered$ = new BehaviorSubject<boolean>(false);

	readonly isVksEnabled$ = this.server.config.isVKSEnable$;
	readonly canChat$ = this.server.category.canChat$;

	get displayName() {
		return this.user?.name || this.user?.displayName;
	}

	getUserColor(user: T): string {
		if (user?.absences?.length) {
			return user.absences[0].userColor || '';
		}

		if (user?.userStatus?.absences?.length) {
			return user.userStatus.absences[0]?.freeBusyStatus?.userColor || '';
		}

		return '';
	}

	dateFromString(val: string): Date {
		return dateFromStringValhalla(val);
	}

	getActualAbsence(user: T): IAbsence {
		return getActualAbsence(user);
	}

	absenceDateFormat(start: string, end: string): string {
		const startDate = this.dateFromString(start),
			endDate = this.dateFromString(end);

		if (isToday(startDate) && isToday(endDate)) {
			return 'HH:mm';
		}

		if (isYearEquals(startDate, endDate)) {
			return 'dd.MM';
		}
		return 'dd.MM.yyyy';
	}

	isUserOnline(user): boolean {
		return isUserOnline(user);
	}

	setContactHovered(hovered: boolean): void {
		this.zone.runTask(() => this.contactHovered$.next(hovered));
	}

	mouseenterHandler(): void {
		this.setContactHovered(true);
		this.contactHovered.emit(this.user);
	}

	mouseleaveHandler(): void {
		this.setContactHovered(false);
	}

	openChatWithUser(): void {
		void this.router.navigate(['/chat/new'], {
			queryParams: {
				with: this.user.id,
				private: true,
			},
		});
		this.hideList.emit();
	}

	openVksWithContact(): void {
		this.userSettingsStore.selectUserSettings$.pipe(take(1)).subscribe(settings => {
			const openRoomName = settings?.jitsiServicePersonalRoom;
			if (openRoomName) {
				return this.conferenceService.openConference(openRoomName, this.user.id);
			}
		});
		this.hideList.emit();
	}

	impersonate(): void {
		this.authService.impersonate(this.user.id).subscribe(() => window.location.reload());
		this.hideList.emit();
	}
}
