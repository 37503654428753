import { Observable } from 'rxjs';

export abstract class SearchDataHttpService {
	abstract simpleSearch(criteria: ISimpleSearchCriteria): Observable<ISimpleSearchResponse>;
}

export interface ISimpleSearchCriteria {
	searchText: string;
	maxResultCount?: number;
	showClosedTasks?: boolean;
	showRejectedTasks?: boolean;
	searchTypes?: number[];
	searchExtParamValues?: boolean;
}

export interface ISimpleSearchResponse {
	name: string;
	items: ISimpleSearchItemResponse[];
	message: string;
	itemsType: ISimpleSearchItemType;
}

export interface ISimpleSearchItemResponse {
	itemId: number;
	type: ISimpleSearchItemType;
	name: string;
	url: string;
	notes: string;
	weight: number;
}

export enum ISimpleSearchItemType {
	none = 'None',
	task = 'Task',
	user = 'User',
	orgUnit = 'OrgUnit',
	email = 'Email',
	file = 'File',
}

export enum SearchItems {
	Tasks,
	Users,
	OrgUnits,
	Emails,
	Files,
}
