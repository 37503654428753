import { enumWithNs } from '@valhalla/utils';

enum StoreNames {
	navigation = 'navigation',
	layout = 'layout',
	tickers = 'tickers',
	userQuickApps = 'user-quick-apps',
	userSettings = 'user-settings',
}
export const nameSpace = 'facade';
export const FacadeStoreNames = enumWithNs(StoreNames, nameSpace);
