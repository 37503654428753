import { Database, IDatabaseDescription, ITable } from '@valhalla/core';
import { IAppDefinition } from './model';

export class ApplicationsDatabase extends Database {
	readonly applications: ITable<IAppDefinition, number>;

	getDescription(): IDatabaseDescription {
		return {
			databaseName: 'facade/feature/applications',
			versions: {
				1: {
					schema: {
						applications: '++id,type,name,description'
					}
				}
			}
		};
	}
}
