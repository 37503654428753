<vh-modal-base
	[showOk]="false"
	[showCancel]="true"
	(close)="closeModal()"
	(cancel)="closeModal()"
	[cancelTitle]="'common.close' | resx"
	[title]="'common.logVersions' | resx"
>
	<vh-file-version
		[fileId]="data?.file?.id || data?.file?.uploadID || data?.file?.uploadId"
		[taskId]="data?.taskId"
		[extParamId]="data?.extParamId"
	></vh-file-version>
</vh-modal-base>
