import { Inject, Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';

import { CultureService } from './culture.service';
import { RESOURCE_DEFINITION_ALL } from './resource-register';
import { IResourceDefinition } from './resource-types';
import { ResourceService } from './resource.service';

@Injectable({ providedIn: 'root' })
export class ResourceAgGridService {
	constructor(
		protected readonly culture: CultureService,
		protected readonly resource: ResourceService,
		@Inject(RESOURCE_DEFINITION_ALL) protected readonly definitions: IResourceDefinition[]
	) {}

	readonly namespace: string = 'agGrid';
	protected activeCulture$ = this.culture.activeCulture$;

	resolveLocaleText() {
		return this.culture.activeCulture$.pipe(
			switchMap(activeCulture => this.resource.resolveResource(activeCulture, this.namespace, ...this.definitions))
		);
	}
}
