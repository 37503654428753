import { getAppTopInjector } from '@spa/api/injectors';
import { DataHttpService } from '@spa/data/http';
import { firstValueFrom } from 'rxjs';
import { SpaCommandExecutorFactory } from '../spa-command.contract';
import { parseMessageFromError } from '@valhalla/utils';

const createCommand: SpaCommandExecutorFactory = injector => {
	return function click2call({ phone, taskId }) {
		const inj = getAppTopInjector();
		const server = inj.get(DataHttpService);
		return firstValueFrom(server.phone.click2call(phone, taskId)).catch(err => {
			throw new Error(parseMessageFromError(err));
		});
	};
};

export default createCommand;
