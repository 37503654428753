import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { ControlToggleComponent } from './control-toggle.component';

@NgModule({
	imports: [CommonModule, MatIconModule, FormsModule],
	exports: [ControlToggleComponent],
	declarations: [ControlToggleComponent],
	providers: [],
})
export class ControlToggleModule {}
