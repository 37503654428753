export abstract class AbstractMessageType {
	abstract readonly id: Symbol;
	abstract readonly name: string;
}

export abstract class AbstractMessage<T = any> {
	constructor(data?: any, sender?: any) {
		this.data = data;
		this.sender = sender;
	}
	abstract readonly type: AbstractMessageType;
	abstract create(data?: T, sender?: any): AbstractMessage<T>;
	data: T;
	sender?: unknown;
}
