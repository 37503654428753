export interface IFilterData {
	parameters: Array<IFilterParamSettings>;
	objectsFilters: Array<IFilterObjectMap>;
	objectsParamValues: Array<IFilterParamOverrideDefaultValues>;
}

export interface IFilterObjectMap {
	/**
	 * Block id
	 */
	objectId: number;
	filterId: number;
	saveFilterState?: boolean;
}

export interface IFilterParamOverrideDefaultValues {
	/**
	 * Block id
	 */
	objectId: number;
	/**
	 * Filter param id
	 */
	paramId: number;
	/**
	 * Default value for override in
	 */
	default_: any;
}

export interface IFilterParamSettings<TData = any> {
	id: number;
	name: string;
	type: FilterParamType;
	title: string;
	titleResx?: string;
	filterId?: number;
	isReadOnly?: boolean;
	isRequired?: boolean;
	data?: TData;
	default_?: any;
	contextId?: number;
	defaultValueType?: string;
	dependsOnParamIDs?: number[];
	smartId?: number;
}

export enum FilterParamType {
	string = 'string',
	number = 'number',
	dateInterval = 'dateinterval',
	user = 'user',
	orgStructure = 'orgstructure',
	select = 'dropdown',
	hidden = 'hidden',
	usersGroup = 'usersgroup',
	group = 'group',
	categories = 'categories',
	subcat = 'subcat',
	date = 'date',
	period = 'period',
}

const valuesToKeys: Record<string, string> = {};
for (const prop of Object.getOwnPropertyNames(FilterParamType)) {
	valuesToKeys[FilterParamType[prop]] = prop;
}

function toFilterType(type: string): string {
	return String(type).toLowerCase();
}

const cache = {};
function memo(fn: (arg: string) => string): (arg: string) => string {
	return arg => {
		if (cache[arg]) {
			return cache[arg];
		}
		const res = fn(arg);
		cache[arg] = res;
		return res;
	};
}

export function isEqualFilterType(str: string, type: FilterParamType) {
	return strToFilterType(str) === type;
}

export const strToFilterType = memo(toFilterType);
