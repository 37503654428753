import { HttpErrorResponse } from '@angular/common/http';

export function isHttpRes4xx(err: any): err is HttpErrorResponse {
	if (err instanceof HttpErrorResponse) {
		return String(err.status)[0] === '4';
	}
	return false;
}

export function isHttpRes428(err: any): err is HttpErrorResponse {
	if (err instanceof HttpErrorResponse) {
		return err.status === 428;
	}
	return false;
}

export function isHttpRes5xx(err: any): err is HttpErrorResponse {
	if (err instanceof HttpErrorResponse) {
		return String(err.status)[0] === '5';
	}
	return false;
}

export function isHttpRes2xx(err: any): err is HttpErrorResponse {
	if (err instanceof HttpErrorResponse) {
		return String(err.status)[0] === '2';
	}
	return false;
}
