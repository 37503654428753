import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ContextMenuModule } from '@valhalla/common/context-menu';
import { LocalizationModule } from '@valhalla/localization';

import { SidebarHeaderModule } from '../sidebar-header/sidebar-header.module';
import { SpacesPanelItemModule } from './spaces-panel-item/spaces-panel-item.module';
import { SpacesPanelComponent } from './spaces-panel.component';

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		LocalizationModule,
		ContextMenuModule,
		SidebarHeaderModule,
		SpacesPanelItemModule,
		ContextMenuModule,
		MatMenuModule,
		MatDividerModule,
	],
	exports: [SpacesPanelComponent],
	declarations: [SpacesPanelComponent],
	providers: [],
})
export class SpacesPanelModule {}
