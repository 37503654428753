import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FeatherIconsModule } from '@spa/common/feather-icons';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { RouterModule } from '@angular/router';
import { ContextMenuModule } from '@valhalla/common/context-menu';
import { LocalizationModule } from '@valhalla/localization';

import { FavoriteExpandListModule } from '../favorites-panel/favorite-expand-list';
import { MyTasksNavLayoutPanelComponent } from './my-tasks-panel.component';
import { SidebarHeaderModule } from '../sidebar-header/sidebar-header.module';

@NgModule({
	imports: [
		CommonModule,
		MatListModule,
		RouterModule,
		MatIconModule,
		FavoriteExpandListModule,
		LocalizationModule,
		ContextMenuModule,
		FeatherIconsModule,
		SidebarHeaderModule,
	],
	exports: [MyTasksNavLayoutPanelComponent],
	declarations: [MyTasksNavLayoutPanelComponent],
	providers: [],
})
export class MyTasksNavLayoutPanelModule {}
