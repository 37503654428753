import { Injectable } from '@angular/core';
import { createDataLayer } from '@valhalla/utils';

@Injectable({ providedIn: 'root' })
export class GlobalSpaContextService {
	protected contextRef = createDataLayer<Partial<IGlobalSpaContext>>({
		keys: [],
		data: {},
	});

	readonly changed$ = this.contextRef.changed$;

	get value() {
		return this.contextRef.value;
	}

	commit(context: Partial<IGlobalSpaContext>) {
		const ref = this.contextRef.commit(context);
		return {
			destroy() {
				ref.rollback();
			},
		};
	}

	destroy(ref: any) {
		if (typeof ref?.destroy === 'function') {
			ref.destroy();
		}
	}

	startTransaction() {
		this.contextRef.startTransaction();
	}

	cancelTransaction() {
		this.contextRef.cancelTransaction();
	}

	endTransaction() {
		this.contextRef.endTransaction();
	}
}

export interface IGlobalSpaContext {
	/**
	 * any string keys that describe some SPA contexts
	 */
	keys: string[];

	data: Record<string, any>;
}
