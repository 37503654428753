<!-- <div class="vh-common-sidebar-content"> -->
<!-- <vh-sidebar-header
		[title]="'common.sideBarMyTasks' | resx"
		(searchInput)="searchHandler($event.target.value)"
		(searchClear)="searchHandler('')"
	>
	</vh-sidebar-header> -->
<!-- <div class="vh-common-sidebar-content-scrollable"> -->
<vh-fv-expand-list
	*ngIf="(searchMenu$ | async)?.length"
	[label]="'common.sideBarMyTasks' | resx"
	[expanded]="(searchInputHasValue$ | async) || (menuParent$ | async)?.expanded"
	[data]="menuParent$ | async"
	(expandCollapseEvent)="onExpand($event)"
>
	<ng-container *ngFor="let menu of searchMenu$ | async">
		<ng-container *ngIf="!menu?.children?.length">
			<vh-fv-expand-list-item
				(click)="navLink(menu, $event)"
				[label]="menu.name | resx"
				[counter]="menu.badge$ ? (menu.badge$ | async) : 0"
				[counterNew]="menu.badgeNew$ ? (menu.badgeNew$ | async) : 0"
				[counterWarn]="menu.badgeWarn$ ? (menu.badgeWarn$ | async) : 0"
				[class.active]="isItemSelected(menu)"
			></vh-fv-expand-list-item>
		</ng-container>
		<ng-container *ngIf="menu?.children?.length">
			<vh-fv-expand-list
				[label]="menu.name | resx"
				[expanded]="menu.expanded"
				[data]="menu"
				(expandCollapseEvent)="onExpand($event)"
			>
				<vh-fv-expand-list-item
					*ngFor="let item of menu.children"
					(click)="navLink(item, $event)"
					[label]="item.name | resx"
					[counter]="item.badge$ ? (item.badge$ | async) : 0"
					[counterNew]="menu.badgeNew$ ? (menu.badgeNew$ | async) : 0"
					[counterWarn]="item.badgeWarn$ ? (item.badgeWarn$ | async) : 0"
					[class.active]="isItemSelected(item)"
				></vh-fv-expand-list-item>
			</vh-fv-expand-list>
		</ng-container>
	</ng-container>
</vh-fv-expand-list>
<!-- </div> -->

<mat-menu #contextMenu="matMenu">
	<ng-template matMenuContent let-data="data">
		<button mat-menu-item (click)="addToFavorites(data)">
			<mat-icon svgIcon="vh-favourites-24"></mat-icon>Добавить в избранное
		</button>
	</ng-template>
</mat-menu>
