import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Inject,
	NgZone,
	ViewChild,
	inject,
} from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { getFileExtension, IAttachment, isImage, isVideo, getFileNameWithoutExtension } from '@valhalla/data/entities';
import { take } from 'rxjs/operators';
import { Downloader, IR7EditorMode, PlatformDetectorProvider, UrlProvider } from '@spa/core';
import { DataHttpService, IFileRef } from '@spa/data/http';
import { FileViewerComponent } from './file-viewer.component';
import { FileViewerService } from './file-viewer.service';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { BehaviorSubject } from 'rxjs';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { copyToClipboard } from '@valhalla/utils';
import { ClipboardSnackBarComponent } from '@spa/components/task/ui/task-card/clipboard-snack-bar/clipboard-snack-bar.component';

const SELECTOR = 'vh-file-viewer-dialog';
const CONFIRM_CLOSE_MESSAGE = 'У вас есть несохраненные данные, вы уверены, что хотите их отменить?';

/**
 * Компонент для рендера файлов
 * рендерит внутренний компонент по типу файла
 */
@Component({
	// tslint:disable-next-line:component-selector
	selector: SELECTOR,
	templateUrl: './file-viewer-dialog.component.html',
	styleUrls: ['./file-viewer-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [],
})
export class FileViewerDialogComponent implements AfterViewInit {
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: IFileViewerDialogComponentData,
		readonly dialogRef: MatDialogRef<FileViewerDialogComponent>,
		readonly cdr: ChangeDetectorRef,
		readonly urlBuilder: UrlProvider,
		readonly server: DataHttpService,
		readonly zone: NgZone,
		readonly fileViewerService: FileViewerService,
		readonly router: Router,
		readonly modal: ModalWindowsService,
		readonly mobileView: MobileViewService,
		readonly downloader: Downloader
	) {
		this.resize(false);
		this.file = data.file;
		this.file.id = this.file.id || this.file.uploadID || this.file.uploadId;

		this.options = data?.options;
		this.toolbarExtMenuButtons = this.defineFileViewerMenuButtonsConfig();

		const showNavigationActions = data?.options?.hasOwnProperty('showNavigationActions')
			? data?.options?.showNavigationActions
			: true;

		if (
			this.file.id &&
			showNavigationActions &&
			!this.options?.isEmailFile &&
			!this.options?.isMeetingFile &&
			!this.options?.openAsBlob
		) {
			server.files
				.getNeighborFiles(this.file.id)
				.pipe(take(1))
				.subscribe(refs => {
					this.fileRefs = refs;
					this.title = this.file.name;
					this.refreshNavigation();
					this.ready = true;
					this.cdr.detectChanges();
				});
		}
	}

	static selector = SELECTOR;

	@ViewChild(FileViewerComponent)
	public fileViewerComponent: FileViewerComponent;

	@HostBinding('class.mobile') get isMobile(): boolean {
		return this.mobileView.mobileMode;
	}

	@HostListener('window:beforeunload', ['$event'])
	handleBeforeUnloadEvent(event: any) {
		if (this.fileViewerComponent.isDirty) {
			event.preventDefault();
			event.returnValue = CONFIRM_CLOSE_MESSAGE;
		}
	}

	@HostListener('window:keyup', ['$event'])
	handleKeyupEvent(event: KeyboardEvent) {
		switch (event.key) {
			case 'Escape':
				this.close();
				break;
		}
	}

	@HostListener('window:keydown', ['$event'])
	handleKeydownEvent(event: KeyboardEvent) {
		switch (event.key) {
			case 'ArrowLeft':
				this.prev();
				break;
			case 'ArrowRight':
				this.next();
				break;
			case 'Escape':
				this.close();
				break;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.resize(true);
	}

	platofrm = inject(PlatformDetectorProvider);
	elRef: ElementRef<HTMLElement> = inject(ElementRef);
	ready = false;
	closed = false;
	file: Partial<IAttachment>;
	options: IFileViewerDialogComponentDataOptions = {};
	title: string;

	width: string;
	height: string;

	fileRefs: IFileRef[];
	allCount = 0;
	current: number;
	nextRef: IFileRef;
	prevRef: IFileRef;
	ignoreEditR7ext = ['doc', 'xls', 'ppt'];

	toolbarExtMenuButtons: {
		icon: string;
		clickHandler: (file: IAttachment) => void;
		visible: boolean;
		tooltipResx: string;
	}[];

	readonly isImage = isImage;
	readonly isVideo = isVideo;
	readonly getFileExtension = getFileExtension;
	readonly getFileNameWithoutExtension = getFileNameWithoutExtension;
	readonly fileVersionsCount$ = new BehaviorSubject<number>(0);

	refreshNavigation() {
		if (this.fileRefs) {
			const index = this.fileRefs.findIndex(ref => ref.fileId == this.file.id);
			if (index > -1 && this.fileRefs.length > 1) {
				if (index == 0) {
					this.nextRef = this.fileRefs[1];
					this.prevRef = this.fileRefs[this.fileRefs.length - 1];
				} else if (index == this.fileRefs.length - 1) {
					this.nextRef = this.fileRefs[0];
					this.prevRef = this.fileRefs[this.fileRefs.length - 2];
				} else {
					this.nextRef = this.fileRefs[index + 1];
					this.prevRef = this.fileRefs[index - 1];
				}

				this.allCount = this.fileRefs.length;
				this.current = index + 1;
			} else {
				this.allCount = this.fileRefs.length;
				this.current = 1;
			}
		}
	}

	ngAfterViewInit(): void {
		this.cdr.detectChanges();
	}

	setFile(fileId: number) {
		this.ready = false;
		this.fileViewerComponent.setFileById(fileId);
		this.options['modeR7Editor'] = IR7EditorMode.view;
		this.cdr.detectChanges();
	}

	next() {
		if (this.nextRef && this.checkClosing()) {
			this.setFile(this.nextRef.fileId);
		}
	}

	prev() {
		if (this.prevRef && this.checkClosing()) {
			this.setFile(this.prevRef.fileId);
		}
	}

	public onReady(newFile) {
		this.file = newFile;
		this.title = this.file.name;
		this.ready = true;
		this.refreshNavigation();
		this.cdr.detectChanges();
		if (this.file.id && !this.options?.isEmailFile && !this.options?.isMeetingFile && !this.options?.openAsBlob) {
			this.server.files
				.getFileVersion(this.file.id)
				.pipe(take(1))
				.subscribe(data => this.fileVersionsCount$.next(data?.length));
		}
	}

	public resize(detectChanges: boolean) {
		if (detectChanges) {
			//this.cdr.detectChanges();
		}
	}

	private checkClosing() {
		return !this.closed && (!this.fileViewerComponent.isDirty || confirm(CONFIRM_CLOSE_MESSAGE));
	}

	public close() {
		if (this.checkClosing()) {
			this.zone.runTask(() => {
				this.dialogRef.close();
			});
			this.closed = true;
			return true;
		}
		return false;
	}

	openInNewWindow(file: IAttachment, shouldCloseInitialDialog = true, editMode = false) {
		const id = file?.id;

		const isElectron = this.platofrm.isElectron();

		if (id) {
			let url = isElectron ? `/file/${id}` : `/noframe/file/${id}`;

			if (file?.versionId) {
				url += `/${file?.versionId}`;
			}

			const queryParams = {};

			if (this.fileViewerService.isOfficeFile(this.file) && this.options?.useR7Editor) {
				if (editMode) {
					queryParams['mode'] = IR7EditorMode.edit;
					const frame = this.elRef.nativeElement.querySelector<HTMLIFrameElement>('vh-office-viewer iframe');
					if (frame && isElectron) {
						let src = frame.src;
						src = src.substring(0, src.indexOf('mode='));
						src += `mode=${IR7EditorMode.edit}`;
						frame.src = src;
						return;
					}
				} else {
					queryParams['mode'] = this.options.modeR7Editor;
				}
				url = this.router.serializeUrl(this.router.createUrlTree([url], { queryParams }));
			}

			url = this.urlBuilder.getUrl(`${url}`, true);

			window.open(url, '_blank');
			if (shouldCloseInitialDialog) {
				return this.close();
			}
		}

		if (shouldCloseInitialDialog) {
			return this.close();
		}
	}

	isOfficeFile() {
		return this.fileViewerService.isOfficeFile(this.file);
	}

	officeFileOnlyReadModeSupport() {
		return !this.isBtnSetEditMode();
	}

	officeFileUnsupportedExtension() {
		const ext = getFileExtension(this.file.name || this.file.fileName);
		return this.ignoreEditR7ext.includes(ext);
	}

	copyFileLink(file: IAttachment): void {
		const link = this.urlBuilder.getAbsoluteUrl(this.downloader.createDownloadLink(file, true));
		void copyToClipboard(link);
		let result: boolean;
		const snackBar = this.modal.openSnackBar(ClipboardSnackBarComponent, {
			data: {
				actions: [{ action: () => (result = true), name: 'Ок', nameResx: 'common.ok', color: 'accent' }],
				messageResx: 'common.linkCopied',
			},
		});

		snackBar
			.afterOpened()
			.pipe(take(1))
			.subscribe(_ => {
				setTimeout(() => {
					snackBar.close();
				}, 1000);
			});
	}

	isBtnSetEditMode() {
		if (this.officeFileUnsupportedExtension()) {
			return false;
		}
		return (
			this.fileViewerService.isOfficeFile(this.file) &&
			this.options?.useR7Editor &&
			this.options?.modeR7Editor === IR7EditorMode.view &&
			!this.fileViewerService.isExceptionFileWebApps(this.file) &&
			!this.options?.isEmailFile &&
			!this.options?.isMeetingFile &&
			!this.options?.openAsBlob
		);
	}

	// isEditMode() {
	// 	return (
	// 		this.fileViewerService.isOfficeFile(this.file) &&
	// 		this.options?.useR7Editor &&
	// 		this.options?.modeR7Editor === IR7EditorMode.edit
	// 	);
	// }
	//
	// openEditModeR7Editor(file: Partial<IAttachment>) {
	// 	this.ready = false;
	// 	this.options['modeR7Editor'] = IR7EditorMode.edit;
	// 	this.fileViewerComponent.init();
	// 	this.cdr.detectChanges();
	// }

	downloadFile(file: Partial<IAttachment>): void {
		this.fileViewerService.downloadFile(file);
	}

	downloadAllFiles(): void {
		this.fileViewerService.downloadAllFiles();
	}

	onShowVersion(file: Partial<IAttachment>): void {
		this.fileViewerService.openVersionFile(file);
	}

	deleteFile(file: Partial<IAttachment>) {
		return this.server.task
			.deleteFile({
				parentFolderType: 7,
				parentFolderId: this.data.taskId,
				fileId: file.id,
			})
			.pipe(take(1))
			.subscribe({
				complete: () => {
					this.dialogRef.close();
				},
				error: err => {
					this.modal.openError(err?.error?.errors[0]?.message);
				},
			});
	}

	defineFileViewerMenuButtonsConfig(): {
		icon: string;
		clickHandler: (file: IAttachment) => void;
		visible: boolean;
		tooltipResx: string;
	}[] {
		return [
			{
				icon: 'vh-open-in-new-tab-24',
				clickHandler: this.openInNewWindow.bind(this),
				visible: !this.options?.isMeetingFile && !this.options?.isEmailFile && !this.options?.openAsBlob,
				tooltipResx: 'common.openInNewTab',
			},
			{
				icon: 'vh-copy-24',
				clickHandler: this.copyFileLink.bind(this),
				visible: true,
				tooltipResx: 'common.attachmentsCopyLink',
			},
			{
				icon: 'vh-download-24',
				clickHandler: this.downloadFile.bind(this),
				visible: true,
				tooltipResx: 'common.fileDownload',
			},
			{
				icon: 'vh-download-all-24',
				clickHandler: this.downloadAllFiles.bind(this),
				visible: true,
				tooltipResx: 'common.allDownload',
			},
			{
				icon: 'vh-hamburger-menu-24',
				clickHandler: this.onShowVersion.bind(this),
				visible: !this.options?.isEmailFile && !this.options?.isMeetingFile && !this.options?.openAsBlob,
				tooltipResx: 'common.attachmentsShowVersions',
			},
			{
				icon: 'vh-trash-24',
				clickHandler: this.deleteFile.bind(this),
				visible: !this.options?.isEmailFile && !this.options?.isMeetingFile && !this.options?.openAsBlob,
				tooltipResx: 'common.mtfDelete',
			},
		];
	}
}

export interface IFileViewerDialogComponentData {
	file: Partial<IAttachment>;
	options?: IFileViewerDialogComponentDataOptions;
	taskId?: number;
}

export interface IFileViewerDialogComponentDataOptions {
	useR7Editor?: boolean;
	modeR7Editor?: IR7EditorMode;
	isMeetingFile?: boolean;
	isEmailFile?: boolean;
	readonly?: boolean;
	lastestVersionId?: number;
	showNavigationActions?: boolean;
	viewer?: any;
	openAsBlob?: boolean;
}
