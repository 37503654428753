import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'vh-layout-navbar-horizontal-style-1',
	templateUrl: './style-1.component.html',
	styleUrls: ['./style-1.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class NavbarHorizontalStyle1Component implements OnInit, OnDestroy {
	@Input()
	navigation: any;
	fuseConfig: any;

	ngOnInit(): void {}

	ngOnDestroy(): void {}
}
