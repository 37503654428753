import { customElementsDefineIfNotYet } from './custom-elements-define-if-not-yet';

class SpaLinkTag extends HTMLAnchorElement {
	static get observedAttributes() {
		return ['href'];
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'href' && newValue?.includes('://')) {
			// need diff and restore original value
			this.rel = 'noopener noreferrer';
		}
	}
}

export const SpaLinkTagName = 'vh-link';

export function registerSpaLinkTag() {
	customElementsDefineIfNotYet(SpaLinkTagName, SpaLinkTag, {
		extends: 'a',
	});
}
