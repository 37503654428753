import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Downloader } from '@spa/core';
import {
	DataHttpCommonService,
	IApiResponse,
	IGetMultiLookupExcelExport,
	IMultiLookupExcelImport,
	IMultiLookupExcelImportResult,
} from '@spa/data/http';
import { map, mergeMap, Observable } from 'rxjs';

import ApiVersion from '../api-versions';
import {
	EpDataHttpService,
	IChooseAllTasksParams,
	ICopyMultilookupTasks,
	IEpMultilookupFoldersDeleteReq,
	IEpMultilookupFoldersReq,
	IEpMultilookupFoldersRes,
	IEpMultilookupFoldersUpdateReq,
	IEpMultilookupTaskChecked,
	IEpTableExportParams,
	IEpTableImportParams,
	IEpTreeReq,
	IEpTreeRes,
	IGetEpAddressInfoResult,
	IGetEpLookupValuesParams,
	IGetEpLookupValuesResult,
	IGetEpSelectValuesParams,
	IGetEpSelectValuesResult,
	IGetMultiLookupTableData,
	IGetMultiLookupTableSettings,
	IHierarchyLookupResponse,
	IUpdateMultiLookupReq,
	IUpdateMultiLookupTableData,
	SuggestTypes,
} from './abstract';
import {
	IEpTableDataDto,
	IEpTableDataRowCellDto,
	IEpTableSettingsDto,
	IGetTableDataCriteria,
	IGetTableSettingsCriteria,
	IUpdateTableDataParams,
} from './table';
import { getAppTopInjector } from '@spa/api/injectors';

@Injectable()
export class EpDataHttpServiceImpl implements EpDataHttpService {
	constructor(public readonly http: HttpClient, public readonly common: DataHttpCommonService) {}

	getTableSettings({ epId, subcatId, taskId }: IGetTableSettingsCriteria): Observable<IEpTableSettingsDto> {
		const url = this.common.getApiUrl(
			`ep/tableSettings/${epId}/subcat/${subcatId}${taskId ? '/' + taskId : ''}`,
			ApiVersion.v12
		);
		return this.http.get<IEpTableSettingsDto>(url);
	}

	getTableData<T extends IEpTableDataRowCellDto = IEpTableDataRowCellDto>({
		epId,
		taskId,
		meta,
	}: IGetTableDataCriteria): Observable<IEpTableDataDto<T>> {
		const url = this.common.getApiUrl(`task/${taskId}/ep/table/${epId}`, ApiVersion.v12);
		return this.http.post<IEpTableDataDto<T>>(url, meta);
	}

	getTableMultiselectData<T extends IEpTableDataRowCellDto = IEpTableDataRowCellDto>({
		epId,
		taskId,
		meta,
	}: IGetTableDataCriteria): Observable<IEpTableDataDto<T>> {
		const url = this.common.getApiUrl(`task/${taskId}/ep/table/${epId}/multiselect`, ApiVersion.v12);
		return this.http.post<IEpTableDataDto<T>>(url, meta);
	}

	upateTableData({ epId, taskId, meta }: IUpdateTableDataParams): Observable<any> {
		const url = this.common.getApiUrl(`task/${taskId}/ep/table/${epId}/update`, ApiVersion.v12);
		return this.http.post<any>(url, meta);
	}

	getEpSelectValues(params: IGetEpSelectValuesParams): Observable<IGetEpSelectValuesResult> {
		const url = this.common.getApiUrl(`ep/select`, ApiVersion.v12);
		return this.http.post<IGetEpSelectValuesResult>(url, params);
	}

	getLookupHierarchyValues(params: IGetEpSelectValuesParams): Observable<IHierarchyLookupResponse> {
		const url = this.common.getApiUrl(`extparams/lookup/${params.extParamId}/hierarchy/data`);
		return this.http.post<IHierarchyLookupResponse>(url, params);
	}

	getEpLookupValues(params: IGetEpLookupValuesParams): Observable<IGetEpLookupValuesResult> {
		const url = this.common.getApiUrl(`ep/lookup`, ApiVersion.v12);
		return this.http.post<IGetEpLookupValuesResult>(url, params);
	}

	getEpAddressSuggections(address: string): Observable<any> {
		const url = this.common.getApiUrl(`address/suggest/${address}`);
		return this.http.get(url);
	}

	getEpAddressInfo(addressId: number): Observable<IGetEpAddressInfoResult> {
		const url = this.common.getApiUrl(`address/${addressId}`, ApiVersion.v10);
		return this.http.get(url) as Observable<IGetEpAddressInfoResult>;
	}

	getMultiLookupTableSettings(params: IGetMultiLookupTableSettings): Observable<any> {
		let url = `eps/multilookup/settings/${params.extParamId}/subcat/${params.subcatId}`;
		if (params.taskId) {
			url += `/${params.taskId}`;
		}
		url = this.common.getApiUrl(url, ApiVersion.v12);
		return this.http.get(url);
	}

	getMultiLookupTableData(params: IGetMultiLookupTableData): Observable<any> {
		let url = `eps/multilookup/${params.extParamId}`;
		if (params.taskId) {
			url += `/${params.taskId}`;
		}
		url = this.common.getApiUrl(url, ApiVersion.v12);
		const body = Object.assign(
			{
				take: 100,
				skip: 0,
				sort: [],
				group: [],
				filter: null,
				subcatId: params.subcatId,
				isNewTask: params.isNewTask || false,
				taskIds: params.taskIds,
				folderId: params?.folderId,
			},
			params.gridState || {}
		);
		return this.http.post(url, body);
	}

	getMultiLookupTableExcelExport(params: IGetMultiLookupExcelExport): Observable<any> {
		const url = this.common.getApiUrl(
			`eps/multilookup/${params.extParamId}/${params.taskId}/excel/export`,
			ApiVersion.v12
		);
		const body: Partial<IEpTableExportParams> = {
			filter: params.gridState?.filter || null,
			group: params.gridState?.group || [],
			observe: params.gridState?.observe || 'response',
			skip: params.gridState?.skip || 0,
			take: params.gridState?.take,
			sort: params.gridState?.sort || [],
		};
		const facadeInjector = getAppTopInjector();
		const downloader = facadeInjector?.get(Downloader) || inject(Downloader);
		return this.http
			.post(url, body, {
				responseType: 'blob',
			})
			.pipe(
				mergeMap(file => {
					const url = downloader.createDownloadLink(file);
					return downloader.download(url, 'export.xlsx', true);
				})
			);
	}

	importMultiLookupTableExcel(params: IMultiLookupExcelImport): Observable<{ data: IMultiLookupExcelImportResult }> {
		const url = this.common.getApiUrl(`extParams/import/excel/MultiSelect/${params.extParamId}/${params.taskId}`);
		return this.http.post(url, params.formData, {
			//params: new HttpParams({ fromObject: params.importSettings }).append('v', '2.0'),
		}) as Observable<{ data: IMultiLookupExcelImportResult }>;
	}

	updateMultiLookupTableData(params: IUpdateMultiLookupTableData): Observable<any> {
		let url = `eps/multilookup/${params.extParamId}/${params.taskId || null}/update`;

		if (params?.comment) {
			url = `${url}?comment=${params?.comment}`;
		}

		url = this.common.getApiUrl(url, ApiVersion.v12);

		return this.http.post(url, `'${params.data}'`, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	exportTable(params: IEpTableExportParams) {
		const url = this.common.getApiUrl(
			`task/${params.taskId}/ep/table/${params.extParamId}/excel/export`,
			ApiVersion.v12
		);
		const data: Partial<IEpTableExportParams> = {
			filter: params.filter,
			group: params.group,
			sort: params.sort,
			skip: params.skip || 0,
			take: params.take || 100,
		};
		const facadeInjector = getAppTopInjector();
		const downloader = facadeInjector?.get(Downloader) || inject(Downloader);
		return this.http
			.post(url, data, {
				responseType: 'blob',
			})
			.pipe(
				mergeMap(file => {
					const url = downloader.createDownloadLink(file);
					return downloader.download(url, params.fileName || 'export.xlsx');
				})
			);
	}

	importTable(params: IEpTableImportParams): Observable<{ data: IMultiLookupExcelImportResult }> {
		const requestParams = `?v=2.0&ignoreNotFoundKeyValues=${params.importSettings.ignoreNotFoundKeyValues}&overwriteTable=${params.importSettings.overwriteTable}&columnNamesInFirstRow=${params.importSettings.columnNamesInFirstRow}`;
		const url = this.common.getApiUrl(
			`extParams/import/excel/Table/${params.extParamId}/${params.taskId}${requestParams}`
		);
		return this.http.post(url, params.formData) as Observable<{ data: IMultiLookupExcelImportResult }>;
	}

	getTreeData(params: IEpTreeReq): Observable<IEpTreeRes> {
		const url = this.common.getApiUrl(`extparams/tree/nodes`);
		return this.http.post<IEpTreeRes>(url, params);
	}

	getEpTextSuggestValues(suggestType: SuggestTypes, searchQuery: string): Observable<string[]> {
		const url = this.common.getApiUrl(`suggests/${suggestType}/${searchQuery}`);
		return this.http.get<any>(url).pipe(map(response => response.data as string[]));
	}

	chooseAllTasks(params: IChooseAllTasksParams): Observable<any> {
		let url = `eps/multilookup/${params.extParamId}/${params.taskId || null}/choose-all`;
		url = this.common.getApiUrl(url, ApiVersion.v12);
		return this.http.post<any>(url, params.data);
	}

	getMultilookupFolders(params: IEpMultilookupFoldersReq): Observable<IEpMultilookupFoldersRes> {
		let url = `extparams/multilookup/${params?.extParamId}/${params?.taskId}/folders`;
		url = this.common.getApiUrl(url);
		return this.http.get<IApiResponse<IEpMultilookupFoldersRes>>(url).pipe(map(res => res?.data));
	}

	updateMultilookupFolders(params: IEpMultilookupFoldersUpdateReq): Observable<any> {
		let url = `extparams/multilookup/${params.extParamId}/${params.taskId}/folders/update`;
		url = this.common.getApiUrl(url);
		return this.http.post<IApiResponse<any>>(url, params?.data);
	}

	deleteMultilookupFolder(params: IEpMultilookupFoldersDeleteReq): Observable<any> {
		let url = `extparams/multilookup/${params.extParamId}/${params.taskId}/folders/delete`;
		url = this.common.getApiUrl(url);
		return this.http.post<IApiResponse<any>>(url, params?.data);
	}

	updateMultiLookup(params: IUpdateMultiLookupReq): Observable<any> {
		let url = `eps/multilookup/${params.extParamId}/${params.taskId}/update`;
		url = this.common.getApiUrl(url, ApiVersion.v12);

		return this.http.post(url, `'${JSON.stringify(params.data)}'`, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	checkedMultilookupTask(params: IEpMultilookupTaskChecked): Observable<any> {
		let url = `extparams/multilookup/${params.extParamId}/${params.taskId}/todo/checked`;
		url = this.common.getApiUrl(url);
		return this.http.post<IApiResponse<any>>(url, params?.data);
	}

	uncheckedMultilookupTask(params: IEpMultilookupTaskChecked): Observable<any> {
		let url = `extparams/multilookup/${params.extParamId}/${params.taskId}/todo/unchecked`;
		url = this.common.getApiUrl(url);
		return this.http.post<IApiResponse<any>>(url, params?.data);
	}

	copyMultilookupTasks(params: ICopyMultilookupTasks): Observable<any> {
		let url = `extparams/multilookup/todo/copy`;
		url = this.common.getApiUrl(url);
		return this.http.post<IApiResponse<any>>(url, params);
	}
}
