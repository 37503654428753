import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { RouterModule } from '@angular/router';

import { ProgressBarCommonComponent } from './progress-bar.component';

@NgModule({
	imports: [CommonModule, RouterModule, MatProgressBarModule],
	declarations: [ProgressBarCommonComponent],
	exports: [ProgressBarCommonComponent],
})
export class ProgressBarCommonModule {}
