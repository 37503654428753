import { guid } from '@valhalla/utils';

export interface IAttachment
	extends Partial<{
		[key: string]: any;
		guid?: string;
		file?: File;
		uploadId: number;
		uploadID?: number;
		fileName: string;
		fileMime: string;
		size: number;
		filesize: number;
		fileSize?: number;
		isDeleted: boolean;
		isOldVersion: boolean;
		versionId: number;
		versionID?: number;
		extInfo?: {
			height: string;
			width: string;
			duration?: string;
		};
		id: number;
		name: string;
		mime: string;
		isPinned?: boolean;
		date?: string;
		type?: string;
		isExistInTask: boolean;
		hasEdsHistory?: boolean;
	}> {}

export enum MimeType {
	pdf = 'application/pdf',
	jpeg = 'image/jpeg',
	png = 'image/png',
	svg = 'image/svg+xml',
	webp = 'image/webp',

	audioMpeg = 'audio/mpeg',
	audioMp4 = 'audio/mp4',
	audioAAC = 'audio/aac',
	audioOGG = 'audio/ogg',
	videoMp4 = 'video/mp4',
	videoWebm = 'video/webm',
	videoOGG = 'video/ogg',
	videoQuickTime = 'video/quicktime',

	word = 'application/msword',
	word2 = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	excel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	excel2 = 'application/vnd.ms-excel',
	powerPoint = 'application/vnd.ms-powerpoint',

	text = 'text/plain',
	textXml = 'text/xml',
	appXml = 'application/xml',
	xZip = 'application/x-zip-compressed',
	zip = 'application/zip',
	octetStream = 'application/octet-stream',
	gif = 'image/gif',
	json = 'application/json',
}

export function isVideo(file: Partial<IAttachment> | string) {
	const videoMimes: string[] = [MimeType.videoMp4, MimeType.videoWebm, MimeType.videoOGG, MimeType.videoQuickTime];
	const mime = typeof file === 'string' ? file : file?.mime;
	return videoMimes.includes(String(mime).toLowerCase());
}

export function isAudio(file: Partial<IAttachment> | string) {
	const audioMimes: string[] = [MimeType.audioAAC, MimeType.audioMp4, MimeType.audioMpeg, MimeType.audioOGG];
	const mime = typeof file === 'string' ? file : file?.mime;
	return audioMimes.includes(String(mime).toLowerCase());
}

export function isOfficeWord(mime: string) {
	return mime === MimeType.word || mime === MimeType.word2;
}

export function isOfficeExcel(mime: string) {
	return mime === MimeType.excel;
}

export function isImage(mime: string) {
	return [MimeType.jpeg, MimeType.png, MimeType.gif, MimeType.webp].includes(mime as MimeType);
}

export function isSvg(mime: string) {
	return [MimeType.svg].includes(mime as MimeType);
}

export function isPdf(mime: string) {
	return mime === MimeType.pdf;
}

export function isOfficeFile(file: Partial<IAttachment>) {
	const byMime = [MimeType.word, MimeType.word2, MimeType.excel].includes(file.mime as MimeType);
	const byExt = Boolean(getOfficeAppIdByExtension(getFileExtension(file?.name || file?.fileName), false));
	return byMime || byExt;
}

export function isText(file: Partial<IAttachment>) {
	const byMime = [MimeType.text, MimeType.textXml].includes((file.mime || file.fileMime) as MimeType);
	const ext = getFileExtension(file?.name || file?.fileName);
	const byExt = ['txt', 'json', 'config', 'cfg', 'js', 'css', 'cs', 'sql', 'ts', 'html', 'lua', 'pgsql'].includes(
		String(ext).toLocaleLowerCase()
	);
	return byMime || byExt;
}

export function isScreenshot(attach: Partial<IAttachment> | File) {
	const fileName = (attach.name || (attach as Partial<IAttachment>).fileName)?.toLowerCase();
	return ['image.png', 'изображение.png', 'screenshot.png'].some(name => fileName?.includes(name));
}

export function getMime(file: Partial<IAttachment>) {
	return file?.mime || file?.fileMime;
}

export enum OfficeAppId {
	word = 'ms-word',
	excel = 'ms-excel',
	powerpoint = 'ms-powerpoint',
	visio = 'ms-visio',
	access = 'ms-access',
	publisher = 'ms-publisher',
	project = 'ms-project',
	infopath = 'ms-infopath',
	xps = 'ms-xps',
	spd = 'ms-spd',
}

export function getOfficeAppIdByExtension(extension: string, useDefault = true) {
	if (!extension) {
		return;
	}

	const officeApps = {
		[OfficeAppId.word]: ['doc', 'dot', 'wbk', 'docx', 'docm', 'dotx', 'dotm', 'docb'],
		[OfficeAppId.excel]: ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xla', 'xlam', 'xll', 'xlw'],
		[OfficeAppId.powerpoint]: [
			'ppt',
			'pot',
			'pps',
			'pptx',
			'pptm',
			'potx',
			'potm',
			'ppam',
			'ppsx',
			'ppsm',
			'sldx',
			'sldm',
		],
		[OfficeAppId.visio]: [
			'vsd',
			'vss',
			'vst',
			'vsw',
			'vdx',
			'vsx',
			'vtx',
			'vsdx',
			'vsdm',
			'vssx',
			'vssm',
			'vstx',
			'vstm',
			'vsl',
		],
		[OfficeAppId.access]: ['accdb', 'accde', 'accdt', 'accdr'],
		[OfficeAppId.publisher]: ['pub'],
		[OfficeAppId.project]: ['mpp', 'mpt', 'mpx', 'mpd', 'mdb', 'odbc'],
		[OfficeAppId.infopath]: ['infopathxml', 'xsf', 'xsn', 'xtp2'],
		[OfficeAppId.xps]: ['xps'],
		[OfficeAppId.spd]: [
			'dwt',
			'fwp',
			'htc',
			'htt',
			'htx',
			'spdesignasax',
			'spdesignasmx',
			'spdesignbot',
			'spdesignconfig',
			'spdesigncss',
			'spdesigndoclib',
			'spdesignedited',
			'spdesignhomeop',
			'spdesignhomepg',
			'spdesignhtml',
			'spdesignhtt',
			'spdesignjs',
			'spdesignlinkbar',
			'spdesignlist',
			'spdesignlistdata',
			'spdesignmaster',
			'spdesignnav',
			'spdesignnopub',
			'spdesignopen',
			'spdesignpiclib',
			'spdesignresx',
			'spdesignshtm',
			'spdesignshtml',
			'spdesignsitemap',
			'spdesignskin',
			'spdesignstm',
			'spdesignsurvey',
			'spdesigntheme',
			'spdesigntxt',
			'spdesignunknown',
			'spdesignweb',
			'spdesignwfdoclib',
			'spdesignxml',
			'spdesignxoml',
			'spdesignxsl',
			'spdesignxslt',
		],
	};

	const ext = String(extension).toLowerCase();
	for (const app in officeApps) {
		if (officeApps[app].indexOf(ext, 0) >= 0) return app;
	}
	if (useDefault) {
		return OfficeAppId.word; // default like in TCBaseNew.js task center old
	}
}

export function openOfficeWebDavInEditMode(urlFilePath: string, appId: string, onError?: (err: any) => void) {
	const link = document.createElement('a');
	try {
		if (!urlFilePath || !appId) {
			throw new Error('Receive empty arguments!');
		}
		if (!urlFilePath.startsWith('http')) {
			throw new Error('Argument urlFilePath must start with http(s)');
		}

		const url = appId + ':ofe|u|' + urlFilePath;

		link.href = url;
		link.setAttribute('is', 'vh-link');
		document.body.appendChild(link);
		link.onerror = onError;
		link.click(); // this is fail and try catch not working
	} catch (err) {
		console.error(err);
		onError && onError(err);
	} finally {
		document.body.removeChild(link);
	}
}

export function getFileExtension(fileName: string) {
	if (!fileName || typeof fileName !== 'string') {
		return;
	}

	const pointIdx = fileName.lastIndexOf('.');
	if (pointIdx === -1) {
		return;
	}

	return fileName.substring(pointIdx + 1);
}

export function getFileNameWithoutExtension(fileName: string): string {
	return fileName.replace(/\.[^.]*$/, '');
}

export function mapFileToAttachment(file: File): IAttachment {
	if (!file) {
		return;
	}
	return {
		file: file,
		name: file.name,
		fileName: file.name,
		size: file.size,
		fileSize: file.size,
		mime: file.type,
		fileMime: file.type,
		guid: guid(),
	};
}

export function adjustAttachment(attach: Partial<IAttachment>): Partial<IAttachment> {
	if (!attach) {
		return attach;
	}
	attach.id = attach.uploadID = attach.uploadId = attach.id || attach.uploadID || attach.uploadId;
	attach.fileMime = attach.mime = attach.fileMime || attach.mime;
	attach.name = attach.fileName = attach.fileName || attach.name;
	return attach;
}
