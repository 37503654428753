import { ISurveyDescription, IRaiting } from '@valhalla/data/entities';
import { Observable } from 'rxjs';

export abstract class SurveyDataHttpService {
	abstract getResult(userId: number): Observable<ISurveyDescription>;
	abstract saveResult(taskId: number, data: any): Observable<any>;
	abstract sendRating(taskId: number, points: Array<IRaiting>): Observable<any>;
	abstract getRenderConfig(taskId: number): Observable<any>;
	abstract getConfig(taskId: number): Observable<any>;
	abstract saveConfig(taskId: number, config: any): Observable<any>;
}
