let init = false;
export function initializeDialogs() {
	if (init) {
		return;
	}
	init = true;
	import('sweetalert')
		.then(m => m.default)
		.then(swal => {
			(window as any).dialogs = new Dialogs(swal);
		})
		.catch(console.error);
}

class Dialogs {
	constructor(readonly swal: any) {
		window.addEventListener('resize', this.#onWindowResize);
	}

	#onWindowResize() {
		fitWrapper();
	}

	destroy() {
		window.removeEventListener('resize', this.#onWindowResize);
	}

	alert(title, text, onClose) {
		this._alertWrapper(
			{
				title: title,
				text: text,
				icon: 'info',
			},
			onClose
		);
	}

	error(title, text, onClose) {
		this._alertWrapper(
			{
				title: title,
				text: text,
				type: 'error',
				icon: 'error',
			},
			onClose
		);
	}

	success(title, text, onClose) {
		this._alertWrapper(
			{
				title: title,
				text: text,
				type: 'success',
				icon: 'success',
			},
			onClose
		);
	}

	warning(title, text, onClose) {
		this._alertWrapper(
			{
				title: title,
				text: text,
				type: 'warning',
				icon: 'warning',
			},
			onClose
		);
	}

	///callback returns 'false' for cancel, 'undefined' for empty user input and confirm or user value for other input and confirm
	prompt(title, text, value, onClose) {
		this._promptWrapper(
			{
				title: title,
				text: text,
				content: {
					element: 'input',
					attributes: {
						value: value || '',
					},
				},
			},
			onClose
		);
	}

	promptPassword(title, text, value, onClose, options) {
		if (options) {
			this._promptWrapper(
				{
					title: title,
					text: text,
					content: {
						element: 'input',
						attributes: {
							value: value || '',
							type: 'password',
						},
					},
					...options,
				},
				onClose
			);
		} else {
			this._promptWrapper(
				{
					title: title,
					text: text,
					content: {
						element: 'input',
						attributes: {
							value: value || '',
							type: 'password',
						},
					},
				},
				onClose
			);
		}
	}

	custom(title, text, options, onClose) {
		options = Object.assign(options, {
			title: title,
			//text: text,
			content: {
				element: 'div',
				attributes: {
					innerHTML: `${text}`,
				},
			},
		});
		this.customWrapper(options, onClose);
	}

	close() {
		this.swal.close();
	}

	_showDialogInternal(options, onClose) {
		this.swal({ ...options }).then(result => {
			onClose?.(result);
		});

		fitWrapper();

		return this.swal;
	}

	_alertWrapper(options, onClose) {
		options = mergeOptionWithDefault(options);

		if (!options.title && !options.text) {
			return console.warn('[SweetAlert] Dialog must have text or title options');
		}

		const content = document.createElement('div');
		content.innerHTML = options.text;
		options.text = undefined;
		options.content = content;

		const sw = this._showDialogInternal(options, onClose);

		return sw;
	}

	_promptWrapper(options, onClose) {
		options = mergeOptionWithDefault(options);
		options.buttons = [options.cancelButtonText || 'Отмена', options.confirmButtonText || 'OK'];
		options.closeOnEsc = typeof options.allowEscapeKey == 'undefined' ? true : options.allowEscapeKey;

		options.closeOnClickOutside = typeof options.allowOutsideClick == 'undefined' ? true : options.allowOutsideClick;
		const sw = this._showDialogInternal(options, onClose);

		return sw;
	}

	customWrapper(options, onClose) {
		options = mergeOptionWithDefault(options);
		const showCancelButton = typeof options.showCancelButton == 'undefined' ? true : options.showCancelButton;
		const showConfirmButton = typeof options.showConfirmButton == 'undefined' ? true : options.showConfirmButton;

		if (showCancelButton) {
			if (!showConfirmButton) {
				options.button = options.cancelButtonText || 'Отмена';
			} else {
				if (options.reverseButtons) {
					options.buttons = {
						confirm: options.confirmButtonText || 'OK',
						cancel: options.cancelButtonText || 'Отмена',
					};
				} else {
					options.buttons = [options.cancelButtonText || 'Отмена', options.confirmButtonText || 'OK'];
				}
			}
		} else {
			if (!showConfirmButton) {
				options.button = false;
			} else {
				options.button = options.confirmButtonText || 'OK';
			}
		}

		options.closeOnEsc = typeof options.allowEscapeKey == 'undefined' ? true : options.allowEscapeKey;

		options.closeOnClickOutside = typeof options.allowOutsideClick == 'undefined' ? true : options.allowOutsideClick;
		const sw = this._showDialogInternal(options, onClose);

		return sw;
	}
}

function mergeOptionWithDefault(options) {
	options.html = true;
	options.animation = false;

	if (options.title && !options.text) {
		options.text = options.title;
		options.title = undefined;
	}

	options.title = prepareText(options.title);
	options.text = prepareText(options.text);

	return options;
}

function prepareText(text) {
	if (!text) {
		return text;
	}
	if (text.toString) {
		text = text.toString();
	} else {
		text = text + '';
	}

	return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

function fitWrapper() {
	const sw = document.getElementsByClassName('sweet-alert');
	if (sw[0]) {
		const obj = sw[0] as HTMLElement; /* объект окна */

		/* Если контент окна не помещается*/
		if (obj.offsetHeight > window.top.innerHeight) {
			obj.style.height = window.top.innerHeight - 100 + 'px';
			obj.className += ' fit';
		} else {
			/* Простое выравнивание по центру вертикали */
			setTimeout(function () {
				/* Cпозиционируем окно по центру вертикали */
				const marginTop = Math.floor(obj.offsetHeight / 2);
				obj.style.marginTop = '-' + marginTop + 'px';
			}, 50);
		}
	}
}
