import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UrlProvider } from '@spa/core';
import { IAttachment } from '@spa/data/entities';
import { IFileInfo } from '@spa/data/http';
import { IFileViewerDialogComponentDataOptions } from '../../file-viewer-dialog.component';
import { IFileViewer, IFileViewerSettings } from '../../file-viewer.component';

@Component({
	selector: 'vh-audio-viewer',
	templateUrl: './audio-viewer.component.html',
	styleUrls: ['./audio-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioViewerComponent implements IFileViewer {
	constructor(private cdr: ChangeDetectorRef, readonly url: UrlProvider) {}

	@Input()
	public file: Partial<IAttachment>;

	@Input()
	public options: IFileViewerDialogComponentDataOptions;

	@Input()
	public fileInfo: IFileInfo;

	public isDirty: boolean;
	public settings: IFileViewerSettings;
}
