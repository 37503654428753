<mat-list-option
	vhUserPreview
	[value]="{ value: user, type: 'user' }"
	[previewUserId]="user.id"
	(mouseenter)="mouseenterHandler()"
	(mouseleave)="mouseleaveHandler()"
>
	<vh-chat-nav-avatar
		mat-list-icon
		[userData]="user"
		[sideLengthPx]="36"
		[showColorText]="!user?.hasAvatar"
		[coloredText]="displayName"
		[preferColorText]="!user?.hasAvatar"
	></vh-chat-nav-avatar>
	<div mat-line>
		<span [style.color]="getUserColor(user)" [vhUserStatus]="user"
			>{{ displayName }}
			<span *ngIf="!user?.isEmployee && (user.appointment || user.position)" class="user-position"
				>&lt;{{ user.appointment || user.position }}&gt;</span
			>
		</span>
	</div>

	<div mat-line [style.display]="'flex'">
		<span [class.green-500-fg]="isUserOnline(user)" [class.secondary-text]="!isUserOnline(user)">
			<ng-container *ngIf="isUserOnline(user)">
				{{ 'common.online' | resx }}
			</ng-container>

			<ng-container *ngIf="!isUserOnline(user) && user?.lastOnlineTime">
				{{ user?.lastOnlineTime | lastOnlineWithGender: user?.gender | async }}
			</ng-container>

			<ng-container *ngIf="!isUserOnline(user) && !user?.lastOnlineTime">
				{{ user?.gender | neverSeenWithGender | async }}
			</ng-container>
		</span>

		<ng-container *ngIf="getActualAbsence(user) as absence">
			&nbsp;
			<div class="secondary-text flex" [style.color]="absence?.userColor || absence?.freeBusyStatus?.userColor">
				<div class="ellipsis" style="display: inline-block; max-width: 175px">
					{{ absence?.message || absence.typeName || absence.absenceType?.name }}
				</div>
				<ng-container *ngIf="absence.dateStart && absence.dateEnd">
					<span
						>&nbsp;{{ dateFromString(absence.dateStart) | date: absenceDateFormat(absence.dateStart, absence.dateEnd) }}
						-
						{{ dateFromString(absence.dateEnd) | date: absenceDateFormat(absence.dateStart, absence.dateEnd) }}</span
					>
				</ng-container>
			</div>
		</ng-container>
	</div>

	<ng-container *ngIf="hoverMenu && !(mobileView.mobileMode$ | async) && contactHovered$ | async">
		<div class="contact-search-item-hover">
			<button
				*ngIf="canChat$ | async"
				class="vh-btn vh-btn-icon vh-btn-icon-action vh-btn--transparent"
				(click)="openChatWithUser(); $event.stopPropagation()"
				[tippy]="'common.chatWithUser' | resx"
			>
				<mat-icon svgIcon="vh-sent-24"></mat-icon>
			</button>
			<button
				*ngIf="isVksEnabled$ | async"
				class="vh-btn vh-btn-icon vh-btn-icon-action vh-btn--transparent"
				tippy="Видеовызов"
				(click)="openVksWithContact(); $event.stopPropagation()"
			>
				<mat-icon svgIcon="vh-video-call-24"></mat-icon>
			</button>

			<button
				*ngIf="sessionUser.isAdmin"
				class="vh-btn vh-btn-icon vh-btn-icon-action vh-btn--transparent"
				(click)="impersonate(); $event.stopPropagation()"
				[tippy]="'common.btnTooltipReincarnation' | resx"
			>
				<mat-icon svgIcon="vh-reface-24"></mat-icon>
			</button>
		</div>
	</ng-container>
</mat-list-option>

<mat-divider *ngIf="showDivider && !isLast" class="ml-16 mr-16"></mat-divider>
