import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AutoFocusModule } from '@spa/common/directives';
import { LocalizationModule } from '@spa/localization';

import { ModalBaseComponent } from './modal-base.component';

@NgModule({
	imports: [CommonModule, LocalizationModule, MatIconModule, MatToolbarModule, AutoFocusModule],
	exports: [ModalBaseComponent],
	declarations: [ModalBaseComponent],
	providers: [],
})
export class ModalBaseModule {}
