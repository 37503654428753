import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ModalBaseModule } from '@spa/facade/features/modals/modal-base';
import { LocalizationModule } from '@spa/localization';

import { TaskFavoriteComponent } from './task-favorite.component';

@NgModule({
	imports: [
		CommonModule,
		MatInputModule,
		MatFormFieldModule,
		MatOptionModule,
		MatSelectModule,
		MatIconModule,
		FormsModule,
		ModalBaseModule,
		LocalizationModule,
	],
	exports: [TaskFavoriteComponent],
	declarations: [TaskFavoriteComponent],
	providers: [],
})
export class TaskFavoriteModule {}
