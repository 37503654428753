import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IEventNotifications } from '@spa/facade/meeting-notifications.service';

const DEFAULT_NOTIFICATION_TIMEOUT_IN_SEC = -1;
const STORAGE_KEY = 'NotificationService-options';

@Injectable()
export class NotificationService implements OnDestroy {
	constructor() {
		this.notifications = [];
		this.onChange = new EventEmitter<NotificationItem>();
		this.onChange$ = this.onChange.pipe();
	}
	public onChange$: Observable<any>;
	private onChange: EventEmitter<any>;

	public notifications: NotificationItem[];

	readonly actionsNotify$ = new Subject<IActionsNotify>();

	ngOnDestroy(): void {
		this.onChange.pipe();
	}

	message(header: string, text: string, options?: NotificationOptions): NotificationItem {
		return this.create(header, text, NotificationType.Message, null, options);
	}

	warn(header: string, text: string, options?: NotificationOptions): NotificationItem {
		return this.create(header, text, NotificationType.Warn, null, options);
	}

	error(header: string, text: string, options?: NotificationOptions): NotificationItem {
		return this.create(header, text, NotificationType.Error, null, options);
	}

	fromUser(header: string, text: string, fromUserId: number, options?: NotificationOptions): NotificationItem {
		return this.create(header, text, NotificationType.User, fromUserId, options);
	}

	meeting(event: IEventNotifications): NotificationItem {
		return this.create(null, null, NotificationType.Meeting, null, {
			meetingNotification: event,
		});
	}

	create(
		header: string,
		text: string,
		type: NotificationType,
		fromUserId?: number,
		options?: NotificationOptions
	): NotificationItem {
		const notification = new NotificationItem(
			header,
			text,
			type,
			fromUserId,
			options || {
				timeout: DEFAULT_NOTIFICATION_TIMEOUT_IN_SEC,
			}
		);

		this.notify(notification);

		return notification;
	}

	canNotify(notificationOptions: NotificationOptions) {
		if (notificationOptions && notificationOptions.frequencyOptions) {
			const storage = sessionStorage || localStorage;

			if (
				this.notifications.findIndex(x => {
					if (
						x.options &&
						x.options.frequencyOptions &&
						x.options.frequencyOptions.uniqueKey === notificationOptions.frequencyOptions.uniqueKey
					) {
						return true;
					}
					return false;
				}) > -1
			) {
				return false;
			}

			const json = storage.getItem(STORAGE_KEY);
			let saved: any;
			if (json) {
				saved = JSON.parse(json);
			} else {
				saved = {};
			}

			if (notificationOptions.frequencyOptions.uniqueKey in saved) {
				const last = new Date(saved[notificationOptions.frequencyOptions.uniqueKey] as string);
				const now = new Date();
				const diff = (now.valueOf() - last.valueOf()) / 1000;
				if (diff < notificationOptions.frequencyOptions.timeout) {
					return false;
				}
			}
		}
		return true;
	}

	blockNotification(notificationOptions: NotificationOptions) {
		if (notificationOptions && notificationOptions.frequencyOptions) {
			const storage = sessionStorage || localStorage;

			const json = storage.getItem(STORAGE_KEY);
			let saved: any;
			if (json) {
				saved = JSON.parse(json);
			} else {
				saved = {};
			}

			if (notificationOptions.frequencyOptions.uniqueKey in saved) {
				const last = new Date(saved[notificationOptions.frequencyOptions.uniqueKey] as string);
				const now = new Date();
				const diff = (now.valueOf() - last.valueOf()) / 1000;
				if (diff < notificationOptions.frequencyOptions.timeout) {
					return;
				}
			}

			saved[notificationOptions.frequencyOptions.uniqueKey] = new Date();

			storage.setItem(STORAGE_KEY, JSON.stringify(saved));
		}
	}

	notify(notification: NotificationItem) {
		if (this.canNotify(notification.options)) {
			this.notifications.push(notification);
			this.onChange.emit(notification);
			notification.onClose.subscribe(() => {
				this.blockNotification(notification.options);
				this.removeNotification(notification);
			});
			notification.start();
		}
	}

	removeNotification(notification: NotificationItem) {
		const index = this.notifications.indexOf(notification);
		if (index > -1) {
			this.notifications.splice(index, 1);
			this.onChange.emit(null);
		}
	}

	closeAllByType(type: NotificationType) {
		this.notifications.forEach(n => {
			if (n.type === type) {
				n?.close();
			}
		});
	}

	closeClick(notification: NotificationItem) {
		this.actionsNotify$.next({ type: NotificationActionType.close, notification });
		notification.close();
	}

	deactivateClick(notification: NotificationItem) {
		this.actionsNotify$.next({ type: NotificationActionType.deactivate, notification });
		notification.close();
	}

	rescheduleClick({ notification, rescheduleMinutes }) {
		this.actionsNotify$.next({
			type: NotificationActionType.reschedule,
			notification,
			rescheduleMinutes,
		});
	}

	joinClick(notification: NotificationItem) {
		this.actionsNotify$.next({ type: NotificationActionType.join, notification });
	}

	openMeetingClick(notification: NotificationItem) {
		this.actionsNotify$.next({ type: NotificationActionType.openMeeting, notification });
	}
}

export interface NotificationFrequencyOptions {
	timeout: number;
	uniqueKey: string;
}

export class NotificationOptions {
	timeout?: number;
	frequencyOptions?: NotificationFrequencyOptions;
	meetingNotification?: IEventNotifications;
}

export class NotificationItem {
	constructor(
		public header: string,
		public text: string,
		public type: NotificationType,
		public fromUserId?: number,
		public options?: NotificationOptions
	) {
		this.onClose = new EventEmitter<NotificationItem>();
		if (!options) {
			options = {
				timeout: DEFAULT_NOTIFICATION_TIMEOUT_IN_SEC,
			};
		}
	}

	public onClose: EventEmitter<NotificationItem>;
	private closed: boolean;
	private timer: any;

	public start() {
		if (!this.timer) {
			if (this.options.timeout > 0) {
				this.timer = setTimeout(() => {
					this.close();
				}, this.options.timeout * 1000);
			}
		}
	}

	public close() {
		if (!this.closed) {
			this.closed = true;
			if (this.timer) {
				clearTimeout(this.timer);
			}
			this.onClose.emit(this);
		}
	}
}

export enum NotificationType {
	Message,
	Warn,
	Error,
	User,
	Meeting,
}

export interface IActionsNotify {
	type: NotificationActionType;
	notification?: NotificationItem;
	rescheduleMinutes?: number;
}

export enum NotificationActionType {
	join = 'join',
	close = 'close',
	deactivate = 'deactivate',
	reschedule = 'reschedule',
	openMeeting = 'openMeeting',
}
