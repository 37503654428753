import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ContextMenuModule } from '@spa/common/context-menu';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';
import { FeatherIconsModule } from '@spa/common/feather-icons';
import { ModalBaseModule } from '@spa/facade/features/modals/modal-base';
import { LocalizationModule } from '@valhalla/localization';

import { FavoritesTasksModule } from '../favorites-tasks/favorites-tasks.module';
import { MyTasksNavLayoutPanelModule } from '../my-tasks-panel';
import { SidebarHeaderModule } from '../sidebar-header/sidebar-header.module';
import { AddElementModalComponent } from './add-element-modal/add-element-modal.component';
import { AddFolderModalComponent } from './add-folder-modal/add-folder-modal.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { FavoriteTickerPipe } from './favorite-ticker.pipe';
import { FavoritesPanelComponent } from './favorites-panel.component';
import { RenameModalComponent } from './rename-modal/rename-modal.component';
import { TippyModule } from '@ngneat/helipopper';
import { SmartExpressionSelectionPipeModule } from '@spa/common/components/navigation/smart-expressions-selection.pipe';
import { BadgeModule, IconModule } from '@vh/core-components';

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		DragDropModule,
		ContextMenuModule,
		MatInputModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		FavoritesTasksModule,
		FeatherIconsModule,
		LocalizationModule,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
		MatDividerModule,
		ModalBaseModule,
		SidebarHeaderModule,
		MyTasksNavLayoutPanelModule,
		TippyModule,
		SmartExpressionSelectionPipeModule,
		BadgeModule,
		IconModule,
	],
	declarations: [
		FavoritesPanelComponent,
		FavoriteTickerPipe,
		AddElementModalComponent,
		AddFolderModalComponent,
		RenameModalComponent,
		DeleteModalComponent,
	],
	exports: [FavoritesPanelComponent],
})
export class FavoritesPanelModule {}
