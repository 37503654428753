import { Injector } from '@angular/core';
import { initializeGlobalApi } from './api';
import { initializeCompatibleLayer } from './old-compatible';
import { initializeDialogs } from './dialogs';

export { configureRadopen } from './radopen';

export function registerGlobals(global: any, injector?: Injector) {
	initializeCompatibleLayer(global, injector);
	initializeGlobalApi(global, injector);
	initializeDialogs();
}
