<vh-modal-base
	[showClose]="true"
	[showCancel]="dialogData?.showCancel"
	[title]="dialogData?.titleResx || dialogData?.title || 'common.notification' | resx"
	[okResx]="dialogData?.okResx || 'common.ok'"
	(close)="close()"
	(cancel)="close()"
	(ok)="(dialogData?.ok && dialogData?.ok()) || close()"
>
	<ng-container *ngIf="dialogData?.message">
		<div *ngIf="dialogData?.resx" [innerHTML]="dialogData?.resx | resx"></div>
		<div *ngIf="!dialogData?.resx" [innerHTML]="dialogData?.message"></div>
	</ng-container>

	<ng-container *ngIf="dialogData?.json">
		<vh-ui-monaco-editor [lang]="'json'" (ready)="onMonacoReady($event)" [readOnly]="true"></vh-ui-monaco-editor>
	</ng-container>
</vh-modal-base>
