import { Observable } from 'rxjs';

export enum LogLevel {
	info = 'Info',
	warn = 'Warning',
	error = 'Error',
	trace = 'Trace',
}

export interface ILoggerOutputOptions {
	useEventLog: boolean;
}

export abstract class AbstractLogger {
	abstract name: string;
	abstract enabled: boolean;
	abstract createOptions(options: Partial<ILoggerOutputOptions>): Partial<ILoggerOutputOptions>;
	abstract write(level: LogLevel, ...data);
	abstract trace(...data);
	abstract log(...data);
	abstract info(...data);
	abstract warn(...data);
	abstract error(...data);
	abstract forceLogInfo(...data);
	abstract group?(label: string, ...args);
	abstract groupCollapsed?(label: string, ...args);
	abstract groupEnd?();
	abstract enable();
	abstract disable();
}

export interface ILoggerFactory {
	loggerNames: string[];
	loggersEnable$: Observable<Record<string, boolean>>;
	getLoggersByName(name: string): AbstractLogger[];
	createLogger(name: string): AbstractLogger;
	enableLogger(name: string);
	disableLogger(name: string);
	enableAllLoggers();
	disableAllLoggers();
}

export abstract class LoggerFactory implements ILoggerFactory {
	abstract loggerNames: string[];
	abstract loggersEnable$: Observable<Record<string, boolean>>;
	abstract getLoggersByName(name: string): AbstractLogger[];
	abstract createLogger(name: string): AbstractLogger;
	abstract enableLogger(name: string);
	abstract disableLogger(name: string);
	abstract enableAllLoggers();
	abstract disableAllLoggers();
}
