import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModalBaseModule } from '@spa/facade/features/modals/modal-base';
import { LocalizationModule } from '@valhalla/localization';
import { CategoryControlModule } from '../../../../../common/components/controls/category-control/category-control.module';
import { SelectCategoryDialogComponent } from './select-category-dialog.component';

@NgModule({
	imports: [CommonModule, LocalizationModule, ModalBaseModule, CategoryControlModule],
	exports: [SelectCategoryDialogComponent],
	declarations: [SelectCategoryDialogComponent],
	providers: [],
})
export class SelectCategoryDialogModule {}
