import { Component, Inject, OnInit } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { ElementType } from '../element-type';

@Component({
	selector: 'vh-delete-modal',
	templateUrl: './delete-modal.component.html',
	styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent implements OnInit {
	constructor(
		readonly dialogRef: MatDialogRef<DeleteModalComponent>,
		@Inject(MAT_DIALOG_DATA) readonly dialogData: any
	) {}

	public deleteLabel: string;

	ngOnInit(): void {
		this.deleteLabel =
			this.dialogData.type === ElementType.folder
				? `Вы действительно хотите удалить папку "${this.dialogData.name}"?`
				: `Вы действительно хотите удалить ссылку "${this.dialogData.name}"?`;
	}

	closeAndRemoveFolder() {
		this.dialogRef.close(true);
	}

	closeModal() {
		this.dialogRef.close(false);
	}
}
