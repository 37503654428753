<ng-container *ngIf="!(isTaskPlaceholderMode || isChatPlaceholderMode || isGroupPlaceholderMode)">
	<ng-container *ngIf="visible$ | async">
		<ng-container *ngIf="!hasSubscribers; then colorTextTmpl"></ng-container>

		<ng-container *ngIf="hasSubscribers && userData && preferColorText; then colorTextTmpl"></ng-container>
		<ng-container *ngIf="hasSubscribers && userData && !preferColorText; then avatarWithUserData"></ng-container>

		<!-- <ng-container *ngIf="hasSubscribers && userId && !preferColorText && !userData; then user"></ng-container> -->
		<ng-container *ngIf="hasSubscribers && !userData && showColorText; then colorTextTmpl"></ng-container>
		<ng-container *ngIf="hasSubscribers && !userData && !showColorText; then usersAvatarsTmpl"></ng-container>
	</ng-container>

	<ng-template #avatarWithUserData>
		<ng-container *ngIf="userData?.hasAvatar || userData?.userAvatarFile; else colorTextTmpl">
			<ng-container *ngTemplateOutlet="img; context: { $implicit: userData | chatUserAvatarLink }"></ng-container>
		</ng-container>
	</ng-template>

	<!-- <ng-template #user>
		<ng-container
			*ngTemplateOutlet="img; context: { $implicit: userId | chatUserAvatarLink: avatarSessionUserLinkTimestamp }"
		></ng-container>
	</ng-template> -->

	<ng-template #usersAvatarsTmpl>
		<ng-container *ngIf="chatOnlyMe$ | async; then onlyMyChatTmpl; else avatarsTmpl"></ng-container>
	</ng-template>

	<ng-template #onlyMyChatTmpl>
		<ng-container *ngTemplateOutlet="img; context: { $implicit: users[0] | chatUserAvatarLink }"></ng-container>
	</ng-template>

	<ng-template #avatarsTmpl>
		<ng-container *ngIf="isOneUserExceptMeOnAvatar$ | async">
			<ng-container *ngTemplateOutlet="oneManAvatar; context: { $implicit: (usersExceptMe$ | async)[0] }">
			</ng-container>
		</ng-container>
	</ng-template>

	<ng-template #oneManAvatar let-user>
		<ng-container *ngIf="user?.hasAvatar || user?.userAvatarFile; else colorTextTmpl">
			<ng-container *ngTemplateOutlet="img; context: { $implicit: user | chatUserAvatarLink }"> </ng-container>
		</ng-container>
	</ng-template>
</ng-container>

<ng-container *ngIf="isTaskPlaceholderMode || isChatPlaceholderMode || isGroupPlaceholderMode">
	<img
		[loading]="imgLoading"
		[height]="sideLengthPx"
		[style.max-height.px]="sideLengthPx"
		[style.max-width.px]="sideLengthPx"
		[style.height.px]="sideLengthPx"
		[style.width.px]="sideLengthPx"
		[src]="
			isTaskPlaceholderMode
				? taskAvatarPlaceholderSrc
				: isChatPlaceholderMode
				? chatAvatarPlaceholderSrc
				: groupAvatarPlaceholderSrc
		"
/></ng-container>

<!-- <ng-template #multiManAvatar>
	<ng-container *ngFor="let userCoord of usersExceptMeOnAvatarCoords$ | async; trackBy: trackByUserId; index as idx">
		<ng-container *ngIf="!userCoord.user?.hasAvatar && !userCoord.user?.userAvatarFile">
			<div
				[style.top.px]="userCoord.top"
				[style.left.px]="userCoord.left"
				[style.width.px]="userCoord.width"
				[style.height.px]="userCoord.height"
				[style.max-height.px]="userCoord.height"
				[style.max-width.px]="userCoord.width"
				[style.background-color]="userCoord.user?.userName || userCoord.user?.displayName | vhStringToHslColor"
				[class]="'vh-chat-nav-avatar__color-text vh-chat-nav-avatar__pic chat-nav-avatar__pic-idx-' + idx"
			>
				<span [style.font-size.px]="userCoord.width / 2">{{
					userCoord.user?.userName || userCoord.user?.displayName | chatAvatarLetters | uppercase
				}}</span>
			</div>
		</ng-container>
	</ng-container>
</ng-template> -->

<ng-template #colorTextTmpl>
	<ng-container>
		<div
			*ngIf="!icon"
			class="vh-chat-nav-avatar__color-text"
			[style.background-color]="coloredText | vhStringToHslColor"
			[style.max-height.px]="sideLengthPx"
			[style.max-width.px]="sideLengthPx"
			[style.height.px]="sideLengthPx"
			[style.width.px]="sideLengthPx"
		>
			<span [style.font-size.px]="sideLengthPx / 2.34">{{ coloredText | chatAvatarLetters | uppercase }}</span>
		</div>
		<div
			*ngIf="icon"
			class="vh-chat-nav-avatar__color-text"
			[style.background-color]="iconBackground"
			[style.max-height.px]="sideLengthPx"
			[style.max-width.px]="sideLengthPx"
			[style.height.px]="sideLengthPx"
			[style.width.px]="sideLengthPx"
		>
			<mat-icon class="s-16" [svgIcon]="icon" [style.color]="iconColor"></mat-icon>
		</div>
	</ng-container>
</ng-template>

<ng-template #img let-src>
	<img
		[loading]="imgLoading"
		[style.display]="isBrokenImage ? 'none' : 'block'"
		[src]="src"
		[width]="sideLengthPx"
		[height]="sideLengthPx"
		[style.max-height.px]="sideLengthPx"
		[style.max-width.px]="sideLengthPx"
		[style.height.px]="sideLengthPx"
		[style.width.px]="sideLengthPx"
		(error)="isBrokenImage = true"
		(load)="isBrokenImage = false"
	/>
	<div *ngIf="isBrokenImage">
		<ng-container *ngTemplateOutlet="colorTextTmpl"></ng-container>
	</div>
</ng-template>
