import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { PlatformDetectorProvider } from '@spa/core';
import { LayoutFacade } from '@spa/facade/layout/layout-facade.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'vh-common-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarCommonComponent implements OnInit, OnDestroy {
	constructor(
		private _layout: LayoutFacade,
		public readonly cd: ChangeDetectorRef,
		readonly platform: PlatformDetectorProvider
	) {
		// Set the defaults
		this.change = new EventEmitter();
		this.collapsed = true;

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	@Input() shouldClearSearch = true;

	@Input()
	searchAutofocus = false;

	@Output()
	change: EventEmitter<any>;

	@Output()
	search: EventEmitter<string> = new EventEmitter();

	@Output()
	clearSearch = new EventEmitter<void>();

	collapsed: boolean;
	fuseConfig: any;

	// Private
	private _unsubscribeAll: Subject<any>;

	get isMobile() {
		return this.platform.isMobile();
	}

	ngOnInit(): void {
		// Subscribe to config changes
		this._layout.state$.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
			this.fuseConfig = config;
			this.cd.detectChanges();
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(0 as any);
		this._unsubscribeAll.complete();
	}

	collapse(): void {
		this.collapsed = true;
		this.clearSearch.emit();
	}

	expand(): void {
		this.collapsed = false;
	}

	onSearch(inputSearch): void {
		this.search.emit(inputSearch.value);
		if (this.shouldClearSearch) {
			inputSearch.value = '';
		}
	}

	onInput(event): void {
		this.change.emit(event);
	}
}
