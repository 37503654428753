import {
	IFlexLayoutContainer,
	IPortalBlockInfo,
	IPortalDashboardData,
	IPortalTemplateData,
	IPortalWidgetFlexData,
	IWebResourceInclude,
	PortalBlockType,
	PortalLayoutType,
	IPortalFolder,
} from '@valhalla/data/entities';
import { Observable } from 'rxjs';

export abstract class PortalDataHttpService {
	abstract getPortal(criteria: IGetPortalCriteria): Observable<IPortalDto>;
	abstract getPortalsList(criteria?: IGetPortalsListCriteria): Observable<Array<IPortalDto>>;
	abstract getPortalBlockData<T = any>(criteria: IGetPortalBlockDataCriteria): Observable<T>;
	abstract getPortalBlockInfo(criteria: IGetPortalBlockInfoCriteria): Observable<Array<IPortalBlockInfo>>;
	abstract updatePortalTemplate<T = any>(payload: IUpdatePortalTemplatePayload): Observable<T>;
	abstract getPortalFoldersAll(): Observable<IPortalFolder[]>;
	abstract getPortalFolder(criteria: IGetPortalFolderCriteria): Observable<IPortalFolder>;
	abstract updateUserDashboardTemplate(payload: IUpdateUserDashboardTemplatePayload): Observable<any>;
	abstract updateAllUserDashboardTemplate(payload: IUpdateAllUserDashboardTemplatePayload): Observable<any>;
	abstract dropUserDashboardTemplate(payload: IDropUserDashboardTemplatePayload): Observable<any>;
	abstract removePortalFolder(criteria: IRemovePortalFolderCriteria): Observable<any>;
	abstract renamePortalFolder(criteria: IRenamePortalFolderCriteria): Observable<any>;
	abstract exportBlockData(criteria: IExportBlockDataCriteria): Observable<Blob>;
}

export interface IRemovePortalFolderCriteria {
	folderId: number;
}

export interface IRenamePortalFolderCriteria {
	id: number;
	newName: string;
}

export interface IGetPortalsListCriteria {
	showUserTemplates?: boolean;
	withTemplateContent?: boolean;
	withTemplateBlockIncludes?: boolean;
}

export interface IUpdateUserDashboardTemplatePayload {
	/**
	 * session user by default
	 */
	userId?: number;
	templateId: number;
	userDashboard: string;
}

export interface IUpdateAllUserDashboardTemplatePayload {
	templateId: number;
	updateAllUsers?: boolean;
	selectedUsers?: number[];
	propertyPath: string;
	value: any;
}

export interface IDropUserDashboardTemplatePayload {
	/**
	 * session user by default
	 */
	userId?: number;
	templateId: number;
}

export interface IGetPortalCriteria {
	targetPortalId: number;
	showUserTemplates?: boolean;
	withTemplateContent?: boolean;
	withTemplateBlockIncludes?: boolean;
	isCustomerZone?: boolean;
}

export interface IPortalDto {
	template: Partial<IPortalTemplateData>;
	blocks: Array<Partial<IPortalBlockInfo>>;
	includes: Array<Partial<IWebResourceInclude>>;
}

export interface IGetPortalBlockDataCriteria {
	blockId: number;
	blockType: PortalBlockType;
	data?: any;
}

export interface IGetDrillDownCriteria {
	blockId: number;
	data?: any;
}

export interface IUpdatePortalTemplatePayload {
	id: number;
	name: string;
	isFixedMode: boolean;
	mesh: Partial<IFlexLayoutContainer<Partial<IPortalWidgetFlexData>>>;
	dashboard: IPortalDashboardData;
	gridType: PortalLayoutType;
}

export interface IGetPortalBlockInfoCriteria {
	blockIds: number[];
}

export interface IGetPortalFolderCriteria {
	folderId: number;
}

export interface IExportBlockDataCriteria {
	blockId: number;
	additionalProps?: IExportBlockDataProps;
}

export interface IExportBlockDataResponse {
	data: IExportBlockData;
	errors: IExportBlockDataError[];
}

export interface IExportBlockData {
	success: boolean;
	widgetName: string;
}

export interface IExportBlockDataError {
	errorType: string;
	message: string;
}

export interface IExportBlockDataProps {
	[prop: string]: string;
}
