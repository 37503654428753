import { AbstractMessageType, AbstractMessage } from '../types';

export class AppBootstrapEventType extends AbstractMessageType {
	readonly id = Symbol();
	readonly name = 'AppBootstrapEvent (rise after angular bootstrap)';
}
export class AppBootstrapEvent extends AbstractMessage<null> {
	readonly type: AppBootstrapEventType = new AppBootstrapEventType();

	create() {
		return new AppBootstrapEvent();
	}
}
