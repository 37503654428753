import { IRxStore, StoreManager } from '@valhalla/core';
import { FacadeStoreNames } from '@spa/facade/store-names.enum';

import { effects, TickerEffectDependencies } from './effects';
import { reducers } from './reducers';
import { initialTickerState } from './state';

export function getOrCreateTickersStore(
	storeManager: StoreManager,
	dependencies: TickerEffectDependencies,
	devTools = false,
	middlewares = []
): IRxStore {
	if (storeManager.isStoreExist(FacadeStoreNames.tickers)) {
		return storeManager.getStore(FacadeStoreNames.tickers);
	}
	const store = storeManager.createStore({
		name: FacadeStoreNames.tickers,
		defaultState: initialTickerState,
		effects,
		dependencies,
		devTools: {
			reduxDevTools: devTools,
			useConsoleLogger: true,
			useEventLog: false
		},
		middlewares
	});
	return store.addReducers(...reducers);
}
