import { ChangeDetectionStrategy, Component, Input, HostBinding, ViewEncapsulation, OnChanges } from '@angular/core';
import {
	AvatarEmphasis,
	AvatarSize,
	AvatarSizeMap,
	AvatarType,
	IAvatarColor,
	IAvatarColorMap,
	AvatarPaddingMap,
	AvatarBorderRadiusMap,
	IAvatarContainerColorMap,
	IAvatarContainerTextColorMap,
} from './avatar.model';

@Component({
	selector: 'vh-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class AvatarComponent implements OnChanges {
	@Input()
	color: IAvatarColor = IAvatarColor.basePrimary;

	@Input()
	emphasis: AvatarEmphasis = AvatarEmphasis.accent;

	@Input()
	type: AvatarType;

	@HostBinding('class.vh-avatar')
	hostClassSelector = true;

	@Input()
	rounded = true;

	@Input()
	imgScr: string;

	@Input()
	imgLoading: 'eager' | 'lazy' = 'eager';

	@Input()
	contentIconName: string;

	@Input()
	size: AvatarSize = AvatarSize.md;

	@Input()
	avatarText: string;

	@Input()
	@HostBinding('attr.show-color-text')
	set showColorText(val: boolean) {
		this._showColorText = val;
	}
	get showColorText() {
		return this._showColorText;
	}

	@HostBinding('style.--avatar-border-radius')
	borderRadiusFromMap: string;

	@HostBinding('style.--avatar-padding')
	paddingFromMap: string;

	@HostBinding('style.--avatar-text-color')
	textColorFromMap: string;

	@HostBinding('style.--avatar-color')
	colorFromMap: string;

	@HostBinding('style.--avatar-size')
	sizeFromMap: string;

	@HostBinding('class.vh-avatar--emphasis-accent')
	get isEmphasisAccent() {
		return this.emphasis === AvatarEmphasis.accent;
	}

	@HostBinding('class.vh-avatar--emphasis-container')
	get isEmphasisContainer() {
		return this.emphasis === AvatarEmphasis.container;
	}

	@HostBinding('class.vh-body-xs')
	get bodyXs() {
		return this.size === AvatarSize.xxs;
	}

	@HostBinding('class.vh-body-sm')
	get bodySm() {
		return this.size === AvatarSize.xs;
	}

	@HostBinding('class.vh-body-md')
	get bodyMd() {
		return this.size === AvatarSize.s;
	}

	@HostBinding('class.vh-body-xl')
	get bodyXl() {
		return this.size === AvatarSize.md;
	}

	@HostBinding('class.vh-headline-sm')
	get headlineSm() {
		return this.size === AvatarSize.l;
	}

	@HostBinding('class.vh-headline-md')
	get headlineMd() {
		return this.size === AvatarSize.xl;
	}

	@HostBinding('class.vh-display-md')
	get displayMd() {
		return this.size === AvatarSize.xxl;
	}

	fontClassFromMap: string;

	private _showColorText = false;
	public AvatarType = AvatarType;

	brokenImagePlaceholder = false;

	ngOnChanges(): void {
		this.getAvatarColorFromMap();
		this.getAvatarSizeFromMap();
		if (this.type !== AvatarType.text) {
			this.getAvatarPaddingFromMap();
		}
		this.getAvatarBorderRadiusFromMap();
		this.getTextColorFromMap();
	}

	trackByUserId(idx: number, data: any) {
		return data.user.userId;
	}

	getAvatarColorFromMap(): void {
		if (!this.brokenImagePlaceholder && this.type === AvatarType.image) {
			return;
		}
		this.colorFromMap =
			this.emphasis === AvatarEmphasis.accent ? IAvatarColorMap[this.color] : IAvatarContainerColorMap[this.color];
	}

	getAvatarSizeFromMap(): void {
		this.sizeFromMap = AvatarSizeMap[this.size];
	}

	getAvatarPaddingFromMap(): void {
		this.paddingFromMap = AvatarPaddingMap[this.size];
	}

	getAvatarBorderRadiusFromMap(): void {
		if (this.rounded) {
			this.borderRadiusFromMap = '50%';
		} else {
			this.borderRadiusFromMap = AvatarBorderRadiusMap[this.size];
		}
	}

	getTextColorFromMap(): void {
		if (this.emphasis === AvatarEmphasis.accent) {
			this.textColorFromMap = 'var(--onsurfaceinverse-primary)';
		} else {
			this.textColorFromMap = IAvatarContainerTextColorMap[this.color];
		}
	}

	handleErrorImageLoad(): void {
		this.brokenImagePlaceholder = true;
		this.getAvatarColorFromMap();
	}
}
