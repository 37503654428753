import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isDetailsHasTypeEventInfo } from './dispatch';

export type SpaEventHandler = (opt: { type: string; payload: any }) => void;
export type SpaEventReturn = {
	unsubscribe: () => void;
};

export function spaSubscribeEvent(name: string, handler: SpaEventHandler): SpaEventReturn {
	if (typeof name !== 'string' || !name) {
		return console.error(`[SPA-API:EVENTS] name must be a not empty string!`) as any;
	}
	if (typeof handler !== 'function') {
		return console.error(`[SPA-API:EVENTS] handler is not a function!`) as any;
	}

	const subscription = new Subscription();

	const names = name
		.split(' ')
		.map(n => n.trim())
		.filter(Boolean);

	names.forEach(eventName => {
		const sub = fromEvent(window, eventName)
			.pipe(filter(e => e instanceof CustomEvent))
			.subscribe((e: CustomEvent) => {
				try {
					if (isDetailsHasTypeEventInfo(e.detail)) {
						handler({ type: e.detail.type, payload: e.detail.payload });
					} else {
						handler({ type: eventName, payload: e.detail });
					}
				} catch (err) {
					console.error(err);
				}
			});
		subscription.add(() => sub.unsubscribe());
	});

	return {
		unsubscribe: () => {
			subscription.unsubscribe();
		},
	};
}
