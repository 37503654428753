<label class="vh-control--custom-toggle" [class.vh-control--custom-toggle-diasbled]="disabled">
	<input
		class="vh-control--input-toggle"
		type="checkbox"
		[disabled]="disabled"
		[checked]="checked$ | async"
		[name]="id"
		[id]="id"
		[value]="value"
		[readonly]="readonly"
		(change)="onInput($event)"
	/>
	<span class="vh-control--input-toggle-slider"></span>
</label>
<span class="vh-control--custom-toggle-label" [class.no-label]="!label">
	<span class="vh-control--custom-toggle-label-text">{{ label }}</span>
	<span *ngIf="subtitle" class="vh-control--custom-toggle-subtitle">{{ subtitle }}</span>
</span>
