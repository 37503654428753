import { NgZone } from '@angular/core';
import { LayoutFacade } from '@spa/facade/layout/layout-facade.service';
import { Effect, IAction, ofType } from '@valhalla/core';
import { rxSetInterval, whenTabVisible } from '@valhalla/utils';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import * as actions from '../actions';
import { INavigationState } from '../state';
import { NavigationEffectDependencies } from './dependency';

const { FacadeNavigationAction } = actions;

export const periodicUpdateFavoriteCountersEffect: Effect<
	IAction,
	IAction,
	INavigationState,
	NavigationEffectDependencies
> = (actions$, state$, deps) => {
	const logger = deps.loggerFactory.createLogger('periodicUpdateFavoriteCountersEffect');
	const turnOff$ = actions$.pipe(ofType(FacadeNavigationAction.turnOffPeriodicCounterUpdate));
	const zone = deps.injector.get(NgZone);
	const layout = deps.injector.get(LayoutFacade);

	return actions$.pipe(
		ofType(FacadeNavigationAction.turnOnPeriodicCounterUpdate),
		switchMap(action => {
			const { updateIntervalMs = 60 * 1000 } = action.payload || {};
			logger.info('run periodical update favorites counter');
			return zone.runOutsideAngular(() =>
				rxSetInterval(updateIntervalMs).pipe(
					startWith(0),
					whenTabVisible(document),
					filter(() => Boolean(!layout.state?.layout?.navbar?.folded)),
					map(_ => actions.loadFavoriteCounters(null)),
					takeUntil(turnOff$)
				)
			);
		})
	);
};
