import { NgZone } from '@angular/core';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { RootRegistrationService } from '../root-registration-service';

import { SpaCommandExecutorFactory } from '../spa-command.contract';

const createCommand: SpaCommandExecutorFactory = injector => {
	const zone = injector.get(NgZone);

	return function openModalIframe(payload: { url: string; title: string }) {
		if (!payload) {
			return console.error(`openModalIframe require option { url: string, title: string }`);
		}
		return zone.runTask(() => {
			const rootReg = injector.get(RootRegistrationService);
			const modals = rootReg.get<ModalWindowsService>('ModalWindowsService');
			return modals.openIFrame(payload.url, payload.title);
		});
	};
};

export default createCommand;
