import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FluentBoxDirective } from './fluent-box-item.directive';
import { FluentBoxMoreDirective } from './fluent-box-more.directive';
import { FluentBoxComponent } from './fluent-box.component';

@NgModule({
	imports: [CommonModule],
	exports: [FluentBoxComponent, FluentBoxDirective, FluentBoxMoreDirective],
	declarations: [FluentBoxComponent, FluentBoxDirective, FluentBoxMoreDirective],
	providers: [],
})
export class FluentBoxModule {}
