import { IAction, produce, ReducerBase } from '@valhalla/core';

import * as actions from '../actions';
import { ITickersState } from '../state';

export class UpdateTickersByNameReducer implements ReducerBase {
	readonly actionType = actions.FacadeTickersAction.updateTickersByName;

	reduce(state: ITickersState, action: IAction<Record<string, number>>) {
		if (!action.payload || !Object.keys(action.payload).length) {
			return state;
		}
		return produce(state, ds => {
			const names = Object.keys(action.payload);
			names.forEach(name => {
				const ticker = state.tickers?.[name];
				if (ticker) {
					state.tickers[name] = {
						...state.tickers?.[name],
						value: action.payload[name],
					};
				}
			});
			state.tickers = { ...state.tickers };
		});
	}
}
