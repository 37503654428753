import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { isString } from './is';

/**
 * use toBoolean instead
 * @deprecated
 */
export function BooleanFilter<T = any>(value: T) {
	if (isString(value)) {
		return Boolean(value.trim());
	}
	return Boolean(value);
}

export function toBoolean<T = any>(value: T) {
	if (isString(value)) {
		return Boolean(value.trim());
	}
	return Boolean(value);
}

export function notNaN(value: any) {
	return !isNaN(value);
}

export function booleanFilter<T>(selector?: (o: T) => any) {
	return filter<T>(o => (selector ? toBoolean(selector(o)) : toBoolean(o)));
}

export function distinctUntilObjectChanged<T>() {
	return distinctUntilChanged<T>((prev, cur) => JSON.stringify(prev) === JSON.stringify(cur));
}

export function not<T>() {
	return map<T, boolean>(v => !v);
}
