import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { DataHttpCommonService } from '../data-http-common.service';
import { IApiResponse } from '../api-response';
import { catchError, map } from 'rxjs/operators';
import { ILinkedTask, LinkedTasksHttpService } from './abstract';

@Injectable()
export class LinkedTasksHttpServiceImpl implements LinkedTasksHttpService {
	constructor(public readonly http: HttpClient, public readonly common: DataHttpCommonService) {}

	getLinkedTasks(id: string | number): Observable<ILinkedTask[]> {
		const url = this.common.getApiUrl(`tasks/linked/${id}`);

		return this.http.get<IApiResponse<ILinkedTask[]>>(url).pipe(
			map(result => result.data),
			catchError(err => {
				console.error(err);
				return of(null);
			})
		);
	}

	deleteLinks(taskId: number, subtaskId: number): Observable<any> {
		const url = this.common.getApiUrl(`tasks/subtasks/removelink/${taskId}/${subtaskId}`);
		return this.http.get<any>(url);
	}
}
