import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ModalBaseModule } from '@spa/facade/features/modals/modal-base';
import { LocalizationModule } from '@spa/localization';
import { AvatarModule } from '../../chat-nav-avatar';
import { GroupMembersDialogComponent } from './group-members-dialog.component';

@NgModule({
	imports: [CommonModule, ModalBaseModule, LocalizationModule, AvatarModule, MatIconModule],
	declarations: [GroupMembersDialogComponent],
})
export class GroupMembersDialogModule {}
