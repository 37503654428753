import { fromEvent, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

export const frameLoadEvent$ = fromEvent(window, 'vh-frame-load').pipe(
	filter((e: CustomEvent) => e?.detail?.url !== 'about:blank'),
	map((e: CustomEvent) => e.detail)
);

export const linkPageFrameLoadEvent$ = frameLoadEvent$.pipe(frameLoadEventBySource('vh-page-link'));

export const frameDestroyEvent$ = fromEvent(window, 'vh-frame-destroy').pipe(map((e: CustomEvent) => e.detail));

export const linkPageDestroyFrameEvent$ = frameDestroyEvent$.pipe(frameLoadEventBySource('vh-page-link'));

export function frameLoadEventBySource(source: string) {
	return (source$: Observable<any>) => source$.pipe(filter(detail => detail?.source === source));
}
