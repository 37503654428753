<vh-modal-base
	(close)="closeModal()"
	(cancel)="closeModal()"
	[showOk]="false"
	[showClose]="true"
	[showCancel]="false"
	[showToolbar]="true"
	[title]="'common.groupMembers' | resx"
>
	<div class="group-members-wrapper" *ngIf="data?.users as users">
		<div *ngFor="let member of users" class="group-members-item">
			<div class="group-members-item--avatar">
				<vh-chat-nav-avatar mat-list-icon [userData]="member" [coloredText]="member.name" [sideLengthPx]="40">
				</vh-chat-nav-avatar>
			</div>

			<div class="group-members-item--info">
				<div class="group-members-item--info-container">
					<div class="group-members-item--info-name">
						{{ member.name }}
					</div>
				</div>
			</div>

			<div class="group-members-item--meta">
				<div class="group-members-item--meta-actions">
					<button
						class="vh-btn vh-btn--contour vh-btn-icon group-members-item--meta-action"
						[tippy]="'common.openProfile' | resx"
						(click)="openProfile(member)"
					>
						<mat-icon svgIcon="vh-contact-24"></mat-icon>
					</button>
				</div>
			</div>
		</div>
	</div>
</vh-modal-base>
