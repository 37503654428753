<vh-portal-target *ngIf="openInModal" [name]="['modal-toolbar']" key="settings">
	<div class="mobile-settings__toolbar-title">Настройки</div>
</vh-portal-target>
<vh-portal-target *ngIf="!openInModal" name="app-mobile-toolbar" key="settings">
	<div>Настройки</div>
</vh-portal-target>

<div class="settings-container">
	<div class="user-profile-wrapper">
		<vh-chat-nav-avatar
			[matMenuTriggerFor]="changeAvatarMenu"
			[preferColorText]="!(sessionUserHasAvatar$ | async)"
			[coloredText]="sessionUserName$ | async"
			[showColorText]="!(sessionUserHasAvatar$ | async)"
			[sideLengthPx]="profileAvatarSideLengthPx"
			[userData]="sessionUser?.userInfo$ | async"
			[matMenuTriggerFor]="changeAvatarMenu"
		></vh-chat-nav-avatar>
		<div class="user-name-wrapper">
			<span class="user-name">{{ sessionUserName$ | async }}</span>
			<span class="f-label">{{ (appSettingsAnonymous$ | async)?.ApplicationName }}</span>
		</div>
	</div>

	<div class="settings-container__items-wrapper">
		<div class="item language" [matMenuTriggerFor]="languageMenu">
			<mat-icon svgIcon="vh-Wiki-v1-24"></mat-icon>
			<div class="item__title-wrapper">
				<span class="item__title">{{ 'common.language' | resx }}</span>
				<span class="item__current-language" *ngIf="currentLanguageName$ | async as langName">{{ langName }}</span>
			</div>

			<mat-icon class="item__right-arrow" [svgIcon]="'vh-arrow-v1-S-right-24'"></mat-icon>
		</div>
		<div *ngIf="(mspaByDefault$ | async) !== true" class="item exit-mobile-mode" (click)="exitMobileSPAMode()">
			<mat-icon svgIcon="vh-logout-24"></mat-icon>
			<div class="item__title-wrapper">
				<span class="item__title">{{ 'common.exitFromMobileSPA' | resx }}</span>
			</div>
		</div>
		<div class="item logout" *ngIf="!isReincarnationMode" (click)="logout()">
			<mat-icon svgIcon="vh-logout-24"></mat-icon>
			<div class="item__title-wrapper">
				<span class="item__title">{{ 'common.logOff' | resx }}</span>
			</div>
		</div>
		<div class="item exit-reincarnation" *ngIf="isReincarnationMode" (click)="stopReincarnation()">
			<mat-icon svgIcon="vh-logout-24"></mat-icon>
			<div class="item__title-wrapper">
				<span class="item__title">{{ 'common.btnStopReincarnation' | resx }}</span>
			</div>
		</div>
	</div>

	<mat-menu #languageMenu class="settings-content-menu">
		<div class="language-menu-container">
			<ng-container *ngFor="let language of languages$ | async; let last = last">
				<div mat-menu-item (click)="setLanguage(language)">
					{{ language.name }}
				</div>
				<div class="separator" *ngIf="!last"></div>
			</ng-container>
		</div>
	</mat-menu>

	<mat-menu #changeAvatarMenu class="settings-content-menu">
		<div class="avatar-menu-container">
			<input
				#uploadAvatarInput
				type="file"
				(change)="onAttachAvatar($event)"
				name="uploadAvatarInput"
				style="display: none"
				accept="image/*"
			/>
			<div mat-menu-item (click)="uploadAvatarInput.click()">{{ 'common.chooseAvatar' | resx }}</div>
			<div class="separator" *ngIf="sessionUserHasAvatar$ | async"></div>
			<div *ngIf="sessionUserHasAvatar$ | async" mat-menu-item [style.color]="'red'" (click)="deleteAvatar()">
				{{ 'common.deleteAvatar' | resx }}
			</div>
		</div>
	</mat-menu>
</div>
