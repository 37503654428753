import { Routes } from '@angular/router';
import { RedirectNotFoundResolver } from '@spa/pages/not-found/not-found.resolver';
import { AndroidRestoreCookieGuard, AuthGuard, CheckVersionGuard, IOSRestoreCookieGuard } from '@valhalla/core';

import { AppSettingsAnonymResolver } from '../resolvers/app-settings-anonym.resolver';
import { ExternalPageGuard } from './external-page.guard';

export const rootRoutes: Routes = [
	{
		// страницы входа (sign in/up)
		path: 'entry',
		loadChildren: () => import('../pages/entry/entry.module').then(m => m.EntryPagesModule),
		resolve: {
			appSettingsAnonym: AppSettingsAnonymResolver,
		},
	},
	{
		path: 'noframe',
		canActivate: [IOSRestoreCookieGuard, AndroidRestoreCookieGuard, AuthGuard, CheckVersionGuard],
		loadChildren: () => import('../pages/noframe/noframe.module').then(m => m.NoFrameModule),
	},
	{
		path: 'default.aspx',
		redirectTo: '',
	},
	{
		path: 'external',
		canActivate: [ExternalPageGuard],
		children: [],
	},
	{
		path: '',
		canActivate: [IOSRestoreCookieGuard, AndroidRestoreCookieGuard, AuthGuard, CheckVersionGuard],
		loadChildren: () => import('../facade/facade.module').then(m => m.FacadeModule),
		resolve: {
			appSettingsAnonym: AppSettingsAnonymResolver,
		},
	},
	{
		path: '**',
		resolve: {
			fromUrl: RedirectNotFoundResolver,
		},
		redirectTo: 'error/404',
	},
];
