import { ModuleWithProviders, NgModule } from '@angular/core';

import { CookieService } from './abstract';
import { CookieServiceImpl } from './cookie.service';

@NgModule()
export class CookieModule {
	static forRoot(): ModuleWithProviders<CookieModule> {
		return {
			ngModule: CookieModule,
			providers: [
				{
					provide: CookieService,
					useClass: CookieServiceImpl,
				},
			],
		};
	}
}
