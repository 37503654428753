import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipeModule } from '../pipes/safe-html';
import { ContentIconComponent } from './content-icon.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
	imports: [CommonModule, SafeHtmlPipeModule, HttpClientModule],
	declarations: [ContentIconComponent],
	exports: [ContentIconComponent],
})
export class ContentIconModule {}
