import { MatThemePaletteEnum } from '@spa/common/material';
import { ITree, ITreeItem } from '@valhalla/utils';
import { SubcategoryRepresentation } from '../../../data/entities';
export { UserLinkType } from '@valhalla/data/http';

export enum MenuItemType {
	item = 'item',
	group = 'group',
	collapsible = 'collapsable',
}

export enum MenuItemActionType {
	openUrl = 'open url',
	openExternalUrl = 'open external url',
	execFunc = 'execute function',
}

export enum NavigationLayoutType {
	vertical = 'vertical',
	horizontal = 'horizontal',
}

export interface INavigationItemBadge {
	order: number;
	title: string;
	translate: string;
	bg: string;
	fg: string;
	matBg: MatThemePaletteEnum;
	matFg: MatThemePaletteEnum;
	value: string | number;
	counterName?: string;
}

export interface INavigationMenuItem extends ITreeItem {
	order?: number;
	title: string;
	name?: string;
	menuType: MenuItemType;
	actionType: MenuItemActionType;
	translate?: string;
	icon?: string;
	hidden?: boolean;
	url?: string;
	href?: string;
	queryParams?: any;
	classes?: string;
	exactMatch?: boolean;
	openInNewTab?: boolean;
	isWorkGroupLink?: boolean;
	subMenu?: INavigationMenuItem[];
	isGroupFavourite?: boolean;
	isOpen?: boolean;
	badges?: Array<Partial<INavigationItemBadge>>;
	type?: Block$Type;
	typeId?: BlockButtonsType;
	openType?: string;
	canCreate?: boolean;
	canCreateTask?: boolean;
	customImageClass?: string;
	tooltip?: string;
	isHeaderHidden?: boolean;
	linkedObjectId?: number;
	color?: string;
	isDictionary?: boolean;
	canEdit?: boolean;
	availableRepresentations?: SubcategoryRepresentation[];
	isSpace?: boolean;
}

export enum Block$Type {
	none = 'None',
	syndicate = 'Syndicate',
	link = 'Link',
	report = 'Report',
	task = 'Task',
	comment = 'Comment',
	newTask = 'NewTask',
}

export enum BlockButtonsType {
	none = 'None',
	link = 'Link',
	report = 'Report',
	task = 'Task',
	user = 'User',
	comment = 'Comment',
	category = 'Category',
	newTaskInSubcat = 'NewTaskInSubcat',
	subcategory = 'Subcategory',
	portalGrid = 'PortalGrid',
	syndicate = 'Syndicate',
	summary = 'Summary',
	calendar = 'Calendar',
	grid = 'Grid',
	gantt = 'Gantt',
	correspondence = 'Correspondence',
	filestorage = 'Filestorage',
	agenda = 'Agenda',
	feed = 'Feed',
	summaryCategory = 'SummaryCategory',
	file = 'File',
	fileBrowser = 'Filebrowser',
	fileBrowserTableView = 'filebrowser_tableView',
	thumbnails = 'filebrowser_thumbnailsview',
	hierarchyDictionary = 'hierarchyDictionary',
	layoutPlan = 'layoutPlan',
	hierarchy = 'Hierarchy',
	kanban = 'kanban',
	portal = 'portal',
	channel = 'channel',
	chats = 'chats',
	Channel = 'Channel',
	Chats = 'Chats',
	SmartSearch = 'SmartSearch',
}

export interface INavigationMenu extends INavigationMenuItem, ITree<INavigationMenu> {}
