import { produce, setAutoFreeze } from 'immer';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { createSelector, OutputSelector as OutputSelectorType, Selector } from 'reselect';
import { Observable } from 'rxjs';

import { IAction } from './actions';

setAutoFreeze(false);

type EpicObservable<
	InAction extends IAction = IAction,
	OutAction extends InAction = InAction,
	State = any,
	Deps = any
> = (action$: Observable<InAction>, state$: StateObservable<State>, dependencies: Deps) => Observable<OutAction>;

type Effect<
	InAction extends IAction = IAction,
	OutAction extends InAction = InAction,
	State = any,
	Deps = any
> = EpicObservable<InAction, OutAction, State, Deps>;

function combineEffects<
	InAction extends IAction = IAction,
	OutAction extends InAction = InAction,
	State = any,
	Deps = any
>(...effects: Array<Effect<InAction, OutAction, State, Deps>>) {
	return combineEpics(...effects) as Effect<InAction, OutAction, State, Deps>;
}

export { createSelector, combineEffects, Effect, ofType, produce };
export type StoreSelector<S = any, R = any> = Selector<S, R>;
export type OutputSelector<S = any, R = any, C = any> = OutputSelectorType<S, R, C>;
