import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { SpaApiEvents, SpaApiEventsEnum } from '@spa/api/events';
import { IUserProfile } from '@valhalla/data/entities';
import { BehaviorSubject, map } from 'rxjs';
import { SessionUser, UrlProvider } from '@spa/core';
import { ConfigurationDataHttpService } from '@spa/data/http';

@Component({
	selector: 'vh-common-user-info-panel',
	templateUrl: './user-info-panel.component.html',
	styleUrls: ['./user-info-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoPanelComponent implements OnInit {
	constructor(
		readonly spaEvents: SpaApiEvents,
		readonly router: Router,
		readonly appSettings: ConfigurationDataHttpService,
		readonly urlProvider: UrlProvider,
		readonly sessionUser: SessionUser
	) {}

	@Input()
	userId: number;

	@Input()
	userInfo: Partial<IUserProfile>;

	@Output()
	close = new EventEmitter();

	@HostBinding('class.vh-common-user-info-panel')
	hostClassSelector = true;

	@HostListener('mouseenter')
	onMouseEnter() {
		this.hovered.next(true);
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		this.hovered.next(false);
	}

	readonly hovered = new BehaviorSubject<boolean>(false);
	readonly spaBoot = Boolean(window.top['__IS_1F_SPA_BOOT__']);

	readonly isSessionUser$ = this.sessionUser.userId$.pipe(
		map(userId => userId === this.userInfo?.userId || userId === this.userId)
	);

	get appointment() {
		return this.userInfo?.appointment;
	}

	get orgUnit() {
		if (this.userInfo?.orgUnits?.length > 2) {
			return this.userInfo.orgUnits[this.userInfo.orgUnits.length - 2];
		}
	}

	conferenceDomain: string;

	ngOnInit(): void {
		this.appSettings.getAppSettingsAnonymous().subscribe(settings => {
			if (settings?.Services?.Conference?.Domain) {
				this.conferenceDomain = settings?.Services?.Conference?.Domain;
			}
		});
	}

	openChatWithUser() {
		this.router.navigate(['/chat/new'], {
			queryParams: {
				with: this.userId || this.userInfo?.userId,
				private: true,
			},
		});
	}

	navigateCommand(commands: any[], e?: Event, extras?: any) {
		e?.preventDefault();
		if (!this.spaBoot) {
			window.open(`/UserInfo.aspx?UserID=${commands[1]}`, '_blank');
		}
		if (this.spaEvents.currentWindowIsChildFrameOfSpa || this.spaEvents.currentWindowIsChild) {
			this.spaEvents.dispatchEvent(SpaApiEventsEnum.spaNavigate, { commands, extras }, null, window.top);
		} else {
			this.router.navigate(commands, extras);
		}
	}

	getJitsiRoom() {
		if (this.conferenceDomain && this.userInfo?.jitsiServicePersonalRoom) {
			return `https://${this.conferenceDomain}/${this.userInfo?.jitsiServicePersonalRoom}`;
		}

		return null;
	}

	jitsiLinkHandler() {
		window.open(this.urlProvider.getUrl(`/jitsi?room=${this.userInfo?.jitsiServicePersonalRoom}`, true), '_blank');
	}
}
