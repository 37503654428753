import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { TabActiveStateService } from '@spa/common/services/tab-active-state.service';
import { Observable } from 'rxjs';

@Injectable()
export class BackgroundRequestInterceptor implements HttpInterceptor {
	tabActive = inject(TabActiveStateService);

	get isBackground() {
		return document.visibilityState === 'hidden' || !this.tabActive.userActive;
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.isBackground) {
			const bgRequest = request.clone({
				headers: request.headers.set('background-request', 'true'),
			});
			return next.handle(bgRequest);
		}
		return next.handle(request);
	}
}
