import * as common from './common';

export default {
	indigo: {
		50: '#e8eaf6',
		100: '#c5cae9',
		200: '#9fa8da',
		300: '#7986cb',
		400: '#5c6bc0',
		500: '#3f51b5',
		600: '#3949ab',
		700: '#303f9f',
		800: '#283593',
		900: '#1a237e',
		A100: '#8c9eff',
		A200: '#536dfe',
		A400: '#3d5afe',
		A700: '#304ffe',
		contrast: {
			50: common.black87,
			100: common.black87,
			200: common.black87,
			300: 'white',
			400: 'white',
			500: common.white87,
			600: common.white87,
			700: common.white87,
			800: common.white87,
			900: common.white87,
			A100: common.black87,
			A200: 'white',
			A400: 'white',
			A700: common.white87,
		},
	},
};
