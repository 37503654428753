import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TaskViewActive, TaskViewActiveRegister } from './task-view-active-register';

@Injectable()
export class TaskViewActiveService implements OnDestroy, TaskViewActive {
	constructor(protected readonly reg: TaskViewActiveRegister, protected readonly zone: NgZone) {
		this.reg.add(this);
	}

	taskComponentRef: any;
	#taskId$ = new BehaviorSubject<number>(null);
	#cardGuid$ = new BehaviorSubject<string>(null);
	#destroyCardGuid = null;

	get elRef() {
		return this.taskComponentRef?.elRef;
	}

	set taskId(val: number) {
		const prev = this.#taskId$.value;
		this.#taskId$.next(val);
		if (prev !== val) {
			this.reg.triggerChange();
		}
	}
	get taskId() {
		return this.#taskId$.value;
	}

	set cardGuid(val: string) {
		const prev = this.#cardGuid$.value;
		this.#destroyCardGuid = val;
		this.#cardGuid$.next(val);
		if (prev !== val) {
			if (prev) {
				this.#destroyCardGuid = prev;
			}
			this.reg.triggerChange();
		}
	}
	get cardGuid() {
		return this.#cardGuid$.value;
	}

	get destroyCardGuid() {
		return this.#destroyCardGuid;
	}

	changeComponentTaskId(taskId: number) {
		if (this.taskComponentRef && taskId > 0) {
			this.zone.runTask(() => {
				this.taskComponentRef.taskId = taskId;
			});
		}
	}

	changeComponentGuid(guid: string): void {
		if (this.taskComponentRef && guid) {
			this.zone.runTask(() => {
				this.taskComponentRef.cardGuid = guid;
			});
		}
	}

	ngOnDestroy(): void {
		this.reg.delete(this);
	}
}
