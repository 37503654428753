import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidebarModule } from '@spa/common/components/sidebar';
import { ThemeOptionsCommonModule } from '@spa/common/components/theme-options';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';

import { ChatPanelModule } from '@spa/facade/layout/components/chat-panel/chat-panel.module';
import { ContentModule } from '@spa/facade/layout/components/content/content.module';
import { FooterModule } from '@spa/facade/layout/components/footer/footer.module';
import { NavbarModule } from '@spa/facade/layout/components/navbar/navbar.module';
import { QuickPanelModule } from '@spa/facade/layout/components/quick-panel/quick-panel.module';
import { ToolbarModule } from '@spa/facade/layout/components/toolbar/toolbar.module';
import { HorizontalLayout1Component } from '@spa/facade/layout/horizontal/layout-1/layout-1.component';

@NgModule({
	declarations: [HorizontalLayout1Component],
	imports: [
		MatSidenavModule,

		SidebarModule,
		ThemeOptionsCommonModule,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
		ChatPanelModule,
		ContentModule,
		FooterModule,
		NavbarModule,
		QuickPanelModule,
		ToolbarModule,
		ScrollingModule,
	],
	exports: [HorizontalLayout1Component],
})
export class HorizontalLayout1Module {}
