import { Injectable } from '@angular/core';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { IndexedDbProvider } from '../db/indexed';
import { MobileViewIDBStorage } from './abstract';
import { MobileViewDatabase, IMobileViewItem } from './db';

@Injectable()
export class MobileViewIDBStorageImpl implements MobileViewIDBStorage {
	constructor(protected readonly dbProvider: IndexedDbProvider) {}

	readonly db$ = this.dbProvider.getDatabase(MobileViewDatabase);

	set<T>(key: string, data: T): Observable<T> {
		return this.db$.pipe(
			mergeMap(db => from(db.containers.put({ key: key, value: data }))),
			map(() => data)
		);
	}

	setAnyway<T = any>(key: string, data: T): void {
		this.db$
			.pipe(
				mergeMap(db => from(db.containers.put({ key: key, value: data }))),
				catchError(err => {
					console.error(err);
					return EMPTY;
				})
			)
			.subscribe();
	}

	get<T>(key: string): Observable<T> {
		return this.db$.pipe(
			mergeMap(db => from(db.containers.get({ key: key }))),
			map(result => result?.value)
		);
	}

	has(key: string): Observable<boolean> {
		return this.get(key).pipe(map(result => Boolean(result)));
	}

	remove(key: string): Observable<any> {
		return this.db$.pipe(mergeMap(db => from(db.containers.where('key').equals(key).delete())));
	}
}
