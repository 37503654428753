import {
	HTTP_INTERCEPTORS,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Observable, mergeMap, of, retryWhen, tap, throwError, timer } from 'rxjs';

@Injectable()
export class RetryHttpInterceptor implements HttpInterceptor {
	retryDelay = 2000;
	retryMaxAttempts = 5;

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(this.retryAfterDelay());
	}

	retryAfterDelay(): any {
		let retryDelay = this.retryDelay;
		return retryWhen(errors => {
			return errors.pipe(
				mergeMap((err, count) => {
					const needRetry = err instanceof HttpErrorResponse && [502, 503].includes(err.status);
					if (!needRetry) {
						return throwError(() => err);
					}
					if (count === this.retryMaxAttempts) {
						return throwError(() => err);
					}
					retryDelay += retryDelay * 1.5;
					return of(err).pipe(
						tap(error => console.log(`Retrying ${error.url}. Retry count ${count + 1}`)),
						mergeMap(() => timer(retryDelay))
					);
				})
			);
		});
	}
}

export const RetryHttpInterceptorProvider: Provider = {
	provide: HTTP_INTERCEPTORS,
	multi: true,
	useClass: RetryHttpInterceptor,
};
