import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ContextMenuModule } from '@spa/common/context-menu';
import { TippyModule } from '@ngneat/helipopper';
import { LocalizationModule } from '@spa/localization';
import { NavPanelEmailItemComponent } from './nav-panel-email-item.component';
import { BadgeModule, IconModule } from '@vh/core-components';

@NgModule({
	imports: [CommonModule, MatIconModule, ContextMenuModule, TippyModule, LocalizationModule, BadgeModule, IconModule],
	exports: [NavPanelEmailItemComponent],
	declarations: [NavPanelEmailItemComponent],
	providers: [],
})
export class NavPanelEmailItemModule {}
