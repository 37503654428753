import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	Inject,
	ApplicationRef,
	HostBinding,
	ViewEncapsulation,
} from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ViewDestroyStreamService, LoggerFactory, AbstractLogger, UrlProvider } from '@valhalla/core';
import { fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'vh-facade-modal-confirm',
	templateUrl: 'modal-confirm.component.html',
	styleUrls: ['modal-confirm.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class FacadeModalConfirmComponent implements OnInit {
	constructor(
		readonly destroy$: ViewDestroyStreamService,
		readonly dialogRef: MatDialogRef<FacadeModalConfirmComponent>,
		@Inject(MAT_DIALOG_DATA) readonly dialogData: any,
		readonly lf: LoggerFactory,
		readonly urlBuilder: UrlProvider,
		readonly appRef: ApplicationRef
	) {}

	@HostBinding('class.modal-dialog-window')
	hostClsModalDialogWindow = true;

	@HostBinding('class.vh-facade-modal-confirm')
	hostClsSelector = true;

	logger: AbstractLogger = this.lf.createLogger('FacadeModalConfirmComponent');

	ngOnInit() {
		this.logger.info(this.dialogData);
		fromEvent<KeyboardEvent>(window, 'keydown')
			.pipe(
				filter(e => e.key === 'Enter'),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.close(true);
			});
	}

	close(result = false) {
		this.dialogRef.close(result);
		this.appRef.tick();
	}
}
