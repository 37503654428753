import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IApiResponse } from '../api-response';
import ApiVersion from '../api-versions';
import { DataHttpCommonService } from '../data-http-common.service';
import {
	AuthDataHttpService,
	IAuthTokenCriteria,
	ICaptchaResponse,
	IChangePasswordCriteria,
	ICheckPasswordRecoveryCriteria,
	IPasswordRecoveryCriteria,
	IAuthTokenResponse,
} from './abstract';

@Injectable()
export class AuthDataHttpServiceImpl implements AuthDataHttpService {
	constructor(public readonly http: HttpClient, public readonly common: DataHttpCommonService) {}

	passwordRecovery(criteria: IPasswordRecoveryCriteria) {
		const url = this.common.getApiUrl(`auth/code/password-recovery`);
		return this.http.post(url, criteria);
	}

	changePassword(criteria: IChangePasswordCriteria) {
		const url = this.common.getApiUrl(`user/change-password`);
		return this.http.post(url, criteria);
	}

	getCaptcha(): Observable<ICaptchaResponse> {
		const url = this.common.getApiUrl(`captcha`);
		return this.http.get<IApiResponse<ICaptchaResponse>>(url).pipe(map(result => result.data));
	}

	// getAuthToken(criteria: IAuthTokenCriteria): Observable<any> {
	// 	const url = this.common.getApiUrl(`auth/token`, ApiVersion.v10);
	// 	return this.http.get<any>(url);
	// }

	checkPasswordRecoveryCode(criteria: ICheckPasswordRecoveryCriteria): Observable<IAuthTokenResponse> {
		const url = this.common.getApiUrl(`auth/token/code/password-recovery`);
		return this.http.post<IAuthTokenResponse>(url, criteria);
	}
}
