export enum IContentIconColorMap {
	onSurfacePrimary = 'var(--onsurface-primary)',
	onSurfaceSecondary = 'var(--onsurface-secondary)',
	onSurfaceTertiary = 'var(--onsurface-tertiary)',
	basePrimary = 'var(--base-primary)',
	baseInfo = 'var(--base-info)',
	baseSuccess = 'var(--base-success)',
	baseWarning = 'var(--base-warning)',
	baseDanger = 'var(--base-danger)',
	baseDefault = 'var(--base-default)',
	extramarkersRed = 'var(--extramarkers-red)',
	extramarkersPink = 'var(--extramarkers-pink)',
	extramarkersPurple = 'var(--extramarkers-purple)',
	extramarkersBlue = 'var(--extramarkers-blue)',
	extramarkersCyan = 'var(--extramarkers-cyan)',
	extramarkersGreen = 'var(--extramarkers-green)',
	extramarkersYellow = 'var(--extramarkers-yellow)',
	extramarkersOrange = 'var(--extramarkers-orange)',
	extramarkersBrown = 'var(--extramarkers-brown)',
	extramarkersGrey = ' var(--extramarkers-grey)',
}

export enum IContentIconColor {
	onSurfacePrimary = 'onSurfacePrimary',
	onSurfaceSecondary = 'onSurfaceSecondary',
	onSurfaceTertiary = 'onSurfaceTertiary',
	basePrimary = 'basePrimary',
	baseInfo = 'baseInfo',
	baseSuccess = 'baseSuccess',
	baseWarning = 'baseWarning',
	baseDanger = 'baseDanger',
	baseDefault = 'baseDefault',
	extramarkersRed = 'extramarkersRed',
	extramarkersPink = 'extramarkersPink',
	extramarkersPurple = 'extramarkersPurple',
	extramarkersBlue = 'extramarkersBlue',
	extramarkersCyan = 'extramarkersCyan',
	extramarkersGreen = 'extramarkersGreen',
	extramarkersYellow = 'extramarkersYellow',
	extramarkersOrange = 'extramarkersOrange',
	extramarkersBrown = 'extramarkersBrown',
	extramarkersGrey = 'extramarkersGrey',
}

export enum IContentIconSizeMap {
	space25 = 'var(--space-025)',
	space05 = 'var(--space-05)',
	space75 = 'var(--space-075)',
	space100 = 'var(--space-100)',
	space150 = 'var(--space-150)',
	space200 = 'var(--space-200)',
	space250 = 'var(--space-250)',
	space300 = 'var(--space-300)',
	space400 = 'var(--space-400)',
	space500 = 'var(--space-500)',
	space600 = 'var(--space-600)',
	space800 = 'var(--space-800)',
	space1000 = 'var(--space-1000)',
}

export enum IContentIconSize {
	space25 = 'space25',
	space05 = 'space05',
	space75 = 'space75',
	space100 = 'space100',
	space150 = 'space150',
	space200 = 'space200',
	space250 = 'space250',
	space300 = 'space300',
	space400 = 'space400',
	space500 = 'space500',
	space600 = 'space600',
	space800 = 'space800',
	space1000 = 'space1000',
}

export interface IContentIconCategory {
	identifier: string;
	name: string;
	parent: string;
}

export interface IContentIconItem {
	identifier: string;
	file: string;
	name: string;
	parent: string;
	tags: string;
}

export type IContentIcon = Partial<IContentIconItem & { iconName: string; iconData: string }>;
