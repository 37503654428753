<div
	class="auto-open-menu-trigger"
	[matMenuTriggerFor]="menu"
	#menuTrigger="matMenuTrigger"
	(mouseenter)="mouseEnter(menuTrigger)"
	(mouseleave)="mouseLeave(menuTrigger)"
	(click)="onClick(menuTrigger, $event)"
	(menuOpened)="menuOpened.emit($event)"
>
	<ng-content></ng-content>
</div>
<mat-menu #menu="matMenu" [hasBackdrop]="clickMode" [class]="matMenuClass">
	<div
		class="auto-open-menu-content"
		(mouseenter)="mouseEnter(menuTrigger)"
		(mouseleave)="mouseLeave(menuTrigger)"
		(click)="onMenuContentClick($event)"
	>
		<ng-content select="[content]"></ng-content>
	</div>
</mat-menu>
