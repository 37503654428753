import { IExtParamLookupDataSource } from '@spa/data/entities';
import { map, Observable, of, take } from 'rxjs';
import { IGetEpSelectValuesParams } from '@spa/data/http';
import { ExtParamBase } from './ext-param-base';
import { Extendable } from '@valhalla/utils';

export type ExtParamLookupValueType = {
	taskId: number;
	taskText: string;
};

export class ExtParamLookup extends ExtParamBase<ExtParamLookupValueType, IExtParamLookupDataSource> {
	_saveImmidiateAfterValueChange = true;

	search(
		filter: string,
		skip = 0,
		take = 50,
		opt?: Extendable<{ isHierarchyMode: boolean; parentTaskId: number; selected: boolean }>
	) {
		const params: IGetEpSelectValuesParams = {
			extParamId: this.id,
			isFromSubcatTasks: true,
			skip: skip,
			sourceBlockId: null,
			subcatId: this.ctxSubcatId,
			take: take,
			taskId: this.ctxTaskId,
			text: filter,
			countTotal: false,
		};
		const firstParentEpId = this.sourceConfig.dataSource?.filter?.firstParentEpId;
		if (firstParentEpId) {
			params.parentId = firstParentEpId;
			const parentEp = this.findEp(firstParentEpId);
			params.parentValues = this.calcParentValues(parentEp);
		}
		const secondParentEpId = this.sourceConfig.dataSource?.filter?.secondParentEpId;
		if (secondParentEpId) {
			params.parentId2 = secondParentEpId;
			const parentEp = this.findEp(secondParentEpId);
			params.parentValues2 = this.calcParentValues(parentEp);
		}
		if (opt?.isHierarchyMode) {
			params.selected = opt.selected;
			if (opt.parentTaskId) {
				params.parentTaskId = opt.parentTaskId;
			}
			return this.server.ep.getLookupHierarchyValues(params).pipe(map(res => res.data?.tasks));
		}
		return this.server.ep.getEpSelectValues(params);
	}

	calcParentValues(ep: ExtParamBase<any, any>) {
		return ep?.value
			? Array.isArray(ep.value)
				? ep.value.map(v => v.taskId || v.value)
				: [ep.value.taskId || ep.value.value]
			: [];
	}

	convertForUpdateExtParamInTask() {
		if (!this.value?.taskId) {
			return `#n${this.id}#v${''}`;
		}
		return `#n${this.id}#v${this.value?.taskId}`;
	}

	convertForUpdateExtParamInNewTask(): string {
		return `${this.value?.taskId}`;
	}

	convertForSaveInNewTaskAsync(): Observable<any> {
		if (typeof this.convertForSaveInNewTaskAsyncMiddleware === 'function') {
			return this.convertForSaveInNewTaskAsyncMiddleware(this).pipe(take(1));
		}
		return of((this.value && String(this.value.taskId)) || '');
	}

	getValueForCopy() {
		return String(this.value?.taskText);
	}

	setCopiedValue(value) {
		this.setSearchContext(value);
	}

	equalsValue(a: ExtParamLookupValueType, b: ExtParamLookupValueType) {
		if (!a && !b) {
			return true;
		}
		return +a?.taskId === +b?.taskId;
	}

	get extParamQueryStringValue(): string {
		if (!this.value?.taskId) {
			return '';
		}
		return `$Ext${this.id}$${this.value.taskId}`;
	}
}
