export type BadgeColor = 'primary' | 'warn' | 'error' | 'success' | 'accent' | 'fav' | 'neutral';

const colorNames = ['primary', 'warn', 'error', 'success', 'accent', 'fav', 'neutral'];

export function isBadgeColor(val: string, color: BadgeColor) {
	return val === color;
}
isBadgeColor.primary = (val: string) => isBadgeColor(val, 'primary');
isBadgeColor.warn = (val: string) => isBadgeColor(val, 'warn');
isBadgeColor.error = (val: string) => isBadgeColor(val, 'error');
isBadgeColor.success = (val: string) => isBadgeColor(val, 'success');
isBadgeColor.accent = (val: string) => isBadgeColor(val, 'accent');
isBadgeColor.fav = (val: string) => isBadgeColor(val, 'fav');
isBadgeColor.neutral = (val: string) => isBadgeColor(val, 'neutral');
isBadgeColor.valid = (val: string) => colorNames.includes(val);
