import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserPreviewPanelService {
	private _ids = new Set<number>();
	private _panelsStack: BehaviorSubject<Set<number>> = new BehaviorSubject<Set<number>>(this._ids);
	public stack = this._panelsStack.pipe();

	addPanel(id: number) {
		this._ids.add(id);
		this._panelsStack.next(this._ids);
	}

	removeFromPanel(id: number) {
		this._ids.delete(id);
		this._panelsStack.next(this._ids);
	}

	has(id: number) {
		return this._ids.has(id);
	}

	clearStack() {
		this._ids.clear();
		this._panelsStack.next(this._ids);
	}
}
