import { map, mergeMap, Observable, of, take } from 'rxjs';

import { IRecipientType } from '../../../data/http';
import { ExtParamBase } from './ext-param-base';

export type ExtParamSelectUsersValueType = {
	users?: any[];
	groups?: any[];
	orgUnits?: any[];
};

export interface ExtParamSelectUsersSaveDto {
	Users?: ExtParamSelectUsersModify;
	Groups?: ExtParamSelectUsersModify;
	OrgUnits?: ExtParamSelectUsersModify;
}

export interface ExtParamSelectUsersModify {
	Deleted?: number[];
	Added?: number[];
	Selected?: number[];
}

export class ExtParamSelectUsers extends ExtParamBase<ExtParamSelectUsersValueType> {
	get saveImmidiateAfterValueChange() {
		return !!this.settings?.singleSelect;
	}

	isEmpty() {
		return !(this.value?.users?.length || this.value?.groups?.length || this.value?.orgUnits?.length);
	}

	search(
		filter: string,
		skip = 0,
		take = 50,
		opt?: {
			makeOrgUnitsNamesHierarchical: boolean;
			recipientTypes: string[];
			orgUnitId: number;
			groupId: number;
			orgStructureHierarchicalResponse?: boolean;
		}
	) {
		return this.server.users
			.getContactsWithGroups({
				target: 'ExtParam',
				search: filter,
				subcatId: this.ctxSubcatId,
				taskId: this.ctxTaskId,
				extParamId: this.id,
				offset: skip,
				limit: take,
				makeOrgUnitsNamesHierarchical: opt?.makeOrgUnitsNamesHierarchical,
				recipientTypes: opt?.recipientTypes,
				orgUnitId: opt?.orgUnitId,
				groupId: opt?.groupId,
				orgStructureHierarchicalResponse: opt?.orgStructureHierarchicalResponse,
			})
			.pipe(
				mergeMap(res => {
					const userIds = res.recipients.reduce((acc, recipient) => {
						if (recipient.type === IRecipientType.user) {
							acc.push(recipient.id);

							return acc;
						}

						return acc;
					}, []);

					if (!userIds.length) {
						userIds.push(0);
					}

					return this.server.users.getUsersShortInfo({ userIds }).pipe(
						map(info => {
							return res.recipients.map(recipient => {
								const userInfo = info.find(user => user.userId === recipient.id);

								if (!userInfo) {
									return recipient;
								}

								return {
									...recipient,
									...userInfo,
								};
							});
						})
					);
				})
			);
	}

	convertForUpdateExtParamInTask() {
		// users
		const newUserIds = this.value?.users.map(v => v?.userId || v?.id);
		const oldUserIds = this.sourceValue?.users?.map(svu => svu?.userId || svu?.id);
		const usersPart = this.convertPartForUpdateExtParamInTask(newUserIds, oldUserIds);
		const usersValues = `"Users":${usersPart}`;

		// groups
		const newGroupIds = this.value?.groups.map(v => v?.id);
		const oldGroupIds = this.sourceValue?.groups?.map(svu => svu?.id);
		const groupsPart = this.convertPartForUpdateExtParamInTask(newGroupIds, oldGroupIds);
		const groupsValues = `"Groups":${groupsPart}`;

		// users
		const newUnitsIds = this.value?.orgUnits.map(v => v?.id);
		const oldUnitsIds = this.sourceValue?.orgUnits?.map(svu => svu?.id);
		const unitsPart = this.convertPartForUpdateExtParamInTask(newUnitsIds, oldUnitsIds);
		const unitsValues = `"OrgUnits":${unitsPart}`;

		return `#n${this.id}#v{${usersValues}, ${groupsValues}, ${unitsValues}}`;
	}

	convertPartForUpdateExtParamInTask(newIds: any[], oldIds: any[]) {
		const addedIdsStr = newIds?.filter(n => !oldIds?.some(o => o === n)).join(',') || '';
		const deletedIdsStr = oldIds?.filter(o => !newIds?.some(n => n === o)).join(',') || '';
		const selectedIds = newIds?.join(',') || '';
		const usersValues = `{"Deleted":[${deletedIdsStr}], "Added":[${addedIdsStr}], "Selected":[${selectedIds}]}`;
		return usersValues;
	}

	convertForSaveInNewTaskAsync(): Observable<any> {
		if (typeof this.convertForSaveInNewTaskAsyncMiddleware === 'function') {
			return this.convertForSaveInNewTaskAsyncMiddleware(this).pipe(take(1));
		}

		const users = this.value?.users || [],
			groups = this.value?.groups || [],
			orgUnits = this.value?.orgUnits || [];
		const modify = (ids: number[]) => ({
			Deleted: [],
			Added: ids,
			Selected: ids,
		});
		const dto: ExtParamSelectUsersSaveDto = {
			Users: modify(users.map(u => u.userId || u.id)),
			Groups: modify(groups.map(g => g.id)),
			OrgUnits: modify(orgUnits.map(g => g.id)),
		};
		return of(JSON.stringify(dto));
	}

	getValueForCopy() {
		const users = this.value?.users.map(u => u?.name || u?.userName);

		const valueForCopy = [...users].join(', ');

		return valueForCopy;
	}

	setCopiedValue(value) {
		this.setSearchContext(value);
	}

	equalsValue(a: ExtParamSelectUsersValueType, b: ExtParamSelectUsersValueType) {
		if (!a && !b) {
			return true;
		}

		let equals =
			a?.users?.length === b?.users?.length &&
			a?.groups?.length === b?.groups?.length &&
			a?.orgUnits?.length === b?.orgUnits?.length;

		if (equals && !!a?.users && !!b?.users) {
			equals = this.arrayCompare(
				a.users.map(user => user.id || user?.userId),
				b.users.map(user => user.id || user?.userId)
			);
		}

		if (equals && !!a?.groups && !!b?.groups) {
			equals = this.arrayCompare(
				a.groups.map(group => group.id),
				b.groups.map(group => group.id)
			);
		}

		if (equals && !!a?.orgUnits && !!b?.orgUnits) {
			equals = this.arrayCompare(
				a.orgUnits.map(orgUnit => orgUnit.id),
				b.orgUnits.map(orgUnit => orgUnit.id)
			);
		}

		return equals;
	}

	clearValue() {
		this.userValue$.next({
			users: [],
			groups: [],
			orgUnits: [],
		});
	}

	arrayCompare(arr1: Array<number>, arr2: Array<number>) {
		if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
			return false;
		}

		// .concat() to not mutate arguments
		const arrRes1 = arr1.concat().sort();
		const arrRes2 = arr2.concat().sort();

		for (let i = 0; i < arrRes1.length; i++) {
			if (arrRes1[i] !== arrRes2[i]) {
				return false;
			}
		}

		return true;
	}

	get extParamQueryStringValue(): string {
		if (Object.values(this.value)?.some((arr: Array<unknown>) => arr?.length)) {
			return Object.values(this.value).reduce(
				(acc: string, cur: Array<{ name: string; id: number; userId: number }>) => {
					acc = acc + cur.map(i => i.id || i.userId).join();
					return acc;
				},
				`$Ext${this.id}$`
			) as string;
		}
		return '';
	}
}
