import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { ExtParamBase } from './ext-param-base';

export type ExtParamAddressTypeNtf = {
	textValue: string;
	address?: string;
	addressId?: number;
};

export class ExtParamAddress extends ExtParamBase {
	search(filter: string) {
		return this.server.ep.getEpAddressSuggections(filter);
	}

	convertForUpdateExtParamInTask() {
		return `#n${this.id}#v${this.value || ''}`;
	}

	// Ожидает textValue на бэке как текст, не как объект.
	convertForUpdateExtParamInNewTask(): string {
		let textValue;
		let addressId;

		if (typeof this.value === 'string') {
			textValue = this.value;
			addressId = null;
		} else {
			textValue = this.value?.address ?? null;
			addressId = this.value?.addressId ?? null;
		}

		return JSON.stringify({ textValue, addressId });
	}

	convertForSaveInNewTaskAsync(): Observable<any> {
		if (typeof this.convertForSaveInNewTaskAsyncMiddleware === 'function') {
			return this.convertForSaveInNewTaskAsyncMiddleware(this).pipe(take(1));
		}
		return of((this.value && this.convertForUpdateExtParamInNewTask()) || '');
	}

	setCopiedValue(value) {
		this.setSearchContext(value);
		this.setValue(value);
	}

	equalsValue(source: ExtParamAddressTypeNtf, value: ExtParamAddressTypeNtf | string): boolean {
		if (typeof value === 'string') {
			return source?.address === value;
		}
		const eq =
			typeof source === typeof value && (source?.addressId === value?.addressId || source?.address === value?.address);
		return eq;
	}

	get extParamQueryStringValue(): string {
		if (!this.value?.address) {
			return '';
		}
		return `$Ext${this.id}$${this.value.address}`;
	}
}
