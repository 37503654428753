import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { AuthService, SessionUser, ViewDestroyStreamService } from '@spa/core';
import { DataHttpService } from '@spa/data/http';
import { EMPTY, map } from 'rxjs';
import { CultureService } from '@spa/localization';
import { ILanguageDescription } from '@spa/data/entities';
import { catchError, filter, share, switchMap, take, takeUntil } from 'rxjs/operators';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { parseMessageFromError } from '@valhalla/utils';
import { Router } from '@angular/router';
import { MobileViewService } from '@spa/common/services/mobile-view.service';

@Component({
	selector: 'vh-mobile-settings',
	templateUrl: 'mobile-settings.component.html',
	styleUrls: ['mobile-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class MobileSettingsComponent {
	constructor(
		readonly sessionUser: SessionUser,
		readonly server: DataHttpService,
		readonly cultureService: CultureService,
		readonly destroy$: ViewDestroyStreamService,
		readonly modal: ModalWindowsService,
		readonly authService: AuthService,
		private readonly router: Router,
		readonly mobileService: MobileViewService
	) {
		this.isReincarnationMode = this.router.getCurrentNavigation()?.extras?.state?.isReincarnationMode;
	}

	@Input() openInModal = false;
	@Input() isReincarnationMode: boolean;

	readonly sessionUserHasAvatar$ = this.sessionUser.hasAvatar$;
	readonly sessionUserName$ = this.sessionUser.userName$;
	readonly currentLanguage$ = this.cultureService.activeLanguage$;
	readonly currentLanguageName$ = this.currentLanguage$.pipe(map(({ name }) => name));
	readonly appSettingsAnonymous$ = this.server.config.getAppSettingsAnonymous().pipe(share());
	public readonly profileAvatarSideLengthPx = 140;

	readonly languages$ = this.server.config.getAppSettingsAnonymous().pipe(
		map(settings => {
			return settings.Languages.reduce((acc, lang) => {
				const objFirstKeyToLower = {};
				Object.keys(lang).map(key => {
					const keyToLower = this.firstLetterToLowerCase(key);
					return (objFirstKeyToLower[keyToLower] = lang[key]);
				});
				acc.push(objFirstKeyToLower);
				return acc;
			}, []);
		})
	);

	readonly mspaByDefault$ = this.appSettingsAnonymous$.pipe(map(settings => settings.MobileAppSettings?.mspaByDefault));

	firstLetterToLowerCase(string: string): string {
		if (!string) {
			return null;
		}
		return string.charAt(0).toLowerCase() + string.slice(1);
	}

	setLanguage(lang: ILanguageDescription): void {
		this.cultureService
			.setLanguage(lang.alias, true)
			.pipe(
				catchError(err => {
					this.modal.openError(err.message || err);
					return EMPTY;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(() => this.modal.closeAll());
	}

	logout(): void {
		this.authService.logout();
		this.modal.closeAll();
	}

	stopReincarnation(): void {
		this.authService
			.undoReincarnation()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: _ => {
					window.location.reload();
				},
				error: err => {
					console.error(err);
				},
			});
	}

	exitMobileSPAMode(): void {
		this.mobileService.toggleMobile();
		this.router.navigate(['/feeds']);
		setTimeout(() => location.reload(), 1000);
	}

	deleteAvatar(): void {
		this.modal
			.openConfirm(null, null, 'common.confirmDeleteAvatar')
			.afterClosed()
			.pipe(
				filter(isConfirm => isConfirm),
				switchMap(() => this.server.users.deleteAvatar(this.sessionUser?.userId)),
				take(1)
			)
			.subscribe({
				error: err => {
					this.modal.openError(parseMessageFromError(err));
				},
			});
	}

	onAttachAvatar(e): void {
		const files = Array.from(e?.target?.files);
		this.server.users
			.uploadAvatar(this.sessionUser?.userId, files[0])
			.pipe(take(1))
			.subscribe({
				error: err => {
					this.modal.openError(parseMessageFromError(err));
				},
			});
	}
}
