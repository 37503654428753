import { Location } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';

export function openInNewTab(e: PointerEvent) {
	return e.ctrlKey || e.metaKey || e.button === 1;
}

export function navigateByPointerEvent(e: {
	event: PointerEvent;
	router: Router;
	commands: any[];
	extras?: NavigationExtras;
}) {
	const newTab = openInNewTab(e.event);
	if (newTab) {
		let serializeUrl = e.router.serializeUrl(e.router.createUrlTree(e.commands, e.extras));
		serializeUrl = Location.joinWithSlash(document.baseURI, serializeUrl);
		return window.open(serializeUrl, '_blank');
	} else {
		return e.router.navigate(e.commands, e.extras);
	}
}
