import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Inject,
	OnInit,
	Optional,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { LocalStorageProvider, UrlProvider, ViewDestroyStreamService } from '@valhalla/core';
import { BooleanFilter } from '@valhalla/utils';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ILayoutState } from '@spa/facade/layout/state';

import { MODAL_CONTENT_CONTEXT } from '../context-data-token';
import type { IFrameViewerContentModalContext } from './iframe-viewer-modal-context';
import { ThemeService } from '@spa/common/services/theme-service';

@Component({
	selector: 'vh-modal-content-area-iframe-viewer',
	templateUrl: './iframe-viewer.component.html',
	styleUrls: ['./iframe-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
	encapsulation: ViewEncapsulation.None,
})
export class IFrameViewerModalContentAreaComponent implements OnInit {
	constructor(
		readonly url: UrlProvider,
		readonly destroy$: ViewDestroyStreamService,
		readonly localStorage: LocalStorageProvider,
		readonly theme: ThemeService,
		@Optional() @Inject(MODAL_CONTENT_CONTEXT) readonly context: IFrameViewerContentModalContext
	) {}

	@HostBinding('class.vh-modal-content-area-iframe-viewer')
	hostClassSelector = true;

	@ViewChild('iframe', { static: true })
	iframe: ElementRef<HTMLIFrameElement>;

	@Output()
	loaded = new EventEmitter<IFrameViewerModalContentAreaComponent>();

	get contentWindow() {
		if (!this.iframe) {
			return;
		}

		return this.iframe?.nativeElement?.contentWindow;
	}

	readonly context$ = new BehaviorSubject<IFrameViewerContentModalContext>(null);

	readonly url$ = this.context$.pipe(
		filter(BooleanFilter),
		map(c => this.url.getSafeResourceUrl(c.url))
	);

	ngOnInit() {
		this.context$.next(this.context);
	}

	refresh(force = false) {
		if (!this.iframe) {
			return;
		}

		(<HTMLIFrameElement>this.iframe.nativeElement).contentWindow.location.reload();
	}

	setBodyLayoutClass() {
		const state: ILayoutState = this.localStorage.get('PersistentStorePlugin/facade/layout');
		const documentBody = this.iframe?.nativeElement?.contentWindow.document.body;

		// Color theme - Use normal for loop for IE11 compatibility
		for (let i = 0; i < documentBody.classList.length; i++) {
			const className = documentBody.classList[i];

			if (className.startsWith('theme-')) {
				documentBody.classList.remove(className);
			}
		}

		documentBody.classList.add(state.colorTheme);

		if (state.colorTheme?.includes('dark')) {
			this.theme.setCurrentTheme('dark', documentBody);
		} else {
			this.theme.setCurrentTheme('light', documentBody);
		}
	}

	onLoad() {
		this.loaded.emit(this);
		this.setBodyLayoutClass();
	}
}
