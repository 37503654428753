import { merge } from '@valhalla/utils';
import { IRxStore, StoreManager } from '../../store';
import { configurationStoreName } from './actions';
import { reducers } from './reducers';
import { createDefaultConfiguration } from './state';

export function getOrCreateConfigurationStore(
	storeManager: StoreManager,
	dependencies: any = null,
	devTools = false,
	middlewares = []
): IRxStore {
	if (storeManager.isStoreExist(configurationStoreName)) {
		return storeManager.getStore(configurationStoreName);
	}

	const akitaOldStores = JSON.parse(localStorage.getItem('AkitaStores')) || {};
	const coreConfigAkita = akitaOldStores['core_Configuration'] || {};
	const initialState = merge(createDefaultConfiguration(), coreConfigAkita);

	const store = storeManager.createStore({
		name: configurationStoreName,
		defaultState: initialState,
		effects: [],
		persistent: false,
		dependencies,
		devTools: {
			reduxDevTools: devTools,
			useConsoleLogger: devTools,
			useEventLog: false,
		},
		middlewares,
	});
	return store.addReducers(...reducers);
}
