import { Observable } from 'rxjs';

import { IAppDefinition } from '../db';
import { IUserQuickAppsDefinition } from '../user/store';

export abstract class ApplicationsManagerProvider {
	abstract resolveAppRoute(app: IAppDefinition): { url: string; query: Record<string, string> };
	abstract selectAppById(appId: number): Observable<IAppDefinition>;
	abstract selectAvailableApplications(...searchWords: string[]): Observable<IAppDefinition[]>;
	abstract updateAvailableApplicationList(): Observable<number>;
	abstract openApplication(app: Partial<IAppDefinition> | number): void;
	abstract addToUserQuickApps(...apps: Array<IUserQuickAppsDefinition>): void;
	abstract addToUserFavoritesMenu(...apps: Array<IUserQuickAppsDefinition>): void;
	abstract removeFromUserQuickApps(...apps: Array<IUserQuickAppsDefinition | number>): void;
	abstract removeFromUserFavoritesMenu(...apps: Array<IUserQuickAppsDefinition | number>): void;
	abstract selectUserQuickApps(): Observable<IAppDefinition[]>;
}
