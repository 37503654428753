import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UrlProvider } from '@valhalla/core';

import { AppType } from '../apps.type';
import { IAppDefinition } from '../db';
import { Activator } from './abstract';

@Injectable()
export class ActivatorExternalApp extends Activator {
	constructor(protected router: Router, protected url: UrlProvider) {
		super();
	}

	activate(app: IAppDefinition): void {
		if (app.type !== AppType.external) {
			this.successor && this.successor.activate(app);
		} else {
			this.router.navigate(['/link'], {
				queryParams: {
					url: app.startup.url,
				},
			});
		}
	}

	resolveAppRoute(app: IAppDefinition): { url: string; query: Record<string, string> } {
		if (app.type === AppType.external) {
			const query = this.url.buildUrlSearchFromObject({ url: app.startup.url });
			return {
				url: `/link`,
				query: { url: app.startup.url },
			};
		} else {
			return this.successor && this.successor.resolveAppRoute(app);
		}
	}
}
