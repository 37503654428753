import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService, LoggerFactory } from '@valhalla/core';
import { IPortalBlockInfo, IPortalFolder, PortalBlockType, PortalLayoutType } from '@valhalla/data/entities';
import { jsonTryParse } from '@valhalla/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import type { IEndpointUrlConfig } from '../endpoint.config';
import ApiVersion from '../api-versions';
import { DataHttpCommonService } from '../data-http-common.service';
import { EndpointUrlConfig } from '../endpoint.config';
import {
	IDropUserDashboardTemplatePayload,
	IGetPortalBlockDataCriteria,
	IGetPortalBlockInfoCriteria,
	IGetPortalCriteria,
	IGetPortalFolderCriteria,
	IGetPortalsListCriteria,
	IPortalDto,
	IRemovePortalFolderCriteria,
	IRenamePortalFolderCriteria,
	IUpdatePortalTemplatePayload,
	IUpdateUserDashboardTemplatePayload,
	PortalDataHttpService,
	IUpdateAllUserDashboardTemplatePayload,
	IExportBlockDataCriteria,
	IExportBlockDataResponse,
} from './abstract';

@Injectable()
export class PortalDataHttpServiceImpl implements PortalDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) readonly config: IEndpointUrlConfig,
		readonly http: HttpClient,
		readonly common: DataHttpCommonService,
		readonly loggerFactory: LoggerFactory,
		readonly auth: AuthService
	) {}

	get sessionUserId() {
		return this.auth.userId as number;
	}

	protected readonly logger = this.loggerFactory.createLogger('PortalDataHttpServiceImpl');

	protected getPortalRequest<T>(criteria: IGetPortalCriteria) {
		const url = this.common.getApiUrl('portal/adminTemplates', ApiVersion.v10);
		const body = {
			isCustomerZone: false,
			...criteria,
			targetPortalId: criteria.targetPortalId ? criteria.targetPortalId : null,
		};
		return this.http.post<T>(url, body);
	}

	protected adjustPortalResponseDto(data: IPortalDto): IPortalDto {
		data = data || ({} as any);
		data.template = data.template || {};
		data.template.mesh = jsonTryParse(data.template.mesh || '{}', err =>
			this.logger.error('Error parse json template.mesh!', err)
		);
		data.blocks = data.blocks || [];
		data.template.dashboard = jsonTryParse(
			data.template.dashboard ||
				`{
			"options": {},
			"widgets": []
		}`,
			err => this.logger.error('Error parse json from template.dashboard!', err)
		);
		data.template.userDashboard =
			data.template.userDashboard &&
			jsonTryParse(data.template.userDashboard, err =>
				this.logger.error('Error parse json from template.dashboard!', err)
			);
		data.template.gridType = data.template.gridType || PortalLayoutType.flex;
		data.includes = data.includes || [];
		return data;
	}

	getPortal(criteria: IGetPortalCriteria): Observable<IPortalDto> {
		return this.getPortalRequest<Array<IPortalDto>>(criteria).pipe(
			map(data => data[0]),
			map(data => this.adjustPortalResponseDto(data))
		);
	}

	getPortalsList(
		criteria: IGetPortalsListCriteria = {
			showUserTemplates: true,
			withTemplateBlockIncludes: true,
			withTemplateContent: true,
		}
	): Observable<Array<IPortalDto>> {
		const defaultCriteria: IGetPortalsListCriteria = {
			showUserTemplates: true,
			withTemplateBlockIncludes: true,
			withTemplateContent: true,
		};

		return this.getPortalRequest<Array<IPortalDto>>({
			targetPortalId: null,
			...defaultCriteria,
			...criteria,
		}).pipe(map(portalsList => portalsList.map(item => this.adjustPortalResponseDto(item))));
	}

	getPortalBlockData<T = any>(criteria: IGetPortalBlockDataCriteria): Observable<T> {
		let url: string;
		switch (criteria.blockType) {
			case PortalBlockType.menu:
				url = this.common.getApiUrl('portal/block/menu/get', ApiVersion.v10);
				return this.http.post<T>(url, criteria.blockId);
			case PortalBlockType.tasksSearch:
			case PortalBlockType.gantt: {
				url = this.common.getApiUrl(`portal/block/data/${criteria.blockId}`, ApiVersion.v10);
				return this.http.post<T>(url, criteria.data);
			}
			default:
				url = this.common.getApiUrl(`portals/block/data/${criteria.blockId}`);
				return this.http
					.post<T>(url, criteria.data, {
						headers: {
							'Content-Type': 'application/json',
						},
					})
					.pipe(
						map((data: any) => {
							if (!data.success) {
								throw new Error(data.error);
							}
							return data;
						})
					);
		}
	}

	updatePortalTemplate<T = any>(payload: IUpdatePortalTemplatePayload): Observable<T> {
		const url = this.common.getApiUrl('portal/updateAdminTemplate', ApiVersion.v10);
		return this.http.post<T>(url, {
			...payload,
			mesh: JSON.stringify(payload.mesh),
			dashboard: JSON.stringify(payload.dashboard),
		});
	}

	getPortalBlockInfo(criteria: IGetPortalBlockInfoCriteria): Observable<Array<IPortalBlockInfo>> {
		const url = this.common.getApiUrl('portal/block/info', ApiVersion.v10);
		return this.http.post<Array<IPortalBlockInfo>>(url, criteria);
	}

	getPortalFoldersAll(): Observable<IPortalFolder[]> {
		const url = this.common.getApiUrl('portal/folder/all', ApiVersion.v10);
		return this.http.get<IPortalFolder[]>(url, { params: { includeBlocks: 'true' } });
	}
	getPortalFolder(criteria: IGetPortalFolderCriteria): Observable<IPortalFolder> {
		const url = this.common.getApiUrl(`portal/folder/${criteria.folderId}`, ApiVersion.v10);
		return this.http.get<IPortalFolder>(url, { params: { includeBlocks: 'true' } });
	}

	updateUserDashboardTemplate(payload: IUpdateUserDashboardTemplatePayload): Observable<any> {
		const url = this.common.getApiUrl('portal/addUserTemplate', ApiVersion.v10);
		payload.userId = payload.userId || this.sessionUserId;
		return this.http.post(url, payload);
	}

	updateAllUserDashboardTemplate(payload: IUpdateAllUserDashboardTemplatePayload): Observable<any> {
		payload.updateAllUsers = typeof payload.updateAllUsers === 'undefined' ? true : payload.updateAllUsers;
		const url = this.common.getApiUrl('portals/UserTemplates');
		return this.http.post(url, payload);
	}

	dropUserDashboardTemplate(payload: IDropUserDashboardTemplatePayload): Observable<any> {
		const url = this.common.getApiUrl('portal/dropUserTemplate', ApiVersion.v10);
		payload.userId = payload.userId || this.sessionUserId;
		return this.http.post(url, payload);
	}

	removePortalFolder({ folderId }: IRemovePortalFolderCriteria): Observable<any> {
		const url = this.common.getApiUrl(`portal/folder/delete?folderId=${folderId}`, ApiVersion.v10);
		return this.http.post(url, undefined);
	}

	renamePortalFolder(criteria: IRenamePortalFolderCriteria): Observable<any> {
		const url = this.common.getApiUrl('portal/folder/rename ', ApiVersion.v10);
		return this.http.post(url, criteria);
	}

	exportBlockData(criteria: IExportBlockDataCriteria): Observable<Blob> {
		const url = this.common.getApiUrl(`portals/block/data/${criteria.blockId}/export`);
		return this.http.post<Blob>(url, criteria.additionalProps, {
			responseType: 'blob' as any,
		});
	}
}
