import { Injectable } from '@angular/core';
import { IHoster } from './hoster';

@Injectable({ providedIn: 'root' })
export class ElectronHosterService implements IHoster {
	get electronProxy() {
		return window['electron'];
	}

	get isOneTabMode(): boolean {
		return true;
	}

	focusTab(): void {
		this.electronProxy.focusMainWindow();
	}

	setupJitsiRenderer(api, options = {}) {
		this.electronProxy.setupJitsiRenderer(api, options);
	}

	disposeJitsiRenderer() {
		this.electronProxy.jitsiAlwaysOnTopRender?._disposeWindow();
		this.electronProxy.jitsiRemoteControl?.dispose();
	}
}
