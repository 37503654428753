import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { UserAvatarLink } from '../components/chat-nav-avatar';

// todo remove same pipe on old avatar component
@Pipe({ name: 'chatUserAvatarLink', pure: true })
export class ChatUserAvatarLinkPipe implements PipeTransform {
	constructor(readonly avatarLink: UserAvatarLink) {}

	transform(userOrId: any, timestamp?: number): string {
		return this.avatarLink.get(userOrId, timestamp);
	}
}

@NgModule({
	declarations: [ChatUserAvatarLinkPipe],
	exports: [ChatUserAvatarLinkPipe],
})
export class ChatUserAvatarLinkPipeModule {}
