import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2, ViewEncapsulation } from '@angular/core';
import { ViewDestroyStreamService } from '@valhalla/core';
import { ILayoutNavbarState } from '../../../layout/state';

@Component({
	selector: 'vh-layout-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class NavbarComponent {
	constructor(
		readonly elementRef: ElementRef,
		readonly renderer: Renderer2,
		readonly destroy$: ViewDestroyStreamService
	) {}

	@Input()
	showHeader = true;

	@Input()
	showUserHeader = true;

	@Input()
	useCustomColors = true;

	@Input()
	showLogo = true;

	@Input()
	showNavBarMini = false;

	@Input()
	set layoutState(value: ILayoutNavbarState) {
		this._layoutState = value;
		this.variant = this._layoutState && this._layoutState.variant;
	}
	get layoutState(): ILayoutNavbarState {
		return this._layoutState;
	}

	set variant(value: string) {
		if (this._variant === value) {
			return;
		}
		this._prevVariant = this._variant;
		this._variant = value;
		this.applyVariant();
	}
	get variant(): string {
		return this._variant;
	}

	private _variant = 'vertical-style-1';
	private _prevVariant: string;
	private _layoutState: ILayoutNavbarState;

	protected applyVariant() {
		// Remove the old class name
		this._prevVariant && this.renderer.removeClass(this.elementRef.nativeElement, this._prevVariant);
		// Add the new class name
		this._variant && this.renderer.addClass(this.elementRef.nativeElement, this._variant);
	}
}
