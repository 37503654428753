import { isNullOrUndefined } from './is';

export function getFloatLength(val: string): number {
	return val.toString().includes('.') ? val.toString().split('.').pop().length : 0;
}

export function parseNumber(number: any) {
	if (isNullOrUndefined(number)) {
		return number;
	}
	if (typeof number === 'number') {
		return number;
	}
	if (typeof number === 'string') {
		number = number.replace(/\s/g, '');
		if (['.', ','].some(i => number.includes(i))) {
			return Number.parseFloat(number.replaceAll(',', '.'));
		}
		return Number.parseInt(number);
	}
	return number;
}
