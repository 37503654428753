import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ContextMenuToolboxComponent } from './context-menu-toolbox/context-menu-toolbox.component';

import { ContextMenuFixedContainerCommonComponent } from './context-menu.component';
import { ContextMenuDirective } from './context-menu.directive';

@NgModule({
	imports: [CommonModule, MatMenuModule],
	exports: [ContextMenuDirective, MatMenuModule, ContextMenuToolboxComponent],
	declarations: [ContextMenuDirective, ContextMenuFixedContainerCommonComponent, ContextMenuToolboxComponent],
})
export class ContextMenuModule {}
