import { Effect, IAction, ofType } from '@valhalla/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, first, switchMap } from 'rxjs/operators';

import * as actions from '../actions';
import { ITickersState, chatTickerStateFactory } from '../state';
import { TickerEffectDependencies } from './dependency';

export const signalChatEffect: Effect<IAction, IAction, ITickersState, TickerEffectDependencies> = (
	actions$,
	state$,
	deps
) => {
	const logger = deps.loggerFactory.createLogger('signalChatEffect');
	return actions$.pipe(
		first(),
		mergeMap(_ => deps.signal.activate()),
		mergeMap(_ =>
			deps.signal.chatMessages$.pipe(
				map(msg => msg.unreadChatCommentCount),
				map(unreadChatCommentCount => {
					return actions.updateTickers(chatTickerStateFactory(unreadChatCommentCount));
				}),
				catchError(e => {
					const errMsg = e.message || String(e);
					logger.error(errMsg, e.error || e);
					return of(actions.loadTickersError(e instanceof Error ? e : errMsg));
				})
			)
		)
	);
};
