import { formatDateTime } from '@valhalla/utils';
import moment from 'moment';
import { Observable, take, of } from 'rxjs';
import { ExtParamBase } from './ext-param-base';

export class ExtParamDateTime extends ExtParamBase {
	_saveImmidiateAfterValueChange = true;

	convertForUpdateExtParamInTask() {
		const dateStr = this.value && formatDateTime(this.value, 'dd.MM.yyyy HH:mm');
		return `#n${this.id}#v${dateStr || ''}`;
	}

	getValueForCopy() {
		return this.value ? formatDateTime(this.value, 'dd.MM.yyyy HH:mm') : '';
	}

	setCopiedValue(value) {
		const format = 'D.MM.YYYY;HH:mm';
		const formattedValue = moment(value, format);

		this.setValue(formattedValue);
		this.setSearchContext(value);

		return;
	}

	convertForSaveInNewTaskAsync(): Observable<any> {
		if (typeof this.convertForSaveInNewTaskAsyncMiddleware === 'function') {
			return this.convertForSaveInNewTaskAsyncMiddleware(this).pipe(take(1));
		}
		return of((this.value && formatDateTime(this.value, 'dd.MM.yyyy HH:mm')) || '');
	}

	equalsValue(a: any, b: any) {
		if (!a && !b) {
			return true;
		}

		const format = 'YYYY-MM-DDTHH:mm:ss';
		return moment(a, format).isSame(moment(b, format)) || moment(a, format).isSame(moment(b));
	}
}
