import { Inject, Injectable } from '@angular/core';
import { IRxStore } from '@valhalla/core';
import { Observable } from 'rxjs';

import { UserQuickAppsProvider } from './abstract';
import { actions, IUserQuickAppsDefinition, IUserQuickAppsState, NGDI_CONF_TOKEN_APPS_USER_QUICK_STORE } from './store';

@Injectable()
export class UserQuickAppsProviderImpl implements UserQuickAppsProvider {
	constructor(@Inject(NGDI_CONF_TOKEN_APPS_USER_QUICK_STORE) readonly store: IRxStore<IUserQuickAppsState>) {}

	selectUserApps(): Observable<IUserQuickAppsDefinition[]> {
		return this.store.select(state => state.apps);
	}
	addApps(...apps: Array<IUserQuickAppsDefinition>) {
		this.store.dispatch(actions.addQuickAppsToUser(apps));
	}
	removeApps(...apps: Array<number | IUserQuickAppsDefinition>) {
		this.store.dispatch(actions.removeQuickAppsToUser(apps));
	}
}
