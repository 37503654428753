import { UserLinkType, INavigationMenu, INavigationMenuItem } from '@spa/common/components/navigation';
import {
	IAssistantFavoriteMenus,
	IAssistantFavoriteMenusState,
	IFavoritesMenuState,
} from '@spa/facade/features/navigation/providers/data-provider.dto';
import { ITree } from '@valhalla/utils';
import { ILoadableState } from '@valhalla/core';
import { IGetFavoriteCountersDto } from '@valhalla/data/http';

export interface INavigationMenuState {
	id: string;
	activeItemId: string | number;
	menuItems: Record<string, INavigationMenuItem>;
	menuView: ITree[];
	buttons?: IFavoriteBlock[];
	allTasksUserOwns?: number;
	allTasksUserPerforms?: number;
}

export interface INavigationMenuTab {
	id: NavigationMenu | string;
	icon: string;
	iconFilled?: string;
	order: number;
	title: string;
}

export enum NavigationMenu {
	categories = 'categories',
	favorites = 'favorites',
	favoritesNew = 'favoritesNew',
	personalCategories = 'personalCategories',
	email = 'email',
	reports = 'reports',
	favoritesTasks = 'favoritesTasks',
	favoritesComments = 'favoritesComments',
	admin = 'admin',
	assistantFavorite = 'assistantFavorite',
	spaces = 'spaces',
}

export interface IFavoriteBlock {
	blockId: number;
	customImageClass: string;
	customImagePath: string;
	folderId: number;
	groupId: number;
	groupOrder: number;
	href: string;
	id: number;
	isButton: boolean;
	isJsFunction: boolean;
	linkedObjectId: number;
	order: number;
	realUserId: number;
	title: string;
	toolTip: string;
	typeId: UserLinkType | string;
	userId: number;
	stats?: IButtonsStats;
}

export interface IButtonsStats {
	allTasksCount: number;
	newTasksCount: number;
	overdueTasksCount: number;
	subcatId: number;
}

export interface INavigationAssistantFavorites {
	menusAssistant: IAssistantFavoriteMenus[];
}

export interface INavigationState extends ILoadableState {
	favoriteCounters: Record<number, IGetFavoriteCountersDto>;
	activeTabMenuId: NavigationMenu | string;
	tabsMenu: Array<INavigationMenuTab>;
	menus: Partial<{
		categories: INavigationMenuState;
		favorites: IFavoritesMenuState;
		assistantFavorite: IAssistantFavoriteMenusState;
		[key: string]: INavigationMenuState | IFavoritesMenuState | INavigationAssistantFavorites;
	}>;
}

export const initialNavigationState: INavigationState = {
	errors: null,
	loading: false,
	activeTabMenuId: NavigationMenu.favoritesNew,
	favoriteCounters: {},
	tabsMenu: [
		// {
		// 	order: 5,
		// 	icon: 'vh-finder-browser',
		// 	iconFilled: 'vh-finder-browser-fill',
		// 	id: NavigationMenu.spaces,
		// 	title: 'common.spaces',
		// },
		{
			order: 6,
			icon: 'vh-settings-24',
			iconFilled: 'vh-settings-filled-24',
			id: NavigationMenu.admin,
			title: 'common.sideBarBtnAdmin',
		},
		{
			order: 4,
			icon: 'vh-reports-24',
			iconFilled: 'vh-reports-filled-24',
			id: NavigationMenu.reports,
			title: 'common.sideBarBtnReports',
		},
		{
			order: 3,
			icon: 'vh-mail-24',
			iconFilled: 'vh-mail-filled-24',
			id: NavigationMenu.email,
			title: 'common.sideBarBtnMail',
		},
		{
			order: 2,
			icon: 'vh-tasks-24',
			iconFilled: 'vh-tasks-filled-24',
			id: NavigationMenu.personalCategories,
			title: 'common.sideBarMyTasks',
		},
		{
			order: 1,
			icon: 'vh-Categories-24',
			iconFilled: 'vh-categories-filled-24',
			id: NavigationMenu.categories,
			title: 'common.sideBarCategory',
		},
		{
			order: 0,
			icon: 'vh-favourites-24',
			iconFilled: 'vh-favourites-filled-24',
			id: NavigationMenu.favoritesNew,
			title: 'common.sideBarBtnFavorites',
		},
	],
	menus: {
		[NavigationMenu.assistantFavorite]: {
			menusAssistant: [],
		},
		[NavigationMenu.categories]: {
			id: NavigationMenu.categories,
			activeItemId: null,
			menuItems: {},
			menuView: [],
		},
		[NavigationMenu.favorites]: {
			id: NavigationMenu.favorites,
			expandedBlocks: [],
			buttons: [],
			folders: [],
			nodes: [],
		},
		[NavigationMenu.favoritesNew]: {
			id: NavigationMenu.favoritesNew,
			activeItemId: null,
			menuItems: {},
			menuView: [],
		},
		[NavigationMenu.personalCategories]: {
			id: NavigationMenu.personalCategories,
			activeItemId: null,
			menuItems: {},
			menuView: [],
		},
		[NavigationMenu.favoritesTasks]: {
			id: NavigationMenu.favoritesTasks,
			activeItemId: null,
			menuItems: {},
			menuView: [],
		},
		[NavigationMenu.favoritesComments]: {
			id: NavigationMenu.favoritesComments,
			activeItemId: null,
			menuItems: {},
			menuView: [],
		},
	},
};

export { INavigationMenuItem, INavigationMenu };
