import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { DataHttpService } from '@valhalla/data/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AppSettingsAnonymResolver {
	constructor(readonly server: DataHttpService, protected readonly router: Router) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
		return this.server.config.getAppSettingsAnonymous().pipe(
			catchError(err => {
				console.error(err);
				return of({});
			})
		);
	}
}
