import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { isPwaMode } from '@valhalla/utils';

import { PlatformDetectorProvider, PlatformType } from './abstract';

@Injectable()
export class PlatformDetectorProviderImpl implements PlatformDetectorProvider {
	constructor(protected cdkPlatform: Platform) {}

	readonly types = PlatformType;

	isOneOfPlatform(...types: Array<PlatformType>): boolean {
		if (!types || !types.length) {
			return false;
		}
		return types.some(type => this.isPlatform(type));
	}

	detectPlatformTypes(): Array<PlatformType> {
		const types = Object.entries(this.types).reduce((acc, [key, value]) => {
			if (this.isPlatform(value)) {
				acc.push(key);
			}
			return acc;
		}, []);

		const platformId = navigator.platform?.toLowerCase();
		if (platformId) {
			types.push(platformId);
		}

		return types;
	}

	isMobile(): boolean {
		return this.isOneOfPlatform(this.types.android, this.types.ios);
	}

	isMac(): boolean {
		return (navigator as any)?.userAgentData?.platform.toUpperCase().indexOf('MAC') >= 0;
	}

	isWin() {
		return window.document.body.classList.contains('is-win32');
	}

	isElectron() {
		return typeof window['electron'] !== 'undefined';
	}

	protected isPlatform(type: PlatformType): boolean {
		return this.cdkPlatform[type];
	}
}
