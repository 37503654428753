export enum ResourceLoadingStatus {
	wait = 'wait',
	loading = 'loading',
	loaded = 'loaded',
	error = 'error',
}

// NEED REFACTOR THIS HARD CODE

export enum Scripts {
	jquery = 'jquery',
	/**
	 * @see https://github.com/slimjack/IWC
	 */
	iwc = 'iwc',
	signalR = 'signalR',
	signalRHubs = 'signalRHubs',
	/**
	 * @see https://github.com/slimjack/IWC-SignalR
	 */
	signalRIwcPatch = 'signalRIwcPatch',
	/**
	 * @see https://github.com/slimjack/IWC-SignalR
	 */
	signalRIwc = 'signalRIwc',
}

export enum Styles {
	kendoAll = 'kendo-all.css',
	agGrid = 'ag-grid.css',
}
