import { combineLatest, concat, fromEvent, Observable, of, throwError } from 'rxjs';
import {
	connect,
	debounceTime,
	delay,
	filter,
	map,
	mergeMap,
	publish,
	retryWhen,
	startWith,
	take,
	tap,
} from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

const getErrorMessageRetry = (maxRetry: number) => `Max retry (${maxRetry}) counts is over!`;
export function delayedRetry(delayMs: number, maxRetry = 5) {
	let retries = maxRetry;
	const errorMsg = getErrorMessageRetry(maxRetry);
	return (src: Observable<any>) =>
		src.pipe(
			retryWhen(errors =>
				errors.pipe(
					delay(delayMs),
					mergeMap(error => {
						if (error instanceof HttpErrorResponse && (error.status === 200 || error.status === 400)) {
							return throwError(() => error);
						}
						return retries--
							? of(error)
							: throwError(() => `${errorMsg}\n${error.message || error.error || String(error)}`);
					})
				)
			)
		);
}

export function debug<T>(opt?: string, predicate?: (data: T) => boolean, mapper?: (val?: T) => any) {
	return (src: Observable<T>) =>
		src.pipe(
			tap(data => {
				if (typeof predicate === 'function') {
					if (!predicate(data)) {
						return;
					}
				}

				if (typeof opt === 'string') {
					console.group(opt);
				}

				// eslint-disable-next-line no-restricted-syntax
				console.log(mapper ? mapper(data) : data);

				if (typeof opt === 'string') {
					console.groupEnd();
				}
			})
		);
}

export function debounceTimeAfter<T = any>(amount: number, dueTime: number) {
	return (source: Observable<T>) =>
		source.pipe(connect(pub$ => concat(pub$.pipe(take(amount)), pub$.pipe(debounceTime(dueTime)))));
}

export function debounceTimeAfterFirst(dueTime: number) {
	return debounceTimeAfter(1, dueTime);
}

export function whenTabVisible<T = any>(document: Document, debounceMs = 0) {
	const visible$ = fromEvent(document, 'visibilitychange', { capture: false }).pipe(
		debounceTime(debounceMs),
		map(() => document.visibilityState),
		startWith(document.visibilityState),
		map(value => value === 'visible')
	);

	return (source$: Observable<T>) => {
		return combineLatest([visible$, source$]).pipe(
			filter(([isTabActive]) => isTabActive),
			map(([, value]) => value)
		);
	};
}

export function rxSetInterval(ms = 1000) {
	return new Observable<number>(observer => {
		let n = 0;
		const id = setInterval(() => {
			observer.next(++n);
		}, ms);
		return () => {
			clearInterval(id);
		};
	});
}

export function everyTrue(...streams$: Observable<any>[]) {
	return combineLatest(streams$).pipe(map(vals => vals.every(i => !!i)));
}

export function someTrue(...streams$: Observable<any>[]) {
	return combineLatest(streams$).pipe(map(vals => vals.some(i => !!i)));
}
