import { INavigationMenuState } from '@spa/facade/features/navigation/state';
import { buildTreeImmutable, isNumber, isObject, isString, merge } from '@valhalla/utils';
import { IAction, produce, ReducerBase } from '@valhalla/core';
import * as actions from '../actions';
import { INavigationMenuItem, INavigationState } from '../state';

export class UpdateNavigationMenuItemReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.updateNavItems;

	reduce(state: Readonly<INavigationState>, action: IAction<actions.IUpdateNavigationItemPayload>) {
		const { items, apply, active, toAll, menuId, rebuildTree } = action.payload;
		if (!items && !apply && !active && !toAll) {
			return state;
		}
		return produce(state, ds => {
			const menu = ds.menus[menuId] as INavigationMenuState;
			if (active) {
				if (isNumber(active) || isString(active)) {
					menu.activeItemId = active;
				} else {
					menu.activeItemId = active.id;
				}
			}
			const isSimpleUpdate = (<any>items).every(item => isNumber(item) || isString(item));
			if (apply) {
				if (toAll) {
					Object.values(menu.menuItems).forEach(item => {
						item = merge(item, apply);
					});
				} else {
					if (isSimpleUpdate) {
						(<any>items).forEach(item => {
							menu.menuItems[String(item)] = merge(menu.menuItems[String(item)], apply);
						});
					}
				}
			}

			// object merge with required id field (by id)
			if (!isSimpleUpdate) {
				(<INavigationMenuItem[]>items).forEach(item => {
					if (item.id) {
						menu.menuItems[String(item.id)] = merge(menu.menuItems[String(item.id)], item);
					}
				});
			}
			if (rebuildTree) {
				menu.menuView = buildTreeImmutable(menu.menuItems);
			}
		});
	}
}

export class CreateNavigationMenuItemReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.createNavItems;

	reduce(state: Readonly<INavigationState>, action: IAction<actions.ICreateNavigationItemPayload>) {
		const { items = null, menuId } = action.payload;
		if (!items || items.length === 0) {
			return state;
		}
		items.forEach(item => {
			let menu: INavigationMenuState = null;
			if (state.menus && state.menus[menuId]) {
				menu = state.menus[menuId] as INavigationMenuState;
			}
			if (Boolean(menu && menu.menuItems && menu.menuItems[item.id])) {
				throw new Error(`can't create menu item with id=${item.id}, item already exists!`);
			}
		});
		return produce(state, ds => {
			const menu = ds.menus[menuId] as INavigationMenuState;
			items.forEach(item => {
				menu.menuItems[item.id] = item;
			});
			menu.menuView = buildTreeImmutable(menu.menuItems);
		});
	}
}

export class DeleteNavigationMenuItemReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.deleteNavItems;

	reduce(state: Readonly<INavigationState>, action: IAction<actions.IDeleteNavigationItemPayload>) {
		const { items = null, menuId } = action.payload;
		if (!items || items.length === 0) {
			return state;
		}
		return produce(state, ds => {
			const menu = ds.menus[menuId] as INavigationMenuState;
			items.forEach(item => {
				const navItem = item as INavigationMenuItem;
				if (isObject(navItem) && Boolean(navItem.id)) {
					delete menu.menuItems[navItem.id];
				} else {
					delete menu.menuItems[String(item)];
				}
			});
			menu.menuView = buildTreeImmutable(menu.menuItems);
		});
	}
}

export const crudReducer = [
	CreateNavigationMenuItemReducer,
	UpdateNavigationMenuItemReducer,
	DeleteNavigationMenuItemReducer,
];
