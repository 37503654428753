import { ElementRef, Injectable } from '@angular/core';
import { map, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TaskViewActiveRegister {
	protected reg: TaskViewActive[] = [];

	protected changeNotify$ = new Subject<void>();
	change$ = this.changeNotify$.pipe(map(() => this.reg));

	get lastActiveTaskId() {
		return this.reg[this.reg.length - 1]?.taskId;
	}

	get lastActiveCardGuid() {
		return this.reg[this.reg.length - 1]?.cardGuid;
	}

	add(view: TaskViewActive) {
		if (view) {
			this.reg.push(view);
			this.triggerChange();
		}
	}

	delete(view: TaskViewActive) {
		if (view) {
			this.reg = this.reg.filter(v => v !== view);
			this.triggerChange();
		}
	}

	hasActiveTaskId(taskId: number) {
		const view = this.reg.find(view => view.taskId === taskId);
		return !!view;
	}

	triggerChange() {
		this.changeNotify$.next();
	}

	findByElement(el: HTMLElement | EventTarget) {
		return this.reg.find(tv => tv.elRef?.nativeElement?.contains(el as Node));
	}
}

export interface TaskViewActive {
	taskId?: number;
	cardGuid?: string;
	elRef: ElementRef<HTMLElement>;
	changeComponentTaskId(taskId: number): void;
	changeComponentGuid(guid: string): void;
}
