import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { DuplicateImportModuleError } from '../errors';
import { idbProviders } from './indexed/configure';
import { dbLocalStorageProviders } from './local-storage/configure';

@NgModule()
export class DatabaseModule {
	constructor(
		@Optional()
		@SkipSelf()
		dbModule: DatabaseModule
	) {
		if (dbModule) throw new DuplicateImportModuleError('DatabaseModule');
	}
	static forRoot(): ModuleWithProviders<DatabaseModule> {
		return {
			ngModule: DatabaseModule,
			providers: [...idbProviders, ...dbLocalStorageProviders],
		};
	}
}
