import { inject, InjectionToken } from '@angular/core';
import { AppVersionService } from './version/abstract';

export const ASSET_INVALIDATE_KEY = new InjectionToken<string>('ASSET_INVALIDATE_KEY', {
	providedIn: 'root',
	factory: () => {
		const versionService = inject(AppVersionService);
		return String(versionService.getRuntimeBuildTime());
	},
});
