/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { ViewDestroyStreamService } from '@spa/core';
import { InputSubject } from '@valhalla/utils';
import { BehaviorSubject } from 'rxjs';

import { INavPanelAdminItem } from './nav-panel-admin-item.model';

@Component({
	selector: 'vh-nav-panel-admin-item',
	templateUrl: './nav-panel-admin-item.component.html',
	styleUrls: ['./nav-panel-admin-item.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class NavPanelAdminItemComponent implements OnInit {
	constructor(
		readonly destroy$: ViewDestroyStreamService,
		readonly mobileService: MobileViewService,
		readonly route: ActivatedRoute,
		readonly router: Router
	) {}

	@Input()
	@InputSubject()
	item: INavPanelAdminItem;
	item$!: BehaviorSubject<INavPanelAdminItem>;

	@Input()
	needContextMenu = false;

	@Output()
	clickItem = new EventEmitter<any>();

	@Output()
	contextItemClicked = new EventEmitter<any>();

	@Output()
	clickExpandCollapse = new EventEmitter<any>();

	@HostBinding('class.vh-nav-panel-admin-item')
	hostClassSelector = true;

	get isActiveRoute() {
		const item = this.item;

		const isActivePage =
			this.router.url.includes(`admin-ds/forms/${item?.alias}`) || this.router.url.includes(item?.url);

		return isActivePage || this.isActiveRouteAspx(item);
	}

	get isOpen() {
		return this.item?.isOpen;
	}

	ngOnInit() {}

	isActiveRouteAspx(item: INavPanelAdminItem) {
		const serializeUrl = this.router.serializeUrl(
			this.router.createUrlTree(['/link'], {
				queryParams: { url: item.url },
			})
		);

		return this.router.url === serializeUrl;
	}

	onClickItem({ e, item }) {
		if (item?.nodeType === 'Folder') {
			return this.expandCollapse(e, item);
		}

		return this.clickItem.emit({ e, item });
	}

	expandCollapse(event, item: INavPanelAdminItem) {
		event.stopPropagation();
		this.item.isOpen = !this.item.isOpen;
		this.clickExpandCollapse.emit(item);
	}

	isChildren(item: INavPanelAdminItem) {
		return item?.children?.length;
	}

	onContextMenuClicked(item) {
		return this.contextItemClicked.emit(item);
	}
}
