<!-- список пространств -->
<div class="vh-common-sidebar-content">
	<div class="vh-common-sidebar-content--search">
		<vh-sidebar-header
			[title]="'common.spaces' | resx"
			(searchInput)="searchHandler($event.target.value)"
			(searchClear)="searchHandler('')"
		>
		</vh-sidebar-header>
	</div>

	<div class="vh-layout-spaces-panel--content">
		<ng-container *ngIf="categoryWithSpaces$ | async as category">
			<ng-container *ngIf="category?.length">
				<ng-container *ngFor="let item of category; trackBy: trackById">
					<vh-spaces-panel-item
						[item]="item"
						[isCategory]="true"
						[allOpened]="searchInputHasValue$ | async"
						(clickItem)="onSpaceItemClick($event)"
						[contextMenu]="categoryWithSpacesContextMenu"
						[openStateFromInput]="true"
					></vh-spaces-panel-item>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>
</div>

<!-- category with spaces menu -->
<mat-menu #categoryWithSpacesContextMenu="matMenu">
	<ng-template matMenuContent let-item="item">
		<button mat-menu-item [matMenuTriggerFor]="addToFavoritesItems" [matMenuTriggerData]="{ data: { item: item } }">
			<mat-icon svgIcon="vh-favourites-24"></mat-icon>
			<span>{{ 'common.сontextToFavorites' | resx }}</span>
		</button>

		<ng-container *ngIf="item?.canEdit">
			<mat-divider></mat-divider>

			<button mat-menu-item (click)="openSettingsClick(item)">
				<mat-icon svgIcon="vh-Settings-v2-24"></mat-icon>
				<span>{{ 'common.settings' | resx }}</span>
			</button>
		</ng-container>
	</ng-template>
</mat-menu>

<mat-menu #addToFavoritesItems="matMenu">
	<ng-template matMenuContent let-data="data">
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Feeds')"
			(click)="addItemToFavorites(data.item, 'feed')"
			mat-menu-item
		>
			{{ 'common.contextFeed' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Grid')"
			(click)="addItemToFavorites(data.item, 'grid')"
			mat-menu-item
		>
			{{ 'common.contextGrid' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Calendar')"
			(click)="addItemToFavorites(data.item, 'calendar')"
			mat-menu-item
		>
			{{ 'common.сontextCalendar' | resx }}
		</button>
		<button (click)="addItemToFavorites(data.item, 'summary')" mat-menu-item>
			{{ 'common.сontextSummary' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Gantt')"
			(click)="addItemToFavorites(data.item, 'gantt')"
			mat-menu-item
		>
			{{ 'common.сontextGantt' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Kanban')"
			(click)="addItemToFavorites(data.item, 'kanban')"
			mat-menu-item
		>
			{{ 'common.kanban' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Chat')"
			(click)="addItemToFavorites(data.item, 'chats')"
			mat-menu-item
		>
			{{ 'common.chat' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Channel')"
			(click)="addItemToFavorites(data.item, 'channel')"
			mat-menu-item
		>
			{{ 'common.channel' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Files')"
			(click)="addItemToFavorites(data.item, 'filestorage')"
			mat-menu-item
		>
			{{ 'common.disk' | resx }}
		</button>
	</ng-template>
</mat-menu>
