import { Injector } from '@angular/core';
import { DataHttpService } from '@valhalla/data/http';
import { firstValueFrom } from 'rxjs';

export function createGetSessionUserInfo(injector: Injector) {
	const dataHttpService: DataHttpService = injector.get(DataHttpService);

	return function getSessionUserInfo() {
		return firstValueFrom(dataHttpService.users.getSessionUserInfo()).then(info => {
			return {
				data: info,
			};
		});
	};
}
