<div class="vh-common-sidebar-content">
	<div class="vh-common-sidebar-content--search">
		<vh-sidebar-header
			[title]="'common.sideBarBtnMail' | resx"
			[showSearch]="false"
			[showBtnAdd]="!(noMailBoxes$ | async)"
			(addClick)="addNewMailBox()"
		>
		</vh-sidebar-header>
	</div>

	<div class="vh-layout-nav-panel-email-container">
		<!-- empty -->
		<div class="empty-emails" *ngIf="noMailBoxes$ | async">
			<div class="empty-emails--text">
				<div class="empty-emails--text-title">{{ 'common.emptyEmailText1' | resx }}</div>
				<div>{{ 'common.emptyEmailText2' | resx }}</div>
				<div>{{ 'common.emptyEmailText3' | resx }}</div>
			</div>

			<button class="vh-btn vh-btn--accent" (click)="addNewMailBox()">{{ 'common.create' | resx }}</button>
		</div>

		<div class="mailbox-loading" *ngIf="firstLoading$ | async">
			<div class="mailbox-loading-text">{{ 'common.loading' | resx }}...</div>
		</div>

		<ng-container *ngFor="let item of mailboxes$ | async; trackBy: trackByMailboxId">
			<vh-nav-panel-email-item
				[item]="item"
				[mailBoxId]="item?.mailBoxId"
				(clickItem)="onFolderClick($event)"
				(clickExpandCollapse)="expandCollapseEmailItem($event)"
				(clickEditMailbox)="onOpenEditMailboxDialog($event)"
				[vhContextMenu]="item?.isMailbox && (!item?.isError || !item?.isDisabled) ? mailContextMenu : null"
				[vhContextMenuData]="{ mailboxId: item?.mailBoxId, item: item }"
				[contextMenu]="boxContextMenu"
			></vh-nav-panel-email-item>
		</ng-container>
	</div>
</div>

<mat-menu #mailContextMenu="matMenu">
	<ng-template matMenuContent let-mailboxId="mailboxId" let-item="item">
		<button *ngIf="!item?.isError && !item?.isDisabled" mat-menu-item (click)="clickNewEmail(mailboxId)">
			{{ 'common.newMail' | resx }}
		</button>
		<button *ngIf="!item?.isError" mat-menu-item (click)="clickSetting(mailboxId)">
			{{ 'common.mailSettings' | resx }}
		</button>
		<button
			*ngIf="!item?.isError && !item?.isDisabled"
			mat-menu-item
			(click)="navLink(['/link'], { queryParams: getFontsAndSignUrl(mailboxId) }, $event)"
		>
			{{ 'common.fontsAndSignatures' | resx }}
		</button>
		<button
			*ngIf="!item?.isError && !item?.isDisabled"
			mat-menu-item
			(click)="navLink(['/link'], { queryParams: getAddFolderSearchUrl(mailboxId) }, $event)"
		>
			{{ 'common.addASearchFolder' | resx }}
		</button>
		<button *ngIf="!item?.isError && !item?.isDisabled" mat-menu-item (click)="addNewMailBox()">
			{{ 'common.addNewMailBox' | resx }}
		</button>
		<button *ngIf="!item?.isDisabled" mat-menu-item (click)="deleteMailBox(mailboxId)">
			{{ 'common.contextDelete' | resx }}
		</button>
	</ng-template>
</mat-menu>

<mat-menu #boxContextMenu="matMenu">
	<ng-template matMenuContent let-folder="folder" let-mailBoxId="mailBoxId">
		<button mat-menu-item (click)="addToFavorites(folder, mailBoxId)">
			{{ 'common.btnAddToFavorites' | resx }}
		</button>
		<button *ngIf="!folder?.isDisabled" mat-menu-item (click)="setAllRead(folder)">
			{{ 'common.mailMarkAllAsRead' | resx }}
		</button>
		<button *ngIf="!folder?.isDisabled" mat-menu-item (click)="deleteAllMessages(folder)">
			{{ 'common.clearFolder' | resx }}
		</button>
	</ng-template>
</mat-menu>
