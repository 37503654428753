import { Injector, Provider } from '@angular/core';
import { ConfigurationProvider, LoggerFactory, StoreManager } from '@valhalla/core';
import { SignalrProvider } from '@valhalla/data/signalr';

import { STORE_USER_SETTINGS } from './ng-tokens';
import { ngProviderUserSettingsFacade } from './providers';
import { UserSettingsEffectDependencies } from './store/effects';
import { getOrCreateUserSettingsStore } from './store/store-factory';

export function facadeStoreFactory(
	manager: StoreManager,
	deps: UserSettingsEffectDependencies,
	config: ConfigurationProvider
) {
	return getOrCreateUserSettingsStore(manager, deps, !config.isProd);
}

export function effectDependencyFactory(injector: Injector) {
	const effectDependency: UserSettingsEffectDependencies = { injector };
	return effectDependency;
}

export const USER_SETTINGS_PROVIDERS: Provider[] = [
	{
		provide: UserSettingsEffectDependencies,
		useFactory: effectDependencyFactory,
		deps: [Injector],
	},
	{
		provide: STORE_USER_SETTINGS,
		useFactory: facadeStoreFactory,
		deps: [StoreManager, UserSettingsEffectDependencies, ConfigurationProvider],
	},
	ngProviderUserSettingsFacade,
];
