import { Inject, Injectable } from '@angular/core';
import { ASSET_INVALIDATE_KEY, UrlProvider } from '@spa/core';
import { from, mergeMap, Observable, shareReplay, take } from 'rxjs';
import { ImageOptimizer } from './image-optimizer';

@Injectable({ providedIn: 'root' })
export class BrowserImageCompressionOptimizer implements ImageOptimizer {
	constructor(
		@Inject(ASSET_INVALIDATE_KEY) protected readonly assetInvalidateKey: string,
		protected readonly urlBuilder: UrlProvider
	) {}

	protected readonly optimizer$ = from(import('browser-image-compression').then(m => m.default)).pipe(
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	optimize(image: File, options?: Record<any, any>): Observable<File> {
		options = {
			useWebWorker: true,
			...(options || {}),
		};
		return this.optimizer$.pipe(
			mergeMap(imageCompression => from<Promise<File>>(imageCompression(image, options))),
			take(1)
		);
	}
}
