import { Injectable } from '@angular/core';
import { UnsaveChangesService } from './unsave-changes.service';

@Injectable({ providedIn: 'root' })
export class BeforeUnloadService {
	constructor(protected unsaveChanges: UnsaveChangesService) {}

	protected fn: EventListener;

	subscribe() {
		if (!this.fn) {
			this.fn = this.handler.bind(this);
			window.addEventListener('beforeunload', this.fn);
		}
	}

	unsubscribe() {
		if (this.fn) {
			window.removeEventListener('beforeunload', this.fn);
			this.fn = null;
		}
	}

	protected handler(e: BeforeUnloadEvent) {
		if (this.unsaveChanges.hasUnsavedChangesSync()) {
			e.returnValue = this.unsaveChanges.confirmMessage;
		}
	}
}
