import { Observable } from 'rxjs';

import { ILogEntry } from './eventlog.model';

export abstract class EventLogProvider {
	abstract onUpdate(cb: () => void);
	abstract write(logEntry: ILogEntry): Observable<any>;
	abstract clear(): Observable<any>;
	abstract select(
		query: Partial<{
			skip: number;
			take: number;
			sort: {
				field: keyof ILogEntry;
				direction?: 'asc' | 'desc';
			};
		}>
	): Observable<{
		data: ILogEntry[];
		total: number;
	}>;
}
