import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SystemCookies } from '../cookie';
import { LoggerFactory } from '../diagnostics';
import { TokenService } from './token';

@Injectable()
export class AuthTokenRefreshResponseInterceptor implements HttpInterceptor {
	constructor(readonly token: TokenService, readonly logFactory: LoggerFactory) {}

	get responseTokenHeader() {
		return SystemCookies.auth1Forma;
	}

	readonly logger = this.logFactory.createLogger('Token response Interceptor');

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap(event => {
				if (event instanceof HttpResponse) {
					const token = event.headers.get(this.responseTokenHeader);
					if (token) {
						const isValid = this.token.isValid(token);
						if (isValid) {
							const prevToken = this.token.getToken();
							this.token.setToken(token);
							if (prevToken !== token) {
								this.logger.info(`Token update on url ${req.url}`);
								this.logger.info(`Token has been updated from ${prevToken} to ${token}`);
							}
						}
					} else {
						this.logger.info(`No token found in response headers [${this.responseTokenHeader}]`);
					}
				}
			})
		);
	}
}
