<div *ngIf="viewedFile" class="mediaview original">
	<iframe
		*ngIf="this.viewedFile.fileType === fileViewerFileType.eml; else nonEml"
		[src]="this.trustedFileUrl"
		class="mediaview-eml"
	></iframe>

	<ng-template #nonEml>
		<div
			class="pdf-viewer"
			[class.pdf-viewer-mobile]="isMobileOrWebView"
			*ngIf="this.viewedFile.fileType === fileViewerFileType.pdf; else nonPdf"
		>
			<embed *ngIf="!isMobileOrWebView" class="mediaview-pdf" [src]="this.trustedFileUrl" type="application/pdf" />
			<iframe *ngIf="isMobileOrWebView" [src]="trustedPdfUrl" width="80%" height="80%" frameborder="0"></iframe>
		</div>

		<ng-template #nonPdf>
			<ng-container [ngSwitch]="this.fileViewerService.getFileTypeToShowMediaInBrowser(this.viewedFile)">
				<div class="mediaview-box">
					<div class="media-header">
						<div class="media-descr">{{ viewedFile.name }}</div>
						<div class="media-actions">
							<a [href]="this.viewedFile.url">
								<mat-icon>get_app</mat-icon>
							</a>
						</div>
					</div>

					<img *ngSwitchCase="fileViewerFileType.image" [src]="this.viewedFile.url" [alt]="viewedFile.name" />

					<video *ngSwitchCase="fileViewerFileType.video" controls="">
						<source [src]="this.viewedFile.url" [attr.type]="viewedFile.mime || null" />
						Видео не поддерживается
					</video>

					<audio *ngSwitchCase="fileViewerFileType.audio" controls="">
						<source [src]="this.viewedFile.url" [attr.type]="viewedFile.mime || null" />
						Аудио не поддерживается
					</audio>

					<span *ngSwitchCase="fileViewerFileType.text" class="text-view-container">
						{{ selectedFileText }}
					</span>

					<a *ngSwitchCase="fileViewerFileType.document" [href]="this.viewedFile.url">
						<vh-file-view-not-supported [file]="viewedFile" class="no-viewer-stub cursor-pointer" title="Загрузка">
						</vh-file-view-not-supported>
					</a>

					<a [href]="this.viewedFile.url">
						<vh-file-view-not-supported
							*ngSwitchDefault
							[file]="viewedFile"
							class="no-viewer-stub cursor-pointer"
							title="Загрузка"
						>
						</vh-file-view-not-supported>
					</a>
				</div>
			</ng-container>
		</ng-template>
	</ng-template>

	<a class="mediaview-arrow mediaview-arrow-left" (click)="onPrevious()">
		<mat-icon *ngIf="!isFirst">chevron_left</mat-icon>
	</a>
	<a class="mediaview-arrow mediaview-arrow-right" (click)="onNext()">
		<mat-icon *ngIf="!isLast">chevron_right</mat-icon>
	</a>
	<a class="mediaview-close" (click)="onClose()">
		<mat-icon>close</mat-icon>
	</a>
</div>
