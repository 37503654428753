import { ModuleWithProviders, NgModule } from '@angular/core';

import { Downloader } from './abstract';
import { DownloaderImpl } from './download.service';

@NgModule({})
export class DownloadModule {
	static forRoot(): ModuleWithProviders<DownloadModule> {
		return {
			ngModule: DownloadModule,
			providers: [
				{
					provide: Downloader,
					useClass: DownloaderImpl,
				},
			],
		};
	}
}
