export enum PlatformType {
	android = 'ANDROID',
	ios = 'IOS',
	blink = 'BLINK',
	edge = 'EDGE',
	firefox = 'FIREFOX',
	safari = 'SAFARI',
	trident = 'TRIDENT',
	webkit = 'WEBKIT',
	browser = 'isBrowser',
}

export abstract class PlatformDetectorProvider {
	abstract isOneOfPlatform(...types: Array<PlatformType>): boolean;
	abstract detectPlatformTypes(): Array<PlatformType>;
	abstract isMobile(): boolean;
	abstract isWin(): boolean;
	abstract isMac(): boolean;
	abstract isElectron(): boolean;
	types: typeof PlatformType = PlatformType;
}
