import { ChangeDetectorRef, Component, Input, ViewContainerRef } from '@angular/core';
import { Downloader, UrlProvider } from '@spa/core';
import { IAttachment } from '@spa/data/entities';
import { DataHttpService } from '@spa/data/http';
import { IEmail } from '@spa/data/http/email';
import { BehaviorSubject, switchMap, take } from 'rxjs';
import { IFileViewerDialogComponentDataOptions } from '../../file-viewer-dialog.component';

/**
 * Компонент для рендера файлов email
 */
@Component({
	// tslint:disable-next-line:component-selector
	selector: 'vh-email-viewer',
	templateUrl: './email-viewer.component.html',
	styleUrls: ['./email-viewer.component.scss'],
	providers: [],
})
export class EmailViewerComponent {
	constructor(
		private cdr: ChangeDetectorRef,
		readonly url: UrlProvider,
		readonly viewContainerRef: ViewContainerRef,
		readonly downloader: Downloader,
		readonly server: DataHttpService
	) {}

	@Input()
	public file: Partial<IAttachment>;

	@Input()
	public options: IFileViewerDialogComponentDataOptions;

	readonly inputEmailData$ = new BehaviorSubject(null);

	ngOnInit() {
		if (!this.options?.openAsBlob) {
			const req$ = this.options?.isEmailFile
				? this.server.files.getFileEmailContent(String(this.file.id))
				: this.server.files.getFileContent(this.file.id, this.file.versionId);
			req$.pipe(take(1)).subscribe(ab => {
				this.parseEmail(ab);
			});
		}

		if (this.options?.openAsBlob) {
			this.parseEmail(this.file?.content);
		}
	}

	mapRecipients(recipients: string) {
		return recipients?.split(',').map(r => {
			return {
				displayName: r.replaceAll('"', ''),
				email: null,
				userAvatarFile: null,
				userId: null,
			};
		});
	}

	async parseEmail(file) {
		// https://github.com/netas-ch/eml-parser/tree/main
		const emlr = await import('./eml-parser-main/src/EmlReader.js');
		const email = new emlr.EmlReader(file);

		const cc = email.getCc() || '';
		const copyRecipients = cc ? this.mapRecipients(cc) : null;

		const to = email.getTo() || '';
		const recipients = to ? this.mapRecipients(to) : null;

		const attachments = email.getAttachments().map(at => {
			return {
				content: at?.content,
				mime: at?.contentType,
				mimeType: at?.contentType,
				size: at?.filesize,
				name: at?.filename,
				attachmentId: at?.attachmentId,
				isEmailAttachment: true,
			};
		});

		const inputEmailData: Partial<IEmail> = {
			subject: email.getSubject(),
			body: email.getMessageHtml(),
			hasAttachments: Boolean(email.getAttachments()?.length),
			dateTimeSent: email.getDate(),
			copyRecipients,
			recipients,
			attachments,
			fromUser: {
				displayName: email.getFrom(),
			},
		};

		this.inputEmailData$.next(inputEmailData);
	}
}
