export const camelCaseToKebabCase = str => {
	return str
		?.split('')
		?.map((letter, idx) => {
			return letter.toUpperCase() === letter ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}` : letter;
		})
		?.join('');
};

export function formatBytes(bytes: number | string, decimals: number = 2, activeLanguage = 'ru') {
	if (!+bytes) return activeLanguage === 'ru' ? '0 Байт' : '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = activeLanguage === 'ru' ? ['Байт', 'Кб', 'Мб', 'Гб'] : ['Bytes', 'KiB', 'MiB', 'GiB'];

	const i = Math.floor(Math.log(+bytes) / Math.log(k));

	return `${parseFloat((+bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
