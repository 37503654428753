import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TippyModule } from '@ngneat/helipopper';
import { ContentIconModule } from '../content-icon/content-icon.module';
import { ButtonIconStateComponent } from './button-icon-state.component';

@NgModule({
	imports: [CommonModule, ContentIconModule, TippyModule],
	exports: [ButtonIconStateComponent],
	declarations: [ButtonIconStateComponent],
})
export class ButtonIconStateModule {}
