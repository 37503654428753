import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

export class VhSubscribeContext {
	$implicit = null;
	vhSubscribe = null;
}

/**
 * Директива для удобного использования с Observable вместо async pipe
 * проблемы стандартного подхода описаны в статье @see https://netbasal.com/diy-subscription-handling-directive-in-angular-c8f6e762697f
 * @example
		<section *vhSubscribe="alerts$ as alerts">
			<app-alerts [alerts]="alerts"></app-alerts>
			<div>
				<other-component [alerts]="alerts"></other-component>
			</div>
		</section>
 */
@Directive({
	selector: '[vhSubscribe]',
})
export class VhSubscribeDirective implements OnInit, OnDestroy {
	constructor(
		readonly viewContainer: ViewContainerRef,
		readonly cdr: ChangeDetectorRef,
		readonly templateRef: TemplateRef<any>
	) {}

	@Input()
	set vhSubscribe(inputObservable: Observable<any>) {
		if (this._observable !== inputObservable) {
			this._observable = inputObservable;
			this.unSubscribe();

			this._subscription = this._observable.subscribe(value => {
				this.log(value);
				this._context.vhSubscribe = value;
				if (this.vhSubscribeForce) {
					this.cdr.detectChanges();
				} else {
					this.cdr.markForCheck();
				}
			});
		}
	}

	@Input()
	vhSubscribeDebugName: string;

	@Input()
	vhSubscribeForce: boolean;

	@Input()
	set vhSubscribeForceCheck(forceObservable: Observable<any>) {
		if (this._observableForce !== forceObservable) {
			this._observableForce = forceObservable;
			this.unSubscribeForce();

			this._subscriptionForce = this._observableForce.subscribe(() => {
				this.cdr.markForCheck();
			});
		}
	}

	private _observable: Observable<any>;
	private _context: VhSubscribeContext = new VhSubscribeContext();
	private _subscription: Subscription;

	private _observableForce: Observable<any>;
	private _subscriptionForce: Subscription;

	ngOnInit() {
		this.viewContainer.createEmbeddedView(this.templateRef, this._context);
	}

	ngOnDestroy() {
		this.unSubscribe();
		this.unSubscribeForce();
	}

	protected unSubscribe() {
		this._subscription && this._subscription.unsubscribe();
	}
	protected unSubscribeForce() {
		this._subscriptionForce && this._subscriptionForce.unsubscribe();
	}

	protected log(...data) {
		if (this.vhSubscribeDebugName) {
			// eslint-disable-next-line no-restricted-syntax
			console.debug(...data);
		}
	}
}

@Directive({
	selector: '[vhForceCheck]',
})
export class VhForceCheckDirective implements OnDestroy {
	constructor(private _cdr: ChangeDetectorRef) {}

	private _observableForce: Observable<any>;
	private _subscriptionForce: Subscription;

	@Input()
	set vhForceCheck(forceObservable: Observable<any>) {
		if (this._observableForce !== forceObservable) {
			this._observableForce = forceObservable;
			this.unSubscribeForce();

			this._subscriptionForce = this._observableForce.subscribe(() => {
				this._cdr.detectChanges();
			});
		}
	}

	ngOnDestroy() {
		this.unSubscribeForce();
	}

	protected unSubscribeForce() {
		this._subscriptionForce && this._subscriptionForce.unsubscribe();
	}
}
