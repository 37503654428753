import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { SpaCommandRegisterService } from '@spa/api';
import { DataHttpService } from '@valhalla/data/http';
import { combineLatest, defer, EMPTY, from, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { ModalContentAreaService } from '../modal-content-area';

@Injectable({ providedIn: 'root' })
export class OpenNewsService {
	constructor(
		protected readonly spaCommands: SpaCommandRegisterService,
		protected readonly server: DataHttpService,
		protected readonly modalContent: ModalContentAreaService
	) {}

	protected registered = false;
	protected readonly newsComponent$ = defer(() => from(import('@spa/components/task').then(m => m.TaskNewsComponent)));

	registerCommandOpenNews() {
		if (this.registered) {
			// tslint:disable-next-line:no-console
			return console.warn('OpenNewsService already register command openNewsInContentArea');
		}

		this.spaCommands.registerCommand('openNewsInContentArea', payload => {
			const taskId = Number(payload?.taskId);
			if (isNaN(taskId) || taskId <= 0) {
				return console.error(`Invalid taskId parameter =${taskId}`);
			}
			return this.openNews(taskId).pipe(
				take(1),
				catchError(err => {
					console.error(err);
					return EMPTY;
				})
			);
		});
		this.registered = true;
	}

	openNews(taskId: number, cfr?: ComponentFactoryResolver, news?): Observable<any> {
		return combineLatest([this.server.task.getNews({ taskId }), this.newsComponent$]).pipe(
			map(([response, component]) => {
				return {
					component,
					task: response.tasks[0],
					template: this.server.task.findNewsTemplate(response.templates),
				};
			}),
			map(({ component, task, template }) =>
				this.modalContent.open({
					content: component,
					context: {
						taskInfo: task,
						taskTemplate: template,
						typeParamsData: news.typeParamsData,
						categories: news.categories,
					},
					componentFactoryResolver: cfr,
				})
			)
		);
	}
}
