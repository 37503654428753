import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	RendererStyleFlags2,
	ViewEncapsulation,
} from '@angular/core';
import {
	LocalStorageProvider,
	MatchMediaService,
	PlatformDetectorProvider,
	ViewDestroyStreamService,
} from '@valhalla/core';
import { takeUntil } from 'rxjs/operators';
import { SidebarService } from '@spa/common/services/sidebar.service';
import { PageRefreshService } from '../../services/page-refresh.service';
import { SidebarKeys } from '@spa/common/components/sidebar/abstract';

@Component({
	selector: 'vh-common-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class SidebarComponent implements OnInit, OnDestroy {
	constructor(
		readonly _animationBuilder: AnimationBuilder,
		readonly _changeDetectorRef: ChangeDetectorRef,
		readonly _elementRef: ElementRef<HTMLElement>,
		readonly _matchMedia: MatchMediaService,
		readonly _fuseSidebarService: SidebarService,
		readonly _renderer: Renderer2,
		readonly _destroy$: ViewDestroyStreamService,
		readonly localStorage: LocalStorageProvider,
		readonly refreshPage: PageRefreshService
	) {
		// Set the defaults
		this.foldedAutoTriggerOnHover = true;
		this.foldedWidth = 64;
		this.foldedChanged = new EventEmitter();
		this.openedChanged = new EventEmitter();
		this.opened = false;
		this.position = 'left';
		this.invisibleOverlay = false;

		// Set the private defaults
		this._animationsEnabled = false;
		this._folded = false;
	}

	static navbarPinned = false;

	// Name
	@Input()
	name: string;

	// Key
	@Input()
	key: string;

	// Position
	@Input()
	set position(value: 'left' | 'right') {
		this._position = value;
		this._setupPosition();
	}
	get position() {
		return this._position;
	}

	// Open
	@HostBinding('class.open')
	set opened(val: boolean) {
		this.#opened = val;
	}
	get opened() {
		return this.#opened;
	}

	// Locked Open
	@Input()
	lockedOpen: string;

	// isLockedOpen
	@HostBinding('class.locked-open')
	set isLockedOpen(v: boolean) {
		this._isLockedOpen = v;
	}
	get isLockedOpen() {
		return this._isLockedOpen;
	}

	// Folded width
	@Input()
	foldedWidth: number;

	@Input()
	hideOnFold = false;

	// Folded auto trigger on hover
	@Input()
	foldedAutoTriggerOnHover: boolean;

	// Folded unfolded
	@HostBinding('class.unfolded')
	unfolded: boolean;

	// Invisible overlay
	@Input()
	invisibleOverlay: boolean;

	// Folded changed
	@Output()
	foldedChanged: EventEmitter<boolean>;

	// Opened changed
	@Output()
	openedChanged: EventEmitter<boolean>;

	@Input()
	showPinned = false;

	@Output()
	pinnedChange = new EventEmitter<boolean>();

	@Input()
	ignorePinned = false;

	@Input()
	isMobile = false;

	@Input()
	set showNavigationAlways(value: boolean) {
		this._showNavigationAlways = value;
		if (this.isMobile) {
			return;
		}
		if (value) {
			// // Show the sidebar
			// this.open();
			this.pinned = true;
			this.pinnedChange.emit(true);
		}
		this.isLockedOpen = value;
		this._showAsMenu = !value;
	}
	get showNavigationAlways() {
		return this._showNavigationAlways;
	}

	@Input()
	set pinned(value: boolean) {
		this._pinned = value;
		this.updatePinned();
	}
	get pinned() {
		return this._pinned;
	}

	@Input()
	set pinnedLeft(value: number) {
		this._pinnedLeft = value;
		this.updatePinned();
	}
	get pinnedLeft() {
		return this._pinnedLeft;
	}

	@Input()
	set pinnedRight(value: number) {
		this._pinnedRight = value;
		this.updatePinned();
	}
	get pinnedRight() {
		return this._pinnedRight;
	}

	@HostBinding('class.animations-enabled')
	private _animationsEnabled: boolean;
	@HostBinding('class.show-as-menu')
	private _showAsMenu: boolean;
	@HostBinding('class.is-not-top-menu')
	private _isNotTopMenu: boolean;

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this.el.contains(event.target)) {
			return;
		} else if (this.pinned && !this.showNavigationAlways) {
			this.changePinned();
		}
	}

	/**
	 * Folded
	 *
	 * @param {boolean} value
	 */
	@Input()
	set folded(value: boolean) {
		// Set the folded
		this._folded = value;

		// Return if the sidebar is closed
		if (!this.opened) {
			return;
		}

		// Programmatically add/remove padding to the element
		// that comes after or before based on the position
		let sibling, styleRule;

		const styleValue = this.foldedWidth + 'px';

		// Get the sibling and set the style rule
		if (this.position === 'left') {
			sibling = this._elementRef.nativeElement.nextElementSibling;
			styleRule = 'padding-left';
		} else {
			sibling = this._elementRef.nativeElement.previousElementSibling;
			//styleRule = 'padding-right';
		}

		// If there is no sibling, return...
		if (!sibling) {
			return;
		}

		// If folded...
		if (value) {
			// Fold the sidebar
			this.fold();

			// Set the folded width
			this._renderer.setStyle(this._elementRef.nativeElement, 'width', styleValue);
			this._renderer.setStyle(this._elementRef.nativeElement, 'min-width', styleValue);
			this._renderer.setStyle(this._elementRef.nativeElement, 'max-width', styleValue);

			// Set the style and class
			this._renderer.setStyle(
				sibling,
				styleRule,
				styleValue,
				RendererStyleFlags2.Important + RendererStyleFlags2.DashCase
			);
			this._renderer.addClass(this._elementRef.nativeElement, 'folded');
		} else {
			// Unfold the sidebar
			this.unfold();

			// Remove the folded width
			this._renderer.removeStyle(this._elementRef.nativeElement, 'width');
			this._renderer.removeStyle(this._elementRef.nativeElement, 'min-width');
			this._renderer.removeStyle(this._elementRef.nativeElement, 'max-width');

			// Remove the style and class
			this._renderer.removeStyle(sibling, styleRule);
			this._renderer.removeClass(this._elementRef.nativeElement, 'folded');
		}

		// Emit the 'foldedChanged' event
		this.foldedChanged.emit(this.folded);
	}
	get folded(): boolean {
		return this._folded;
	}

	/**
	 * Mouseenter
	 */
	@HostListener('mouseenter')
	onMouseEnter(): void {
		// Only work if the auto trigger is enabled
		if (!this.foldedAutoTriggerOnHover) {
			return;
		}

		this.unfoldTemporarily();
	}

	/**
	 * Mouseleave
	 */
	@HostListener('mouseleave')
	onMouseLeave(): void {
		// Only work if the auto trigger is enabled
		if (!this.foldedAutoTriggerOnHover) {
			return;
		}

		this.foldTemporarily();
	}

	private _position: 'left' | 'right' = 'left';
	private _isLockedOpen: boolean;
	private _pinnedLeft: number;
	private _pinnedRight: number;

	// Private

	private _folded: boolean;
	private _pinned = SidebarComponent.navbarPinned;
	private _wasActive: boolean;
	private _wasFolded: boolean;
	private _backdrop: HTMLElement | null = null;
	private _player: AnimationPlayer;
	private _showNavigationAlways = false;
	#opened = false;
	platform = inject(PlatformDetectorProvider);

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	get el() {
		return this._elementRef.nativeElement;
	}

	updatePinned() {
		if (this.ignorePinned) {
			return;
		}
		const el = this._elementRef.nativeElement;
		if (el) {
			this._renderer.setStyle(
				el,
				'left',
				this.pinned ? null : this.pinnedLeft ? `${this.pinnedLeft}px` : this.pinnedLeft === undefined ? null : 0,
				RendererStyleFlags2.Important
			);
			this._renderer.setStyle(
				el,
				'right',
				this.pinned ? null : this.pinnedRight ? `${this.pinnedRight}px` : this.pinnedRight === undefined ? null : 0,
				RendererStyleFlags2.Important
			);
		}

		if (!this.isMobile) {
			this._isNotTopMenu = this.pinnedLeft > 0;
		}

		this.isLockedOpen = this.pinned && (this.isMobile || this.showNavigationAlways);
	}

	changePinned(val?: boolean) {
		val = typeof val === 'boolean' ? val : !this.pinned;
		this.pinned = val;
		this.pinnedChange.emit(val);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.updatePinned();
		// Register the sidebar
		this._fuseSidebarService.register(this.name, this);

		// Setup visibility
		this._setupVisibility();

		// Setup position
		this._setupPosition();

		// Setup lockedOpen
		this._setupLockedOpen();

		// Setup folded
		this._setupFolded();

		this.refreshPage.navigationEnd$.pipe(takeUntil(this._destroy$)).subscribe(() => {
			if (!this.showNavigationAlways && !this.isMobile && this.pinned) {
				this.changePinned();
			}
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// If the sidebar is folded, unfold it to revert modifications
		if (this.folded) {
			this.unfold();
		}

		// Unregister the sidebar
		this._fuseSidebarService.unregister(this.name);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Setup the visibility of the sidebar
	 *
	 * @private
	 */
	private _setupVisibility(): void {
		// Remove the existing box-shadow
		this._renderer.setStyle(this._elementRef.nativeElement, 'box-shadow', 'none');

		// Make the sidebar invisible
		this._renderer.setStyle(this._elementRef.nativeElement, 'visibility', 'hidden');
	}

	/**
	 * Setup the sidebar position
	 *
	 * @private
	 */
	private _setupPosition(): void {
		this._renderer.removeClass(this._elementRef.nativeElement, 'right-positioned');
		this._renderer.removeClass(this._elementRef.nativeElement, 'left-positioned');
		if (this.isMobileMode) {
			this._renderer.removeClass(this._elementRef.nativeElement, 'is-mobile');
		}

		if (this.position === 'right') {
			this._renderer.addClass(this._elementRef.nativeElement, 'right-positioned');
		} else {
			this._renderer.addClass(this._elementRef.nativeElement, 'left-positioned');
			if (this.isMobileMode) {
				this._renderer.addClass(this._elementRef.nativeElement, 'is-mobile');
			}
		}
	}

	/**
	 * Setup the lockedOpen handler
	 *
	 * @private
	 */
	private _setupLockedOpen(): void {
		// Return if the lockedOpen wasn't set
		if (!this.lockedOpen) {
			// Return
			return;
		}

		// Set the wasActive for the first time
		this._wasActive = false;

		// Set the wasFolded
		this._wasFolded = this.folded;

		// Show the sidebar
		this._showSidebar();

		// Act on every media change
		this._matchMedia.media$.pipe(takeUntil(this._destroy$)).subscribe(() => {
			// Get the active status
			const isActive = !(this.isMobile || this.platform.isMobile());

			// If the both status are the same, don't act
			if (this._wasActive === isActive) {
				return;
			}

			// Activate the lockedOpen
			if (isActive) {
				// Set the lockedOpen status
				this.isLockedOpen = this.isMobile || this.showNavigationAlways;

				// Show the sidebar
				this._showSidebar();

				// Force the the opened status to true
				this.opened = true;

				// Emit the 'openedChanged' event
				this.openedChanged.emit(this.opened);

				// If the sidebar was folded, forcefully fold it again
				if (this._wasFolded) {
					// Enable the animations
					this._enableAnimations();

					// Fold
					this.folded = true;

					// Mark for check
					this._changeDetectorRef.markForCheck();
				}

				// Hide the backdrop if any exists
				this._hideBackdrop();
			} else {
				// Set the lockedOpen status
				this.isLockedOpen = false;

				// Unfold the sidebar in case if it was folded
				this.unfold();

				// Force the the opened status to close
				this.opened = false;

				// Emit the 'openedChanged' event
				this.openedChanged.emit(this.opened);

				// Hide the sidebar
				this._hideSidebar();
			}

			// Store the new active status
			this._wasActive = isActive;
			this.updatePinned();
		});
	}

	/**
	 * Setup the initial folded status
	 *
	 * @private
	 */
	private _setupFolded(): void {
		// Return, if sidebar is not folded
		if (!this.folded) {
			return;
		}

		// Return if the sidebar is closed
		if (!this.opened) {
			return;
		}

		// Programmatically add/remove padding to the element
		// that comes after or before based on the position
		let sibling, styleRule;

		const styleValue = this.foldedWidth + 'px';

		// Get the sibling and set the style rule
		if (this.position === 'left') {
			sibling = this._elementRef.nativeElement.nextElementSibling;
			styleRule = 'padding-left';
		} else {
			sibling = this._elementRef.nativeElement.previousElementSibling;
			//styleRule = 'padding-right';
		}

		// If there is no sibling, return...
		if (!sibling) {
			return;
		}

		// Fold the sidebar
		this.fold();

		// Set the folded width
		this._renderer.setStyle(this._elementRef.nativeElement, 'width', styleValue);
		this._renderer.setStyle(this._elementRef.nativeElement, 'min-width', styleValue);
		this._renderer.setStyle(this._elementRef.nativeElement, 'max-width', styleValue);

		// Set the style and class
		this._renderer.setStyle(
			sibling,
			styleRule,
			styleValue,
			RendererStyleFlags2.Important + RendererStyleFlags2.DashCase
		);
		this._renderer.addClass(this._elementRef.nativeElement, 'folded');
	}

	/**
	 * Show the backdrop
	 *
	 * @private
	 */
	private _showBackdrop(): void {
		this._backdrop = this._renderer.createElement('div');

		this._backdrop.classList.add('fuse-sidebar-overlay');

		if (this.invisibleOverlay) {
			this._backdrop.classList.add('fuse-sidebar-overlay-invisible');
		}

		this._renderer.appendChild(this._elementRef.nativeElement.parentElement, this._backdrop);

		if (!this.isMobileMode) {
			this._player = this._animationBuilder
				.build([animate('300ms ease', style({ opacity: 1 }))])
				.create(this._backdrop);

			this._player.play();
		}

		this._backdrop.addEventListener('click', () => {
			this.close();
		});

		this._changeDetectorRef.markForCheck();
	}

	/**
	 * Hide the backdrop
	 *
	 * @private
	 */
	private _hideBackdrop(): void {
		if (!this._backdrop) {
			return;
		}

		// Create the leave animation and attach it to the player
		this._player = this._animationBuilder.build([animate('300ms ease', style({ opacity: 0 }))]).create(this._backdrop);

		this._player.play();

		// Once the animation is done...
		this._player.onDone(() => {
			// If the backdrop still exists...
			if (this._backdrop) {
				// Remove the backdrop
				this._backdrop.parentNode.removeChild(this._backdrop);
				this._backdrop = null;
			}
		});

		this._changeDetectorRef.markForCheck();
	}

	/**
	 * Change some properties of the sidebar
	 * and make it visible
	 *
	 * @private
	 */
	private _showSidebar(): void {
		// Remove the box-shadow style
		this._renderer.removeStyle(this._elementRef.nativeElement, 'box-shadow');

		// Make the sidebar invisible
		this._renderer.removeStyle(this._elementRef.nativeElement, 'visibility');

		// Mark for check
		this._changeDetectorRef.markForCheck();
	}

	/**
	 * Change some properties of the sidebar
	 * and make it invisible
	 *
	 * @private
	 */
	private _hideSidebar(delay = true): void {
		const delayAmount = delay ? 300 : 0;

		// Add a delay so close animation can play
		setTimeout(() => {
			// Remove the box-shadow
			this._renderer.setStyle(this._elementRef.nativeElement, 'box-shadow', 'none');

			// Make the sidebar invisible
			this._renderer.setStyle(this._elementRef.nativeElement, 'visibility', 'hidden');
		}, delayAmount);

		// Mark for check
		this._changeDetectorRef.markForCheck();
	}

	/**
	 * Enable the animations
	 *
	 * @private
	 */
	private _enableAnimations(): void {
		// Return if animations already enabled
		if (this._animationsEnabled || this.isMobileMode) {
			return;
		}

		// Enable the animations
		this._animationsEnabled = true;

		// Mark for check
		this._changeDetectorRef.markForCheck();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Open the sidebar
	 */
	open(): void {
		if ((this.opened || this.isLockedOpen) && !this.showNavigationAlways) {
			return;
		}

		this._enableAnimations();
		this._showSidebar();
		this._showBackdrop();
		this.opened = true;
		this.openedChanged.emit(this.opened);
		this._changeDetectorRef.markForCheck();
	}

	/**
	 * Close the sidebar
	 */
	close(): void {
		if (!this.opened || this.isLockedOpen) {
			return;
		}

		// Enable the animations
		this._enableAnimations();

		// Hide the backdrop
		this._hideBackdrop();

		// Set the opened status
		this.opened = false;

		// Emit the 'openedChanged' event
		this.openedChanged.emit(this.opened);

		// Hide the sidebar
		this._hideSidebar();

		// Mark for check
		this._changeDetectorRef.markForCheck();
	}

	/**
	 * Toggle open/close the sidebar
	 */
	toggleOpen(): void {
		if (this.opened) {
			this.close();
		} else {
			this.open();
		}
	}

	/**
	 * Fold the sidebar permanently
	 */
	fold(): void {
		// Only work if the sidebar is not folded
		if (this.folded) {
			return;
		}

		// Enable the animations
		this._enableAnimations();

		// Fold
		this.folded = true;

		// Mark for check
		this._changeDetectorRef.markForCheck();
	}

	/**
	 * Unfold the sidebar permanently
	 */
	unfold(): void {
		// Only work if the sidebar is folded
		if (!this.folded) {
			return;
		}

		// Enable the animations
		this._enableAnimations();

		// Unfold
		this.folded = false;

		// Mark for check
		this._changeDetectorRef.markForCheck();
	}

	/**
	 * Toggle the sidebar fold/unfold permanently
	 */
	toggleFold(): void {
		if (this.folded) {
			this.unfold();
		} else {
			this.fold();
		}
	}

	/**
	 * Fold the temporarily unfolded sidebar back
	 */
	foldTemporarily(): void {
		// Only work if the sidebar is folded
		if (!this.folded) {
			return;
		}

		// Enable the animations
		this._enableAnimations();

		// Fold the sidebar back
		this.unfolded = false;

		// Set the folded width
		const styleValue = this.foldedWidth + 'px';

		this._renderer.setStyle(this._elementRef.nativeElement, 'width', styleValue);
		this._renderer.setStyle(this._elementRef.nativeElement, 'min-width', styleValue);
		this._renderer.setStyle(this._elementRef.nativeElement, 'max-width', styleValue);

		// Mark for check
		this._changeDetectorRef.markForCheck();
	}

	/**
	 * Unfold the sidebar temporarily
	 */
	unfoldTemporarily(): void {
		// Only work if the sidebar is folded
		if (!this.folded) {
			return;
		}

		// Enable the animations
		this._enableAnimations();

		// Unfold the sidebar temporarily
		this.unfolded = true;

		// Remove the folded width
		this._renderer.removeStyle(this._elementRef.nativeElement, 'width');
		this._renderer.removeStyle(this._elementRef.nativeElement, 'min-width');
		this._renderer.removeStyle(this._elementRef.nativeElement, 'max-width');

		// Mark for check
		this._changeDetectorRef.markForCheck();
	}

	get isMobileMode(): boolean {
		return this.name === SidebarKeys.mobileMainMenu;
	}
}

export const pinnedToken = 'pinned';
