import { FacadeStoreNames } from '@spa/facade/store-names.enum';
import { enumWithNs } from '@valhalla/utils';
import { ActionCreator } from '@valhalla/core';

import { IUserQuickAppsDefinition } from './state.contract';

const Actions = Object.freeze({
	addQuickAppsToUser: 'add-quick-apps-to-user',
	updateQuickAppsToUser: 'update-quick-apps-to-user',
	removeQuickAppsFromUser: 'remove-quick-apps-from-user'
});

export const userQuickAppsAction = enumWithNs(Actions, FacadeStoreNames.userQuickApps);

export const addQuickAppsToUser: ActionCreator<IUserQuickAppsDefinition[]> = payload => ({
	type: userQuickAppsAction.addQuickAppsToUser,
	payload
});

export const updateQuickAppsToUser: ActionCreator<IUserQuickAppsDefinition[]> = payload => ({
	type: userQuickAppsAction.updateQuickAppsToUser,
	payload
});

export const removeQuickAppsToUser: ActionCreator<Array<IUserQuickAppsDefinition | number>> = payload => ({
	type: userQuickAppsAction.removeQuickAppsFromUser,
	payload
});
