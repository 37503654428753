import { Injector, NgZone } from '@angular/core';
import { UpdateExtParamsInTaskService } from '@spa/application/ext-params/update-ext-params-in-task';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { ExtParamRegisterService } from './ext-param-register.service';
import { TaskViewActiveRegister } from '@spa/application/task';

const win = window as any;

export function registerExtParamGlobal(injector: Injector) {
	const epReg = () => injector.get(ExtParamRegisterService);
	const tasksActive = () => injector.get(TaskViewActiveRegister);
	const lastActiveCardGuid = () => tasksActive().lastActiveCardGuid;
	const ep = (id: number, guid?: string) => {
		if (guid) {
			return epReg().get(id, guid);
		}

		const currentEvent = window.event;
		if (currentEvent) {
			if (currentEvent.target instanceof HTMLElement) {
				const targetFromTask = tasksActive().findByElement(currentEvent.target);
				if (targetFromTask?.cardGuid) {
					return epReg().get(id, targetFromTask.cardGuid);
				}
			}
		}

		return epReg().get(id, lastActiveCardGuid());
	};
	const zone = () => injector.get(NgZone);

	win.ExtParam = class ExtParam {
		constructor(readonly id: number, guid: string) {
			const currentEvent = window.event as CustomEvent;
			this.#cardGuid = guid || currentEvent?.detail?.cardGuid;
			ep(id, guid)
				?.change$.pipe(takeUntil(this.#destroy$))
				.subscribe(v => {
					queueMicrotask(() => {
						this.#changeHandlers.forEach(fn => {
							try {
								fn.call(this, v);
							} catch (error) {
								console.error(error);
							}
						});
					});
				});
		}

		#changeHandlers: Function[] = [];
		#destroy$ = new Subject<void>();
		#cardGuid: string;

		get #realEp() {
			return ep(this.id, this.#cardGuid);
		}

		get() {
			return this;
		}

		label(text?: string) {
			if (typeof text === 'undefined') {
				return this.#realEp?.name;
			} else {
				zone().runTask(() => {
					this.#realEp?.setName(text);
				});
			}
		}

		show() {
			zone().runTask(() => {
				this.#realEp?.show();
			});
			return this;
		}

		hide() {
			zone().runTask(() => {
				this.#realEp?.hide();
			});
			return this;
		}

		val(newVal?: any) {
			if (typeof newVal !== 'undefined') {
				const epi = this.#realEp;
				zone().runTask(() => {
					epi.setValue(newVal);
				});
				return this;
			}
			return this.#realEp?.cloneValue;
		}

		textVal() {
			const val = this.#realEp?.cloneValue;
			return val && JSON.stringify(val);
		}

		getAvailableValues(cb?: Function) {}

		change(handler?: null | Function) {
			if (handler === undefined) {
				this.#changeHandlers.forEach(h => {
					try {
						h.call(this, this.val());
					} catch (error) {
						console.error(error);
					}
				});
			}
			if (handler === null) {
				this.#changeHandlers = [];
			}
			if (typeof handler === 'function') {
				this.#changeHandlers.push(handler);
			}
			return this;
		}

		save(handler?: null | Function) {
			if (typeof handler === 'function') {
				// subscribe to save event
				return this;
			}
			if (handler === null) {
				// UNsubscribe to save event
				return this;
			}
			zone().runTask(() => {
				const epi = this.#realEp;
				const updateEp = injector.get(UpdateExtParamsInTaskService);
				return lastValueFrom(
					updateEp.update({
						taskId: epi.ctxTaskId,
						extParams: [epi],
						writeComment: true,
					})
				);
			});
			return this;
		}

		update(handler?: null | Function) {
			return this;
		}

		isHidden() {
			return this.#realEp?.hidden;
		}

		freeze(freeze = true) {
			zone().runTask(() => {
				const mode = freeze ? 'read' : 'write';
				this.#realEp?.setAccessMode(mode);
			});
			return this;
		}

		adaptDesign() {}

		destroy() {
			this.#destroy$.next();
		}
	};

	win.SaveEPsWithIDs = function () {};
}
