import { Type } from '@angular/core';

export function ExtendsWithFakes<F1>(): Type<F1>;
export function ExtendsWithFakes<F1, F2>(): Type<F1 & F2>;
export function ExtendsWithFakes<F1, F2, F3>(): Type<F1 & F2 & F3>;
export function ExtendsWithFakes<F1, F2, F3, F4>(): Type<F1 & F2 & F3 & F4>;
export function ExtendsWithFakes<F1, F2, F3, F4, F5>(): Type<F1 & F2 & F3 & F4 & F5>;
export function ExtendsWithFakes<RealT, F1>(realTypeForExtend?: Type<RealT>): Type<RealT & F1>;
export function ExtendsWithFakes<RealT, F1, F2>(realTypeForExtend?: Type<RealT>): Type<RealT & F1 & F2>;
export function ExtendsWithFakes<RealT, F1, F2, F3>(realTypeForExtend?: Type<RealT>): Type<RealT & F1 & F2 & F3>;
export function ExtendsWithFakes<RealT, F1, F2, F3, F4>(
	realTypeForExtend?: Type<RealT>
): Type<RealT & F1 & F2 & F3 & F4>;
export function ExtendsWithFakes<RealT, F1, F2, F3, F4, F5>(
	realTypeForExtend?: Type<RealT>
): Type<RealT & F1 & F2 & F3 & F4 & F5> {
	if (realTypeForExtend) {
		return realTypeForExtend as Type<any>;
	} else {
		return class {} as Type<any>;
	}
}

interface IFake {
	fake(): string;
}

function UseFake() {
	return (target: Type<any>) => {
		target.prototype.fake = () => 'hello fake';
	};
}

class A {
	a() {}
}

class B {
	b() {}
}

@UseFake()
class C extends ExtendsWithFakes<A, IFake, B>(A) {
	b() {
		this.fake();
		this.a();
		this.b(); // failed at runtime
	}
}

// tslint:disable-next-line:no-unused-expression
new C();
