<ng-container *ngIf="!menuItem.hidden">
	<div
		*ngIf="!menuItem.isHeaderHidden"
		class="group-title"
		(click)="menuItemClick.emit(menuItem)"
		[ngClass]="menuItem.classes"
	>
		<span [translate]="menuItem.translate">{{ menuItem.title | lowercase }}</span>
	</div>

	<div class="group-items">
		<ng-container *ngFor="let childMenuItem of children; trackBy: trackById">
			<vh-common-nav-vertical-group
				*ngIf="childMenuItem.menuType === menuTypes.group"
				[contextMenu]="contextMenu"
				(menuItemClick)="menuItemClick.emit($event)"
				[menuItem]="childMenuItem"
				[attr.data-id]="childMenuItem.id"
			></vh-common-nav-vertical-group>

			<vh-common-nav-vertical-collapsable
				*ngIf="childMenuItem.menuType === menuTypes.collapsible"
				[vhContextMenu]="{
					menu: contextMenu,
					data: {
						item: childMenuItem
					}
				}"
				[contextMenu]="contextMenu"
				(menuItemClick)="menuItemClick.emit($event)"
				[isOpen]="!!childMenuItem.isOpen"
				[menuItem]="childMenuItem"
				[attr.data-id]="childMenuItem.id"
			></vh-common-nav-vertical-collapsable>

			<vh-common-nav-vertical-item
				*ngIf="childMenuItem.menuType === menuTypes.item"
				[contextMenu]="contextMenu"
				(menuItemClick)="menuItemClick.emit($event)"
				[menuItem]="childMenuItem"
				[attr.data-id]="childMenuItem.id"
			></vh-common-nav-vertical-item>
		</ng-container>
	</div>
</ng-container>
