import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { PipesCommonModule } from '../../pipes';
import { ChatNavAvatarComponent } from './chat-nav-avatar.component';
import { ChatAvatarLetterPipe } from './color-text.pipe';
import { ChatUserAvatarLinkPipe } from './user-avatar-link.pipe';

@NgModule({
	imports: [CommonModule, PipesCommonModule, MatIconModule],
	exports: [ChatNavAvatarComponent, ChatAvatarLetterPipe, ChatUserAvatarLinkPipe],
	declarations: [ChatNavAvatarComponent, ChatAvatarLetterPipe, ChatUserAvatarLinkPipe],
	providers: [],
})
export class AvatarModule {}
