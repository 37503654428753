<div
	class="vh-nav-panel-email-item--name"
	[class.vh-nav-panel-email-item--name-active]="isActiveRoute$ | async"
	[class.no-collapsable]="!isChildren(item) && !item?.isError"
	[class.vh-nav-panel-email-item--disabled]="item?.isDisabled && item?.isMailbox"
	(click)="onClickItem({ e: $event, item: this.item })"
	[vhContextMenu]="{
		menu: item?.isMailbox ? null : contextMenu,
		data: {
			folder: item,
			mailBoxId: mailBoxId
		}
	}"
>
	<mat-icon
		*ngIf="isChildren(item) || item?.isError"
		class="collapsable-arrow-icon"
		(click)="expandCollapse($event, item)"
		[svgIcon]="item?.isOpen ? 'vh-arrow-v1-S-down-24' : 'vh-arrow-v1-S-right-24'"
	></mat-icon>

	<span class="vh-nav-panel-email-item--name-txt">{{ item?.name }}</span>

	<div *ngIf="getCounterValue(item) || item?.isError" class="vh-nav-panel-email-item--counters">
		<vh-badge
			*ngIf="!item?.isError"
			[value]="getCounterValue(item)"
			[color]="badgeColor.primary"
			[locale]="locale$ | async"
		></vh-badge>

		<span *ngIf="item?.isError" class="vh-nav-panel-email-item--badge-error"> i </span>
	</div>
</div>

<!-- error -->
<div *ngIf="item?.isOpen && item?.isError" class="vh-nav-panel-email-item--error">
	<div class="empty-emails">
		<div class="empty-emails--text">
			<div class="empty-emails--text-title">{{ 'common.authorizationError' | resx }}</div>
			<div>{{ 'common.authorizationErrorText1' | resx }}</div>
			<div>{{ 'common.authorizationErrorText2' | resx }}</div>
		</div>

		<button class="vh-btn vh-btn--accent" (click)="openEditMailboxDialog(item)">{{ 'common.enter2' | resx }}</button>
	</div>
</div>

<!-- folders -->
<div class="vh-nav-panel-email-item--children" *ngIf="item?.isOpen && isChildren(item) && !item?.isError">
	<div *ngFor="let childItem of item?.children">
		<vh-nav-panel-email-item
			[item]="childItem"
			[mailBoxId]="mailBoxId"
			(clickItem)="clickItem.emit($event)"
			(clickExpandCollapse)="clickExpandCollapse.emit($event)"
			[contextMenu]="contextMenu"
		></vh-nav-panel-email-item>
	</div>
</div>
