import { IAppDefinition } from '../db';

export abstract class ApplicationActivator {
	abstract openApplication(app: Partial<IAppDefinition> | number): void;
	abstract resolveAppRoute(app: IAppDefinition): { url: string; query: Record<string, string> };
}

/**
 * абстрактный активатор приложения
 * для стандартного паттерна chain of responsibility
 */
export abstract class Activator {
	abstract activate(app: IAppDefinition): void;
	abstract resolveAppRoute(app: IAppDefinition): { url: string; query: Record<string, string> };

	private _successor: Activator;
	set successor(value: Activator) {
		this._successor = value;
	}
	get successor() {
		return this._successor;
	}
}
