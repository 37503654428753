import { Directive, ElementRef, HostBinding, TemplateRef } from '@angular/core';

@Directive({
	selector: '[vhFluentBoxMore]',
})
export class FluentBoxMoreDirective {
	constructor(readonly elRef: ElementRef<HTMLElement>, readonly templateRef: TemplateRef<any>) {}

	@HostBinding('class.vh-common-fluent-box__more-item')
	hostClassSelector = true;
}
