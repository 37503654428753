import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { DataHttpService } from '@spa/data/http';
import { booleanFilter, htmlToText } from '@valhalla/utils';
import { BehaviorSubject, Observable, shareReplay, switchMap, take } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { AutoFocusModule } from '@spa/common/directives';
import { ModalBaseModule } from '@spa/facade/features/modals/modal-base';
import { LocalizationModule } from '@valhalla/localization';
import { TippyModule } from '@ngneat/helipopper';

@Component({
	selector: 'vh-task-reason-dialog',
	templateUrl: 'task-reason-dialog.component.html',
	styleUrls: ['task-reason-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		CommonModule,
		LocalizationModule,
		ModalBaseModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		AutoFocusModule,
		TippyModule,
	],
})
export class TaskReasonDialogComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<any>,
		readonly server: DataHttpService
	) {}

	public reason: UntypedFormControl;

	taskId$ = new BehaviorSubject(null);
	saveReason$ = new BehaviorSubject<boolean>(false);
	quickReplies$ = this.taskId$.pipe(
		booleanFilter(),
		switchMap(taskId => this.server.signatures.getQuickReplies(taskId)),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	ngOnInit() {
		this.reason = new UntypedFormControl('', [Validators.required]);
		this.taskId$.next(this.data?.taskId);
	}

	accept() {
		if (!this.reason.value) {
			this.reason.setErrors({ required: true });
			this.reason.markAsTouched();
			return;
		}

		if (this.saveReason$.value) {
			this.addQuickReply(this.reason.value).pipe(take(1)).subscribe();
		}

		this.dialogRef.close({
			reason: this.reason.value,
		});
	}

	close() {
		this.dialogRef.close();
	}

	keydownHandler(event: KeyboardEvent) {
		if (event.ctrlKey && event.keyCode === 13) {
			this.accept();
		}
	}

	onClickReply(str: string) {
		this.reason.setValue(str);
	}

	parseReasons(reason: string): string {
		if (!reason) return;
		return htmlToText(reason);
	}

	addQuickReply(reason: string): Observable<any> {
		return this.server.signatures.addQuickReply({ reason: reason });
	}
}
