import { Injectable, Injector } from '@angular/core';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { adjustTask, ITaskInfo } from '@spa/data/entities';
import { ITaskUpdateExtParams } from '@spa/data/http';
import { SignalrProvider } from '@spa/data/signalr';
import { merge, Observable, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ExtParamBase } from '../ext-params';
import { resolveServer } from '../server-resolver';
import { TaskApplication } from './task-application';
import { TaskViewActiveRegister } from './task-view-active-register';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class TaskApplicationService {
	constructor(protected readonly injector: Injector, readonly mobileView: MobileViewService) {
		this.init();
	}

	protected tasks = new Map<number, TaskApplication>();
	readonly refreshMTFExtParamIds = new Set<number>();

	get(taskId: number, update?: boolean): TaskApplication {
		taskId = +taskId;
		if (typeof taskId !== 'number' || taskId <= 0) {
			return;
		}
		let appTask = this.tasks.get(taskId);
		if (!this.tasks.has(taskId)) {
			appTask = new TaskApplication(taskId, this.injector, this.mobileView);
			appTask.update();
			this.tasks.set(taskId, appTask);
		} else if (update || !appTask.isFullTask()) {
			appTask.update();
		}
		return appTask;
	}

	has(taskId: number) {
		return !!this.tasks.get(taskId)?.sourceModel;
	}

	getTaskSourceSync(taskId: number) {
		return this.tasks.get(taskId)?.sourceModel;
	}

	updateTaskCache(taskId: number) {
		this.tasks.get(taskId)?.update();
	}

	getTaskSource(taskId: number): Observable<Partial<ITaskInfo>> {
		return this.selectTaskSource(taskId).pipe(take(1));
	}

	selectTaskRoute(taskId: number) {
		const task = this.get(taskId);
		// if (!task.taskRoute) {
		task.updateTaskRoute();
		// }
		return task.taskRoute$;
	}

	selectTaskStates(taskId: number) {
		const task = this.get(taskId);
		if (!task.taskStates?.length) {
			task.updateTaskStates();
		}
		return task.taskStates$;
	}

	/**@deprecated use .has(taskId: number) */
	hasTaskInCache(taskId: number) {
		return !!this.tasks.get(taskId)?.sourceModel;
	}

	updateTask(taskId: number, create = false) {
		if (!this.tasks.has(taskId) && create) {
			return this.get(taskId).sourceModel$.pipe(take(1));
		}
		return this.tasks.get(taskId)?.update();
	}

	selectTaskSource(taskId: number, update = true): Observable<Partial<ITaskInfo>> {
		const task = this.get(taskId, update);
		return merge(task.sourceModel$, task.error$ as Observable<any>).pipe(
			map(r => {
				if (r instanceof Error || r instanceof HttpErrorResponse) {
					throw r;
				}
				return r;
			})
		);
	}

	selectAnyTaskSource(taskId: number): Observable<Partial<ITaskInfo>> {
		const task = this.get(taskId);
		return task.anySourceModel$;
	}

	selectTask(taskId: number) {
		return this.selectTaskSource(taskId, false);
	}

	ignoreNextUpdateTask(taskId: number, async = false) {
		const ignoreNextUpdate = () => {
			const task = this.get(taskId);
			if (task) {
				task.ignoreNextUpdate();
			}
		};
		if (async) {
			setTimeout(ignoreNextUpdate);
		} else {
			ignoreNextUpdate();
		}
	}

	add(...tasks: Partial<ITaskInfo>[]) {
		tasks.forEach(t => {
			const taskId = TaskApplication.getTaskId(t);
			if (this.tasks.has(taskId)) {
				return;
			}
			const appTask = new TaskApplication(taskId, this.injector, this.mobileView);
			appTask.load(t);
			this.tasks.set(taskId, appTask);
		});
	}

	remove(taskId: number) {
		const task = this.tasks.get(taskId);
		task?.destroy();
		this.tasks.delete(taskId);
	}

	loadTaskInCache(task?: Partial<ITaskInfo>, fullUpdate = false) {
		const taskId = TaskApplication.getTaskId(task);
		if (!this.tasks.has(taskId)) {
			this.tasks.set(taskId, new TaskApplication(taskId, this.injector, this.mobileView));
		}
		const taskApp = this.tasks.get(taskId);
		return taskApp.load(task, fullUpdate);
	}

	checkExistAndAccess(taskId: number, loadTaskInCache = true) {
		return resolveServer(this.injector).pipe(
			switchMap(server => server.task.checkExistAndAccess(taskId)),
			tap(access => {
				if (loadTaskInCache && access.isTaskExists && access.isUserHasAccess && access.taskShortInfo) {
					const task = adjustTask(access.taskShortInfo);
					this.loadTaskInCache(task);
				}
			})
		);
	}

	updateExtParams(params: ITaskUpdateExtParams, updateExtParams?: ExtParamBase[]) {
		return this.get(params?.taskId)?.updateExtParams(params, updateExtParams);
	}

	whenStable(taskId: number) {
		if (!this.has(taskId)) {
			return throwError(() => new Error(`task with id ${taskId} not loaded`));
		}
		const task = this.get(taskId, false);
		return task.isUpdating().pipe(
			filter(updating => !updating),
			take(1)
		);
	}

	protected init() {
		const signal = this.injector.get(SignalrProvider, null);
		const activeTaskViews = this.injector.get(TaskViewActiveRegister, null);
		signal.refreshMTF$.subscribe(refreshMtf => {
			const epIds = refreshMtf.details?.extParamIds || [];
			const isActiveTask = activeTaskViews.hasActiveTaskId(refreshMtf.taskId);
			const task = this.tasks.get(refreshMtf.taskId);
			if (epIds?.length > 0) {
				epIds.forEach(epId => {
					const epInst = task?.extParams.get(epId);
					this.refreshMTFExtParamIds.add(epId);
					if (isActiveTask) {
						epInst?.pendingUpdateConfirm(false);
					} else {
						epInst?.clearPendingUpdate();
					}
				});
			}
			if (isActiveTask) {
				task?.update(refreshMtf);
			}
		});
	}
}
