import { enumWithNs, Id } from '@valhalla/utils';
import { ActionCreator } from '@valhalla/core';
import { FacadeStoreNames } from '@spa/facade/store-names.enum';
import { ITicker } from '../ticker.model';

const Actions = Object.freeze({
	turnOnPeriodicTickersUpdate: 'runPeriodicTickersUpdate',
	turnOffPeriodicTickersUpdate: 'turnOffPeriodicTickersUpdate',
	loadTickers: 'loadTickers',
	loadTickersError: 'loadTickersError',
	updateTickers: 'updateTickers',
	updateChatTicker: 'updateChatTicker',
	updateTickersByName: 'updateTickersByName',
});

export const FacadeTickersAction = enumWithNs(Actions, FacadeStoreNames.tickers);

export const loadTickers: ActionCreator = payload => ({
	type: FacadeTickersAction.loadTickers,
	payload,
});

export const loadTickersError: ActionCreator<Error | string> = payload => ({
	type: FacadeTickersAction.loadTickersError,
	payload,
});

export const updateTickers: ActionCreator<Record<Id, ITicker> | Array<ITicker>> = payload => ({
	type: FacadeTickersAction.updateTickers,
	payload,
});

export const turnOnPeriodicTickersUpdate: ActionCreator = payload => ({
	type: FacadeTickersAction.turnOnPeriodicTickersUpdate,
	payload,
});

export const turnOffPeriodicTickersUpdate: ActionCreator = (payload?) => ({
	type: FacadeTickersAction.turnOffPeriodicTickersUpdate,
	payload,
});

export const updateChatTicker: ActionCreator = (payload: number) => ({
	type: FacadeTickersAction.updateChatTicker,
	payload,
});

export const updateTickersByName: ActionCreator = (payload: Record<string, number>) => ({
	type: FacadeTickersAction.updateTickersByName,
	payload,
});
