import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { IFrameViewerModalContentAreaComponent } from './iframe-view';
import { ModalContentAreaComponent } from './modal-content-area.component';

@NgModule({
	imports: [CommonModule, PortalModule, MatIconModule, MatToolbarModule],
	exports: [ModalContentAreaComponent, IFrameViewerModalContentAreaComponent],
	declarations: [ModalContentAreaComponent, IFrameViewerModalContentAreaComponent],
})
export class ModalContentAreaModule {}
