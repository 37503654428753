import { arrayToObject } from '@valhalla/utils';
import { ExtractPayloadType, IAction, produce, ReducerBase } from '@valhalla/core';

import * as actions from '../actions';
import { IUserQuickAppsState } from '../state.contract';

export class AddQuickAppToUserReducer implements ReducerBase {
	readonly actionType = actions.userQuickAppsAction.addQuickAppsToUser;

	reduce(state: IUserQuickAppsState, { payload }: IAction<ExtractPayloadType<typeof actions.addQuickAppsToUser>>) {
		return produce(state, ds => {
			const appsRecord = arrayToObject(ds.apps, app => app.appId);
			for (const newApp of payload) {
				if (!Boolean(appsRecord[newApp.appId])) {
					ds.apps.push(newApp);
				}
			}
		});
	}
}
