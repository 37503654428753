import { Pipe, PipeTransform } from '@angular/core';
import { timeago, dateFromStringValhalla } from '@valhalla/utils';
import { NgModule } from '@angular/core';

@Pipe({
	name: 'vhTimeAgo',
})
export class TimeAgoPipe implements PipeTransform {
	transform(value: string | number | Date, locale?: string): any {
		const date = typeof value === 'string' ? dateFromStringValhalla(value) : value;
		if (!value) {
			return '...';
		}
		const result = timeago.format(date, locale);
		return result;
	}
}

@NgModule({
	exports: [TimeAgoPipe],
	declarations: [TimeAgoPipe],
})
export class TimeAgoPipeModule {}
