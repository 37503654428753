export function testDate(str: string, isDatetime = false, resx: Record<string, string> = {}) {
	const dateReg =
		'((((0[1-9]|[12][0-9]|3[01])\\.(0[13578]|1[02]))|((0[1-9]|[12][0-9]|30)\\.(0[469]|11))|((0[1-9]|[1][0-9]|2[0-8])\\.02))\\.([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3}))|(29\\.02\\.(([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00)))';
	const timeReg = '([01][0-9]|2[0-3]):[0-5][0-9]';

	const monthReg =
		'(0?[1-9]|[12][0-9]|3[01]) (января|февраля|марта|апреля|мая|июня|июля|августа|сентября|октября|ноября|декабря)';

	const at = resx.at;
	const dayReg = `(${resx.yesterday} ${at}|${resx.today} ${at}|${resx.tommorow} ${at})`;

	return (
		getDateRegExt(dateReg, isDatetime, timeReg).test(str) ||
		getDateRegExt(monthReg, isDatetime, timeReg).test(str) ||
		getDateRegExt(dayReg, isDatetime, timeReg).test(str)
	);
}

function getDateRegExt(reg: string, isDatetime: boolean, timeReg: string): RegExp {
	return new RegExp(`^${reg}${isDatetime ? ` ${timeReg}` : ''}$`);
}
