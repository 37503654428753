import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, inject, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, of, switchMap, take } from 'rxjs';
import {
	BROWSER_TAB_ID,
	DesktopNotifications,
	LocalStorageProvider,
	PlatformHosterService,
	UrlProvider,
} from '@spa/core';
import { DataHttpService } from '../data/http';
import { ACTIVE_TABS, IActiveTabs, IBanerType, INotificationJitsi, JITSI_ROOM_ACTIVE } from './jitsi.model';
import { copyToClipboard, isExternalLink, toURL } from '@valhalla/utils';
import { Router } from '@angular/router';
import { TabActiveStateService } from '@spa/common/services/tab-active-state.service';
import { UserAvatarLink } from '@spa/common/components/chat-nav-avatar';
import { OpenPagesService } from '@spa/common/services/open-pages.service';

@Injectable({ providedIn: 'root' })
export class JitsiService {
	constructor(
		readonly urlBuilder: UrlProvider,
		readonly storage: LocalStorageProvider,
		readonly router: Router,
		readonly server: DataHttpService,
		readonly http: HttpClient,
		@Inject(BROWSER_TAB_ID) readonly browserTabId: string,
		readonly userAvatar: UserAvatarLink,
		readonly hoster: PlatformHosterService,
		readonly openPages: OpenPagesService
	) {}

	ringtoneCall; // = new Audio(this.urlBuilder.getUrlRelativeToAssets(`audio/ringtone.mp3`));
	readonly ringtoneJoined = new Audio(this.urlBuilder.getUrlRelativeToAssets(`audio/ringtone-type2.mp3`));
	readonly jitsiOperationUrl$ = new BehaviorSubject(null);

	get jitsiOperationUrl() {
		return this.jitsiOperationUrl$.value;
	}
	set jitsiOperationUrl(val: string) {
		this.jitsiOperationUrl$.next(val);
	}

	activeJitsiNotifications: INotificationJitsi[] = [];

	init() {
		this.server.users
			.getSessionUserSharedData('vksRingtone')
			.pipe(take(1))
			.subscribe(val => {
				if (val?.value) {
					this.ringtoneCall = new Audio(this.urlBuilder.getUrlRelativeToAssets(`audio/${val?.value}`));
					return;
				}
				this.ringtoneCall = new Audio(this.urlBuilder.getUrlRelativeToAssets(`audio/ringtone.mp3`));
			});
	}

	isUrlVKS(url: string) {
		if (!url || !url.trim?.()) {
			return of(false);
		}
		return this.server.config.appSettingsAnonymousConfig$.pipe(
			map(s => s.Services?.Jitsi?.Domain),
			map(vksDomain => {
				if (isExternalLink(url)) {
					return url.includes(vksDomain);
				}
				return url.toLowerCase().includes('conference');
			})
		);
	}

	isUrlVKSSync(url: string) {
		if (!url || !url.trim?.()) {
			return false;
		}
		const vksDomain = this.server.config.appSettingsAnonymousConfig?.Services?.Jitsi?.Domain;
		if (isExternalLink(url)) {
			return url.includes(vksDomain);
		}
		const u = url.toLowerCase();
		return u.includes('conference') && u.includes('room=');
	}

	getRoomFromLink(link: string) {
		if (this.isUrlVKSSync(link)) {
			const url = toURL(link);
			const room = url.pathname.includes('conference') ? url.searchParams.get('room') : url.pathname.substring(1);
			return room;
		}
	}

	setActiveTab() {
		const activeTabs = this.storage.get<IActiveTabs[]>(ACTIVE_TABS) || [];

		if (!activeTabs.length) {
			activeTabs.push({
				tabId: this.browserTabId,
				isActive: true,
			});

			this.storage.set(ACTIVE_TABS, activeTabs);
			return;
		}

		const currentTab = activeTabs.find(tab => tab.tabId === this.browserTabId);
		const activeTab = activeTabs.find(tab => tab.isActive);

		activeTab.isActive = false;

		if (currentTab) {
			currentTab.isActive = true;
			this.storage.set(ACTIVE_TABS, activeTabs);
			return;
		}

		activeTabs.push({
			tabId: this.browserTabId,
			isActive: true,
		});

		this.storage.set(ACTIVE_TABS, activeTabs);
	}

	deleteActiveTab() {
		const activeTabs = this.storage.get<IActiveTabs[]>(ACTIVE_TABS) || [];

		if (!activeTabs.length) {
			return;
		}

		const currentTabIndex = activeTabs.findIndex(tab => tab.tabId === this.browserTabId);

		if (currentTabIndex !== -1) {
			activeTabs.splice(currentTabIndex, 1);
		}

		const lastActiveTabs = activeTabs[activeTabs.length - 1];

		if (!lastActiveTabs) {
			this.storage.set(ACTIVE_TABS, activeTabs);
			return;
		}

		lastActiveTabs.isActive = true;
		this.storage.set(ACTIVE_TABS, activeTabs);
	}

	isLastFocusWindow() {
		const activeTabs = this.storage.get<IActiveTabs[]>(ACTIVE_TABS);
		if (!activeTabs?.length) {
			return true;
		}

		const currentTab = activeTabs.find(tab => tab.tabId === this.browserTabId);
		return currentTab.isActive;
	}

	isOpenCurrentRoom(roomName: string) {
		const activeRoom = this.storage.get(JITSI_ROOM_ACTIVE);

		if (!activeRoom?.length) {
			return false;
		}

		const activeRoomExist = activeRoom.find(room => room.roomName?.includes(roomName));

		if (activeRoomExist) {
			return true;
		}

		return false;
	}

	addActiveRoom(activeUrl: string) {
		const activeRoom = this.storage.get(JITSI_ROOM_ACTIVE) || [];
		const activeRoomExist = activeRoom.find(room => room.tabId === this.browserTabId);
		if (activeRoomExist) {
			activeRoomExist.roomName = activeUrl;
			return;
		}

		activeRoom.push({
			tabId: this.browserTabId,
			roomName: activeUrl,
		});

		this.storage.set(JITSI_ROOM_ACTIVE, activeRoom);
	}

	deleteActiveRoom() {
		const activeRoom = this.storage.get(JITSI_ROOM_ACTIVE);

		if (!activeRoom?.length) {
			return;
		}

		const activeRoomIndex = activeRoom.findIndex(room => room.tabId === this.browserTabId);
		if (activeRoomIndex !== -1) {
			activeRoom.splice(activeRoomIndex, 1);
		}

		this.storage.set(JITSI_ROOM_ACTIVE, activeRoom);
	}

	getTargetNotification(notification: INotificationJitsi) {
		const targetNotification = this.activeJitsiNotifications.find(
			act =>
				act?.roomName === notification?.roomName &&
				act?.user?.userName === notification?.user?.userName &&
				act?.typeBanner === notification?.typeBanner
		);

		return targetNotification;
	}

	onAcceptCall(notification: INotificationJitsi, cdr?: ChangeDetectorRef, newTab = true) {
		this.clearAllNotification(IBanerType.call, cdr);
		this.stopRingtone(this.ringtoneCall);
		notification.desktopNotificationIgnoreHandle = true;
		notification.desktopNotification?.close?.();
		this.openPages.openVKS({
			roomName: notification.roomName,
			focusTab: true,
			uuid: notification?.uuid,
			newTab,
		});
	}

	openRoom(roomName: string, newTab = true) {
		return this.openPages.openVKS({
			roomName: roomName,
			newTab,
		});
	}

	openConference(roomName: string, inviteUserIds: number | number[] = null, newTab = true) {
		return this.openPages.openVKS({
			roomName,
			inviteUserIds,
			newTab,
		});
	}

	openRoomInCurrentWindow(roomName: string) {
		return this.openPages.openVKS({ roomName });
	}

	copyConferenceLink(conferenceDomain, roomName) {
		copyToClipboard(`https://${conferenceDomain}/${roomName}`);
	}

	onAcceptCallNeighbourTab(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		this.clearAllNotification(IBanerType.call, cdr);
		this.stopRingtone(this.ringtoneCall);
		notification.desktopNotification?.close?.();
	}

	onDeclineCall(notification: INotificationJitsi, cdr?: ChangeDetectorRef, sendReq = true) {
		//localStorage.setItem(`${JITSI_NOTIFICATION}-${notification.roomName}`, `${Date.now()}`);

		if (sendReq) {
			this.http
				.post(this.jitsiOperationUrl, {
					action: 'user-invite-declined',
					data: {
						room: notification?.roomName,
						recipientUserId: notification?.user?.userId,
						uuid: notification?.uuid,
					},
				})
				.pipe(take(1))
				.subscribe();
		}

		this.deactivateJitsiNotification(notification, cdr);
	}

	onDeclineCallNeighbourTab(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		const targetNotification = this.getTargetNotification(notification);

		if (targetNotification) {
			this.onDeclineCall(targetNotification, cdr, false);
		}
	}

	onCloseJoinedNotification(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		this.deactivateJitsiNotification(notification, cdr);
	}

	onCloseJoinedNotificationNeighbourTab(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		const targetNotification = this.getTargetNotification(notification);
		if (targetNotification) {
			this.deactivateJitsiNotification(targetNotification, cdr);
		}
	}

	onCloseMissedNotification(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		this.deactivateJitsiNotification(notification, cdr);
	}

	onCloseMissedNotificationNeighbourTab(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		const targetNotification = this.getTargetNotification(notification);
		if (targetNotification) {
			this.deactivateJitsiNotification(notification, cdr);
		}
	}

	onCloseDeclinedNotification(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		this.deactivateJitsiNotification(notification, cdr);
	}

	onCloseDeclinedNotificationNeighbourTab(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		const targetNotification = this.getTargetNotification(notification);
		if (targetNotification) {
			this.deactivateJitsiNotification(notification, cdr);
		}
	}

	playRingtone(ringtone, loop) {
		if (!ringtone) {
			return;
		}
		ringtone.loop = loop;
		ringtone.play();
	}

	stopRingtone(ringtone) {
		ringtone.pause();
		ringtone.currentTime = 0;
	}

	activateNotificationJoined(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		if (this.isLastFocusWindow()) {
			this.playRingtone(this.ringtoneJoined, false);
		}

		setTimeout(() => {
			this.deactivateJitsiNotification(notification, cdr);
		}, 10000);
	}

	activateNotificationCall(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		if (!this.isActiveNotificationCall() && this.isLastFocusWindow()) {
			this.playRingtone(this.ringtoneCall, true);
			const tab = inject(TabActiveStateService);
			if (!tab.tabVisible) {
				this.showDesktopNotification(notification, cdr);
			}
		}

		notification.timeout = setTimeout(() => {
			this.deactivateJitsiNotification(notification, cdr);
			this.activateJitsiNotification(notification, IBanerType.missed, cdr);
		}, 30000);
	}

	activateNotificationDeclined(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		notification.timeout = setTimeout(() => {
			this.deactivateJitsiNotification(notification, cdr);
		}, 10000);
	}

	activateJitsiNotification(notification: INotificationJitsi, typeBanner: IBanerType, cdr?: ChangeDetectorRef) {
		notification['typeBanner'] = typeBanner;

		if (typeBanner === IBanerType.missed) {
			const alreadyMissedNotification = this.activeJitsiNotifications.find(
				el => el.roomName === notification.roomName && el.user?.userId === notification.user.userId
			);

			if (alreadyMissedNotification) {
				alreadyMissedNotification.missedCallCount = alreadyMissedNotification?.missedCallCount + 1;
				alreadyMissedNotification.lastMissedTime = new Date();
				cdr?.detectChanges();
				return;
			}

			notification.missedCallCount = 1;
			notification.lastMissedTime = new Date();

			this.activeJitsiNotifications.push(notification);

			cdr?.detectChanges();
			return;
		}

		const isAlreadyActive = this.activeJitsiNotifications.find(
			el => el.roomName === notification.roomName && el.typeBanner === notification.typeBanner
		);

		if (isAlreadyActive) {
			return;
		}

		if (typeBanner === IBanerType.joined) {
			this.activateNotificationJoined(notification);
		}

		if (typeBanner === IBanerType.call) {
			this.activateNotificationCall(notification, cdr);
		}

		if (typeBanner === IBanerType.declined) {
			this.activateNotificationDeclined(notification);
		}

		this.activeJitsiNotifications.push(notification);
		cdr?.detectChanges();
	}

	showDesktopNotification(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		const desktopNotifications = inject(DesktopNotifications);
		const userName = notification?.user?.userName || '';
		const userId = notification?.user?.userId || '';
		desktopNotifications
			.send({
				title: 'Звонок',
				body: userName,
				icon: notification?.user ? this.userAvatar.get(notification.user) : undefined,
				closeOnClick: false,
				focusTabOnClick: true,
				// tag: `1f-vks-call${userId ? '-uid:' + userId : ''}`,
				requireInteraction: true,
				badge: 'assets/icons/phone-call-24.png',
				// actions only in service worker support
				// actions: [
				// 	{
				// 		action: 'confirm',
				// 		title: 'Принять'
				// 	},
				// 	{
				// 		action: 'decline',
				// 		title: 'Отклонить'
				// 	}
				// ],
				onclose: noti => {
					if (notification.desktopNotificationIgnoreHandle) {
						return;
					}
					notification.desktopNotification = null;
					this.onDeclineCall(notification, cdr);
					this.storage.set(StorageVKSEvents.onDeclineCall, notification);
					noti.close();
				},
				onclick: (noti, e) => {
					notification.desktopNotificationIgnoreHandle = true;
					notification.desktopNotification = null;
					noti.close();
					this.onAcceptCall(notification, cdr);
					this.storage.set(StorageVKSEvents.onAcceptCall, notification);
				},
			})
			.pipe(take(1))
			.subscribe(result => {
				notification.desktopNotification = result?.notification;
			});
	}

	deactivateJitsiNotification(notification: INotificationJitsi, cdr?: ChangeDetectorRef) {
		const targetCallIndex = this.activeJitsiNotifications.findIndex(
			el => el.roomName === notification.roomName && el.typeBanner === notification.typeBanner
		);

		if (targetCallIndex === -1) {
			return;
		}

		clearTimeout(notification?.timeout);
		this.activeJitsiNotifications.splice(targetCallIndex, 1);

		if (!this.isActiveNotificationCall()) {
			this.stopRingtone(this.ringtoneCall);
		}
		notification.desktopNotificationIgnoreHandle = true;
		notification.desktopNotification?.close?.();
		cdr?.detectChanges();
	}

	clearAllNotification(type: IBanerType, cdr?: ChangeDetectorRef) {
		this.activeJitsiNotifications.forEach(n => {
			if (n?.typeBanner === type) {
				clearTimeout(n?.timeout);
			}
		});

		this.activeJitsiNotifications = this.activeJitsiNotifications.filter(n => n?.typeBanner !== type);
		cdr?.detectChanges();
	}

	isActiveNotificationCall() {
		const notifyCall = this.activeJitsiNotifications.filter(n => n?.typeBanner === IBanerType.call);
		return notifyCall.length;
	}
}

export enum StorageVKSEvents {
	onAcceptCall = 'onAcceptCall',
	onDeclineCall = 'onDeclineCall',
	onCloseJoinedNotification = 'onCloseJoinedNotification',
	onCloseMissedNotification = 'onCloseMissedNotification',
	onCloseDeclinedNotification = 'onCloseDeclinedNotification',
}
