import { Injectable } from '@angular/core';
import { DataHttpService } from '@spa/data/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BrowserImageCompressionOptimizer } from './browser-image-compression-optimizer';
import { CompressorjsOptimizer } from './compressorjs-optimizer';
import { ImageOptimizeStrategy } from './image-optimizer';
import { SquooshImageOptimizer } from './squoosh-image-optimizer';

@Injectable({ providedIn: 'root' })
export class ImageOptimizerService {
	constructor(protected readonly server: DataHttpService) {}

	protected strategies: Array<ImageOptimizeStrategy> = [
		new ImageOptimizeStrategy('Squoosh', SquooshImageOptimizer),
		new ImageOptimizeStrategy('BrowserImageCompression', BrowserImageCompressionOptimizer),
		new ImageOptimizeStrategy('Compressorjs', CompressorjsOptimizer),
	];
	protected defaultStrategy = 'Compressorjs';
	protected strategyOptions$ = this.server.config.appSettingsAnonymousConfig$.pipe(
		map(c => {
			const opt = c.CustomSettings?.imageCompressionEngine || {};
			const strategyName = opt.engine || this.defaultStrategy;
			return {
				strategyName,
				options: opt[strategyName] || {},
			};
		})
	);

	optimize(image: File): Observable<File> {
		return this.strategyOptions$.pipe(
			switchMap(({ options, strategyName }) => {
				const strategy = this.getStrategyByName(strategyName);
				return strategy.optimize(image, options);
			})
		);
	}

	addStrategy(strategy: ImageOptimizeStrategy) {
		this.strategies.push(strategy);
	}

	protected getStrategyByName(name: string) {
		let strategy = this.strategies.find(s => s.name === name);
		if (!strategy) {
			console.warn(`image optimize strategy with name '${name}' is not found, use default '${this.defaultStrategy}'`);
			strategy = this.strategies.find(s => s.name === this.defaultStrategy);
		}
		return strategy;
	}
}
