import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { booleanFilter } from '@valhalla/utils';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, shareReplay, take, exhaustMap, filter, tap } from 'rxjs/operators';

import { IApiResponse } from '../api-response';
import ApiVersion from '../api-versions';
import { DataHttpCommonService } from '../data-http-common.service';
import { ConfigurationDataHttpService, IAppConfiguration } from './abstract';
import { IApplicationSettingsDto, IAppSettingsAnonymousDto } from './dto';
import { ITaskInfo } from '@spa/data/entities';
import { LocalStorageProvider } from '@spa/core';

@Injectable()
export class ConfigurationDataHttpServiceImpl implements ConfigurationDataHttpService {
	constructor(readonly http: HttpClient, readonly common: DataHttpCommonService) {}

	readonly ls = inject(LocalStorageProvider);
	readonly lsKeyConfig = 'spa_api_configuration';
	readonly lsKeyAnonConfig = 'spa_app_settings_json';
	readonly refreshRequest$ = new BehaviorSubject(1);
	protected lastUpdateTimeAppSettingsJson: number;
	protected lastUpdateTimeConfiguration: number;
	appConfig: Partial<IAppConfiguration> = this.ls.get(this.lsKeyConfig) || ({} as any);
	readonly cacheTimeSec = 300;

	readonly appConfig$ = this.refreshRequest$.pipe(
		filter(() => this.needUpdate(this.lastUpdateTimeConfiguration)),
		map(_ => this.common.getApiUrl(`configuration`)),
		exhaustMap(url => {
			if (!this.isEmptyObject(this.appConfig) && !this.lastUpdateTimeConfiguration) {
				setTimeout(() => {
					this.http.get<IApiResponse<Record<string, any>>>(url).subscribe(res => {
						this.ls.set(this.lsKeyConfig, res);
					});
				});
				return of(this.appConfig);
			}
			return this.http.get<IApiResponse<Record<string, any>>>(url).pipe(
				tap(res => {
					this.ls.set(this.lsKeyConfig, res);
				})
			);
		}),
		map(({ data }) => data),
		tap(config => {
			this.lastUpdateTimeConfiguration = Date.now();
			this.appConfig = config;
		}),
		shareReplay({ bufferSize: 1, refCount: false })
	);

	appSettingsAnonymousConfig: IAppSettingsAnonymousDto = this.ls.get(this.lsKeyAnonConfig) || ({} as any);
	readonly appSettingsAnonymousConfig$ = this.refreshRequest$.pipe(
		filter(() => this.needUpdate(this.lastUpdateTimeAppSettingsJson)),
		map(_ => this.common.getEndpointUrl(`/app-settings.json?t=${Date.now()}`)),
		exhaustMap(url => {
			if (!this.isEmptyObject(this.appSettingsAnonymousConfig) && !this.lastUpdateTimeAppSettingsJson) {
				setTimeout(() => {
					this.http.get<IAppSettingsAnonymousDto>(url).subscribe(res => {
						this.ls.set(this.lsKeyAnonConfig, res);
					});
				});
				return of(this.appSettingsAnonymousConfig);
			}
			return this.http.get<IAppSettingsAnonymousDto>(url).pipe(
				tap(res => {
					this.ls.set(this.lsKeyAnonConfig, res);
				})
			);
		}),
		tap(settings => {
			this.lastUpdateTimeAppSettingsJson = Date.now();
			this.appSettingsAnonymousConfig = settings;
		}),
		shareReplay({ bufferSize: 1, refCount: false })
	);

	readonly localizationsCache: Record<
		string,
		{
			state$: BehaviorSubject<Record<string, string>>;
			value$: Observable<Record<string, string>>;
			loaded: boolean;
			pending: boolean;
		}
	> = {};

	readonly isVKSEnable$ = this.getAppSettingsAnonymous().pipe(map(settings => !!settings.Services?.Jitsi?.Domain));

	isEmptyObject(obj: any) {
		return obj && Object.keys(obj).length === 0;
	}

	getAppConfiguration(): Observable<Partial<IAppConfiguration>> {
		this.refreshRequest$.next(1);
		return this.appConfig$.pipe(take(1));
	}

	getAppSettingsAnonymous(): Observable<IAppSettingsAnonymousDto> {
		this.refreshRequest$.next(1);
		return this.appSettingsAnonymousConfig$.pipe(take(1));
	}

	getLocalizations(culture: string): Observable<Record<string, string>> {
		if (!this.localizationsCache[culture]) {
			const state$ = new BehaviorSubject(null);
			this.localizationsCache[culture] = {
				loaded: false,
				pending: true,
				state$: state$,
				value$: state$.pipe(booleanFilter()),
			};
			this.getLocalizationsFromServer(culture).subscribe(data => {
				this.localizationsCache[culture].loaded = true;
				this.localizationsCache[culture].pending = false;
				state$.next(data);
			});
		}
		return this.localizationsCache[culture].value$;
	}

	protected needUpdate(lastUpdateTime: number) {
		return lastUpdateTime ? Date.now() - lastUpdateTime > this.cacheTimeSec * 1000 : true;
	}

	protected getLocalizationsFromServer(culture: string): Observable<Record<string, string>> {
		const url = this.common.getApiUrl(`configuration/language`, ApiVersion.v10);
		const queryParams = {
			culture,
		};
		return this.http.get<any>(url, { params: queryParams });
	}

	getAppConfigurationByName(): Observable<any> {
		const url = this.common.getApiUrl('configuration');
		return this.http.get(url);
	}

	getApplicationSettings(): Observable<IApplicationSettingsDto> {
		const url = this.common.getApiUrl('configuration/applicationSettings', ApiVersion.v10);
		return this.http.get<IApplicationSettingsDto>(url);
	}

	isPrivateChatSync(subcatIdOrTask: number | Partial<ITaskInfo>) {
		return this.appConfig.chatSubcatId === this.getSubcatId(subcatIdOrTask);
	}

	isGroupChatSync(subcatIdOrTask: number | Partial<ITaskInfo>) {
		return this.appConfig.groupChatSubCatId === this.getSubcatId(subcatIdOrTask);
	}

	isPrivateOrGroupChatSync(subcatIdOrTask: number | Partial<ITaskInfo>) {
		return this.isPrivateChatSync(subcatIdOrTask) || this.isGroupChatSync(subcatIdOrTask);
	}

	isPrivateChat(subcatIdOrTask: number | Partial<ITaskInfo>): Observable<boolean> {
		return this.isPrivateChatFn().pipe(map(fn => fn(subcatIdOrTask)));
	}

	isGroupChat(subcatIdOrTask: number | Partial<ITaskInfo>): Observable<boolean> {
		return this.isGroupChatFn().pipe(map(fn => fn(subcatIdOrTask)));
	}

	isPrivateOrGroupChat(subcatIdOrTask: number | Partial<ITaskInfo>) {
		return this.isPrivateOrGroupChatFn().pipe(map(fn => fn(subcatIdOrTask)));
	}

	isPrivateChatFn() {
		return this.appConfig$.pipe(
			map(({ privateSubcatId, chatSubcatId }) => {
				return (subcatIdOrTask: number | Partial<ITaskInfo>) => {
					if (!subcatIdOrTask) {
						return false;
					}
					const subcatId = this.getSubcatId(subcatIdOrTask);
					return chatSubcatId === subcatId;
				};
			})
		);
	}

	isGroupChatFn() {
		return this.appConfig$.pipe(
			map(({ groupChatSubCatId }) => {
				return (subcatIdOrTask: number | Partial<ITaskInfo>) => {
					if (!subcatIdOrTask) {
						return false;
					}
					const subcatId = this.getSubcatId(subcatIdOrTask);
					return groupChatSubCatId === subcatId;
				};
			})
		);
	}

	isPrivateOrGroupChatFn() {
		return this.appConfig$.pipe(
			map(({ privateSubcatId, groupChatSubCatId }) => {
				return (subcatIdOrTask: number | Partial<ITaskInfo>) => {
					if (!subcatIdOrTask) {
						return false;
					}
					const subcatId = this.getSubcatId(subcatIdOrTask);
					return [privateSubcatId, groupChatSubCatId].includes(subcatId);
				};
			})
		);
	}

	getSubcatId(subcatIdOrTask: number | Partial<ITaskInfo>) {
		const subcatId =
			typeof subcatIdOrTask === 'number' ? subcatIdOrTask : subcatIdOrTask.subcatId || subcatIdOrTask.subcatID;
		return subcatId;
	}
}
