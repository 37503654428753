import { Subject } from 'rxjs';
import { cloneDeep } from './clone';
import merge from './merge';

export function createDataLayer<T extends Record<any, any>>(initialState: T = {} as any) {
	let commits = [];
	let destroyed = false;
	const changed$ = new Subject<T>();
	let inTransaction = false;
	let startTransactionFromCommitNum: number;

	const checkDestroy = () => {
		if (destroyed) {
			// tslint:disable-next-line:no-console
			console.warn('Data layer has been destroyed!');
		}
		return destroyed;
	};

	const getValue = () => {
		const val = commits.reduce((acc, cur) => {
			if (typeof cur !== 'object') {
				return acc;
			}
			return merge(acc, cur);
		}, initialState);

		return cloneDeep(val) as T;
	};

	return {
		get value() {
			return getValue();
		},
		changed$: changed$.asObservable(),
		commit: (data: T) => {
			if (checkDestroy()) {
				return;
			}

			commits.push(data);
			const dataIdx = commits.length - 1;

			changed$.next(getValue());

			return {
				rollback: () => {
					if (checkDestroy()) {
						return;
					}
					commits[dataIdx] = undefined;
					return {
						undoRollback: () => {
							if (checkDestroy()) {
								return;
							}
							commits[dataIdx] = data;
						},
					};
				},
			};
		},
		startTransaction() {
			if (inTransaction) {
				// tslint:disable-next-line:no-console
				return console.warn('Transaction already started');
			}
			inTransaction = true;
			startTransactionFromCommitNum = commits.length;
		},
		cancelTransaction() {
			commits.splice(startTransactionFromCommitNum);
			startTransactionFromCommitNum = -1;
			inTransaction = false;
		},
		endTransaction() {
			startTransactionFromCommitNum = -1;
			inTransaction = false;
		},
		destroy: () => {
			commits.length = 0;
			commits = null;
			destroyed = true;
		},
		toString() {
			return JSON.stringify({
				commits: commits,
				startTransactionFromIdx: startTransactionFromCommitNum,
				inTransaction,
			});
		},
	};
}
