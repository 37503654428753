import { ComponentFactoryResolver, Injectable, TemplateRef, Type } from '@angular/core';
import { take } from 'rxjs/operators';

import { IFrameViewerContentModalContext, IFrameViewerModalContentAreaComponent } from './iframe-view';
import { ModalContentAreaRegisterService } from './modal-content-area-register.service';

@Injectable({ providedIn: 'root' })
export class ModalContentAreaService {
	constructor(protected readonly register: ModalContentAreaRegisterService) {}

	protected readonly defaultName = 'default';

	open(openArgs: IModalContentAreaOpenOptions) {
		openArgs = this.defaultOpenOptions(openArgs);
		const host = this.register.get(openArgs.in);
		if (!host) {
			return;
		}
		const result = host.open({
			content: openArgs.content,
			context: openArgs.context,
			options: openArgs.options,
			componentFactoryResolver: openArgs.componentFactoryResolver,
		});
		if (openArgs?.options?.height) {
			host.setPercentHeight(openArgs?.options?.height);
		}
		this.setStylesMainContent(false);
		result
			.afterClosed()
			.pipe(take(1))
			.subscribe(() => {
				this.setStylesMainContent(true);
			});
		return result;
	}

	close(name: string = this.defaultName) {
		const host = this.register.get(name);
		if (!host) {
			return;
		}
		host.close();
	}

	setPercentHeight(x: number, name: string = this.defaultName): void {
		const host = this.register.get(name);
		if (!host) {
			return;
		}
		host.setPercentHeight(x);
	}

	openIframe(options: IModalContentAreaOpenOptions<IFrameViewerContentModalContext>) {
		options.content = options.content || IFrameViewerModalContentAreaComponent;
		return this.open(options);
	}

	protected getMainContentContainer() {
		return document.querySelector('#router-outlet-main-content')?.nextElementSibling as HTMLElement;
	}

	protected setStylesMainContent(modalVisible: boolean = true) {
		// const container = this.getMainContentContainer();
		// if (container) {
		// 	container.style.visibility = modalVisible ? 'visible' : 'hidden';
		// }
	}

	protected defaultOpenOptions(opt: IModalContentAreaOpenOptions): IModalContentAreaOpenOptions {
		const defaultOpts: Partial<IModalContentAreaOpenOptions> = {
			in: this.defaultName,
		};
		return Object.assign(defaultOpts, opt);
	}
}

export interface IModalContentAreaOpenOptions<T = any> {
	in?: string;
	content?: TemplateRef<any> | Type<any> | HTMLElement;
	componentFactoryResolver?: ComponentFactoryResolver;
	context?: T;
	options?: {
		height?: number;
		toolbar?: {
			title: string;
		};
	};
}
