export enum AppType {
	/**
	 * систеное приложение, доступное по стандартному роуту, например /tasks/234312
	 */
	system = 'system',
	/**
	 * приложение-расширение (плагин), подобные приложения загружаются в роут /apps/:appId
	 * для подобных приложений скорее всего будет создаваться отдельная директория на сервере spa-plugins
	 * для каждого прилоежния формироваться директория spa-plugins/exampleapp(app id)/ и manifest.json описания
	 */
	custom = 'custom',
	/**
	 * приложения открываемые в iframe (sandbox), обычно на других доменах
	 */
	external = 'external'
}
