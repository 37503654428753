export const linkRegexp = () => /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\*\+,;=.%]+/gm;

export function isExternalLink(link: string) {
	if (typeof link !== 'string' || !link.trim()) {
		return false;
	}
	const isHostSame = (() => {
		const tmp = document.createElement('a');
		tmp.href = link;
		return tmp.host === window.location.host;
	})();
	const hasSchema = ['//', '://'].some(es => link.includes(es));
	if (hasSchema) {
		return !isHostSame;
	}
	if (link.startsWith('/')) {
		return false;
	}
	const host = link.split('/')[0];
	const likeHost = host.includes('.');
	if (!likeHost) {
		return false;
	}
	return host.toLowerCase().trim() !== location.host.toLowerCase();
}

export function hrefPrefixIfExternal(link: string): string {
	if (isExternalLink(link)) {
		if (!link.startsWith('//') && !link.includes('://')) {
			return `//${link}`;
		}
	}
	return link;
}

export function toURL(url: string): URL {
	const tmp = document.createElement('a');
	tmp.href = url;
	return new URL(tmp.href);
}
