import { Observable } from 'rxjs';
export abstract class LinkedTasksHttpService {
	abstract getLinkedTasks(taskId: string | number): Observable<ILinkedTask[]>;
	abstract deleteLinks(taskId: number, subtaskId: number): Observable<any>;
}

export interface ILinkedTask {
	taskId?: number;
	taskID?: number;
	createdTask?: Date;
	taskAssigner?: string;
	subcat?: string;
	description: string;
	stateName: string;
	taskPerformer: string;
	isClosed: boolean;
	hasRightsToUnlink: boolean;
	hasViewPermissions: boolean;
}
