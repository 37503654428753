<ng-container *ngIf="!useCustomItems">
	<div
		*ngIf="styles$ | async as styles"
		class="tabbar"
		[style.background-color]="styles.containerBackgroundColor"
		[style.grid-template-columns]="tabbarGridTemplateColumns$ | async"
		[style.display]="customItemsSettings?.length ? 'flex' : 'grid'"
		[style.justify-content]="customItemsSettings?.length ? 'space-between' : null"
	>
		<ng-container *ngFor="let block of blocks$ | async as blocks; index as i">
			<div
				*ngIf="getBlockCustomSettingVisibility(block.id)"
				class="tabbar-block"
				[style.margin-top.px]="useCustomItems ? 5 : 0"
				(click)="clickHandler(blocks, i)"
			>
				<div class="tabbar-icon-block">
					<mat-icon
						[svgIcon]="block.isSelected ? block.template.svgIconFilled : block.template.svgIcon"
						[style.color]="getBlockColor(block, styles)"
					></mat-icon>
					<div class="tabbar-icon-badge">
						<vh-common-badge
							*ngIf="getTickerData(block) | async as badgeCount"
							[value]="badgeCount > 99 ? '99+' : badgeCount"
							[color]="block.template?.badgeItemColor || 'accent'"
						>
						</vh-common-badge>
					</div>
				</div>

				<div class="fall-back-title" [style.visibility]="useCustomItems ? 'hidden' : ''">
					{{ block.template.fallBackTitle }}
				</div>
			</div>
		</ng-container>
	</div>
</ng-container>

<ng-container *ngIf="useCustomItems">
	<div class="tabbar" [style.display]="'flex'" [style.justify-content]="'space-around'">
		<ng-container *ngFor="let block of customItemsSettings; index as i">
			<div
				*ngIf="block.visibility"
				class="tabbar-block"
				[style.margin-top.px]="5"
				(click)="clickHandler(customItemsSettings, i)"
			>
				<div class="tabbar-icon-block">
					<mat-icon [svgIcon]="(selectedBlockIndex$ | async) === i ? block.svgIconFilled : block.svgIcon"></mat-icon>
					<div class="tabbar-icon-badge">
						<vh-common-badge
							*ngIf="getTickerData(block) | async as badgeCount"
							[value]="badgeCount > 99 ? '99+' : badgeCount"
							color="accent"
						>
						</vh-common-badge>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</ng-container>
