import { Injectable } from '@angular/core';
import { MatProgressModeEnum } from '@spa/common/components/progress-bar';
import { MatThemePaletteEnum } from '@spa/common/material';
import { BehaviorSubject, Subject, merge } from 'rxjs';
import { distinctUntilChanged, takeUntil, debounceTime } from 'rxjs/operators';

// TODO: Add Angular decorator.
@Injectable()
export class SearchOverlayProgressBarService {
	private _bufferValue$ = new BehaviorSubject(0);
	private _mode$ = new BehaviorSubject(MatProgressModeEnum.indeterminate);
	private _value$ = new BehaviorSubject(0);
	private _visible$ = new BehaviorSubject(false);
	private _color$ = new BehaviorSubject(MatThemePaletteEnum.accent);
	private _destroy$ = new Subject();

	readonly bufferValue$ = this._bufferValue$.pipe(distinctUntilChanged(), takeUntil(this._destroy$));
	readonly mode$ = this._mode$.pipe(distinctUntilChanged(), takeUntil(this._destroy$));
	readonly visible$ = this._visible$.pipe(distinctUntilChanged(), takeUntil(this._destroy$));
	readonly value$ = this._value$.pipe(distinctUntilChanged(), takeUntil(this._destroy$));
	readonly color$ = this._color$.pipe(distinctUntilChanged(), takeUntil(this._destroy$));
	readonly change$ = merge(this.bufferValue$, this.mode$, this.visible$, this.value$, this.color$).pipe(
		debounceTime(20),
		takeUntil(this._destroy$)
	);

	ngOnDestroy(): void {
		this.dispose();
	}

	dispose() {
		this._destroy$.next(true);
		this._destroy$.complete();
		this._destroy$ = null;
		this._bufferValue$.complete();
		this._bufferValue$ = null;
		this._color$.complete();
		this._color$ = null;
		this._mode$.complete();
		this._mode$ = null;
		this._value$.complete();
		this._value$ = null;
		this._visible$.complete();
		this._visible$ = null;
	}

	show() {
		this._visible$.next(true);
		return this;
	}

	hide() {
		this._visible$.next(false);
		return this;
	}

	setMode(value: MatProgressModeEnum) {
		this._mode$.next(value);
		return this;
	}

	setBufferValue(value: number) {
		this._bufferValue$.next(value);
		return this;
	}

	setValue(value: number) {
		this._value$.next(value);
		return this;
	}

	setColor(value: MatThemePaletteEnum) {
		this._color$.next(value);
		return this;
	}
}
