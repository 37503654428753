import { HttpClient } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserLinkType } from '@spa/common/components/navigation';
import { ColorService } from '@spa/common/services/color.service';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { AuthService, UrlProvider, ViewDestroyStreamService } from '@valhalla/core';
import { IReportDescription } from '@valhalla/data/entities';
import { DataHttpService } from '@valhalla/data/http';
import { BooleanFilter, rxHandler, sortByLocaleCompare } from '@valhalla/utils';
import { from, Subject, combineLatest, Observable } from 'rxjs';
import {
	debounceTime,
	distinctUntilChanged,
	map,
	shareReplay,
	startWith,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';

@Component({
	selector: 'vh-layout-nav-panel-reports',
	templateUrl: './nav-panel-reports.component.html',
	styleUrls: ['./nav-panel-reports.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class NavPanelReportsComponent implements OnInit {
	constructor(
		readonly server: DataHttpService,
		readonly cdr: ChangeDetectorRef,
		readonly destroy$: ViewDestroyStreamService,
		private _http: HttpClient,
		readonly router: Router,
		readonly urlBuilder: UrlProvider,
		readonly colorService: ColorService,
		readonly elRef: ElementRef<HTMLElement>,
		readonly modal: ModalWindowsService,
		readonly auth: AuthService
	) {}

	@HostBinding('class.vh-layout-nav-panel-reports')
	hostClassSelector = true;

	@Output()
	expandCollapseEvent = new EventEmitter();

	readonly refreshReq$ = new Subject();

	readonly searchHandler = rxHandler<string>();

	readonly searchInput$ = from<string>(this.searchHandler as any).pipe(
		startWith(''),
		tap(() => {
			this.scrollToSearchInput();
		})
	);

	readonly searchInputHasValue$ = this.searchInput$.pipe(distinctUntilChanged(), map(BooleanFilter));

	readonly reports$ = this.refreshReq$.pipe(
		startWith(1),
		switchMap(_ => this._http.get<Array<IReportDescription>>('/app/v1.0/api/reports?v=1.0')),
		map(reports => sortByLocaleCompare(reports, report => report.description)),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	readonly groupReports$: Observable<{ blockName: string; reports: IReportDescription[]; expanded: boolean }[]> =
		combineLatest([this.reports$, this.searchInput$.pipe(debounceTime(300))])
			.pipe(
				map(([reports, query]) =>
					reports
						.filter(r => r.description.toLowerCase().includes(query.toLowerCase()))
						.reduce((acc, report) => {
							let target = acc.find(reportGroup => reportGroup.blockName === report.block);

							if (!target) {
								target = { blockName: report.block, reports: [], expanded: !!query };
								acc.push(target);
							}
							target.reports.push(report);
							return acc;
						}, [] as { blockName: string; reports: IReportDescription[]; expanded: boolean }[])
				)
			)
			.pipe(
				map(data =>
					data.map(item => {
						return { ...item, reports: item.reports.sort((a, b) => a.position - b.position) };
					})
				)
			);

	readonly isAdmin$ = this.auth.isAdmin$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));

	ngOnInit(): void {
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe(e => {
			if (e instanceof NavigationEnd) {
				this.cdr.detectChanges();
			}
		});
	}

	scrollToSearchInput() {
		const scrollContainer = this.elRef.nativeElement?.closest('.navbar-scroll-container');
		scrollContainer.scrollTop = 0;
	}

	onItemClick(id: number, e?: Event) {
		const mouseEvent = e as MouseEvent;
		const openInNewTab = mouseEvent?.ctrlKey || mouseEvent?.metaKey;
		const navParts = ['/report', id];
		if (openInNewTab) {
			let serializeUrl = this.router.serializeUrl(this.router.createUrlTree(navParts));
			serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
			return window.open(serializeUrl, '_blank');
		} else {
			return this.router.navigate(navParts);
		}
	}

	isItemSelected(id: number): boolean {
		const navParts = ['/report', id];
		let serializeUrl = this.router.serializeUrl(this.router.createUrlTree(navParts));
		serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
		const spaLinkPart = '/spa';
		if (serializeUrl.includes(spaLinkPart)) {
			serializeUrl = serializeUrl.substr(serializeUrl.indexOf(spaLinkPart) + spaLinkPart.length);
		}
		return this.router.url === serializeUrl;
	}

	favItemsColors() {
		return this.colorService.getFavColors();
	}

	addToFavorites(report: IReportDescription) {
		const requestParams = {
			customImageClass: null,
			customImagePath: null,
			folderId: null,
			isJsFunction: false,
			link: 'spa/report/' + report.id,
			linkedObjectId: report.id + '',
			name: report.description,
			type: UserLinkType['report'],
		};
		this.server.favorites
			.addLinkToFavorites(requestParams)
			.pipe(take(1))
			.subscribe(() => {
				this.refreshReq$.next(1);
				this.groupReports$.pipe(take(1)).subscribe(() => this.cdr.detectChanges());
			});
	}

	removeFromFavorites(report: IReportDescription) {
		this.server.favorites
			.deleteFavoriteLink(report.linkId)
			.pipe(take(1))
			.subscribe(() => {
				this.refreshReq$.next(1);
				this.groupReports$.pipe(take(1)).subscribe(() => this.cdr.detectChanges());
			});
	}

	editReport(report: IReportDescription, e?: Event) {
		const mouseEvent = e as MouseEvent;
		const openInNewTab = mouseEvent?.ctrlKey || mouseEvent?.metaKey;
		const navParts = ['/reportdesigner', report.id];
		if (openInNewTab) {
			let serializeUrl = this.router.serializeUrl(this.router.createUrlTree(navParts));
			serializeUrl = this.urlBuilder.getUrl(serializeUrl, false);
			return window.open(serializeUrl, '_blank');
		} else {
			const url = this.urlBuilder.getUrl(`/reportdesigner/${report.id}`, false);
			this.modal.openIFrame(url).afterClosed().pipe(take(1)).subscribe();
		}
	}

	getColor(color) {
		return this.colorService.сolorMapToRGB(color);
	}
}
