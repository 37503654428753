import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContentPortalComponent } from './content-portal.component';

@NgModule({
	imports: [CommonModule, PortalModule],
	exports: [ContentPortalComponent],
	declarations: [ContentPortalComponent],
	providers: []
})
export class ContentPortalModule {}
