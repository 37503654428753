import { Observable } from 'rxjs';

export function addScript(opt: { src: string; type?: string }): Observable<any> {
	return new Observable($ => {
		const script = document.createElement('script');
		script.src = opt.src;
		script.onload = () => {
			$.next();
			$.complete();
		};
		script.onerror = err => {
			$.error(err);
			$.complete();
		};
		document.body.appendChild(script);
	});
}

export function addCss(src: string): Observable<any> {
	return new Observable($ => {
		const link = document.createElement('link');
		link.href = src;
		link.rel = 'stylesheet';
		link.onload = () => {
			$.next();
			$.complete();
		};
		link.onerror = err => {
			$.error(err);
			$.complete();
		};
		document.head.appendChild(link);
	});
}
