import { Injector } from '@angular/core';
import { ExtParamRegisterService } from '@spa/api/ext-param/ext-param-register.service';
import { ExtParamTypeEnum, IExtParam } from '@spa/data/entities';
import { DataHttpService } from '@spa/data/http';
import { booleanFilter, clone, jsonClone, minmax, not, parseMessageFromError } from '@valhalla/utils';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	mergeMap,
	pairwise,
	shareReplay,
	skip,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { ExtParamAccessMode, ExtParamViewMode } from './ext-param-mode';
import { ExtParamValidator, ValidatorResult } from './ext-param-validator';
import { ControlStyleEditorService } from '@spa/common/components/controls/control-style-editor/control-style-editor.service';

export class ExtParamBase<TValue = any, TDataSource = any> {
	constructor(opt: ExtParamBaseOptions<TValue>) {
		this.options = opt;
		this.injector = opt.injector;
		this.initValidator = opt.initValidator;
		this.server = this.injector?.get(DataHttpService, null);
		this.controlStyleEditorService = this.injector?.get(ControlStyleEditorService, null);
		this.epRegister = this.injector?.get(ExtParamRegisterService, null);
		//TODO: validation
		this.setSourceConfig(opt.sourceConfig, true);
		this.setInitialUserValue(this.getSourceConfig());
		this.configure();
	}

	@minmax(0)
	protected _pendingUpdateConfirm = 0;

	protected readonly options: ExtParamBaseOptions;
	protected readonly injector: Injector;
	protected readonly initValidator: ExtParamValidator<TValue>;
	protected readonly server: DataHttpService;
	protected readonly controlStyleEditorService: ControlStyleEditorService;
	protected readonly epRegister: ExtParamRegisterService;
	protected readonly destroy$ = new Subject<void>();
	protected readonly sourceConfig$ = new BehaviorSubject<IExtParam<TValue, TDataSource>>(null);
	protected readonly sourceValue$ = this.sourceConfig$.pipe(
		map(sc => sc?.value),
		distinctUntilChanged((a, b) => this.equalsValue(a, b)),
		takeUntil(this.destroy$)
	);
	readonly sourceValueUpdateNotify$ = this.sourceValue$.pipe(map(sourceValue => sourceValue));

	protected userValue$ = new BehaviorSubject<TValue>(null);
	protected userName$ = new BehaviorSubject('');
	protected visibleState$ = new BehaviorSubject(true);
	protected setUserValueRequest$ = new Subject<TValue>();
	protected readonly validator$ = new BehaviorSubject<ExtParamValidator>(null);
	protected readonly validationResultState$ = new BehaviorSubject<ValidatorResult>(null);
	protected readonly validating$ = new BehaviorSubject(false);
	readonly validationResult$ = this.validationResultState$.asObservable();
	protected _saveImmidiateAfterValueChange = false;
	protected updatingState$ = new BehaviorSubject(false);
	protected updatingRealAccessMode: any;
	protected commonError$ = new BehaviorSubject<Error>(null);

	protected validationMessage$ = new BehaviorSubject(null);

	readonly TYPES = ExtParamTypeEnum;

	readonly updating$ = this.updatingState$.asObservable();

	readonly canEdit$ = combineLatest([this.updating$, this.sourceConfig$.pipe(map(() => this.readonly))]).pipe(
		map(([updating, readonly]) => !updating && !readonly)
	);

	readonly hidden$ = this.sourceConfig$.pipe(
		map(sc => sc?.isHidden),
		distinctUntilChanged(),
		takeUntil(this.destroy$)
	);
	readonly dirty$ = combineLatest([this.sourceValue$, this.userValue$]).pipe(
		map(() => this.dirty),
		takeUntil(this.destroy$)
	);
	readonly valid$ = combineLatest([this.userValue$, this.validator$, this.commonError$]).pipe(
		switchMap(([val, validator]) => {
			this.validating$.next(true);
			if (typeof validator?.validate === 'function') {
				return validator?.validate.call(this, val) as Observable<ValidatorResult>;
			}
			return of<ValidatorResult>({ result: true });
		}),
		tap(r => {
			this.validationResultState$.next(r);
			this.validating$.next(false);
		}),
		map(r => r.result),
		catchError(err => {
			const vr = {
				result: false,
				errors: [parseMessageFromError(err)],
			};
			this.validationResultState$.next(vr);
			this.validating$.next(false);
			return of(vr);
		}),
		shareReplay({ refCount: true, bufferSize: 1 }),
		takeUntil(this.destroy$)
	);
	readonly invalid$ = this.valid$.pipe(not());
	readonly invalidAndDirty$ = combineLatest([this.invalid$, this.dirty$]).pipe(
		map(([invalid, dirty]: any) => invalid && dirty),
		shareReplay(1),
		takeUntil(this.destroy$)
	);
	readonly required$ = this.sourceConfig$.pipe(map(sc => sc?.isRequired));
	protected readonly accessModeState$ = new BehaviorSubject<string>(ExtParamAccessMode.read);
	readonly accessMode$ = this.accessModeState$.pipe(booleanFilter(), distinctUntilChanged());
	protected readonly viewModeState$ = new BehaviorSubject<string>(ExtParamViewMode.read);
	readonly viewMode$ = this.viewModeState$.pipe(booleanFilter(), distinctUntilChanged());
	readonly value$ = this.userValue$.asObservable();
	readonly change$ = this.value$.pipe(
		skip(1),
		distinctUntilChanged((a, b) => this.equalsValue(a, b))
	);

	readonly helperText$ = this.sourceConfig$.pipe(
		map(sourceConfig => {
			if (sourceConfig?.regularExpression) {
				return null;
			}

			return sourceConfig?.regularExpressionAlert;
		})
	);

	readonly empty$ = this.userValue$.pipe(map(() => this.isEmpty()));
	readonly searchContext$ = new BehaviorSubject<any>(null);
	readonly name$ = this.sourceConfig$.pipe(map(() => this.name));
	readonly visible$ = this.visibleState$.asObservable();
	readonly undoValue$ = new Subject();
	readonly isReadonly$ = this.sourceConfig$.pipe(map(cfg => cfg?.canEdit));
	readonly onlyFocusMode$ = new BehaviorSubject<boolean>(null);
	readonly mobileReadonly$ = new BehaviorSubject<boolean>(null);
	readonly mobileHideInfoButton$ = new BehaviorSubject<boolean>(null);

	convertForSaveInNewTaskAsyncMiddleware: (e?: ExtParamBase) => Observable<any>;
	convertForSaveStringInNewTaskAsyncMiddleware: (e?: ExtParamBase) => Observable<string>;
	getValueMiddleware: (e?: ExtParamBase) => any;
	customDirtyChecker: (e?: ExtParamBase) => boolean;
	customModalModeChecker: (e?: ExtParamBase) => boolean;
	customConvertForUpdateExtParamInTask: (e?: ExtParamBase) => string | Observable<string>;

	get type() {
		return this.sourceConfig$.value?.type;
	}

	get id() {
		return this.sourceConfig$.value?.id;
	}

	get name() {
		if (this.userName$.value) {
			return this.userName$.value;
		}
		return this.getSourceConfig()?.name;
	}

	get hidden() {
		return this.sourceConfig$.value?.isHidden || !this.visibleState$.value;
	}

	get settings() {
		return this.sourceConfig$.value?.settings;
	}

	get value() {
		if (this.getValueMiddleware) {
			return this.getValueMiddleware.call(null, this);
		}
		return this.userValue$.value;
	}

	set value(val: TValue) {
		this.setUserValueRequest$.next(val);
	}

	get cloneValue() {
		return clone(this.value);
	}

	get sourceValue() {
		return this.sourceConfig$.value?.value;
	}

	get sourceConfig() {
		return this.getSourceConfig();
	}

	get required() {
		return this.sourceConfig$.value?.isRequired;
	}

	get readonly() {
		if (this.mobileReadonly) {
			return true;
		}
		return !this.sourceConfig$.value?.canEdit;
	}

	get canEdit() {
		return this.updating || !this.sourceConfig$.value?.canEdit;
	}

	get changeWithComment() {
		return this.sourceConfig$.value?.changeWithComment;
	}

	get accessMode() {
		return this.accessModeState$.value;
	}

	get placeholder() {
		return this.sourceConfig$.value?.placeholder || '';
	}

	get ctxTaskId() {
		return this.options?.ctxTaskId;
	}

	set ctxTaskId(value: number) {
		if (this.options) {
			this.options.ctxTaskId = value;
		}
	}

	get ctxSubcatId() {
		return this.options?.ctxSubcatId;
	}

	set ctxSubcatId(value: number) {
		if (this.options) {
			this.options.ctxSubcatId = value;
		}
	}

	get dirty() {
		if (typeof this.customDirtyChecker === 'function') {
			return this.customDirtyChecker(this);
		}
		return !this.equalsValue(this.sourceValue, this.value);
	}

	get isModalMode(): boolean {
		if (typeof this.customModalModeChecker === 'function') {
			return this.customModalModeChecker(this);
		}
		return false;
	}

	get extParamQueryStringValue(): string {
		if (typeof this.value === 'string' || typeof this.value === 'number' || typeof this.value === 'boolean') {
			return `$Ext${this.id}$${this.value}`;
		}
		if (!this.value) {
			return '';
		}
	}

	get viewMode() {
		return this.viewModeState$.value;
	}

	get isOpened() {
		return this.viewMode === ExtParamViewMode.write;
	}

	get isNewTask() {
		return !this.ctxTaskId;
	}

	get validationErrors() {
		return this.validationResultState$.value?.errors || [];
	}

	get valid() {
		return !!this.validationResultState$.value?.result;
	}

	get validating() {
		return this.validating$.value;
	}

	get saveImmidiateAfterValueChange() {
		return this._saveImmidiateAfterValueChange;
	}
	set saveImmidiateAfterValueChange(v: boolean) {
		this._saveImmidiateAfterValueChange = v;
	}

	get updating() {
		return this.updatingState$.value;
	}

	get mobileReadonly(): boolean {
		return this.mobileReadonly$.value;
	}

	set mobileReadonly(value: boolean) {
		this.mobileReadonly$.next(value);
	}

	get mobileHideInfoButton(): boolean {
		return this.mobileHideInfoButton$.value;
	}

	set mobileHideInfoButton(value: boolean) {
		this.mobileHideInfoButton$.next(value);
	}

	/** ДП ждет, что по сигналке придет обновление RefreshMTF после смены значения ДП
	 * считаем подтвержденным обновление - когда пришла сигналка RefreshMTF с details.extrParamIds
	 */
	get hasPendingUpdateConfirm() {
		return this._pendingUpdateConfirm > 0;
	}

	setUpdating(updating = true) {
		if (updating && this.updating) {
			return;
		}
		if (updating) {
			this.updatingRealAccessMode = this.accessModeState$.value;
			this.setAccessMode(ExtParamAccessMode.read);
		} else {
			this.setAccessMode(this.updatingRealAccessMode);
			this.updatingRealAccessMode = undefined;
		}
		this.updatingState$.next(updating);
	}

	pendingUpdateConfirm(incOrDec = true) {
		if (incOrDec) {
			this._pendingUpdateConfirm++;
		} else {
			this._pendingUpdateConfirm--;
		}
	}

	clearPendingUpdate() {
		this._pendingUpdateConfirm = 0;
	}

	setError(err: Error) {
		this.commonError$.next(err);
	}

	clearError() {
		this.commonError$.next(null);
	}

	setValidationMessage(err: string) {
		this.validationMessage$.next(err);
	}

	clearValidationMessage() {
		this.validationMessage$.next(null);
	}

	validationEnd() {
		return this.validating$.pipe(
			booleanFilter(validating => !validating),
			map(() => this.validationResultState$.value),
			take(1)
		);
	}

	setAccessMode(mode: string) {
		if (!ExtParamAccessModeValuesIdx[mode]) {
			return false;
		}
		mode = this.readonly ? ExtParamAccessMode.read : mode;
		this.accessModeState$.next(mode);
		if (mode === ExtParamAccessMode.read) {
			this.viewModeState$.next(ExtParamViewMode.read);
		}
		return true;
	}

	setViewMode(mode: string) {
		if (this.accessMode === ExtParamAccessMode.read) {
			return mode === ExtParamAccessMode.read;
		}

		if (ExtParamViewMode[mode]) {
			this.viewModeState$.next(mode);
			return true;
		}
		return false;
	}

	toggleViewMode() {
		const viewMode = this.viewMode;
		if (viewMode === ExtParamViewMode.read) {
			this.setViewMode(ExtParamViewMode.write);
		} else {
			this.setViewMode(ExtParamViewMode.read);
		}
	}

	selectValue(): Observable<TValue> {
		return this.userValue$;
	}

	setSourceConfig(sourceConfig: IExtParam<TValue>, clone = false) {
		const val = clone ? jsonClone(sourceConfig) : sourceConfig;
		this.sourceConfig$.next(val);
		return val;
	}

	getSourceConfig(): IExtParam<TValue, TDataSource> {
		return this.sourceConfig$.value;
	}

	selectSourceConfig(): Observable<IExtParam<TValue>> {
		return this.sourceConfig$;
	}

	setSourceValue(val: TValue) {
		const source = this.sourceConfig$.value;
		source.value = val;
		this.setSourceConfig(source);
	}

	setValue(val: TValue, needClone = false) {
		if (needClone) {
			this.value = clone(val);
		} else {
			this.value = val;
		}
	}

	getValue() {
		return this.value;
	}

	getValueForCopy() {
		return String(this.value);
	}

	setSearchContext(val) {
		this.searchContext$.next(val);
	}

	setCopiedValue(value) {
		this.setValue(value);
	}

	setName(name: string) {
		if (typeof name === 'string') {
			this.userName$.next(name);
			this.setSourceConfig({
				...this.sourceConfig,
			});
		}
	}

	show() {
		this.visibleState$.next(true);
	}

	hide() {
		this.visibleState$.next(false);
	}

	undoValue() {
		this.setValue(this.getSourceValue(true));
		this.undoValue$.next(0 as any);
	}

	clearValue() {
		this.userValue$.next(null);
	}

	getSourceValue(clone = false): TValue {
		const val = this.sourceConfig$.value?.value;
		if (!val) {
			return val;
		}
		return clone ? jsonClone(val) : val;
	}

	selectSourceValue(): Observable<TValue> {
		return this.sourceValue$;
	}

	equalsValue(a: TValue, b: TValue) {
		return a === b;
	}

	search(filter: string, skip = 0, take = 50, params?: any): Observable<any> {
		return EMPTY;
	}

	destroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	isEmpty() {
		//#1236661 Правка для дп мультифайл
		if (Array.isArray(this.value)) {
			return !this.value.length;
		}

		return !this.value;
	}

	convertForUpdateExtParamInTask(): string | Observable<string> {
		if (typeof this.customConvertForUpdateExtParamInTask === 'function') {
			return this.customConvertForUpdateExtParamInTask(this);
		}
		if (!this.value) {
			return `#n${this.id}#v${''}`;
		}
		return `#n${this.id}#v${this.value}`;
	}

	convertForUpdateExtParamInNewTask(): any {
		return typeof this.value === 'object' ? JSON.stringify(this.value) : `${this.value}`;
	}

	convertForSaveInNewTaskAsync(): Observable<any> {
		if (typeof this.convertForSaveInNewTaskAsyncMiddleware === 'function') {
			return this.convertForSaveInNewTaskAsyncMiddleware(this).pipe(take(1));
		}
		return of(this.value !== null && typeof this.value === 'object' ? JSON.stringify(this.value) : this.value || '');
	}

	convertForSaveStringInNewTaskAsync(): Observable<string> {
		if (typeof this.convertForSaveStringInNewTaskAsyncMiddleware === 'function') {
			return this.convertForSaveStringInNewTaskAsyncMiddleware(this).pipe(take(1));
		}
		if (this.isEmpty()) {
			return of('');
		}

		const res = this.convertForUpdateExtParamInTask();

		if (res instanceof Observable) {
			return res;
		}

		return of(res);
	}

	findEp(id: number) {
		return this.epRegister?.get(id, this.options.cardGuid);
	}

	getDependentEp() {
		return this.epRegister?.getDependentEp(this.id, this.options.cardGuid);
	}

	setValueFromString(value: string) {
		let val: any = value;
		try {
			// try js objects parse
			val = eval('(' + value + ')');
		} catch {}
		this.setValue(val);
	}

	canSave(source: any) {
		return true;
	}

	protected setInitialUserValue(sourceConfig?: IExtParam) {
		this.userValue$.next(this.getSourceValue(true));
	}

	protected createUserValueMiddleware() {
		return (nextVal: TValue) =>
			of(nextVal as any).pipe(
				tap(nextVal => {
					// clear dependent ext params if value change
					if (!this.equalsValue(this.value, nextVal)) {
						const deps = this.getDependentEp();
						deps?.forEach(ep => ep.clearValue());
					}
				})
			);
	}

	protected createValidator(
		sourceConfig?: IExtParam,
		initValidator?: ExtParamValidator<TValue>
	): ExtParamValidator<TValue> {
		return {
			validate: val => {
				// if (sourceConfig?.isRequired && this.isEmpty()) {
				// 	return of({ result: false, errors: ['common.errorFillRequired'] });
				// }
				let validation$: Observable<ValidatorResult>;
				if (typeof initValidator?.validate === 'function') {
					validation$ = initValidator?.validate.call(this, val);
				}
				validation$ = validation$
					? validation$.pipe(
							mergeMap(r => {
								if (!r.result) {
									return of(r);
								}
								return this.validate(val);
							})
					  )
					: this.validate(val);
				return validation$;
			},
		};
	}

	protected validate(val: TValue): Observable<ValidatorResult> {
		// default validator regularExpression exclude for some EP
		const applyForEpType = [
			ExtParamTypeEnum.money,
			ExtParamTypeEnum.number,
			ExtParamTypeEnum.numerator,
			ExtParamTypeEnum.numericValue,
			ExtParamTypeEnum.phone,
			ExtParamTypeEnum.url,
			ExtParamTypeEnum.text,
			ExtParamTypeEnum.textArea,
			ExtParamTypeEnum.textareaWOFormat,
			ExtParamTypeEnum.url,
		];
		if (this.sourceConfig?.regularExpression && applyForEpType.some(t => t === this.type)) {
			const re = new RegExp(this.sourceConfig.regularExpression);
			const valid = re.test(String(val));
			if (!valid) {
				return of({
					result: false,
					errors: [this.sourceConfig.regularExpressionAlert || ''],
				});
			}
		}
		if (this.commonError$.value) {
			return of({
				result: false,
				errors: [parseMessageFromError(this.commonError$.value)],
			});
		}
		return of({ result: true });
	}

	protected configure() {
		const userValueMiddleware = this.createUserValueMiddleware();
		this.setUserValueRequest$
			.pipe(
				switchMap(val => userValueMiddleware(val)),
				takeUntil(this.destroy$)
			)
			.subscribe(userValue => {
				this.userValue$.next(userValue);
			});
		const validator = this.createValidator(this.getSourceConfig(), this.initValidator);
		this.validator$.next(validator);
		this.valid$.pipe(takeUntil(this.destroy$)).subscribe();

		combineLatest([this.value$, this.viewMode$.pipe(pairwise())])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([value, [prevMode, nextMode]]) => {
				//если пользователь кликнул на ДП, очищаем сообщение валидации для МТФ
				if (prevMode === ExtParamViewMode.read && nextMode === ExtParamViewMode.write) {
					this.clearValidationMessage();
				}

				if (
					this.isNewTask &&
					this.sourceConfig?.isRequired &&
					prevMode === ExtParamViewMode.write &&
					nextMode === ExtParamViewMode.read &&
					!(this.type === ExtParamTypeEnum.table)
				) {
					if (this.isEmpty()) {
						this.setValidationMessage('Поле обязательно для заполнения');
					} else {
						this.clearValidationMessage();
					}
				}
			});

		this.userValue$.pipe(skip(1), takeUntil(this.destroy$)).subscribe(v => {
			if (this.isNewTask && this.sourceConfig?.isRequired && this.type === ExtParamTypeEnum.checkbox) {
				if (this.isEmpty()) {
					this.setValidationMessage('Поле обязательно для заполнения');
				} else {
					this.clearValidationMessage();
				}
			}
		});
	}
}

const ExtParamAccessModeValuesIdx = Object.values(ExtParamAccessMode).reduce((acc, cur) => {
	acc[cur] = true;
	return acc;
}, {} as Record<string, boolean>);

export type ExtParamBaseOptions<TValue = any> = {
	sourceConfig: IExtParam;
	injector?: Injector;
	initValidator?: ExtParamValidator<TValue>;
	ctxTaskId?: number;
	ctxSubcatId?: number;
	isNewTask?: boolean;
	cardGuid?: string;
};
