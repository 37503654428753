<vh-modal-base
	[showClose]="true"
	[showCancel]="dialogData?.data?.showCancel"
	[title]="
		dialogData?.data?.titleResx
			? (dialogData?.data?.titleResx | resx)
			: dialogData?.title || ('common.attention' | resx)
	"
	(close)="close()"
	(cancel)="close()"
	(ok)="close(true)"
	[okTitle]="dialogData?.data?.okTitle"
	[cancelTitle]="dialogData?.data?.cancelTitle"
>
	<div *ngIf="dialogData?.data?.messageResx" [innerHTML]="dialogData?.data?.messageResx | resx"></div>
	<div *ngIf="!dialogData?.data?.messageResx" [innerHTML]="dialogData?.message | replace: [['\r\n', '<br>']]"></div>
</vh-modal-base>
