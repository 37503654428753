import { Observable } from 'rxjs';

// tslint:disable:no-empty-interface
export abstract class SystemDataHttpService {
	abstract getInfo(): Observable<ISystemInformation>;
	abstract restartApplicationPool(): Observable<any>;
	abstract denormalizeAllSubcats(): Observable<any>;
	abstract denormalizeSubcats(subcats: number[]): Observable<any>;
	abstract clearLocalCaches(): Observable<any>;
}

export interface ISystemInformation {
	applicationVersion: string;
	productVersion: string;
	refreshApplicationDate: string;
	webServer: string;
	dbServer: string;
	dbName: string;
	totalUsersCount: number;
	totalUsersOnline: number;
	totalErrosDuringLastHour: number;
	webServerTime: string;
	licensesInfo: {
		application: number;
		eMailBoxes: number;
		personalArea: number;
		crm: number;
		firstForm: number;
		projectManagement: number;
		intergrationWithOneC: number;
		viewEncryptedTasks: number;
	};
	dbSchemaModificationDate?: string;
	applicationInstanceId?: string;
}
