import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { FluentBoxModule } from '@spa/common/components/fluent-box';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';
import { MatBadgeClassModule } from '@spa/common/directives/mat-badge-class';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';
import { LocalizationModule } from '@valhalla/localization';

import { ToolbarTickersComponent } from './toolbar-tickers/toolbar-tickers.component';
import { TippyModule } from '@ngneat/helipopper';
import { BadgeModule } from '@vh/core-components';

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		MatBadgeModule,
		MatMenuModule,
		FluentBoxModule,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
		LocalizationModule,
		MatBadgeClassModule,
		TippyModule,
		BadgeModule,
	],
	exports: [ToolbarTickersComponent],
	declarations: [ToolbarTickersComponent],
	providers: [],
})
export class TickerComponentsModule {}
