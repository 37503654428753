import { IAction, ReducerBase } from '@valhalla/core';
import { merge } from '@valhalla/utils';

import * as actions from '../actions';
import { IUserSettingsState } from '../state';

export class UpdateUserSettingsReducer implements ReducerBase {
	readonly actionType = actions.FacadeUserSettingsAction.updateUserSettings;

	reduce(state: Readonly<IUserSettingsState>, action: IAction<Partial<IUserSettingsState>>) {
		if (!action.payload) {
			return state;
		}
		return merge(state, action.payload);
	}
}
