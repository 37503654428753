import { AbstractMessage, AbstractMessageType } from '@valhalla/core';

export class UpdateTickersEventBusMessageType implements AbstractMessageType {
	id = Symbol();
	name = 'UpdateTickersEventBusMessageType';
}

export class UpdateTickersEventBusMessage implements AbstractMessage {
	constructor(readonly data: any) {}

	readonly type = new UpdateTickersEventBusMessageType();

	create(data?: any): AbstractMessage<any> {
		return new UpdateTickersEventBusMessage(data);
	}
}

export class UpdateSystemTickersEventBusMessageType implements AbstractMessageType {
	id = Symbol();
	name = 'UpdateSystemTickersEventBusMessageType';
}

export class UpdateSystemTickersEventBusMessage implements AbstractMessage {
	constructor(readonly data: any) {}

	readonly type = new UpdateSystemTickersEventBusMessageType();

	create(data?: any): AbstractMessage<any> {
		return new UpdateSystemTickersEventBusMessage(data);
	}
}
