<mat-toolbar
	class="mat-toolbar mat-toolbar-single-row"
	[class.space-between]="allCount <= 1 || !options?.showNavigationActions"
>
	<ng-container *ngIf="!ready">
		<div class="file-viewer-toolbar-column"></div>
		<ng-container *ngTemplateOutlet="navigationArrowButtons"></ng-container>
	</ng-container>
	<ng-container *ngIf="ready">
		<div class="file-viewer-toolbar-column clamped-1" [class.space-between]="allCount <= 1">
			<div class="file-name" [tippy]="title" [onlyTextOverflow]="true">{{ title }}</div>
			<div
				class="settings-panel"
				*ngIf="
					!mobileView.isMobile &&
					ready &&
					fileViewerComponent &&
					fileViewerComponent.settings &&
					fileViewerComponent.settings.settingsPanel
				"
			>
				<ng-template [cdkPortalOutlet]="fileViewerComponent.settings.settingsPanel"></ng-template>
			</div>
		</div>
		<ng-container *ngTemplateOutlet="navigationArrowButtons"></ng-container>
		<ng-container *ngTemplateOutlet="fileViewerDialogActions"></ng-container>
	</ng-container>
</mat-toolbar>

<div class="vh-file-viewer-dialog--container">
	<vh-file-viewer #fileViewer [file]="file" [options]="options" (ready)="onReady($event)"></vh-file-viewer>
</div>

<mat-menu #toolbarMenuButtons class="file-viewer-menu">
	<div class="toolbar-menu-buttons">
		<ng-container
			*ngIf="
				mobileView.isMobile &&
				ready &&
				fileViewerComponent &&
				fileViewerComponent.settings &&
				fileViewerComponent.settings.mobileActionPanel
			"
		>
			<ng-template [cdkPortalOutlet]="fileViewerComponent.settings.mobileActionPanel"></ng-template>
		</ng-container>
		<button *ngIf="mobileView.isMobile" [matMenuTriggerFor]="fileInfoMenu" mat-menu-item>
			<mat-icon svgIcon="vh-info-24"></mat-icon>
			<span>{{ 'common.information' | resx }}</span>
		</button>
		<ng-container *ngFor="let button of toolbarExtMenuButtons; let last = last">
			<mat-divider *ngIf="last && button?.visible"></mat-divider>
			<button
				*ngIf="button.visible"
				mat-menu-item
				[class.danger-color]="button.tooltipResx === 'common.mtfDelete'"
				(click)="button.clickHandler(file)"
			>
				<mat-icon [svgIcon]="button.icon" [class.danger-color]="button.tooltipResx === 'common.mtfDelete'"></mat-icon>
				<span>{{ button.tooltipResx | resx }}</span>
			</button>
		</ng-container>
	</div>
</mat-menu>

<mat-menu #fileInfoMenu class="file-viewer-info-menu">
	<div class="row">
		<span class="row-label"
			>Имя: <span class="row-value">&nbsp;{{ getFileNameWithoutExtension(file.fileName || file.name) }}</span></span
		>
	</div>
	<div class="row">
		<span class="row-label"
			>Размер: <span class="row-value">&nbsp;{{ file.size | modifySize }}</span></span
		>
	</div>
	<div class="row" *ngIf="isVideo(file.mime) && file.extInfo?.duration">
		<span class="row-label"
			>Продолжительность: <span class="row-value">&nbsp;{{ file.extInfo?.duration }} мин</span></span
		>
	</div>
	<div class="row" *ngIf="file.date">
		<span class="row-label"
			>Дата вложения:
			<span class="row-value"
				>&nbsp;{{ file.date | date: 'dd.MM.yyyy' }} в {{ file.date | date: 'shortTime' }}</span
			></span
		>
	</div>
	<div class="row">
		<span class="row-label"
			>Количество версий: <span class="row-value">&nbsp;{{ fileVersionsCount$ | async }}</span></span
		>
	</div>

	<div class="row" *ngIf="isImage(file.mime) && file.extInfo?.width && file.extInfo?.height">
		<span class="row-label"
			>Разрешение: <span class="row-value">&nbsp;{{ file.extInfo?.width }}x{{ file.extInfo?.height }}px</span></span
		>
	</div>

	<div class="row">
		<span class="row-label"
			>Формат: <span class="row-value">&nbsp;*.{{ getFileExtension(file.fileName || file.name) }}</span></span
		>
	</div>

	<div class="row" *ngIf="file.user?.userName">
		<span class="row-label"
			>Автор: &nbsp;
			<span
				class="row-value pointer"
				vhUserPreview
				(click)="preview.show(true); $event.stopPropagation()"
				[previewUserId]="file.user?.userId"
				#preview="vhUserPreview"
				>{{ file.user?.userName }}</span
			></span
		>
	</div>
</mat-menu>

<ng-template #navigationArrowButtons>
	<div *ngIf="allCount > 1" class="file-viewer-toolbar-column navigation-arrow-buttons">
		<button class="vh-file-viewer-dialog--action" (click)="prev()" [tabIndex]="'-1'">
			<mat-icon svgIcon="vh-arrow-v1-L-left-24"></mat-icon>
		</button>
		<span class="files-count-label">{{ current }}/{{ allCount }}</span>
		<button class="vh-file-viewer-dialog--action" (click)="next()" [tabIndex]="'-1'">
			<mat-icon svgIcon="vh-arrow-v1-L-right-24"></mat-icon>
		</button>
	</div>
</ng-template>

<ng-template #fileViewerDialogActions>
	<div class="file-viewer-toolbar-column vh-file-viewer-dialog--actions" [class.space-between]="allCount <= 1">
		<div
			class="actions-panel"
			*ngIf="
				!mobileView.isMobile &&
				ready &&
				fileViewerComponent &&
				fileViewerComponent.settings &&
				fileViewerComponent.settings.actionPanel
			"
		>
			<ng-template [cdkPortalOutlet]="fileViewerComponent.settings.actionPanel"></ng-template>
		</div>

		<div
			*ngIf="officeFileUnsupportedExtension() && !mobileView.isMobile"
			class="vh-file-viewer-dialog--action-edit flex align-center"
			style="font-size: 16px; font-weight: 400"
		>
			<mat-icon
				svgIcon="vh-info-24"
				class="mr-4 s-20"
				[title]="
					officeFileUnsupportedExtension()
						? 'Офисные файлы doc/xls/ppt доступны только на просмотр. Перевложите файлы в новом формате docx/xlsx/pptx'
						: 'У вас нет доступа на редактирование'
				"
			></mat-icon>
			Только просмотр
		</div>

		<button
			*ngIf="isBtnSetEditMode() && !mobileView.isMobile"
			class="vh-file-viewer-dialog--action"
			(click)="openInNewWindow(file, false, true)"
			[tippy]="'common.fileViewerBtnTooltipEdit' | resx"
		>
			<mat-icon svgIcon="vh-edit-text-24"></mat-icon>
		</button>

		<button
			class="vh-file-viewer-dialog--action divider"
			*ngIf="((ready && fileViewerComponent?.settings?.actionPanel) || isBtnSetEditMode()) && !mobileView.isMobile"
		>
			<mat-icon svgIcon="vh-divider"></mat-icon>
		</button>

		<button
			*ngIf="!mobileView.isMobile"
			[matMenuTriggerFor]="fileInfoMenu"
			class="vh-file-viewer-dialog--action"
			[tippy]="'common.information' | resx"
		>
			<mat-icon svgIcon="vh-info-24"></mat-icon>
		</button>

		<button
			*ngIf="!options?.isMeetingFile && !options?.isEmailFile"
			[matMenuTriggerFor]="toolbarMenuButtons"
			class="vh-file-viewer-dialog--action"
			[tippy]="'common.otherActions' | resx"
		>
			<mat-icon svgIcon="vh-more-menu-24"></mat-icon>
		</button>

		<button (click)="close()" class="vh-file-viewer-dialog--action" [tippy]="'common.close' | resx">
			<mat-icon svgIcon="vh-cross_v1-24"></mat-icon>
		</button>
	</div>
</ng-template>
