export enum Block$Type {
	syndicate = 'TCClassLib.API.Blocks.Syndicate, TCClassLib',
	folder = 'TCClassLib.API.Folder, TCClassLib',
	link = 'TCClassLib.API.Blocks.Link, TCClassLib',
	report = 'TCClassLib.API.Blocks.Report, TCClassLib',
	task = 'TCClassLib.API.Blocks.Task, TCClassLib',
	comment = 'TCClassLib.API.Blocks.Comment, TCClassLib',
}

export enum BlockData$Type {
	title = 'TCClassLib.API.TitleData, TCClassLib',
	syndicateSubcatCounter = 'TCClassLib.API.SyndicateSubcatCounterBlockData, TCClassLib',
	syndicateCat = 'TCClassLib.API.SyndicateCatBlockData, TCClassLib',
	url = 'TCClassLib.API.UrlData, TCClassLib',
}

export enum ActionType {
	syndicate = 'Syndicate',
	link = 'Link',
	task = 'Task',
	report = 'Report',
	comment = 'Comment',
}

export enum BlockType {
	syndicate = 'Syndicate',
	folder = 'Folder',
	dashboard = 'Dashboard',
	mainMenu = 'MainMenu',
	contact = 'Contact',
	newTask = 'NewTask',
	settings = 'Settings',
	diagnostics = 'Diagnostics',
}

export enum BlockId {
	contacts = 'Contacts',
	tasksFeed = 'TasksFeed',
	dashboard = 'Dashboard',
	feed = 'Feed',
	chats = 'Chats',
	// MainMenu IDs
	card = 'VCard',
	toSign = 'ToSign',
	subcatAll = 'SubcatAll',
	taskSource = 'TaskSource',
	reports = 'Reports',
	newTask = 'NewTask',
	settings = 'Settings',
	diagnostics = 'Diagnostics',
	subcat = 'Subcat',
	// Dashboards IDs
	separator = 'separator',
	folder = 'Folder',
	questionsToMe = 'QuestionsToMe',
	overdueTasks = 'OverdueTasks',
	customTaskSource = 'CustomTaskSource',
}

export const mobileIcons: Record<string, { default: string; filled?: string }> = {
	ChatsTabIcon: {
		default: 'vh-chats-24',
		filled: 'vh-chats-filled-24',
	},
	'ChatsTabIcon-solid': { default: 'vh-chats-filled-24' },
	FeedIcon: {
		default: 'vh-feed-24',
		filled: 'vh-feed-filled-24',
	},
	'FeedIcon-solid': { default: 'vh-feed-filled-24' },
	DashboardIcon: {
		default: 'vh-home-24',
		filled: 'vh-home-filled-24',
	},
	'DashboardIcon-solid': { default: 'vh-home-filled-24' },
	TasksFeedIcon: {
		default: 'vh-tasks-24',
		filled: 'vh-tasks-filled-24',
	},
	'TasksFeedIcon-solid': { default: 'vh-tasks-filled-24' },
	ContactsIcon: {
		default: 'vh-contacts-24',
		filled: 'vh-contacts-filled-24',
	},
	'ContactsIcon-solid': { default: 'vh-contacts-filled-24' },
	VCardButton: { default: 'vh-personal-id-24' },
	VCard: { default: 'vh-personal-id-24' },
	SignJournal: { default: 'vh-sign-request-24' },
	AllCategories: { default: 'vh-All-Categories-24' },
	recent_tasks: { default: 'vh-History-24' },
	Signed: { default: 'vh-sign-done-24' },
	report: { default: 'vh-reports-24', filled: 'vh-reports-filled-24' },
	AddAbsence: { default: 'vh-add-absence-24' },
	settings: { default: 'vh-settings-24', filled: 'vh-settings-filled-24' },
	SettingsIcon: { default: 'vh-settings-24', filled: 'vh-settings-filled-24' },
	'SettingsIcon-solid': { default: 'vh-settings-filled-24' },
	VideoCall: { default: 'vh-video-call-24' },
	'VideoCall-solid': { default: 'vh-video-call-filled-24' },
	Calendar: { default: 'vh-calendar-24' },
	'Calendar-solid': { default: 'vh-calendar-filled-24' },
	ToSign: { default: 'vh-Sign-Add-24', filled: 'vh-sign-24' },
	Questions: { default: 'vh-question-24' },
	OverdueTasks: { default: 'vh-Time-Sources-24' },
	DBAdvertising: { default: 'vh-DBAdvertising' },
	'Absence-solid': { default: 'vh-absence-solid-32' },
	VideoIcon: { default: 'vh-video-call-24' },
	'VideoIcon-solid': { default: 'vh-video-call-filled-24' },
	SearchIcon: { default: 'vh-search-24' },
	'SearchIcon-solid': { default: 'vh-search-filled-24' },
	ReportsIcon: { default: 'vh-reports-24' },
	'ReportsIcon-solid': { default: 'vh-reports-filled-24' },
	PhonePadIcon: { default: 'vh-phone-call-24' },
	'PhonePadIcon-solid': { default: 'vh-phone-call-filled-24' },
	CalendarTabIcon: { default: 'vh-calendar-24' },
	'CalendarTabIcon-solid': { default: 'vh-calendar-filled-24' },
	tosign_cat: { default: 'vh-Sign-Add-24' },
	star: { default: 'vh-favourites-24' },
	signed: { default: 'vh-sign-done-24' },
	diagnostic: { default: 'vh-Diagnostic-24' },
	Contacts: { default: 'vh-contacts-24' },
	Unlock: { default: 'vh-Size-24' },
	Subtasks: { default: 'vh-tasks-subtasks-24' },
	Structure: { default: 'vh-Structure' },
	Speaker: { default: 'vh-sound-on-24' },
	RecentTasks: { default: 'vh-History-24' },
	Paste: { default: 'vh-paste-24' },
	Lock: { default: 'vh-lock-24' },
	GroupChat: { default: 'vh-Chats-Group-24' },
	Download: { default: 'vh-download-24' },
	Chat: { default: 'vh-chats-24' },
	Search: { default: 'vh-search-24' },
	Notification: { default: 'vh-notifications-24' },
	Menu: { default: 'vh-hamburger-menu-24' },
	Filter: { default: 'vh-filter-24' },
	DotsMenu: { default: 'vh-more-menu-24' },
	CalendarPlus: { default: 'vh-icons8-calendar_plus' },
	Basket: { default: 'vh-icons8-basket' },
	View: { default: 'vh-eye-24' },
	ToTaskButton: { default: 'vh-plus-v2-24' },
	ShowOnMap: { default: 'vh-icons8-карта-120' },
	ResendButton: { default: 'vh-forward-24' },
	ReplyToAllButton: { default: 'vh-reply-all-24' },
	ReplyButton: { default: 'vh-reply-24' },
	QuickReplies: { default: 'vh-quick-mail-24' },
	NotToMeButton: { default: 'vh-not-for-me-24' },
	CopyButton: { default: 'vh-copy-24' },
	AnsweredButton: { default: 'vh-as-done-24' },
	'Warning-solid': { default: 'vh-warning-solid-32' },
	'Wallet-solid': { default: 'vh-wallet-32' },
	'Taxi-solid': { default: 'vh-taxi-32' },
	'Tasks-solid': { default: 'vh-tasks-32' },
	'TaskIt-solid': { default: 'vh-task-it-32' },
	'Reports-solid': { default: 'vh-reports-32' },
	'RentHouse-solid': { default: 'vh-rent-house-32' },
	'PassPersonal-solid': { default: 'vh-pass-personal-32' },
	'PassAuto-solid': { default: 'vh-pass-auto-24' },
	'FoodDelivery-solid': { default: 'vh-food-delivery-32' },
	'Diary-solid': { default: 'vh-diary-32' },
	'Conference-solid': { default: 'vh-conference-32' },
	'Cleaning-solid': { default: 'vh-cleaning-32' },
	'ChatsSupport-solid': { default: 'vh-chats-support-32' },
	'ChatsHouseholder2-solid': { default: 'vh-chats-householder-v2-32' },
	'ChatsHouseholder-solid': { default: 'vh-chats-householder-32' },
	'ChatsColiving-solid': { default: 'vh-chats-coliving-32' },
	'Auto-solid': { default: 'vh-auto-solid-32' },
	'DBAdvertising-filled': { default: 'vh-DBAdvertising-filled' },
	DBAgreement: { default: 'vh-DBAgreement_3x' },
	DBAirport: { default: 'vh-DBAirport' },
	DBAlarmClock: { default: 'vh-DBAlarmClock' },
	DBApproval: { default: 'vh-DBApproval' },
	DBBankcard: { default: 'vh-DBBankcard' },
	DBBarchart: { default: 'vh-DBBarchart' },
	DBBarcode: { default: 'vh-DBBarcode' },
	DBBeach: { default: 'vh-DBBeach' },
	DBBeginner: { default: 'vh-DBBeginner' },
	DBBinoculars: { default: 'vh-icons8-binoculars-500' },
	DBBookmark: { default: 'vh-icons8-bookmark-500' },
	DBBox: { default: 'vh-icons8-box-500' },
	DBBoxImportant: { default: 'vh-DBBoxImportant' },
	DBBriefcase: { default: 'vh-icons8-briefcase-500' },
	DBBulletCamera: { default: 'vh-DBBulletCamera' },
	DBBullets: { default: 'vh-DBBullets' },
	DBBusiness: { default: 'vh-DBBusiness' },
	DBCalendar: { default: 'vh-DBCalendar' },
	'DBCalls-filled': { default: 'vh-icons8-phone' },
	DBCalls: { default: 'vh-DBCalls' },
	DBCamera: { default: 'vh-icons8-compact_camera' },
	DBCategory: { default: 'vh-DBCategory' },
	'DBChats-filled': { default: 'vh-icons8-faq' },
	DBChats: { default: 'vh-DBChats' },
	DBCheckAll: { default: 'vh-icons8-check-all-500' },
	DBChecked: { default: 'vh-icons8-checked-500' },
	DBChip: { default: 'vh-DBChip' },
	DBChoice: { default: 'vh-DBChoice' },
	DBClock: { default: 'vh-icons8-clock-500' },
	DBCoins: { default: 'vh-DBCoins' },
	DBCollaboration: { default: 'vh-DBCollaboration' },
	DBCollapse: { default: 'vh-DBCollapse' },
	DBCollect: { default: 'vh-DBCollect' },
	DBComments: { default: 'vh-DBComments' },
	DBConference: { default: 'vh-DBConference' },
	DBConnect: { default: 'vh-icons8-connect-500' },
	DBContacts: { default: 'vh-DBContacts' },
	DBCreate: { default: 'vh-icons8-create' },
	DBCurrencyExchange: { default: 'vh-DBCurrencyExchange' },
	DBDecision: { default: 'vh-DBDecision' },
	DBDocumentary: { default: 'vh-DBDocumentary' },
	DBDownloadFromTheCloud: { default: 'vh-icons8-download-from-the-cloud-500' },
	DBEdit: { default: 'vh-icons8-edit-500' },
	DBEmpty: { default: 'vh-DBEmpty' },
	DBError: { default: 'vh-DBError' },
	DBEvents: { default: 'vh-DBEvents' },
	DBExample: { default: 'vh-icons8-example-500' },
	DBExchange: { default: 'vh-DBExchange' },
	DBExclamationMark: { default: 'vh-DBExclamationMark' },
	DBFaceID: { default: 'vh-DBFaceID' },
	DBFeed: { default: 'vh-DBFeed' },
	DBFile: { default: 'vh-DBFile' },
	DBFingerprint: { default: 'vh-DBFingerprint' },
	DBFolder: { default: 'vh-DBFolder' },
	DBGamepad: { default: 'vh-DBGamepad' },
	DBGood: { default: 'vh-DBGood' },
	DBHandshake: { default: 'vh-DBHandshake' },
	DBHD1080: { default: 'vh-DBHD1080' },
	DBHighImportance: { default: 'vh-DBHighImportance' },
	DBHome: { default: 'vh-DBHome' },
	DBIcons8: { default: 'vh-icons8-icons8-500' },
	DBIdea: { default: 'vh-icons8-idea-500' },
	DBImac: { default: 'vh-DBImac' },
	'DBImportant-filled': { default: 'vh-icons8-box_important' },
	DBInspection: { default: 'vh-DBInspection' },
	DBInstagram: { default: 'vh-icons8-instagram-500' },
	DBInstagramOld: { default: 'vh-icons8-instagram-old-500' },
	DBInTransit: { default: 'vh-DBInTransit' },
	DBInvoice: { default: 'vh-DBInvoice' },
	DBKey: { default: 'vh-icons8-key-500' },
	DBLeaderboard: { default: 'vh-DBLeaderboard' },
	DBLinechart: { default: 'vh-DBLinechart' },
	DBLink: { default: 'vh-DBLink' },
	DBLogistics: { default: 'vh-DBLogistics' },
	'DBManager-filled': { default: 'vh-icons8-manager' },
	DBManager: { default: 'vh-DBManager' },
	DBManual: { default: 'vh-DBManual' },
	DBMaterials: { default: 'vh-DBMaterials' },
	DBMedal: { default: 'vh-DBMedal' },
	DBMedia: { default: 'vh-DBMedia' },
	DBMicrophone: { default: 'vh-icons8-microphone' },
	DBMyspace: { default: 'vh-icons8-myspace-500' },
	DBMyspace3: { default: 'vh-icons8-myspace-3-500' },
	DBMyspaceApp: { default: 'vh-icons8-myspace-app-500' },
	DBNewProduct: { default: 'vh-DBNewProduct' },
	DBNews: { default: 'vh-DBNews' },
	DBOk: { default: 'vh-icons8-ok-500' },
	DBOpenedFolder: { default: 'vh-icons8-opened-folder-500' },
	DBParticipants: { default: 'vh-DBParticipants' },
	DBPen: { default: 'vh-DBPen' },
	DBPlus: { default: 'vh-icons8-plus' },
	DBProcess: { default: 'vh-DBProcess' },
	DBProjects: { default: 'vh-DBProjects' },
	DBPuzzle: { default: 'vh-icons8-puzzle-500' },
	DBQRCode: { default: 'vh-DBQRCode' },
	DBQuestionIn: { default: 'vh-DBQuestionIn' },
	DBQuestionMark: { default: 'vh-DBQuestionMark' },
	DBQuestionOut: { default: 'vh-DBQuestionOut' },
	DBQuestions: { default: 'vh-DBQuestions' },
	DBRaiting: { default: 'vh-DBRaiting' },
	DBRate: { default: 'vh-DBRate' },
	DBReading: { default: 'vh-DBReading' },
	DBReddit: { default: 'vh-icons8-reddit-500' },
	DBReport: { default: 'vh-DBReport' },
	DBRestart: { default: 'vh-icons8-restart-500' },
	DBRestaurant: { default: 'vh-DBRestaurant' },
	DBRoundPlus: { default: 'vh-icons8-plus-500' },
	DBSafe: { default: 'vh-DBSafe' },
	DBSearch: { default: 'vh-DBSearch' },
	DBSellProperty: { default: 'vh-DBSellProperty' },
	DBServices: { default: 'vh-DBServices' },
	DBSettings: { default: 'vh-DBSettings' },
	DBShare: { default: 'vh-icons8-share-500' },
	DBSign: { default: 'vh-DBSign' },
	DBSmileQuestion: { default: 'vh-DBSmileQuestion' },
	DBStatistics: { default: 'vh-DBStatistics' },
	DBStructure: { default: 'vh-DBStructure' },
	DBSun: { default: 'vh-icons8-sun-500' },
	DBSupport: { default: 'vh-DBSupport' },
	DBSwot: { default: 'vh-DBSwot' },
	DBSynchronize: { default: 'vh-icons8-synchronize-500' },
	DBTarget: { default: 'vh-DBTarget' },
	DBTasks: { default: 'vh-DBTasks' },
	DBThink: { default: 'vh-DBThink' },
	DBTickBox: { default: 'vh-icons8-tick-box-500' },
	DBTinder: { default: 'vh-icons8-tinder-500' },
	DBToolbox: { default: 'vh-icons8-toolbox-500' },
	DBTraining: { default: 'vh-DBTraining' },
	DBTreeStructure: { default: 'vh-DBTreeStructure' },
	DBTruefalse: { default: 'vh-DBTruefalse' },
	DBTwitter: { default: 'vh-icons8-twitter-500' },
	DBUncheckAll: { default: 'vh-icons8-uncheck-all-500' },
	DBVideoCall: { default: 'vh-icons8-video_call' },
	DBVip: { default: 'vh-DBVip' },
	DBWallet: { default: 'vh-DBWallet' },
	MailButton: { default: 'vh-mail-24' },
};

export interface IBlockData {
	tasks: any[];
	fullTasks?: any;
	id: number;
	type: string;
	title: string;
	url?: string;
	counter?: number;
	allCounter?: number;
	overdueCounter?: number;
	templateInfo?: any;
	templates: any[];
	taskId?: number;
	canCreate?: boolean;
	rules?: any;
}

export interface ITemplate {
	id: string;
	title?: string;
	align?: any;
	badgeItemColor?: string;
	fallBackTitle?: string;
	groups?: string;
	isDefault?: any;
	icon?: string;
	containerBackgroundColor?: string;
	itemColor?: string;
	selectedItemColor?: string;
	topTitle?: string;
	cellStyle?: string;
}

export interface IBlock {
	data?: IBlockData;
	action: ActionType;
	type: string;
	id: BlockId;
	template: ITemplate;
	blocks?: IBlock[];
	isEmergencyMailingBlock?: boolean;
}

export interface IMobileContainer {
	blocks: IBlock[];
	id: string;
	template: ITemplate;
}

export interface IMobileContainerResultDto {
	data: IMobileContainer[];
	total: number;
	aggregates?: any;
}

export interface ITaskSourceCriteria {
	sourceId: number;
	showLastComment?: boolean;
	take?: number;
	skip?: number;
	v?: string;
	extras?: {
		filters?: ITaskSourceFilter[];
		include?: string[];
	};
}

export interface ITaskSourceFilter {
	filterType: string;
	extParamValue?: any;
	extParamId?: number;
	filterOperation: string;
	sortType: string;
	filterValues: any[];
}
/**
 * Main Menu
 */

export interface IMobileMainMenuResultDto extends IMobileContainerResultDto {
	data: IMobileMainMenu[];
}

export interface IMainMenuBlock extends IBlock {
	template: IMainMenuTemplate;
	blocks?: IMainMenuBlock[];
	navigationId: number;
	navigationType: BlockId;
}

export interface IMobileMainMenu extends IMobileContainer {
	blocks: IMainMenuBlock[];
	template: IMainMenuTemplate;
}

export interface IMainMenuTemplate extends ITemplate {
	rightBarButtons: string;
}

export enum MainMenuButtonActions {
	openVKS = 'openVideoConferenceRoom',
	openCalendar = 'openCalendar',
}

export interface IMainMenuActionButton {
	action: MainMenuButtonActions;
	icon: string;
}

/**
 * Dashboards
 */

export interface IDashboardsResultDto extends IMobileContainerResultDto {
	data: IDashboard[];
}

export interface IDashboard extends IMobileContainer {
	blocks: IDashboardBlock[];
	template: IDashboardTemplate;
	type: string;
	buttons?: IDashboardButton[];
}

export interface IDashboardBlock extends IBlock {
	template: IDashboardTemplate;
	blocks: IDashboardBlock[];
}

export interface IFavouritesDashboardBlock {
	data: { title: string; id?: number; url?: string; canCreate: boolean; counter: number; color: string };
	blocks?: { data: { url: string; title: string; color: string }; id: string }[];
	type: FavouritesBlockTypes;
}

export enum FavouritesBlockTypes {
	Syndicate = 'Syndicate',
	Link = 'Link',
	PortalGrid = 'PortalGrid',
	None = 'None',
	Contact = 'Contact',
	Task = 'Task',
	Subcategory = 'Subcategory',
	Folder = 'Folder',
	Report = 'Report',
}

export interface IDashboardButton {
	href: string;
	title: string;
	order: number;
	stats?: { subcatId: number };
	typeId: FavouritesBlockTypes;
}

export interface IDashboardTemplate extends ITemplate {
	bgImage: string;
	bgImageURL: string;
	cellCounterFontSize: string;
	cellIconSize: string;
	cellTextFontSize: string;
	cellTitleFontSize: string;
	headerBackgroundColor: string;
	heightMultiplier: string;
	hideBlock: boolean;
	hideCounter: string;
	hideOnZeroCount: string;
	iconColor: string;
	idToRedirect: number;
	showIconCircle: string;
	showSeparators: string;
	separatorColor: string;
	showStyle: DashboardShowStyles;
	showTileBorderShadow: string;
	style: DashboardBlockStyles;
	textColor: string;
	tileCornerRadius: string;
	tileSpacing: string;
	titleColor: string;
	verticalGradientColor: string;
	widthRatio: string;
	lastGroupItem: boolean;
	urlPath: string;
	previewURL: string;
	isButton: string;
	phone: string;
	allowPreviewInteraction: string;
	disabled: string;
	onTapAlert: string;
	needConfirm: string;
	showSingleTask: string;
	showTaskOrCreate: string;
	showTaskOrNTF: string;
	subcatId: string;
	emptyContentTitle: string;
	context: string;
	dataSourceUrl: string;
	rightBarButtons: string;
	objectId?: string;
	URLPath?: string;
	titleExtParamId?: string;
	forbidCreateTask?: string;
}

export interface ITopBarDashboardButton {
	action: TopBarDashboardButtonAction;
	icon: string;
	dataSourceUrl: string;
	tickerAlias: string;
}

export enum TopBarDashboardButtonAction {
	openCallHistory = 'openCallHistory',
	openTaskSource = 'openTaskSource',
}

export enum DashboardShowStyles {
	background = 'background',
	expand = 'expand',
	push = 'push',
	swipe = 'swipe',
}

export enum DashboardBlockStyles {
	segment = 'segment',
	vertical = 'vertical',
}
