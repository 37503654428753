import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { spaDispatchEvent } from '@spa/api/events';
import { setCurrentInjector } from '@spa/common/injector-context';
import { Environment, environment } from '@spa/environment';
import 'hammerjs';
import { BootstrapModule } from './app/bootstrap/bootstrap.module';
import { PostBootstrapService } from './app/bootstrap/post-bootstrap.service';

async function bootstrapNgHost(env: Environment) {
	if (env.production) {
		enableProdMode();
	} else {
		setDevelopmentGlobalHelpers();
	}
	const ngModuleRef = await platformBrowserDynamic().bootstrapModule(BootstrapModule, {
		ngZoneEventCoalescing: true,
		ngZoneRunCoalescing: true,
	});
	setCurrentInjector(ngModuleRef.injector);
	const zone = ngModuleRef.injector.get(NgZone);
	zone.runTask(() => {
		const postBootstrapService = ngModuleRef.injector.get(PostBootstrapService);
		postBootstrapService.run();
	});
	zone.runTask(() => {
		spaDispatchEvent('init');
	});
	return ngModuleRef;
}

function startUpApplication(env: Environment) {
	// check for iframe inject
	(window as any).__checkXFrameTopHref = window.top.location.href;
	bootstrapNgHost(env)
		.then(() => {
			console.timeLog('loading', 'bootstrapNgHost');
		})
		.catch(err => console.error(err));
}

function setDevelopmentGlobalHelpers() {
	const ng = window['ng'] || {};
	if (ng) {
		ng.get = domEl => {
			if (ng.probe) {
				// view engine
				const debugEl = ng.probe(domEl);
				return debugEl && debugEl.componentInstance;
			} else if (ng.getComponent) {
				// ivy
				return ng.getComponent(domEl);
			}
		};
	}
}

Object.defineProperty(window, 'hammer', {
	get() {
		return window.Hammer;
	},
});

startUpApplication(environment);
