export enum ExtParamAccessMode {
	read = 'read',
	write = 'write',
	readWrite = 'read-write',
}

export enum ExtParamViewMode {
	read = 'read',
	write = 'write',
}
