import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class SplashScreenService {
	constructor(@Inject(DOCUMENT) private readonly _doc: Document, readonly rendererFactory: RendererFactory2) {}
	private get _splashEl(): HTMLElement {
		return this._doc.getElementById('vh-splash-screen');
	}

	private get _appRoot(): HTMLElement {
		return this._doc.getElementById('vh-root');
	}

	protected readonly renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);

	hide() {
		const splash = this._splashEl;
		if (splash) {
			this.renderer.setStyle(splash, 'display', 'none');
		}
		const root = this._appRoot;
		if (root) {
			this.renderer.removeStyle(root, 'display');
		}
		// eslint-disable-next-line no-restricted-syntax
		console.timeEnd('loading');
	}

	show() {
		const splash = this._splashEl;
		if (splash) {
			this.renderer.setStyle(splash, 'display', 'block');
		}
		const root = this._appRoot;
		if (root) {
			this.renderer.setStyle(root, 'display', 'none');
		}
	}
}
