<div class="header mat-elevation-z4 mat-primary-bg">
	<div *ngIf="selectedContact === null" class="title ml-16" (click)="unfoldSidebarTemporarily()">
		<mat-icon class="s-32 white-fg">chat</mat-icon>
		<h3 class="ml-12">Чаты</h3>
	</div>

	<div *ngIf="selectedContact !== null" class="title">
		<img [src]="selectedContact.avatar" class="avatar mx-16" />
		<h3 class="text-truncate">{{ selectedContact.name }}</h3>
	</div>

	<button class="toggle-sidebar-folded mr-8 d-none d-block-md" (click)="foldSidebarTemporarily(); resetChat()">
		<mat-icon class="secondary-text s-20">close</mat-icon>
	</button>

	<button class="toggle-sidebar-open mr-8 d-block d-none-md" (click)="toggleSidebarOpen(); resetChat()">
		<mat-icon class="secondary-text">close</mat-icon>
	</button>
</div>

<div class="content">
	<!-- Contacts -->
	<div id="contacts-list" vhPerfectScrollbar [vhPerfectScrollbarOptions]="{ suppressScrollX: true }">
		<div
			*ngFor="let contact of contacts"
			class="contacts-list-item"
			[ngClass]="contact.status"
			[class.active]="contact.id === selectedContact?.id"
			(click)="toggleChat(contact)"
		>
			<img class="avatar" [src]="contact.avatar" [tippy]="contact.name" placement="left" />
			<div class="unread-count" *ngIf="contact.unread">{{ contact.unread }}</div>
			<div class="status-icon" [ngClass]="contact.status"></div>
		</div>
	</div>
	<!-- / Contacts -->

	<!-- Chat -->
	<div id="chat">
		<div id="messages" class="messages" vhPerfectScrollbar>
			<ng-container *ngIf="chat && chat.dialog && chat.dialog.length > 0">
				<div
					*ngFor="let message of chat.dialog; let i = index"
					class="message-row"
					[ngClass]="{
						me: message.who === user.id,
						contact: message.who !== user.id,
						'first-of-group': isFirstMessageOfGroup(message, i),
						'last-of-group': isLastMessageOfGroup(message, i)
					}"
				>
					<img *ngIf="shouldShowContactAvatar(message, i)" src="{{ selectedContact.avatar }}" class="avatar" />

					<div class="bubble">
						<div class="message">{{ message.message }}</div>
						<div class="time secondary-text">{{ message.time | date: 'short' }}</div>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="selectedContact && chat && chat.dialog && chat.dialog.length === 0">
				<div class="no-messages-icon">
					<mat-icon class="s-128">chat</mat-icon>
				</div>

				<div class="no-messages secondary-text">Начать диалог</div>
			</ng-container>

			<ng-container *ngIf="!selectedContact">
				<div class="no-contact-selected">
					<div class="no-contact-icon">
						<mat-icon class="s-128">chat</mat-icon>
					</div>

					<div class="no-contact secondary-text">Выберите контакт, чтобы начать диалог</div>
				</div>
			</ng-container>
		</div>

		<div class="reply-form" *ngIf="selectedContact">
			<form #replyForm="ngForm" (ngSubmit)="reply($event)" (keydown.enter)="reply($event)">
				<mat-form-field class="message-text" floatLabel="never" appearance="standard">
					<textarea
						matInput
						#replyInput
						ngModel
						name="message"
						placeholder="Введите сообщение"
						[rows]="1"
						[matTextareaAutosize]="true"
					></textarea>
				</mat-form-field>

				<button class="send-message-button" type="submit" aria-label="Send message">
					<mat-icon class="secondary-text">send</mat-icon>
				</button>
			</form>
		</div>
	</div>
	<!-- / Chat -->
</div>
