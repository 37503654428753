import { ISystemTickersDto, TickerType, ISystemTickersDto2, TickerSystemType } from '@valhalla/data/entities';

import { SystemTicker } from '../ticker-type.enum';
import { ITicker } from '../ticker.model';
import { chatTickerStateFactory } from './state';

export function mapSystemTickersToRecord(
	systemTickerDto: Partial<ISystemTickersDto | ISystemTickersDto2>,
	tickerNames?
): Record<string, ITicker> {
	const dto = systemTickerDto as Partial<ISystemTickersDto>;
	const dto2 = systemTickerDto as Partial<ISystemTickersDto2>;

	const notUndefined = (...vars: any[]) => {
		return vars.find(i => typeof i !== 'undefined');
	};

	const recordTickers: Record<number | string, ITicker> = {
		[`system/${SystemTicker.directorSignaturesCount}`]: {
			id: `system/${SystemTicker.directorSignaturesCount}`,
			available: dto2.availableTypes?.includes(TickerSystemType.signatures),
			type: TickerType.system,
			name: SystemTicker.directorSignaturesCount,
			title: 'Подпись руководителя',
			icon: {
				name: 'playlist_add_check',
			},
			value: notUndefined(dto.DirectorSignaturesCount, dto2.directorSignaturesCount),
			order: 7,
		},
		[`system/${SystemTicker.hasChatInvites}`]: {
			id: `system/${SystemTicker.hasChatInvites}`,
			available: true,
			type: TickerType.system,
			name: SystemTicker.hasChatInvites,
			title: 'HasChatInvites',
			icon: {
				name: '',
			},
			value: dto.HasChatInvites,
			order: 8,
		},
		[`system/${SystemTicker.milestones}`]: {
			id: `system/${SystemTicker.milestones}`,
			available: dto2.availableTypes?.includes(TickerSystemType.milestones),
			type: TickerType.system,
			name: SystemTicker.milestones,
			title: 'Повестка дня',
			icon: {
				name: 'access_alarm',
			},
			value: notUndefined(dto.Milestones, dto2.milestones),
			order: 6,
		},
		[`system/${SystemTicker.missedCalls}`]: {
			id: `system/${SystemTicker.missedCalls}`,
			available: dto2.availableTypes?.includes(TickerSystemType.missedCalls),
			type: TickerType.system,
			name: SystemTicker.missedCalls,
			title: 'Пропущенные звонки',
			icon: {
				name: 'phone_missed',
			},
			value: notUndefined(dto.MissedCalls, dto2.missedCalls),
			order: 0,
		},
		[`system/${SystemTicker.overDueTasksCount}`]: {
			id: `system/${SystemTicker.overDueTasksCount}`,
			available: dto2.availableTypes?.includes(TickerSystemType.overdue),
			type: TickerType.system,
			name: SystemTicker.overDueTasksCount,
			title: (tickerNames && tickerNames?.['overDueTasksCount']) || 'Просроченные задачи',
			icon: {
				name: 'vh-tasks-warning-24',
				isFormaIcon: true,
			},
			value: notUndefined(dto.OverDueTasksCount, dto2.overDueTasksCount),
			order: 1,
		},
		[`system/${SystemTicker.overdueSigns}`]: {
			id: `system/${SystemTicker.overdueSigns}`,
			available: dto2.availableTypes?.includes(TickerSystemType.overdue),
			type: TickerType.system,
			name: SystemTicker.overdueSigns,
			title: 'Просроченные подписи',
			icon: {
				name: 'gesture',
			},
			value: notUndefined(dto.OverdueSigns, dto2.overdueSigns),
			order: 4,
		},
		[`system/${SystemTicker.questionsCount}`]: {
			id: `system/${SystemTicker.questionsCount}`,
			available: dto2.availableTypes?.includes(TickerSystemType.question),
			type: TickerType.system,
			name: SystemTicker.questionsCount,
			title: (tickerNames && tickerNames?.['questionsCount']) || 'Вопросы мне',
			icon: {
				name: 'vh-question-24',
				isFormaIcon: true,
			},
			value: notUndefined(dto.QuestionsCount, dto2.questionsCount),
			order: 5,
		},
		[`system/${SystemTicker.myQuestionsCount}`]: {
			id: `system/${SystemTicker.myQuestionsCount}`,
			available: dto2.availableTypes?.includes(TickerSystemType.myQuestions),
			type: TickerType.system,
			name: SystemTicker.myQuestionsCount,
			title: 'Мои вопросы',
			icon: {
				name: 'contact_support',
			},
			value: dto2.myQuestionsCount,
			order: -1,
		},
		[`system/${SystemTicker.signaturesCount}`]: {
			id: `system/${SystemTicker.signaturesCount}`,
			available: dto2.availableTypes?.includes(TickerSystemType.signatures),
			type: TickerType.system,
			name: SystemTicker.signaturesCount,
			title: (tickerNames && tickerNames?.['signaturesCount']) || 'Ожидает подписи',
			icon: {
				name: 'vh-sign-request-24',
				isFormaIcon: true,
			},
			value: notUndefined(dto.SignaturesCount, dto2.signaturesCount),
			order: 3,
		},
		[`system/${SystemTicker.unreadCommentsCount}`]: {
			id: `system/${SystemTicker.unreadCommentsCount}`,
			available: dto2.availableTypes.includes(TickerSystemType.unread),
			type: TickerType.system,
			name: SystemTicker.unreadCommentsCount,
			title: (tickerNames && tickerNames?.['unreadCommentsCount']) || 'Непрочитанные комментарии',
			icon: {
				name: 'vh-mail-unread-24',
				isFormaIcon: true,
			},
			value: notUndefined(dto.UnreadCommentsCount, dto2.unreadCommentsCount),
			order: 1,
		},
		[`system/${SystemTicker.wasOffline}`]: {
			id: `system/${SystemTicker.wasOffline}`,
			available: true,
			type: TickerType.system,
			name: SystemTicker.wasOffline,
			title: 'WasOffline',
			icon: null,
			value: dto.WasOffline,
			order: 9,
		},
		[`system/${SystemTicker.favoriteComments}`]: {
			id: `system/${SystemTicker.favoriteComments}`,
			available: dto2.availableTypes?.includes(TickerSystemType.favoriteComments),
			type: TickerType.system,
			name: SystemTicker.favoriteComments,
			title: (tickerNames && tickerNames?.['favoriteComments']) || 'Избранные комментарии',
			icon: {
				name: 'vh-chats-fav-24',
				isFormaIcon: true,
			},
			value: notUndefined(dto.favoriteComments, dto2.favoriteComments, 0),
			order: 1,
		},
		[`system/${SystemTicker.chatUnreadMessageCount}`]: {
			id: `system/${SystemTicker.chatUnreadMessageCount}`,
			available: dto2.availableTypes?.includes(TickerSystemType.chats),
			type: TickerType.system,
			name: SystemTicker.chatUnreadMessageCount,
			title: 'Чаты',
			icon: {
				name: 'fa-comments-o',
				fontSet: 'fa',
			},
			value: notUndefined(dto.UnreadChatCommentsCount, dto2.unreadChatCommentsCount, 0),
			order: 1,
		},
		/**
		 * newTasks = 'NewTasks',
			overdueNoPerformers = 'OverdueNoPerformers',
			overdueSignatures = 'OverdueSignatures',
			todayTasks = 'TodayTasks',
			totalTasks = 'TotalTasks',
			totalTasksAssistant = 'TotalTasksAssistant',
		 */
		[`system/${SystemTicker.overdueSignatures}`]: {
			id: `system/${SystemTicker.overdueSignatures}`,
			available: dto2.availableTypes?.includes(TickerSystemType.overdueSignatures),
			type: TickerType.system,
			name: SystemTicker.overdueSignatures,
			title: tickerNames?.[SystemTicker.overdueSignatures],
			icon: {
				name: 'vh-sign-journal-24',
				isFormaIcon: true,
			},
			value: notUndefined(dto.overdueSignatures, dto2.overdueSignatures, 0),
			order: 10,
		},
		[`system/${SystemTicker.newTasks}`]: {
			id: `system/${SystemTicker.newTasks}`,
			available: dto2.availableTypes?.includes(TickerSystemType.newTasks),
			type: TickerType.system,
			name: SystemTicker.newTasks,
			title: tickerNames?.[SystemTicker.newTasks],
			icon: {
				name: 'vh-tasks-subtasks-add-24',
				isFormaIcon: true,
			},
			value: notUndefined(dto.newTasks, dto2.newTasks, 0),
			order: 10,
		},
		[`system/${SystemTicker.todayTasks}`]: {
			id: `system/${SystemTicker.todayTasks}`,
			available: dto2.availableTypes?.includes(TickerSystemType.todayTasks),
			type: TickerType.system,
			name: SystemTicker.todayTasks,
			title: tickerNames?.[SystemTicker.todayTasks],
			icon: {
				name: 'vh-clock-24',
				isFormaIcon: true,
			},
			value: notUndefined(dto.todayTasks, dto2.todayTasks, 0),
			order: 10,
		},
		[`system/${SystemTicker.totalTasks}`]: {
			id: `system/${SystemTicker.totalTasks}`,
			available: dto2.availableTypes?.includes(TickerSystemType.totalTasks),
			type: TickerType.system,
			name: SystemTicker.totalTasks,
			title: tickerNames?.[SystemTicker.totalTasks],
			icon: {
				name: 'vh-subtasks-collection-24',
				isFormaIcon: true,
			},
			value: notUndefined(dto.totalTasks, dto2.totalTasks, 0),
			order: 10,
		},
		[`system/${SystemTicker.totalTasksAssistant}`]: {
			id: `system/${SystemTicker.totalTasksAssistant}`,
			available: dto2.availableTypes?.includes(TickerSystemType.totalTasksAssistant),
			type: TickerType.system,
			name: SystemTicker.totalTasksAssistant,
			title: tickerNames?.[SystemTicker.totalTasksAssistant],
			icon: {
				name: 'vh-Participants-24',
				isFormaIcon: true,
			},
			value: notUndefined(dto.totalTasksAssistant, dto2.totalTasksAssistant, 0),
			order: 10,
		},
		[`system/${SystemTicker.overdueNoPerformers}`]: {
			id: `system/${SystemTicker.overdueNoPerformers}`,
			available: dto2.availableTypes?.includes(TickerSystemType.overdueNoPerformers),
			type: TickerType.system,
			name: SystemTicker.overdueNoPerformers,
			title: tickerNames?.[SystemTicker.overdueNoPerformers],
			icon: {
				name: 'vh-personal-remove-24',
				isFormaIcon: true,
			},
			value: notUndefined(dto.overdueNoPerformers, dto2.overdueNoPerformers, 0),
			order: 10,
		},
	};
	return recordTickers;
}
