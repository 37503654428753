import { ModuleWithProviders, NgModule } from '@angular/core';

import { AppVersionService, CheckVersionGuard } from './abstract';
import { AppVersionServiceImpl } from './app-version.service';
import { CheckVersionGuardImpl } from './check-version.guard';

@NgModule()
export class VersionModule {
	static forRoot(): ModuleWithProviders<VersionModule> {
		return {
			ngModule: VersionModule,
			providers: [
				{
					provide: AppVersionService,
					useClass: AppVersionServiceImpl,
				},
				{
					provide: CheckVersionGuard,
					useClass: CheckVersionGuardImpl,
				},
			],
		};
	}
}
