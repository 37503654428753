<ng-container *ngIf="!menuItem.hidden">
	<!-- execFunc -->
	<div
		*ngIf="menuItem.actionType === actionTypes.execFunc"
		class="nav-link"
		[class.active]="isRouterActiveItem()"
		[class.is-mobile]="isMobile"
		[ngClass]="menuItem.classes"
		(click)="prevent($event); onItemClick(menuItem)"
		matRipple
		[vhContextMenu]="{
			menu: contextMenu,
			data: {
				item: menuItem
			}
		}"
	>
		<ng-container *ngTemplateOutlet="itemContent"></ng-container>
	</div>

	<!-- url -->
	<div
		*ngIf="menuItem.actionType === actionTypes.openUrl"
		class="nav-link"
		[ngClass]="menuItem.classes"
		[class.is-mobile]="isMobile"
		(click)="prevent($event); onItemClick(menuItem)"
		[routerLink]="[menuItem.url]"
		[routerLinkActive]="['active', 'accent']"
		[routerLinkActiveOptions]="{ exact: menuItem.exactMatch || false }"
		[target]="menuItem.openInNewTab ? '_blank' : '_self'"
		matRipple
		[vhContextMenu]="{
			menu: contextMenu,
			data: {
				item: menuItem
			}
		}"
	>
		<ng-container *ngTemplateOutlet="itemContent"></ng-container>
	</div>

	<!-- externalUrl -->
	<div
		*ngIf="menuItem.actionType === actionTypes.openExternalUrl"
		class="nav-link"
		[ngClass]="menuItem.classes"
		(click)="prevent($event); onItemClick(menuItem)"
		[href]="menuItem.url"
		[target]="menuItem.openInNewTab ? '_blank' : '_self'"
		matRipple
		[vhContextMenu]="{
			menu: contextMenu,
			data: {
				item: menuItem
			}
		}"
	>
		<ng-container *ngTemplateOutlet="itemContent"></ng-container>
	</div>

	<div class="children" [@slideInOut]="isOpen">
		<ng-container *ngFor="let childMenuItem of children; trackBy: trackById">
			<vh-common-nav-vertical-item
				*ngIf="childMenuItem.menuType === menuTypes.item"
				[vhContextMenu]="{
					menu: contextMenu,
					data: {
						item: childMenuItem
					}
				}"
				[contextMenu]="contextMenu"
				(menuItemClick)="onItemClick($event)"
				[menuItem]="childMenuItem"
				[attr.data-id]="childMenuItem.id"
			></vh-common-nav-vertical-item>
			<vh-common-nav-vertical-collapsable
				*ngIf="childMenuItem.menuType === menuTypes.collapsible"
				[vhContextMenu]="{
					menu: contextMenu,
					data: {
						item: childMenuItem
					}
				}"
				[contextMenu]="contextMenu"
				(menuItemClick)="onItemClick($event)"
				[isOpen]="!!childMenuItem.isOpen"
				[menuItem]="childMenuItem"
				[attr.data-id]="childMenuItem.id"
			></vh-common-nav-vertical-collapsable>
			<vh-common-nav-vertical-group
				*ngIf="childMenuItem.menuType === menuTypes.group"
				[contextMenu]="contextMenu"
				(menuItemClick)="onItemClick($event)"
				[menuItem]="childMenuItem"
				[attr.data-id]="childMenuItem.id"
			></vh-common-nav-vertical-group>
		</ng-container>
	</div>
</ng-container>

<ng-template #itemContent>
	<mat-icon class="collapsable-arrow-icon" svgIcon="vh-arrow-v1-S-right-24"></mat-icon>

	<span class="nav-link-title">{{ menuItem.title }}</span>

	<ng-container *ngIf="menuItem.badges">
		<ng-container *ngFor="let badge of menuItem.badges; trackBy: trackByBadgeValue">
			<span
				*ngIf="badge.value"
				class="nav-link-badge"
				[attr.matBg]="badge.matBg"
				[attr.matFg]="badge.matFg"
				[title]="badge.title"
				[ngStyle]="{ 'background-color': badge.bg, color: badge.fg }"
			>
				{{ badge.value }}
			</span>
		</ng-container>
	</ng-container>
</ng-template>
