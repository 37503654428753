import { ExtParamBase } from './ext-param-base';

export class ExtParamUrl extends ExtParamBase {
	equalsValue(a: any, b: any) {
		if (!a && !b) {
			return true;
		}

		return a === b;
	}
}
