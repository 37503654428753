/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { InputSubject } from '@valhalla/utils';
import { BehaviorSubject, map, startWith, takeUntil } from 'rxjs';
import { ViewDestroyStreamService } from '@spa/core';

import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { MenuItemType } from '@spa/common/components/navigation';

import { ActivatedRoute, Router } from '@angular/router';
import { NavPanelEmailService } from '../nav-panel-email.service';
import { IBadgeColor } from 'libs/core-components/src/lib/badge/badge.model';
import { CultureService } from '@spa/localization';
import { ModalWindowsService } from '@spa/facade/features/modals';

@Component({
	selector: 'vh-nav-panel-email-item',
	templateUrl: './nav-panel-email-item.component.html',
	styleUrls: ['./nav-panel-email-item.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class NavPanelEmailItemComponent implements OnInit {
	constructor(
		readonly destroy$: ViewDestroyStreamService,
		readonly mobileService: MobileViewService,
		readonly route: ActivatedRoute,
		readonly router: Router,
		readonly navPanelEmailService: NavPanelEmailService,
		readonly culture: CultureService,
		readonly modal: ModalWindowsService
	) {}

	@Input()
	@InputSubject()
	item: INavPanelEmailItem;
	item$!: BehaviorSubject<INavPanelEmailItem>;

	@Input()
	mailBoxId: number;

	@Input()
	contextMenu = null;

	@Output()
	clickItem = new EventEmitter<any>();

	@Output()
	clickExpandCollapse = new EventEmitter<any>();

	@Output()
	clickEditMailbox = new EventEmitter<any>();

	@HostBinding('class.vh-nav-panel-email-item')
	hostClassSelector = true;

	readonly menuTypes = MenuItemType;

	readonly isActiveRoute$ = this.navPanelEmailService.activeRouteParams$.pipe(
		map(res => {
			const routeFolderPath = (res as any)?.folderPath;
			const routeMailBoxId = (res as any)?.mailBoxId;
			return (
				routeMailBoxId === this.item?.mailBoxId &&
				routeFolderPath === this.item.serverPath &&
				!(this.item.serverPath === undefined)
			);
		})
	);

	readonly badgeColor = IBadgeColor;
	readonly locale$ = this.culture.activeCulture$;

	ngOnInit() {}

	onClickItem({ e, item }) {
		if (item?.isMailbox) {
			this.item.isOpen = !this.item.isOpen;
			this.clickExpandCollapse.emit(item);
			return;
		}

		return this.clickItem.emit({ e, item });
	}

	openEditMailboxDialog(item) {
		this.clickEditMailbox.emit(item);
	}

	getCounterValue(item: INavPanelEmailItem) {
		const folderName = item?.folderName?.toLocaleLowerCase();

		if (item?.isMailbox) {
			return null; //item?.inboxUnreadCount;
		}

		//const folderIncomming = this.getSystemFodlerByName('Входящие');
		const folderDrafts = this.getSystemFodlerByName('Черновики');
		const folderSent = this.getSystemFodlerByName('Отправленные');
		const folderDeleted = this.getSystemFodlerByName('Удаленные');
		const folderArchive = this.getSystemFodlerByName('Архив');
		const folderSpam = this.getSystemFodlerByName('Спам');

		if (
			folderSent?.keys?.includes(folderName) ||
			folderArchive?.keys?.includes(folderName) ||
			folderSpam?.keys?.includes(folderName)
		) {
			return null;
		}

		if (folderDeleted?.keys?.includes(folderName)) {
			return item?.unreadCount;
		}

		if (folderDrafts?.keys?.includes(folderName)) {
			return item?.messageCount;
		}

		return item?.unreadCount;
	}

	getSystemFodlerByName(folderName) {
		return this.navPanelEmailService.systemFolders.find(f => f?.folderName === folderName);
	}

	expandCollapse(event, item: INavPanelEmailItem) {
		event.stopPropagation();
		this.item.isOpen = !this.item.isOpen;
		this.clickExpandCollapse.emit(item);
	}

	isChildren(item: INavPanelEmailItem) {
		return item?.children?.length;
	}
}

export interface INavPanelEmailItem {
	name: string;
	children: any[];
	folderName?: string;
	isMailbox?: boolean;
	isDisabled?: boolean;
	unreadCount?: number;
	isOpen?: boolean;
	serverPath?: string;
	mailBoxId?: number;
	messageCount?: number;
	inboxUnreadCount?: number;
	isError?: boolean;
}
