import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

import { SearchOverlayComponent } from './search-overlay.component';
import { SearchOverlayService } from './search-overlay.service';
import { RouterModule } from '@angular/router';
import { LocalizationModule } from '@valhalla/localization';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';
import { AvatarModule } from '@spa/common/components/chat-nav-avatar';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';

@NgModule({
	imports: [
		CommonModule,
		OverlayModule,
		MatIconModule,
		MatInputModule,
		MatProgressBarModule,
		MatListModule,
		MatDividerModule,
		AvatarModule,
		RouterModule,
		LocalizationModule,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
	],
	exports: [SearchOverlayComponent],
	declarations: [SearchOverlayComponent],
	providers: [SearchOverlayService],
})
export class SearchOverlayModule {}
