/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, Component, Input, HostBinding, ViewEncapsulation } from '@angular/core';
import {
	IPointIndicatorColor,
	IPointIndicatorColorMap,
	PointIndicatorSize,
	PointIndicatorSizeMap,
} from './point-indicator.model';

@Component({
	selector: 'vh-point-indicator',
	templateUrl: './point-indicator.component.html',
	styleUrls: ['./point-indicator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class PointIndicatorComponent {
	@Input()
	set color(value: IPointIndicatorColor) {
		this.colorFromMap = IPointIndicatorColorMap[value];
	}

	@Input()
	set size(value: PointIndicatorSize) {
		this._size = PointIndicatorSizeMap[value];
	}

	@HostBinding('class.vh-point-indicator')
	hostClassSelector = true;

	@HostBinding('style.background-color')
	colorFromMap: string = IPointIndicatorColorMap.basePrimary;

	@HostBinding('style.--point-indicator-size')
	_size: string;
}
