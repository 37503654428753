import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreenService } from '@spa/common/services/splash-screen.service';
import { ProgressBarCommonComponent } from '@valhalla/common/components/progress-bar';
import { AbstractLogger, LoggerFactory, UrlProvider } from '@valhalla/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavigationFeatureProvider, NavigationMenu } from './features/navigation';
import { LayoutFacade } from './layout';

@Injectable()
export class FacadeViewService implements OnDestroy {
	constructor(
		logger: LoggerFactory,
		private readonly _layout: LayoutFacade,
		readonly navigationFeature: NavigationFeatureProvider,
		public readonly splash: SplashScreenService,
		readonly url: UrlProvider,
		readonly router: Router
	) {
		this._logger = logger.createLogger('UiFacadeViewService');
	}
	private _destroy$: Subject<any> = new Subject();
	private _logger: AbstractLogger;
	private _progressBarComponent: ProgressBarCommonComponent;

	readonly layoutState$ = this._layout.state$.pipe(takeUntil(this._destroy$));

	// FIXME: какая-то фигня, надо делать отдельный сервис упраления навигацией с привязкой к навигационному компоненту по Id
	loadNavigation() {
		this.navigationFeature.load([
			NavigationMenu.favorites,
			NavigationMenu.categories,
			NavigationMenu.favoritesNew,
			NavigationMenu.assistantFavorite,
		]);
	}

	turnOnPeriodicUpdateFavoriteCounters() {
		this.navigationFeature.turnOnPeriodicUpdateFavoriteCounters();
	}

	turnOnPeriodicUpdateFavoritesMenu(menus) {
		this.navigationFeature.turnOnPeriodicFavoritesMenuUpdate(menus);
	}

	// FIXME: создать API service который предоставляет доступ к методам каркаса
	openLink(link: string) {
		const url = this.url.getUrl(link);
		this.router.navigate(['/link'], {
			queryParams: {
				url,
			},
		});
	}

	setLoaderRef(progressBarComponent: ProgressBarCommonComponent) {
		this._progressBarComponent = progressBarComponent;
	}

	showProgressBar() {
		if (this._progressBarComponent) {
			this._progressBarComponent.progress.show();
		}
	}

	hideProgressBar() {
		if (this._progressBarComponent) {
			this._progressBarComponent.progress.hide();
		}
	}

	ngOnDestroy() {
		this._destroy$.next(0 as any);
		this._destroy$.complete();
	}
}
