import { merge } from '@valhalla/utils';
import { IAction, ReducerBase } from '../../store';

import * as actions from './actions';
import { IConfigurationModel } from './state';

export class UpdateConfigurationReducer implements ReducerBase {
	readonly actionType = actions.CoreConfigurationAction.updateConfiguration;

	reduce(state: Readonly<IConfigurationModel>, action: IAction<Partial<IConfigurationModel>>) {
		if (!action.payload) {
			return state;
		}
		return merge(state, action.payload);
	}
}

export const reducers = [UpdateConfigurationReducer];
