import { RecursivePartial } from '@valhalla/utils';
import { Observable } from 'rxjs';

export abstract class LocalStorageProvider {
	abstract length: number;
	abstract keys: string[];
	abstract set<T = any>(key: string, value: T, asyncEventEmit?: boolean): LocalStorageProvider;
	abstract get<T = any>(key: string): T;
	abstract select<T = any>(key: string): Observable<T>;
	abstract clear();
	abstract remove(...keys: string[]);
	abstract destroy();
	abstract patchValue<T = any>(key: string, value: RecursivePartial<T>): T;
}
