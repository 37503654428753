// tslint:disable:no-console
import { helper } from './helper';
import { SpaCommandExecutorFn } from './spa-command.contract';
import { Observable } from 'rxjs';

const { log, warn } = helper.logger;

const executors = new Map<string, SpaCommandExecutorFn>();

export function clearCommands() {
	executors.clear();
}

export function registerCommand(name: string, executor: SpaCommandExecutorFn) {
	name = name.toLowerCase();
	executors.set(name, executor);
}

export function unregisterCommand(name: string) {
	name = name.toLowerCase();
	executors.delete(name);
}

export function spaCommand<T = any>(name: string, payload?: any) {
	return new Promise<T | void>(async (res, rej) => {
		try {
			log(name, payload);

			name = name.toLowerCase();

			const executor = executors.get(name);

			if (typeof executor !== 'function') {
				warn('Invalid command, executor is not a function!');
				return res();
			}

			let result = executor(payload);

			if (result instanceof Promise) {
				result = await result;
			}

			if (result instanceof Observable) {
				result = await result.toPromise();
			}

			res(result as any);
		} catch (err) {
			rej(err);
		}
	});
}
