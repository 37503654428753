<div class="vh-common-sidebar-content">
	<div class="vh-common-sidebar-content--search">
		<vh-sidebar-header
			*ngIf="searchable"
			[title]="'common.sideBarCategory' | resx"
			(searchInput)="searchHandler($event.target.value)"
			(searchClear)="searchHandler('')"
		>
		</vh-sidebar-header>
	</div>
	<div
		class="nav"
		[class.horizontal]="layout === layoutTypes.horizontal"
		[class.vertical]="layout === layoutTypes.vertical"
		[style.display]="(searchInputHasValue$ | async) ? 'block' : 'none'"
	>
		<ng-container *ngIf="layout === layoutTypes.vertical">
			<ng-container *ngFor="let item of navigationBySearch$ | async; trackBy: trackById">
				<vh-common-nav-vertical-group
					*ngIf="item.menuType === menuTypes.group"
					[contextMenu]="categoriesContextMenu?.matMenu"
					(menuItemClick)="onNavigationClick($event)"
					[menuItem]="item"
					[attr.data-id]="item.id"
				></vh-common-nav-vertical-group>

				<vh-common-nav-vertical-collapsable
					*ngIf="item.menuType === menuTypes.collapsible"
					[contextMenu]="categoriesContextMenu?.matMenu"
					(menuItemClick)="onNavigationClick($event, true)"
					[menuItem]="item"
					[isOpen]="!!item.isOpen"
					[attr.data-id]="item.id"
				></vh-common-nav-vertical-collapsable>

				<vh-common-nav-vertical-item
					*ngIf="item.menuType === menuTypes.item"
					[contextMenu]="categoriesContextMenu?.matMenu"
					(menuItemClick)="onNavigationClick($event)"
					[menuItem]="item"
					[attr.data-id]="item.id"
				></vh-common-nav-vertical-item>
			</ng-container>
		</ng-container>
	</div>

	<div
		class="nav nav-items"
		[class.horizontal]="layout === layoutTypes.horizontal"
		[class.vertical]="layout === layoutTypes.vertical"
		[style.display]="(searchInputHasValue$ | async) ? 'none' : 'block'"
	>
		<ng-container *ngIf="layout === layoutTypes.vertical">
			<ng-container *ngFor="let item of navigation$ | async; trackBy: trackById">
				<vh-common-nav-vertical-group
					*ngIf="item.menuType === menuTypes.group"
					[contextMenu]="categoriesContextMenu?.matMenu"
					(menuItemClick)="onNavigationClick($event)"
					[menuItem]="item"
					[attr.data-id]="item.id"
				></vh-common-nav-vertical-group>

				<vh-common-nav-vertical-collapsable
					*ngIf="item.menuType === menuTypes.collapsible"
					[contextMenu]="categoriesContextMenu?.matMenu"
					(menuItemClick)="onNavigationClick($event)"
					[menuItem]="item"
					[isOpen]="item.isOpen"
					[attr.data-id]="item.id"
				></vh-common-nav-vertical-collapsable>

				<vh-common-nav-vertical-item
					*ngIf="item.menuType === menuTypes.item"
					[contextMenu]="categoriesContextMenu?.matMenu"
					(menuItemClick)="onNavigationClick($event)"
					[menuItem]="item"
					[attr.data-id]="item.id"
				></vh-common-nav-vertical-item>
			</ng-container>
		</ng-container>
	</div>
</div>

<!-- <mat-menu #categoriesContextMenu="matMenu">
	<ng-template matMenuContent let-item="item">
		<ng-container *ngIf="item.nodeType === 'Subcategory'">
			<button *ngIf="item.canCreateTask" (click)="createTask(item, $event)" mat-menu-item>
				<mat-icon svgIcon="vh-tasks-add-24"></mat-icon>
				<span>{{ 'common.contextCreate' | resx }}</span>
			</button>
		</ng-container>
		<button mat-menu-item [matMenuTriggerFor]="addToFavoritesItems" [matMenuTriggerData]="{ data: { item: item } }">
			<mat-icon svgIcon="vh-favourites-24"></mat-icon>
			<span>{{ 'common.сontextToFavorites' | resx }}</span>
		</button>
		<button mat-menu-item [matMenuTriggerFor]="openAsItems" [matMenuTriggerData]="{ item }">
			<mat-icon svgIcon="vh-grid-24"></mat-icon>
			<span>{{ 'common.openAs' | resx }}</span>
		</button>
		<ng-container *ngIf="item.nodeType === 'Subcategory'">
			<button
				*ngIf="item?.$id | smartExpressionsSelection | async as smartExpressions"
				mat-menu-item
				[matMenuTriggerFor]="openSelectionItems"
				[matMenuTriggerData]="{ data: { items: smartExpressions, subcatId: item.$id } }"
			>
				<mat-icon svgIcon="vh-filter-v2-24"></mat-icon>
				<span>{{ 'common.openSelection' | resx }}</span>
			</button>
		</ng-container>
		<div *ngIf="item.canEdit" class="subcat-menu-separator"></div>
		<button *ngIf="item.canEdit" (click)="openCategorySettings(item)" mat-menu-item>
			<mat-icon svgIcon="vh-Settings-v2-24"></mat-icon>
			<span>{{ 'common.contextSettingCategory' | resx }}</span>
		</button>
		<button *ngIf="item.canEdit" (click)="openCategorySettings(item, true)" mat-menu-item>
			<mat-icon svgIcon="vh-Settings-v2-24"></mat-icon>
			<span>{{ 'common.contextSettingCategorySPA' | resx }}</span>
		</button>
	</ng-template>
</mat-menu>

<mat-menu #addToFavoritesItems="matMenu">
	<ng-template matMenuContent let-data="data">
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Feeds')"
			(click)="addItemToFavorites(data.item, 'feed')"
			mat-menu-item
		>
			{{ 'common.contextFeed' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Grid')"
			(click)="addItemToFavorites(data.item, 'grid')"
			mat-menu-item
		>
			{{ 'common.contextGrid' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Calendar')"
			(click)="addItemToFavorites(data.item, 'calendar')"
			mat-menu-item
		>
			{{ 'common.сontextCalendar' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Gantt')"
			(click)="addItemToFavorites(data.item, 'gantt')"
			mat-menu-item
		>
			{{ 'common.сontextGantt' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Kanban')"
			(click)="addItemToFavorites(data.item, 'kanban')"
			mat-menu-item
		>
			{{ 'common.kanban' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Chat')"
			(click)="addItemToFavorites(data.item, 'chats')"
			mat-menu-item
		>
			{{ 'common.chat' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Channel')"
			(click)="addItemToFavorites(data.item, 'channel')"
			mat-menu-item
		>
			{{ 'common.channel' | resx }}
		</button>
		<button
			*ngIf="!data.item.availableRepresentations || data.item.availableRepresentations.includes('Files')"
			(click)="addItemToFavorites(data.item, 'filestorage')"
			mat-menu-item
		>
			{{ 'common.disk' | resx }}
		</button>
	</ng-template>
</mat-menu>

<mat-menu #openAsItems="matMenu">
	<ng-template matMenuContent let-item="item">
		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Grid')"
			(click)="openTable(item, $event)"
			mat-menu-item
		>
			<mat-icon svgIcon="vh-table-24"></mat-icon>
			<span>{{ 'common.contextGrid' | resx }}</span>
		</button>
		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Feeds')"
			(click)="openFeed(item, $event)"
			mat-menu-item
		>
			<mat-icon svgIcon="vh-feed-24"></mat-icon>
			<span>{{ 'common.contextFeed' | resx }}</span>
		</button>

		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Calendar')"
			(click)="openCalendar(item, $event)"
			mat-menu-item
		>
			<mat-icon svgIcon="vh-calendar-24"></mat-icon>
			<span>{{ 'common.сontextCalendar' | resx }}</span>
		</button>

		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Gantt')"
			(click)="openGantt(item, $event)"
			mat-menu-item
		>
			<mat-icon svgIcon="vh-gant-24"></mat-icon>
			<span>{{ 'common.сontextGantt' | resx }}</span>
		</button>

		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Channel')"
			(click)="openChannel(item, $event)"
			mat-menu-item
		>
			<mat-icon svgIcon="vh-feed-24"></mat-icon>
			<span>{{ 'common.channel' | resx }}</span>
		</button>

		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Chat')"
			(click)="openChat(item, $event)"
			mat-menu-item
		>
			<mat-icon svgIcon="vh-chats-24"></mat-icon>
			<span>{{ 'common.chat' | resx }}</span>
		</button>

		<ng-container *ngIf="item.nodeType === 'Subcategory'">
			<button
				*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Kanban')"
				(click)="openKanban(item, $event)"
				mat-menu-item
			>
				<mat-icon svgIcon="vh-kanban-24"></mat-icon>
				<span>{{ 'common.contextKanban' | resx }}</span>
			</button>
		</ng-container>

		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Files')"
			(click)="openFiles(item, $event)"
			mat-menu-item
		>
			<vh-icon [name]="'cloud-storage-blank'" size="md"></vh-icon>
			<span>{{ 'common.disk' | resx }}</span>
		</button>
	</ng-template>
</mat-menu>

<mat-menu #openSelectionItems="matMenu">
	<ng-template matMenuContent let-data="data">
		<div class="menu-title">{{ 'common.selectionsInSubcat' | resx }}</div>
		<button *ngFor="let item of data.items" (click)="openGridWithSelection(data.subcatId, item.id)" mat-menu-item>
			{{ item.name }}
		</button>
	</ng-template>
</mat-menu> -->

<!-- context menu -->
<vh-navigation-context-menu
	#categoriesContextMenu
	(contextMenuClick)="contextMenuClick.emit($event)"
></vh-navigation-context-menu>
