export class ResultInfo<T> {
	constructor(public succeeded: boolean, public data?: T, public message?: any) {}
}

export class SuccessResultInfo<T> extends ResultInfo<T> {
	constructor(data: T) {
		super(true, data);
	}
}

export class FailureResultInfo<T> extends ResultInfo<T> {
	constructor(error: any) {
		super(false, undefined, error);
	}
}
