import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'vh-layout-quick-panel',
	templateUrl: './quick-panel.component.html',
	styleUrls: ['./quick-panel.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent {
	date: Date = new Date();
	events: any[] = [];
	notes: any[] = [];
	settings: any = {};
}
