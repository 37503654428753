import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { IAttachment, isImage } from '@spa/data/entities';
import { DataHttpService, IFileInfo } from '@spa/data/http';
import { IFileViewer, IFileViewerSettings } from '../../file-viewer.component';
import { MsgReaderLoadResult, MSG_READER_SCRIPT_LOADER } from './msg-reader-script-loader';
import { DomSanitizer } from '@angular/platform-browser';

declare const MSGReader: IMsgReaderConstructor;
const selector = 'vh-msg-viewer';
/**
 * Компонент для рендера PDF-файлов
 */
@Component({
	// tslint:disable-next-line:component-selector
	selector: selector,
	templateUrl: './msg-viewer.component.html',
	styleUrls: ['./msg-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	providers: [],
})
export class MsgViewerComponent implements IFileViewer, OnInit {
	constructor(
		private cdr: ChangeDetectorRef,
		readonly server: DataHttpService,
		private domSanitizer: DomSanitizer,
		@Inject(MSG_READER_SCRIPT_LOADER) readonly msgReaderLoader: MsgReaderLoadResult
	) {}

	@Input()
	public file: Partial<IAttachment>;

	@Input()
	public fileInfo: IFileInfo;

	public fileData: IMsgFileData;
	public reader: IMsgReader;
	public isDirty: boolean;
	public settings: IFileViewerSettings;

	ngOnInit(): void {
		this.msgReaderLoader.subscribe(() => {
			this.server.files.getFileContent(this.file.id, this.file.versionId).subscribe(ab => {
				this.parseMsg(new Uint8Array(ab));
			});
		});
	}

	attachmentFileToUrl(index: number, attachment: IMsgAttachment) {
		const file = this.reader.getAttachment(index);
		const url = URL.createObjectURL(
			new File([file.content], attachment.fileName, {
				type: attachment.mimeType ? attachment.mimeType : 'application/octet-stream',
			})
		);

		return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
	}

	isImage(attachment: IMsgAttachment) {
		return isImage(attachment.mimeType);
	}

	parseMsg(buffer: Uint8Array) {
		this.reader = new MSGReader(buffer);
		this.fileData = this.reader.getFileData();
		this.cdr.detectChanges();
	}
}

interface IMsgAttachment {
	contentLength: number;
	dataId: number;
	extension: string;
	fileName: string;
	fileNameShort: string;
	mimeType: string;
	name: string;
	pidContentId: string;
}

interface IMsgAttachmentFile {
	content: Blob;
}

interface IMsgFileData {
	subject: string;
	senderName: string;
	senderEmail: string;
	body: string;
	bodyHTML: string;
	headers: string;
	recipients: IMsgRecipient[];
	attachments: IMsgAttachment[];
}

interface IMsgRecipient {
	name: string;
	email: string;
}

interface IMsgReader {
	getFileData(): IMsgFileData;
	getAttachment(i: number): IMsgAttachmentFile;
}

interface IMsgReaderConstructor {
	new (buffer: Uint8Array): IMsgReader;
}
