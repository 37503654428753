import {
	IDashboardsResultDto,
	IMobileContainerResultDto,
	IMobileMainMenuResultDto,
	ITaskSourceCriteria,
} from '@spa/data/entities';
import { Observable } from 'rxjs';
import { ITaskFeedsResult } from '../task';

export abstract class MobileDataHttpService {
	abstract getFavoritesMenu(): Observable<IMobileContainerResultDto>;
	abstract getTabbar(): Observable<IMobileContainerResultDto>;
	abstract getMainMenu(): Observable<IMobileMainMenuResultDto>;
	abstract getDashboards(): Observable<IDashboardsResultDto>;
	abstract getDashboardById(id: string): Observable<IDashboardsResultDto>;
	abstract getTaskSource(criteria: ITaskSourceCriteria): Observable<ITaskFeedsResult>;
}
