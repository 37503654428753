export enum GroupView {
	grid = 'grid',
	list = 'list'
}

export interface ISearchGroupDefinition {
	order: number;
	name: string;
	viewOptions?: Partial<{
		view: GroupView;
	}>;
	/** app pid's for include */
	includeAppPid?: string[];
	/** app pid's for exclude */
	excludeAppPid?: string[];
}
