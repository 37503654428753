import { ActivatedRoute, NavigationExtras, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { delay, map, of, shareReplay, switchMap } from 'rxjs';

export function pathnameFromRouterState(state: RouterStateSnapshot): string {
	const url = urlFrom(state.url);
	return url.pathname;
}

export function urlFrom(link: string) {
	if (!link) return;

	const url = link.includes('://') ? new URL(link) : new URL(Location.joinWithSlash(location.origin, link));

	return url;
}

export function queryParamsObjectFrom(link: string): Record<string, string | number> {
	const url = urlFrom(link);

	if (!url) return;

	const queryParams = {};

	url.searchParams.forEach((value, name) => {
		queryParams[name] = value;
	});

	return queryParams;
}

export function toAngularRouterParts(link: string) {
	const url = urlFrom(link);

	if (!url) return;

	const pathname = url.pathname;
	const queryParams = {};

	url.searchParams.forEach((value, name) => {
		queryParams[name] = value;
	});

	return {
		queryParams,
		pathname,
	};
}

export function mergeRouterQueryParams(router: Router, queryParams: Record<string, any>, state?: any) {
	const pathname = urlFrom(router.url).pathname;
	return router.navigate([], {
		replaceUrl: true,
		queryParamsHandling: 'merge',
		queryParams: queryParams,
		state: state,
		skipLocationChange: true,
	});
}

export function updateRouterQueryParams(
	router: Router,
	queryParams: Record<string, any>,
	extras?: Partial<NavigationExtras>,
	state?: any
) {
	return router.navigate([], {
		replaceUrl: true,
		queryParamsHandling: 'merge',
		queryParams: queryParams,
		state: state,
		...(extras || {}),
	});
}

export function resolveLinkUrl2(path: string): string {
	path =
		path.includes('://') || path.startsWith('./') || path.startsWith('/')
			? path
			: path.includes('.')
			? `https://${path}`
			: path;
	return resolveLinkUrl(path);
}

export function resolveLinkUrl(path: string): string {
	const a = document.createElement('a');
	a.href = path;
	return a.href;
}

export function createSpaRouteCheck() {
	const router = inject(Router);
	const rootPaths: Route[] = (router.config.find(i => i.path === '') as any)?._loadedRoutes?.[0]?.children || [];
	return {
		isExist(url: string) {
			if (!url || typeof url !== 'string') {
				return false;
			}
			const startPart = url.split('/').filter(Boolean)[0];
			const exists = rootPaths.find(i => i.path.toLowerCase() === startPart.toLowerCase());
			return !!exists;
		},
	};
}

function getRouteByHostType(route: ActivatedRoute, hostType: any) {
	if (route.component instanceof hostType) {
		return route;
	}
	for (const child of route.children) {
		const result = getRouteByHostType(child, hostType);
		if (result) {
			return result;
		}
	}
	return route;
}

function delayFrom<T>(value: T, ms = 1) {
	return of(value).pipe(delay(ms));
}

export function routeQueryString<T = string>(names: string | string[], mapper?: (v: any) => T) {
	names = (Array.isArray(names) ? names : [names]).map(i => i.toLowerCase());
	return inject(ActivatedRoute).queryParams.pipe(
		map(qp => {
			const param = Object.keys(qp).find(k => names.includes(k.toLowerCase()));
			const value = param && qp[param];
			const mapped = mapper ? mapper(value) : value;
			return mapped as T;
		}),
		shareReplay(1)
	);
}

export function routeParam<T = string>(names: string | string[], mapper?: (v: any) => T) {
	names = (Array.isArray(names) ? names : [names]).map(i => i.toLowerCase());
	return inject(ActivatedRoute).params.pipe(
		map(ps => {
			const param = Object.keys(ps).find(k => names.includes(k.toLowerCase()));
			const value = param && ps[param];
			const mapped = mapper ? mapper(value) : value;
			return mapped as T;
		}),
		shareReplay(1)
	);
}

export function routeQueryStringAsync(names: string | string[], type: any, mapper?: (v: any) => any) {
	names = (Array.isArray(names) ? names : [names]).map(i => i.toLowerCase());
	return delayFrom(inject(ActivatedRoute).root).pipe(
		switchMap(route => getRouteByHostType(route, type).queryParams),
		map(qp => {
			const param = Object.keys(qp).find(k => names.includes(k.toLowerCase()));
			const value = param && qp[param];
			const mapped = mapper ? mapper(value) : value;
			return mapped;
		}),
		shareReplay(1)
	);
}

export function routeParamAsync(names: string | string[], type: any, mapper?: (v: any) => any) {
	names = (Array.isArray(names) ? names : [names]).map(i => i.toLowerCase());
	return delayFrom(inject(ActivatedRoute).root).pipe(
		switchMap(route => getRouteByHostType(route, type).params),
		map(ps => {
			const param = Object.keys(ps).find(k => names.includes(k.toLowerCase()));
			const value = param && ps[param];
			const mapped = mapper ? mapper(value) : value;
			return mapped;
		}),
		shareReplay(1)
	);
}
