import { Injector } from '@angular/core';
import { IExtParam } from '@spa/data/entities';
import { ExtParamBase } from '../ext-params/ext-param/ext-param-base';
import { createExtParam } from '../ext-params/ext-param/ext-param-factory';

export class TaskExtParams {
	constructor(params: IExtParam[], protected readonly injector: Injector) {
		this.add(...(params || []));
	}

	protected records = new Map<number, ExtParamBase>();

	get(id: number) {
		return this.records.get(id);
	}

	add(...configs: IExtParam[]) {
		configs.forEach(config => {
			if (!config || this.records.has(config.id)) {
				return;
			}
			const ep = createExtParam({
				sourceConfig: config,
				injector: this.injector,
			});
			this.records.set(ep.id, ep);
		});
	}

	clear() {
		this.records.forEach(ep => {
			ep.destroy();
		});
		this.records.clear();
	}

	setInstance(ep: ExtParamBase) {
		this.records.set(ep.id, ep);
	}

	entries() {
		return this.records.entries();
	}
}
