<vh-modal-base
	*ngIf="(reportsGroup$ | async)?.length"
	(close)="closeModal()"
	(cancel)="closeModal()"
	[showOk]="false"
	[showClose]="true"
	title="Выберите отчет"
>
	<vh-profile-spa-reports
		[reportsGroup]="reportsGroup$ | async"
		(openReportClicked)="openReport($event)"
	></vh-profile-spa-reports>
</vh-modal-base>
