/// <reference path="../../../declarations/type.d.ts" />
import {
	IFavoriteButton,
	IFavoriteFolder,
	IFavoriteNode,
	IMyTasksConfig,
} from '@spa/facade/features/navigation/providers/data-provider.dto';
import { FacadeStoreNames } from '@spa/facade/store-names.enum';
import { enumWithNs } from '@valhalla/utils';
import { ActionCreator, commonActions } from '@valhalla/core';

import { INavigationMenuItem, NavigationMenu, IFavoriteBlock } from './state';

const Actions = Object.freeze({
	...commonActions,
	setActiveMenuItem: 'setActiveMenuItem',
	setActiveTabMenu: 'setActiveTabMenu',
	updateNavItems: 'updateNavItems',
	deleteNavItems: 'deleteNavItems',
	createNavItems: 'createNavItems',
	turnOnPeriodicCounterUpdate: 'turnOnPeriodicCounterUpdate',
	turnOffPeriodicCounterUpdate: 'turnOffPeriodicCounterUpdate',
	turnOnPeriodicFavoritesMenuUpdate: 'turnOnPeriodicFavoritesMenuUpdate',
	turnOffPeriodicFavoritesMenuUpdate: 'turnOffPeriodicFavoritesMenuUpdate',
	loadFavoriteCounters: 'loadFavoriteCounters',
	updateFavoriteCounters: 'updateFavoriteCounters',
	updateFavoritesItems: 'updateFavoritesItems',
	updateFavoritesAssistantItems: 'updateFavoritesAssistantItems',
	expandFavoriteFolder: 'expandFavoriteFolder',
	expandFavoriteFolderAssistant: 'expandFavoriteFolderAssistant',
});

export const FacadeNavigationAction = enumWithNs(Actions, FacadeStoreNames.navigation);

export const loadRequestActionCreator: ActionCreator<Array<NavigationMenu>> = payload => ({
	type: FacadeNavigationAction.loadDataRequest,
	payload,
});

export interface INavigationLoadSuccessPayload {
	menuId: NavigationMenu | string;
	menuItems: {
		[key: string]: INavigationMenuItem;
	};
	menuView?: any[];
	buttons?: IFavoriteBlock[];
	allTasksUserOwns?: number;
	allTasksUserPerforms?: number;
}
export const loadSuccessActionCreator: ActionCreator<INavigationLoadSuccessPayload> = payload => ({
	type: FacadeNavigationAction.loadDataSuccess,
	payload,
});

export interface INavigationLoadErrorPayload {
	error: string | Error;
}

export const loadErrorActionCreator: ActionCreator<INavigationLoadErrorPayload> = payload => ({
	type: FacadeNavigationAction.loadDataError,
	payload,
});

export interface IUpdateNavigationItemPayload {
	menuId: NavigationMenu | string;
	items?: Array<PartialRequireId<INavigationMenuItem>> | Array<number> | Array<string>;
	apply?: Partial<INavigationMenuItem>;
	toAll?: boolean;
	active?: PartialRequireId<INavigationMenuItem> | string | number;
	rebuildTree?: boolean;
}

export interface IUpdateFavoritesPayload {
	id: NavigationMenu | string;
	buttons: IFavoriteButton[];
	folders: IFavoriteFolder[];
	nodes: IFavoriteNode[];
	expandedBlocks: number[];
	myTasksConfig?: IMyTasksConfig;
}

export interface IUpdateFavoritesAssistantPayload {
	menusAssistant: IUpdateFavoritesPayload[];
}

export const updateNavigationItemActionCreator: ActionCreator<IUpdateNavigationItemPayload> = payload => ({
	type: FacadeNavigationAction.updateNavItems,
	payload,
});

export const updateFavoritesItemsActionCreator: ActionCreator<IUpdateFavoritesPayload> = payload => ({
	type: FacadeNavigationAction.updateFavoritesItems,
	payload,
});

export const updateFavoritesItemsAssistantActionCreator: ActionCreator<IUpdateFavoritesAssistantPayload> = payload => ({
	type: FacadeNavigationAction.updateFavoritesAssistantItems,
	payload,
});

export const expandFavoriteFolderActionCreator: ActionCreator<{
	folderId: number;
	collapseOthers: boolean;
}> = payload => ({
	type: FacadeNavigationAction.expandFavoriteFolder,
	payload,
});

export const expandFavoriteFolderAssistantActionCreator: ActionCreator<{
	folderId: number;
	collapseOthers: boolean;
	userId: number;
}> = payload => ({
	type: FacadeNavigationAction.expandFavoriteFolderAssistant,
	payload,
});

export interface ICreateNavigationItemPayload {
	menuId: NavigationMenu | string;
	items: INavigationMenuItem[];
}

export const createNavigationItemActionCreator: ActionCreator<ICreateNavigationItemPayload> = payload => ({
	type: FacadeNavigationAction.updateNavItems,
	payload,
});

export interface IDeleteNavigationItemPayload {
	menuId: NavigationMenu | string;
	items: Array<INavigationMenuItem | string | number>;
}

export const deleteNavigationItemActionCreator: ActionCreator<IDeleteNavigationItemPayload> = payload => ({
	type: FacadeNavigationAction.updateNavItems,
	payload,
});

export const initNavigationMenuActionCreator: ActionCreator<any> = payload => ({
	type: FacadeNavigationAction.init,
	payload,
});

export interface ISetActiveNavigationItemPayload {
	menuId: NavigationMenu | string;
	item: PartialRequireId<INavigationMenuItem> | string | number;
	/**установить активное меню по текущему УРЛ */
	byUrl?: boolean;
	/**схлопнуть все другие элементы группы не являющиеся родителями для активного элемента */
	collapseNotParent?: boolean;
}

export const setActiveNavigationItemActionCreator: ActionCreator<ISetActiveNavigationItemPayload> = payload => ({
	type: FacadeNavigationAction.setActiveMenuItem,
	payload,
});

export interface ISetActiveMenuTabPayload {
	menuTabActiveId?: NavigationMenu | string;
}

export const setActiveMenuTab: ActionCreator<ISetActiveMenuTabPayload> = payload => ({
	type: FacadeNavigationAction.setActiveTabMenu,
	payload,
});

export const turnOnPeriodicCounterUpdate: ActionCreator = payload => ({
	type: FacadeNavigationAction.turnOnPeriodicCounterUpdate,
	payload,
});

export const turnOffPeriodicCounterUpdate: ActionCreator = (payload?) => ({
	type: FacadeNavigationAction.turnOffPeriodicCounterUpdate,
	payload,
});

export const turnOnPeriodicFavoritesMenuUpdate: ActionCreator = payload => ({
	type: FacadeNavigationAction.turnOnPeriodicFavoritesMenuUpdate,
	payload,
});

export const turnOffPeriodicFavoritesMenuUpdate: ActionCreator = (payload?) => ({
	type: FacadeNavigationAction.turnOffPeriodicFavoritesMenuUpdate,
	payload,
});

export const loadFavoriteCounters: ActionCreator = (payload?) => ({
	type: FacadeNavigationAction.loadFavoriteCounters,
	payload,
});

export const updateFavoriteCounters: ActionCreator = (payload?) => ({
	type: FacadeNavigationAction.updateFavoriteCounters,
	payload,
});
