import { FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import { ApplicationRef } from '@angular/core';

export function overlayPatch(appRef: ApplicationRef) {
	const reapplyLastPositionFn = FlexibleConnectedPositionStrategy.prototype.reapplyLastPosition;

	const refs = new WeakMap();

	// фикс - дергается меню контекстное при открытии / перерисовывает позицию на след тик, убираем
	FlexibleConnectedPositionStrategy.prototype.reapplyLastPosition = function () {
		let meta: any = refs.get(this);
		if (meta?.start) {
			return;
		} else {
			meta = meta || { start: true };
			refs.set(this, meta);
		}
		const res = reapplyLastPositionFn.apply(this);
		setTimeout(() => {
			meta.start = false;
		});
		return res;
	};
}
