import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApi20Result, IComment, ICommentReaction } from '@valhalla/data/entities';
import { Observable, of, throwError, zip } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import ApiVersion from '../api-versions';

import { DataHttpCommonService } from '../data-http-common.service';
import {
	CommentsDataHttpService,
	IGetCommentViewersCriteria,
	IGetCommentViewersResult,
	IMarkCommentAsQuestionCriteria,
	ICommentFavoriteCriteria,
	IGetCommentsOffsetCriteria,
	IGetCommentsOffsetResult,
	IEditCommentsCriteria,
	IGetPreviousTaskTextResult,
	ICommentFavoriteDeleteCriteria,
	ICommentAddReactionParams,
	ICommentRemoveReactionParams,
	IAllowedReactions,
	ICommentReactionsParams,
} from './abstract';

@Injectable()
export class CommentsDataHttpServiceImpl implements CommentsDataHttpService {
	constructor(public readonly http: HttpClient, public readonly common: DataHttpCommonService) {}

	// no comments...
	readonly colorForRemove = '#ffffff';

	readonly commentFavoriteColorDefault = '#039be5'; //mat blue active

	addReaction(params: ICommentAddReactionParams): Observable<any> {
		const url = this.common.getApiUrl(`/comments/${params.commentId}/reactions/${params.reactionId}/add`);
		return this.http.post(url, undefined);
	}

	removeReaction(params: ICommentRemoveReactionParams): Observable<any> {
		const url = this.common.getApiUrl(`/comments/${params.commentId}/reactions/remove`);
		return this.http.post(url, undefined);
	}

	getCommentViewers(criteria: IGetCommentViewersCriteria): Observable<IGetCommentViewersResult> {
		const searchInArchiveIfNotFound =
			typeof criteria?.searchInArchiveIfNotFound === 'undefined' ? false : criteria?.searchInArchiveIfNotFound;
		const url = this.common.getApiUrl(`/comments/recipients/${criteria?.commentId}/${searchInArchiveIfNotFound}`);
		return this.http.get<any>(url).pipe(
			map(res => ({
				users: res?.map(user => ({
					user,
					seenAt: user?.readDate,
				})),
			}))
		);
	}

	markCommentsAsReadRest(...commentIds: number[]): Observable<any> {
		const url = this.common.getApiUrl('/comments/mark-comments-as-read');
		return this.http.post(url, { commentIds });
	}

	markCommentAsUnread(commentId: number): Observable<any> {
		const url = this.common.getApiUrl(`/comments/mark-comments-as-unread/${commentId}`);
		return this.http.post(url, { commentId });
	}

	markCommentAsQuestionRest(data: IMarkCommentAsQuestionCriteria): Observable<any> {
		const requests$ = data.commentIds.map(commentId => {
			const url = this.common.getApiUrl(`/comments/mark-as-question/${commentId}`);
			return this.http.post(url, {
				commentId,
			});
		});

		return zip(...requests$);
	}

	markCommentsAsAnsweredRest(commentId: number): Observable<any> {
		const url = this.common.getApiUrl('/comments/mark-as-answered');
		return this.http.post(url, {
			commentText: null,
			isForAll: null,
			commentID: commentId,
		});
	}

	markCommentsAsNotMyQuestionRest(commentId: number): Observable<any> {
		const url = this.common.getApiUrl(`/comments/mark-as-not-my-question/${commentId}`);

		return this.http.post(url, { commentId });
	}

	addCommentToFavoriteRest({
		commentId,
		color,
		forAllTaskSubscribers = false,
	}: ICommentFavoriteCriteria): Observable<any> {
		color = !color || color === this.colorForRemove ? this.commentFavoriteColorDefault : color;

		const url = this.common.getApiUrl('/comments/add-to-favorites');

		return this.http.post(url, { commentId, color: '#E65100', forAllTaskSubscribers });
	}

	removeCommentToFavoriteRest({
		commentId,
		forAllTaskSubscribers = false,
	}: ICommentFavoriteDeleteCriteria): Observable<any> {
		const url = this.common.getApiUrl('/comments/remove-from-favorites');

		return this.http.post(url, { commentId, forAllTaskSubscribers });
	}

	getCommentsSlice(criteria: IGetCommentsOffsetCriteria): Observable<IGetCommentsOffsetResult> {
		if (criteria.searchString && !criteria.searchType) {
			criteria.searchType = 'Contains';
		}
		criteria.includeReactions = typeof criteria.includeReactions === 'boolean' ? criteria.includeReactions : true;
		const url = this.common.getApiUrl('comments');
		return this.http.post<IApi20Result<IGetCommentsOffsetResult>>(url, criteria).pipe(map(result => result.data));
	}

	getComment(commentId: number): Observable<Partial<IComment>> {
		return this.getCommentsSlice({
			commentId: commentId,
			limitAfter: 0,
			limitBefore: 0,
			taskInfo: false,
		}).pipe(map(result => result.comments[0]));
	}

	delete(...ids: number[]): Observable<any> {
		if (ids.length <= 0) {
			return of();
		}
		const url = this.common.getApiUrl('comments/delete');
		return this.http
			.post<IApi20Result<any>>(url, {
				isDeleted: true,
				ids: ids,
			})
			.pipe(
				catchError(err => {
					const hasErrors = Array.isArray(err?.error?.errors);
					if (hasErrors && err.error.errors[0]?.message) {
						return throwError(new Error(err.error.errors[0].message));
					}
					return throwError(err);
				})
			);
	}

	edit(model: IEditCommentsCriteria) {
		if (!model?.commentId) {
			return throwError(new Error('commentId is not defined'));
		}
		const url = this.common.getApiUrl(`mobile/comments/${model.commentId}/edit`, ApiVersion.v12);
		const updateRecipients =
			model.recipientIds?.length ||
			model.recipientGroupsIds?.length ||
			model.copyRecipientsIds?.length ||
			model.copyRecipientGroupsIds?.length;

		return this.http.post<any>(url, {
			text: model.text,
			isQuestion: model.asQuestion,
			recipientIds: model.recipientIds,
			recipientGroupsIds: model.recipientGroupsIds,
			copyRecipientsIds: model.copyRecipientsIds,
			copyRecipientGroupsIds: model.copyRecipientGroupsIds,
			updateRecipients,
		});
	}

	getPreviousTaskText(commentId: number): Observable<IGetPreviousTaskTextResult> {
		const url = this.common.getApiUrl(`comments/previous-task-text/${commentId}`);
		return this.http.get<IGetPreviousTaskTextResult>(url);
	}

	allowedReactions(): Observable<IAllowedReactions> {
		const url = this.common.getApiUrl(`emoji-reactions/allowed`);
		return this.http.get<IApi20Result<IAllowedReactions>>(url).pipe(map(r => r.data));
	}

	commentReactions(params: ICommentReactionsParams): Observable<ICommentReaction[]> {
		const url = this.common.getApiUrl(`comments/${params.commentId}/reactions`);
		const search = {} as any;
		if (params.reactionId > 0) {
			search.reactionId = params.reactionId;
		}
		return this.http
			.get<IApi20Result<ICommentReaction[]>>(url, {
				params: search,
			})
			.pipe(map(r => r.data));
	}

	protected mapGetTaskCommentRecipients(data: any): IGetCommentViewersResult {
		const result: IGetCommentViewersResult = {
			users: [],
		};

		if (!Array.isArray(data)) {
			return result;
		}

		data.forEach(({ First, Second }) => {
			result.users.push({
				seenAt: Second,
				user: {
					displayName: First.DisplayName,
					userName: First.DisplayName,
					userId: First.UserID,
					isOnline: First.IsOnline,
				},
			});
		});

		return result;
	}
}
