import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CultureService, ResourceService } from '@valhalla/localization';
import { isCurrentYear, isTodayNew, isYesterday } from '@valhalla/utils';
import moment from 'moment';
import { map } from 'rxjs/operators';

@Pipe({
	name: 'lastOnlineWithGender',
})
export class LastOnlineWithGenderPipe implements PipeTransform {
	constructor(readonly resource: ResourceService, readonly culture: CultureService) {}

	transform(value: string, gender: boolean, useOnlinePrefix?: boolean): any {
		return this.resource
			.resolveCurrentResource('common')
			.pipe(map(rsxData => this.transform2(value, gender, rsxData, useOnlinePrefix)));
	}

	transform2(value: any, gender: boolean, rsxData: Record<string, string>, useOnlinePrefix?: boolean) {
		const lastSeen = rsxData[this.getGenderRsx(gender)];
		const date = moment(value).format('DD.MM.YYYY');
		const dateCurrentYear = moment(value).format('DD.MM');
		const at = rsxData['at'];
		const time = moment(value).format('HH:mm');
		const yesterday = rsxData['yesterday'];

		let onlinePrefix = '';
		if (useOnlinePrefix) {
			onlinePrefix = (rsxData['online2'] || rsxData['online']).toLowerCase();
		}

		if (isTodayNew(value)) {
			return `${lastSeen} ${onlinePrefix} ${at} ${time}`;
		}

		if (isYesterday(value)) {
			return `${lastSeen} ${onlinePrefix} ${yesterday?.toLowerCase()} ${at} ${time}`;
		}

		if (isCurrentYear(value)) {
			return `${lastSeen} ${onlinePrefix} ${dateCurrentYear} ${at} ${time}`;
		}

		return `${lastSeen} ${onlinePrefix} ${date} ${at} ${time}`;
	}

	getGenderRsx(gender) {
		if (gender === 1) {
			return 'lastSeenMan';
		}
		if (gender === 0) {
			return 'lastSeenWoman';
		}

		return 'lastSeen';
	}
}

@NgModule({
	imports: [CommonModule],
	exports: [LastOnlineWithGenderPipe],
	declarations: [LastOnlineWithGenderPipe],
	providers: [],
})
export class LastOnlineWithGenderPipeModule {}
