import { IFilterData, IReportDescription } from '@valhalla/data/entities';
import { Observable } from 'rxjs';
import { IApiResponse } from '../api-response';

export abstract class ReportsDataHttpService {
	abstract getReports(criteria?: IGetReportsCriteria): Observable<IReportDescription[]>;
	abstract getReportsFilter(criteria?: IGetReportsFilterCriteria): Observable<IFilterData>;
	abstract exportReport(criteria: string[]): Observable<IReportExportData>;
	abstract importReport(criteria: IReportImportCriteria): Observable<void>;
	abstract getExportSettings(reportId: number): Observable<IReportExportSettingsData>;
	abstract updateExportSettings(reportId: number, body: IReportExportSettingsData): Observable<any>;
}

export interface IGetReportsCriteria {
	contextType?: number;
}

export interface IGetReportsResult {
	reports: Array<IReportDescription>;
}

export interface IGetReportsFilterCriteria {
	reportId: number;
}

export interface IReportExportResult {
	data: IReportExportData;
}

export interface IReportExportData {
	content: number[];
	name: string;
	contentType: string;
}

export interface IReportImportCriteria {
	name: string;
	file: File;
}

export interface IReportExportSettingsData {
	docxExportSettingsDto: DocxExportSettingsDto;
	rtfExportSettingsDto: RtfExportSettingsDto;
	xlsxExportSettingsDto: XlsxExportSettingsDto;
	pptExportSettingsDto: PptExportSettingsDto;
}

export interface DocxExportSettingsDto {
	display: string;
	rowHeight: string;
	wysiwyg: boolean;
	printOptimized: boolean;
}

export interface RtfExportSettingsDto {
	wysiwyg: boolean;
	pageBreaks: boolean;
	imageFormat: string;
}

export interface XlsxExportSettingsDto {
	wysiwyg: boolean;
	pageBreaks: boolean;
	splitPages: boolean;
	dataOnly: boolean;
	seamless: boolean;
	printOptimized: boolean;
}

export interface PptExportSettingsDto {
	imageFormat: string;
}
