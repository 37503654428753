<vh-modal-base (close)="close()" (cancel)="close()" (ok)="accept()" [title]="'common.commentRequired' | resx">
	<div class="reason-container">
		<div class="reason-header">{{ ('common.reason' | resx) || 'Причина' }}<span class="warn-reason">*</span></div>
		<div class="reason-form">
			<textarea matInput [formControl]="reason" (keydown)="keydownHandler($event)" [vhAutoFocus]="true"></textarea>
		</div>
		<div class="reason-checkbox" *ngIf="taskId$ | async">
			<input
				type="checkbox"
				name="reason-checkbox"
				id="reason-checkbox"
				[checked]="saveReason$ | async"
				(change)="saveReason$.next(!saveReason$.value)"
			/>
			<label for="reason-checkbox">{{ 'common.makeAsAnswer' | resx }}</label>
		</div>
		<div class="quick-replies-container">
			<span
				*ngFor="let reply of quickReplies$ | async"
				(click)="onClickReply(reply)"
				[tippy]="parseReasons(reply)"
				[delay]="500"
				>{{ parseReasons(reply) }}</span
			>
		</div>
	</div>
</vh-modal-base>
