/**
 * Список захардкоженых системных тикеров
 */
export enum SystemTicker {
	directorSignaturesCount = 'DirectorSignaturesCount',
	hasChatInvites = 'HasChatInvites',
	milestones = 'Milestones',
	missedCalls = 'MissedCalls',
	overDueTasksCount = 'OverDueTasksCount',
	overdueSigns = 'OverdueSigns',
	questionsCount = 'QuestionsCount',
	myQuestionsCount = 'MyQuestionsCount',
	signaturesCount = 'SignaturesCount',
	unreadCommentsCount = 'UnreadCommentsCount',
	wasOffline = 'WasOffline',
	chatUnreadMessageCount = 'chatUnreadMessageCount',
	favoriteComments = 'favoriteComments',
	newTasks = 'newTasks',
	overdueNoPerformers = 'overdueNoPerformers',
	overdueSignatures = 'overdueSignatures',
	todayTasks = 'todayTasks',
	totalTasks = 'totalTasks',
	totalTasksAssistant = 'totalTasksAssistant',
}
