import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ContextMenuModule } from '@spa/common/context-menu';
import { SpacesPanelItemComponent } from './spaces-panel-item.component';
import { TippyModule } from '@ngneat/helipopper';
import { LocalizationModule } from '@spa/localization';

@NgModule({
	imports: [CommonModule, MatIconModule, ContextMenuModule, TippyModule, LocalizationModule],
	exports: [SpacesPanelItemComponent],
	declarations: [SpacesPanelItemComponent],
	providers: [],
})
export class SpacesPanelItemModule {}
