import { Component, EventEmitter, forwardRef, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'vh-control-toggle',
	templateUrl: './control-toggle.component.html',
	styleUrls: ['./control-toggle.component.scss', '../control-common.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ControlToggleComponent),
			multi: true,
		},
	],
})
export class ControlToggleComponent implements ControlValueAccessor {
	@HostBinding('class.vh-control')
	@HostBinding('class.vh-control-toggle')
	hostClassSelector = true;

	@Input()
	set checked(val: boolean) {
		this.onChange(val);
		this.checked$.next(val || false);
	}

	get checked() {
		return this.checked$.value;
	}

	@Input()
	label = null;

	@Input()
	subtitle: string;

	@Input()
	id;

	@Input()
	value;

	@Input()
	disabled = false;

	@Input()
	readonly = false;

	@Input()
	@HostBinding('class.vh-control--filled')
	filled = false;

	@Output()
	inputValue = new EventEmitter();

	@Output()
	checkedChange = new EventEmitter();

	checked$ = new BehaviorSubject<boolean>(false);

	touched = false;

	onChange = checked => {};

	onTouched = () => {};

	writeValue(value: any) {
		this.checked$.next(value);
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	onInput(e) {
		this.markAsTouched();
		this.inputValue.emit(e.target);
		this.checked$.next(e.target.checked);
		this.onChange(e.target.checked);
		this.checkedChange.emit(e.target.checked);
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}
}
