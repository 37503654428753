<div class="panel-wrapper">
	<ng-container *ngFor="let menuItem of menuItems">
		<div class="title-wrapper">
			<div class="title" *ngIf="menuItem.subMenu?.length">
				<div class="panel-title"><mat-icon class="title-icon">comment_bank</mat-icon>&nbsp;{{ menuItem.title }}</div>
			</div>
		</div>
		<mat-nav-list>
			<mat-list-item *ngFor="let subMenuItem of menuItem.subMenu" (click)="onItemClick(subMenuItem)">
				<span class="task-item">{{ subMenuItem.title }}</span>
			</mat-list-item>
		</mat-nav-list>
	</ng-container>
</div>
