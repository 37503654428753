import { Provider } from '@angular/core';
import { TokenService } from './abstract';
import { TokenServiceImpl } from './token.service';

export const TOKEN_PROVIDERS: Provider[] = [
	{
		provide: TokenService,
		useClass: TokenServiceImpl,
	},
];
