import { Pipe, PipeTransform } from '@angular/core';
import { stringToDSColor } from '@vh/core-components';

/**
 * generate hsl from string
 * @see https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
 */
@Pipe({
	name: 'vhStringToHslColor',
})
export class StringToHslColorPipe implements PipeTransform {
	transform(str: string, saturationPercent = 100, lightnessPercent = 40, useColorMap = false): any {
		return stringToDSColor(str, saturationPercent, lightnessPercent, useColorMap);
	}
}
