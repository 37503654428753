import { Injectable } from '@angular/core';
import { DataHttpService } from '@spa/data/http';
import { map, Observable, take } from 'rxjs';
import { CategoryTreeNodeType } from '@spa/facade/features/navigation/providers/data-provider.dto';
import { TreeNodeDatum, TreeNodeType } from '@spa/common/components/select-tree-dialog/abscract';

@Injectable({ providedIn: 'root' })
export class TreeNodeDataMapperService {
	constructor(readonly server: DataHttpService) {}

	mapCategoriesTree(): Observable<TreeNodeDatum[]> {
		return this.server.category.subcategoriesTree().pipe(
			map(subcategoriesTreeData => {
				return subcategoriesTreeData?.data
					.filter(i => (i.nodeType === CategoryTreeNodeType.subcategory ? i.canCreateTask : i))
					.map(node => {
						let mappedNodeType: TreeNodeType;
						if (node.nodeType === CategoryTreeNodeType.subcategory) {
							mappedNodeType = TreeNodeType.leaf;
						}
						if (node.nodeType === CategoryTreeNodeType.category) {
							mappedNodeType = TreeNodeType.parent;
						}
						return { ...node, nodeType: mappedNodeType };
					});
			}),
			take(1)
		);
	}
}
