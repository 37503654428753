import { enumWithNs } from '@valhalla/utils';
import { FacadeStoreNames } from '@spa/facade/store-names.enum';
import { ActionCreator } from '@valhalla/core';
import { ILayoutState } from './state';

const Actions = Object.freeze({
	updateLayout: 'updateLayout'
});

export const FacadeLayoutAction = enumWithNs(Actions, FacadeStoreNames.layout);

export const updateLayoutActionCreator: ActionCreator<Partial<ILayoutState>> = payload => ({
	type: FacadeLayoutAction.updateLayout,
	payload
});
