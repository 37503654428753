const themeMap = {
	'theme-default': 'Light',
	'theme-blue-gray-dark': 'Dark',
	Dark: 'theme-blue-gray-dark',
	Light: 'theme-default',
};

export function mapThemeName(name: string) {
	return themeMap[name];
}
