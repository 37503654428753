import { ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TabActiveStateService } from '@spa/common/services/tab-active-state.service';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { AuthService, ViewDestroyStreamService } from '@spa/core';
import { MobileViewIDBStorage } from '@spa/core/mobile-view-idb-storage';
import { BlockId, IBlock, IMobileContainer, mobileIcons, TickerType } from '@spa/data/entities';
import { DataHttpService } from '@spa/data/http';
import { TickersFacadeProvider } from '@spa/facade/features/tickers';
import { delayRunInZone } from '@valhalla/utils';
import {
	BehaviorSubject,
	combineLatest,
	distinctUntilChanged,
	map,
	merge,
	Observable,
	of,
	shareReplay,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs';

@Component({
	selector: 'vh-tabbar',
	templateUrl: 'tabbar.component.html',
	styleUrls: ['tabbar.component.scss'],
})
export class TabbarComponent implements OnInit {
	constructor(
		readonly server: DataHttpService,
		readonly destroy$: ViewDestroyStreamService,
		readonly router: Router,
		readonly mobileViewDb: MobileViewIDBStorage,
		readonly cdr: ChangeDetectorRef,
		readonly tickersFacade: TickersFacadeProvider,
		readonly zone: NgZone,
		readonly mobileView: MobileViewService,
		readonly tabActive: TabActiveStateService,
		readonly authService: AuthService
	) {}

	@Input()
	useCustomItems: boolean;

	@Input()
	customItemsSettings: {
		id: string;
		visibility: boolean;
		url: string;
		svgIcon: string;
		svgIconFilled: string;
	}[];

	readonly cachedTabbar$ = new BehaviorSubject<IMobileContainer>(null);
	readonly tabbarRequest$ = this.server.mobile.getTabbar().pipe(
		map(response => response.data[0]),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	isReincarnateMode: boolean;
	readonly isReincarnateMode$ = this.authService.isReincarnateMode$
		.pipe(takeUntil(this.destroy$))
		.subscribe(value => (this.isReincarnateMode = value));

	readonly tabbar$ = this.cachedTabbar$.pipe(
		switchMap(data => {
			if (!this.useCustomItems) {
				this.updateCachedTabbar();
			}

			if (data) {
				return of(data);
			}

			return this.tabbarRequest$;
		}),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	systemTickers$ = this.tickersFacade.selectTickers$.pipe(
		map(record =>
			Object.values(record)
				//.filter(ticker => ticker.id !== 'system/chatUnreadMessageCount')
				.filter(ticker => ticker.type === TickerType.system)
				.sort((a, b) => b.order - a.order)
		),
		tap(() => delayRunInZone(this.zone, () => this.cdr.detectChanges())),
		takeUntil(this.destroy$)
	);

	allTickers$ = combineLatest([this.tickersFacade.selectTickers$, this.systemTickers$]).pipe(
		map(([recordAll, systemTickers]) => {
			const customTickers = Object.values(recordAll)
				.filter(ticker => ticker.type === TickerType.custom)
				.sort((a, b) => b.order - a.order);
			return [...systemTickers, ...customTickers];
		}),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	readonly tickers$ = this.server.tickers.getAll();

	readonly selectedBlockIndex$ = new BehaviorSubject<number>(undefined);

	readonly styles$ = this.tabbar$.pipe(map(tabbar => tabbar.template));
	readonly blocks$ = combineLatest([this.tabbar$, this.selectedBlockIndex$.pipe(distinctUntilChanged())]).pipe(
		map(([tabbar, selectedBlockIndex]) => ({
			blocks: tabbar.blocks.slice(0, 5),
			selectedBlockIndex,
		})),
		map(data => {
			const blocks = data.blocks;
			let selectedBlockIndex = data.selectedBlockIndex;
			let block;

			if (!isNaN(selectedBlockIndex)) {
				block = blocks[selectedBlockIndex];
				if (!block) {
					block = this.getDefaultBlock(blocks);
					selectedBlockIndex = blocks.findIndex(b => b?.id === block?.id);
					this.selectedBlockIndex$.next(selectedBlockIndex);
					this.navigateByBlock(block, true);
				}
			}

			if (isNaN(selectedBlockIndex)) {
				block = this.getDefaultBlock(blocks);
				const blockIndex = blocks.findIndex(b => b?.id === block?.id);

				selectedBlockIndex = blockIndex;
				this.selectedBlockIndex$.next(blockIndex);
				this.mobileView.pageTitle = block.template.topTitle;
				this.navigateByBlock(block, true);
			}

			return (blocks as any[]).map((block, index) => {
				block.template.svgIcon = mobileIcons[block.template.icon]?.default;
				block.template.svgIconFilled = mobileIcons[block.template.icon]?.filled;
				block.type = block.type ? block.type : 'Folder';
				block.isSelected = index === selectedBlockIndex;
				return block;
			});
		})
	);

	readonly toolbarTitle$ = this.blocks$.pipe(
		map(blocks => {
			const selectedBlock: IBlock = blocks.find(b => b?.isSelected);
			return selectedBlock?.template?.topTitle || selectedBlock?.template?.fallBackTitle || '';
		})
	);
	readonly tabbarGridTemplateColumns$ = this.blocks$.pipe(map(b => 'repeat(' + b.length + ', 1fr)'));
	readonly cacheBlockDataRx: any = {};

	ngOnInit() {
		if (!this.useCustomItems) {
			this.mobileViewDb
				.get('tabbar')
				.pipe(takeUntil(this.destroy$))
				.subscribe(data => {
					this.cachedTabbar$.next(data);
				});

			merge(this.tabActive.tabBecomeVisible$, this.tabActive.goOnline$)
				.pipe(takeUntil(this.destroy$))
				.subscribe(() => {
					this.updateCachedTabbar();
				});

			this.toolbarTitle$.pipe(takeUntil(this.destroy$)).subscribe(title => {
				this.mobileView.pageTitle = title;
			});
		} else {
			const key = this.router.url.split('?')[0];
			const defaultItemIndex = this.customItemsSettings.findIndex(i => i.url === key);
			this.selectedBlockIndex$.next(defaultItemIndex >= 0 ? defaultItemIndex : 0);
		}
	}

	clickHandler(blocks: IBlock[], index: number) {
		let block = blocks[index];
		if (block) {
			this.selectedBlockIndex$.next(index);
			this.navigateByBlock(block);
		} else {
			block = this.getDefaultBlock(blocks);
			index = blocks.findIndex(b => b?.id === block?.id);
			this.selectedBlockIndex$.next(index);
			this.navigateByBlock(block);
		}
	}

	// todo skipFirstNavigate its a hack to avoid incorrect redirect, refactor later
	navigateByBlock(block: IBlock, skipFirstNavigate?: boolean) {
		if (block?.id === BlockId.chats) {
			this.router.navigate(['/chat'], { queryParams: { mode: 'chat' } });
		}

		if (block?.id === BlockId.contacts) {
			this.router.navigate(['/contacts-search']);
		}

		if (block?.id === BlockId.dashboard) {
			this.router.navigate([!skipFirstNavigate && this.useCustomItems ? '/' : '/mobile-dashboard']);
		}

		if (block?.id === BlockId.feed) {
			this.router.navigate(['/feeds']);
		}

		if (block?.id === BlockId.tasksFeed) {
			this.router.navigate(['/feeds/tasks']);
		}

		if (block?.id === BlockId.toSign) {
			this.router.navigate(['/mobile-signatures']);
			this.mobileView.hideTabbar = false;
		}

		if (block?.id === BlockId.settings) {
			void this.router.navigate(['/mobile-settings'], { state: { isReincarnationMode: this.isReincarnateMode } });
			this.mobileView.hideTabbar = false;
		}
	}

	updateCachedTabbar() {
		combineLatest([this.cachedTabbar$, this.tabbarRequest$])
			.pipe(
				tap(([cachedTabbar, tabbar]) => {
					if (cachedTabbar && tabbar) {
						if (JSON.stringify(cachedTabbar) !== JSON.stringify(tabbar)) {
							this.mobileViewDb.setAnyway('tabbar', tabbar);
							this.cachedTabbar$.next(tabbar);
						}
					}

					if (!cachedTabbar && tabbar) {
						this.mobileViewDb.setAnyway('tabbar', tabbar);
						this.cachedTabbar$.next(tabbar);
					}
				}),
				take(1)
			)
			.subscribe();
	}

	getDefaultBlock(blocks: IBlock[]): IBlock {
		const defaultBlock = blocks.find(b => (b.template.isDefault === '0' ? false : b.template.isDefault));
		const dashboardBlock = blocks.find(b => b.id === BlockId.dashboard);
		let navigationBlock: IBlock;

		if (defaultBlock) {
			navigationBlock = defaultBlock;
		} else if (dashboardBlock) {
			navigationBlock = dashboardBlock;
		} else {
			navigationBlock = blocks[0];
		}

		return navigationBlock;
	}

	getTickerData(block): Observable<any> {
		this.cacheBlockDataRx[block?.id] =
			this.cacheBlockDataRx[block?.id] ||
			this.allTickers$.pipe(
				map(tickers => {
					if (block.id === BlockId.chats) {
						return +tickers.find(t => t.name === 'chatUnreadMessageCount').value;
					}

					if (block.id === BlockId.feed) {
						return +tickers.find(t => t.name === 'UnreadCommentsCount').value;
					}

					if (block.id === BlockId.tasksFeed) {
						return +tickers.find(t => t.name === 'OverDueTasksCount').value;
					}

					return null;
				}),
				distinctUntilChanged()
			);
		return this.cacheBlockDataRx[block?.id];
	}

	getBlockCustomSettingVisibility(blockId: string): boolean {
		if (!this.useCustomItems) {
			return true;
		}
		return this.customItemsSettings.find(item => item.id === blockId)?.visibility;
	}

	getBlockColor(isBlockSelected: boolean, styles?): string {
		if (this.useCustomItems) {
			return isBlockSelected ? 'var(--onsurface-primary)' : 'var(--onsurface-secondary)';
		}
		return isBlockSelected ? styles.selectedItemColor : styles.itemColor;
	}
}
