import { Inject, Injectable, Provider } from '@angular/core';
import { Id } from '@valhalla/utils';
import { IRxStore, StoreSelector, ConfigurationProvider, EventBusService } from '@valhalla/core';
import { Observable } from 'rxjs';

import { STORE_TICKERS } from '../ng-tokens';
import * as actions from '../store/actions';
import { ITickersState } from '../store/state';
import { ITicker } from '../ticker.model';
import { TickersFacadeProvider } from './abstract';
import { UpdateTickersEventBusMessage } from '../update-tickers-eventbus-message';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class TickersFacadeProviderImpl implements TickersFacadeProvider {
	constructor(
		@Inject(STORE_TICKERS) protected readonly store: IRxStore<ITickersState>,
		protected readonly config: ConfigurationProvider,
		protected readonly bus: EventBusService
	) {
		this.initEvents();
	}

	selectTickers$: Observable<Record<Id, ITicker>> = this.store.select(state => state.tickers);

	initEvents() {
		this.bus
			.ofType(UpdateTickersEventBusMessage)
			.pipe(debounceTime(500))
			.subscribe(() => {
				this.updateTickers();
			});
	}

	selectTickerById(id: Id): Observable<ITicker> {
		return this.store.select(state => state.tickers[id]);
	}

	selectTicker<R = any>(selector: StoreSelector<ITickersState, R>): Observable<R> {
		return this.store.select(selector);
	}

	turnOnPeriodicUpdate() {
		const tickersConfig = this.config.config.features.tickers;
		return this.store.dispatch(actions.turnOnPeriodicTickersUpdate(tickersConfig));
	}
	turnOffPeriodicUpdate() {
		return this.store.dispatch(actions.turnOffPeriodicTickersUpdate(null));
	}
	updateTickers() {
		return this.store.dispatch(actions.loadTickers(null));
	}
	updateChatTicker(value: number) {
		return this.store.dispatch(actions.updateChatTicker(value));
	}

	systemTickerByName(name: string) {
		const tickers = this.store.getState(s => s.tickers);
		return tickers[`system/${name}`];
	}
}

export const ngProviderTickersFacade: Provider = {
	provide: TickersFacadeProvider,
	useClass: TickersFacadeProviderImpl,
};
