import { Id } from '@valhalla/utils';

import { ITicker } from '../ticker.model';
import { mapSystemTickersToRecord } from './ticker-mapper';
import { SystemTicker } from '../ticker-type.enum';
import { TickerType } from '@valhalla/data/entities';

export interface ITickersState {
	customAssistantTickers: any;
	tickers: Record<Id, ITicker>;
	error?: Error | string;
}

export const defaultFeedTickerStateFactory: (value: number) => Record<Id, ITicker> = value => {
	const tickerName = 'default-feed';
	return {
		[`system/${tickerName}`]: {
			id: `system/${tickerName}`,
			type: TickerType.system,
			name: tickerName,
			title: 'Лента',
			icon: {
				name: 'vertical_split',
			},
			value: value,
			order: 100,
			available: true,
		},
	};
};

export const initialTickerState: ITickersState = {
	tickers: {
		...defaultFeedTickerStateFactory(0),
		...mapSystemTickersToRecord({
			DirectorSignaturesCount: 0,
			HasChatInvites: false,
			Milestones: 0,
			MissedCalls: 0,
			OverDueTasksCount: 0,
			OverdueSigns: 0,
			QuestionsCount: 0,
			SignaturesCount: 0,
			UnreadCommentsCount: 0,
			WasOffline: false,
			availableTypes: [],
		}),
		...chatTickerStateFactory(0),
	},
	customAssistantTickers: null,
	error: null,
};

export function chatTickerStateFactory(value: number): Record<Id, ITicker> {
	return {
		[`system/${SystemTicker.chatUnreadMessageCount}`]: {
			id: `system/${SystemTicker.chatUnreadMessageCount}`,
			type: TickerType.system,
			name: SystemTicker.chatUnreadMessageCount,
			title: 'Чаты',
			icon: {
				name: 'fa-comments-o',
				fontSet: 'fa',
			},
			value: value,
			order: 1,
		},
	};
}
