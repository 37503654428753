import { ComponentRef, inject, Injectable, NgZone, Type } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IHasDirtyChanges } from '@spa/common/has-dirty-changes';
import { UnsaveChangesService } from '@spa/guards/unsave-changes.service';
import { filter, map, startWith, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContentOverlayService {
	constructor(protected readonly router: Router, readonly unsaved: UnsaveChangesService) {
		this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => this.clear());
		this.unsaved.addRouterChecker(() => {
			if (!this.currentComponent) {
				return false;
			}
			if (typeof (this.currentComponent.instance as IHasDirtyChanges)?.hasDirtyChanges !== 'function') {
				return false;
			}
			return (this.currentComponent.instance as IHasDirtyChanges).hasDirtyChanges();
		});
	}

	protected zone = inject(NgZone);
	protected stack: ContentOverlayStackItem[] = [];

	readonly change$ = new Subject<ContentOverlayService>();
	readonly stackItems$ = this.change$.pipe(
		startWith(this.stack),
		map(() => this.stack)
	);

	get currentComponent() {
		return this.stack[this.stack.length - 1];
	}

	push<T>(context: ContentOverlayContext<T>, triggerChange = true) {
		this.stack.unshift({ context });
		triggerChange && this.triggerChange();
	}

	pop(triggerChange = true) {
		const item = this.stack.shift();
		triggerChange && this.triggerChange();
		return item;
	}

	replace<T>(context: ContentOverlayContext<T>) {
		this.pop(false);
		this.push(context, false);
		this.triggerChange();
	}

	clear() {
		this.stack = [];
		this.triggerChange();
	}

	protected triggerChange() {
		this.zone.runTask(() => {
			this.change$.next(this);
		});
	}
}

export interface ContentOverlayStackItem<C = any> {
	context: ContentOverlayContext<C>;
	instance?: C;
}

export interface ContentOverlayContext<C> {
	component: Type<C>;
	bindings: Partial<Record<keyof C, C[keyof C]>>;
	onCreate?: (ref: C) => void;
}
