// tslint:disable:no-empty-interface
import { IAbsence, IMailboxDescription } from '@valhalla/data/entities';
import { Observable } from 'rxjs';
import { ICalendarEvent } from '../provider-calendars';

export abstract class EmailDataHttpService {
	abstract getMailboxes(): Observable<IGetMailboxesResult>;
	// deleteMailBox old
	// abstract deleteMailBox(mailboxId: number, userId: number): Observable<any>;
	abstract addToFavorites(requestParams: IAddToFavoritesRequestParams): Observable<any>;
	abstract markAllAsRead(folderId: number): Observable<any>;
	abstract clearFolder(folderId: number): Observable<any>;

	abstract getMessages(req: IGetMessagesReq): Observable<IGetMessagesRes>;
	abstract getMessage(req: IGetMessageReq): Observable<IGetMessageRes>;
	abstract setRead(req: ISetReadReq): Observable<any>;
	abstract setUnRead(req: ISetReadReq): Observable<any>;
	abstract getMailboxList(): Observable<IMailbox[]>;
	abstract getMailserverList(): Observable<IMailServerItem[]>;
	abstract getSyncFoldersList(req: IGetSyncFoldersReq): Observable<ISyncFolder[]>;
	abstract addMailbox(req: IAddMailboxReq): Observable<any>;
	// deleteMailBox new
	abstract deleteMailbox(mailboxId: number): Observable<any>;
	abstract getMailbox(mailBoxId: number): Observable<IMailbox>;
	abstract updateMailbox(req: IUpdateMailboxReq): Observable<any>;
	abstract getFolders(req: IGetFoldersReq): Observable<ISyncFolder[]>;
	abstract mailSend(req: IMailSent): Observable<any>;
	abstract deleteMessage(req: IDeleteMessage): Observable<any>;
	abstract setAllRead(mailBoxId: number, mailFolderName: string): Observable<any>;
	abstract deleteAllMessages(mailBoxId: number, mailFolderName: string): Observable<any>;
}

export interface IDeleteMessage {
	mailBoxId: number;
	mailFolderName: string;
	internetMessageId: string;
}
export interface IMailSent {
	mailBoxId: number;
	toEmail: string;
	htmlBody: string;
	textBody: string;
	subject: string;
	cc: string;
	bcc: string;
	attachments: IMailSentAttachment[];
	notifyOnRead?: boolean;
}

export interface IMailSentAttachment {
	fileName: string;
	attachmentId?: string;
	mimeType: string;
	size: number;
	content: string;
}

export interface IGetFoldersReq {
	mailBoxId: number;
	getWatchFoldersOnly: boolean;
}

export interface IMailbox {
	mailBoxId: number;
	mailServerId: number;
	mailBoxName: string;
	senderName: string;
	senderEmail: string;
	login: string;
	isDisabled: boolean;
	password?: string;
	ownerUserIds?: number[];
	mailBoxUserIds?: number[];
	syncFolders?: ISyncFolderAdd[];
	notUseInSmarts?: boolean;
	emailStoreDuration?: number;
	daysCountToSyncOldMail?: number;
	sendingOnly?: boolean;
	calDavLogin?: string;
	showCounters?: boolean;
	calDavAddress?: string;
}

export interface IUpdateMailboxReq {
	//mailBoxId: number;
}

export interface IAddMailboxReq {
	mailServerId: number;
	mailBoxName: string;
	senderName: string;
	senderEmail: string;
	login: string;
	password: string;
	ownerUserIds: number[];
	mailBoxUserIds: number[];
	syncFolders: ISyncFolderAdd[];
	notUseInSmarts?: boolean;
	emailStoreDuration?: number;
	daysCountToSyncOldMail?: number;
	sendingOnly?: boolean;
	showCounters?: boolean;
}

export interface ISyncFolderAdd {
	serverId: number;
	name: string;
	path: string;
	folderType: ISyncFolderType;
}

export interface ISyncFolder {
	name: string;
	path: string;
	id?: string;
	serverId?: number;
	folderType?: ISyncFolderType;
	messageCount?: number;
	parentId?: number;
	unreadCount?: number;
}

export enum ISyncFolderType {
	Folder = 'Folder',
	Trash = 'Trash',
	Drafts = 'Drafts',
	Sent = 'Sent',
	Outbox = 'Outbox',
	Inbox = 'Inbox',
	Archive = 'Archive',
	Spam = 'Spam',
}

export interface IGetSyncFoldersReq {
	login: string;
	password: string;
	mailServerId: number;
}

export interface IMailServerItem {
	id: number;
	name: string;
	imapAddress: string;
	smtpAddress: string;
	imapUseSSL: boolean;
	smtpUseSSL: boolean;
	imapPort: number;
	smtpPort: number;
	isExchange: boolean;
	isGmail: boolean;
	emailDomains: string;
	noRetryErrors: string;
	mailServerCategoryId: number;
	receivedHeader: string;
	sslProtocol: number;
	mailProviderId: number;
	ewsUrl: string;
	smtpAnonymous: boolean;
	isSynchronized: boolean;
	isCalDav?: boolean;
	calDavAddress: string;
}

export interface ISetReadReq {
	mailBoxId: number;
	mailFolderName: string;
	internetMessageId: string;
}

export interface IGetMessageRes extends IEmail {
	//senderId: number;
}

export interface IGetMessageReq {
	mailBoxId: number;
	mailFolderName: string;
	internetMessageId: string;
	includeAttachmentContent: boolean;
}

export interface IGetMessagesRes {
	emails: IEmail[];
	moreAvailable: boolean;
	sortDisabled: boolean;
	filterDisabled: boolean;
	notSortColumns: string[];
	notFilterColumns: string[];
}

export interface IEmail {
	id: 0;
	preview: string;
	subject: string;
	body: string;
	hasAttachments: boolean;
	dateTimeSent: string;
	messageId: string;
	meetingKey: string;
	isRead: boolean;
	isDisabled: boolean;
	copyRecipients: any[];
	recipients: any[];
	blindCopyRecipients: any[];
	attachments: IEmailFile[];
	meeting?: ICalendarEvent;
	fromUser: {
		lastOnlineTime?: string;
		lastPersonalInfoUpdateTime?: string;
		userId?: number;
		displayName?: string;
		hasAvatar?: boolean;
		absences?: IAbsence[];
		isEmployee?: boolean;
		isFired?: boolean;
		userAvatarFile?: {
			fileId?: number;
			latestVersionId?: number;
		};
	};
	senderUser: {
		lastOnlineTime?: string;
		lastPersonalInfoUpdateTime?: string;
		userId: number;
		displayName?: string;
		hasAvatar?: boolean;
		absences?: IAbsence[];
		isEmployee?: boolean;
		isFired?: boolean;
		userAvatarFile?: {
			fileId?: number;
			latestVersionId?: number;
		};
	};

	//
	//sender: string;
	senderEmail: string;
	// senderAvatarFile: {
	// 	fileId: number;
	// 	latestVersionId: number;
	// };
	//from: string;
	// fromAvatarFile: {
	// 	fileId: number;
	// 	latestVersionId: number;
	// };
	fromEmail: string;
	//fromId: number;
}

export interface IEmailFile {
	fileName: string;
	attachmentId: string;
	mimeType: string;
	size: number;
	content?: any[];
	isInline?: boolean;
}
export interface IGetMessagesReq {
	mailBoxId: number;
	mailFolderName: string;
	pageSize: number;
	offset: number;
	sortField?: string;
	sortingDirection?: 'asc' | 'desc';
	filter: {
		readState: 'Any' | 'Unread' | 'Read';
		messageType: 'Any' | 'CalendarEvent ';
		sender?: string;
		body?: string;
		subject?: string;
		dateFrom?: string;
		dateTo?: string;
		dateExact?: string;
		toRecipient?: string;
		generalSearch?: string;
	};
}

export interface IGetMailboxesResult {
	mailBoxes: Array<IMailboxDescription>;
}

export interface IAddToFavoritesRequestParams {
	folderName: string;
	link: string;
}
