import type { ILoggerOptions } from '../../diagnostics/logger';

/**
 * описание объекта конфигурации приложения
 */
export interface IConfigurationModel {
	/** конфигурация логгирования */
	logger?: Partial<ILoggerOptions>;
	/** конфигурация обработки исключений */
	exceptions?: {
		ignoreUnhandled?: boolean;
	};
	animation?: boolean;
	features?: Partial<{
		tickers: {
			/** интервал обновления значений тикеров */
			updateIntervalMs: number;
		};
	}>;
}

export function createDefaultConfiguration(): IConfigurationModel {
	return {
		logger: {},
		exceptions: {
			ignoreUnhandled: true,
		},
		animation: false,
		features: {
			tickers: {
				updateIntervalMs: 60 * 1000,
			},
		},
	};
}
