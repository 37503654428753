<div class="search-coworkers-panel" id="coworkers-search-panel">
	<div class="search-coworkers-panel__search search-coworkers-panel-compact">
		<input
			(keydown.arrowdown)="onKeyDownArrowInUserSearch($event)"
			(keydown.enter)="onEnterUserSearch()"
			(input)="userSearchHandler($event.target.value)"
			matInput
			[placeholder]="'common.searchEmployee' | resx"
			tabIndex="0"
			#inputCoworker
			[vhAutoFocus]="true"
		/>
		<mat-icon
			*ngIf="userSearchInputHasValue$ | async"
			matSuffix
			(click)="inputCoworker.value = ''; userSearchHandler('')"
			class="s-22 secondary-text"
			>close</mat-icon
		>
	</div>

	<div #userSearchResultContainer class="search-coworkers-panel__history">
		<div *ngIf="!mobileView.mobileMode" class="search-actions">
			<a class="search-action" [routerLink]="['/org/list/workers']" (click)="hidePanel()">{{
				'common.coworkerDictionary' | resx
			}}</a>

			<ng-container *ngIf="orgChartVisible$ | async">
				<a class="search-action" [routerLink]="['/org/chart2']" (click)="hidePanel()">{{
					'common.organizationalStructure' | resx
				}}</a>
			</ng-container>
		</div>
		<mat-selection-list [multiple]="false" (selectionChange)="selectItem($event)">
			<ng-container *ngFor="let user of userSearchResult$ | async; let isLast = last">
				<vh-contacts-search-item [user]="user" [isLast]="isLast" (hideList)="hidePanel()"></vh-contacts-search-item>
			</ng-container>
		</mat-selection-list>
	</div>
</div>

<vh-portal-target name="app-mobile-toolbar" *ngIf="platform.isMobile()"> Контакты </vh-portal-target>
