import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';

import { NavbarHorizontalStyle1Module } from './horizontal/style-1/style-1.module';
import { NavbarComponent } from './navbar.component';
import { NavbarVerticalStyle1Module } from './vertical/style-1/style-1.module';
import { NavbarVerticalStyle2Module } from './vertical/style-2/style-2.module';

@NgModule({
	declarations: [NavbarComponent],
	imports: [
		CommonModule,
		NavbarHorizontalStyle1Module,
		NavbarVerticalStyle1Module,
		NavbarVerticalStyle2Module,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
	],
	exports: [NavbarComponent],
})
export class NavbarModule {}
