<div
	*ngIf="(blocks$ | async)?.length"
	class="main-menu"
	[style.background-color]="(styles$ | async).containerBackgroundColor || defaultMenuBackground"
	(outsideClick)="closeMenuFromOutsideClick($event)"
>
	<div class="profile-row">
		<div class="user-profile-wrapper" (click)="openProfile()">
			<vh-chat-nav-avatar
				[tippy]="sessionUserName$ | async"
				[preferColorText]="!(sessionUserHasAvatar$ | async)"
				[coloredText]="sessionUserName$ | async"
				[showColorText]="!(sessionUserHasAvatar$ | async)"
				[sideLengthPx]="profileAvatarSideLengthPx"
				[userData]="sessionUser?.userInfo$ | async"
			></vh-chat-nav-avatar>

			<div class="user-name-wrapper">
				<span class="user-name">{{ sessionUserName$ | async }}</span>
				<span class="f-label">{{ (appSettingsAnonymous$ | async)?.ApplicationName }}</span>
			</div>
		</div>

		<div class="action-buttons-wrapper">
			<ng-container *ngFor="let actionButton of actionButtons$ | async">
				<button
					*ngIf="actionButton?.action === MainMenuButtonActions.openVKS ? (isOpenVKSButtonEnabled$ | async) : true"
					mat-mini-fab
					class="vh-btn vh-btn--accent"
					(click)="onActionButtonClickHandler(actionButton.action)"
				>
					<mat-icon class="icon-20" [svgIcon]="mobileIcons[actionButton.icon]?.default"></mat-icon>
				</button>
			</ng-container>
		</div>
	</div>

	<mat-nav-list *ngIf="styles$ | async" class="blocks">
		<mat-list-item
			matListIcon
			*ngFor="let block of blocks$ | async"
			class="block-item"
			(click)="onBlockClickHandler(block)"
		>
			<mat-icon class="icon-20" [svgIcon]="block.template.svgIcon"></mat-icon>
			{{ block.template.fallBackTitle || block.template.title }}
		</mat-list-item>
	</mat-nav-list>
</div>
