import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CultureService, ResourceService } from '@valhalla/localization';
import { map } from 'rxjs/operators';

@Pipe({
	name: 'neverSeenWithGender',
})
export class NeverSeenWithGenderPipe implements PipeTransform {
	constructor(readonly resource: ResourceService, readonly culture: CultureService) {}

	transform(gender: boolean): any {
		return this.resource.resolveCurrentResource('common').pipe(map(rsxData => this.transform2(gender, rsxData)));
	}

	transform2(gender: boolean, rsxData: Record<string, string>) {
		const neverSeen = rsxData[this.getGenderRsx(gender)];

		return `${neverSeen}`;
	}

	getGenderRsx(gender) {
		if (gender === 1) {
			return 'neverSeenMan';
		}
		if (gender === 0) {
			return 'neverSeenWoman';
		}

		return 'neverSeen';
	}
}

@NgModule({
	imports: [CommonModule],
	exports: [NeverSeenWithGenderPipe],
	declarations: [NeverSeenWithGenderPipe],
	providers: [],
})
export class NeverSeenWithGenderPipeModule {}
