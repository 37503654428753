import { Effect, IAction, ofType } from '@valhalla/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IUserQuickAppsState } from '../state.contract';
import { UserQuickAppsEffectDependencies } from './dependency';

export const syncUserQuickAppsEffect: Effect<IAction, IAction, IUserQuickAppsState, UserQuickAppsEffectDependencies> = (
	actions$,
	state$,
	deps
) => {
	const logger = deps.loggerFactory.createLogger('syncUserQuickAppsEffect');
	return actions$.pipe(
		ofType('never-in'),
		switchMap(action => of({ type: 'never-out' }))
	);
};
