import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Downloader } from '@spa/core';
import { IAttachment } from '@spa/data/entities';
import { DataHttpService, IFileInfo } from '@spa/data/http';
import { booleanFilter, InputSubject } from '@valhalla/utils';
import { BehaviorSubject, map, of, shareReplay, switchMap, take, tap } from 'rxjs';
import { IFileViewerDialogComponentDataOptions } from '../../file-viewer-dialog.component';
import { IFileViewer, IFileViewerSettings } from '../../file-viewer.component';

const selector = 'vh-office-viewer';

/**
 * Компонент для рендера ОФИС-файлов
 */
@Component({
	// tslint:disable-next-line:component-selector
	selector: selector,
	templateUrl: './office-viewer.component.html',
	styleUrls: ['./office-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [],
})
export class OfficeViewerComponent implements IFileViewer {
	constructor(private cdr: ChangeDetectorRef, readonly downloader: Downloader, readonly server: DataHttpService) {}
	// @Input()
	// public file: Partial<IAttachment>;

	@Input()
	@InputSubject()
	file: Partial<IAttachment>;
	file$!: BehaviorSubject<Partial<IAttachment>>;

	@Input()
	public options: IFileViewerDialogComponentDataOptions;

	@Input()
	public fileInfo: IFileInfo;

	public settings: IFileViewerSettings;
	public isDirty: boolean;

	readonly fileUrl$ = this.file$.pipe(
		booleanFilter(),
		switchMap(file => {
			const data = new FormData();
			data.append('', file?.fileBlob, file.name);

			return this.server.files.ToPreUploadedFiles(data).pipe(
				take(1),
				map(pd =>
					this.downloader.createPreviewOfficeR7EditorLink(
						pd?.data[0]?.preUploadFileId,
						this.options?.modeR7Editor,
						null,
						'temp:'
					)
				)
			);
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	ngOnInit() {}

	public getFileLink(): string {
		if (this.options?.useR7Editor) {
			const needEncode = false; //this.options?.isMeetingFile;
			return this.downloader.createPreviewOfficeR7EditorLink(this.file, this.options?.modeR7Editor, needEncode);
		}

		return this.downloader.createPreviewOfficeWebAppLink(this.file); //`/component/WAFrame.aspx?fileId=${this.file.id}`;
	}

	onLoad(el: HTMLIFrameElement) {
		el.contentDocument.body.style.margin = '0';
	}
}
