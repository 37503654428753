import { Database, IDatabaseDescription, ITable } from '../../db/indexed';
import { ILogEntry } from './eventlog.model';

export class EventLogDatabase extends Database {
	readonly eventLog: ITable<ILogEntry, number>;

	getDescription(): IDatabaseDescription {
		return {
			databaseName: 'core/diagnostics/eventLog',
			versions: {
				1: {
					schema: {
						eventLog: '++id,timestamp,name,level',
					},
				},
			},
		};
	}
}
