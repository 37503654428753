import { Injector } from '@angular/core';

import { registerCommands } from './commands';
import { registerExtParamGlobal } from './ext-param';
import { registerGlobals } from './global';

export { portalApi } from './portal';

export { GlobalSpaContextService, IGlobalSpaContext } from './global-spa-context.service';
export { SpaCommandRegisterService } from './global-spa-command-register.service';

let initialized = false;
export function initializeApi(injector?: Injector) {
	if (initialized) return;

	registerGlobals(window, injector);
	registerCommands(injector);
	registerExtParamGlobal(injector);
	initialized = true;
}
