import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TippyModule } from '@ngneat/helipopper';
import { AutoFocusModule } from '@spa/common/directives';
import { LocalizationModule } from '@valhalla/localization';
import { SidebarSearchModule } from '../sidebar-search/sidebar-search.module';
import { SidebarHeaderComponent } from './sidebar-header.component';

@NgModule({
	declarations: [SidebarHeaderComponent],
	imports: [CommonModule, MatIconModule, AutoFocusModule, LocalizationModule, SidebarSearchModule, TippyModule],
	exports: [SidebarHeaderComponent],
})
export class SidebarHeaderModule {}
