import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';

import { FavoritesCommentsComponent } from './favorites-comments.component';

@NgModule({
	imports: [CommonModule, MatIconModule, MatListModule],
	declarations: [FavoritesCommentsComponent],
	exports: [FavoritesCommentsComponent],
})
export class FavoritesCommentsModule {}
