import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HTTP_INTERCEPTORS,
	HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventBusService, LoggerFactory } from '@valhalla/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UpdateSystemTickersEventBusMessage, UpdateTickersEventBusMessage } from './update-tickers-eventbus-message';

@Injectable()
export class UpdateTickersByRequestsInterceptor implements HttpInterceptor {
	constructor(readonly logFactory: LoggerFactory, readonly bus: EventBusService) {}

	readonly logger = this.logFactory.createLogger('UpdateTickersByRequestsInterceptor');
	readonly updateSystemTickersUrls = [
		'comments/delete',
		'comments/mark-comments-as-read',
		'comments/mark-as-question',
		'comments/mark-as-answered',
		'comments/mark-as-not-my-question',
		'comments/mark-comments-as-unread',
	].map(u => u.toLowerCase());
	readonly updateAllTickersUrls = ['comments/remove-from-favorites', 'comments/add-to-favorites'].map(u =>
		u.toLowerCase()
	);
	readonly matchedUrls = [...this.updateAllTickersUrls, ...this.updateSystemTickersUrls];
	readonly index = {};

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap((r: any) => {
				if (r instanceof HttpResponse && this.check(r.url)) {
					this.logger.info(`update tickers after url action ${req.url}`);
					if (this.check(req.url, this.updateAllTickersUrls)) {
						this.bus.createAndSend(UpdateTickersEventBusMessage);
					} else if (this.check(req.url, this.updateSystemTickersUrls)) {
						this.bus.createAndSend(UpdateSystemTickersEventBusMessage);
					}
				}
			})
		);
	}

	check(url: string, urls?: string[]) {
		if (!url) {
			return false;
		}
		if (!urls && this.index[url]) {
			return true;
		}
		const lcUrl = url.toLowerCase();
		if ((urls || this.matchedUrls).some(mu => lcUrl.indexOf(mu) !== -1)) {
			this.index[url] = true;
			return true;
		}
		return false;
	}
}

export const UpdateTickersByRequestsInterceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	multi: true,
	useClass: UpdateTickersByRequestsInterceptor,
};
