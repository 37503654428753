import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { SidebarKeys } from '@spa/common/components/sidebar/abstract';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { OpenPagesService } from '@spa/common/services/open-pages.service';
import { SidebarService } from '@spa/common/services/sidebar.service';
import { SelectCategoryDialogComponent } from '@spa/components/task/ui/task-card/select-category-dialog/select-category-dialog.component';
import { AuthService, SessionUser, UrlProvider, ViewDestroyStreamService } from '@spa/core';
import { MobileViewIDBStorage } from '@spa/core/mobile-view-idb-storage';
import {
	BlockId,
	IMainMenuActionButton,
	IMainMenuBlock,
	IMainMenuTemplate,
	IMobileMainMenu,
	MainMenuButtonActions,
	mobileIcons,
} from '@spa/data/entities';
import { DataHttpService, UsersDataHttpService } from '@spa/data/http';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { ReportsDialogComponent } from '@spa/facade/layout/components/mobile-main-menu/reports-dialog/reports-dialog.component';
import {
	BehaviorSubject,
	catchError,
	combineLatest,
	filter,
	from,
	map,
	Observable,
	of,
	shareReplay,
	switchMap,
	take,
	tap,
} from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';
import { MobileSettingsComponent } from '@spa/components/mobile-settings/mobile-settings.component';
import { UserSettingsFacadeProvider } from '@spa/facade/features/user-settings';
const NtfChatDialogComponent$ = from(
	import('@spa/components/new-task/ui/new-task-card/new-task-card-chat-modal/new-task-card-chat-modal.component').then(
		m => m.NtfDialogComponent
	)
);

@Component({
	selector: 'vh-mobile-main-menu',
	templateUrl: './mobile-main-menu.component.html',
	styleUrls: ['./mobile-main-menu.component.scss'],
})
export class MobileMainMenuComponent implements OnInit {
	constructor(
		readonly server: DataHttpService,
		readonly destroy$: ViewDestroyStreamService,
		readonly mobileViewDb: MobileViewIDBStorage,
		readonly sessionUser: SessionUser,
		readonly openPages: OpenPagesService,
		readonly usersDataService: UsersDataHttpService,
		readonly router: Router,
		readonly urlBuilder: UrlProvider,
		readonly dialog: MatDialog,
		readonly modal: ModalWindowsService,
		readonly fuseSidebarService: SidebarService,
		readonly mobileView: MobileViewService,
		readonly authService: AuthService,
		readonly userSettingsStore: UserSettingsFacadeProvider
	) {
		this.authService.isReincarnateMode$.pipe(take(1)).subscribe(val => (this.isReincarnateMode = val));
	}

	public readonly MainMenuButtonActions = MainMenuButtonActions;
	public readonly mobileIcons = mobileIcons;
	public readonly profileAvatarSideLengthPx = 40;
	readonly cashedMainMenu$ = new BehaviorSubject<IMobileMainMenu>(null);
	readonly mainMenuRequest$ = this.server.mobile.getMainMenu().pipe(
		map(response => response.data[0]),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	readonly appConfig$ = this.server.config.getAppConfiguration();
	readonly calendarSubcatId$ = this.appConfig$.pipe(map(config => config.calendarSubcatId));

	readonly mainMenu$ = this.cashedMainMenu$.pipe(
		switchMap(data => {
			this.updateCashedMainMenu();
			if (data) {
				return of(data);
			}

			return this.mainMenuRequest$;
		}),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	readonly blocks$: Observable<IMainMenuBlock[]> = this.mainMenu$.pipe(
		filter(data => Boolean(data)),
		map(data => {
			// diagnostics is used only in native mobile app
			// VCard is temporary hidden
			const blocks = data.blocks.filter(
				block => block.id !== BlockId.diagnostics && block.id !== BlockId.card && mobileIcons[block.template.icon]
			);

			return blocks.map(block => {
				// in some cases we get the id from the backend in the format 'TaskSource67'
				const blockId = block.id.match(/\d+/g);
				const blockType = block.id.match(/[a-zA-Z]+/g);
				block.navigationId = blockId?.length ? +blockId[0] : null;
				block.navigationType = blockType?.length ? (blockType[0] as BlockId) : null;

				(block.template as IMainMenuTemplate & { svgIcon: string }).svgIcon = mobileIcons[block.template.icon].default;
				(block.template as IMainMenuTemplate & { svgIconFilled: string }).svgIconFilled =
					mobileIcons[block.template.icon].filled;

				return block;
			});
		})
	);

	readonly actionButtons$: Observable<IMainMenuActionButton[]> = this.mainMenu$.pipe(
		map(data => JSON.parse(data.template.rightBarButtons)),
		catchError(() => {
			console.error('json actionButtons: parse failed');
			return of([]);
		})
	);

	readonly styles$ = this.mainMenu$.pipe(map(mainManu => mainManu.template));

	readonly sessionUserHasAvatar$ = this.sessionUser.hasAvatar$;
	readonly sessionUserName$ = this.sessionUser.userName$;
	readonly defaultMenuBackground = '#383b40';
	readonly appSettingsAnonymous$ = this.server.config.getAppSettingsAnonymous().pipe(share());
	isReincarnateMode: boolean;

	public isOpenVKSButtonEnabled$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(userSettings => !!userSettings?.jitsiServicePersonalRoom)
	);

	ngOnInit(): void {
		this.mobileViewDb
			.get('mainMenu')
			.pipe(takeUntil(this.destroy$))
			.subscribe(data => this.cashedMainMenu$.next(data));
		this.blocks$
			.pipe(takeUntil(this.destroy$))
			.subscribe(blocks => this.mobileView.hideMainMenuHamburgerBtn$.next(!blocks?.length));
	}

	updateCashedMainMenu(): void {
		combineLatest([this.cashedMainMenu$, this.mainMenuRequest$])
			.pipe(
				tap(([cashedMainMenu, mainMenu]) => {
					if (cashedMainMenu && mainMenu) {
						if (JSON.stringify(cashedMainMenu) !== JSON.stringify(mainMenu)) {
							// todo add to enums
							this.mobileViewDb.setAnyway('mainMenu', mainMenu);
							this.cashedMainMenu$.next(mainMenu);
						}
					}

					if (!cashedMainMenu && mainMenu) {
						this.mobileViewDb.setAnyway('mainMenu', mainMenu);
						this.cashedMainMenu$.next(mainMenu);
					}
				}),
				take(1)
			)
			.subscribe();
	}

	onBlockClickHandler(block: IMainMenuBlock): void {
		switch (block.navigationType as BlockId) {
			case BlockId.toSign:
				void this.router.navigate(['/mobile-signatures']);
				this.mobileView.hideTabbar = true;
				this.closeMenuAnyway();
				break;
			case BlockId.settings:
				this.modal.openGeneric({
					componentGetter: () => MobileSettingsComponent,
					componentInputs: { openInModal: true, isReincarnationMode: this.isReincarnateMode },
					dialogConfig: {
						width: '100%',
						height: '100%',
					},
				});
				this.closeMenuAnyway();
				break;
			case BlockId.newTask:
				// eslint-disable-next-line no-case-declarations
				const subcatId = block.id.replace('NewTask', '') || block.data?.id;
				if (!subcatId) {
					return;
				}
				this.createMeeting(+subcatId);
				break;
			case BlockId.reports:
				this.dialog.open(ReportsDialogComponent);
				break;
			case BlockId.subcatAll:
				this.openAllCategories();
				break;
			case BlockId.taskSource:
				void this.router.navigate(['/feeds/tasksource', block.data.id], {
					queryParams: { title: block.data.title, cellStyle: block.template?.cellStyle || 'default' },
				});
				this.closeMenuAnyway();
				break;
			case BlockId.subcat:
				void this.router.navigate([`/feeds/tasks`], { queryParams: { subcatId: block.data.id } });
				this.closeMenuAnyway();
				break;
			default:
				return;
		}
	}

	openAllCategories(): void {
		this.modal
			.openDialog(SelectCategoryDialogComponent, { width: '100%', height: '100%' })
			.afterClosed()
			.pipe(
				take(1),
				filter(subcat => subcat?.catId),
				map(subcat => subcat.catId)
			)
			.subscribe(subcatId => {
				void this.router.navigate([`/feeds/tasks`], { queryParams: { subcatId: subcatId } });
				this.closeMenuAnyway();
			});
	}

	openVKS(): void {
		this.usersDataService.sessionUserSettings$.pipe(take(1)).subscribe(user => {
			this.openPages.openVKS({
				roomName: user.jitsiServicePersonalRoom,
			});
			this.closeMenuAnyway();
		});
	}

	createMeeting(subcatId: number): void {
		NtfChatDialogComponent$.pipe(take(1)).subscribe(component => {
			const data = {
				subcatId,
			};
			const dialogRef = this.dialog.open(component, {
				data: data,
				minWidth: '90vw',
				maxWidth: '90vw',
				hasBackdrop: true,
			});

			dialogRef.disableClose = true;

			let isDirty = false;
			dialogRef.componentInstance.isDirty$.pipe(takeUntil(dialogRef.afterClosed())).subscribe(data => (isDirty = data));

			dialogRef.backdropClick().subscribe(() => {
				if (isDirty) {
					this.closeMeetingDialog(dialogRef);
				} else {
					dialogRef.close();
				}
			});
		});
	}

	closeMeetingDialog(dialogRef: MatDialogRef<any>): void {
		this.modal
			.openConfirm('Вы уверены, что хотите закрыть окно ?', {}, 'common.confirmMessage')
			.afterClosed()
			.pipe(
				tap(answer => {
					if (answer) {
						dialogRef.close();
					}
				}),
				take(1)
			)
			.subscribe();
	}

	openCalendar(): void {
		void this.router.navigate(['/calendar']);
	}

	onActionButtonClickHandler(action: MainMenuButtonActions): void {
		switch (action) {
			case MainMenuButtonActions.openVKS:
				this.openVKS();
				this.closeMenuAnyway();

				break;
			case MainMenuButtonActions.openCalendar:
				this.openCalendar();
				this.closeMenuAnyway();
				break;
		}
	}

	openProfile(): void {
		this.sessionUser.userId$
			.pipe(
				map(id => `/user/profile/${id}`),
				takeUntil(this.destroy$)
			)
			.subscribe(link => {
				void this.router.navigate([link]);
				this.closeMenuAnyway();
			});
	}

	closeMenuFromOutsideClick($event: Event): void {
		const bar = this.fuseSidebarService.getSidebar(SidebarKeys.mobileMainMenu);
		const toggleMenuIcon = document.getElementById('toolbar-toggle-icon');
		if (bar.opened && $event.target !== toggleMenuIcon && !this.dialog.openDialogs?.length) {
			bar.close();
		}
	}

	closeMenuAnyway(): void {
		this.fuseSidebarService.getSidebar(SidebarKeys.mobileMainMenu).close();
	}
}
