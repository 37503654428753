import { Provider } from '@angular/core';

import { DesktopNotifications } from './abstract';
import { DesktopNotificationsImpl } from './desktop-notifications.service';

export const DESKTOP_NOTIFICATIONS_PROVIDERS: Provider[] = [
	{
		provide: DesktopNotifications,
		useClass: DesktopNotificationsImpl,
	},
];
