import { InjectionToken } from '@angular/core';

export interface ILocalizationOptions {
	refreshOnChangeLanguage: boolean;
	cultureToAlias: Record<string, string>;
}

export const defaultLocalizationOptions: ILocalizationOptions = {
	refreshOnChangeLanguage: false,
	cultureToAlias: {
		'ru-RU': 'rus',
		'en-US': 'eng',
		'zh-CN': 'cn',
	},
};

export const LOCALIZATION_OPTIONS = new InjectionToken<ILocalizationOptions>('Localization module options');
