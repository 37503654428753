/* eslint-disable @typescript-eslint/member-ordering */
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	ViewChild,
	inject,
	SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject, take } from 'rxjs';

import { DS_SIZE } from '../common';
import { useDestroyRef } from '../directives/view-destroy';
import { IContentIconColor, IContentIconColorMap, IContentIconSize, IContentIconSizeMap } from './content-icon.model';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'vh-content-icon',
	templateUrl: './content-icon.component.html',
	styleUrls: ['./content-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [useDestroyRef.provider],
})
export class ContentIconComponent implements OnChanges, OnInit {
	constructor(private readonly httpClient: HttpClient, private readonly sanitizer: DomSanitizer) {}

	@Input()
	set name(val: string) {
		this.name$.next(val);
	}

	get name() {
		return this.name$.value;
	}

	@Input()
	set color(val: IContentIconColor) {
		this.colorFromMap = IContentIconColorMap[val];
	}

	@Input()
	set size(val: IContentIconSize | DS_SIZE) {
		this.sizeFromMap = IContentIconSizeMap[val];
	}

	@Input()
	containerColor: string;

	@Input()
	@HostBinding('class.no-default-size')
	noDefaultSize = false;

	@HostBinding('style.--icon-color')
	colorFromMap: string = IContentIconColorMap.onSurfacePrimary;

	@HostBinding('style.--icon-size')
	sizeFromMap: string = IContentIconSizeMap.space400;

	@ViewChild('contentIconWrapper') contentIconWrapper: ElementRef<HTMLDivElement>;

	svgIcon: SafeHtml;

	readonly name$ = new BehaviorSubject<string>(null);
	readonly elRef = inject<ElementRef<HTMLElement>>(ElementRef);

	ngOnInit(): void {
		this.getIconData();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.containerColor?.currentValue) {
			this.colorFromMap = this.containerColor;
			setTimeout(() => this.refreshView());
		} else if (changes.name && !changes.name?.firstChange) {
			this.getIconData();
		}
	}

	refreshView() {
		this.updateIconColor();
		setTimeout(() => this.elRef.nativeElement.querySelector('title')?.remove?.());
	}

	updateIconColor(): void {
		const svgElements = ['path', 'circle', 'line', 'g', 'rect', 'ellipse', 'polyline', 'polygon'];
		if (this.contentIconWrapper?.nativeElement) {
			const el = this.contentIconWrapper.nativeElement;
			const selector = svgElements.map(svgElement => `${svgElement}[stroke]`).join(', ');

			el.querySelectorAll(selector).forEach(item => {
				item.setAttribute('stroke', 'currentColor');
			});
		}
	}

	getIconData(): void {
		this.httpClient
			.get(`assets/icons/content-icons/${this.name}.svg`, { responseType: 'text' })
			.pipe(take(1))
			.subscribe(data => {
				const svgData = data.replace('width="24px"', '').replace('height="24px"', '');
				this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(
					data.replace('width="24px"', '').replace('height="24px"', '')
				);
				this.contentIconWrapper.nativeElement.innerHTML = svgData;
				this.refreshView();
			});
	}
}
