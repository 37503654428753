import {
	Directive,
	Inject,
	Injectable,
	InjectionToken,
	OnDestroy,
	Optional,
	Provider,
	Type,
	inject,
} from '@angular/core';
import { Draft, produce } from 'immer';
import { BehaviorSubject, Observable, distinctUntilChanged, map } from 'rxjs';

const SimpleStoreState = new InjectionToken('simple initial state');
export function provideSimpleState(state: any): Provider {
	return {
		provide: SimpleStoreState,
		useValue: state,
	};
}

export function provideExistingSimpleStore(type: Type<any>): Provider {
	return {
		provide: SimpleStore,
		useExisting: type,
	};
}

export function currentSimpleStore<T = any>() {
	return inject<SimpleStore<T>>(SimpleStore);
}

@Injectable()
@Directive()
export class SimpleStore<S = any> implements OnDestroy {
	constructor(@Optional() @Inject(SimpleStoreState) initialState?: any) {
		initialState = [null, undefined].some(i => i === initialState) ? this.getInitialState() : initialState;
		this.#state$.next(initialState);
	}

	#state$ = new BehaviorSubject<S>(undefined);
	readonly state$ = this.#state$.asObservable();

	protected getInitialState(): Partial<S> {
		return {} as S;
	}

	get state() {
		return this.#state$.value;
	}

	select<R>(selector: (s: S) => R): Observable<R> {
		return this.state$.pipe(
			map(s => selector(s)),
			distinctUntilChanged()
		);
	}

	setState(action: (state: Draft<S>) => void) {
		if (typeof action !== 'function') {
			return;
		}

		const nextState = produce(this.state, draft => {
			action(draft);
			return draft;
		});
		this.#state$.next(nextState);
	}

	destroy() {
		this.#state$.complete();
	}

	ngOnDestroy(): void {
		this.destroy();
	}
}
