import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AdminDataHttpProvider } from './admin/provider';
import { ApiTokenInterceptor } from './api-token-interceptor';

import { AuthDataHttpProvider } from './auth';
import { BackgroundRequestInterceptor } from './background-request.interceptor';
import { CacheInterceptor, CACHE_REQUEST_CONFIG, ICacheRequestConfig } from './cache-requests';
import { CategoryDataHttpProvider } from './category';
import { ChatDataHttpProvider } from './chat';
import { CommentsDataHttpProvider } from './comments';
import { ConfigurationDataHttpProvider } from './configuration';
import { DataHttpCommonServiceProvider } from './data-http-common.service';
import { DataHttpServiceProvider } from './data-http.service-provider';
import { DataSourceHttpProvider } from './data-source';
import { EmailDataHttpProvider } from './email';
import { EpDataHttpProvider } from './ep';
import { FavoritesDataHttpProvider } from './favorites';
import { FeedsDataHttpProvider } from './feeds';
import { FileDataHttpProvider } from './file';
import { FiltersDataHttpProvider } from './filters';
import { HistoryDataHttpProvider } from './history';
import { LinkedTasksHttpProvider } from './linked-tasks';
import { LocalizationDataHttpProvider } from './localization';
import { MobileDataHttpProvider } from './mobile/provider';
import { PhoneDataHttpProvider } from './phone';
import { PortalDataHttpProvider } from './portal';
import { ProviderCalendarDataHttpServiceProvider } from './provider-calendars/provider';
import { PublicationsDataHttpProvider } from './publications';
import { ReportsDataHttpProvider } from './reports';
import { SearchDataHttpProvider } from './search';
import { SignaturesHttpProvider } from './signatures';
import { SipDataHttpProvider } from './sip';
import { SubtasksHttpProvider } from './subtasks';
import { SurveyDataHttpProvider } from './survey';
import { SystemDataHttpProvider } from './system';
import { TaskDataHttpProvider } from './task';
import { TaskHierarhyHttpProvider } from './task-hierarhy';
import { TickersDataHttpProvider } from './tickers';
import { UsersDataHttpProvider } from './users';
import { Response428Provider } from './http-interceptor-428';
import { TimeoutHttpInterceptorProvider } from './http-interceptor-timeout';
import { LanguageHttpInterceptorProvider } from './http-interceptor-language';
import { BatchProcessingDataHttpProvider } from './batch-processing';
import { ResourcesDataHttpProvider } from './resources';
import { RetryHttpInterceptorProvider } from './http-retry.interceptor';
import { CachesDataHttpProvider } from './caches';
import { JobsDataHttpProvider } from './jobs';

@NgModule()
export class DataHttpModule {
	static forRoot(options?: Partial<{ cache: ICacheRequestConfig }>): ModuleWithProviders<DataHttpModule> {
		return {
			ngModule: DataHttpModule,
			providers: [
				DataHttpCommonServiceProvider,
				ChatDataHttpProvider,
				DataHttpServiceProvider,
				FileDataHttpProvider,
				TaskDataHttpProvider,
				PortalDataHttpProvider,
				EpDataHttpProvider,
				UsersDataHttpProvider,
				TickersDataHttpProvider,
				FavoritesDataHttpProvider,
				DataSourceHttpProvider,
				HistoryDataHttpProvider,
				ConfigurationDataHttpProvider,
				LocalizationDataHttpProvider,
				PublicationsDataHttpProvider,
				EmailDataHttpProvider,
				ReportsDataHttpProvider,
				CommentsDataHttpProvider,
				CategoryDataHttpProvider,
				FeedsDataHttpProvider,
				SurveyDataHttpProvider,
				SystemDataHttpProvider,
				SearchDataHttpProvider,
				SignaturesHttpProvider,
				AuthDataHttpProvider,
				FiltersDataHttpProvider,
				TaskHierarhyHttpProvider,
				SubtasksHttpProvider,
				LinkedTasksHttpProvider,
				PhoneDataHttpProvider,
				AdminDataHttpProvider,
				ProviderCalendarDataHttpServiceProvider,
				MobileDataHttpProvider,
				SipDataHttpProvider,
				ResourcesDataHttpProvider,
				CachesDataHttpProvider,
				JobsDataHttpProvider,
				RetryHttpInterceptorProvider,
				{
					provide: HTTP_INTERCEPTORS,
					useClass: ApiTokenInterceptor,
					multi: true,
				},
				{
					provide: CACHE_REQUEST_CONFIG,
					useValue: options?.cache || {},
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: CacheInterceptor,
					multi: true,
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: BackgroundRequestInterceptor,
					multi: true,
				},
				Response428Provider,
				TimeoutHttpInterceptorProvider,
				LanguageHttpInterceptorProvider,
				BatchProcessingDataHttpProvider,
			],
		};
	}
}
