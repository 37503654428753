<div class="profile-reports-wrapper">
	<div class="profile-reports">
		<ng-container *ngFor="let reportGroup of reportsGroup">
			<div class="profile-reports__group">
				<div class="profile-reports__group-name">{{ reportGroup?.groupName }}</div>
				<ng-container *ngFor="let report of reportGroup?.reports">
					<div class="profile-reports__group-report">
						<a
							(click)="openReportClicked.emit({ id: report?.id, annotation: report?.annotation })"
							[tippy]="report?.annotation"
							[isEnabled]="!mobileService.isMobile"
							>{{ report?.description }}</a
						>
					</div>
				</ng-container>
			</div>
		</ng-container>
	</div>
</div>
