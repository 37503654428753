<ng-template #actionPanel>
	<button class="vh-file-viewer-dialog--action" (click)="rotate()" [tippy]="'common.rotate' | resx">
		<mat-icon svgIcon="vh-rotate-24"></mat-icon>
	</button>
	<button
		class="vh-file-viewer-dialog--action"
		(click)="zoomIn()"
		[tippy]="'common.zoomIn' | resx"
		[style.opacity]="currentImageScale === maxPossibleScale ? 0.4 : 1"
	>
		<mat-icon svgIcon="vh-zoom-in-24"></mat-icon>
	</button>
	<button
		class="vh-file-viewer-dialog--action"
		(click)="zoomOut()"
		[tippy]="'common.zoomOut' | resx"
		[style.opacity]="currentImageScale === minPossibleScale ? 0.4 : 1"
	>
		<mat-icon svgIcon="vh-zoom-out-24"></mat-icon>
	</button>
</ng-template>

<ng-template #mobileActionPanel>
	<button class="vh-file-viewer-dialog--action" mat-menu-item (click)="rotate()">
		<mat-icon svgIcon="vh-rotate-24"></mat-icon>
		<span>{{ 'common.rotate' | resx }}</span>
	</button>
	<button
		class="vh-file-viewer-dialog--action"
		mat-menu-item
		(click)="zoomIn()"
		[style.opacity]="currentImageScale === maxPossibleScale ? 0.4 : 1"
	>
		<mat-icon svgIcon="vh-zoom-in-24"></mat-icon>
		<span> {{ 'common.zoomIn2' | resx }}</span>
	</button>
	<button
		class="vh-file-viewer-dialog--action"
		mat-menu-item
		(click)="zoomOut()"
		[style.opacity]="currentImageScale === minPossibleScale ? 0.4 : 1"
	>
		<mat-icon svgIcon="vh-zoom-out-24"></mat-icon>
		<span>{{ 'common.zoomOut2' | resx }}</span>
	</button>
</ng-template>

<div
	class="container"
	[ngStyle]="
		currentImageScale >= 1.25
			? { transform: 'translate(' + position.x + 'px, ' + position.y + 'px)' }
			: { transform: 'translate(' + 0 + 'px, ' + 0 + 'px)' }
	"
>
	<ng-container *ngIf="!options?.openAsBlob">
		<ng-container *ngIf="options?.isMeetingFile">
			<img #img [src]="getFileMeetingLink(file)" />
		</ng-container>

		<ng-container *ngIf="options?.isEmailFile">
			<img #img [src]="getFileEmailLink(file)" />
		</ng-container>

		<ng-container *ngIf="!options?.isMeetingFile && !options?.isEmailFile">
			<img #img *ngIf="!file.file" [src]="file | fileLink | urlSearch: 'getTrueMime':'true' | safeResource" />
			<img #img *ngIf="file.file" [src]="file | fileLink" />
		</ng-container>
	</ng-container>

	<ng-container *ngIf="options?.openAsBlob">
		<img #img *ngIf="file?.fileBlob" [src]="file?.src" />
	</ng-container>
</div>
