export enum FileViewerFileType {
	generic = 'generic',
	image = 'image',
	audio = 'audio',
	video = 'video',
	pdf = 'pdf',
	eml = 'eml',
	text = 'text',
	document = 'document',
}
