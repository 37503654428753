import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { commonAnimations } from '@spa/common/animations';
import { ViewDestroyStreamService } from '@spa/core';
import { preventDefault } from '@valhalla/utils';
import { filter, takeUntil } from 'rxjs';

import { INavigationItemBadge, INavigationMenu, MenuItemActionType, MenuItemType } from '../../navigation.model';
import { Platform } from '@angular/cdk/platform';

@Component({
	selector: 'vh-common-nav-vertical-collapsable',
	templateUrl: './collapsable.component.html',
	styleUrls: ['./collapsable.component.scss'],
	animations: commonAnimations,
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class NavVerticalCollapsableCommonComponent implements OnInit {
	constructor(
		readonly router: Router,
		readonly cdr: ChangeDetectorRef,
		readonly destroy$: ViewDestroyStreamService,
		readonly platform: Platform
	) {}

	@HostBinding('class.vh-common-nav-vertical-collapsable')
	hostClassSelector = true;

	@Input()
	menuItem: INavigationMenu;

	@Input()
	contextMenu;

	@Input()
	set isOpen(val: boolean) {
		this._isOpen = val;
		if (this._isOpen) {
			this._childRendered = true;
		}
		this.buildHostClasses();
	}
	get isOpen(): boolean {
		return this._isOpen;
	}

	@HostBinding('class')
	get classes() {
		return this._hostClasses;
	}

	@Output()
	menuItemClick: EventEmitter<INavigationMenu> = new EventEmitter();

	readonly actionTypes = MenuItemActionType;
	readonly menuTypes = MenuItemType;
	readonly prevent = preventDefault;

	private _isOpen = false;
	private _hostClasses = '';
	private _childRendered = false;

	get children() {
		if (!this._childRendered) {
			return [];
		}
		return ((this.menuItem && this.menuItem.children) || []) as INavigationMenu[];
	}

	ngOnInit() {
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.cdr.markForCheck();
			});
	}

	isRouterActiveItem() {
		const entity = this.menuItem.nodeType === 'Subcategory' ? 'subcat' : 'category';
		const link = `/tasks/${entity}/${this.menuItem.$id}`;
		const active = this.router.url.toLowerCase().includes(link.toLowerCase());
		return active;
	}

	onItemClick(e) {
		this.menuItemClick.emit(e);
	}

	trackById(index: number, nav: INavigationMenu) {
		return nav.id;
	}

	trackByBadgeValue(badge: INavigationItemBadge) {
		return badge.value;
	}

	buildHostClasses() {
		this._hostClasses = 'nav-collapsable nav-item';
		if (this.isOpen) {
			this._hostClasses += ' open';
		}
	}

	get isMobile(): boolean {
		return this.platform.ANDROID || this.platform.IOS;
	}
}
