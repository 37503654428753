import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DomainChannelService {
	protected channels: Record<string, BroadcastChannel> = {};

	from<T = unknown>(name: string) {
		return new Observable<T>($ => {
			const ch = this.channel(name);
			const handler = e => {
				$.next(e.data);
			};
			const errorHandler = e => {
				$.error(e);
			};
			ch.addEventListener('message', handler);
			ch.addEventListener('messageerror', errorHandler);
			return () => {
				ch.removeEventListener('message', handler);
				ch.removeEventListener('messageerror', errorHandler);
				$.complete();
			};
		});
	}

	send(name: string, data: any) {
		this.channel(name).postMessage(data);
	}

	protected channel(name: string) {
		if (!this.channels[name]) {
			this.channels[name] = new BroadcastChannel(name);
		}
		return this.channels[name];
	}

	protected close(name: string) {
		this.channels[name]?.close();
		delete this.channels[name];
	}
}
