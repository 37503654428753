import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { DuplicateImportModuleError } from '../errors';
import { MatchMediaService } from './abstract';
import { MatchMediaServiceImpl } from './match-media.service';

@NgModule()
export class MediaQueryModule {
	constructor(
		@Optional()
		@SkipSelf()
		thatModule: MediaQueryModule
	) {
		if (thatModule) throw new DuplicateImportModuleError('MediaQueryModule');
	}

	static forRoot(): ModuleWithProviders<MediaQueryModule> {
		return {
			ngModule: MediaQueryModule,
			providers: [
				{
					provide: MatchMediaService,
					useClass: MatchMediaServiceImpl,
				},
			],
		};
	}
}
