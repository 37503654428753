import { Injectable } from '@angular/core';
import { DataHttpService } from '@spa/data/http';
import { map, take } from 'rxjs/operators';
import { SessionUser } from '@spa/core';
import { UserSettingsFacadeProvider } from '@spa/facade/features/user-settings';
import { combineLatest } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CanSpaNewTaskActivateService {
	constructor(
		readonly server: DataHttpService,
		readonly sessionUser: SessionUser,
		readonly userSettings: UserSettingsFacadeProvider
	) {}

	canUseSpaNewTask(subcatId: number) {
		const sessionUserId = this.sessionUser.userId;
		return combineLatest([
			this.server.config.appSettingsAnonymousConfig$,
			this.userSettings.selectUserSettings$.pipe(map(settings => settings?.useNewTaskCard)),
		]).pipe(
			map(([conf, userSettingsUseNewTaskCard]) => {
				const newTaskSpa = conf.CustomSettings.newTaskSpa;
				if (userSettingsUseNewTaskCard) {
					return true;
				}
				if (typeof newTaskSpa === 'boolean') {
					return newTaskSpa;
				}
				if (typeof newTaskSpa === 'object') {
					// rules: [{ subcats: [] | '*', users: [] | '*' }]
					const { excludeUsers, excludeSubcats, rules } = newTaskSpa;
					if (excludeSubcats?.includes(subcatId)) {
						return false;
					}
					if (excludeUsers?.includes(this.sessionUser.userId)) {
						return false;
					}
					if (Array.isArray(rules)) {
						const can = rules.some(rule => {
							const { subcats, users } = rule || {};
							if (!subcats || !users) {
								return false;
							}
							if (subcats === '*') {
								return users === '*' || users.includes(sessionUserId);
							}
							if (subcats.includes(subcatId)) {
								return users === '*' || users.includes(sessionUserId);
							}
							return false;
						});
						return can;
					}
					return false;
				}
				return false;
			}),
			take(1)
		);
	}
}
