import { StoreSelector } from '@valhalla/core';
import { IGetAllTickersResult } from '@valhalla/data/http';
import { Id } from '@valhalla/utils';
import { Observable } from 'rxjs';

import { ITickersState } from '../store/state';
import { ITicker } from '../ticker.model';

export abstract class TickerProvider {
	abstract readonly tickerValues$: Observable<IGetAllTickersResult>;

	///**@deprecated use version 2 */
	//abstract selectSystemTickers(options?: any): Observable<Record<string, ITicker>>;
	//**@deprecated use version 2 */
	//abstract selectChatTicker(options?: any): Observable<Record<string, ITicker>>;
	///**@deprecated use version 2 */
	//abstract selectCustomTickers(): Observable<Record<string, ITicker>>;

	abstract selectSystemTickers2(data?: IGetAllTickersResult): Observable<Record<string, ITicker>>;
	//abstract selectChatTicker2(options?: any): Observable<Record<string, ITicker>>;
	abstract selectCustomTickers2(data?: IGetAllTickersResult): Observable<Record<string, ITicker>>;
}

export abstract class TickersFacadeProvider {
	abstract selectTickerById(id: Id): Observable<ITicker>;
	abstract selectTicker<R = any>(selector: StoreSelector<ITickersState, R>): Observable<R>;

	abstract turnOnPeriodicUpdate();
	abstract turnOffPeriodicUpdate();
	abstract updateTickers();
	abstract updateChatTicker(value: number);
	abstract systemTickerByName(name: string): ITicker;

	selectTickers$: Observable<Record<Id, ITicker>>;
}
