export function badgeRoundValue(value: string | number): string {
	if (!value) return;

	value = typeof value === 'string' ? parseFloat(value) : value;
	if (value >= 1000 && value < 100000) {
		return (value % 1000 > 49 ? (value / 1000).toFixed(1) : (value / 1000).toFixed(0)) + 'k';
	} else if (value >= 100000 && value < 1000000) {
		return (value / 1000).toFixed(0) + 'k';
	} else if (value >= 1000000 && value < 100000000) {
		return (value % 1000000 > 49000 ? (value / 1000000).toFixed(1) : (value / 1000000).toFixed(0)) + 'm';
	} else if (value >= 100000000 && value < 1000000000) {
		return (value / 1000000).toFixed(0) + 'm';
	} else if (value >= 1000000000 && value < 100000000000) {
		return (value % 1000000000 > 49000000 ? (value / 1000000000).toFixed(1) : (value / 1000000000).toFixed(0)) + 'b';
	} else if (value >= 100000000000) {
		return (value / 1000000000).toFixed(0) + 'b';
	} else {
		return value.toFixed(0);
	}
}
