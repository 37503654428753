import { getFloatLength } from '@valhalla/utils';
import { ExtParamBase } from './ext-param-base';

export class ExtParamNumericValue extends ExtParamBase {
	convertForUpdateExtParamInTask() {
		let valStr = this.value;

		const minValue = this.sourceConfig.settings?.minValue;
		const maxValue = this.sourceConfig.settings?.maxValue;

		if (typeof minValue === 'number' && Number(this.value) < minValue) {
			valStr = minValue;
			this.setValue(String(minValue));
		}

		if (typeof maxValue === 'number' && Number(this.value) > maxValue) {
			valStr = maxValue;
			this.setValue(String(maxValue));
		}

		return `#n${this.id}#v${valStr || ''}`;
	}

	equalsValue(a: any, b: any) {
		if (!a && !b) {
			return true;
		}

		// const floatLength = this.sourceConfig.settings?.floatLength;
		// const valA = Number.parseFloat(a?.replace(',', '.')?.replace(/\s+/g, ''))?.toFixed(floatLength);
		// const valB = Number.parseFloat(b?.replace(',', '.')?.replace(/\s+/g, ''))?.toFixed(floatLength);
		// return valA === valB;

		const maxFloatLength = 8;
		const floatLengthB = getFloatLength(String(b));

		const strA = a?.replace(',', '.')?.replace(/\s+/g, '');
		let strB = b?.replace(',', '.')?.replace(/\s+/g, '');

		if (floatLengthB > maxFloatLength) {
			const truncCount = floatLengthB - maxFloatLength;
			strB = strB.slice(0, -truncCount);
		}

		const valA = Number.parseFloat(strA)?.toFixed(maxFloatLength);
		const valB = Number.parseFloat(strB)?.toFixed(maxFloatLength);

		return valA === valB;
	}
}
