export function parseMessageFromError(obj: any) {
	if (!obj) {
		return;
	}

	if (Array.isArray(obj?.error?.errors)) {
		return obj?.error?.errors.reduce((acc, value) => {
			return `${acc}${value.message}\n`;
		}, '');
	}

	if (typeof obj?.error === 'string') {
		return obj.error;
	}

	if (obj?.error?.detail) {
		return obj.error.detail;
	}

	if (typeof obj?.error?.title === 'string') {
		return obj.error.title;
	}

	if (obj?.error?.message) {
		return obj.error.message;
	}

	if (obj?.error?.Message) {
		return obj.error.Message;
	}

	if (obj?.message) {
		return obj.message;
	}

	if (obj?.Message) {
		return obj.Message;
	}

	return 'Произошла неизвестная ошибка';
}

export function parseTypeFromError(obj: any) {
	if (!obj) {
		return;
	}

	if (Array.isArray(obj?.error?.errors)) {
		return obj?.error?.errors.reduce((acc, value) => {
			return `${acc}${value.errorType}`;
		}, '');
	}

	if (typeof obj?.errorType === 'string') {
		return obj.errorType;
	}

	if (obj?.error?.errorType) {
		return obj.error.errorType;
	}

	return '';
}
