import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserLinkType } from '@spa/common/components/navigation/navigation.model';
import { IDataSourceDto, IFavoritesMenu } from '@spa/facade/features/navigation/providers/data-provider.dto';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IApiResponse } from '../api-response';
import { DataHttpCommonService } from '../data-http-common.service';
import { EndpointUrlConfig } from '../endpoint.config';
import type { IEndpointUrlConfig } from '../endpoint.config';
import {
	FavoritesDataHttpService,
	GetFavoriteFoldersResult,
	IGetFavoriteCountersDto,
	IGetFavoriteFolders,
} from './abstract';
import { ApiVersion } from '../api-versions';
import { IMobileContainerResultDto } from '@valhalla/data/entities';

@Injectable()
export class FavoritesDataHttpServiceImpl implements FavoritesDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService
	) {}

	getCounters(): Observable<IGetFavoriteCountersDto[]> {
		const url = this.common.getApiUrl(`subcategories/favorites`);
		return this.http.get<IApiResponse<IGetFavoriteCountersDto[]>>(url).pipe(
			map(result => result.data),
			catchError(err => {
				console.error(err);
				return of([]);
			})
		);
	}

	getFavoritesMenuNew(): Observable<IFavoritesMenu> {
		const url = this.common.getApiUrl('/favorite/menu');
		return this.http.post<IDataSourceDto<IFavoritesMenu>>(url, null).pipe(map(result => result.data));
	}

	getFavoritesMenu(): Observable<IMobileContainerResultDto> {
		const url = this.common.getApiUrl('mobile/containers', ApiVersion.v12);
		return this.http.post<IMobileContainerResultDto>(url, ['FavouritesMenu']);
	}

	addToFavorites(requestParams: IAddToFavoritesRequest): Observable<any> {
		return this.addLinkToFavorites(requestParams);
	}

	addFolderToFavorites(requestParams: IAddFolderToFavorites): Observable<any> {
		const url = this.common.getEndpointUrl('/api/favorite/addFolder');
		return this.http.post<any>(url, requestParams);
	}

	renameFavoriteFolder(requestParams: IRenameFavoriteFolder): Observable<any> {
		const url = this.common.getEndpointUrl('/api/favorite/renameFolder');
		return this.http.post<any>(url, requestParams);
	}

	deleteFavoriteFolder(folderId: number): Observable<any> {
		const url = this.common.getEndpointUrl('/api/favorite/deleteFolder?folderId=' + folderId);
		return this.http.post<any>(url, { folderId: folderId });
	}

	addLinkToFavorites(requestParams: IAddLinkToFavorites | IAddToFavoritesRequest): Observable<any> {
		const url = this.common.getApiUrl('/favorite/addLink');
		return this.http.post<any>(url, requestParams);
	}

	deleteFavoriteLink(itemId: number): Observable<any> {
		const url = this.common.getEndpointUrl('/api/favorite/deleteLink?linkId=' + itemId);
		return this.http.post<any>(url, { linkId: itemId });
	}

	renameFavoriteLink(requestParams: IRenameFavoriteFolder): Observable<any> {
		const url = this.common.getEndpointUrl('/api/favorite/renameLink');
		return this.http.post<any>(url, requestParams);
	}

	moveFavoritesLink(requestParams: IMoveLink): Observable<any> {
		const url = this.common.getEndpointUrl('/api/favorite/moveLink');
		return this.http.post<any>(url, requestParams);
	}

	reorderFavoritesLink(requestParams: IReorderLink): Observable<any> {
		const url = this.common.getEndpointUrl('/api/favorite/reorder');
		return this.http.post<any>(url, requestParams);
	}

	shareFavoritesLink(requestParams: IShareLink): Observable<any> {
		const url = this.common.getEndpointUrl('/api/favorite/shareLink');
		return this.http.post<any>(url, requestParams);
	}

	moveFavoritesFolder(requestParams: IMoveFolder): Observable<any> {
		const url = this.common.getEndpointUrl('/api/favorite/moveFolder');
		return this.http.post<any>(url, requestParams);
	}

	getFavoriteFolders(requestParams: IGetFavoriteFolders): Observable<GetFavoriteFoldersResult> {
		const url = this.common.getApiUrl('favorite/folders');
		return this.http.post<IApiResponse<GetFavoriteFoldersResult>>(url, requestParams).pipe(map(r => r.data));
	}
}

export interface IAddToFavoritesRequest {
	customImageClass: string;
	customImagePath: string;
	folderId: number;
	isJsFunction: boolean;
	link: string;
	linkedObjectId: string;
	name: string;
	type: UserLinkType;
}

export interface IAddFolderToFavorites {
	name: string;
	parentId: number;
}

export interface IRenameFavoriteFolder {
	linkId: number;
	newName: string;
}

export interface IAddLinkToFavorites {
	link: string;
	name: string;
	isJsFunction: boolean;
	customImagePath: string | null;
	customImageClass: string;
	type: string;
	linkedObjectId: number | null;
	folderId: number | null;
}

export interface IMoveLink {
	linkId: number;
	folderId: number;
	groupId?: number;
}

export interface IMoveFolder {
	targetFolderId: number;
	newParentId: number;
	placeAbove: boolean;
}

export interface IReorderFolder {
	folderId: number;
}

export interface IReorderLink {
	linkId: number;
	destinationLinkId: number;
	destinationFolderId: number;
	placeAbove: boolean;
}

export interface IShareLink {
	linkId: number;
	userIds: number[];
}
