import { ComponentFactory, ComponentFactoryResolver, Injectable, Type } from '@angular/core';

import { CoreComponentFactoryResolver } from './abstract';

@Injectable()
export class CoreComponentFactoryResolverImpl implements CoreComponentFactoryResolver {
	protected resolvers = new Set<ComponentFactoryResolver>();

	resolveComponentFactory<T>(component: Type<T>): ComponentFactory<T> {
		for (const resolver of Array.from(this.resolvers.values())) {
			try {
				return resolver.resolveComponentFactory(component);
			} catch {}
		}
		throw new Error(`Can't resolve component factory of type ${component.name}`);
	}

	addComponentFactoryResolver(resolver: ComponentFactoryResolver): CoreComponentFactoryResolver {
		if (!resolver || this.resolvers.has(resolver)) {
			return;
		}
		this.resolvers.add(resolver);
	}
}
