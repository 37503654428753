import {
	ApplicationRef,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { BooleanFilter, buildTrees, rxHandler } from '@valhalla/utils';
import { combineLatest, debounceTime, distinctUntilChanged, from, map, startWith, take } from 'rxjs';

import { ViewDestroyStreamService } from '@spa/core';
import { DataHttpService, ISubategoriesTreeDatum } from '@spa/data/http';
import { CategoryTreeNodeType, ICategoryDto } from '@spa/facade/features/navigation/providers/data-provider.dto';
import { INavigationMenu } from '../../navigation';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { ResourceService } from '@spa/localization';
const CalendarComponent$ = from(import('@spa/common/components/calendar').then(m => m.CalendarComponent));

@Component({
	selector: 'vh-category-control',
	templateUrl: './category-control.component.html',
	styleUrls: ['./category-control.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class CategoryControlComponent {
	constructor(
		readonly server: DataHttpService,
		readonly destroy$: ViewDestroyStreamService,
		readonly cdr: ChangeDetectorRef,
		readonly appRef: ApplicationRef,
		readonly mobileService: MobileViewService,
		readonly modal: ModalWindowsService,
		readonly resourceService: ResourceService
	) {}

	@Output()
	selectCategory = new EventEmitter<any>();

	@HostBinding('class.vh-category-control')
	hostClassSelector = true;

	@HostBinding('class.mobile')
	get isMobile(): boolean {
		return this.mobileService.mobileMode;
	}

	cashedSubcatData: ISubategoriesTreeDatum[];

	readonly searchHandler = rxHandler<string>();
	readonly searchInput$ = from<string>(this.searchHandler as any).pipe(startWith(''));
	readonly searchInputHasValue$ = this.searchInput$.pipe(distinctUntilChanged(), map(BooleanFilter));
	readonly subcategoriesTree$ = combineLatest([
		this.server.category.subcategoriesTree(),
		this.searchInput$.pipe(debounceTime(300)),
	]).pipe(
		map(([data, filter]) => {
			if (this.mobileService.mobileMode) {
				this.cashedSubcatData = data?.data;
			}
			return { data: data.data, filter: filter.toLocaleLowerCase() };
		}),
		map(res => {
			let data = res.data;
			const filter = res.filter;
			if (filter) {
				const treeItems = data.filter(e => {
					return e.nodeType === CategoryTreeNodeType.subcategory && e.name.toLocaleLowerCase().includes(filter);
				});

				const searchRootElements = (parentId): void => {
					if (parentId === CategoryTreeNodeType.root || treeItems.find(ti => ti.id === parentId)) {
						return;
					}
					const parents = data.filter(e => e.id === parentId);
					treeItems.push(...parents);
					parents.forEach(p => {
						searchRootElements(p.parentId);
					});
				};

				treeItems.forEach(subcat => {
					searchRootElements(subcat.parentId);
				});

				data = treeItems;
			}
			return data.filter(i => (i.nodeType === CategoryTreeNodeType.subcategory ? i.canCreateTask : i));
		}),
		map(data => {
			const rootId: any = 'root';

			data.forEach(item => {
				if (!item.parentId) {
					item.parentId = rootId;
				}
			});

			data.push({
				id: rootId,
				name: 'Категории',
				nodeType: CategoryTreeNodeType.root,
				parentId: null,
				tasksCounters: null,
			});

			const parentIdGetter = (i: ICategoryDto) =>
				i.parentId === rootId || !i.parentId ? i.parentId : `Category${i.parentId}`;
			const idGetter = (i: ICategoryDto) => (i.id === rootId || !i.id ? i.id : `${i.nodeType}${i.id}`);
			const result = buildTrees(data, idGetter, parentIdGetter);

			return result[0]?.children?.filter(
				i =>
					i.children?.length &&
					i.children?.some(
						c =>
							c.nodeType === CategoryTreeNodeType.subcategory ||
							(c.nodeType === CategoryTreeNodeType.category && c.children?.length)
					)
			);
		})
	);

	searchClear() {
		this.searchHandler('');
		setTimeout(() => {
			this.appRef.tick();
		}, 310);
	}

	onItemClick(e) {
		/**@see https://ru.1forma.ru/spa/tasks/1252001 */
		if (
			this.cashedSubcatData?.length &&
			this.cashedSubcatData.find(item => item.id === e.catId)?.openType === 'calendar'
		) {
			combineLatest([CalendarComponent$, this.resourceService.resolveCurrentResource('common')])
				.pipe(take(1))
				.subscribe(([calendarComponent, resx]) => {
					this.modal.openGeneric({
						componentGetter: () => calendarComponent,
						componentInputs: { openInModal: true, pageTitle: resx?.todayMeetings, showCommonHeader: true },
						dialogConfig: { width: '100%', height: '100%' },
					});
				});
			this.modal.closeAll();
			return;
		}
		this.selectCategory.emit(e);
	}

	trackById(index: number, nav: INavigationMenu) {
		return nav.id;
	}
}
