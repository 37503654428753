import { IFavoritesMenuState } from '@spa/facade/features/navigation/providers/data-provider.dto';
import { arrayToObject, buildTreeImmutable, isNumber, isObject, isString, merge } from '@valhalla/utils';
import { IAction, produce, ReducerBase } from '@valhalla/core';
import * as actions from '../actions';
import { INavigationState } from '../state';

export class UpdateFavoritesMenuItemReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.updateFavoritesItems;

	reduce(state: Readonly<INavigationState>, action: IAction<actions.IUpdateFavoritesPayload>) {
		const newState = produce(state, ds => {
			const expandedBlocks = arrayToObject(state.menus.favorites.expandedBlocks || [], i => i);

			ds.menus.favorites = action.payload;

			ds.menus.favorites.folders.forEach(f => {
				if (expandedBlocks[f.id]) {
					f.isExpand = true;
				} else {
					f.isExpand = false;
				}
				return f;
			});
		});
		return newState;
	}
}

export class CreateFavoritesMenuItemReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.updateFavoritesItems;

	reduce(state: Readonly<INavigationState>, action: IAction<actions.IUpdateFavoritesPayload>) {
		return produce(state, ds => {
			ds.menus.favorites = action.payload;
		});
	}
}

export class ExpandFavoriteFolderReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.expandFavoriteFolder;
	reduce(state: Readonly<INavigationState>, action: IAction<{ folderId: number; collapseOthers: boolean }>) {
		return produce(state, ds => {
			const { folderId, collapseOthers } = action.payload;
			const expandedBlocks = arrayToObject(state.menus.favorites.expandedBlocks || [], i => i);

			ds.menus.favorites.expandedBlocks = ds.menus.favorites.expandedBlocks || [];
			ds.menus.favorites.folders.forEach(folder => {
				folder.subFolders = folder.subFolders || [];
				// Проверяем подпапки на скрытие
				this.reсursionFolders(folder, folderId, expandedBlocks, collapseOthers, ds, state);

				if (folder.id === folderId) {
					folder.isExpand = !folder.isExpand;
				} else if (collapseOthers) {
					folder.isExpand = false;
				}
				if (folder.isExpand && !expandedBlocks[folder.id]) {
					ds.menus.favorites.expandedBlocks.push(folder.id);
				} else if (!folder.isExpand && expandedBlocks[folder.id]) {
					ds.menus.favorites.expandedBlocks = state.menus.favorites.expandedBlocks.filter(i => i !== folder.id);
				}
				return folder;
			});
		});
	}

	reсursionFolders(folder, folderId, expandedBlocks, collapseOthers, ds, state) {
		folder.subFolders.forEach(subFolder => {
			if (subFolder.id === folderId) {
				subFolder.isExpand = !subFolder.isExpand;
			} else if (collapseOthers) {
				subFolder.isExpand = false;
			}
			if (subFolder.isExpand && !expandedBlocks[subFolder.id]) {
				ds.menus.favorites.expandedBlocks.push(subFolder.id);
			} else if (!subFolder.isExpand && expandedBlocks[subFolder.id]) {
				ds.menus.favorites.expandedBlocks = state.menus.favorites.expandedBlocks.filter(i => i !== subFolder.id);
			}

			if (subFolder.subFolders) {
				this.reсursionFolders(subFolder, folderId, expandedBlocks, collapseOthers, ds, state);
			}
		});
	}
}

// export class DeleteFavoritesMenuItemReducer implements ReducerBase {
// readonly actionType = actions.FacadeNavigationAction.deleteNavItems;
//
// reduce(state: Readonly<INavigationState>, action: IAction<actions.IDeleteNavigationItemPayload>) {
// 	const { items = null, menuId } = action.payload;
// 	if (!items || items.length === 0) {
// 		return state;
// 	}
// 	return produce(state, ds => {
// 		const menu = ds.menus[menuId];
// 		items.forEach(item => {
// 			const navItem = item as INavigationMenuItem;
// 			if (isObject(navItem) && Boolean(navItem.id)) {
// 				delete menu.menuItems[navItem.id];
// 			} else {
// 				delete menu.menuItems[String(item)];
// 			}
// 		});
// 		menu.menuView = buildTreeImmutable(menu.menuItems);
// 	});
// }
// }

export class UpdateFavoritesAssistantMenuItemReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.updateFavoritesAssistantItems;

	reduce(state: Readonly<INavigationState>, action: IAction<actions.IUpdateFavoritesAssistantPayload>) {
		const newState = produce(state, ds => {
			ds.menus.assistantFavorite = action.payload;

			ds.menus.assistantFavorite.menusAssistant.forEach(menu => {
				const currentExpandedBlocks = state.menus.assistantFavorite.menusAssistant.find(
					el => el.userId === menu.userId
				)?.expandedBlocks;

				const expandedBlocks = arrayToObject<any>(currentExpandedBlocks || [], i => i);

				menu.folders.forEach(f => {
					if (expandedBlocks[f.id]) {
						f.isExpand = true;
					} else {
						f.isExpand = false;
					}
					return f;
				});
			});
		});

		return newState;
	}
}

export class ExpandFavoriteFolderAssistantReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.expandFavoriteFolderAssistant;
	reduce(
		state: Readonly<INavigationState>,
		action: IAction<{ folderId: number; collapseOthers: boolean; userId: number }>
	) {
		return produce(state, ds => {
			const { folderId, collapseOthers, userId } = action.payload;

			const currentExpandedBlocks = state.menus.assistantFavorite.menusAssistant.find(
				menu => menu.userId === userId
			).expandedBlocks;
			const expandedBlocks = arrayToObject<any>(currentExpandedBlocks || [], i => i);

			ds.menus.assistantFavorite.menusAssistant.forEach(menu => {
				if (menu.userId === userId) {
					menu.expandedBlocks = menu.expandedBlocks || [];

					menu.folders.forEach(folder => {
						folder.subFolders = folder.subFolders || [];
						// Проверяем подпапки на скрытие
						this.reсursionFolders(folder, folderId, expandedBlocks, collapseOthers, ds, state, userId);

						if (folder.id === folderId) {
							folder.isExpand = !folder.isExpand;
						} else if (collapseOthers) {
							folder.isExpand = false;
						}
						if (folder.isExpand && !expandedBlocks[folder.id]) {
							ds.menus.assistantFavorite.menusAssistant.find(el => el.userId === userId).expandedBlocks.push(folder.id);
						} else if (!folder.isExpand && expandedBlocks[folder.id]) {
							ds.menus.assistantFavorite.menusAssistant.find(el => el.userId === userId).expandedBlocks =
								state.menus.assistantFavorite.menusAssistant
									.find(el => el.userId === userId)
									.expandedBlocks.filter(i => i !== folder.id);
						}
						return folder;
					});
				}
			});
		});
	}

	reсursionFolders(folder, folderId, expandedBlocks, collapseOthers, ds, state, userId) {
		folder.subFolders.forEach(subFolder => {
			if (subFolder.id === folderId) {
				subFolder.isExpand = !subFolder.isExpand;
			} else if (collapseOthers) {
				subFolder.isExpand = false;
			}
			if (subFolder.isExpand && !expandedBlocks[subFolder.id]) {
				ds.menus.assistantFavorite.menusAssistant.find(el => el.userId === userId).expandedBlocks.push(subFolder.id);
			} else if (!subFolder.isExpand && expandedBlocks[subFolder.id]) {
				ds.menus.assistantFavorite.menusAssistant.find(el => el.userId === userId).expandedBlocks =
					state.menus.assistantFavorite.menusAssistant
						.find(el => el.userId === userId)
						.expandedBlocks.filter(i => i !== subFolder.id);
			}

			if (subFolder.subFolders) {
				this.reсursionFolders(subFolder, folderId, expandedBlocks, collapseOthers, ds, state, userId);
			}
		});
	}
}

export const favoritesCrudReducer = [
	CreateFavoritesMenuItemReducer,
	UpdateFavoritesMenuItemReducer,
	ExpandFavoriteFolderReducer,
	// DeleteFavoritesMenuItemReducer,

	UpdateFavoritesAssistantMenuItemReducer,
	ExpandFavoriteFolderAssistantReducer,
];
