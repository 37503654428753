import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ICategoryShort, ISubCategories, ISubcategorySettings } from '@valhalla/data/entities';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { IApiResponse } from '../api-response';
import ApiVersion from '../api-versions';
import { DataHttpCommonService } from '../data-http-common.service';
import type { IEndpointUrlConfig } from '../endpoint.config';
import { EndpointUrlConfig } from '../endpoint.config';
import { INtfTaskTemplate } from '../task';
import {
	ICategoryItem,
	ISelectCategoryCriteria,
	ISubcategoryGraph,
	ISubcategoryGraphParams,
	ISubcategoryHierarchy,
	ISubcategoryHierarchyParams,
} from './abstract';
import {
	CategoryDataHttpService,
	IDataSourceGetSmartExpressions,
	IGetSubcategoryStatesResult,
	ISubategoriesTree,
} from './abstract';
import { ConfigurationDataHttpService } from '@spa/data/http';
import { IDataSourceGridConfigColumn } from '@spa/pages/data-source/data-source-grid/data-source-grid-options';

@Injectable()
export class CategoryDataHttpServiceImpl implements CategoryDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService,
		readonly configurationService: ConfigurationDataHttpService
	) {}

	protected readonly chatSubcatId$ = this.configurationService
		.getAppConfiguration()
		.pipe(map(config => config.chatSubcatId));

	canChat$ = combineLatest([this.subcategoriesTree(), this.chatSubcatId$]).pipe(
		map(([subcatTree, chatSubcatId]) => subcatTree.data.find(c => c.id === chatSubcatId)?.canCreateTask),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	selectCatSubcat(settings: ISelectCategoryCriteria): Observable<ICategoryItem[]> {
		const url = this.common.getApiUrl('selectCatSubcat', ApiVersion.v10);
		return this.http.post<ICategoryItem[]>(url, { settings: settings });
	}

	storageSubCategory(subcatId: number): Observable<ISubCategories> {
		const url = this.common.getApiUrl(`storage/subcategories/${subcatId}`);
		return this.http.get<IApiResponse<ISubCategories>>(url).pipe(map(r => r.data));
	}

	subcategoriesTree(): Observable<ISubategoriesTree> {
		const url = this.common.getApiUrl('subcategories/tree', ApiVersion.v10);
		return this.http.get<ISubategoriesTree>(url);
	}

	subcategoryStates(subcatId: number): Observable<IGetSubcategoryStatesResult> {
		const url = this.common.getApiUrl(`subcategory/${subcatId}/states`, ApiVersion.v10);
		return this.http.get<IGetSubcategoryStatesResult>(url);
	}

	smartExpressions(criteria: IDataSourceGetSmartExpressions) {
		if (criteria?.subcatId) {
			const url = this.common.getApiCoreUrl(`datasource/${criteria?.subcatId}/smartexpressions`);
			return this.http.get<any[]>(url);
		}
		return of([]);
	}

	newTaskTemplate(subcatId: number, sourceTaskId?: number, action?: string): Observable<INtfTaskTemplate> {
		const url = this.common.getApiUrl(`tasks/template`, ApiVersion.v10);
		return this.http.post<INtfTaskTemplate>(url, { subcatId, parentTaskId: sourceTaskId, action: action });
	}

	categoryShortInfo(catId: number): Observable<ICategoryShort> {
		const url = this.common.getApiUrl(`categories/${catId}/short`, ApiVersion.v10);
		return this.http.get<ICategoryShort>(url);
	}

	updateSubcategorySettings(criteria: ISubcategorySettings, subcatId: number) {
		const url = this.common.getApiUrl(`subcategories/${subcatId}/settings/update`);
		return this.http.post<any>(url, criteria);
	}

	subcategoryGraph(params: ISubcategoryGraphParams) {
		const url = this.common.getApiUrl(`subcategories/${params.subcatId}/steps/graph`);
		const qp: Record<string, any> = {};
		if (params.stateId > 0) {
			qp.stateId = params.stateId;
		}
		return this.http
			.get<IApiResponse<ISubcategoryGraph>>(url, {
				params: qp,
			})
			.pipe(map(r => r.data));
	}

	getHierarchy(params: ISubcategoryHierarchyParams) {
		const url = this.common.getApiUrl(`subcategories/${params.subcatId}/hierarchy/data`);
		return this.http.post<IApiResponse<ISubcategoryHierarchy>>(url, params).pipe(map(r => r.data));
	}

	getHierarchyConfig(params: ISubcategoryHierarchyParams): Observable<IDataSourceGridConfigColumn[]> {
		const url = this.common.getApiUrl(`subcategories/${params.subcatId}/hierarchy/config`);
		return this.http.get<IApiResponse<IDataSourceGridConfigColumn[]>>(url).pipe(map(r => r.data));
	}
}
