import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
	selector: 'vh-layout-add-element-modal',
	templateUrl: './add-element-modal.component.html',
	styleUrls: ['./add-element-modal.component.scss'],
})
export class AddElementModalComponent implements OnInit {
	constructor(readonly dialogRef: MatDialogRef<AddElementModalComponent>) {}

	public link: UntypedFormControl;
	public name: UntypedFormControl;

	ngOnInit(): void {
		this.link = new UntypedFormControl();
		this.name = new UntypedFormControl();
	}

	closeAndReturnModalData() {
		this.dialogRef.close({
			link: this.link.value,
			name: this.name.value,
		});
	}

	close() {
		this.dialogRef.close();
	}
}
