import { ChangeDetectorRef, Component, OnInit, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { INavigationMenu, INavigationMenuItem } from '@spa/facade/features/navigation';
import { Router } from '@angular/router';
import { LocalStorageProvider, UrlProvider, ViewDestroyStreamService } from '@valhalla/core';

@Component({
	selector: 'vh-layout-favorites-tasks-panel',
	templateUrl: './favorites-tasks.component.html',
	styleUrls: ['./favorites-tasks.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
	encapsulation: ViewEncapsulation.None,
})
export class FavoritesTasksComponent implements OnInit {
	constructor(
		private _cdr: ChangeDetectorRef,
		readonly router: Router,
		readonly urlBuilder: UrlProvider,
		readonly destroy$: ViewDestroyStreamService,
		readonly localStorage: LocalStorageProvider
	) {}

	@Input()
	menuItems: INavigationMenu[] = [];

	ngOnInit(): void {
		this._cdr.detectChanges();
	}

	onItemClick(menuItem: INavigationMenuItem) {
		this.router.navigate(['/tasks/' + menuItem.linkedObjectId]);
	}
}
