import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'vh-sidebar-header',
	templateUrl: './sidebar-header.component.html',
	styleUrls: ['./sidebar-header.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SidebarHeaderComponent implements OnInit {
	constructor() {}

	@HostBinding('class.vh-sidebar-header')
	hostClassSelector = true;

	@Input()
	title: string;

	@Input()
	showSearch = true;

	@Input()
	showBtnBack = false;

	@Input()
	showBtnAdd = false;

	@Input()
	tippyBtnBack = null;

	@Output()
	searchInput = new EventEmitter();

	@Output()
	searchClear = new EventEmitter();

	@Output()
	clickBtnBack = new EventEmitter();

	@Output()
	addClick = new EventEmitter();

	readonly isSearchActive$ = new BehaviorSubject(false);
	readonly searchFocus$ = new BehaviorSubject(false);

	ngOnInit(): void {}

	setActiveSearch() {
		this.isSearchActive$.next(true);
		this.searchFocus$.next(true);
	}

	setInActiveSearch() {
		this.isSearchActive$.next(false);
		this.searchFocus$.next(false);
	}

	onAddClick() {
		this.addClick.emit();
	}

	onSearchInput(e) {
		this.searchInput.emit(e);
	}

	onSearchClear() {
		this.searchClear.emit();
	}
}
