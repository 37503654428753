<ng-container [ngSwitch]="type">
	<ng-container *ngSwitchCase="AvatarType.icon">
		<ng-container *ngTemplateOutlet="iconTmpl"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="AvatarType.text">
		<ng-container *ngTemplateOutlet="textTmpl"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="AvatarType.image">
		<ng-container *ngTemplateOutlet="imgTmpl; context: { $implicit: imgScr }"> </ng-container>
	</ng-container>
	<ng-content></ng-content>
</ng-container>

<ng-template #iconTmpl>
	<vh-content-icon
		[name]="contentIconName"
		[noDefaultSize]="true"
		[containerColor]="textColorFromMap"
	></vh-content-icon>
</ng-template>

<ng-template #textTmpl>
	<ng-container>
		<div class="vh-avatar__color-text" [ngClass]="{ fontClassFromMap: fontClassFromMap }">
			<span>{{ avatarText | avatarLetters | uppercase }}</span>
		</div>
	</ng-container>
</ng-template>

<ng-template #imgTmpl let-src>
	<img
		[style.display]="brokenImagePlaceholder ? 'none' : 'block'"
		#img
		[loading]="imgLoading"
		[src]="src"
		vhSkeletonImgLoader
		[skeletonImgLoaderWidth]="sizeFromMap"
		[skeletonImgLoaderBorderRadius]="borderRadiusFromMap"
		(error)="handleErrorImageLoad()"
		(load)="brokenImagePlaceholder = false"
	/>
	<vh-content-icon
		*ngIf="brokenImagePlaceholder"
		[name]="'browser-internet-web-404-error'"
		[noDefaultSize]="true"
		[containerColor]="textColorFromMap"
	></vh-content-icon>
</ng-template>
