import { OnDestroy, Type } from '@angular/core';
import { Subject } from 'rxjs';

import { ViewDestroyStreamService } from './view-destroy-stream.service';

export interface IViewDestroyableService {
	viewDestroy$: ViewDestroyStreamService;
}

export type ClassDecorator<T extends new (...args: any[]) => {} = any> = (target: T) => T;
export interface IViewService {
	readonly destroy$: Subject<any>;
	destroy(): void;
	init(): void;
}

const __initialized__ = Symbol();

export const ViewService: () => ClassDecorator = () => {
	return (original: Type<IViewService>) => {
		const DecoratedViewService = class extends original implements OnDestroy {
			constructor(...args: any[]) {
				super(...args);
				// Promise.resolve()
				// 	.then(() => {
				// 		if (!this[__initialized__] && typeof super.init === 'function') {
				// 			super.init();
				// 			this[__initialized__] = true;
				// 		}
				// 	})
				// 	.catch(console.error);
				setTimeout(() => {
					if (!this[__initialized__] && typeof super.init === 'function') {
						super.init();
						this[__initialized__] = true;
					}
				});
			}

			ngOnDestroy() {
				try {
					if (typeof super.destroy === 'function') {
						super.destroy();
					}
					if (typeof super['ngOnDestroy'] === 'function') {
						super['ngOnDestroy']();
					}
				} catch (err) {
					console.error(err);
				} finally {
					if (this.destroy$) {
						this.destroy$.next(0 as any);
						this.destroy$.complete();
					}
				}
			}
		};

		DecoratedViewService['ɵprov'] = original['ɵprov'];
		DecoratedViewService['ɵfac'] = original['ɵfac'];

		const hasIvyMeta = Boolean(original['ɵprov'] || original['ɵfac']);

		if (!hasIvyMeta && (Reflect as any).defineMetadata) {
			const lenDesc = Object.getOwnPropertyDescriptor(DecoratedViewService, 'length');
			Object.defineProperty(DecoratedViewService, 'length', {
				configurable: lenDesc.configurable,
				get() {
					return original.length;
				},
			});
			(Reflect as any).defineMetadata(
				'design:paramtypes',
				(Reflect as any).getOwnMetadata('design:paramtypes', original),
				DecoratedViewService
			);
		}

		return DecoratedViewService;
	};
};
