import {
	Directive,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { FeatherSvgLoader } from './feather-svg-loader.service';

@Directive({ selector: '[vhFeatherIcon]' })
export class FeatherInlineIconDirective implements OnInit, OnChanges, OnDestroy {
	constructor(
		readonly vcr: ViewContainerRef,
		readonly elRef: ElementRef<HTMLElement>,
		readonly svgLoader: FeatherSvgLoader
	) {}

	@Input('vhFeatherIcon')
	vhFeatherIconName: string;

	@HostBinding('class.feather-icon-host')
	featherIconHostClass = true;

	@Input('vhFeatherIconSize')
	size: number;

	@HostBinding('class.reset-icon-size')
	get isResetIconSize() {
		return this.size > 0;
	}

	protected destroy$ = new Subject();

	ngOnInit() {
		this.setupSvg();
	}

	ngOnDestroy() {
		this.destroy$.next(0 as any);
		this.destroy$.complete();
		this.destroy$ = null;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.vhFeatherIconName && !changes.vhFeatherIconName.firstChange) {
			this.setupSvg();
		}
	}

	setupSvg() {
		if (!this.vhFeatherIconName) {
			return;
		}

		this.svgLoader
			.get(this.vhFeatherIconName)
			.pipe(take(1), takeUntil(this.destroy$))
			.subscribe({
				next: svg => {
					this.setSvgHtml(svg);
				},
				error: () => {
					// tslint:disable-next-line:no-console
					console.warn(`Feather icon ${this.vhFeatherIconName} is not loaded!`);
				},
			});
	}

	setSvgHtml(svg: string) {
		this.elRef.nativeElement.innerHTML = svg;
		if (this.isResetIconSize) {
			this.setupSize(this.size);
		}
	}

	setupSize(size: number) {
		if (!this.elRef.nativeElement.firstElementChild) {
			return;
		}
		const svgEl = this.elRef.nativeElement.firstElementChild as HTMLElement;
		svgEl.style.width = svgEl.style.height = `${this.size}px`;
		const host = this.elRef.nativeElement;
		host.style.width = host.style.height = `${this.size}px`;
	}
}
