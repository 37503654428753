import { notNaN } from './filters';
import { linkRegexp } from './link';

export function findTaskIdsFromText(text: string): number[] {
	const linksExcluded = text.replace(linkRegexp(), '');
	const match = linksExcluded.match(/(( )?#\b\d+\b)/gm);
	if (!match) {
		return [];
	}
	const tokens = [...(match || [])].map(token => token.trim()) || [];
	const result = tokens.map(token => Number(token.replace('#', ''))).filter(notNaN);

	return result;
}
