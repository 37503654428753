import { Provider } from '@angular/core';

import { NGDI_CONF_APPS_ACTIVATOR } from './activator';
import { NGDI_CONF_APPS_DB } from './db';
import { NGDI_CONF_APPS_MANAGER } from './manager';
import { NGDI_CONF_USER_QUICK_APPS } from './user';

export const NGDI_CONF_FACADE_APPS: Provider[] = [
	...NGDI_CONF_APPS_ACTIVATOR,
	...NGDI_CONF_APPS_DB,
	...NGDI_CONF_APPS_MANAGER,
	...NGDI_CONF_USER_QUICK_APPS
];
