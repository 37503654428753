/* eslint-disable no-restricted-syntax */
import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	ContentChildren,
	ElementRef,
	EmbeddedViewRef,
	EventEmitter,
	HostBinding,
	Input,
	NgZone,
	OnInit,
	Output,
	QueryList,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { ViewDestroyStreamService } from '@valhalla/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { FluentBoxDirective } from './fluent-box-item.directive';
import { FluentBoxMoreDirective } from './fluent-box-more.directive';

@Component({
	selector: 'vh-common-fluent-box',
	templateUrl: './fluent-box.component.html',
	styleUrls: ['./fluent-box.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class FluentBoxComponent implements OnInit, AfterViewInit, AfterViewChecked {
	constructor(
		readonly elRef: ElementRef<HTMLElement>,
		readonly destroy$: ViewDestroyStreamService,
		readonly vcr: ViewContainerRef,
		readonly zone: NgZone,
		readonly cdr: ChangeDetectorRef
	) {}

	@Input()
	@HostBinding('attr.direction')
	direction: 'row' | 'column' = 'row';

	@Input()
	@HostBinding('attr.align')
	align: 'left' | 'right' | 'top' | 'bottom' = 'right';

	@HostBinding('class.vh-common-fluent-box')
	hostClassSelector = true;

	@Input()
	itemHeight: number;

	@Input()
	itemWidth: number;

	@Input()
	debounceTime = 0;

	@Input()
	container: HTMLElement;

	@Input()
	bufferBasis = 0;

	@Input()
	hideAllToMore: boolean;

	@Output()
	visibleChange = new EventEmitter();

	@ViewChild('content', { read: ViewContainerRef })
	thisHost: ViewContainerRef;

	@ContentChildren(FluentBoxDirective)
	items: QueryList<FluentBoxDirective>;

	@ViewChild('moreContent', { read: ViewContainerRef })
	moreContainer: ViewContainerRef;

	@ContentChild(FluentBoxMoreDirective, { read: TemplateRef })
	moreContentTemplate: TemplateRef<any>;

	protected childrenEmbeddedViewRef: EmbeddedViewRef<any>[] = [];
	protected firstRender = false;
	readonly allVisible$ = new BehaviorSubject(false);
	readonly itemsInMore$ = new BehaviorSubject([]);
	height: any;
	width: any;

	get itemsArray() {
		return this.items?.toArray() || [];
	}

	get itemsInMore() {
		return this.itemsInMore$.value;
	}

	get allVisible() {
		return this.allVisible$.value;
	}

	get disappearFrom() {
		return ['top', 'left'].some(a => a === this.align) ? 'end' : 'begin';
	}

	ngOnInit() {
		if (this.direction === 'column' && !['top', 'bottom'].some(a => a === this.align)) {
			this.align = 'top';
		}
		if (this.direction === 'row' && !['left', 'right'].some(a => a === this.align)) {
			this.align = 'left';
		}
	}

	detectChanges() {
		this.cdr.detectChanges();
	}

	ngAfterViewInit() {
		this.zone.runOutsideAngular(() => {
			let resize$ = this.createResizeObserver(this.container || this.elRef.nativeElement);
			if (this.debounceTime) {
				resize$ = resize$.pipe(debounceTime(this.debounceTime));
			}
			resize$.pipe(takeUntil(this.destroy$)).subscribe(e => {
				this.zone.run(() => this.onHostResize(e));
			});
		});
		this.items.changes.pipe(takeUntil(this.destroy$)).subscribe(() =>
			this.onHostResize({
				size: {
					width: this.width,
					height: this.height,
				},
			})
		);
	}

	ngAfterViewChecked() {
		this.checkMoreContainer();
		if (this.hideAllToMore) {
			this.clearItems();
		}
	}

	protected emitVisibleChanged() {
		const allVisible = this.childrenEmbeddedViewRef.length >= this.items.length && !this.hideAllToMore;
		this.allVisible$.next(allVisible);
		this.checkMoreContainer();
		if (allVisible) {
			this.itemsInMore$.next([]);
		} else {
			let moreItems = this.itemsArray.slice(this.childrenEmbeddedViewRef.length);
			if (this.disappearFrom === 'end') {
				moreItems = this.itemsArray.slice(
					-this.itemsArray.length,
					this.itemsArray.length - this.childrenEmbeddedViewRef.length
				);
			}
			this.itemsInMore$.next(moreItems);
		}
		this.visibleChange.emit();
		this.detectChanges();
	}

	protected checkMoreContainer() {
		if (this.allVisible$.value && !this.hideAllToMore) {
			this.moreContainer?.clear();
		} else {
			if (this.moreContainer?.length === 0 && this.moreContentTemplate) {
				this.moreContainer?.createEmbeddedView(this.moreContentTemplate);
			}
		}
		this.detectChanges();
	}

	protected redrawItems(items: TemplateRef<any>[]) {
		this.clearItems();
		this.addItems(items);
		this.firstRender = true;
	}

	protected addItems(items: TemplateRef<any>[]) {
		const newest = items.map(item => {
			if (this.direction === 'row') {
				return this.thisHost.createEmbeddedView(item, null, 0);
			} else {
				return this.thisHost.createEmbeddedView(item);
			}
		});
		newest.forEach(eViewRef => {
			const el = this.getItemElement(eViewRef);
			el?.classList.add('vh-common-fluent-box__item');
		});
		this.childrenEmbeddedViewRef.push(...newest);
		this.emitVisibleChanged();
	}

	protected removeItemsFromIdx(fromIndex: number) {
		const renderedLen = this.childrenEmbeddedViewRef.length;
		let idx = fromIndex;
		if (this.disappearFrom === 'begin') {
			while (idx < renderedLen) {
				// @see https://github.com/angular/angular/issues/38201
				this.childrenEmbeddedViewRef[idx]?.destroy();
				idx++;
			}
			this.childrenEmbeddedViewRef = this.childrenEmbeddedViewRef.slice(0, fromIndex);
		}

		if (this.disappearFrom === 'end') {
			let idx2 = 0;
			while (idx < renderedLen) {
				this.childrenEmbeddedViewRef[idx2]?.destroy();
				idx2++;
				idx++;
			}
			this.childrenEmbeddedViewRef = this.childrenEmbeddedViewRef.slice(-fromIndex);
		}

		this.emitVisibleChanged();
	}

	protected clearItems() {
		this.thisHost.clear();
		this.childrenEmbeddedViewRef = [];
		this.emitVisibleChanged();
	}

	protected onHostResize(e) {
		const { height, width } = e?.size || {};
		this.height = height;
		this.width = width;

		if ([height, width].some(i => i === undefined)) {
			if (!this.firstRender) {
				console.debug(this.debugMsg(`can't calculating height or width of self container`));
				this.redrawItems(this.itemsArray.map(i => i.templateRef));
			}
			return;
		}

		if (this.isDirection(this.direction, 'row')) {
			if (!this.itemWidth && !this.firstRender) {
				console.debug(this.debugMsg(`itemWidth must be set for row direction`));
			}
		} else {
			if (!this.itemHeight && !this.firstRender) {
				console.debug(this.debugMsg(`itemHeight must be set for column direction`));
			}
		}

		const maxItemsCount = this.isDirection(this.direction, 'row')
			? Math.floor((width + this.bufferBasis) / this.itemWidth)
			: Math.floor((height + this.bufferBasis) / this.itemHeight);

		if (isNaN(maxItemsCount)) {
			throw new Error(`Can not calculate 'maxItemsCount', check itemWidth, itemHeight properties`);
		}

		const renderedLen = this.childrenEmbeddedViewRef.length;

		if (!this.firstRender) {
			let renderItems = this.itemsArray.slice(0, maxItemsCount);
			if (this.disappearFrom === 'end') {
				renderItems = this.itemsArray.slice(-maxItemsCount, maxItemsCount - renderedLen);
			}
			this.redrawItems(renderItems.map(i => i.templateRef));
		} else if (maxItemsCount !== renderedLen) {
			if (maxItemsCount === 0) {
				this.clearItems();
			} else if (maxItemsCount < renderedLen) {
				this.removeItemsFromIdx(maxItemsCount);
			} else {
				let addToRender = this.itemsArray.slice(renderedLen, maxItemsCount);
				if (this.disappearFrom === 'end') {
					addToRender = this.itemsArray.slice(-maxItemsCount);
				}

				if (this.direction === 'column') {
					this.clearItems();
				}

				this.addItems(addToRender.map(i => i.templateRef));
			}
		}
	}

	protected isDirection(val: string, dir: typeof FluentBoxComponent.prototype.direction) {
		return val === dir;
	}

	protected debugMsg(msg: string) {
		return `[vh-common-fluent-box]: ${msg}`;
	}

	protected getItemElement(item: EmbeddedViewRef<any>) {
		return item?.rootNodes[0] as HTMLElement;
	}

	protected createResizeObserver(el: HTMLElement) {
		return new Observable<any>(observer => {
			const ResizeObserver = (window as any).ResizeObserver;
			const ro = new ResizeObserver(entries => {
				// @see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#answer-58701523
				window.requestAnimationFrame(() => {
					if (!Array.isArray(entries) || !entries.length) {
						return;
					}
					if (!this.hideAllToMore) {
						for (const entry of entries) {
							const { left, top, width, height, bottom, right } = entry.contentRect,
								{ target } = entry;
							observer.next({
								target,
								size: {
									unit: 'px',
									width,
									height,
								},
								paddings: { top, bottom, right, left, unit: 'px' },
							});
						}
					}
				});
			});
			ro.observe(el);
			return () => {
				ro.unobserve(el);
				ro.disconnect();
			};
		});
	}
}
