import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { DataHttpCommonService } from '../data-http-common.service';
import { IApiResponse } from '../api-response';
import { catchError, map } from 'rxjs/operators';
import { ISubtaskRootResponse, ISubtasksData, SubtasksHttpService } from './abstract';

@Injectable()
export class SubtasksHttpServiceImpl implements SubtasksHttpService {
	constructor(public readonly http: HttpClient, public readonly common: DataHttpCommonService) {}

	getSubtasks(
		id: string | number,
		skip: number,
		take: number,
		getRootTask: boolean,
		allLevels = false,
		allowClosed = true
	): Observable<ISubtasksData> {
		const url = this.common.getApiUrl(`tasks/subtasks/${id}`);
		const requestDto = {
			skip: skip,
			take: take,
			getRootTask: getRootTask,
			allLevels: allLevels,
			allowClosed: allowClosed,
		};
		return this.http.post<IApiResponse<ISubtasksData>>(url, requestDto).pipe(
			map((result: IApiResponse<ISubtasksData>) => result.data),
			catchError(err => {
				console.error(err);
				return of(null);
			})
		);
	}

	deleteSubtask(taskId: number, subtaskId: number): Observable<any> {
		const url = this.common.getApiUrl(`tasks/subtasks/removesubtask/${taskId}/${subtaskId}`);
		return this.http.get<any>(url);
	}

	getRootId(taskId: number): Observable<number> {
		const url = this.common.getApiUrl(`tasks/subtasks/roottask/${taskId}`);
		return this.http.get<IApiResponse<number>>(url).pipe(
			map((result: IApiResponse<number>) => result.data),
			catchError(err => {
				console.error(err);
				return of(null);
			})
		);
	}

	getRootWithPathId(taskId: number): Observable<ISubtaskRootResponse> {
		const url = this.common.getApiUrl(`tasks/subtasks/root-with-path/${taskId}`);
		return this.http.get<IApiResponse<ISubtaskRootResponse>>(url).pipe(
			map((result: IApiResponse<ISubtaskRootResponse>) => result.data),
			catchError(err => {
				console.error(err);
				return of(null);
			})
		);
	}
}
