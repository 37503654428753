export enum IBageContent {
	text = 'text',
	number = 'number',
}

export enum IBadgeEmphasis {
	accent = 'accent',
	container = 'container',
}

export enum IBadgeColor {
	primary = 'primary',
	accent = 'accent',
	info = 'info',
	success = 'success',
	warning = 'warning',
	fav = 'fav',
	danger = 'danger',
	error = 'error',
	default = 'default',
	blackWhite = 'blackWhite',
}
