import { NgModule, ModuleWithProviders } from '@angular/core';

import { FormatDateService } from './abstract/formatDate.service';
import { FormatNumberService } from './abstract/formatNumber.service';
import { AngularKendoFormatDateServiceImpl } from './angularKendoFormatDate.service';
import { AngularKendoFormatNumberServiceImpl } from './angularKendoFormatNumber.service';
import { FormatDateKendoPipe } from './format-date-kendo.pipe';
import { FormatNumberKendoPipe } from './format-number-kendo.pipe';

// fixme: load on demand
// tslint:disable-next-line:max-line-length
// @see https://www.telerik.com/kendo-angular-ui/components/globalization/internationalization/loading-data/#toc-loading-locale-data-on-demand
// import '@progress/kendo-angular-intl/locales/ru/all';

@NgModule({
	declarations: [FormatDateKendoPipe, FormatNumberKendoPipe],
	exports: [FormatDateKendoPipe, FormatNumberKendoPipe],
})
export class FormattingModule {
	static forRoot(): ModuleWithProviders<FormattingModule> {
		return {
			ngModule: FormattingModule,
			providers: [
				{
					provide: FormatNumberService,
					useClass: AngularKendoFormatNumberServiceImpl,
				},
				{
					provide: FormatDateService,
					useClass: AngularKendoFormatDateServiceImpl,
				},
			],
		};
	}
}
