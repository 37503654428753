import { Observable } from 'rxjs';
import { IComment, ICommentReaction, ITaskInfo, IUserShort } from '@valhalla/data/entities';

export abstract class CommentsDataHttpService {
	abstract getCommentViewers(criteria: IGetCommentViewersCriteria): Observable<IGetCommentViewersResult>;
	abstract markCommentAsUnread(commentId: number): Observable<any>;
	abstract getCommentsSlice(criteria: IGetCommentsOffsetCriteria): Observable<IGetCommentsOffsetResult>;

	abstract getComment(commentId: number): Observable<Partial<IComment>>;

	abstract delete(...ids: number[]): Observable<any>;

	abstract edit(model: IEditCommentsCriteria): Observable<any>;

	abstract getPreviousTaskText(commentId: number): Observable<IGetPreviousTaskTextResult>;

	abstract markCommentsAsNotMyQuestionRest(commentId: number): Observable<any>;
	abstract removeCommentToFavoriteRest(criteria: ICommentFavoriteDeleteCriteria): Observable<any>;
	abstract addCommentToFavoriteRest(criteria: ICommentFavoriteCriteria): Observable<any>;
	abstract markCommentsAsReadRest(...commentIds: number[]): Observable<any>;
	abstract markCommentAsQuestionRest(criteria: IMarkCommentAsQuestionCriteria): Observable<any>;
	abstract markCommentsAsAnsweredRest(commentId: number): Observable<any>;
	abstract addReaction(params: ICommentAddReactionParams): Observable<any>;
	abstract removeReaction(params: ICommentRemoveReactionParams): Observable<any>;
	abstract allowedReactions(): Observable<IAllowedReactions>;
	abstract commentReactions(params: ICommentReactionsParams): Observable<ICommentReaction[]>;
}

export interface ICommentReactionsParams {
	commentId: number;
	reactionId?: number;
}

export interface IAllowedReactions {
	reactions: IReactionDescription[];
	groups: any[];
}

export interface IReactionDescription {
	id: number;
	isFavourite: boolean;
	emoji: string;
	name: string;
	description: string;
	groupId: number;
	subGroupId: number;
}

export interface ICommentAddReactionParams {
	commentId: number;
	reactionId: number;
}

export interface ICommentRemoveReactionParams {
	commentId: number;
}

export interface IGetPreviousTaskTextResult {
	newTaskText: string;
	oldTaskText: string;
}

export interface IEditCommentsCriteria {
	commentId: number;
	text: string;
	asQuestion?: true;
	recipientIds?: number[];
	recipientGroupsIds?: number[];
	copyRecipientsIds?: number[];
	copyRecipientGroupsIds?: number[];
	updateRecipients?: boolean;
}

export interface IGetCommentViewersCriteria {
	commentId: number;
	searchInArchiveIfNotFound?: boolean;
}

export interface IGetCommentViewersResult {
	users: IGetCommentViewerResult[];
}

export interface IGetCommentViewerResult {
	user: Partial<IUserShort>;
	seenAt: string;
}

export interface IMarkCommentAsQuestionCriteria {
	commentIds: number[];
}

export interface ICommentFavoriteCriteria {
	commentId: number;
	color?: string;
	forAllTaskSubscribers?: boolean;
}

export interface ICommentFavoriteDeleteCriteria {
	commentId: number;
	forAllTaskSubscribers?: boolean;
}

export interface IGetCommentsOffsetCriteria {
	commentId?: number;
	limitBefore?: number;
	limitAfter?: number;
	taskInfo?: boolean;
	taskId?: number;
	selectedTypes?: number[];
	/**включения/выключения фильтра комментариев по всем типам сыобытий */
	useFeedFilterSettings?: boolean;
	/**из связанных */
	forTaskTree?: boolean;
	/**из подзадач */
	forLinkedTasks?: boolean;
	searchString?: string;
	searchType?: string;
	includeReactions?: boolean;
}

export interface IGetCommentsOffsetResult extends Omit<Partial<ITaskInfo>, 'comments'> {
	comments: Array<Partial<IComment>>;
	tasks?: Partial<ITaskInfo>[];
}
