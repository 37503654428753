import { BehaviorSubject, exhaustMap, Observable, skip, Subject, switchMap, take, takeUntil } from 'rxjs';
import { booleanFilter } from '@valhalla/utils';

export type ValueStore = ReturnType<typeof createValueStore>;

export type CreateValueStoreOptions<T> = {
	initialValue?: T;
	updater?: () => Observable<T>;
	truthyValue?: boolean;
	invalidate?: number;
};

export function createValueStore<T>({ initialValue, updater, truthyValue, invalidate }: CreateValueStoreOptions<T>) {
	let lastInvalidate = Date.now();
	let store$ = new BehaviorSubject(initialValue);
	let destroy$ = new Subject<void>();
	let updateNotify$ = new Subject<any>();
	let value$ = store$.pipe(
		switchMap(() => {
			if (invalidate && Date.now() - lastInvalidate > invalidate && !updater?.length) {
				updateNotify$.next([]);
				return store$.pipe(skip(1));
			}
			return store$;
		})
	);
	if (truthyValue) {
		value$ = value$.pipe(booleanFilter());
	}
	value$ = value$.pipe(takeUntil(destroy$));
	updateNotify$
		.pipe(
			exhaustMap(params => updater.call(null, ...params).pipe(take(1))),
			takeUntil(destroy$)
		)
		.subscribe({
			next: (val: any) => {
				lastInvalidate = Date.now();
				store$.next(val);
			},
			error: console.error,
		});
	if (!updater?.length) {
		updateNotify$.next([]);
	}
	return {
		update(...params: any[]) {
			if (typeof updater !== 'function') {
				return;
			}
			updateNotify$.next(params);
		},
		destroy() {
			if (store$) {
				destroy$.next();
				destroy$.complete();
				destroy$ = null;
				store$.complete();
				store$ = null;
				updateNotify$.complete();
				updateNotify$ = null;
			}
		},
		get value() {
			return store$?.value;
		},
		value$,
	};
}
