import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject, Subscriber } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';

import { IUiMonacoEditorOptions, UI_MONACO_EDITOR_OPTIONS } from './ui-monaco-editor-options';
import { IUiMonacoEditor } from './ui-monaco-editor-ref';

@Component({
	selector: 'vh-ui-monaco-editor',
	templateUrl: 'ui-monaco-editor.component.html',
	styleUrls: ['ui-monaco-editor.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiMonacoEditorComponent implements OnInit, AfterViewInit, OnDestroy {
	constructor(
		@Inject(UI_MONACO_EDITOR_OPTIONS) readonly options: IUiMonacoEditorOptions,
		readonly elRef: ElementRef<HTMLElement>,
		readonly cdr: ChangeDetectorRef
	) {}

	static dependenciesLoaded = false;
	static monaco: any;

	@HostBinding('class.vh-ui-monaco-editor')
	hostClassSelector = true;

	@Input()
	set lang(value: string) {
		this._lang = value;
		if ((window as any).monaco && this.editorRef) {
			(window as any).monaco.editor.setModelLanguage(this.editorRef.getModel(), value);
		}
	}
	get lang(): string {
		return this._lang;
	}

	@Input()
	set readOnly(value: boolean) {
		this._readOnly = value;
		if (this.editorRef) {
			this.editorRef.updateOptions({ readOnly: this._readOnly });
		}
	}
	get readOnly(): boolean {
		return this._readOnly;
	}

	@Output()
	ready = new EventEmitter<UiMonacoEditorComponent>();

	@Output()
	contentChange = new EventEmitter<string>();

	protected editorRef: IUiMonacoEditor;
	protected domReady$ = new BehaviorSubject(false);
	protected contentChanged$ = new Subject();
	protected destroy$ = new Subject();

	_lang: string;

	_readOnly = false;

	get editor() {
		return this.editorRef;
	}

	ngOnInit() {
		this.initializeEditor();
		this.contentChanged$.pipe(debounceTime(200), takeUntil(this.destroy$)).subscribe(() => {
			const value = this.editorRef.getValue();
			this.contentChange.emit(value);
		});
	}

	ngAfterViewInit() {
		this.domReady$.next(true);
	}

	ngOnDestroy() {
		this.destroy$.next(0 as any);
		this.destroy$.complete();
		this.editorRef?.dispose();
	}

	initializeEditor() {
		this.validateRequirements();
		combineLatest([this.loadMonaco(), this.domReady$.pipe(filter(Boolean))])
			.pipe(
				take(1),
				map(([monaco]) => monaco),
				takeUntil(this.destroy$)
			)
			.subscribe(monaco => {
				this.editorRef = monaco.editor.create(this.elRef.nativeElement, {
					language: this.lang,
					readOnly: this.readOnly,
					wordWrap: 'on',
					automaticLayout: true,
				});
				setTimeout(() => {
					this.editorRef.getModel().onDidChangeContent(() => {
						this.contentChanged$.next(0 as any);
					});
				});
				setTimeout(() => {
					this.ready.emit(this);
				});
				setTimeout(() => this.editorRef.focus());
			});
	}

	setValue(data: any) {
		const isString = typeof data === 'string';
		const str = isString ? data : this.tryStringify(data);
		str && this.editorRef.setValue(str);
	}

	getValue(): string {
		return this.editorRef.getValue();
	}

	selectAll() {
		this.focus();
		const range = this.editorRef.getModel().getFullModelRange();
		this.editorRef.setSelection(range);
	}

	focus() {
		this.editorRef.focus();
		this.editorRef.setPosition(this.editorRef.getPosition());
	}

	protected tryStringify(data: any) {
		try {
			return JSON.stringify(data, null, 2);
		} catch (error) {
			console.error(error);
		}
	}

	private initConfig(observer: Subscriber<unknown>) {
		try {
			const require = (window as any).require;
			if (require) {
				require.config({ paths: { vs: this.options.monacoVsPath } });
				require(['vs/editor/editor.main'], () => {
					observer.next(0 as any);
					observer.complete();
				});
			} else {
				setTimeout(() => this.initConfig(observer), 100);
			}
		} catch (err) {
			observer.error(err);
		}
	}

	protected loadMonaco() {
		if (UiMonacoEditorComponent.dependenciesLoaded) {
			return of(UiMonacoEditorComponent.monaco);
		}

		const loadMonaco$ = new Observable(this.initConfig.bind(this));

		return this.options.loadScripts(this.options.monacoLoaderPath).pipe(
			mergeMap(() => loadMonaco$),
			tap(() => {
				UiMonacoEditorComponent.dependenciesLoaded = true;
				UiMonacoEditorComponent.monaco = (window as any).monaco;
			}),
			map(() => UiMonacoEditorComponent.monaco),
			catchError(err => {
				this.error(err);
				return EMPTY;
			})
		);
	}

	protected validateRequirements() {
		if (!this.lang) {
			this.riseError('@Input() lang is not provided');
		}
		if (!this.options?.loadScripts) {
			this.riseError('loadScripts is not provided in options');
		}
		if (!this.options?.monacoLoaderPath) {
			this.riseError('monacoLoaderPath is not provided in options');
		}
		if (!this.options?.monacoVsPath) {
			this.riseError('monacoVsPath is not provided in options');
		}
	}

	protected riseError(msg: string, err?: any): never {
		throw new Error(this.msgPrefix(msg));
	}

	protected msgPrefix(msg: string) {
		return `[ui-monaco-editor] ${msg}`;
	}

	protected error(err: any) {
		console.group(this.msgPrefix(''));
		console.error(err);
		console.groupEnd();
	}
}
