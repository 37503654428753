import { Observable } from 'rxjs';
export abstract class SubtasksHttpService {
	abstract getSubtasks(
		taskId: string | number,
		startRow?: number,
		endRow?: number,
		getRootTask?: boolean,
		allLevels?: boolean,
		allowClosed?: boolean
	): Observable<ISubtasksData>;
	abstract deleteSubtask(taskId: number, subtaskId: number): Observable<any>;
	abstract getRootId(taskId: number): Observable<number>;
	abstract getRootWithPathId(taskId: number): Observable<ISubtaskRootResponse>;
}

export interface ISubtask {
	id: number;
	parentId?: number;
	description: string;
	stateName: string;
	isClosed: boolean;
	subCat: string;
	hasChildren: boolean;
	group?: boolean;
	hasRightsToUnlink: boolean;
	subtaskId: number;
	percentDone: number;
	children?: ISubtask[];
	expanded?: boolean;
	startDate?: Date;
	endDate?: Date;
	performer?: string;
	owner?: string;
}

export interface ISubtasksData {
	subtasks?: ISubtask[];
	lastRow: number;
}

export interface ISubtaskRootResponse {
	rootTaskId: number;
	path: string;
}
