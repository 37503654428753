import { Platform } from '@angular/cdk/platform';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	Input,
	NgZone,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INavigationMenuItem, MenuItemActionType, MenuItemType } from '@spa/common/components/navigation';
import { PerfectScrollbarDirective } from '@spa/common/directives';
import { MatThemePaletteEnum } from '@spa/common/material';
import { SidebarService } from '@spa/common/services/sidebar.service';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { INavigationMenuTab, NavigationFeatureProvider, NavigationMenu } from '@spa/facade/features/navigation';
import { UserSettingsFacadeProvider } from '@spa/facade/features/user-settings';
import { ILayoutNavbarState } from '@spa/facade/layout/state';
import { OpenAdminPanelEvent } from '@spa/facade/open-admin-panel-event';
import {
	AuthService,
	EventBusService,
	PlatformDetectorProvider,
	SessionUser,
	UrlProvider,
	ViewDestroyStreamService,
} from '@valhalla/core';
import {
	DataHttpService,
	FavoritesDataHttpService,
	IAddToFavoritesRequest,
	isVisibleElement,
} from '@valhalla/data/http';
import { BehaviorSubject, combineLatest, fromEvent, Subject } from 'rxjs';
import { debounceTime, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { LayoutFacade } from '@spa/facade/layout';
import { NavPanelEmailComponent } from '../../../nav-panel-email';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { NavPanelEmailService } from '../../../nav-panel-email/nav-panel-email.service';
import { IBadgeColor } from 'libs/core-components/src/lib/badge/badge.model';
import { CultureService } from '@spa/localization';
@Component({
	selector: 'vh-layout-navbar-vertical-style-2',
	templateUrl: './style-2.component.html',
	styleUrls: ['./style-2.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class NavbarVerticalStyle2Component implements OnInit, AfterViewInit {
	constructor(
		readonly fuseSidebarService: SidebarService,
		readonly router: Router,
		readonly userInfo: SessionUser,
		readonly url: UrlProvider,
		readonly destroy$: ViewDestroyStreamService,
		readonly zone: NgZone,
		readonly navigationState: NavigationFeatureProvider,
		readonly auth: AuthService,
		readonly activatedRoute: ActivatedRoute,
		readonly favoritesDataHttpService: FavoritesDataHttpService,
		readonly navigationFeature: NavigationFeatureProvider,
		readonly platformDetector: PlatformDetectorProvider,
		readonly server: DataHttpService,
		readonly userSettingsStore: UserSettingsFacadeProvider,
		readonly modal: ModalWindowsService,
		readonly eventsBus: EventBusService,
		private readonly _layout: LayoutFacade,
		readonly platform: Platform,
		readonly mobileView: MobileViewService,
		readonly navPanelEmailService: NavPanelEmailService,
		readonly culture: CultureService
	) {
		this.hideSideBarOnResize();
	}
	@Input()
	showHeader = true;

	@Input()
	showUserHeader = true;

	@Input()
	showLogo = true;

	@Input()
	@HostBinding('class.show-navbar-mini')
	showNavBarMini = false;

	@Input()
	layoutState: ILayoutNavbarState;

	@Input()
	useCustomColors = true;

	@ViewChild(PerfectScrollbarDirective)
	fusePerfectScrollbar: PerfectScrollbarDirective;

	@ViewChild('navScrollContainer')
	navScrollContainer: ElementRef<HTMLElement>;

	@ViewChild(NavPanelEmailComponent)
	navPanelEmailComponent: NavPanelEmailComponent;

	readonly resizeHandler$ = fromEvent(window, 'resize').pipe(debounceTime(200));

	menuTabs = NavigationMenu;

	readonly isReincarnateMode$ = this.auth.isReincarnateMode$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));
	protected readonly NavigationMenu = NavigationMenu;

	readonly isAdmin$ = this.auth.isAdmin$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));
	readonly categoryVisible$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.categoryVisible))
	);
	readonly personalVisible$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.personalVisible))
	);
	readonly favoriteVisible$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.favoriteVisible))
	);
	readonly reportVisible$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.reportVisible))
	);
	readonly mailVisible$ = this.userSettingsStore.selectUserSettings$.pipe(map(s => isVisibleElement(s.mailVisible)));

	readonly appConfig$ = this.server.config.getAppConfiguration();
	readonly spaceSettings$ = this.appConfig$.pipe(map(config => config.spaceSettings));

	readonly spacesVisible$ = combineLatest([this.spaceSettings$, this.userSettingsStore.selectUserSettings$]).pipe(
		map(([spaceSettings, userSetting]) => spaceSettings?.isActive && isVisibleElement(userSetting.spacesVisible))
	);

	readonly menuTabs$ = this.navigationState.tabsMenu$.pipe(takeUntil(this.destroy$));

	readonly showNavigationAlways$ = this._layout.state$.pipe(
		map(s => {
			this.toolTipChecked = s.showNavigationAlways;
			return s.showNavigationAlways;
		})
	);

	readonly menuTabsFiltered$ = combineLatest([
		this.isAdmin$,
		this.categoryVisible$,
		this.personalVisible$,
		this.favoriteVisible$,
		this.reportVisible$,
		this.mailVisible$,
		this.spacesVisible$,
		this.menuTabs$,
	]).pipe(
		map(
			([isAdmin, categoryVisible, personalVisible, favoriteVisible, reportVisible, mailVisible, spacesVisible, tabs]: [
				boolean,
				boolean,
				boolean,
				boolean,
				boolean,
				boolean,
				boolean,
				INavigationMenuTab[]
			]) => {
				return tabs.reduce((acc, item) => {
					if (isAdmin && item.id === NavigationMenu.admin) {
						acc.push(item);
					}
					if (categoryVisible && item.id === NavigationMenu.categories) {
						acc.push(item);
					}
					// if (personalVisible && item.id === NavigationMenu.personalCategories) {
					// 	acc.push(item);
					// }
					if (favoriteVisible && item.id === NavigationMenu.favoritesNew) {
						acc.push(item);
					}
					if (reportVisible && item.id === NavigationMenu.reports) {
						acc.push(item);
					}
					if (mailVisible && item.id === NavigationMenu.email) {
						acc.push(item);
					}

					// spaces
					// if (spacesVisible && item.id === NavigationMenu.spaces) {
					// 	acc.push(item);
					// }

					return acc;
				}, []);
			}
		)
	);

	readonly activeMenuTabId$ = this.navigationState.activeMenuId$.pipe(takeUntil(this.destroy$));
	readonly navigation$ = this.navigationState.navigation$.pipe(takeUntil(this.destroy$));
	readonly activeMenu$ = this.navigationState.favoritesNavigationMenu$.pipe(takeUntil(this.destroy$));

	readonly showFooter$ = this.menuTabsFiltered$.pipe(
		map(tabs => {
			if (tabs.length < 2 && this.isMobile) {
				return false;
			}

			return true;
		})
	);

	readonly matColors = MatThemePaletteEnum;
	readonly perfectScrollBarOptions = { suppressScrollX: true };
	readonly backgroundDivImg$ = this.userInfo.userAvatar$.pipe(
		map(urlImg => this.url.domSanitizer.bypassSecurityTrustStyle(`url(${urlImg}) center / cover no-repeat`)),
		takeUntil(this.destroy$)
	);

	toolTipChecked: boolean;
	activeMenuTabId: NavigationMenu;

	readonly isNavbarFooterShadow$ = new BehaviorSubject(false);
	readonly tabSpacesClickNotify$ = new Subject();
	cachedShowNavigationAlwaysSetting: boolean;

	readonly badgeColor = IBadgeColor;
	readonly locale$ = this.culture.activeCulture$;
	readonly emailCounter$ = this.navPanelEmailService.commonUnreadCounter$;
	readonly isEmailError$ = this.navPanelEmailService.unauthorizedMailboxes$.pipe(map(unMb => unMb?.length));

	ngOnInit(): void {
		this.activeMenuTabId$.pipe(takeUntil(this.destroy$)).subscribe(el => (this.activeMenuTabId = el as NavigationMenu));
		this.eventsBus
			.ofType(OpenAdminPanelEvent)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.navigationState.setActiveTabMenu('admin');
				this.navScrollContainer.nativeElement.scrollTop = 0;
			});

		// если кнопка не доступна, ставим активной первую из доступных
		combineLatest([this.menuTabsFiltered$, this.activeMenuTabId$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([menuTabsFiltered, activeMenuTabId]) => {
				if (!menuTabsFiltered.some(t => t?.id === activeMenuTabId)) {
					const firstTab = menuTabsFiltered[0];
					this.navigationState.setActiveTabMenu(firstTab?.id);
				}
			});
	}

	ngAfterViewInit() {
		if (!this.navScrollContainer) {
			return;
		}
		setTimeout(() => {
			this.checkNavbarFooterShadow(this.navScrollContainer.nativeElement);
		}, 501);

		fromEvent(this.navScrollContainer.nativeElement, 'scroll')
			.pipe(takeUntil(this.destroy$))
			.subscribe(event => {
				this.checkNavbarFooterShadow(event.target);
			});

		fromEvent<CustomEvent>(window, 'select-nav-tab')
			.pipe(takeUntil(this.destroy$))
			.subscribe(e => {
				const tab = this.navigationState.tabsMenu.find(i => i.id === e.detail.tabId);
				this.onTabMenuClick(tab);
			});
	}

	get isMobile() {
		return this.platform.ANDROID || this.platform.IOS;
	}

	clickIntoScrollContainer() {
		setTimeout(() => {
			this.checkNavbarFooterShadow(this.navScrollContainer.nativeElement);
		}, 501);
	}

	checkNavbarFooterShadow(e) {
		if (e.scrollTop + e.clientHeight >= e.scrollHeight) {
			return this.isNavbarFooterShadow$.next(false);
		}
		return this.isNavbarFooterShadow$.next(true);
	}

	toggleSidebarOpened(): void {
		this.fuseSidebarService.getSidebar('navbar').toggleOpen();
	}

	toggleSidebarFolded(): void {
		this.fuseSidebarService.getSidebar('navbar').toggleFold();
	}

	toggleSidebarPinned(): void {
		this.fuseSidebarService.getSidebar('navbar')?.changePinned();
	}

	onSearchNavigation(query: string, tabId: string) {
		this.navigationState.searchByQuery(query, tabId);
	}

	goHome() {
		this.router.navigate(['/']);
	}

	onContextClick(param: IAddToFavoritesRequest): void {
		this.favoritesDataHttpService.addToFavorites(param).subscribe(() => {
			this.navigationFeature.load([NavigationMenu.favorites, NavigationMenu.favoritesNew]);
		});
	}

	onToggle(checked) {
		this.toolTipChecked = checked;

		const updateData = { showNavigationAlways: checked };

		this._layout.update(updateData);

		this.server.users.postUiSettings(this.userInfo.userId, updateData).pipe(take(1)).subscribe();

		if (!checked) {
			this.toggleSidebarPinned();
		}
	}

	getSliderTooltip() {
		if (this.toolTipChecked) {
			return 'Открепить панель';
		}

		return 'Закрепить панель';
	}

	onNavigationItemClick(navItem: INavigationMenuItem, menuId: string) {
		this.fuseSidebarService.getSidebar('navbar').unfold();
		if (navItem.menuType === MenuItemType.collapsible) {
			this.navigationState.update({
				menuId: this.navigationState.activeMenuId,
				items: [String(navItem.id)],
				apply: {
					isOpen: !navItem.isOpen,
				},
			});
			this.zone.runOutsideAngular(() =>
				setTimeout(() => {
					this.fusePerfectScrollbar && this.fusePerfectScrollbar.update();
					this.checkNavbarFooterShadow(this.navScrollContainer.nativeElement);
				}, 500)
			);
		} else if (navItem.menuType === MenuItemType.item && navItem.actionType === MenuItemActionType.openUrl) {
			this.navigationState.setActiveMenuItem({
				item: navItem,
				menuId: this.navigationState.activeMenuId,
			});
		}
	}

	onTabMenuClick(tab: INavigationMenuTab) {
		this.navigationState.setActiveTabMenu(tab.id);
		this.navScrollContainer.nativeElement.scrollTop = 0;

		if (tab?.id === NavigationMenu.spaces) {
			this.tabSpacesClickNotify$.next(0 as any);
		}

		setTimeout(() => {
			this.checkNavbarFooterShadow(this.navScrollContainer.nativeElement);
		}, 501);
	}

	trackByTabId(idx, tab: INavigationMenuTab) {
		return tab.id;
	}

	addNewMailBox() {
		this.navPanelEmailComponent.addNewMailBox();
	}

	hideSideBarOnResize(): void {
		this.resizeHandler$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			if (window.innerWidth <= 960 && typeof this.cachedShowNavigationAlwaysSetting !== 'boolean') {
				this.cachedShowNavigationAlwaysSetting = this._layout.state.showNavigationAlways;
				this.onToggle(false);
			}
			if (window.innerWidth > 960 && this.cachedShowNavigationAlwaysSetting === true) {
				this.onToggle(this.cachedShowNavigationAlwaysSetting);
				this.cachedShowNavigationAlwaysSetting = null;
			}
		});
	}
}
