<vh-modal-base [title]="'common.favorites' | resx" (cancel)="closeDialog()" (close)="closeDialog()" [showOk]="false">
	<div [class.hide]="loading$ | async">
		<div class="fav-container">
			<div class="fav-select">
				<div class="fav-colors">
					<div
						class="fav-color pointer"
						*ngFor="let colors of favoriteColors$ | async"
						[ngStyle]="{ 'background-color': colors.color }"
						[class.selected-color]="colors.selected"
						(click)="selectColor(colors)"
					></div>
				</div>
			</div>
			<div class="fav-select mt-12 mb-12">
				<div class="folder-select-contianer">
					<mat-form-field class="folder-select">
						<mat-label>{{ 'common.selectAFolder' | resx }}</mat-label>
						<mat-select [(ngModel)]="folder$">
							<mat-option *ngFor="let folder of favoritesFolders$ | async" [value]="folder">
								<ng-container *ngIf="folder?.nameResx">{{ folder?.nameResx | resx }}</ng-container>
								<ng-container *ngIf="!folder?.nameResx">{{ folder.name }}</ng-container>
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-icon class="add-icon" svgIcon="vh-plus-24" (click)="addLink(folder$)"></mat-icon>
				</div>
			</div>
		</div>
	</div>

	<div class="selected-favorites">
		<ng-container *ngFor="let folder of taskFolders$ | async">
			<div class="selected-favorite">
				<div class="selected-favorite-name">
					<ng-container *ngIf="folder?.nameResx">{{ folder?.nameResx | resx }}</ng-container>
					<ng-container *ngIf="!folder?.nameResx">{{ folder.name }}</ng-container>
				</div>
				<div class="selected-favorite-actions">
					<mat-icon svgIcon="vh-cross_v1-24" (click)="deleteLink(folder)"></mat-icon>
				</div>
			</div>
		</ng-container>
	</div>

	<button
		*ngIf="(hasChanges$ | async) || (folderChosen$ | async)"
		modal-base-before-ok
		class="vh-btn vh-btn--accent"
		[class.hide]="loading$ | async"
		(click)="updateFavorites()"
	>
		{{ 'common.refresh' | resx }}
	</button>
</vh-modal-base>
