import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	NgZone,
	OnInit,
	Output,
	ViewEncapsulation,
	inject,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BlockButtonsType, NavigationCommonService, UserLinkType } from '@spa/common/components/navigation';
import { ModalWindowsService } from '@spa/facade/features/modals';
import {
	IFavoriteBlock,
	INavigationMenu,
	INavigationMenuItem,
	NavigationFeatureProvider,
	NavigationMenu,
} from '@spa/facade/features/navigation';
import { IFavoriteFolder, IFavoriteNode } from '@spa/facade/features/navigation/providers/data-provider.dto';
import { AddElementModalComponent } from '@spa/facade/layout/components/favorites-panel/add-element-modal/add-element-modal.component';
import { AddFolderModalComponent } from '@spa/facade/layout/components/favorites-panel/add-folder-modal/add-folder-modal.component';
import { DeleteModalComponent } from '@spa/facade/layout/components/favorites-panel/delete-modal/delete-modal.component';
import { RenameModalComponent } from '@spa/facade/layout/components/favorites-panel/rename-modal/rename-modal.component';
import { MatThemePaletteEnum } from '@valhalla/common/material';
import { AuthService, LocalStorageProvider, UrlProvider, ViewDestroyStreamService } from '@valhalla/core';
import { DataHttpService, ISubategoriesTreeDatum } from '@valhalla/data/http';
import {
	BooleanFilter,
	createSpaRouteCheck,
	removeDuplicateByFn,
	rxHandler,
	toAngularRouterParts,
	urlFrom,
} from '@valhalla/utils';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom, from, Observable } from 'rxjs';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	filter,
	first,
	map,
	startWith,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';

import { ElementType } from './element-type';
import { ColorService } from '@spa/common/services/color.service';
import { DOCUMENT } from '@angular/common';
import { CanSpaNewTaskActivateService } from '@spa/components/new-task/can-spa-new-task-activate';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { IBadgeColor, IBadgeEmphasis } from 'libs/core-components/src/lib/badge/badge.model';
import { ApplicationService } from '@spa/application';

const UserSelectDialogComponent$ = from(
	import('@spa/common/components/scheduling-assistant/user-select-dialog/user-select-dialog.component').then(
		m => m.UserSelectDialogComponent
	)
);
@Component({
	selector: 'vh-layout-favorites-panel',
	templateUrl: './favorites-panel.component.html',
	styleUrls: ['./favorites-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
	encapsulation: ViewEncapsulation.None,
})
export class FavoritesPanelComponent implements OnInit {
	constructor(
		private readonly _zone: NgZone,
		readonly navigationFeature: NavigationFeatureProvider,
		readonly router: Router,
		readonly modal: ModalWindowsService,
		readonly urlBuilder: UrlProvider,
		readonly destroy$: ViewDestroyStreamService,
		readonly localStorage: LocalStorageProvider,
		readonly server: DataHttpService,
		readonly colorService: ColorService,
		readonly cdr: ChangeDetectorRef,
		readonly elRef: ElementRef<HTMLElement>,
		readonly auth: AuthService,
		@Inject(DOCUMENT) private document: Document,
		readonly navigationCommonService: NavigationCommonService,
		readonly canSpaNewTask: CanSpaNewTaskActivateService,
		readonly mobileView: MobileViewService
	) {}

	@Output()
	expandCollapseEvent = new EventEmitter();

	readonly perfectScrollBarOptions = { suppressScrollX: true };
	readonly searchHandler = rxHandler<string>();
	readonly spaRouteCheck = createSpaRouteCheck();
	readonly app = inject(ApplicationService);

	readonly searchInput$ = from<string>(this.searchHandler as any).pipe(
		startWith(''),
		tap(() => {
			this.scrollToSearchInput();
		})
	);

	readonly searchInputHasValue$ = this.searchInput$.pipe(distinctUntilChanged(), map(BooleanFilter));

	menuItems: INavigationMenu[] = [];
	favoritesGroupItems: INavigationMenu[] = [];
	matThemePalette = MatThemePaletteEnum;
	blockButtonsType = UserLinkType;
	expandedBlockId: number;

	readonly selectedMenuItemId$ = new BehaviorSubject<number>(null);
	readonly selectedMenuItem$ = new BehaviorSubject<INavigationMenuItem>(null);

	expandAssistantOpenState: { id: number; isOpen: boolean }[] = [];

	readonly subcategoriesTree$: Observable<ISubategoriesTreeDatum[]> = this.server.category
		.subcategoriesTree()
		.pipe(map(res => res.data));

	readonly favoritesMenu$ = this.navigationFeature.select(state => state.menus.favorites);
	readonly favoritesMenuButtons$ = this.favoritesMenu$.pipe(map(menu => menu?.buttons || []));
	readonly favoritesMenuFolders$ = this.favoritesMenu$.pipe(
		map(menu => menu?.folders.filter(f => f?.name !== 'ИзбранныеПунктыЮзера') || [])
	);

	readonly favoritesMenuAllFolders$ = this.favoritesMenu$.pipe(
		map(favMenu => {
			return this.plainFolders(favMenu.folders);
		})
	);

	readonly filteredFavoritesMenuFolders$ = combineLatest([
		this.favoritesMenuFolders$,
		this.searchInput$.pipe(debounceTime(300)),
	]).pipe(
		map(([folders, query]) => {
			if (!query) {
				return folders;
			}
			const folderClone = JSON.parse(JSON.stringify(folders));
			return this.folderDeepSearch(folderClone, query, null);
		})
	);

	readonly favFolderMenu$ = this.favoritesMenu$.pipe(
		map(menu => menu?.folders.filter(f => f?.name === 'ИзбранныеПунктыЮзера')[0])
	);

	readonly subcategoriesTreeLoaded$ = new BehaviorSubject<boolean>(false);

	readonly filteredFavFolderMenu$ = combineLatest([
		this.favFolderMenu$,
		this.searchInput$.pipe(debounceTime(300)),
	]).pipe(
		map(([favorites, query]) => {
			const favoritesClone = favorites && JSON.parse(JSON.stringify(favorites));
			if (query && favoritesClone?.subMenu?.length) {
				favoritesClone.subMenu = favoritesClone.subMenu.filter(menu =>
					menu.title.toLowerCase().includes(query.toLowerCase())
				);
			}
			return favoritesClone;
		})
	);

	nodes = [];
	dropTargetIds = [];
	nodeLookup = {};
	dropActionTodo: any = null;

	readonly favoritesMenuAssistant$ = this.navigationFeature.select(
		state => state.menus.assistantFavorite.menusAssistant
	);

	readonly filteredFavMenuAssistant$ = combineLatest([
		this.favoritesMenuAssistant$,
		this.searchInput$.pipe(debounceTime(300)),
	]).pipe(
		map(([assistants, query]) => {
			if (!query) {
				return assistants;
			}
			let assClone = assistants && JSON.parse(JSON.stringify(assistants));
			assClone = assClone.map(assistant => {
				if (assistant.folders?.length) {
					const favorites = assistant.folders; //это основная папка, может быть только одна;
					const favoritesClone = favorites && JSON.parse(JSON.stringify(favorites));

					assistant.folders = this.folderDeepSearch(favoritesClone, query, null);
				}
				return assistant;
			});
			return assClone.filter(ass => ass.folders?.length);
		})
	);

	readonly favoritesMenuButtonsAssistant$ = this.favoritesMenuAssistant$.pipe(
		map(menusAssistant => {
			return menusAssistant.map(assistant => {
				return assistant.buttons;
			});
		})
	);

	readonly favoritesButtonsCommon$ = combineLatest([
		this.favoritesMenuButtons$,
		this.favoritesMenuButtonsAssistant$,
		this.subcategoriesTree$,
	]).pipe(
		map(([favoritesMenuButtons, favoritesMenuButtonsAssistant, subcategoriesTree]) => {
			const buttons = [...favoritesMenuButtons, ...favoritesMenuButtonsAssistant.flat()];
			const buttonsWithoutDuplicate = removeDuplicateByFn(buttons, (a, b) => a.id === b.id);
			buttonsWithoutDuplicate.forEach(b => {
				b.canCreate = subcategoriesTree.find(
					subcat => subcat.id === b.linkedObjectId && subcat.nodeType === 'Subcategory'
				)?.canCreateTask;
			});
			return buttonsWithoutDuplicate;
		})
	);

	readonly IsFeedsViewOnly$ = this.server.config
		.getAppSettingsAnonymous()
		.pipe(map(settings => settings?.CustomSettings?.IsFeedsViewOnly));

	readonly isAdmin$ = this.auth.isAdmin$;
	public readonly IBadgeColor = IBadgeColor;
	public readonly IBadgeEmphasis = IBadgeEmphasis;

	ngOnInit(): void {
		this.subcategoriesTree$.pipe(takeUntil(this.destroy$)).subscribe(data => {
			if (data?.length) {
				this.subcategoriesTreeLoaded$.next(true);
				this.cdr.detectChanges();
			}
		});
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				takeUntil(this.destroy$)
			)
			.subscribe((event: NavigationEnd) => {
				if (this.selectedMenuItem$.value && !this.isCurrentMenuItemSelected(this.selectedMenuItem$.value)) {
					this.selectedMenuItemId$.next(null);
				}

				this.cdr.detectChanges();
			});

		this.favoritesMenuAssistant$.pipe(takeUntil(this.destroy$)).subscribe(menuAssistant => {
			this.expandAssistantOpenState = menuAssistant.reduce((acc, el) => {
				if (this.expandAssistantOpenState.length) {
					return this.expandAssistantOpenState;
				}

				acc.push({ id: el.userId, isOpen: false });
				return acc;
			}, []);
		});

		combineLatest([this.filteredFavFolderMenu$, this.filteredFavoritesMenuFolders$])
			.pipe(
				map(([favorite, menuFolders]) => {
					this.nodes = [];
					this.dropTargetIds = [];
					this.prepareDragDrop(menuFolders);
					favorite && this.dropTargetIds.push(favorite.id);
					this.nodes.push(...menuFolders);
					favorite && this.nodes.push(favorite);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	scrollToSearchInput() {
		const scrollContainer = this.elRef.nativeElement?.closest('.navbar-scroll-container');

		if (scrollContainer.scrollTop > 0) {
			const folderWrapper = this.elRef.nativeElement.querySelector('.folder-wrapper.fv-buttons');
			const folderWrapperHeight = folderWrapper?.clientHeight;
			return (scrollContainer.scrollTop = folderWrapperHeight || 0);
		}

		scrollContainer.scrollTop = 0;
	}

	prepareDragDrop(nodes: IFavoriteFolder[]) {
		nodes.forEach(node => {
			this.dropTargetIds.push(node.id);
			this.nodeLookup[node.id] = node;
			this.prepareDragDrop(node.subFolders);
		});
	}

	plainFolders(nodes: IFavoriteFolder[]) {
		const totalNodes = [];
		nodes.forEach(node => {
			totalNodes.push(node);
			if (node.subFolders && node.subFolders.length > 0) {
				totalNodes.push(...this.plainFolders(node.subFolders));
			}
		});

		return totalNodes;
	}

	getDropTargetIds(id) {
		return this.dropTargetIds.filter(x => id !== x).map(x => String(x));
	}

	dragMoved(event) {
		const e = this.document.elementFromPoint(event.pointerPosition.x, event.pointerPosition.y);

		if (!e) {
			this.clearDragInfo();
			return;
		}
		const container = e.classList.contains('node-item') ? e : e.closest('.node-item');
		if (!container) {
			this.clearDragInfo();
			return;
		}
		this.dropActionTodo = {
			targetId: container.getAttribute('data-id'),
			itemId: container.getAttribute('item-id'),
		};

		if (this.dropActionTodo.itemId) {
			this.dropActionTodo['action'] = 'inside';
		} else {
			const targetRect = container.getBoundingClientRect();
			const oneThird = targetRect.height / 3;

			if (event.pointerPosition.y - targetRect.top < oneThird) {
				// before
				this.dropActionTodo['action'] = 'before';
			} else if (event.pointerPosition.y - targetRect.top > 2.5 * oneThird) {
				// after
				this.dropActionTodo['action'] = 'after';
			} else {
				// inside
				this.dropActionTodo['action'] = 'inside';
			}
		}
		this.showDragInfo();
	}

	getParentNodeId(id: string, nodesToSearch: IFavoriteFolder[], parentId: string): string {
		for (const node of nodesToSearch) {
			if (node.id === Number(id)) return parentId;
			const ret = this.getParentNodeId(id, node.subFolders, String(node.id));
			if (ret) return ret;
		}
		return null;
	}
	showDragInfo() {
		this.clearDragInfo();
		if (this.dropActionTodo) {
			if (this.dropActionTodo.itemId) {
				this.document
					.getElementById('item-' + this.dropActionTodo.itemId)
					.classList.add('drop-' + this.dropActionTodo.action);
			} else {
				this.document.getElementById(this.dropActionTodo.targetId).classList.add('drop-' + this.dropActionTodo.action);
			}
		}
	}

	clearDragInfo(dropped = false) {
		if (dropped) {
			this.dropActionTodo = null;
		}
		this.document.querySelectorAll('.drop-before').forEach(element => element.classList.remove('drop-before'));
		this.document.querySelectorAll('.drop-after').forEach(element => element.classList.remove('drop-after'));
		this.document.querySelectorAll('.drop-inside').forEach(element => element.classList.remove('drop-inside'));
	}

	getColor(color) {
		return this.colorService.сolorMapToRGB(color);
	}

	folderDeepSearch(folders: IFavoriteFolder[], query, parentFolder: IFavoriteFolder = null): IFavoriteFolder[] {
		folders = folders.filter(folder => {
			if (folder.subFolders?.length > 0) {
				folder.subFolders = this.folderDeepSearch(folder.subFolders, query, folder);
			}

			const filterChildrenMenu = folder.subMenu?.filter(c => c.title.toLowerCase().includes(query.toLowerCase()));
			const folderSubMenu = folder.subMenu;
			folder.subMenu = [];
			if (filterChildrenMenu.length > 0) {
				folder.subMenu = filterChildrenMenu;
				folder.isExpand = true;
			}

			if (folder.subFolders?.length > 0) {
				folder.isExpand = true;
			}

			if (folder.name.toLowerCase().includes(query.toLowerCase())) {
				// Если мы нашли папку и внутри никаких пересечений с поиском, то отображаем все (ситуация, что ищут только папку и хотят увидеть ее содержимое)
				if (folder.subMenu.length === 0) {
					folder.subMenu = folderSubMenu;
				}
				return true;
			} else if (folder.subFolders?.length > 0 || folder.subMenu?.length > 0) {
				return true;
			}

			return false;
		});

		return folders;
	}

	drop(e) {
		if (e.previousContainer.id && e.container.id) {
			if (!this.dropActionTodo) return;

			const parentItemId = e.previousContainer.id;
			// id папки
			const targetListId = this.dropActionTodo.targetId;

			//id ноды, если имеем перенос
			const nodeItemId = Number(this.dropActionTodo.itemId) || null;

			const oldItemContainer = parentItemId != '1000' ? this.nodeLookup[parentItemId] : null;
			let newContainer = targetListId != '1000' ? this.nodeLookup[targetListId] : null;

			let curIndex = 0;
			switch (this.dropActionTodo.action) {
				case 'before':
				case 'after':
					newContainer = this.nodeLookup[newContainer?.parentId] || null;
					if (newContainer && newContainer.subFolders && newContainer.subFolders.length > 0) {
						curIndex = newContainer?.subFolders?.findIndex(c => c.id === Number(this.dropActionTodo.targetId));
						if (this.dropActionTodo === 'after') {
							curIndex = curIndex + 1;
						}
					}
					break;

				case 'inside':
					break;
			}

			this.clearDragInfo(true);

			const curFolder = newContainer?.id;
			const previousFolder = oldItemContainer?.id;
			const itemId = e.item.data.id;

			if (!itemId) {
				return;
			}

			if (this.typeFileGuard(e.item.data)) {
				this.reorderFolders(previousFolder, curFolder, curIndex, itemId);
				return;
			}

			if (curFolder === previousFolder) {
				this.favoritesMenu$.pipe(first()).subscribe(favorites => {
					const curNode: IFavoriteNode = favorites.nodes.filter(node => node.id === nodeItemId)[0];
					const itemPosition = favorites.nodes.findIndex(n => n.id === itemId);
					const destinationPosition = favorites.nodes.findIndex(n => n.id === curNode.id);
					const isError = this.showAttentions(
						curNode,
						favorites.nodes.find(n => n.id === itemId)
					);
					if (isError) {
						return;
					}

					this.server.favorites
						.reorderFavoritesLink({
							linkId: itemId,
							destinationLinkId: curNode ? curNode.id : null,
							destinationFolderId: curFolder,
							placeAbove: itemPosition > destinationPosition,
						})
						.pipe(
							catchError(err => {
								this.showError(err);
								return EMPTY;
							})
						)
						.subscribe(_ => {
							this.navigationFeature.load([NavigationMenu.favorites, NavigationMenu.favoritesNew]);
						});
				});
				return;
			}

			this.server.favorites
				.moveFavoritesLink({
					linkId: itemId,
					folderId: curFolder,
					groupId: e.item?.data?.groupId ? e.item.data.groupId : null,
				})
				.pipe(
					catchError(err => {
						this.showError(err);
						return EMPTY;
					})
				)
				.subscribe(_ => {
					this.favoritesMenu$.pipe(first()).subscribe(favorites => {
						const curNode: IFavoriteNode = favorites.nodes.filter(node => node.id === nodeItemId)[0];
						const itemPosition = favorites.nodes.findIndex(n => n.id === itemId);
						const destinationPosition = curNode ? favorites.nodes.findIndex(n => n.id === curNode.id) : 0;
						const isError = this.showAttentions(
							curNode,
							favorites.nodes.find(n => n.id === itemId),
							curFolder
						);

						if (isError) {
							return;
						}

						this.server.favorites
							.reorderFavoritesLink({
								linkId: itemId,
								destinationLinkId: curNode ? curNode.id : null,
								destinationFolderId: curFolder,
								placeAbove: itemPosition > destinationPosition,
							})
							.pipe(
								catchError(err => {
									this.showError(err);
									return EMPTY;
								})
							)
							.subscribe(__ => {
								this.navigationFeature.load([NavigationMenu.favorites, NavigationMenu.favoritesNew]);
							});
					});
				});
		}
	}

	reorderFolders(curFolderId, destinationFolderId, curIndex, itemId) {
		this.favoritesMenuAllFolders$.pipe(first()).subscribe(folders => {
			const currentFoler = folders.filter(x => x.id === itemId)[0];
			const destFolder = folders.filter(x => x.id === destinationFolderId)[0];

			const isError = this.showFolderAttentions(currentFoler, destFolder);

			if (isError) {
				return;
			}

			this.server.favorites
				.moveFavoritesFolder({
					targetFolderId: itemId,
					newParentId: destinationFolderId,
					placeAbove: true,
				})
				.pipe(
					catchError(err => {
						this.showError(err);
						return EMPTY;
					})
				)
				.subscribe(__ => {
					this.navigationFeature.load([NavigationMenu.favorites, NavigationMenu.favoritesNew]);
				});
		});
	}

	typeFileGuard(toBeDetermined: any): toBeDetermined is IFavoriteFolder {
		if (toBeDetermined.isExpand !== undefined) {
			return true;
		}

		return false;
	}

	showError(err) {
		if (err?.error?.errors[0]?.message) {
			this._zone.run(() => {
				this.modal.openSnackBar(err.error.errors[0].message, {});
			});
		}
	}

	showAttentions(link: IFavoriteNode, destinationLink: IFavoriteNode, destinationFolder?: any): boolean {
		let isErrorsExists = false;
		const options = {
			duration: 2000,
		};
		if (destinationLink.groupId !== null && destinationLink.userId === null) {
			this._zone.run(() => {
				this.modal.openSnackBar('Разрешено перемещение только пользовательских ссылок', options);
			});
			isErrorsExists = true;
		}
		if (destinationFolder) {
			if ((destinationFolder.groupId != null && destinationFolder.userId == null) || destinationFolder.isSystemFolder) {
				this._zone.run(() => {
					this.modal.openSnackBar('Перемещение разрешено только в личные папки', options);
				});
				isErrorsExists = true;
			}
		}
		return isErrorsExists;
	}

	showFolderAttentions(currentFolder: IFavoriteFolder, destinationFolder?: IFavoriteFolder): boolean {
		let isErrorsExists = false;
		const options = {
			duration: 2000,
		};
		if ((currentFolder.groupId !== null && currentFolder.userId === null) || currentFolder.isSystemFolder) {
			this._zone.run(() => {
				this.modal.openSnackBar('Разрешено перемещение только пользовательских папок', options);
			});
			isErrorsExists = true;
		}
		if (destinationFolder) {
			if ((destinationFolder.groupId != null && destinationFolder.userId == null) || destinationFolder.isSystemFolder) {
				this._zone.run(() => {
					this.modal.openSnackBar('Перемещение разрешено только в личные папки', options);
				});
				isErrorsExists = true;
			}
		}
		return isErrorsExists;
	}

	openCreateFolderModal(item?: any) {
		this.modal
			.openDialog(AddFolderModalComponent, {
				width: '400px',
			})
			.afterClosed()
			.subscribe(modalData => {
				if (modalData && modalData.folderName) {
					this.server.favorites
						.addFolderToFavorites({
							name: modalData.folderName,
							parentId: item?.id || null,
						})
						.subscribe(_ => {
							this.navigationFeature.load([NavigationMenu.favorites, NavigationMenu.favoritesNew]);
						});
				}
			});
	}

	openCreateElementModal(item: INavigationMenu) {
		this.modal
			.openDialog(AddElementModalComponent, {
				width: '400px',
			})
			.afterClosed()
			.subscribe(modalData => {
				if (modalData && modalData.link && modalData.name) {
					this.server.favorites
						.addLinkToFavorites({
							link: modalData.link,
							name: modalData.name,
							isJsFunction: false,
							customImagePath: null,
							customImageClass: null,
							type: '0',
							linkedObjectId: +item.id,
							folderId: item.id === defaultFavoritesFolderId ? null : +item.id,
						})
						.subscribe(_ => {
							this.navigationFeature.load([NavigationMenu.favorites, NavigationMenu.favoritesNew]);
						});
				}
			});
	}

	isSystemOrWorkGroup(folder) {
		if (folder.isSystemFolder) {
			return true;
		}
		if (folder.isWorkGroupFolder) {
			return true;
		}
		return false;
	}

	isWorkGroupLink(link) {
		return link.isWorkGroupLink;
	}

	openRenameFolderModal(folder: IFavoriteFolder) {
		this.modal
			.openDialog(RenameModalComponent, {
				width: '400px',
				data: {
					type: ElementType.folder,
					value: folder.name,
				},
			})
			.afterClosed()
			.subscribe(modalData => {
				if (modalData && modalData.folderName) {
					this.server.favorites
						.renameFavoriteFolder({
							linkId: folder.id,
							newName: modalData.folderName,
						})
						.subscribe(_ => {
							this.navigationFeature.load([NavigationMenu.favorites, NavigationMenu.favoritesNew]);
						});
				}
			});
	}

	openCategorySettings(item: INavigationMenu) {
		const url = this.urlBuilder.getUrl(`/admin/subcategories/EditSubcatFrameset.aspx?SubcatID=${item.linkedObjectId}`);
		window.open(url, '_blank');
	}

	openDeleteFolderModal(folder: IFavoriteFolder) {
		this.modal
			.openDialog(DeleteModalComponent, {
				width: '400px',
				data: {
					name: folder.name,
					type: ElementType.folder,
				},
			})
			.afterClosed()
			.subscribe(modalData => {
				if (modalData) {
					this.server.favorites.deleteFavoriteFolder(folder.id).subscribe(_ => {
						this.navigationFeature.load([NavigationMenu.favorites, NavigationMenu.favoritesNew]);
					});
				}
			});
	}

	openRenameItemModal(item: INavigationMenu) {
		this.modal
			.openDialog(RenameModalComponent, {
				width: '400px',
				data: {
					type: ElementType.link,
					value: item.title,
				},
			})
			.afterClosed()
			.subscribe(modalData => {
				if (modalData && modalData.folderName) {
					this.server.favorites
						.renameFavoriteLink({
							linkId: +item.id,
							newName: modalData.folderName,
						})
						.subscribe(_ => {
							this.navigationFeature.load([NavigationMenu.favorites, NavigationMenu.favoritesNew]);
						});
				}
			});
	}

	openShareItemModal(item: INavigationMenu) {
		UserSelectDialogComponent$.pipe(
			take(1),
			switchMap(comp => {
				return this.modal
					.openDialog(comp, {
						width: '400px',
						data: {
							btnText: 'common.contextShare',
						},
					})
					.afterClosed()
					.pipe(
						filter(users => users?.length > 0),
						map(users => users.map(u => u.userId)),
						take(1)
					);
			})
		).subscribe(modalData => {
			this.server.favorites
				.shareFavoritesLink({
					linkId: Number(item.id),
					userIds: modalData,
				})
				.subscribe();
		});
	}

	openDeleteItemModal(item: IFavoriteBlock) {
		this.modal
			.openDialog(DeleteModalComponent, {
				width: '400px',
				data: {
					name: item.title,
					type: ElementType.link,
				},
			})
			.afterClosed()
			.subscribe(modalData => {
				if (modalData) {
					this.server.favorites.deleteFavoriteLink(item.id).subscribe(_ => {
						this.navigationFeature.load([NavigationMenu.favorites, NavigationMenu.favoritesNew]);
					});
				}
			});
	}

	getFeedLink(item: INavigationMenuItem, view = 'feeds'): string {
		const linkType = item.typeId === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.linkedObjectId}/${view}`;
		}

		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.linkedObjectId}/${view}`;
		}

		return url;
	}

	openFeed(item: INavigationMenuItem, e) {
		const url = this.getFeedLink(item);
		if (e?.ctrlKey || e?.metaKey) {
			const urlForWindow = this.urlBuilder.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}
		this.router.navigate([url]);
	}

	openChannel(item: INavigationMenuItem, e) {
		const url = this.getFeedLink(item, 'channel');
		if (e?.ctrlKey || e?.metaKey) {
			const urlForWindow = this.urlBuilder.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}
		this.router.navigate([url]);
	}

	openChat(item: INavigationMenuItem, e) {
		const url = this.getFeedLink(item, 'chat');
		if (e?.ctrlKey || e?.metaKey) {
			const urlForWindow = this.urlBuilder.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}
		this.router.navigate([url], { queryParams: { mode: 'feed' } });
	}

	openFiles(item: INavigationMenuItem, e) {
		const url = this.getFeedLink(item, 'files');
		if (e?.ctrlKey || e?.metaKey) {
			const urlForWindow = this.urlBuilder.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}
		this.router.navigate([url]);
	}

	createTask(item: INavigationMenu, e) {
		const pathname = '/link';
		const queryParams = {
			url: `/NewTask.aspx?SubCatID=${item.linkedObjectId}`,
		};
		if (e?.ctrlKey || e?.metaKey) {
			const urlForWindow = this.urlBuilder.getUrl(
				this.router.serializeUrl(this.router.createUrlTree([pathname], { queryParams })),
				true
			);
			window.open(urlForWindow, '_blank');
			return;
		}

		this.router.navigate([pathname], { queryParams });
	}

	openKanban(item: INavigationMenu, e) {
		if (e?.ctrlKey || e?.metaKey) {
			const url = this.urlBuilder.getUrl(`/tasks/subcat/${item.linkedObjectId}/kanban`, true);
			window.open(url, '_blank');
			return;
		}
		this.router.navigate([`/tasks/subcat/${item.linkedObjectId}/kanban`]);
	}

	openHierarchy(item: INavigationMenu, e) {
		if (e?.ctrlKey || e?.metaKey) {
			const url = this.urlBuilder.getUrl(`/tasks/subcat/${item.linkedObjectId}/hierarchy`, true);
			window.open(url, '_blank');
			return;
		}
		this.router.navigate([`/tasks/subcat/${item.linkedObjectId}/hierarchy`]);
	}

	getCalendarLink(item: INavigationMenu): string {
		const linkType = item.typeId === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.linkedObjectId}/calendar`;
		}
		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.linkedObjectId}/calendar`;
		}
		return url;
	}

	openCalendar(item: INavigationMenu, e) {
		const url = this.getCalendarLink(item);
		if (e?.ctrlKey || e?.metaKey) {
			const urlForWindow = this.urlBuilder.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		this.router.navigate([url]);
	}

	getGanttLink(item: INavigationMenu): string {
		const linkType = item.typeId === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.linkedObjectId}/gantt`;
		}
		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.linkedObjectId}/gantt`;
		}
		return url;
	}

	openGantt(item: INavigationMenu, e) {
		const url = this.getGanttLink(item);
		if (e?.ctrlKey || e?.metaKey) {
			const urlForWindow = this.urlBuilder.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		this.router.navigate([url]);
	}

	getCorrespondenceLink(item: INavigationMenu): string {
		const linkType = item.typeId === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.linkedObjectId}/correspondence`;
		}
		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.linkedObjectId}/correspondence`;
		}

		return url;
	}

	openCorrespondence(item: INavigationMenu, e) {
		const url = this.getCorrespondenceLink(item);

		if (e?.ctrlKey || e?.metaKey) {
			const urlForWindow = this.urlBuilder.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		this.router.navigate([url]);
	}

	getAgendaLink(item: INavigationMenu): string {
		const linkType = item.typeId === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.linkedObjectId}/agenda`;
		}
		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.linkedObjectId}/agenda`;
		}
		return url;
	}

	openAgenda(item: INavigationMenu, e) {
		const url = this.getAgendaLink(item);
		if (e?.ctrlKey || e?.metaKey) {
			const urlForWindow = this.urlBuilder.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		this.router.navigate([url]);
	}

	getSummaryLink(item: INavigationMenu) {
		const linkType = item.typeId === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.linkedObjectId}/summary`;
		}
		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.linkedObjectId}/summary`;
		}
		return url;
	}

	openSummary(item: INavigationMenu, e) {
		const url = this.getSummaryLink(item);

		if (e?.ctrlKey || e?.metaKey) {
			const urlForWindow = this.urlBuilder.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		this.router.navigate([url]);
	}

	getTableLink(item: INavigationMenu): string {
		let url: string;
		switch (item.typeId) {
			case 'Subcategory':
				url = `/tasks/subcat/${item.linkedObjectId}/grid`;
				break;
			case 'Category':
				url = `/tasks/category/${item.linkedObjectId}/grid`;
				break;
			case 'SummaryCategory':
				url = `/tasks/summary/${item.linkedObjectId}/grid`;
				break;
			default:
				return '';
		}
		return url;
	}

	openTable(item: INavigationMenu, e) {
		let url = this.getTableLink(item);
		if (!url) {
			console.error(`No rule found for open grid with typeId='${item.typeId}'`);
		}
		const queryParams: Record<string, string> = {};

		if (item.typeId === 'SummaryCategory') {
			queryParams.title = item.title;
		}

		if (e?.ctrlKey || e?.metaKey) {
			if (item.typeId === 'SummaryCategory') {
				url += `?title=${item.title}`;
			}
			const urlForWindow = this.urlBuilder.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		this.router.navigate([url], { state: { navItem: item }, queryParams });
	}

	isOpenAssistantBlock(id: number) {
		const elIndex = this.expandAssistantOpenState.findIndex(el => el.id === id);
		return this.expandAssistantOpenState[elIndex]?.isOpen;
	}

	expandAssistantBlock(event: MouseEvent, assistant) {
		const elIndex = this.expandAssistantOpenState.findIndex(el => el.id === assistant.userId);
		this.expandAssistantOpenState[elIndex].isOpen = !this.expandAssistantOpenState[elIndex].isOpen;
	}

	expandBlock(event: MouseEvent, folder: IFavoriteFolder, userId?: number) {
		event.stopPropagation();

		this.expandCollapseEvent.emit();

		if (userId) {
			this.navigationFeature.expandFavoriteAssistantFolder(folder.id, false, userId);
			return;
		}
		this.navigationFeature.expandFavoriteFolder(folder.id, false);
	}

	isTask(menuItem: INavigationMenuItem) {
		return menuItem.typeId === BlockButtonsType.task;
	}

	isPortal(menuItem: INavigationMenuItem) {
		return menuItem.href && menuItem.href.includes('/portal/');
	}

	isReport(menuItem: INavigationMenuItem) {
		return menuItem.typeId === BlockButtonsType.report;
	}

	getButtonIcon(iconName): string {
		let icon = '';
		switch (iconName) {
			case 'metrobutton_icon_user':
				icon = 'person';
				break;
			case 'metrobutton_icon_info':
				icon = 'info';
				break;
			case 'metrobutton_icon_task':
				icon = 'folder_shared';
				break;
			case 'metrobutton_icon_calendar':
				icon = 'calendar_today';
				break;
			case 'metrobutton_icon_book':
				icon = 'book';
				break;
			case 'metrobutton_icon_doc':
				icon = 'insert_drive_file';
				break;
			case 'fa-home':
				icon = 'home';
				break;
			case 'fa-tasks':
				icon = 'subject';
				break;
			case 'fa-bar-chart':
				icon = 'bar_chart';
				break;
			case 'fa-clock-o':
				icon = 'access_time';
				break;
			case 'fa-comments-o':
				icon = 'chat';
				break;
			case 'fa-credit-card':
				icon = 'credit_card';
				break;
			case 'fa-tachometer':
				icon = 'speed';
				break;
			case 'fa-calculator':
				icon = 'vh-plus-24';
				break;
			case 'fa-bookmark-o':
				icon = 'bookmark_border';
				break;
			case 'fa-calendar':
				icon = 'calendar_today';
				break;
			case 'fa-users':
				icon = 'people';
				break;
			case 'fa-hourglass-half':
				icon = 'hourglass_empty';
				break;
			case 'fa-line-chart':
				icon = 'show_chart';
				break;
			case 'fa-phone':
				icon = 'call';
				break;
			case 'fa-pie-chart':
				icon = 'pie_chart';
				break;
			case 'fa-bars':
				icon = 'menu';
				break;
			case 'fa-star-o':
				icon = 'star_border';
				break;
			case 'fa-sign-out':
				icon = 'exit_to_app';
				break;
			case 'fa-exclamation-circle':
				icon = 'error';
				break;
		}
		return icon;
	}

	toFeatherIcon(name: string) {
		const iconName = this.getButtonIcon(name);
		let featherIcon = iconName;
		switch (iconName) {
			case 'person':
				featherIcon = 'user';
				break;
			case 'book':
				featherIcon = 'bookmark';
				break;
			case 'calendar_today':
				featherIcon = 'calendar';
				break;
			case 'folder_shared':
				featherIcon = 'share-2';
				break;
			default:
				break;
		}
		return featherIcon;
	}

	isLink(menuItem: INavigationMenuItem) {
		if (menuItem.typeId === BlockButtonsType.link || menuItem.typeId === BlockButtonsType.none) {
			if (menuItem.href) {
				if (menuItem.href && menuItem.isWorkGroupLink) {
					return true;
				}
			}
		}
		return false;
	}

	isExternalLink(menuItem: INavigationMenuItem) {
		if (menuItem.typeId === BlockButtonsType.none && menuItem.href) {
			if (this.isExternalLinkUrl(menuItem.href)) {
				return true;
			}
		}
		return false;
	}

	isAbsoluteLink(url: string) {
		return url && url.includes('://');
	}

	isAbsoluteLinkHasTheSameOrigin(url: string) {
		const urlRef = new URL(url);
		return location.origin === urlRef.origin;
	}

	removeSameOrigin(url: string) {
		return url && url.replace(location.origin, '');
	}

	getCurrentDomainWithProtocol() {
		const url = window.location.href;
		const arr = url.split('/');
		return arr[0] + '//' + arr[2];
	}

	isCategory(menuItem: INavigationMenuItem) {
		return menuItem.typeId === BlockButtonsType.subcategory;
	}

	isSyndicate(menuItem: INavigationMenuItem) {
		return menuItem.typeId === BlockButtonsType.syndicate;
	}

	getIcon(menuItem: INavigationMenuItem) {
		if (menuItem.canCreate) {
			return 'vh-plus-24';
		}
		if (menuItem.customImageClass) {
			return this.getButtonIcon(menuItem.customImageClass);
		}
		if (this.isPortal(menuItem) || this.isReport(menuItem)) {
			return 'vh-arrow-v1-L-right-24';
		}
		if (this.isExternalLink(menuItem)) {
			return 'call_made';
		}
		if (this.isLink(menuItem) || menuItem.typeId === BlockButtonsType.portalGrid) {
			return 'link';
		}
		return '';
	}

	isFormaIcon(menuItem: INavigationMenuItem) {
		return this.isPortal(menuItem) || this.isReport(menuItem) || menuItem.canCreate;
	}

	onIconClick(event: Event, menuItem: INavigationMenuItem) {
		if (this.isCategory(menuItem)) {
			event.stopPropagation();
			return this.createNewTask(menuItem);
		}
	}

	createNewTask(menuItem: INavigationMenuItem, e?: MouseEvent) {
		this.server.category
			.storageSubCategory(menuItem.linkedObjectId)
			.pipe(
				map(data => data.subcategory.viewAndPermissions),
				take(1)
			)
			.subscribe(viewAndPermissions => {
				if (viewAndPermissions.redirectFromNewTask?.length) {
					this.navigationCommonService.redirectToIFrameLink(viewAndPermissions.redirectFromNewTask);
					return;
				}
				const newTab = e?.ctrlKey || e?.metaKey;
				const routerCommands = ['/newtask', menuItem.linkedObjectId];
				if (newTab) {
					const urlForWindow = this.urlBuilder.getUrl(
						this.router.serializeUrl(this.router.createUrlTree(routerCommands)),
						true
					);
					window.open(urlForWindow, '_blank');
					return;
				}
				return this.router.navigate(routerCommands);
			});
	}

	getClearMenuItemLink(url: string): string {
		const spaNoFrameLinkPart = 'spa/noframe';
		if (url.includes(spaNoFrameLinkPart)) {
			const spaLink = url.substr(url.indexOf(spaNoFrameLinkPart) + spaNoFrameLinkPart.length);
			const { pathname, queryParams } = toAngularRouterParts(spaLink);
			return pathname;
		}

		const spaLinkPart = '/spa/';
		if (url.includes(spaLinkPart)) {
			const spaLink = url.substr(url.indexOf(spaLinkPart) + spaLinkPart.length);
			const { pathname, queryParams } = toAngularRouterParts(spaLink);
			return pathname;
		}

		const oldSpaLinkPart = '/spaex.aspx/';
		if (url.includes(oldSpaLinkPart)) {
			const spaLink = url.substr(url.indexOf(oldSpaLinkPart) + oldSpaLinkPart.length);
			const { pathname, queryParams } = toAngularRouterParts(spaLink);
			return pathname;
		}

		const linkPrefix = '/link?url=';
		if (url.includes(linkPrefix)) {
			const spaLink = url.substr(url.indexOf(linkPrefix) + linkPrefix.length);
			return spaLink;
		}

		return url;
	}

	getMenuItemLink(menuItem: INavigationMenuItem): string {
		let type = 'CategoryId';
		switch (menuItem.typeId) {
			case BlockButtonsType.subcategory:
				type = 'SubcatId';
				break;
			case BlockButtonsType.summaryCategory:
				type = 'SummaryID';
				break;
			default:
				break;
		}

		if (menuItem.openType === BlockButtonsType.subcategory) {
			return `/Syndicate.aspx?forceURL=/NewCustomGrid.aspx?${type}=${menuItem.linkedObjectId}`;
		}

		if (menuItem.openType === BlockButtonsType.feed) {
			return this.getFeedLink(menuItem);
		}

		if (menuItem.openType === BlockButtonsType.summary) {
			return this.getSummaryLink(menuItem);
		}

		if (menuItem.openType === BlockButtonsType.calendar) {
			return this.getCalendarLink(menuItem);
		}

		if (menuItem.openType === BlockButtonsType.grid) {
			return this.getTableLink(menuItem);
		}

		if (menuItem.openType === BlockButtonsType.gantt) {
			return this.getGanttLink(menuItem);
		}

		if (menuItem.openType === BlockButtonsType.correspondence) {
			return this.getCorrespondenceLink(menuItem);
		}

		if (menuItem.openType === BlockButtonsType.filestorage) {
			return `/Syndicate.aspx?forceURL=/filestorage/default.aspx?${type}=${menuItem.linkedObjectId}`;
		}

		if (menuItem.openType === BlockButtonsType.agenda) {
			return this.getAgendaLink(menuItem);
		}

		if (menuItem.openType === BlockButtonsType.kanban) {
			return `/tasks/subcat/${menuItem.linkedObjectId}/kanban`;
		}

		if (menuItem.openType === BlockButtonsType.hierarchy) {
			return `/tasks/subcat/${menuItem.linkedObjectId}/hierarchy`;
		}

		const customHrefParts = [
			`javascript:void(TCHeader.openSyndicate('?forceURL=encodeURIComponent('`,
			`javascript:void(TCHeader.openSyndicate('?forceURL=`,
			`javascript:void(TCLeftTrees.openSyndicate('?forceURL=encodeURIComponent('`,
			`javascript:void(TCLeftTrees.openSyndicate('?forceURL=`,
		];
		const thePart = customHrefParts.find(p => menuItem.href.includes(p));
		if (thePart) {
			const customLink = menuItem.href.replace(thePart, '').replace(`')))`, '').replace(`'))`, '');
			return customLink;
		}

		if (menuItem.href.includes('OpenTask')) {
			const urlArray = menuItem.href.match(/\d+/g);
			if (urlArray && urlArray.length && urlArray.length > 0) {
				const taskId = urlArray[0];

				return `/tasks/${taskId}`;
			}
		}

		if (menuItem.typeId === BlockButtonsType.task) {
			return `/tasks/${menuItem.linkedObjectId}`;
		}

		if (menuItem.typeId === BlockButtonsType.user) {
			return `/user/profile/${menuItem.linkedObjectId}`;
		}

		if (menuItem.typeId === BlockButtonsType.category) {
			return `/Syndicate.aspx?forceURL=/1FMain.aspx?CategoryId=${menuItem.linkedObjectId}`;
		}

		if (menuItem.typeId === BlockButtonsType.portalGrid) {
			return `/PortalGrid.aspx?PortalID=${menuItem.linkedObjectId}`;
		}

		if (menuItem.typeId === BlockButtonsType.newTaskInSubcat) {
			const taskIdArray = (menuItem.id + '').match(/(NewTask)(\d*)/);
			let taskId = menuItem.linkedObjectId;
			if (taskIdArray && taskIdArray.length && taskIdArray.length >= 3) {
				taskId = +taskIdArray[2];
			}
			return `/NewTask.aspx?&SubCatID=${taskId}`;
		}

		if (menuItem.typeId === BlockButtonsType.none) {
			if (menuItem.href.includes('/TaskHierarchy.aspx')) {
				const hierarchyIdArray = menuItem.href.match(/(TaskHierarchy\.aspx\?id=)(\d*)/);
				if (hierarchyIdArray && hierarchyIdArray.length && hierarchyIdArray.length >= 2) {
					return `/TaskHierarchies/TaskHierarchy.aspx?id=${hierarchyIdArray[2]}`;
				}
			}
		}

		return menuItem.href;
	}

	isCurrentMenuItemSelected(menuItem: INavigationMenuItem): boolean {
		const isSyndicate = ['/tasks/subcat/', '/tasks/category/'].some(u => this.router.url.toLowerCase().includes(u));
		if (isSyndicate) {
			const synId = parseInt(this.router.url.match(/\d+/)[0], 10);
			return menuItem?.linkedObjectId === synId;
		} else {
			const url = this.getClearMenuItemLink(decodeURIComponent(this.getMenuItemLink(menuItem)));
			const routerUrl = this.getClearMenuItemLink(decodeURIComponent(this.router.url));
			return url === routerUrl;
		}
	}

	onItemClick(menuItem: INavigationMenuItem, e?) {
		if (menuItem.typeId === BlockButtonsType.subcategory) {
			this.server.category
				.storageSubCategory(menuItem.linkedObjectId)
				.pipe(
					map(data => data.subcategory.viewAndPermissions),
					take(1)
				)
				.subscribe(viewAndPermissions => {
					if (viewAndPermissions.redirectFromGridHref?.length) {
						this.navigationCommonService.redirectToIFrameLink(viewAndPermissions.redirectFromGridHref);
						return;
					}
				});
		}

		this.selectedMenuItem$.next(menuItem);
		this.selectedMenuItemId$.next(+menuItem.id);

		let type = 'CategoryId';
		switch (menuItem.typeId) {
			case BlockButtonsType.subcategory:
				type = 'SubcatId';
				break;
			case BlockButtonsType.summaryCategory:
				type = 'SummaryID';
				return;
			case BlockButtonsType.portalGrid:
				return this.router.navigate([`/portal/${menuItem.linkedObjectId}`]);
			case BlockButtonsType.SmartSearch:
				this.openLink(menuItem.href, e);
				return;
			case BlockButtonsType.newTaskInSubcat:
				return this.router.navigate([`/newtask/${menuItem.linkedObjectId}`]);
			case BlockButtonsType.category:
				if (menuItem.openType === 'Kanban') {
					return this.openKanban(menuItem, e);
				}
				break;
			default:
				break;
		}

		if (
			menuItem.href?.includes('javascript:void(TCLeftTrees.openSyndicate') &&
			menuItem.href?.includes('force=files')
		) {
			return this.openFiles(menuItem, e);
		}

		if (menuItem.openType === BlockButtonsType.subcategory) {
			this.openLink(`/Syndicate.aspx?forceURL=/NewCustomGrid.aspx?${type}=${menuItem.linkedObjectId}`, e);
			return;
		}

		if (menuItem.openType === BlockButtonsType.feed) {
			this.openFeed(menuItem, e);
			return;
		}

		if ([BlockButtonsType.channel, BlockButtonsType.Channel].includes(menuItem.openType as any)) {
			this.openChannel(menuItem, e);
			return;
		}

		if ([BlockButtonsType.chats, BlockButtonsType.Chats].includes(menuItem.openType as any)) {
			this.openChat(menuItem, e);
			return;
		}

		if (menuItem.openType === BlockButtonsType.summary) {
			this.openSummary(menuItem, e);
			return;
		}

		if (menuItem.openType === BlockButtonsType.calendar) {
			this.openCalendar(menuItem, e);
			return;
		}

		if (menuItem.openType === BlockButtonsType.grid) {
			this.IsFeedsViewOnly$.pipe(take(1)).subscribe(val => {
				if (val) {
					this.openFeed(menuItem, e);
					return;
				}

				this.openTable(menuItem, e);
				return;
			});
			return;
		}

		if (menuItem.openType === BlockButtonsType.gantt) {
			this.openGantt(menuItem, e);
			return;
		}

		if (menuItem.openType === BlockButtonsType.correspondence) {
			this.openCorrespondence(menuItem, e);
			return;
		}

		if (menuItem.openType === BlockButtonsType.filestorage) {
			const url = this.getFeedLink(menuItem, 'files');
			if (e?.ctrlKey || e?.metaKey) {
				const urlForWindow = this.urlBuilder.getUrl(url, true);
				window.open(urlForWindow, '_blank');
				return;
			}
			this.router.navigate([url]);
			// const fsLink = fileStorageUrl([menuItem.typeId, `${menuItem.linkedObjectId}`], {
			// 	view: 'grid',
			// 	onlySpaStyles: 1,
			// 	showlefttree: '0',
			// });
			// // this.openLink(`/Syndicate.aspx?forceURL=/filestorage/default.aspx?${type}=${menuItem.linkedObjectId}`, e);
			// // this.openLink(fsLink, e);
			// if (e?.ctrlKey || e?.metaKey) {
			// 	const urlForWindow = this.urlBuilder.getUrl(
			// 		this.router.serializeUrl(this.router.createUrlTree(['/link'], { queryParams: { url: fsLink } })),
			// 		true
			// 	);
			// 	window.open(urlForWindow, '_blank');
			// 	return;
			// }

			// this.router.navigate(['/link'], {
			// 	queryParams: {
			// 		url: fsLink,
			// 	},
			// });
			return;
		}

		if (menuItem.openType === BlockButtonsType.agenda) {
			this.openAgenda(menuItem, e);
			return;
		}

		if (menuItem.openType?.toLowerCase() === BlockButtonsType.kanban) {
			this.openKanban(menuItem, e);
			return;
		}

		if (menuItem.openType === BlockButtonsType.hierarchy) {
			this.openHierarchy(menuItem, e);
			return;
		}

		const customHrefParts = [
			`javascript:void(TCHeader.openSyndicate('?forceURL=encodeURIComponent('`,
			`javascript:void(TCHeader.openSyndicate('?forceURL=`,
			`javascript:void(TCLeftTrees.openSyndicate('?forceURL=encodeURIComponent('`,
			`javascript:void(TCLeftTrees.openSyndicate('?forceURL=`,
		];
		const thePart = customHrefParts.find(p => menuItem.href.includes(p));
		if (thePart) {
			const customLink = menuItem.href.replace(thePart, '').replace(`')))`, '').replace(`'))`, '');
			this.openLink(customLink, e);
			return;
		}

		const taskOrChatOpen = async (tid: number, newTab = false) => {
			const exi = await firstValueFrom(this.app.task.checkExistAndAccess(tid));
			if (exi.isTaskExists && exi.isUserHasAccess) {
				const isChat = this.server.config.isPrivateOrGroupChatSync(exi.taskShortInfo.subcatId);
				if (isChat) {
					return this.app.openPages.openChat({
						newTab: newTab,
						taskId: tid,
						mode: 'chat',
					});
				} else {
					return this.app.openPages.openTask({
						newTab: newTab,
						taskId: tid,
					});
				}
			}
		};

		if (menuItem.href.includes('OpenTask')) {
			const urlArray = menuItem.href.match(/\d+/g);
			if (urlArray && urlArray.length && urlArray.length > 0) {
				const taskId = urlArray[0];

				return taskOrChatOpen(+taskId, e?.ctrlKey || e?.metaKey);

				// if (e?.ctrlKey || e?.metaKey) {
				// 	const url = this.urlBuilder.getUrl(`/tasks/${taskId}`, true);
				// 	window.open(url, '_blank');
				// 	return;
				// }

				// return this.router.navigate([`/tasks/${taskId}`]);
			}
		}

		if (menuItem.typeId === BlockButtonsType.task) {
			return taskOrChatOpen(+menuItem.linkedObjectId, e?.ctrlKey || e?.metaKey);

			// if (e?.ctrlKey || e?.metaKey) {
			// 	const url = this.urlBuilder.getUrl(`/tasks/${menuItem.linkedObjectId}`, true);
			// 	window.open(url, '_blank');
			// 	return;
			// }

			// return this.router.navigate([`/tasks/${menuItem.linkedObjectId}`]);
		}

		if (menuItem.typeId === BlockButtonsType.user) {
			if (e?.ctrlKey || e?.metaKey) {
				const url = this.urlBuilder.getUrl(`/user/profile/${menuItem.linkedObjectId}`, true);
				window.open(url, '_blank');
				return;
			}

			return this.router.navigate([`/user/profile/${menuItem.linkedObjectId}`]);
		}

		if (menuItem.typeId === BlockButtonsType.category) {
			this.openLink(`/Syndicate.aspx?forceURL=/1FMain.aspx?CategoryId=${menuItem.linkedObjectId}`, e);
			return;
		}

		if ((menuItem.typeId as string) === BlockButtonsType.portalGrid) {
			this.openLink(`/PortalGrid.aspx?PortalID=${menuItem.linkedObjectId}`, e);
			return;
		}

		if ((menuItem.typeId as string) === BlockButtonsType.newTaskInSubcat) {
			const taskIdArray = (menuItem.id + '').match(/(NewTask)(\d*)/);
			let taskId = menuItem.linkedObjectId;
			if (taskIdArray && taskIdArray.length && taskIdArray.length >= 3) {
				taskId = +taskIdArray[2];
			}
			this.openLink(`/NewTask.aspx?&SubCatID=${taskId}`, e);
			return;
		}

		if (menuItem.typeId === BlockButtonsType.none) {
			if (menuItem.href.includes('/TaskHierarchy.aspx')) {
				const hierarchyIdArray = menuItem.href.match(/(TaskHierarchy\.aspx\?id=)(\d*)/);
				if (hierarchyIdArray && hierarchyIdArray.length && hierarchyIdArray.length >= 2) {
					this.openLink(`/TaskHierarchies/TaskHierarchy.aspx?id=${hierarchyIdArray[2]}`, e);
					return;
				}
			}
		}

		const link = this.removeSameOrigin(menuItem.href);

		if (this.isAbsoluteLink(link)) {
			return window.open(link, '_blank');
		}

		return this.openLink(link, e);
	}

	isActiveRoute(button: IFavoriteBlock) {
		if (
			button.typeId === BlockButtonsType.newTaskInSubcat &&
			location.href.includes(`/newtask/${button.linkedObjectId}`)
		) {
			return true;
		}

		return false;
	}

	onButtonClick(button: IFavoriteBlock, e) {
		this.selectedMenuItem$.next(button as any);
		this.selectedMenuItemId$.next(+button.id);

		if (button.href.includes('TCLeftTrees.openSyndicate(')) {
			const linkArray = [...button.href['matchAll'](/(javascript:void\(TCLeftTrees\.openSyndicate\(')(.*)'(\)\))/gi)];
			const link = linkArray[0][2];
			const lcLink = link.toLowerCase();

			if (lcLink.includes('force=feed')) {
				const fakeUrl = urlFrom(lcLink);
				const catId = fakeUrl.searchParams.get('pid');
				const isCat = fakeUrl.searchParams.get('iscat') === 'true';
				const url = isCat ? '/feeds/category' : '/feeds/subcat';
				const pathParts = [url, catId];
				const queryParams = {};

				if (e?.ctrlKey || e?.metaKey) {
					const urlForWindow = this.urlBuilder.getUrl(
						this.router.serializeUrl(this.router.createUrlTree(pathParts, { queryParams })),
						true
					);
					window.open(urlForWindow, '_blank');
					return;
				}
				return this.router.navigate(pathParts, { queryParams });
			}

			if (
				lcLink.includes('force=grid') ||
				lcLink.includes('force=chats') ||
				lcLink.includes('force=channel') ||
				lcLink.includes('force=kanban')
			) {
				const view = lcLink.split('force=')[1];
				if (button.typeId === BlockButtonsType.category) {
					const url = `/tasks/category/${button.linkedObjectId}/${view}`;
					if (e?.ctrlKey || e?.metaKey) {
						const urlToOpen = this.urlBuilder.getUrl(url, true);
						window.open(urlToOpen, '_blank');
						return;
					}
					return this.router.navigate([url]);
				}
				if (button.typeId === BlockButtonsType.subcategory) {
					this.IsFeedsViewOnly$.pipe(take(1)).subscribe(val => {
						if (val) {
							const url = `/tasks/subcat/${button.linkedObjectId}/feeds`;
							if (e?.ctrlKey || e?.metaKey) {
								const urlToOpen = this.urlBuilder.getUrl(url, true);
								window.open(urlToOpen, '_blank');
								return;
							}
							return this.router.navigate([url]);
						}

						const url = `/tasks/subcat/${button.linkedObjectId}/${view}`;
						if (e?.ctrlKey || e?.metaKey) {
							const urlToOpen = this.urlBuilder.getUrl(url, true);
							window.open(urlToOpen, '_blank');
							return;
						}
						return this.router.navigate([url]);
					});

					return;
				}
			}

			this.openLink('/Syndicate.aspx' + link, e);
			return;
		}
		if (button.typeId === this.blockButtonsType.category) {
			this.openLink('/1FMain.aspx?CategoryId=' + button.linkedObjectId, e);
		} else if (
			button.typeId === this.blockButtonsType.subcategory ||
			button.typeId === this.blockButtonsType.report ||
			button.typeId === this.blockButtonsType.newTaskInSubcat ||
			button.typeId === this.blockButtonsType.portalGrid
		) {
			this.openLink(button.href, e);
		} else if (button.typeId === this.blockButtonsType.none) {
			if (this.isExternalLinkUrl(button.href)) {
				window.open(button.href, '_blank');
			} else {
				this.openLink(button.href, e);
			}
		}

		if (button.typeId === 'NewTaskInSubcat') {
			return this.openOldOrNewNTF(button.linkedObjectId, e);
			//this.openLink(`/NewTask.aspx?SubcatID=${button.linkedObjectId}`, e);
		}

		this.openLink(button.href, e);
	}

	openOldOrNewNTF(subcatId: number, e: any) {
		return this.canSpaNewTask
			.canUseSpaNewTask(subcatId)
			.pipe(
				take(1),
				map(canSpaNewTask => ({ canSpaNewTask, subcatId }))
			)
			.subscribe(({ canSpaNewTask, subcatId }) => {
				if (!canSpaNewTask) {
					return this.openLink(`/NewTask.aspx?SubcatID=${subcatId}`, e);
				}

				const url = `/newtask/${subcatId}`;
				if (e?.ctrlKey || e?.metaKey) {
					const urlToOpen = this.urlBuilder.getUrl(url, true);
					window.open(urlToOpen, '_blank');
					return;
				}
				return this.router.navigate([url]);
			});
	}

	isExternalLinkUrl(url: string): boolean {
		return url.substring(0, 4) === 'http' && !url.includes(this.getCurrentDomainWithProtocol());
	}

	openLink(url: string, e?) {
		if (this.isExternalLinkUrl(url)) {
			return window.open(url, '_blank');
		}
		const spaNoFrameLinkPart = 'spa/noframe';
		if (url.includes(spaNoFrameLinkPart)) {
			const spaLink = url.substr(url.indexOf(spaNoFrameLinkPart) + spaNoFrameLinkPart.length);
			const { pathname, queryParams } = toAngularRouterParts(spaLink);

			if (e?.ctrlKey || e?.metaKey) {
				const urlForWindow = this.urlBuilder.getUrl(
					this.router.serializeUrl(this.router.createUrlTree([pathname], { queryParams: { ...queryParams } })),
					true
				);
				window.open(urlForWindow, '_blank');
				return;
			}

			return this.router.navigate([pathname], { queryParams });
		}

		const spaLinkPart = 'spa/';
		if (url.includes(spaLinkPart)) {
			const spaLink = url.substr(url.indexOf(spaLinkPart) + spaLinkPart.length - 1);
			const { pathname, queryParams } = toAngularRouterParts(spaLink);

			if (e?.ctrlKey || e?.metaKey) {
				const urlForWindow = this.urlBuilder.getUrl(
					this.router.serializeUrl(this.router.createUrlTree([pathname], { queryParams: { ...queryParams } })),
					true
				);
				window.open(urlForWindow, '_blank');
				return;
			}

			return this.router.navigate([pathname], { queryParams });
		}

		const portalLink = '/portal/';
		if (url.includes(portalLink)) {
			const strPid = url.substr(url.indexOf(portalLink) + portalLink.length);
			const portalId = Number(strPid);
			if (!isNaN(portalId)) {
				if (e?.ctrlKey || e?.metaKey) {
					const urlForWindow = this.urlBuilder.getUrl(`/portal/${portalId}`, true);
					window.open(urlForWindow, '_blank');
					return;
				}

				return this.router.navigate([`/portal/${portalId}`]);
			}
		}

		if (url.includes('ReportView')) {
			const reportId = url.match(/\d+/g).map(Number)[0];
			const presetId = url.match(/\d+/g).map(Number)[1];
			const pathname = '/report/' + reportId;
			const urlForWindow = this.urlBuilder.getUrl(
				this.router.serializeUrl(this.router.createUrlTree([pathname], { queryParams: { filterPresetId: presetId } })),
				true
			);
			if (e?.ctrlKey || e?.metaKey) {
				window.open(urlForWindow, '_blank');
				return;
			}

			return this.router.navigate([pathname], { queryParams: { filterPresetId: presetId } });
		}

		const isSpaRoute = this.spaRouteCheck.isExist(url);
		if (isSpaRoute) {
			if (e?.ctrlKey || e?.metaKey) {
				window.open(url, '_blank');
				return;
			}
			const { pathname, queryParams } = toAngularRouterParts(url);
			return this.router.navigate([pathname], { queryParams });
		}

		url = decodeURIComponent(url);

		if (e?.ctrlKey || e?.metaKey) {
			const pathname = '/link';
			const urlForWindow = this.urlBuilder.getUrl(
				this.router.serializeUrl(this.router.createUrlTree([pathname], { queryParams: { url } })),
				true
			);
			window.open(urlForWindow, '_blank');
			return;
		}

		this.router.navigate(['/link'], {
			queryParams: {
				url: url,
			},
		});
	}

	openGridWithSelection(subcatId: number, expressionId: number) {
		this.router.navigate([`/tasks/subcat/${subcatId}/grid`], { queryParams: { SmartQueryId: expressionId } });
	}
}

export const expandedBlockToken = 'expandedFavoritesBlock';
export const defaultFavoritesFolderId = 1000;
