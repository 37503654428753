import { FacadeStoreNames } from '@spa/facade/store-names.enum';
import { merge } from '@valhalla/utils';
import { IRxStore, StoreManager } from '@valhalla/core';

import { effects, NavigationEffectDependencies } from './effects';
import { reducers } from './reducers';
import { initialNavigationState, INavigationState } from './state';

export function getOrCreateNavigationStore(
	storeManager: StoreManager,
	dependencies: NavigationEffectDependencies,
	devTools = false,
	middlewares = []
): IRxStore {
	if (storeManager.isStoreExist(FacadeStoreNames.navigation)) {
		return storeManager.getStore(FacadeStoreNames.navigation);
	}
	const store = storeManager.createStore({
		name: FacadeStoreNames.navigation,
		defaultState: initialNavigationState,
		persistent: {
			migration: state => {
				const s: INavigationState = merge(initialNavigationState, state);
				s.tabsMenu = initialNavigationState.tabsMenu;
				return s;
			},
		},
		effects,
		dependencies,
		devTools: {
			reduxDevTools: devTools,
			useConsoleLogger: devTools,
			useEventLog: false,
		},
		middlewares,
	});
	return store.addReducers(...reducers);
}
