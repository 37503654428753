import {
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { ViewDestroyStreamService } from '../../../../core';

@Component({
	selector: 'vh-sidebar-search',
	templateUrl: './sidebar-search.component.html',
	styleUrls: ['./sidebar-search.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class SidebarSearchComponent implements OnInit {
	constructor(readonly destroy$: ViewDestroyStreamService) {}

	@HostBinding('class.vh-sidebar-search')
	hostClassSelector = true;

	@Input()
	set searchFocus(value) {
		this.searchFocus$.next(value);
	}
	get searchFocus() {
		return this.searchFocus$.value;
	}

	@Input()
	hideClearBtnIfEmpty = false;

	@Output()
	searchInput = new EventEmitter();

	@Output()
	searchClear = new EventEmitter();

	@Output()
	closeSearch = new EventEmitter();

	@ViewChild('inputSearch')
	inputSearch: ElementRef<HTMLInputElement>;

	readonly searchFocus$ = new BehaviorSubject(false);
	readonly clearBtnVisible$ = new BehaviorSubject(true);

	ngOnInit(): void {
		this.clearBtnVisible$.next(!this.hideClearBtnIfEmpty);

		this.searchFocus$.pipe(takeUntil(this.destroy$)).subscribe(isFocus => {
			if (isFocus) {
				this.inputSearch?.nativeElement?.focus();
			}
		});
	}

	onSearchInput(e) {
		if (this.hideClearBtnIfEmpty && e?.target?.value) {
			if (e?.target?.value) {
				this.clearBtnVisible$.next(true);
			} else {
				this.clearBtnVisible$.next(false);
			}
		}

		this.searchInput.emit(e);
	}

	onSearchClear() {
		const searchValue = this.inputSearch.nativeElement.value;

		if (this.hideClearBtnIfEmpty) {
			this.clearBtnVisible$.next(false);
			this.inputSearch?.nativeElement?.focus();
		}

		if (searchValue) {
			this.inputSearch.nativeElement.value = '';
			this.searchClear.emit();
			return;
		}

		this.closeSearch.emit();
	}
}
