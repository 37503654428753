import { BehaviorSubject, exhaustMap, Observable, Subject, take, takeUntil } from 'rxjs';
import { booleanFilter } from '@valhalla/utils';

export type ValueStore<T = any> = ReturnType<Wrapper<T>['wrap']>;

class Wrapper<T> {
	wrap() {
		return createValueStore<T>({});
	}
}

export type CreateValueStoreOptions<T> = {
	initialValue?: T;
	updater?: () => Observable<T>;
	initialUpdate?: boolean;
	truthyValue?: boolean;
};

export function createValueStore<T>({ initialValue, updater, truthyValue, initialUpdate }: CreateValueStoreOptions<T>) {
	let store$ = new BehaviorSubject(initialValue);
	let destroy$ = new Subject<void>();
	let updateNotify$ = new Subject<any>();
	let value$ = store$.asObservable();
	if (truthyValue) {
		value$ = value$.pipe(booleanFilter());
	}
	value$ = value$.pipe(takeUntil(destroy$));
	updateNotify$
		.pipe(
			exhaustMap(params => updater.call(null, ...params).pipe(take(1))),
			takeUntil(destroy$)
		)
		.subscribe({
			next: (val: any) => store$.next(val),
			error: console.error,
		});
	if (initialUpdate && updater) {
		updateNotify$.next([]);
	}
	return {
		update(...params: any[]) {
			if (typeof updater !== 'function') {
				return;
			}
			updateNotify$.next(params);
		},
		destroy() {
			if (store$) {
				destroy$.next();
				destroy$.complete();
				destroy$ = null;
				store$.complete();
				store$ = null;
				updateNotify$.complete();
				updateNotify$ = null;
			}
		},
		get value() {
			return store$?.value;
		},
		value$,
		setValue(value: T) {
			store$.next(value);
		},
	};
}
