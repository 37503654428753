import { HttpClient, HttpEventType, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IAttachment, mapFileToAttachment } from '@valhalla/data/entities';
import { BehaviorSubject, EMPTY, fromEvent, merge, Observable } from 'rxjs';
import { skip, takeUntil, tap, map } from 'rxjs/operators';

import { DataHttpCommonService } from '../data-http-common.service';
import { EndpointUrlConfig } from '../endpoint.config';
import type { IEndpointUrlConfig } from '../endpoint.config';
import {
	IAttachUploadParams,
	IAttachUploadResult,
	IUploadResult,
	FileDataHttpService,
	IFileDialogResult,
	IFileDialogOptions,
	IPreuploadedFilesResponse,
	IUploadFileToTask,
	IUploadType,
	IUploadFileToEp,
	IUploadFileToMultifile,
	IFileInfo,
	IUpdateFileBase64Params,
	IFileRef,
	IFileVersion,
	IPreUploadFileToTask,
	IFileToEPTableLegacy,
	IFileToEPTableLegacyResponse,
	IGetFilestorage,
	IFileStorageRes,
	IFileStorageCreateFolderReq,
	IStorageFolderType,
	IGetFolderInfo,
	IFolderInfoRes,
	IFileToFileStorageFolderReq,
	IFileLoaded,
	IFileStorageFile,
	IFileStorageViewType,
	IFileStorageDeleteFileReq,
	IFileStorageToEP,
	IFileStorageToMultiFile,
	ISearchFileReq,
	ISearchFileRes,
} from './abstract';
import { guid, delayedRetry } from '@valhalla/utils';
import { ResponseType } from '../response-type.enum';
import { ApiVersion, ConfigurationDataHttpService, IApiResponse } from '@spa/data/http';

@Injectable()
export class FileDataHttpServiceImpl implements FileDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService,
		readonly configurationDataService: ConfigurationDataHttpService
	) {}

	searchFiles(req: ISearchFileReq): Observable<ISearchFileRes[]> {
		let url = `files/search-fulltext`;
		url = this.common.getApiUrl(url);

		const qp = {
			query: req?.query,
			dontShowDeleted: req?.dontShowDeleted,
			resultRowsCount: req?.resultRowsCount,
			resultSetOffset: req?.resultSetOffset,
		};

		if (req?.abstractTypeId) {
			qp['abstractTypeId'] = req?.abstractTypeId;
		}

		return this.http
			.get<IApiResponse<ISearchFileRes[]>>(url, {
				params: qp,
			})
			.pipe(map(res => res?.data));
	}

	getFilestorage(params: IGetFilestorage): Observable<IFileStorageRes> {
		let url = `filestorage/${params.folderType}/`;
		const qp = {};

		if (params.folderId) {
			url += `${params.folderId}`;
		}

		if (params?.withFiles) {
			qp['withFiles'] = params?.withFiles;
		}

		if (params?.extraId) {
			qp['extraId'] = params?.extraId;
		}

		url = this.common.getApiUrl(url, ApiVersion.v12);

		return this.http.get<IFileStorageRes>(url, {
			params: qp,
		});
	}

	encryptFile(fileId: number): Observable<any> {
		const url = this.common.getApiUrl(`filestorage/file/${fileId}/encrypt`, ApiVersion.v12);
		return this.http.post(url, { fileId });
	}

	deleteFile(params: IFileStorageDeleteFileReq): Observable<any> {
		const url = this.common.getApiUrl(
			`filestorage/${params?.parentFolderType}/${params?.parentFolderId}/file/${params?.fileId}/delete`,
			ApiVersion.v12
		);
		return this.http.post(url, { ...params });
	}

	getFileInfo2(fileId: number, viewType?: IFileStorageViewType, extraId?: number): Observable<IFileStorageFile> {
		let url = `/filestorage/file/${fileId}/info`;

		const qp = {
			viewType,
			extraId,
		};

		url = this.common.getApiUrl(url, ApiVersion.v12);

		return this.http.get<IFileStorageFile>(url, {
			params: qp,
		});
	}

	getFolderInfo(params: IGetFolderInfo): Observable<IFolderInfoRes> {
		let url = `filestorage/info/${params.folderType}/`;

		if (params?.folderId) {
			url += `${params.folderId}/`;
		}

		const qp = {
			viewType: params?.viewType || 'Grid',
		};

		if (params?.extraId) {
			qp['extraId'] = params?.extraId;
		}

		url = this.common.getApiUrl(url, ApiVersion.v12);
		return this.http.get<IFolderInfoRes>(url, {
			params: qp,
		});
	}

	linkFileWithTask(fileId: number, taskId: number): Observable<any> {
		const url = this.common.getApiUrl(`filestorage/file/${fileId}/link/task/${taskId}`, ApiVersion.v12);
		return this.http.post(url, { fileId, taskId });
	}

	createFolder(
		parentFolderType: IStorageFolderType,
		parentFolderId: number,
		params: IFileStorageCreateFolderReq
	): Observable<any> {
		let url = `filestorage/add/${parentFolderType}/`;

		if (parentFolderId) {
			url += parentFolderId;
		}

		url = this.common.getApiUrl(url, ApiVersion.v12);
		return this.http.post(url, params);
	}

	updateFolder(folderType: IStorageFolderType, folderId: number, params: IFileStorageCreateFolderReq): Observable<any> {
		const url = this.common.getApiUrl(`filestorage/${folderType}/${folderId}/update`, ApiVersion.v12);
		return this.http.post(url, params);
	}

	deleteFolder(folderType: IStorageFolderType, folderId: number, deleteChildren = true): Observable<any> {
		let url = `filestorage/${folderType}/${folderId}/delete`;

		if (deleteChildren) {
			url += '?deleteChildren=true';
		}

		url = this.common.getApiUrl(url, ApiVersion.v12);
		return this.http.post(url, {});
	}

	fileToEPTableLegacy(params: IFileToEPTableLegacy): Observable<IFileToEPTableLegacyResponse[]> {
		const url = this.common.getApiUrl(`files/upload/FileToEPTableLegacy`);
		const formData = new FormData();
		const omitKey = ['file'];
		const queryParams = Object.entries(params)
			.filter(([k, v]) => v !== undefined)
			.map(([key, val]) => (omitKey.includes(key) ? '' : `${key}:${val}`))
			.filter(Boolean)
			.join(',');
		formData.append('', `{${queryParams}}`);
		formData.append(params.file.name, params.file);
		return this.http.post<IApiResponse<IFileToEPTableLegacyResponse[]>>(url, formData).pipe(map(r => r.data));
	}

	fileUrl(fileId: number, versionId?: number, options?: any): string {
		return this.common.urlProvider.fileUrl(fileId, versionId, options);
	}

	getFileContent(fileId: number, versionId?: number, options?: any): Observable<ArrayBuffer> {
		const url = this.fileUrl(fileId, versionId, options);
		return this.http.get(url, {
			responseType: ResponseType.arrayBuffer,
		});
	}

	getFileMeetingContent(key: string, versionId?: number, options?: any): Observable<ArrayBuffer> {
		const url = this.common.urlProvider.fileMeetingUrl(key);
		return this.http.get(url, {
			responseType: ResponseType.arrayBuffer,
		});
	}

	getFileEmailContent(key: string, versionId?: number, options?: any): Observable<ArrayBuffer> {
		const url = this.common.urlProvider.fileEmailUrl(key);
		return this.http.get(url, {
			responseType: ResponseType.arrayBuffer,
		});
	}

	mapFileToAttachment(file: File): Partial<IAttachment> {
		return mapFileToAttachment(file);
	}

	updateFileBase64(req: IUpdateFileBase64Params): Observable<any> {
		const url = this.common.getApiUrl(`/files/update/base64`);
		return this.http.post(url, req);
	}

	preUploadFile(params: IAttachUploadParams): IAttachUploadResult {
		if (!params || !params.attach) {
			return null;
		}
		const url = this.common.getApiUrl(`/files/upload/ToPreUploadedFiles`);
		return this.uploadFileToUrl(params, url);
	}

	getFileInfo(fileId: number): Observable<IFileInfo> {
		const url = `/app/v1.2/api/filestorage/file/${fileId}/info`;

		return this.http.get<IFileInfo>(url);
	}

	getFileVersion(fileId: number): Observable<IFileVersion[]> {
		const url = `/app/v1.2/api/filestorage/file/${fileId}/version`;

		return this.http.get<IFileVersion[]>(url);
	}

	getNeighborFiles(fileId: number): Observable<IFileRef[]> {
		const url = `/app/v1.2/api/filestorage/file/${fileId}/neighbours`;

		return this.http.get<IFileRef[]>(url);
	}

	uploadFileToTaskRest(req: IUploadFileToTask): Observable<IPreuploadedFilesResponse> {
		const url = this.common.getApiUrl(`/files/upload/${IUploadType.fileToTask}`);

		const formData = new FormData();
		formData.append('', `{taskid: ${req.taskId}}`);
		formData.append(req?.attach?.name, req?.attach as any as string);

		return this.http.post<IPreuploadedFilesResponse>(url, formData);
	}

	fileToFileStorageFolder(req: IFileToFileStorageFolderReq): Observable<any> {
		const url = this.common.getApiUrl(`/files/upload/FileToFileStorageFolder`);

		const formData = new FormData();
		formData.append('', `{clientId: '${req.clientId}'}`);
		formData.append(req?.file?.name, req?.file as any as string);
		return this.http.post<IApiResponse<IFileLoaded[]>>(url, formData);
	}

	preUploadedFilesToTask(req: IPreUploadFileToTask): Observable<IPreuploadedFilesResponse> {
		const url = this.common.getApiUrl(`/files/upload/${IUploadType.preUploadedFilesToTask}`);

		const formData = new FormData();
		formData.append('', `{TaskId: ${req.taskId}, PreUploadedFileId: ${req?.preUploadFileId}}`);
		//formData.append(req?.file?.name, req?.file as any as string);

		return this.http.post<IPreuploadedFilesResponse>(url, formData);
	}

	uploadFileToEP(params: IUploadFileToEp): Observable<any> {
		const url = this.common.getApiUrl(`/files/upload/FileToEP`);
		const formData = new FormData();

		let queryParams = `taskid: ${params.taskId}, extParamId: ${params.extParamId}`;

		if (params.comment) {
			queryParams = `${queryParams}, comment: '${params.comment}'`;
		}

		formData.append('', `{${queryParams}}`);
		formData.append(params.fileName, params.fileData);

		return this.http.post<IPreuploadedFilesResponse>(url, formData);
	}

	fileStorageToEP(params: IFileStorageToEP): Observable<any> {
		const url = this.common.getApiUrl(`/files/upload/FileStorageToEP`);
		const formData = new FormData();
		const queryParams = `taskId: ${params.taskId}, extParamId: ${params.extParamId}, fileId: ${params.fileId}, versionId: ${params.fileVersion}`;
		formData.append('', `{${queryParams}}`);

		return this.http.post<IPreuploadedFilesResponse>(url, formData);
	}

	fileStorageToMultiFile(params: IFileStorageToMultiFile): Observable<any> {
		const url = this.common.getApiUrl(`/files/upload/FileStorageToMultiFile`);
		const formData = new FormData();
		//const queryParams = `taskId: ${params.taskId}, extParamId: ${params.extParamId}, files: '${params.files}', clearAllBeforeAdd: ${params?.clearAllBeforeAdd}`;
		//formData.append('', `{${queryParams}}`);

		const queryParams = {
			taskId: params.taskId,
			extParamId: params.extParamId,
			files: params.files,
			clearAllBeforeAdd: params?.clearAllBeforeAdd,
		};

		formData.append('', `${JSON.stringify(queryParams)}`);

		return this.http.post<IPreuploadedFilesResponse>(url, formData);
	}

	uploadFileToMultifile(params: IUploadFileToMultifile): Observable<any> {
		const url = this.common.getApiUrl(`/files/upload/FileToMultiFile`);
		const formData = new FormData();

		let queryParams = `taskid: ${params.taskId}, extParamId: ${params.extParamId}`;

		if (params.comment) {
			queryParams = `${queryParams}, comment: '${params.comment}'`;
		}

		formData.append('', `{${queryParams}}`);

		params.files.forEach(f => {
			formData.append(f.fileName, f.fileData);
		});

		return this.http.post<IPreuploadedFilesResponse>(url, formData);
	}

	ToPreUploadedFiles(formData: FormData): Observable<IPreuploadedFilesResponse> {
		const url = '/api/files/upload/ToPreUploadedFiles';
		return this.http.post<IPreuploadedFilesResponse>(url, formData);
	}

	uploadFile(params: IAttachUploadParams): IAttachUploadResult {
		if (!params || !params.attach) {
			return null;
		}
		const url = this.common.getEndpointUrl(this.config.file.uploadFile);
		return this.uploadFileToUrl(params, url);
	}

	openFileDialog(options?: Partial<IFileDialogOptions>) {
		options = Object.assign(this.defaultFileDialogOptions(), options);
		return new Observable<IFileDialogResult>(observer => {
			const inputEl = document.createElement('input');
			inputEl.type = 'file';
			inputEl.style.display = 'none';
			inputEl.multiple = options.multiple;
			if (options?.onlyMediaFiles) {
				inputEl.accept = 'image/*, video/*';
			}

			if (options?.onlyEmails) {
				inputEl.accept = '.eml';
			}

			function onPickFiles(e: Event) {
				const files = Array.from((<HTMLInputElement>e.target).files);
				inputEl.value = null;
				observer.next({ files });
				observer.complete();
			}

			const change$ = fromEvent(inputEl, 'change');
			const cancel$ = fromEvent(inputEl, 'cancel');
			const pickFiles$ = merge(change$, cancel$).subscribe(onPickFiles);

			document.body.appendChild(inputEl);
			inputEl.click();

			function dispose() {
				pickFiles$.unsubscribe();
				document.body.removeChild(inputEl);
			}

			return () => {
				dispose();
			};
		});
	}

	protected defaultFileDialogOptions(): Partial<IFileDialogOptions> {
		return {
			multiple: true,
		};
	}

	protected uploadFileToUrl(params: IAttachUploadParams, url: string): IAttachUploadResult {
		const taskId = Number(params.taskId);
		if (taskId > 0) {
			url += `?taskId=${taskId}`;
		}

		const formData = new FormData();
		formData.append(`file-0`, params.attach.file, params.attach.name);

		const httpHeaders = new HttpHeaders().append('ngsw-bypass', 'true');

		const req = new HttpRequest('POST', url, formData, {
			reportProgress: true,
			headers: httpHeaders,
			responseType: 'text',
		});
		const settings = this.configurationDataService.appSettingsAnonymousConfig;
		if (settings.MaxRequestLength > 0 && params.attach.file.size >= settings.MaxRequestLength) {
			return {
				progressPercent$: EMPTY,
				result$: EMPTY,
				cancel: () => upload.cancel(),
				maxRequestLength: `${settings.MaxRequestLength}`,
			};
		}
		const upload = this.buildProgressiveRequest<any>(req, params.cancel$);

		return {
			progressPercent$: upload.progressPercent$,
			result$: upload.result$.pipe(
				map(res => {
					const resObj = JSON.parse(res);
					if (resObj?.data[0]?.preUploadFileId) {
						params.attach.id = resObj?.data[0]?.preUploadFileId;
						return params.attach;
					}

					params.attach.id = parseInt(res, 10);
					return params.attach;
				})
			),
			cancel: () => upload.cancel(),
		};
	}

	protected buildProgressiveRequest<R = any, T = any>(
		req: HttpRequest<T>,
		cancel$?: Observable<any>
	): IUploadResult<R> {
		const result$ = new BehaviorSubject<R>(null);
		const progress$ = new BehaviorSubject<number>(1);
		let returnResult$ = result$.pipe(skip(1));
		let returnProgressPercent$ = progress$.asObservable();
		let req$ = this.http.request<R>(req).pipe(
			delayedRetry(3000),
			tap(event => {
				switch (event.type) {
					case HttpEventType.UploadProgress:
						const percentDone = Math.round((100 * event.loaded) / event.total);
						return progress$.next(percentDone);
					case HttpEventType.Response:
						return result$.next(event.body);
				}
			})
		);
		if (cancel$) {
			returnResult$ = result$.pipe(skip(1), takeUntil(cancel$));
			returnProgressPercent$ = progress$.pipe(takeUntil(cancel$));
			req$ = req$.pipe(takeUntil(cancel$));
		}
		const reqSubscription = req$.subscribe({
			error: err => {
				result$.error(err.status === 400 ? err.error || err.message : err.message || err.error);
			},
			complete: () => {
				result$.complete();
				progress$.complete();
			},
		});
		return {
			result$: returnResult$,
			progressPercent$: returnProgressPercent$,
			cancel: () => reqSubscription.unsubscribe(),
		};
	}
}
