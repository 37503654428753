/**
 * Wait for check returns true and call callback if callback not call in timeout then reject
 * @param check check function
 * @param action action callback
 * @param to timeout in seconds
 */
export function waitFor(check: () => boolean, action: () => void, to = 10) {
	const endTime = Date.now() + to * 1000;
	function act() {
		if (Date.now() > endTime) {
			return;
		}
		if (check()) {
			action();
		} else {
			requestAnimationFrame(act);
		}
	}
	act();
}
