import { Injector, NgModuleRef, StaticProvider } from '@angular/core';
import { Observable } from 'rxjs';

import { Scripts, ResourceLoadingStatus, Styles } from './resources.enum';

export abstract class UniversalLoaderProvider {
	/**
	 * Загружает фабрику angular lazy модуля, указанного в angular.json
	 * @param modulePath путь вида: "{путь до файла модуля без расширения}#{имя класса модуля}"
	 * @returns Promise<NgModuleRef>
	 * @example loadModule('src/app/common/web-components/button/button.module#ButtonCommonModule')
	 * @deprecated
	 */
	abstract loadNgModule<T = any>(modulePath, injector?: Injector): Observable<NgModuleRef<T>>;
	/**@deprecated use injectScripts*/
	abstract loadScripts(...scripts: Scripts[]): Observable<boolean>;

	abstract loadStyles(...scripts: Styles[] | string[]): Observable<boolean>;
	/**@deprecated */
	abstract loadSignalR(): Observable<boolean>;
	/**@deprecated */
	abstract getScriptPath(script: Scripts): string;

	abstract injectScripts(...path: string[]): Observable<any>;
	abstract injectScriptsOnce(...paths: string[]): Observable<any>;
	abstract removeStyle(stylePath: string): void;

	abstract loadModule<T>(
		loader: Promise<T>,
		injector?: Injector,
		providers?: StaticProvider[],
		name?: string
	): Observable<NgModuleRef<T>>;

	readonly loadingStatuses: typeof ResourceLoadingStatus;
}
