import { NgModule, Pipe } from '@angular/core';

@Pipe({
	name: 'phone',
})
export class PhoneFormatPipe {
	transform(rawNum: string, phonePrefix = '+') {
		if (rawNum.length !== 11) {
			return rawNum;
		}
		rawNum = phonePrefix + rawNum;

		const countryCodeStr = rawNum.slice(0, 2);
		const areaCodeStr = rawNum.slice(2, 5);
		const midSectionStr = rawNum.slice(5, 8);
		const lastSectionStr = rawNum.slice(8, 10);
		const lastSectionStr2 = rawNum.slice(10, 12);

		return `${countryCodeStr} (${areaCodeStr}) ${midSectionStr}-${lastSectionStr}-${lastSectionStr2}`;
	}
}

@NgModule({
	exports: [PhoneFormatPipe],
	declarations: [PhoneFormatPipe],
})
export class PhoneFormatPipeModule {}
