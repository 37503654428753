import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { IAppInfo } from './model';

export abstract class CheckVersionGuard {
	abstract canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean;
}

export abstract class AppVersionService {
	abstract updateStarted$: Observable<any>;
	abstract get appInfo(): Partial<IAppInfo>;
	abstract update(info?: Partial<IAppInfo>): void;
	/**
	 * return object parsed from assets/release-info/app-info.json file
	 * that file auto generate after deploy
	 */
	abstract getAppInfoFromAssetFile(withInvalidateKey?: boolean): Observable<Partial<IAppInfo>>;

	abstract hasRuntimeBuildNumber(): boolean;

	abstract getRuntimeBuildNumber(): number;

	abstract getRuntimeBuildTime(): number;

	abstract getRuntimeGitHash(): number;

	abstract serverVersionMismatch(): Observable<boolean>;
}
