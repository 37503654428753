import { AnyAction } from 'redux';

export interface IAction<T = any> extends AnyAction {
	readonly payload?: T;
}

export type ActionCreator<T = any> = (payload?: T) => IAction<T>;

export const commonActions = Object.freeze({
	init: 'init',
	loadDataRequest: 'loadDataRequest',
	loadDataSuccess: 'loadDataSuccess',
	loadDataError: 'loadDataError',
});

export type ExtractPayloadType<A> = A extends ActionCreator<infer PayloadT> ? PayloadT : never;
