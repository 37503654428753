import { Inject, Injectable, Optional } from '@angular/core';
import { get, set } from '@valhalla/utils';
import { AbstractLogger, LoggerFactory } from '../diagnostics/logger';
import type { IRxStore } from '../store';

import { StoreSelector } from '../store/utils';
import { ConfigurationProvider, IS_PRODUCTION } from './abstract';
import { actions, CORE_CONFIGURATION_STORE, IConfigurationModel } from './store';

@Injectable()
export class ConfigurationProviderImpl implements ConfigurationProvider {
	constructor(
		@Inject(CORE_CONFIGURATION_STORE) protected readonly store: IRxStore<IConfigurationModel>,
		logger: LoggerFactory,
		@Optional() @Inject(IS_PRODUCTION) protected readonly isProduction: boolean
	) {
		this._logger = logger.createLogger('ConfigurationService');
	}
	private readonly _logger: AbstractLogger;

	get isProd(): boolean {
		return Boolean(this.isProduction);
	}

	get config$() {
		return this.store.select(state => state);
	}

	get config() {
		return this.store.getState<IConfigurationModel>();
	}

	select<R>(selector?: StoreSelector<IConfigurationModel, R>) {
		return this.store.select(selector);
	}

	applyConfig(config: Partial<IConfigurationModel>) {
		if (config) {
			this.store.dispatch(actions.updateConfigurationActionCreator(config));
		}
		return this;
	}

	getPath(path: string) {
		return get(this.config, path);
	}

	setPath(path: string, value) {
		const newConfig = set(this.config, path, value);
		this.applyConfig(newConfig);
	}
}
