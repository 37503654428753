import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { booleanFilter } from '@valhalla/utils';
import { filter, map, share, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PageRefreshService {
	constructor(protected readonly router: Router) {
		this.currentUrl = this.router.url;
	}

	protected currentUrl: string;

	readonly pageRefresh$ = this.router.events.pipe(
		filter(e => e instanceof NavigationEnd),
		map(e => {
			if (history.state['@@ignorePageRefresh']) {
				queueMicrotask(() => {
					delete history.state['@@ignorePageRefresh'];
				});
				return false;
			}
			const isRefresh = this.currentUrl === this.router.url;
			this.currentUrl = this.router.url;
			return isRefresh;
		}),
		booleanFilter(),
		share()
	);

	readonly navigationEnd$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));

	async refresh() {
		return await this.router.navigateByUrl(this.currentUrl);
	}

	async updateQueryParams(
		queryParams: Record<string, any>,
		ignorePageRefresh = true,
		replaceUrl = true,
		updateMode: 'merge' | 'preserve' = 'merge'
	) {
		return this.router.navigate([], {
			queryParams: queryParams,
			queryParamsHandling: updateMode,
			replaceUrl: replaceUrl,
			state: {
				'@@ignorePageRefresh': ignorePageRefresh,
			},
		});
	}
}
