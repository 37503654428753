import { HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from '@valhalla/utils';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpRequestCache {
	// Using the object gives more performance than a Map
	protected readonly requests: Record<string, Observable<HttpEvent<any>>> = {};

	public has(request: HttpRequest<any>): boolean {
		return this.key(request) in this.requests;
	}

	public get(request: HttpRequest<any>): Observable<HttpEvent<any>> {
		return this.requests[this.key(request)].pipe(
			map(e => {
				if (e instanceof HttpResponse) {
					const clone = new HttpResponse({
						body: cloneDeep(e.body),
						headers: e.headers,
						status: e.status,
						statusText: e.statusText,
						url: e.url,
					});
					return clone;
				}
				return e;
			})
		);
	}

	public set(request: HttpRequest<any>, response: Observable<HttpEvent<any>>): void {
		this.requests[this.key(request)] = response;
	}

	public delete(request: HttpRequest<any>): void {
		delete this.requests[this.key(request)];
	}

	protected key(request: HttpRequest<any>): string {
		return [request.urlWithParams, request.responseType].join('#');
	}
}
