import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { AppCardComponent } from './app-card.component';

@NgModule({
	imports: [CommonModule, MatIconModule],
	declarations: [AppCardComponent],
	exports: [AppCardComponent]
})
export class AppCardModule {}
