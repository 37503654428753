import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { IsNoFrameModeService } from '@spa/common/services/is-noframe-mode.service';
import { SpaCommandExecutorFactory } from '../spa-command.contract';
import { RootRegistrationService } from '../root-registration-service';
import type { ModalWindowsService } from '@spa/facade/features/modals';

const createCommand: SpaCommandExecutorFactory = injector => {
	const router = injector.get(Router);
	const zone = injector.get(NgZone);
	const isNoFrame = injector.get(IsNoFrameModeService);

	return async function openTask(payload: any) {
		const taskId = payload?.taskId;
		const isModal = payload?.modal;
		if (!taskId) {
			return console.error(`openTask require option { taskId: number }`);
		}

		if (isModal) {
			return await zone.runTask(async () => {
				const rootReg = injector.get(RootRegistrationService);
				const modals = rootReg.get<ModalWindowsService>('ModalWindowsService');
				return (
					await modals.openTask({
						taskId,
					})
				).afterClosed();
			});
		} else {
			const params = {
				...payload,
			};
			delete params.taskId;

			return zone.runTask(() =>
				router.navigate([isNoFrame.value ? '/noframe/tasks' : '/tasks', taskId], {
					queryParams: {
						...params,
					},
				})
			);
		}
	};
};

export default createCommand;
