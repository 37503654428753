export function preventDefault(e: Event) {
	e && e.preventDefault && e.preventDefault();
}

export function stopPropagation(e: Event) {
	e && e.stopPropagation && e.stopPropagation();
}

export function stopImmediatePropagation(e: Event) {
	e && e.stopImmediatePropagation && e.stopImmediatePropagation();
}
