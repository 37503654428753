import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthService } from '../auth';
import { CookieService } from '../cookie';
import { AbstractLogger, LoggerFactory } from '../diagnostics';
import { AppVersionService, CheckVersionGuard } from './abstract';

@Injectable()
export class CheckVersionGuardImpl implements CheckVersionGuard {
	constructor(
		protected readonly platform: Platform,
		protected readonly cookies: CookieService,
		protected readonly auth: AuthService,
		protected readonly version: AppVersionService,
		logger: LoggerFactory
	) {
		this.logger = logger.createLogger('CheckVersionGuard');
	}

	protected logger: AbstractLogger;

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		this.logger.forceLogInfo('check actual version');
		return this.version.serverVersionMismatch().pipe(
			tap(needInvalidateCache => {
				if (needInvalidateCache) {
					// hard reload for update version
					location.reload();
				}
			}),
			map(needInvalidateCache => !needInvalidateCache)
		);
	}
}
