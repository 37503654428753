import { Pipe, PipeTransform } from '@angular/core';

import { filterArrayByString } from '@valhalla/utils';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
	transform(mainArr: any[], searchText: string, property: string): any {
		return filterArrayByString(mainArr, searchText);
	}
}
