import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ContextMenuModule } from '@valhalla/common/context-menu';

import { AppCardModule } from '../app-card';
import { AppSearchGroupComponent } from './apps-search-group.component';

@NgModule({
	imports: [CommonModule, AppCardModule, MatDividerModule, ContextMenuModule, MatMenuModule, MatIconModule],
	declarations: [AppSearchGroupComponent],
	exports: [AppSearchGroupComponent],
})
export class AppSearchGroupModule {}
