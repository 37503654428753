import { CdkPortal, DomPortalHost } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
	AfterViewInit,
	ApplicationRef,
	Component,
	ComponentFactoryResolver,
	Inject,
	Injector,
	OnDestroy,
	Renderer2,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';

@Component({
	selector: 'vh-layout-content-portal',
	templateUrl: './content-portal.component.html',
	styleUrls: ['./content-portal.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ContentPortalComponent implements AfterViewInit, OnDestroy {
	constructor(
		protected componentFactoryResolver: ComponentFactoryResolver,
		protected applicationRef: ApplicationRef,
		protected injector: Injector,
		@Inject(DOCUMENT) protected document: Document,
		protected renderer: Renderer2
	) {}
	@ViewChild(CdkPortal)
	protected portal: CdkPortal;

	protected domPortalHost: DomPortalHost;

	protected get domPortalHostAnchor() {
		return this.document.querySelector('#vh-layout-main-content-area');
	}

	ngAfterViewInit(): void {
		this.domPortalHost = new DomPortalHost(
			this.domPortalHostAnchor,
			this.componentFactoryResolver,
			this.applicationRef,
			this.injector
		);
		// to send data to portal
		// @see https://stackoverflow.com/questions/47469844/angular-cdk-how-to-set-inputs-in-a-componentportal
		this.domPortalHost.attach(this.portal);
		this.renderer.addClass(this.domPortalHostAnchor, 'portal-rendered');
	}

	ngOnDestroy(): void {
		this.renderer.removeClass(this.domPortalHostAnchor, 'portal-rendered');
		this.domPortalHost.detach();
	}
}
