import { Injectable } from '@angular/core';
import { TabActiveStateService } from '@spa/common/services/tab-active-state.service';
import { DataHttpService } from '@spa/data/http';
import { SignalrProvider } from '@spa/data/signalr';
import { BehaviorSubject, debounceTime, takeUntil } from 'rxjs';

const DEFAULT_TIMEOUT = 300000;

@Injectable({
	providedIn: 'root',
})
export class UnreadMessagesService {
	constructor(
		readonly server: DataHttpService,
		readonly signal: SignalrProvider,
		readonly tabActive: TabActiveStateService
	) {
		this.updateMessagesCount = this.updateMessagesCount.bind(this);
		this.updateMessagesCountWithArgs = this.updateMessagesCountWithArgs.bind(this);
		this.updateMessagesCountOnSignal = this.updateMessagesCountOnSignal.bind(this);

		this.signal.activate();
		server.providersCalendars.onUnreadMessageCountChanged$.addListener('changed', this.updateMessagesCount);
		server.providersCalendars.onUnreadMessageCountChanged$.addListener(
			'changedCount',
			this.updateMessagesCountWithArgs
		);

		signal.calendarEventsChanged$.pipe(debounceTime(300)).subscribe(this.updateMessagesCountOnSignal);
		document.addEventListener('visibilitychange', this.updateMessagesCount);
	}

	nextUpdateTimer: any;
	debounceTimer: any;
	_hasSubscribers = false;
	_unreadMessagesCount$ = new BehaviorSubject(0);

	get unreadMessagesCount$() {
		this._hasSubscribers = true;
		this.updateMessagesCount();
		return this._unreadMessagesCount$;
	}

	clearTimers() {
		if (this.debounceTimer) {
			clearTimeout(this.debounceTimer);
			this.debounceTimer = null;
		}

		if (this.nextUpdateTimer) {
			clearTimeout(this.nextUpdateTimer);
			this.nextUpdateTimer = null;
		}
	}

	updateMessagesCountOnSignal() {
		this.updateMessagesCount();
	}

	updateMessagesCountWithArgs(messagesCount: number) {
		this.clearTimers();

		this._unreadMessagesCount$.next(messagesCount);

		this.clearTimers();
		this.nextUpdateTimer = setTimeout(this.updateMessagesCount, DEFAULT_TIMEOUT);
	}

	updateMessagesCount() {
		if (
			this.server.config.appSettingsAnonymousConfig?.CustomSettings?.showCalendarMessageCounter === false ||
			!this.tabActive.userActive ||
			!this.tabActive.tabVisible
		) {
			return;
		}
		if (this._hasSubscribers && this.tabActive.isMasterTab) {
			this.clearTimers();

			this.debounceTimer = setTimeout(() => {
				const now = new Date();
				now.setDate(now.getDate() - 14);
				this.server.providersCalendars
					.getUnreadMessagesCount({
						dateFrom: now,
					})
					.subscribe(this.updateMessagesCountWithArgs);
			}, 1000);

			this.nextUpdateTimer = setTimeout(this.updateMessagesCount, DEFAULT_TIMEOUT);
		}
	}
}
