/* eslint-disable @typescript-eslint/member-ordering */
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	HostBinding,
	ViewEncapsulation,
	inject,
	Output,
	EventEmitter,
	ElementRef,
	ViewChild,
	OnChanges,
	SimpleChanges,
	OnInit,
	OnDestroy,
} from '@angular/core';
import { IIconColor } from '../icon/icon.model';
import {
	AlertMessageBtnType,
	IAlertMessageBtnColorMap,
	IAlertMessageBtnHoverColorMap,
	IAlertMessageColor,
	IAlertMessageColorMap,
	IAlertMessageIconColorMap,
	IAlertMessageTitleColorMap,
} from './alert-message.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject, fromEvent, map, startWith, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'vh-text-alert',
	templateUrl: './alert-message.component.html',
	styleUrls: ['./alert-message.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class AlertMessageComponent implements OnChanges, OnInit, OnDestroy {
	@Input()
	set color(value: IAlertMessageColor) {
		this.colorFromMap = IAlertMessageColorMap[value];
		this.iconColor = IAlertMessageIconColorMap[value] as unknown as IIconColor;
		this.titleColor = IAlertMessageTitleColorMap[value];
		this.actonButtonColor = IAlertMessageBtnColorMap[value];
		this.actonButtonHoverColor = IAlertMessageBtnHoverColorMap[value];
		this.defineIconName(value);
	}

	@Input()
	title: string;

	@Input()
	useIcon = true;

	@Input()
	useCloseIcon = true;

	@Input()
	alertText: string;

	@Input()
	useHtmlText = false;

	@Input()
	useActionButtons = false;

	@Input()
	mainBtnText: string;

	@Input()
	mainBtnType: AlertMessageBtnType = AlertMessageBtnType.button;

	@Input()
	secondBtnText: string;

	@Input()
	secondBtnType: string;

	@Input()
	set height(value: number) {
		value ? (this._height = `${value}px`) : '100%';
	}

	get height(): number {
		return +this._height?.replace('px', '') || 0;
	}

	@HostBinding('class.vh-text-alert')
	hostClassSelector = true;

	@HostBinding('style.--alert-message-color')
	colorFromMap: string = IAlertMessageColorMap.containerDefault;

	@HostBinding('style.--alert-message-btn-color')
	actonButtonColor: string;

	@HostBinding('style.--alert-message-btn-color-hover')
	actonButtonHoverColor: string;

	@Output()
	closeClicked = new EventEmitter<any>();

	@Output()
	mainBtnClicked = new EventEmitter<any>();

	@Output()
	secondBtnClicked = new EventEmitter<any>();

	@HostBinding('style.height')
	_height: string;

	@ViewChild('alertTitle')
	alertTitleSpan: ElementRef<HTMLSpanElement>;

	@ViewChild('btnWrapper')
	btnWrapper: ElementRef<HTMLDivElement>;

	@ViewChild('alertContent')
	alertContent: ElementRef<HTMLDivElement>;

	readonly sanitizer = inject(DomSanitizer);
	readonly host = inject(ElementRef);
	readonly isAlertContentReady$ = new BehaviorSubject<boolean>(false);
	readonly destroy$ = new Subject();
	readonly IIconColor = IIconColor;
	iconColor: IIconColor;
	titleColor: string;
	iconName: 'info' | 'warning';

	readonly resizeHandler$ = fromEvent(window, 'resize').pipe(
		map(event => (event.target as Window).innerWidth),
		startWith(window.innerWidth)
	);

	ngOnInit(): void {
		this.resizeHandler$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.setAlertContentHeight();
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.height?.currentValue) {
			this.isAlertContentReady$.next(true);
		}
		if (changes.height?.currentValue) {
			setTimeout(() => this.setAlertContentHeight(), 200);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(0);
		this.destroy$.complete();
	}

	defineIconName(color: IAlertMessageColor): void {
		switch (color) {
			case IAlertMessageColor.containerWarning:
			case IAlertMessageColor.containerDanger:
				this.iconName = 'warning';
				break;
			default:
				this.iconName = 'info';
		}
	}

	setAlertContentHeight(): void {
		if (this.height) {
			const titleHeight = this.alertTitleSpan?.nativeElement?.offsetHeight || 0;
			const btnsHeight = this.btnWrapper?.nativeElement?.offsetHeight || 0;
			if (this.alertContent?.nativeElement) {
				this.alertContent.nativeElement.style.height = `calc(${this._height} - ${titleHeight}px - ${btnsHeight}px - 16px)`;
				this.alertContent.nativeElement.style.overflow = 'auto';
				this.isAlertContentReady$.next(true);
			}
		}
	}

	truncateText(text: string, limit: number): string {
		if (text.length > limit) {
			return text.substring(0, limit) + '...';
		}
		return text;
	}

	getSanitizedHTMLAlertText(): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(this.alertText);
	}

	onCloseClick(): void {
		this.closeClicked.emit();
		this.host.nativeElement.remove();
	}

	onMainBtnClick(): void {
		this.mainBtnClicked.emit();
		if (this.mainBtnType === AlertMessageBtnType.button) {
			this.onCloseClick();
		}
	}

	onSecondBtnClick(): void {
		this.secondBtnClicked.emit();
		if (this.secondBtnType === AlertMessageBtnType.button) {
			this.onCloseClick();
		}
	}
}
