<ng-container *ngIf="variant === 'horizontal-style-1'">
	<vh-layout-navbar-horizontal-style-1></vh-layout-navbar-horizontal-style-1>
</ng-container>

<ng-container *ngIf="variant === 'vertical-style-1'">
	<vh-layout-navbar-vertical-style-1></vh-layout-navbar-vertical-style-1>
</ng-container>

<ng-container *ngIf="variant === 'vertical-style-2'">
	<vh-layout-navbar-vertical-style-2
		[showHeader]="showHeader"
		[showUserHeader]="showUserHeader"
		[showLogo]="showLogo"
		[showNavBarMini]="showNavBarMini"
		[layoutState]="layoutState"
		[useCustomColors]="useCustomColors"
	></vh-layout-navbar-vertical-style-2>
</ng-container>
