import { INavigationMenuItem } from '@spa/facade/features/navigation';
import { CategoryTreeNodeType, ICategoryDto } from '@spa/facade/features/navigation/providers/data-provider.dto';
import { buildTrees, sortByLocaleCompare } from '@valhalla/utils';
import { ISpaceItem } from './spaces-panel-item/spaces-panel-item.component';

export function sortChildren(children) {
	return children.sort((child1, child2) => {
		if (child1?.children?.length && !child2?.children?.length) {
			return -1;
		}

		if (!child1?.children?.length && child2?.children?.length) {
			return 1;
		}

		return 0;
	});
}

export function sortTreeByChildren(tree) {
	const treeSorted = sortChildren(tree);

	for (let item of treeSorted) {
		if (item?.children?.length) {
			item.children = sortTreeByChildren(item?.children);
		}
	}

	return treeSorted;
}

export function createTaskTree(tasks, epParentId, category?: Partial<INavigationMenuItem>) {
	const tasksMap1: ISpaceItem[] = tasks.map(t => ({
		name: t?.task?.taskText,
		parentId: (t as any)?.extParams?.[`ExtParam${epParentId}`]?.decimalValue,
		taskId: t.task.taskId,
		children: [],
		data: t,
		canCreate: category?.canCreateTask,
		subcatId: category?.id,
		isSpecialSection: false,
		isOpenedState: t?.isOpenedState,
		selected: t?.selected,
	}));

	const tasksMap2 = Object.assign({}, ...tasksMap1.map(t => ({ [t.taskId]: Object.assign(t, { children: [] }) })));

	const tree = Object.values(tasksMap2).filter(t => {
		const parentId = (t as any)?.parentId;
		return !(parentId && tasksMap2[parentId]?.children.push(t));
	});

	return sortTreeByChildren(tree);
}

export function createTaskTreeWithoutSpecialSection(
	tasks,
	epParentId,
	epSpecialSectionId,
	category?: Partial<INavigationMenuItem>
) {
	const tree = createTaskTree(tasks, epParentId, category);
	return tree.filter(t => !t?.data?.extParams[`ExtParam${epSpecialSectionId}`]?.stringValue);
}

export function createSpecialSectionsTree(specialSections, tasks, epParentId, epSpecialSectionId, subcatId) {
	const tasksTree = createTaskTree(tasks, epParentId, { id: subcatId });
	const taskWithSpecialParent = tasksTree.filter(t => t?.data?.extParams[`ExtParam${epSpecialSectionId}`]?.stringValue);

	const specialSectionsTree = specialSections.map(ss => {
		return {
			id: `${ss?.value}${ss?.text}`,
			name: ss?.text,
			value: ss?.value,
			isSpecialSection: true,
			isOpenedState: ss?.isOpenedState,
			children: taskWithSpecialParent
				.filter(t => t?.data?.extParams[`ExtParam${epSpecialSectionId}`]?.stringValue === ss?.value)
				.map(el => ({
					...el,
					parentSpecialSectionId: `${ss?.value}${ss?.text}`,
				})),
		};
	});

	return specialSectionsTree;
}

export function filterSpaceItems(items: any[], filter: string) {
	const filterLowerCase = filter?.toLowerCase();

	return items.reduce((acc, el) => {
		const name = el?.name?.toLowerCase();
		if (name.includes(filterLowerCase)) {
			const children = filterSpaceItems(el?.children, filterLowerCase);

			acc.push({
				...el,
				children,
			});

			return acc;
		}

		if (el?.children?.length && !name.includes(filterLowerCase)) {
			const children = filterSpaceItems(el?.children, filterLowerCase);

			if (children?.length) {
				acc.push({
					...el,
					children,
				});
			}

			return acc;
		}

		return acc;
	}, []);
}

export function searchSpaceItems(items, filter) {
	if (!filter) {
		return items;
	}

	const result = items.filter(e => {
		return e.nodeType === CategoryTreeNodeType.subcategory && e.name.toLocaleLowerCase().includes(filter);
	});

	const searchRootElements = (parentId): void => {
		if (parentId === CategoryTreeNodeType.root || result.find(ti => ti.id === parentId)) {
			return;
		}
		const parents = items.filter(e => e.id === parentId);
		result.push(...parents);
		parents.forEach(p => {
			searchRootElements(p.parentId);
		});
	};

	result.forEach(subcat => {
		searchRootElements(subcat.parentId);
	});

	return result;
}

export function createSpaceTree(data) {
	const rootId: any = 'root';

	data.forEach(item => {
		if (!item.parentId) {
			item.parentId = rootId;
		}
	});

	data.push({
		id: rootId,
		name: 'Категории',
		nodeType: CategoryTreeNodeType.root,
		parentId: null,
		tasksCounters: null,
	});

	const parentIdGetter = (i: ICategoryDto) =>
		i.parentId === rootId || !i.parentId ? i.parentId : `Category${i.parentId}`;
	const idGetter = (i: ICategoryDto) => (i.id === rootId || !i.id ? i.id : `${i.nodeType}${i.id}`);
	const result = buildTrees(data, idGetter, parentIdGetter);
	return result[0];
}

export function getItemsForBuildTree(items: any[], rootCatId, onlyEnableEdit = false) {
	const spasesItems = onlyEnableEdit
		? items.filter(i => i?.isSpace && i?.canCreateTask)
		: items.filter(i => i?.isSpace);
	const parentsItems = [];

	const getParent = parentId => {
		const parent = items.find(
			i => i?.id === parentId && (i.nodeType === 'Category' || i.nodeType === CategoryTreeNodeType.root)
		);
		const isAdded = parentsItems.some(pi => pi?.id === parent?.id);

		if (parent && !isAdded) {
			parentsItems.push(parent);
		}

		if (parent?.parentId) {
			getParent(parent.parentId);
		}
	};

	spasesItems.forEach(i => {
		getParent(i.parentId);
	});

	// rootCatId - системная категория для пространств
	// для всех parentId === rootCatId ставим root, что бы не отображать уровень этой системной категории
	const parentsItemsMapped = parentsItems
		.filter(i => i?.id !== rootCatId)
		.map(i => {
			if (i.parentId === rootCatId || !i?.parentId) {
				i.parentId = 'root';
			}
			return i;
		});

	const resArr = [...spasesItems, ...parentsItemsMapped];

	return sortByLocaleCompare(resArr, el => el.name);
}
