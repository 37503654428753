import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'vh-layout-mobile-dynamic-panel',
	templateUrl: './mobile-dynamic-panel.component.html',
	styleUrls: ['./mobile-dynamic-panel.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MobileDynamicPanelComponent {
	// @HostBinding('attr.id')
	// readonly attrId = 'vh-layout-mobile-dynamic-panel';
}
