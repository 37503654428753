export function localeStringSort<T = any>(config?: {
	selector?: (item: T) => T[keyof T];
	ascDesc?: boolean;
	locales?: string | string[];
	options?: Intl.CollatorOptions;
}) {
	config = Object.assign(
		{
			ascDesc: true
		},
		config
	);
	const ascDesc = Boolean(config && config.ascDesc) ? 1 : -1;
	return (a, b) => {
		const selector = config && config.selector;
		if (selector) {
			const sa = String(selector(a));
			const sb = String(selector(b));
			return ascDesc * sa.localeCompare(sb);
		}
		return ascDesc * String(a).localeCompare(String(b));
	};
}
