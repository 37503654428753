import { Injectable, Provider } from '@angular/core';
import { enumWithNs, isNullOrUndefined, isObject } from '@valhalla/utils';
import { Subscription } from 'rxjs';
import { debounceTime, ignoreElements, map, skip, tap } from 'rxjs/operators';
import { LocalStorageProvider } from '../../db/local-storage';
import { IAction } from '../actions';
import { ReducerBase } from '../reducer-base';
import { IRxStore } from '../rx-store';
import { Effect, ofType } from '../utils';
import { StorePlugin, StorePluginFactory } from './abstract';

const pluginName = 'PersistentStorePlugin';

enum Actions {
	loadFromDb = 'loadFromDb',
	saveToDb = 'saveToDb',
}
const actions = enumWithNs(Actions, pluginName);

function loadFromDbAction(data): IAction {
	return {
		type: actions.loadFromDb,
		payload: data,
	};
}

function saveToDbAction(data): IAction {
	return {
		type: actions.saveToDb,
		payload: data,
	};
}

class LoadFromDbReducer implements ReducerBase {
	actionType = actions.loadFromDb;

	reduce(state: any, action: IAction) {
		return isNullOrUndefined(action.payload) ? state : action.payload;
	}
}

export class PersistentStorePlugin implements StorePlugin {
	constructor(readonly dbProvider: LocalStorageProvider) {}

	name = pluginName;
	reducers = [LoadFromDbReducer];
	storeName = '';
	storeSub: Subscription;

	get effects() {
		return [this.saveToDbEffect];
	}

	get storageKey() {
		return `${this.name}/${this.storeName}`;
	}

	saveToDbEffect: Effect = actions$ => {
		return actions$.pipe(
			ofType(actions.saveToDb),
			map(action => action.payload),
			tap(data => {
				this.dbProvider.set(this.storageKey, data);
			}),
			ignoreElements()
		);
	};

	activate(store: IRxStore): void {
		this.storeName = store.storeName;
		let dbData = this.dbProvider.get(this.storageKey);
		if (isObject(store.config.persistent) && store.config.persistent.migration) {
			dbData = store.config.persistent.migration(dbData);
		}
		store.dispatch(loadFromDbAction(dbData));
		this.storeSub = store
			.select()
			.pipe(skip(1), debounceTime(1000))
			.subscribe(data => {
				store.dispatch(saveToDbAction(data));
			});
	}
	deactivate(store: IRxStore): void {
		this.storeSub && this.storeSub.unsubscribe();
	}
}

@Injectable()
export class PersistentStorePluginFactory implements StorePluginFactory {
	constructor(readonly dbProvider: LocalStorageProvider) {}
	createPlugin(): PersistentStorePlugin {
		return new PersistentStorePlugin(this.dbProvider);
	}
}

export function persistentStorePluginFactory(factory: PersistentStorePluginFactory) {
	return factory.createPlugin();
}

export const persistentPluginProviders: Provider[] = [
	PersistentStorePluginFactory,
	{
		provide: PersistentStorePlugin,
		useFactory: persistentStorePluginFactory,
		deps: [PersistentStorePluginFactory],
	},
];
