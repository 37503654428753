export async function copyToClipboard(value: string) {
	try {
		return await navigator.clipboard.writeText(value);
	} catch (message) {
		console.error(message);
	}
}

export async function valueFromClipboard() {
	try {
		const text = await navigator.clipboard.readText();
		return text;
	} catch (message) {
		console.error(message);
	}
}
