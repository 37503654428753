<div class="navbar-top" [ngClass]="fuseConfig?.layout?.navbar?.secondaryBackground">
	<div class="logo">
		<img class="logo-icon" src="assets/images/logos/fuse.svg" />
		<span class="logo-text secondary-text">FUSE</span>
	</div>

	<div class="buttons">
		<button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()">
			<mat-icon class="secondary-text">menu</mat-icon>
		</button>

		<button class="toggle-sidebar-opened d-block d-md-none" (click)="toggleSidebarOpened()">
			<mat-icon class="secondary-text">arrow_back</mat-icon>
		</button>
	</div>
</div>

<div
	class="navbar-scroll-container"
	[ngClass]="fuseConfig.layout.navbar.primaryBackground"
	vhPerfectScrollbar
	[vhPerfectScrollbarOptions]="{ suppressScrollX: true }"
>
	<div class="user" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
		<div class="h3 username">Charlie Adams</div>
		<div class="h5 email hint-text mt-8">adams.charlie@mail.com</div>
		<div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
			<img class="avatar" src="assets/images/avatars/Velazquez.jpg" />
		</div>
	</div>

	<div class="navbar-content">
		<vh-common-navigation layout="vertical" [navigation]="navigation"></vh-common-navigation>
	</div>
</div>
