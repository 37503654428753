import { Provider } from '@angular/core';
import { StoreManager, LoggerFactory, ConfigurationProvider } from '@valhalla/core';

import { UserQuickAppsEffectDependencies } from './effects';
import { NGDI_CONF_TOKEN_APPS_USER_QUICK_STORE } from './ngdi.tokens';
import { getOrCreateUserQuickAppsStore } from './store.factory';

export function facadeStoreFactory(
	manager: StoreManager,
	deps: UserQuickAppsEffectDependencies,
	config: ConfigurationProvider
) {
	return getOrCreateUserQuickAppsStore(manager, deps, !config.isProd);
}

export function effectDependencyFactory(loggerFactory: LoggerFactory) {
	const effectDependency: UserQuickAppsEffectDependencies = {
		loggerFactory: loggerFactory
	};
	return effectDependency;
}

export const NGDI_CONF_STORE_USER_QUICK_APPS: Provider[] = [
	{
		provide: UserQuickAppsEffectDependencies,
		useFactory: effectDependencyFactory,
		deps: [LoggerFactory]
	},
	{
		provide: NGDI_CONF_TOKEN_APPS_USER_QUICK_STORE,
		useFactory: facadeStoreFactory,
		deps: [StoreManager, UserQuickAppsEffectDependencies, ConfigurationProvider]
	}
];
