import { Observable } from 'rxjs';

import {
	IEpTableDataDto,
	IEpTableDataRowCellDto,
	IEpTableSettingsDto,
	IGetTableDataCriteria,
	IGetTableSettingsCriteria,
	IUpdateTableDataParams,
} from './table';

export abstract class EpDataHttpService {
	abstract getTableSettings(criteria: IGetTableSettingsCriteria): Observable<IEpTableSettingsDto>;
	abstract getTableData<T extends IEpTableDataRowCellDto = IEpTableDataRowCellDto>(
		criteria: IGetTableDataCriteria
	): Observable<IEpTableDataDto<T>>;
	abstract getTableMultiselectData<T extends IEpTableDataRowCellDto = IEpTableDataRowCellDto>(
		criteria: IGetTableDataCriteria
	): Observable<IEpTableDataDto<T>>;
	abstract upateTableData({ epId, taskId, meta }: IUpdateTableDataParams): Observable<any>;

	abstract getEpSelectValues(params: IGetEpSelectValuesParams): Observable<IGetEpSelectValuesResult>;
	abstract getLookupHierarchyValues(params: IGetEpSelectValuesParams): Observable<IHierarchyLookupResponse>;
	abstract getEpLookupValues(params: IGetEpLookupValuesParams): Observable<IGetEpLookupValuesResult>;

	abstract getEpAddressSuggections(address: string): Observable<any>;
	abstract getEpAddressInfo(addressId: number): Observable<IGetEpAddressInfoResult>;

	abstract getMultiLookupTableSettings(params: IGetMultiLookupTableSettings): Observable<IEpTableSettingsDto>;
	abstract getMultiLookupTableData<T extends IEpTableDataRowCellDto = IEpTableDataRowCellDto>(
		params: IGetMultiLookupTableData
	): Observable<IEpTableDataDto<T>>;
	abstract updateMultiLookupTableData(params: IUpdateMultiLookupTableData): Observable<any>;
	abstract updateMultiLookupTableData(params: IUpdateMultiLookupTableData): Observable<any>;
	abstract getMultiLookupTableExcelExport(params: IGetMultiLookupExcelExport): Observable<any>;
	abstract importMultiLookupTableExcel(
		params: IMultiLookupExcelImport
	): Observable<{ data: IMultiLookupExcelImportResult }>;

	abstract exportTable(params: IEpTableExportParams): Observable<any>;

	abstract importTable(params: IEpTableImportParams): Observable<{ data: IMultiLookupExcelImportResult }>;

	abstract getTreeData(params: IEpTreeReq): Observable<IEpTreeRes>;

	abstract getEpTextSuggestValues(suggestType: SuggestTypes, searchQuery: string): Observable<string[]>;

	abstract chooseAllTasks(params: IChooseAllTasksParams): Observable<any>;

	abstract getMultilookupFolders(params: IEpMultilookupFoldersReq): Observable<IEpMultilookupFoldersRes>;
	abstract updateMultiLookup(params: IUpdateMultiLookupReq): Observable<any>;
	abstract updateMultilookupFolders(params: IEpMultilookupFoldersUpdateReq): Observable<any>;
	abstract deleteMultilookupFolder(params: IEpMultilookupFoldersDeleteReq): Observable<any>;
	abstract checkedMultilookupTask(params: IEpMultilookupTaskChecked): Observable<any>;
	abstract uncheckedMultilookupTask(params: IEpMultilookupTaskChecked): Observable<any>;
	abstract copyMultilookupTasks(params: ICopyMultilookupTasks): Observable<any>;
}

export interface ICopyMultilookupTasks {
	targetTaskId: number;
	targetExtParamId: number;
	targetFolderId: number;
	sourceTaskId: number;
	sourceExtParamId: number;
	sourceFolderId?: number;
}

export interface IEpMultilookupTaskChecked {
	extParamId: number;
	taskId: number;
	data: {
		selectedTaskId: number;
	};
}

export interface IEpMultilookupFoldersDeleteReq {
	extParamId: number;
	taskId: number;
	data: {
		folderId: number;
		removeTasks?: boolean;
	};
}

export interface IEpMultilookupFoldersUpdateReq {
	extParamId: number;
	taskId: number;
	data: {
		folders: IEpMultilookupFolderItem[];
	};
}

export interface IUpdateMultiLookupReq {
	extParamId: number;
	taskId: number;
	data: {
		add?: IUpdateMultiLookupItem[];
		delete?: IUpdateMultiLookupItem[] | any;
		clearFolders?: number[];
	};
}

export interface IUpdateMultiLookupItem {
	taskId: number;
	folderId: number;
}

export interface IEpMultilookupFoldersRes {
	folders: IEpMultilookupFolderItem[];
}

export interface IEpMultilookupFolderItem {
	id?: number;
	name?: string;
	order?: number;
}
export interface IEpMultilookupFoldersReq {
	extParamId: number;
	taskId: number;
}

export interface IEpTreeRes {
	nodes: IEpTreeNode[];
}
export interface IEpTreeReq {
	extParamId: number;
	childNodeId: number;
	filter: string;
}

export interface IEpTreeNode {
	id: number;
	extParamId: number;
	parentId: number;
	text: string;
	order: number;
	guid: string;
}

export interface IEpTableExportParams {
	taskId: number;
	extParamId: number;
	filter?: any;
	group?: any[];
	skip?: number;
	sort?: any[];
	take?: number;
	fileName?: string;
	observe?: string;
}

export interface IEpTableImportParams {
	taskId: number;
	extParamId: number;
	formData: any;
	importSettings: Partial<{
		ignoreNotFoundKeyValues: boolean;
		overwriteTable: boolean;
		columnNamesInFirstRow: boolean;
	}>;
}

export interface IGetMultiLookupTableSettings {
	extParamId: number;
	subcatId: number;
	taskId?: number;
}

export interface IGetMultiLookupTableData {
	extParamId: number;
	taskId: number;
	subcatId: number;
	taskIds?: number[];
	isNewTask?: boolean;
	folderId?: number;
	gridState?: Partial<{
		take: number;
		skip: number;
		sort: [];
		group: [];
		filter: null;
	}>;
}

export interface IGetMultiLookupExcelExport {
	extParamId: number;
	taskId: number;
	gridState?: Partial<{
		take: number;
		skip: number;
		sort: [];
		group: [];
		filter: null;
		observe: string;
	}>;
}

export interface IMultiLookupExcelImport {
	extParamId: number;
	taskId: number;
	formData: any;
	importSettings: Partial<{
		ignoreNotFoundKeyValues: boolean;
		overwriteTable: boolean;
		columnNamesInFirstRow: boolean;
	}>;
}

export interface IMultiLookupExcelImportResult {
	errors: string[];
	messages: string[];
}

export interface IUpdateMultiLookupTableData {
	extParamId: number;
	taskId: number;
	data: any;
	comment?: string;
}

export interface IChooseAllTasksParams {
	extParamId: number;
	taskId: number;
	data: any;
}

export interface IGetEpLookupValuesParams {
	subcatId: number;
	extParamId: number;
	textFilter?: string;
	taskId?: number;
	take?: number;
	skip?: number;
	tableEpColumnId?: number;
	parentExtParamId?: number;
	parentExtParamId2?: number;
	parentColumnId?: number;
	parentColumnId2?: number;
	parentExtParamValues?: any[];
	parentExtParamValuesStr?: string;
	parentExtParamValues2?: any[];
	valuesFilter?: any[];
	selectedValues?: any[];
}

export type IGetEpLookupValuesResult = {
	text: string;
	value: string;
}[];

export interface IGetEpSelectValuesParams {
	subcatId: number;
	extParamId: number;
	text?: string;
	skip?: number;
	take?: number;
	taskId?: number;
	isFromSubcatTasks?: boolean;
	columnId?: number;
	parentColumnId?: number;
	parentColumnId2?: number;
	parentId?: number;
	parentId2?: number;
	parentValues?: any[];
	parentValues2?: any[];
	otherValuesTableJson?: string;
	sourceBlockId?: number;
	parentTaskId?: number;
	selected?: boolean;
	countTotal?: boolean;
}

export interface IGetEpSelectValuesResult {
	data: IGetEpSelectValue[];
	total: number;
	group?: any[];
	aggregates?: any;
	hasMore?: boolean;
}

export interface IGetEpSelectValue {
	text: string;
	value: string;
}

export interface IGetEpAddressInfoResult {
	area: string;
	areaType: string;
	areaTypeFull: string;
	block: string;
	blockType: string;
	blockTypeFull: string;
	city: string;
	cityDistrict: string;
	cityType: string;
	cityTypeFull: string;
	country: string;
	fiasId: string;
	fiasLevel: string;
	flat: string;
	flatType: string;
	flatTypeFull: string;
	fullAddress: string;
	house: string;
	houseType: string;
	houseTypeFull: string;
	kladrId: string;
	latitude: number;
	longitude: number;
	okato: string;
	oktmo: string;
	postalCode: number;
	region: string;
	regionType: string;
	regionTypeFull: string;
	settlement: string;
	settlementType: string;
	settlementTypeFull: string;
	street: string;
	streetType: string;
	streetTypeFull: string;
}

export enum SuggestTypes {
	FullName = 'FullName',
	LastName = 'LastName',
	Name = 'Name',
	Address = 'Address',
	Email = 'Email',
}

export interface IHierarchyLookupItem {
	isClosed: boolean;
	isFolder: boolean;
	isLeaf: boolean;
	isOverdue: boolean;
	isWorkFinished: boolean;
	parentTaskId: number | null;
	taskId: number;
	taskText: string;
}
export interface IHierarchyLookupResponse {
	data: {
		tasks: IHierarchyLookupItem[];
	};
}
