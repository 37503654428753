import { Portal } from '@angular/cdk/portal';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	Type,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { getFileExtension, getMime, IAttachment, MimeType } from '@valhalla/data/entities';
import { isNullOrUndefined } from '@valhalla/utils';
import { DataHttpService, IFileInfo } from '@spa/data/http';
import { DownloadViewerComponent } from './extensions/download/download-viewer.component';
import { IFileViewerDialogComponentDataOptions } from './file-viewer-dialog.component';
import { VIEWERS } from './file-viewer.config';
import { FileViewerService } from './file-viewer.service';

const selector = 'vh-file-viewer';

/*
	Чтобы добавить новый вьювер/редактор файлов, просто добавляем сюда класс вьювера,
	который поддерживает интерфейс IFileViewer и обозначаем на какие MimeTypes или FileExtensions он будет работать
*/

/**
 * Компонент для рендера файлов
 * рендерит внутренний компонент по типу файла
 */
@Component({
	// tslint:disable-next-line:component-selector
	selector: selector,
	templateUrl: './file-viewer.component.html',
	styleUrls: ['./file-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [],
})
export class FileViewerComponent implements OnDestroy, IFileViewer, OnChanges {
	constructor(
		private resolver: ComponentFactoryResolver,
		readonly server: DataHttpService,
		readonly cdr: ChangeDetectorRef,
		readonly fileViewerService: FileViewerService
	) {}

	static getMimeToViewer(file: Partial<IAttachment>) {
		const mime = getMime(file) as MimeType;
		const ext = getFileExtension(file.name)?.toLowerCase();
		const viewer = VIEWERS.find(
			x => (x.mime && mime && x.mime.indexOf(mime) > -1) || (x.ext && ext && x.ext.indexOf(ext) > -1)
		);
		return viewer;
	}

	static hasViewer(file: Partial<IAttachment>) {
		return !isNullOrUndefined(this.getMimeToViewer(file));
	}

	@Input()
	public file: Partial<IAttachment>;

	@Input()
	public options: IFileViewerDialogComponentDataOptions;

	@Input()
	public fileInfo: IFileInfo;

	@Input()
	@HostBinding('class.vh-file-viewer--full-screen')
	fullScreenMode: boolean;

	@Output()
	public ready = new EventEmitter<any>();

	@ViewChild('viewerContainer', { static: true, read: ViewContainerRef })
	private viewerContainer: ViewContainerRef;

	public settings: IFileViewerSettings;

	private viewer: ComponentRef<IFileViewer>;

	public get isDirty(): boolean {
		if (this.viewer && this.viewer.instance) {
			return this.viewer.instance.isDirty;
		}

		return false;
	}

	ngOnDestroy(): void {
		if (this.viewer && this.viewer.destroy) this.viewer.destroy();
	}

	ngOnChanges(): void {
		this.init();
	}

	normalizeFile() {
		this.file.name = this.fileInfo.name;
		this.file.mime = this.fileInfo.mime;
	}

	createComponent() {
		this.viewerContainer.clear();
		const factory = this.getViewerFactory();
		if (this.viewer && this.viewer.destroy) {
			this.viewer.destroy();
		}

		if (factory) {
			this.viewer = this.viewerContainer.createComponent(factory);
			this.viewer.instance.file = this.file;
			this.viewer.instance.fileInfo = this.fileInfo;
			this.viewer.instance.options = this.options;
			this.settings = this.viewer.instance.settings;
		}
	}

	setFileById(fileId: number) {
		this.file = {
			id: fileId,
		};

		this.init();
	}

	init() {
		this.settings = null;
		this.file.id = this.file.id || this.file.uploadID || this.file.uploadId;

		if (this.file.id && !this.options?.isEmailFile && !this.options?.isMeetingFile && !this.options?.openAsBlob) {
			if (this.fileInfo && this.fileInfo.id === this.file.id) {
				this.normalizeFile();
				this.createComponent();
				this.ready.emit(this.file);
				this.cdr.detectChanges();
			} else {
				this.cdr.detectChanges();
				this.server.files.getFileInfo(this.file.id).subscribe(fi => {
					this.fileInfo = fi;
					this.normalizeFile();
					this.createComponent();
					this.ready.emit(this.file);
					this.cdr.detectChanges();
				});
			}
		} else {
			this.createComponent();
			this.ready.emit(this.file);
			this.cdr.detectChanges();
		}
	}

	getViewerFactory() {
		const mimeToViewer = FileViewerComponent.getMimeToViewer(this.file);

		if (this.fileViewerService.isExceptionFileWebApps(this.file)) {
			return this.resolver.resolveComponentFactory(DownloadViewerComponent);
		}

		if (mimeToViewer) {
			return this.resolver.resolveComponentFactory(mimeToViewer.viewer as Type<IFileViewer>);
		} else {
			return this.resolver.resolveComponentFactory(DownloadViewerComponent);
		}
	}
}

export interface IFileViewer {
	file: Partial<IAttachment>;
	fileInfo: IFileInfo;
	settings: IFileViewerSettings;
	isDirty: boolean;
	options?: IFileViewerDialogComponentDataOptions;
}

export interface IFileViewerSettings {
	actionPanel: Portal<any>;
	settingsPanel: Portal<any>;
	mobileActionPanel?: Portal<any>;
}
