import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	adjustComment,
	IApi20Result,
	IAsmxDto,
	IChat,
	IComment,
	ITableDataSource,
	IUser,
} from '@valhalla/data/entities';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { IApiResponse } from '../api-response';
import ApiVersion from '../api-versions';
import { IGetCommentsOffsetCriteria, IGetCommentsOffsetResult } from '../comments/abstract';
import { DataHttpCommonService } from '../data-http-common.service';
import type { IEndpointUrlConfig } from '../endpoint.config';
import { EndpointUrlConfig } from '../endpoint.config';
import {
	ChatDataHttpService,
	IAddComment,
	IChangeTaskText,
	IChatClose,
	IChatCreate,
	IChatCreateResult,
	IChatSetNotificationModeCriteria,
	IChatUnsubscribe,
	IGetChatsCriteria,
	IGetCommentsCriteria,
	IGetCommentsResult,
	IGetContactsCriteria,
	IGetGetCountersResult,
	IPostComment,
	IRequetOptions,
	ISendComment,
	ISendCommentResult,
} from './abstract';

@Injectable()
export class ChatDataHttpServiceImpl implements ChatDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService
	) {}

	getCounters(userId?: number): Observable<IGetGetCountersResult> {
		const url = this.common.getApiUrl(`chats/counters`);
		const params: any = {};
		if (userId > 0) {
			params.userId = userId;
		}
		return this.http.get<IApiResponse<any>>(url, { params }).pipe(map(res => res?.data || []));
	}

	getContacts(criteria?: Partial<IGetContactsCriteria>): Observable<IUser[]> {
		const body = {
			query: String((criteria && criteria.userName) || '          '),
		};

		const url = this.common.getApiUrl(`mobile/users/search`, ApiVersion.v10);
		return this.http.post<IApiResponse<IUser[]>>(url, body).pipe(map(res => res?.data || []));
	}

	close(taskId: number): Observable<any> {
		const url = this.common.getApiUrl(`chats/close/${taskId}`);
		return this.http.post<any>(url, null);
	}

	leave(taskId: number): Observable<any> {
		const url = this.common.getApiUrl(`chats/left/${taskId}`);
		return this.http.post<any>(url, null);
	}

	getChats(
		criteria?: Partial<IGetChatsCriteria>,
		options?: {
			headers?: HttpHeaders | { [header: string]: string | string[] };
			observe?: 'body';
			params?: HttpParams | { [param: string]: string | string[] };
			reportProgress?: boolean;
			responseType?: 'json';
			withCredentials?: boolean;
		}
	): Observable<ITableDataSource<IChat>> {
		const url = this.common.getApiUrl('chats');
		const limit = Math.min(Math.max(criteria?.limit || 0, 1), 30);
		const offset = Math.max(criteria?.offset || 0, 0);
		options = options || {};
		options.params = { limit: String(limit), offset: String(offset) };
		const filter = String(criteria?.queryString || '').trim();
		if (filter) {
			options.params.queryString = filter;
		}
		if (Array.isArray(criteria?.subcatIds)) {
			options.params['ids.ids'] = criteria.subcatIds.map(String);
		}
		return this.http.get<ITableDataSource<IChat>>(url, options);
	}
	getComments(
		criteria: IGetCommentsCriteria,
		options?: {
			headers?: HttpHeaders | { [header: string]: string | string[] };
			observe?: 'body';
			params?: HttpParams | { [param: string]: string | string[] };
			reportProgress?: boolean;
			responseType?: 'json';
			withCredentials?: boolean;
		}
	): Observable<IGetCommentsResult> {
		const req$ = this.getCommentsOffset({
			commentId: criteria.maxCommentId,
			limitBefore: criteria.maxCommentsToReturn,
			limitAfter: 0,
		}).pipe(
			map(data => {
				const result: IGetCommentsResult = {
					// если пришло меньше чем запрашивали - делаем вывод, что больше комментов нет
					hasMoreComments: !(data.comments.length < criteria.maxCommentsToReturn),
					comments: data.comments,
				};
				return result;
			})
		);

		return req$;
	}

	getCommentsOffset(
		criteria: IGetCommentsOffsetCriteria,
		options?: Partial<IRequetOptions>
	): Observable<IGetCommentsOffsetResult> {
		const url = this.common.getApiUrl(this.config.chat.getCommentsOffset);
		return this.http.post<IApi20Result<IGetCommentsOffsetResult>>(url, criteria).pipe(map(result => result.data));
	}

	sendComment(data: Partial<ISendComment>): Observable<ISendCommentResult> {
		const url = this.common.getEndpointUrl(this.config.chat.sendComment);

		const body: Partial<ISendComment> = {
			copyRecipientsIds: null,
			fileIds: null,
			inReplyToCommentId: null,
			isChat: false,
			location: null,
			needsAnswer: false,
			recipientIds: null,
			guid: null,
			...data,
		};

		type ResponseDto = IAsmxDto<number>;

		return this.http.post<ResponseDto>(url, body).pipe(
			map(result => ({
				commentId: result && result.d,
			}))
		);
	}

	arrayFromString(str) {
		if (!str?.length) {
			return [];
		}
		return str.split(';').map(el => Number(el));
	}

	transformBodyForAddComment(data: Partial<IPostComment>): Partial<IAddComment> {
		const newData: IAddComment = {
			guid: data.guid,
			addToQuickReply: data?.addToQuickReply,
			chatMode: data?.chatMode,
			commentText: data?.commentText ? data?.commentText : null,
			copyRecipientGroupsIds: this.arrayFromString(data?.copyRecipientGroups),
			copyRecipientsIds: this.arrayFromString(data?.copyRecipientsList),
			fileIds: this.arrayFromString(data?.fileIDs),
			forceEmail: data?.forceEmail,
			forwardedCommentId: data?.forwardedCommentID,
			inReplyToCommentId: data?.inReplyToCommentID,
			location: data?.location,
			maxCommentId: data?.maxCommentId,
			needSubscribe: data?.needSubscribe,
			needSubscriberOthers: data?.needSubscriberOthers,
			needsAnswer: data?.needsAnswer,
			recipientGroupsIds: this.arrayFromString(data?.recipientGroups),
			recipientIds: this.arrayFromString(data?.recipientsList),
			sendSms: data?.sendSms,
			taskId: data?.taskId,
			SetAnsweredOnlyForMe: data?.SetAnsweredOnlyForMe,
			commentRequest: data?.commentRequest,
			includeText: data?.includeText,
			forwardedCommentsIds: data?.forwardedCommentsIds,
			postFromCommentFeed: data?.postFromCommentFeed,
		};

		return newData;
	}

	/**@deprecated use addComment */
	postComment(data: Partial<IPostComment>): Observable<Partial<IComment>> {
		const url = this.common.getEndpointUrl(this.config.chat.postComment);

		const body: Partial<IPostComment> = {
			addToQuickReply: false,
			chatMode: false,
			commentText: null,
			copyRecipientGroups: '',
			copyRecipientsList: '',
			fileIDs: '',
			forTaskTree: false,
			forceEmail: data.forceEmail ?? false,
			forceRefreshRecipients: false,
			forwardedCommentID: null,
			inReplyToCommentID: null,
			location: null,
			maxCommentId: null,
			needSubscribe: true,
			needSubscriberOthers: true,
			needsAnswer: false,
			recipientGroups: '',
			recipientsList: '',
			rootPath: '.',
			sendSms: false,
			guid: null,
			isAllCommentsFilterChecked: false,
			includeText: data.includeText,
			...data,
		};

		const bodyAddComment = this.transformBodyForAddComment(body);

		return data.forwardedCommentID || data.forwardedCommentsIds
			? this.forwardComment(bodyAddComment)
			: this.addComment(bodyAddComment);
	}

	addComment(data: Partial<IAddComment>): Observable<Partial<IComment>> {
		const url = this.common.getApiUrl('comments/add');
		const commentRequest = typeof data.commentRequest === 'boolean' ? data.commentRequest : true;
		delete data.commentRequest;
		delete data.includeText;
		const body: Partial<IAddComment> = {
			addToQuickReply: false,
			chatMode: false,
			commentText: null,
			copyRecipientGroupsIds: [],
			copyRecipientsIds: [],
			fileIds: [],
			forceEmail: data.forceEmail ?? false,
			forwardedCommentId: null,
			inReplyToCommentId: null,
			location: null,
			maxCommentId: null,
			needSubscribe: true,
			needSubscriberOthers: true,
			needsAnswer: false,
			recipientGroupsIds: [],
			recipientIds: [],
			sendSms: false,
			guid: null,
			silentComment: false,
			...data,
		};

		if (body.forwardedCommentId && typeof body.commentText !== 'string') {
			/** @see #940176 */
			body.commentText = '';
		}

		return this.http.post<IApiResponse<any>>(url, body).pipe(
			map(result => ({
				commentId: result.data,
			})),
			switchMap(({ commentId }) => {
				if (commentRequest) {
					return this.getCommentsOffset({
						commentId: commentId,
						limitAfter: 0,
						limitBefore: 0,
						taskInfo: false,
					}).pipe(map(offsetResult => offsetResult.comments[0]));
				}
				return of({ commentId });
			}),
			tap((comment: any) => {
				if (comment) {
					adjustComment(comment);
					comment.taskId = data.taskId as number;
				}
			})
		);
	}

	forwardComment(data: Partial<IAddComment>): Observable<Partial<IComment>> {
		const url = this.common.getApiUrl('comments/forward');
		const commentRequest = typeof data.commentRequest === 'boolean' ? data.commentRequest : true;
		delete data.commentRequest;
		const body: Partial<IAddComment> = {
			addToQuickReply: false,
			chatMode: false,
			copyRecipientGroupsIds: [],
			copyRecipientsIds: [],
			fileIds: [],
			forceEmail: data.forceEmail ?? false,
			includeText: data.includeText,
			location: null,
			maxCommentId: null,
			needSubscribe: true,
			needSubscriberOthers: true,
			needsAnswer: false,
			recipientGroupsIds: [],
			recipientIds: [],
			sendSms: false,
			guid: null,
			silentComment: false,
			taskId: data.taskId,
			commentText: data.commentText,
			...data,
			forwardedCommentsIds: data.forwardedCommentsIds || [data.forwardedCommentId],
		};

		if (typeof body.commentText !== 'string') {
			/** @see #940176 */
			body.commentText = '';
		}

		return this.http.post<IApiResponse<any>>(url, body).pipe(
			map(result => ({
				commentId: result.data,
			})),
			switchMap(({ commentId }) => {
				if (commentRequest) {
					return this.getCommentsOffset({
						commentId: Array.isArray(commentId) ? commentId[0] : commentId,
						limitAfter: 0,
						limitBefore: 0,
						taskInfo: false,
					}).pipe(map(offsetResult => offsetResult.comments[0]));
				}
				return of({ commentId });
			}),
			tap((comment: any) => {
				if (comment) {
					adjustComment(comment);
					comment.taskId = data.taskId as number;
				}
			})
		);
	}

	chatUnsubscribe(data: Partial<IChatUnsubscribe>): Observable<any> {
		const url = this.common.getEndpointUrl(this.config.chat.chatUnsubscribe);

		return this.http.post(url, data);
	}

	chatClose(data: Partial<IChatClose>): Observable<any> {
		const url = this.common.getEndpointUrl(this.config.chat.chatClose);

		return this.http.post(url, data);
	}

	changeTaskText(data: Partial<IChangeTaskText>): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${data.taskId}/changeText`);

		return this.http.post(url, { value: data.taskText });
	}

	createChat(data: Partial<IChatCreate>): Observable<IChatCreateResult> {
		const url = this.common.getApiUrl('chats');

		return this.http.post<IApiResponse<IChatCreateResult>>(url, data).pipe(map(res => res.data));
	}

	setNotificationMode({ mode, taskId }: IChatSetNotificationModeCriteria): Observable<any> {
		const url = this.common.getApiUrl(`chats/${taskId}/setNotificationMode/${mode}`, ApiVersion.v12);
		return this.http.post<any>(url, undefined);
	}
}
