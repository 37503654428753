import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { guid } from '@valhalla/utils';
import { ExtParamBase } from './ext-param-base';
import { IExtParamFileValue } from './ext-param-file';

export type IExtParamMultiFileValue = Array<IExtParamFileValue>;

export class ExtParamMultifile extends ExtParamBase<IExtParamMultiFileValue> {
	getValueForCopy() {
		return '';
	}

	setCopiedValue(value) {
		return;
	}

	get dirty() {
		if (this.isNewTask) {
			return !!this.value?.length;
		}
		return !this.equalsValue(this.sourceValue, this.value);
	}

	equalsValue(a: any, b: any) {
		if (this.isNewTask) {
			if ((!a && !b) || (!a && !b?.length) || (!a?.length && !b)) {
				return true;
			}

			if (a?.length !== b?.length) {
				return false;
			}

			return a?.every(first => b?.some(second => first?.file === second?.file));
		}

		return true;
	}

	clearValue() {
		this.userValue$.next([]);
	}

	convertForSaveInNewTaskAsyncMiddleware = () =>
		this.value$.pipe(
			switchMap(attachments => {
				if (!attachments?.length) {
					return of({
						exisistingFiles: [],
						preuploadedFiles: [],
					});
				}
				const data = new FormData();
				const existingFiles = [];
				let hasPreuploadedFiles = false;
				attachments.forEach(a => {
					if (a?.file) {
						hasPreuploadedFiles = true;
						data.append(guid(), a.file, a.file.name);
					}

					if (a?.fileId || a?.uploadId) {
						existingFiles.push(a.fileId || a.uploadId);
					}
				});
				if (hasPreuploadedFiles) {
					return this.server.files.ToPreUploadedFiles(data).pipe(
						map(r => {
							return {
								exisistingFiles: existingFiles,
								preuploadedFiles: r.data,
							};
						})
					);
				} else {
					return of({
						exisistingFiles: existingFiles,
						preuploadedFiles: [],
					});
				}
			}),
			map(value =>
				value.preuploadedFiles.length > 0 || value.exisistingFiles.length > 0
					? JSON.stringify({
							fileIds: value.exisistingFiles,
							preuploadIds: value.preuploadedFiles.map(f => f.preUploadFileId) || [],
					  })
					: ''
			)
		);
}
