import { InjectionToken } from '@angular/core';
import { merge } from '@valhalla/utils';

export interface ICoreConfig {
	isProduction?: boolean;
	useRouter?: boolean;
	redirect403httpInterceptor?: boolean;
}

export function mergeDefaultConfig(conf: ICoreConfig) {
	const defaultConfig: ICoreConfig = {
		isProduction: false,
		useRouter: true,
		redirect403httpInterceptor: false,
	};
	const merged = merge(defaultConfig, conf);
	return merged;
}

export function isProductionTokenFactory(conf: ICoreConfig) {
	return Boolean(conf && conf.isProduction);
}

export const extConfigToken = new InjectionToken<ICoreConfig>('external ICoreConfig from withConfig');
