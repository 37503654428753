import { Injectable } from '@angular/core';
import { OutputData } from '@editorjs/editorjs';
import { UrlProvider } from '@spa/core';
import { DataHttpService } from '@spa/data/http';
import { blobToDataUrl } from '@valhalla/utils';
import { forkJoin, lastValueFrom, map, of, Subject, switchMap, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpacesService {
	constructor(readonly server: DataHttpService, readonly url: UrlProvider) {}

	readonly update$ = new Subject();

	update() {
		this.update$.next(0 as any);
	}

	uploadFile(file) {
		const data = new FormData();
		data.append('', file, file.name);

		return lastValueFrom(
			this.server.files
				.ToPreUploadedFiles(data)
				.pipe
				// switchMap(res => {
				// 	return blobToDataUrl(file).pipe(map(base64 => ({ res, base64 })));
				// })
				()
		).then(res => {
			const preUploadFileId = res?.data[0]?.preUploadFileId;

			return {
				success: 1,
				file: {
					url: `/api/files/download-preuploaded/${preUploadFileId}`,
					size: file?.size,
					name: file?.name,
					title: file?.name,
					//preUploadFileId: preUploadFileId,
				},
			};
		});
	}

	uploadImageByFile(file, preuploadedImage = null) {
		const data = new FormData();
		let fileName = file?.name;

		if (preuploadedImage) {
			if (preuploadedImage.get(file?.name)) {
				const count = preuploadedImage.get(file?.name);
				if (count > 0) {
					fileName = `${fileName} (${count})`;
				}

				preuploadedImage.set(file?.name, count + 1);
			} else {
				preuploadedImage.set(file?.name, 1);
			}
		}

		data.append('', file, fileName);

		return lastValueFrom(
			this.server.files
				.ToPreUploadedFiles(data)
				.pipe
				// switchMap(res => {
				// 	return blobToDataUrl(file).pipe(map(base64 => ({ res, base64 })));
				// })
				()
		).then(res => {
			const preUploadFileId = res?.data[0]?.preUploadFileId;

			return {
				success: 1,
				file: {
					url: `/api/files/download-preuploaded/${preUploadFileId}`,
					//preUploadFileId: preUploadFileId,
				},
			};
		});
	}

	// возвращает контент для редактора, с изменненным fileId для изображений
	updateFilesUrl(editorContent: OutputData | any, taskId) {
		const blocksWithFiles = editorContent.blocks.filter(b => b.type === 'image' || b.type === 'attaches');
		const preuploadedFiles = blocksWithFiles.filter(pi => pi?.data?.file?.preUploadFileId);

		if (!preuploadedFiles?.length) {
			return of(editorContent);
		}

		return forkJoin(
			preuploadedFiles.map(pi => {
				return this.server.files
					.preUploadedFilesToTask({
						taskId: taskId,
						preUploadFileId: pi?.data?.file?.preUploadFileId,
					})
					.pipe(
						tap(res => {
							const file = res?.data[0];

							const fileId = file?.fileId;
							const fileVersionId = file?.fileVersion;

							pi.data.file = {
								...pi.data.file,
								url: this.url.fileUrl(fileId, fileVersionId),
							};

							delete pi.data.file['preUploadFileId'];
						})
					);
			})
		).pipe(
			take(1),
			map(() => editorContent)
		);
	}

	getPreaploadedIds(editorContent: OutputData | any) {
		const blocksWithFiles = editorContent.blocks.filter(b => b.type === 'image' || b.type === 'attaches');
		const preuploadedFiles = blocksWithFiles.filter(pi => pi?.data?.file?.preUploadFileId);

		return preuploadedFiles?.map(item => item?.data?.file?.preUploadFileId);
	}

	isPreuploadedFiles(editorContent: OutputData | any) {
		const files = this.getPreaploadedIds(editorContent);
		return files?.length;
	}
}
