import { Injectable } from '@angular/core';
import { DataHttpService } from '@spa/data/http';
import { combineLatest, map, of, shareReplay, Subject, switchMap, take } from 'rxjs';
import {
	createSpecialSectionsTree,
	createTaskTreeWithoutSpecialSection,
	getItemsForBuildTree,
} from './space-panel.helper';
import { ISpaceItem } from './spaces-panel-item/spaces-panel-item.component';

@Injectable({ providedIn: 'root' })
export class SpacePanelService {
	constructor(readonly server: DataHttpService) {}

	readonly appConfig$ = this.server.config.getAppConfiguration();
	readonly spaceSettings$ = this.appConfig$.pipe(map(config => config.spaceSettings));
	readonly rootCatId$ = this.spaceSettings$.pipe(map(ss => ss?.rootCatId));
	readonly epParentId$ = this.spaceSettings$.pipe(
		map(spaceSettings => {
			return spaceSettings?.extParams?.parent;
		})
	);
	readonly epSpecialSectionId$ = this.spaceSettings$.pipe(
		map(spaceSettings => {
			return spaceSettings?.extParams?.specialSection;
		})
	);

	readonly updateTree$ = new Subject();

	readonly subcategoriesItemsTree$ = this.server.category
		.subcategoriesTree()
		.pipe(shareReplay({ bufferSize: 1, refCount: true }));

	readonly subcategoriesTree$ = combineLatest([this.subcategoriesItemsTree$, this.rootCatId$]).pipe(
		map(([subcategoriesTree, rootCatId]) => {
			// Категории с флагом isSpace - пространства, но они могут быть вложены
			// в другие категории которые не являются пространством, поэтому
			// получаем список пространств со всеми вложенными категориями
			const items = getItemsForBuildTree(subcategoriesTree?.data, rootCatId);
			return items;
		}),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	readonly subcategoriesTreeEnableEdit$ = combineLatest([this.subcategoriesItemsTree$, this.rootCatId$]).pipe(
		map(([subcategoriesTree, rootCatId]) => {
			// Категории с флагом isSpace - пространства, но они могут быть вложены
			// в другие категории которые не являются пространством, поэтому
			// получаем список пространств со всеми вложенными категориями
			const items = getItemsForBuildTree(subcategoriesTree?.data, rootCatId, true);
			return items;
		}),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	getFirstSpaceId(items: ISpaceItem[]) {
		const firstSpace = items?.find(i => i?.taskId);

		if (firstSpace) {
			return firstSpace?.taskId;
		}

		const firstSpecialSection = items?.find(i => i?.isSpecialSection && i?.children[0]?.taskId);

		if (firstSpecialSection) {
			return firstSpecialSection?.children[0]?.taskId;
		}
	}

	getSpacesItems(subcatId) {
		return combineLatest([this.epParentId$, this.epSpecialSectionId$]).pipe(
			switchMap(([epParentId, epSpecialSectionId]) => {
				return this.server.ds
					.getDataSource('subcat', subcatId, {
						startRow: 0,
						endRow: 1000,
						rowGroupCols: [],
						valueCols: [],
						pivotCols: [],
						groupKeys: [],
						filterModel: {},
						sortModel: [],
						select: [
							{
								extParamType: 'number',
								field: `ExtParam${epParentId}`,
								type: 'extparam',
							},
							{
								extParamType: 'number',
								field: `ExtParam${epSpecialSectionId}`,
								type: 'extparam',
							},
						],
						tasksState: 'isOpened',
					})
					.pipe(
						map(res => res?.tasksDataSourceResponse),
						shareReplay({ refCount: true, bufferSize: 1 })
					);
			}),
			shareReplay({ refCount: true, bufferSize: 1 })
		);
	}

	getSpecialSections(subcatId) {
		return combineLatest([this.epSpecialSectionId$]).pipe(
			switchMap(([epSpecialSectionId]) => {
				return this.server.ep
					.getEpSelectValues({
						subcatId: subcatId,
						extParamId: epSpecialSectionId,
						isFromSubcatTasks: false,
						skip: 0,
						sourceBlockId: null,
						text: null,
						take: 50,
					})
					.pipe(map(res => res?.data));
			}),
			shareReplay({ refCount: true, bufferSize: 1 })
		);
	}

	getSpacesTree(subcatId) {
		return this.getSpacesItems(subcatId).pipe(
			switchMap(spacesItems => {
				return combineLatest([this.spaceSettings$]).pipe(
					take(1),
					map(([spaceSettings]) => {
						return { spaceSettings, spacesItems };
					})
				);
			}),
			switchMap(({ spaceSettings, spacesItems }) => {
				const parentExtParamId = spaceSettings?.extParams?.parent;
				const specialSectionId = spaceSettings?.extParams?.specialSection;

				return of(spacesItems).pipe(
					switchMap(taskItems => {
						const tree = createTaskTreeWithoutSpecialSection(taskItems, parentExtParamId, specialSectionId, {
							id: subcatId,
						});

						return this.getSpecialSections(subcatId).pipe(
							take(1),
							map(specialSections => {
								const specialSectionsTree = createSpecialSectionsTree(
									specialSections,
									taskItems,
									parentExtParamId,
									specialSectionId,
									subcatId
								);

								return [...tree, ...specialSectionsTree];
							})
						);
					})
				);
			}),
			shareReplay({ refCount: true, bufferSize: 1 })
		);
	}

	update() {
		this.updateTree$.next(0 as any);
	}
}
