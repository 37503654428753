import { Observable } from 'rxjs';
import { ICalendarExtParams } from '../../entities/calendar-ext-params';
import { IApplicationSettingsDto, IAppSettingsAnonymousDto } from './dto';
import { ITaskInfo } from '@spa/data/entities';

export abstract class ConfigurationDataHttpService {
	abstract getAppConfiguration(): Observable<Partial<IAppConfiguration>>;

	abstract getAppSettingsAnonymous(): Observable<IAppSettingsAnonymousDto>;

	abstract getLocalizations(culture: string): Observable<Record<string, string>>;

	abstract getAppConfigurationByName(): Observable<any>;

	abstract getApplicationSettings(): Observable<IApplicationSettingsDto>;

	abstract isPrivateChatSync(subcatIdOrTask: number | Partial<ITaskInfo>): boolean;

	abstract isGroupChatSync(subcatIdOrTask: number | Partial<ITaskInfo>): boolean;

	abstract isPrivateOrGroupChatSync(subcatIdOrTask: number | Partial<ITaskInfo>): boolean;

	appConfig: Partial<IAppConfiguration>;
	appConfig$: Observable<Partial<IAppConfiguration>>;
	appSettingsAnonymousConfig$: Observable<Partial<IAppSettingsAnonymousDto>>;
	appSettingsAnonymousConfig: IAppSettingsAnonymousDto;
	isVKSEnable$: Observable<boolean>;
}

export interface IAppConfiguration {
	[key: string]: any;

	calendarSubcatId: number;
	errorSubcatId: number;
	feedBackSubcatId: number;
	chatSubcatId: number;
	privateSubcatId: number;
	groupChatSubCatId: number;
	calendarExtParams: ICalendarExtParams;
	officeOnlineEditor?: any;
	spaceSettings?: any;
	signatureGridConfig?: {
		extParams: { extParamId: number; extParamName: string }[];
		settings: { columns: SignatureGridColumn[] } | null;
	};
	dynSignatureRequestDefaultType: string;
}

export function useR7OfficeEditor(config: Partial<IAppConfiguration>) {
	return ['r7', 'R7'].some(v => config?.officeOnlineEditor?.editor === v || config?.officeOnlineEditor?.Editor === v);
}

export function hasOnlineOfficeEditor(config: Partial<IAppConfiguration>) {
	return !!config?.officeOnlineEditor?.editor || config?.officeOnlineEditor?.Editor;
}

export interface SignatureGridColumn {
	key: SignatureGridColumnKey | string;
	type: 'General' | 'Extparam';
	isHidden: 'true' | 'false';
}

export enum SignatureGridColumnKey {
	signatureOrderedTime = 'signatureOrderedTime',
	description = 'description',
	signatureAndText = 'signatureAndText',
	signatureAcceptants = 'signatureAcceptants',
	signatureInitializeDate = 'signatureInitializeDate',
	subcatName = 'subcatName',
	ownerName = 'ownerName',
	responsiblePerformer = 'responsiblePerformer',
	requestorName = 'requestorName',
	timeToSign = 'timeToSign',
	actions = 'actions',
	commentsFromMeCount = 'commentsFromMeCount',
	commentsToMeCount = 'commentsToMeCount',
	isAnyUnAnswered = 'isAnyUnAnswered',
	taskId = 'taskId',
	Extparam = 'Extparam',
}

export enum IFieldResolutions {
	signatures_selection = 'signatures_selection',
	signaturesSign = 'signaturesSign',
	request_date = 'request_date',
	signatures_owner = 'signatures_owner',
	signatures_performer = 'signatures_performer',
	signatures_requesting = 'signatures_requesting',
	signatures_signatories = 'signatures_signatories',
	acceptors = 'acceptors',
	expiration = 'expiration',
	time_to_sign = 'time_to_sign',
	resolutions_actions = 'resolutions_actions',
	description = 'description',
	signatures_category = 'signatures_category',
	tags = 'tags',
	questions_from_me = 'questions_from_me',
	questions_to_me = 'questions_to_me',
	my_questions = 'my_questions',
	taskId = 'taskId',
	Extparam = 'Extparam',
}

export const mappedSignatureGridColumnKeys: Record<SignatureGridColumnKey, IFieldResolutions> = {
	[SignatureGridColumnKey.signatureOrderedTime]: IFieldResolutions.expiration,
	[SignatureGridColumnKey.description]: IFieldResolutions.description,
	[SignatureGridColumnKey.signatureAndText]: IFieldResolutions.signaturesSign,
	[SignatureGridColumnKey.signatureAcceptants]: IFieldResolutions.acceptors,
	[SignatureGridColumnKey.signatureInitializeDate]: IFieldResolutions.request_date,
	[SignatureGridColumnKey.subcatName]: IFieldResolutions.signatures_category,
	[SignatureGridColumnKey.ownerName]: IFieldResolutions.signatures_owner,
	[SignatureGridColumnKey.responsiblePerformer]: IFieldResolutions.signatures_performer,
	[SignatureGridColumnKey.requestorName]: IFieldResolutions.signatures_requesting,
	[SignatureGridColumnKey.timeToSign]: IFieldResolutions.time_to_sign,
	[SignatureGridColumnKey.actions]: IFieldResolutions.resolutions_actions,
	[SignatureGridColumnKey.commentsFromMeCount]: IFieldResolutions.questions_from_me,
	[SignatureGridColumnKey.commentsToMeCount]: IFieldResolutions.questions_to_me,
	[SignatureGridColumnKey.isAnyUnAnswered]: IFieldResolutions.my_questions,
	[SignatureGridColumnKey.taskId]: IFieldResolutions.taskId,
	[SignatureGridColumnKey.Extparam]: IFieldResolutions.Extparam,
};

export function getSignatureGridColumnTitle(
	resx: Record<string, string>,
	signatureGridColumnKey: SignatureGridColumnKey | string
): string {
	switch (signatureGridColumnKey) {
		case SignatureGridColumnKey.signatureOrderedTime:
			return resx?.resolutionGridExpiration;
		case SignatureGridColumnKey.description:
			return resx?.resolutionGridDescr;
		case SignatureGridColumnKey.signatureAndText:
			return resx?.resolutionGridSignature;
		case SignatureGridColumnKey.signatureAcceptants:
			return resx?.resolutionGridAcceptors;
		case SignatureGridColumnKey.signatureInitializeDate:
			return resx?.resolutionGridReqDate;
		case SignatureGridColumnKey.subcatName:
			return resx?.resolutionGridCategory;
		case SignatureGridColumnKey.ownerName:
			return resx?.resolutionGridOwner;
		case SignatureGridColumnKey.responsiblePerformer:
			return resx?.resolutionGridPerformer;
		case SignatureGridColumnKey.requestorName:
			return resx?.resolutionGridRequestor;
		case SignatureGridColumnKey.timeToSign:
			return resx?.resolutionGridTimeToSign;
		case SignatureGridColumnKey.actions:
			return resx?.resolutionGridActions;
		case SignatureGridColumnKey.commentsFromMeCount:
			return resx?.resolutionGridQuestionsFromMe;
		case SignatureGridColumnKey.commentsToMeCount:
			return resx?.resolutionGridQuestionsToMe;
		case SignatureGridColumnKey.isAnyUnAnswered:
			return resx?.resolutionGridMyQuestions;
		case SignatureGridColumnKey.taskId:
			return resx?.taskNumber;
		case SignatureGridColumnKey.Extparam:
			return '';
	}
}
