import { Injectable, inject } from '@angular/core';
import { ICron, ICronRunContext } from '../cron';
import { DataHttpService } from '@spa/data/http';
import { take } from 'rxjs';
import { TabActiveStateService } from '@spa/common/services/tab-active-state.service';

@Injectable({ providedIn: 'root' })
export class MailboxesCronService implements ICron {
	readonly server = inject(DataHttpService);
	readonly tabActive = inject(TabActiveStateService);

	run(context: ICronRunContext) {
		if (this.tabActive.userActive && this.tabActive.tabVisible) {
			this.server.mail.getMailboxList().pipe(take(1)).subscribe({
				next: context.complete,
				error: context.fail,
			});
		} else {
			context.complete(context.lastResult);
		}
	}
}
