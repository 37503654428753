import { Directive, ElementRef, Input, NgModule, OnChanges, SimpleChanges } from '@angular/core';

/** directive to block the hover effect on mobile devices when clicked outside the context menu */
@Directive({ selector: '[vhBlockHover]' })
export class BlockHoverDirective implements OnChanges {
	constructor(readonly elRef: ElementRef<HTMLElement>) {}

	@Input('vhBlockHover')
	vhHideHover: HTMLElement;

	@Input() shouldBlockHover: boolean;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.shouldBlockHover?.currentValue) {
			(document.querySelector('vh-facade') as HTMLElement).style.pointerEvents = 'none';
		} else {
			setTimeout(() => ((document.querySelector('vh-facade') as HTMLElement).style.pointerEvents = 'initial'), 200);
		}
	}
}

@NgModule({ declarations: [BlockHoverDirective], exports: [BlockHoverDirective] })
export class BlockHoverModule {}
