import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UrlProvider } from '@valhalla/core';

@Injectable({ providedIn: 'root' })
export class SyndicateFrameService {
	constructor(protected readonly router: Router, protected readonly url: UrlProvider) {}

	findSyndicateFrames(win: Window = window) {
		return this.findFrames(win, fr => {
			try {
				return fr?.location?.href?.toLowerCase().includes('syndicate.aspx');
			} catch (error) {}
		});
	}

	findFrames(win: Window, predicate: (frame: Window) => boolean): Window {
		for (const fr of Array.from(win.frames || [])) return predicate(fr) ? fr : this.findFrames(fr, predicate);
	}

	openTask(taskId: number, preview = false): void {
		if (!taskId) {
			return;
		}
		const syndicate: any = this.findSyndicateFrames();
		if (!syndicate) {
			// open syndicate default feeds with task
			this.router.navigate(['/link'], {
				queryParams: {
					url: `Syndicate.aspx?today=true&force=feed&forcePreview=true&forceTaskOpen=1&taskid=${taskId}`,
				},
			});
			return;
		}
		// try to open in current syndicate
		const frameSet: HTMLFrameSetElement = syndicate.document.getElementById('FS');
		const setSize = size => {
			frameSet.rows = size;
		};
		const isDefaultSize = frameSet?.rows === '100%, 0',
			isAlreadyPreviewSize = frameSet?.rows === '50%, 50%';
		const hostFrame: HTMLFrameElement = frameSet?.children['SecFrame'] || frameSet?.children['preview'];
		if (!hostFrame) {
			syndicate.OpenTask(null, taskId);
		} else {
			hostFrame.src = this.url.getUrl(`maintaskform.aspx?taskid=${taskId}`);
			if (isDefaultSize) {
				setSize('50%, 50%');
			}
		}
	}
}
