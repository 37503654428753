import { IFilterData } from '@spa/data/entities';
import { Observable } from 'rxjs';

export abstract class TaskHierarhyHttpService {
	abstract getConfig(hierarchyDto: IGetTaskHierarchyDto): Observable<ITaskHierarchySettings>;

	abstract getData(hierarchyDto: IGetTaskHierarchyDto): Observable<ITaskHierarchyNode[]>;

	abstract getFilters(hierarchyId: number): Observable<IFilterData>;
}

export interface IGetTaskHierarchyDto {
	id?: number;
	taskId?: number;
	rootTaskId?: number;
	filter?: string;
	dataType?: StoredProcedureEnum;
	fullPathToNode?: string;
}

export interface IFieldSetting {
	isVisible: boolean;
	name: string;
	width: string;
	alignment: string;
	spColumnName: string;
	spColumnNameForLink: string;
	spColumnNameForStyle: string;
	spColumnNameForIcon: string;
	columnType: string;
	outputFormat: string;
	maxLength?: number;
	isLocked: boolean;
	enableDisplayChange: boolean;
	tooltip: string;
	fieldType: string;
}

export enum ServerColumnTypes {
	text = 'Text',
	taskText = 'TaskText',
	html = 'Html',
	userId = 'UserId',
	date = 'Date',
	dateTime = 'DateTime',
	percent = 'Percent',
	number = 'Number',
	money = 'Money',
	boolean = 'Boolean',
}

export enum RequiredFields {
	id = 'id',
	parentId = 'parentId',
	hasChildren = 'hasChildren',
	isEncrypted = 'isEncrypted',
}

export enum ServerFieldType {
	staticField = 'Static',
	dynamicField = 'Dynamic',
}

export interface IActionButton {
	description: string;
	iconUrl: string;
	jsExpression: string;
	idField: string;
	isVisibleField: string;
	isActiveField: string;
	hasServerExecution: boolean;
	name: string;
	id: number;
}

export interface ITaskHierarchySettings {
	id: number;
	name: string;
	dataType: string;
	enableDragNDrop: boolean;
	loadFullTree: boolean;
	filtersExist: boolean;
	showSubtaskMenu: boolean;
	fieldsSettings: IFieldSetting[];
	actionButtons: IActionButton[];
	enableDisplayChange: boolean;
	enableInlineEdit: boolean;
}

export interface ITaskHierarchyNode {
	group: any;
	hasChildren: boolean;
	haschildren: boolean;
	values: any;
	parentId?: number;
}

export interface IKeyValuePair {
	[key: string]: string;
}

export enum StoredProcedureEnum {
	getChilds = 'GetChilds',
	getItem = 'GetItem',
}

// tslint:disable-next-line:no-empty-interface
export interface ITaskHierarchyItemDto {}
