import { Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UserMiniProfileComponent } from '@spa/common/components/user-mini-profile/user-mini-profile.component';
import { UserSettingsFacadeProvider } from '@spa/facade/features/user-settings';
import { PlatformDetectorProvider } from '@valhalla/core';
import { DataHttpService } from '@valhalla/data/http';
import { InputSubject } from '@valhalla/utils';
import { BehaviorSubject, EMPTY, fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { UserPreviewPanelService } from './user-preview-panel.service';
import { IUserProfile } from '@spa/data/entities';

@Directive({ selector: '[vhUserPreview]', exportAs: 'vhUserPreview' })
export class UserPreviewPanelDirective implements OnInit, OnDestroy {
	constructor(
		readonly overlayPositionBuilder: OverlayPositionBuilder,
		readonly elementRef: ElementRef<HTMLElement>,
		protected server: DataHttpService,
		protected userPreviewService: UserPreviewPanelService,
		protected readonly overlay: Overlay,
		readonly zone: NgZone,
		readonly platform: PlatformDetectorProvider,
		readonly userSettingsStore: UserSettingsFacadeProvider
	) {}

	@Input('previewUserId') userId: number;
	@Input('previewGroupId') groupId: number;

	@Input('previewSelectorHost') selectorHost: string;

	@Input('previewUserEnable')
	enabled = true;

	@Input()
	openProfileModal = false;

	@Input('previewUserInfo')
	@InputSubject()
	userInfo: Partial<IUserProfile>;
	userInfo$: BehaviorSubject<Partial<IUserProfile>>;

	overlayRef: OverlayRef;
	protected closeTimeoutRef: any;
	protected openTimeoutRef: any;
	protected offsetX: number;
	protected hovered: boolean;
	protected tooltipRef: ComponentRef<UserMiniProfileComponent>;
	protected destroy$ = new Subject();
	protected canActivate = true;
	readonly opened$ = new BehaviorSubject(false);
	protected enabledByMouseOver = false;

	canChat: boolean;
	prepareOverlayFn: (...args: any[]) => void;

	get isMobile() {
		return this.platform.isMobile();
	}

	// @HostListener('mouseenter')
	onMouseEnter() {
		if (!this.canActivate || !this.enabled || this.isMobile || !this.enabledByMouseOver) {
			return;
		}

		this.hovered = true;
		if (!this.openTimeoutRef) {
			this.openTimeoutRef = setTimeout(() => {
				this.show();
			}, 800);
		}
	}

	// @HostListener('mouseleave')
	onMouseLeave() {
		if (!this.canActivate || !this.enabled || this.isMobile || !this.enabledByMouseOver) {
			return;
		}
		this.hovered = false;
		if (!this.closeTimeoutRef) {
			this.closeTimeoutRef = setTimeout(() => {
				this.hide();
			}, 300);
		}
		if (this.openTimeoutRef) {
			clearTimeout(this.openTimeoutRef);
			this.openTimeoutRef = null;
		}
	}

	show(force = false) {
		if (!(this.userId || this.groupId) || this.tooltipRef) {
			return;
		}
		const canOpen = this.hovered || force;
		if (!canOpen) {
			return;
		}
		// if (this.userPreviewService.has(this.userId)) {
		// 	return;
		// }
		this.userPreviewService.addPanel(this.userId);
		this.userInfo$.pipe(take(1), takeUntil(this.destroy$)).subscribe(userInfo => {
			this.prepareOverlay();
			const tooltipPortal = new ComponentPortal(UserMiniProfileComponent);
			this.tooltipRef = this.overlayRef.attach(tooltipPortal);
			this.tooltipRef.instance.userInfo = userInfo;
			this.tooltipRef.instance.isChatEnable = this.canChat;
			this.tooltipRef.instance.userId = this.userId;
			this.tooltipRef.instance.groupId = this.groupId;
			this.tooltipRef.instance.openProfileModal = this.openProfileModal;

			this.tooltipRef.instance.close.pipe(takeUntil(this.destroy$)).subscribe(() => {
				this.hide(true);
			});
			this.opened$.next(true);
			if (this.closeTimeoutRef) {
				clearTimeout(this.closeTimeoutRef);
				this.closeTimeoutRef = null;
			}
		});
	}

	hide(force = false) {
		clearTimeout(this.closeTimeoutRef);
		clearTimeout(this.openTimeoutRef);
		const hovered = this.tooltipRef?.instance?.hovered?.value;
		if (force || !hovered) {
			this.overlayRef?.detach();
			this.tooltipRef = null;
			this.opened$.next(false);
			this.userPreviewService.removeFromPanel(this.userId);
		}
	}

	ngOnInit() {
		this.server.category.canChat$.subscribe(canChat => (this.canChat = canChat));
		// see #921388
		fromEvent(document, 'keydown')
			.pipe(
				tap(() => {
					this.canActivate = false;
				}),
				debounceTime(3000),
				tap(() => {
					this.canActivate = true;
				}),
				finalize(() => {
					this.canActivate = true;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();

		fromEvent<KeyboardEvent>(document, 'keydown')
			.pipe(
				filter(e => e.code === 'Escape'),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.hide(true);
			});

		this.opened$
			.pipe(
				distinctUntilChanged(),
				switchMap(opened => {
					if (!opened) {
						return EMPTY;
					}
					return fromEvent(document, 'click').pipe(filter(() => Boolean(this.tooltipRef)));
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(e => {
				const target = e.target as HTMLElement;
				const isInsidePanel = this.overlayRef?.hostElement?.contains(target);
				const isOnElRef = this.elementRef.nativeElement?.contains(target);
				if (!isInsidePanel && !isOnElRef) {
					this.hide(true);
				}
			});
		this.zone.runOutsideAngular(() => {
			fromEvent(this.elementRef.nativeElement, 'mouseenter', this.onMouseEnter.bind(this))
				.pipe(takeUntil(this.destroy$))
				.subscribe();
			fromEvent(this.elementRef.nativeElement, 'mouseleave', this.onMouseLeave.bind(this))
				.pipe(takeUntil(this.destroy$))
				.subscribe();
		});
	}

	prepareOverlay() {
		if (this.prepareOverlayFn) {
			return this.prepareOverlayFn(this);
		}
		this.overlayRef?.dispose();

		const position = this.elementRef.nativeElement.getBoundingClientRect();
		const clientWidth = window.innerWidth;
		const clientHeight = window.innerHeight;
		const hostEl = this.selectorHost
			? this.elementRef.nativeElement.querySelector(this.selectorHost)
			: this.elementRef.nativeElement;
		this.offsetX = position.x > clientWidth / 2 ? -50 : 50;
		const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(hostEl).withPositions([
			{
				originX: position.x < clientWidth / 2 ? 'start' : 'end',
				originY: position.y < clientHeight / 2 ? 'top' : 'bottom',
				overlayX: position.x < clientWidth / 2 ? 'start' : 'end',
				overlayY: position.y < clientHeight / 2 ? 'top' : 'bottom',
				offsetX: this.offsetX,
				offsetY: position.y > clientHeight / 2 ? -25 : 25,
			},
		]);

		const config = new OverlayConfig({
			positionStrategy: positionStrategy,
		});

		this.overlayRef = this.overlay.create(config);
	}

	ngOnDestroy() {
		this.hide(true);
		this.destroy$.next(0 as any);
		this.destroy$.complete();
	}
}
