// tslint:disable:no-empty-interface

import { IFile } from '@spa/components/widgets/task-search/task-search.interfaces';
import { Observable } from 'rxjs';

export abstract class BatchProcessingDataHttpService {
	abstract processTaskStep(req: IProcessTaskStepCriteria): Observable<IProcessTaskResult>;
	abstract processTaskSmartButton(req: IProcessTaskSmartButtonCriteria): Observable<IProcessTaskResult>;
	abstract getBatchStepsByTaskIds(req: ITaskBatchStepsRequest): Observable<ITaskBatchStepsItem[]>;
	abstract getBatchSmartButtonsByTaskIds(req: ITaskBatchStepsRequest): Observable<ITaskBatchSmartButtonItem[]>;
	abstract processTaskPerformers(req: IProcessTaskPerformersCriteria): Observable<IProcessTaskResult>;
	abstract processTaskOwner(req: IProcessTaskOwnerCriteria): Observable<IProcessTaskResult>;
	abstract processTaskDuedate(req: IProcessTaskDuedateCriteria): Observable<IProcessTaskResult>;
	abstract processTaskDelete(req: IProcessTaskDeleteCriteria): Observable<IProcessTaskResult>;
	abstract processTaskComment(req: IProcessTaskCommentCriteria): Observable<IProcessTaskResult>;
	abstract processTaskSubscribers(req: IProcessTaskSubscribersCriteria): Observable<IProcessTaskResult>;
	abstract processTaskMove(req: IProcessTaskMoveCriteria): Observable<IProcessTaskResult>;
	abstract processTaskCopy(req: IProcessTaskCopyCriteria): Observable<IProcessTaskResult>;
	abstract processTaskExtParams(req: IProcessTaskExtParams): Observable<IProcessTaskResult>;
	abstract processTaskFiles(req: IProcessTaskFilesCriteria): Observable<Blob>;
}

export interface IProcessTaskStepCriteria {
	tasksIds: number[];
	initiatorUserId?: number;
	stepId: number;
	comment?: string;
	userConfirmedTSTAction?: boolean;
}

export interface IProcessTaskResult {
	succeed: number;
	failed: number;
	errors: IProcessTaskResultError[];
}

export interface IProcessTaskResultError {
	taskId: number;
	error: string;
}

export interface ITaskBatchStepsRequest {
	tasksIds: number[];
	initiatorUserId?: number;
}

export interface ITaskBatchStepsItem {
	stepId: number;
	stateId: number;
	stateNextId: number;
	title: string;
	forceComment: boolean;
	tstChangeConfirmText: string;
	confirmText: string;
	finishWork: boolean;
	closeTask: boolean;
	workNotStarted: boolean;
}

export interface ITaskBatchSmartButtonItem {
	id: string;
	description: string;
	disabled: boolean;
	otherActions: boolean;
	forceComment: boolean;
	startTimeChangeConfirmText: string;
	stepTooltip: string;
	stateDescription: string;
	doSetPerformer: boolean;
	closeTask: boolean;
	finishWork: boolean;
	newOrderedTimeRequired: boolean;
	overdueReasonRequired: boolean;
	requireChangeDateBefore: boolean;
	alertText: string;
	confirmText: string;
	signatures: ITaskBatchSmartButtonSignatureItem[];
	orderId: number;
	stepOrderedTime: string;
}

export interface ITaskBatchSmartButtonSignatureItem {
	id: number;
	description: string;
	reason: string;
}

export interface IProcessTaskSmartButtonCriteria {
	tasksIds: number[];
	initiatorUserId?: number;
	smartBunttonId: string;
	comment?: string;
	signatures?: [
		{
			id: number;
			description: string;
			reason: string;
		}
	];
}

export interface IProcessTaskPerformersCriteria {
	tasksIds: number[];
	initiatorUserId?: number;
	performersIds: number[];
	action: string;
}

export interface IProcessTaskOwnerCriteria {
	tasksIds: number[];
	initiatorUserId?: number;
	ownerId: number;
}

export interface IProcessTaskDuedateCriteria {
	tasksIds: number[];
	initiatorUserId?: number;
	due: string;
	reason: string;
}

export interface IProcessTaskDeleteCriteria {
	tasksIds: number[];
	initiatorUserId?: number;
}
export interface IProcessTaskCommentCriteria {
	tasksIds: number[];
	initiatorUserId?: number;
	recipientsIds: number[];
	commentText: string;
}

export interface IProcessTaskSubscribersCriteria {
	tasksIds: number[];
	initiatorUserId?: number;
	subscribersIds: number[];
	subscribersGroupsIds: number[];
	action: string;
}
export interface IProcessTaskMoveCriteria {
	tasksIds: number[];
	initiatorUserId?: number;
	targetSubcatId: number;
}

export interface IProcessTaskCopyCriteria {
	tasksIds: number[];
	subcatId: number;
	initiatorUserId?: number;
	taskName?: string;
	orderedTime?: string;
	noOrderedTime?: boolean;
	linkToCopiedTask?: boolean;
	assignAsSubtaskForCopiedTask?: boolean;
	extParams?: IProcessTaskCopyExtParam[];
}

export interface IProcessTaskCopyExtParam {
	id: number;
	value: any;
}

export interface IProcessTaskExtParams {
	tasksIds: number[];
	initiatorUserId?: number;
	extParamsUpdates: IProcessTaskExtParamUpdate[];
}

export interface IProcessTaskExtParamUpdate {
	extParamStr: string;
	addOnly?: boolean;
	writeComment: boolean;
	commentText: string;
}

export interface IProcessTaskFilesCriteria {
	tasksIds: number[];
	initiatorUserId?: number;
	extParamId: number;
	getEdsFiles: boolean;
	organizeFilesIntoFolders: boolean;
}
