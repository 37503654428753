import { ModuleWithProviders, NgModule } from '@angular/core';

import { DESKTOP_NOTIFICATIONS_PROVIDERS } from './ngdi.config';

@NgModule({})
export class DesktopNotificationsModule {
	static forRoot(): ModuleWithProviders<DesktopNotificationsModule> {
		return {
			ngModule: DesktopNotificationsModule,
			providers: DESKTOP_NOTIFICATIONS_PROVIDERS,
		};
	}
}
