import { NgModule, ModuleWithProviders, ComponentFactoryResolver } from '@angular/core';

import { CoreComponentFactoryResolver } from './abstract';
import { CoreComponentFactoryResolverImpl } from './component-factory-resolver';

@NgModule()
export class ResolversModule {
	constructor(resolver: ComponentFactoryResolver, facadeResolver: CoreComponentFactoryResolver) {
		const fnSelf = resolver.resolveComponentFactory.bind(resolver);
		resolver.resolveComponentFactory = component => {
			try {
				return fnSelf(component);
			} catch {}
			return facadeResolver.resolveComponentFactory(component);
		};
	}

	static forRoot(): ModuleWithProviders<ResolversModule> {
		return {
			ngModule: ResolversModule,
			providers: [
				{
					provide: CoreComponentFactoryResolver,
					useClass: CoreComponentFactoryResolverImpl,
				},
			],
		};
	}
}
