import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from '@valhalla/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IApiResponse } from '../api-response';

import { DataHttpCommonService } from '../data-http-common.service';
import { ISimpleSearchCriteria, ISimpleSearchResponse, SearchDataHttpService, SearchItems } from './abstract';

@Injectable()
export class SearchDataHttpServiceImpl implements SearchDataHttpService {
	constructor(readonly http: HttpClient, readonly common: DataHttpCommonService, readonly cookie: CookieService) {}

	simpleSearch(criteria: ISimpleSearchCriteria): Observable<ISimpleSearchResponse> {
		const payload = Object.assign(this.defaultSipleSearchCriteria(), criteria);
		const url = this.common.getApiUrl(`search/simple`);
		return this.http.post<IApiResponse<ISimpleSearchResponse>>(url, payload).pipe(map(r => r?.data));
	}

	protected defaultSipleSearchCriteria(): Partial<ISimpleSearchCriteria> {
		const showClosedTasks = this.cookie.check('SimpleSearch_ShowFinishedTasks');
		const showRejectedTasks = this.cookie.check('SimpleSearch_ShowRejectedTasks');
		return {
			maxResultCount: 2,
			showClosedTasks: showClosedTasks,
			showRejectedTasks: showRejectedTasks,
			searchTypes: [SearchItems.Tasks],
		};
	}
}
