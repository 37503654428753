import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { InputSubject } from '@valhalla/utils';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { ViewDestroyStreamService } from '@spa/core';

import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { MenuItemType } from '@spa/common/components/navigation';
import { sortChildren } from '../space-panel.helper';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'vh-spaces-panel-item',
	templateUrl: './spaces-panel-item.component.html',
	styleUrls: ['./spaces-panel-item.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class SpacesPanelItemComponent implements OnInit {
	constructor(
		readonly destroy$: ViewDestroyStreamService,
		readonly mobileService: MobileViewService,
		readonly route: ActivatedRoute,
		readonly router: Router
	) {}

	@Input()
	item: ISpaceItem; // | Partial<INavigationMenuItem>;

	@Input()
	isCategory = false;

	@Input()
	contextMenu = null;

	@Input()
	highlightActiveRoute = true;

	@Input()
	isSpaceTree = false;

	@Input()
	currentTaskId;

	@Input()
	openStateFromInput = false;

	@Output()
	clickItem = new EventEmitter<any>();

	@Output()
	addBtnClick = new EventEmitter<any>();

	@Output()
	clickExpandCollapse = new EventEmitter<any>();

	@HostBinding('class.vh-spaces-panel-item')
	hostClassSelector = true;

	@Input()
	@InputSubject()
	allOpened;
	allOpened$;

	readonly menuTypes = MenuItemType;

	isOpen$ = new BehaviorSubject<boolean>(false);

	get isOpenState() {
		if (this.openStateFromInput) {
			return this.item?.isOpenedState || this.allOpened;
		}

		return this.isOpen$.value;
	}

	get isActiveRoute() {
		const item = this.item as any;

		const isActivePage =
			this.router.url.includes(`spaces/${this.item?.taskId}`) ||
			this.router.url.includes(`?pageId=${this.item?.taskId}`);

		const isActiveSpace =
			(item?.nodeType === 'Category' || item?.nodeType === 'Subcategory') &&
			this.router.url.includes(`subcat/${item?.id}`);

		return (isActivePage || isActiveSpace) && this.highlightActiveRoute;
	}

	ngOnInit() {
		this.allOpened$
			.pipe(takeUntil(this.destroy$))
			.subscribe(val => (val ? this.isOpen$.next(true) : this.isOpen$.next(false)));
	}

	onClickCategory(item: ISpaceItem) {
		if (item?.taskId === this.currentTaskId && this.isSpaceTree) {
			return;
		}

		if (this.isCategory) {
			if (this.isChildren(item)) {
				if (this.openStateFromInput) {
					this.item.isOpenedState = !this.item?.isOpenedState;
					this.clickExpandCollapse.emit(item);
				} else {
					this.isOpen$.next(!this.isOpen$.value);
				}
			}

			if (item?.isSpace && !item?.isSpecialSection) {
				return this.clickItem.emit(item);
			}

			return;
		}

		if (item?.isSpecialSection && !this.isSpaceTree) {
			if (!item?.isOpenedState) {
				this.item.isOpenedState = true;
			}

			return;
		}

		if (this.isChildren(item) && !item?.isOpenedState) {
			this.item.isOpenedState = true;
		}

		return this.clickItem.emit(item);
	}

	expandCollapse(event, item: ISpaceItem) {
		event.stopPropagation();

		if (this.openStateFromInput) {
			this.item.isOpenedState = !this.item?.isOpenedState;
			this.clickExpandCollapse.emit(item);
			return;
		}

		this.isOpen$.next(!this.isOpen$.value);
	}

	isChildren(item: ISpaceItem) {
		return item?.children?.length;
	}

	sortChildren(children) {
		return sortChildren(children);
	}
}

export interface ISpaceItem {
	name: string;
	parentId?: number;
	taskId?: number;
	children: any[];
	data?: any;
	canCreate?: boolean;
	isSpace?: boolean;
	subcatId?: number;
	value?: any;
	isSpecialSection?: boolean;
	isRoot?: boolean;
	isOpenedState?: boolean;
	nodeType?: string;
}
