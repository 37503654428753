import { map } from 'rxjs';
import { ExtParamBase } from './ext-param-base';
import edjsHTML from 'editorjs-html';

export class ExtParamTextArea extends ExtParamBase {
	readonly replaceSymb = '&#58;&#58;';
	convertForUpdateExtParamInTask() {
		let val = this.value;

		if (this.settings.editorType === 'Editorjs') {
			const valObj = JSON.parse(this.value);
			const content = valObj?.content;

			if (this.isNewTask) {
				val = {
					content: content,
					format: 'Editorjs',
					previewText: this.getPreviewText(content),
				};
				return `#n${this.id}#v${JSON.stringify(val) || ''}`;
			}

			return this.controlStyleEditorService.updateFilesUrl(content, this.ctxTaskId).pipe(
				map(res => {
					val = {
						content: res,
						format: 'Editorjs',
						previewText: this.getPreviewText(content),
					};
					return `#n${this.id}#v${JSON.stringify(val) || ''}`;
				})
			);
		}

		val = this.value?.replace(/::/g, this.replaceSymb);
		return `#n${this.id}#v${val || ''}`;
	}

	isEmpty() {
		if (this.settings?.editorType === 'Editorjs') {
			let val = null;

			try {
				val = JSON.parse(this.value as string);
			} catch {}

			return !val?.content?.blocks?.length;
		}

		return !this.value;
	}

	getPreviewText(val) {
		const supportedBlocks = val?.blocks?.filter(b => !(b?.type === 'embed')) || [];
		const value = {
			...val,
			blocks: supportedBlocks,
		};

		const edjsParser = edjsHTML();
		const htmlArray = edjsParser.parse(value);
		const div = document.createElement('div');
		div.innerHTML = htmlArray.join(' ');
		const divInnerText = div.innerText;

		if (divInnerText?.length > 250) {
			return divInnerText.slice(0, 250) + '...';
		}
		return divInnerText;
	}

	equalsValue(a: any, b: any) {
		if (!a && !b) {
			return true;
		}

		if (this.settings.editorType === 'Editorjs') {
			let isEqual = String(a) === String(b);

			try {
				const aJson = JSON.parse(a);
				const bJson = JSON.parse(b);

				if (aJson?.content && bJson?.content) {
					isEqual = JSON.stringify(aJson?.content) === JSON.stringify(bJson?.content);
					return isEqual;
				}
				isEqual = String(a) === String(b);
				return isEqual;
			} catch {
				isEqual = String(a) === String(b);
				return isEqual;
			}
		}

		return String(a?.replace(/::/g, this.replaceSymb)).trim() === String(b?.replace(/::/g, this.replaceSymb)).trim();
	}
}
