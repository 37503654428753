<vh-modal-base
	[title]="'common.confirmTitle' | resx"
	[showClose]="true"
	(close)="close()"
	(cancel)="close()"
	(ok)="close(true)"
>
	<ng-container *ngIf="dialogData?.resx">
		{{ dialogData?.resx | resx }}
	</ng-container>

	<ng-container *ngIf="!dialogData?.resx">
		{{ dialogData?.message }}
	</ng-container>
</vh-modal-base>
