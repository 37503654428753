import { ApplicationRef, Injectable, Injector, inject } from '@angular/core';
import { KeyValueIDBStorage, UrlProvider } from '@valhalla/core';
import { booleanFilter, debug } from '@valhalla/utils';
import { BehaviorSubject, from, merge, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { CultureService } from './culture.service';
import { RESOURCE_DEFINITION_ALL } from './resource-register';
import { IResourceDefinition } from './resource-types';
import specialKey from './special-key';

@Injectable({ providedIn: 'root' })
export class ResourceService {
	constructor(
		protected readonly culture: CultureService,
		protected readonly url: UrlProvider,
		protected readonly keyValueDb: KeyValueIDBStorage,
		protected readonly injector: Injector,
		protected readonly appRef: ApplicationRef
	) {}

	ALL_RESOURCES = inject(RESOURCE_DEFINITION_ALL);

	protected overrideNamespaceKey = `${specialKey}overrides`;

	protected namespaceKeyDelimiter = '.';

	protected resourceOverrideDefinitions = this.culture.languages$.pipe(
		map(langs =>
			langs.map(lang => {
				const resxPath = this.getResourceOverridePath(lang.culture);
				const def: IResourceDefinition = {
					culture: lang.culture,
					namespace: this.overrideNamespaceKey,
					/**
					 * !!! IMPORTANT: do not delete webpackIgnore comment - this is a directive for webpack bundler
					 * webpackMode: 'eager' - magic comment for lazy initializing but store in bundle
					 * multiple magic comments supports: webpackChunkName: "route--home", webpackPrefetch: true
					 */
					factory: () => import(/* webpackIgnore: true */ resxPath),
				};
				return def;
			})
		)
	);

	protected resourceCache: Record<
		string,
		{
			init: boolean;
			pending: boolean;
			cache$: BehaviorSubject<Record<string, string>>;
			cacheReturn$: () => Observable<Record<string, string>>;
		}
	> = {};

	get pending() {
		const caches = Object.values(this.resourceCache);
		if (!caches.length) {
			return true;
		}
		const pending = caches.some(c => c.pending);
		return pending;
	}

	extractNamespace(key: string) {
		const [ns, ...keyPart] = key.split(this.namespaceKeyDelimiter);
		if (keyPart.length === 0) {
			throw new Error(`Localization key hasn't namespace: ${key}. Please set key with format 'namespace.key'`);
		}
		return {
			namespace: ns,
			key: keyPart.join(this.namespaceKeyDelimiter),
		};
	}

	resolveKey(nsAndKey: string, cultureOrAlias: string, ...definitions: IResourceDefinition[]): Observable<string>;
	resolveKey(nsAndKey: string, ...definitions: IResourceDefinition[]): Observable<string>;
	resolveKey(nsAndKey: string, ...args: any[]): Observable<string> {
		const { namespace, key } = this.extractNamespace(nsAndKey);

		const isCulturePassed = typeof args[0] === 'string';
		if (isCulturePassed) {
			return throwError(() => new Error('Resolve key with culture or alias is not implemented!'));
		}

		const definitions = args as IResourceDefinition[];

		return this.culture.activeCulture$.pipe(
			switchMap(activeCulture => this.resolveResource(activeCulture, namespace, ...definitions)),
			map(resourceData => resourceData[key])
		);
	}

	protected getResourceOverridePath(culture: string) {
		return this.url.getUrlRelativeToAssets(`resources/resource-override.${culture}.js`);
	}

	public resolveCurrentResource(namespace: string) {
		return this.culture.activeCulture$.pipe(mergeMap(culture => this.resolveResource(culture, namespace)));
	}

	public resolveResource(
		culture: string,
		namespace: string,
		...definitions: IResourceDefinition[]
	): Observable<Record<string, any>> {
		const notFoundValue = {};
		// try find in cache
		const cacheKey = `resources/${culture}${specialKey}${namespace}`;
		let cacheMeta = this.resourceCache[cacheKey];
		definitions = definitions.length === 0 ? this.ALL_RESOURCES : definitions;

		if (!cacheMeta) {
			const cache$ = new BehaviorSubject(null);
			const cacheReturn$ = cache$.pipe(booleanFilter());
			cacheMeta = this.resourceCache[cacheKey] = {
				init: false,
				cache$: cache$,
				pending: false,
				cacheReturn$: () => cacheReturn$,
			};
		}

		if (cacheMeta?.init || cacheMeta.pending) {
			return cacheMeta.cacheReturn$();
		}

		if (!cacheMeta.init) {
			const definition = definitions.find(def => def.culture === culture && def.namespace === namespace);
			if (!definition) {
				return cacheMeta.cacheReturn$();
			}
			cacheMeta.pending = true;
			// set cache and return
			const resolvedResource = definition.factory(this.injector, culture);
			const resolveResourceFromFactory$ =
				resolvedResource instanceof Observable || resolvedResource instanceof Promise
					? from(resolvedResource)
					: of(resolvedResource);

			const resolveResourceFromCache$ = this.keyValueDb.get<Record<any, any>>(cacheKey);

			const fromCache = 'cache',
				fromRemote = 'remote';
			merge(
				// resolveResourceFromCache$.pipe(
				// 	map(value => ({ from: fromCache, value })),
				// 	take(1)
				// ),
				resolveResourceFromFactory$.pipe(
					map(value => ({ from: fromRemote, value })),
					take(1)
				)
			)
				.pipe(
					tap(result => {
						if (result.from === fromRemote) {
							this.keyValueDb.setAnyway(cacheKey, result.value);
						}
					}),
					map(result => result.value),
					booleanFilter(),
					catchError(err => {
						console.error(err);
						return of(notFoundValue);
					})
				)
				.subscribe(rsxData => {
					cacheMeta.pending = false;
					cacheMeta.init = true;
					cacheMeta.cache$.next(rsxData);
					this.appRef.tick();
				});
		}

		return cacheMeta.cacheReturn$();
	}
}
