import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	Inject,
	ApplicationRef,
	HostBinding,
	ViewEncapsulation,
} from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ViewDestroyStreamService, LoggerFactory, AbstractLogger, UrlProvider } from '@valhalla/core';

@Component({
	selector: 'vh-facade-modal-error',
	templateUrl: 'modal-error.component.html',
	styleUrls: ['modal-error.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class FacadeModalErrorComponent implements OnInit {
	constructor(
		readonly destroy$: ViewDestroyStreamService,
		readonly dialogRef: MatDialogRef<FacadeModalErrorComponent>,
		@Inject(MAT_DIALOG_DATA) readonly dialogData: any,
		readonly lf: LoggerFactory,
		readonly urlBuilder: UrlProvider,
		readonly appRef: ApplicationRef
	) {}

	@HostBinding('class.modal-dialog-window')
	hostClsModalDialogWindow = true;

	@HostBinding('class.vh-facade-modal-error')
	hostClsSelector = true;

	logger: AbstractLogger = this.lf.createLogger('FacadeModalErrorComponent');

	ngOnInit() {
		this.logger.info(this.dialogData);
	}

	close(ok = false) {
		this.dialogRef.close(ok);
		this.appRef.tick();
	}
}
