import { NgModule } from '@angular/core';

import { SidebarComponent } from './sidebar.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [CommonModule, MatIconModule],
	declarations: [SidebarComponent],
	exports: [SidebarComponent],
})
export class SidebarModule {}
