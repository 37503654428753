import { Injectable } from '@angular/core';
import { IHoster } from './hoster';

@Injectable({ providedIn: 'root' })
export class BrowserHosterService implements IHoster {
	get isOneTabMode(): boolean {
		return false;
	}

	focusTab(): void {
		window.focus();
	}

	setupJitsiRenderer(api, options = {}) {}

	disposeJitsiRenderer() {}
}
