import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IAsmxDto, ISystemTickersDto, ITickerDto, ISystemTickersDto2 } from '@valhalla/data/entities';
import { merge } from '@valhalla/utils';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { IApiResponse } from '../api-response';
import { DataHttpCommonService } from '../data-http-common.service';
import { EndpointUrlConfig } from '../endpoint.config';
import type { IEndpointUrlConfig } from '../endpoint.config';
import {
	TickersDataHttpService,
	IGetValuesCustomTickersCriteria,
	IGetValueCustomTickerDto,
	IGetAllTickersResult,
} from './abstract';

@Injectable()
export class TickersDataHttpServiceImpl implements TickersDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService
	) {}

	getSchemaCustomTickers(): Observable<ITickerDto[]> {
		const url = this.common.getApiUrl(`tickers/custom`);
		return this.http.get<IApiResponse<ITickerDto[]>>(url).pipe(
			map(result => result.data),
			catchError(err => {
				console.error(err);
				return of([]);
			})
		);
	}

	// getValuesCustomTickers(criteria: IGetValuesCustomTickersCriteria): Observable<IGetValueCustomTickerDto[]> {
	// 	const url = this.common.getEndpointUrl('/TCWeb.asmx/GetCustomTickerValues');

	// 	return this.http.post<IAsmxDto<IGetValueCustomTickerDto[]>>(url, criteria).pipe(
	// 		map(response => response.d),
	// 		catchError(err => {
	// 			console.error(err);
	// 			return of([]);
	// 		})
	// 	);
	// }

	// getSystemTickers(options?: Record<string, any>): Observable<ISystemTickersDto> {
	// 	options = Object.assign(options || {}, { isOnline: true });

	// 	const url = this.common.getEndpointUrl('/TCWeb.asmx/GetUserEvents');

	// 	return this.http.post<IAsmxDto<ISystemTickersDto>>(url, options).pipe(map(response => response.d));
	// }

	// getChatUnreadCountTicker(options?: any): Observable<number> {
	// 	const defaultOptions = {
	// 		pars: {
	// 			OpenedChatId: -1,
	// 			AutoReturnCommentsForFirstChat: false,
	// 			ClosedChatsCount: -1,
	// 			Chats: [],
	// 		},
	// 	};
	// 	options = merge(defaultOptions, options || {});

	// 	const url = this.common.getEndpointUrl('/TCWeb.asmx/GetChatsUpdate');
	// 	return this.http.post<IAsmxDto<any>>(url, options).pipe(
	// 		map(response => response.d),
	// 		map(r => r.Chats.map(chat => chat.UnreadCount).reduce((acc, cur) => acc + cur, 0))
	// 	);
	// }

	getAll(): Observable<IGetAllTickersResult> {
		const url = this.common.getApiUrl(`tickers/all`);
		return this.http
			.get<IApiResponse<IGetAllTickersResult>>(url, {
				headers: {
					['background-request']: 'true',
				},
			})
			.pipe(map(result => result.data));
	}

	getCustom(): Observable<ITickerDto[]> {
		const url = this.common.getApiUrl(`tickers/custom`);
		return this.http.get<IApiResponse<ITickerDto[]>>(url).pipe(map(result => result.data));
	}

	getSystem(...keys: string[]): Observable<Partial<ISystemTickersDto2>> {
		const url = this.common.getApiUrl(`tickers/system`);
		let tickers: string;
		if (keys.length) {
			tickers = keys.join(',');
		}
		return this.http
			.get<IApiResponse<Partial<ISystemTickersDto2>>>(url, {
				params: { tickers },
			})
			.pipe(map(result => result.data));
	}

	updateUserTickersCache(): Observable<void> {
		const url = this.common.getApiUrl(`tickers/refresh-cache`);
		return this.http.get<void>(url);
	}

	updateUserTickersCacheForSubcat(subcatId: number): Observable<void> {
		const url = this.common.getApiUrl(`tickers/refresh-subcat-cache/${subcatId}`);
		return this.http.get<void>(url);
	}
}
