import { NgModule } from '@angular/core';
import { FileViewerComponent } from './file-viewer.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FileViewNotSupportedComponent } from './file-view-no-supported/file-view-not-supported.component';
import { FileViewerService } from './file-viewer.service';

@NgModule({
	imports: [CommonModule, MatIconModule],
	exports: [FileViewerComponent, FileViewNotSupportedComponent],
	declarations: [FileViewerComponent, FileViewNotSupportedComponent],
	providers: [FileViewerService],
})
export class FileViewerModule {}
