import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { UrlProvider, ViewDestroyStreamService } from '@valhalla/core';
import { IAddToFavoritesRequest } from '@valhalla/data/http';
import { BooleanFilter, cloneDeep, rxHandler } from '@valhalla/utils';
import { BehaviorSubject, combineLatest, from, of } from 'rxjs';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	shareReplay,
	startWith,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { DataHttpService } from '@valhalla/data/http';
import { INavigationMenu, MenuItemType, NavigationLayoutType, UserLinkType } from './navigation.model';
import { NavigationCommonService } from '@spa/common/components/navigation/navigation.service';

@Component({
	selector: 'vh-common-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class NavigationCommonComponent implements OnInit {
	constructor(
		protected router: Router,
		protected url: UrlProvider,
		protected readonly server: DataHttpService,
		readonly destroy$: ViewDestroyStreamService,
		readonly cdr: ChangeDetectorRef,
		readonly elRef: ElementRef<HTMLElement>,
		readonly navigationCommonService: NavigationCommonService
	) {}

	@Input()
	layout: NavigationLayoutType = NavigationLayoutType.vertical;

	@Input()
	set navigation(value: INavigationMenu[]) {
		this.navigation$.next(value);
	}

	get navigation() {
		return this.navigation$.value;
	}

	@Output()
	navigationClick: EventEmitter<INavigationMenu> = new EventEmitter();

	@Output()
	contextMenuClick: EventEmitter<IAddToFavoritesRequest> = new EventEmitter();

	@Input()
	searchable: EventEmitter<IAddToFavoritesRequest> = new EventEmitter();

	readonly layoutTypes = NavigationLayoutType;
	readonly menuTypes = MenuItemType;

	readonly navigation$ = new BehaviorSubject<INavigationMenu[]>([]);

	readonly searchHandler = rxHandler<string>();

	readonly searchInput$ = from<string>(this.searchHandler as any).pipe(
		tap(() => {
			this.isOpenSetAfterSearch = false;
			this.scrollToSearchInput();
		}),
		startWith('')
	);

	readonly searchInputHasValue$ = this.searchInput$.pipe(distinctUntilChanged(), map(BooleanFilter));

	readonly perfectScrollBarOptions = { suppressScrollX: true };

	isOpenSetAfterSearch = false;
	cacheFilter = {};

	readonly navigationBySearch$ = combineLatest([this.navigation$, this.searchInput$.pipe(debounceTime(300))]).pipe(
		switchMap(([nav, query]) => {
			const res: [INavigationMenu[], string, Set<number>] = [nav, query, undefined];
			const maySubcatOrTaskId = +query;
			if (isNaN(maySubcatOrTaskId)) {
				return of(res);
			}
			res[2] = new Set([maySubcatOrTaskId]);
			// check if task with given id
			return this.server.task.checkExistAndAccess(maySubcatOrTaskId).pipe(
				map(r => {
					const subcatId = r.isTaskExists ? r.taskShortInfo?.subcatId : null;
					if (subcatId) {
						res[2].add(subcatId);
					}
					return res;
				}),
				catchError(() => {
					return of(res);
				})
			);
		}),
		map(([nav, query, subcatIds]) => {
			if (query) {
				const cloned = cloneDeep(nav);
				return this.filterNavigation(cloned, query, subcatIds);
			} else {
				return [];
			}
		}),
		tap(() => setTimeout(() => this.cdr.detectChanges()))
	);

	readonly appConfig$ = this.server.config.getAppConfiguration().pipe(shareReplay({ bufferSize: 1, refCount: true }));
	readonly appConfigAnon$ = this.server.config
		.getAppSettingsAnonymous()
		.pipe(shareReplay({ bufferSize: 1, refCount: true }));
	readonly calendarSubcatId$ = this.appConfig$.pipe(map(data => Number(data.calendarSubcatId)));
	searchSubcategoryFolders = false;

	ngOnInit() {
		this.appConfigAnon$.pipe(take(1), takeUntil(this.destroy$)).subscribe(config => {
			this.searchSubcategoryFolders = Boolean(
				config.CustomSettings?.searchSubcategoryFolders || config.CustomSettings?.SearchSubcategoryFolders
			);
		});
	}

	scrollToSearchInput() {
		const scrollContainer = this.elRef.nativeElement?.closest('.navbar-scroll-container');
		scrollContainer.scrollTop = 0;
	}

	onNavigationClick(item: INavigationMenu, toggleOpen = false) {
		if (toggleOpen) {
			item.isOpen = !item.isOpen;
		}
		this.navigationClick.emit(item);
	}

	trackById(index: number, nav: INavigationMenu) {
		return nav.id;
	}

	// openFeed(item: INavigationMenu, e) {
	// 	const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
	// 	let url;

	// 	if (linkType === 'CategoryId') {
	// 		url = `/tasks/category/${item.$id}/feeds`;
	// 	}

	// 	if (linkType === 'SubCatID') {
	// 		url = `/tasks/subcat/${item.$id}/feeds`;
	// 	}

	// 	if (e?.ctrlKey) {
	// 		const urlForWindow = this.url.getUrl(url, true);
	// 		window.open(urlForWindow, '_blank');
	// 		return;
	// 	}

	// 	return this.router.navigate([url]);
	// }

	// openFiles(item: INavigationMenu, e) {
	// 	const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
	// 	let url;

	// 	if (linkType === 'CategoryId') {
	// 		url = `/tasks/category/${item.$id}/files`;
	// 	}

	// 	if (linkType === 'SubCatID') {
	// 		url = `/tasks/subcat/${item.$id}/files`;
	// 	}

	// 	if (e?.ctrlKey) {
	// 		const urlForWindow = this.url.getUrl(url, true);
	// 		window.open(urlForWindow, '_blank');
	// 		return;
	// 	}

	// 	return this.router.navigate([url]);
	// }

	// openChannel(item: INavigationMenu, e) {
	// 	const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
	// 	let url;

	// 	if (linkType === 'CategoryId') {
	// 		url = `/tasks/category/${item.$id}/channel`;
	// 	}

	// 	if (linkType === 'SubCatID') {
	// 		url = `/tasks/subcat/${item.$id}/channel`;
	// 	}

	// 	if (e?.ctrlKey) {
	// 		const urlForWindow = this.url.getUrl(url, true);
	// 		window.open(urlForWindow, '_blank');
	// 		return;
	// 	}

	// 	return this.router.navigate([url]);
	// }

	// openChat(item: INavigationMenu, e) {
	// 	const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
	// 	let url;

	// 	if (linkType === 'CategoryId') {
	// 		url = `/tasks/category/${item.$id}/chat`;
	// 	}

	// 	if (linkType === 'SubCatID') {
	// 		url = `/tasks/subcat/${item.$id}/chat`;
	// 	}

	// 	if (e?.ctrlKey) {
	// 		const urlForWindow = this.url.getUrl(url, true);
	// 		window.open(urlForWindow, '_blank');
	// 		return;
	// 	}

	// 	return this.router.navigate([url], { queryParams: { mode: 'feed' } });
	// }

	// createTask(item: INavigationMenu, e) {
	// 	const url = item.isSpace ? `/spaces/${item.$id}/new` : `/newtask/${item.$id}`;
	// 	this.server.category
	// 		.storageSubCategory(item.$id)
	// 		.pipe(
	// 			map(data => data.subcategory.viewAndPermissions),
	// 			take(1)
	// 		)
	// 		.subscribe(viewAndPermissions => {
	// 			if (viewAndPermissions.redirectFromNewTask?.length) {
	// 				this.navigationCommonService.redirectToIFrameLink(viewAndPermissions.redirectFromNewTask);
	// 				return;
	// 			}
	// 			if (e?.ctrlKey || e?.metaKey) {
	// 				window.open(this.url.getUrl(`${url}`, true), '_blank');
	// 				return;
	// 			}

	// 			this.router.navigate([url]);
	// 		});
	// }

	// openKanban(item: INavigationMenu, e) {
	// 	if (e?.ctrlKey) {
	// 		const url = this.url.getUrl(`/tasks/subcat/${item.$id}/kanban`, true);
	// 		window.open(url, '_blank');
	// 		return;
	// 	}

	// 	this.router.navigate([`/tasks/subcat/${item.$id}/kanban`]);
	// }

	// openTable(item: INavigationMenu, e) {
	// 	const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
	// 	let url;

	// 	if (linkType === 'CategoryId') {
	// 		url = `/tasks/category/${item.$id}/grid`;
	// 	}

	// 	if (linkType === 'SubCatID') {
	// 		url = `/tasks/subcat/${item.$id}/grid`;
	// 	}

	// 	if (e?.ctrlKey) {
	// 		const urlForWindow = this.url.getUrl(url, true);
	// 		window.open(urlForWindow, '_blank');
	// 		return;
	// 	}

	// 	this.router.navigate([url]);
	// }

	// openCalendar(item: INavigationMenu, e) {
	// 	const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
	// 	let url;

	// 	if (linkType === 'CategoryId') {
	// 		url = `/tasks/category/${item.$id}/calendar`;
	// 	}

	// 	if (linkType === 'SubCatID') {
	// 		url = `/tasks/subcat/${item.$id}/calendar`;
	// 		this.calendarSubcatId$.pipe(take(1), takeUntil(this.destroy$)).subscribe(subcatId => {
	// 			if (item.$id === subcatId) {
	// 				url = this.url.getUrl('/calendar');
	// 			}
	// 		});
	// 	}

	// 	if (e?.ctrlKey) {
	// 		const urlForWindow = this.url.getUrl(url, true);
	// 		window.open(urlForWindow, '_blank');
	// 		return;
	// 	}

	// 	this.router.navigate([url]);
	// }

	// openGantt(item: INavigationMenu, e) {
	// 	const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
	// 	let url;

	// 	if (linkType === 'CategoryId') {
	// 		url = `/tasks/category/${item.$id}/gantt`;
	// 	}

	// 	if (linkType === 'SubCatID') {
	// 		url = `/tasks/subcat/${item.$id}/gantt`;
	// 	}

	// 	if (e?.ctrlKey) {
	// 		const urlForWindow = this.url.getUrl(url, true);
	// 		window.open(urlForWindow, '_blank');
	// 		return;
	// 	}

	// 	this.router.navigate([url]);
	// }

	// openCategorySettings(item: INavigationMenu, newSettings = false) {
	// 	if (newSettings) {
	// 		const subcatId = item.$id;
	// 		const url = this.url.getUrl(`/administration/subcategory/${subcatId}`, true);
	// 		window.open(url, '_self');
	// 		return;
	// 	}
	// 	const url = this.url.getUrl(`/admin/subcategories/EditSubcatFrameset.aspx?SubcatID=${item.$id}`);
	// 	window.open(url, '_blank');
	// }

	// addItemToFavorites(item: INavigationMenu, type: string) {
	// 	let isCat = '';
	// 	if (item.nodeType === 'Category') {
	// 		isCat = 'iscat=true&';
	// 	}

	// 	let requestParams = {
	// 		customImageClass: 'subcatFolder',
	// 		customImagePath: null,
	// 		folderId: null,
	// 		isJsFunction: true,
	// 		link: 'javascript:void(TCLeftTrees.openSyndicate("?' + isCat + 'PID=' + item.$id + '&force=' + type + '"))',
	// 		linkedObjectId: item.$id + '',
	// 		name: item.title,
	// 		type: UserLinkType[this.firstLetterToLowerCase(item.nodeType)],
	// 	};

	// 	if (type === 'kanban') {
	// 		requestParams = {
	// 			customImageClass: 'subcatFolder',
	// 			customImagePath: null,
	// 			folderId: null,
	// 			isJsFunction: true,
	// 			link: 'spa/noframe/tasks/subcat/' + item.$id + '/kanban',
	// 			linkedObjectId: item.$id + '',
	// 			name: item.title,
	// 			type: UserLinkType[this.firstLetterToLowerCase(item.nodeType)],
	// 		};
	// 	}

	// 	this.contextMenuClick.emit(requestParams);
	// }

	firstLetterToLowerCase(string: string): string {
		if (!string) {
			return null;
		}
		return string.charAt(0).toLowerCase() + string.slice(1);
	}

	filterNavigation(menu: INavigationMenu[], filter: string, subcatIds?: Set<number>): INavigationMenu[] {
		if (this.cacheFilter[filter]) {
			return this.cacheFilter[filter];
		}

		function escapeRegExp(text) {
			return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
		}

		const esc = escapeRegExp(filter);
		const pattern = esc.replace(/е|ё/gi, '(е|ё)');
		const regExp = new RegExp(pattern, 'i');
		const filterMatch = (str: string) => {
			const res = regExp.test(str);
			return res;
		};
		const catOrSubcatIdMatch = (item: INavigationMenu) => {
			if (!subcatIds) {
				return false;
			}
			const id = item.$id;
			return subcatIds.has(id);
		};

		const isLeaf = (item: INavigationMenu) => item.menuType === 'item';

		const isNavigationMenuMatch = (item: INavigationMenu, parent: INavigationMenu = null) => {
			if (this.searchSubcategoryFolders) {
				if (subcatIds?.size > 0) {
					if (isLeaf(item)) {
						if (catOrSubcatIdMatch(parent)) {
							return true;
						}
						const match = catOrSubcatIdMatch(item);
						return match;
					}
					const folderMatch = catOrSubcatIdMatch(item);
					if (folderMatch) {
						return true;
					}
					const someChildrenMatch = (item.children as Array<INavigationMenu>).some(i => isNavigationMenuMatch(i, item));
					return someChildrenMatch;
				}
				if (isLeaf(item)) {
					if (filterMatch(parent?.title)) {
						return true;
					}
					const match = filterMatch(item.title);
					return match;
				}
				const folderMatch = filterMatch(item.title);
				if (folderMatch) {
					return true;
				}
				const someChildrenMatch = (item.children as Array<INavigationMenu>).some(i => isNavigationMenuMatch(i, item));
				return someChildrenMatch;
			} else {
				if (subcatIds?.size > 0) {
					if (isLeaf(item)) {
						const leafIdMatch = catOrSubcatIdMatch(item);
						return leafIdMatch;
					}
					const someChildrenMatch = (item.children as Array<INavigationMenu>).some(i => isNavigationMenuMatch(i));
					return someChildrenMatch;
				}
				if (isLeaf(item)) {
					const leafTitleMatch = filterMatch(item.title);
					return leafTitleMatch;
				}
				const someChildrenMatch = (item.children as Array<INavigationMenu>).some(i => isNavigationMenuMatch(i));
				return someChildrenMatch;
			}
		};

		function filterMenu(items: INavigationMenu[], parent: INavigationMenu = null): INavigationMenu[] {
			const filtered = items.filter(i => isNavigationMenuMatch(i, parent));
			filtered.forEach(item => {
				item.isOpen = true;
				item.children = filterMenu.call(this, item.children as Array<INavigationMenu>, item);
			});
			return filtered;
		}

		const filteredMenu = filterMenu.call(this, menu);

		this.isOpenSetAfterSearch = true;
		this.cacheFilter[filter] = filteredMenu;

		return filteredMenu;
	}

	// openGridWithSelection(subcatId: number, expressionId: number) {
	// 	this.router.navigate([`/tasks/subcat/${subcatId}/grid`], { queryParams: { SmartQueryId: expressionId } });
	// }
}
