import { Observable } from 'rxjs';
import { IApiResponse } from '../api-response';
import { IParentFiles } from '../task';

export abstract class JobsDataHttpService {
	abstract getJobsList(): Observable<IApiResponse<IJobDto[]>>;
	abstract continueAll(): Observable<void>;
	abstract runJob(jobKey: string): Observable<void>;
	abstract unlock(jobKey: string): Observable<void>;
	abstract getServerStates(): Observable<IApiResponse<IJobServerState[]>>;
	abstract getJobSettings(jobKey: string): Observable<JobSettingsDto>;
	abstract saveJobSettings(jobKey: string, jobSettings: JobSettingsDto): Observable<JobSettingsDto>;
}

export interface IJobDto {
	jobKey: string;
	nextFireTime: any;
	prevFireTime: any;
	state: string;
	period: string;
	isSuccess: boolean;
	disabled: boolean;
}

export interface IJobServerState {
	checkinInterval: string;
	checkinTimestamp: string;
	shedulerInstanceId: string;
}

export enum JobSettingsDurationType {
	NoEndDate = 'NoEndDate',
	EndByDate = 'EndByDate',
	OccurrenceCount = 'OccurrenceCount',
}

export type JobSettingsDto = {
	isActive: boolean;
	useDefaultSettings: boolean;
	startDate: string;
	dayNumber: number;
	dayOfWeeks: string[];
	weekOfMonth: string;
	month: string;
	recurrenceType: string;
	businessHoursOnly: boolean;
	periodicity: number;
	endDate: string;
	occurrenceCount: number;
	parentFilesBehavior: IParentFiles;
	durationType: JobSettingsDurationType;
};
