import { Inject, Injectable } from '@angular/core';
import { isNullOrUndefined, debug } from '@valhalla/utils';
import { from, Observable, of } from 'rxjs';
import { map, mapTo, shareReplay, take, tap } from 'rxjs/operators';

import { AbstractLogger, LoggerFactory } from '../diagnostics';
import { FormatNumberService } from './abstract/formatNumber.service';
import { LazyKendoLocale } from './lazy-kendo-locale-ru';
import type { LazyKendoLocaleType } from './lazy-kendo-locale-ru';

@Injectable()
export class AngularKendoFormatNumberServiceImpl implements FormatNumberService {
	constructor(logger: LoggerFactory, @Inject(LazyKendoLocale) readonly lazyKendoLocale: LazyKendoLocaleType) {
		this.logger = logger.createLogger('AngularKendoFormatNumberServiceImpl');
		this.ready$.pipe().subscribe();
	}

	readonly logger: AbstractLogger;
	protected formatFn: Function;
	ready$: Observable<boolean> = from(this.lazyKendoLocale().then(m => m.formatNumber)).pipe(
		tap(fn => {
			this.formatFn = fn;
		}),
		mapTo(true),
		shareReplay({ bufferSize: 1, refCount: false })
	);

	formatNumber$(num: number, format: string): Observable<string> {
		if (isNullOrUndefined(num)) {
			return of('');
		}

		const formatNumberFn$ = from(this.lazyKendoLocale().then(m => m.formatNumber));

		return formatNumberFn$.pipe(map(fn => fn(num, format, 'ru-RU')));
	}

	formatNumber(num: number, format: string): string {
		if (!this.formatFn) {
			return String(num);
		}

		return this.formatFn(num, format, 'ru-RU');
	}
}
