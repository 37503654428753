import { NgModule } from '@angular/core';
import { MobileMainMenuComponent } from '@spa/facade/layout/components/mobile-main-menu/mobile-main-menu.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AvatarModule } from '@spa/common/components/chat-nav-avatar';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ReportsDialogComponent } from './reports-dialog/reports-dialog.component';
import { ProfileSpaReportsModule } from '@spa/pages/user/profile-spa/profile-spa-reports/profile-spa-reports.module';
import { ModalBaseModule } from '@spa/facade/features/modals/modal-base';
import { SelectCategoryDialogModule } from '@spa/components/task/ui/task-card/select-category-dialog/select-category-dialog.module';
import { OutsideClickModule } from '@spa/common/directives/outside-click.directive';
import { MobileSettingsModule } from '@spa/components/mobile-settings/mobile-settings.module';
import { TippyModule } from '@ngneat/helipopper';

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		AvatarModule,
		MatListModule,
		ProfileSpaReportsModule,
		ModalBaseModule,
		SelectCategoryDialogModule,
		OutsideClickModule,
		MobileSettingsModule,
		TippyModule,
	],
	exports: [MobileMainMenuComponent],
	declarations: [MobileMainMenuComponent, ReportsDialogComponent],
})
export class MobileMainMenuModule {}
