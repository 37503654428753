import { NgModule, ModuleWithProviders } from '@angular/core';
import { UrlProvider } from './abstract';
import { UrlProviderImpl } from './url.service';

@NgModule()
export class UrlModule {
	static forRoot(): ModuleWithProviders<UrlModule> {
		return {
			ngModule: UrlModule,
			providers: [
				{
					provide: UrlProvider,
					useClass: UrlProviderImpl,
				},
			],
		};
	}
}
