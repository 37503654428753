import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'htmlToPlaintext' })
export class HtmlToPlainTextPipe implements HtmlToPlainText {
	readonly domParser = new DOMParser();

	transform(value: string, args: any[] = []): string {
		//return value ? String(value).replace(/<[^>]+>/gm, '') : '';
		if (!value) {
			return value;
		}
		const dom = this.domParser.parseFromString(value, 'text/html');
		return dom.body.textContent.trim();
	}
}

@Injectable({ providedIn: 'root', useClass: HtmlToPlainTextPipe })
export abstract class HtmlToPlainText implements PipeTransform {
	abstract transform(value: string, args?: any[]): string;
}
