import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { ApiVersion } from '@valhalla/data/http';

export abstract class UrlProvider {
	abstract basePath: string;
	abstract domSanitizer: DomSanitizer;
	/** return right URL prefix with BASE path for API
	 * {basePath}/app/v1.0/api/{your url}
	 */
	abstract getApiUrl(url: string, apiVer?: ApiVersion): string;
	/**return whole path: baseHref + url without /(at)spa by default
	 * @example
	 * // baseHref = '/app1/(at)spa/'
	 * const path = '/my/path/';
	 * getUrl(path) // '/app1/my/path'
	 * getUrl(path, true) // '/app1/(at)spa/my/path'
	 */
	abstract getUrl(url: string, includeSpaPrefix?: boolean): string;
	abstract getAbsoluteUrl(url: string, includeSpaPrefix?: boolean): string;
	abstract joinWithSlash(...urls: string[]): string;
	abstract currentUrlExceptBaseHref(): string;
	abstract safeUrlFromBlob(blob: Blob): SafeValue;
	abstract urlFromBlob(blob: Blob): string;
	/** Bypass security and trust the given value to be a safe style URL, i.e. a value that can be used in hyperlinks or <img src>.*/
	abstract getSafeUrl(url: string): SafeValue;
	/**
	 * used DomSanitizer.bypassSecurityTrustResourceUrl
	 * for <script src>, or <iframe src>
	 * Bypass security and trust the given value to be a safe resource URL,
	 * location that may be used to load executable code from, like <script src>, or * <iframe src>
	 */
	abstract getSafeResourceUrl(url: string, includeSpaPrefix?: boolean): any;
	abstract decodeUriComponent(url: string): string;
	abstract encodeUriComponent(url: string): string;
	abstract buildUrlSearchFromObject(obj: object, omitEmpty?: boolean): string;
	abstract getUrlRelativeToAssets(path: string, withInvalidateKey?: boolean): string;
	abstract locationBack(): void;
	abstract locationFroward(): void;

	abstract fileUrl(fileId: number | string, versionId?: number | string, options?: any): string;
	abstract withInvalidateCacheKey(url: string): string;
	abstract fileMeetingUrl(key: string);
	abstract fileEmailUrl(key: string);
}
