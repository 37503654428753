import { NgZone } from '@angular/core';
import { Effect, IAction, ofType } from '@valhalla/core';
import { merge } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { LayoutFacade } from '@spa/facade/layout/layout-facade.service';
import { SignalrProvider } from '@valhalla/data/signalr';
import { rxSetInterval, whenTabVisible } from '@valhalla/utils';
import * as actions from '../actions';
import { INavigationState } from '../state';
import { NavigationEffectDependencies } from './dependency';
import { CronClient } from '@spa/cron';

const { FacadeNavigationAction } = actions;

export const periodicUpdateFavoritesMenuEffect: Effect<
	IAction,
	IAction,
	INavigationState,
	NavigationEffectDependencies
> = (actions$, state$, deps) => {
	const logger = deps.loggerFactory.createLogger('periodicUpdateFavoritesMenuEffect');
	const turnOff$ = actions$.pipe(ofType(FacadeNavigationAction.turnOffPeriodicFavoritesMenuUpdate));
	const zone = deps.injector.get(NgZone);
	const layout = deps.injector.get(LayoutFacade);
	const signal = deps.injector.get(SignalrProvider);
	const cron = deps.injector.get(CronClient);

	return actions$.pipe(
		ofType(FacadeNavigationAction.turnOnPeriodicFavoritesMenuUpdate),
		switchMap(action => {
			const { updateIntervalMs = 5 * 60 * 1000, menus } = action.payload || {};
			logger.info(`run periodical update favorites menu every ${updateIntervalMs / 1000} seconds`);

			// timer only when tab is active and menu visible
			// const timerUpdate$ = rxSetInterval(updateIntervalMs).pipe(
			const timerUpdate$ = cron.from(cron.knownNames.favourites).pipe(
				startWith(0),
				whenTabVisible(document, 10000),
				filter(() => Boolean(!layout.state?.layout?.navbar?.folded))
				// take(0)
			);

			return zone.runOutsideAngular(() =>
				merge(signal.updateFavorites$, timerUpdate$).pipe(
					map(_ => actions.loadRequestActionCreator(menus)),
					takeUntil(turnOff$)
				)
			);
		})
	);
};
