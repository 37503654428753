import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'avatarLetters' })
export class AvatarLetterPipe implements PipeTransform {
	transform(value: string): string {
		const emojiRegex = /(?:[\uD800-\uDBFF][\uDC00-\uDFFF])/g;
		const unicodeRegex = new RegExp(/[a-d]/, 'u');

		let count = 0;
		let result = '';

		for (const char of value) {
			if (count >= 2) {
				break;
			}

			if (emojiRegex.test(char) || unicodeRegex.test(char)) {
				count++;
			} else {
				count++;
			}

			result += char;
		}

		return result;
	}
}
