import { Injectable } from '@angular/core';
import { DataHttpService } from '@spa/data/http';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EditMailboxService {
	constructor(readonly server: DataHttpService) {}

	readonly updateNotify$ = new Subject();

	updateNotify() {
		this.updateNotify$.next(0);
	}
}
