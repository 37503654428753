import { TokenService } from '@valhalla/core';
import { SpaCommandExecutorFactory } from '../spa-command.contract';

const createCommand: SpaCommandExecutorFactory = injector => {
	return function setAuthToken(token: string) {
		if (!token) {
			return;
		}
		const tokenService = injector.get(TokenService);
		tokenService.setToken(token);
	};
};

export default createCommand;
