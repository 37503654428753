import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { parseMessageFromError } from '@valhalla/utils';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { extConfigToken } from '../core.config';
import type { ICoreConfig } from '../core.config';
import { LoggerFactory } from '../diagnostics';
import { AuthService } from './abstract';

@Injectable()
export class Auth403Interceptor implements HttpInterceptor {
	constructor(
		readonly auth: AuthService,
		readonly logFactory: LoggerFactory,
		readonly router: Router,
		readonly zone: NgZone,
		@Inject(extConfigToken) readonly conf: ICoreConfig
	) {}

	readonly logger = this.logFactory.createLogger('403 Interceptor');
	// todo: вынести в отдельный сервис, в котором нужно будет регистрировать игнорируемые роуты
	readonly allowedUrls = [
		'/api/resource-facts',
		'/api/resource-facts/approve',
		'/api/resource-facts/unapprove',
		'/api/resource-facts/unlock',
		'/api/resource-facts/lock',
		'get-booking-reject-task?taskId=',
		'api/tasks',
	];

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap(
				() => {},
				async err => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 403) {
							if (this.conf.redirect403httpInterceptor) {
								this.logger.error(String(err.message));
								this.zone.runTask(() => {
									let isAllowedRoute = false;
									this.allowedUrls.forEach(url => {
										if (err.url.includes(url)) {
											isAllowedRoute = true;
										}
									});
									if (!isAllowedRoute) {
										this.router.navigate(['error/403'], {
											// skipLocationChange: true,
											// replaceUrl: true,
											state: {
												data: {
													message: parseMessageFromError(err),
													url: location.pathname + location.search,
												},
											},
										});
									}
								});
							}
						}
					}
				}
			)
		);
	}
}
