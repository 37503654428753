import {
	HTTP_INTERCEPTORS,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Injectable, Optional, Provider } from '@angular/core';
import { parseMessageFromError } from '@valhalla/utils';
import { EMPTY, Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class Response428InterceptorModalConfirm {
	confirmHandler: (message: string) => Observable<boolean>;
}

@Injectable()
export class Response428Interceptor implements HttpInterceptor {
	constructor(readonly confirm: Response428InterceptorModalConfirm) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError(e => {
				if (e instanceof HttpErrorResponse) {
					if (e.status === 428 && typeof this.confirm.confirmHandler === 'function') {
						return this.confirm.confirmHandler(parseMessageFromError(e)).pipe(
							switchMap(confirm => {
								if (confirm) {
									const confirmreq = req.clone({
										params: req.params.set('confirmed', true),
									});
									return next.handle(confirmreq);
								}
								// return of(e as any as HttpEvent<any>);
								return throwError(() => e);
							})
						);
					}
				}
				return throwError(() => e);
			})
		);
	}
}

export const Response428Provider: Provider = {
	provide: HTTP_INTERCEPTORS,
	multi: true,
	useClass: Response428Interceptor,
};
