import { IUserQuickAppsState } from './state.contract';

export const defaultState: IUserQuickAppsState = {
	apps: [
		{
			order: 0,
			appId: 19,
		},
		{
			order: 1,
			appId: 21,
		},
		{
			order: 2,
			appId: 20,
		},
		{
			order: 3,
			appId: 22,
		},
	],
};
