import { Params } from '@angular/router';
import { GlobalSpaContextService } from '@spa/api/global-spa-context.service';
import { spaCommand } from '@spa/api/spa-command';

import { GlobalEventHandler } from './type';

export const openTask: GlobalEventHandler = injector => {
	const eventName = 'spa:open-task';
	const spaContext = injector.get(GlobalSpaContextService);

	function onOpenTaskHandler(e: CustomEvent<{ taskId: number; queryParams?: Params }>) {
		if (spaContext.value.keys.includes('feed-widgets')) {
			spaCommand('feed-widgets:openTask', { taskId: e?.detail?.taskId });
		} else {
			const queryParams = e?.detail?.queryParams ?? {};
			spaCommand('openTask', { taskId: e?.detail?.taskId, ...queryParams });
		}
	}

	window.addEventListener(eventName, onOpenTaskHandler);

	return () => {
		// dispose
		window.removeEventListener(eventName, onOpenTaskHandler);
	};
};
