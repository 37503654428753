import { Injectable } from '@angular/core';
// FIXME: do it lazy
// import * as StackTrace from 'stacktrace-js';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class StackTraceService {
	async fromError(err): Promise<string> {
		if (err instanceof HttpErrorResponse) {
			return Promise.resolve(null);
		} else if (err instanceof Error) {
			return Promise.resolve(err.stack);
		}
		return Promise.resolve(String(err));
		// return err
		// 	? StackTrace.fromError(err)
		// 			.then(stackframes => stackframes.splice(0, 20).join('\n'))
		// 			.catch(_ => String(err))
		// 	: Promise.resolve(err);
	}

	async get(skip = 0): Promise<string> {
		return Promise.resolve(new Error().stack);
		// return StackTrace.get()
		// 	.then(stackframes =>
		// 		stackframes
		// 			.slice(skip)
		// 			.map(frame => frame.toString())
		// 			.join('\n')
		// 	)
		// 	.catch(err => String(err));
	}
}
