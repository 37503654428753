import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressCircleComponent } from './progress-circle/progress-circle.component';
import { ProgressLinearComponent } from './progress-linear/progress-linear.component';

@NgModule({
	imports: [CommonModule],
	declarations: [ProgressCircleComponent, ProgressLinearComponent],
	exports: [ProgressCircleComponent, ProgressLinearComponent],
})
export class ProgressModule {}
