import { Injectable, inject } from '@angular/core';
import { TabActiveStateService } from '@spa/common/services/tab-active-state.service';
import { MeetingNotificationsService } from '@spa/facade/meeting-notifications.service';
import { ICron, ICronRunContext } from '../cron';

@Injectable({ providedIn: 'root' })
export class MeetingEventsCronService implements ICron {
	readonly tabActive = inject(TabActiveStateService);
	readonly meetings = inject(MeetingNotificationsService);

	run(context: ICronRunContext) {
		if (this.tabActive.isMasterTab) {
			this.meetings.update(() => context.complete());
		} else {
			context.complete();
		}
	}
}
