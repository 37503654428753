import { IAction, ReducerBase, produce } from '@valhalla/core';

import * as actions from '../actions';
import { INavigationState } from '../state';
import { IGetFavoriteCountersDto } from '@valhalla/data/http';

export class UpdateFavoriteCountersReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.updateFavoriteCounters;

	reduce(state: Readonly<INavigationState>, { payload: counters }: IAction<IGetFavoriteCountersDto[]>) {
		return produce(state, ds => {
			counters.forEach(counter => {
				ds.favoriteCounters[counter.id] = counter;
			});
		});
	}
}
