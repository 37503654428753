import { Injectable, inject } from '@angular/core';
import { ICron, ICronRunContext } from '../cron';
import { DataHttpService } from '@spa/data/http';
import { take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChatTickersCronService implements ICron {
	readonly server = inject(DataHttpService);

	run(context: ICronRunContext) {
		this.server.chats.getCounters().pipe(take(1)).subscribe({
			next: context.complete,
			error: context.fail,
		});
	}
}
