import { ModuleWithProviders, NgModule } from '@angular/core';

import { MobileViewIDBStorage } from './abstract';
import { MobileViewIDBStorageImpl } from './mobile-view-idb-storage.provider';

@NgModule({})
export class MobileViewIDBStorageModule {
	static forRoot(): ModuleWithProviders<MobileViewIDBStorageModule> {
		return {
			ngModule: MobileViewIDBStorageModule,
			providers: [
				{
					provide: MobileViewIDBStorage,
					useClass: MobileViewIDBStorageImpl,
				},
			],
		};
	}
}
