import { IAppSettingAnonymousAuthProvider } from '@spa/data/http';
import { Observable } from 'rxjs';
import { ICaptchaResponse, ICredentials } from './auth.model';

export abstract class AuthService {
	abstract isAdmin$: Observable<boolean>;

	abstract isAdmin: boolean;

	abstract reincarnateFromUserId$: Observable<number>;
	/**Режим перевоплощения */
	abstract isReincarnateMode$: Observable<boolean>;

	abstract authenticated$: Observable<boolean>;

	abstract error$: Observable<any>;

	abstract loading$: Observable<boolean>;

	abstract isCaptchaRequired$: Observable<boolean>;

	abstract providers: IAppSettingAnonymousAuthProvider[];

	abstract authInfo$: Observable<{
		userId: number;
		isAdmin: boolean;
		groups: Array<{
			id: number;
			description: string;
			typeId: number;
			code: string;
			customerId: number;
			adSid: string;
			domain: string;
		}>;
	}>;

	abstract get authenticated(): boolean;

	abstract get currentPageIsEntry(): boolean;

	abstract get tokenPayload(): any;

	abstract get token(): string;

	abstract get userId(): number;

	abstract get userLogin(): string;

	abstract navigateToSignInPage(
		backUrl?: string,
		queryParams?: { [key: string]: string | number | boolean },
		replaceUrl?: boolean
	): Promise<boolean>;

	abstract navigateToSignUpPage(replaceUrl?: boolean): Promise<boolean>;

	abstract login(credentials: ICredentials): void;

	abstract logout(): void;

	abstract clearToken(): void;

	abstract checkAuthBySentRequest(): Observable<{ isAuth: boolean; token: string }>;

	abstract undoReincarnation(): Observable<any>;

	abstract impersonate(userId: string | number): Observable<any>;

	abstract getCaptcha(): Observable<ICaptchaResponse>;

	abstract requestRegistrationCode(params: IRequestRegistrationCode): Observable<IRequestRegistrationCodeResult>;

	abstract sendConfirmationCode(params: ISendConfirmationCode): Observable<ISendConfirmationCodeResult>;

	abstract register(params: IRegisterParams): Observable<IRegisterResult>;

	abstract setupToken(token: string): void;

	abstract getSecondFactor(secondFactorCookie: string, login: string, providerId: any): void;
}

export interface IRequestRegistrationCode {
	contact: string;
	senderType: 'Email' | 'Sms';
	captchaKey?: string;
	captchaSecret?: string;
}

export interface IRequestRegistrationCodeResult {
	factorKey: string;
}

export interface ISendConfirmationCode {
	factorKey: string;
	factorCode: number;
}

export interface ISendConfirmationCodeResult {
	result: 'LoggedIn' | 'RegistrationAllowed' | 'ChangePasswordRequired';
	registrationCode: string;
	fields: Array<{
		key: string;
		title: string;
		type: string;
		isRequired?: boolean;
		isVisible?: boolean;
	}>;
	apiKey?: string;
	isRegistrationRequired?: boolean;
	token?: string;
	accessToken?: string;
	refreshToken?: string;
}

export interface IRegisterParams extends Partial<IUserDataRegister> {
	registrationCode: string;
	[key: string]: any;
}

export interface IUserDataRegister {
	nick: string;
	lastName: string;
	firstName: string;
	middleName: string;
	phone: string;
	phone2: string;
	phone3: string;
	email: string;
	externalEmail: string;
	roomID: number;
	timeZoneId: string;
	displayName: string;
	englishDisplayName: string;
	externalDisplayName: string;
	password: string;
	languageID: number;
	cellPhone: string;
	homePhone: string;
	fax: string;
	skype: string;
	telegramId: string;
	telegramUserName: string;
	facebook: string;
	whatsapp: string;
	birthDate: string;
	country: string;
	city: string;
	disableMail: boolean;
	disableSms: boolean;
	gender: number;
	restrictCellPhoneView: boolean;
	sid: string;
	domainController: string;
	phoneAdditional: string;
	phone2Additional: string;
	phone3Additional: string;
	homePhoneAdditional: string;
	maidenName: string;
	autoReadCommentsInMTF: boolean;
	useNewTaskCard: boolean;
	authProviderId: number;
}

export interface IRegisterResult {
	token: string;
	accessToken: string;
	refreshToken: string;
}

export enum IRegisterSource {
	mspa = 'mspa',
	web = 'web',
}
