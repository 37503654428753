<mat-icon class="vh-sidebar-search--icon-search" svgIcon="vh-search-24"></mat-icon>
<input
	class="vh-sidebar-search-input"
	(input)="onSearchInput($event)"
	[vhAutoFocus]="true"
	#inputSearch
	type="text"
	[placeholder]="'common.search' | resx"
/>
<span *ngIf="clearBtnVisible$ | async" class="vh-sidebar-search--action-clear" (click)="onSearchClear()">
	<mat-icon svgIcon="vh-cross_v1-24"></mat-icon>
</span>
