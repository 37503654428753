import {
	Directive,
	ElementRef,
	Input,
	OnInit,
	Renderer2,
	ViewContainerRef,
	ComponentFactoryResolver,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxSkeletonLoaderComponent } from 'ngx-skeleton-loader';
import { ViewDestroyStreamService } from '../view-destroy/view-destroy-stream.service';

@Directive({
	selector: '[vhSkeletonImgLoader]',
	providers: [ViewDestroyStreamService],
})
export class SkeletonImgLoaderDirective implements OnInit {
	constructor(
		readonly destroy$: ViewDestroyStreamService,
		readonly elRef: ElementRef<HTMLImageElement>,
		readonly vcr: ViewContainerRef,
		readonly cfr: ComponentFactoryResolver,
		readonly render: Renderer2
	) {}

	@Input('vhSkeletonImgLoader')
	options: any;

	@Input()
	skeletonImgLoaderWidth: string;

	@Input()
	skeletonImgLoaderBorderRadius: string;

	get el() {
		return this.elRef.nativeElement;
	}

	get canApplyToHost() {
		return this.el?.tagName === 'IMG';
	}

	ngOnInit() {
		if (!this.canApplyToHost) {
			return;
		}

		this.setImgVisible(false);
		this.render.setStyle(this.el, 'transition', 'opacity 1s');

		const disposeSkeleton = this.createSkeleton();

		fromEvent(this.el, 'error')
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.setImgVisible(false);
				disposeSkeleton();
			});

		fromEvent(this.el, 'load')
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.render.setStyle(this.el, 'display', 'initial');
				disposeSkeleton();
				this.setImgVisible();
			});
	}

	setImgVisible(visible = true) {
		this.render.setStyle(this.el, 'opacity', visible ? 1 : 0);
	}

	createSkeleton() {
		const factory = this.cfr.resolveComponentFactory(NgxSkeletonLoaderComponent);
		const cmpRef = this.vcr.createComponent(factory);

		const defaultTheme = {
			height: '100%',
			width: this.skeletonImgLoaderWidth,
			'border-radius': this.skeletonImgLoaderBorderRadius,
		};
		const theme = Object.assign(defaultTheme, this.options?.theme || {});

		cmpRef.instance.theme = theme;
		cmpRef.instance.appearance = 'line';
		cmpRef.instance.count = 1;

		const elRef = cmpRef.injector.get(ElementRef, null);
		const el = elRef?.nativeElement as HTMLElement;
		const initialPosition = el.parentElement.style.position;
		const initialMinWidth = el.parentElement.style.minWidth;
		if (el) {
			this.render.setStyle(el.parentElement, 'min-width', this.skeletonImgLoaderWidth);
			this.render.setStyle(el.parentElement, 'position', 'relative');
			this.render.setStyle(el, 'position', 'absolute');
			this.render.setStyle(el, 'top', '-4px');
			this.render.setStyle(el, 'bottom', 0);
			this.render.setStyle(el, 'left', 0);
			this.render.setStyle(el, 'right', 0);
		}

		return () => {
			if (el.parentElement) {
				this.render.setStyle(el.parentElement, 'position', initialPosition);
				this.render.setStyle(el.parentElement, 'min-width', initialMinWidth);
				cmpRef.destroy();
			}
		};
	}
}
