import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, from, merge, Observable, of, Subject } from 'rxjs';
import {
	catchError,
	concatMap,
	debounceTime,
	distinctUntilChanged,
	exhaustMap,
	map,
	mergeMap,
	shareReplay,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { base64Encode, resolveLinkUrl } from '@valhalla/utils';

import { CookieService, SystemCookies } from '../cookie';
import { extConfigToken } from '../core.config';
import type { ICoreConfig } from '../core.config';
import { AbstractLogger, LoggerFactory } from '../diagnostics';
import { UrlProvider } from '../url';
import {
	AuthService,
	IRegisterParams,
	IRegisterResult,
	IRegisterSource,
	IRequestRegistrationCode,
	IRequestRegistrationCodeResult,
	ISendConfirmationCode,
	ISendConfirmationCodeResult,
} from './abstract';
import { ICaptchaResponse, ICredentials } from './auth.model';
import { TokenService } from './token';
import { IApiResponse, IAppSettingAnonymousAuthProvider, isExternalAuthProvider } from '@valhalla/data/http';
import { PlatformDetectorProvider } from '../platform';

@Injectable()
export class AuthServiceImpl implements AuthService {
	constructor(
		private readonly _router: Router,
		private readonly _loggerService: LoggerFactory,
		private readonly _urlBuilder: UrlProvider,
		private readonly _http: HttpClient,
		private readonly _token: TokenService,
		readonly cookies: CookieService,
		@Inject(extConfigToken) readonly conf: ICoreConfig,
		readonly platform: PlatformDetectorProvider
	) {
		this._logger = this._loggerService.createLogger('AuthService');
		this._token.clearToken();
		this.connectStreams();
	}

	private readonly _authRequest$: Subject<ICredentials | string> = new Subject();
	private readonly _error$: Subject<string> = new Subject();
	private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private readonly _destroy$: Subject<any> = new Subject();
	private readonly _logger: AbstractLogger;
	private readonly _authenticated$ = new BehaviorSubject(false);
	private _authenticated = false;
	readonly isCaptchaRequired$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	readonly authenticated$ = this._authenticated$.pipe(
		distinctUntilChanged(),
		mergeMap(isAuthInitial => {
			const checkAuthBySentRequest$ = this.checkAuthBySentRequest().pipe(
				tap(({ isAuth, token }) => {
					if (isAuth && Boolean(token)) {
						this._token.setToken(token);
					} else {
						this._token.clearToken();
					}
				}),
				map(({ isAuth, token }) => {
					return isAuth && Boolean(token);
				})
			);
			return isAuthInitial ? of(true) : checkAuthBySentRequest$;
		}),
		tap(isAuth => {
			this._authenticated = isAuth;
			window['SessionUserId'] = this.userId;
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);
	readonly error$ = this._error$.asObservable();
	readonly loading$ = this._loading$.asObservable();
	readonly isAdmin$ = this.authenticated$.pipe(
		map(isAuth => {
			if (!isAuth) {
				return false;
			}
			return this.isAdmin;
		})
	);
	get isAdmin() {
		return this.getRoles().includes('admin');
	}

	readonly authInfo$ = this.getAuthInfo().pipe(shareReplay(1));

	readonly reincarnateFromUserId$: Observable<number> = merge(
		this.getSessionUserInfo(),
		this._token.updated$.pipe(debounceTime(500))
	).pipe(map(_ => this.tokenPayload.OldUserId && Number(this.tokenPayload.OldUserId)));

	/**Режим перевоплощения */
	readonly isReincarnateMode$: Observable<boolean> = this.reincarnateFromUserId$.pipe(map(userId => Boolean(userId)));

	providers: IAppSettingAnonymousAuthProvider[] = [];

	protected getRoles() {
		const accessLevels = String(this.tokenPayload && this.tokenPayload.AccessLevels);
		return accessLevels.split(',').map(al => al.toLowerCase());
	}

	protected connectStreams() {
		this._authRequest$
			.pipe(
				takeUntil(this._destroy$),
				exhaustMap(credentials => this.checkUserCredentials(credentials))
			)
			.subscribe(this._authenticated$);
	}

	protected checkUserCredentials(data: ICredentials | string): Observable<boolean> {
		let res$: Observable<any>;
		if (typeof data === 'string') {
			res$ = of(data);
		} else {
			const { login, password, isPersistent, providerId, captchaKey, captchaSecret } = data;
			this._loading$.next(true);
			const url = this._urlBuilder.getApiUrl(`auth/token-v2`);
			this._logger.info('🚀 loading token...');

			const paramsArr = Object.entries({
				login: login && base64Encode(login),
				password: password && base64Encode(password),
				isBase64: true,
				isPersistent: String(!!isPersistent),
				providerId: providerId && String(providerId),
				captchaKey,
				captchaSecret,
			}).filter(([key, val]) => val);
			const paramsNotEmpty = paramsArr.reduce((acc, [key, val]) => {
				acc[key] = val;
				return acc;
			}, {});
			res$ = this._http.post<string>(url, paramsNotEmpty);
		}
		return res$.pipe(
			map(result => {
				if (typeof result === 'string') {
					return result;
				}

				return result.data.token;
			}),
			map(token => {
				const tokenDecoded = this._token.decodeToken(token);
				const accessLevels = tokenDecoded?.AccessLevels;

				if (accessLevels === 'Temp') {
					this._router.navigate(['/entry/password-change'], { state: { token } });
					return false;
				}

				this._logger.info('🍻 get token success');
				this._token.setToken(token);
				return true;
			}),
			concatMap(success => {
				if (!success) {
					return of(success);
				}
				const userId = this._token.payload.primarysid;
				const urlUserSettings = this._urlBuilder.getApiUrl(`user/settings`);
				return this._http.get<any>(urlUserSettings, { params: { userId: `${userId}` } }).pipe(
					map(({ data }) => data?.forceChangePassword),
					mergeMap(forceChangePassword => {
						if (!forceChangePassword) {
							return of(success);
						}
						return from(
							this._router.navigate(['/entry/password-change'], { state: { token: this._token.getToken() } })
						).pipe(map(() => false));
					})
				);
			}),
			catchError(err => {
				this._logger.warn('checkUserCredentials', err);
				this._error$.next(err);

				const isCaptchaRequired = err?.headers.get('captcha-required');

				if (isCaptchaRequired === 'true') {
					this.isCaptchaRequired$.next(true);
				} else {
					this.isCaptchaRequired$.next(false);
				}

				return of(false);
			}),
			tap(_ => this._loading$.next(false))
		);
	}

	get authenticated() {
		return this._authenticated || this._authenticated$.value;
	}

	get currentPageIsEntry() {
		return ['/entry/', '/entry'].some(seg => this._router.url.indexOf(seg) !== -1);
	}

	get tokenPayload() {
		return this._token.payload;
	}

	get token() {
		return this._token.getToken();
	}

	get userId() {
		const tokenPayload = this.tokenPayload;
		const sid = Number(tokenPayload?.primarysid);
		return isNaN(sid) ? tokenPayload?.primarysid : sid;
	}

	get userLogin() {
		const tokenPayload = this.tokenPayload;
		return tokenPayload && tokenPayload.unique_name;
	}

	navigateToSignInPage(
		backUrl?: string,
		queryParams?: { [key: string]: string | number | boolean },
		replaceUrl = false
	): Promise<boolean> {
		let fromUrl: string = backUrl || this._urlBuilder.currentUrlExceptBaseHref();
		if (fromUrl.indexOf('entry/') > -1) {
			fromUrl = '/';
		}
		if (!this.conf.useRouter) {
			return Promise.resolve(true);
		}
		this._logger.info(`navigateToSignInPage, fromUrl='${fromUrl}'`);
		const navPromise = this._router.navigate(['/entry/signin'], {
			queryParams: { fromUrl, ...queryParams },
			replaceUrl: replaceUrl,
		});
		return navPromise;
	}

	navigateToSignUpPage(replaceUrl = false) {
		if (!this.conf.useRouter) {
			return Promise.resolve(true);
		}
		return this._router.navigate(['/entry/signup'], { replaceUrl: replaceUrl });
	}

	login(credentials: ICredentials) {
		this._authRequest$.next(credentials);
	}

	logout() {
		const provider = this.providers.find(p => p.Id == this.tokenPayload.ProviderId);
		if (isExternalAuthProvider(provider)) {
			const logoutPath = provider?.Settings?.LogoutPath;
			if (!logoutPath) {
				return console.warn('External LogoutPath not found');
			}
			const url = resolveLinkUrl(logoutPath);
			window.location.href = url;
		} else {
			const url = this._urlBuilder.getApiUrl(`logoff`, 'v1.0' as any);
			this._http.post(url, null).subscribe(_ => {
				this.isCaptchaRequired$.next(false);
				this._token.clearToken();
				location.reload();
			});
		}
	}

	setupToken(token: string) {
		this._authRequest$.next(token);
	}

	clearToken(): void {
		this._token.clearToken();
		this._authenticated$.next(false);
		this._authenticated = false;
	}

	checkAuthBySentRequest(): Observable<{ isAuth: boolean; token: string }> {
		const url = this._urlBuilder.getApiUrl(`info?_v=${Date.now()}`, 'v1.0' as any);
		return this._http.get(url, { headers: { 'ignore-license-busy': 'true' } }).pipe(
			map(_ => ({
				isAuth: true,
				token: this._token.getToken() || this.cookies.get(SystemCookies.auth1Forma),
			})),
			catchError(_ => {
				this._token.clearToken();
				return of({
					isAuth: false,
					token: null,
				});
			})
		);
	}

	// Надо будет дописать провайдер, пока так.
	getSecondFactor(secondFactorCookie: string, login: string, providerId: any) {
		switch (secondFactorCookie) {
			case 'Multifactor':
				// eslint-disable-next-line no-case-declarations
				const url = this._urlBuilder.getApiUrl(`multifactor/login/${Number(providerId)}?login=${login}`);
				this._http
					.get(url)
					.pipe(
						take(1),
						catchError(err => {
							console.log(err);
							return EMPTY;
						})
					)
					.subscribe((urlR: any) => {
						urlR = resolveLinkUrl(urlR.url || urlR);
						window.location.href = urlR;
					});
				break;
			default:
				'Not implemented';
				break;
		}
	}

	getAuthInfo() {
		const url = this._urlBuilder.getApiUrl(`auth/info`);
		return this._http.get<any>(url).pipe(map(res => res.data));
	}

	getSessionUserInfo(): Observable<number> {
		return this.authInfo$.pipe(map(res => res?.data?.userId));
	}

	undoReincarnation(): Observable<any> {
		const url = this._urlBuilder.getApiUrl('auth/undotransformation');
		return this._http.post<any>(url, null);
	}

	impersonate(userId: string | number): Observable<any> {
		const url = this._urlBuilder.getApiUrl(`auth/${userId}/impersonate`);
		return this._http.post<any>(url, null);
	}

	getCaptcha(): Observable<ICaptchaResponse> {
		const url = this._urlBuilder.getApiUrl(`captcha`);
		return this._http.get<IApiResponse<ICaptchaResponse>>(url).pipe(map(result => result.data));
	}

	requestRegistrationCode(params: IRequestRegistrationCode): Observable<IRequestRegistrationCodeResult> {
		const url = this._urlBuilder.getApiUrl('auth/code');
		return this._http.post<IApiResponse<IRequestRegistrationCodeResult>>(url, params).pipe(map(result => result.data));
	}

	sendConfirmationCode(params: ISendConfirmationCode): Observable<ISendConfirmationCodeResult> {
		const url = this._urlBuilder.getApiUrl('auth/token/code');
		return this._http.post<IApiResponse<ISendConfirmationCodeResult>>(url, params).pipe(map(result => result.data));
	}

	register(params: IRegisterParams): Observable<IRegisterResult> {
		const registerMode =
			this.platform.isMobile() && localStorage.getItem('mobileMode') ? IRegisterSource.mspa : IRegisterSource.web;
		const language = localStorage.getItem('currentLanguage');
		const url = this._urlBuilder.getApiUrl(`user?source=${registerMode}&language=${language}`);
		return this._http.post<IApiResponse<IRegisterResult>>(url, params).pipe(map(result => result.data));
	}
}
