import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { commonAnimations } from '@spa/common/animations';

export const selector = 'vh-fv-expand-list';

@Component({
	selector: selector,
	templateUrl: 'favorite-expand-list.component.html',
	styleUrls: ['favorite-expand-list.component.scss'],
	animations: commonAnimations,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteExpandListComponent implements AfterViewInit {
	constructor(readonly elRef: ElementRef<HTMLElement>) {}

	@HostBinding('class.vh-fv-expand-list')
	hostClassSelector = true;

	@Input()
	label: string;

	@Input()
	data: any;

	@Input()
	get expanded() {
		return this.expanded$.value;
	}
	set expanded(value: boolean) {
		this.expanded$.next(value);
	}

	@Output()
	expandCollapseEvent = new EventEmitter();
	readonly expanded$ = new BehaviorSubject(false);

	ngAfterViewInit() {
		const prevSiblingTag = this.elRef.nativeElement?.previousElementSibling?.tagName;
		const isPrevList = prevSiblingTag === selector.toUpperCase();

		if (isPrevList) {
			//this.elRef.nativeElement.classList.add('mt-12');
		}
	}
}
