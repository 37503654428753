import { isString, isObject } from './is';

export function searchInObj(itemObj, searchText): boolean {
	for (const prop in itemObj) {
		if (!itemObj.hasOwnProperty(prop)) {
			continue;
		}

		const value = itemObj[prop];

		if (typeof value === 'string') {
			if (searchInString(value, searchText)) {
				return true;
			}
		} else if (Array.isArray(value)) {
			if (searchInArray(value, searchText)) {
				return true;
			}
		}

		if (typeof value === 'object') {
			if (searchInObj(value, searchText)) {
				return true;
			}
		}
	}
}

export function searchInArray(arr: Array<string | object>, searchText: string): boolean {
	for (const value of arr) {
		if (isString(value)) {
			if (searchInString(value, searchText)) {
				return true;
			}
		}

		if (isObject(value)) {
			if (searchInObj(value, searchText)) {
				return true;
			}
		}
	}
	return false;
}

export function searchInString(value: string, searchText: string): boolean {
	return (
		String(value)
			.toLowerCase()
			.indexOf(String(searchText).toLowerCase()) !== -1
	);
}

export function searchWordsInTexts(texts: string[], words: string[]) {
	return words.filter(Boolean).some(word => searchInArray(texts, word));
}
