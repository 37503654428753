import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { DuplicateImportModuleError } from '../errors';
import { UniversalLoaderProvider } from './abstract';
import { UniversalLoaderProviderImpl } from './universal-loader.service';

@NgModule()
export class UniversalLoaderModule {
	constructor(
		@Optional()
		@SkipSelf()
		loaderModule: UniversalLoaderModule
	) {
		if (loaderModule) throw new DuplicateImportModuleError('UniversalLoaderModule');
	}

	static forRoot(): ModuleWithProviders<UniversalLoaderModule> {
		return {
			ngModule: UniversalLoaderModule,
			providers: [
				{
					provide: UniversalLoaderProvider,
					useClass: UniversalLoaderProviderImpl,
				},
			],
		};
	}
}
