import {
	HTTP_INTERCEPTORS,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable, Provider, inject, isDevMode } from '@angular/core';
import { Observable, merge, throwError, timeout } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TimeoutHttpInterceptorSettings {
	timeoutSeconds: number;
	requestTimeoutExcludeUrls: string[];
}

export class TimeoutHttpError extends Error {
	constructor(readonly url?: string, readonly timeout?: number) {
		super(`Timeout request for ${url || ''} more than ${timeout}ms`);
	}
	readonly name = 'TimeoutHttpError';
}

@Injectable()
export class TimeoutHttpInterceptor implements HttpInterceptor {
	readonly settings = inject(TimeoutHttpInterceptorSettings);

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const excludeUrls = (this.settings.requestTimeoutExcludeUrls || ['/files/']).map(i => i.toLowerCase());
		const reqUrl = req.url.toLowerCase();
		const isExclude = excludeUrls.some(ex => reqUrl.includes(ex));

		if (!isDevMode() && this.settings.timeoutSeconds > 0 && !isExclude) {
			const limit = this.settings.timeoutSeconds * 1000;
			return next.handle(req).pipe(
				timeout({
					with: () => throwError(() => new TimeoutHttpError(req.url, limit)),
					each: limit,
				})
			);
		}
		return next.handle(req);
	}
}

export const TimeoutHttpInterceptorProvider: Provider = {
	provide: HTTP_INTERCEPTORS,
	multi: true,
	useClass: TimeoutHttpInterceptor,
};
