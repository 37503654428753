import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector, LOCALE_ID, Provider, inject } from '@angular/core';
import { CultureService } from '@spa/localization';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageHttpInterceptor implements HttpInterceptor {
	readonly injector = inject(Injector);
	culture: CultureService;

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.url.includes('/ui.json')) {
			return next.handle(req);
		}

		if (!this.culture) {
			this.culture = this.injector.get(CultureService);
		}
		const locale = this.culture.activeCulture || this.injector.get(LOCALE_ID);
		if (locale) {
			const reqWithLang = req.clone({
				headers: req.headers.set('language', locale || ''),
			});
			return next.handle(reqWithLang);
		}
		return next.handle(req);
	}
}

export const LanguageHttpInterceptorProvider: Provider = {
	provide: HTTP_INTERCEPTORS,
	multi: true,
	useClass: LanguageHttpInterceptor,
};
