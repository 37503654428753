import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
	selector: 'vh-layout-add-folder-modal',
	templateUrl: './add-folder-modal.component.html',
	styleUrls: ['./add-folder-modal.component.scss'],
})
export class AddFolderModalComponent implements OnInit, AfterViewInit {
	constructor(readonly dialogRef: MatDialogRef<AddFolderModalComponent>) {}

	@ViewChild('focusedElement', { read: ElementRef })
	focusedElement: ElementRef;

	public folderName: UntypedFormControl;

	ngOnInit(): void {
		this.folderName = new UntypedFormControl();
	}

	ngAfterViewInit() {
		this.focusedElement.nativeElement.focus();
	}

	close() {
		this.dialogRef.close();
	}

	closeAndReturnFolderName() {
		this.dialogRef.close({
			folderName: this.folderName.value,
		});
	}
}
