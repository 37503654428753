import { inject, Inject, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { RootRegistrationService } from '@spa/api/root-registration-service';
import { ApplicationService } from '@spa/application';
import { IsNoFrameModeService } from '@spa/common/services/is-noframe-mode.service';
import { PlatformHosterService, UrlProvider } from '@spa/core';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { forkJoin, from, map, Observable, take } from 'rxjs';
import { IsDev } from './is-dev';

@Injectable({ providedIn: 'root' })
export class OpenPagesService {
	constructor(
		readonly router: Router,
		readonly urlHelper: UrlProvider,
		@Inject(IsDev) readonly isDev: boolean,
		readonly isNoFrame: IsNoFrameModeService,
		readonly rootReg: RootRegistrationService,
		readonly hoster: PlatformHosterService,
		readonly injector: Injector
	) {}

	protected zone = inject(NgZone);

	get isProd() {
		return !this.isDev;
	}

	get modals(): ModalWindowsService {
		return this.rootReg.get('ModalWindowsService');
	}

	get app() {
		return this.injector.get(ApplicationService);
	}

	openProfile(opt: OpenProfileOptions) {
		this.optDefaults(opt);

		if (opt.newTab) {
			const url = this.profileAbsoluteUrl(opt);
			return window.open(url, '_blank');
		} else if (opt.modal) {
			opt.noframe = true;
			const url = this.profileAbsoluteUrl(opt);
			this.modals.openIFrame({
				url,
			});
		} else {
			const url = this.getOpenProfileUrl(opt);
			return this.zone.run(() => this.router.navigateByUrl(url));
		}
	}

	openSpace(opt: OpenSpaceOptions) {
		this.optDefaults(opt);

		if (opt.newTab) {
			const url = this.spaceAbsoluteUrl(opt);
			return window.open(url, '_blank');
		} else if (opt.modal) {
			const url = this.spaceAbsoluteUrl(opt);
			return this.modals.openIFrame({
				url,
				openInNewTabUrl: this.spaceAbsoluteUrl({
					...opt,
					short: false,
					noframe: false,
					modal: false,
				}),
			});
		} else {
			const url = this.getOpenSpaceUrl(opt);
			return this.zone.run(() => this.router.navigateByUrl(url));
		}
	}

	openEmail(opt: OpenEmailOptions) {
		this.optDefaults(opt);
		if (opt.newTab) {
			const url = this.emailAbsoluteUrl(opt);
			return window.open(url, '_blank');
		} else {
			const url = this.getOpenEmailUrl(opt);
			return this.zone.run(() => this.router.navigateByUrl(url));
		}
	}

	async openTask(opt: OpenTaskOptions, returnModalRef = false) {
		this.optDefaults(opt);
		if (opt.newTab) {
			if (this.hoster.isOneTabMode) {
				this.modals.closeAll();
				const url = this.getOpenTaskUrl(opt);
				if (opt.focusTab || this.hoster.isOneTabMode) {
					this.hoster.focusTab();
				}
				return this.zone.run(() => this.router.navigateByUrl(url));
			} else {
				const url = this.taskAbsoluteUrl(opt);
				return window.open(url, '_blank');
			}
		} else if (opt.modal) {
			const ref = await this.modals?.openTask({ taskId: opt.taskId, props: opt.props });
			if (opt.focusTab || this.hoster.isOneTabMode) {
				this.hoster.focusTab();
			}
			if (opt.closeWhen) {
				if (returnModalRef) {
					ref.closeWhen(opt.closeWhen);
					return ref;
				}
				return ref.closeWhen(opt.closeWhen);
			} else {
				if (returnModalRef) {
					return ref;
				}
				return ref.afterClosed();
			}
		} else {
			const url = this.getOpenTaskUrl(opt);
			if (opt.focusTab || this.hoster.isOneTabMode) {
				this.hoster.focusTab();
			}
			return this.zone.run(() => this.router.navigateByUrl(url, { state: opt.routeState }));
		}
	}

	openAdminSmartGrid(opt: OpenAdminSmartGridOptions) {
		this.optDefaults(opt);
		if (opt.newTab) {
			const url = this.adminSmartGridAbsoluteUrl(opt);
			return window.open(url, '_blank');
		} else {
			const url = this.getAdminSmartGridUrl(opt);
			return this.zone.run(() => this.router.navigateByUrl(url));
		}
	}

	openAdminSmartExpressions(opt: OpenOptions) {
		this.optDefaults(opt);
		if (opt.newTab) {
			const url = this.adminSmartExpressionsAbsoluteUrl(opt);
			return window.open(url, '_blank');
		} else {
			const url = this.adminSmartExpressionsAbsoluteUrl(opt);
			return this.zone.run(() => this.router.navigateByUrl(url));
		}
	}

	openAdminSmartExpressionConstructor(opt: OpenOptions) {
		this.optDefaults(opt);
		if (opt.newTab) {
			const url = this.adminSmartExpressionConstructorAbsoluteUrl(opt);
			return window.open(url, '_blank');
		} else {
			const url = this.adminSmartExpressionConstructorAbsoluteUrl(opt);
			return this.zone.run(() => this.router.navigateByUrl(url));
		}
	}

	openAdminSmartLuaScript(opt: OpenOptions) {
		this.optDefaults(opt);
		if (opt.newTab) {
			const url = this.adminSmartLuaScriptAbsoluteUrl(opt);
			return window.open(url, '_blank');
		} else {
			const url = this.adminSmartLuaScriptAbsoluteUrl(opt);
			return this.zone.run(() => this.router.navigateByUrl(url));
		}
	}

	emailAbsoluteUrl(opt: OpenEmailOptions) {
		this.optDefaults(opt);
		let url = this.getOpenEmailUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	taskAbsoluteUrl(opt: OpenTaskOptions) {
		this.optDefaults(opt);
		let url = this.getOpenTaskUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	profileAbsoluteUrl(opt: OpenProfileOptions) {
		this.optDefaults(opt);
		let url = this.getOpenProfileUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	signaturesJournalAbsoluteUrl(opt: OpenSignaturesJournalOptions) {
		this.optDefaults(opt);
		let url = this.getSignaturesJournalUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	ganttSubtasksAbsoluteUrl(opt: OpenGanttSubtasksOptions) {
		this.optDefaults(opt);
		let url = this.getGanttSubtasksUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	subtasksAbsoluteUrl(opt: OpenSubtasksOptions) {
		this.optDefaults(opt);
		let url = this.getSubtasksUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	spaceAbsoluteUrl(opt: OpenSpaceOptions) {
		this.optDefaults(opt);
		let url = this.getOpenSpaceUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	openSubcatGrid(opt: OpenSubcatOptions) {
		this.optDefaults(opt);
		if (opt.newTab) {
			const url = this.subcatGridAbsoluteUrl(opt);
			return window.open(url, '_blank');
		} else {
			const url = this.getOpenSubcatUrl(opt);
			return this.zone.run(() => this.router.navigateByUrl(url));
		}
	}

	subcatGridAbsoluteUrl(opt: OpenSubcatOptions) {
		this.optDefaults(opt);
		let url = this.getOpenSubcatUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	adminSmartGridAbsoluteUrl(opt: OpenAdminSmartGridOptions) {
		this.optDefaults(opt);
		let url = this.getAdminSmartGridUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	adminSmartExpressionsAbsoluteUrl(opt: OpenOptions) {
		this.optDefaults(opt);
		let url = this.getAdminSmartExpressionsUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	adminSmartExpressionConstructorAbsoluteUrl(opt: OpenOptions) {
		this.optDefaults(opt);
		let url = this.getAdminSmartExpressionConstructorUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	adminSmartLuaScriptAbsoluteUrl(opt: OpenOptions) {
		this.optDefaults(opt);
		let url = this.getAdminSmartLuaScriptUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	openSignaturesJournal(opt: OpenSignaturesJournalOptions) {
		this.optDefaults(opt);
		if (opt.newTab) {
			const url = this.signaturesJournalAbsoluteUrl(opt);
			return window.open(url, '_blank');
		} else {
			const url = this.getSignaturesJournalUrl(opt);
			return this.zone.run(() => this.router.navigateByUrl(url));
		}
	}

	openGanttSubtasks(opt: OpenGanttSubtasksOptions) {
		this.optDefaults(opt);
		if (opt.newTab) {
			const url = this.ganttSubtasksAbsoluteUrl(opt);
			return window.open(url, '_blank');
		} else {
			const url = this.getGanttSubtasksUrl(opt);
			return this.zone.run(() => this.router.navigateByUrl(url));
		}
	}

	openSubtasks(opt: OpenSubtasksOptions) {
		this.optDefaults(opt);
		if (opt.newTab) {
			const url = this.subtasksAbsoluteUrl(opt);
			return window.open(url, '_blank');
		} else {
			const url = this.getSubtasksUrl(opt);
			return this.zone.run(() => this.router.navigateByUrl(url));
		}
	}

	openVKS(opt: OpenVKSOptions) {
		if (!opt.roomName) {
			return console.error(`VKS room name is not specified`);
		}
		const commands = [this.isNoFrame.value ? '/noframe' : '/', 'conference'];
		const queryParams = {
			room: opt.roomName,
			inviteUserIds: String(opt.inviteUserIds || []) || undefined,
			uuid: opt?.uuid,
		};
		if (opt.focusTab || this.hoster.isOneTabMode) {
			this.hoster.focusTab();
		}
		if (opt.newTab && !this.hoster.isOneTabMode) {
			const url = this.router.serializeUrl(this.router.createUrlTree(['/conference'], { queryParams }));
			return window.open(url, '_blank');
		}
		this.modals?.closeAll();
		return this.router.navigate(commands, { queryParams });
	}

	openChat(opt: OpenChatOptions) {
		const mode = opt.mode || 'chat';
		const commands = ['/chat'];
		const queryParams = {
			mode: mode,
			taskId: opt.taskId,
			with: undefined,
			...(opt.queryParams || {}),
		};
		if (opt.with?.length > 0) {
			commands.push('new');
			queryParams.with = opt.with.join(',');
			queryParams.taskId = undefined;
		}
		if (opt.focusTab || this.hoster.isOneTabMode) {
			this.hoster.focusTab();
		}
		if (this.hoster.isOneTabMode) {
			return this.router.navigate(commands, { queryParams });
		}
		if (opt.newTab) {
			const url = this.router.serializeUrl(this.router.createUrlTree(commands, { queryParams }));
			return window.open(url, '_blank');
		}
		return this.router.navigate(commands, { queryParams });
	}

	openNewTaskContentOverlay(opt: IOpenNewTaskContentOverlay) {
		const { subcatId, onCreated, onCancel, onFinish, openTaskAfterCreate = false, onComponentCreate } = opt;
		forkJoin({
			component: from(
				import('@spa/components/new-task/ui/new-task-card/new-task-card/new-task-card.component').then(
					m => m.NewTaskCardComponent
				)
			),
			title: this.app.category.selectShortSubcatConfig(subcatId).pipe(map(data => data.name)),
			subcatInfo: this.app.category.selectNewTaskTemplate({ subcatId, action: 'NewTask' }),
			subcatFullInfo: this.app.category.selectSubcatConfig(subcatId).pipe(map(d => d.subcategory)),
		})
			.pipe(take(1))
			.subscribe(({ component, title, subcatInfo, subcatFullInfo }) => {
				this.app.contentOverlay.push({
					component,
					onCreate: onComponentCreate,
					bindings: {
						subcatId,
						subcatData: {
							title,
							subcatInfo,
							subcatFullInfo,
						},
						needTaskRedirectAndClose: false,
						needRedirectAfterCreated: false,
						needCloseBtn: false,
						onlyEmitAfterCreate: true,
						taskCancelHandler: () => {
							this.app.contentOverlay.clear();
							onCancel?.();
						},
						taskCreatedHandler: result => {
							onCreated?.(result);
							if (!openTaskAfterCreate) {
								return this.app.contentOverlay.clear();
							}
							forkJoin({
								component: from(
									import('@spa/components/task/ui/task-card/task-card/task-card.component').then(
										m => m.TaskCardComponent
									)
								),
							})
								.pipe(take(1))
								.subscribe(({ component }) => {
									this.app.contentOverlay.replace({
										component,
										bindings: {
											taskId: result.taskId,
											showCollapseButtons: false,
											showToolbarCloseBtn: true,
											closeHandler: e => {
												this.app.contentOverlay.clear();
												onFinish?.();
											},
										},
									});
								});
						},
					},
				});
			});
	}

	openProject(opt: OpenProjectOptions, returnModalRef = false) {
		this.optDefaults(opt);
		if (opt.newTab) {
			if (this.hoster.isOneTabMode) {
				this.modals.closeAll();
				const url = this.getOpenProjectUrl(opt);
				return this.zone.run(() => this.router.navigateByUrl(url));
			} else {
				const url = this.projectAbsoluteUrl(opt);
				return window.open(url, '_blank');
			}
		} else if (opt.modal) {
			const ref = this.modals?.openProject({ projectId: opt.projectId });
			if (opt.closeWhen) {
				if (returnModalRef) {
					ref.closeWhen(opt.closeWhen);
					return ref;
				}
				return ref.closeWhen(opt.closeWhen);
			} else {
				if (returnModalRef) {
					return ref;
				}
				return ref.afterClosed();
			}
		} else {
			const url = this.getOpenProjectUrl(opt);
			return this.zone.run(() => this.router.navigateByUrl(url, { state: opt.routeState }));
		}
	}

	projectAbsoluteUrl(opt: OpenProjectOptions) {
		this.optDefaults(opt);
		let url = this.getOpenProjectUrl(opt);
		if (this.isProd) {
			url = this.urlHelper.getUrl(url, true);
		}
		return url;
	}

	protected getOpenProjectUrl(opt: OpenProjectOptions): string {
		const urlTree = this.router.createUrlTree([opt.noframe ? '/noframe/project' : '/project', opt.projectId], {
			queryParams: {
				inModal: opt.modal || undefined,
				...(opt.queryParams || {}),
			},
		});
		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected getOpenEmailUrl(opt: OpenEmailOptions): string {
		const urlTree = this.router.createUrlTree(
			[opt.noframe ? '/noframe/email-client' : '/email-client', opt.mailBoxId, opt.emailId],
			{
				queryParams: {
					folderPath: opt.folderName,
					inModal: opt.modal || undefined,
					...(opt.queryParams || {}),
				},
			}
		);
		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected getOpenTaskUrl(opt: OpenTaskOptions): string {
		const urlTree = this.router.createUrlTree([opt.noframe ? '/noframe/tasks' : '/tasks', opt.taskId], {
			queryParams: {
				inModal: opt.modal || undefined,
				...(opt.queryParams || {}),
			},
		});
		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected getOpenProfileUrl(opt: OpenProfileOptions): string {
		const urlTree = this.router.createUrlTree([opt.noframe ? '/noframe/user/profile' : '/user/profile', opt.userId], {
			queryParams: { inModal: opt.modal || undefined },
		});
		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected getOpenSpaceUrl(opt: OpenSpaceOptions): string {
		let urlTree = this.router.createUrlTree([opt.noframe ? '/noframe/spaces' : '/spaces', opt.spaceId], {
			queryParams: { inModal: opt.modal || undefined },
		});

		if (opt?.subcatId) {
			urlTree = this.router.createUrlTree(
				[opt.noframe ? '/noframe/spaces/subcat' : '/spaces/subcat', opt?.subcatId, opt.short ? 'short' : ''],
				{
					queryParams: { pageId: opt.spaceId, inModal: opt.modal || undefined },
				}
			);
		}

		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected getOpenSubcatUrl(opt: OpenSubcatOptions): string {
		const subcatOpenTypeUrlPart = SubcatOpenTypes[opt.subcatOpenType] || SubcatOpenTypes.grid;
		const urlTree = this.router.createUrlTree(
			[
				opt.noframe
					? `/noframe/tasks/subcat/${opt.subcatId}/${subcatOpenTypeUrlPart}`
					: `/tasks/subcat/${opt.subcatId}/${subcatOpenTypeUrlPart}`,
			],
			{
				queryParams: { inModal: opt.modal, taskId: opt.taskId },
			}
		);
		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected getSignaturesJournalUrl(opt: OpenSignaturesJournalOptions): string {
		const urlTree = this.router.createUrlTree(
			[opt.noframe ? `/noframe/tasks/${opt.taskId}/signaturehistory` : `/tasks/${opt.taskId}/signaturehistory`],
			{
				queryParams: { inModal: opt.modal || undefined },
			}
		);
		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected getGanttSubtasksUrl(opt: OpenGanttSubtasksOptions): string {
		const urlTree = this.router.createUrlTree(
			[opt.noframe ? `/noframe/tasks/${opt.taskId}/gantt-subtasks` : `/tasks/${opt.taskId}/gantt-subtasks`],
			{
				queryParams: { inModal: opt.modal || undefined },
			}
		);
		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected getSubtasksUrl(opt: OpenSubtasksOptions): string {
		const urlTree = this.router.createUrlTree(
			[opt.noframe ? `/noframe/subtasks/${opt.taskId}` : `/subtasks/${opt.taskId}`],
			{
				queryParams: { inModal: opt.modal || undefined },
			}
		);
		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected getAdminSmartGridUrl(opt: OpenAdminSmartGridOptions) {
		const urlTree = this.router.createUrlTree(
			[opt.noframe ? '/noframe/administration/smart-packs' : '/administration/smart-packs', opt.subcatId],
			{
				queryParams: {
					inModal: opt.modal || undefined,
					...(opt.queryParams || {}),
				},
			}
		);
		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected getAdminSmartExpressionsUrl(opt: OpenOptions) {
		const urlTree = this.router.createUrlTree(
			[opt.noframe ? '/noframe/administration/smart-expressions' : '/administration/smart-expressions'],
			{
				queryParams: {
					inModal: opt.modal || undefined,
					...(opt.queryParams || {}),
				},
			}
		);
		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected getAdminSmartExpressionConstructorUrl(opt: OpenOptions) {
		const urlTree = this.router.createUrlTree(
			[
				opt.noframe
					? '/noframe/administration/smart-expression-constructor'
					: '/administration/smart-expression-constructor',
			],
			{
				queryParams: {
					inModal: opt.modal || undefined,
					...(opt.queryParams || {}),
				},
			}
		);
		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected getAdminSmartLuaScriptUrl(opt: OpenOptions) {
		const urlTree = this.router.createUrlTree(
			[opt.noframe ? '/noframe/administration/smart-script' : '/administration/smart-script'],
			{
				queryParams: {
					inModal: opt.modal || undefined,
					...(opt.queryParams || {}),
				},
			}
		);
		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	getOpenChatUrl(opt: OpenChatOptions): string {
		const urlTree = this.router.createUrlTree([opt.noframe ? '/noframe/chat' : '/chat'], {
			queryParams: { taskId: opt.taskId },
		});

		const url = this.router.serializeUrl(urlTree);
		return url;
	}

	protected optDefaults(opt: OpenOptions) {
		if (typeof opt.noframe === 'undefined') {
			opt.noframe = this.isNoFrame.value;
		}
		return opt;
	}
}

interface OpenOptions {
	noframe?: boolean;
	modal?: boolean;
	newTab?: boolean;
	closeWhen?: Observable<any>;
	focusTab?: boolean;
	routeState?: any;
	queryParams?: Record<string, string | number | boolean>;
}

export interface OpenProjectOptions extends OpenOptions {
	projectId: number;
}

export interface OpenEmailOptions extends OpenOptions {
	emailId: number;
	mailBoxId: number;
	folderName: string;
}

export interface OpenTaskOptions extends OpenOptions {
	taskId: number;
	props?: Record<string, any>;
}

export interface OpenProfileOptions extends OpenOptions {
	userId: number;
}

export interface OpenSpaceOptions extends OpenOptions {
	spaceId: number;
	subcatId?: number;
	short?: boolean;
}

export interface OpenSubcatOptions extends OpenOptions {
	subcatId: number;
	taskId?: number;
	subcatOpenType?: string;
}

export interface OpenVKSOptions extends OpenOptions {
	roomName: string;
	inviteUserIds?: number | number[];
	uuid?: string;
}

export interface OpenChatOptions extends OpenOptions {
	mode?: string;
	taskId?: number;
	with?: number[];
}

export interface OpenSignaturesJournalOptions extends OpenOptions {
	taskId: number;
	taskText?: string;
}
export interface OpenGanttSubtasksOptions extends OpenOptions {
	taskId: number;
}

export interface OpenSubtasksOptions extends OpenOptions {
	taskId: number;
}

export interface OpenAdminSmartGridOptions extends OpenOptions {
	subcatId: number;
}

export interface IOpenNewTaskContentOverlay {
	subcatId: number;
	onCreated?: Function;
	onCancel?: Function;
	onFinish?: Function;
	openTaskAfterCreate?: boolean;
	onComponentCreate?: (instance: any) => void;
}

enum SubcatOpenTypes {
	hierarchyDictionary = 'hierarchy',
	file = 'files',
	feed = 'feeds',
	grid = 'grid',
	calendar = 'calendar',
	kanban = 'kanban',
	chats = 'chat',
	channel = 'channel',
}
