import { ITaskInfo, IUserShort } from '@spa/data/entities';
import { Observable } from 'rxjs';

export abstract class SipDataHttpService {
	abstract getCallHistory(params: ICallHistoryReq): Observable<ICallHistory>;
	abstract getMissedCount(userId: number): Observable<number>;
	abstract setViewedMissedCall(): Observable<any>;
}

export interface ICallHistoryReq {
	userId: number;
	limit?: number;
	offset?: number;
}
export interface ICallHistory {
	phoneCalls: ICallHistoryItem[];
}

export interface ICallHistoryItem {
	callerPhone: string;
	callerUser: IUserShort;
	calleePhone: string;
	calleeUser: IUserShort;
	startTime: string | Date;
	disposition: string;
	dispositionId: number;
	reactionTime: string | Date;
	isAccepted: boolean;
	userAgent: string;
	viewed: boolean;
	uuid: string;
	id: number;
	dispositionTask: Partial<ITaskInfo>;
	dispositionUser: IUserShort;
}
