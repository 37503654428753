export abstract class CookieService {
	abstract get isCookieSupported(): boolean;

	/**
	 * Check if cookie exists
	 * @param name Cookie name
	 * @returns {boolean}
	 */
	abstract check(name: string): boolean;

	/**
	 * Get cookie value by name
	 * @param name Cookie name
	 * @returns {any}
	 */
	abstract get(name: string): string;

	/**
	 * Get all cookies
	 * @returns {[key: string]: string}
	 */
	abstract getAll(): Record<string, string>;

	/**
	 * @param name    Cookie name
	 * @param value   Cookie value
	 * @param expires Number of days until the cookies expires or an actual `Date`
	 * @param path    Cookie path
	 * @param domain  Cookie domain
	 * @param secure  Secure flag
	 */
	abstract set(
		name: string,
		value: string,
		expires?: number | Date,
		path?: string,
		domain?: string,
		secure?: boolean
	): void;

	/**
	 * @param name   Cookie name
	 * @param path   Cookie path
	 * @param domain Cookie domain
	 */
	abstract delete(name: string, path?: string, domain?: string): void;
	abstract deleteAll(path?: string, domain?: string): void;
}
