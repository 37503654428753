import { Observable } from 'rxjs';
import { IApiResponse } from '../api-response';

export abstract class CachesDataHttpService {
	abstract getCachesList(): Observable<IApiResponse<ICacheDto[]>>;
	abstract updateAllCaches(): Observable<void>;
	abstract getAllCacheKeys(cacheTypeName: string): Observable<IApiResponse<string[]>>;
	abstract getValuesCountInCache(cacheTypeName: string): Observable<IApiResponse<number>>;
	abstract getValueByKey(cacheTypeName: string, key: string): Observable<any>;
	abstract updateCache(cacheTypeName: string): Observable<void>;
	abstract getQueuesList(): Observable<IApiResponse<IRebusQueueDto[]>>;
	abstract clearQueue(queue: string): Observable<void>;
	abstract deleteQueue(queue: string): Observable<void>;
}

export interface ICacheDto {
	id: number;
	name: string;
	assemblyName: string;
	count: string;
	size: string;
	expirationTimeout: any;
	cacheType: string;
}

export interface IRebusQueueDto {
	queue: string;
	count: number;
}
