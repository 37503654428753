import { NgModule } from '@angular/core';

import { FeatherInlineIconDirective } from './feather-svg-inline.directive';

@NgModule({
	imports: [],
	exports: [FeatherInlineIconDirective],
	declarations: [FeatherInlineIconDirective],
})
export class FeatherIconsModule {}
