import { InjectionToken } from '@angular/core';

export interface ILoggerOptions {
	isProd?: boolean;
	prefixColor?: string;
	withStack?: boolean;
}

export const LOGGER_OPTIONS = new InjectionToken<ILoggerOptions>('logger configuration options');
export const LOGGER_OPTIONS_DEFAULT = new InjectionToken<ILoggerOptions>('default logger configuration options');

export const defaultOptions: ILoggerOptions = {
	prefixColor: 'cornflowerblue',
	isProd: false,
	withStack: false,
};
