import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Input,
	NgZone,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { SidebarComponent } from '@spa/common/components/sidebar';
import { SidebarService } from '@spa/common/services/sidebar.service';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { OpenAdminPanelEvent } from '@spa/facade/open-admin-panel-event';
import {
	AuthService,
	EventBusService,
	LocalStorageProvider,
	PlatformDetectorProvider,
	ViewDestroyStreamService,
} from '@valhalla/core';
import { booleanFilter, firstLetterLowerCaseMapper } from '@valhalla/utils';
import { BehaviorSubject, combineLatest, empty, fromEvent, of, Subject } from 'rxjs';
import {
	debounceTime,
	delay,
	distinctUntilChanged,
	filter,
	map,
	sampleTime,
	shareReplay,
	switchMap,
	takeUntil,
	tap,
} from 'rxjs/operators';

import { LayoutFacade } from '@spa/facade/layout';
import { SidebarKeys } from '@spa/common/components/sidebar/abstract';

@Component({
	selector: 'vh-layout-vertical-1',
	templateUrl: './layout-1.component.html',
	styleUrls: ['./layout-1.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class VerticalLayout1Component implements OnInit, AfterViewInit {
	constructor(
		readonly platformDetector: PlatformDetectorProvider,
		readonly layoutFacade: LayoutFacade,
		readonly destroy$: ViewDestroyStreamService,
		readonly router: Router,
		readonly fuseSidebarService: SidebarService,
		readonly localStorage: LocalStorageProvider,
		readonly auth: AuthService,
		readonly zone: NgZone,
		readonly eventBus: EventBusService,
		readonly mobileView: MobileViewService,
		readonly activatedRoute: ActivatedRoute
	) {}

	@Input()
	layoutState: any;

	@Input()
	navigation: any;

	@ViewChild('sidebarMenu')
	sideBar: SidebarComponent;

	useNewLayout = true;
	isMobile = false;
	sideBarPinned = true;
	menuMouseLeave = true;
	showNavigationAlways = true;

	readonly sidebarMouseEnter$ = new BehaviorSubject(false);
	hideSidebar = false;

	public readonly SidebarKeys = SidebarKeys;
	public sideMenuName: SidebarKeys.mobileMainMenu | SidebarKeys.navbar;

	closeNavBar$ = this.layoutFacade.state$.pipe(
		map(state => state.layout.navbar.folded),
		distinctUntilChanged(),
		filter<boolean>(Boolean),
		takeUntil(this.destroy$)
	);

	clickOutsideFromSidebar$ = fromEvent(window, 'click', { passive: true }).pipe(
		map((ev: MouseEvent) => {
			const target = ev.target as HTMLElement;
			const sideBarEl = this.sideBar && this.sideBar.el;
			return Boolean(sideBarEl && sideBarEl.contains(target));
		}),
		map(isContains => !isContains),
		filter(_ => !this.isMobile),
		takeUntil(this.destroy$)
	);

	readonly isReincarnateMode$ = this.auth.isReincarnateMode$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));

	readonly appNavBarPosition$ = this.layoutFacade.state$.pipe(
		map(s => s.appNavBarPosition),
		distinctUntilChanged()
	);
	readonly isLeftAppNavBarPosition$ = this.appNavBarPosition$.pipe(
		map(pos => firstLetterLowerCaseMapper(pos) === 'left')
	);
	readonly isRightAppNavBarPosition$ = this.appNavBarPosition$.pipe(
		map(pos => firstLetterLowerCaseMapper(pos) === 'right')
	);
	readonly isTopAppNavBarPosition$ = this.appNavBarPosition$.pipe(
		map(pos => ['above', 'top'].includes(firstLetterLowerCaseMapper(pos)))
	);

	readonly showNavigationAlways$ = this.layoutFacade.state$.pipe(
		map(s => s.showNavigationAlways && !this.isMobile),
		distinctUntilChanged()
	);

	readonly mouseTrack$ = fromEvent<MouseEvent>(document, 'mouseover').pipe(map(e => ({ x: e.clientX, y: e.clientY })));
	btnMouseLeave$ = new Subject();
	stopMouseTrackLeave$ = new Subject();
	closeSidebarNotify$ = new Subject();

	appNavBarPositionTyped$ = combineLatest(
		this.isLeftAppNavBarPosition$,
		this.isRightAppNavBarPosition$,
		this.isTopAppNavBarPosition$
	).pipe(map(([isLeft, isRight, isTop]) => ({ isLeft, isRight, isTop })));

	miniBarPinnedLeft$ = this.appNavBarPositionTyped$.pipe(
		map(({ isLeft, isRight, isTop }) => {
			if (isLeft) {
				return 64;
			}
			if (isRight) {
				return;
			}
			if (isTop) {
				return 0;
			}
		}),
		distinctUntilChanged()
	);
	miniBarPanelPosition$ = this.isRightAppNavBarPosition$.pipe(map(isRight => (isRight ? 'right' : 'left')));
	miniBarPinnedRight$ = this.appNavBarPositionTyped$.pipe(
		map(({ isLeft, isRight, isTop }) => {
			if (isLeft || isTop) {
				return;
			}
			if (isRight) {
				return 64;
			}
		}),
		distinctUntilChanged()
	);

	get isContextMenuOpen() {
		const isContextMenuOpened = Boolean(document.querySelector('#vh-common-context-menu-fixed-container[is-open]'));
		return isContextMenuOpened;
	}

	get showFooter() {
		return !this.mobileView.mobileMode && this.isMobile;
	}

	ngOnInit() {
		this.isMobile = this.platformDetector.isOneOfPlatform(
			this.platformDetector.types.android,
			this.platformDetector.types.ios
		);
		if (this.localStorage.get(pinnedToken) !== null) {
			this.sideBarPinned = this.localStorage.get(pinnedToken);
		}
		this.showNavigationAlways$
			.pipe(
				map(s => {
					window.dispatchEvent(new Event('resize'));
					if (s) {
						this.pinnedChange(s);
					}
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();

		this.layoutFacade.state$
			.pipe(
				map(s => s.layout.navbar.folded),
				switchMap(folded => {
					if (folded) {
						return empty();
					}
					return this.clickOutsideFromSidebar$.pipe(
						// skip(1),
						tap(isOutsideClick => {
							if (isOutsideClick && !this.isContextMenuOpen) {
								this.closeSideBar();
							}
						})
					);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
		this.router.events
			.pipe(
				filter(e => e instanceof NavigationStart),
				takeUntil(this.destroy$)
			)
			.subscribe(_ => {
				if (this.isMobile) {
					const bar = this.fuseSidebarService.getSidebar('navbar');
					bar && bar.close();
				}
				if (!this.sideBarPinned) {
					this.closeSideBar();
				}
			});

		this.closeSidebarNotify$.pipe(debounceTime(300)).subscribe(() => this.onCloseSidebarHandler());
		this.eventBus.ofType(OpenAdminPanelEvent).subscribe(() => {
			if (this.sideBarPinned === false) {
				this.pinnedChange(!this.sideBarPinned);
			}
		});
		this.defineSideMenuName();
		this.activatedRoute.queryParams
			.pipe(takeUntil(this.destroy$))
			.subscribe(params => (this.hideSidebar = !!params?.hideLeftPanel));
	}

	ngAfterViewInit() {
		const buttonRect$ = of(document.getElementById('toggleSideBarBtn')).pipe(
			booleanFilter(),
			delay(100),
			map(btn => btn.getBoundingClientRect()),
			shareReplay({ bufferSize: 1, refCount: true })
		);

		const trackOnLeave$ = this.btnMouseLeave$.pipe(
			switchMap(() => this.mouseTrack$.pipe(sampleTime(100), takeUntil(this.stopMouseTrackLeave$)))
		);

		combineLatest([trackOnLeave$, buttonRect$])
			.pipe(
				map(([track, buttonRect]) => this.isMouseLeave(track, buttonRect)),
				filter(isLeave => isLeave && !this.sidebarMouseEnter$.value),
				debounceTime(100),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				if (!this.isContextMenuOpen) {
					this.stopMouseTrackLeave$.next(0 as any);
					this.closeSideBar();
				}
			});

		this.layoutFacade.update({
			layout: {
				navbar: {
					folded: !this.sideBarPinned,
				},
			},
		});
	}

	isMouseLeave(coordMouse, coordEl) {
		const coordElTop = coordEl.top;
		const coordElBottom = coordEl.bottom + 25;
		const coordElRight = coordEl.right + 25;
		const coordElLeft = coordEl.left;

		if (
			coordMouse.x < coordElLeft ||
			coordMouse.x > coordElRight ||
			coordMouse.y < coordElTop ||
			coordMouse.y > coordElBottom
		) {
			return true;
		}

		return false;
	}

	onMouseLeaveOnSidebar() {
		this.sidebarMouseEnter$.next(false);
	}

	onMouseEnterOnSidebar() {
		this.sidebarMouseEnter$.next(true);
	}

	closeSideBar() {
		this.closeSidebarNotify$.next(0 as any);
	}

	onCloseSidebarHandler() {
		if (this.sideBarPinned) {
			return;
		}
		this.layoutFacade.update({
			layout: {
				navbar: {
					folded: true,
				},
			},
		});
	}

	toggleFold(forceFold?: boolean) {
		const folded = this.layoutFacade.state.layout.navbar.folded;
		this.sideBar?._elementRef?.nativeElement?.classList.toggle('folded', folded);
		this.sideBar?._elementRef?.nativeElement?.classList.toggle('is-folded', folded);
		if (folded && this.sideBar?._elementRef?.nativeElement) {
			this.sideBar._elementRef.nativeElement.style.cssText =
				'left: 0px !important; width: 0px; min-width: 0px; max-width: 0px;';
		}

		this.layoutFacade.update({
			layout: {
				navbar: {
					folded: forceFold ? !forceFold : !folded,
				},
			},
		});
	}

	toggleFoldWhenMouseLeave(forceFold?: boolean) {
		if (!this.menuMouseLeave) {
			return;
		}
		this.toggleFold(forceFold);
	}

	pinnedChange(pinned: boolean) {
		this.menuMouseLeave = false;
		this.sideBarPinned = pinned;
		this.localStorage.set(pinnedToken, this.sideBarPinned);
		this.toggleFold(pinned);
		if (!pinned) {
			this.closeSideBar();
		}
		//window.dispatchEvent(new Event('resize'));
	}

	menuMouseLeaveHandler() {
		this.menuMouseLeave = true;
		this.btnMouseLeave$.next(0 as any);
	}

	onFoldedChanged(folded: boolean) {
		this.layoutFacade.update({
			layout: {
				navbar: {
					folded: folded,
				},
			},
		});
	}

	defineSideMenuName(): void {
		this.sideMenuName = this.mobileView.mobileMode ? SidebarKeys.mobileMainMenu : SidebarKeys.navbar;
	}
}

export const pinnedToken = 'pinned';
