import { ExtParamTypeEnum } from '@spa/data/entities';
import { ExtParamAddress } from './ext-param-address';
import { ExtParamBase, ExtParamBaseOptions } from './ext-param-base';
import { ExtParamCheckbox } from './ext-param-checkbox';
import { ExtParamCombobox } from './ext-param-combobox';
import { ExtParamDate } from './ext-param-date';
import { ExtParamDateTime } from './ext-param-datetime';
import { ExtParamEmailAddresses } from './ext-param-email-addresses';
import { ExtParamFile } from './ext-param-file';
import { ExtParamLookup } from './ext-param-lookup';
import { ExtParamMoney } from './ext-param-money';
import { ExtParamMultifile } from './ext-param-multifile';
import { ExtParamMultiselectSubcatTasks } from './ext-param-multiselect-subcat-tasks';
import { ExtParamNumericValue } from './ext-param-numeric-value';
import { ExtParamSelect } from './ext-param-select';
import { ExtParamSelectUsers } from './ext-param-select-users';
import { ExtParamTable } from './ext-param-table';
import { ExtParamText } from './ext-param-text';
import { ExtParamTextArea } from './ext-param-textarea';
import { ExtParamUrl } from './ext-param-url';
import { ExtParamTree } from './ext-param-tree';

type ExtParamFactory = (options: ExtParamBaseOptions) => ExtParamBase<any>;

const extParamFactoryMap: Record<string, ExtParamFactory> = {
	[ExtParamTypeEnum.lookUpField]: options => new ExtParamLookup(options),
	[ExtParamTypeEnum.select]: options => new ExtParamSelect(options),
	[ExtParamTypeEnum.combobox]: options => new ExtParamCombobox(options),
	[ExtParamTypeEnum.selectUsers]: options => new ExtParamSelectUsers(options),
	[ExtParamTypeEnum.address]: options => new ExtParamAddress(options),
	[ExtParamTypeEnum.extParamAddressees]: options => new ExtParamEmailAddresses(options),
	[ExtParamTypeEnum.multiSlctSubcatTasks]: options => new ExtParamMultiselectSubcatTasks(options),
	[ExtParamTypeEnum.date]: options => new ExtParamDate(options),
	[ExtParamTypeEnum.datetime]: options => new ExtParamDateTime(options),
	[ExtParamTypeEnum.file]: options => new ExtParamFile(options),
	[ExtParamTypeEnum.table]: options => new ExtParamTable(options),
	[ExtParamTypeEnum.multifile]: options => new ExtParamMultifile(options),
	[ExtParamTypeEnum.checkbox]: options => new ExtParamCheckbox(options),
	[ExtParamTypeEnum.text]: options => new ExtParamText(options),
	[ExtParamTypeEnum.money]: options => new ExtParamMoney(options),
	[ExtParamTypeEnum.numericValue]: options => new ExtParamNumericValue(options),
	[ExtParamTypeEnum.textArea]: options => new ExtParamTextArea(options),
	[ExtParamTypeEnum.url]: options => new ExtParamUrl(options),
	[ExtParamTypeEnum.tree]: options => new ExtParamTree(options),
};

export function createExtParam<TValue = any>(options: ExtParamBaseOptions<TValue>): ExtParamBase<TValue> {
	const factory = extParamFactoryMap[options?.sourceConfig?.type];
	if (factory) {
		return factory(options);
	}
	return new ExtParamBase(options);
}
