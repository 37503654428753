import { Injectable } from '@angular/core';
import { AuthService } from '@spa/core';
import { DataHttpService } from '@spa/data/http';
import { booleanFilter } from '@valhalla/utils';
import { take } from 'rxjs';
import { MatomoInitializerService } from '@ngx-matomo/tracker';
import { environment } from '@spa/environment';

const win = window as any;

@Injectable({
	providedIn: 'root',
})
export class MatomoService {
	constructor(
		protected readonly auth: AuthService,
		protected readonly server: DataHttpService,
		protected readonly matomoInitializer: MatomoInitializerService
	) {}

	#init = false;

	init() {
		if (this.#init) {
			return;
		}
		this.#init = true;
		if (!environment.production) {
			return;
		}
		this.server.config.appSettingsAnonymousConfig$.pipe(take(1)).subscribe(cfg => {
			// add matomo
			const matomo = cfg?.CustomSettings?.matomo;
			if (matomo) {
				this.matomoInitializer.initializeTracker({
					siteId: matomo.siteId,
					trackerUrl: `//${matomo.serverAddress}/`,
					scriptUrl: `//${matomo.serverAddress}/matomo.js?_=${new Date().toLocaleDateString().replaceAll('.', '')}`,
				});
				this.auth.authInfo$.pipe(take(1)).subscribe(info => {
					const userGroups = info.groups;
					const analyticGroupsIds = matomo.analyticGroups || [];
					const analyticGroups = userGroups.filter(i => analyticGroupsIds.includes(i.id));
					const topGroup = analyticGroups[0];
					// eslint-disable-next-line no-restricted-syntax
					console.debug({ userGroups, analyticGroupsIds, analyticGroups, topGroup });
					if (topGroup) {
						this.pushAQ(['setCustomDimension', 1, topGroup.description]);
					}
				});
			}
		});
		this.auth.authenticated$.pipe(booleanFilter(), take(1)).subscribe(() => {
			this.pushAQ(['setUserId', this.auth.userId]);
		});
	}

	pushAQ(matomoData: any[]) {
		const _paq = (win._paq = win._paq || []);
		_paq.push(matomoData);
	}
}
