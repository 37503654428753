import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { DuplicateImportModuleError } from '../../errors';

import { defaultOptions, LOGGER_OPTIONS, LOGGER_OPTIONS_DEFAULT } from './logger-options';
import type { ILoggerOptions } from './logger-options';
import { LoggerFactory } from './abstract';
import { LoggerFactoryImpl } from './logger.service';
import { IS_PRODUCTION } from '../../configuration';

export function loggerOptionsFactory(options: ILoggerOptions, isProd: boolean): ILoggerOptions {
	return {
		...options,
		isProd: isProd,
	};
}

@NgModule({
	providers: [
		{
			provide: LOGGER_OPTIONS_DEFAULT,
			useValue: defaultOptions,
		},
	],
})
export class LoggerModule {
	constructor(
		@Optional()
		@SkipSelf()
		loggerModule: LoggerModule
	) {
		if (loggerModule) throw new DuplicateImportModuleError('LoggerModule');
	}
	static forRoot(options: ILoggerOptions = defaultOptions): ModuleWithProviders<LoggerModule> {
		return {
			ngModule: LoggerModule,
			providers: [
				{
					provide: LOGGER_OPTIONS,
					useFactory: loggerOptionsFactory,
					deps: [LOGGER_OPTIONS_DEFAULT, IS_PRODUCTION],
				},
				{
					provide: LoggerFactory,
					useClass: LoggerFactoryImpl,
				},
			],
		};
	}
}
