import { ModuleWithProviders, NgModule } from '@angular/core';

import { NGDI_PLATFORM_PROVIDERS } from './ngdi.config';

@NgModule()
export class PlatformModule {
	static forRoot(): ModuleWithProviders<PlatformModule> {
		return {
			ngModule: PlatformModule,
			providers: [NGDI_PLATFORM_PROVIDERS],
		};
	}
}
