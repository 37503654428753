<!-- SIDE PANEL -->
<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'left'">
	<!-- CHAT PANEL -->
	<vh-common-sidebar
		name="chatPanel"
		position="left"
		class="chat-panel left-chat-panel"
		[folded]="true"
		[foldedWidth]="70"
		[foldedAutoTriggerOnHover]="false"
		lockedOpen="gt-md"
	>
		<vh-layout-chat-panel></vh-layout-chat-panel>
	</vh-common-sidebar>
	<!-- / CHAT PANEL -->
</ng-container>
<!-- / SIDE PANEL -->

<div id="main">
	<!-- TOOLBAR: Above fixed -->
	<ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above-fixed'">
		<ng-container *ngTemplateOutlet="toolbar"></ng-container>
	</ng-container>
	<!-- / TOOLBAR: Above fixed -->

	<div
		id="container-1"
		class="layout-container"
		vhPerfectScrollbar
		[vhPerfectScrollbarOptions]="{ suppressScrollX: true, updateOnRouteChange: true }"
	>
		<!-- TOOLBAR: Above static -->
		<ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above-static'">
			<ng-container *ngTemplateOutlet="toolbar"></ng-container>
		</ng-container>
		<!-- / TOOLBAR: Above static -->

		<div id="container-2" class="layout-container">
			<!-- NAVBAR: Left -->
			<ng-container *ngIf="fuseConfig.layout.navbar.position === 'left'">
				<ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
			</ng-container>
			<!-- / NAVBAR: Left -->

			<!-- CONTENT -->
			<vh-layout-content
				cdkScrollable
				id="vh-layout-main-content-area"
				[ngClass]="{
					'ml-32': fuseConfig.layout.navbar.position === 'left',
					'mr-32': fuseConfig.layout.navbar.position === 'right'
				}"
			>
			</vh-layout-content>
			<!-- / CONTENT -->

			<!-- NAVBAR: Right -->
			<ng-container *ngIf="fuseConfig.layout.navbar.position === 'right'">
				<ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
			</ng-container>
			<!-- / NAVBAR: Right -->
		</div>

		<!-- FOOTER: Above static -->
		<ng-container *ngIf="fuseConfig.layout.footer.position === 'above-static'">
			<ng-container *ngTemplateOutlet="footer"></ng-container>
		</ng-container>
		<!-- FOOTER: Above static -->
	</div>

	<!-- FOOTER: Above fixed -->
	<ng-container *ngIf="fuseConfig.layout.footer.position === 'above-fixed'">
		<ng-container *ngTemplateOutlet="footer"></ng-container>
	</ng-container>
	<!-- FOOTER: Above fixed -->
</div>

<!-- SIDE PANEL -->
<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'right'">
	<!-- CHAT PANEL -->
	<vh-common-sidebar
		name="chatPanel"
		position="right"
		class="chat-panel right-chat-panel"
		[folded]="true"
		[foldedWidth]="70"
		[foldedAutoTriggerOnHover]="false"
		lockedOpen="gt-md"
	>
		<vh-layout-chat-panel></vh-layout-chat-panel>
	</vh-common-sidebar>
	<!-- / CHAT PANEL -->
</ng-container>
<!-- / SIDE PANEL -->

<!-- QUICK PANEL -->
<vh-common-sidebar name="quickPanel" position="right" class="quick-panel">
	<vh-layout-quick-panel></vh-layout-quick-panel>
</vh-common-sidebar>
<!-- / QUICK PANEL -->

<!-- TOOLBAR -->
<ng-template #toolbar>
	<vh-layout-toolbar
		*ngIf="!fuseConfig.layout.toolbar.hidden"
		[ngClass]="[fuseConfig.layout.toolbar.position, fuseConfig.layout.toolbar.background]"
	>
	</vh-layout-toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
	<vh-layout-footer
		*ngIf="!fuseConfig.layout.footer.hidden"
		[ngClass]="[fuseConfig.layout.footer.position, fuseConfig.layout.footer.background]"
	>
	</vh-layout-footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
	<vh-common-sidebar
		name="navbar"
		class="navbar-fuse-sidebar"
		[folded]="fuseConfig.layout.navbar.folded"
		lockedOpen="gt-md"
		*ngIf="!fuseConfig.layout.navbar.hidden"
		[showNavigationAlways]="fuseConfig.showNavigationAlways"
	>
		<vh-layout-navbar class="left-navbar" [ngClass]="fuseConfig.layout.navbar.background"></vh-layout-navbar>
	</vh-common-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
	<vh-common-sidebar
		name="navbar"
		position="right"
		class="navbar-fuse-sidebar"
		[folded]="fuseConfig.layout.navbar.folded"
		lockedOpen="gt-md"
		*ngIf="!fuseConfig.layout.navbar.hidden"
		[showNavigationAlways]="fuseConfig.showNavigationAlways"
	>
		<vh-layout-navbar class="right-navbar" [ngClass]="fuseConfig.layout.navbar.background"></vh-layout-navbar>
	</vh-common-sidebar>
</ng-template>
<!-- / RIGHT NAVBAR -->
