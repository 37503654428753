import { NgModule } from '@angular/core';
import { ProfileSpaReportsComponent } from '@spa/pages/user/profile-spa/profile-spa-reports/profile-spa-reports.component';
import { CommonModule } from '@angular/common';
import { TippyModule } from '@ngneat/helipopper';

@NgModule({
	imports: [CommonModule, TippyModule],
	exports: [ProfileSpaReportsComponent],
	declarations: [ProfileSpaReportsComponent],
})
export class ProfileSpaReportsModule {}
