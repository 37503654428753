import { NgModule, ModuleWithProviders } from '@angular/core';

import { TickerComponentsModule } from './components';
import { TICKERS_PROVIDERS } from './ng-providers';

@NgModule({
	imports: [TickerComponentsModule],
	exports: [TickerComponentsModule],
})
export class TickersModule {
	static onlyProviders(): ModuleWithProviders<TickersModule> {
		return {
			ngModule: TickersModule,
			providers: TICKERS_PROVIDERS,
		};
	}
}
