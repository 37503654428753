import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	inject,
} from '@angular/core';
import {
	ButtonIconState,
	ButtonIconStateEvent,
	ButtonIconStateMachine,
	createButtonIconStateMachine,
} from './button-icon-state.model';

@Component({
	selector: 'vh-button-icon-state',
	templateUrl: './button-icon-state.component.html',
	styleUrls: ['./button-icon-state.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonIconStateComponent implements OnChanges {
	readonly cdr = inject(ChangeDetectorRef);

	@Input()
	states: ButtonIconState[];

	@Output()
	stateChange = new EventEmitter<ButtonIconStateEvent>();

	@Input()
	@HostBinding('style.--icon-size.px')
	iconSize = 24;

	@Input()
	@HostBinding('style.--icon-color')
	iconColor: string;

	machine: ButtonIconStateMachine;

	get current$() {
		return this.machine.current$;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.states) {
			this.rebuildButtons(this.states);
		}
	}

	rebuildButtons(states: ButtonIconState[]) {
		this.machine = createButtonIconStateMachine(states, e => this.stateChange.emit(e));
		this.cdr.markForCheck();
	}

	onClick(e: Event) {
		this.machine.action(e);
	}
}
