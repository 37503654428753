import { Injectable } from '@angular/core';
import { SessionUser } from '@spa/core';
import { IUser } from '@spa/data/entities';

@Injectable({ providedIn: 'root' })
export class UserAvatarLink {
	constructor(protected readonly useInfo: SessionUser) {}

	get(userOrId: any, timestamp?: number) {
		if (userOrId?.userAvatarFile?.fileId) {
			return `/api/files/download/${userOrId?.userAvatarFile?.fileId}/${userOrId?.userAvatarFile?.latestVersionId}`;
		}

		switch (typeof userOrId) {
			case 'object':
				const user = userOrId as Partial<IUser>;
				return this.useInfo.getAvatarLinkById(user.userId || user.id, timestamp);
			case 'string':
			case 'number':
				return this.useInfo.getAvatarLinkById(userOrId, timestamp);
			default:
				return;
		}
	}
}
