import { Router } from '@angular/router';
import { Injector, NgZone } from '@angular/core';
import { AuthService } from '@valhalla/core';

import { portalApi, setNgZone, setNgRouter } from '../portal';
import { createGetSessionUserInfo } from './get-session-user-info';
import { radopen } from './radopen';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { RootRegistrationService } from '../root-registration-service';
import { IFileViewerFile } from '@spa/facade/features/modals/file-viewer/file-viewer-file';
import { ExtParamTableApiService } from '@spa/common/services/ext-param-table-api.service';

const firstFormaApi = {
	portal: portalApi.publicApi,
};

/** For old implementation API */
export function initializeCompatibleLayer(global: any, injector?: Injector) {
	const auth = injector.get(AuthService);
	const rootReg = injector.get(RootRegistrationService);
	const zone = injector.get(NgZone);
	const router = injector.get(Router);
	const epTableApi = injector.get(ExtParamTableApiService);

	setNgZone(zone);
	setNgRouter(router);

	global.firstForma = {
		...(global.firstForma || {}),
		...firstFormaApi,
		fileViewer: (files: IFileViewerFile[], fileIndex: number = 0) => {
			const modals = rootReg.get<ModalWindowsService>('ModalWindowsService');
			modals.openFileViewer2({ files, fileIndex });
		},
		ep: {
			table(id: number) {
				return epTableApi.table(id);
			},
		},
	};
	global.radopen = radopen;
	global.GetRadWindowManager = () => {
		function closeActiveModal() {
			global.spaCommand?.('closeActiveModal');
		}
		return {
			CloseActiveWindow: closeActiveModal,
			closeActiveWindow: closeActiveModal,
		};
	};

	Object.defineProperty(global, 'appFullPath', {
		configurable: false,
		get() {
			const frame = global.frames[0];
			// we don't really know
			return (frame && frame.appFullPath) || '.';
		},
	});

	Object.defineProperty(global, 'UserID', {
		configurable: false,
		get() {
			const id = Number(auth.userId);
			return isNaN(id) ? auth.userId : id;
		},
	});

	global.getSessionUserInfo = createGetSessionUserInfo(injector);
}
