import { isDevMode } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export function InputSubject(mapper?: (value: any) => any) {
	return function (target: any, propertyKey: string) {
		const subjectKey = `${propertyKey}$`;
		if (target[subjectKey]) {
			return;
		}
		const store = new WeakMap<any, BehaviorSubject<any>>();
		Object.defineProperty(target, subjectKey, {
			get() {
				if (!store.has(this)) {
					store.set(this, new BehaviorSubject(undefined));
				}
				return store.get(this);
			},
		});
		Object.defineProperty(target, propertyKey, {
			get() {
				return this[subjectKey].value;
			},
			set(val) {
				this[subjectKey].next(mapper ? mapper(val) : val);
			},
		});
	};
}

export function watch(fn: Function) {
	return function (target: any, propertyKey: string) {
		if (typeof fn !== 'function') {
			return;
		}
		const watchKey = `__$$watch$$__${propertyKey}`;
		Object.defineProperty(target, propertyKey, {
			get() {
				return this[watchKey];
			},
			set(val) {
				this[watchKey] = val;
				fn.call(this, val, propertyKey);
			},
			configurable: true,
		});
	};
}

export function log(opt?: Partial<{ prefix: string; prod: boolean }>) {
	const prefix = opt?.prefix;
	const prod = typeof opt?.prod === 'boolean' ? opt.prod : false;
	return watch((v, propName) => {
		if (isDevMode() || prod) {
			console.log(prefix || propName, v);
		}
	});
}

export function minmax(min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER) {
	return function (target: any, propertyKey: string) {
		const tryValue = `__$$minmax_try_value$$__${propertyKey}`;
		Object.defineProperty(target, propertyKey, {
			get() {
				if (this[tryValue] < min) {
					return min;
				} else if (this[tryValue] > max) {
					return max;
				}
				return this[tryValue];
			},
			set(val) {
				this[tryValue] = val;
			},
			configurable: true,
		});
	};
}
