import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';

import { FavoriteTickerTaskPipe } from './favorite-ticker-task.pipe';
import { FavoritesTasksComponent } from './favorites-tasks.component';
import { FavoriteExpandListModule } from '../favorites-panel/favorite-expand-list';

@NgModule({
	imports: [CommonModule, MatIconModule, MatListModule, FavoriteExpandListModule],
	declarations: [FavoritesTasksComponent, FavoriteTickerTaskPipe],
	exports: [FavoritesTasksComponent],
})
export class FavoritesTasksModule {}
