import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export function rxCached<T = any>(source$: Observable<T>, cacheTimeSec: number = 60): Observable<T> {
	const cache = {
		lastValue: undefined,
		hasInit: false,
		lastTimeUpdate: -1,
	};
	return of(cache).pipe(
		switchMap(ca => {
			if (ca.hasInit && ca.lastTimeUpdate + cacheTimeSec * 1000 >= Date.now()) {
				return of(ca.lastValue);
			}

			return source$.pipe(
				tap(value => {
					cache.lastTimeUpdate = Date.now();
					cache.lastValue = value;
					cache.hasInit = true;
				})
			);
		})
	);
}
