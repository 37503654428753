import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CategoryItemComponent } from './category-item.component';
import { TippyModule } from '@ngneat/helipopper';

@NgModule({
	imports: [CommonModule, MatIconModule, TippyModule],
	exports: [CategoryItemComponent],
	declarations: [CategoryItemComponent],
	providers: [],
})
export class CategoryItemModule {}
