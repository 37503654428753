import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { OutsideClickModule } from '../../directives/outside-click.directive';

import { AutoOpenMenuComponent } from './auto-open-menu.component';

@NgModule({
	imports: [CommonModule, MatMenuModule, OutsideClickModule],
	exports: [AutoOpenMenuComponent, MatMenuModule],
	declarations: [AutoOpenMenuComponent],
	providers: [],
})
export class AutoOpenMenuModule {}
