<ng-container *ngIf="isElectron">
	<iframe
		[src]="pdfViwerUrl(file | fileLink | urlSearch: 'getTrueMime':'true') | safeResource"
		width="100%"
		height="100%"
		frameborder="0"
	></iframe>
</ng-container>

<ng-container *ngIf="options?.isMeetingFile">
	<iframe
		[src]="getFileMeetingLink(file) | urlSearch: 'inline':'true' | safeResource"
		width="100%"
		height="100%"
		frameborder="0"
	></iframe>
</ng-container>

<ng-container *ngIf="options?.isEmailFile">
	<iframe
		*ngIf="!options?.openAsBlob"
		[src]="getFileEmailLink(file) | urlSearch: 'inline':'true' | safeResource"
		width="100%"
		height="100%"
		frameborder="0"
	></iframe>

	<iframe
		*ngIf="options?.openAsBlob"
		[src]="file?.src | safeResource"
		width="100%"
		height="100%"
		frameborder="0"
	></iframe>
</ng-container>

<ng-container *ngIf="!isElectron && !options?.isEmailFile && !options?.isEmailFile">
	<embed *ngIf="isSafari" [src]="file | fileLink | urlSearch: 'getTrueMime':'true' | safeResource" />
	<embed
		*ngIf="!isSafari"
		[src]="file | fileLink | urlSearch: 'getTrueMime':'true' | safeResource"
		type="application/pdf"
	/>
</ng-container>
