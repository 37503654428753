import { Injectable } from '@angular/core';
import { DataHttpService } from '@spa/data/http';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmailListGridService {
	constructor() {}

	readonly gridref$ = new BehaviorSubject(null);
	readonly updateEmailListNotify$ = new Subject();
	readonly updateEmailListGrid$ = new Subject();

	readonly toolbarSearchValue$ = new BehaviorSubject('');

	get gridref() {
		return this.gridref$.value;
	}

	markAllRead() {
		console.log(this.gridref);
	}

	updateEmailListGrid() {
		this.updateEmailListGrid$.next(0 as any);
	}

	updateEmailList() {
		this.updateEmailListNotify$.next(0 as any);
	}
}
