export const JITSI_NOTIFICATION = 'jitsiNotification';
export const JITSI_ROOM_ACTIVE = 'jitsiRoomActive';
export const ACTIVE_TABS = 'ActiveTabs';

export interface INotificationJitsi {
	force?: boolean;
	roomName: string;
	uuid?: string;
	user: {
		gender: number;
		hasAvatar: boolean;
		userId: number;
		userName: string;
	};
	typeBanner?: IBanerType;
	missedCallCount?: number;
	lastMissedTime?: string | Date;
	timeout?: any;
	desktopNotification?: Notification;
	desktopNotificationIgnoreHandle?: boolean;
}

export interface IActiveTabs {
	tabId: string;
	isActive: boolean;
}

export enum IBanerType {
	call = 'call',
	joined = 'joined',
	missed = 'missed',
	declined = 'declined',
}
