import { Injectable, InjectionToken } from '@angular/core';
import { getAppTopInjector } from '@spa/api/injectors';

export const IS_NOFRAME_MODE = new InjectionToken(
	'True when open page only without frame (url starts with /noframe/...)'
);

@Injectable({ providedIn: 'root' })
export class IsNoFrameModeService {
	get value() {
		return getAppTopInjector().get<boolean>(IS_NOFRAME_MODE, false);
	}
}
