<button
	*ngIf="collapsed; else search"
	class="vh-btn vh-btn-icon vh-btn--transparent fuse-search-bar-expander"
	[tippy]="'common.search' | resx"
	aria-label="Expand Search Bar"
	(click)="expand()"
>
	<mat-icon class="s-24 secondary-text">search</mat-icon>
</button>

<ng-template #search>
	<div class="fuse-search-bar" [ngClass]="{ expanded: !collapsed }">
		<div class="fuse-search-bar-content" [ngClass]="fuseConfig.layout.toolbar.background">
			<input
				#inputSearch
				id="fuse-search-bar-input"
				class="ml-24"
				type="text"
				[placeholder]="'common.search' | resx"
				[class.is-mobile]="isMobile"
				[vhAutoFocus]="searchAutofocus"
				(input)="onInput($event)"
				(keydown.enter)="onSearch(inputSearch)"
			/>
			<button
				*ngIf="!isMobile"
				class="vh-btn vh-btn-icon vh-btn--transparent"
				(click)="onSearch(inputSearch)"
				aria-label="Search"
			>
				<mat-icon class="s-24 secondary-text">search</mat-icon>
			</button>

			<button class="vh-btn vh-btn-icon vh-btn--transparent" (click)="collapse()" aria-label="Collapse Search Bar">
				<mat-icon class="secondary-text">close</mat-icon>
			</button>
		</div>
	</div>
</ng-template>
