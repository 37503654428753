import { AfterViewInit, Directive, EventEmitter, NgModule, OnInit, Output } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[mounted]' })
export class MountedDirective implements AfterViewInit {
	@Output()
	mounted = new EventEmitter();

	ngAfterViewInit() {
		this.mounted.emit();
	}
}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[inited]' })
export class InitedDirective implements OnInit {
	@Output()
	inited = new EventEmitter();

	ngOnInit() {
		this.inited.emit();
	}
}

@NgModule({
	declarations: [MountedDirective, InitedDirective],
	exports: [MountedDirective, InitedDirective],
})
export class MountedDirectiveModule {}
