import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NGDI_CONF_FACADE_APPS } from './ngdi.config';
import { ApplicationsSearchModule } from './search';

@NgModule({
	imports: [CommonModule, ApplicationsSearchModule],
	exports: [ApplicationsSearchModule],
	providers: [NGDI_CONF_FACADE_APPS]
})
export class FacadeApplicationsModule {}
