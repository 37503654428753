import { IUser, IUserProfile, IUserShort, IUserStatus } from '@valhalla/data/entities';
import { Observable } from 'rxjs';

import {
	IGetUserContactsCriteria,
	IGetUserSessionInfoResult,
	IGetUserSettingsResult,
	IGetUserStatusCriteria,
	ICowokersCriteria,
	IRecipientResolve,
	IRecipientResponse,
	IUserShortInfo,
	IUserSignatures,
	IUserOrgUnitsReq,
	IUserOrgUnitsRes,
	IUserSubstitute,
	IUserSubstituteData,
	IUserProcessAssistant,
	IUpdateSettings,
	IQuickReplies,
	IAddQuickRepliesReq,
	IDeleteQuickRepliesReq,
	IUserOrgStructureResponse,
	IUserChangePasswordRequest,
} from './types';

export abstract class UsersDataHttpService {
	abstract sessionUserSettings$: Observable<IGetUserSettingsResult>;

	abstract getStatus(criteria: IGetUserStatusCriteria): Observable<IUserStatus[]>;

	/**@deprecated */
	abstract getMembersInGroup(groupId: number): Observable<{ id: number; name: string; type: string }[]>;

	abstract getMembersGroup(groupId: number): Observable<any>;

	abstract getSettings(userId: number): Observable<IGetUserSettingsResult>;
	abstract updateSettings(req: IUpdateSettings): Observable<void>;

	abstract getUiSettings(userId: number): Observable<any>;

	abstract postUiSettings(userId: number, body: any): Observable<any>;

	abstract getUserInfo(userId: number): Observable<IUser>;

	abstract getUserList(...ids: number[]): Observable<IUser[]>;

	abstract getUserProfile(userId: number): Observable<IUserProfile>;

	abstract getSessionUserInfo(): Observable<IGetUserSessionInfoResult>;

	abstract getContacts(criteria?: Partial<IGetUserContactsCriteria>): Observable<IUser[]>;

	abstract searchContacts(criteria?: Partial<IGetUserContactsCriteria>, withEmailOnly?: boolean): Observable<IUser[]>;

	abstract getQuickReplies(): Observable<string[]>;

	abstract getSessionUserSharedData<T = any>(propertyPath?: string): Observable<T>;

	abstract setSessionUserSharedData<T = any>(value: any, propertyPath?: string): Observable<T>;

	abstract getCoworkers<T = any>(criteria: ICowokersCriteria): Observable<T>;

	abstract getContactsWithGroups(criteria?: Partial<IRecipientResolve>): Observable<IRecipientResponse>;

	abstract getUsersShortInfo(criteria?: Partial<IUserShortInfo>): Observable<IUserShort[]>;

	abstract getSignatures(extParamIds?: string, onlyOverdue?: boolean): Observable<IUserSignatures>;

	abstract uploadAvatar(userId: number, file: any): Observable<any>;
	abstract deleteAvatar(userId: number): Observable<any>;
	abstract getUserOrgUnits(params: IUserOrgUnitsReq): Observable<IUserOrgUnitsRes[]>;

	abstract getQuickRepliesUser(userId: number): Observable<IQuickReplies[]>;
	abstract addQuickReplies(req: IAddQuickRepliesReq[]): Observable<void>;
	abstract deleteQuickReplies(req: IDeleteQuickRepliesReq[]): Observable<void>;

	abstract sessionUserSettingsUpdate(): void;

	//abstract getUserSubstitutes(userId: number): Observable<IUserSubstitute[]>;
	abstract getUserAllAssistants(userId: number): Observable<IUserSubstitute[]>;
	abstract setUserAssistants(assistants: IUserSubstituteData[]): any;
	abstract removeUserAssistant(assistants: IUserSubstituteData[]): any;

	//abstract getUserPrincipals(userId: number): Observable<IUserSubstitute[]>;
	abstract getUserAssistantsPrincipals(userId: number): Observable<IUserSubstitute[]>;

	abstract getUserProcessAssistants(userId: number): Observable<IUserProcessAssistant[]>;
	abstract addUserProcessAssistants(assistants: IUserProcessAssistant[]): any;
	abstract removeUserProcessAssistants(assistants: IUserProcessAssistant[]): any;

	abstract getUserProcessPrincipals(userId: number): Observable<IUserProcessAssistant[]>;

	abstract getUserOrgStructure(orgUnitId: number): Observable<IUserOrgStructureResponse>;

	abstract changePassword(params: IUserChangePasswordRequest): Observable<any>;
}
