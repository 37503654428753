import { Effect, IAction, ofType } from '@valhalla/core';
import { of, zip } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as actions from '../actions';
import { ITickersState, chatTickerStateFactory } from '../state';
import { TickerEffectDependencies } from './dependency';

export const updateChatTickerEffect: Effect<IAction, IAction, ITickersState, TickerEffectDependencies> = (
	actions$,
	state$,
	deps
) => {
	const logger = deps.loggerFactory.createLogger('updateChatTickerEffect');
	return actions$.pipe(
		ofType(actions.FacadeTickersAction.updateChatTicker),
		map(({ payload }) => {
			const ticker = chatTickerStateFactory(payload);
			return actions.updateTickers(ticker);
		})
	);
};
