import { Provider } from '@angular/core';
import { StoreManager, LoggerFactory, ConfigurationProvider } from '@valhalla/core';

import { LayoutEffectDependencies } from './effects/dependency';
import { STORE_FACADE_LAYOUT } from './ng-tokens';
import { getOrCreateLayoutStore } from './store-factory';
import { LayoutFacadeImpl, LayoutFacade } from './layout-facade.service';

export function facadeLayoutStoreFactory(
	manager: StoreManager,
	deps: LayoutEffectDependencies,
	config: ConfigurationProvider
) {
	return getOrCreateLayoutStore(manager, deps, !config.isProd);
}

export function effectDependencyFactory(loggerFactory: LoggerFactory) {
	const effectDependency: LayoutEffectDependencies = {
		loggerFactory: loggerFactory
	};
	return effectDependency;
}

export const FACADE_LAYOUT_PROVIDERS: Provider[] = [
	{
		provide: LayoutEffectDependencies,
		useFactory: effectDependencyFactory,
		deps: [LoggerFactory]
	},
	{
		provide: STORE_FACADE_LAYOUT,
		useFactory: facadeLayoutStoreFactory,
		deps: [StoreManager, LayoutEffectDependencies, ConfigurationProvider]
	},
	LayoutFacadeImpl,
	{
		provide: LayoutFacade,
		useExisting: LayoutFacadeImpl
	}
];
