import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { IAppDefinition } from '../../db';
import { AppCardViewType } from './app-card-options';

@Component({
	selector: 'vh-app-card',
	templateUrl: './app-card.component.html',
	styleUrls: ['./app-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppCardComponent {
	@Input()
	appInfo: IAppDefinition;

	@HostBinding('attr.viewType')
	@Input()
	viewType: AppCardViewType;

	@HostBinding('attr.title')
	get cardTitle() {
		const title = this.appInfo && `${this.appInfo.name || ''}\n${this.appInfo.description || ''}`;
		return (Boolean(title) && title) || undefined;
	}

	readonly viewTypes = AppCardViewType;
}
