import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SpaCommandExecutorFactory } from '../spa-command.contract';

const createCommand: SpaCommandExecutorFactory = injector => {
	const zone = injector.get(NgZone);
	const router = injector.get(Router);

	return function openPortal(payload: { portalId: number }) {
		if (!payload?.portalId) {
			return console.error(`openPortal commend require option { portalId: numbert }`);
		}
		return zone.runTask(() => {
			router.navigate(['/portal', payload.portalId]);
		});
	};
};

export default createCommand;
