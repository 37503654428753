import { Injectable, inject } from '@angular/core';
import { CronClient } from '@spa/cron';
import { DataHttpService } from '@spa/data/http';
import { ISyncFolderType } from '@spa/data/http/email';
import { BehaviorSubject, Subject, map, merge, shareReplay, startWith, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavPanelEmailService {
	constructor(readonly server: DataHttpService) {}

	readonly cron = inject(CronClient);
	readonly update$ = new Subject();

	readonly updatedNotify$ = new Subject();
	readonly openActiveFolderNotify$ = new Subject();
	readonly activeRouteParams$ = new BehaviorSubject({});

	readonly mailboxes$ = this.update$.pipe(
		startWith(0),
		switchMap(() => {
			return this.server.mail.getMailboxList();
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	readonly unauthorizedMailboxes$ = new BehaviorSubject([]);

	get unauthorizedMailboxes() {
		return this.unauthorizedMailboxes$.value;
	}

	set unauthorizedMailboxes(value) {
		this.unauthorizedMailboxes$.next(value);
	}

	readonly mailboxesTree$ = new BehaviorSubject([]);
	readonly commonUnreadCounter$ = new BehaviorSubject(0);
	readonly activeMailboxes$ = this.mailboxes$.pipe(map(mailboxes => mailboxes || [])); //.filter(m => !m?.isDisabled)));

	readonly systemFolders = [
		{
			order: 0,
			folderName: 'Входящие',
			resxKey: 'folderIncomming',
			keys: ['входящие', 'inbox'],
			folderType: ISyncFolderType.Inbox,
		},
		{
			order: 1,
			folderName: 'Черновики',
			resxKey: 'folderDrafts',
			keys: ['черновики', 'drafts'],
			folderType: ISyncFolderType.Drafts,
		},
		{
			order: 2,
			folderName: 'Отправленные',
			resxKey: 'folderSent',
			keys: ['отправленные', 'sent'],
			folderType: ISyncFolderType.Sent,
		},
		{
			order: 3,
			folderName: 'Удаленные',
			resxKey: 'folderDeleted',
			keys: ['корзина', 'удаленные', 'trash', 'deleted'],
			folderType: ISyncFolderType.Trash,
		},
		{
			order: 4,
			folderName: 'Архив',
			resxKey: 'folderArchive',
			keys: ['архив', 'archive'],
			folderType: ISyncFolderType.Archive,
		},
		{
			order: 5,
			folderName: 'Спам',
			resxKey: 'folderSpam',
			keys: ['спам', 'нежелательная почта', 'spam', 'junk', 'blocked'],
			folderType: ISyncFolderType.Spam,
		},
	];

	isUnauthorized(mailBoxId) {
		return this.unauthorizedMailboxes.find(mb => mb?.mailBoxId === mailBoxId);
	}

	setUnauthorizedMailbox(mailbox) {
		const curr = this.unauthorizedMailboxes.find(mb => mb?.mailBoxId === mailbox?.mailBoxId);

		if (curr) {
			return;
		}

		this.unauthorizedMailboxes = [...this.unauthorizedMailboxes, mailbox];
	}

	deleteUnauthorizedMailbox(mailboxId) {
		const unauthorizedMailboxes = this.unauthorizedMailboxes.filter(mb => mb?.mailBoxId !== mailboxId);
		this.unauthorizedMailboxes = [...unauthorizedMailboxes];
	}

	update() {
		this.update$.next(0 as any);
	}

	updatedNotify() {
		this.updatedNotify$.next(0 as any);
	}

	updateRouteParams(params: { mailBoxId: number; folderPath: string }) {
		this.activeRouteParams$.next(params);
	}

	openActiveFolder(mailBoxId, folderPath) {
		this.openActiveFolderNotify$.next({ mailBoxId, folderPath });
	}

	getMailboxList() {
		return this.server.mail.getMailboxList();
	}

	getFoldersList(mailBoxId: number) {
		return this.server.mail.getFolders({
			mailBoxId: mailBoxId,
			getWatchFoldersOnly: true,
		});
	}
}
