import { MsOfficeClickActions } from '../users';

export interface IAppSettingsAnonymousDto {
	[key: string]: any;
	Languages: IAppSettingAnonymousLanguageDto[];
	Providers: IAppSettingAnonymousAuthProvider[];
	AppLogoPath: string;
	AppLogoPathSPAHorizontal: string;
	AppLogoPathSPAHorizontalDark: string;
	AppLogoPathSPAHorizontalHeight: string;
	AppLogoPathSPAHorizontalWidth: string;
	AppLogoPathSPAVertical: string;
	AppLogoPathSPAVerticalDark: string;
	AppLogoPathSPAVerticalHeight: string;
	AppLogoPathSPAVerticalWidth: string;
	AppLogoName: string;
	ApplicationName: string;
	ApplicationNameShort: string;
	ApplicationEmail: string;
	CustomSettings: Partial<{
		[key: string]: any;
		matomo: {
			serverAddress: string;
			siteId: number;
			analyticGroups?: Array<any>;
		};
		charts: string;
		requestTimeout?: number;
		requestTimeoutExcludeUrls?: string[];
		backgroundTasks?: Record<
			string,
			Partial<{
				isEnabled: boolean;
				interval: number;
			}>
		>;
		AdminPath: string;
		spaResources: Array<
			Partial<{
				type: string;
				src: string;
			}>
		>;
		newEpTable: Array<{ users: Array<number> | '*'; subcat: Array<number> | '*' }>;
		AuthConfig?: { AuthTypes: IAuthConfigLoginMethod[] };
	}>;
	LoginLogoPath: string;
	MobileAppSettings: { mspaByDefault: boolean };
	NavigationPanelLogo: string;
	WelcomeText: string;
	IosApplicationUrl: string;
	AndroidApplicationUrl: string;
	ShowTaskByIDIfExists?: boolean;
	EnableEmail: boolean;
	EnableSMS: boolean;
	WebAppsEnabled?: boolean;
	WebDavEnabled?: boolean;
	Services?: {
		Conference?: {
			Domain?: string;
			OperationAddress?: string; // will be change
			RoomPrefix?: string;
		};
		Jitsi?: {
			Domain?: string;
			OperationAddress?: string; // will be change
			RoomPrefix?: string;
		};
	};
	UpdateLastActiveTime?: number;
	ChangeLanguageInAppBar?: boolean;
	VersionInfo?: string;
	RegistrationIsAllowed?: boolean;
	MaxRequestLength: number;
	CustomAdminPanelPath?: string;
	EverybodyVirtualGroupId?: number;
	NobodyVirtualGroupId?: number;
	IsPostgreDB?: boolean;
	AppEngine: 'TC' | 'Uniform';
	MaxExcelExportRows?: number;
	AuthConfig?: { AuthTypes: IAuthConfigLoginMethod[] };
	BackgroundLoginPagePath?: string;
	NumberCharactersForSearch?: number;
}

export interface IAppSettingAnonymousLanguageDto {
	Alias: string;
	IsDefault: boolean;
	Culture: string;
	IsInternational: boolean;
	Name: string;
	Id: number;
}

export type AuthConfigLoginType = 'login-pass' | 'phone-code' | 'email-code';
export type AuthConfigLoginVisibility = 'all' | 'mobile' | 'web';

export interface IAuthConfigLoginMethod {
	Type: AuthConfigLoginType;
	IsDefault: boolean;
	AllowRegister: boolean;
	AutoRegister: boolean;
	PrivacyLink: string;
	HideProviders: boolean;
	Visibility: AuthConfigLoginVisibility;
}

export interface IAppSettingAnonymousAuthProvider {
	ProviderType: ProviderType;
	IsDefault: boolean;
	IsHidden: boolean;
	Name: string;
	Id: number;
	LinkForPasswordRecovery?: string;
	IsExternal?: boolean;
	Settings?: Partial<{
		LoginPath: string;
		LogoutPath: string;
	}>;
}

export enum ProviderType {
	none = 'None',
	activeDirectory = 'ActiveDirectory',
	radius = 'Radius',
	openLdap = 'OpenLDAP',
	saml = 'SAML',
}

export interface IApplicationSettingsDto {
	isCustomerZone: boolean;
	version: number;
	culture: string;
	workStartTimeMinutes: number;
	workEndTimeMinutes: number;
	useWebAppsDocumentView: boolean;
	useWebDavDocumentEdit: boolean;
	portalBlockPalette: string[];
	applicationPath: string;
	fileOnClickActionMode: MsOfficeClickActions;
}

export function isExternalAuthProvider(provider: IAppSettingAnonymousAuthProvider) {
	return provider?.ProviderType === ProviderType.saml || provider?.IsExternal;
}
