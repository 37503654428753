import { encode, decode, btoa, atob } from 'js-base64';
import { encode as encodeArr } from 'typescript-base64-arraybuffer';

export function base64Encode(str: string, urlSafe?: boolean): string {
	return encode(str, urlSafe);
}

export function base64Decode(str: string): string {
	return decode(str);
}

export function base64Btoa(str: string): string {
	return btoa(str);
}

export function base64Atob(str: string): string {
	return atob(str);
}

export function arrayBufferToBase64(arr: ArrayBuffer): string {
	return encodeArr(arr);
}
