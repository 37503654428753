import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	OnInit,
	Output,
	ViewEncapsulation,
	Input,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationFeatureProvider } from '@spa/facade/features/navigation/feature-provider';
import { TickerProvider } from '@spa/facade/features/tickers/providers';
import { LocalStorageProvider, UrlProvider, ViewDestroyStreamService } from '@valhalla/core';
import { BooleanFilter, ITree, ITreeItem, rxHandler } from '@valhalla/utils';
import { BehaviorSubject, EMPTY, Observable, combineLatest } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { DataHttpService, FavoritesDataHttpService } from '@valhalla/data/http';
import { ResourceService } from '@spa/localization/resource.service';
export interface IMyTaskMenuItem extends ITreeItem, ITree<IMyTaskMenuItem> {
	route?: string;
	queryParams?: any;
	id: number;
	name: string;
	translate?: string;
	url?: string;
	icon?: string;
	badge$?: Observable<number>;
	badgeNew$?: Observable<number>;
	badgeWarn$?: Observable<number>;
	children: IMyTaskMenuItem[];
	expanded?: boolean;
	isAvailable: boolean;
}

export interface IMyTaskMenu {
	expanded: boolean;
	menu: IMyTaskMenuItem[];
}

@Component({
	selector: 'vh-layout-my-tasks-nav-panel',
	templateUrl: 'my-tasks-panel.component.html',
	styleUrls: ['my-tasks-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class MyTasksNavLayoutPanelComponent implements OnInit {
	constructor(
		readonly destroy$: ViewDestroyStreamService,
		readonly cdr: ChangeDetectorRef,
		readonly tickers: TickerProvider,
		readonly router: Router,
		readonly urlBuilder: UrlProvider,
		readonly favoritesService: FavoritesDataHttpService,
		readonly resourceService: ResourceService,
		readonly localization: ResourceService,
		readonly localStorage: LocalStorageProvider,
		readonly server: DataHttpService,
		readonly navState: NavigationFeatureProvider
	) {}

	@HostBinding('class.vh-layout-my-tasks-nav-panel')
	hostClassSelector = true;

	@Output()
	expandCollapseEvent = new EventEmitter();

	@Input()
	set search(value: string) {
		this.searchInput$.next(value);
	}

	get search() {
		return this.searchInput$.value;
	}
	readonly IsFeedsViewOnly$ = this.server.config
		.getAppSettingsAnonymous()
		.pipe(map(settings => settings?.CustomSettings?.IsFeedsViewOnly));

	readonly favoritesMenu$ = this.navState.select(state => state.menus.favorites);

	readonly myTasksConfig$ = this.favoritesMenu$.pipe(
		takeUntil(this.destroy$),
		map(menu => menu?.myTasksConfig || null)
	);

	readonly menu$ = new BehaviorSubject<IMyTaskMenu>(this.getInitialMenu());
	readonly menuParent$ = this.menu$.pipe(
		map(menu => {
			return {
				id: 0,
				expanded: menu.expanded,
			};
		})
	);

	readonly searchHandler = rxHandler<string>();

	// readonly searchInput$ = from<string>(this.searchHandler as any).pipe(startWith(''));
	readonly searchInput$ = new BehaviorSubject<string>('');

	readonly searchInputHasValue$ = this.searchInput$.pipe(distinctUntilChanged(), map(BooleanFilter));

	readonly searchMenu$ = combineLatest([
		this.menu$,
		this.searchInput$.pipe(debounceTime(300)),
		this.localization.resolveCurrentResource('common'),
	]).pipe(
		map(([menus, query, translate]) => {
			const storageMenu: IMyTaskMenu = this.localStorage.get('myTaskMenu');

			menus.expanded = storageMenu.expanded;
			menus.menu.map(m => {
				m.expanded = storageMenu.menu.find(e => e.id === m.id)?.expanded || false;
				return m;
			});
			menus.menu = menus.menu.filter(m => m.isAvailable);

			if (!query) {
				return menus.menu;
			}
			const menusClone = [
				...menus.menu.map(m => {
					m.children = m.children.map(c => {
						return Object.assign({}, c);
					});
					return Object.assign({}, m);
				}),
			];
			return menusClone
				.filter(m => {
					const getLocalKey = (key: string) => {
						return translate[key.replace('common.', '')];
					};
					const menuName = getLocalKey(m.name);
					if (m?.children?.length) {
						const filterCildren = m.children.filter(c =>
							getLocalKey(c.name).toLowerCase().includes(query.toLowerCase())
						);
						if (filterCildren?.length) {
							m.children = filterCildren;
							return true;
						} else {
							return false;
						}
					}
					return menuName.toLowerCase().includes(query.toLowerCase());
				})
				.map(menu => {
					if (query) {
						menus.expanded = true;
						menu.expanded = true;
					}
					return menu;
				}, []);
		})
	);

	ngOnInit() {
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe(e => {
			if (e instanceof NavigationEnd) {
				this.cdr.detectChanges();
			}
		});
	}

	onExpandCollapse() {
		this.expandCollapseEvent.emit();
	}

	navLink(link, e?: Event) {
		const mouseEvent = e as MouseEvent;
		const openInNewTab = mouseEvent?.ctrlKey || mouseEvent?.metaKey;

		if (openInNewTab) {
			let serializeUrl = this.router.serializeUrl(
				this.router.createUrlTree([link.route], { queryParams: link.queryParams })
			);
			serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
			return window.open(serializeUrl, '_blank');
		} else {
			return this.router.navigate([link.route], { queryParams: link.queryParams });
		}
	}

	isItemSelected(link): boolean {
		let serializeUrl = this.router.serializeUrl(
			this.router.createUrlTree([link.route], { queryParams: link.queryParams })
		);
		serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
		const spaLinkPart = '/spa';
		if (serializeUrl.includes(spaLinkPart)) {
			serializeUrl = serializeUrl.substr(serializeUrl.indexOf(spaLinkPart) + spaLinkPart.length);
		}
		return this.router.url === serializeUrl;
	}

	getRoute(type) {
		return `/tasks/${type}/grid`;
	}

	getInitialMenu() {
		let menu;
		combineLatest([this.myTasksConfig$, this.IsFeedsViewOnly$])
			.pipe()
			.subscribe(([myTasksConfig, val]) => {
				menu = {
					expanded: false,
					menu: [
						{
							name: 'common.personalTasksOwnerFolder',
							icon: 'assignment_ind_outline',
							id: 1,
							route: val ? '/feeds/tasks' : this.getRoute('FromYou'),
							queryParams: {
								all: false,
								feedType: 'Owner',
							},
							url: '/tasks/FromYou/grid',
							badge$: this.tickers.tickerValues$.pipe(map(t => t?.systemTickers?.allTasksUserOwns)),
							badgeNew$: this.tickers.tickerValues$.pipe(map(t => t?.systemTickers?.allNewTasksUserOwns)),
							isAvailable: myTasksConfig?.ownerTasks ?? true,
							children: [],
						},
						{
							name: 'common.personalTasksPerformerFolder',
							icon: 'assignment_outline',
							id: 2,
							route: val ? '/feeds/tasks' : this.getRoute('YouPerformerTasks'),
							queryParams: {
								all: false,
								feedType: 'Performer',
							},
							url: '/tasks/FromYou/grid',
							isAvailable: myTasksConfig?.performerTasks ?? true,
							badge$: this.tickers.tickerValues$.pipe(map(t => t?.systemTickers?.allTasksUserPerforms)),
							badgeNew$: this.tickers.tickerValues$.pipe(map(t => t?.systemTickers?.allNewTasksUserPerforms)),
							children: [],
						},
						{
							name: 'common.youSubscribed',
							icon: 'assignment_ind_outline',
							id: 3,
							isAvailable: myTasksConfig?.subscriberTasks ?? true,
							route: val ? '/feeds/tasks' : this.getRoute('Subscribed'),
							queryParams: {
								all: false,
								feedType: 'All',
							},
							url: '/tasks/Subscribed/grid',
							children: [],
						},
					],
				} as IMyTaskMenu;

				if (!val) {
					menu.menu = [
						...menu.menu,
						{
							name: 'common.personalTasksSignaturesFolder',
							id: 4,
							isAvailable: myTasksConfig?.signatureTasks ?? true,
							icon: 'assignment_turned_in', //
							expanded: false,
							children: [
								{
									route: '/resolutions',
									name: 'common.personalTasksSignaturesFolderOnSign',
									id: -1,
									url: '/syndicate.aspx?forceUrl=/newCustomGrid.aspx?type=ToSign',
									badge$: this.tickers.tickerValues$.pipe(map(t => t?.systemTickers?.signaturesCount)),
									badgeWarn$: this.tickers.tickerValues$.pipe(map(t => t?.systemTickers?.overdueSigns)),
									expanded: true,
									children: [],
								},
								{
									route: this.getRoute('Signed'),
									name: 'common.personalTasksSignaturesFolderAccepted',
									id: -1,
									url: '/syndicate.aspx?forceUrl=/newCustomGrid.aspx?type=Signed',
									children: [],
								},
								{
									route: this.getRoute('Declined'),
									name: 'common.personalTasksSignaturesFolderRejected',
									id: -1,
									url: '/syndicate.aspx?forceUrl=/newCustomGrid.aspx?type=Declined',
									children: [],
								},
								{
									route: this.getRoute('Requested'),
									name: 'common.personalTasksSignaturesFolderRequested',
									id: -1,
									url: '/syndicate.aspx?forceUrl=/newCustomGrid.aspx?type=Requested',
									children: [],
								},
							],
						},
						{
							name: 'common.personalTasksSubordinatesTasks',
							id: 5,
							icon: 'assignment_ind_outline',
							expanded: false,
							isAvailable: myTasksConfig?.subordinatesTasks ?? true,
							children: [
								{
									route: this.getRoute('YourGroupsOwners'),
									queryParams: {
										OrgUnitId: 'all',
										// url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroups&OrgUnitId=all',
									},
									name: 'common.personalTasksSubordinatesOwners',
									id: -1,
									url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroups&OrgUnitId=all',
									children: [],
								},
								{
									route: this.getRoute('YourGroupsPerformers'),
									queryParams: {
										OrgUnitId: 'all',
										// url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroups&OrgUnitId=all',
									},
									name: 'common.personalTasksSubordinatesPerformers',
									id: -1,
									url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroups&OrgUnitId=all',
									children: [],
								},
								{
									route: this.getRoute('YourGroupsOverdue'),
									queryParams: {
										// url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroupsOverdue',
									},
									name: 'common.interfaceOverdueTasks',
									id: -1,
									url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroupsOverdue',
									children: [],
								},
							],
						},
						{
							name: 'common.personalTasksSubordinatesSignaturesFolder',
							id: 6,
							isAvailable: myTasksConfig?.subordinatesSignatures ?? true,
							icon: 'assignment_turned_in',
							expanded: false,
							children: [
								{
									route: this.getRoute('YourGroupsActiveSigns'),
									queryParams: {
										// url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroupsActiveSigns',
									},
									name: 'common.interfaceActiveSignatures',
									id: -1,
									url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroupsActiveSigns',
									children: [],
								},
								{
									route: this.getRoute('YourGroupsSignSigns'),
									queryParams: {
										// url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroupsSignSigns',
									},
									name: 'common.personalTasksSignaturesFolderAcceptedBySubordinates',
									id: -1,
									url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroupsSignSigns',
									children: [],
								},
								{
									route: this.getRoute('YourGroupsOverdueSigns'),
									queryParams: {
										// url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroupsOverdueSigns',
									},
									name: 'common.personalTasksSignaturesFolderOverdueBySubordinates',
									id: -1,
									url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroupsOverdueSigns',
									children: [],
								},
								{
									route: this.getRoute('YourGroupsRefusedSigns'),
									queryParams: {
										// url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroupsRefusedSigns',
									},
									name: 'common.personalTasksSignaturesFolderRejectedBySubordinates',
									id: -1,
									url: '/syndicate.aspx?forceUrl=/NewCustomGrid.aspx?Type=YourGroupsRefusedSigns',
									children: [],
								},
							],
						},
					];
				}
			});

		const storageMenu = {
			expanded: menu.expanded,
			menu: menu.menu.map(m => {
				return { id: m.id, expanded: m?.expanded };
			}),
		};
		const myTaskMenu: IMyTaskMenu = this.localStorage.get('myTaskMenu');

		if (!myTaskMenu) {
			this.localStorage.set('myTaskMenu', storageMenu);
		}

		return menu;
	}

	addToFavorites(data) {
		const fav = {
			link: data.linkedObject.url,
			name: data.name,
			isJsFunction: false,
			customImagePath: null,
			customImageClass: 'subcatFolder',
			type: '1',
			linkedObjectId: null,
			folderId: null,
		};
		this.favoritesService
			.addLinkToFavorites(fav)
			.pipe(
				take(1),
				takeUntil(this.destroy$),
				catchError(err => {
					console.error(err);
					return EMPTY;
				})
			)
			.subscribe();
	}

	onExpand(event: IMyTaskMenuItem) {
		const storageMenu: IMyTaskMenu = this.localStorage.get('myTaskMenu');

		if (event.id === 0) {
			storageMenu.expanded = !storageMenu.expanded;
			this.localStorage.set('myTaskMenu', storageMenu);
			return;
		}

		storageMenu.menu = storageMenu.menu.map(m => {
			if (m.id === event.id) {
				m.expanded = !event.expanded;
			}
			return m;
		});
		this.localStorage.set('myTaskMenu', storageMenu);
		return;
	}
}
