import { ModuleWithProviders, NgModule } from '@angular/core';

import { ClassTransformService } from './abstract';
import { ClassTransformServiceImpl } from './class-transform.service';

@NgModule()
export class ClassTransformModule {
	static forRoot(): ModuleWithProviders<ClassTransformModule> {
		return {
			ngModule: ClassTransformModule,
			providers: [
				{
					provide: ClassTransformService,
					useClass: ClassTransformServiceImpl,
				},
			],
		};
	}
}
