import { Effect, ExtractPayloadType, IAction, ofType } from '@valhalla/core';
import { merge, of } from 'rxjs';
import { catchError, exhaustMap, map, shareReplay, switchMap } from 'rxjs/operators';

import * as actions from '../actions';
import { IFavoriteFolder, IFavoriteNode } from '../providers/data-provider.dto';
import { INavigationState, NavigationMenu } from '../state';
import { NavigationEffectDependencies } from './dependency';

export const loadNavigationMenuEffect: Effect<
	IAction<ExtractPayloadType<typeof actions.loadRequestActionCreator>>,
	IAction,
	INavigationState,
	NavigationEffectDependencies
> = (actions$, state$, deps) => {
	const logger = deps.loggerFactory.createLogger('loadNavigationMenuEffect');

	const requestMapper = (menuId: NavigationMenu) => {
		const favMenuNew$ = deps.dataProvider.getFavoritesMenuNew().pipe(shareReplay({ bufferSize: 1, refCount: true }));
		return {
			[NavigationMenu.assistantFavorite]: favMenuNew$.pipe(
				map(({ assistantFavoriteMenus }) => {
					const favoritesAssistant = assistantFavoriteMenus.map(({ buttons, folders, nodes, userId, userName }) => {
						const expanded =
							state$.value.menus.assistantFavorite.menusAssistant.find(el => el.userId === userId)?.expandedBlocks ||
							[];

						const favorites = {
							id: NavigationMenu.favorites,
							userId,
							userName,
							buttons,
							folders: folders
								.map(f => {
									f.subMenu = nodes.filter(n => n.folderId === f.id);
									f.isExpand = expanded.includes(f.id);
									f.subFolders = folders.filter(n => n.parentId === f.id);
									return f;
								})
								.filter(f => f.name !== 'Комментарии' && f.name !== 'Comments' && !f.parentId),
							nodes: nodes.sort(sortByOrder),
							expandedBlocks: expanded,
						};

						const workGroupFolders = mergeFolders(favorites.folders.filter(f => f.isWorkGroupFolder));
						const nonWorkGroupFolders = favorites.folders.filter(f => !f.isWorkGroupFolder);

						favorites.folders = [...workGroupFolders, ...nonWorkGroupFolders];
						favorites.folders.push({
							groupId: 1000,
							id: 1000,
							isExpand: expanded.includes(1000),
							isSystemFolder: false,
							isWorkGroupFolder: false,
							name: 'ИзбранныеПунктыЮзера',
							order: 1000,
							parentId: null,
							userId: null,
							subMenu: nodes.filter(n => !n.folderId),
						});
						return favorites;
					});

					return {
						menusAssistant: favoritesAssistant,
					};
				})
			),
			[NavigationMenu.favorites]: favMenuNew$.pipe(
				map(({ buttons, folders, nodes, myTasksConfig }) => {
					const expanded = state$.value.menus.favorites.expandedBlocks || [];

					const favorites = {
						id: NavigationMenu.favorites,
						buttons,
						folders: folders
							.map(f => {
								f.subMenu = nodes.filter(n => n.folderId === f.id);
								f.isExpand = expanded.includes(f.id);
								f.subFolders = folders.filter(n => n.parentId === f.id);
								return f;
							})
							.filter(f => f.name !== 'Комментарии' && f.name !== 'Comments' && !f.parentId),
						nodes: nodes.sort(sortByOrder),
						expandedBlocks: expanded,
						myTasksConfig: myTasksConfig,
					};

					const workGroupFolders = mergeFolders(favorites.folders.filter(f => f.isWorkGroupFolder));
					const nonWorkGroupFolders = favorites.folders.filter(f => !f.isWorkGroupFolder);

					favorites.folders = [...workGroupFolders, ...nonWorkGroupFolders];

					favorites.folders.push({
						groupId: 1000,
						id: 1000,
						isExpand: expanded.includes(1000),
						isSystemFolder: false,
						isWorkGroupFolder: false,
						name: 'ИзбранныеПунктыЮзера',
						order: 1000,
						parentId: null,
						userId: null,
						subMenu: nodes.filter(n => !n.folderId),
					});

					return favorites;
				})
			),
			[NavigationMenu.categories]: deps.dataProvider.getNavigationCategoriesMenu().pipe(
				map(({ menuView, records, allTasksUserOwns, allTasksUserPerforms }) => ({
					menuView,
					records,
					menuId: NavigationMenu.categories,
					buttons: [],
					allTasksUserOwns,
					allTasksUserPerforms,
				}))
			),
			[NavigationMenu.favoritesNew]: of({
				menuView: [],
				records: {},
				menuId: NavigationMenu.favoritesNew,
				buttons: [],
			}),
		}[menuId];
	};
	return actions$.pipe(
		ofType(actions.FacadeNavigationAction.loadDataRequest),
		map(action => action.payload.map(requestMapper)),
		exhaustMap(requests => merge(...requests)),
		map(
			({
				records,
				menuId,
				menuView,
				buttons,
				id,
				folders,
				nodes,
				expandedBlocks,
				allTasksUserOwns,
				allTasksUserPerforms,
				menusAssistant,
				myTasksConfig,
			}) => {
				if (id) {
					return actions.updateFavoritesItemsActionCreator({
						id,
						buttons,
						folders,
						nodes,
						expandedBlocks: expandedBlocks,
						myTasksConfig: myTasksConfig,
					});
				}
				if (menusAssistant) {
					return actions.updateFavoritesItemsAssistantActionCreator({
						menusAssistant,
					});
				}
				return actions.loadSuccessActionCreator({
					menuItems: records,
					menuId,
					menuView,
					buttons,
					allTasksUserOwns,
					allTasksUserPerforms,
				});
			}
		),
		catchError(e => {
			logger.error(e.message, e);
			return of(actions.loadErrorActionCreator({ error: e }));
		})
	);
};

function sortByOrder(a: IFavoriteNode, b: IFavoriteNode) {
	if (sortByGroupOrder(a, b) === 0) {
		if (a.order > b.order) {
			return 1;
		}
		if (a.order < b.order) {
			return -1;
		}
	}
	return sortByGroupOrder(a, b);
}

function sortByGroupOrder(a: IFavoriteNode, b: IFavoriteNode) {
	if (!Number.isInteger(b.groupOrder) && !Number.isInteger(a.groupOrder)) {
		return 0;
	}
	if (!Number.isInteger(b.groupOrder) && Number.isInteger(a.groupOrder)) {
		return -1;
	}
	if (!Number.isInteger(a.groupOrder) && Number.isInteger(b.groupOrder)) {
		return 1;
	}
	if (a.groupOrder > b.groupOrder) {
		return 1;
	}
	if (a.groupOrder < b.groupOrder) {
		return -1;
	}
	return 0;
}

function mergeFolders(rootFolders: IFavoriteFolder[], parentNode?: IFavoriteFolder) {
	const foldersWithSameName = new Map<number, IFavoriteFolder[]>();
	const duplicatedFolders: number[] = [];

	rootFolders = rootFolders.filter(
		f => (f.subFolders && f.subFolders.length > 0) || (f.subMenu && f.subMenu.length > 0)
	);

	rootFolders.forEach(rootFolder => {
		if (parentNode) {
			rootFolder.parentId == parentNode.id;
		}

		if (rootFolder.subFolders && rootFolder.subFolders.length > 0) {
			// проходим вниз по одной ветке
			rootFolder.subFolders = mergeFolders(rootFolder.subFolders, rootFolder);
		}

		if (!foldersWithSameName.has(rootFolder.id) && !(duplicatedFolders.filter(x => x === rootFolder.id).length > 0)) {
			const sameFoldersWithName = rootFolders.filter(x => x.name === rootFolder.name && x.id !== rootFolder.id);
			if (sameFoldersWithName.length > 0) {
				duplicatedFolders.push(...sameFoldersWithName.map(x => x.id));
				foldersWithSameName.set(rootFolder.id, sameFoldersWithName);
			}
		}
	});

	rootFolders = rootFolders.filter(folder => {
		if (duplicatedFolders.filter(x => x === folder.id).length > 0) {
			return false;
		}

		if (foldersWithSameName.has(folder.id)) {
			foldersWithSameName.get(folder.id).forEach(duplicatedF => {
				if (folder.subFolders) {
					folder.subFolders.push(...duplicatedF.subFolders);
					folder.subMenu.push(...duplicatedF.subMenu);
				}
			});

			return true;
		}
		return true;
	});

	// Если были изменения
	if (foldersWithSameName.size > 0) {
		mergeFolders(rootFolders);
	}

	return rootFolders;
}
