import { IGetUserSettingsResult, VisibleValue } from '@valhalla/data/http';

// tslint:disable-next-line: no-empty-interface
export interface IUserSettingsState extends IGetUserSettingsResult {}

export function createDefaultUserSettingsState(): Partial<IUserSettingsState> {
	return {
		navigationPanelLogo: VisibleValue.noSet,
		calendarVisible: VisibleValue.noSet,
		categoryVisible: VisibleValue.noSet,
		chooseLanguageVisible: VisibleValue.noSet,
		createButtonVisible: VisibleValue.noSet,
		favoriteVisible: VisibleValue.noSet,
		lentaVisible: VisibleValue.noSet,
		chatsVisible: VisibleValue.noSet,
		mailVisible: VisibleValue.noSet,
		personalVisible: VisibleValue.noSet,
		reportVisible: VisibleValue.noSet,
		searchVisible: VisibleValue.noSet,
		staffVisible: VisibleValue.noSet,
		spacesVisible: VisibleValue.noSet,
	};
}
