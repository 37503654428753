import {
	Component,
	ComponentRef,
	ElementRef,
	HostBinding,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	Type,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ViewDestroyStreamService } from '@spa/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { IConfirmDialogClose } from '../confirm-dialog-close';

@Component({
	selector: 'vh-modal-generic',
	templateUrl: './modal-generic.component.html',
	styleUrls: ['./modal-generic.component.scss'],
	providers: [ViewDestroyStreamService],
})
export class ModalGenericComponent implements OnInit, OnDestroy, IConfirmDialogClose {
	constructor(
		readonly dialogRef: MatDialogRef<ModalGenericComponent>,
		@Inject(MAT_DIALOG_DATA) readonly dialogData: IModalGenericComponentDialogData,
		readonly destroy$: ViewDestroyStreamService,
		readonly elRef: ElementRef<HTMLElement>,
		readonly mobileService: MobileViewService
	) {}

	@Input()
	showToolbar = true;

	@Input()
	showClose = true;

	@Input()
	title: string = this.dialogData?.title;

	@ViewChild('componentPlace', { read: ViewContainerRef, static: true })
	vcr: ViewContainerRef;

	@HostBinding('class.mobile')
	get isMobile(): boolean {
		return this.mobileService.mobileMode;
	}

	@Input()
	@HostBinding('class.no-overflow')
	noOverflow = this.dialogData?.dialogConfig?.noOverflow;

	componentRef: ComponentRef<any>;

	toolbarPortalKey$ = new BehaviorSubject<any>(undefined);

	get canOpenInNewTab() {
		return !!this.openInNewTabHandler;
	}

	get openInNewTabHandler() {
		if (typeof this.dialogData.openInNewTabHandler === 'function') {
			return this.dialogData.openInNewTabHandler;
		}
		if (typeof this.componentRef?.instance?.openInNewTabHandler === 'function') {
			return this.componentRef.instance.openInNewTabHandler.bind(this.componentRef.instance);
		}
	}

	ngOnInit() {
		this.createComponent().pipe(first()).subscribe();
	}

	ngOnDestroy() {
		this.componentRef?.destroy();
		this.componentRef = null;
	}

	createComponent() {
		if (this.componentRef) {
			return of(this.componentRef);
		}
		const getterValue = this.dialogData.componentGetter();
		return from(getterValue instanceof Promise ? getterValue : of(getterValue)).pipe(
			map(type => {
				this.componentRef = this.vcr.createComponent(type);
				this.dialogData.footerTemplate =
					this.dialogData.footerTemplate || this.dialogData.footerTemplateGetter?.(this.componentRef.instance);
				Object.entries(this.dialogData.componentInputs).forEach(([prop, val]) => {
					this.componentRef.instance[prop] = val;
				});
				if (this.dialogData.toolbarPortalKey) {
					this.toolbarPortalKey$.next(this.dialogData.toolbarPortalKey);
				}
				if (this.componentRef.instance.toolbarPortalKey$ instanceof Observable) {
					this.componentRef.instance.toolbarPortalKey$.pipe(takeUntil(this.destroy$)).subscribe(key => {
						this.toolbarPortalKey$.next(key);
					});
				}
				this.componentRef.changeDetectorRef.markForCheck();

				return this.componentRef;
			})
		);
	}

	close(dialogResult?: any) {
		this.dialogRef.close(dialogResult);
	}

	openInNewTab() {
		this.openInNewTabHandler?.call?.(null);
	}

	confirmDialogClose(result?: any): boolean {
		const component = this.componentRef?.instance as IConfirmDialogClose;
		if (typeof component?.confirmDialogClose === 'function') {
			return component.confirmDialogClose(result);
		}
	}
}

export interface IModalGenericComponentDialogData {
	componentGetter: () => Promise<Type<any>> | Type<any>;
	componentInputs: Record<string, any>;
	toolbarPortalKey?: any;
	openInNewTabHandler?: () => void;
	title?: string;
	footerTemplate?: TemplateRef<any>;
	footerTemplateGetter?: (componentInstance: any) => TemplateRef<any>;
	footerTemplateContext?: any;
	dialogConfig?: { noOverflow?: boolean };
}
