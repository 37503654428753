import { Injectable, Injector, inject } from '@angular/core';
import { getAppTopInjector, setAppTopInjector } from '@spa/api/injectors';
import { DataHttpService, IAppSettingsAnonymousDto } from '@spa/data/http';
import { addCss, addScript } from '@valhalla/utils';
import { EMPTY, Observable, catchError, forkJoin, map, of, switchMap, take } from 'rxjs';

type SpaResource = IAppSettingsAnonymousDto['CustomSettings']['spaResources'][0];

@Injectable({
	providedIn: 'root',
})
export class CustomResourcesService {
	protected server = inject(DataHttpService);
	protected injector = inject(Injector);

	init(): Observable<any> {
		if (!getAppTopInjector()) {
			setAppTopInjector(this.injector);
		}
		return this.server.config.appSettingsAnonymousConfig$.pipe(
			switchMap(c => this.loadResources(c.CustomSettings.spaResources)),
			catchError(err => {
				console.error(err);
				return of(null);
			}),
			take(1)
		);
	}

	protected loadResources(res: SpaResource[]): Observable<any> {
		if (!Array.isArray(res)) {
			return of(null);
		}
		return forkJoin(
			res.map(r => {
				switch (r.type) {
					case 'js':
					case 'jsm':
						return addScript({ src: r.src, type: r.type });
					case 'css':
						return addCss(r.src);
					default:
						break;
				}
				return of(null);
			})
		);
	}
}
