import { Injectable, inject } from '@angular/core';
import { CronService } from './cron.service';
import { EMPTY, filter, map, merge, scan, startWith } from 'rxjs';
import { CronNames } from './cron-names';

@Injectable({ providedIn: 'root' })
export class CronClient {
	#cron = inject(CronService);

	readonly knownNames = CronNames;

	from(name: string) {
		const info = this.#cron.getInfo(name);
		if (!info) {
			console.warn(`Cron name '${name}' not found`);
			return EMPTY;
		}
		return info.updated$.pipe(
			startWith(0),
			filter(() => info.firstEmited),
			map(() => ({ lastResult: info.lastResult, error: info.error }))
		);
	}
}
