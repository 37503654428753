<vh-modal-base
	[title]="'common.confirmTitle' | resx"
	(cancel)="closeModal()"
	(close)="closeModal()"
	(ok)="closeAndRemoveFolder()"
	okResx="common.yes"
	cancelResx="common.no"
>
	{{ deleteLabel }}
</vh-modal-base>
