import { inject, InjectionToken } from '@angular/core';

import { LOCALIZATION_OPTIONS } from './localization-options';

export type CultureToAliasMapperType = (culture: string, notFound?: string) => string;

export const CULTURE_TO_ALIAS_MAPPER = new InjectionToken<CultureToAliasMapperType>('culture to alias mapper', {
	factory: () => {
		const { cultureToAlias } = inject(LOCALIZATION_OPTIONS);

		return function mapper(culture: string, notFound = culture) {
			return cultureToAlias[culture] || notFound;
		};
	},
});
