import { IAvatarColor } from './avatar/avatar.model';

export function stringToDSColor(
	str: string,
	saturationPercent = 100,
	lightnessPercent = 40,
	useColorMap = false
): string {
	str = str?.trim();
	if (!str) {
		return;
	}
	let hash = 0;
	str = String(str);
	for (let i = 0; i < str.length; i++) {
		hash = (str.charCodeAt(i) + ((hash << 5) - hash)) | 0;
	}

	const h = Math.abs(hash % 100);

	if (h >= 0 && h < 10) {
		return useColorMap ? IAvatarColor.extramarkersRed : 'var(--extramarkers-red)';
	}
	if (h >= 10 && h < 20) {
		return useColorMap ? IAvatarColor.extramarkersPink : 'var(--extramarkers-pink)';
	}
	if (h >= 20 && h < 30) {
		return useColorMap ? IAvatarColor.extramarkersPurple : 'var(--extramarkers-purple)';
	}
	if (h >= 30 && h < 40) {
		return useColorMap ? IAvatarColor.extramarkersBlue : 'var(--extramarkers-blue)';
	}
	if (h >= 40 && h < 50) {
		return useColorMap ? IAvatarColor.extramarkersCyan : 'var(--extramarkers-cyan)';
	}
	if (h >= 50 && h < 60) {
		return useColorMap ? IAvatarColor.extramarkersGreen : 'var(--extramarkers-green)';
	}
	if (h >= 60 && h < 70) {
		return useColorMap ? IAvatarColor.extramarkersYellow : 'var(--extramarkers-yellow)';
	}
	if (h >= 70 && h < 80) {
		return useColorMap ? IAvatarColor.extramarkersOrange : 'var(--extramarkers-orange)';
	}
	if (h >= 80 && h < 90) {
		return useColorMap ? IAvatarColor.extramarkersBrown : 'var(--extramarkers-brown)';
	}
	if (h >= 90) {
		return useColorMap ? IAvatarColor.extramarkersGrey : 'var(--extramarkers-grey)';
	}

	return useColorMap ? IAvatarColor.extramarkersGrey : 'var(--extramarkers-grey)';
}

export function stringToHslColor(str: string, s: number, l: number) {
	let hash = 0;
	str = String(str);
	for (let i = 0; i < str.length; i++) {
		// tslint:disable-next-line:no-bitwise
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}

	const h = hash % 360;
	return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
}
