import { Provider } from '@angular/core';

import { LocalStorageProvider } from './abstract';
import { LocalStorageProviderImpl } from './provider';

export const dbLocalStorageProviders: Provider[] = [
	{
		provide: LocalStorageProvider,
		useClass: LocalStorageProviderImpl,
	},
];
