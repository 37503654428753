import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { API_TOKEN, API_TOKEN_HEADER } from './api-token';

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {
	constructor(
		@Optional() @Inject(API_TOKEN) readonly apiToken: string,
		@Optional() @Inject(API_TOKEN_HEADER) readonly apiTokenHeader: string
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.apiToken) {
			if (!this.apiTokenHeader) {
				// tslint:disable-next-line:no-console
				console.warn('API Token Header name is not provided, setup default 1FormaAuth');
			}
			const headerName = this.apiTokenHeader || '1FormaAuth';
			const withTokenReq = req.clone({
				setHeaders: {
					[headerName]: this.apiToken,
				},
			});
			return next.handle(withTokenReq);
		}

		return next.handle(req);
	}
}
