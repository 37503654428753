import { distinctUntilChanged } from 'rxjs/operators';

export function excludeUndefinedProps(o) {
	return Object.keys(o).reduce((result, key) => {
		if (o[key] !== undefined) {
			result[key] = o[key];
		}
		return result;
	}, {});
}

export function excludeProps<T>(o: T, ...keys: Array<keyof T>): Partial<T> {
	return Object.keys(o).reduce((result, key) => {
		if (!keys.includes(key as any)) {
			result[key] = o[key];
		}
		return result;
	}, {});
}

export function equalsObjectsByJson(a: any, b: any) {
	return JSON.stringify(a) === JSON.stringify(b);
}

export function distinctUntilChangedObjectKeys<T>(...keys: Array<keyof T>) {
	return distinctUntilChanged((prev: T, cur: T) => {
		let ks = keys;
		if (!ks.length) {
			if (Object.keys(prev).length !== Object.keys(cur).length) {
				return false;
			}
			ks = Object.keys(cur) as any;
		}
		const same = ks.every(key => prev[key] === cur[key]);
		return same;
	});
}
