import { IFileDialogOptions, IFileDialogResult } from '@spa/data/http';
import { IAttachment } from '@valhalla/data/entities';
import { Observable } from 'rxjs';

export type FileLink = number | string | Partial<IAttachment>;

export abstract class Downloader {
	abstract download(link: string, fileName?: string, avoidFileNameSanitizing?: boolean): Observable<any>;
	abstract downloadFiles(...ids: (FileLink | File)[]): void;
	abstract downloadAllFilesInComment(commentId: number): Observable<any>;
	abstract createDownloadLink(fileOrId: FileLink | Blob, getTrueMime?: boolean): string;
	abstract createPreviewPdfLink(fileOrId: FileLink): string;
	abstract createPreviewOfficeR7EditorLink(
		fileOrId: FileLink,
		mode?: IR7EditorMode,
		needEncode?: boolean,
		idKey?: string
	): string;
	abstract createPreviewOfficeWebAppLink(fileOrId: FileLink, qpDate?: boolean): string;
	abstract createPreviewWebDAVLink(fileOrId: FileLink, name: string): string;
	abstract createPreviewTextLink(fileOrId: FileLink): string;
	abstract createPreviewImageLink(fileOrId: FileLink): string;
	abstract downloadBytesArray(fileName: string, fileMime: string, fileBytes: any[]): void;
	abstract openFileDialog(options?: Partial<IFileDialogOptions>): Observable<IFileDialogResult>;
}

export enum IR7EditorMode {
	view = 'view',
	edit = 'edit',
}
