import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Inject, OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { RESOURCE_DEFINITION, RESOURCE_DEFINITION_ALL } from './resource-register';
import { IResourceDefinition } from './resource-types';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';

@Pipe({ name: 'resx', pure: false })
export class ResourceAsyncPipe implements PipeTransform, OnDestroy {
	constructor(
		protected readonly resource: ResourceService,
		@Inject(RESOURCE_DEFINITION_ALL) protected readonly definitions: IResourceDefinition[],
		protected cdr: ChangeDetectorRef
	) {
		this.asyncPipe = new AsyncPipe(cdr);
		definitions.forEach(def => {
			this.nsIdx[def.namespace] = true;
		});
	}

	protected asyncPipe: AsyncPipe;
	protected nsIdx: Record<string, boolean> = {};
	protected resolveCache = new Map<string, Observable<any>>();

	transform(resxKey: string, defaultValue?: string, debug = false) {
		if ((resxKey && !resxKey?.includes('.')) || resxKey?.includes('. ')) {
			return resxKey;
		}
		const [ns, name] = resxKey?.split('.') || [];
		if (!this.nsIdx[ns]) {
			return resxKey;
		}
		const notFound = this.resource.pending ? '' : name || '';
		if (!this.resolveCache.has(resxKey)) {
			this.resolveCache.set(resxKey, this.resource.resolveKey(resxKey, ...this.definitions));
		}
		// const resxVal$ = this.resource.resolveKey(resxKey, ...this.definitions);
		const resxVal$ = this.resolveCache.get(resxKey);
		const value = this.asyncPipe.transform(resxVal$);
		const result = value || defaultValue || notFound;
		if (debug) {
			// eslint-disable-next-line no-restricted-syntax
			console.debug(`${resxKey}: ${result}`);
		}
		return result;
	}

	ngOnDestroy() {
		this.asyncPipe.ngOnDestroy();
		this.asyncPipe = null;
		this.resolveCache.clear();
	}
}
