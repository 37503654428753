import {
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { SpaApiEvents, SpaApiEventsEnum } from '@spa/api/events';
import { OpenPagesService } from '@spa/common/services/open-pages.service';
import { getActualAbsence, IAbsence, isUserOnline, IUserProfile, IUserShort } from '@valhalla/data/entities';
import {
	buildTreeFromOrgUnits,
	dateFromStringValhalla,
	getTreePathStrings,
	isToday,
	isYearEquals,
	rxCached,
} from '@valhalla/utils';
import { BehaviorSubject, from, map, Observable, switchMap, take } from 'rxjs';
import { SessionUser, UrlProvider } from '@spa/core';
import { DataHttpService } from '@spa/data/http';
import { ModalWindowsService } from '@spa/facade/features/modals';

const GroupMembersDialogComponent$ = from(
	import('@spa/common/components/user-mini-profile/group-members-dialog/group-members-dialog.component').then(
		m => m.GroupMembersDialogComponent
	)
);

@Component({
	selector: 'vh-common-user-mini-profile',
	templateUrl: './user-mini-profile.component.html',
	styleUrls: ['./user-mini-profile.component.scss'],
	//changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class UserMiniProfileComponent implements OnInit {
	constructor(
		readonly spaEvents: SpaApiEvents,
		readonly router: Router,
		readonly urlProvider: UrlProvider,
		readonly sessionUser: SessionUser,
		readonly openPages: OpenPagesService,
		readonly server: DataHttpService,
		readonly modals: ModalWindowsService
	) {}

	@Input()
	userId: number;

	@Input()
	groupId: number;

	@Input()
	userInfo: Partial<IUserProfile & { description: string; groupType: { description: string }; users: IUserShort[] }>;

	@Input()
	isChatEnable: boolean;

	@Input()
	openProfileModal = false;

	@Output()
	close = new EventEmitter();

	@HostBinding('class.vh-common-user-mini-profile')
	hostClassSelector = true;

	@HostListener('mouseenter')
	onMouseEnter(): void {
		this.hovered.next(true);
	}

	@HostListener('mouseleave')
	onMouseLeave(): void {
		this.hovered.next(false);
	}

	absence: IAbsence;
	readonly hovered = new BehaviorSubject<boolean>(false);
	readonly spaBoot = Boolean(window.top['__IS_1F_SPA_BOOT__']);
	userOrgUnitsString: string[];

	private _cachedUserProfile$: Observable<IUserProfile>;
	get cachedUserProfile$(): Observable<IUserProfile> {
		if (!this._cachedUserProfile$) {
			this._cachedUserProfile$ = rxCached(
				this.groupId ? this.server.users.getMembersGroup(this.groupId) : this.server.users.getUserProfile(this.userId)
			);
		}
		return this._cachedUserProfile$;
	}

	readonly isSessionUser$ = this.sessionUser.userId$.pipe(
		map(userId => userId === this.userInfo?.userId || userId === this.userId)
	);
	readonly vksRoom$: Observable<string | null> = this.server.config.getAppSettingsAnonymous().pipe(
		map(settings => {
			const allUrlPathDefined = Boolean(
				settings?.Services?.Conference?.Domain && this.userInfo?.jitsiServicePersonalRoom
			);
			if (allUrlPathDefined) {
				return `https://${settings?.Services?.Conference?.Domain}/${this.userInfo?.jitsiServicePersonalRoom}`;
			}
			return null;
		})
	);

	readonly isVksEnabled$ = this.server.config.isVKSEnable$;
	readonly loadingUserInfo$ = new BehaviorSubject<boolean>(true);

	get appointment(): string | undefined {
		return this.userInfo?.appointment;
	}

	get groupUsersLength(): number {
		return this.userInfo?.users?.length;
	}

	get groupDescription(): string {
		return this.userInfo?.groupType?.description;
	}

	get phone(): string {
		return this.userInfo?.phone;
	}

	get cellPhone(): string {
		return this.userInfo?.cellPhone;
	}

	ngOnInit(): void {
		this.cachedUserProfile$.pipe(take(1)).subscribe(userInfo => {
			this.userInfo = userInfo;
			this.absence = getActualAbsence(userInfo);
			this.loadingUserInfo$.next(false);
			if (this.userInfo.orgUnits?.length) {
				this.userOrgUnitsString = this.getOrgUnitsString();
			}
		});
	}

	getOrgUnitsString(): string[] {
		const orgUnitsTree = buildTreeFromOrgUnits(this.userInfo.orgUnits);
		const paths = getTreePathStrings(orgUnitsTree);
		return paths;
	}

	isOnline(user: Partial<IUserShort>) {
		return isUserOnline(user);
	}

	openChatWithUser(): void {
		this.router.navigate(['/chat/new'], {
			queryParams: {
				with: this.userId || this.userInfo?.userId,
				private: true,
			},
		});
	}

	openGroupMembersList(): void {
		GroupMembersDialogComponent$.pipe(
			take(1),
			switchMap(component => {
				return this.modals
					.openDialog(component, {
						data: {
							users: this.userInfo.users,
						},
					})
					.afterClosed();
			})
		).subscribe();
	}

	navigateCommand(commands: unknown[], e?: Event, extras?: unknown): void {
		e?.preventDefault();
		if (!this.spaBoot) {
			window.open(`/UserInfo.aspx?UserID=${commands[1]}`, '_blank');
		}
		if (this.spaEvents.currentWindowIsChildFrameOfSpa || this.spaEvents.currentWindowIsChild) {
			this.spaEvents.dispatchEvent(SpaApiEventsEnum.spaNavigate, { commands, extras }, null, window.top);
		} else {
			this.router.navigate(commands, extras);
		}
	}

	openUserProfile(userId: number, e: PointerEvent) {
		const ctrl = e.ctrlKey || e.metaKey || e.button === 1;
		this.openPages.openProfile({
			userId,
			modal: ctrl ? false : this.openProfileModal,
			newTab: ctrl,
		});
	}

	jitsiLinkHandler(e: MouseEvent): void {
		const inviteUserId = this.userInfo?.userId;
		this.server.users.sessionUserSettings$.pipe(take(1)).subscribe(user => {
			this.openPages.openVKS({
				roomName: user.jitsiServicePersonalRoom,
				inviteUserIds: inviteUserId,
				newTab: true,
			});
		});
	}

	dateFromString(dateString: string): Date {
		return dateFromStringValhalla(dateString);
	}

	absenceDateFormat(start: string, end: string): string {
		const startDate = this.dateFromString(start),
			endDate = this.dateFromString(end);

		if (isToday(startDate) && isToday(endDate)) {
			return 'HH:mm';
		}

		if (isYearEquals(startDate, endDate)) {
			return 'dd.MM';
		}
		return 'dd.MM.yyyy';
	}
}
