import { Observable } from 'rxjs';

import { IGetHistoryEmployeesDto, IGetHistoryTasksDto } from './dto';

export abstract class HistoryDataHttpService {
	abstract getTasks(criteria?: IHistoryGetTasksCriteria): Observable<IGetHistoryTasksDto[]>;
	abstract getEmployees(criteria?: Partial<IHistoryGetEmployeesCriteria>): Observable<IGetHistoryEmployeesDto[]>;
}

export interface IHistoryGetTasksCriteria {
	count: number;
}

export interface IHistoryGetEmployeesCriteria {
	groupsCount: number;
	coworkersCount: number;
	userOnly?: boolean;
}
