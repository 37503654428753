import { IAction, produce, ReducerBase } from '@valhalla/core';
import { merge } from 'lodash-es';

import * as actions from '../actions';
import { INavigationMenuState, INavigationState, NavigationMenu } from '../state';

export class NavigationLoadRequestReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.loadDataRequest;

	reduce(state: INavigationState, action: IAction) {
		return produce(state, ds => {
			ds.loading = true;
			ds.errors = null;
		});
	}
}

export class NavigationLoadSuccessReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.loadDataSuccess;

	reduce(state: INavigationState, action: IAction<actions.INavigationLoadSuccessPayload>) {
		const { menuView, menuId, menuItems, buttons, allTasksUserPerforms, allTasksUserOwns } = action.payload;
		const readOnlyMenu = state.menus[menuId] as INavigationMenuState;

		return produce(state, ds => {
			ds.loading = false;
			ds.errors = null;

			const menu = ds.menus[menuId] as INavigationMenuState;
			menu.menuItems = menuItems;
			menu.menuView = menuView;
			menu.buttons = buttons;
			menu.allTasksUserOwns = allTasksUserOwns;
			menu.allTasksUserPerforms = allTasksUserPerforms;

			// apply clients datas like isOpen
			Object.entries(menu.menuItems).forEach(([key, newItem]) => {
				const clientItem = readOnlyMenu.menuItems[key];
				if (!clientItem) {
					return;
				}
				newItem.isOpen = clientItem.isOpen;
			});
		});
	}
}

export class NavigationLoadErrorReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.loadDataError;

	reduce(state: INavigationState, action: IAction<actions.INavigationLoadErrorPayload>) {
		return produce(state, ds => {
			ds.loading = false;
			ds.errors = [action.payload.error];
		});
	}
}

export const loadReducers = [NavigationLoadRequestReducer, NavigationLoadSuccessReducer, NavigationLoadErrorReducer];
