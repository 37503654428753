// tslint:disable:no-empty-interface
import { Observable } from 'rxjs';

export abstract class AuthDataHttpService {
	abstract passwordRecovery(criteria: IPasswordRecoveryCriteria): Observable<any>;
	abstract changePassword(criteria: IChangePasswordCriteria): Observable<any>;
	abstract getCaptcha(): Observable<ICaptchaResponse>;
	//abstract getAuthToken(criteria: IAuthTokenCriteria): Observable<any>;
	abstract checkPasswordRecoveryCode(criteria: ICheckPasswordRecoveryCriteria): Observable<IAuthTokenResponse>;
}

export interface ICheckPasswordRecoveryCriteria {
	mode?: IModeType;
	restoreByLogin?: boolean;
	cellPhone?: string;
	email?: string;
	login?: string;
	invitationCode?: string;
}

export interface IAuthTokenCriteria {
	login: string;
	password: string;
	isPersistent?: boolean;
	providerId?: number;
	isBase64?: boolean;
	onlyForPasswordChange?: boolean;
}

export interface ICaptchaResponse {
	imageWithSecret: string;
	key: string;
}

export interface IChangePasswordCriteria {
	userId?: number;
	newPassword?: string;
}

export interface IPasswordRecoveryCriteria {
	mode?: IModeType;
	restoreByLogin?: boolean;
	cellPhone?: string;
	email?: string;
	login?: string;
	captchaKey?: string;
	captchaSecret?: string;
	language?: string;
}

export enum IModeType {
	email = 'Email',
	sms = 'SMS',
}

export interface IAuthTokenResponse {
	token: string;
	accessToken: string;
	refreshToken?: string;
}
