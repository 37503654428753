import { CdkPortal } from '@angular/cdk/portal';
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ViewDestroyStreamService } from '@spa/core';
import { InputSubject } from '@valhalla/utils';
import { BehaviorSubject, combineLatest, distinctUntilChanged, Observable, takeUntil } from 'rxjs';
import { IPortalHost, PortalHostService } from './portal-host.service';
import { PortalTargetService } from './portal-target.service';

const seq = (() => {
	let id = 0;
	return () => ++id;
})();

@Component({
	selector: 'vh-portal-host',
	exportAs: 'portalHost',
	template: `
		<ng-container *cdkPortal>
			<ng-content></ng-content>
		</ng-container>
		<ng-template [cdkPortalOutlet]="portalOutletValue$ | async"></ng-template>
		<ng-container *ngIf="showContent$ | async">
			<ng-template [cdkPortalOutlet]="portalContent$ | async"></ng-template>
		</ng-container>
	`,
	providers: [ViewDestroyStreamService],
})
export class PortalHostComponent implements IPortalHost, OnInit, OnDestroy {
	constructor(
		protected readonly hosts: PortalHostService,
		protected readonly destroy$: ViewDestroyStreamService,
		protected readonly targets: PortalTargetService
	) {}

	@Input()
	showContentIfEmpty = true;

	@Input()
	containerConstraint: ElementRef<HTMLElement> | HTMLElement;

	@ViewChild(CdkPortal, { static: true })
	portalContentRef: CdkPortal;

	@HostBinding('attr.id')
	readonly id = `vh-portal-host-${seq()}`;

	@Input()
	@InputSubject()
	@HostBinding('attr.vh-portal-host-name')
	name: string;
	name$: Observable<string>;

	// eslint-disable-next-line @typescript-eslint/member-ordering
	@Input()
	@InputSubject()
	key: any;
	key$: Observable<any>;

	portalOutletValue$ = new BehaviorSubject(null);

	readonly portalContent$ = new BehaviorSubject(null);
	readonly showContent$ = new BehaviorSubject(false);

	ngOnInit() {
		combineLatest([
			this.targets.update$,
			this.name$.pipe(distinctUntilChanged()),
			this.key$.pipe(distinctUntilChanged()),
		])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([, name, key]) => {
				const candidates = this.targets.targets(name, key);
				const target = this.containerConstraint
					? candidates.find(t => this.targetInContainerConstraints(t.elRef))
					: candidates?.[0];
				if (!target && this.showContentIfEmpty) {
					this.portalContent$.next(this.portalContentRef);
					this.showContent$.next(true);
				} else {
					this.showContent$.next(false);
					this.portalContent$.next(null);
				}

				this.setPortalTarget(target?.portalTargetRef);
			});
		this.hosts.reg(this);
		this.targets.notifyUpdate();
	}

	targetInContainerConstraints(target: HTMLElement | ElementRef<HTMLElement>) {
		const targetElement = target instanceof ElementRef ? target.nativeElement : target;
		if (this.containerConstraint) {
			const container =
				this.containerConstraint instanceof ElementRef
					? this.containerConstraint.nativeElement
					: this.containerConstraint;
			return container.contains(targetElement);
		}
		return false;
	}

	ngOnDestroy(): void {
		this.hosts.unreg(this);
	}

	setPortalTarget(target: any) {
		this.portalOutletValue$.next(target);
	}
}
