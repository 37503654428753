import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IApiResponse } from '../api-response';
import { DataHttpCommonService } from '../data-http-common.service';
import type { IEndpointUrlConfig } from '../endpoint.config';
import { EndpointUrlConfig } from '../endpoint.config';
import { UrlProvider } from '@valhalla/core';
import {
	DataSourceHttpService,
	IDataSourceGetConfigByEntityCriteria,
	IDataSourceGetConfigByTypeCriteria,
	IDataSourceGetConfigResult,
	IDataSourceGetDataByEntityTypeCriteria,
	IDataSourceGetDataByTypeCriteria,
	IDataSourceGetDataResult,
	IDataSourceSaveSettingsParams,
	IAdminDataSourceSaveSettingsParams,
	IGetDataSourceConfigResult,
	IDataSourceConfigResult,
	ICustomDataSource,
	ICustomColumn,
	ICustomColumnRequest,
	ICustomColumnUpdateRequest,
	ICustomColumnResponse,
	IGetDataByColumnRequest,
	IDataSourceParams,
	IDataSourceRes,
	IGetColumnDataResponse,
	IFormActionRequest,
	IFormCRUDAction,
	ISmartRecurrencesItem,
	ISmartPacksListItem,
	ISmartExpressionsItem,
	SmartPacksOnEventsActiveState,
	ISmartPacksOnEventsItem,
	IDataSourceSettings,
} from './abstract';
import {
	IDataSourceAggResponse,
	IGridContext,
} from '../../../pages/data-source/data-source-grid/data-source-grid-options';
import { IDataSourceFormConfig } from '@spa/pages/data-source/data-source-grid-forms/data-source-grid-forms.service';

@Injectable()
export class DataSourceHttpServiceImpl implements DataSourceHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly endpointConfig: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService,
		protected readonly url: UrlProvider
	) {}

	config(source: string, isAdmin?: boolean, queryParams?: Map<string, string>): Observable<IDataSourceConfigResult> {
		isAdmin = isAdmin || false;
		let additionalParams = '';
		if (queryParams) {
			additionalParams +=
				'&' +
				Object.keys(queryParams)
					.map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
					.join('&');
		}
		const url = this.common.getApiCoreUrl(`data-source/${source}/config?isAdmin=${isAdmin}${additionalParams}`);
		return this.http.get<IApiResponse>(url).pipe(
			map(res => {
				return this.normalizeDataSourceGridConfig(res.data, isAdmin);
			})
		);
	}

	configEntity(entityId: number, entityType: string): Observable<IDataSourceConfigResult> {
		const url = this.common.getApiCoreUrl(`data-source/${entityType}/${entityId}/config`);
		return this.http.get<IApiResponse>(url).pipe(
			map(res => {
				return this.normalizeDataSourceGridConfig(res.data, false);
			})
		);
	}

	configTaskUsed(source: string, blockId: number, taskId: number): Observable<IGetDataSourceConfigResult> {
		const url = this.common.getApiCoreUrl(`data-source/taskused/${blockId}/config?taskId=${taskId}`);
		return this.http.get<IApiResponse>(url).pipe(
			map(res => {
				return this.normalizeDataSourceGridConfig(res.data, false);
			})
		);
	}

	data(source: string, params: any): Observable<any> {
		const url = this.common.getApiCoreUrl(`data-source/${source}/data`);
		return this.http.post<IApiResponse>(url, params).pipe(map(r => r.data));
	}

	getDataSource(entity: string, entityId: number, params: IDataSourceParams): Observable<IDataSourceRes> {
		const url = `/api-core/datasource/${entity}/${entityId}/data`;
		return this.http.post<any>(url, params);
	}

	dataEntity(entityId: number, entityType: string, params: any): Observable<any> {
		const url = this.common.getApiCoreUrl(`data-source/${entityType}/${entityId}/data`);
		return this.http.post<IApiResponse>(url, params).pipe(map(r => r.data));
	}

	groups(source: string, params: any): Observable<any> {
		const url = this.common.getApiCoreUrl(`data-source/${source}/groups`);
		return this.http.post<IApiResponse>(url, params).pipe(map(r => r.data));
	}

	groupsEntity(entityId: number, entityType: string, params: any): Observable<any> {
		const url = this.common.getApiCoreUrl(`data-source/${entityType}/${entityId}/groups`);
		return this.http.post<IApiResponse>(url, params).pipe(map(r => r.data));
	}

	getAggregatesSource(source: string, params: any): Observable<IDataSourceAggResponse> {
		const url = this.common.getApiCoreUrl(`data-source/${source}/aggregates`);
		return this.http.post<any>(url, params);
	}

	getAggregatesEntity(entityId: number, entityType: string, params?: any): Observable<IDataSourceAggResponse> {
		const url = this.common.getApiCoreUrl(`data-source/${entityType}/${entityId}/aggregates`);
		return this.http.post<any>(url, params);
	}

	saveSettings(params: IAdminDataSourceSaveSettingsParams): Observable<any> {
		//const url = this.common.getApiUrl(`data-source/settings/save`);
		const url = this.common.getApiCoreUrl(`datasource/admin/settings/save`);
		return this.http.post<any>(url, params);
	}

	dataSourceConfig(
		type: string,
		id?: number,
		params?: any,
		userId?: number,
		isAdmin?: boolean
	): Observable<IGetDataSourceConfigResult> {
		let url = `datasource/${type}`;
		if (typeof id === 'number') {
			url += `/${id}`;
		}
		url = this.common.getApiCoreUrl(`${url}/config`);

		const qs = this.url.buildUrlSearchFromObject({ userId, isAdmin, ...params });
		if (qs) {
			url += '?' + qs;
		}

		return this.http.get<IGetDataSourceConfigResult>(url).pipe(
			map(res => {
				return this.normalizeDataSourceGridConfig(res, isAdmin);
			})
		);
	}

	dataSourceRows(type: string, id?: number, params?: any, userId?: number): Observable<any> {
		let url = `datasource/${type}`;
		if (typeof id === 'number') {
			url += `/${id}`;
		}
		url = this.common.getApiCoreUrl(`${url}/data`);
		if (typeof userId === 'number') {
			url += `?userId=${userId}`;
		}

		if (!params?.sortModel?.filter(c => c.colId === 'taskId')?.length && !params?.groupKeys?.length) {
			params.sortModel.push({ sort: 'desc', colId: 'taskId' });
		}

		return this.http.post<any>(url, params);
	}

	dataSourceExport(
		type: string,
		id?: number,
		params?: any,
		userId?: number,
		fileType: 'excel' | 'csv' = 'excel'
	): Observable<any> {
		let url = `datasource/${type}`;
		if (typeof id === 'number') {
			url += `/${id}`;
		}
		url = this.common.getApiCoreUrl(`${url}/data/export`);
		if (typeof userId === 'number') {
			url += `?userId=${userId}`;
		}
		return this.http.post<any>(url, { dataRowRequest: params, fileType }, { responseType: 'blob' as any });
	}

	dataSourceExportSource(
		source: string,
		params?: any,
		userId?: number,
		fileType: 'excel' | 'csv' = 'excel'
	): Observable<any> {
		const url = this.common.getApiCoreUrl(`data-source/${source}/data/export`);
		return this.http.post<any>(url, { dataRequest: params, fileType }, { responseType: 'blob' as any });
	}

	dataSourceExportTicker(
		tickerId: number,
		params?: any,
		userId?: number,
		fileType: 'excel' | 'csv' = 'excel'
	): Observable<any> {
		const url = this.common.getApiCoreUrl(`data-source/Ticker/${tickerId}/data/export`);
		return this.http.post<any>(url, { dataRequest: params, fileType }, { responseType: 'blob' as any });
	}

	dataSourceGroupRows(type: string, id?: number, params?: any, userId?: number): Observable<any> {
		let url = `datasource/${type}`;
		if (typeof id === 'number') {
			url += `/${id}`;
		}
		url = this.common.getApiCoreUrl(`${url}/groups`);
		if (typeof userId === 'number') {
			url += `?userId=${userId}`;
		}
		return this.http.post<any>(url, params);
	}

	dataSourceAggregateRows(type: string, id?: number, params?: any, userId?: number): Observable<any> {
		let url = `datasource/${type}`;
		if (typeof id === 'number') {
			url += `/${id}`;
		}
		url = this.common.getApiCoreUrl(`${url}/aggregates`);
		if (typeof userId === 'number') {
			url += `?userId=${userId}`;
		}
		return this.http.post<any>(url, params);
	}

	dataSourceSaveSettings(params: IDataSourceSaveSettingsParams): Observable<any> {
		const url = this.common.getApiCoreUrl(`datasource/settings/save`);
		return this.http.post<any>(url, params);
	}

	dataSourceTaskUsedConfig(
		blockSettingId: number,
		context: IGridContext,
		isAdmin = false
	): Observable<IGetDataSourceConfigResult> {
		let taskUsedUrl = `datasource/TaskUsed/${blockSettingId}/config`;
		if (context.taskId !== null) {
			taskUsedUrl += `?TaskId=${context.taskId}`;
		}
		if (isAdmin) {
			taskUsedUrl += `?isAdmin=${isAdmin}`;
		}
		const url = this.common.getApiCoreUrl(taskUsedUrl);
		return this.http.get<IGetDataSourceConfigResult>(url).pipe(
			map(res => {
				return this.normalizeDataSourceGridConfig(res, isAdmin);
			})
		);
	}

	dataSourceTaskUsedRows(blockId: number, params: any): Observable<any> {
		const url = this.common.getApiCoreUrl(`datasource/TaskUsed/${blockId}/data`);
		return this.http.post<any>(url, params);
	}

	dataSourceTaskUsedGroupRows(type: string, id: number, params: any): Observable<any> {
		const url = this.common.getApiCoreUrl(`datasource/${type}/${id}/groups`);
		return this.http.post<any>(url, params);
	}

	getAggregates(type: string, id?: number, params?: any, userId?: number): Observable<IDataSourceAggResponse> {
		const url = this.common.getApiCoreUrl(`datasource/${type}/${id}/aggregates`);
		return this.http.post<any>(url, params);
	}

	getAggregatesTaskUsed(blockId: number, params: any): Observable<IDataSourceAggResponse> {
		const url = this.common.getApiCoreUrl(`datasource/TaskUsed/${blockId}/aggregates`);
		return this.http.post<any>(url, params);
	}

	normalizeDataSourceGridConfig(data: IGetDataSourceConfigResult, isAdmin: boolean): IGetDataSourceConfigResult {
		data = data || ({} as any);
		data.userHasNoSettings = !data.adminColumnsSettings && !data.userColumnsSettings;

		data.adminColumnsSettings = data.adminColumnsSettings || {};
		data.adminColumnsSettings.columnState = data.adminColumnsSettings.columnState || [];

		const defaultFields = new Set<string>();
		const defaultCols = (data?.taskColumnsSettings || []).filter(i => {
			if (i.isDefault) {
				defaultFields.add(i.field);
			}
			return i.isDefault;
		});
		const adminColState = [],
			adminColDiff = new Set<string>();
		for (const acs of data.adminColumnsSettings.columnState) {
			adminColState.push(acs);
			adminColDiff.add(acs.colId);
		}
		for (const dc of defaultCols) {
			if (adminColDiff.has(dc.field)) {
				continue;
			}
			adminColState.push({
				hide: false,
				colId: dc.field,
				rowGroup: false,
				rowGroupIndex: null,
				width: dc.defaultWidth,
				flex: dc.defaultWidth ? undefined : 1,
				available: true,
				alignment: dc.alignment,
			});
			adminColDiff.add(dc.field);
		}

		data.adminColumnsSettings.columnState = adminColState;

		if (data.tableSettings) {
			if (!isAdmin && data.tableSettings.saveUserSettings === false) {
				data.userColumnsSettings = null;
			}
		} else {
			if (!isAdmin && data.adminColumnsSettings.saveUserSettings === false) {
				data.userColumnsSettings = null;
			}
		}

		return data;
	}

	getCustomDataSources(): Observable<ICustomDataSource[]> {
		const url = this.common.getApiCoreUrl('data-source/custom-datasources');
		return this.http.get<ICustomDataSource[]>(url);
	}

	getCustomDataSourceColumns(request: ICustomColumnRequest): Observable<ICustomColumnResponse> {
		const url = this.common.getApiCoreUrl('data-source/source/columns');
		return this.http.post<ICustomColumnResponse>(url, request);
	}

	deleteCustomSource(id: number): Observable<any> {
		const url = this.common.getApiCoreUrl(`data-source/custom-datasources/${id}/delete`);
		return this.http.get<any>(url);
	}

	updateCustomSourceColumns(request: ICustomColumnUpdateRequest): Observable<any> {
		const url = this.common.getApiCoreUrl('data-source/source/columns/update');
		return this.http.post<ICustomColumn[]>(url, request);
	}

	getColumnData(request: IGetDataByColumnRequest): Observable<IGetColumnDataResponse[]> {
		const url = this.common.getApiCoreUrl('data-source/data-for-column');
		return this.http.post<IGetColumnDataResponse[]>(url, request);
	}

	isWithResultset(source: string): Observable<boolean> {
		const url = this.common.getApiCoreUrl('data-source/custom-datasources/is-row-with-set/' + source);
		return this.http.get<boolean>(url);
	}

	configForm(alias: string): Observable<IDataSourceFormConfig[]> {
		const url = this.common.getApiCoreUrl(`data-source-form/${alias}/config`);
		return this.http.get<any>(url);
	}

	dataForm(formId: number, params: any): Observable<any> {
		const url = this.common.getApiCoreUrl(`data-source-form/${formId}/data`);
		return this.http.post<any>(url, params);
	}

	updateForm(formId: number, params: IFormActionRequest): Observable<any> {
		const url = this.common.getApiCoreUrl(`data-source-form/${formId}/action`);
		params.action = IFormCRUDAction.Update;
		return this.http.post<any>(url, params);
	}

	deleteFormRow(formId: number, params: IFormActionRequest): Observable<any> {
		const url = this.common.getApiCoreUrl(`data-source-form/${formId}/action`);
		params.action = IFormCRUDAction.Delete;
		return this.http.post<any>(url, params);
	}

	createRow(formId: number, params: IFormActionRequest): Observable<any> {
		const url = this.common.getApiCoreUrl(`data-source-form/${formId}/action`);
		params.action = IFormCRUDAction.Insert;
		return this.http.post<any>(url, params);
	}

	resetUsersSettings(entityId: number, entityType: string): Observable<void> {
		const url = this.common.getApiCoreUrl(`datasource/${entityType}/${entityId}/reset-users-settings`);
		return this.http.delete<void>(url);
	}

	resetTableUsersSettings(entityType: string, entityId?: number): Observable<void> {
		let url = '';
		if (entityId) {
			url = this.common.getApiCoreUrl(`datasource/${entityType}/${entityId}/table-settings/reset-users-settings`);
		} else {
			url = this.common.getApiCoreUrl(`datasource/${entityType}/table-settings/reset-users-settings`);
		}
		return this.http.delete<void>(url);
	}

	/**@deprecated */
	getConfig<T = any>(criteria: IDataSourceGetConfigByTypeCriteria): Observable<IDataSourceGetConfigResult<T>>;
	getConfig<T = any>(criteria: IDataSourceGetConfigByEntityCriteria): Observable<IDataSourceGetConfigResult<T>>;
	getConfig<T = any>(criteria: any): Observable<IDataSourceGetConfigResult<T>> {
		const id = (criteria as IDataSourceGetConfigByEntityCriteria).id;

		const url = this.common.getApiCoreUrl(
			id ? `data-source/config/${criteria.type}/${id}` : `data-source/config/${criteria.type}`
		);

		return this.http.get<IApiResponse<IDataSourceGetConfigResult<T>>>(url).pipe(map(result => result.data));
	}

	/**@deprecated */
	getData<T = any>(criteria: IDataSourceGetDataByTypeCriteria): Observable<IDataSourceGetDataResult<T>>;
	getData<T = any>(criteria: IDataSourceGetDataByEntityTypeCriteria): Observable<IDataSourceGetDataResult<T>>;
	getData<T = any>(criteria: any): Observable<IDataSourceGetDataResult<T>> {
		const id = (criteria as IDataSourceGetConfigByEntityCriteria).id;

		const url = this.common.getApiCoreUrl(
			id ? `data-source/data/${criteria.type}/${id}` : `data-source/data/${criteria.type}`
		);

		return this.http
			.post<IApiResponse<IDataSourceGetDataResult<T>>>(url, criteria.request)
			.pipe(map(result => result.data));
	}

	getSmartPacksOnEvents(
		subcatId: number,
		activeState: SmartPacksOnEventsActiveState
	): Observable<ISmartPacksOnEventsItem[]> {
		const url = this.common.getApiUrl(
			subcatId
				? `admin/smart/packs-on-events/list/${subcatId}/${activeState}`
				: `admin/smart/packs-on-events/list-by-state/${activeState}`
		);
		return this.http.get<IApiResponse<ISmartPacksOnEventsItem[]>>(url).pipe(map(result => result.data));
	}
	getSmartRecurrences(subcatId: number): Observable<ISmartRecurrencesItem[]> {
		const url = this.common.getApiUrl(`admin/smart/recurrences/list/${subcatId ? subcatId : ''}`);
		return this.http.get<IApiResponse<ISmartRecurrencesItem[]>>(url).pipe(map(result => result.data));
	}
	executeSmartRecurrenceItem(id: number): Observable<string> {
		const url = this.common.getApiUrl(`admin/smart/recurrences/execute/${id}`);
		return this.http.post<IApiResponse<{ result: string }>>(url, {}).pipe(map(result => result.data?.result));
	}
	deleteSmartRecurrenceItem(id: number): Observable<any> {
		const url = this.common.getApiUrl(`admin/smart/recurrences/delete/${id}`);
		return this.http.post(url, {});
	}
	getSmartPacks(subcatId: number): Observable<ISmartPacksListItem[]> {
		const url = this.common.getApiUrl(`admin/smart/packs/list/${subcatId ? subcatId : ''}`);
		return subcatId
			? this.http.get<IApiResponse<ISmartPacksListItem[]>>(url).pipe(map(result => result.data))
			: this.http
					.post<IApiResponse<{ actionPacks: ISmartPacksListItem[] }[]>>(url, {})
					.pipe(map(result => result.data[0]?.actionPacks));
	}
	deleteSmartPackItem(id: number): Observable<any> {
		const url = this.common.getApiUrl(`admin/smart/packs/delete/${id}`);
		return this.http.post(url, {});
	}
	getSmartExpressions(subcatId: number, enabledOnly = false, isFilter?: boolean): Observable<ISmartExpressionsItem[]> {
		const url = this.common.getApiUrl(`admin/smartexpressions/list/${subcatId ? subcatId : ''}`);
		return this.http
			.get<IApiResponse<ISmartExpressionsItem[]>>(url)
			.pipe(
				map(result =>
					result.data
						.filter(item => (enabledOnly ? item?.availableAsSmartSearch : item))
						.filter(item => (typeof isFilter === 'boolean' ? item?.isFilter === isFilter : item))
				)
			);
	}
	deleteSmartExpressionItem(id: number): Observable<any> {
		const url = this.common.getApiUrl(`admin/smartexpressions/delete/${id}`);
		return this.http.post(url, {});
	}

	saveTableSettings(type: string, id?: number, params?: IDataSourceSettings): Observable<any> {
		const url =
			id > 0
				? this.common.getApiCoreUrl(`datasource/${type}/${id}/table-settings/save`)
				: this.common.getApiCoreUrl(`datasource/${type}/table-settings/save`);
		return this.http.post(url, params);
	}
}
