import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DataHttpService } from '@spa/data/http';
import { Observable, catchError, filter, of, take } from 'rxjs';

@Pipe({
	name: 'smartExpressionsSelection',
})
export class SmartExpressionSelectionPipe implements PipeTransform {
	constructor(protected readonly server: DataHttpService) {}
	transform(subcatId: number): Observable<any[]> {
		return this.server.category.smartExpressions({ subcatId }).pipe(
			filter(items => !!items?.length),
			take(1),
			catchError(err => {
				console.error(err);
				return of([]);
			})
		);
	}
}

@NgModule({
	exports: [SmartExpressionSelectionPipe],
	declarations: [SmartExpressionSelectionPipe],
})
export class SmartExpressionSelectionPipeModule {}
