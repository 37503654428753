import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UrlProvider } from '@valhalla/core';

import { AppType } from '../apps.type';
import { IAppDefinition } from '../db';
import { Activator } from './abstract';

@Injectable()
export class ActivatorSystemApp extends Activator {
	constructor(protected router: Router, protected url: UrlProvider) {
		super();
	}

	activate(app: IAppDefinition): void {
		if (app.type !== AppType.system) {
			this.successor && this.successor.activate(app);
		} else {
			this.router.navigate([app.startup.url], { queryParams: app.startup.queryParams });
		}
	}

	resolveAppRoute(app: IAppDefinition): { url: string; query: Record<string, string> } {
		if (app.type === AppType.system) {
			return {
				url: app.startup.url,
				query: app.startup.queryParams
			};
		} else {
			return this.successor && this.successor.resolveAppRoute(app);
		}
	}
}
