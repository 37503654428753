import { IFavoritesMenu } from '@spa/facade/features/navigation/providers/data-provider.dto';

// tslint:disable:no-empty-interface
import { IMobileContainerResultDto } from '@valhalla/data/entities';
import { Observable } from 'rxjs';
import {
	IAddFolderToFavorites,
	IAddLinkToFavorites,
	IAddToFavoritesRequest,
	IMoveFolder,
	IMoveLink,
	IRenameFavoriteFolder,
	IReorderLink,
	IShareLink,
} from './favorites-data-http.service';

export abstract class FavoritesDataHttpService {
	abstract getFavoritesMenuNew(): Observable<IFavoritesMenu>;
	abstract getCounters(): Observable<IGetFavoriteCountersDto[]>;
	abstract getFavoritesMenu(): Observable<IMobileContainerResultDto>;
	abstract addToFavorites(requestParams: IAddToFavoritesRequest): Observable<any>;
	abstract addFolderToFavorites(requestParams: IAddFolderToFavorites): Observable<any>;
	abstract renameFavoriteFolder(requestParams: IRenameFavoriteFolder): Observable<any>;
	abstract deleteFavoriteFolder(folderId: number): Observable<any>;
	abstract renameFavoriteLink(requestParams: IRenameFavoriteFolder): Observable<any>;
	abstract deleteFavoriteLink(itemId: number): Observable<any>;
	abstract addLinkToFavorites(requestParams: IAddLinkToFavorites | IAddToFavoritesRequest): Observable<any>;
	abstract moveFavoritesLink(requestParams: IMoveLink): Observable<any>;
	abstract reorderFavoritesLink(requestParams: IReorderLink): Observable<any>;
	abstract shareFavoritesLink(requestParams: IShareLink): Observable<any>;
	abstract moveFavoritesFolder(requestParams: IMoveFolder): Observable<any>;
	abstract getFavoriteFolders(requestParams: IGetFavoriteFolders): Observable<GetFavoriteFoldersResult>;
}

export interface IGetFavoriteCountersDto {
	id: number;
	tasksCounters?: {
		allTasksCount: number;
		newTasksCount: number;
		overdueTasksCount: number;
	};
	parentId: number;
	nodeType: string;
	name: string;
}

export enum UserLinkType {
	none = 0,
	subcategory = 1,
	category = 2,
	task = 3,
	searchReport = 4,
	newTaskInSubcat = 5,
	report = 6,
	user = 7,
	comment = 8,
	mailFolder = 9,
	summaryCategory = 10,
	searchMailFolder = 11,
	portalGrid = 12,
	publicLink = 13,
}

export interface IGetFavoriteFolders {
	userLinkType: keyof typeof UserLinkType;
	linkedObjectId: number;
}

export type GetFavoriteFoldersResult = Array<{
	id: number;
	parentId: number;
	name: string;
	userId: null;
	order: number;
	groupId: number;
	isWorkGroupFolder: boolean;
	isSystemFolder: boolean;
}>;
