import {
	ChangeDetectionStrategy,
	Component,
	HostListener,
	Inject,
	OnInit,
	Optional,
	ViewEncapsulation,
} from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { ViewDestroyStreamService } from '@valhalla/core';
import { DataHttpService } from '@valhalla/data/http';
import { parseMessageFromError } from '@valhalla/utils';
import { BehaviorSubject, filter, map, shareReplay, take, takeUntil } from 'rxjs';
import { IFavoriteFolder } from '@spa/facade/features/navigation/providers/data-provider.dto';

@Component({
	selector: 'vh-task-favorite',
	templateUrl: './task-favorite.component.html',
	styleUrls: ['./task-favorite.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class TaskFavoriteComponent implements OnInit {
	constructor(
		readonly destroy$: ViewDestroyStreamService,
		readonly server: DataHttpService,
		@Optional() @Inject(MAT_DIALOG_DATA) public data,
		@Optional() public dialogRef: MatDialogRef<any>,
		readonly modal: ModalWindowsService
	) {}

	@HostListener('document:keydown', ['$event'])
	submitEmit($event) {
		if (
			($event.ctrlKey || $event.metaKey) &&
			$event.key === 'Enter' &&
			$event.target?.children[0]?.tagName === 'VH-TASK-FAVORITE'
		) {
			return this.updateFavorites();
		}
	}

	folder$ = null;
	linkId$ = null;

	favoriteColors$ = new BehaviorSubject([
		{
			id: 1,
			color: 'var(--extramarkers-blue)',
			selected: true,
		},
		{
			id: 2,
			color: 'var(--extramarkers-cyan)',
			selected: false,
		},
		{
			id: 3,
			color: 'var(--extramarkers-green)',
			selected: false,
		},
		{
			id: 4,
			color: 'var(--extramarkers-yellow)',
			selected: false,
		},
		{
			id: 5,
			color: 'var(--extramarkers-purple)',
			selected: false,
		},
		{
			id: 6,
			color: 'var(--extramarkers-red)',
			selected: false,
		},
	]);

	favorites$ = this.server.favorites.getFavoritesMenuNew().pipe(shareReplay({ bufferSize: 1, refCount: true }));

	favoritesFoldersFromServer$ = this.favorites$.pipe(
		map(data =>
			data.folders.filter(folder => !this.taskFolders.map(f => f.id).includes(folder.id) && !folder.isWorkGroupFolder)
		)
	);

	alreadyCreated$ = this.favorites$.pipe(
		map(data => data.nodes),
		map(nodes => nodes.find(link => link.linkedObjectId === this.data.taskId)),
		takeUntil(this.destroy$)
	);
	loading$ = new BehaviorSubject(true);
	hasChanges$ = new BehaviorSubject(false);
	folderChosen$ = new BehaviorSubject(false);

	taskFolders$ = new BehaviorSubject<IFavoriteFolder[]>([]);
	favoritesFolders$ = new BehaviorSubject<IFavoriteFolder[]>(null);

	set taskFolders(folders: any) {
		this.taskFolders$.next(folders);
	}

	get taskFolders() {
		return this.taskFolders$.value;
	}

	set favoritesFolders(folders: any) {
		this.favoritesFolders$.next(folders);
	}

	get favoritesFolders() {
		return this.favoritesFolders$.value;
	}

	favorites;

	ngOnInit() {
		this.taskFolders = this.data.linkedFolders;

		this.favoritesFoldersFromServer$.pipe(take(1), takeUntil(this.destroy$)).subscribe(folders => {
			if (folders.findIndex(f => f.id === null) < 0) {
				folders.push({
					name: 'Избранное',
					nameResx: 'common.favorites',
					id: null,
					isSystemFolder: false,
					isWorkGroupFolder: false,
					isExpand: false,
					userId: null,
					groupId: null,
					parentId: null,
					order: null,
				});
			}

			this.favoritesFolders$.next(folders.sort((f1, f2) => (f1.name < f2.name ? -1 : 1)));
		});

		this.favorites$.pipe(takeUntil(this.destroy$)).subscribe(data => {
			this.favorites = data;
			this.loading$.next(false);
		});

		this.alreadyCreated$
			.pipe(
				filter(link => !!link),
				map(link => {
					return {
						folder: link.folderId,
						color: link.color,
						linkId: link.id,
					};
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(data => {
				this.linkId$ = data.linkId;
			});
	}

	selectColor(color) {
		const colors = this.favoriteColors$.value.map(c => {
			if (c.id !== color.id) {
				c.selected = false;
			}
			if (c.id === color.id) {
				c.selected = !c.selected;
			}
			return c;
		});
		this.favoriteColors$.next(colors);
	}

	closeDialog() {
		this.dialogRef.close();
	}

	updateFavorites() {
		const color = this.favoriteColors$.value.find(c => c.selected).color;
		this.server.task
			.updateFavorites({
				taskId: this.data.taskId,
				color: color,
				favsFolderIds: this.taskFolders$.value.map(fol => fol.id),
			})
			.subscribe({
				next: () => this.closeDialog(),
				error: err => this.modal.openError(parseMessageFromError(err)),
			});
	}

	deleteLink(delFolder) {
		this.taskFolders = this.taskFolders.filter(folder => folder.id !== delFolder.id);
		this.favoritesFolders.push(delFolder);
		this.hasChanges$.next(true);
	}

	addLink(folder) {
		if (folder) {
			this.taskFolders.push(folder);
			this.favoritesFolders = this.favoritesFolders$.value.filter(x => x.id !== folder.id);
			this.hasChanges$.next(true);
			return;
		}

		this.modal.openError('Выберите папку', null, {
			titleResx: 'common.error',
			messageResx: 'common.selectAFolder',
		});
	}
}
