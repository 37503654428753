import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ModalBaseModule } from '@spa/facade/features/modals/modal-base';
import { AvatarModule } from '../chat-nav-avatar';
import { ProgressBarCommonModule } from '@spa/common/components/progress-bar/progress-bar.module';
import { TaskAvatarEditModalComponent } from './task-avatar-edit-modal/task-avatar-edit-modal.component';
import { TaskAvatarComponent } from './task-avatar.component';
import { TaskAvatarPipe } from './task-avatar.pipe';
import { LocalizationModule } from '@spa/localization';

@NgModule({
	imports: [CommonModule, AvatarModule, ModalBaseModule, MatDialogModule, ProgressBarCommonModule, LocalizationModule],
	exports: [TaskAvatarComponent, TaskAvatarPipe, TaskAvatarEditModalComponent],
	declarations: [TaskAvatarComponent, TaskAvatarPipe, TaskAvatarEditModalComponent],
	providers: [],
})
export class TaskAvatarModule {}
