/**
 * Базовый класс для создания кастоманых ошибок
 * @export
 * @abstract
 * @class ErrorBase
 * @extends {Error}
 * @see https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax-babel#answer-32749533
 */
export abstract class ErrorBase extends Error {
	constructor(message?: string) {
		super(message);
		this.name = this.constructor.name;
		if (typeof Error.captureStackTrace === 'function') {
			Error.captureStackTrace(this, this.constructor);
		} else {
			this.stack = new Error(message).stack;
		}
	}
}
