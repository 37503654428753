<mat-toolbar *ngIf="showToolbar" class="vh-modal-toolbar">
	<span *ngIf="title" class="pl-12">{{ title | resx }}</span>
	<vh-portal-host
		*ngIf="!title"
		name="modal-toolbar"
		[key]="toolbarPortalKey$ | async"
		[containerConstraint]="elRef"
	></vh-portal-host>
	<span class="mla"></span>
	<button class="vh-btn vh-btn-icon vh-btn--transparent" *ngIf="canOpenInNewTab" (click)="openInNewTab()">
		<mat-icon class="s-22">launch</mat-icon>
	</button>
	<button class="vh-btn vh-btn-icon vh-btn--transparent" *ngIf="showClose" (click)="close()">
		<mat-icon>close</mat-icon>
	</button>
</mat-toolbar>

<div class="vh-modal-content">
	<ng-container #componentPlace></ng-container>
</div>

<div *ngIf="dialogData.footerTemplate" class="vh-modal-footer">
	<ng-container *ngTemplateOutlet="dialogData.footerTemplate; context: dialogData.footerTemplateContext"></ng-container>
</div>
