<button
	type="button"
	class="vh-btn vh-btn-icon"
	[matMenuTriggerFor]="colorMenu"
	(menuOpened)="onMenuOpen()"
	[ngClass]="selectedPalette + '-' + selectedHue"
>
	<mat-icon>palette</mat-icon>
</button>

<mat-menu #colorMenu="matMenu" class="fuse-material-color-picker-menu mat-elevation-z8">
	<header [ngClass]="selectedColor?.class || 'accent'" class="mat-elevation-z4">
		<button
			class="vh-btn vh-btn-icon secondary-text"
			[style.visibility]="view === 'hues' ? 'visible' : 'hidden'"
			(click)="goToPalettesView($event)"
			aria-label="Palette"
		>
			<mat-icon class="s-20">arrow_back</mat-icon>
		</button>

		<span *ngIf="selectedColor?.palette"> {{ selectedColor.palette }} {{ selectedColor.hue }} </span>

		<span *ngIf="!selectedColor?.palette"> Select a Color </span>

		<button
			class="vh-btn vh-btn-icon remove-color-button secondary-text"
			(click)="removeColor($event)"
			aria-label="Remove color"
			tippy="Remove color"
		>
			<mat-icon class="s-20">delete</mat-icon>
		</button>
	</header>

	<div [ngSwitch]="view" class="views">
		<div class="view" *ngSwitchCase="'palettes'">
			<div class="colors" vhPerfectScrollbar>
				<div
					class="color"
					*ngFor="let color of colors | keys"
					[ngClass]="color.key"
					[class.selected]="selectedPalette === color.key"
					(click)="selectPalette($event, color.key)"
				></div>
			</div>
		</div>

		<div class="view" *ngSwitchCase="'hues'">
			<div class="colors" vhPerfectScrollbar>
				<div
					class="color"
					*ngFor="let hue of hues"
					[style.display]="
						(selectedPalette === 'fuse-white' && hue !== '500') || (selectedPalette === 'fuse-black' && hue !== '500')
							? 'none'
							: 'flex'
					"
					[ngClass]="selectedPalette + '-' + hue"
					[class.selected]="selectedHue === hue"
					(click)="selectHue($event, hue)"
				></div>
			</div>
		</div>
	</div>
</mat-menu>
