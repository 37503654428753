import { IUser } from './user';
import { IComment } from './comment';
import { IGroupTag } from './task';
import { IFavoriteFolder } from '@spa/facade/features/navigation/providers/data-provider.dto';

export interface IChat {
	taskId: number;
	subcatId: number;
	chatType: ChatType;
	allowComments: boolean;
	taskText: string;
	hasAvatar?: boolean;
	guid: string;
	unreadCount: number;
	chatNotificationMode: ChatNotificationMode;
	owner: Partial<IUser>;
	isEncrypted: boolean;
	isOverdue: boolean;
	subscribers: Partial<IUser>[];
	/** last comment */
	comments: Partial<IComment>[];
	/**in favorites link id */
	linkedTaskId?: number;
	/**color favorites link */
	color?: string;
	colorClass?: string;
	colorMaterialRGB?: string;
	viewTags?: IGroupTag[];
	subscribersCount?: number;
	subscribersGroups?: any[];
	linkedFolders?: IFavoriteFolder[];
}

export enum ChatType {
	pinned = 'PinnedTask',
	group = 'GroupChat',
}

export enum ChatNotificationMode {
	normal = 'Normal',
	muted = 'Muted',
	forced = 'Forced',
}
