import { ComponentType } from '@angular/cdk/portal';
import { ComponentFactoryResolver, InjectionToken, Injector, TemplateRef, Type } from '@angular/core';
import {
	MatLegacyDialogConfig as MatDialogConfig,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { MimeType } from '@valhalla/data/entities';
import { Observable } from 'rxjs';

import { ISnackRef } from './snack-bar.interface';
import { NewTaskAction } from '@spa/components/new-task/ui/new-task-card/new-task-card/types';

export abstract class ModalWindowsService {
	abstract getActiveModal(): MatDialogRef<any>;
	abstract closeAll(): void;
	abstract closeLast(data?: any): boolean;
	abstract closeAllButLast(): void;
	abstract closeAllButFirst(): void;
	abstract withOpenTill(openTill$: Observable<any>): ModalWindowsService;
	abstract openDialog<T = any>(
		template: ComponentType<any> | TemplateRef<any>,
		settings: IDialogConfig,
		componentFactoryResolver?: ComponentFactoryResolver,
		injector?: Injector,
		container?: HTMLElement
	): IModalRef<T>;
	/**@deprecated use openConfirm */
	abstract openConfirmModal(data, settings): IModalRef;
	abstract openConfirm(message: string, data?: { confirmBtnColor?: string }, resx?: string): IModalRef<boolean>;
	abstract openSnackBar(
		template: ComponentType<any> | TemplateRef<any> | string,
		settings?: MatSnackBarConfig
	): ISnackRef;
	abstract openIFrame(
		urlOrOptions: string | IOpenIFrame,
		title?: string,
		titleResx?: string,
		dialogOptions?: IDialogConfig,
		frameClasses?: string | string[],
		componentFactoryResolver?: ComponentFactoryResolver,
		injector?: Injector,
		container?: HTMLElement,
		hideOnLoading?: boolean,
		windowProps?: Record<any, any>
	): IModalRef;
	abstract openFileViewer(data: {
		uploadId: number;
		mimeType?: MimeType;
		versionId?: number;
		taskId?: number;
		fileName?: string;
	}): IModalRef;
	abstract openError(
		message: string,
		title?: string,
		data?: { titleResx?: string; messageResx?: string; showCancel?: boolean; okTitle?: string; cancelTitle?: string }
	): IModalRef;
	abstract openInfo(message: string, data?: { resx?: string; json?: any; title?: string }): IModalRef;
	abstract openPrompt(value: string, settings?: any): IModalRef;
	abstract openFileViewer2(options): IModalRef;
	abstract openIFrameInContent(url: string, title?: string, modalClass?: string): Partial<IModalRef>;
	abstract openTask(options: IModalOpenTask): Promise<IModalRef>;
	abstract openSpace(options: IModalOpenSpace): Observable<any>;
	abstract openProject(options: IModalOpenProject): IModalRef;
	abstract openGeneric<T = any>(options: IModalOpenGeneric): IModalRef<T>;
	abstract createTask(options: IModalCreateTask): Observable<any>;
}

export interface IModalCreateTask {
	action?: NewTaskAction;
	subcatId?: number;
	parentTaskId?: number;
	linkedTaskId?: number;
	sourceTaskId?: number;
	onlyCloseAfterCreate?: boolean;
	onlyEmitAfterCreate?: boolean;
	parentTaskText?: string;
	customTitle?: string;
}

export interface IModalOpenGeneric {
	componentGetter: () => Promise<Type<any>> | Type<any>;
	componentInputs: Record<string, any>;
	toolbarPortalKey?: any;
	openInNewTabHandler?: () => void;
	dialogConfig?: IDialogConfig;
	cfr?: ComponentFactoryResolver;
	injector?: Injector;
	title?: string;
}
export interface IModalOpenProject {
	projectId: number;
	replaceModal?: boolean;
	dialogConfig?: IDialogConfig;
	toolbarPortalKey?: any;
	cfr?: ComponentFactoryResolver;
	injector?: Injector;
}

export interface IModalOpenSpace {
	spaceId: number;
}

export interface IModalOpenTask {
	taskId: number;
	dialogConfig?: IDialogConfig;
	cfr?: ComponentFactoryResolver;
	injector?: Injector;
	replaceModal?: boolean;
	shouldShowTabbar?: boolean;
	showDialogTitle?: boolean;
	props?: Record<string, any>;
	customTaskTitle?: string;
}

export interface IDialogConfig extends MatDialogConfig {
	closeOnBackdropClick?: boolean;
	openTill$?: Observable<any>;
	noOverflow?: boolean;
}

export interface IModalRef<T = any> {
	componentRef: MatDialogRef<any, any>;
	afterOpened(): Observable<void>;
	afterClosed(): Observable<T>;
	beforeClosed(): Observable<T>;
	backdropClick(): Observable<MouseEvent>;
	keydownEvents(): Observable<KeyboardEvent>;
	close(result?: any): void;
	/** return afterClose() Observable<any>*/
	closeWhen?(source$: Observable<any>): Observable<any>;
}

export interface IOpenIFrame {
	url: string;
	title?: string;
	titleResx?: string;
	dialogOptions?: IDialogConfig;
	frameClasses?: string | string[];
	componentFactoryResolver?: ComponentFactoryResolver;
	injector?: Injector;
	container?: HTMLElement;
	hideOnLoading?: boolean;
	postData?: Record<string, any>;
	iframeBaseHref?: string;
	windowProps?: Record<any, any>;
	openInNewTabUrl?: string;
}

export const MODAL_OPEN_TILL = new InjectionToken<Observable<any>>('Modal open till stream');
