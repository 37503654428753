<div class="vh-layout-favorites-panel-wrapper" *ngIf="subcategoriesTreeLoaded$ | async">
	<div
		*ngIf="(favoritesButtonsCommon$ | async)?.length > 0"
		class="vh-common-sidebar-content vh-common-sidebar-content--fav-buttons py-8"
	>
		<div class="drop-item node-item">
			<ng-container *ngFor="let button of favoritesButtonsCommon$ | async; let index = index">
				<ng-container
					*ngTemplateOutlet="
						MenuBlock;
						context: { subMenuItem: button, clickHandler: onButtonClick.bind(this, button) }
					"
				></ng-container>
			</ng-container>
		</div>
	</div>

	<div
		class="vh-common-sidebar-content vh-common-sidebar-content-favorites"
		vhPerfectScrollbar
		[vhPerfectScrollbarOptions]="perfectScrollBarOptions"
		[class.mobile]="mobileView.isMobile"
	>
		<div class="vh-common-sidebar-content--search">
			<vh-sidebar-header
				[title]="'common.sideBarBtnFavorites' | resx"
				(searchInput)="searchHandler($event.target.value)"
				(searchClear)="searchHandler('')"
			>
			</vh-sidebar-header>
		</div>

		<div class="folder-wrapper fv-buttons favorites-group">
			<ng-container *ngFor="let item of favoritesGroupItems" cdkDropListGroup>
				<ng-container *ngTemplateOutlet="MenuBlock; context: { subMenuItem: item }"></ng-container>
			</ng-container>
		</div>

		<vh-layout-my-tasks-nav-panel [search]="searchInput$ | async"></vh-layout-my-tasks-nav-panel>

		<div
			cdkDropList
			[id]="'menu'"
			(cdkDropListDropped)="drop($event)"
			[cdkDropListConnectedTo]="getDropTargetIds('menu')"
			class="menu-wrapper"
			[vhContextMenu]="{
				menu: favoritesContextMenu,
				data: {
					item: null
				}
			}"
		>
			<div
				*ngFor="let menuItem of filteredFavoritesMenuFolders$ | async"
				cdkDrag
				[cdkDragData]="menuItem"
				[cdkDragDisabled]="(menuItem.groupId !== null && menuItem.userId === null) || mobileView.isMobile"
				(cdkDragMoved)="dragMoved($event)"
			>
				<ng-container *ngTemplateOutlet="favoriteMenuFolders; context: { folder: menuItem }"></ng-container>
			</div>
			<!-- Fav Folder -->
			<div
				*ngIf="filteredFavFolderMenu$ | async as favFolderMenu"
				cdkDropList
				[cdkDropListConnectedTo]="getDropTargetIds(favFolderMenu.id)"
				[id]="favFolderMenu.id.toString()"
				(cdkDropListDropped)="drop($event)"
				class="node-item fav-folder"
				[attr.data-id]="favFolderMenu.id"
				[attr.id]="favFolderMenu.id"
			>
				<div *ngIf="favFolderMenu.subMenu.length" class="fav-sub-menu">
					<div
						*ngFor="let subMenuItem of favFolderMenu.subMenu"
						cdkDrag
						(cdkDragMoved)="dragMoved($event)"
						[cdkDragData]="subMenuItem"
						[cdkDragDisabled]="(subMenuItem.groupId !== null && subMenuItem.userId === null) || mobileView.isMobile"
						class="drop-item node-item"
						[attr.data-id]="favFolderMenu.id"
						[attr.id]="'item-' + subMenuItem.id"
						[attr.item-id]="subMenuItem.id"
						cdkDragBoundary="{{ subMenuItem.userId ? '.panel-wrapper' : '.folder-wrapper' }}"
					>
						<ng-container *ngTemplateOutlet="MenuBlock; context: { subMenuItem: subMenuItem }"></ng-container>
					</div>
				</div>
			</div>
			<!-- Fav Folder End -->
		</div>

		<!-- template assistant -->
		<ng-container *ngIf="(filteredFavMenuAssistant$ | async)?.length">
			<div *ngFor="let assistant of filteredFavMenuAssistant$ | async" class="menu-wrapper">
				<div>
					<div class="title-wrapper">
						<div class="folder-title" (click)="expandAssistantBlock($event, assistant)">
							<mat-icon
								class="forma-icon-size"
								[svgIcon]="isOpenAssistantBlock(assistant.userId) ? 'vh-arrow-v1-S-down-24' : 'vh-arrow-v1-S-right-24'"
							></mat-icon>
							<div class="folder-title-txt">{{ 'common.favorites' | resx }} ({{ assistant.userName }})</div>
						</div>
					</div>

					<ng-container *ngIf="isOpenAssistantBlock(assistant.userId)">
						<div *ngFor="let menuItem of assistant.folders" class="assistant-wrapper">
							<ng-container
								*ngTemplateOutlet="favoriteMenuAssistantFolders; context: { folder: menuItem, assistant: assistant }"
							></ng-container>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-container>
		<!-- template assistant end -->
	</div>
</div>

<ng-template #MenuBlock let-subMenuItem="subMenuItem" let-hideContext="hideContext" let-clickHandler="clickHandler">
	<div
		class="fv-item"
		[vhContextMenu]="{
			menu: (!subMenuItem.isWorkGroupLink && !hideContext) || isCategory(subMenuItem) ? renameOrRemoveElement : null,
			data: {
				item: subMenuItem
			}
		}"
		(click)="clickHandler ? clickHandler($event) : onItemClick(subMenuItem, $event)"
		[tippy]="subMenuItem?.tooltip"
		[class.is-link]="isExternalLink(subMenuItem)"
		[class.is-task]="isTask(subMenuItem)"
		[class.is-portal]="isPortal(subMenuItem)"
		[class.is-category]="isCategory(subMenuItem)"
		[class.is-report]="isReport(subMenuItem)"
		[class.active]="subMenuItem.id === (selectedMenuItemId$ | async) || isActiveRoute(subMenuItem)"
		[attr.data-href]="subMenuItem.href"
	>
		<div class="info clamped-2">
			<mat-icon class="nav-link-icon mat-icon material-icons mat-icon-no-color" role="img">view_comfy</mat-icon>

			<div class="fv-label-wrapper">
				<span
					*ngIf="subMenuItem?.color"
					class="fv-label-badge"
					[ngStyle]="{ 'background-color': getColor(subMenuItem?.color) }"
				></span>
				<p class="fv-label">
					{{ subMenuItem.title }}
				</p>
			</div>
		</div>
		<div class="fv-sub">
			<div class="counter">
				<ng-container *ngIf="subMenuItem.customCounters || subMenuItem.stats as counters">
					<vh-badge
						*ngIf="counters?.allTasksCount"
						[color]="IBadgeColor.default"
						[value]="counters.allTasksCount"
						[emphasis]="IBadgeEmphasis.container"
						[tippy]="'Всего'"
						[delay]="400"
					></vh-badge>

					<vh-badge
						*ngIf="counters?.newTasksCount"
						[color]="IBadgeColor.info"
						[value]="counters.newTasksCount"
						[tippy]="'Новые'"
						[delay]="400"
					></vh-badge>

					<vh-badge
						*ngIf="counters?.overdueTasksCount"
						[color]="IBadgeColor.danger"
						[value]="counters.overdueTasksCount"
						[tippy]="'Просроченные'"
						[delay]="400"
					></vh-badge>
				</ng-container>
			</div>

			<div
				*ngIf="subMenuItem?.canCreate"
				class="fv-item-actions"
				[tippy]="'common.create' | resx"
				[class.custom-icon]="isFormaIcon(subMenuItem)"
			>
				<div class="fv-item-action" *ngIf="isFormaIcon(subMenuItem)" (click)="onIconClick($event, subMenuItem)">
					<mat-icon [svgIcon]="getIcon(subMenuItem)" [class.s-20]="getIcon(subMenuItem) === 'vh-plus-24'"></mat-icon>
				</div>

				<div class="fv-item-action" *ngIf="!isFormaIcon(subMenuItem)" (click)="onIconClick($event, subMenuItem)">
					<mat-icon class="mat-icon">{{ getIcon(subMenuItem) }}</mat-icon>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #folderBlock let-subMenuFolder="subMenuFolder" let-hideContext="hideContext">
	<div
		class="fv-item"
		[vhContextMenu]="{
			menu: !subMenuFolder.isWorkGroupLink && !hideContext ? renameOrRemoveElement : null,
			data: {
				item: subMenuFolder
			}
		}"
		(click)="onItemClick(subMenuFolder, $event)"
	>
		<div class="info clamped-2">
			<mat-icon class="nav-link-icon mat-icon material-icons mat-icon-no-color" role="img">view_comfy</mat-icon>

			<div class="fv-label-wrapper">
				<span
					*ngIf="subMenuItem?.color"
					class="fv-label-badge"
					[ngStyle]="{ 'background-color': getColor(subMenuItem?.color) }"
				></span>
				<p class="fv-label">
					{{ subMenuItem.title }}
				</p>
			</div>
		</div>
		<div class="fv-sub">
			<div class="counter">
				<ng-container *ngIf="subMenuItem.customCounters || subMenuItem.stats as counters">
					<vh-badge
						*ngIf="counters?.allTasksCount"
						[color]="IBadgeColor.default"
						[value]="counters.allTasksCount"
						[emphasis]="IBadgeEmphasis.container"
						[tippy]="'Всего'"
						[delay]="400"
					></vh-badge>

					<vh-badge
						*ngIf="counters?.newTasksCount"
						[color]="IBadgeColor.info"
						[value]="counters.newTasksCount"
						[tippy]="'Новые'"
						[delay]="400"
					></vh-badge>

					<vh-badge
						*ngIf="counters?.overdueTasksCount"
						[color]="IBadgeColor.danger"
						[value]="counters.overdueTasksCount"
						[tippy]="'Просроченные'"
						[delay]="400"
					></vh-badge>
				</ng-container>
			</div>

			<div
				*ngIf="subMenuItem?.canCreate"
				class="fv-item-actions"
				[tippy]="'common.create' | resx"
				[class.custom-icon]="isFormaIcon(subMenuItem)"
			>
				<mat-icon
					*ngIf="isFormaIcon(subMenuItem)"
					(click)="onIconClick($event, subMenuItem)"
					[svgIcon]="getIcon(subMenuItem)"
				></mat-icon>
				<mat-icon *ngIf="!isFormaIcon(subMenuItem)" (click)="onIconClick($event, subMenuItem)" class="mat-icon">{{
					getIcon(subMenuItem)
				}}</mat-icon>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #favoriteMenuFolders let-menuItem="folder">
	<div class="node-item" [attr.data-id]="menuItem.id" [attr.id]="menuItem.id">
		<div
			cdkDropList
			[id]="menuItem.id.toString()"
			[cdkDropListConnectedTo]="getDropTargetIds(menuItem.id)"
			(cdkDropListDropped)="drop($event)"
		>
			<div
				class="title-wrapper"
				(click)="expandBlock($event, menuItem)"
				[vhContextMenu]="{
					menu: menuItem.isWorkGroupFolder || menuItem.isSystemFolder ? null : renameOrRemoveFolder,
					data: {
						folder: menuItem
					}
				}"
			>
				<div class="folder-title" [class.active]="menuItem.isExpand">
					<mat-icon
						class="forma-icon-size"
						[svgIcon]="menuItem.isExpand ? 'vh-arrow-v1-S-down-24' : 'vh-arrow-v1-S-right-24'"
					></mat-icon>
					<div class="folder-title-txt">
						{{ menuItem.name }}
					</div>
				</div>
			</div>

			<ng-container *ngIf="menuItem.isExpand">
				<div
					class="folder-wrapper"
					[vhContextMenu]="{
						menu: menuItem.isWorkGroupFolder ? null : addLinkContextMenu,
						data: {
							item: menuItem
						}
					}"
				>
					<div
						*ngFor="let subMenuFolder of menuItem.subFolders"
						cdkDrag
						[cdkDragData]="subMenuFolder"
						(cdkDragMoved)="dragMoved($event)"
						[cdkDragDisabled]="(subMenuFolder.groupId !== null && subMenuFolder.userId === null) || mobileView.isMobile"
						class="drop-item"
						cdkDragBoundary="{{ subMenuFolder.userId ? '.panel-wrapper' : '.folder-wrapper' }}"
					>
						<ng-container *ngTemplateOutlet="favoriteMenuFolders; context: { folder: subMenuFolder }"></ng-container>
					</div>
					<div
						*ngFor="let subMenuItem of menuItem.subMenu"
						cdkDrag
						(cdkDragMoved)="dragMoved($event)"
						[cdkDragData]="subMenuItem"
						[cdkDragDisabled]="(subMenuItem.groupId !== null && subMenuItem.userId === null) || mobileView.isMobile"
						class="drop-item node-item"
						[attr.data-id]="menuItem.id"
						[attr.id]="'item-' + subMenuItem.id"
						[attr.item-id]="subMenuItem.id"
						cdkDragBoundary="{{ subMenuItem.userId ? '.panel-wrapper' : '.folder-wrapper' }}"
					>
						<ng-container *ngTemplateOutlet="MenuBlock; context: { subMenuItem: subMenuItem }"></ng-container>
					</div>
					<div *ngIf="!menuItem.subMenu?.length && !menuItem.subFolders?.length" class="empty-folder">
						<div class="empty-folder__label">{{ 'common.noItems' | resx }}</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</ng-template>

<ng-template #favoriteMenuAssistantFolders let-menuItem="folder" let-assistant="assistant">
	<ng-container *ngIf="menuItem.name !== 'ИзбранныеПунктыЮзера'">
		<div class="title-wrapper" (click)="expandBlock($event, menuItem, assistant.userId)">
			<div class="folder-title" [class.active]="menuItem.isExpand">
				<mat-icon [svgIcon]="menuItem.isExpand ? 'vh-arrow-v1-S-down-24' : 'vh-arrow-v1-S-right-24'"></mat-icon>
				<div class="folder-title-txt">
					{{ menuItem.name }}
				</div>
			</div>
		</div>
		<ng-container *ngIf="menuItem.isExpand">
			<div class="folder-wrapper">
				<div *ngFor="let subMenuFolder of menuItem.subFolders" class="drop-item">
					<ng-container
						*ngTemplateOutlet="favoriteMenuAssistantFolders; context: { folder: subMenuFolder, assistant: assistant }"
					></ng-container>
				</div>
				<div *ngFor="let subMenuItem of menuItem.subMenu" class="drop-item">
					<ng-container
						*ngTemplateOutlet="MenuBlock; context: { subMenuItem: subMenuItem, hideContext: true }"
					></ng-container>
				</div>
				<div *ngIf="!menuItem.subMenu?.length && !menuItem.subFolders.length" class="empty-folder">
					<div class="empty-folder__label">{{ 'common.noItems' | resx }}</div>
				</div>
			</div>
		</ng-container>
	</ng-container>

	<!-- Fav Sub Menu Assistant-->
	<div *ngIf="menuItem.name === 'ИзбранныеПунктыЮзера' && menuItem.subMenu.length" class="fav-sub-menu">
		<ng-container *ngFor="let subMenuItem of menuItem.subMenu">
			<div class="drop-item">
				<ng-container
					*ngTemplateOutlet="MenuBlock; context: { subMenuItem: subMenuItem, hideContext: true }"
				></ng-container>
			</div>
		</ng-container>
	</div>
	<!-- Fav Sub Menu Assistant End-->
</ng-template>
<mat-menu #favoritesContextMenu="matMenu">
	<ng-template matMenuContent let-item="item">
		<button mat-menu-item (click)="openCreateFolderModal(item)">
			<mat-icon svgIcon="vh-folder-add-24"></mat-icon>
			<span>{{ 'common.contextCreateFolder' | resx }}</span>
		</button>
	</ng-template>
</mat-menu>

<mat-menu #renameOrRemoveFolder="matMenu">
	<ng-template matMenuContent let-folder="folder">
		<button *ngIf="!isSystemOrWorkGroup(folder)" mat-menu-item (click)="openRenameFolderModal(folder)">
			<mat-icon svgIcon="vh-edit-text-24"></mat-icon>{{ 'common.contextRename' | resx }}
		</button>
		<button *ngIf="!isSystemOrWorkGroup(folder)" mat-menu-item (click)="openCreateElementModal(folder)">
			<mat-icon svgIcon="vh-plus-24"></mat-icon>{{ 'common.contextCreateLink' | resx }}
		</button>
		<button *ngIf="!isSystemOrWorkGroup(folder)" (click)="openCreateFolderModal(folder)" mat-menu-item>
			<mat-icon svgIcon="vh-folder-add-24"></mat-icon>
			<span>{{ 'common.contextCreateFolder' | resx }}</span>
		</button>
		<ng-container *ngIf="!isSystemOrWorkGroup(folder)">
			<mat-divider></mat-divider>

			<button class="danger-color" mat-menu-item (click)="openDeleteFolderModal(folder)">
				<mat-icon class="danger-color" svgIcon="vh-trash-24"></mat-icon>{{ 'common.contextDelete' | resx }}
			</button>
		</ng-container>
	</ng-template>
</mat-menu>

<mat-menu #renameOrRemoveElement="matMenu">
	<ng-template matMenuContent let-item="item" let-canCreate="canCreate">
		<ng-container *ngIf="item.typeId === 'Category' || item.typeId === 'Subcategory'">
			<ng-container *ngIf="item.typeId === 'Subcategory'">
				<button *ngIf="item.canCreate || canCreate" mat-menu-item (click)="createNewTask(item, $event)">
					<mat-icon svgIcon="vh-tasks-add-24"></mat-icon>{{ 'common.contextCreate' | resx }}
				</button>
			</ng-container>
			<button mat-menu-item [matMenuTriggerFor]="openAsItems" [matMenuTriggerData]="{ item }">
				<mat-icon svgIcon="vh-grid-24"></mat-icon>
				<span>{{ 'common.openAs' | resx }}</span>
			</button>
			<ng-container *ngIf="item.typeId === 'Subcategory'">
				<button
					*ngIf="item?.linkedObjectId | smartExpressionsSelection | async as smartExpressions"
					mat-menu-item
					[matMenuTriggerFor]="openSelectionItems"
					[matMenuTriggerData]="{ data: { items: smartExpressions, subcatId: item.linkedObjectId } }"
				>
					<mat-icon svgIcon="vh-filter-v2-24"></mat-icon>
					<span>{{ 'common.openSelection' | resx }}</span>
				</button>
			</ng-container>

			<mat-divider></mat-divider>

			<button
				*ngIf="(isAdmin$ | async) && item.typeId === 'Subcategory'"
				mat-menu-item
				(click)="openCategorySettings(item)"
			>
				<mat-icon svgIcon="vh-Settings-v2-24"></mat-icon>{{ 'common.contextSettingCategory' | resx }}
			</button>
		</ng-container>

		<button mat-menu-item *ngIf="item.typeId === 'Task'" (click)="openShareItemModal(item)">
			<mat-icon svgIcon="vh-share-v1-24"></mat-icon>{{ 'common.contextShare' | resx }}
		</button>

		<button *ngIf="!isWorkGroupLink(item)" mat-menu-item (click)="openRenameItemModal(item)">
			<mat-icon svgIcon="vh-edit-text-24"></mat-icon>{{ 'common.contextRename' | resx }}
		</button>

		<mat-divider *ngIf="!isWorkGroupLink(item)"></mat-divider>

		<button *ngIf="!isWorkGroupLink(item)" class="danger-color" mat-menu-item (click)="openDeleteItemModal(item)">
			<mat-icon class="danger-color" svgIcon="vh-trash-24"></mat-icon>{{ 'common.contextDeleteFromFavorites' | resx }}
		</button>
	</ng-template>
</mat-menu>

<mat-menu #addLinkContextMenu="matMenu">
	<ng-template matMenuContent let-item="item">
		<button *ngIf="!isWorkGroupLink(item)" mat-menu-item (click)="openCreateElementModal(item)">
			<mat-icon svgIcon="vh-plus-24"></mat-icon>{{ 'common.contextCreateLink' | resx }}
		</button>
		<button *ngIf="!isWorkGroupLink(item)" (click)="openCreateFolderModal(item)" mat-menu-item>
			<mat-icon svgIcon="vh-folder-add-24"></mat-icon>
			<span>{{ 'common.contextCreateFolder' | resx }}</span>
		</button>
	</ng-template>
</mat-menu>
<mat-menu #openAsItems="matMenu">
	<ng-template matMenuContent let-item="item">
		<button
			*ngIf="
				!(IsFeedsViewOnly$ | async) &&
				(!item.availableRepresentations || item.availableRepresentations.includes('Grid'))
			"
			mat-menu-item
			(click)="openTable(item, $event)"
		>
			<mat-icon svgIcon="vh-table-24"></mat-icon>{{ 'common.contextGrid' | resx }}
		</button>
		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Feeds')"
			mat-menu-item
			(click)="openFeed(item, $event)"
		>
			<mat-icon svgIcon="vh-feed-24"></mat-icon>{{ 'common.contextFeed' | resx }}
		</button>

		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Calendar')"
			mat-menu-item
			(click)="openCalendar(item, $event)"
		>
			<mat-icon svgIcon="vh-calendar-24"></mat-icon>{{ 'common.сontextCalendar' | resx }}
		</button>

		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Gantt')"
			(click)="openGantt(item, $event)"
			mat-menu-item
		>
			<mat-icon svgIcon="vh-gant-24"></mat-icon>
			<span>{{ 'common.сontextGantt' | resx }}</span>
		</button>

		<button
			*ngIf="
				item.typeId === 'Subcategory' &&
				(!item.availableRepresentations || item.availableRepresentations.includes('Kanban'))
			"
			mat-menu-item
			(click)="openKanban(item, $event)"
		>
			<mat-icon svgIcon="vh-kanban-24"></mat-icon>{{ 'common.contextKanban' | resx }}
		</button>

		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Channel')"
			mat-menu-item
			(click)="openChannel(item, $event)"
		>
			<mat-icon svgIcon="vh-feed-24"></mat-icon>{{ 'common.channel' | resx }}
		</button>

		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Chat')"
			mat-menu-item
			(click)="openChat(item, $event)"
		>
			<mat-icon svgIcon="vh-chats-24"></mat-icon>{{ 'common.chat' | resx }}
		</button>

		<button
			*ngIf="!item.availableRepresentations || item.availableRepresentations.includes('Files')"
			mat-menu-item
			(click)="openFiles(item, $event)"
		>
			<vh-icon [name]="'cloud-storage-blank'" size="md"></vh-icon>
			{{ 'common.disk' | resx }}
		</button>
	</ng-template>
</mat-menu>

<mat-menu #openSelectionItems="matMenu">
	<ng-template matMenuContent let-data="data">
		<div class="menu-title">{{ 'common.selectionsInSubcat' | resx }}</div>
		<button *ngFor="let item of data.items" (click)="openGridWithSelection(data.subcatId, item.id)" mat-menu-item>
			{{ item.name }}
		</button>
	</ng-template>
</mat-menu>
