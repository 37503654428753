import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { ContextMenuModule } from '@valhalla/common/context-menu';

import { ContentPortalModule } from '../content-portal';
import { ApplicationsSearchModule } from '../../../features/applications/search';
import { QuickLinksUserDockComponent } from './quick-links-user-dock.component';
import { TippyModule } from '@ngneat/helipopper';

/**
 * Пользовательские быстрые ссылки, отображаются в левом верхнем угле тулбара для десктопа
 * и в нижней панели таб для мобилки
 */
@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		MatIconModule,
		MatMenuModule,
		ContentPortalModule,
		ApplicationsSearchModule,
		ContextMenuModule,
		TippyModule,
	],
	declarations: [QuickLinksUserDockComponent],
	exports: [QuickLinksUserDockComponent],
})
export class QuickLinksUserDockModule {}
