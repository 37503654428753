import { NamedEntity } from './entity';

export interface IUserStatus {
	/**User id */
	id: number;
	isOnline: boolean;
	state: UserStatusState;
	absences: IUserAbsence[];
}

export enum UserStatusState {
	none = 'None',
	/**нет на работе . на встрече */
	away = 'Away',
	/**уволен */
	fired = 'Fired',
	/**внешний сотрудник */
	external = 'External',
	/**в декретном отпуске */
	maternityLeave = 'MaternityLeave',
}

export interface IUserAbsence {
	id: number;
	dateStart: string;
	dateEnd: string;
	message: string;
	absenceType: IAbsenceType;
	freeBusyStatus: IBusyStatus;
}

export interface IBusyStatus extends NamedEntity {
	userColor: string;
}

export interface IAbsenceType extends IBusyStatus {
	isNonWorkingAbsence: boolean;
	showInAgenda: boolean;
}
