import { Injectable } from '@angular/core';
import { IContentIconCategory, IContentIconItem } from '../content-icon/content-icon.model';
import { loadContentIconsMetaData } from '../content-icon/load-icons';
(async () => loadContentIconsMetaData())();

@Injectable()
export class ContentIconsRegisterService {
	async getIconsCategories(): Promise<IContentIconCategory[]> {
		return Object.values((await loadContentIconsMetaData()).sets);
	}

	async getIconsItems(): Promise<IContentIconItem[]> {
		return Object.values((await loadContentIconsMetaData()).items);
	}
}
