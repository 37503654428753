import { IComment, ITaskInfo } from '@valhalla/data/entities';
import { Observable } from 'rxjs';

export abstract class FeedsDataHttpService {
	abstract comments(criteria: IGetFeedCommentsCriteria): Observable<IGetFeedCommentsResult>;
	abstract tasks(criteria: IGetFeedTasksCriteria, options?: Record<string, any>): Observable<IGetFeedTasksResult>;
	abstract tasksRawRequest(body: Record<string, any>): Observable<IGetFeedTasksResult>;
	abstract tasksSearchRequest(body: Record<string, any>): Observable<IGetFeedTasksResult>;
	abstract read(criteria: IFeedReadAllCriteria): Observable<any>;
	abstract readSupport(type: FeedCommentsType): boolean;
	abstract getCommentsFiltersBySubcat(subcatId: number): Observable<ICommentsFiltersBySubcat>;
	abstract feedBySP(criteria: IGetFeedTasksCriteria, options?: Record<string, any>): Observable<IGetFeedTasksResult>;
	abstract setCommentsFiltersBySubcat(subcatId: number, filters: ICommentsFiltersBySubcat): Observable<any>;
}

export interface ICommentsFiltersBySubcat {
	showAllCommentsFromTaskTree: boolean;
	showAllCommentsFromAllEvents: boolean;
	showAllComments: boolean;
}

export interface IFeedReadAllCriteria {
	type: FeedCommentsType;
}

export enum FeedCommentsType {
	all = 0,
	unread = 1,
	toMe = 2,
	myQuestions = 3,
	questionsToMe = 4,
	favorites = 5,
	myQuestionsAndAnswers = 6,
	questionToMeAndAnswers = 7,
}

export enum FeedTasksType {
	all = 'All',
	new = 'New',
	owner = 'Owner',
	performer = 'Performer',
	favorites = 'Favorites',
	tasksByCategory = 'TasksByCategory',
	/** Поставовка */
	stage = 'Stage',
	allForKanban = 'AllForKanban',
	allForNewKanban = 'AllForNewKanban',
	channel = 'Channel',
	created = 'Created',
	lastCommented = 'LastCommented',
}

export interface IGetFeedTasksCriteria {
	feedType?: string;
	limit: number;
	offset: number;
	showClosed?: boolean;
	showLastComment?: boolean;
	catIds?: number[];
	subcatIds?: number[];
	taskIds?: number[];
	closedOnlyF?: boolean;
	openOnlyF?: boolean;
	searchString?: string;
	myTasks?: string;
	tasksState?: string;
	smartExpressionIds?: number[];
}

export interface IGetFeedTasksResult {
	tasks: ITaskInfo[];
	tabs: Partial<{
		type: string;
		taskIds: number[];
		hasMore: boolean;
		hasMoreTasks: boolean;
		counter: number;
	}>[];
	categories: {
		permissions: any[];
		rules: any[];
		subcatID: number;
		subcatDescription: string;
	}[];
	templates: any[];
}

export interface IGetFeedCommentsCriteria {
	limit: number;
	offset: number;
	lentaCommTypes: number[];
	subcatId?: number;
	catId?: number;
	appName?: string;
	userId?: number;
	commentId?: number;
	limitBefore?: number;
	limitAfter?: number;
	searchString?: string;
	searchType?: string;
	includeReactions?: boolean;
	grouped?: boolean;
}

export interface IGetFeedCommentsResult {
	comments: IComment[];
	tasks: ITaskInfo[];
	tabs: {
		type: string;
		commentIds: number[];
		hasMore: boolean;
		counter: number;
	}[];
}
