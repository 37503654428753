import { Inject, Injectable } from '@angular/core';
import { AuthService, WINDOW } from '@valhalla/core';
import { ConfigurationDataHttpService, DataHttpService } from '@valhalla/data/http';
import { EMPTY, Subject } from 'rxjs';
import { concatMap, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';

import { CULTURE_TO_ALIAS_MAPPER } from './culture-to-alias-mapper';
import { LOCALIZATION_OPTIONS } from './localization-options';

import type { CultureToAliasMapperType } from './culture-to-alias-mapper';
import type { ILocalizationOptions } from './localization-options';

@Injectable({ providedIn: 'root' })
export class CultureService {
	constructor(
		readonly server: DataHttpService,
		@Inject(WINDOW) readonly window: Window,
		@Inject(CULTURE_TO_ALIAS_MAPPER) readonly mapCultureToAlias: CultureToAliasMapperType,
		@Inject(LOCALIZATION_OPTIONS) readonly localizationOptions: ILocalizationOptions,
		readonly auth: AuthService,
		readonly appSettings: ConfigurationDataHttpService
	) {}

	protected readonly updateLangsReq$ = new Subject();
	protected readonly updateActiveLangReq$ = new Subject();

	readonly appSettingsLanguages$ = this.appSettings
		.getAppSettingsAnonymous()
		.pipe(map(appSettings => this.transformPropsToLowCase(appSettings.Languages)));

	readonly languages$ = this.updateLangsReq$.pipe(
		startWith(0),
		concatMap(() => {
			if (this.auth.authenticated) {
				return this.server.locale.getLanguages();
			}

			return this.appSettingsLanguages$;
		}),
		shareReplay({ refCount: false, bufferSize: 1 })
	);

	readonly activeLanguage$ = this.updateActiveLangReq$.pipe(
		startWith(0),
		// isAuth
		switchMap(() => {
			if (this.auth.authenticated) {
				return this.server.locale.getCurrentLanguage();
			}

			const prevSelectedLang = localStorage.getItem(selectedLanguageStorageKey);

			if (prevSelectedLang) {
				return this.appSettingsLanguages$.pipe(map(lang => lang.find(l => l.alias === prevSelectedLang)));
			}

			return this.appSettingsLanguages$.pipe(map(lang => lang.find(l => l.isDefault)));
		}),
		tap(culture => (this._currentLanguage = culture)),
		shareReplay({ refCount: false, bufferSize: 1 })
	);

	readonly activeCulture$ = this.activeLanguage$.pipe(map(lang => lang.culture)); // ru-RU, en-US...

	private _currentLanguage;

	get activeCulture() {
		return this._currentLanguage?.culture;
	}

	transformPropsToLowCase(arr) {
		return arr.map(el => {
			const newObj = {};

			Object.keys(el).forEach(k => {
				newObj[this.firstLetterToLowerCase(k)] = el[k];
			});

			return newObj;
		});
	}

	firstLetterToLowerCase(string: string): string {
		if (!string) {
			return null;
		}
		return string.charAt(0).toLowerCase() + string.slice(1);
	}

	update() {
		this.updateLangsReq$.next(0 as any);
		this.updateActiveLangReq$.next(0 as any);
	}

	setLanguage(aliasOrCulture: string, allowNotification?: boolean) {
		const setAlias = this.mapCultureToAlias(aliasOrCulture);

		return this.activeLanguage$.pipe(
			take(1),
			switchMap(activeLang => {
				if (activeLang.alias === setAlias) {
					return EMPTY;
				}

				if (this.auth.authenticated) {
					return this.server.locale.setLanguage({ alias: setAlias }, allowNotification).pipe(
						tap(() => {
							localStorage.setItem(selectedLanguageStorageKey, aliasOrCulture);
							if (this.localizationOptions.refreshOnChangeLanguage) {
								this.window.location.reload();
							}
							this.updateActiveLangReq$.next(0 as any);
						})
					);
				}

				// !isAuth;
				localStorage.setItem(selectedLanguageStorageKey, aliasOrCulture);
				this.updateActiveLangReq$.next(0 as any);
				return EMPTY;
			})
		);
	}

	updateLanguageOnServer() {
		const selectedLanguageValue = localStorage.getItem(selectedLanguageStorageKey);
		if (selectedLanguageValue) {
			return this.server.locale.setLanguage({ alias: selectedLanguageValue });
		}
		return EMPTY;
	}
}

const selectedLanguageStorageKey = 'unauthorizeLanguage';
