import { environment } from '@spa/environment';

import { AppType } from '../apps.type';
import { IAppDefinition } from './model';

const fakeApps: IAppDefinition[] = [
	{
		id: 1,
		pid: 'category',
		type: AppType.system,
		name: ' Департамент разработки/Дерево задач/3. Функции ',
		description: 'Большие доработки, длящиеся несколько спринтов',
		icon: 'home',
		startup: {
			url: '/category/1',
		},
	},
	{
		id: 2,
		pid: 'category',
		type: AppType.system,
		name: `Департамент разработки/Дерево задач/4. Сценарии (496)`,
		description: 'Задачи в разработку, ограниченные одним спринтом',
		icon: 'dashboard',
		startup: {
			url: '/category/2',
		},
	},
	{
		id: 3,
		pid: 'report',
		type: AppType.system,
		name: `Manager's KPI`,
		description: 'kpi сотрудников отдела внедерения',
		icon: 'dashboard',
		iconUrl: 'https://placeimg.com/128/128/tech',
		startup: {
			url: '/report/21',
		},
	},
	{
		id: 4,
		pid: 'portal',
		type: AppType.system,
		name: `Главный портал`,
		description: 'Основной портал компании',
		icon: 'dashboard',
		iconUrl: 'https://placeimg.com/128/128/tech',
		startup: {
			url: '/portal',
		},
	},
	{
		id: 5,
		pid: 'task-hierarchy',
		type: AppType.system,
		name: `Иерархия задач Dev`,
		description: 'задачи отдела разработки',
		icon: 'dashboard',
		iconUrl: 'https://placeimg.com/128/128/tech',
		startup: {
			url: '/hierarchy/23',
		},
	},
	// {
	// 	id: 6,
	// 	pid: 'feed',
	// 	type: AppType.system,
	// 	name: `Лента`,
	// 	description: '',
	// 	icon: 'dashboard',
	// 	startup: {
	// 		url: '/feed'
	// 	}
	// },
	{
		id: 7,
		pid: 'agenda',
		type: AppType.external,
		name: `Повестка дня`,
		description: '',
		icon: 'view_agenda',
		startup: {
			url: '/spaex.aspx/agenda?hideFirstDayHeader=1&today=true',
		},
	},
	{
		id: 8,
		pid: 'calendar',
		type: AppType.external,
		name: `Календарь`,
		description: '',
		icon: 'calendar_today',
		startup: {
			url:
				'/Scheduler.aspx?today=true&fileBrowserHidden=true&openNewAbsence=&UserID=&selectedDate=&selectedCalendars=-1',
		},
	},
	{
		id: 9,
		pid: 'mail',
		type: AppType.external,
		name: `Почта`,
		description: 'почтовый клиент',
		icon: 'mail_outline',
		startup: {
			url: 'https://owa.1forma.ru/owa/',
		},
	},
	{
		id: 10,
		pid: 'file-storage',
		type: AppType.external,
		name: `Файловое хранилище`,
		description: 'хранилище файлов',
		icon: 'storage',
		startup: {
			url: '/spaex.aspx/file-storage?showlefttree=1&onlySpaStyles=1',
		},
	},
	{
		id: 11,
		pid: 'help-1f',
		type: AppType.external,
		name: `Справка`,
		description: 'Справка по обьектам первой формы',
		icon: 'help',
		startup: {
			url: 'http://help.1forma.ru/',
		},
	},
	{
		id: 12,
		pid: 'my-tasks',
		type: AppType.system,
		name: `Мои задачи`,
		description: `старница с быстрыми фильтрами по своим задачам: Я - исполнитель, Я - Заказчик, Я - подписчик, Я - акцептант и т.д.`,
		icon: 'assignment',
		startup: {
			url: '/my-tasks',
		},
	},
	{
		id: 13,
		pid: 'org-structure',
		type: AppType.system,
		name: `Оргструктура и сотрудники`,
		description: `Оргструктура компании`,
		icon: 'people',
		startup: {
			url: '/org-structure',
		},
	},
	{
		id: 14,
		pid: 'org-structure',
		type: AppType.system,
		name: `Приложение с очень очень длинным наименованием, которое не влезает в стандартное представление`,
		description: `Оргструктура компании`,
		icon: 'link',
		startup: {
			url: '/org-structure',
		},
	},
	{
		id: 15,
		pid: 'category',
		type: AppType.system,
		name: `Департамент разработки/Backlog/Backlog (829)`,
		description: 'Задачи в разработку',
		icon: 'dashboard',
		startup: {
			url: '/category/4',
		},
	},
	{
		id: 16,
		pid: 'category',
		type: AppType.system,
		name: `!Управление компанией/Внутренние документы 1Формы`,
		description: 'Внутренние документы 1Формы',
		icon: 'dashboard',
		iconUrl: 'https://placeimg.com/128/128/tech',
		startup: {
			url: '/category/4',
		},
	},
	{
		id: 17,
		pid: 'report',
		type: AppType.system,
		name: `Проектная деятельность/Табель рабочего времени`,
		description: 'Табель рабочего времени в ДПД',
		icon: 'dashboard',
		iconUrl: 'https://placeimg.com/128/128/tech',
		startup: {
			url: '/report/4',
		},
	},
	{
		id: 18,
		pid: 'report',
		type: AppType.system,
		name: `Задачи, не выполненные за рекомендуемый срок`,
		description: 'Задачи, не выполненные за рекомендуемый срок',
		icon: 'dashboard',
		iconUrl: 'https://placeimg.com/128/128/tech',
		startup: {
			url: '/report/4',
		},
	},
	{
		id: 19,
		pid: 'link',
		type: AppType.system,
		name: 'Главная',
		icon: 'home',
		startup: {
			url: '/',
		},
	},
	{
		id: 20,
		pid: 'link',
		type: AppType.system,
		name: 'Лента',
		icon: 'comment',
		startup: {
			url: '/feeds/comments',
		},
	},
	{
		id: 21,
		pid: 'link',
		type: AppType.system,
		name: 'Лента задач',
		icon: 'assignment',
		startup: {
			url: '/feeds/tasks',
		},
	},
	{
		id: 22,
		pid: 'link',
		type: AppType.system,
		name: 'Создать задачу',
		icon: 'note_add',
		startup: {
			url: '/tasks/new-from-category',
		},
	},
	{
		id: 23,
		pid: 'link',
		type: AppType.system,
		name: 'Лента задач и комментариев',
		icon: 'vertical_split',
		startup: {
			url: '/feeds/tc',
		},
	},
	{
		id: 24,
		pid: 'link',
		type: AppType.system,
		name: 'Чат',
		icon: 'chat',
		startup: {
			url: '/chat',
		},
	},
	{
		id: 25,
		pid: 'demo',
		type: AppType.system,
		name: 'Демо',
		icon: 'important_devices',
		startup: {
			url: '/demo',
		},
	},
	{
		id: 26,
		pid: 'link',
		type: AppType.system,
		name: 'Чат-лента',
		icon: 'language',
		startup: {
			url: '/chat/feed',
		},
	},
	{
		id: 27,
		pid: 'link',
		type: AppType.system,
		name: 'Совещание',
		icon: 'event',
		startup: {
			url: '/tasks/557824',
		},
	},
];

if (!environment.production) {
	fakeApps.push({
		id: fakeApps.length,
		pid: 'link',
		type: AppType.system,
		name: '🍺 DEV PAGE',
		icon: 'build',
		startup: {
			url: '/dev',
		},
	});
}

export { fakeApps };
