<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'left'">
	<vh-common-sidebar
		name="chatPanel"
		position="left"
		class="chat-panel left-chat-panel"
		[folded]="true"
		[foldedWidth]="70"
		[foldedAutoTriggerOnHover]="false"
		lockedOpen="gt-md"
	>
		<vh-layout-chat-panel></vh-layout-chat-panel>
	</vh-common-sidebar>
</ng-container>

<div id="main">
	<ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above'">
		<ng-container *ngTemplateOutlet="toolbar"></ng-container>
	</ng-container>

	<ng-container>
		<ng-container *ngTemplateOutlet="topNavbar"></ng-container>
	</ng-container>

	<ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below'">
		<ng-container *ngTemplateOutlet="toolbar"></ng-container>
	</ng-container>

	<div id="container-1" class="layout-container">
		<ng-container>
			<ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
		</ng-container>

		<div id="container-2" class="layout-container">
			<div
				id="container-3"
				class="layout-container"
				vhPerfectScrollbar
				[vhPerfectScrollbarOptions]="{ suppressScrollX: true, updateOnRouteChange: true }"
			>
				<vh-layout-content id="vh-layout-main-content-area" cdkScrollable></vh-layout-content>

				<ng-container *ngIf="fuseConfig.layout.footer.position === 'above-static'">
					<ng-container *ngTemplateOutlet="footer"></ng-container>
				</ng-container>
			</div>
		</div>
	</div>

	<ng-container *ngIf="fuseConfig.layout.footer.position === 'above-fixed'">
		<ng-container *ngTemplateOutlet="footer"></ng-container>
	</ng-container>
</div>

<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'right'">
	<vh-common-sidebar
		name="chatPanel"
		position="right"
		class="chat-panel right-chat-panel"
		[folded]="true"
		[foldedWidth]="70"
		[foldedAutoTriggerOnHover]="false"
		lockedOpen="gt-md"
	>
		<vh-layout-chat-panel></vh-layout-chat-panel>
	</vh-common-sidebar>
</ng-container>

<vh-common-sidebar name="quickPanel" position="right" class="quick-panel">
	<vh-layout-quick-panel></vh-layout-quick-panel>
</vh-common-sidebar>

<ng-template #toolbar>
	<vh-layout-toolbar
		*ngIf="!fuseConfig.layout.toolbar.hidden"
		[ngClass]="[fuseConfig.layout.toolbar.position, fuseConfig.layout.toolbar.background]"
	>
	</vh-layout-toolbar>
</ng-template>

<ng-template #footer>
	<footer
		*ngIf="!fuseConfig.layout.footer.hidden"
		[ngClass]="[fuseConfig.layout.footer.position, fuseConfig.layout.footer.background]"
	></footer>
</ng-template>

<ng-template #topNavbar>
	<vh-layout-navbar
		class="top-navbar d-none d-md-block"
		[ngClass]="fuseConfig.layout.navbar.background"
		*ngIf="!fuseConfig.layout.navbar.hidden"
		[showNavigationAlways]="fuseConfig.showNavigationAlways"
	>
	</vh-layout-navbar>
</ng-template>

<ng-template #leftNavbar>
	<vh-common-sidebar
		name="navbar"
		class="navbar-fuse-sidebar"
		[folded]="fuseConfig.layout.navbar.folded"
		*ngIf="!fuseConfig.layout.navbar.hidden"
		[pinned]="fuseConfig.showNavigationAlways"
		[showNavigationAlways]="fuseConfig.showNavigationAlways"
	>
		<vh-layout-navbar class="left-navbar" [ngClass]="fuseConfig.layout.navbar.background"></vh-layout-navbar>
	</vh-common-sidebar>
</ng-template>
