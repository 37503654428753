import { Injectable } from '@angular/core';
import { arrayToObject } from '@valhalla/utils';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApplicationActivator } from '../activator';
import { ApplicationsDbProvider, IAppDefinition } from '../db';
import { IUserQuickAppsDefinition, UserQuickAppsProvider } from '../user';
import { ApplicationsManagerProvider } from './abstract';

@Injectable()
export class ApplicationsManagerProviderImpl implements ApplicationsManagerProvider {
	constructor(
		protected appDb: ApplicationsDbProvider,
		protected userApps: UserQuickAppsProvider,
		protected activator: ApplicationActivator
	) {}

	selectAvailableApplications(...searchWords: string[]): Observable<IAppDefinition[]> {
		return this.appDb.selectApplications(...searchWords);
	}

	updateAvailableApplicationList(): Observable<number> {
		return this.appDb.updateAvailableApplicationList();
	}

	openApplication(app: number | Partial<IAppDefinition>): void {
		this.activator.openApplication(app);
	}

	addToUserQuickApps(...apps: Array<IUserQuickAppsDefinition>): void {
		this.userApps.addApps(...apps);
	}

	addToUserFavoritesMenu(...apps: Array<IUserQuickAppsDefinition>): void {}

	removeFromUserQuickApps(...apps: Array<IUserQuickAppsDefinition | number>): void {
		this.userApps.removeApps(...apps);
	}

	removeFromUserFavoritesMenu(...apps: Array<IUserQuickAppsDefinition | number>): void {}

	selectUserQuickApps(): Observable<IAppDefinition[]> {
		return combineLatest(
			this.userApps.selectUserApps(),
			this.appDb.selectApplications().pipe(map(apps => arrayToObject(apps, app => app.id)))
		).pipe(
			map(([userApps, dbApps]) => {
				const sorted = userApps.slice().sort((a, b) => Number(a.order) - Number(b.order));
				const result = sorted.map(userApp => dbApps[userApp.appId]);
				return result;
			})
		);
	}

	selectAppById(appId: number): Observable<IAppDefinition> {
		return this.appDb.selectAppById(appId);
	}

	resolveAppRoute(app: IAppDefinition): { url: string; query: Record<string, string> } {
		return this.activator.resolveAppRoute(app);
	}
}
