<mat-toolbar *ngIf="!isMobile" class="mat-elevation-z1" [class.p-0]="isMobile" [class.mobile-toolbar]="isMobile">
	<div class="toolbar-wrapper">
		<div class="toolbar-left-menu-container">
			<button
				*ngIf="!hiddenNavbar && !rightNavbar && !(mobileView.hideTabbar$ | async)"
				class="navbar-toggle-button"
				(click)="toggleSidebarOpen()"
			>
				<mat-icon id="toolbar-toggle-icon" class="secondary-text">menu </mat-icon>
			</button>
			<button *ngIf="mobileView.hideTabbar$ | async" class="navbar-toggle-button" (click)="prevPage()">
				<mat-icon
					svgIcon="vh-arrow-v2-L-right-24"
					class="secondary-text"
					[style.transform]="'rotate(180deg)'"
				></mat-icon>
			</button>

			<div class="logo-wrapper" *ngIf="horizontalNavbar">
				<div class="logo ml-16"><img class="logo-icon" src="assets/images/logos/1f.png" /></div>
			</div>

			<!-- portal for quick links or mobile header -->
		</div>
		<vh-portal-host name="app-mobile-toolbar" [class.is-mobile]="mobileView.mobileMode">
			<!-- tickers and system menu -->
			<ng-container *ngTemplateOutlet="defaultPortal"></ng-container>
		</vh-portal-host>
	</div>
</mat-toolbar>

<div *ngIf="isMobile" class="mat-elevation-z1 mobile-toolbar">
	<div class="mobile-toolbar-wrapper">
		<div class="toolbar-left-menu-container">
			<button
				*ngIf="!hiddenNavbar && !rightNavbar && !(mobileView.hideTabbar$ | async)"
				class="navbar-toggle-button"
				(click)="toggleSidebarOpen()"
				[class.hidden]="mobileView.hideMainMenuHamburgerBtn$ | async"
			>
				<mat-icon id="toolbar-toggle-icon" class="secondary-text">menu </mat-icon>
			</button>
			<button *ngIf="mobileView.hideTabbar$ | async" class="navbar-toggle-button" (click)="prevPage()">
				<mat-icon
					svgIcon="vh-arrow-v2-L-right-24"
					class="secondary-text"
					[style.transform]="'rotate(180deg)'"
				></mat-icon>
			</button>

			<div class="mobile-toolbar-logo-wrapper" *ngIf="horizontalNavbar">
				<div class="logo ml-16"><img class="logo-icon" src="assets/images/logos/1f.png" /></div>
			</div>

			<!-- portal for quick links or mobile header -->
		</div>
		<vh-portal-host
			name="app-mobile-toolbar"
			[class.is-mobile]="mobileView.mobileMode"
			[style.padding-left.px]="(mobileView.hideMainMenuHamburgerBtn$ | async) ? 16 : 0"
		>
			<!-- tickers and system menu -->
			<ng-container *ngTemplateOutlet="defaultPortal"></ng-container>
		</vh-portal-host>
	</div>
</div>

<ng-template #defaultPortal>
	<div class="toolbar-portal">
		<!-- <vh-toolbar-tickers></vh-toolbar-tickers> -->
		<div *ngIf="mobileView.mobileMode" class="toolbar-title">{{ mobileView.pageTitle$ | async }}</div>
		<vh-common-search-bar #searchBar (search)="search($event); searchBar.collapse()"></vh-common-search-bar>

		<mat-menu #userMenu="matMenu" [overlapTrigger]="false">
			<!-- ng-template fix for lazy rendering menu on iOS -->
			<ng-template matMenuContent>
				<button (click)="goToPage('/user/profile')" type="button" mat-menu-item>
					<mat-icon>account_circle</mat-icon>
					<span>{{ 'common.profile' | resx }}</span>
				</button>

				<button (click)="goToPage('/user/profile-edit')" type="button" mat-menu-item class="">
					<mat-icon>edit</mat-icon>
					<span>{{ 'common.editProfile' | resx }}</span>
				</button>

				<button (click)="goToPage('/user/notification-settings')" type="button" mat-menu-item class="">
					<mat-icon>notifications_none</mat-icon>
					<span>{{ 'common.notifications' | resx }}</span>
				</button>

				<button (click)="goToPage('/user/substitutes')" type="button" mat-menu-item class="">
					<mat-icon>people</mat-icon>
					<span>{{ 'common.assistants' | resx }}</span>
				</button>

				<button (click)="goToPage('/user/mobile-apps')" type="button" mat-menu-item class="">
					<mat-icon>phone_iphone</mat-icon>
					<span>{{ 'common.mobileApps' | resx }}</span>
				</button>

				<button (click)="goToPage('/user/misc')" type="button" mat-menu-item class="">
					<mat-icon>bookmarks</mat-icon>
					<span>{{ 'common.other' | resx }}</span>
				</button>

				<button (click)="goToPage('/user/time-sheet')" type="button" mat-menu-item class="">
					<mat-icon>access_alarm</mat-icon>
					<span>{{ 'common.timesheet' | resx }}</span>
				</button>

				<button (click)="toggleSidebarOpen('themeOptionsPanel')" type="button" mat-menu-item class="">
					<mat-icon>settings</mat-icon>
					<span>{{ 'common.uisettings' | resx }}</span>
				</button>

				<button (click)="openWindow('/admin/default.aspx')" type="button" mat-menu-item class="">
					<mat-icon>settings</mat-icon>
					<span>Админ</span>
				</button>

				<button
					(click)="openWindow('http://help.1forma.ru/', true)"
					type="button"
					mat-menu-item
					aria-label="Open help info"
				>
					<mat-icon>help_outline</mat-icon>
					<span>{{ 'common.help' | resx }}</span>
				</button>

				<button (click)="goToPage('/infra/changelog')" type="button" mat-menu-item aria-label="Open help info">
					<mat-icon>info</mat-icon>
					<span>{{ 'common.clientVersion' | resx }}</span>
				</button>

				<button (click)="refreshApp()" type="button" mat-menu-item aria-label="Open help info">
					<mat-icon>refresh</mat-icon>
					<span>{{ 'common.refresh' | resx }}</span>
				</button>

				<button (click)="auth.logout()" type="button" mat-menu-item class="">
					<mat-icon>exit_to_app</mat-icon>
					<span>{{ 'common.logOff' | resx }}</span>
				</button>
			</ng-template>
		</mat-menu>

		<button *ngIf="!hiddenNavbar && rightNavbar" class="navbar-toggle-button" (click)="toggleSidebarOpen('navbar')">
			<mat-icon class="secondary-text">menu</mat-icon>
		</button>
	</div>
</ng-template>
