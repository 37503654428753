import { IAction, ReducerBase, produce } from '@valhalla/core';

import * as actions from '../actions';
import { INavigationState } from '../state';

export class SetActiveTabNavigationMenuReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.setActiveTabMenu;

	reduce(state: Readonly<INavigationState>, action: IAction<actions.ISetActiveMenuTabPayload>) {
		return produce(state, ds => {
			const { menuTabActiveId } = action.payload;
			ds.activeTabMenuId = menuTabActiveId;
		});
	}
}

export const activeReducers = [SetActiveTabNavigationMenuReducer];
