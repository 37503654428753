import { Injectable, OnDestroy, inject } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * @description view provider for destroy stream
 * @example
		@Component({
			selector: 'hello',
			// Basic provider
			viewProviders: [ ViewDestroyStreamService ]
		})
		export class HelloComponent implements OnInit {
			// Basic inject
			constructor(private ngOnDestroy$: ViewDestroyStreamService) {}

			ngOnInit() {
				observable.pipe(
					// Basic use
					takeUntil(this.ngOnDestroy$)
				).subscribe();
			}
		}
 */
@Injectable()
export class ViewDestroyStreamService extends Subject<null> implements OnDestroy {
	// TODO: add explicit constructor

	ngOnDestroy() {
		this.next(0 as any);
		this.complete();
	}
}

export function useDestroyRef() {
	return inject(ViewDestroyStreamService, { self: true });
}

useDestroyRef.provider = ViewDestroyStreamService;
