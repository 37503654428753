import { Pipe, PipeTransform } from '@angular/core';
import { UserAvatarLink } from './user-avatar-link.service';

@Pipe({ name: 'chatUserAvatarLink', pure: true })
export class ChatUserAvatarLinkPipe implements PipeTransform {
	constructor(readonly avatarLink: UserAvatarLink) {}

	transform(userOrId: any, timestamp?: number): string {
		return this.avatarLink.get(userOrId, timestamp);
	}
}
