import { ExtParamTypeEnum } from '@valhalla/data/entities';

/**структура получения данных с сервера для таблицы */
export interface IEpTableDataDto<T extends IEpTableDataRowCellDto = IEpTableDataRowCellDto> {
	data: {
		group: Array<any>;
		data: Array<IEpTableDataRowDto<T>>;
		total: number;
		aggregates: any;
	};
	totals: {
		totals: IEpTableDataTotal[];
	};
}

export type IEpTableDataRowDto<T extends IEpTableDataRowCellDto = IEpTableDataRowCellDto> = IEpTableDataRowAnyDto<T> &
	IEpTableDataRowIdDto;
interface IEpTableDataRowAnyDto<T> {
	[key: string]: T;
}

interface IEpTableDataRowIdDto {
	id: number;
}

export interface IEpTableDataRowCellDto {
	[key: string]: any;
	boolValue: boolean;
	dataSourceItemId: number;
	dateTimeValue: string;
	decimalValue: number;
	intValue: number;
	stringValue: string;
	newValue?: any;
	selectUsersValue?: Partial<{
		hasAvatar: boolean;
		id: number;
		name: string;
		type: 'User' | 'Group' | 'OrgUnit';
		userAvatarFile: { fileId: number; latestVersionId: number };
	}>[];
}

/**значение агрегата по колонке */
export interface IEpTableDataTotal {
	/**id колонки */
	id: number;
	/**имя колонки */
	name: string;
	/**значение агрегата */
	value: number;
}

export interface IEpTableSettingsDto {
	tableSettings?: {
		autosaveEnabled: boolean;
		scrollingHeight?: number;
		id?: number;
		multiselectColumnId?: number;
		rowsPerPage?: number;
		/**Кол-во итераций загрузки одного файла */
		numberOfIterationsForDownloadOneFile?: number;
		/**Кол-во параллельных загрузок */
		numberOfParallelDownloads?: number;
		/**Время ожидания загрузки одного файла */
		timeToWaitForDownloadOneFile?: number;
		/**Время ожидания для повторной загрузки файла */
		timeToWaitForReloadFile?: number;
		/**Загружать файлы в момент вкладывания */
		uploadWhenFileAttach?: boolean;
		/**Ключ выделенного веб-сервера */
		webServerKey?: string;
		useRowIdAsKeyColumn?: boolean;
		addNewRowModal?: boolean;
	};
	tableColumnsSettings?: ITableEpColumnDescription[];
	tableMultiselectColumnsSettings?: ITableEpColumnDescription[];
	readonly: boolean;
	canAddNewRows?: boolean;
	canRemoveRows?: boolean;
	exportEnabled?: boolean;
	importEnabled?: boolean;
	columnDependencies?: Array<{
		dependentColumnId: number;
		parentColumnId: number;
	}>;
}

export interface IGetTableSettingsCriteria {
	epId: number;
	subcatId: number;
	taskId: number;
}

export interface IGetTableDataCriteria {
	epId: number;
	taskId: number;
	meta?: Partial<IGetTableDataPayload>;
}

export interface IUpdateTableDataParams {
	epId: number;
	taskId: number;
	meta: {
		rows: Array<{
			id: number;
			modifyType: 'create' | 'update' | 'delete';
			cols: Array<IUpdateEpTableCellValue>;
			lookupId?: number;
			clientRow?: any;
		}>;
	};
}

export interface IUpdateEpTableCellValue {
	/**
	 * id столбца без 'c' префикса (c1234 -> 1234)
	 */
	id: number;
	value: any;
	dataSourceItemId?: any;
}

export interface IGetTableDataPayload {
	take: number;
	skip: number;
	sort: any[];
	group: any[];
	filter: any;
}

/**описание колонок ДП Таблица*/
export interface ITableEpColumnDescription {
	[key: string]: any;

	/**Идентификатор атрибута */
	id: number;

	/**Название столбца, которое будет отображаться в таблице */
	name: string;

	/**значение по умолчанию */
	defaultValue?: string;

	selectUsersDefaultValue?: Array<any>;

	/**Порядок расположения атрибутов (столбцов) в таблице, слева направо */
	orderId?: number;

	/**Тип данных атрибута.
	 * Доступные типы данных: текст, выпадающий список, текст с маской, деньги,
	 * дата, телефон, LookUpField, флажок, число, файл */
	columnType: ExtParamTypeEnum;

	dataSource?: any;
	dataValueField?: string;
	dataTextField?: string;
	setOfElementsAsDataSource?: boolean;

	/**Задается маска для типа данных текст с маской. При описании маски используются следующие спецсимволы:
	 * # - цифра или пробел,
	 * L - обязательно большая буква,
	 * l - обязательно маленькая буква,
	 * a - любой символ,
	 * <n..m> - обязательно числовое значение от n до m,
	 * <Символ1|Символ2|Символ3> - один из указанных символов,
	 * \x - вставить в маску любой символ (например, "\а" вставит в маску символ "а"). */
	mask?: string;

	/**Формировать итоговое значение.
	 * Флажок доступен для типов "Число" или "Деньги" */
	enableTotal?: boolean;

	/**режим отображения колонки */
	mode?: 'Default' | 'ReadOnly' | 'Required' | 'Invisible';

	/**Ширина колонки в пикселях. Если не задано (значение равно 0), то рассчитывается автоматически */
	columnWidth?: number;

	align?: TableEpColumnTextAlign;

	lookupParamSettingId?: any;
	smartExpressionId?: any;

	/**идентификатор другого ДП (для лукапа или выпадающего списка) */
	referencedExtParamId?: any;
	referencedExtParamLookupSettings?: Partial<{
		gridVirtualPageSize: number;
		groupsCollapsed: boolean;
		hierarchyLookupDisableFolderSelection: boolean;
		isDropdownLookup: boolean;
		isHierarchyLookup: boolean;
		isLayoutPlanLookup: boolean;
		smartFilterId: number;
		subcatId: number;
		summaryCategoryId: number;
		viewPageSize: number;
		viewAsText: boolean;
	}>;
	referencedExtParamNumberSettings?: Partial<{
		guid: string;
		id: number;
		isWithFractionalPart: boolean;
		maxValue: number;
		minValue: number;
		scale: number;
		sliderColor: any;
		step: number;
		type: string;
		useGroupSeparator: boolean;
		useZeroAsEmptyValue: boolean;
	}>;
	referencedExtParamSelectUserSettings?: any;

	taskIdContextField?: any;

	/**флаг указывающий прикрепить колонку слева */
	locked?: any;

	virtualColumnReferencedLookupColumnId?: number;

	virtualColumnReferencedExtParamId?: number;

	isVirtual?: boolean;
	maxHeight?: number;
	showImagePreview?: boolean;
	imagePreviewWidth?: number;
	imagePreviewHeight?: number;
	template?: string;
	filter?: string;

	winAppLinks: boolean;

	buttons: Array<
		Partial<{
			description: string;
			hasServerExecution: boolean;
			iconUrl: string;
			id: number;
			idField: string;
			isActiveField: string;
			isVisibleField: string;
			jsExpression: string;
			name: string;
		}>
	>;
	isMultifile: boolean;
	extParamId: number;
	allowScanning?: boolean;
	showEdsIcon?: boolean;
}

/**Определяет способ выравнивания текста в столбце */
export enum TableEpColumnTextAlign {
	/**Способ "по умолчанию" подразумевает выравнивание по левому краю для текстовых полей и по правому краю для чисел. */
	default = 0,

	/**по левому краю */
	left = 1,

	/**по правому краю */
	right = 3,

	/**по центру */
	center = 2,

	/**по ширине */
	onWidth = 4,
}

export function alignToString(align: TableEpColumnTextAlign) {
	switch (align) {
		case TableEpColumnTextAlign.default:
			return 'align-default';
		case TableEpColumnTextAlign.center:
			return 'align-center';
		case TableEpColumnTextAlign.left:
			return 'align-left';
		case TableEpColumnTextAlign.right:
			return 'align-right';
		case TableEpColumnTextAlign.onWidth:
			return 'align-onWidth';
		default:
			break;
	}
	return 'align-default';
}
