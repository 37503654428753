// tslint:disable-next-line:nx-enforce-module-boundaries
import { enumWithNs } from '@valhalla/utils';
import { ActionCreator } from '../../store';
import { IConfigurationModel } from './state';

const Actions = Object.freeze({
	updateConfiguration: 'updateConfiguration',
});

export const configurationStoreName = 'core/configuration';

export const CoreConfigurationAction = enumWithNs(Actions, configurationStoreName);

export const updateConfigurationActionCreator: ActionCreator<Partial<IConfigurationModel>> = payload => ({
	type: CoreConfigurationAction.updateConfiguration,
	payload,
});
