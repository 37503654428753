import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppVersionService, UrlProvider, ViewDestroyStreamService } from '@valhalla/core';
import { DataHttpService, ISystemInformation } from '@valhalla/data/http';
import { BooleanFilter, rxHandler, sortByLocaleCompare, copyToClipboard, queryParamsObjectFrom } from '@valhalla/utils';
import { combineLatest, from } from 'rxjs';
import {
	distinctUntilChanged,
	map,
	share,
	shareReplay,
	startWith,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { IFavoriteBtn, IMenuAdminElement, IOpenStates } from './nav-panel-admin.model';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { IAdminTreeItem } from '@spa/data/http/admin/abstract';
import { INavPanelAdminItem } from './nav-panel-admin-item/nav-panel-admin-item.model';
import { filterItems } from './nav-panel-admin.helper';
import { getAppTopInjector } from '@spa/api/injectors';
import { ModalWindowsService } from '@spa/facade/features/modals/abstract';
import { IBadgeColor } from 'libs/core-components/src/lib/badge/badge.model';

@Component({
	selector: 'vh-layout-nav-panel-admin',
	templateUrl: './nav-panel-admin.component.html',
	styleUrls: ['./nav-panel-admin.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class NavPanelAdminComponent implements OnInit {
	constructor(
		readonly url: UrlProvider,
		protected readonly destroy$: ViewDestroyStreamService,
		readonly server: DataHttpService,
		readonly router: Router,
		readonly titleService: Title,
		readonly appVersion: AppVersionService,
		readonly mobileService: MobileViewService,
		readonly cdr: ChangeDetectorRef
	) {}

	@HostBinding('class.vh-layout-nav-panel-admin')
	hostClassSelector = true;

	@Output()
	expandCollapseEvent = new EventEmitter();

	menuElements: IMenuAdminElement[] = [];
	menuElementsbySearch: IMenuAdminElement[] = [];
	favoriteBtns: IFavoriteBtn[] = [];
	isSearchActive = false;
	isOpenStates: IOpenStates[] = [];
	systemInfoElements: ISystemInformation;
	isOpenPopular = false;
	popularSections = [];
	isSpaAdminMode = false;
	showOnlySpaMode = false;

	frontBuildTime = this.appVersion.getRuntimeBuildTime();
	frontVersion = this.appVersion?.appInfo?.version;

	get modal() {
		return getAppTopInjector().get(ModalWindowsService, null);
	}

	//readonly showSystemInfo$ = new BehaviorSubject(false);
	//readonly showTopBar$ = new BehaviorSubject(true);
	readonly systemInfo$ = this.server.system.getInfo();
	readonly searchHandler = rxHandler<string>();
	readonly searchInput$ = from<string>(this.searchHandler as any).pipe(
		tap(query => {
			if (query) {
				this.isSearchActive = true;
			} else {
				this.isSearchActive = false;
			}
		}),
		startWith('')
	);
	readonly searchInputHasValue$ = this.searchInput$.pipe(distinctUntilChanged(), map(BooleanFilter));

	readonly menuElementsbySearch$ = this.searchInput$.pipe(
		map(query => {
			if (query) {
				const filteredElements = this.menuElements.filter(item => {
					return item.name.toLowerCase().includes(query.toLowerCase());
				});
				// удаляем пустые parent
				filteredElements.forEach((el, idx) => {
					// проверяем только parent
					if (el.id) {
						if (!filteredElements.some(elem => elem.parentId && elem.parentId === el.id)) {
							filteredElements.splice(idx, 1);
						}
					}
				});

				// добавляем parent если есть вложенные в него элементы
				filteredElements.forEach(el => {
					// проверяем только вложенные элементы
					if (!el.id) {
						const parentEl = this.menuElements.find(menuEl => menuEl.id === el.parentId);
						if (!filteredElements.some(elem => elem.id && elem.id === parentEl.id)) {
							filteredElements.push(parentEl);
						}
					}
				});
				return sortByLocaleCompare(filteredElements, element => element.name);
			} else {
				return this.menuElements;
			}
		})
	);

	readonly appSettingsAnonymous$ = this.server.config.getAppSettingsAnonymous().pipe(share());
	readonly adminPanel$ = this.appSettingsAnonymous$.pipe(
		switchMap(settings => {
			if (settings.CustomAdminPanelPath) {
				return from(
					fetch(settings.CustomAdminPanelPath)
						.then(res => res.json())
						.catch(err => {
							console.error(`json CustomAdminPanelPath: ${settings.CustomAdminPanelPath} parse failed`);
							throw err;
						})
				);
			}
			return from(
				fetch(this.url.getUrlRelativeToAssets(`config/admin-panel.json?t=${Date.now()}`)).then(res => res.json())
			);
		})
	);
	readonly isSpaAdminMode$ = this.appSettingsAnonymous$.pipe(map(s => Boolean(s.CustomSettings?.isSpaAdminMode)));
	readonly menuElements$ = combineLatest([this.adminPanel$, this.appSettingsAnonymous$]).pipe(
		map(([menu, settings]) => {
			const isSpaAdminMode = Boolean(settings.CustomSettings?.isSpaAdminMode);
			const parentShow = new Set<string>();
			['elements', 'buttons', 'popular'].forEach(group => {
				menu[group]?.forEach(e => {
					e.title = `Админ - ${e.name} - ${settings.ApplicationName}`;
					if (this.showOnlySpaMode) {
						e.hide = isSpaAdminMode ? !Boolean(e.showInSpaMode) : false;
					}
					if (!e.hide && e.parentId) {
						parentShow.add(e.parentId);
					}
				});
				if (this.showOnlySpaMode) {
					menu[group] = (menu[group] || []).filter(i => !i.hide || parentShow.has(i.id));
				}
			});
			return menu;
		})
	);

	readonly adminTree$ = this.server.admin.getAdminTree().pipe(
		map(items => {
			const actualItems = Array.isArray(items)
				? items.filter(item => !(item.nodeType === 'Form' && !item?.parentId))
				: [];
			const sortedItems = sortByLocaleCompare(actualItems, item => item?.name);

			return this.createTree(sortedItems || []);
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	readonly isNewAdminTree$ = this.adminTree$.pipe(map(tree => tree?.length));

	readonly adminTreeBySearch$ = combineLatest([this.searchInput$, this.adminTree$]).pipe(
		map(([filter, items]) => {
			if (!filter) {
				return items;
			}

			return filterItems(items, filter);
		}),
		tap(() => {
			setTimeout(() => {
				this.cdr.detectChanges();
			}, 30);
		})
	);

	ngOnInit(): void {
		this.menuElements$
			.pipe(
				tap(res => {
					this.popularSections = res.popular;
					this.menuElements = sortByLocaleCompare(res.elements, element => element.name);
					this.menuElementsbySearch = this.menuElements;
					this.favoriteBtns = res.buttons || [];
					this.isOpenStates = res.elements.reduce((acc: IOpenStates[], el: IMenuAdminElement) => {
						if (el.id) {
							const state = { id: el.id, isOpen: false };
							acc.push(state);
						}
						return acc;
					}, []);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();

		this.menuElementsbySearch$
			.pipe(takeUntil(this.destroy$))
			.subscribe(elements => (this.menuElementsbySearch = elements));

		this.systemInfo$.pipe(takeUntil(this.destroy$)).subscribe(el => (this.systemInfoElements = el));
		this.isSpaAdminMode$.pipe(takeUntil(this.destroy$)).subscribe(val => (this.isSpaAdminMode = val));
	}

	expandCollapseTreeItem(e) {
		console.log(e);
	}

	onTreeItemClick({ e, item }: { e: any; item: INavPanelAdminItem }) {
		if (item?.nodeType === 'Folder') {
			return;
		}

		if (item?.url && item?.url?.includes('.aspx')) {
			return this.navLink(['/link'], { queryParams: { url: item?.url } }, e);
		}

		const url = item?.url ? item?.url : `/admin-ds/forms/${item.alias}/grid`;
		const mouseEvent = e as MouseEvent;
		const openInNewTab = mouseEvent?.ctrlKey || mouseEvent?.metaKey;

		if (openInNewTab) {
			const urlForNewWindow = this.url.getUrl(url, true);
			return window.open(urlForNewWindow, '_blank');
		} else {
			const urlForNavigate = this.url.getUrl(url);
			if (urlForNavigate.includes('TaskScanner')) {
				return this.navLink(['/link'], { queryParams: { url: urlForNavigate } }, e);
			}
			const queryParams = queryParamsObjectFrom(urlForNavigate);
			const urlNav = urlForNavigate.split('?')[0];
			return this.router.navigate([urlNav], { queryParams: queryParams });
		}
	}

	createTree(arr: IAdminTreeItem[]) {
		const arrMap = arr.map(item => ({
			...item,
			name: item?.name,
			children: [],
		}));

		const res = arrMap.filter(item => {
			item.children = item.nodeType === 'Folder' ? arrMap.filter(i => i.parentId === item.id) : [];
			item.children = item.children.sort((a, b) => (a.nodeType === 'Folder' ? -1 : 0));
			const isRoot = !item?.parentId || !arrMap.find(i => i?.id === item?.parentId);
			return isRoot;
		});

		return res;
	}

	isOpen(id: string) {
		const elIndex = this.isOpenStates.findIndex(el => el.id === id);
		return this.isOpenStates[elIndex].isOpen;
	}

	expandGroup(event, id: string) {
		if (event.target !== event.currentTarget) return;
		const elIndex = this.isOpenStates.findIndex(el => el.id === id);
		this.isOpenStates[elIndex].isOpen = !this.isOpenStates[elIndex].isOpen;
	}

	// toggleShowSystemInfo() {
	// 	this.showSystemInfo$.next(!this.showSystemInfo$.value);
	// 	this.showTopBar$.next(!this.showTopBar$.value);
	// }

	systemRestart() {
		if (confirm('Вы действительно хотите перезагрузить пул приложения ?')) {
			this.server.system.restartApplicationPool().pipe(take(1)).subscribe();
		}
	}

	denormalize() {
		if (confirm('Вы действительно хотите денормализовать все категории ?')) {
			this.server.system.denormalizeAllSubcats().pipe(take(1)).subscribe();
		}
	}

	clearCaches() {
		if (confirm('Вы действительно хотите очистить локальные кеши приложения ?')) {
			this.server.system.clearLocalCaches().pipe(take(1)).subscribe();
		}
	}

	copyVersion(version, v = true) {
		copyToClipboard((v ? 'v' : '') + version);
	}

	onHover(element) {}

	navLink(navParts: any[], navExtras: Record<any, any>, e?: Event) {
		const mouseEvent = e as MouseEvent;
		const openInNewTab = mouseEvent?.ctrlKey || mouseEvent?.metaKey;

		if (openInNewTab) {
			let serializeUrl = this.router.serializeUrl(this.router.createUrlTree(navParts, navExtras));
			serializeUrl = this.url.getUrl(serializeUrl, true);
			return window.open(serializeUrl, navExtras.state.title);
		} else {
			if (navExtras?.state?.title) {
				this.titleService.setTitle(navExtras.state.title);
			}
			return this.router.navigate(navParts, navExtras);
		}
	}

	onMenuLeafClick(leaf: any, e?: Event) {
		const spaOpen = this.isSpaAdminMode && leaf.showInSpaMode;
		// if (leaf.name === 'Импорт конфигурации') {
		// 	return this.navLink(['admin/migration'], {}, e);
		// }
		if (spaOpen) {
			return this.navLink([leaf.spaModeUrl], { queryParams: { title: leaf.title }, state: { title: leaf.title } }, e);
		} else {
			// excludes
			const url: string = leaf.url || '';
			if (url.toLowerCase().startsWith('/spa.aspx/portal/admin')) {
				return this.navLink(
					['/administration/old-portal-admin'],
					{ queryParams: { title: leaf.title }, state: { title: leaf.title } },
					e
				);
			}
			return this.navLink(
				['/link'],
				{ queryParams: { url: leaf.url, title: leaf.title }, state: { title: leaf.title } },
				e
			);
		}
	}

	isMenuLeafSelected(leaf: any) {
		const spaOpen = this.isSpaAdminMode && leaf.showInSpaMode; //|| leaf?.name === 'Импорт конфигурации';
		if (spaOpen) {
			return this.isItemSelected(
				[leaf.spaModeUrl],
				{
					queryParams: { title: leaf.title },
					state: { title: leaf.title },
				},
				true,
				leaf
			);
		} else {
			return this.isItemSelected(
				['/link'],
				{
					queryParams: { url: leaf.url, title: leaf.title },
					state: { title: leaf.title },
				},
				false
			);
		}
	}

	isItemSelected(navParts: any[], navExtras: Record<any, any>, spanOpen: boolean, leaf?) {
		const serializeUrl = this.router.serializeUrl(this.router.createUrlTree(navParts, navExtras));
		// if (leaf?.name === 'Импорт конфигурации') {
		// 	return this.router.url === '/administration/migration/import';
		// }
		if (spanOpen && serializeUrl.includes('/admin/')) {
			/**@see https://ru.1forma.ru/spa/tasks/1432689 */
			return serializeUrl.replace('/admin/', '/administration/') === this.router.url;
		}
		return this.router.url === serializeUrl;
	}

	btnCounter(btn) {
		const counter = {
			counter: 0,
			counterNew: 0,
			counterWarn: 0,
			counterNewColor: '',
		};
		if (btn?.iconName === 'users') {
			counter.counterNew = this.systemInfoElements?.totalUsersOnline;
			counter.counterNewColor = IBadgeColor.success;
			counter.counter = this.systemInfoElements?.totalUsersCount;
		}
		if (btn?.iconName === 'info') {
			counter.counterWarn = this.systemInfoElements?.totalErrosDuringLastHour;
		}
		return counter;
	}

	onAddAdminRow(item: IAdminTreeItem) {
		const url = `/admin-ds/forms/${item.alias}/create`;
		const urlForNavigate = this.url.getUrl(url);
		this.router.navigate([urlForNavigate]);
	}
}
