import { Inject, Injectable, Optional } from '@angular/core';
import { LoggerFactory } from '../diagnostics/logger';
import { PersistentStorePluginFactory, StorePluginFactory } from './plugins';
import { createStoreFactory, IRxStore, IRxStoreConfig } from './rx-store';
import { StoreManager } from './store-manager.base';

@Injectable()
export class StoreManagerImpl implements StoreManager {
	constructor(
		@Optional() readonly logger?: LoggerFactory,
		@Optional()
		@Inject(PersistentStorePluginFactory)
		readonly persistentStorePluginFactory?: StorePluginFactory
	) {}

	private _destroyed = false;
	private _stores = new Map<string, { store: IRxStore }>();

	setStore(store: IRxStore) {
		if (this.isStoreExist(store.storeName)) {
			_throw(`store ${store.storeName} already exist!`);
		}
		this._stores.set(store.storeName, { store });
		return store;
	}

	createStore(config: IRxStoreConfig): IRxStore {
		this._throwIfDestroy();
		if (typeof config !== 'object') {
			_throw('createStore method must be call with config object!');
		}
		if (this._stores.has(config.name)) {
			_throw(`Store with name [${config.name}] already created!`);
		}
		if (Boolean(config.persistent) && Boolean(this.persistentStorePluginFactory)) {
			config.plugins = [this.persistentStorePluginFactory.createPlugin(), ...(config.plugins || [])];
		}
		const store = createStoreFactory(config);
		this._stores.set(store.storeName, { store });
		this._applyLoggerToStore(store);
		return store;
	}

	getStore(name: string): IRxStore {
		this._throwIfDestroy();
		if (!this._stores.has(name)) {
			_throw(`Store with name [${name}] not found!`);
		}
		return this._stores.get(name).store;
	}

	isStoreExist(name: string): boolean {
		return this._stores.has(name);
	}

	destroyStore(name: string) {
		this._throwIfDestroy();
		const store = this.getStore(name);
		store.destroy();
		this._stores.delete(name);
	}

	destroy() {
		if (this._destroyed) {
			return;
		}
		const errors = [];
		this._stores.forEach(value => {
			try {
				value.store.destroy();
			} catch (e) {
				errors.push(e);
			}
		});
		this._stores.clear();
		this._destroyed = true;
		if (Boolean(errors[0])) {
			_throw(errors.map(e => String(e)).join('\n'));
		}
	}

	private _throwIfDestroy() {
		if (this._destroyed) {
			_throw('Store manager has already been destroyed!');
		}
	}

	private _applyLoggerToStore(store: IRxStore) {
		if (!store || !this.logger) {
			return;
		}
		const logger = this.logger.createLogger(`store:${store.storeName}`);
		store.log$.subscribe(arg => {
			if (logger[arg.method]) {
				const loggerOptions = logger.createOptions({
					useEventLog: arg.useEventLog,
				});
				logger[arg.method](loggerOptions, ...arg.data);
			}
		});
	}
}

function _throw(msg: string) {
	throw new Error(msg);
}
