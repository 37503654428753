import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataHttpCommonService } from '../data-http-common.service';
import { CachesDataHttpService, ICacheDto, IRebusQueueDto } from './abstract';
import { Observable } from 'rxjs';
import { IApiResponse } from '../api-response';

@Injectable()
export class CachesDataHttpServiceImpl implements CachesDataHttpService {
	constructor(readonly http: HttpClient, readonly common: DataHttpCommonService) {}

	getCachesList(): Observable<IApiResponse<ICacheDto[]>> {
		const url = `caches`;
		return this.http.get<IApiResponse<ICacheDto[]>>(this.common.getApiUrl(url));
	}

	updateAllCaches(): Observable<void> {
		const url = this.common.getApiUrl(`caches/updateallcaches`);
		return this.http.post<void>(url, {});
	}

	getAllCacheKeys(cacheTypeName: string): Observable<IApiResponse<string[]>> {
		const url = this.common.getApiUrl(`caches/keys?cacheTypeName=${cacheTypeName}`);
		return this.http.get<IApiResponse<string[]>>(url);
	}

	getValuesCountInCache(cacheTypeName: string): Observable<IApiResponse<number>> {
		const url = this.common.getApiUrl(`caches/count?cacheTypeName=${cacheTypeName}`);
		return this.http.get<IApiResponse<number>>(url);
	}

	getValueByKey(cacheTypeName: string, key: string): Observable<any> {
		const url = this.common.getApiUrl(`caches/value?cacheTypeName=${cacheTypeName}&key=${key}`);
		return this.http.get<void>(url);
	}

	updateCache(cacheTypeName: string): Observable<void> {
		const url = this.common.getApiUrl(`caches/updatecache?cacheTypeName=${cacheTypeName}`);
		return this.http.post<void>(url, {});
	}

	getQueuesList(): Observable<IApiResponse<IRebusQueueDto[]>> {
		const url = this.common.getApiUrl(`caches/rebusqueues`);
		return this.http.get<IApiResponse<IRebusQueueDto[]>>(url);
	}

	clearQueue(queue: string): Observable<void> {
		const url = this.common.getApiUrl(`caches/clearqueue?queue=${queue}`);
		return this.http.post<void>(url, {});
	}

	deleteQueue(queue: string): Observable<void> {
		const url = this.common.getApiUrl(`caches/deletequeue?queue=${queue}`);
		return this.http.post<void>(url, {});
	}
}
