import { Injectable } from '@angular/core';
import { isArray, searchWordsInTexts } from '@valhalla/utils';
import { LoggerFactory, IndexedDbProvider, useDb } from '@valhalla/core';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApplicationsDbProvider } from './abstract';
import { ApplicationsDatabase } from './database';
import { IAppDefinition } from './model';
import { fakeApps } from './fake-apps-data';

@Injectable()
export class ApplicationsDbProviderImpl implements ApplicationsDbProvider {
	constructor(protected dbProvider: IndexedDbProvider, protected loggerFactory: LoggerFactory) {}

	protected db$ = this.dbProvider.getDatabase(ApplicationsDatabase);
	protected logger = this.loggerFactory.createLogger('ApplicationsDbProviderImpl');

	protected useDb<R>(cb: (db: ApplicationsDatabase) => Observable<R>) {
		return useDb(this.db$, cb);
	}

	selectApplications(...searchWords: string[]): Observable<IAppDefinition[]> {
		searchWords = searchWords.filter(Boolean);
		return this.useDb(db => {
			if (!isArray(searchWords) || searchWords.length === 0) {
				return from(db.applications.toArray());
			} else {
				return from(
					db.applications
						// @see full text search https://dexie.org/docs/Table/Table.hook('creating')
						.filter(appDefinition => searchWordsInTexts([appDefinition.name, appDefinition.description], searchWords))
						.toArray()
				);
			}
		});
	}

	updateAvailableApplicationList() {
		return of(fakeApps).pipe(
			switchMap(appList => {
				return this.useDb(db => {
					return from(db.applications.bulkPut(appList));
				}) as Observable<number>;
			})
		);
	}

	selectAppById(appId: number): Observable<IAppDefinition> {
		return this.useDb(db => from(db.applications.get(appId)));
	}
}
