/**
 * Список системных кук, обрабатываемых сервером
 */
export enum SystemCookies {
	/**
	 * Данная кука включает/отключает отображение шапки и левого меню во фреймах aspx 1Ф
	 */
	forceHideHeaderAndTrees = 'ForceHideHeaderAndTrees',
	auth1Forma = '1FormaAuth',
}
