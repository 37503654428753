import { Inject, Injectable, Injector } from '@angular/core';
import { SpaCommandRegisterService } from '@spa/api';
import { DataHttpService } from '@valhalla/data/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { ModalContentAreaService } from '../modal-content-area';
import { IsDev } from './is-dev';

@Injectable({ providedIn: 'root' })
export class OpenPortalService {
	constructor(
		protected readonly spaCommands: SpaCommandRegisterService,
		protected readonly server: DataHttpService,
		protected readonly modalContent: ModalContentAreaService,
		protected readonly injector: Injector,
		@Inject(IsDev) protected readonly isDev: boolean
	) {}

	protected externalInjector: Injector;
	protected registered = false;
	// protected portalModule$ = defer(() => from(import('../../pages/portal/portal.module').then(m => m.DashboardModule)));
	// protected dashboardModule$ = defer(() =>
	// 	from(import('../../pages/portal/dashboard/dashboard.module').then(m => m.PortalDashboardModule))
	// );

	// protected portalModuleInjector$ = this.dashboardModule$.pipe(
	// 	map(mod => {
	// 		const modInjector = createInjector(mod, this.externalInjector || this.injector);
	// 		return modInjector.get(mod);
	// 	})
	// );
	// protected readonly portalComponentFactory$ = this.portalModuleInjector$.pipe(
	// 	map(mod => mod.resolvePortalDashboardComponent())
	// );

	setupInjector(injector: Injector) {
		this.externalInjector = injector;
	}

	registerCommandOpenPortal() {
		if (this.registered) {
			// tslint:disable-next-line:no-console
			return console.warn('OpenPortalService already register command openPortalInContentArea');
		}

		this.spaCommands.registerCommand('openPortalInContentArea', payload => {
			const portalId = Number(payload?.portalId);
			if (isNaN(portalId) || portalId <= 0) {
				return console.error(`Invalid portalId parameter =${portalId}`);
			}
			return this.openPortal(portalId).pipe(
				take(1),
				catchError(err => {
					console.error(err);
					return EMPTY;
				})
			);
		});

		this.registered = true;
	}

	openPortal(portalId: number): Observable<any> {
		const url = this.isDev ? `/noframe/portal/${portalId}` : `/spa/noframe/portal/${portalId}`;

		return of(
			this.modalContent.openIframe({
				context: {
					url: url,
				},
			})
		);
	}
}
