<div *ngIf="tagColor" class="vh-fv-expand-list-item__tag">
	<span [style.background-color]="tagColor"></span>
</div>

<div *ngIf="icon" class="vh-fv-expand-list-item__icon-prefix">
	<i vhFeatherIcon="{{ icon }}" class="reset-icon-size mat-prefix-icon vh-fv-expand-list-item__icon"></i>
</div>

<div
	class="vh-fv-expand-list-item__label"
	[ngClass]="labelClass"
	[class.ellipsis]="!lineClamped"
	class="{{ lineClamped ? 'clamped-' + lineClamped : '' }}"
>
	<mat-icon
		*ngIf="expandable"
		class="arrow-icon forma-icon-size"
		(click)="onExpandClick($event)"
		[svgIcon]="expand ? 'vh-arrow-v1-S-down-24' : 'vh-arrow-v1-S-right-24'"
	></mat-icon>
	{{ label }}
</div>

<div class="vh-fv-expand-list-item__sub">
	<div class="vh-fv-expand-list-item__counters">
		<vh-badge
			*ngIf="counterWarn"
			[color]="IBadgeColor.warning"
			[value]="counterWarn"
			[tippy]="'Просроченные'"
			[delay]="400"
		></vh-badge>

		<vh-badge
			*ngIf="counterNew"
			[color]="counterNewColor || IBadgeColor.info"
			[value]="counterNew"
			[tippy]="'Новые'"
			[delay]="400"
		></vh-badge>

		<vh-badge
			*ngIf="counter"
			[color]="IBadgeColor.default"
			[value]="counter"
			[emphasis]="IBadgeEmphasis.container"
			[tippy]="'Всего'"
			[delay]="400"
		></vh-badge>
	</div>

	<div class="vh-fv-expand-list-item__icon">
		<ng-content select="[vhFvExpandListItemIcon]"></ng-content>
	</div>
</div>
