import { NgZone } from '@angular/core';
import { FileViewerService } from '@spa/common/components/file-viewer/file-viewer.service';
import { Downloader } from '@spa/core';
import { IAttachment, isOfficeFile, isPdf } from '@valhalla/data/entities';

import { radopen } from '../global/radopen';
import { getAppTopInjector } from '../injectors';
import { SpaCommandExecutorFactory } from '../spa-command.contract';

const createCommand: SpaCommandExecutorFactory = injector => {
	return function openFilePreview(payload: { file: Partial<IAttachment>; taskId: number; previewLink?: string }) {
		const { file, taskId, previewLink } = payload || {};

		const facadeInjector = getAppTopInjector();
		injector = facadeInjector || injector;
		const zone = injector.get(NgZone);
		const viewer = injector.get(FileViewerService, null);
		if (zone.runTask(() => viewer?.OpenFileViewer(file, null, taskId))) {
			return;
		}
		const downloaderService = injector.get(Downloader);

		if (!file) {
			// tslint:disable-next-line:no-console
			console.warn(`Can't open file preview, invalid params, property file is not specified`, { payload });
			return;
		}

		const fileId = file.id || file.uploadId || file.uploadID;
		const versionId = file.versionID || file.versionId;
		const fileName = file.name || file.fileName;

		if (previewLink) {
			return radopen(previewLink, fileName);
		}

		let filePreviewUrl = `/FilePreview.aspx?inRadWindow=1&id=${fileId}`;

		if (isOfficeFile(file)) {
			filePreviewUrl = downloaderService.createPreviewOfficeWebAppLink(file); // `/component/WAFrame.aspx?fileId=${fileId}&versionId=${versionId}`;
		}

		if (isPdf(file.mime)) {
			filePreviewUrl = `/PDFPreview.aspx?id=${fileId}&GetTrueMime=1&VersionId=${versionId}`;
		}

		if (taskId) {
			filePreviewUrl += `&taskId=${taskId}`;
		}

		return radopen(filePreviewUrl, fileName);
	};
};

export default createCommand;
