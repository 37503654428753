import { Injectable, Injector, inject } from '@angular/core';
import { DataHttpService } from '@spa/data/http';
import { BehaviorSubject, Observable, Subject, combineLatest, filter, map, take, tap } from 'rxjs';
import { CRONS, ICron, ICronProvide, ICronSettings } from './cron';
import { rxSetInterval } from '@valhalla/utils';

@Injectable({ providedIn: 'root' })
export class CronService {
	protected injector = inject(Injector);
	protected active$ = new BehaviorSubject(false);
	protected initialized = false;
	protected server: DataHttpService;
	protected cronSettings$: Observable<Record<string, ICronSettings>>;
	protected crons: ICronProvide<ICron>[];
	protected cronInfo = new Map<string, Partial<CronInfo>>();

	start() {
		this.init();
		this.active$.next(true);
	}

	stop() {
		this.active$.next(false);
	}

	getInfo(name: string) {
		return this.cronInfo.get(name);
	}

	protected init() {
		if (this.initialized) {
			return;
		}
		this.initialized = true;
		this.server = this.injector.get(DataHttpService);
		this.cronSettings$ = this.server.config.appSettingsAnonymousConfig$.pipe(
			map(s => (s.CustomSettings?.backgroundTasks as Record<string, ICronSettings>) || {})
		);
		this.crons = this.injector.get(CRONS, []);
		this.crons.forEach(cron => this.activateCron(cron));
	}

	protected activateCron(provideConfig: ICronProvide<ICron>) {
		try {
			if (this.cronInfo.has(provideConfig.name)) {
				throw new Error(`Duplicate cron with name '${provideConfig.name}'!`);
			}
			const info: Partial<CronInfo> = {
				provideConfig,
				updated$: new Subject(),
			};
			this.cronInfo.set(provideConfig.name, info);
			this.cronSettings$
				.pipe(
					map(s => s[provideConfig.name] || ({} as ICronSettings)),
					take(1)
				)
				.subscribe(cronSettings => {
					info.instance = this.injector.get(provideConfig.type);
					info.settings = Object.assign(provideConfig.defaultSettings || {}, cronSettings);
					// interval run
					if (info.settings.interval > 0) {
						combineLatest([rxSetInterval(info.settings.interval), this.active$])
							.pipe(
								filter(([, active]) => active && info.settings.isEnabled && !info.running),
								tap(() => {
									info.running = true;
									info.instance.run({
										complete: result => {
											info.firstEmited = true;
											info.running = false;
											info.error = undefined;
											info.lastResult = result;
											info.updated$.next();
										},
										fail: err => {
											info.firstEmited = true;
											info.running = false;
											info.error = err;
											info.updated$.next();
										},
										data: info.settings.data,
										lastResult: info.lastResult,
									});
								})
							)
							.subscribe();
					}
				});
		} catch (error) {
			console.error(`Failed activate cron`, provideConfig);
			console.error(error);
		}
	}
}

type CronInfo = {
	settings: ICronSettings;
	provideConfig: ICronProvide<ICron>;
	instance: ICron;
	updated$: Subject<void>;
	lastResult: any;
	error: any;
	running?: boolean;
	firstEmited?: boolean;
};
