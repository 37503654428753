import { Location } from '@angular/common';
import { Inject, Provider, Injectable, Optional, Injector, inject } from '@angular/core';
import { firstLetterLowerCaseMapper, trimProps } from '@valhalla/utils';
import { UrlProvider } from '@spa/core';
import { API_ORIGIN } from './api-origin';

import { ApiVersion } from './api-versions';
import { RootAppUrl } from './root-app-url.token';

export abstract class DataHttpCommonService {
	abstract injector: Injector;
	abstract apiVersion: typeof ApiVersion;
	abstract urlProvider: UrlProvider;
	abstract getEndpointUrl(url: string): string;
	abstract normalizeResponseDto<T>(item: T): T;
	abstract getApiUrl(url: string, apiVer?: ApiVersion, apiPrefix?: string): string;
	abstract getApiCoreUrl(url: string, apiVer?: ApiVersion): string;
}

@Injectable()
export class DataHttpCommonServiceImpl implements DataHttpCommonService {
	constructor(
		@Inject(RootAppUrl) readonly rootAppUrl: string,
		readonly urlProvider: UrlProvider,
		@Optional() @Inject(API_ORIGIN) readonly apiOrigin?: string
	) {
		this.rootUrl = this.removeSpaPrefix(rootAppUrl);
	}

	readonly injector = inject(Injector);

	readonly apiVersion = ApiVersion;

	readonly rootUrl: string;

	getEndpointUrl(url: string): string {
		return Location.joinWithSlash(this.rootUrl, url);
	}

	normalizeResponseDto<T>(item: T): T {
		return trimProps(item, firstLetterLowerCaseMapper);
	}

	/** return right URL prefix with BASE path for API
	 * {basePath}/app/v1.0/api/{your url}
	 */
	getApiUrl(url: string, apiVer?: ApiVersion, apiPrefix = 'api'): string {
		let result: string;
		if (!url) {
			throw new Error(`Invalid url='${url}'`);
		}
		if (url.startsWith(`/${apiPrefix}`) || url.startsWith(`${apiPrefix}/`)) {
			throw new Error(`Invalid url='${url}', /${apiPrefix}/ must not be include in target url`);
		}
		if (apiVer) {
			result = this.joinWithSlash('/', this.removeSpaPrefix(this.rootAppUrl), `/app/${apiVer}/${apiPrefix}/`, url);
		} else {
			result = this.joinWithSlash('/', this.removeSpaPrefix(this.rootAppUrl), `${apiPrefix}`, url);
		}
		if (this.apiOrigin) {
			return this.joinWithSlash(this.apiOrigin, result);
		}
		return result;
	}

	getApiCoreUrl(url: string, apiVer?: ApiVersion): string {
		return this.getApiUrl(url, apiVer, 'api-core');
	}

	joinWithSlash(...urls: string[]) {
		return (urls || []).reduce((acc, curr) => Location.joinWithSlash(acc, curr), '');
	}

	/** сейчас префикс /spa/, а для работы с API нужно обращение на /app/...
	 * // TODO: этот метод надо будет удалить как SPA будет доступно по пути / вместо /spa
	 *  для ядра недопустимы харкоды - нужно в конфигурацию выносить это
	 */
	protected removeSpaPrefix(url: string): string {
		return url && url.replace('/spa/', '');
	}
}

export const DataHttpCommonServiceProvider: Provider = {
	provide: DataHttpCommonService,
	useClass: DataHttpCommonServiceImpl,
};
