let timer = null;
let duration = 800;

export function touchStart(onLongTouchHandler: (T) => void) {
	if (!timer) {
		timer = setTimeout(onLongTouchHandler, duration);
	}
}

export function touchEnd() {
	if (timer) {
		clearTimeout(timer);
		timer = null;
	}
}
