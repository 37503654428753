import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PerfectScrollbarDirective } from '@valhalla/common/directives/perfect-scrollbar';
import { LayoutFacade } from '@spa/facade/layout/layout-facade.service';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { SidebarService } from '@spa/common/services/sidebar.service';

@Component({
	selector: 'vh-layout-navbar-vertical-style-1',
	templateUrl: './style-1.component.html',
	styleUrls: ['./style-1.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
	constructor(
		private _layoutConfigService: LayoutFacade,
		private _fuseSidebarService: SidebarService,
		private _router: Router
	) {
		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}
	@Input()
	navigation: any;

	fuseConfig: any;
	fusePerfectScrollbarUpdateTimeout: any;
	// Private
	private _fusePerfectScrollbar: PerfectScrollbarDirective;
	private _unsubscribeAll: Subject<any>;

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	// Directive
	@ViewChild(PerfectScrollbarDirective)
	set directive(theDirective: PerfectScrollbarDirective) {
		if (!theDirective) {
			return;
		}

		this._fusePerfectScrollbar = theDirective;

		// Scroll to the active item position
		this._router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				take(1)
			)
			.subscribe(() => {
				setTimeout(() => {
					const activeNavItem: any = document.querySelector('navbar .nav-link.active');

					if (activeNavItem) {
						const activeItemOffsetTop = activeNavItem.offsetTop,
							activeItemOffsetParentTop = activeNavItem.offsetParent.offsetTop,
							scrollDistance = activeItemOffsetTop - activeItemOffsetParentTop - 48 * 3 - 168;

						this._fusePerfectScrollbar.scrollToTop(scrollDistance);
					}
				});
			});
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this._router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(() => {
				if (this._fuseSidebarService.getSidebar('navbar')) {
					this._fuseSidebarService.getSidebar('navbar').close();
				}
			});

		// Subscribe to the config changes
		this._layoutConfigService.state$.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
			this.fuseConfig = config;
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		if (this.fusePerfectScrollbarUpdateTimeout) {
			clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
		}

		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(0 as any);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar opened status
	 */
	toggleSidebarOpened(): void {
		this._fuseSidebarService.getSidebar('navbar').toggleOpen();
	}

	/**
	 * Toggle sidebar folded status
	 */
	toggleSidebarFolded(): void {
		this._fuseSidebarService.getSidebar('navbar').toggleFold();
	}
}
