import { Injectable } from '@angular/core';
import { isPwaMode } from '@valhalla/utils';
import { BrowserHosterService } from './browser-hoster.service';
import { ElectronHosterService } from './electron-hoster.service';
import { IHoster } from './hoster';
import { PwaHosterService } from './pwa-hoster.service';

@Injectable({ providedIn: 'root' })
export class PlatformHosterService implements IHoster {
	constructor(
		protected browserHost: BrowserHosterService,
		protected electronHost: ElectronHosterService,
		protected pwaHoster: PwaHosterService
	) {}

	protected hoster = this.chooseHoster();

	get isOneTabMode(): boolean {
		return this.hoster.isOneTabMode;
	}

	focusTab(): void {
		return this.hoster.focusTab();
	}

	setupJitsiRenderer(api, options = {}) {
		return this.hoster.setupJitsiRenderer(api, options);
	}

	disposeJitsiRenderer() {
		return this.hoster.disposeJitsiRenderer();
	}

	protected chooseHoster(): IHoster {
		if (isPwaMode()) {
			return this.pwaHoster;
		}
		if (typeof window['electron'] === 'object') {
			return this.electronHost;
		}
		return this.browserHost;
	}
}
