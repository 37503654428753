const isDev = () => Boolean((window as any).__IS_DEV__);

export const helper = {
	logger: {
		error(...args) {
			window.console && window.console.error(...args);
		},
		log(...args) {
			isDev() && window.console.debug(...args);
		},
		warn(...args) {
			window.console && window.console.warn(...args);
		},
	},
	tryRun(fn: () => void, ...data: any[]) {
		try {
			fn();
		} catch (err) {
			helper.logger.error(err, ...data);
		}
	},
};
