import { fromEvent, Observable } from 'rxjs';

// todo: https://stackoverflow.com/questions/7460272/getting-image-dimensions-using-javascript-file-api
export function getImageDimension(file: File, timeout = 5000): Observable<any> {
	return new Observable(observer => {
		const timeoutId = setTimeout(() => observer.error(new Error('Timeout of get image dimensions!')), timeout);
		try {
			const fr = new FileReader();
			fr.onabort = e => {
				clearTimeout(timeoutId);
				observer.error(e);
			};
			fr.onerror = e => {
				clearTimeout(timeoutId);
				observer.error(e);
			};
			fr.onload = () => {
				const img = new Image();
				img.onload = () => {
					clearTimeout(timeoutId);
					if (!observer.closed) {
						observer.next({
							imgFile: file,
							imgWidth: img.width,
							imgHeight: img.height,
						});
						observer.complete();
					}
				};
				img.src = fr.result as string;
			};
			fr.readAsDataURL(file);
		} catch (error) {
			clearTimeout(timeoutId);
			observer.error(error);
		}
	});
}

export function base64ImgFromDataUrl(dataUrl: string) {
	const prefix = 'base64,';
	const idxOf = dataUrl?.indexOf(prefix);
	if (idxOf !== -1) {
		return dataUrl.substring(idxOf + prefix.length);
	}
	return dataUrl;
}

export function bytesArrayToBase64(array: any[], mimeType: string) {
	let binary = '';
	const bytes = new Uint8Array(array);
	const len = bytes.byteLength;
	for (let i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return `data:${mimeType};base64,${window.btoa(binary)}`;
}

export function blobToDataUrl(blob: Blob | File, timeout = 5000): Observable<string | ArrayBuffer> {
	return new Observable(observer => {
		const timeoutId = setTimeout(() => observer.error(new Error('Timeout of blobToDataUrl!')), timeout);
		try {
			const fr = new FileReader();
			fr.onabort = e => {
				clearTimeout(timeoutId);
				observer.error(e);
			};
			fr.onerror = e => {
				clearTimeout(timeoutId);
				observer.error(e);
			};
			fr.onload = () => {
				clearTimeout(timeoutId);
				if (!observer.closed) {
					observer.next(fr.result);
					observer.complete();
				}
			};
			fr.readAsDataURL(blob);
		} catch (error) {
			clearTimeout(timeoutId);
			observer.error(error);
		}
	});
}

export function dataUrlToBlob(url: string, timeout = 5000): Observable<Blob> {
	return new Observable(observer => {
		const timeoutId = setTimeout(() => observer.error(new Error('Timeout of dataUrlToBlob!')), timeout);
		try {
			fetch(url)
				.then(res => res.blob())
				.then(blob => {
					clearTimeout(timeoutId);
					if (!observer.closed) {
						observer.next(blob);
						observer.complete();
					}
				})
				.catch(error => {
					clearTimeout(timeoutId);
					observer.error(error);
				});
		} catch (error) {
			clearTimeout(timeoutId);
			observer.error(error);
		}
	});
}

export type FileDialogOptions = {
	multiple: boolean;
	accept: string;
};

export type FileDialogResult = {
	files: File[];
};

export const FileDialogAccept = {
	images: '.jpg, .png, .jpeg, .webp|image/*',
	all: '*',
};

function defaultFileDialogOptions(): FileDialogOptions {
	return {
		multiple: true,
		accept: FileDialogAccept.all,
	};
}

export function openFileDialog(options?: Partial<FileDialogOptions>) {
	options = Object.assign(defaultFileDialogOptions(), options);

	return new Observable<FileDialogResult>(observer => {
		const inputEl = document.createElement('input');
		inputEl.type = 'file';
		inputEl.style.display = 'none';
		inputEl.multiple = options.multiple;
		inputEl.accept = options.accept;

		function onPickFiles(e: Event) {
			const files = Array.from((<HTMLInputElement>e.target).files);
			inputEl.value = null;
			observer.next({ files });
			observer.complete();
		}

		const change$ = fromEvent(inputEl, 'change');
		const pickFiles$ = change$.subscribe(onPickFiles);

		document.body.appendChild(inputEl);
		inputEl.click();

		function dispose() {
			pickFiles$.unsubscribe();
			document.body.removeChild(inputEl);
		}

		return () => {
			dispose();
		};
	});
}

export function bytesToKB(size: number) {
	return size / 1024;
}

export function bytesToMB(size: number) {
	return size / (1024 * 1024);
}

export function bytesToGB(size: number) {
	return size / (1024 * 1024 * 1024);
}
