import { Injector } from '@angular/core';

export function domPatch(injector: Injector) {
	nearestViewportElement();
}

/**@see https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Releases/109#removals */
function nearestViewportElement() {
	const hasProp = 'nearestViewportElement' in SVGGraphicsElement.prototype;
	if (!hasProp) {
		Object.defineProperty(SVGGraphicsElement.prototype, 'nearestViewportElement', {
			get() {
				return this.viewportElement;
			},
		});
	}
}
