<mat-toolbar *ngIf="showToolbar" class="vh-modal-toolbar">
	<span *ngIf="title">{{ title }}</span>
	<ng-content select="[modal-base-title]"></ng-content>
	<span class="mla"></span>
	<button
		class="vh-btn vh-btn-icon vh-btn--transparent vh-modal-btn-close"
		*ngIf="showOpenInNewTab"
		(click)="openInNewTab.emit()"
	>
		<mat-icon class="s-20">launch</mat-icon>
	</button>
	<button class="vh-btn vh-btn-icon vh-btn--transparent vh-modal-btn-close" *ngIf="showClose" (click)="close.emit()">
		<mat-icon class="s-20">close</mat-icon>
	</button>
</mat-toolbar>

<div class="pt-4 mb-4 flex-1 modal-base-content-container">
	<ng-content></ng-content>
</div>

<div class="flex modal-base-actions">
	<span class="mla"></span>
	<ng-content select="[modal-base-before-cancel]"></ng-content>
	<div class="vh-btn-container">
		<button class="vh-btn {{ cancelClass }}" *ngIf="showCancel" (click)="cancel.emit()">
			{{ cancelTitle ? cancelTitle : (cancelResx | resx) }}
		</button>
		<ng-content select="[modal-base-before-ok]"></ng-content>
		<button
			*ngIf="showOk"
			[vhAutoFocus]="okAutofocus"
			class="vh-btn vh-btn--accent {{ okClass }}"
			(click)="ok.emit()"
			[disabled]="okDisabled"
		>
			{{ okTitle ? okTitle : (okResx | resx) }}
		</button>
	</div>
	<ng-content select="[modal-base-after-ok]"></ng-content>
</div>
