import { Routes } from '@angular/router';
import { RedirectNotFoundResolver } from '@spa/pages/not-found/not-found.resolver';
import { AuthAdminGuard } from '@valhalla/core';
import { UnsafeChangesGuard } from '../guards/unsave-changes.guard';
import { CanActivateDefaultPage } from './default-page.guard';
import { developmentRoutes } from './pages-routes.dev';

export const productionRoutes: Routes = [
	{
		path: '',
		// redirectTo: 'portal/new',
		pathMatch: 'full',
		canActivate: [CanActivateDefaultPage],
		children: [],
	},
	{
		// сервисные страницы (404...)
		path: 'svc',
		loadChildren: () => import('../pages/svc/svc.module').then(m => m.SvcPagesModule),
	},
	{
		path: 'admin',
		redirectTo: 'administration',
		// loadChildren: () => import('../pages/admin/admin.module').then(m => m.AdminFeatureModule),
		// canActivate: [AuthAdminGuard],
	},
	{
		path: 'tasks',
		loadChildren: () => import('../components/task').then(m => m.FeatureTaskModule),
	},
	{
		path: 'kanban',
		loadChildren: () => import('../pages/kanban/kanban.module').then(m => m.PageKanbanModule),
	},
	{
		path: 'gantt',
		loadChildren: () => import('../pages/gantt/gantt.module').then(m => m.PageGanttModule),
	},
	{
		path: 'portal',
		loadChildren: () => import('../pages/portal/portal.module').then(m => m.DashboardModule),
	},
	{
		path: 'search',
		loadChildren: () => import('../pages/search/search.module').then(m => m.SearchModule),
	},
	{
		path: 'user',
		loadChildren: () => import('../pages/user/user.module').then(m => m.UserModule),
	},
	{
		path: 'user',
		pathMatch: 'full',
		redirectTo: 'user/profile',
	},
	{
		path: 'jitsi',
		redirectTo: 'conference',
	},
	{
		path: 'conference',
		loadChildren: () => import('../pages/jitsi/jitsi.module').then(m => m.JitsiModule),
	},
	{
		path: 'feeds',
		loadChildren: () => import('../pages/feed/feed.module').then(m => m.FeedModule),
		canDeactivate: [UnsafeChangesGuard],
	},
	{
		path: 'file',
		loadChildren: () => import('../pages/file/file.module').then(m => m.FileModule),
	},
	{
		path: 'report',
		loadChildren: () => import('../pages/report/report.module').then(m => m.ReportModule),
	},
	{
		path: 'link',
		loadChildren: () => import('../pages/link/link.module').then(m => m.LinkModule),
		canDeactivate: [UnsafeChangesGuard],
	},
	{
		path: 'infra',
		loadChildren: () => import('../pages/infra/infra.module').then(m => m.InfraModule),
	},
	{
		path: 'chat',
		loadChildren: () => import('../pages/chat/chat.module').then(m => m.ChatModule),
		canDeactivate: [UnsafeChangesGuard],
	},
	{
		path: 'demo',
		loadChildren: () => import('../pages/demo/demo.module').then(m => m.DemoPagesModule),
	},
	{
		path: 'data-source',
		loadChildren: () => import('../pages/data-source/data-source.module').then(m => m.DataSourcePageModule),
	},
	{
		path: 'scheduler',
		loadChildren: () => import('../pages/scheduler/scheduler.module').then(m => m.SchedulerPageModule),
	},
	{
		path: 'subcat',
		loadChildren: () => import('../pages/subcat/subcat.module').then(m => m.SubcatModule),
	},
	{
		path: 'org',
		loadChildren: () => import('../pages/org/org.module').then(m => m.OrgPageModule),
	},
	{
		path: 'survey',
		loadChildren: () => import('../pages/survey/survey.module').then(m => m.SurveyModule),
	},
	{
		path: 'administration',
		canActivate: [AuthAdminGuard],
		loadChildren: () =>
			import('../pages/administration/administration-page.module').then(m => m.AdministrationPageModule),
	},
	{
		path: 'ds',
		loadChildren: () =>
			import('../pages/data-source/custom-data-sources/data-source.module').then(m => m.DataSourceModule),
	},
	{
		path: 'calendar-event',
		loadChildren: () =>
			import('../pages/calendar-event-view/calendar-event-viewer-page-routing.module').then(
				m => m.CalendarEventViewPageRoutingModule
			),
	},
	{
		path: 'calendar-events',
		loadChildren: () =>
			import('../pages/calendar-events/calendar-events-page.module').then(m => m.CalendarEventsPageModule),
	},
	{
		path: 'calendar',
		loadChildren: () => import('../pages/calendar/calendar-page.module').then(m => m.CalendarPageModule),
	},
	{
		path: 'scheduling-assistant',
		loadChildren: () =>
			import('../pages/scheduling-assistant/scheduling-assistant-page.module').then(
				m => m.SchedulingAssistantPageModule
			),
	},
	{
		path: 'resolutions',
		loadChildren: () => import('../pages/resolutions/resolutions.module').then(m => m.PageResolutionsModule),
	},
	{
		path: 'task-hierarchy',
		loadChildren: () => import('../pages/task-hierarchy/task-hierarchy.module').then(m => m.TaskHierarchyModule),
	},
	{
		path: 'subtasks',
		loadChildren: () => import('../pages/subtasks/subtasks-page.module').then(m => m.SubtasksPageModule),
	},
	{
		path: 'timeline-events',
		loadChildren: () => import('../pages/timeline-events/timeline-events.module').then(m => m.TimelineEventsPageModule),
	},
	{
		path: 'resource-table',
		loadChildren: () =>
			import('../common/components/resource-table/resource-table.module').then(m => m.ResourceTableModule),
	},
	{
		path: 'resource-table',
		loadChildren: () =>
			import('../common/components/resource-table/resource-table.module').then(m => m.ResourceTableModule),
	},
	{
		path: 'error/404',
		resolve: {
			fromUrl: RedirectNotFoundResolver,
		},
		loadChildren: () => import('../pages/not-found/not-found.module').then(m => m.NotFoundModule),
	},
	{
		path: 'error/500',
		loadChildren: () => import('../pages/error/error.module').then(m => m.ErrorModule),
	},
	{
		path: 'error/403',
		loadChildren: () => import('../pages/forbidden/forbidden.module').then(m => m.ForbiddenModule),
	},
	{
		path: 'icons',
		loadChildren: () => import('../pages/icons/icons.module').then(m => m.IconsModule),
	},
	{
		path: 'ui-icons',
		loadChildren: () => import('../pages/ui-icons/ui-icons.module').then(m => m.UIIconsModule),
	},
	{
		path: 'content-icons',
		loadChildren: () => import('../pages/content-icons/content-icons.module').then(m => m.ContentIconsModule),
	},
	{
		path: 'controls',
		loadChildren: () => import('../pages/controls/controls.module').then(m => m.ControlsModule),
	},
	{
		path: 'newtask',
		loadChildren: () => import('../components/new-task/feature-new-task.module').then(m => m.FeatureNewTaskModule),
		canDeactivate: [UnsafeChangesGuard],
		runGuardsAndResolvers: 'always',
	},
	{
		path: 'meeting',
		loadChildren: () => import('../components/meeting/meeting-route.module').then(m => m.MeetingRoutingModule),
	},
	{
		path: 'mobile-dashboard',
		loadChildren: () =>
			import('../components/mobile-dashboard/mobile-dashboard-routing.module').then(
				m => m.MobileDashboardRoutingModule
			),
	},
	{
		path: 'contacts-search',
		loadChildren: () =>
			import('../pages/contacts-search/contacts-search-page-routing.module').then(
				m => m.ContactsSearchPageRoutingModule
			),
	},
	{
		path: 'mobile-signatures',
		loadChildren: () =>
			import('../components/mobile-signatures/mobile-signatures-routing.module').then(
				m => m.MobileSignaturesRoutingModule
			),
	},
	{
		path: 'mobile-profile',
		loadChildren: () =>
			import('../components/mobile-profile/mobile-profile-routing.module').then(m => m.MobileProfileRoutingModule),
	},
	{
		path: 'mobile-settings',
		loadChildren: () =>
			import('../components/mobile-settings/mobile-settings-routing.module').then(m => m.MobileSettingsRoutingModule),
	},
	{
		path: 'spaces',
		canDeactivate: [UnsafeChangesGuard],
		runGuardsAndResolvers: 'always',
		loadChildren: () => import('../pages/spaces/spaces-page-routing.module').then(m => m.SpacesRoutingModule),
	},
	{
		path: 'email-client',
		loadChildren: () => import('../pages/email/email-page-routing.module').then(m => m.EmailRoutingModule),
	},
	{
		path: 'disk',
		loadChildren: () => import('../pages/disk/disk-page-routing.module').then(m => m.DiskRoutingModule),
	},
	{
		path: 'project',
		loadChildren: () => import('../pages/project/project.module').then(m => m.ProjectPageModule),
	},
	{
		path: 'data-source-ep',
		loadChildren: () =>
			import('../pages/ext-param-lookup/ext-param-lookup-page.module').then(m => m.ExtParamLookupPageModule),
	},
	{
		path: 'admin-ds',
		loadChildren: () =>
			import('../pages/data-source/data-source-grid-forms/data-source-grid-forms-routing.module').then(
				m => m.DataSourceFormsRoutingModule
			),
	},
	{
		path: 'extparam',
		loadChildren: () => import('../pages/ext-param-page/ext-param-page.module').then(m => m.ExtParamPageModule),
	},
];

export const pagesRoutes: Routes = [...productionRoutes, ...developmentRoutes];
