import { Injectable, Type } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { UnsaveChangesService } from './unsave-changes.service';
import { LocalStorageProvider } from '@spa/core';

const notToCheckUnsaveRouteProp = Symbol('notToCheckUnsaveRouteProp');

@Injectable({ providedIn: 'root' })
export class UnsafeChangesGuard {
	constructor(readonly unsaveChanges: UnsaveChangesService, readonly localStorage: LocalStorageProvider) {}

	canDeactivate(
		component: any,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		// tslint:disable-next-line:no-console
		// console.log({ component });
		if (!!this.localStorage.get('canDeactivateNewTask')) {
			return true;
		}
		if (currentRoute[notToCheckUnsaveRouteProp]) {
			currentRoute[notToCheckUnsaveRouteProp] = false;
			return true;
		}

		if (component?.canDeactivateConfirm) {
			component.canDeactivateConfirm = undefined;
			return true;
		}

		return this.unsaveChanges.hasUnsavedChanges().pipe(
			mergeMap(hasUnsavedChanges => {
				// console.log({currentRoute, currentState, nextState});
				// ignore double check
				if (hasUnsavedChanges && nextState?.url !== '/') {
					this.markParentNotToCheck(currentRoute.parent);
					return this.unsaveChanges.confirmUnsave().pipe(
						tap(res => {
							if (component) {
								component.canDeactivateConfirm = res;
							}
						})
					);
				}
				return of(true);
			})
		);
	}

	markParentNotToCheck(route: ActivatedRouteSnapshot) {
		if (!route) {
			return;
		}
		if (route.routeConfig?.canDeactivate?.some(provider => provider === UnsafeChangesGuard)) {
			route[notToCheckUnsaveRouteProp] = true;
		}
		this.markParentNotToCheck(route.parent);
	}
}
