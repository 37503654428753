<iframe
	*ngIf="src || postData"
	[name]="frameName"
	[class.hide]="((loading$ | async) && hideOnLoading) || !src"
	#iframe
	[src]="postData ? blankSrc : src"
	(load)="onLoad()"
	frameborder="0"
	(error)="onLoad()"
	[attr.scrolling]="scrolling ? 'yes' : 'no'"
></iframe>
