import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, inject } from '@angular/core';
import { DataHttpService, IFileDialogOptions, IFileDialogResult } from '@spa/data/http';
import { BehaviorSubject, fromEvent, map, merge, Observable, take } from 'rxjs';

import { UrlProvider } from '../url';
import { Downloader, FileLink, IR7EditorMode } from './abstract';
import { PlatformDetectorProvider } from '../platform';

@Injectable()
export class DownloaderImpl implements Downloader {
	constructor(
		@Inject(DOCUMENT) readonly document: Document,
		readonly url: UrlProvider,
		readonly server: DataHttpService
	) {
		this.server.config.appSettingsAnonymousConfig$
			.pipe(
				take(1),
				map(info => {
					const version = info?.VersionInfo;
					const minor = version && Number(version.split('.')[1]);
					return minor;
				})
			)
			.subscribe(version => this.serverVersion$.next(version));
	}

	platform = inject(PlatformDetectorProvider);
	isSafari = this.platform.isOneOfPlatform(this.platform.types.safari);

	readonly serverVersion$ = new BehaviorSubject(null);

	getFileId(file: Record<any, any>) {
		return file?.id || file?.uploadId || file?.uploadID;
	}

	downloadAllFilesInComment(commentId: number): Observable<any> {
		const url = this.url.getApiUrl(`files/${commentId}/zip`);
		return this.download(url, `all_files_${commentId}`);
	}

	download(href: string, fileName?: string, avoidFileNameSanitizing = this.isSafari): Observable<any> {
		return new Observable(observer => {
			try {
				const link = this.document.createElement('a');
				link.style.display = 'none';
				link.href = href;
				if (fileName) {
					// + убираем точки и прочие знаки препинания
					link.download = fileName.replace(/[.,\/#!$%\^&\*;:{}=\-_`~]/g, '').replace(/\s{2,}/g, ' ');
				}
				if (avoidFileNameSanitizing) {
					link.download = fileName;
				}
				this.document.body.appendChild(link);
				link.click();
				this.document.body.removeChild(link);
				observer.next(0 as any);
			} catch (err) {
				observer.error(err);
			} finally {
				observer.complete();
			}
		});
	}

	downloadFiles(...ids: (FileLink | File)[]) {
		ids.forEach(id => {
			const url = this.createDownloadLink(id);
			const name = id instanceof File ? id.name : typeof id === 'object' ? id.name || id.fileName : '';
			this.download(url, name).pipe(take(1)).subscribe();
		});
	}

	downloadBytesArray(fileName, fileMime, fileBytes) {
		const link = document.createElement('a');
		const bytes = new Uint8Array(fileBytes);

		const blob = new Blob([bytes], { type: fileMime });
		link.href = window.URL.createObjectURL(blob);

		this.document.body.appendChild(link);
		link.download = fileName;
		link.click();
		this.document.body.removeChild(link);
	}

	createDownloadLink(fileOrId: FileLink | Blob, getTrueMime = false): string {
		if (typeof fileOrId === 'number' || typeof fileOrId === 'string') {
			if (!fileOrId) {
				return '';
			}

			return this.url.fileUrl(fileOrId);
		}

		if (fileOrId instanceof Blob) {
			return URL.createObjectURL(fileOrId);
		}

		const id = this.getFileId(fileOrId);
		const versionId = fileOrId.versionId || fileOrId.versionID;

		if (id) {
			return getTrueMime ? `${this.url.fileUrl(id, versionId)}getTrueMime=true` : this.url.fileUrl(id, versionId);
		}

		if (fileOrId.file instanceof Blob) {
			return URL.createObjectURL(fileOrId.file);
		}

		return '';
	}

	createPreviewPdfLink(fileOrId: FileLink): string {
		if (typeof fileOrId === 'number' || typeof fileOrId === 'string') {
			if (!fileOrId) {
				return '';
			}

			return this.url.getUrl(`PDFPreview.aspx?id=${fileOrId}`);
		}

		const id = this.getFileId(fileOrId);
		const versionId = fileOrId.versionId;

		if (!id) {
			return '';
		}

		return this.url.getUrl(`PDFPreview.aspx?id=${id}&VersionId=${versionId}&GetTrueMime=1`);
	}

	createPreviewOfficeR7EditorLink(
		fileOrId: FileLink,
		mode: IR7EditorMode = IR7EditorMode.view,
		needEncode = false,
		idKey = ''
	) {
		if (typeof fileOrId === 'number' || typeof fileOrId === 'string') {
			if (!fileOrId) {
				return '';
			}

			let id = fileOrId;
			id = needEncode ? encodeURIComponent(id) : id;

			return this.url.getUrl(`/r7/?fileId=${idKey}${id}&mode=${mode}`);
		}

		let id = this.getFileId(fileOrId);
		id = needEncode ? encodeURIComponent(id) : id;

		if (!id) {
			return '';
		}

		return this.url.getUrl(`/r7/?fileId=${id}&mode=${mode}`);
	}

	createPreviewOfficeWebAppLink(fileOrId: FileLink, qpDate = false): string {
		let webappsLink = `component/WAFrame.aspx`;

		if (this.serverVersion$.value >= 257) {
			webappsLink = `/waframe.html`;
		}

		if (typeof fileOrId === 'number' || typeof fileOrId === 'string') {
			if (!fileOrId) {
				return '';
			}

			return this.url.getUrl(`${webappsLink}?fileId=${fileOrId}`);
		}

		const id = this.getFileId(fileOrId);
		const versionId = fileOrId.versionId || fileOrId?.versionID || fileOrId?.fileVersion;

		if (!id) {
			return '';
		}

		let url = `${webappsLink}?fileId=${id}`;

		if (versionId) {
			url += `&versionId=${versionId}`;
		}

		if (qpDate) {
			url += `&v=${Date.now()}`;
		}

		return this.url.getUrl(url);
	}

	createPreviewWebDAVLink(fileOrId: FileLink, name: string): string {
		if (typeof fileOrId === 'number' || typeof fileOrId === 'string') {
			if (!fileOrId) {
				return '';
			}

			return this.url.getUrl(`/WebDav/${fileOrId}/No/${name}`);
		}

		const id = this.getFileId(fileOrId);
		const versionId = fileOrId.versionId;

		if (!id) {
			return '';
		}

		return this.url.getUrl(`/WebDav/${id}/No/${name}`);
	}

	createPreviewTextLink(fileOrId: FileLink): string {
		if (typeof fileOrId === 'number' || typeof fileOrId === 'string') {
			if (!fileOrId) {
				return '';
			}

			return this.url.getUrl(`TextFilePreview.aspx?id=${fileOrId}&GetTrueMime=1&addbr=1`);
		}

		const id = this.getFileId(fileOrId);
		const versionId = fileOrId.versionId;

		if (!id) {
			return '';
		}

		return this.url.getUrl(`TextFilePreview.aspx?id=${id}&VersionId=${versionId}&GetTrueMime=1&addbr=1`);
	}

	createPreviewImageLink(fileOrId: FileLink): string {
		if (typeof fileOrId === 'number' || typeof fileOrId === 'string') {
			if (!fileOrId) {
				return '';
			}

			return this.url.getUrl(`FilePreview.aspx?inRadWindow=1&id=${fileOrId}&GetTrueMime=1&addbr=1`);
		}

		const id = this.getFileId(fileOrId);
		const versionId = fileOrId.versionId;

		if (id) {
			return this.url.getUrl(`FilePreview.aspx?inRadWindow=1&id=${id}&VersionId=${versionId}&GetTrueMime=1&addbr=1`);
		}

		if (fileOrId.file instanceof Blob) {
			return URL.createObjectURL(fileOrId.file);
		}

		return '';
	}

	openFileDialog(options?: Partial<IFileDialogOptions>) {
		options = Object.assign(this.defaultFileDialogOptions(), options);
		return new Observable<IFileDialogResult>(observer => {
			const inputEl = document.createElement('input');
			inputEl.type = 'file';
			inputEl.style.display = 'none';
			inputEl.multiple = options.multiple;

			function onPickFiles(e: Event) {
				const files = Array.from((<HTMLInputElement>e.target).files);
				inputEl.value = null;
				observer.next({ files });
				observer.complete();
			}

			const change$ = fromEvent(inputEl, 'change');
			const cancel$ = fromEvent(inputEl, 'cancel');
			const pickFiles$ = merge(change$, cancel$).subscribe(onPickFiles);

			document.body.appendChild(inputEl);
			inputEl.click();

			function dispose() {
				pickFiles$.unsubscribe();
				document.body.removeChild(inputEl);
			}

			return () => {
				dispose();
			};
		});
	}

	protected defaultFileDialogOptions(): Partial<IFileDialogOptions> {
		return {
			multiple: true,
		};
	}
}
