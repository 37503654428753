// tslint:disable:naming-convention
import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HotKeysService {
	readonly keydown$ = fromEvent<KeyboardEvent>(window, 'keydown', { capture: true });

	readonly ctrl_only$ = this.keydown$.pipe(filter(e => e.ctrlKey));
	readonly ctrl_or_cmd$ = this.keydown$.pipe(filter(e => e.ctrlKey || e.metaKey));
	readonly alt$ = this.keydown$.pipe(filter(e => e.altKey));
	readonly alt_shift$ = this.alt$.pipe(filter(e => e.shiftKey));
	readonly ctrl_shift$ = this.ctrl_or_cmd$.pipe(filter(e => e.shiftKey));

	readonly ctrl_s$ = this.keydown$.pipe(filter(e => e.code === 'KeyS' && (e.ctrlKey || e.metaKey)));

	readonly ctrl_k$ = this.keydown$.pipe(filter(e => e.code === 'KeyK' && (e.ctrlKey || e.metaKey) && !e.shiftKey));

	readonly ctrl_shift_7$ = this.keydown$.pipe(filter(e => e.code === 'Digit7' && (e.ctrlKey || e.metaKey)));

	readonly ctrl_enter$ = this.keydown$.pipe(filter(e => e.key === 'Enter' && (e.ctrlKey || e.metaKey)));

	readonly enter$ = this.keydown$.pipe(filter(e => e.key === 'Enter'));

	readonly escape$ = this.keydown$.pipe(filter(e => e.key === 'Escape'));

	readonly ctrl_z_OR_escape$ = this.keydown$.pipe(
		filter(e => e.key === 'Escape' || (e.code === 'KeyZ' && (e.ctrlKey || e.metaKey)))
	);

	readonly ctrl_z$ = this.keydown$.pipe(filter(e => e.code === 'KeyZ' && (e.ctrlKey || e.metaKey)));

	readonly ctrl_e$ = this.keydown$.pipe(filter(e => e.code === 'KeyE' && (e.ctrlKey || e.metaKey)));

	readonly ctrl_u$ = this.keydown$.pipe(filter(e => e.code === 'KeyU' && (e.ctrlKey || e.metaKey)));

	readonly arrowLeft$ = this.keydown$.pipe(filter(e => e.code === 'ArrowLeft'));

	readonly arrowRight$ = this.keydown$.pipe(filter(e => e.code === 'ArrowRight'));

	readonly arrowUp$ = this.keydown$.pipe(filter(e => e.code === 'ArrowUp'));

	readonly arrowDown$ = this.keydown$.pipe(filter(e => e.code === 'ArrowDown'));

	readonly tab$ = this.keydown$.pipe(filter(e => e.code === 'Tab' && !e.shiftKey));

	readonly shift_tab$ = this.keydown$.pipe(filter(e => e.code === 'Tab' && e.shiftKey));

	readonly ctrl_i$ = this.ctrl_or_cmd$.pipe(filter(e => e.code === 'KeyI'));

	readonly ctrl_shift_j$ = this.ctrl_only$.pipe(filter(e => e.shiftKey && e.code === 'KeyJ'));

	readonly ctrl_shift_k$ = this.ctrl_only$.pipe(filter(e => e.shiftKey && e.code === 'KeyK'));

	readonly alt_shift_j$ = this.alt$.pipe(filter(e => e.shiftKey && e.code === 'KeyJ'));

	readonly alt_shift_k$ = this.alt$.pipe(filter(e => e.shiftKey && e.code === 'KeyK'));
}
