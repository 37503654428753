<ng-container *ngIf="hasToolbar$ | async; else noToolbar">
	<mat-toolbar>
		<span class="vh-widget-toolbar-title">{{ toolbarTitle$ | async }}</span>

		<span class="mla"></span>
		<button class="vh-btn vh-btn-icon vh-btn--transparent" (click)="close()">
			<mat-icon>clear</mat-icon>
		</button>
	</mat-toolbar>
</ng-container>

<ng-template #noToolbar>
	<button
		class="vh-btn vh-btn-icon vh-btn--transparent vh-modal-content-area__close-btn"
		[tabIndex]="'-1'"
		(click)="close()"
	>
		<mat-icon>clear</mat-icon>
	</button>
</ng-template>

<ng-template [cdkPortalOutlet]="currentPortal" (attached)="attached($event)"></ng-template>

<div style="display: none">
	<div #hiddenContainer></div>
</div>
