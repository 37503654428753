import { Observable } from 'rxjs';
import { INotificationOptions } from './notification-options';

export abstract class DesktopNotifications {
	abstract send<T = any>(options: INotificationOptions<T>): Observable<IDesktopNotificationSendResult<T>>;
}

export interface IDesktopNotificationSendResult<T = any> {
	notification: Notification;
	close: () => void;
	closed: boolean;
	data: T;
}
