import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, mapTo, shareReplay, tap } from 'rxjs/operators';

import { AbstractLogger, LoggerFactory } from '../diagnostics';
import { FormatDateService } from './abstract/formatDate.service';
import { LazyKendoLocale } from './lazy-kendo-locale-ru';
import type { LazyKendoLocaleType } from './lazy-kendo-locale-ru';

@Injectable()
export class AngularKendoFormatDateServiceImpl implements FormatDateService {
	constructor(logger: LoggerFactory, @Inject(LazyKendoLocale) readonly lazyKendoLocale: LazyKendoLocaleType) {
		this.logger = logger.createLogger('AngularKendoFormatDateServiceImpl');
	}

	ready$: Observable<boolean> = from(this.lazyKendoLocale().then(m => m.formatDate)).pipe(
		tap(fn => {
			this.formatFn = fn;
		}),
		mapTo(true),
		shareReplay({ bufferSize: 1, refCount: false })
	);

	readonly logger: AbstractLogger;
	protected formatFn: Function;

	prepareFormat(format: string): string {
		let preparedFormat = format;
		preparedFormat = preparedFormat.replace(/d{4}/gm, 'EEEE');
		return preparedFormat;
	}

	formatDate$(date: string, format: string): Observable<string> {
		const formatDateFn$ = from(this.lazyKendoLocale().then(m => m.formatDate));

		const preparedFormat = this.prepareFormat(format);

		return formatDateFn$.pipe(map(fn => fn(new Date(date), preparedFormat, 'ru-RU')));
	}

	formatDate(date: string, format: string): string {
		if (!this.formatFn) {
			return date;
		}
		const preparedFormat = this.prepareFormat(format);
		return this.formatFn(new Date(date), preparedFormat, 'ru-RU');
	}
}
