export enum IPointIndicatorColorMap {
	basePrimary = 'var(--base-primary)',
	baseInfo = 'var(--base-info)',
	baseDanger = 'var(--base-danger)',
	baseWarning = 'var(--base-warning)',
	baseSuccess = 'var(--base-success)',
	baseDefault = 'var(--base-default)',
}

export enum IPointIndicatorColor {
	basePrimary = 'basePrimary',
	baseInfo = 'baseInfo',
	baseDanger = 'baseDanger',
	baseWarning = 'baseWarning',
	baseSuccess = 'baseSuccess',
	baseDefault = 'baseDefault',
}

export enum PointIndicatorSizeMap {
	xs = 'var(--space-075)',
	sm = 'var(--space-100)',
	md = 'var(--space-150)',
	lg = 'var(--space-200)',
	xl = 'var(--space-300)',
}

export enum PointIndicatorSize {
	xs = 'xs',
	sm = 'sm',
	md = 'md',
	lg = 'lg',
	xl = 'xl',
}
