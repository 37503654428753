import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
	AdminDataHttpServiceBase,
	GetRepresentationDto,
	IModel,
	ISchemaWithModel,
	IScheme,
	IListOf,
	IProfileSettings,
	IUpdateUserSettingsReq,
	IUserSettingList,
	IUserNotificationSettings,
	IUpdateNotificationSettingReq,
	IEntityModelActionResult,
	IMigrationUtilityImportResponse,
	IAdminTreeItem,
	IMigrationTreeRootResponse,
	IMigrationFetchResponse,
	IExportMigrationConfigItem,
	IMigrationTreeItem,
	ISmartExpressionResponse,
	ISmartExpressionExecuteResponse,
	ISmartExpressionExecutePayload,
	ISmartExpressionSaveResponse,
	ISmartLuaScriptResponse,
	ISmartLuaScriptExecutePayload,
	ISmartLuaScriptExecuteResponse,
	ISmartLuaScriptActionParameters,
	ISmartLuaScriptActionParametersResponse,
	ISmartLuaScript,
	ISmartLuaScriptGridCriteria,
	ISmartExpressionGridResponse,
	ISmartExpressionTestCaseRequest,
	ISmartExpressionTestCasesResponse,
	ISmartExpressionTestCase,
	ISaveSmartExpressionTestCaseRequest,
	ISmartPackSchemaRequest,
	ISmartPackSchema,
	ISmartPackExpressionRequest,
	ISmartExpressionList,
	ISaveSmartPackRequest,
	IRedrawSmartPackSchemeRequest,
	IDeleteSmartPackActionRequest,
	IReorderSmartPackActionRequest,
	ISmartPackAction,
	IGetGroupedSmartPackActionRequest,
	ISmartActionsList,
	INewSmartPack,
	ISmartActionsListResponse,
	IExpandSmartExpressionTreeNode,
	ISmartExpressionTree,
	IPermissionsListByEntityResponse,
	IPermissionsByEntityResponse,
	ISetPermissionsByEntityBody,
} from './abstract';
import { DataHttpCommonService } from '../data-http-common.service';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { IApiResponse } from '../api-response';
import { isNullOrUndefined } from '@valhalla/utils';
import { AdministrationImportConstraintsAction } from '@spa/pages/admin/migration/administration-migration-enum';
import { MigrationImportPreviewItem } from '@spa/pages/admin/migration/dto/migration-item.dto';

@Injectable()
export class AdminDataHttpServiceImpl implements AdminDataHttpServiceBase {
	constructor(readonly http: HttpClient, readonly common: DataHttpCommonService) {}

	readonly notificationSettingsCache$ = new BehaviorSubject<IUserNotificationSettings>(null);
	readonly notificationSettings$ = this.notificationSettingsCache$.asObservable();

	action(schemeName: string, action: string, model: IModel): Observable<IEntityModelActionResult> {
		const url = this.common.getApiUrl(`/admin/entityModel/${schemeName}/action/${action}`);
		return this.http.post<IEntityModelActionResult>(url, model);
	}

	getEntityScheme(schemeName: string): Observable<IScheme> {
		const url = this.common.getApiUrl(`/admin/entityModel/${schemeName}/scheme`);
		return this.http.get<IScheme>(url);
	}

	getEntitySchemeAndModel(schemeName: string, key: number): Observable<ISchemaWithModel> {
		if (key) {
			const url = this.common.getApiUrl(`/admin/entityModel/${schemeName}/scheme/${key}`);
			return this.http.get<ISchemaWithModel>(url);
		} else {
			return this.getEntityScheme(schemeName).pipe(
				map(scheme => {
					return {
						scheme,
						model: {},
					} as ISchemaWithModel;
				})
			);
		}
	}

	getEntityModel(schemeName: string, key: number): Observable<any> {
		const url = this.common.getApiUrl(`/admin/entityModel/${schemeName}/${key}`);
		return this.http.get<any>(url);
	}

	createEntityModel(schemeName: string, model: IModel): Observable<IModel> {
		const url = this.common.getApiUrl(`/admin/entityModel/${schemeName}`);
		return this.http.post<IModel>(url, model);
	}

	updateEntityModel(schemeName: string, model: IModel): Observable<IModel> {
		const url = this.common.getApiUrl(`/admin/entityModel/${schemeName}`);
		return this.http.put<IModel>(url, model);
	}

	mergeEntityModel(schemeName: string, model: IModel): Observable<IModel> {
		if (isNullOrUndefined(model.entityModelKey)) {
			return this.createEntityModel(schemeName, model);
		} else {
			return this.updateEntityModel(schemeName, model);
		}
	}

	deleteEntityModel(schemeName: string, key: number): Observable<boolean> {
		const url = this.common.getApiUrl(`/admin/entityModel/${schemeName}/${key}`);
		return this.http.delete<boolean>(url);
	}

	getRepresentation(
		schemeName: string,
		representation: string,
		skip?: number,
		take?: number
	): Observable<GetRepresentationDto> {
		let url = this.common.getApiUrl(`/admin/entityModel/${schemeName}/representation/${representation}`);
		let question = false;
		if (skip) {
			url += `?skip=${skip}`;
			question = true;
		}

		if (take) {
			url += `${question ? '&' : '?'}take=${take}`;
			question = true;
		}

		return this.http.get<GetRepresentationDto>(url);
	}

	getUserSettings(userId: number) {
		const url = this.common.getApiUrl(`admin/user/${userId}`);
		return this.http.get<IApiResponse<IProfileSettings>>(url).pipe(map(res => res?.data));
	}

	updateUserSettings(userId: number, request: IUpdateUserSettingsReq) {
		const url = this.common.getApiUrl(`/admin/user/${userId}`);
		return this.http.post<any>(url, request);
	}

	getUserSettingList(listOf: IListOf) {
		const url = this.common.getApiUrl(`admin/lists/${listOf}`);
		return this.http.get<IApiResponse<IUserSettingList[]>>(url).pipe(map(res => res?.data));
	}

	getNotificationSetting(userId: number) {
		const url = this.common.getApiUrl(`admin/user/notifications/${userId}`);
		return this.http.get<IApiResponse<IUserNotificationSettings>>(url).pipe(
			map(res => {
				this.notificationSettingsCache$.next(res?.data);
				return res?.data;
			})
		);
	}

	updateNotificationSetting(userId: number, request: IUpdateNotificationSettingReq) {
		const url = this.common.getApiUrl(`admin/user/notifications/${userId}`);
		this.notificationSettingsCache$.next({
			...(this.notificationSettingsCache$.value || ({} as any)),
			...request,
		});
		return this.http.post<any>(url, request).pipe(
			tap(() => {
				window.dispatchEvent(new CustomEvent('need-update-session-user-settings'));
			})
		);
	}

	getAdminTree() {
		const url = this.common.getApiUrl(`admin/forms/tree`);
		return this.http.get<any>(url).pipe(map(res => (res?.data ? res.data : res)));
	}

	uploadMigrationImportConfig(
		fileToUpload: File,
		withDenormalization: boolean,
		includeAllResolutions: boolean,
		importConstraintsAction: AdministrationImportConstraintsAction
	): Observable<IMigrationUtilityImportResponse[]> {
		const params = `?withDenormalization=${withDenormalization}&importConstraintsAction=${importConstraintsAction}&includeAllResolutions=${includeAllResolutions}`;
		const relativeUrl = `migration/import/config${params}`;
		const formData: FormData = new FormData();
		formData.append('file', fileToUpload, fileToUpload.name);
		return this.http
			.post<any>(this.common.getApiUrl(relativeUrl), formData)
			.pipe(map(res => (res?.data ? res.data : null)));
	}

	previewMigrationImport(
		fileToUpload: File,
		withDenormalization: boolean,
		importConstraintsAction: AdministrationImportConstraintsAction
	) {
		const params = `?withDenormalization=${withDenormalization}&importConstraintsAction=${importConstraintsAction}`;
		const formData: FormData = new FormData();
		formData.append('file', fileToUpload, fileToUpload.name);
		return this.http.post<IApiResponse<MigrationImportPreviewItem[]>>(
			this.common.getApiUrl(`migration/import/preview${params}`),
			formData
		);
	}

	getApplicationSettingsConfig(): Observable<string> {
		const url = this.common.getApiUrl(`system/app-settings`);
		return this.http.get<string>(url).pipe(map(res => res));
	}

	getApplicationConfigViaJson(): Observable<string> {
		const url = this.common.getApiUrl(`system/appsettings-configuration`);
		return this.http.get<string>(url, { responseType: 'text' as any }).pipe(map(res => res));
	}

	getMigrationTreeRoot(): Observable<IMigrationTreeItem[]> {
		const url = this.common.getApiUrl(`migration/tree-root`);
		return this.http.get<IMigrationTreeRootResponse>(url).pipe(map(res => res.data));
	}

	getMigrationFetch(id: number, entityType: string): Observable<IMigrationTreeItem[]> {
		const url = this.common.getApiUrl(`migration/fetch`);
		return this.http.get<IMigrationFetchResponse>(url, { params: { id, entityType } }).pipe(map(res => res.data));
	}

	exportMigrationConfig(
		saveLog: boolean,
		includeSubcat: boolean,
		exportItems: IExportMigrationConfigItem[]
	): Observable<any> {
		const url = this.common.getApiUrl(`migration/export/config`);
		return this.http.post<any>(url, exportItems, { params: { saveLog, includeSubcat } });
	}

	getNLogConfig(): Observable<string> {
		const url = this.common.getApiUrl(`system/nlog-configuration`);
		return this.http.get<string>(url, { responseType: 'text' as any }).pipe(map(res => res));
	}

	getSmartExpression(
		smartExpressionId: number,
		additionalParams: Record<string, string>
	): Observable<ISmartExpressionResponse> {
		let params = new HttpParams();
		Object.keys(additionalParams).forEach(key => {
			params = params.set(key, additionalParams[key]);
		});
		const url = this.common.getApiUrl(`admin/smart/expressions/${smartExpressionId}/editor`);
		return this.http.get<{ data: ISmartExpressionResponse }>(url, { params }).pipe(map(res => res.data));
	}

	saveSmartExpression(smartExpression: ISmartExpressionResponse): Observable<ISmartExpressionSaveResponse> {
		const url = this.common.getApiUrl('admin/smart/expressions/editor');
		return this.http.post<{ data: ISmartExpressionSaveResponse }>(url, smartExpression).pipe(map(res => res.data));
	}

	executeSmartExpression(smartExpression: ISmartExpressionExecutePayload): Observable<ISmartExpressionExecuteResponse> {
		const url = this.common.getApiUrl('admin/smart/expressions/editor/execute');
		return this.http.post<{ data: ISmartExpressionExecuteResponse }>(url, smartExpression).pipe(map(res => res.data));
	}

	getLuaSmartExpression(
		smartExpressionId: number,
		additionalParams: Record<string, string>
	): Observable<ISmartLuaScriptResponse> {
		let params = new HttpParams();
		Object.keys(additionalParams).forEach(key => {
			params = params.set(key, additionalParams[key]);
		});
		const url = this.common.getApiUrl(`admin/smart/scripts/${smartExpressionId}/editor`);
		return this.http.get<{ data: ISmartLuaScriptResponse }>(url, { params }).pipe(map(res => res.data));
	}

	saveLuaSmartExpression(smartExpression: ISmartLuaScriptResponse): Observable<ISmartLuaScript> {
		const url = this.common.getApiUrl('admin/smart/scripts/editor');
		return this.http.post<{ data: ISmartLuaScript }>(url, smartExpression).pipe(map(res => res.data));
	}

	executeLuaSmartExpression(
		smartExpression: ISmartLuaScriptExecutePayload
	): Observable<ISmartLuaScriptExecuteResponse> {
		const url = this.common.getApiUrl('admin/smart/scripts/editor/execute');
		return this.http.post<{ data: ISmartLuaScriptExecuteResponse }>(url, smartExpression).pipe(map(res => res.data));
	}

	getLuaActionParams(): Observable<ISmartLuaScriptActionParameters[]> {
		const url = this.common.getApiUrl('admin/smart/scripts/editor/actionparams');
		return this.http
			.get<{ data: ISmartLuaScriptActionParametersResponse }>(url)
			.pipe(map(res => res.data?.actionsParameters));
	}

	deleteLuaSmartExpression(id: number, confirm = false): Observable<any> {
		const url = this.common.getApiUrl(`admin/smart/scripts/${id}${confirm ? '?confirm=true' : ''}`);
		return this.http.delete(url);
	}

	getLuaSmartExpressionGrid(req: ISmartLuaScriptGridCriteria): Observable<IApiResponse<ISmartExpressionGridResponse>> {
		const url = this.common.getApiUrl('admin/smart/scripts');
		return this.http.post<IApiResponse<ISmartExpressionGridResponse>>(url, req);
	}

	getSmartExpressionsTestCases(req: ISmartExpressionTestCaseRequest): Observable<ISmartExpressionTestCase[]> {
		const url = this.common.getApiUrl('admin/smart/expressions/testcases/query');
		return this.http
			.post<IApiResponse<ISmartExpressionTestCasesResponse>>(url, req)
			.pipe(map(response => response.data?.testCases));
	}

	deleteSmartExpressionsTestCase(testCaseId: number): Observable<void> {
		const url = this.common.getApiUrl(`admin/smart/expressions/testcases/${testCaseId}`);
		return this.http.delete<void>(url);
	}

	saveSmartExpressionsTestCase(req: ISaveSmartExpressionTestCaseRequest): Observable<ISmartExpressionTestCase> {
		const url = this.common.getApiUrl(`admin/smart/expressions/testcases/${req.id}`);
		return this.http.post<IApiResponse<ISmartExpressionTestCase>>(url, req).pipe(map(response => response.data));
	}

	createSmartExpressionsTestCase(req: ISaveSmartExpressionTestCaseRequest): Observable<ISmartExpressionTestCase> {
		const url = this.common.getApiUrl('admin/smart/expressions/testcases');
		return this.http.post<IApiResponse<ISmartExpressionTestCase>>(url, req).pipe(map(response => response.data));
	}

	getSmartPackSchema(req: ISmartPackSchemaRequest): Observable<ISmartPackSchema> {
		const url = this.common.getApiUrl(`admin/smart/packs/${req.packId}/schema`);
		let params = new HttpParams();
		Object.keys(req).forEach(key => {
			if (key !== 'packId') {
				params = params.set(key, req[key]);
			}
		});
		return this.http.get<IApiResponse<ISmartPackSchema>>(url, { params }).pipe(map(response => response.data));
	}

	getSmartExpressionsList(req: ISmartPackExpressionRequest): Observable<ISmartExpressionList[]> {
		const url = this.common.getApiUrl('admin/smartexpressions/list');
		return this.http.post<IApiResponse<ISmartExpressionList[]>>(url, req).pipe(map(response => response.data));
	}

	saveSmartPack(req: ISaveSmartPackRequest): Observable<INewSmartPack> {
		const url = this.common.getApiUrl(`admin/smart/packs/${req.pack.packId ? req.pack.packId : ''}`);
		return this.http.post<IApiResponse<INewSmartPack>>(url, req).pipe(map(response => response.data));
	}

	// todo check return value
	saveSmartPackAction(
		req: IRedrawSmartPackSchemeRequest,
		packId: number,
		actionOrder: number
	): Observable<ISmartPackSchema> {
		const url = this.common.getApiUrl(`admin/smart/packs/${packId}/actions/${actionOrder}`);
		return this.http.post<IApiResponse<ISmartPackSchema>>(url, req).pipe(map(response => response.data));
	}

	redrawSmartPackSchema(req: IRedrawSmartPackSchemeRequest): Observable<ISmartPackAction> {
		const url = this.common.getApiUrl('admin/smart/packs/action/schema');
		return this.http.post<IApiResponse<ISmartPackAction>>(url, req).pipe(map(response => response.data));
	}

	deleteSmartPackAction(req: IDeleteSmartPackActionRequest, packId: number, actionOrder: number): Observable<void> {
		const url = this.common.getApiUrl(`admin/smart/packs/${packId}/actions/${actionOrder}`);
		return this.http.delete<void>(url, { body: req });
	}

	reorderSmartPackAction(
		req: IReorderSmartPackActionRequest,
		packId: number,
		actionOrder: number,
		offset: number
	): Observable<void> {
		const url = this.common.getApiUrl(`admin/smart/packs/${packId}/actions/${actionOrder}/reorder/${offset}`);
		return this.http.post<void>(url, req);
	}

	getGroupedSmartPackActions(req: Partial<IGetGroupedSmartPackActionRequest>): Observable<ISmartActionsList[]> {
		const url = this.common.getApiUrl('admin/smart/packs/schema/actions');
		let params = new HttpParams();
		Object.keys(req).forEach(key => {
			params = params.set(key, req[key]);
		});
		return this.http
			.get<IApiResponse<ISmartActionsListResponse>>(url, { params })
			.pipe(map(response => response.data?.groups));
	}

	expandSmartExpressionTreeNode(req: IExpandSmartExpressionTreeNode): Observable<ISmartExpressionTree> {
		const url = this.common.getApiUrl('admin/smart/expressions/editor/tree/expand');
		return this.http.post<IApiResponse<ISmartExpressionTree>>(url, req).pipe(map(response => response.data));
	}

	permissionsListByEntity(
		entityType: 'Subcategory' | 'Category' | 'Group'
	): Observable<IPermissionsListByEntityResponse> {
		const url = this.common.getApiUrl(`admin/permissions/${entityType}/actions`);
		return this.http.get<IPermissionsListByEntityResponse>(url);
	}
	permissionsListByEntityId(
		entityType: 'Subcategory' | 'Category' | 'Group',
		entityId: number | string
	): Observable<IPermissionsListByEntityResponse> {
		const url = this.common.getApiUrl(`admin/permissions/${entityType}/${entityId}/actions`);
		return this.http.get<IPermissionsListByEntityResponse>(url);
	}
	permissionsByEntityId(
		entityType: 'Subcategory' | 'Category' | 'Group',
		entityId: number | string
	): Observable<IPermissionsByEntityResponse> {
		const url = this.common.getApiUrl(`admin/permissions/${entityType}/${entityId}`);
		return this.http.get<IPermissionsByEntityResponse>(url);
	}
	setPermissionsByEntityId(
		entityType: 'Subcategory' | 'Category' | 'Group',
		entityId: number | string,
		body: ISetPermissionsByEntityBody
	): Observable<void> {
		const url = this.common.getApiUrl(`admin/permissions/${entityType}/${entityId}/set`);
		return this.http.post<void>(url, body);
	}
}
