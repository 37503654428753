import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { badgeRoundValue } from '@valhalla/utils';

import { BadgeColor, isBadgeColor } from './badge-model';

@Component({
	selector: 'vh-common-badge',
	templateUrl: './badge.component.html',
	styleUrls: ['./badge.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent implements OnInit {
	constructor(readonly cdr: ChangeDetectorRef) {}

	@HostBinding('class.vh-common-badge')
	hostClassSelector = true;

	@Input()
	set value(val: string | number) {
		this.#_value = val;
		this.#_valueView = this.useRound ? badgeRoundValue(val) : String(val);
	}
	get value() {
		return this.#_value;
	}

	@Input()
	useRound = false;

	@Input()
	//@HostBinding('class.vh-common-badge--small')
	small = false;

	@Input()
	@HostBinding('class.vh-common-badge--pale')
	pale = false;

	@Input()
	color: BadgeColor = 'primary';

	@HostBinding('style.width.px')
	width: number;

	@HostBinding('class.vh-common-badge--primary')
	get isColorPrimary() {
		return isBadgeColor.primary(this.color);
	}

	@HostBinding('class.vh-common-badge--warn')
	get isColorWarn() {
		return isBadgeColor.warn(this.color);
	}

	@HostBinding('class.vh-common-badge--error')
	get isColorError() {
		return isBadgeColor.error(this.color);
	}

	@HostBinding('class.vh-common-badge--success')
	get isColorSuccess() {
		return isBadgeColor.success(this.color);
	}

	@HostBinding('class.vh-common-badge--accent')
	get isColorAccent() {
		return isBadgeColor.accent(this.color);
	}

	@HostBinding('class.vh-common-badge--neutral')
	get isColorNeutral() {
		return isBadgeColor.neutral(this.color);
	}

	@HostBinding('class.vh-common-badge--favourites')
	get isColorFavorite() {
		return isBadgeColor.fav(this.color);
	}

	@HostBinding('style.background-color')
	get cssColor() {
		return isBadgeColor.valid(this.color) ? null : this.color;
	}

	#_value: string | number;
	#_valueView: string;

	get valueView() {
		return this.#_valueView;
	}

	ngOnInit() {
		this.#_valueView = this.useRound ? badgeRoundValue(this.value) : String(this.value);
	}
}
