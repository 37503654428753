export const urlRegExp = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.%]+/;

export function getAllUrlsFromText(text: string) {
	const match = text.match(new RegExp(urlRegExp, 'gm'));
	if (!match) {
		return [];
	}
	const urls = match.map(url => url.trim());
	return urls;
}

export function excludeAllUrlsFromText(text: string) {
	const result = text.replace(new RegExp(urlRegExp, 'gm'), '');
	return result;
}
