import { Type } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractMessage } from './types';

export abstract class EventBusService {
	/**
	 * Stream of messages
	 */
	abstract get messages$(): Observable<AbstractMessage>;
	/**
	 * Send message to bus
	 */
	abstract send(message: AbstractMessage): void;
	/**
	 * Create and send message to bus with no params on constructor
	 */
	abstract createAndSend<T extends AbstractMessage>(cls: Type<T>, data?: any, sender?: any): void;
	/**
	 *
	 * @param cls Subscribe to message of type
	 */
	abstract ofType<T extends AbstractMessage>(cls: Type<T>): Observable<T>;
}
