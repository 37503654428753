import { Observable, of, take } from 'rxjs';
import { ExtParamBase } from './ext-param-base';

export class ExtParamTree extends ExtParamBase {
	convertForUpdateExtParamInTask() {
		return `#n${this.id}#v${this.value?.id || ''}`;
	}

	convertForUpdateExtParamInNewTask(): string {
		return this.value?.id || '';
	}

	convertForSaveInNewTaskAsync(): Observable<any> {
		if (typeof this.convertForSaveInNewTaskAsyncMiddleware === 'function') {
			return this.convertForSaveInNewTaskAsyncMiddleware(this).pipe(take(1));
		}
		return of(this.value?.id || '');
	}

	isEmpty() {
		return !this.value || !this.value?.id;
	}

	equalsValue(a: any, b: any) {
		if (!a && !b) {
			return true;
		}

		const aText = this.getValueText(a);
		const bText = this.getValueText(b);

		return aText === bText;
	}

	getValueText(val) {
		if (typeof val === 'string') {
			return val;
		}

		if (typeof val === 'object') {
			return val?.text;
		}

		return null;
	}
}
