import { ComponentPortal, Portal } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from '@spa/common/services/sidebar.service';
import { SplashScreenService } from '@spa/common/services/splash-screen.service';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { FacadeSearchProvider } from '@spa/facade/features';
import { LayoutFacade } from '@spa/facade/layout/layout-facade.service';
import { AuthService, PlatformDetectorProvider, UrlProvider, ViewDestroyStreamService, WINDOW } from '@valhalla/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuickLinksUserDockComponent } from '../quick-links-user-dock/quick-links-user-dock.component';
import { MobileDynamicPanelComponent } from './mobile-dynamic-panel';
import { SidebarKeys } from '@spa/common/components/sidebar/abstract';

@Component({
	selector: 'vh-layout-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class ToolbarComponent implements OnInit {
	constructor(
		private _fuseConfigService: LayoutFacade,
		private _fuseSidebarService: SidebarService,
		private readonly _router: Router,
		private readonly _destroy$: ViewDestroyStreamService,
		public readonly auth: AuthService,
		public readonly urlBuilder: UrlProvider,
		@Inject(WINDOW) readonly window: Window,
		readonly splashScreen: SplashScreenService,
		readonly searchProvider: FacadeSearchProvider,
		readonly platformDetector: PlatformDetectorProvider,
		readonly mobileView: MobileViewService,
		private _location: Location
	) {}

	horizontalNavbar: boolean;
	rightNavbar: boolean;
	hiddenNavbar: boolean;
	languages: any;
	navigation: any;

	private _toolbarUserDockPortal$: BehaviorSubject<Portal<any>> = new BehaviorSubject(null);
	private _quickUserLinksPortal = new ComponentPortal(QuickLinksUserDockComponent);
	private _mobileDynamicPanelPortal = new ComponentPortal(MobileDynamicPanelComponent);

	isMobile = false;

	ngOnInit(): void {
		// Subscribe to the config changes
		this._fuseConfigService.state$.pipe(takeUntil(this._destroy$)).subscribe(settings => {
			this.horizontalNavbar = settings.layout.navbar.position === 'top';
			this.rightNavbar = settings.layout.navbar.position === 'right';
			this.hiddenNavbar = settings.layout.navbar.hidden === true;
		});

		this.isMobile = this.platformDetector.isOneOfPlatform(
			this.platformDetector.types.android,
			this.platformDetector.types.ios
		);

		if (this.isMobile) {
			this._toolbarUserDockPortal$.next(this._mobileDynamicPanelPortal);
		} else {
			this._toolbarUserDockPortal$.next(this._quickUserLinksPortal);
		}
	}

	toggleSidebarOpen(): void {
		const key = this.mobileView.mobileMode ? SidebarKeys.mobileMainMenu : SidebarKeys.navbar;
		const bar = this._fuseSidebarService.getSidebar(key);
		bar.toggleOpen();
		if (this.isMobile) {
			bar.unfold();
		}
	}

	search(query): void {
		this.searchProvider.openPageDataSearchResult(query);
	}

	goToPage(page) {
		this._router.navigate([page]);
	}

	openWindow(url, otherDomain = false) {
		this.window.open(otherDomain ? url : this.urlBuilder.getUrl(url));
	}

	refreshApp() {
		this.splashScreen.show();
		this.window.location.reload();
	}

	prevPage() {
		this.mobileView.hideTabbar = false;
		this._location.back();
		this.mobileView.backClick();
	}
}
