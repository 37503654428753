/// <reference path="../../declarations/type.d.ts" />
import { applyMiddleware, compose, createStore, Middleware, Reducer, Store, Unsubscribe } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { BehaviorSubject, Observable, pipe, Subject } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, takeUntil } from 'rxjs/operators';

import { IAction } from './actions';
import { StorePlugin } from './plugins';
import { ReducerBase } from './reducer-base';
import { combineEffects, Effect, StoreSelector } from './utils';
import { inject, NgZone } from '@angular/core';

export interface IRxStoreLogData {
	method: string;
	data: any | any[];
	useEventLog?: boolean;
}

export interface IRxStore<S = any> {
	config: IRxStoreConfig<S>;
	log$: Observable<IRxStoreLogData>;
	storeName: string;
	destroy();
	select<R = any>(selector?: StoreSelector<S, R>): Observable<R>;
	dispatch(action: IAction);
	getState<R>(selector?: StoreSelector<Partial<S>, R>): R;
	addEffects(...epics: Effect<IAction, IAction, S, any>[]): IRxStore<S>;
	addReducers(...reducers: ClassType<ReducerBase>[]): IRxStore<S>;
}

export interface IPersistentConfig {
	migration(state: any): any;
}

export interface IRxStoreConfig<State = any> {
	name: string;
	reducer?: Reducer<State>;
	defaultState?: Partial<State>;
	effects?: Array<Effect<IAction, IAction, State, any>>;
	middlewares?: Middleware[];
	dependencies?: any;
	plugins?: StorePlugin[];
	persistent?: IPersistentConfig | boolean;
	devTools?: Partial<{
		reduxDevTools: boolean;
		useEventLog: boolean;
		useConsoleLogger: boolean;
	}>;
}

export class RxStore<S = any> implements IRxStore<S> {
	constructor(config: IRxStoreConfig<S>) {
		this._validateConfig(config);
		this._init(config);
	}

	private _destroyed = false;
	private _unsubscribeStore: Unsubscribe;
	private _logger$ = new Subject<IRxStoreLogData>();
	private _config: IRxStoreConfig<S>;
	protected store: Store;
	protected rootReducer: Reducer<S, IAction>;
	protected defaultState;
	protected state$: BehaviorSubject<Partial<S>>;
	protected effect$: BehaviorSubject<Effect>;
	protected destroy$ = new Subject();
	protected reducers = new Map<ClassType<ReducerBase>, ReducerBase>();
	protected zone = inject(NgZone, { optional: true });

	readonly log$ = this._logger$.pipe(takeUntil(this.destroy$));

	get storeName() {
		return this._config.name;
	}

	get config() {
		return this._config;
	}

	destroy() {
		if (this._destroyed) {
			return;
		}
		this._deactivatePlugins();
		this._unsubscribeStore();
		this.destroy$.next(0 as any);
		if (this.store) {
			this.store = null;
			this.state$.complete();
			this.state$ = null;
			this.rootReducer = null;
			this.defaultState = null;
			this.effect$.complete();
			this.effect$ = null;
			this.reducers.clear();
			this.reducers = null;
		}
		this._logger$.complete();
		this._logger$ = null;
		this.destroy$.complete();
		this.destroy$ = null;
		this._destroyed = true;
	}

	select<ST = Partial<S>, R = any>(selector?: StoreSelector<ST, R>): Observable<R> {
		this._checkForDestroy();
		const distinctUntilDestroy = pipe(distinctUntilChanged<any>(), takeUntil(this.destroy$));
		// prettier-ignore
		const pipeState = selector
			? pipe(map(selector), distinctUntilDestroy)
			: pipe(distinctUntilDestroy);
		return this.state$.pipe(pipeState) as Observable<R>;
	}

	dispatch(action: IAction) {
		this._checkForDestroy();
		return this.store.dispatch(action);
	}

	getState<R = Partial<S>>(selector?: StoreSelector<Partial<S>, R>): R {
		this._checkForDestroy();
		const state = this.state$.getValue();
		return selector ? selector(state) : (state as R);
	}

	addEffects<State = S>(...effects: Effect<IAction, IAction, State, any>[]) {
		this._checkForDestroy();
		this.effect$.next(combineEffects(...effects));
		return this;
	}

	addReducers(...reducers: ClassType<ReducerBase>[]) {
		reducers = reducers || [];
		reducers.forEach(reducer => {
			if (this.reducers.has(reducer)) {
				_throw('duplicate adding reducer!');
			}
			this.reducers.set(reducer, new reducer());
		});
		return this;
	}

	private _activatePlugins() {
		Promise.resolve().then(() => {
			(this._config.plugins || []).forEach(plugin => {
				this.addReducers(...(plugin.reducers || [])).addEffects(...(plugin.effects || []));
				plugin.activate(this);
			});
		});
	}

	private _deactivatePlugins() {
		(this._config.plugins || []).forEach(plugin => {
			plugin.deactivate(this);
		});
	}

	private _init(config: IRxStoreConfig<S>) {
		this._config = config;
		this.defaultState = this._config.defaultState;
		this.rootReducer = (...args) => this._rootReducer(...args);
		this.effect$ = new BehaviorSubject(combineEffects(...(this._config.effects || [])));
		const rootEpic = (action$, state$, deps) => this.effect$.pipe(mergeMap(epic => epic(action$, state$, deps)));
		const epicMiddleware = createEpicMiddleware({ dependencies: this._config.dependencies });
		const loggerMiddleware = this._createLogger();
		let composeEnhancer = compose;
		const devTools = this._config.devTools || {};
		if (devTools.reduxDevTools && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
			composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: this.storeName });
		}
		this.store = createStore(
			<any>this.rootReducer,
			this.defaultState,
			devTools.useConsoleLogger
				? composeEnhancer(applyMiddleware(epicMiddleware, ...(this._config.middlewares || []), loggerMiddleware))
				: composeEnhancer(applyMiddleware(epicMiddleware, ...(this._config.middlewares || [])))
		);
		epicMiddleware.run(rootEpic);
		this.state$ = new BehaviorSubject(this.defaultState);
		this._unsubscribeStore = this.store.subscribe(() => {
			const state = this.store.getState();
			this.state$.next(state);
		});
		this._activatePlugins();
	}

	private _rootReducer(state: S, action: IAction) {
		this._checkForDestroy();
		let nextState = state;
		if (this._config.reducer) {
			nextState = this._config.reducer(nextState, action);
		}
		nextState = Array.from(this.reducers.values()).reduce((_state, _reducer) => {
			if (_reducer.actionType === action.type) {
				return _reducer.reduce(_state, action);
			}
			return _state;
		}, nextState);
		return nextState;
	}

	private _createLogger() {
		const devTools = this._config.devTools || {};
		return createLogger({
			timestamp: true,
			logErrors: true,
			logger: {
				log: (...args) => {
					if (this._destroyed) return;
					this._logger$.next({ method: 'log', data: args, useEventLog: devTools.useEventLog });
				},
				error: (...args) => {
					if (this._destroyed) return;
					this._logger$.next({ method: 'error', data: args, useEventLog: devTools.useEventLog });
				},
				warn: (...args) => {
					if (this._destroyed) return;
					this._logger$.next({ method: 'warn', data: args, useEventLog: devTools.useEventLog });
				},
				info: (...args) => {
					if (this._destroyed) return;
					this._logger$.next({ method: 'info', data: args, useEventLog: devTools.useEventLog });
				},
				group: (...args) => {
					if (this._destroyed) return;
					this._logger$.next({ method: 'group', data: args, useEventLog: devTools.useEventLog });
				},
				groupCollapsed: (...args) => {
					if (this._destroyed) return;
					this._logger$.next({ method: 'groupCollapsed', data: args, useEventLog: devTools.useEventLog });
				},
				groupEnd: () => {
					if (this._destroyed) return;
					this._logger$.next({ method: 'groupEnd', data: [], useEventLog: devTools.useEventLog });
				},
			},
		});
	}

	private _validateConfig(config: IRxStoreConfig<S>) {
		if (!config) {
			_throw('store config can not be empty!');
		}
		if (!config.name) {
			_throw('store config must have a name!');
		}
	}

	private _checkForDestroy() {
		if (this._destroyed) {
			_throw('Store is destroyed!');
		}
	}
}

function _throw(msg: string) {
	throw new Error(msg);
}

export function createStoreFactory<STATE>(config: IRxStoreConfig): IRxStore<STATE> {
	return new RxStore<STATE>(config);
}
