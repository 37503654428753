import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CookieService, SessionUser } from '@valhalla/core';
import { IUser, IUserProfile, IUserStatus } from '@valhalla/data/entities';
import { EMPTY, fromEvent, merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, exhaustMap, ignoreElements, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';

import { IApiResponse } from '@spa/data/http';
import ApiVersion from '../api-versions';
import { DataHttpCommonService } from '@spa/data/http';
import { EndpointUrlConfig } from '../endpoint.config';
import { UsersDataHttpService } from './abstract';
import {
	IAddQuickRepliesReq,
	ICowokersCriteria,
	IDeleteQuickRepliesReq,
	IGetUserContactsCriteria,
	IGetUserSessionInfoResult,
	IGetUserSettingsResult,
	IGetUserStatusCriteria,
	IQuickReplies,
	IRecipientResolve,
	IRecipientResponse,
	IUpdateSettings,
	IUserChangePasswordRequest,
	IUserOrgUnitsReq,
	IUserOrgUnitsRes,
	IUserProcessAssistant,
	IUserShortInfo,
	IUserSubstitute,
	IUserSubstituteData,
	MtfAreaView,
} from './types';

import type { IEndpointUrlConfig, IUserOrgStructureResponse } from '@spa/data/http';
@Injectable()
export class UsersDataHttpServiceImpl implements UsersDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) readonly config: IEndpointUrlConfig,
		readonly http: HttpClient,
		readonly common: DataHttpCommonService,
		readonly cookies: CookieService,
		readonly sessionUser: SessionUser
	) {}

	domParser = new DOMParser();

	quickRepliesReq$ = new Subject();
	quickReplies$ = this.quickRepliesReq$.pipe(
		startWith(0),
		exhaustMap(() => {
			const url = this.common.getApiUrl(`users/current/quickreplies`, ApiVersion.v10);
			return this.http.get<string[]>(url);
		}),
		shareReplay({ refCount: false, bufferSize: 1 })
	);

	#sessionUserSettingsUpdate$ = new Subject<any>();
	#sessionUserSettings$: Observable<IGetUserSettingsResult>;
	get sessionUserSettings$(): Observable<IGetUserSettingsResult> {
		if (!this.#sessionUserSettings$) {
			this.#sessionUserSettings$ = merge(
				this.#sessionUserSettingsUpdate$.pipe(startWith(0)),
				fromEvent(window, 'need-update-session-user-settings')
			).pipe(
				switchMap(() => this.getSettings(this.sessionUser.userId)),
				shareReplay({ refCount: false, bufferSize: 1 })
			);
		}
		return this.#sessionUserSettings$;
	}

	sessionUserSettingsUpdate() {
		this.#sessionUserSettingsUpdate$.next(0);
	}

	getStatus(criteria: IGetUserStatusCriteria): Observable<IUserStatus[]> {
		if (criteria.userIds.length === 0) {
			return of().pipe(ignoreElements());
		}
		const url = this.common.getApiUrl(`users/status`);
		return this.http.post<IUserStatus[]>(url, criteria.userIds).pipe(
			catchError(err => {
				if (err instanceof HttpErrorResponse) {
					if (err.status === 404) {
						return of().pipe(ignoreElements());
					}
				}
				return throwError(() => err);
			})
		);
	}

	getMembersInGroup(groupId: number): Observable<{ id: number; name: string; type: string }[]> {
		// need use getMembersGroup
		return EMPTY;
	}

	// todo add type
	getMembersGroup(groupId: number): Observable<any> {
		const url = this.common.getApiUrl(`group/group-card`);
		return this.http.get<IApiResponse<any>>(url, { params: { groupId } }).pipe(map(res => res.data));
	}

	parseUsersFromHTML(html: string): Array<{ id: number; name: string; type: string }> {
		if (!html) return [];

		const document = this.domParser.parseFromString(html, 'text/html');
		const userNodes = document.body.querySelectorAll<HTMLElement>('.user');
		const users: Array<{ id: number; name: string; type: string }> = Array.from(userNodes || []).map(node => {
			return {
				id: parseInt(node.dataset.userid, 10),
				name: node.textContent,
				type: 'user',
			};
		});

		return users;
	}

	getSettings(userId: number): Observable<IGetUserSettingsResult> {
		const url = this.common.getApiUrl(`user/settings`);

		return this.http
			.get<IApiResponse<IGetUserSettingsResult>>(url, {
				params: {
					userId: String(userId),
				},
			})
			.pipe(
				map(res => this.applyUserSettingsFromCookies(res.data)),
				tap(s => {
					if (s?.userUISettings?.viewTaskState === 'NewWindow') {
						s.userUISettings.viewTaskState = 'PopupWindow';
					}
				})
			);
	}

	updateSettings(req: IUpdateSettings): Observable<void> {
		const url = this.common.getApiUrl(`/user/misc/settings/update`);
		return this.http.post<void>(url, req);
	}

	getUiSettings(userId: number): Observable<any> {
		const url = this.common.getApiUrl(`user/${userId}/ui-settings`);

		return this.http.get<any>(url);
	}

	getQuickRepliesUser(userId: number): Observable<IQuickReplies[]> {
		const url = this.common.getApiUrl(`user/misc/quick-replies/${userId}`);
		return this.http.get<IApiResponse<IQuickReplies[]>>(url).pipe(map(res => res.data));
	}

	addQuickReplies(req: IAddQuickRepliesReq[]): Observable<void> {
		const url = this.common.getApiUrl(`user/misc/quick-replies/add`);
		return this.http.post<any>(url, req);
	}

	deleteQuickReplies(req: IDeleteQuickRepliesReq[]): Observable<void> {
		const url = this.common.getApiUrl(`user/misc/quick-replies/delete`);
		return this.http.post<any>(url, req);
	}

	postUiSettings(userId: number, body): Observable<void> {
		const url = this.common.getApiUrl(`user/${userId}/ui-settings`);

		return this.http.post<void>(url, body);
	}

	getUserInfo(userId: number): Observable<IUser> {
		const url = this.common.getApiUrl(`/user/${userId}/info`);
		return this.http.get<IApiResponse<IUser>>(url).pipe(map(res => res.data));
	}

	getUserList(...ids: number[]): Observable<IUser[]> {
		const url = this.common.getApiUrl(`users/infoByIds`, ApiVersion.v10);
		return this.http.post<any>(url, ids);
	}

	getUserProfile(userId: number): Observable<IUserProfile> {
		const url = this.common.getApiUrl(`/user/${userId}/profile`);
		return this.http.get<IApiResponse<IUserProfile>>(url).pipe(map(res => res.data));
	}

	getSessionUserInfo(): Observable<IGetUserSessionInfoResult> {
		const url = this.common.getApiUrl(`auth/info`);
		return this.http.get<IApiResponse<IGetUserSessionInfoResult>>(url).pipe(map(res => res.data));
	}

	getContacts(criteria?: Partial<IGetUserContactsCriteria>): Observable<IUser[]> {
		return this.searchContacts(criteria);
	}

	getContactsWithGroups(criteria?: Partial<IRecipientResolve>): Observable<IRecipientResponse> {
		const url = this.common.getApiUrl(`recipient/resolve`);
		return this.http.post<IApiResponse<IRecipientResponse>>(url, criteria).pipe(map(res => res.data));
	}

	searchContacts(criteria?: Partial<IGetUserContactsCriteria>, withEmailOnly?: boolean): Observable<IUser[]> {
		const body = {
			query: String((criteria && criteria.userName) || '          '),
			withEmailOnly,
		};

		const url = this.common.getApiUrl(`mobile/users/search`, ApiVersion.v10);
		return this.http.post<IApiResponse<IUser[]>>(url, body).pipe(map(res => res?.data));
	}

	getQuickReplies(): Observable<string[]> {
		this.quickRepliesReq$.next(0 as any);
		return this.quickReplies$;
	}

	getSessionUserSharedData<T = any>(propertyPath?: string): Observable<T> {
		const url = this.common.getApiUrl(`usersettings`);
		return this.http.get<IApiResponse<T>>(url, { params: { propertyPath } }).pipe(map(res => res.data));
	}

	setSessionUserSharedData<T = any>(value: any, propertyPath?: string): Observable<T> {
		const url = this.common.getApiUrl(`usersettings`);
		return this.http.post<any>(url, value, { params: { propertyPath } });
	}

	getCoworkers(data: ICowokersCriteria) {
		const url = this.common.getApiUrl(`user/coworkers`);
		return this.http.post<any>(url, data).pipe(map(res => res.data));
	}

	protected applyUserSettingsFromCookies(settings: IGetUserSettingsResult): IGetUserSettingsResult {
		settings = settings || ({} as any);
		let syndLayout = this.cookies.get('SyndLayout');
		const inPreview = this.cookies.get('TCPreview') === 'true';
		const commentsViewMode: any = this.cookies.get('CommentsViewMode');

		// in place not supported yet
		// if (!inPreview) {
		// 	settings.mtfPreview = MtfAreaView.inPlace;
		// } else
		if (!syndLayout) {
			syndLayout = 'horizontal';
			this.cookies.set('SyndLayout', syndLayout, undefined, '/');
		}
		if (syndLayout === 'vertical') {
			settings.mtfPreview = MtfAreaView.right;
		} else if (syndLayout === 'horizontal') {
			settings.mtfPreview = MtfAreaView.bottom;
		} else if (syndLayout === 'popup') {
			settings.mtfPreview = MtfAreaView.popup;
		}

		settings.commentsViewMode = commentsViewMode ?? 'down';

		// test
		// settings.mtfPreview = MtfAreaView.right;
		// settings.autoReadCommentsInTask = false;
		// settings.commentsViewMode = 'down';
		// settings.useNewGridSubcats.myTasks = false;
		// settings.useNewTaskCard = true;

		return settings;
	}

	getUsersShortInfo(criteria: Partial<IUserShortInfo>) {
		const url = this.common.getApiUrl(`user/short-info`);
		return this.http.post<IApiResponse>(url, criteria).pipe(map(res => res.data));
	}

	getSignatures(extParamIds?: string, onlyOverdue?: boolean) {
		const url = this.common.getApiUrl(`user/signatures/tasks-for-accept`);
		let params = new HttpParams();
		if (extParamIds) {
			params = params.append('includeExtParams', extParamIds);
		}
		if (onlyOverdue) {
			params = params.append('onlyOverdue', 'true');
		}
		return this.http.get<IApiResponse>(url, { params }).pipe(map(res => res.data));
	}

	uploadAvatar(userId: number, file: any) {
		const formData = new FormData();
		formData.append(file.name, file);

		const url = this.common.getApiUrl(`/user/${userId}/avatar/upload`);
		return this.http.post<any>(url, formData);
	}

	deleteAvatar(userId: number) {
		const url = this.common.getApiUrl(`/user/${userId}/avatar/delete`);
		return this.http.post<any>(url, {});
	}

	getUserOrgUnits(params: IUserOrgUnitsReq): Observable<IUserOrgUnitsRes[]> {
		const url = this.common.getApiUrl(`/user/${params?.userId}/orgunits`);
		return this.http.get<IApiResponse<IUserOrgUnitsRes[]>>(url, { params: { ...params } }).pipe(map(res => res.data));
	}

	// getUserSubstitutes(userId: number): Observable<IUserSubstitute[]> {
	// 	const url = this.common.getApiUrl(`/user/assistants/${userId}`);
	// 	return this.http.get<IApiResponse>(url).pipe(map(res => res.data));
	// }

	getUserAllAssistants(userId: number): Observable<IUserSubstitute[]> {
		const url = this.common.getApiUrl(`/user/assistants/all/${userId}`);
		return this.http.get<IApiResponse>(url).pipe(map(res => res.data));
	}

	setUserAssistants(assistants: IUserSubstituteData[]): any {
		const url = this.common.getApiUrl(`/user/assistants/add`);
		return this.http.post<any>(url, assistants);
	}

	removeUserAssistant(assistants: IUserSubstituteData[]): any {
		const url = this.common.getApiUrl(`/user/assistants/remove`);
		return this.http.post<any>(url, assistants);
	}

	// getUserPrincipals(userId: number): Observable<IUserSubstitute[]> {
	// 	const url = this.common.getApiUrl(`/user/assistants/principals/${userId}`);
	// 	return this.http.get<IApiResponse>(url).pipe(map(res => res.data));
	// }

	getUserAssistantsPrincipals(userId: number): Observable<IUserSubstitute[]> {
		const url = this.common.getApiUrl(`/user/assistants/principals/all/${userId}`);
		return this.http.get<IApiResponse>(url).pipe(map(res => res.data));
	}

	getUserProcessAssistants(userId: number): Observable<IUserProcessAssistant[]> {
		const url = this.common.getApiUrl(`/user/process-assistants/${userId}`);
		return this.http.get<IApiResponse>(url).pipe(map(res => res.data));
	}

	removeUserProcessAssistants(assistants: IUserProcessAssistant[]): any {
		const url = this.common.getApiUrl(`/user/process-assistants/remove`);
		return this.http.post<any>(url, assistants);
	}

	addUserProcessAssistants(assistants: IUserProcessAssistant[]): any {
		const url = this.common.getApiUrl(`/user/process-assistants/add`);
		return this.http.post<any>(url, assistants);
	}

	getUserProcessPrincipals(userId: number): Observable<IUserProcessAssistant[]> {
		const url = this.common.getApiUrl(`/user/process-assistants/principals/${userId}`);
		return this.http.get<IApiResponse>(url).pipe(map(res => res.data));
	}

	getUserOrgStructure(orgUnitId: number): Observable<IUserOrgStructureResponse> {
		const url = this.common.getApiUrl(`/org-structure/orgunit/${orgUnitId}`);
		return this.http.get<IApiResponse>(url).pipe(map(res => res.data));
	}

	changePassword(params: IUserChangePasswordRequest): Observable<any> {
		const url = this.common.getApiUrl(`/user/change-password`);
		return this.http.post<any>(url, params);
	}
}
