import { ModuleWithProviders, NgModule } from '@angular/core';

import { ngProviderGlobalErrorHandler } from './app-error-handler.service';

@NgModule()
export class ErrorHandlerModule {
	static forRoot(): ModuleWithProviders<ErrorHandlerModule> {
		return {
			ngModule: ErrorHandlerModule,
			providers: [ngProviderGlobalErrorHandler],
		};
	}
}
