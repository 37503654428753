export { TickerEffectDependencies } from './dependency';
import { loadTickersEffect } from './load-tickers.effect';
import { periodicUpdateTickersEffect } from './periodic-update-ticker.effect';
import { signalChatEffect } from './signal-chat.effect';
import { updateChatTickerEffect } from './update-chat-ticker.effect';
import { bySignalUpdateTickersEffect } from './update-ticker-by-signal.effect';

export const effects = [
	loadTickersEffect,
	periodicUpdateTickersEffect,
	signalChatEffect,
	updateChatTickerEffect,
	bySignalUpdateTickersEffect,
];
