import { Provider } from '@angular/core';

import { UserQuickAppsProvider } from './abstract';
import { NGDI_CONF_STORE_USER_QUICK_APPS } from './store';
import { UserQuickAppsProviderImpl } from './user-quick-apps.provider';

export const NGDI_CONF_USER_QUICK_APPS: Provider[] = [
	...NGDI_CONF_STORE_USER_QUICK_APPS,
	{
		provide: UserQuickAppsProvider,
		useClass: UserQuickAppsProviderImpl
	}
];
