import { IRxStore, StoreManager } from '@valhalla/core';
import { FacadeStoreNames } from '@spa/facade/store-names.enum';

import { effects, UserQuickAppsEffectDependencies } from './effects';
import { reducers } from './reducers';
import { defaultState } from './state.default';

export function getOrCreateUserQuickAppsStore(
	storeManager: StoreManager,
	dependencies: UserQuickAppsEffectDependencies,
	devTools = false,
	middlewares = []
): IRxStore {
	if (storeManager.isStoreExist(FacadeStoreNames.userQuickApps)) {
		return storeManager.getStore(FacadeStoreNames.userQuickApps);
	}
	const store = storeManager.createStore({
		name: FacadeStoreNames.userQuickApps,
		defaultState: defaultState,
		persistent: true,
		dependencies,
		devTools: {
			reduxDevTools: devTools,
			useConsoleLogger: devTools,
			useEventLog: true
		},
		middlewares
	});
	return store.addReducers(...reducers).addEffects(...effects);
}
