import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { ChatHelperService } from '@spa/components/chat/chat-helper.service';
import { EventBusService, ViewDestroyStreamService } from '@spa/core';
import { getTaskId, ITaskInfo, IUser } from '@spa/data/entities';
import { booleanFilter, InputSubject } from '@valhalla/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TaskAvatarChangeMessage } from './task-avatar-change-event';
import { TaskAvatarEditModalComponent } from './task-avatar-edit-modal/task-avatar-edit-modal.component';

@Component({
	selector: 'vh-task-avatar',
	templateUrl: './task-avatar.component.html',
	styleUrls: ['./task-avatar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class TaskAvatarComponent implements OnInit {
	constructor(
		readonly dialog: MatDialog,
		readonly cdr: ChangeDetectorRef,
		readonly eventBus: EventBusService,
		readonly destroy$: ViewDestroyStreamService,
		readonly sanitizer: DomSanitizer,
		readonly chatHelper: ChatHelperService
	) {}

	@Input()
	set taskAvatarDataUrl(val: string) {
		this.#taskAvatarDataUrl = val;
		this.taskAvatarDataUrlSanitized = val && this.sanitizer.bypassSecurityTrustResourceUrl(val);
	}
	get taskAvatarDataUrl() {
		return this.#taskAvatarDataUrl;
	}

	@Input()
	@HostBinding('class.vh-task-avatar--editable')
	editable = false;

	@Input()
	@HostBinding('style.height.px')
	@HostBinding('style.max-height.px')
	@HostBinding('style.min-height.px')
	@HostBinding('style.width.px')
	@HostBinding('style.max-width.px')
	@HostBinding('style.min-width.px')
	sideLengthPx = 49;

	@Input()
	biggerAvatarUserOrId: number | Partial<IUser>;

	@Input()
	subscribersMode = false;

	@Input()
	coloredText: string;

	@Input()
	chatAvatarDeleted: boolean;

	@Input()
	taskAvatarDeleted: boolean;

	@Input()
	showColorText: boolean;

	@Input()
	localEdit = false;

	@Input()
	set includeMeIfMultiple(val: boolean) {
		this.includeMeIfMultiple$.next(val);
	}
	get includeMeIfMultiple() {
		return this.includeMeIfMultiple$.value;
	}

	@Output()
	avatarChange = new EventEmitter();

	@Output()
	edited = new EventEmitter();

	@Output()
	avatarClicked = new EventEmitter();

	@HostBinding('class.vh-task-avatar')
	hostClassSelector = true;

	@Input()
	@InputSubject()
	task: Partial<ITaskInfo>;
	task$!: Observable<Partial<ITaskInfo>>;

	// eslint-disable-next-line @typescript-eslint/member-ordering
	@HostListener('click', ['$event'])
	onEditClick(e: Event) {
		e?.preventDefault();
		this.avatarClicked.emit();
		if (!this.editable) {
			return;
		}
		this.edit();
		return false;
	}

	#taskAvatarDataUrl: string;
	taskAvatarDataUrlSanitized: SafeValue;

	protected avatarBlob: Blob;

	readonly includeMeIfMultiple$ = new BehaviorSubject(false);

	readonly isTaskPlaceholderMode$ = this.task$.pipe(map(t => !t?.isChat && !t?.hasAvatar));
	readonly isChatPlaceholderMode$ = this.task$.pipe(map(t => t?.isChat && !t?.hasAvatar));
	readonly isPrivateChat$ = this.task$.pipe(
		booleanFilter(),
		switchMap(task => this.chatHelper.isPrivateChat(task))
	);

	get isCustomAvatar() {
		return !this.subscribersMode && (this.task?.hasAvatar || this.taskAvatarDataUrl);
	}

	get avatarUsers() {
		if (!this.isCustomAvatar) {
			let users = [];

			if (this.task?.avatarSubscribers) {
				return this.task.avatarSubscribers;
			}

			if (this.task?.owner) {
				users = [this.task.owner];
			}

			if (this.task?.performers) {
				users = [
					...users,
					...this.task.performers
						.filter(u => u.userId !== this.task?.owner?.userId)
						.sort((a, b) => a.userId - b.userId),
				];
			}

			if (this.task?.subscribers) {
				users = [
					...users,
					...this.task.subscribers
						.filter(subsriber => !users.map(user => user.userId).includes(subsriber.userId))
						.sort((a, b) => a.userId - b.userId),
				];
			}

			return users;
		}

		return null;
	}

	ngOnInit(): void {
		this.eventBus
			.ofType(TaskAvatarChangeMessage)
			.pipe(
				filter(e => e?.data?.taskId === getTaskId(this.task)),
				tap(e => {
					if (e.data.task && this.task) {
						this.task.hasAvatar = e.data.task.hasAvatar;
					}
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				if (this.isCustomAvatar) {
					this.subscribersMode = true;
					this.cdr.detectChanges();
					setTimeout(() => {
						this.subscribersMode = false;
						this.cdr.detectChanges();
					}, 40);
				} else {
					this.cdr.detectChanges();
				}
			});
	}

	edit(task?: Partial<ITaskInfo>) {
		task = task || this.task;
		this.dialog
			.open(TaskAvatarEditModalComponent, {
				data: {
					task: task,
					lastSender: task?.comments[0]?.sender,
					localEdit: this.localEdit,
					taskAvatarDataUrl: this.#taskAvatarDataUrl,
					coloredText: this.coloredText,
				},
			})
			.afterClosed()
			.subscribe(result => {
				if (this.localEdit) {
					this.edited.emit(result);
				} else {
					if (result) {
						this.eventBus.createAndSend(
							TaskAvatarChangeMessage,
							{
								taskId: getTaskId(task),
								task,
							},
							this
						);
						this.edited.emit();
					}
				}
			});
	}
}
