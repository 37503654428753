import { IAppDefinition } from '../../db';

export enum AppCardCommandType {
	addToQuickAppsPanel = 'addToQuickAppsPanel',
	addToFavoritesMenu = 'addToFavoritesMenu',
	openApp = 'openApp',
	addToStartupPage = 'addToStartupPage'
}

export class AppCardCommand {
	constructor(public command: AppCardCommandType, public appInfo: IAppDefinition) {}
}
