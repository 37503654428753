import { Observable } from 'rxjs';
import { ILanguageDescription } from '@valhalla/data/entities';

export abstract class LocalizationDataHttpService {
	abstract getLanguages(): Observable<Array<ILanguageDescription>>;
	abstract getCurrentLanguage(): Observable<ILanguageDescription>;
	abstract setLanguage(criteria: ILocalizationSetLanguageCriteria, allowNotification?: boolean): Observable<any>;

	abstract getLocalizationEntity(
		entityType: ILocalizationEntityType,
		entityId: number,
		taskId: number,
		extParamId: number
	): Observable<ILocalizationEntity>;
	abstract saveLocalizationEntity(
		entityType: ILocalizationEntityType,
		entityId: number,
		req: ISaveLocalizationItem[]
	): Observable<any>;
}

export interface ISaveLocalizationItem {
	languageId: number;
	value: string;
}

export enum ILocalizationEntityType {
	userFirstName = 'UserFirstName',
	userLastName = 'UserLastName',
	userMiddleName = 'UserMiddleName',
	task = 'Task',
	extParam = 'ExtParam',
	category = 'Category',
	Subcategory = 'Subcategory',
	SubcategoryEntity = 'SubcategoryEntity',
	StateRouteInSubcat = 'StateRouteInSubcat',
	State = 'State',
	ExtParamBlock = 'ExtParamBlock',
	Signature = 'Signature',
	SignatureResolutionType = 'SignatureResolutionType',
	TaskFormCustomButton = 'TaskFormCustomButton',
	TaskFormCustomButtonMemo = 'TaskFormCustomButtonMemo',
	ExtParamInSubcat = 'ExtParamInSubcat',
	PortalGridBlock = 'PortalGridBlock',
	MenuItem = 'MenuItem',
	FilterParam = 'FilterParam',
	ActionButtonInListName = 'ActionButtonInListName',
	ActionButtonInListDescription = 'ActionButtonInListDescription',
	PortalBlockTableColumnTitle = 'PortalBlockTableColumnTitle',
	ExtParamOption = 'ExtParamOption',
	CustomBlockUsedAsEpBlockName = 'CustomBlockUsedAsEpBlockName',
	CustomBlockUsedAsEpBlockSettingsName = 'CustomBlockUsedAsEpBlockSettingsName',
	SubcategorySendButton = 'SubcategorySendButton',
	SubcategorySrokName = 'SubcategorySrokName',
	SubcategoryTaskStartName = 'SubcategoryTaskStartName',
	CustomTabName = 'CustomTabName',
	OrgStructureName = 'OrgStructureName',
	PortalBlockAddButtonText = 'PortalBlockAddButtonText',
	APIBlockTemplateValue = 'APIBlockTemplateValue',
	APIFolderTemplateValue = 'APIFolderTemplateValue',
	APIContainerTemplateValue = 'APIContainerTemplateValue',
	StepTooltip = 'StepTooltip',
	StepOwnerTooltip = 'StepOwnerTooltip',
	StepUserTooltip = 'StepUserTooltip',
	ConfirmText = 'ConfirmText',
	TextExtParamValues = 'TextExtParamValues',
	StepperStepName = 'StepperStepName',
	StepperActionName = 'StepperActionName',
	NavigationPanelLogo = 'NavigationPanelLogo',
	StaticSignatureReason = 'StaticSignatureReason',
	UserFavsFolderName = 'UserFavsFolderName',
	UserLinksTitle = 'UserLinksTitle',
	ExtParamTableSettingsName = 'ExtParamTableSettingsName',
	ExtParamTooltip = 'ExtParamTooltip',
	FreeBusyStatus = 'FreeBusyStatus',
	AbsenceType = 'AbsenceType',
	SubcatTechWorksText = 'SubcatTechWorksText',
	ExtParamdButtonTitle = 'ExtParamdButtonTitle',
	ExtParamdOnlyCurrentUser = 'ExtParamdOnlyCurrentUser',
	ExtParamdRemoveUser = 'ExtParamdRemoveUser',
	ExtParamRemoveAll = 'ExtParamRemoveAll',
	ExtParamUserInfo = 'ExtParamUserInfo',
	ExtParamdEmptyTooltip = 'ExtParamdEmptyTooltip',
	ExtParamdSelectListTitle = 'ExtParamdSelectListTitle',
	PasswordPolicy = 'PasswordPolicy',
	AqbMetadata = 'AqbMetadata',
	EmojiReactionName = 'EmojiReactionName',
	EmojiReactionDescription = 'EmojiReactionDescription',
	GlobalStepSettingsDescription = 'GlobalStepSettingsDescription',
	GlobalStepSettingsForMail = 'GlobalStepSettingsForMail',
	GlobalStepSettingsForMailCustomer = 'GlobalStepSettingsForMailCustomer',
	GlobalStepSettingsCommentOnAttainment = 'GlobalStepSettingsCommentOnAttainment',
	GlobalStepSettingsParentComment = 'GlobalStepSettingsParentComment',
	StateForMails = 'StateForMails',
	StateForMailCustomers = 'StateForMailCustomers',
	StateCommentOnAttainments = 'StateCommentOnAttainments',
	StateParentComments = 'StateParentComments',
	OrgStructureTypeName = 'OrgStructureTypeName',
	UserTaskOnlySettingName = 'UserTaskOnlySettingName',
}

export interface ILocalizationEntity {
	entityId: number;
	entityType: ILocalizationEntityType;
	values: ILocalizationEntityItem[];
}

export interface ILocalizationEntityItem {
	languageId: number;
	value: string;
}

export interface ILocalizationSetLanguageCriteria {
	alias: string;
}
