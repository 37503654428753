import { Component, HostBinding, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { INavigationMenu, MenuItemType } from '../../navigation.model';

@Component({
	selector: 'vh-common-nav-vertical-group',
	templateUrl: './group.component.html',
	styleUrls: ['./group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavVerticalGroupCommonComponent {
	@HostBinding('class')
	classes = 'nav-group nav-item';

	@Input()
	menuItem: INavigationMenu;

	@Input()
	contextMenu;

	@Output()
	menuItemClick: EventEmitter<INavigationMenu> = new EventEmitter();

	readonly menuTypes = MenuItemType;
	get children() {
		return ((this.menuItem && this.menuItem.children) || []) as INavigationMenu[];
	}

	trackById(index: number, nav: INavigationMenu) {
		return nav.id;
	}
}
