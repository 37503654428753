import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DataHttpService, MsOfficeClickActions, useR7OfficeEditor } from '@spa/data/http';
import { map, of, switchMap, take } from 'rxjs';
import { getOfficeAppIdByExtension, IAttachment, openOfficeWebDavInEditMode } from '@spa/data/entities';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { FileVersionDialogComponent } from '../file-version/file-version-dialog/file-version-dialog.component';
import { FileViewerDialogComponent, IFileViewerDialogComponentData } from './file-viewer-dialog.component';
import { FileViewerComponent } from './file-viewer.component';
import { MimeType } from '@valhalla/data/entities';
import { Downloader, IR7EditorMode, UrlProvider } from '@spa/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { copyToClipboard } from '@valhalla/utils';

@Injectable({ providedIn: 'root' })
export class FileViewerService {
	constructor(
		readonly dialog: MatDialog,
		readonly downloader: Downloader,
		readonly modals: ModalWindowsService,
		readonly server: DataHttpService,
		readonly activatedRoute: ActivatedRoute,
		readonly urlBuilder: UrlProvider
	) {}

	readonly userSettings$ = this.server.users.sessionUserSettings$;
	readonly userSettingsClickAction$ = this.userSettings$.pipe(map(s => s.fileOnClickActionMode));
	readonly useR7Editor$ = this.server.config.appConfig$.pipe(map(useR7OfficeEditor));

	readonly applicationSettings$ = this.server.config.getApplicationSettings();
	readonly defaultFileOnClickActionMode$ = this.applicationSettings$.pipe(map(res => res?.fileOnClickActionMode));

	upper: MatDialogRef<any, any>;
	taskId: number;

	OpenFileViewer(file: Partial<IAttachment>, options?: FileViewerOptions, taskId?: number): boolean {
		this.taskId = taskId;
		if (FileViewerComponent.hasViewer(file)) {
			const fileForViewer = { ...file } as Partial<IAttachment>;

			if (!fileForViewer.isOldVersion) {
				fileForViewer.versionId = fileForViewer.versionId || fileForViewer.versionID || null;
			}

			if (options?.openAsBlob && file?.content) {
				fileForViewer.fileBlob = new Blob([new Uint8Array(file?.content)]);
			}

			const data: IFileViewerDialogComponentData = { file: fileForViewer };

			this.userSettingsClickAction$
				.pipe(
					switchMap(action => {
						// если в прочих настройках /spa/user/misc:
						// выбран пункт "По умолчанию"
						if (action === MsOfficeClickActions.default) {
							return this.defaultFileOnClickActionMode$;
						}

						// выбран любой пункт кроме "По умолчанию", возвращаем его
						return of(action);
					}),
					switchMap(action => {
						return this.useR7Editor$.pipe(map(useR7Editor => ({ action, useR7Editor })));
					}),
					take(1)
				)
				.subscribe(({ action, useR7Editor }) => {
					if (this.isOfficeFile(fileForViewer)) {
						if (options?.isEmailFile || options?.isMeetingFile) {
							return this.openR7Editor(fileForViewer, IR7EditorMode.view, options);
						}

						if (action === MsOfficeClickActions.download && !options?.openForView) {
							return this.downloadFile(file);
						}
						if (action === MsOfficeClickActions.webdav) {
							return this.openWebDav(file);
						}

						// если файл не поддерживается WebApps и R7, то скачиваем
						if (this.isExceptionFileWebApps(file)) {
							return this.downloadFile(file);
						}

						if (useR7Editor && action === MsOfficeClickActions.webapps) {
							return this.openR7Editor(fileForViewer, IR7EditorMode.view, options);
						}
					}

					data.options = data.options || {};
					data.options = {
						...data.options,
						...options,
					};
					data.options.viewer = FileViewerComponent.getMimeToViewer(file);
					data.options.useR7Editor = useR7Editor;
					data.options.modeR7Editor = IR7EditorMode.view;
					data.options.showNavigationActions = options?.hasOwnProperty('showNavigationActions')
						? options?.showNavigationActions
						: true;

					// если WebApps то открываем через окно файл вьювера
					this.upper = this.openFileViewerDialog(data);
					return this.upper;
				});

			return true;
		}

		return false;
	}

	isExceptionFileWebApps(file: Partial<IAttachment>) {
		const ext = this.getFileExtension(file);
		return ext === 'vsdx';
	}

	openLatestVersionFile(fileId, options?: FileViewerOptions) {
		this.server.files.getFileInfo(fileId).subscribe(fi => {
			if (window.location.pathname.indexOf('/noframe/file/') != -1) {
				let url = `/noframe/file/${fileId}`;
				if (fi?.latestVersionId) {
					url += `/${fi?.latestVersionId}`;
				}
				url = this.urlBuilder.getUrl(`${url}`, true);
				window.location = url as any;
			} else {
				this.closeUpper();
				this.OpenFileViewer(
					{
						id: fi.id,
						versionId: fi.latestVersionId,
						mime: fi.mime,
						fileMime: fi.mime,
						name: fi.name,
						fileName: fi.name,
					},
					options
				);
			}
		});
	}

	closeUpper() {
		if (this?.upper?.componentInstance) {
			this.upper.close();
		}
	}

	openFileViewerDialog(data) {
		return this.dialog.open(FileViewerDialogComponent, {
			closeOnNavigation: true,
			disableClose: true,
			data: { ...data, taskId: this.taskId },
			panelClass: ['mat-elevation-z24'],
			minWidth: '100vw',
			minHeight: '100vh',
		});
	}

	openR7Editor(file: Partial<IAttachment>, mode: IR7EditorMode = IR7EditorMode.view, options?) {
		const fileForViewer = { ...file } as Partial<IAttachment>;

		if (!fileForViewer.isOldVersion) {
			fileForViewer.versionId = fileForViewer.versionID = null;
		}

		const data: IFileViewerDialogComponentData = {
			file: fileForViewer,
			options: {
				useR7Editor: true,
				modeR7Editor: mode,
				...options,
			},
		};

		return this.openFileViewerDialog(data);
	}

	openWebApps(file: Partial<IAttachment>) {
		const fileForViewer = { ...file } as Partial<IAttachment>;

		if (!fileForViewer.isOldVersion) {
			fileForViewer.versionId = fileForViewer.versionID = null;
		}

		const data: IFileViewerDialogComponentData = { file: fileForViewer };

		return this.openFileViewerDialog(data);
	}

	openWebDav(file: Partial<IAttachment>) {
		const previewLink = this.downloader.createPreviewWebDAVLink(file, file.name);
		const fullUrlPreview = Location.joinWithSlash(document.baseURI.replace(/\/spa\/?$/, ''), previewLink);
		const appId = this.getOfficeAppIdByExtFile(this.getFileExtension(file));
		return openOfficeWebDavInEditMode(fullUrlPreview, appId, err => {
			this.modals.openError(err.message || String(err));
		});
	}

	getOfficeAppIdByExtFile(extFile: string, useDefault = false) {
		return getOfficeAppIdByExtension(extFile, useDefault);
	}

	getFileExtension(file: Partial<IAttachment>) {
		const ext = file?.name?.substr(file?.name?.lastIndexOf('.') + 1);
		return ext;
	}

	isOfficeFile(file: Partial<IAttachment>) {
		const byMime = [MimeType.word, MimeType.word2, MimeType.excel, MimeType.excel2].includes(file?.mime as MimeType);
		const byExt = Boolean(this.getOfficeAppIdByExtFile(this.getFileExtension(file), false));
		return byMime || byExt;
	}

	isPdfFile(file: Partial<IAttachment>) {
		const byMime = [MimeType.pdf].includes(file?.mime as MimeType);
		return byMime;
	}

	downloadFile(file: Partial<IAttachment>) {
		const link = this.downloader.createDownloadLink(file);
		this.downloader
			.download(link, file.name || file.fileName)
			.pipe(take(1))
			.subscribe();
	}

	downloadAllFiles() {
		const url = this.urlBuilder.getUrl(`/api/task/files/${this.taskId}/zip?v=2.0`);
		return this.downloader.download(url).pipe(take(1)).subscribe();
	}

	openVersionFile(file: Partial<IAttachment>) {
		this.modals.openDialog(FileVersionDialogComponent, {
			width: '90vw',
			data: {
				file: file,
			},
		});
	}

	onCopyLink(file: Partial<IAttachment>) {
		const link = this.urlBuilder.getAbsoluteUrl(this.downloader.createDownloadLink(file, true));
		copyToClipboard(link);
	}
}

export interface FileViewerOptions {
	isMeetingFile?: boolean;
	isEmailFile?: boolean;
	showNavigationActions?: boolean;
	openForView?: boolean; // открыть на просмотр, не учитывая настройки по умолчанию.
	openAsBlob?: boolean;
}
