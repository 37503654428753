import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentOverlayComponent } from './content-overlay.component';
import { NgxdModule } from '@ngxd/core';

@NgModule({
	imports: [CommonModule, NgxdModule],
	declarations: [ContentOverlayComponent],
	exports: [ContentOverlayComponent],
})
export class ContentOverlayModule {}
