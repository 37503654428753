import { Injectable } from '@angular/core';
import { LocalStorageProvider } from '@spa/core';
import { IReaction } from '@spa/data/entities';
import {
	DataHttpService,
	ICommentAddReactionParams,
	ICommentReactionsParams,
	ICommentRemoveReactionParams,
	IReactionDescription,
} from '@spa/data/http';
import { BehaviorSubject, combineLatest, exhaustMap, map, merge, of, shareReplay, Subject, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReactionsService {
	constructor(protected readonly server: DataHttpService, protected readonly ls: LocalStorageProvider) {
		this.setupAllowReactions();
		this.frequencyUse();
	}

	protected allowedReactionsStore$ = new BehaviorSubject<IReactionDescription[]>([]);
	protected allowedReactionsIdx = new Map<number, IReactionDescription>();
	protected reactionUseFrequency$ = new BehaviorSubject<Record<number, number>>({});
	readonly frequentlyUsedReactions$ = this.reactionUseFrequency$.pipe(
		map(data =>
			Object.entries(data)
				.sort((a, b) => b[1] - a[1])
				.map(i => +i[0])
		),
		map(ord => {
			const allowed = ord.filter(o => this.allowedReactionsIdx.has(o));
			return allowed.map(i => this.allowedReactionsIdx.get(i));
		}),
		shareReplay(1)
	);
	readonly allowedReactions$ = combineLatest([this.allowedReactionsStore$, of(this.defaultReactions())]).pipe(
		map(([all, def]) => {
			const res: IReactionDescription[] = [];
			const defSet = new Set(def.map(d => d.id));
			def.forEach(d => {
				if (this.allowedReactionsIdx.has(d.id)) {
					res.push(this.allowedReactionsIdx.get(d.id));
				}
			});
			all.forEach(a => {
				if (!defSet.has(a.id)) {
					res.push(a);
				}
			});
			return res;
		}),
		shareReplay(1)
	);
	readonly favoriteReactions$ = combineLatest([
		// отсортированые id эмоджи по частоте использования
		this.frequentlyUsedReactions$,
		// доступные реакции
		this.allowedReactions$,
	]).pipe(
		map(([freq, all]) => {
			const max = 6;
			const ord = freq.slice(0, max);
			const fav: IReactionDescription[] = [];
			const added = new Set<number>();
			ord.forEach(o => {
				if (this.allowedReactionsIdx.has(o.id)) {
					fav.push(this.allowedReactionsIdx.get(o.id));
					added.add(o.id);
				}
			});
			let padCount = max - fav.length;
			for (const emoji of all) {
				if (padCount <= 0) {
					break;
				}
				if (added.has(emoji.id)) {
					continue;
				}
				fav.push(emoji);
				added.add(emoji.id);
				padCount--;
			}
			return fav;
		}),
		shareReplay(1)
	);
	readonly symbols$ = this.allowedReactions$.pipe(
		map(a => a.filter(r => r.subGroupId === 82)),
		map(a => a.sort((a, b) => a.id - b.id)),
		shareReplay(1)
	);
	readonly allExceptSymbols$ = this.allowedReactions$.pipe(
		map(a => a.filter(r => r.subGroupId !== 82)),
		shareReplay(1)
	);
	readonly update$ = new Subject<void>();

	get(id: number) {
		return this.allowedReactionsIdx.get(id);
	}

	updateAllowedReactions() {
		this.update$.next();
	}

	removeReaction(params: ICommentRemoveReactionParams) {
		return this.server.comments.removeReaction(params);
	}

	addReaction(params: ICommentAddReactionParams) {
		this.frequencyUse({ [params.reactionId]: +1 });
		return this.server.comments.addReaction(params);
	}

	commentReactions(params: ICommentReactionsParams) {
		return this.server.comments.commentReactions(params);
	}

	frequencyUse(update?: Record<number, number>): Record<number, number> {
		const key = 'emoji.frequency.use';
		const current = this.ls.get<Record<number, number>>(key) || {};
		if (update) {
			Object.entries(update).forEach(([id, count]) => {
				current[id] = (current[id] || 0) + count;
			});
			this.ls.set(key, current);
		}
		this.reactionUseFrequency$.next(current);
		return current;
	}

	defaultReactions() {
		const rs: IReaction[] = [
			{ id: 1569, emoji: '👍' },
			{ id: 1563, emoji: '👎' },
			{ id: 4427, emoji: '🔥' },
			{ id: 1674, emoji: '👏' },
			{ id: 3899, emoji: '❤️' },
			{ id: 3919, emoji: '🥰' },
		];
		return rs;
	}

	protected setupAllowReactions() {
		const lsKey = 'emoji.allowed';
		this.update$
			.pipe(
				take(3),
				exhaustMap(() =>
					merge(of(this.ls.get(lsKey) || { reactions: [], groups: [] }), this.server.comments.allowedReactions())
				)
			)
			.subscribe(r => {
				this.ls.set(lsKey, r);
				r.reactions.forEach(i => {
					this.allowedReactionsIdx.set(i.id, i);
				});
				this.allowedReactionsStore$.next(r.reactions);
			});
	}
}
