import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { rxSetInterval } from '@valhalla/utils';
import { fromEvent } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { GlobalEventHandler } from '../type';
import { linkPageFrameLoadEvent$ } from './events';

export const profileAspxCreateChatButtonHandler: GlobalEventHandler = injector => {
	const router = injector.get(Router);
	const ngZone = injector.get(NgZone);

	return ngZone.runOutsideAngular(() => {
		const selector = (document: Document) => document.querySelector<HTMLElement>('.toolBarChatIcon');

		const openUserInfoAspxFrame$ = linkPageFrameLoadEvent$.pipe(
			filter(data => {
				const url = data?.url?.toLowerCase();
				return Boolean(url && url.includes('UserInfo.aspx'.toLowerCase()));
			})
		);

		const sub = openUserInfoAspxFrame$
			.pipe(
				switchMap(detail =>
					rxSetInterval(500).pipe(
						map(() => selector(detail.contentWindow.document)),
						filter(el => Boolean(el)),
						map(el => ({ el, detail })),
						take(1)
					)
				),
				switchMap(({ el, detail }) =>
					fromEvent(el, 'click', { capture: true }).pipe(
						map((e: MouseEvent) => {
							e.stopPropagation();
							e.preventDefault();

							const searchParams = new URLSearchParams(detail.contentWindow.location.search);
							const userId = searchParams.get('UserID');
							return { userId };
						})
					)
				),
				tap(({ userId }) => {
					ngZone.run(() => {
						router.navigate(['/chat/new'], {
							queryParams: {
								with: userId,
							},
						});
					});
				})
			)
			.subscribe();

		return () => {
			sub.unsubscribe();
		};
	});
};
