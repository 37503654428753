export enum IIconColorMap {
	onSurfacePrimary = 'var(--onsurface-primary)',
	onSurfaceSecondary = 'var(--onsurface-secondary)',
	onSurfaceTertiary = 'var(--onsurface-tertiary)',
	onSurfaceExtraPrimary = 'var(--onsurfaceextra-primary)',
	basePrimary = 'var(--base-primary)',
	baseInfo = 'var(--base-info)',
	baseSuccess = 'var(--base-success)',
	baseWarning = 'var(--base-warning)',
	baseDanger = 'var(--base-danger)',
	baseDefault = 'var(--base-default)',
	extramarkersRed = 'var(--extramarkers-red)',
	extramarkersPink = 'var(--extramarkers-pink)',
	extramarkersPurple = 'var(--extramarkers-purple)',
	extramarkersBlue = 'var(--extramarkers-blue)',
	extramarkersCyan = 'var(--extramarkers-cyan)',
	extramarkersGreen = 'var(--extramarkers-green)',
	extramarkersYellow = 'var(--extramarkers-yellow)',
	extramarkersOrange = 'var(--extramarkers-orange)',
	extramarkersBrown = 'var(--extramarkers-brown)',
	extramarkersGrey = ' var(--extramarkers-grey)',
	onContainerPrimary = 'var(--oncontainer-primary)',
	extraSurfaceRating = 'var(--extrasurface-rating)',
	onContainerDefault = 'var(--oncontainer-default)',
}

export enum IIconColor {
	onSurfacePrimary = 'onSurfacePrimary',
	onSurfaceSecondary = 'onSurfaceSecondary',
	onSurfaceTertiary = 'onSurfaceTertiary',
	onSurfaceExtraPrimary = 'onSurfaceExtraPrimary',
	basePrimary = 'basePrimary',
	baseInfo = 'baseInfo',
	baseSuccess = 'baseSuccess',
	baseWarning = 'baseWarning',
	baseDanger = 'baseDanger',
	baseDefault = 'baseDefault',
	extramarkersRed = 'extramarkersRed',
	extramarkersPink = 'extramarkersPink',
	extramarkersPurple = 'extramarkersPurple',
	extramarkersBlue = 'extramarkersBlue',
	extramarkersCyan = 'extramarkersCyan',
	extramarkersGreen = 'extramarkersGreen',
	extramarkersYellow = 'extramarkersYellow',
	extramarkersOrange = 'extramarkersOrange',
	extramarkersBrown = 'extramarkersBrown',
	extramarkersGrey = 'extramarkersGrey',
	onContainerPrimary = 'onContainerPrimary',
	onContainerDefault = 'onContainerDefault',
	extraSurfaceRating = 'extraSurfaceRating',
}

export enum IIconSizeMap {
	sm = '16px',
	md = '24px',
	lg = '32px',
}

export enum IIconSize {
	sm = 'sm',
	md = 'md',
	lg = 'lg',
}
