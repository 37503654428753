import { Directive, ElementRef, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { ViewDestroyStreamService } from '@spa/core';
import { debounceTime, filter, fromEvent, takeUntil } from 'rxjs';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[outsideClick]', providers: [ViewDestroyStreamService] })
export class OutsideClickDirective implements OnInit {
	constructor(readonly elRef: ElementRef<HTMLElement>, readonly destroy$: ViewDestroyStreamService) {}

	@Output()
	outsideClick = new EventEmitter<Event>();

	@Input()
	outsideClickDebounce = 0;

	@Input()
	outsideClickIgnoreClosest: string | string[];

	@Input()
	outsideClickIgnoreContainers: HTMLElement | HTMLElement[];

	@Input()
	outsideClickIgnoreWhenSelection = false;

	@Input()
	capture = true;

	get hasSelection() {
		return !!window.getSelection().toString();
	}

	ngOnInit(): void {
		let outsideClick$ = fromEvent(window, 'click', { capture: this.capture }).pipe(
			filter(e => {
				const target = e.target as HTMLElement;
				if (this.outsideClickIgnoreClosest) {
					const hasClosest = Array.isArray(this.outsideClickIgnoreClosest)
						? this.outsideClickIgnoreClosest.some(ig => target.closest(ig))
						: target.closest(this.outsideClickIgnoreClosest);
					if (hasClosest) {
						return false;
					}
				}
				if (this.outsideClickIgnoreContainers) {
					const containers = Array.isArray(this.outsideClickIgnoreContainers)
						? this.outsideClickIgnoreContainers
						: [this.outsideClickIgnoreContainers];
					const fromContainer = containers.some(e => e.contains(target));
					if (fromContainer) {
						return false;
					}
				}
				return !this.elRef.nativeElement.contains(target);
			}),
			takeUntil(this.destroy$)
		);
		if (this.outsideClickDebounce > 0) {
			outsideClick$ = outsideClick$.pipe(debounceTime(this.outsideClickDebounce));
		}
		outsideClick$.pipe(takeUntil(this.destroy$)).subscribe(e => {
			if (this.outsideClickIgnoreWhenSelection && this.hasSelection) {
				return;
			}
			this.outsideClick.emit(e);
		});
	}
}

@NgModule({
	exports: [OutsideClickDirective],
	declarations: [OutsideClickDirective],
})
export class OutsideClickModule {}
