import { Injectable } from '@angular/core';
import { getAppTopInjector } from '@spa/api/injectors';
import { SessionUser } from '@spa/core';
import { DataHttpService } from '@spa/data/http';
import { isHttpRes4xx } from '@valhalla/utils';
import { catchError, EMPTY, finalize, forkJoin, map, Observable, of, pipe, switchMap, take, throwError } from 'rxjs';
import { TaskReasonDialogComponent } from '@spa/components/task/ui/task-card/task-reason-dialog/task-reason-dialog.component';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { ApplicationService } from '@spa/application';
import { ExtParamBase } from './ext-param/ext-param-base';
import { CultureService } from '@spa/localization';
import { ILocalizationEntityType } from '@spa/data/http/localization';
import { ExtParamValidationService } from './ext-param-validation.service';

export type UpdateExtParamsInTaskArg = {
	taskId: number;
	extParams: ExtParamBase[];
	writeComment?: boolean;
};

@Injectable({ providedIn: 'root' })
export class UpdateExtParamsInTaskService {
	constructor(
		protected readonly server: DataHttpService,
		protected readonly sessionUser: SessionUser,
		protected readonly app: ApplicationService,
		protected readonly culture: CultureService,
		readonly extParamValidationService: ExtParamValidationService
	) {}

	get modal() {
		return getAppTopInjector().get(ModalWindowsService, null);
	}

	cashedReason: string;

	update(arg: UpdateExtParamsInTaskArg): Observable<any> {
		const extParams = arg.extParams.filter(ep => !['file', 'multifile', 'Multifile', 'File'].includes(ep.type));

		if (!extParams.length) {
			return EMPTY;
		}
		const needCommentToChange = extParams.some(ep => ep.changeWithComment && ep.dirty);

		const normalExtParams = extParams.filter(
			ep => !ep?.settings?.isLocalizable || ep?.settings?.editorType === 'Editorjs'
		);
		const localizedExtParams = extParams.filter(
			ep => ep?.settings?.isLocalizable && !(ep?.settings?.editorType === 'Editorjs')
		);

		const hasError = false;

		const afterUpdatePipe = () =>
			pipe(
				catchError(err => {
					if (isHttpRes4xx(err)) {
						const validationErrors = err?.error?.errors[0]?.validationErrors;

						if (validationErrors?.length) {
							this.extParamValidationService.setExtParamsValidationMessage(validationErrors, arg?.taskId);

							return throwError(() => err);
						}

						const message = err?.error?.errors[0]?.message;

						if (message) {
							const validationErrors = arg.extParams?.map(ep => ({
								extParamId: ep?.id,
								message,
							}));

							this.extParamValidationService.setExtParamsValidationMessage(validationErrors, arg?.taskId);

							return throwError(() => err);
						}

						// hasError = true;
						// extParams.forEach(e => {
						// 	e.setError(err);
						// });
					}
					return throwError(() => err);
				}),
				finalize(() => {
					if (!hasError) {
						this.cashedReason = null;
					}
					extParams.forEach(e => {
						e.setUpdating(false);
					});
					this.app.task.get(arg.taskId).update();
				})
			);

		extParams.forEach(e => {
			e.clearError();
			e.setUpdating();
		});

		if (needCommentToChange && !this.cashedReason) {
			return this.modal
				.openDialog(TaskReasonDialogComponent, {
					minWidth: 400,
				})
				.afterClosed()
				.pipe(
					switchMap(data => {
						if (!data?.reason) {
							extParams.forEach(ep => {
								ep.undoValue();
							});
							return EMPTY;
						}
						this.cashedReason = data.reason;
						return forkJoin([
							this.updateNormalEp(normalExtParams, arg.taskId, data.reason, arg.writeComment),
							...this.updateLocalazibleEp(normalExtParams, arg.taskId, data.reason, arg.writeComment),
						]);
					}),
					afterUpdatePipe()
				);
		}

		const arr = [
			this.updateNormalEp(normalExtParams, arg.taskId, '', arg.writeComment),
			...this.updateLocalazibleEp(localizedExtParams, arg.taskId, '', arg.writeComment),
		].filter(i => i !== EMPTY);

		return forkJoin(arr).pipe(afterUpdatePipe());
	}

	updateLocalazibleEp(extParams: ExtParamBase[], taskId: number, reason = '', writeComment = false): Observable<any>[] {
		if (!extParams?.length) {
			return [EMPTY];
		}

		const extParamsUpdate = extParams.map(ep => {
			if (reason) {
				this.culture.activeLanguage$.pipe(
					switchMap(language => {
						return this.server.locale.saveLocalizationEntity(
							ILocalizationEntityType.TextExtParamValues,
							ep.sourceConfig.localizedEntityId,
							[
								{
									languageId: language.id,
									value: ep.value,
								},
							]
						);
					}),
					take(1)
				);
			}

			return this.culture.activeLanguage$.pipe(
				switchMap(language => {
					return this.server.locale.saveLocalizationEntity(
						ILocalizationEntityType.TextExtParamValues,
						ep.sourceConfig.localizedEntityId,
						[
							{
								languageId: language.id,
								value: ep.value,
							},
						]
					);
				}),
				take(1)
			);
		});

		return extParamsUpdate;
	}

	updateNormalEp(extParams: ExtParamBase[], taskId: number, reason = '', writeComment = false): Observable<any> {
		if (!extParams?.length) {
			return EMPTY;
		}

		return forkJoin(extParams.map(ep => this.convert(ep))).pipe(
			switchMap(res => {
				const values = res.filter(Boolean);
				if (!values.length) {
					return EMPTY;
				}

				const value = values.join('::');

				if (reason) {
					return this.app.task.updateExtParams(
						{
							extParamStr: value,
							taskId: taskId,
							userId: this.sessionUser.userId,
							writeComment: writeComment,
							commentText: reason,
						},
						extParams
					);
				}

				return this.app.task.updateExtParams(
					{
						extParamStr: value,
						taskId: taskId,
						userId: this.sessionUser.userId,
						writeComment: writeComment,
					},
					extParams
				);
			})
		);
	}

	convert(ep: ExtParamBase): Observable<string> {
		const res = ep.convertForUpdateExtParamInTask();

		if (res instanceof Observable) {
			return res;
		}
		return of(res);
	}
}
