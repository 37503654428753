// tslint:disable:no-empty-interface
import { IColumnModelDto } from '@spa/pages/data-source/data-source-grid/column/column-model';
import { Observable } from 'rxjs';
import {
	DataSourceColumnAlignment,
	IDataSourceAggResponse,
	IDataSourceGridConfigColumn,
	IGridContext,
} from '../../../pages/data-source/data-source-grid/data-source-grid-options';
import {
	ICustomAction,
	IToolbarSettings,
} from '@spa/pages/data-source/data-source-grid/column-packs/custom-actions-column-pack/custom-actions-types/custom-action-base';
import { IDataSourceFormConfig } from '@spa/pages/data-source/data-source-grid-forms/data-source-grid-forms.service';
import { TableColumnWidthType } from '@vh/core-components';
import { FilterTypeSetting } from '@spa/pages/admin/data-source-settings-v2/helpers/setting-types-services/filterType-setting.service';

export abstract class DataSourceHttpService {
	/**@deprecated */
	abstract getConfig<T = any>(criteria: IDataSourceGetConfigByTypeCriteria): Observable<IDataSourceGetConfigResult<T>>;
	/**@deprecated */
	abstract getConfig<T = any>(
		criteria: IDataSourceGetConfigByEntityCriteria
	): Observable<IDataSourceGetConfigResult<T>>;
	/**@deprecated */
	abstract getData<T = any>(criteria: IDataSourceGetDataByTypeCriteria): Observable<IDataSourceGetDataResult<T>>;
	/**@deprecated */
	abstract getData<T = any>(criteria: IDataSourceGetDataByEntityTypeCriteria): Observable<IDataSourceGetDataResult<T>>;

	abstract config(source: string, isAdmin?: boolean, queryParams?: any): Observable<IDataSourceConfigResult>;
	abstract configEntity(entityId: number, entityType: string): Observable<IDataSourceConfigResult>;
	abstract configTaskUsed(source: string, blockId: number, taskId: number): Observable<IGetDataSourceConfigResult>;
	abstract data(source: string, params: any): Observable<any>;
	abstract dataEntity(entityId: number, entityType: string, params: any): Observable<any>;
	abstract groups(source: string, params: any): Observable<any>;
	abstract groupsEntity(entityId: number, entityType: string, params: any): Observable<any>;
	abstract saveSettings(params: IAdminDataSourceSaveSettingsParams): Observable<any>;

	abstract dataSourceConfig(
		type: string,
		id?: number,
		params?: any,
		userId?: number,
		isAdmin?: boolean
	): Observable<IGetDataSourceConfigResult>;
	abstract dataSourceRows(type: string, id?: number, params?: any, userId?: number): Observable<any>;
	abstract dataSourceExport(
		type: string,
		id?: number,
		params?: any,
		userId?: number,
		fileType?: 'excel' | 'csv'
	): Observable<any>;
	abstract dataSourceGroupRows(type: string, id?: number, params?: any, userId?: number): Observable<any>;
	abstract dataSourceSaveSettings(params: IDataSourceSaveSettingsParams): Observable<any>;
	abstract dataSourceTaskUsedConfig(
		blockSettingId: number,
		context: IGridContext,
		isAdmin: boolean
	): Observable<IGetDataSourceConfigResult>;
	abstract dataSourceTaskUsedRows(blockId: number, params: any): Observable<any>;
	abstract dataSourceTaskUsedGroupRows(type: string, id: number, params: any): Observable<any>;
	abstract getCustomDataSources(): Observable<ICustomDataSource[]>;
	abstract deleteCustomSource(id: number): Observable<any>;
	abstract getCustomDataSourceColumns(request: ICustomColumnRequest): Observable<ICustomColumnResponse>;
	abstract updateCustomSourceColumns(request: ICustomColumnUpdateRequest): Observable<any>;
	abstract dataSourceExportSource(
		source: string,
		params?: any,
		userId?: number,
		fileType?: 'excel' | 'csv'
	): Observable<any>;
	abstract dataSourceExportTicker(
		tickerId: number,
		params?: any,
		userId?: number,
		fileType?: 'excel' | 'csv'
	): Observable<any>;

	abstract getAggregates(type: string, id?: number, params?: any, userId?: number): Observable<IDataSourceAggResponse>;
	abstract getAggregatesTaskUsed(blockId: number, params: any): Observable<IDataSourceAggResponse>;
	abstract getAggregatesEntity(entityId: number, entityType: string, params?: any): Observable<IDataSourceAggResponse>;
	abstract getAggregatesSource(source: string, params: any): Observable<IDataSourceAggResponse>;
	abstract dataSourceAggregateRows(
		type: string,
		id?: number,
		params?: any,
		userId?: number
	): Observable<IDataSourceAggResponse>;
	abstract getColumnData(request: IGetDataByColumnRequest): Observable<IGetColumnDataResponse[]>;

	abstract getDataSource(entity: string, entityId: number, params: IDataSourceParams): Observable<IDataSourceRes>;

	abstract isWithResultset(source: string): Observable<boolean>;

	abstract configForm(alias: string): Observable<IDataSourceFormConfig[]>;
	abstract dataForm(formId: number, params: any): Observable<any>;
	abstract updateForm(formId: number, params: IFormActionRequest): Observable<any>;
	abstract deleteFormRow(formId: number, params: IFormActionRequest): Observable<any>;
	abstract createRow(formId: number, params: IFormActionRequest): Observable<any>;

	abstract resetUsersSettings(entityId: number, entityType: string): Observable<void>;
	abstract resetTableUsersSettings(entityType: string, entityId?: number): Observable<void>;

	abstract getSmartPacksOnEvents(
		subcatId: number,
		activeState: SmartPacksOnEventsActiveState
	): Observable<ISmartPacksOnEventsItem[]>;
	abstract getSmartRecurrences(subcatId: number): Observable<ISmartRecurrencesItem[]>;
	abstract executeSmartRecurrenceItem(id: number): Observable<string>;
	abstract deleteSmartRecurrenceItem(id: number): Observable<any>;
	abstract getSmartPacks(subcatId: number): Observable<ISmartPacksListItem[]>;
	abstract deleteSmartPackItem(id: number): Observable<any>;
	abstract getSmartExpressions(
		subcatId: number,
		enabledOnly: boolean,
		isFilter?: boolean
	): Observable<ISmartExpressionsItem[]>;
	abstract deleteSmartExpressionItem(id: number): Observable<any>;
	abstract saveTableSettings(type: string, id?: number, params?: IDataSourceSettings);
}

export interface IDataSourceParams {
	startRow: number | undefined;
	endRow: number | undefined;
	rowGroupCols: any[];
	valueCols: any[];
	pivotCols: any[];
	groupKeys: string[];
	filterModel: any;
	sortModel: any[];
	select?: IDataSourceGridConfigColumn[];
	quickFilter?: string;
	quickFilterAllColumns?: boolean;
	tasksState?: string;
	myTasks?: string;
	smartExpressionIds?: number[];
	context?: IGridContext;
	aggregations?: string[];
}

export interface IDataSourceRes {
	totalCount: number;
	tasksDataSourceResponse: ITasksDataSourceResponse[];
}

export interface ITasksDataSourceResponse {
	task: {
		taskId: number;
		taskText: string;
	};
	taskId: number;
	subcat: number;
	state: any;
	owner: any;
	performer: any;
	priority: {
		priorityId: number;
		priority: string;
	};
	modifiedDate: string;
	createdTask: string;
	percentDone: any;
	hasChildren: boolean;
	extParams: Record<string, ITasksDataSourceEp>;
	signatures: any;
	allAcceptants: any;
	allPerformers: any;
	closedDate: any;
	duration: any;
	overallPlan: any;
	overallFact: any;
	performerOrgUnit: any;
	stepAcceptedBy: any;
	dueDate: any;
	taskStartTime: any;
	endTime: any;
	isClosed: boolean;
	isRejected: boolean;
	isWaitingSign: boolean;
	isSelected: boolean;
	customFields: any;
	isOverdue: boolean;
	counters: any;
	colors: {
		stateText: string;
		stateBackground: string;
		taskTextText: string;
		taskTextBackground: string;
		dueDateText: string;
		dueDateBackground: string;
		ownerText: string;
		ownerBackground: string;
		performerText: string;
		performerBackground: string;
		planText: string;
		planBackground: string;
		factText: string;
		factBackground: string;
	};
}

export interface ITasksDataSourceEp {
	extParamId: number;
	extParamType: string;
	stringValue: string;
	dataSourceItemId: number;
	intValue: number;
	dateTimeValue: string;
	decimalValue: any;
	boolValue: boolean;
	selectUsersValue: any;
	type: any;
	canRead: boolean;
}
export interface IDataSourceSaveSettingsParams {
	entityId?: number;
	clientSettings: any;
	isAdmin?: boolean;
	userId?: number;
	key?: string;
	type?: string;
	settingName?: string;
	isViewSettingsIncluded?: boolean;
}

export interface IAdminDataSourceSaveSettingsParams {
	source?: string;
	entityId?: number;
	entityType?: string;
	forAdmin?: boolean;
	clientSettings: any;
}

export interface IDataSourceConfigResult {
	taskColumnsSettings: Array<IColumnModelDto>;
	userColumnsSettings?: any;
	aggregates?: string[];
	adminColumnsSettings?: any;
}

export interface IGetDataSourceConfigResult {
	/**
	 * Набор доступных колонок для грида
	 */
	taskColumnsSettings: Array<{
		type: string;
		field: string;
		title?: string;
		isDefault?: boolean;
		metaData?: Partial<{
			extParamType: string;
			subcatId: number;
			catId: number;
			taskUsedId: number;
			extParamSetting: any;
		}>;
		defaultWidth?: number;
		alignment?: DataSourceColumnAlignment;
		hidden?: boolean;
	}>;

	/**
	 * Админсикие настройки стейта грида
	 */
	adminColumnsSettings?: any;

	/**
	 * Сохраненный стейт грида юзера
	 */
	userColumnsSettings?: any;

	userHasNoSettings: boolean;

	aggregates?: string[];

	tableSettings: IDataSourceSettings;
}

export interface IDataSourceGetDataByEntityTypeCriteria {
	id: number;
	type: DataSourceEntityType;
	request: any;
}

export interface IDataSourceGetDataByTypeCriteria {
	type: DataSourceType;
	request: any;
}

export interface IDataSourceGetDataResult<T = any> {
	data: T[];
	total: number;
	group?: any[];
	aggregates?: any;
}

export interface IDataSourceGetConfigByTypeCriteria {
	type: DataSourceType;
	isAdmin?: boolean;
}

export interface IDataSourceGetConfigByEntityCriteria {
	type: DataSourceEntityType;
	id: number;
	isAdmin?: boolean;
}

export interface IDataSourceGetConfigResult<T = any> {
	id: number;
	type: string;
	settings: Array<T>;
	smartFilters?: ISmartFilter[];
}

export interface ISmartFilter {
	id: number;
	name: string;
}

export interface ICustomDataSource {
	name: string;
	description: string;
	id: number;
	source: string;
	alias: string;
	groups?: any[];
	type: string;
}

export interface ICustomColumn {
	title: string;
	column: string;
	isDisabled?: boolean;
	type: string;
	isAvailable?: boolean;
	filterSource: string;
	filterType?: DataSourceFilterType;
}

export interface ICustomColumnResponse {
	isNew: boolean;
	columns: ICustomColumn[];
	storedProcWithoutResultset?: boolean;
}

export interface ICustomColumnRequest {
	type: string;
	source: string;
	alias: string;
	specialType?: DataSourceCustomSpecialType;
	needNew?: boolean;
	settingId?: number;
}

export interface ICustomColumnUpdateRequest {
	columns: ICustomColumn[];
	alias: string;
	source: string;
	description: string;
	name: string;
	type: string;
	groupIds?: number[];
	settingId?: number;
	specialType?: DataSourceCustomSpecialType;
	downloadData?: boolean;
	customActions: ICustomAction[];
	toolbarSettings: IToolbarSettings;
}

export interface IGetDataByColumnRequest {
	filter: string;
	field: string;
	source: string;
	values: any[];
}

export interface IGetColumnDataResponse {
	name: any;
	value: any;
}

export interface IFormActionRequest {
	updateValue: string;
	action?: IFormCRUDAction;
}

export interface ISmartRecurrencesItem {
	id: number;
	recurrenceInfoID: number;
	packID: number;
	packName: string;
	filterID: number;
	filterName: string;
	periodicity: string;
	enabled: boolean;
	subcatID: number;
	lastExecutionDateTime: string;
}

export interface ISmartPacksListItem {
	id: number;
	name: string;
	eventID: number;
	parameterValue: number;
	eventName: string;
	isUnused: boolean;
	packDescription: string;
}

export interface ISmartExpressionsItem {
	id: number;
	name: string;
	eventId: string;
	isFilter: boolean;
	subcatId: number;
	noContextMode: boolean;
	originId: string;
	availableAsSmartSearch: boolean;
	isPublic: boolean;
	tSql: string;
	xhtml: string;
	parameterValue: number;
	isUnused: boolean;
	groupFilter: string;
}

export interface ISmartPacksOnEventsItem {
	id: number;
	enabled: true;
	parameterValue: number;
	smartFilterID: number;
	eventID: number;
	actionsPackID: number;
	smartFilterName: string;
	actionsPackName: string;
	toolTipText: string;
	eventName: string;
	eventGroup: string;
	eventGroupName: string;
	parameterValueName: string;
	actionPackDescription: string;
}

export interface IDataSourceSettings {
	smartExpressionIds?: number[];
	groupByColumns?: string[];
	canChangeWidth?: boolean;
	canChangeOrder?: boolean;
	canEnableFilters?: boolean;
	canEnableGroup?: boolean;
	saveOrderAndHideColumns?: boolean;
	saveFilters?: boolean;
	saveGrouping?: boolean;
	pinColumns?: any;
	calculateResults?: boolean;
	headerWrap?: boolean;
	canEnableAdditionalColumns?: boolean;
	columns?: IDataSourceColumnState[];
	saveUserSettings?: boolean;
}

export interface IDataSourceColumnState {
	alignment?: DataSourceColumnAlignment;
	width?: TableColumnWidthType;
	filterType?: FilterTypeSetting;
	available?: boolean;
	visibility?: boolean;
	order?: number;
	pinColumn?: boolean;
	results?: any;
	field?: string;
	title?: string;
	type?: string;
	epType?: string;
	wrapText?: boolean;
}

export enum DataSourceColumnTypes {
	numberValue = 'numberValue',
	stringValue = 'stringValue',
	dateValue = 'dateValue',
	dateTimeValue = 'dateTimeValue',
	task = 'task',
	subcat = 'subcat',
	owner = 'owner',
	performer = 'performer',
	priority = 'priority',
	state = 'state',
	percent = 'percent',
	orgUnit = 'orgUnit',
	extparam = 'extparam',
	strippedHtml = 'strippedHtml',
	html = 'html',
	signatures = 'signatures',
	allAcceptants = 'allAcceptants',
	stepAcceptedBy = 'stepAcceptedBy',
	booleanValue = 'booleanValue',
}

export enum IFormCRUDAction {
	Delete = 'delete',
	Insert = 'insert',
	Update = 'update',
}

export enum DataSourceFilterType {
	standart = 'standart',
	multifilter = 'multifilter',
}

export enum DataSourceType {
	default = 'default',
	general = 'general',
	signatures = 'signatures',
}

export enum DataSourceEntityType {
	subcategory = 'subcategory',
}

export enum DataSourceCustomSpecialType {
	CustomTaskUsed = 'customTaskUsed',
}

export enum SmartPacksOnEventsActiveState {
	All = 'All',
	Enabled = 'Enabled',
	Disabled = 'Disabled',
}
