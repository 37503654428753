import type { ColDef } from '@ag-grid-community/core';

/**
 * Типы ширины (справочник)
 * @see https://ru.1forma.ru/spa/spaces/subcat/45250?pageId=1261211
 */
export enum TableColumnWidthType {
	/**Один человек*/
	oneMan = 'oneMan',
	/**Несколько людей*/
	manyMan = 'manyMan',
	/**Дата*/
	date = 'date',
	/**Дата и время*/
	dateTime = 'dateTime',
	/**Узки диапазон*/
	xxs = 'xxs',
	/**Малый диапазон*/
	xs = 'xs',
	/**Средний диапазон*/
	md = 'md',
	/**Крупный диапазон*/
	xl = 'xl',
	/**Один контрол*/
	oneControl = 'oneControl',
	/**Кнопки*/
	buttons = 'buttons',
	/**число */
	number = 'number',
	/**узкий */
	narrow = 'narrow',
}

export function applyColumnSettingsByWidthType(
	column: Partial<ColDef>,
	type: TableColumnWidthType,
	metaData?: TableColumnWidthMetadata
) {
	// defaults
	column.resizable = true;
	return widthTypeToValueMap[type]?.(column, metaData);
}

export type TableColumnWidthMetadata = Partial<{
	hasHeader: boolean;
	wrapHeader: boolean;
}>;

type ApplyColumnSettings = (column: Partial<ColDef>, metaData?: TableColumnWidthMetadata) => Partial<ColDef>;

const widthTypeToValueMap: Record<string, ApplyColumnSettings> = {
	[TableColumnWidthType.oneMan]: col => {
		col.resizable = true;
		col.width = 200;
		col.minWidth = 135;
		col.maxWidth = 220;
		return col;
	},
	[TableColumnWidthType.manyMan]: col => {
		col.resizable = true;
		col.width = 265;
		col.minWidth = 180;
		col.maxWidth = 460;
		return col;
	},
	[TableColumnWidthType.date]: col => {
		col.resizable = true;
		col.width = 150;
		col.minWidth = 105;
		col.maxWidth = 170;
		col.wrapText = false;
		return col;
	},
	[TableColumnWidthType.dateTime]: col => {
		col.resizable = true;
		col.width = 150;
		col.minWidth = 105;
		col.maxWidth = 170;
		col.wrapText = false;
		return col;
	},
	[TableColumnWidthType.oneControl]: (col, meta) => {
		if (meta?.hasHeader === true) {
			col.minWidth = 64;
			col.maxWidth = 200;
			col.width = 160;
			col.resizable = true;
			col.wrapText = false;
		} else {
			col.minWidth = 44;
			col.suppressSizeToFit = true;
			col.resizable = false;
			col.wrapText = false;
		}
		return col;
	},
	[TableColumnWidthType.buttons]: col => {
		col.flex = 4;
		col.minWidth = 250;
		col.resizable = true;
		return col;
	},
	[TableColumnWidthType.xxs]: col => {
		col.minWidth = 100;
		col.flex = 1;
		return col;
	},
	[TableColumnWidthType.xs]: col => {
		col.minWidth = 160;
		col.flex = 2;
		return col;
	},
	[TableColumnWidthType.md]: col => {
		col.minWidth = 190;
		col.flex = 4;
		return col;
	},
	[TableColumnWidthType.xl]: col => {
		col.minWidth = 270;
		col.flex = 6;
		return col;
	},
	[TableColumnWidthType.number]: (col, meta) => {
		if (meta?.wrapHeader === true) {
			col.minWidth = 72;
			col.maxWidth = 140;
			col.width = 120;
			col.resizable = true;
		} else {
			col.minWidth = 72;
			col.maxWidth = 200;
			col.width = 120;
			col.resizable = true;
		}
		if (meta?.hasHeader === true) {
			col.minWidth = 72;
			col.maxWidth = 200;
			col.width = 120;
			col.resizable = true;
		} else {
			col.minWidth = 52;
			col.maxWidth = 120;
			col.width = 72;
			col.resizable = true;
		}
		return col;
	},
	[TableColumnWidthType.narrow]: col => {
		col.minWidth = 100;
		col.flex = 1;
		return col;
	},
};
