/**
 * This file only for type information
 * and Adapt the Dexie wrapper to runtime
 */
export type IndexableTypePart = string | number | Date | ArrayBuffer | ArrayBufferView | DataView | Array<Array<void>>;

export type IndexableTypeArray = Array<IndexableTypePart>;
export type IndexableTypeArrayReadonly = ReadonlyArray<IndexableTypePart>;
export type IndexableType = IndexableTypePart | IndexableTypeArrayReadonly;

export type ThenShortcut<T, TResult> = (value: T) => TResult | PromiseLike<TResult>;

export type TransactionMode = 'r' | 'r!' | 'r?' | 'rw' | 'rw!' | 'rw?';

export interface IProbablyError {
	name?: string;
	stack?: string;
	message?: string;
}

export interface IEntity<T = number | string> {
	id?: T;
}

/**
 * Adapter for Ijs, relate to Dexie class
 */
export abstract class DatabaseAdapter {
	readonly name: string;
	readonly tables: ITable<any, any>[];
	readonly verno: number;

	on: IDbEvents;

	// Make it possible to touch physical class constructors where they reside - as properties on db instance.
	// For example, checking if (x instanceof db.Table). Can't do (x instanceof ITable because it's just a virtual interface)
	// tslint:disable-next-line:naming-convention
	Table: new () => ITable<any, any>;
	// tslint:disable-next-line:naming-convention
	WhereClause: new () => IWhereClause<any, any>;
	// tslint:disable-next-line:naming-convention
	Version: new () => IVersion;
	// tslint:disable-next-line:naming-convention
	Transaction: new () => ITransaction;
	// tslint:disable-next-line:naming-convention
	Collection: new () => ICollection<any, any>;

	// static addons: Array<(db: Dexie) => void>;
	// static version: number;
	// static semVer: string;
	// static currentTransaction: ITransaction;
	// static waitFor<T> (promise: PromiseLike<T> | T) : IPromise<T>;
	// static waitFor<T> (promise: PromiseLike<T> | T, timeoutMilliseconds: number) : IPromise<T>;
	// static getDatabaseNames(): IPromise<string[]>;
	// static getDatabaseNames<R>(thenShortcut: ThenShortcut<string[],R>): IPromise<R>;
	// static override<F> (origFunc:F, overridedFactory: (fn:any)=>any) : F;
	// static getByKeyPath(obj: Object, keyPath: string): any;
	// static setByKeyPath(obj: Object, keyPath: string, value: any): void;
	// static delByKeyPath(obj: Object, keyPath: string): void;
	// static shallowClone<T> (obj: T): T;
	// static deepClone<T>(obj: T): T;
	// static asap(fn: Function) : void;
	// static maxKey: Array<Array<void>> | string;
	// static minKey: number;
	// static exists(dbName: string) : IPromise<boolean>;
	// static delete(dbName: string): IPromise<void>;
	// static dependencies: {
	// 		indexedDB: IDBFactory,
	// 		IDBKeyRange: IDBKeyRange
	// };
	// static default: Dexie;

	version(versionNumber: Number): IVersion {
		return null;
	}

	open(): Promise<DatabaseAdapter> {
		return null;
	}

	table(tableName: string): ITable<any, any>;

	table<T extends IEntity>(tableName: string): ITable<T, any>;

	table<T extends IEntity, Key>(tableName: string): ITable<T, Key> {
		return null;
	}

	transaction<U>(
		mode: TransactionMode,
		table: ITable<any, any> | ITable<any, any>[],
		scope: () => PromiseLike<U> | U
	): Promise<U> {
		return new Promise<U>(res => res(0 as any));
	}

	// transaction<U>(
	// 	mode: TransactionMode,
	// 	table: ITable<any, any>,
	// 	table2: ITable<any, any>,
	// 	table3: ITable<any, any>,
	// 	scope: () => PromiseLike<U> | U
	// ): Promise<U> {
	// 	return new Promise<U>(res => res());
	// }

	// transaction<U>(
	// 	mode: TransactionMode,
	// 	table: ITable<any, any>,
	// 	table2: ITable<any, any>,
	// 	table3: ITable<any, any>,
	// 	table4: ITable<any, any>,
	// 	scope: () => PromiseLike<U> | U
	// ): Promise<U>;

	// transaction<U>(
	// 	mode: TransactionMode,
	// 	table: ITable<any, any>,
	// 	table2: ITable<any, any>,
	// 	table3: ITable<any, any>,
	// 	table4: ITable<any, any>,
	// 	table5: ITable<any, any>,
	// 	scope: () => PromiseLike<U> | U
	// ): Promise<U> {
	// 	return null;
	// }

	close(): void {}

	delete(): Promise<void> {
		return null;
	}

	isOpen(): boolean {
		return null;
	}

	hasBeenClosed(): boolean {
		return null;
	}

	hasFailed(): boolean {
		return null;
	}

	dynamicallyOpened(): boolean {
		return null;
	}

	backendDB(): IDBDatabase {
		return null;
	}

	vip<U>(scopeFunction: () => U): U {
		return null;
	}
}

export interface IVersion {
	stores(schema: { [key: string]: string | null }): IVersion;
	upgrade(fn: (trans: ITransaction) => void): IVersion;
}

export interface ITransaction {
	active: boolean;
	db: DatabaseAdapter;
	mode: string;
	idbtrans: IDBTransaction;
	tables: { [type: string]: ITable<any, any> };
	storeNames: Array<string>;
	on: ITransactionEvents;
	abort(): void;
	table(tableName: string): ITable<any, any>;
	table<T extends IEntity>(tableName: string): ITable<T, any>;
	table<T extends IEntity, Key>(tableName: string): ITable<T, Key>;
}

export interface IDexieEvent {
	subscribers: Function[];
	fire(...args: any[]): any;
	subscribe(fn: (...args: any[]) => any): void;
	unsubscribe(fn: (...args: any[]) => any): void;
}

export interface IDexieErrorEvent {
	subscribe(fn: (error: any) => any): void;
	unsubscribe(fn: (error: any) => any): void;
	fire(error: any): any;
}

export interface IDexieVersionChangeEvent {
	subscribe(fn: (event: IDBVersionChangeEvent) => any): void;
	unsubscribe(fn: (event: IDBVersionChangeEvent) => any): void;
	fire(event: IDBVersionChangeEvent): any;
}

export interface IDexieOnReadyEvent {
	subscribe(fn: () => any, bSticky: boolean): void;
	unsubscribe(fn: () => any): void;
	fire(): any;
}

export interface IDexieEventSet {
	(eventName: string): IDexieEvent; // To be able to unsubscribe.

	addEventType(
		eventName: string,
		chainFunction?: (f1: Function, f2: Function) => Function,
		defaultFunction?: Function
	): IDexieEvent;
	addEventType(events: {
		[eventName: string]: 'asap' | [(f1: Function, f2: Function) => Function, Function];
	}): IDexieEvent;
}

export interface IDbEvents extends IDexieEventSet {
	(eventName: 'ready', subscriber: () => any, bSticky?: boolean): void;
	(eventName: 'populate', subscriber: () => any): void;
	// tslint:disable-next-line:unified-signatures
	(eventName: 'blocked', subscriber: () => any): void;
	(eventName: 'versionchange', subscriber: (event: IDBVersionChangeEvent) => any): void;
	ready: IDexieOnReadyEvent;
	populate: IDexieEvent;
	blocked: IDexieEvent;
	versionchange: IDexieVersionChangeEvent;
}

export interface ICreatingHookContext<T, Key> {
	onsuccess?: (primKey: Key) => void;
	onerror?: (err: any) => void;
}

export interface IUpdatingHookContext<T, Key> {
	onsuccess?: (updatedObj: T) => void;
	onerror?: (err: any) => void;
}

export interface IDeletingHookContext<T, Key> {
	onsuccess?: () => void;
	onerror?: (err: any) => void;
}

export interface ITableHooks<T, Key> extends IDexieEventSet {
	(
		eventName: 'creating',
		subscriber: (this: ICreatingHookContext<T, Key>, primKey: Key, obj: T, transaction: ITransaction) => any
	): void;
	(eventName: 'reading', subscriber: (obj: T) => T | any): void;
	(
		eventName: 'updating',
		subscriber: (
			this: IUpdatingHookContext<T, Key>,
			modifications: Object,
			primKey: Key,
			obj: T,
			transaction: ITransaction
		) => any
	): void;
	(
		eventName: 'deleting',
		subscriber: (this: IDeletingHookContext<T, Key>, primKey: Key, obj: T, transaction: ITransaction) => any
	): void;
	creating: IDexieEvent;
	reading: IDexieEvent;
	updating: IDexieEvent;
	deleting: IDexieEvent;
}

export interface ITransactionEvents extends IDexieEventSet {
	(eventName: 'complete' | 'abort', subscriber: () => any): void;
	(eventName: 'error', subscriber: (error: any) => any): void;
	complete: IDexieEvent;
	abort: IDexieEvent;
	error: IDexieEvent;
}

export interface ITable<T extends IEntity, Key = number | string> {
	name: string;
	schema: ITableSchema;
	hook: ITableHooks<T, Key>;

	get(key: Key): Promise<T | undefined>;
	get<R>(key: Key, thenShortcut: ThenShortcut<T | undefined, R>): Promise<R>;
	// tslint:disable-next-line:unified-signatures
	get(equalityCriterias: { [key: string]: IndexableType }): Promise<T | undefined>;
	// tslint:disable-next-line:unified-signatures
	get<R>(equalityCriterias: { [key: string]: IndexableType }, thenShortcut: ThenShortcut<T | undefined, R>): Promise<R>;
	where(index: string | string[]): IWhereClause<T, Key>;
	where(equalityCriterias: { [key: string]: IndexableType }): ICollection<T, Key>;

	filter(fn: (obj: T) => boolean): ICollection<T, Key>;

	count(): Promise<number>;
	count<R>(thenShortcut: ThenShortcut<number, R>): Promise<R>;

	offset(n: number): ICollection<T, Key>;

	limit(n: number): ICollection<T, Key>;

	each(callback: (obj: T, cursor: { key: IndexableType; primaryKey: Key }) => any): Promise<void>;

	toArray(): Promise<Array<T>>;
	toArray<R>(thenShortcut: ThenShortcut<T[], R>): Promise<R>;

	toCollection(): ICollection<T, Key>;
	orderBy(index: string | string[]): ICollection<T, Key>;
	reverse(): ICollection<T, Key>;
	mapToClass(constructor: Function): Function;
	add(item: T, key?: Key): Promise<Key>;
	update(key: Key, changes: { [keyPath: string]: any }): Promise<number>;
	put(item: T, key?: Key): Promise<Key>;
	delete(key: Key): Promise<void>;
	clear(): Promise<void>;
	bulkAdd(items: T[], keys?: IndexableTypeArrayReadonly): Promise<Key>;
	bulkPut(items: T[], keys?: IndexableTypeArrayReadonly): Promise<Key>;
	bulkDelete(keys: IndexableTypeArrayReadonly): Promise<void>;
}

export interface IWhereClause<T, Key> {
	above(key: IndexableType): ICollection<T, Key>;
	aboveOrEqual(key: IndexableType): ICollection<T, Key>;
	anyOf(keys: IndexableTypeArrayReadonly): ICollection<T, Key>;
	anyOf(...keys: IndexableTypeArray): ICollection<T, Key>;
	anyOfIgnoreCase(keys: string[]): ICollection<T, Key>;
	anyOfIgnoreCase(...keys: string[]): ICollection<T, Key>;
	below(key: IndexableType): ICollection<T, Key>;
	belowOrEqual(key: IndexableType): ICollection<T, Key>;
	between(
		lower: IndexableType,
		upper: IndexableType,
		includeLower?: boolean,
		includeUpper?: boolean
	): ICollection<T, Key>;
	equals(key: IndexableType): ICollection<T, Key>;
	equalsIgnoreCase(key: string): ICollection<T, Key>;
	inAnyRange(ranges: Array<IndexableTypeArrayReadonly>): ICollection<T, Key>;
	startsWith(key: string): ICollection<T, Key>;
	startsWithAnyOf(prefixes: string[]): ICollection<T, Key>;
	startsWithAnyOf(...prefixes: string[]): ICollection<T, Key>;
	startsWithIgnoreCase(key: string): ICollection<T, Key>;
	startsWithAnyOfIgnoreCase(prefixes: string[]): ICollection<T, Key>;
	startsWithAnyOfIgnoreCase(...prefixes: string[]): ICollection<T, Key>;
	noneOf(keys: Array<IndexableType>): ICollection<T, Key>;
	notEqual(key: IndexableType): ICollection<T, Key>;
}

export interface ICollection<T, Key> {
	and(filter: (x: T) => boolean): ICollection<T, Key>;
	clone(props?: Object): ICollection<T, Key>;
	count(): Promise<number>;
	count<R>(thenShortcut: ThenShortcut<number, R>): Promise<R>;
	distinct(): ICollection<T, Key>;
	each(callback: (obj: T, cursor: { key: IndexableType; primaryKey: Key }) => any): Promise<void>;
	eachKey(callback: (key: IndexableType, cursor: { key: IndexableType; primaryKey: Key }) => any): Promise<void>;
	eachPrimaryKey(callback: (key: Key, cursor: { key: IndexableType; primaryKey: Key }) => any): Promise<void>;
	eachUniqueKey(callback: (key: IndexableType, cursor: { key: IndexableType; primaryKey: Key }) => any): Promise<void>;
	filter(filter: (x: T) => boolean): ICollection<T, Key>;
	first(): Promise<T | undefined>;
	first<R>(thenShortcut: ThenShortcut<T | undefined, R>): Promise<R>;
	keys(): Promise<IndexableTypeArray>;
	keys<R>(thenShortcut: ThenShortcut<IndexableTypeArray, R>): Promise<R>;
	primaryKeys(): Promise<Key[]>;
	primaryKeys<R>(thenShortcut: ThenShortcut<Key[], R>): Promise<R>;
	last(): Promise<T | undefined>;
	last<R>(thenShortcut: ThenShortcut<T | undefined, R>): Promise<R>;
	limit(n: number): ICollection<T, Key>;
	offset(n: number): ICollection<T, Key>;
	or(indexOrPrimayKey: string): IWhereClause<T, Key>;
	raw(): ICollection<T, Key>;
	reverse(): ICollection<T, Key>;
	sortBy(keyPath: string): Promise<T[]>;
	sortBy<R>(keyPath: string, thenShortcut: ThenShortcut<T[], R>): Promise<R>;
	toArray(): Promise<Array<T>>;
	toArray<R>(thenShortcut: ThenShortcut<T[], R>): Promise<R>;
	uniqueKeys(): Promise<IndexableTypeArray>;
	uniqueKeys<R>(thenShortcut: ThenShortcut<IndexableTypeArray, R>): Promise<R>;
	until(filter: (value: T) => boolean, includeStopEntry?: boolean): ICollection<T, Key>;
	// Mutating methods
	delete(): Promise<number>;
	modify(changeCallback: (obj: T, ctx: { value: T }) => void): Promise<number>;
	modify(changes: { [keyPath: string]: any }): Promise<number>;
}

export interface ITableSchema {
	name: string;
	primKey: IIndexSpec;
	indexes: IIndexSpec[];
	mappedClass: Function;
}

export interface IIndexSpec {
	name: string;
	keyPath: string | Array<string>;
	unique: boolean;
	multi: boolean;
	auto: boolean;
	compound: boolean;
	src: string;
}
