import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@valhalla/core';
import { map } from 'rxjs/operators';

import ApiVersion from '../api-versions';
import { DataHttpCommonService } from '../data-http-common.service';
import { ResourcesDataHttpService } from './abstract';
import {
	IResourceData,
	IResourcePlan,
	ISetFactParams,
	IPerformerDays,
	IChangePlanParams,
	ISetPlanParams,
	ICalendar,
	IShortPerformer,
} from '@spa/common/components/resource-table/resource-table.types';
import { Observable } from 'rxjs';

@Injectable()
export class ResourcesDataHttpServiceImpl implements ResourcesDataHttpService {
	constructor(readonly http: HttpClient, readonly common: DataHttpCommonService) {}
	// Получение ресурсов для задачи - план, факт и оргструктуры доступные (группы ресурсов)
	public getResources(taskId: number): Observable<IResourceData> {
		const url = `tasks/${taskId}/resources`;
		return this.http.get<IResourceData>(this.common.getApiUrl(url, ApiVersion.v10));
	}

	// получение плана факта и текущего ресурса, привязанного к задаче
	public getPlanAndFact(taskId: number, performerId: number, resourceId: number): Observable<IResourcePlan> {
		let url = '';
		if (resourceId) {
			url = `tasks/${taskId}/performers/${performerId}/load/${resourceId}`;
		} else {
			url = `tasks/${taskId}/performers/${performerId}/load/?_=${new Date().getTime()}`;
		}
		return this.http.get<IResourcePlan>(this.common.getApiUrl(url, ApiVersion.v10));
	}

	// добавление факта
	public setFact(taskId: number, resourceId: number, params: ISetFactParams): Observable<any> {
		let url = '';
		if (resourceId) {
			url = `tasks/${taskId}/performers/load/fact/${resourceId}`;
		} else {
			url = `tasks/${taskId}/performers/load/fact/`;
		}
		return this.http.post<any>(this.common.getApiUrl(url, ApiVersion.v11), params);
	}

	public getPossiblePerformers(taskId: number, resourceId: number): Observable<IPerformerDays[]> {
		const url = `tasks/${taskId}/possiblePerformers/${resourceId}`;
		return this.http.get<IPerformerDays[]>(this.common.getApiUrl(url, ApiVersion.v10));
	}

	public changePlans(taskId: number, params: IChangePlanParams) {
		const url = `tasks/${taskId}/resources/plan`;
		return this.http.post<any>(this.common.getApiUrl(url, ApiVersion.v10), params);
	}

	public setPlan(taskId: number, params: ISetPlanParams) {
		const url = `/tasks/${taskId}/performers/load/plan`;
		return this.http.post<any>(this.common.getApiUrl(url, ApiVersion.v11), params);
	}

	public setPlanForPerrformer(params: any) {
		const url = `/api/resource-plans/set-performer-plan`;
		return this.http.post<any>(url, params);
	}

	public getCalendar(taskId: number, userId: number, from: string, to: string): Observable<ICalendar[]> {
		const url = `tasks/${taskId}/performers/User/${userId}/load/plan/calendar`;
		return this.http.get<ICalendar[]>(this.common.getApiUrl(url, ApiVersion.v11), {
			params: {
				from: from,
				to: to,
			},
		});
	}

	public assignPerformers(taskId: number, users: IShortPerformer[]) {
		const url = `/tasks/${taskId}/assignPerformers`;
		return this.http.post<any>(this.common.getApiUrl(url, ApiVersion.v10), users);
	}
}
