<ng-container *ngIf="isViewAllTickers$ | async; then allIconsNotification; else oneIconNotification"></ng-container>

<mat-menu #tickers="matMenu">
	<ng-template matMenuContent let-items>
		<button
			*ngFor="let item of items"
			mat-menu-item
			(click)="tickerClick(item.value, $event)"
			type="button"
			class="fluent-box-more-menu-button"
			[attr.ticker-id]="item.value.id"
		>
			<ng-container *ngIf="!item.value.icon?.fontSet">
				<mat-icon *ngIf="item.value.icon?.isFormaIcon; else materialIcon" [svgIcon]="item.value.icon?.name"> </mat-icon>

				<ng-template #materialIcon>
					<mat-icon
						class="s-24"
						[class.secondary-text]="secondaryColor"
						[class.material-icons-outlined]="useOutlinedIcons"
						[svgIcon]="isFormaIcons(item.value.icon?.name) ? item.value.icon?.name : null"
						>{{ isFormaIcons(item.value.icon?.name) ? null : item.value.icon?.name }}</mat-icon
					>
				</ng-template>
			</ng-container>

			<mat-icon
				*ngIf="item.value.icon?.fontSet"
				class="s-24"
				[class.secondary-text]="secondaryColor"
				[fontSet]="item.value.id === 'system/chatUnreadMessageCount' ? null : item.value.icon?.fontSet"
				[fontIcon]="item.value.id === 'system/chatUnreadMessageCount' ? null : item.value.icon?.name"
				[svgIcon]="item.value.id === 'system/chatUnreadMessageCount' ? 'vh-chats-24' : null"
			></mat-icon>
			<span>{{
				item.value.id === 'system/chatUnreadMessageCount' ? ('common.tickerTooltipChats' | resx) : item.value.title
			}}</span>

			<vh-badge
				*ngIf="item.value.value"
				[value]="item.value.value"
				[color]="colorBadgeByTicker(item.value)"
				[locale]="locale$ | async"
			></vh-badge>
		</button>
	</ng-template>
</mat-menu>

<ng-template #allIconsNotification let-pass="pass">
	<vh-common-fluent-box
		#fluentBox
		[bufferBasis]="vertical ? -40 : fluentBoxBufferBasis"
		[container]="fluentBoxContainer"
		[direction]="vertical ? 'column' : 'row'"
		[align]="vertical ? 'top' : 'right'"
		[itemHeight]="42"
		[itemWidth]="42"
		[hideAllToMore]="hideAllTickers"
	>
		<button
			*vhFluentBoxMore
			class="vh-btn vh-btn-icon vh-btn--sidebar-mini"
			[matMenuTriggerFor]="tickers"
			[matMenuTriggerData]="{ $implicit: fluentBox.itemsInMore$ | async }"
		>
			<mat-icon svgIcon="vh-more-menu-24" [style]="vertical ? { transform: 'rotate(90deg)' } : null"></mat-icon>
		</button>

		<ng-container *ngFor="let ticker of allTickers$ | async; trackBy: trackById; index as i">
			<ng-container *ngIf="ticker.id === 'system/chatUnreadMessageCount'; else elseNotChat">
				<button
					class="vh-btn vh-btn-icon vh-btn--sidebar-mini"
					*vhFluentBoxItem="ticker"
					[tippy]="('common.tickerTooltipChats' | resx) + (ticker.value > 0 ? ' (' + ticker.value + ')' : '')"
					[isEnabled]="!mobileService.isMobile"
					[placement]="tooltipPosition"
					(click)="tickerClick(ticker, $event)"
					[attr.ticker-id]="ticker.id"
					[class.active]="isUrl(ticker)"
				>
					<vh-badge
						*ngIf="ticker.value"
						[value]="ticker.value"
						[color]="colorBadgeByTicker(ticker)"
						[locale]="locale$ | async"
					></vh-badge>

					<mat-icon class="s-24" svgIcon="vh-chats-24"></mat-icon>
				</button>
			</ng-container>

			<ng-template #elseNotChat>
				<button
					*vhFluentBoxItem="ticker"
					[tippy]="ticker.title + (ticker.value > 0 ? ' (' + ticker.value + ')' : '')"
					[isEnabled]="!mobileService.isMobile"
					[placement]="tooltipPosition"
					(click)="tickerClick(ticker, $event)"
					[attr.ticker-id]="ticker.id"
					[class.active]="isUrl(ticker)"
					class="vh-btn vh-btn-icon vh-btn--sidebar-mini"
				>
					<vh-badge
						*ngIf="ticker.value"
						[value]="ticker.value"
						[color]="colorBadgeByTicker(ticker)"
						[locale]="locale$ | async"
					></vh-badge>

					<ng-container *ngIf="!ticker.icon?.fontSet">
						<mat-icon *ngIf="ticker.icon?.isFormaIcon; else materialIcon" [svgIcon]="ticker.icon?.name"> </mat-icon>

						<ng-template #materialIcon>
							<mat-icon
								class="s-24"
								[class.secondary-text]="secondaryColor"
								[class.material-icons-outlined]="useOutlinedIcons"
								[svgIcon]="isFormaIcons(ticker.icon?.name) ? ticker.icon?.name : null"
								>{{ isFormaIcons(ticker.icon?.name) ? null : ticker.icon?.name }}</mat-icon
							>
						</ng-template>
					</ng-container>

					<mat-icon
						*ngIf="ticker.icon?.fontSet"
						class="s-24"
						[class.secondary-text]="secondaryColor"
						[fontSet]="ticker.icon?.fontSet"
						[fontIcon]="ticker.icon?.name"
					></mat-icon>
				</button>
			</ng-template>
		</ng-container>
	</vh-common-fluent-box>
</ng-template>

<ng-template #oneIconNotification>
	<button
		class="vh-btn vh-btn-icon vh-btn--sidebar-mini"
		[tippy]="'Уведомления'"
		[matMenuTriggerFor]="userMenu"
		[isEnabled]="!mobileService.isMobile"
	>
		<vh-badge
			*ngIf="sumTickersValue$ | async"
			[value]="sumTickersValue$ | async"
			[color]="colorSumTickers$ | async"
			[locale]="locale$ | async"
		></vh-badge>

		<mat-icon
			*vhSubscribe="sumTickersValue$ as sumValue"
			class="s-24"
			[class.secondary-text]="secondaryColor"
			[class.material-icons-outlined]="useOutlinedIcons"
			>notifications</mat-icon
		>
	</button>

	<mat-menu #userMenu="matMenu" [overlapTrigger]="false">
		<ng-template matMenuContent>
			<button
				*ngFor="let ticker of allTickers$ | async; trackBy: trackById"
				type="button"
				mat-menu-item
				[style.overflow]="'initial'"
				[tippy]="ticker.title"
				[isEnabled]="!mobileService.isMobile"
				(click)="tickerClick(ticker, $event)"
				[attr.ticker-id]="ticker.id"
				[class.active]="isUrl(ticker)"
				class="fluent-box-more-menu-button"
			>
				<mat-icon
					*ngIf="!ticker.icon?.fontSet && !ticker.icon?.isFormaIcon"
					class="s-24"
					[class.secondary-text]="secondaryColor"
					[class.material-icons-outlined]="useOutlinedIcons"
					[fontSet]="ticker.id === 'system/chatUnreadMessageCount' ? null : ticker.icon?.fontSet"
					[fontIcon]="
						ticker.id === 'system/chatUnreadMessageCount' ? null : ticker.icon?.fontSet ? ticker.icon?.name : null
					"
					>{{ !ticker.icon?.fontSet ? ticker.icon?.name : '' }}
				</mat-icon>
				<mat-icon
					*ngIf="ticker.icon?.isFormaIcon || ticker.id === 'system/chatUnreadMessageCount'"
					[svgIcon]="ticker.id === 'system/chatUnreadMessageCount' ? 'vh-chats-24' : ticker.icon?.name"
				>
				</mat-icon>
				<span>{{ ticker.title }}</span>

				<vh-badge
					*ngIf="ticker.value"
					[value]="ticker.value"
					[color]="colorBadgeByTicker(ticker)"
					[locale]="locale$ | async"
				></vh-badge>
			</button>
		</ng-template>
	</mat-menu>
</ng-template>
