import { Injectable, Injector, Type } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { UniversalLoaderProvider } from '../../loader';
import { Database, DatabaseFactory } from './abstract';

@Injectable()
export class IndexedDbProvider {
	constructor(protected readonly moduleLoader: UniversalLoaderProvider, protected readonly injector: Injector) {}
	private _moduleImport = import('./indexed.module').then(m => m.IndexedDbModule);

	/**
	 * Create database if not exist or return existing
	 */
	createDatabase<T extends Database>(dbClass: Type<T>) {
		return this.moduleLoader.loadModule(this._moduleImport, this.injector).pipe(
			mergeMap(moduleRef => {
				const dbFactory: DatabaseFactory = moduleRef.injector.get(DatabaseFactory);
				return dbFactory.createDatabase<T>(dbClass);
			})
		);
	}

	/**
	 * More semantic wrapper for createDatabase()
	 */
	getDatabase<T extends Database>(dbClass: Type<T>) {
		return this.createDatabase(dbClass).pipe(
			catchError(err => {
				console.error(err);
				return EMPTY;
			})
		);
	}
}
