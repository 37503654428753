import { Provider } from '@angular/core';

import { Activator, ApplicationActivator } from './abstract';
import { ApplicationActivatorImpl } from './activator';
import { ActivatorCustomApp } from './activator.custom';
import { ActivatorExternalApp } from './activator.external';
import { ActivatorSystemApp } from './activator.system';

export const NGDI_CONF_APPS_ACTIVATOR: Provider[] = [
	{
		provide: Activator,
		useClass: ActivatorSystemApp,
		multi: true
	},
	{
		provide: Activator,
		useClass: ActivatorExternalApp,
		multi: true
	},
	{
		provide: Activator,
		useClass: ActivatorCustomApp,
		multi: true
	},
	{
		provide: ApplicationActivator,
		useClass: ApplicationActivatorImpl
	}
];
