import { InjectionToken, inject, InjectFlags, Provider } from '@angular/core';
import { merge, RecursivePartial } from '@valhalla/utils';

export interface IEndpointUrlConfig {
	chat: {
		getContacts: string;
		getComments: string;
		getCommentsOffset: string;
		markCommentsAsRead: string;
		markCommentsAsQuestion: string;
		markCommentsAsAnswered: string;
		markCommentsAsNotMyQuestion: string;
		sendComment: string;
		postComment: string;
		chatUnsubscribe: string;
		chatClose: string;
		changeTaskText: string;
		chatCreate: string;
	};
	file: {
		/** load to temp table */
		preUploadFile: string;
		/** load to main table */
		uploadFile: string;
	};
	task: {
		feeds: string;
	};
}

/**@deprecated better use in place url (in direct method) */
export function getDefaultEndpointConfig(): IEndpointUrlConfig {
	return {
		chat: {
			getContacts: '/iOSClientServices/DataService.asmx/SearchContacts',
			getComments: '/iOSClientServices/DataService.asmx/GetPreviousTaskComments_v2',
			getCommentsOffset: 'comments',
			markCommentsAsRead: '/iOSClientServices/DataService.asmx/MarkCommentsAsRead',
			markCommentsAsQuestion: '/TCWeb.asmx/MarkCommentAsQuestion',
			markCommentsAsAnswered: '/TCWeb.asmx/MarkCommentAsAnswered',
			markCommentsAsNotMyQuestion: '/TCWeb.asmx/MarkCommentAsNotMyQuetion',
			sendComment: '/iOSClientServices/DataService.asmx/CreateComment',
			postComment: '/TCWeb.asmx/PostComment',
			chatUnsubscribe: '/iOSClientServices/DataService.asmx/RemoveSubscriber',
			chatClose: '/iOSClientServices/DataService.asmx/CloseChat',
			changeTaskText: '/iOSClientServices/DataService.asmx/ChangeTaskText',
			chatCreate: '/app/v1.0/api/chat',
		},
		file: {
			preUploadFile: '/NewTaskPreUploadFile.ashx',
			uploadFile: '/UploadFilesAsync.ashx',
		},
		task: {
			feeds: 'task/feeds',
		},
	};
}

export const EndpointUrlCustomConfig = new InjectionToken<RecursivePartial<IEndpointUrlConfig>>('custom api endpoitns');

export const EndpointUrlConfig = new InjectionToken<IEndpointUrlConfig>('api endpoints', {
	providedIn: 'root',
	factory: () => {
		const custom = inject(EndpointUrlCustomConfig, InjectFlags.Optional);
		const _default = getDefaultEndpointConfig();

		return merge(_default, custom || {});
	},
});
