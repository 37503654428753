<mat-toolbar>
	<span *ngIf="title || titleResx; else iconTitle">{{ titleResx ? (titleResx | resx) : title }}</span>

	<span class="mla"></span>

	<a
		class="vh-btn vh-btn-icon vh-btn--transparent"
		[href]="safeOpenInNewTabUrl || safeUrl"
		target="_blank"
		(click)="openInNewTab($event)"
	>
		<mat-icon>launch</mat-icon>
	</a>
	<button class="vh-btn vh-btn-icon vh-btn--transparent" (click)="close()">
		<mat-icon>close</mat-icon>
	</button>
</mat-toolbar>

<div class="flex-1">
	<vh-common-iframe-viewer
		[ngClass]="frameClasses"
		#frameViewer
		[src]="safeUrl"
		[postData]="postData"
		(loaded)="frameLoaded.emit($event)"
		[hideOnLoading]="hideOnLoading"
		[iframeBaseHref]="iframeBaseHref"
		[windowProps]="windowProps"
	></vh-common-iframe-viewer>
</div>

<ng-template #iconTitle>
	<mat-icon>info_outline</mat-icon>
</ng-template>
