let current = 0;

export function sequence() {
	return current++;
}

export function createSequence(start = 1) {
	return () => {
		return start++;
	};
}
