import { loadReducers } from './load';
import { crudReducer } from './crud';
import { favoritesCrudReducer } from './favorites-crud';
import { activeReducers } from './active';
import { UpdateFavoriteCountersReducer } from './update-favorite-counters';
import { InitNaviagationMenuReducer } from './init';

export const reducers = [
	...loadReducers,
	...crudReducer,
	...activeReducers,
	...favoritesCrudReducer,
	UpdateFavoriteCountersReducer,
	InitNaviagationMenuReducer,
];
