import { Injectable, inject } from '@angular/core';
import { TabActiveStateService } from './tab-active-state.service';
import { CronClient } from '@spa/cron';
import { DataHttpService, IGetGetCountersResult } from '@spa/data/http';
import { debounceTime, exhaustMap, filter, map, merge, shareReplay, switchMap } from 'rxjs';
import { SignalrProvider } from '@spa/data/signalr';

type ChatCounter = IGetGetCountersResult[0];
type ChatCounterKey = ChatCounter['key'];

@Injectable({ providedIn: 'root' })
export class CountersService {
	protected readonly tabActive = inject(TabActiveStateService);
	protected readonly cron = inject(CronClient);
	protected readonly server = inject(DataHttpService);
	protected readonly signal = inject(SignalrProvider);

	chatCounters$ = this.buildChatCounters();

	protected buildChatCounters() {
		return merge(
			this.server.chats.getCounters(),
			this.cron.from(this.cron.knownNames.chatTickers).pipe(map(r => r.lastResult)),
			merge(
				this.tabActive.tabBecomeVisible$.pipe(
					debounceTime(1000),
					filter(() => this.tabActive.tabVisible)
				),
				this.tabActive.goOnline$,
				this.signal.newComments$,
				this.signal.readComments$
			).pipe(
				debounceTime(1000),
				exhaustMap(() => this.server.chats.getCounters())
			)
		).pipe(
			map((r: IGetGetCountersResult) => {
				const result: Partial<Record<ChatCounterKey, number>> = r.reduce((acc, cur) => {
					acc[cur.key] = cur.value;
					return acc;
				}, {});
				result.GroupChatCount = result.GroupChatCount || 0;
				result.SubscribeCount = result.SubscribeCount || 0;
				result.UserChatCount = result.UserChatCount || 0;
				result.TotalChatCount = result.TotalChatCount || result.GroupChatCount + result.UserChatCount || 0;
				return result;
			}),
			shareReplay({ refCount: false, bufferSize: 1 })
		);
	}
}
