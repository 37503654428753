import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IApiResponse } from '../api-response';
import { DataHttpCommonService } from '../data-http-common.service';
import { EndpointUrlConfig } from '../endpoint.config';
import type { IEndpointUrlConfig } from '../endpoint.config';
import { EmailDataHttpService } from './abstract';
import type {
	IGetMailboxesResult,
	IAddToFavoritesRequestParams,
	IGetMessagesRes,
	IGetMessagesReq,
	IGetMessageReq,
	IGetMessageRes,
	ISetReadReq,
	IMailServerItem,
	IGetSyncFoldersReq,
	ISyncFolder,
	IAddMailboxReq,
	IMailbox,
	IUpdateMailboxReq,
	IGetFoldersReq,
	IMailSent,
	IDeleteMessage,
} from './abstract';

@Injectable()
export class EmailDataHttpServiceImpl implements EmailDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService
	) {}

	getMailboxes(): Observable<IGetMailboxesResult> {
		const url = this.common.getApiUrl(`emails/mailboxes`);
		return this.http
			.get<IApiResponse<IGetMailboxesResult>>(url, {
				headers: {
					['background-request']: 'true',
				},
			})
			.pipe(map(result => result.data));
	}

	getMailboxList(): Observable<IMailbox[]> {
		const url = this.common.getApiUrl(`mailbox/list`);
		return this.http.get<IApiResponse<IMailbox[]>>(url, {}).pipe(map(result => result.data));
	}

	// deleteMailBox(mailboxId: number, userId: number): Observable<any> {
	// 	const url = this.common.getApiUrl('emails/remove-mailbox');
	// 	return this.http.post<any>(url, { mailboxId, userId });
	// }

	addToFavorites(requestParams: IAddToFavoritesRequestParams) {
		const url = this.common.getApiUrl('favorite/addLink');

		const request = {
			link: requestParams?.link, //`javascript:void(TCLeftTrees.openSyndicate('?forceURL=Emails%2fEmailList.aspx%3ffolderId%3d${requestParams.folderId}&previewUrl=EmptyPreviewPage.aspx%3ffromEmail%3d1'))`,
			name: requestParams.folderName,
			isJsFunction: false,
			customImagePath: null,
			customImageClass: 'emailTreeMailFolder',
			type: 9,
			//linkedObjectId: `${requestParams?.mailBoxId}-${requestParams?.folderPath}`,
			//folderId: null,
		};
		return this.http.post<any>(url, request);
	}

	setAllRead(mailBoxId: number, mailFolderName: string): Observable<any> {
		const url = this.common.getApiUrl('mail/set-all-read');
		return this.http.post<any>(url, {
			mailBoxId,
			mailFolderName,
		});
	}

	deleteAllMessages(mailBoxId: number, mailFolderName: string): Observable<any> {
		const url = this.common.getApiUrl('mail/delete-all-messages');
		return this.http.post<any>(url, {
			mailBoxId,
			mailFolderName,
		});
	}

	markAllAsRead(folderId: number): Observable<any> {
		const url = this.common.getApiUrl('emails/change-state');
		const request = { emailIds: [], folderId: folderId, fromSearchFolder: false, setRead: true };
		return this.http.post<any>(url, request);
	}

	clearFolder(folderId: number): Observable<any> {
		const url = this.common.getApiUrl('emails/delete');
		const request = { emailIds: [], folderId: folderId, fromSearchFolder: true };
		return this.http.post<any>(url, request);
	}

	getMessages(req: IGetMessagesReq): Observable<IGetMessagesRes> {
		const url = this.common.getApiUrl('mail/find-messages');
		return this.http.post<IApiResponse<IGetMessagesRes>>(url, req).pipe(map(res => res?.data));
	}

	getMessage(req: IGetMessageReq): Observable<IGetMessageRes> {
		const url = this.common.getApiUrl('mail/find-message');
		return this.http.post<IApiResponse<IGetMessageRes>>(url, req).pipe(map(res => res?.data));
	}

	deleteMessage(req: IDeleteMessage): Observable<any> {
		const url = this.common.getApiUrl('mail/delete-message');
		return this.http.post<IApiResponse<any>>(url, req);
	}

	setRead(req: ISetReadReq): Observable<any> {
		const url = this.common.getApiUrl('mail/set-read');
		return this.http.post<IApiResponse<any>>(url, req);
	}

	setUnRead(req: ISetReadReq): Observable<any> {
		const url = this.common.getApiUrl('mail/set-unread');
		return this.http.post<IApiResponse<any>>(url, req);
	}

	getMailbox(mailBoxId: number): Observable<IMailbox> {
		const url = this.common.getApiUrl(`mailbox/${mailBoxId}`);
		return this.http.get<IApiResponse<IMailbox>>(url, {}).pipe(map(result => result.data));
	}

	getMailserverList(): Observable<IMailServerItem[]> {
		const url = this.common.getApiUrl(`mailserver/list`);
		return this.http.get<IApiResponse<any>>(url, {}).pipe(map(result => result.data));
	}

	getFolders(req: IGetFoldersReq): Observable<ISyncFolder[]> {
		const url = this.common.getApiUrl('mail/folders/list');
		return this.http.post<IApiResponse<any>>(url, req).pipe(map(result => result.data));
	}

	getSyncFoldersList(req: IGetSyncFoldersReq): Observable<ISyncFolder[]> {
		const url = this.common.getApiUrl('mail/sync-folders/list');
		return this.http.post<IApiResponse<any>>(url, req).pipe(map(result => result.data));
	}

	addMailbox(req: IAddMailboxReq): Observable<any> {
		const url = this.common.getApiUrl('mailbox/add');
		return this.http.post<IApiResponse<any>>(url, req);
	}

	deleteMailbox(mailboxId: number): Observable<any> {
		const url = this.common.getApiUrl(`mailbox/delete/${mailboxId}`);
		return this.http.post<any>(url, { mailboxId });
	}

	updateMailbox(req: IUpdateMailboxReq): Observable<any> {
		const url = this.common.getApiUrl('mailbox/update');
		return this.http.post<any>(url, req);
	}

	mailSend(req: IMailSent): Observable<any> {
		const url = this.common.getApiUrl('mail/send');
		return this.http.post<any>(url, req);
	}
}
