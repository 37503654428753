import { Injector } from '@angular/core';
import { getAppTopInjector } from '../injectors';
import { AuthService, SessionUser } from '@spa/core';
import { firstValueFrom } from 'rxjs';

export function createSpaApi(injector: Injector) {
	return {
		getSessionUser() {
			return {
				get data() {
					const auth = getAppTopInjector().get(AuthService);
					if (!auth.authenticated) {
						return Promise.resolve(null);
					}
					const sessionUser = getAppTopInjector().get(SessionUser);
					return firstValueFrom(sessionUser.userInfo$);
				},
			};
		},
	};
}
