import { periodicUpdateFavoritesMenuEffect } from './periodic-update-favorites-menu.effect';
import { loadNavigationMenuEffect } from './load';
import { setActiveNavigationItemEffect } from './set-active';
import { periodicUpdateFavoriteCountersEffect } from './periodic-update-counters.effect';
import { loadFavoritesCountersEffect } from './load-favorites-counters';

export { NavigationEffectDependencies } from './dependency';
export const effects = [
	loadNavigationMenuEffect,
	setActiveNavigationItemEffect,
	periodicUpdateFavoriteCountersEffect,
	loadFavoritesCountersEffect,
	periodicUpdateFavoritesMenuEffect,
];
