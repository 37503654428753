import { NgZone } from '@angular/core';
import { AuthService, Effect, EventBusService, IAction, ofType } from '@valhalla/core';
import { NotifyHubEvents, SignalrProvider } from '@valhalla/data/signalr';
import { merge } from 'rxjs';
import { debounceTime, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import { InternetConnectionService } from '@spa/common/services/internet-connection.service';
import { TabActiveStateService } from '@spa/common/services/tab-active-state.service';
import * as actions from '../actions';
import { ITickersState } from '../state';
import { TickerEffectDependencies } from './dependency';
import { DataHttpService } from '@spa/data/http';
import { ISystemTickersDto2 } from '@spa/data/entities';
import { firstLetter } from '@valhalla/utils';
import { UpdateSystemTickersEventBusMessage } from '../../update-tickers-eventbus-message';

export const bySignalUpdateTickersEffect: Effect<IAction, IAction, ITickersState, TickerEffectDependencies> = (
	actions$,
	state$,
	deps
) => {
	const logger = deps.loggerFactory.createLogger('signalUpdateTickersEffect');
	const turnOff$ = actions$.pipe(ofType(actions.FacadeTickersAction.turnOffPeriodicTickersUpdate));
	const zone = deps.injector.get(NgZone);
	const auth = deps.injector.get(AuthService);
	const activity = deps.injector.get(TabActiveStateService);
	const internet = deps.injector.get(InternetConnectionService);
	const signal = deps.injector.get(SignalrProvider);
	const server = deps.injector.get(DataHttpService);
	const bus = deps.injector.get(EventBusService);
	const signal$ = signal.signal$.pipe(filter(() => activity.isMasterTab));
	const readComments$ = signal$.pipe(filter(e => e.name === NotifyHubEvents.readComments));
	const newComments$ = signal$.pipe(filter(e => e.name === NotifyHubEvents.newComment));
	const questionComment$ = signal$.pipe(filter(e => e.name === NotifyHubEvents.questionComment));
	const refreshMTF$ = signal$.pipe(filter(e => e.name === NotifyHubEvents.refreshMTF));

	const updateBySignal$ = signal
		.activate()
		.pipe(
			switchMap(() =>
				merge(
					readComments$,
					newComments$,
					questionComment$,
					refreshMTF$,
					bus.ofType(UpdateSystemTickersEventBusMessage)
				).pipe(debounceTime(500))
			)
		);

	const updateTickersName: (keyof ISystemTickersDto2)[] = [
		'unreadCommentsCount',
		'badge',
		'questionsCount',
		'myQuestionsCount',
		'signaturesCount',
		'overdueSignatures',
		'overdueSigns',
		'directorSignaturesCount',
		'overDueTasksCount',
		'unreadChatCommentsCount',
	];

	return actions$.pipe(
		ofType(actions.FacadeTickersAction.turnOnPeriodicTickersUpdate),
		switchMap(action => {
			logger.info('run update tickers by signal');
			return merge(updateBySignal$, internet.goOnline$).pipe(
				activity.whenUserActive(true, false),
				mergeMap(() => server.tickers.getSystem(...updateTickersName)),
				map((data: any) => {
					const payload = updateTickersName.reduce((acc, cur) => {
						acc[`system/${firstLetter(cur, 'up')}`] = data[cur];
						return acc;
					}, {});
					return actions.updateTickersByName(payload);
				}),
				takeUntil(turnOff$)
			);
		}),
		filter(() => auth.authenticated)
	);
};
