<div #searchWrapper class="search-wrapper">
	<mat-icon class="s-28 secondary-text">search</mat-icon>
	<input
		#input
		[value]="searchInput$ | async"
		(input)="searchHandler($event.target.value)"
		(keydown.enter)="search.emit(input.value)"
		(keydown.arrowdown)="onKeyDownArrowInTaskSearch($event)"
		type="text"
		[placeholder]="'common.search' | resx"
	/>
	<button
		[style.visibility]="(searchInputHasValue$ | async) ? null : 'hidden'"
		matSuffix
		aria-label="Clear"
		class="cursor-pointer"
		(click)="clearSearch()"
	>
		<mat-icon class="s-24">close</mat-icon>
	</button>

	<ng-container *ngIf="progress.visible$ | async">
		<mat-progress-bar
			[color]="progress.color$ | async"
			[bufferValue]="progress.bufferValue$ | async"
			[mode]="progress.mode$ | async"
			[value]="progress.value$ | async"
		></mat-progress-bar>
	</ng-container>
</div>

<div class="search-actions">
	<a class="search-action" [routerLink]="['/search']" [queryParams]="{ mode: 'advanced' }">{{
		'common.searchExtended' | resx
	}}</a>
	<a class="search-action" [routerLink]="['/search']" [queryParams]="{ mode: 'attachment' }">{{
		'common.searchByAttachments' | resx
	}}</a>
</div>

<ng-container *ngIf="tasksHistory$ | async as tasksHistory">
	<div #searchHistory class="search-history">
		<mat-selection-list [multiple]="false" (selectionChange)="selectHistoryItem($event)">
			<div mat-subheader>{{ 'common.history' | resx }}</div>
			<ng-container *ngFor="let task of tasksHistory; let isLast = last">
				<mat-list-option [value]="{ value: task, type: 'task' }">
					<vh-chat-nav-avatar
						vhUserPreview
						[previewUserId]="task.id"
						[offsetY]="0"
						[offsetX]="0"
						mat-list-icon
						[coloredText]="task.name"
						[showColorText]="true"
						[sideLengthPx]="32"
					></vh-chat-nav-avatar>
					<div mat-line>{{ task.name }}</div>
					<div mat-line class="secondary-text">№{{ task.key }}</div>
				</mat-list-option>

				<mat-divider *ngIf="!isLast" class="ml-16 mr-16"></mat-divider>
			</ng-container>
		</mat-selection-list>
	</div>
</ng-container>
