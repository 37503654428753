import { Injector } from '@angular/core';

import { spaSubscribeEvent } from '../events';
import { GlobalSpaContextService } from '../global-spa-context.service';
import { spaCommand } from '../spa-command';
import { createSpaApi } from './spa-api';

export function initializeGlobalApi(global: any, injector?: Injector) {
	global.spaCommand = spaCommand;
	global.spaEvent = spaSubscribeEvent;
	global.spaApi = createSpaApi(injector);

	const spaContext = injector.get(GlobalSpaContextService);
	Object.defineProperty(global, 'spaContext', {
		get() {
			return spaContext.value;
		},
	});
}
