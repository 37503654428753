export function jsonTryParse<T = any>(json, cb?: (err: Error) => void, reviver?: (key, value) => any) {
	try {
		return JSON.parse(json, reviver) as T;
	} catch (e) {
		cb && cb(e);
	}
}

const getCircularReplacer = () => {
	const seen = new WeakSet();
	return (key, value) => {
		if (typeof value === 'object' && value !== null) {
			if (seen.has(value)) {
				return;
			}
			seen.add(value);
		}
		return value;
	};
};

export function jsonTryStringify(
	value,
	cbError?: (err: Error) => void,
	replacer?: (key, value) => any,
	space?: string | number
) {
	try {
		replacer = replacer || getCircularReplacer();
		const result = JSON.stringify(value, replacer, space);
		return result;
	} catch (e) {
		if (typeof cbError === 'function') {
			cbError(e);
		} else {
			console.error(e);
		}
	}
}

export function jsonClone<T>(data: T): T {
	const json = jsonTryStringify(data, console.error);
	const res = jsonTryParse(json, console.error);
	return res;
}
