import { Location } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { FEATHER_ICONS_FOLDER_PATH } from './feather-icons-folder-path.token';

@Injectable({ providedIn: 'root' })
export class FeatherSvgLoader {
	constructor(@Inject(FEATHER_ICONS_FOLDER_PATH) @Optional() protected readonly iconsFolderPath: string) {}

	protected readonly cache = new Map<string, BehaviorSubject<string>>();

	get(name: string): Observable<string> {
		if (!this.cache.has(name)) {
			const svg$ = new BehaviorSubject(null);
			this.cache.set(name, svg$);
			const iconPath = this.getIconPath(name);
			from(
				fetch(iconPath).then(r => {
					if (r.status !== 200) {
						return Promise.reject(r);
					}
					return r.text();
				})
			).subscribe({
				next: svg => svg$.next(svg),
				error: err => {
					console.error(`Feather error load: ${iconPath}`);
					console.error(err);
				},
			});
		}
		const result$ = this.cache.get(name).pipe(filter(svg => Boolean(svg)));
		return result$;
	}

	protected getIconPath(name: string): string {
		return Location.joinWithSlash(this.iconsFolderPath || 'assets/icons/feather', `${name}.svg`);
	}
}
