import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IAppDefinition } from '../../db';
import { AppCardViewType } from '../app-card';
import { AppCardCommand, AppCardCommandType } from './app-card-menu-command';
import { GroupView, ISearchGroupDefinition } from './group-definition';

@Component({
	selector: 'vh-apps-search-group',
	templateUrl: './apps-search-group.component.html',
	styleUrls: ['./apps-search-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSearchGroupComponent {
	@Input()
	group: ISearchGroupDefinition;

	@Input()
	apps: IAppDefinition[];

	@Output()
	contextMenuClick = new EventEmitter<AppCardCommand>();

	readonly groupView = GroupView;

	trackByAppId(idx, app: IAppDefinition) {
		return app.id;
	}

	cardViewByGroup(groupView: GroupView) {
		return groupView === GroupView.grid ? AppCardViewType.column : AppCardViewType.row;
	}

	commandAddToQuickApps(app: IAppDefinition) {
		this.contextMenuClick.emit(new AppCardCommand(AppCardCommandType.addToQuickAppsPanel, app));
	}

	commandAddToFavoritesMenu(app: IAppDefinition) {
		this.contextMenuClick.emit(new AppCardCommand(AppCardCommandType.addToFavoritesMenu, app));
	}

	commandAddToStartupPage(app: IAppDefinition) {
		this.contextMenuClick.emit(new AppCardCommand(AppCardCommandType.addToStartupPage, app));
	}

	commandOpenApp(app: IAppDefinition) {
		this.contextMenuClick.emit(new AppCardCommand(AppCardCommandType.openApp, app));
	}
}
