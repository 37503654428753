<div
	class="vh-spaces-panel-item--name"
	#itemNameTooltip="tippy"
	[class.vh-spaces-panel-item--name-active]="isActiveRoute"
	[class.vh-spaces-panel-item--name-disabled]="item?.taskId === currentTaskId && isSpaceTree"
	[class.no-collapsable]="!isChildren(item)"
	[class.is-special-section]="item?.isSpecialSection"
	[tippy]="mobileService.mobileMode ? null : item?.name"
	(click)="onClickCategory(item)"
	[vhContextMenu]="{
		menu: item?.isSpecialSection ? null : contextMenu,
		data: {
			item: item
		}
	}"
>
	<mat-icon
		*ngIf="isChildren(item)"
		class="collapsable-arrow-icon"
		(click)="expandCollapse($event, item)"
		[svgIcon]="isOpenState ? 'vh-arrow-v1-S-down-24' : 'vh-arrow-v1-S-right-24'"
	></mat-icon>

	<span>{{ item?.name }}</span>

	<div *ngIf="item?.canCreate" class="vh-spaces-panel-item--actions">
		<button
			class="vh-spaces-panel-item--action"
			(click)="$event.stopPropagation(); addBtnClick.emit(item)"
			[tippy]="'common.spaceCreateSubpage' | resx"
			(mouseenter)="itemNameTooltip.hide()"
			(mouseleave)="itemNameTooltip.show()"
		>
			<mat-icon class="s-20" svgIcon="vh-plus-24"></mat-icon>
		</button>
	</div>
</div>

<div class="vh-spaces-panel-item--children" *ngIf="isOpenState && isChildren(item)">
	<div *ngFor="let childItem of sortChildren(item?.children)">
		<vh-spaces-panel-item
			[item]="childItem"
			[isCategory]="isCategory"
			(clickItem)="clickItem.emit($event)"
			(addBtnClick)="addBtnClick.emit($event)"
			[allOpened]="allOpened"
			[highlightActiveRoute]="highlightActiveRoute"
			[isSpaceTree]="isSpaceTree"
			[contextMenu]="contextMenu"
			[currentTaskId]="currentTaskId"
			[openStateFromInput]="openStateFromInput"
			(clickExpandCollapse)="clickExpandCollapse.emit($event)"
		></vh-spaces-panel-item>
	</div>
</div>
