import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { RouterModule } from '@angular/router';
import { LocalizationModule } from '@valhalla/localization';
import { FeatherIconsModule } from '@spa/common/feather-icons';

import { FavoriteExpandListModule } from '../favorites-panel/favorite-expand-list';
import { NavPanelAdminComponent } from './nav-panel-admin.component';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';
import { SidebarHeaderModule } from '../sidebar-header/sidebar-header.module';
import { TippyModule } from '@ngneat/helipopper';
import { NavPanelAdminItemModule } from './nav-panel-admin-item/nav-panel-admin-item.module';
import { DataSourceFormsEditModule } from '@spa/pages/data-source/data-source-grid-forms/data-source-grid-forms-edit/data-source-forms-edit.module';

@NgModule({
	imports: [
		CommonModule,
		FavoriteExpandListModule,
		RouterModule,
		MatRippleModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		LocalizationModule,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
		SidebarHeaderModule,
		FeatherIconsModule,
		TippyModule,
		NavPanelAdminItemModule,
	],
	exports: [NavPanelAdminComponent],
	declarations: [NavPanelAdminComponent],
	providers: [],
})
export class NavPanelAdminModule {}
