import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';
import { LocalizationModule } from '@valhalla/localization';
import { ControlToggleModule } from '../controls/control-toggle/control-toggle.module';
import { MaterialColorPickerCommonModule } from '../material-color-picker';

import { SidebarModule } from '../sidebar';
import { ThemeOptionsCommonComponent } from './theme-options.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,

		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatOptionModule,
		MatRadioModule,
		MatSelectModule,
		MatCheckboxModule,

		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
		MaterialColorPickerCommonModule,
		SidebarModule,
		LocalizationModule,
		ControlToggleModule,
	],
	declarations: [ThemeOptionsCommonComponent],
	exports: [ThemeOptionsCommonComponent],
})
export class ThemeOptionsCommonModule {}
