export enum IAvatarColorMap {
	basePrimary = 'var(--base-primary)',
	extramarkersRed = 'var(--extramarkers-red)',
	extramarkersPink = 'var(--extramarkers-pink)',
	extramarkersPurple = 'var(--extramarkers-purple)',
	extramarkersBlue = 'var(--extramarkers-blue)',
	extramarkersCyan = 'var(--extramarkers-cyan)',
	extramarkersGreen = 'var(--extramarkers-green)',
	extramarkersYellow = 'var(--extramarkers-yellow)',
	extramarkersOrange = 'var(--extramarkers-orange)',
	extramarkersBrown = 'var(--extramarkers-brown)',
	extramarkersGrey = 'var(--extramarkers-grey)',
}

export enum IAvatarColor {
	basePrimary = 'basePrimary',
	extramarkersRed = 'extramarkersRed',
	extramarkersPink = 'extramarkersPink',
	extramarkersPurple = 'extramarkersPurple',
	extramarkersBlue = 'extramarkersBlue',
	extramarkersCyan = 'extramarkersCyan',
	extramarkersGreen = 'extramarkersGreen',
	extramarkersYellow = 'extramarkersYellow',
	extramarkersOrange = 'extramarkersOrange',
	extramarkersBrown = 'extramarkersBrown',
	extramarkersGrey = 'extramarkersGrey',
}

export enum IAvatarContainerColorMap {
	basePrimary = 'var(--container-primary)',
	extramarkersRed = 'var(--extrasurface-red)',
	extramarkersPink = 'var(--extrasurface-pink)',
	extramarkersPurple = 'var(--extrasurface-purple)',
	extramarkersBlue = 'var(--extrasurface-blue)',
	extramarkersCyan = 'var(--extrasurface-cyan)',
	extramarkersGreen = 'var(--extrasurface-green)',
	extramarkersYellow = 'var(--extrasurface-yellow)',
	extramarkersOrange = 'var(--extrasurface-orange)',
	extramarkersBrown = 'var(--extrasurface-brown)',
	extramarkersGrey = 'var(--extrasurface-grey)',
}

export enum IAvatarContainerTextColorMap {
	basePrimary = 'var(--oncontainer-primary)',
	extramarkersRed = 'var(--extraonsurface-red)',
	extramarkersPink = 'var(--extraonsurface-pink)',
	extramarkersPurple = 'var(--extraonsurface-purple)',
	extramarkersBlue = 'var(--extraonsurface-blue)',
	extramarkersCyan = 'var(--extraonsurface-cyan)',
	extramarkersGreen = 'var(--extraonsurface-green)',
	extramarkersYellow = 'var(--extraonsurface-yellow)',
	extramarkersOrange = 'var(--extraonsurface-orange)',
	extramarkersBrown = 'var(--extraonsurface-brown)',
	extramarkersGrey = 'var(--extraonsurface-grey)',
}

export enum AvatarType {
	icon = 'icon',
	image = 'image',
	text = 'text',
}

export enum AvatarEmphasis {
	accent = 'accent',
	container = 'container',
}

export enum AvatarSizeMap {
	xxs = 'var(--space-300)',
	xs = 'var(--space-400)',
	s = 'var(--space-500)',
	md = 'calc(var(--space-500) + var(--space-200)',
	l = 'calc(var(--space-500) + var(--space-400)',
	xl = 'calc(var(--space-1000) + var(--space-200)',
	xxl = 'calc(var(--space-1000) + var(--space-600)',
}

export enum AvatarSize {
	xxs = 'xxs',
	xs = 'xs',
	s = 's',
	md = 'md',
	l = 'l',
	xl = 'xl',
	xxl = 'xxl',
}

export enum AvatarPaddingMap {
	xxs = 'var(--space-05)',
	xs = 'var(--space-100)',
	s = 'var(--space-100)',
	md = 'var(--space-150)',
	l = 'var(--space-200)',
	xl = 'var(--space-300)',
	xxl = 'var(--space-400)',
}

export enum AvatarBorderRadiusMap {
	xxs = 'var(--radius-075)',
	xs = 'var(--radius-100)',
	s = 'var(--radius-150)',
	md = 'var(--radius-200)',
	l = 'var(--radius-250)',
	xl = 'var(--radius-300)',
	xxl = 'var(--radius-400, 32px)',
}
