import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatSideNavHelperService } from '@spa/common/directives';
import { PlatformDetectorProvider } from '@spa/core';
import { ITaskInfo, IUser } from '@spa/data/entities';
import { DataHttpService } from '@spa/data/http';
import { BehaviorSubject, first, map, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { MobileViewService } from '@spa/common/services/mobile-view.service';

@Injectable({ providedIn: 'root' })
export class ChatHelperService {
	constructor(
		readonly server: DataHttpService,
		readonly zone: NgZone,
		readonly platform: PlatformDetectorProvider,
		readonly router: Router,
		readonly sideNav: MatSideNavHelperService,
		readonly mobileView: MobileViewService
	) {
		this.initialLoadTaskId = new URLSearchParams(window.location.search).get('taskId');
	}

	protected appConfig$ = this.server.config.getAppConfiguration();

	protected readonly closePreviewTaskEvent$ = new Subject<{ taskId: number }>();

	readonly closePreviewTask$ = this.closePreviewTaskEvent$.asObservable();
	readonly startChatWithContact$ = new BehaviorSubject<Partial<IUser>>(null);
	readonly chatWithContactCreated$ = new Subject<any>();
	readonly startNewChatWithContactActive$ = this.startChatWithContact$.pipe(map(Boolean));
	readonly exitFromChat$ = new BehaviorSubject<boolean>(null);
	readonly initialLoadTaskId: string;

	closePreviewTask(taskId: number) {
		if (taskId > 0) {
			this.closePreviewTaskEvent$.next({ taskId });
		}
	}

	startNewChatWithContact(user: Partial<IUser>) {
		if (!user?.userId) {
			return;
		}
		this.zone.runTask(() => {
			this.chatWithContactCreated$.next(null);
			this.startChatWithContact$.next(user);
		});
		if (this.platform.isMobile()) {
			this.closeLeftSideNav();

			this.router.navigate([], {
				queryParams: { newChat: true },
				queryParamsHandling: 'merge',
			});

			this.mobileView.backClick$.pipe(first(), takeUntil(this.chatWithContactCreated$.pipe(first()))).subscribe(_ => {
				this.openLeftSideNav();
			});
		}
		return this.chatWithContactCreated$.pipe(
			first(),
			tap(_ => {
				this.router.navigate([], {
					queryParams: { newChat: null },
					queryParamsHandling: 'merge',
					replaceUrl: true,
				});
			})
		);
	}

	cancelStartNewChat() {
		this.chatWithContactCreated$.next(null);
		this.startChatWithContact$.next(null);
	}

	isPrivateChatSync(subcatIdOrTask: number | Partial<ITaskInfo>) {
		return this.server.config.appConfig.chatSubcatId === this.getSubcatId(subcatIdOrTask);
	}

	isPrivateChat(subcatIdOrTask: number | Partial<ITaskInfo>): Observable<boolean> {
		return this.isPrivateChatFn().pipe(map(fn => fn(subcatIdOrTask)));
	}

	isGroupChat(subcatIdOrTask: number | Partial<ITaskInfo>): Observable<boolean> {
		return this.isGroupChatFn().pipe(map(fn => fn(subcatIdOrTask)));
	}

	isPrivateOrGroupChat(subcatIdOrTask: number | Partial<ITaskInfo>) {
		return this.isPrivateOrGroupChatFn().pipe(map(fn => fn(subcatIdOrTask)));
	}

	isPrivateChatFn() {
		return this.appConfig$.pipe(
			map(({ privateSubcatId, chatSubcatId }) => {
				return (subcatIdOrTask: number | Partial<ITaskInfo>) => {
					if (!subcatIdOrTask) {
						return false;
					}
					const subcatId = this.getSubcatId(subcatIdOrTask);
					return chatSubcatId === subcatId;
				};
			})
		);
	}

	isGroupChatFn() {
		return this.appConfig$.pipe(
			map(({ groupChatSubCatId }) => {
				return (subcatIdOrTask: number | Partial<ITaskInfo>) => {
					if (!subcatIdOrTask) {
						return false;
					}
					const subcatId = this.getSubcatId(subcatIdOrTask);
					return groupChatSubCatId === subcatId;
				};
			})
		);
	}

	isPrivateOrGroupChatFn() {
		return this.appConfig$.pipe(
			map(({ privateSubcatId, groupChatSubCatId }) => {
				return (subcatIdOrTask: number | Partial<ITaskInfo>) => {
					if (!subcatIdOrTask) {
						return false;
					}
					const subcatId = this.getSubcatId(subcatIdOrTask);
					return [privateSubcatId, groupChatSubCatId].includes(subcatId);
				};
			})
		);
	}

	openLeftSideNav() {
		this.sideNav.getSidenav('chat-left-sidenav')?.open();
		this.mobileView.hideTabbar = false;
	}

	closeLeftSideNav() {
		this.sideNav.getSidenav('chat-left-sidenav')?.close();
		this.mobileView.hideTabbar = true;
	}

	protected getSubcatId(subcatIdOrTask: number | Partial<ITaskInfo>) {
		const subcatId =
			typeof subcatIdOrTask === 'number' ? subcatIdOrTask : subcatIdOrTask.subcatId || subcatIdOrTask.subcatID;
		return subcatId;
	}
}
