import { Injectable, NgZone, Optional } from '@angular/core';
import { jsonTryParse, jsonTryStringify, merge, RecursivePartial } from '@valhalla/utils';
import { AbstractLogger, LoggerFactory } from '../../diagnostics/logger';
import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { LocalStorageProvider } from './abstract';

@Injectable()
export class LocalStorageProviderImpl implements LocalStorageProvider {
	constructor(logger: LoggerFactory, @Optional() protected zone?: NgZone) {
		this._logger = logger.createLogger('LocalStorageProvider');
		this._storage = (window && window.localStorage) || this._getMockStorage();
		if (zone) {
			zone.runOutsideAngular(() => this._subscribeStorageEvent());
		} else {
			this._subscribeStorageEvent();
		}
	}
	private readonly _logger: AbstractLogger;
	private readonly _streams = new Map<string, Subject<any>>();
	private readonly _storage: Storage;

	get length() {
		return this._storage.length;
	}

	get keys() {
		return Object.getOwnPropertyNames(this._storage);
	}

	set<T = any>(key: string, value: T, asyncEventEmit = false) {
		this._checkKey(key);
		const json = this.toJson(value);
		try {
			this._storage.setItem(key, json);
		} catch (error) {
			console.error(error);
		}
		if (this.zone) {
			this.zone.runOutsideAngular(() => this._fireStreams(key, value, asyncEventEmit));
		} else {
			this._fireStreams(key, value, asyncEventEmit);
		}
		return this as any as LocalStorageProvider;
	}

	patchValue<T extends Object = any>(key: string, value: RecursivePartial<T>): T {
		const currentValue = this.get<T>(key);
		if (!value || typeof value !== 'object') {
			return currentValue;
		}
		const newValue = merge(currentValue, value);
		this.set(key, newValue);
		return newValue;
	}

	get<T = any>(key: string): T {
		this._checkKey(key);
		const json = this._storage.getItem(key);
		const value = this.fromJson<T>(json);
		return value;
	}

	select<T = any>(key: string): Observable<T> {
		this._checkKey(key);
		if (!this._streams.has(key)) {
			this._streams.set(key, new Subject());
		}
		const stream = this._streams.get(key);
		return stream.pipe(startWith(this.get(key)));
	}

	clear() {
		this._storage.clear();
		this._logger.forceLogInfo('clear all local storage data');
		this._streams.forEach((stream, key) => {
			stream.next(undefined);
		});
	}

	remove(...keys: string[]) {
		keys = keys || [];
		keys.forEach(key => {
			this._storage.removeItem(key);
			const stream = this._streams.get(key);
			if (stream) {
				stream.next(undefined);
			}
		});
	}

	destroy() {
		const streams = Array.from(this._streams.values());
		for (const stream of streams) {
			stream.complete();
		}
		this._streams.clear();
	}

	protected toJson(value): string {
		return jsonTryStringify(value, err =>
			this._logger.error({
				method: 'toJson',
				value: value,
				error: err,
			})
		);
	}

	protected fromJson<T = any>(json: string): T {
		return jsonTryParse<T>(json, err =>
			this._logger.error({
				method: 'fromJson',
				value: json,
				error: err,
			})
		);
	}

	private _subscribeStorageEvent() {
		if (!window || !window.addEventListener) {
			return;
		}
		window.addEventListener(
			'storage',
			e => {
				if (this._streams.has(e.key)) {
					this._fireStreams(e.key, this.fromJson(e.newValue));
				}
			},
			false
		);
	}

	private _checkKey(key: string) {
		if (!key) {
			throw new Error('key must not be empty!');
		}
	}

	private _fireStreams(key: string, value, async = false) {
		const stream = this._streams.get(key);
		if (stream) {
			if (async) {
				setTimeout(() => stream.next(value));
			} else {
				stream.next(value);
			}
		}
	}

	private _getMockStorage(): Storage {
		this._logger.warn('localStorage is not supported, setup using mock storage');
		return {
			clear() {},
			getItem(key: string) {
				return undefined;
			},
			key(index: number) {
				return undefined;
			},
			length: 0,
			removeItem(key: string) {},
			setItem(key: string, value) {},
		};
	}
}
