import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatRippleModule } from '@angular/material/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import { ChatPanelComponent } from './chat-panel.component';
import { ChatPanelService } from './chat-panel.service';
import { FormsModule } from '@angular/forms';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';
import { TippyModule } from '@ngneat/helipopper';

@NgModule({
	declarations: [ChatPanelComponent],
	providers: [ChatPanelService],
	imports: [
		CommonModule,

		FormsModule,

		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatTabsModule,
		MatRippleModule,

		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
		TippyModule,
	],
	exports: [ChatPanelComponent],
})
export class ChatPanelModule {}
