import { ImgViewerComponent } from './extensions/img/img-viewer.component';
import { MsgViewerComponent } from './extensions/msg/msg-viewer.component';
import { PdfViewerComponent } from './extensions/pdf/pdf-viewer.component';
import { TxtViewerComponent } from './extensions/txt/txt-viewer.component';
import { MimeType, getFileExtension } from '@valhalla/data/entities';
import { VideoViewerComponent } from './extensions/video/video-viewer.component';
import { OfficeViewerComponent } from './extensions/office/office-viewer.component';
import { AudioViewerComponent } from '@spa/common/components/file-viewer/extensions/audio/audio-viewer.component';
import { EmailViewerComponent } from './extensions/email/email-viewer.component';

export const ENCODINGS: { name: string; value: string }[] = [
	{ name: 'UTF-8', value: 'UTF-8' },
	{ name: 'WINDOWS-1251', value: 'WINDOWS-1251' },
	{ name: 'UTF-16', value: 'UTF-16' },
	{ name: 'KOI8-R', value: 'KOI8-R' },
];

export const DEFAULT_TXT_LANGUAGE = 'plaintext';

export const TXT_EXT_TO_LANGUAGES = {
	abap: 'abap',
	aes: 'aes',
	apex: 'apex',
	azcli: 'azcli',
	bat: 'bat',
	cmd: 'bat',
	bicep: 'bicep',
	c: 'c',
	cameligo: 'cameligo',
	clojure: 'clojure',
	coffeescript: 'coffeescript',
	cpp: 'cpp',
	cs: 'csharp',
	csp: 'csp',
	css: 'css',
	conf: 'plaintext',
	config: 'plaintext',
	log: 'plaintext',
	dart: 'dart',
	dockerfile: 'dockerfile',
	ecl: 'ecl',
	elixir: 'elixir',
	flow9: 'flow9',
	freemarker2: 'freemarker2',
	fsharp: 'fsharp',
	go: 'go',
	graphql: 'graphql',
	handlebars: 'handlebars',
	hcl: 'hcl',
	html: 'html',
	ini: 'ini',
	java: 'java',
	js: 'javascript',
	json: 'json',
	julia: 'julia',
	kotlin: 'kotlin',
	less: 'less',
	lexon: 'lexon',
	liquid: 'liquid',
	lua: 'lua',
	m3: 'm3',
	md: 'markdown',
	markdown: 'markdown',
	mips: 'mips',
	msdax: 'msdax',
	mysql: 'mysql',
	pascal: 'pascal',
	pascaligo: 'pascaligo',
	perl: 'perl',
	pgsql: 'pgsql',
	php: 'php',
	pla: 'pla',
	txt: 'plaintext',
	postiats: 'postiats',
	powerquery: 'powerquery',
	ps: 'powershell',
	ps1: 'powershell',
	proto: 'proto',
	pug: 'pug',
	py: 'python',
	qsharp: 'qsharp',
	r: 'r',
	razor: 'razor',
	cshtml: 'razor',
	vbhtml: 'razor',
	redis: 'redis',
	redshift: 'redshift',
	restructuredtext: 'restructuredtext',
	ruby: 'ruby',
	rust: 'rust',
	sb: 'sb',
	scala: 'scala',
	scheme: 'scheme',
	scss: 'scss',
	sh: 'shell',
	sol: 'sol',
	sparql: 'sparql',
	sql: 'sql',
	st: 'st',
	swift: 'swift',
	systemverilog: 'systemverilog',
	tcl: 'tcl',
	twig: 'twig',
	ts: 'typescript',
	vb: 'vb',
	verilog: 'verilog',
	xml: 'xml',
	yaml: 'yaml',
	csv: 'csv',
};

export const OFFICE_EXTENSIONS = [
	'doc',
	'dot',
	'wbk',
	'docx',
	'docm',
	'dotx',
	'dotm',
	'docb',
	'xls',
	'xlt',
	'xlm',
	'xlsx',
	'xlsm',
	'xltx',
	'xltm',
	'xlsb',
	'xla',
	'xlam',
	'xll',
	'xlw',
	'ppt',
	'pot',
	'pps',
	'pptx',
	'pptm',
	'potx',
	'potm',
	'ppam',
	'ppsx',
	'ppsm',
	'sldx',
	'sldm',
	'vsd',
	'vss',
	'vst',
	'vsw',
	'vdx',
	'vsx',
	'vtx',
	'vsdx',
	'vsdm',
	'vssx',
	'vssm',
	'vstx',
	'vstm',
	'vsl',
	'accdb',
	'accde',
	'accdt',
	'accdr',
	'pub',
	'mpp',
	'mpt',
	'mpx',
	'mpd',
	'mdb',
	'odbc',
	'infopathxml',
	'xsf',
	'xsn',
	'xtp2',
	'xps',
	'dwt',
	'fwp',
	'htc',
	'htt',
	'htx',
	'spdesignasax',
	'spdesignasmx',
	'spdesignbot',
	'spdesignconfig',
	'spdesigncss',
	'spdesigndoclib',
	'spdesignedited',
	'spdesignhomeop',
	'spdesignhomepg',
	'spdesignhtml',
	'spdesignhtt',
	'spdesignjs',
	'spdesignlinkbar',
	'spdesignlist',
	'spdesignlistdata',
	'spdesignmaster',
	'spdesignnav',
	'spdesignnopub',
	'spdesignopen',
	'spdesignpiclib',
	'spdesignresx',
	'spdesignshtm',
	'spdesignshtml',
	'spdesignsitemap',
	'spdesignskin',
	'spdesignstm',
	'spdesignsurvey',
	'spdesigntheme',
	'spdesigntxt',
	'spdesignunknown',
	'spdesignweb',
	'spdesignwfdoclib',
	'spdesignxml',
	'spdesignxoml',
	'spdesignxsl',
	'spdesignxslt',
];

export const IMAGE_EXTENSIONS = ['jpeg', 'jpg', 'png', 'webp', 'svg', 'gif'];

export const PDF_EXTENSIONS = ['pdf'];

export const MSG_EXTENSIONS = ['msg'];

export const EMAIL_EXTENSIONS = ['eml'];

function getTxtExtensions() {
	const result = [];
	for (const key in TXT_EXT_TO_LANGUAGES) {
		result.push(key);
	}
	return result;
}

export const VIEWERS = [
	{
		ext: PDF_EXTENSIONS,
		mime: [MimeType.pdf],
		viewer: PdfViewerComponent,
	},
	{
		ext: getTxtExtensions(),
		mime: [MimeType.text],
		viewer: TxtViewerComponent,
	},
	{
		ext: MSG_EXTENSIONS,
		viewer: MsgViewerComponent,
	},
	{
		ext: EMAIL_EXTENSIONS,
		viewer: EmailViewerComponent,
	},
	{
		ext: IMAGE_EXTENSIONS,
		mime: [MimeType.jpeg, MimeType.gif, MimeType.png, MimeType.svg],
		viewer: ImgViewerComponent,
	},
	{
		ext: ['mp4'],
		mime: [MimeType.videoMp4, MimeType.videoWebm, MimeType.videoOGG, MimeType.videoQuickTime],
		viewer: VideoViewerComponent,
	},
	{
		ext: OFFICE_EXTENSIONS,
		mime: [MimeType.word, MimeType.word2, MimeType.excel],
		viewer: OfficeViewerComponent,
	},
	{
		ext: ['mp3'],
		mime: [MimeType.audioMpeg, MimeType.audioOGG, MimeType.audioMp4, MimeType.audioAAC],
		viewer: AudioViewerComponent,
	},
];

const IMG_EXT_INDEX = IMAGE_EXTENSIONS.reduce<Record<string, boolean>>((acc, cur) => {
	acc[cur] = true;
	return acc;
}, {});
export function isImageExtension(name: string) {
	const ext = getFileExtension(name)?.toLowerCase();
	return !!IMG_EXT_INDEX[ext];
}
