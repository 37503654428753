import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
	selector: 'vh-common-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalCommonComponent implements OnInit {
	constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<ConfirmModalCommonComponent>) {}

	@Output()
	confirm = new EventEmitter<MatDialogRef<ConfirmModalCommonComponent>>();
	@Output()
	refuse = new EventEmitter<MatDialogRef<ConfirmModalCommonComponent>>();
	@Output()
	cancel = new EventEmitter<MatDialogRef<ConfirmModalCommonComponent>>();

	confirmTitle: string;
	confirmMessage: string;
	confirmButtonLabel: string;
	cancelButtonLabel: string;

	ngOnInit() {
		this.confirmTitle = this.data.confirmTitle;
		this.confirmMessage = this.data.confirmMessage;
		this.confirmButtonLabel = this.data.confirmButtonLabel;
		this.cancelButtonLabel = this.data.cancelButtonLabel;
		this.dialogRef.afterClosed().subscribe(result => {
			this.cancel.emit(result);
		});
	}

	onConfirmEvent() {
		this.confirm.emit(this.dialogRef);
	}

	onRefuseEvent() {
		this.refuse.emit(this.dialogRef);
	}

	onCancelEvent() {
		this.cancel.emit(this.dialogRef);
	}
}
