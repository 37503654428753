import { ModuleWithProviders, NgModule } from '@angular/core';

import { KeyValueIDBStorage } from './abstract';
import { KeyValueIDBStorageImpl } from './key-value-idb-storage.provider';

@NgModule({})
export class KeyValueIDBStorageModule {
	static forRoot(): ModuleWithProviders<KeyValueIDBStorageModule> {
		return {
			ngModule: KeyValueIDBStorageModule,
			providers: [
				{
					provide: KeyValueIDBStorage,
					useClass: KeyValueIDBStorageImpl,
				},
			],
		};
	}
}
