import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyMenu as MatMenu, MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ViewDestroyStreamService } from '@spa/core';
import { takeUntil } from 'rxjs';
import { OutsideClickDirective } from '../../directives/outside-click.directive';

@Component({
	selector: 'vh-auto-open-menu',
	templateUrl: './auto-open-menu.component.html',
	styleUrls: ['./auto-open-menu.component.scss'],
	providers: [ViewDestroyStreamService],
})
export class AutoOpenMenuComponent implements OnInit {
	constructor(readonly destro$: ViewDestroyStreamService, readonly elRef: ElementRef<HTMLElement>) {}

	@Input()
	matMenu: MatMenu;

	@Input()
	matMenuClass: string;

	@Input()
	timeout = 200;

	@Input()
	closeOnClick = false;

	@Input()
	clickMode = false;

	@Input()
	enabled = true;

	@Input()
	stopPropagateOnClick = false;

	@Output()
	menuOpened = new EventEmitter<any>();

	@HostBinding('class.vh-auto-open-menu')
	hostClassSelector = true;

	@ViewChild(MatMenuTrigger)
	menuTrigger: MatMenuTrigger;

	protected timedOutCloser: any;
	protected outsideClickDirective: OutsideClickDirective;
	currentTrigger: MatMenuTrigger;

	get isOpen() {
		return this.currentTrigger?.menuOpen;
	}

	blockClose = false;

	ngOnInit(): void {
		this.outsideClickDirective = new OutsideClickDirective(this.elRef, this.destro$);
		this.outsideClickDirective.outsideClick.pipe(takeUntil(this.destro$)).subscribe(() => {
			if (this.currentTrigger) {
				this.close(this.currentTrigger);
			}
		});
	}

	mouseEnter(trigger: MatMenuTrigger) {
		if (!this.clickMode || this.currentTrigger) {
			this.open(trigger);
		}
	}

	mouseLeave(trigger: MatMenuTrigger) {
		if (!this.clickMode) {
			this.close(trigger);
		}
	}

	onClick(trigger: MatMenuTrigger, e?: PointerEvent) {
		if (this.stopPropagateOnClick) {
			// e?.stopImmediatePropagation();
			e?.stopPropagation();
		}
		if (this.currentTrigger) {
			return;
		}
		if (this.clickMode) {
			this.open(trigger);
		}
	}

	onMenuContentClick(e: Event) {
		if (!this.closeOnClick) {
			e.stopPropagation();
		}
	}

	open(trigger: MatMenuTrigger) {
		if (!this.enabled) {
			return;
		}
		if (this.timedOutCloser) {
			clearTimeout(this.timedOutCloser);
			this.timedOutCloser = null;
		}
		trigger?.openMenu();
		if (!this.clickMode) {
			this.currentTrigger = trigger;
		}
	}

	close(trigger: MatMenuTrigger) {
		if (this.blockClose) {
			return;
		}
		this.timedOutCloser = setTimeout(() => {
			trigger?.closeMenu();
			this.currentTrigger = null;
		}, this.timeout || 200);
	}

	closeMenu() {
		this.menuTrigger.closeMenu();
	}
}
