import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationRef, Injector, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import {
	MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions,
	MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
} from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxdModule } from '@ngxd/core';
import { registerSpaLinkTag } from '@spa/built-in-dom-elements-extend/a-tag';
import { setCurrentInjector } from '@spa/common/injector-context';
import { environment } from '@spa/environment';
import { UpdateTickersByRequestsInterceptorProvider } from '@spa/facade/features/tickers/update-tickers-by-requests-interceptor';
import { UserSettingsModule } from '@spa/facade/features/user-settings';
import { AuthService, CoreModule } from '@valhalla/core';
import { DataHttpModule, DataHttpService } from '@valhalla/data/http';
import { DataSignalrModule } from '@valhalla/data/signalr';
import { LocalizationModule } from '@valhalla/localization';
import { debounceTime, filter, switchMap, take } from 'rxjs/operators';
import { LicenseManagerModule } from '../license-manager';
import { RoutingModule } from '../routing';
import { BootstrapComponent } from './bootstrap.component';
import { LocaleProvider } from './lazy-ng-locale';
import { PostBootstrapService } from './post-bootstrap.service';
import { resourceProviders } from './resource-providers';
import { TippyModule, tooltipVariation, popperVariation } from '@ngneat/helipopper';
import { NgxMatomoTrackerModule, MatomoInitializationMode } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { CronModule } from '@spa/cron';
import { ThemeService } from '@spa/common/services/theme-service';
import { EMPTY } from 'rxjs';
import { booleanFilter } from '@valhalla/utils';
import { CustomResourcesService } from './custom-resources.service';
import { initializeApi } from '@spa/api';

if (!environment.production) {
	(window as any).__IS_DEV__ = true;
}

registerSpaLinkTag();

@NgModule({
	imports: [
		BrowserModule,
		// NoopAnimationsModule,
		BrowserAnimationsModule,
		ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
		HttpClientModule,
		DataHttpModule.forRoot(),
		DataSignalrModule,
		CoreModule.withConfig({ isProduction: environment.production }),
		RoutingModule,
		NgxdModule,
		NgxMatNativeDateModule,
		LocalizationModule.forRoot({
			refreshOnChangeLanguage: true,
		}),
		UserSettingsModule.forRoot(),
		MatMomentDateModule,
		MatNativeDateModule,
		LicenseManagerModule.forRoot(),
		TippyModule.forRoot({
			defaultVariation: 'tooltip',
			variations: {
				tooltip: {
					...tooltipVariation,
					arrow: true,
					animation: 'fade',
				},
				popper: popperVariation,
			},
		}),
		NgxMatomoTrackerModule.forRoot({
			mode: MatomoInitializationMode.AUTO_DEFERRED,
		}),
		NgxMatomoRouterModule.forRoot(),
		CronModule.forRoot(),
	],
	declarations: [BootstrapComponent],
	bootstrap: [BootstrapComponent],
	providers: [
		...resourceProviders,
		PostBootstrapService,
		...LocaleProvider,
		UpdateTickersByRequestsInterceptorProvider,
		// {
		// 	provide: API_ORIGIN,
		// 	useValue: 'https://kama.1forma.ru/',
		// },
		{
			provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
			useFactory: () => {
				const opts: MatTooltipDefaultOptions = {
					disableTooltipInteractivity: true,
					showDelay: 0,
					hideDelay: 0,
					touchendHideDelay: 1500,
				};
				return opts;
			},
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (ds: ThemeService) => () => ds.loadStyle(),
			deps: [ThemeService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (server: DataHttpService) => () => server.config.appSettingsAnonymousConfig$.pipe(take(1)),
			deps: [DataHttpService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (srv: CustomResourcesService) => () => srv.init(),
			deps: [CustomResourcesService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (server: DataHttpService, auth: AuthService) => () =>
				auth.authenticated$.pipe(
					take(1),
					switchMap(isAuth => {
						if (isAuth) {
							return server.config.appConfig$.pipe(take(1));
						} else {
							auth.authenticated$
								.pipe(
									booleanFilter(),
									take(1),
									switchMap(() => server.config.appConfig$.pipe(take(1)))
								)
								.subscribe();
						}
						return EMPTY;
					})
				),
			deps: [DataHttpService, AuthService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (injector: Injector) => () => initializeApi(injector),
			deps: [Injector],
			multi: true,
		},
	],
})
export class BootstrapModule {
	constructor(router: Router, app: ApplicationRef, injector: Injector) {
		setCurrentInjector(injector);
		router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				debounceTime(50)
			)
			.subscribe(() => {
				app.tick();
			});
	}
}
