import { MediaBreakpoint } from '@valhalla/core';

import { NamedEntity } from './entity';
import { IFlexLayoutContainer, IFlexLayoutElement } from './flex-layout';
import { IFilterData } from './filter';

export interface IPortalTemplateData {
	id: number;
	guid: string;
	name: string;
	gridType: PortalLayoutType;
	userId: number;
	templateJson: string;
	systemTemplateId: string;
	isFixedMode: boolean;
	isCustomerZone: boolean;
	mesh: Partial<IFlexLayoutContainer<Partial<IPortalWidgetFlexData>>>;
	dashboard?: IPortalDashboardData;
	userDashboard?: IPortalDashboardData;
	showHeader: boolean;
}

export interface IPortalDashboardData<WidgetType = any, OptionsType = any>
	extends IDashboardGridSettings<WidgetType, OptionsType> {
	edited?: boolean;
	responsive?: Partial<Record<MediaBreakpoint, IDashboardGridSettings>>;
	type?: string;
	board?: any;
}

export interface IDashboardGridSettings<WidgetType = any, OptionsType = any> {
	options?: OptionsType;
	widgets?: Array<WidgetType>;
}

export interface IPortalBlockInfo {
	blockId: number;
	templateId: number;
	name: string;
	subHeader: string;
	userId: number;
	type: number;
	isRequired: boolean;
	isEnabled: boolean;
	headerColor: string;
	icon: string;
	footerText: string;
	footerLink: string;
	showFooter: boolean;
	isFilterHidden: boolean;
	isHideableByUser: boolean;
	isHeaderHidden: boolean;
	startPageBlockId: number;
	typeParams: string;
	typeParamsData: any;
	filterData: IFilterData;
	canExpand?: boolean;
	guid: string;
	contextType: number;
	/** mustache template */
	templateValue: string;
	cssIncludesIds?: number[];
	jsIncludesIds?: number[];
	showBack?: boolean;
	isAdmin?: boolean;
	widgetNameType?: string;
	color?: string;
	widgetColorMode: IWidgetColorMode;
}

export enum IWidgetColorMode {
	color = 'Color',
	default = 'Default',
	transparent = 'Transparent',
}

export enum PortalLayoutType {
	flex = 'Flex',
	dashboard = 'Dashboard',
}

export interface IWebResourceInclude {
	content?: string;
	id: number;
	name: string;
	type: IncludeType;
}

export enum IncludeType {
	css = 'Css',
	js = 'Js',
	jsUrl = 'JsUrl',
}

export interface IPortalWidgetFlexData extends IFlexLayoutElement {
	blockId: number;
}

export enum PortalBlockType {
	unknown = 'unknown',
	buttons = 0,
	html = 1,
	subcat = 2,
	users = 3,
	statistics = 4,
	smartBlock = 5,
	workspaceButtons = 10,
	signatures = 12,
	tasksFromFavorites = 13,
	favoritesTree = 14,
	workspaceTree = 15,
	task = 16,
	mailBoxFolder = 17,
	chart = 18,
	tasksSearch = 19,
	feed = 20,
	menu = 21,
	table = 22,
	report = 23,
	pivot = 29,
	gantt = 30,
	news = 31,
	frame = 32,
	smartHtml = 33,
	calendar = 34,
	wizard = 35,
}

export interface IPortalFolder {
	folderId: number;
	name: string;
	blocks: Array<NamedEntity>;
}
