import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { DataHttpCommonService } from '../data-http-common.service';
import { IGetTaskHierarchyDto, ITaskHierarchyNode, ITaskHierarchySettings, TaskHierarhyHttpService } from './abstract';
import { IApiResponse } from '../api-response';
import { catchError, map } from 'rxjs/operators';
import ApiVersion from '../api-versions';
import { IFilterData } from '@spa/data/entities';

@Injectable()
export class TaskHierarchyHttpServiceImpl implements TaskHierarhyHttpService {
	constructor(public readonly http: HttpClient, public readonly common: DataHttpCommonService) {}

	getConfig(criteria: IGetTaskHierarchyDto): Observable<ITaskHierarchySettings> {
		const url = this.common.getApiUrl(`tasksHierarchies/${criteria.id}`, ApiVersion.v12);

		return this.http.post<ITaskHierarchySettings>(url, criteria).pipe(
			map(result => result),
			catchError(err => {
				console.error(err);
				return of(null);
			})
		);
	}

	getData(criteria: IGetTaskHierarchyDto): Observable<ITaskHierarchyNode[]> {
		const url = this.common.getApiUrl(`tasksHierarchies/${criteria.id}/nodes`, ApiVersion.v12);

		return this.http.post<ITaskHierarchyNode[]>(url, criteria);
	}

	getFilters(hierarchyId: number): Observable<IFilterData> {
		const url = this.common.getApiUrl(`filters/config/tasksHierarchy/${hierarchyId}`, ApiVersion.v10);

		return this.http.get<IFilterData>(url).pipe(
			map(result => result),
			catchError(err => {
				console.error(err);
				return of(null);
			})
		);
	}
}
