import { Injectable } from '@angular/core';

import { IModalContentAreaComponent } from './modal-content-area.component-base';

@Injectable({ providedIn: 'root' })
export class ModalContentAreaRegisterService {
	protected components = new Map<string, IModalContentAreaComponent>();

	register(component: IModalContentAreaComponent) {
		if (!component || !component.name) {
			return;
		}

		if (this.components.has(component.name)) {
			return console.error(`${component.name} already registered in ModalContentAreaRegisterService!`);
		}

		this.components.set(component.name, component);
	}

	unregister(name: string) {
		this.components.delete(name);
	}

	get(name: string): IModalContentAreaComponent {
		const cmp = this.components.get(name);
		if (!cmp) {
			// tslint:disable-next-line:no-console
			console.warn(`vh-modal-content-area component with name '${name}' is not registered`);
		}
		return cmp;
	}
}
