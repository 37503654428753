import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RedirectNotFoundResolver {
	resolve(): Observable<string> {
		return of(location.pathname + location.search);
	}
}
