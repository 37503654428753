import * as common from './common';

export default {
	red: {
		50: '#ffebee',
		100: '#ffcdd2',
		200: '#ef9a9a',
		300: '#e57373',
		400: '#ef5350',
		500: '#f44336',
		600: '#e53935',
		700: '#d32f2f',
		800: '#c62828',
		900: '#b71c1c',
		A100: '#ff8a80',
		A200: '#ff5252',
		A400: '#ff1744',
		A700: '#d50000',
		contrast: {
			50: common.black87,
			100: common.black87,
			200: common.black87,
			300: common.black87,
			400: common.black87,
			500: 'white',
			600: 'white',
			700: 'white',
			800: common.white87,
			900: common.white87,
			A100: common.black87,
			A200: 'white',
			A400: 'white',
			A700: 'white',
		},
	},
};
