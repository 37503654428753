import {
	ActionRedirectFixType,
	ActionRedirectType,
} from '@spa/components/task/ui/task-card/task-card-system-params/abstract';

export interface ConsisImplementOptions {
	consisImplementEnable: boolean;
	consisImplementDefaultSubcat: number;
	consisImplementRestricSubcats: string;
	consisImplementDisableAlerts: boolean;
	consisImplementFinishMainTask: boolean;
}

export interface IResourceManagementOptions {
	resourceManagementEnabled: boolean;
	planningMode: string;
	needAcceptPlannedResources: boolean;
	needAcceptPlannedPerformer: boolean;
	performerDefaultResourceId: number;
	syncPlans: boolean;
	resourcesVisibilitySmartExpressionId: number;
	disablePlansAutocalculation: boolean;
	adjustAbsoultePlanToManual: boolean;
}

export interface IAutomationOptions {
	newTaskSPName: string;
	saveTaskSPName: string;
	stepSignsFromSysRobot: boolean;
	defaultCopySubscribers: boolean;
	subtasksOnly: boolean;
	commentTreeSubTasks: boolean;
	isProlongOrderedTime: boolean;
	clearPerformersOnCopyMove: boolean;
	rejectSubTasksWhenRejected: boolean;
	createSystemFileVersionsOnSignatureSign: boolean;
	mainRouteStepsAvailableSmartExpressionId: number;
}

export interface ITaskParticipantsOptions {
	doNotSelectOwner: boolean;
	restrictPerformerRemove: boolean;
	restrictedSubscribtion: boolean;
	allowGroupSubcription: boolean;
	performerMode: 'Disallow' | 'Allow' | 'Deny' | 'Must';
	delegateTaskConfirmSignatureId: number;
	delegateTaskConfirmSignatureSmartExpressionId: number;
	taskOwnerChangeConfirmSignatureId: number;
	taskOwnerChangeSmartExpressionId: number;
	taskHelpersTimeVisible: boolean;
	notifyOwnerOnWorkAmountExcess: boolean;
	notifyAboutOverdueTaskOnlyResponsibleGroup: boolean;
	taskHelpersGradeMode: string;
	taskHelperGradeNecessary: boolean;
	canDeleteLastPerformer: boolean;
	multiFinishAction: string;
	helpersAction: string;
	forwardCommentsToAllHelpers: boolean;
	specialUsersSmartExpressionId: number;
	specialUsersTitle: string;
	allowSetSinglePerformer: boolean;
}

export interface ITextOptions {
	fixTaskText: boolean;
	allowNullTask: boolean;
	disableTaskDescription: boolean;
	allowHTMLTaskText: boolean;
	allowImagesInTaskText: boolean;
	showTasksTextAsHtml: boolean;
	ownerCanChangeText: boolean;
	adMinCanEditText: boolean;
	subscriberCanEditText: boolean;
	anyoneCanEditText: boolean;
	enableComments: boolean;
	enableCommentsEdit: boolean;
	denyEnterComments: boolean;
	allowHTMLCommentsText: boolean;
	notAllowCommentWithoutRecipients: boolean;
	hideSystemComments: boolean;
	denyPostCommentsInClosedTasks: boolean;
	autoMarkQuestions: boolean;
	showAllCommentsFromTaskTreeByDefault: boolean;
	forRootTasks: boolean;
	showCommentAttachments: boolean;
	taskTextSearchType: string;
	tasksEncryptionMode: string;
	commentReplacerId: number;
	taskTextReplacerId: number;
	taskTextMaxLength: number;
}

export interface IDueOptions {
	defaultDuration: number;
	defaultDurationMode: number;
	minSrok: number;
	minSrokMode: number;
	recommendedDueDate: number;
	recommendedDueDateMode: number;
	allowChangeDuration: boolean;
	allowSetTaskStartTime: boolean;
	fillNewTaskStartTimeWithCreatedDate: boolean;
	allowSetTimeParamsInPast: boolean;
	allowStepsInOverdueTask: boolean;
	allowChangePerformersInOverdueTask: boolean;
	allowOrderedTimeOnlyInWorkPeriod: boolean;
	allowOrderedTimeOnlyInWorkTime: boolean;
	allowChangeTaskStartTimeInClosedTasks: boolean;
	restrictDueDateEdit: boolean;
	changeDueConfirmSignatureId: number;
	changeDueConfirmSignatureSmartExpressionId: number;
	notShowChangeDueDateCount: boolean;
	orderedTimeCanBeLocked: boolean;
	srokControl: number;
	smart25: boolean;
	smart50: boolean;
	smart75: boolean;
	smart100: boolean;
}

export interface IViewAndPermissions {
	allowChangeTaskOwner: boolean;
	allowChat: boolean;
	allowPriority: boolean;
	showTaskCountInTitle: boolean;
	showAllTaskCountInTree: boolean;
	showNewTaskCountInTree: boolean;
	showOverdueTaskCountInTree: boolean;
	showResources: boolean;
	classicCalendar: boolean;
	showTasksInTerminalStatusInCalendar: boolean;
	showLoadingIndicator: boolean;
	displaySubscribeDuringNewTask: boolean;
	displayNotifyDuringNewTask: boolean;
	displayTaskSubcategoryPath: boolean;
	displayTaskId: boolean;
	displayTaskState: boolean;
	displayTaskStartDate: boolean;
	displayTaskDelegateButton: boolean;
	displayTaskChangeDueDateButton: boolean;
	attachFilesPermission: string;
	newTaskFormTemplateID: number;
	newTaskFormTemplateIdSmartExpressionId: number;
	mainTaskFormTemplateID: number;
	mainTaskFormTemplateIdSmartExpressionId: number;
	mtfDefaultTemplateVisibilityMode: string;
	mtfDefaultTemplateVisibilitySmartId: number;
	redirectFromNewTask: string;
	redirectFromGridHref: string;
	notLoadGridAndShowSearch: boolean;
	useShortNewTaskView: boolean;
	defaultViewType: string;
	showPinToChatIcon?: boolean;
}

export interface IChild {}

export interface IToolbarSave {
	languageKey: string;
	show: boolean;
	isAvailable: boolean;
	child: IChild[];
}

export interface IRefresh {
	languageKey: string;
	show: boolean;
	isAvailable: boolean;
	child: IChild[];
}

export interface ISubTasks {
	languageKey: string;
	show: boolean;
	isAvailable: boolean;
	child: IChild[];
}

export interface ISignatures {
	languageKey: string;
	show: boolean;
	isAvailable: boolean;
	child: IChild[];
}

export interface IDocuments {
	languageKey: string;
	show: boolean;
	isAvailable: boolean;
	child: IChild[];
}

export interface ITaskMember {
	languageKey: string;
	show: boolean;
	isAvailable: boolean;
	child: IChild[];
}

export interface ITime {
	languageKey: string;
	show: boolean;
	isAvailable: boolean;
	child: IChild[];
}

export interface IRemFav {
	languageKey: string;
	show: boolean;
	isAvailable: boolean;
	child: IChild[];
}

export interface IViewMilestones {
	languageKey: string;
	show: boolean;
	isAvailable: boolean;
	child: IChild[];
}

export interface IProject {
	languageKey: string;
	show: boolean;
	isAvailable: boolean;
	child: IChild[];
}

export interface IAllDecompositions {
	languageKey: string;
	show: boolean;
	isAvailable: boolean;
	child: IChild[];
}

export interface IOtherActions {
	languageKey: string;
	show: boolean;
	isAvailable: boolean;
	child: IChild[];
}

export interface IVCActions {
	child: IVCActions[];
	isAvailable: boolean;
	languageKey: string;
	show: boolean;
}
export interface IToolbarConfig {
	toolbarSave: IToolbarSave;
	refresh: IRefresh;
	subTasks: ISubTasks;
	signatures: ISignatures;
	documents: IDocuments;
	taskMember: ITaskMember;
	time: ITime;
	remFav: IRemFav;
	viewMilestones: IViewMilestones;
	project: IProject;
	allDecompositions: IAllDecompositions;
	otherActions: IOtherActions;
	vcActions: IVCActions;
}

export interface ISubcategory {
	id: number;
	version: number;
	subcatTypeID: number;
	description: string;
	details: string;
	categoryId: number;
	guid: string;
	entity: string;
	consisImplementOptions: ConsisImplementOptions;
	resourceManagementOptions: IResourceManagementOptions;
	automationOptions: IAutomationOptions;
	taskParticipantsOptions: ITaskParticipantsOptions;
	textOptions: ITextOptions;
	dueOptions: IDueOptions;
	viewAndPermissions: IViewAndPermissions;
	sendButton: string;
	srokName: string;
	requireSubtaskFinish: boolean;
	defaultText: string;
	forMailAdministrators: string;
	responsibleGroupID: number;
	newTaskMessage: string;
	chatTemplate: string;
	useChatTemplate: boolean;
	customerID: number;
	forInvitations: boolean;
	disableMail: boolean;
	isDictionary: boolean;
	reminderTextTemplate: string;
	perfOnCreateAssignedText: string;
	inviteTemplate: string;
	inactiveTaskWarningTime: number;
	extParamIDToWriteInSubjects: number;
	textToAppendToSubjects: string;
	taskHelperDefaultPlan: number;
	invidedUserGroupID: number;
	autoTaskTextTemplate: string;
	extUsersMailerFrom: string;
	extUsersMailerFromName: string;
	recipientsTitle: string;
	useEPTemplate: boolean;
	epTemplate: string;
	enableSms: boolean;
	taskStartTimeName: string;
	parentTaskDescriptionReference: string;
	forbidUploadFilesIntoClosedTasks: boolean;
	notifyResponsibleStateChange: boolean;
	groupToInformOnPlanTimeOverdueID: number;
	isPortal: boolean;
	isNotice: boolean;
	forbidMovingTasks: boolean;
	memo: string;
	fullPath: string;
	docxTemplateProtectionLevel: number;
	fullPathIds: string;
	isForProjects: boolean;
	isForProjectTasks: boolean;
	projectTasksSubcatId: number;
	giveRightsOnlyToADGroups: boolean;
	autoDeletePrevVersionsOfFiles: boolean;
	allowSignatureRevoke: boolean;
	allowSignatureRevokeByAcceptors: boolean;
	taskHelpersPlanTimeMode: string;
	projectPlannedSumEpId: number;
	projectFactSumEpId: number;
	projectCurrencyName: string;
	projectDefaultViewPreset: string;
	projectShortDateView: boolean;
	additionalSyndicateLink: string;
	disableTasksMove: boolean;
	addSubscriberText: string;
	addSubscribersText: string;
	oneFMainVisibilityMode: string;
	isSystemSubcat: boolean;
	isResourceReservation: boolean;
	isTasksLayoutSubcat: boolean;
	isForSurveys: boolean;
	taskLikesEnabled: boolean;
	enableTaskViewsCounter: boolean;
	enableTaskUserCommentsCounter: boolean;
	allowSignatureRevokeByOwner: boolean;
	allowSignatureRevokeByRequester: boolean;
	mobileTemplateID: number;
	confidentialMode: string;
	showExtParamsAfterBlocks: boolean;
	severalDecompositions: boolean;
	isForProjectForceTasks: boolean;
	mtfDefaultExtParamTableColumns: number;
	ntfDefaultExtParamTableColumns: number;
	extParamsBlocksColor: string;
	mobileTaskTemplateId: number;
	msOfficeInteractionSettingId: number;
	isCalendar: boolean;
	tasksIsNotOverdue: boolean;
	mobileCellTemplateId: number;
	isSaveUsersSettingsForGrid: boolean;
	isPinnedToChats: boolean;
	disableTasksCopyFromSubcategory: boolean;
	disableTasksCopyToSubcategory: boolean;
	toolbarConfig: IToolbarConfig;
	srokMode: string;
	localizedDescriptionId: number;
	localizedEntityId: number;
	taskHelpersTimeMode: string;
	isTechnicalWorksNow: boolean;
	technicalWorksText: string;
	localizedTechnicalWorksTextId: number;
	notUseNewTaskForm: boolean;
	enableRecurrence?: boolean;
	displayEmptyAttachments: boolean;
}

export interface IExtParamsInSubcat {
	subcatId: number;
	extParamId: number;
	extParamName: string;
	isRequired: boolean;
	newTask: boolean;
	subcatOrder: number;
	isReadonly: boolean;
	accessControl: number;
	syncToParent: boolean;
	syncToChild: boolean;
	stateControl: boolean;
	newTaskMode: number;
	defaultValue: string;
	notifyOnChange: boolean;
	allowEditHistory: boolean;
	guid: string;
	changeWithComment: boolean;
	hidden: boolean;
	defaultValueSmartExpressionID: number;
	blockId: number;
	validatorId: number;
	colspan: number;
	maxLength: number;
	allowHistory: boolean;
	dontCopySourceTaskValue: boolean;
	smartAccessControl: boolean;
	encrypt: boolean;
	extParamType?: string;
}

export interface IStateRouteInSubcat {
	stateID: number;
	stateNextID: number;
	actionID: number;
	ownerOnly: boolean;
	userOnly: boolean;
	subcatID: number;
	stepDescr: string;
	hiddenStep: boolean;
	stepToolTip: string;
	autoPerformerSignatureID: number;
	autoperformerAssingAction: number;
	termMinutes: number;
	transparentApproval: boolean;
	forceComment: boolean;
	resetDueTime: boolean;
	stepOwnerToolTip: string;
	stepUserToolTip: string;
	isButtonPresserAutoPerformer: boolean;
	autoAssignCustomer: boolean;
	cycleApproval: boolean;
	disableMail: boolean;
	autoPerformStepOnSubtasksClose: boolean;
	onOverduePerformStepID: number;
	confirmText: string;
	termDateSourceExtParamID: number;
	requestDirectorSignatureOnReject: boolean;
	forAdmin: boolean;
	forViewver: boolean;
	taskStartTimeAction: number;
	askPermissionToPerformTSTAction: boolean;
	autoPerformerSourceExtParamID: number;
	isAutoPerfomedOnPostTask: boolean;
	guid: string;
	storedProcedures: string;
	changeTaskText: boolean;
	changeTaskTextTemplate: string;
	forPerfRight: boolean;
	forAddRight: boolean;
	forResponsiblePerformer: boolean;
	notifyUsersInSubcat: boolean;
	notifyUsersInSubcatText: string;
	notifyUsersInSubcatGroupID: number;
	actionsWithExtParams: number;
	visibilitySmartFilterID: number;
	disallowRevisionsOnSign: boolean;
	doSetComplainted: boolean;
	removeCommentsOnSignWhenEP: number;
	redirectUrlAfterStepSmartExpressionID: number;
	isMainRouteStep: boolean;
	isMainRouteAvailable: boolean;
	requireChangeDateBefore: boolean;
	localizedStepDescrId: number;
}

export interface IStepSubTask {
	stepId: number;
	subcatId: number;
	taskDescr: string;
	includeParentDescr: boolean;
	isLinked: boolean;
	copySubscribersFromParent: boolean;
	fromWhom: number;
	assignToParentTaskOwner: boolean;
	customerRoleToAssignID: number;
	copyPerformers: boolean;
	copyOnlyResponsiblePerformer: boolean;
	separateTaskForEachPerformer: boolean;
	performersFromGroupID: number;
	orderedTimeMode: number;
	orderedTimeExtParam: number;
	copyAttachmentsFromParent: boolean;
	guid: string;
	orderedTimeOffset: number;
	orderedTimeOffsetUnit: number;
	smartFilterID: number;
}

export interface IStateRoutesSignature {
	id: number;
	stepId: number;
	signatureId: number;
	subcatId: number;
	isNecessarily: boolean;
	isByPerformer: boolean;
	signatureOrder: number;
	maxTimeToSign: number;
	isForMainAcceptor: boolean;
	spName: string;
	reason: string;
	returnIfNotSignedStateId: number;
	canChangeSigRequestReason: boolean;
	sigRequestReasonRequired: boolean;
	guid: string;
	actionIfNotSigned: number;
	excludeAlreadySigned: boolean;
	smartFilterId: number;
	requestReasonOnAccept: boolean;
	isByResponsiblePerformer: boolean;
	canBeEscalated: boolean;
	timeToSignSmartId: number;
	escalateOnOverDue: boolean;
	isOwnerSignature: boolean;
	enabled: boolean;
	reasonSmartId: number;
	splitForEachAcceptor: boolean;
	acceptorsEvalutionBaseUser: number;
	baseUserSmartId: number;
	canBeDelegated: boolean;
	isEds: boolean;
}

export interface ICustomButton {
	id: number;
	subcatId: number;
	name: string;
	memo: string;
	iconUrl: string;
	isVisible: boolean;
	actionsPackId: number;
	jsExpression: string;
	actionId: number;
	forOwner: boolean;
	forPerformer: boolean;
	forResponsiblePerformer: boolean;
	forAdmin: boolean;
	forAddRight: boolean;
	forViewer: boolean;
	visibilitySmartFilterId: number;
	urlSmartExpressionId: number;
	redirectActionTypeId: string;
	catSmartExpressionId: number;
	subcatSmartExpressionId: number;
	taskSmartExpressionId: number;
	taskSourceSmartExpressionId: number;
	sourceTaskSmartExpressionId: number;
	hideOnVisibilitySmartFilter: boolean;
	disabledReasonSmartExpressionId: number;
	disabledReason: string;
	guid: string;
	openLinkTarget: ActionRedirectType | ActionRedirectFixType;
	urlSourceType: string;
	urlLink: string;
	urlNewTaskSubcategorySourceType: string;
	urlNewTaskSubcategoryId: number;
	urlNewTaskTaskSourceType: string;
	urlNewTaskTaskId: number;
	urlNewTaskLinkedSourceType: string;
	urlNewTaskMakeLinked: boolean;
	urlNewTaskSubtaskSourceType: string;
	urlNewTaskMakeSubtask: boolean;
	urlTaskFormSourceType: string;
	urlTaskFormTaskId: number;
	urlSubcategoryId: number;
	urlSubcategorySourceType: string;
	urlCategorySourceType: string;
	urlCategoryId: number;
	urlTaskDataSourceSourceType: string;
	urlTaskDataSourceId: number;
}

export interface IExtParamBlock {
	id?: number;
	extParamBlockId?: number;
	name?: string;
	title?: string;
	subcatId: number;
	canFold: boolean;
	defaultMinimized: boolean;
	defaultBlock: boolean;
	guid: string;
	order: number;
	color: string;
	localizedNameId: number;
	extParamIds: number[];
	collapsedByDefault?: boolean;
}

export interface ISubcategoryData {
	subcategory: Partial<ISubcategory>;
	extParams: IExtParamsInSubcat[];
	stateRouteInSubcats: IStateRouteInSubcat[];
	stepSubTasks: IStepSubTask[];
	stateRoutesSignatures: IStateRoutesSignature[];
	customButtons: ICustomButton[];
	extParamBlocks: IExtParamBlock[];
	id: number;
	version: number;
	subcategoryIncludes?: Array<ISubcategoryInclude>;
	notes?: ISubCategoryNote[];
	stateHints?: ISubCategoryStateHint[];
	extraFormTemplates?: IExtraFormTemplate[];
}

export enum SubcategoryIncludeType {
	TaskForm = 'TaskForm',
}

export enum SubcategoryIncludeContentType {
	js = 'Js',
	css = 'Css',
}

export interface ISubcategoryInclude {
	id: number;
	includeId: number;
	includeType: SubcategoryIncludeType;
	includeContentType: SubcategoryIncludeContentType;
	name: string;
	subcatId: number;
	useForMtf: boolean;
	useForNewTask: boolean;
	notes: ISubCategoryNote[];
	stateHints: ISubCategoryStateHint[];
}

export interface ISubCategoryStateHint {
	id: number;
	stateId: number;
	subcatId: number;
	text: string;
	guid: string;
}

export interface IExtraFormTemplate {
	name: string;
	templateId: number;
}

export interface ISubCategoryNote {
	id: number;
	subcatId: number;
	noteTitle: string;
	note: string;
	isInNewTask: boolean;
	inInTask: boolean;
	isInTasksGrid: boolean;
	guid: string;
}

export interface ISubCategoryStateHint {
	id: number;
	stateId: number;
	subcatId: number;
	text: string;
	guid: string;
}

export interface ISubCategoryNote {
	id: number;
	subcatId: number;
	noteTitle: string;
	note: string;
	isInNewTask: boolean;
	inInTask: boolean;
	isInTasksGrid: boolean;
	guid: string;
}

export interface IError {
	errorType: string;
	message: string;
}

export interface ISubcatInfo {
	data: ISubcategoryData;
	errors: Error[];
}
