import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaChange } from '../media-observer/media-change';
import { MediaObserver } from '../media-observer/media-observer';
import { MatchMediaService } from './abstract';
import { MediaBreakpoint } from './breakpoints';

@Injectable()
export class MatchMediaServiceImpl implements MatchMediaService {
	constructor(protected mediaObserver: MediaObserver) {
		this.init();
	}

	private _activeMediaQuery: MediaBreakpoint = null;
	private _mediaChange: BehaviorSubject<MediaBreakpoint> = new BehaviorSubject<MediaBreakpoint>(null);
	private _destroy$ = new Subject();

	get activeMediaQuery() {
		return this._activeMediaQuery;
	}

	readonly media$ = this._mediaChange.pipe(takeUntil(this._destroy$));

	isActive(...breakPoints: MediaBreakpoint[]): boolean {
		breakPoints = breakPoints.filter(Boolean);
		if (breakPoints.length === 0) {
			return false;
		}
		return this.mediaObserver.isActive(breakPoints);
	}

	destroy() {
		this._destroy$.next(0 as any);
		this._destroy$.complete();
	}

	protected init(): void {
		this.mediaObserver.media$.subscribe((change: MediaChange) => {
			if (this.activeMediaQuery !== change.mqAlias) {
				const breakPoint = change.mqAlias as MediaBreakpoint;
				this._activeMediaQuery = breakPoint;
				this._mediaChange.next(breakPoint);
			}
		});
	}
}
