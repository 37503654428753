import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { FacadeSearchProvider } from '@spa/facade/features';
import { fromEvent, merge, Subject } from 'rxjs';
import { filter, take, takeUntil, mergeMap, tap, map, switchMap } from 'rxjs/operators';
import { parseMessageFromError } from '@valhalla/utils';
import { ModalWindowsService } from '../../../features/modals';

import { SearchOverlayComponent } from './search-overlay.component';

@Injectable()
export class SearchOverlayService {
	constructor(
		protected readonly overlay: Overlay,
		protected readonly searchService: FacadeSearchProvider,
		readonly modal: ModalWindowsService
	) {}

	protected overlayRef: OverlayRef;
	protected closeRef: () => void;

	open() {
		if (this.overlayRef) {
			this.closeRef();
		}

		this.overlayRef = this.overlay.create({
			hasBackdrop: true,
			width: '100vw',
			height: '100vh',
		});

		let searchPortal = new ComponentPortal(SearchOverlayComponent);
		const cmpRef = this.overlayRef.attach(searchPortal);

		let destroy$ = new Subject();
		destroy$.subscribe(_ => {
			this.overlayRef.detach();
			this.overlayRef.dispose();
			this.overlayRef = null;
			this.closeRef = null;
		});

		const close = () => {
			destroy$.next(0 as any);
			destroy$.complete();
			searchPortal = null;
			destroy$ = null;
		};
		this.closeRef = close;

		const escClick$ = fromEvent<KeyboardEvent>(window, 'keydown').pipe(filter(e => e.key === 'Escape'));

		cmpRef.instance.search
			.pipe(
				take(1),
				tap(_ => cmpRef.instance.loading(true)),
				switchMap(query => {
					return this.searchService.isTask(query).pipe(map(isTask => ({ isTask, query })));
				}),
				takeUntil(destroy$)
			)
			.subscribe({
				next: ({ query, isTask }) => {
					close();
					this.searchService.openPageDataSearchResult(query, isTask);
				},
				error: err => {
					this.modal.openError(parseMessageFromError(err));
				},
			});

		cmpRef.instance.outsideClick.pipe(takeUntil(destroy$)).subscribe(() => {
			close();
		});

		merge(this.overlayRef.backdropClick(), escClick$)
			.pipe(takeUntil(destroy$))
			.subscribe(_ => {
				close();
			});

		return {
			close,
		};
	}
}
