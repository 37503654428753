import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import { IFileViewerFile } from './file-viewer-file';
import { FileViewerFileType } from './file-viewer-file-type';

@Injectable()
export class FileViewerService implements OnDestroy {
	constructor(readonly http: HttpClient) {}

	videoForCanPlayTypeTest: HTMLVideoElement = document.createElement('video');
	audioForCanPlayTypeTest: HTMLAudioElement = document.createElement('audio');

	ngOnDestroy() {
		this.videoForCanPlayTypeTest.remove();
		this.audioForCanPlayTypeTest.remove();
	}

	getFileIconCssClass(item: IFileViewerFile): { [key: string]: boolean } {
		if (!item) return;

		const type = item.fileType;
		return {
			'video-icon': type === FileViewerFileType.video,
			'audio-icon': type === FileViewerFileType.audio,
			'file-icon': type !== FileViewerFileType.video && type !== FileViewerFileType.audio,
		};
	}

	getFileIconName(item: IFileViewerFile): string {
		if (!item) return;

		const type = item.fileType;
		switch (type) {
			case FileViewerFileType.video:
				return 'play_circle_filled';
			case FileViewerFileType.audio:
				return 'audiotrack';
		}
		return 'insert_drive_file';
	}

	getFileText(file: IFileViewerFile): Observable<string> {
		return this.http.get<string>(file.url, { responseType: 'text' as any });
	}

	getFileTypeToShowMediaInBrowser(file: IFileViewerFile): FileViewerFileType {
		if (!file) return;

		if (file.mime) {
			if (file.fileType === FileViewerFileType.video)
				return this.videoForCanPlayTypeTest.canPlayType(file.mime)
					? FileViewerFileType.video
					: FileViewerFileType.generic;

			if (file.fileType === FileViewerFileType.audio)
				return this.audioForCanPlayTypeTest.canPlayType(file.mime)
					? FileViewerFileType.audio
					: FileViewerFileType.generic;
		}

		return file.fileType;
	}

	getFileType(fileName: string, url: string): FileViewerFileType {
		if (fileName) {
			const nameExtension = fileName.split('.').pop();
			if (nameExtension && nameExtension !== fileName) {
				const type = this.getFileTypeByExtension(nameExtension);
				if (type) {
					return type;
				}
			}
		}

		if (url) {
			const urlExtension = url.split('.').pop();
			if (urlExtension && urlExtension !== url) {
				const type = this.getFileTypeByExtension(urlExtension);
				if (type) {
					return type;
				}
			}
		}

		return null;
	}

	getFileTypeByExtension(extension: string): FileViewerFileType {
		if (!extension) return null;

		const lowered = extension.toLocaleLowerCase();

		if (imageExtensions.includes(lowered)) return FileViewerFileType.image;

		if (videoExtensions.includes(lowered)) return FileViewerFileType.video;

		if (pdfExtensions.includes(lowered)) return FileViewerFileType.pdf;

		if (audioExtensions.includes(lowered)) return FileViewerFileType.audio;

		return null;
	}
}

// got from https://github.com/dyne/file-extension-list/tree/master/data
const imageExtensions = [
	'3dm',
	'3ds',
	'max',
	'bmp',
	'dds',
	'gif',
	'jpg',
	'jpeg',
	'png',
	'psd',
	'xcf',
	'tga',
	'thm',
	'tif',
	'tiff',
	'yuv',
	'ai',
	'eps',
	'ps',
	'svg',
	'dwg',
	'dxf',
	'gpx',
	'kml',
	'kmz',
];

const audioExtensions = [
	'aac',
	'aiff',
	'ape',
	'au',
	'flac',
	'gsm',
	'it',
	'm3u',
	'm4a',
	'mid',
	'mod',
	'mp3',
	'mpa',
	'pls',
	'ra',
	's3m',
	'sid',
	'wav',
	'wma',
	'xm',
];

const videoExtensions = [
	'3g2',
	'3gp',
	'aaf',
	'asf',
	'avchd',
	'avi',
	'drc',
	'flv',
	'm2v',
	'm4p',
	'm4v',
	'mkv',
	'mng',
	'mov',
	'mp2',
	'mp4',
	'mpe',
	'mpeg',
	'mpg',
	'mpv',
	'mxf',
	'nsv',
	'ogg',
	'ogv',
	'ogm',
	'qt',
	'rm',
	'rmvb',
	'roq',
	'srt',
	'svi',
	'vob',
	'webm',
	'wmv',
	'yuv',
];

const pdfExtensions = ['pdf'];
