export function textWidth(text: string, fontSize?: string | number, fontWeight?: number) {
	let fakeEl = document.getElementById('fakeEl');
	if (!fakeEl) {
		fakeEl = document.createElement('span');
		fakeEl.id = 'fakeEl';
		fakeEl.style.position = 'fixed';
		fakeEl.style.visibility = 'hidden';
		fakeEl.style.zIndex = '-1';
		document.body.appendChild(fakeEl);
	}

	if (!text) {
		return 0;
	}
	fakeEl.style.fontSize = typeof fontSize === 'number' ? `${fontSize}px` : fontSize;
	fakeEl.style.fontWeight = fontWeight && String(fontWeight);
	fakeEl.textContent = text;
	return Number.parseFloat(getComputedStyle(fakeEl).width);
}
