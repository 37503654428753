export function mapHexToMatPallete(hex: string) {
	hex = hex && hex.toUpperCase();
	const matColor: string = {
		'0E62A0': 'Indigo-600',
		'3C5DB6': 'Indigo-500',
		'394D85': 'deep-purple-900',
		'165373': 'Indigo-900',
		'5885FF': 'blue-600',
		'629DF6': 'blue-500',
		'87A8FF': 'blue-300',
		'8BB0D2': 'blue-A100',
		EA0714: 'red-A700',
		EA2E2E: 'red-600',
		C42F41: 'red-800',
		FF6417: 'deep-orange-500',
		CA910D: 'yellow-800',
		'017160': 'teal-800',
		'87A006': 'lime-800',
		'13A570': 'green-300',
		'895B98': 'purple-400',
		B96AB6: 'purple-300',
		'9E4752': 'pink-800',
		'5C5C5C': 'blue-grey-700',
		'7A7A7A': 'grey-600',
		A3A3A3: 'fuse-navy-200',
		CCCCCC: 'blue-grey-100',
		D6D6D6: 'grey-300',
		'0072CE': 'indigo-A400',
		'00B5E2': 'cyan-500',
		'00968F': 'cyan-800',
		AC145A: 'pink-900',
		FDD26E: 'orange-300',
		'789D4A': 'light-green-800',
		E03C31: 'red-900',
		'003A70': 'indigo-900',
		'898D8D': 'fuse-navy-300',
	}[hex];

	return matColor && matColor.toLowerCase();
}
