import { AuthService, Effect, IAction, ofType, SessionUser } from '@valhalla/core';
import { DataHttpService } from '@valhalla/data/http';
import { EMPTY, of, zip } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import * as actions from '../actions';
import { IUserSettingsState } from '../state';
import { UserSettingsEffectDependencies } from './dependency';
import { mapThemeName, LayoutFacade } from '@spa/facade/layout';
import { getAppTopInjector } from '@spa/api/injectors';
import { waitFor } from '@valhalla/utils';

export const loadUserSettingsEffect: Effect<IAction, IAction, IUserSettingsState, UserSettingsEffectDependencies> = (
	actions$,
	state$,
	deps
) => {
	const server = deps.injector.get(DataHttpService);
	const sessionUser = deps.injector.get(SessionUser);
	let initialThemeSet = true;

	return actions$.pipe(
		ofType(actions.FacadeUserSettingsAction.loadUserSettings),
		mergeMap(() => {
			server.users.sessionUserSettingsUpdate();
			return server.users.sessionUserSettings$;
		}),
		tap(c => {
			if (initialThemeSet) {
				initialThemeSet = false;
				const activeTheme = mapThemeName(c?.userUISettings?.colorTheme);
				waitFor(
					() => {
						try {
							return !!getAppTopInjector().get(LayoutFacade);
						} catch (error) {}
						return false;
					},
					() => {
						const layout = getAppTopInjector().get(LayoutFacade);
						if (activeTheme) {
							layout.update({
								colorTheme: activeTheme,
							});
						}
					}
				);
			}
		}),
		map(data => actions.updateUserSettings(data)),
		catchError(e => {
			const errMsg = e.message || String(e);
			console.error(errMsg, e.error || e);
			return EMPTY;
		})
	);
};
