import { Observable } from 'rxjs';

export abstract class FacadeSearchProvider {
	/**
	 * поиск по данным системы (задачи, чаты, пользователи, комментарии)
	 */
	abstract openPageDataSearchResult(query: string, isTask?: boolean);
	abstract isTask(query: string): Observable<boolean>;
	abstract simpleSearch(query: string, searchClosedTask?: boolean, maxCount?: number): Observable<any>;
}
