import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUserContact } from '@valhalla/data/entities';
import { BehaviorSubject, EMPTY, Subject, of } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { UrlProvider } from '../url';
import { AuthService } from './abstract';
import { booleanFilter } from '@valhalla/utils';

@Injectable()
export class SessionUser {
	constructor(
		protected readonly auth: AuthService,
		protected readonly http: HttpClient,
		protected readonly url: UrlProvider
	) {}

	private readonly _avatar$ = new BehaviorSubject<string>(null);
	private readonly _userInfo$ = new BehaviorSubject<IUserContact>(null);
	private readonly _update$ = new Subject();

	readonly update$ = this._update$.asObservable();
	readonly userAvatar$ = this._avatar$.asObservable();
	readonly userInfo$ = this._userInfo$.pipe(filter(ui => Boolean(ui)));
	readonly userName$ = this._userInfo$.asObservable().pipe(map(info => info?.name));
	readonly userEmail$ = this._userInfo$.asObservable().pipe(map(info => info?.email));
	readonly hasAvatar$ = this.userInfo$.pipe(map(ui => ui?.hasAvatar));
	readonly userId$ = this.userInfo$.pipe(map(ui => ui?.id || ui.userId));
	readonly isAdmin$ = this.auth.isAdmin$;
	readonly profile$ = this.auth.authenticated$.pipe(
		booleanFilter(),
		switchMap(() => {
			const url = this.url.getApiUrl(`user/${this.userId}/profile`);
			return this.http.get<any>(url).pipe(
				map(r => r.data),
				catchError(() => of({}))
			);
		}),
		shareReplay(1)
	);

	get userId() {
		return Number(this.auth.userId);
	}

	get login() {
		return this.auth.userLogin;
	}

	update() {
		if (!this.auth.authenticated) {
			return;
		}
		this.getUserInfo()
			.pipe(
				tap(info => {
					info.userId = info.id;

					if (info?.positions[info?.positions?.length - 1]) {
						info['position'] = info?.positions[info?.positions?.length - 1];
					}
					this._userInfo$.next(info);
					this._update$.next(0 as any);
				}),
				take(1)
			)
			.subscribe();
	}

	getAvatar() {
		const url = this.avatarLink;
		return this.http
			.get(url, {
				responseType: 'blob',
			})
			.pipe(map(blob => this.url.urlFromBlob(blob)));
	}

	get avatarLink(): string {
		if (!this.auth.userId) {
			throw new Error('Session user is undefined!');
		}
		return `/api/files/download/${this.userInfo?.userAvatarFile?.fileId}/${this.userInfo?.userAvatarFile?.latestVersionId}`;
	}

	get userName() {
		const userInfo = this._userInfo$.value;
		return userInfo && userInfo.name;
	}

	get data() {
		return this._userInfo$.value;
	}

	get userInfo() {
		return this._userInfo$.value;
	}

	get hasAvatar() {
		return this.userInfo?.hasAvatar;
	}

	get isAdmin() {
		return this.auth.isAdmin;
	}

	getAvatars(opts: { userIds: number[]; scaleX?: number; scaleY?: number }) {}

	getUserInfo() {
		if (!this.auth.userId) {
			throw new Error('Session user is undefined!');
		}
		const url = this.url.getApiUrl(`users/${this.auth.userId}/contactinfo`, 'v1.0' as any);
		return this.http.get<IUserContact>(url);
	}

	getAvatarLinkById(id: number | string, timestamp?: number) {
		// return `${this.url.getUrl(`GetAvatar.ashx`)}?id=${id}${timestamp ? `&_t=${timestamp}` : ''}`;
		return `/app/v1.1/api/users/${id}/avatar?showEmptyAvatar=true`;
	}

	isSessionUser(userId: number) {
		return this.userId === userId;
	}
}
