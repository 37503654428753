import { FacadeStoreNames } from '@spa/facade/store-names.enum';
import { IRxStore, StoreManager } from '@valhalla/core';

import { effects, UserSettingsEffectDependencies } from './effects';
import { reducers } from './reducers';
import { createDefaultUserSettingsState } from './state';

export function getOrCreateUserSettingsStore(
	storeManager: StoreManager,
	dependencies: UserSettingsEffectDependencies,
	devTools = false,
	middlewares = []
): IRxStore {
	if (storeManager.isStoreExist(FacadeStoreNames.userSettings)) {
		return storeManager.getStore(FacadeStoreNames.userSettings);
	}
	const store = storeManager.createStore({
		name: FacadeStoreNames.userSettings,
		defaultState: createDefaultUserSettingsState(),
		effects,
		dependencies,
		devTools: {
			reduxDevTools: devTools,
			useConsoleLogger: true,
			useEventLog: false,
		},
		middlewares,
	});
	return store.addReducers(...reducers);
}
