import { Observable } from 'rxjs';

export abstract class FilterHttpService {
	abstract getChildParamDropdownValues(
		params: IGetChildParamDropdownValuesParams
	): Observable<Array<IFilterParamDropdownValues>>;
}

export interface IGetChildParamDropdownValuesParams {
	childSmartId: number;
	valueProperty: string;
	titleProperty: string;
	parentId?: number;
	parentValue?: any;
	parentName?: string;
	parentType?: number | string;
	contextId?: number;
	take?: number;
	skip?: number;
	filter?: string;
	selectedValues?: any[];
}

export interface IFilterParamDropdownValues {
	value: any;
	title: string;
}
