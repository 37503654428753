import { Injectable } from '@angular/core';
import { debounceTime, map, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PortalHostService {
	protected hostsStore = new Set<IPortalHost>();
	protected readonly notifyUpdate$ = new Subject<Set<IPortalHost>>();
	readonly update$ = this.notifyUpdate$.pipe(
		debounceTime(20),
		map(() => this.hosts())
	);

	hosts(name: string | string[] = '', key?: any) {
		const names = (Array.isArray(name) ? name : [name]).map(n => n?.toLowerCase());
		if (name) {
			return Array.from(this.hostsStore).filter(t => {
				const nameEquals = names.includes(t.name?.toLowerCase());
				if (key) {
					return nameEquals && t.key === key;
				}
				return nameEquals;
			});
		}
		return Array.from(this.hostsStore);
	}

	reg(host: IPortalHost) {
		this.hostsStore.add(host);
		this.notifyUpdate();
	}

	unreg(host: IPortalHost) {
		this.hostsStore.delete(host);
		this.notifyUpdate();
	}

	notifyUpdate() {
		this.notifyUpdate$.next(this.hostsStore);
	}
}

export interface IPortalHost {
	name: string;
	name$: Observable<string>;
	key?: any;
	setPortalTarget(target: any): void;
}
