import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { IBadgeColor, IBadgeEmphasis } from 'libs/core-components/src/lib/badge/badge.model';

export const selector = 'vh-fv-expand-list-item';

@Component({
	selector: selector,
	templateUrl: 'favorite-expand-list-item.component.html',
	styleUrls: ['favorite-expand-list-item.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteExpandListItemComponent {
	@HostBinding('class.vh-fv-expand-list-item')
	hostClassSelector = true;

	@Output()
	expandClick = new EventEmitter<any>();

	@Input()
	label: string;

	@Input()
	labelClass: string | string[] | Record<string, boolean>;

	@Input()
	counter: number;

	@Input()
	counterNew: number;

	@Input()
	counterNewColor: IBadgeColor;

	@Input()
	counterWarn: number;

	@Input()
	@HostBinding('style.background-color')
	bgColor: string;

	@Input()
	tagColor: string;

	@Input()
	data: any;

	@Input()
	icon: string;

	@Input()
	expandable: boolean;

	@HostBinding('class.vh-fv-expand-list-item--expandable')
	get isExpandeble() {
		return this.expandable;
	}

	@Input()
	expand: boolean;

	@Input()
	lineClamped: number;

	public readonly IBadgeColor = IBadgeColor;
	public readonly IBadgeEmphasis = IBadgeEmphasis;

	onExpandClick(event) {
		event.stopPropagation();
		this.expandClick.emit(this.data);
	}
}
