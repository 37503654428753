import { Injectable } from '@angular/core';

import { registerCommand, unregisterCommand } from './spa-command';
import { SpaCommandExecutorFn } from './spa-command.contract';

@Injectable({ providedIn: 'root' })
export class SpaCommandRegisterService {
	registerCommand(name: string, handler: SpaCommandExecutorFn) {
		registerCommand(name, handler);
	}

	unregisterCommand(name: string) {
		unregisterCommand(name);
	}
}
