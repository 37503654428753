// tslint:disable:no-empty-interface
import {
	ITickerDto,
	ICustomAssistantTickerDto,
	ISystemTickersDto,
	ISystemTickersDto2,
	ISystemTickersNamesDto2,
} from '@valhalla/data/entities';
import { Observable } from 'rxjs';

export abstract class TickersDataHttpService {
	/** @deprecated */
	abstract getSchemaCustomTickers(): Observable<ITickerDto[]>;
	///** @deprecated */
	//abstract getValuesCustomTickers(criteria: IGetValuesCustomTickersCriteria): Observable<IGetValueCustomTickerDto[]>;
	///** @deprecated */
	//abstract getSystemTickers(options?: Record<string, any>): Observable<ISystemTickersDto>;
	///** @deprecated */
	//abstract getChatUnreadCountTicker(options?: any): Observable<number>;

	abstract getAll(): Observable<IGetAllTickersResult>;
	abstract getCustom(): Observable<ITickerDto[]>;
	abstract getSystem(...keys: (keyof ISystemTickersDto2)[]): Observable<Partial<ISystemTickersDto2>>;
	abstract updateUserTickersCache(): Observable<void>;
	abstract updateUserTickersCacheForSubcat(subcatId: number): Observable<void>;
}

export interface IGetValuesCustomTickersCriteria {
	tickerIds: number[];
}

export interface IGetValueCustomTickerDto {
	/** ticker id */
	First: number;
	/** ticker value */
	Second: number;
}

export interface IGetAllTickersResult {
	systemTickers: Partial<ISystemTickersDto2>;
	systemTickersNames: Partial<ISystemTickersNamesDto2>;
	customTickers: ITickerDto[];
	customAssistantTickers: ICustomAssistantTickerDto[];
}
