import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	NgZone,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DataHttpService } from '@spa/data/http';
import { UrlProvider, ViewDestroyStreamService } from '@valhalla/core';
import { preventDefault } from '@valhalla/utils';
import { filter, map, takeUntil } from 'rxjs';

import { BlockButtonsType, INavigationItemBadge, INavigationMenu, MenuItemActionType } from '../../navigation.model';
import { take } from 'rxjs/operators';
import { NavigationCommonService } from '@spa/common/components/navigation';
import { IBadgeColor, IBadgeEmphasis } from 'libs/core-components/src/lib/badge/badge.model';

@Component({
	selector: 'vh-common-nav-vertical-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class NavVerticalItemCommonComponent implements OnInit {
	constructor(
		readonly router: Router,
		readonly urlBuilder: UrlProvider,
		readonly cdr: ChangeDetectorRef,
		readonly destroy$: ViewDestroyStreamService,
		readonly server: DataHttpService,
		readonly zone: NgZone,
		readonly navigationCommonService: NavigationCommonService
	) {}

	@HostBinding('class.nav-item')
	hostClassNavItem = true;

	@HostBinding('class.vh-common-nav-vertical-item')
	hostClassSelector = true;

	@Input()
	menuItem: INavigationMenu;

	@Input()
	contextMenu;

	@Output()
	menuItemClick: EventEmitter<INavigationMenu> = new EventEmitter();

	readonly actionTypes = MenuItemActionType;
	readonly prevent = preventDefault;
	public readonly IBadgeColor = IBadgeColor;
	public readonly IBadgeEmphasis = IBadgeEmphasis;

	readonly appConfig$ = this.server.config.getAppConfiguration();
	readonly calendarSubcatId$ = this.appConfig$.pipe(map(config => config.calendarSubcatId));

	calendarSubcatId;

	ngOnInit() {
		this.calendarSubcatId$.pipe(takeUntil(this.destroy$)).subscribe(subcatId => {
			this.calendarSubcatId = subcatId;
		});
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.cdr.markForCheck();
			});
	}

	trackByBadgeValue(idx, badge: INavigationItemBadge) {
		return badge.value;
	}

	orderedBadges(item: INavigationMenu) {
		const include = ['overdueTasksCount', 'newTasksCount', 'allTasksCount'];
		return (item.badges || [])
			.filter(i => include.indexOf(i.counterName) !== -1)
			.map(b => {
				return {
					...b,
					order: b.matBg === 'warn' ? 0 : 1,
				};
			})
			.sort((a, b) => a.order - b.order);
	}

	menuItemClickHandler(e, menuItem) {
		const url = this.prepareUrlByItem(menuItem);
		this.server.category
			.storageSubCategory(menuItem.$id)
			.pipe(
				map(data => data.subcategory.viewAndPermissions),
				take(1)
			)
			.subscribe(viewAndPermissions => {
				if (viewAndPermissions.redirectFromGridHref?.length) {
					this.navigationCommonService.redirectToIFrameLink(viewAndPermissions.redirectFromGridHref);
					return;
				}
				if (e?.ctrlKey || e?.metaKey || menuItem.openInNewTab) {
					const urlForWindow = this.urlBuilder.getUrl(url, true);
					window.open(urlForWindow, '_blank');
					return;
				}

				this.zone.runTask(() => {
					this.router.navigate([url]);
				});
			});
	}

	isRouterActiveItem() {
		const entity = this.menuItem.nodeType === 'Subcategory' ? 'subcat' : 'category';
		const urlParts = this.router.url.toLowerCase().split('/');
		const tasksIdx = urlParts.findIndex(i => i === 'tasks');
		if (tasksIdx === -1) {
			return false;
		}
		const active = urlParts[tasksIdx + 1] === entity && urlParts[tasksIdx + 2] === `${this.menuItem.$id}`;
		return active;
	}

	prepareUrlByItem(menuItem): string {
		const linkType = menuItem.nodeType === 'Subcategory' ? 'subcat' : 'category';
		let openType, url;
		const toLowerCase = str => String(str).toLowerCase();

		switch (menuItem.openType) {
			case toLowerCase(BlockButtonsType.channel):
				openType = 'channel';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case toLowerCase(BlockButtonsType.chats):
				openType = 'chat';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case toLowerCase(BlockButtonsType.grid):
				openType = 'grid';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case toLowerCase(BlockButtonsType.feed):
				openType = 'feeds';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case toLowerCase(BlockButtonsType.calendar): {
				openType = 'calendar';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;

				if (menuItem.$id === this.calendarSubcatId) {
					url = `/calendar`;
				}
				break;
			}
			case toLowerCase(BlockButtonsType.gantt):
				openType = 'gantt';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case toLowerCase(BlockButtonsType.kanban):
				openType = 'kanban';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case toLowerCase(BlockButtonsType.file):
			case toLowerCase(BlockButtonsType.fileBrowser):
			case BlockButtonsType.fileBrowserTableView:
				openType = 'file';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case toLowerCase(BlockButtonsType.summary):
				openType = 'summary';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case toLowerCase(BlockButtonsType.portal):
				openType = 'portal';
				url = `/portal/${menuItem.portalId}`;
				break;
			case toLowerCase(BlockButtonsType.thumbnails):
				openType = 'thumbnails';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case BlockButtonsType.hierarchyDictionary:
				openType = 'hierarchy';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case BlockButtonsType.layoutPlan:
				openType = 'layout-plan';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case toLowerCase(BlockButtonsType.correspondence):
				openType = 'correspondence';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case toLowerCase(BlockButtonsType.hierarchy):
				openType = 'hierarchy';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
			case toLowerCase(BlockButtonsType.agenda):
				openType = 'agenda';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;

			default:
				openType = 'grid';
				url = `/tasks/${linkType}/${menuItem.$id}/${openType}`;
				break;
		}

		return url;
	}

	getBadgeColor(color: 'warn' | 'primary' | 'accent'): IBadgeColor {
		switch (color) {
			case 'primary':
				return IBadgeColor.default;
			case 'accent':
				return IBadgeColor.accent;
			case 'warn':
				return IBadgeColor.danger;
		}
	}

	getBadgeEmphasis(color: 'warn' | 'primary' | 'accent'): IBadgeEmphasis {
		switch (color) {
			case 'primary':
				return IBadgeEmphasis.container;
			default:
				return IBadgeEmphasis.accent;
		}
	}
}
