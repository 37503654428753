import { IAction, ReducerBase } from '@valhalla/core';

import * as actions from '../actions';
import { INavigationState } from '../state';

export class InitNaviagationMenuReducer implements ReducerBase {
	readonly actionType = actions.FacadeNavigationAction.init;

	reduce(state: Readonly<INavigationState>, action: IAction) {
		return state;
	}
}
