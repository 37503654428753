import { Injectable, Optional, SkipSelf, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DuplicateImportServiceError } from '../errors';
import { EventBusService } from './abstract';
import { AbstractMessage } from './types';

@Injectable()
export class EventBusServiceImpl implements EventBusService {
	constructor(
		@Optional()
		@SkipSelf()
		eventBusService?: EventBusService
	) {
		if (eventBusService) throw new DuplicateImportServiceError('EventBusService');
	}
	private readonly _bus: Subject<AbstractMessage> = new Subject();

	get messages$() {
		return this._bus.asObservable();
	}

	send(message: AbstractMessage) {
		if (message?.type?.id) this._bus.next(message);
	}

	createAndSend<T extends AbstractMessage>(cls: Type<T>, data?: T, sender?: any) {
		this.send(new cls().create(data, sender));
	}

	ofType<T extends AbstractMessage>(cls: Type<T>) {
		return this._bus.pipe<T>(filter<T>(msg => msg instanceof cls));
	}
}
