import { Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { isArraysHasTheSameValues } from '@valhalla/utils';
import { IRecipientType } from '../../../data/http';
import { ExtParamBase } from './ext-param-base';

export type ExtParamEmailAddressesValueType = {
	users?: any[];
	emails?: any[];
};

export class ExtParamEmailAddresses extends ExtParamBase<ExtParamEmailAddressesValueType> {
	isEmpty() {
		return !(this.value?.users?.length || this.value?.emails?.length);
	}

	search(filter: string, skip = 0, take = 50) {
		return this.server.users
			.getContactsWithGroups({
				target: 'ExtParam',
				search: filter,
				subcatId: this.ctxSubcatId,
				taskId: this.ctxTaskId,
				extParamId: this.id,
				offset: skip,
				limit: take,
			})
			.pipe(
				map(items => {
					return items?.recipients?.filter(item => item.type === IRecipientType.user);
				}),
				mergeMap(res => {
					const userIds = res.reduce((acc, recipient) => {
						if (recipient.type === IRecipientType.user) {
							acc.push(recipient.id);

							return acc;
						}

						return acc;
					}, []);

					if (!userIds.length) {
						userIds.push(0);
					}

					return this.server.users.getUsersShortInfo({ userIds }).pipe(
						map(info => {
							return res.map(recipient => {
								const userInfo = info.find(user => user.userId === recipient.id);

								if (!userInfo) {
									return recipient;
								}

								return {
									...recipient,
									...userInfo,
								};
							});
						})
					);
				})
			);
	}

	convertForUpdateExtParamInTask() {
		const usersStr = this.value?.users.map(el => `{"UserID": ${el?.userId || el?.id}}`) || [];
		const emailsStr = this.value?.emails.map(el => `{"Email": "${el?.displayName || el}"}`) || [];
		const valsToAdd = [...usersStr, ...emailsStr].join(',');

		const deletedUsers = this.sourceValue?.users?.filter(
			svu => !this.value.users.some(v => (v?.userId || v?.id) === svu?.id)
		);
		const deletedUsersStr = deletedUsers?.map(el => `{"UserID": ${el?.id}}`) || [];

		const deletedEmails = this.sourceValue?.emails?.filter(
			sve => !this.value.emails.some(v => v?.displayName === sve || v === sve)
		);
		const deletedEmailsStr = deletedEmails?.map(el => `{"Email": "${el}"}`) || [];

		const valsToDelete = [...deletedUsersStr, ...deletedEmailsStr].join(',');

		return `#n${this.id}#v{"ValsToAdd": [${valsToAdd}], "ValsToDelete":[${valsToDelete}]}`;
	}

	convertForSaveInNewTaskAsync(): Observable<any> {
		if (typeof this.convertForSaveInNewTaskAsyncMiddleware === 'function') {
			return this.convertForSaveInNewTaskAsyncMiddleware(this).pipe(take(1));
		}
		const { valsToAdd, valsToDelete } = this.getToAddToDel();
		return of(JSON.stringify({ ValsToDelete: valsToDelete, ValsToAdd: valsToAdd }));
	}

	getToAddToDel() {
		const usersStr = this.value?.users.map(el => ({ UserID: el?.userId || el?.id })) || [];
		const emailsStr = this.value?.emails.map(el => ({ Email: el?.displayName || el })) || [];
		const valsToAdd = [...usersStr, ...emailsStr];

		const deletedUsers = this.sourceValue?.users?.filter(
			svu => !this.value.users.some(v => (v?.userId || v?.id) === svu?.id)
		);
		const deletedUsersStr = deletedUsers?.map(el => ({ UserID: el?.id })) || [];

		const deletedEmails = this.sourceValue?.emails?.filter(
			sve => !this.value.emails.some(v => (v?.displayName || v) === sve)
		);
		const deletedEmailsStr = deletedEmails?.map(el => ({ Email: el })) || [];

		const valsToDelete = [...deletedUsersStr, ...deletedEmailsStr];

		return { valsToDelete, valsToAdd };
	}

	getValueForCopy() {
		const users = this.value?.users.map(u => u?.name || u?.userName);
		const emails = this.value?.emails.map(el => el?.displayName || el);

		const valueForCopy = [...users, ...emails].join(', ');

		return valueForCopy;
	}

	setCopiedValue(value) {
		this.setSearchContext(value);
	}

	equalsValue(a: ExtParamEmailAddressesValueType, b: ExtParamEmailAddressesValueType) {
		if (!a && !b) {
			return true;
		}
		const emailsSame = isArraysHasTheSameValues(
			a?.emails || [],
			b?.emails || [],
			i => i?.displayName,
			i => i?.displayName
		);
		const usersSame = isArraysHasTheSameValues(
			a?.users || [],
			b?.users || [],
			i => i?.userId || i?.id,
			i => i?.userId || i?.id
		);
		return usersSame && emailsSame;
	}

	get extParamQueryStringValue(): string {
		if (!this.value?.users?.length) {
			return '';
		}
		return this.value?.users.reduce((acc: string, cur: { name: string; id: number }) => {
			acc = acc + cur.id;
			return acc;
		}, `$Ext${this.id}$`) as string;
	}
}
