import { Inject, Injectable, NgZone } from '@angular/core';
import { SpaApiEvents } from '@spa/api/events';
import { BROWSER_TAB_ID, CookieService, LocalStorageProvider } from '@spa/core';
import { DataHttpService } from '@spa/data/http';
import { booleanFilter } from '@valhalla/utils';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	filter,
	fromEvent,
	map,
	merge,
	Observable,
	skip,
	startWith,
	take,
} from 'rxjs';
import { InternetConnectionService } from './internet-connection.service';

const masterTabKey = '@spa-master-tab';

@Injectable({ providedIn: 'root' })
export class TabActiveStateService {
	constructor(
		readonly spaEvents: SpaApiEvents,
		readonly server: DataHttpService,
		readonly zone: NgZone,
		@Inject(BROWSER_TAB_ID) readonly tabId: string,
		readonly localStore: LocalStorageProvider,
		readonly cookies: CookieService,
		readonly internet: InternetConnectionService
	) {}

	protected goToInactiveStateMinutes = localStorage.getItem('goToInactiveStateMinutes')
		? Number(localStorage.getItem('goToInactiveStateMinutes'))
		: 5;
	protected isUserActiveState$ = new BehaviorSubject(true);
	protected initialized = false;

	get tabVisible() {
		return document.visibilityState === 'visible';
	}

	get userActive() {
		return this.isUserActiveState$.value;
	}

	get isMasterTab() {
		return this.localStore.get(masterTabKey) === this.tabId;
	}

	readonly tabVisible$ = fromEvent(document, 'visibilitychange', { capture: false }).pipe(
		map(() => document.visibilityState),
		startWith(document.visibilityState),
		map(value => value === 'visible')
	);

	readonly userActive$ = this.isUserActiveState$.pipe(debounceTime(300));

	readonly tabBecomeVisible$ = this.tabVisible$.pipe(skip(1), distinctUntilChanged(), booleanFilter());

	readonly goOnline$ = this.internet.goOnline$;
	readonly goOffline$ = this.internet.goOffline$;

	init() {
		const run = (fn: () => void) => this.zone.run(fn);
		this.zone.runOutsideAngular(() => {
			if (this.initialized) {
				return console.warn('TabActiveStateService already initialized');
			}
			this.initialized = true;
			this.server.config
				.getAppSettingsAnonymous()
				.pipe(take(1))
				.subscribe(settings => {
					const updateLastActiveTime = settings.UpdateLastActiveTime || this.goToInactiveStateMinutes;
					const inactiveTimeout = () => {
						return setTimeout(() => {
							run(() => this.isUserActiveState$.next(false));
						}, updateLastActiveTime * 60 * 1000);
					};
					let inactiveTimeoutId = inactiveTimeout();
					merge(fromEvent(window, 'mousemove'), fromEvent(window, 'keydown')).subscribe(() => {
						if (!this.isUserActiveState$.value) {
							run(() => this.isUserActiveState$.next(true));
						}
						clearTimeout(inactiveTimeoutId);
						inactiveTimeoutId = inactiveTimeout();
					});

					const inactiveAfterTabHide = () => {
						return setTimeout(() => {
							run(() => this.isUserActiveState$.next(false));
						}, 5 * 1000);
					};
					let inactiveAfterTabHideId: any;
					this.tabVisible$.subscribe(visible => {
						this.setBackgroundCookie();
						if (visible) {
							this.setMasterTab();
							run(() => this.isUserActiveState$.next(visible));
							clearTimeout(inactiveTimeoutId);
							clearTimeout(inactiveAfterTabHideId);
							inactiveTimeoutId = inactiveTimeout();
						} else {
							inactiveAfterTabHideId = inactiveAfterTabHide();
						}
					});

					this.userActive$.pipe(booleanFilter()).subscribe(() => {
						this.spaEvents.dispatchEvent('user-activate-page');
					});
				});
		});
	}

	whenUserActive<T = any>(alwayOnWhenMasterTab = false, repeatWithLast = true) {
		let lastValue: any;
		return (source$: Observable<T>) => {
			return combineLatest([this.userActive$.pipe(map(isActive => ({ isActive, lastValue }))), source$]).pipe(
				filter(([{ isActive }]) => (alwayOnWhenMasterTab ? (this.isMasterTab ? true : isActive) : isActive)),
				map(([, value]) => {
					if (lastValue === value && !repeatWithLast) {
						return undefined;
					}
					lastValue = value;
					return value;
				})
			);
		};
	}

	setBackgroundCookie() {
		const name = 'Background-state';
		if (this.tabVisible) {
			this.cookies.delete(name, '/');
		} else {
			this.cookies.set(name, '1', 7, '/');
		}
	}

	setMasterTab() {
		if (this.spaEvents.isTopWindow) {
			this.localStore.set(masterTabKey, this.tabId);
		}
	}
}
