<!-- sidebar vertical -->
<ng-container *ngIf="isVerticalLayout; else sideBarBtnTop">
	<a class="logo pointer" routerLink="/">
		<div
			class="logo-icon"
			[class.logo-icon--default]="isDefaultLogo$ | async"
			[style.background-image]="appLogoPathBgImageUrlCss$ | async"
			[style.width]="appLogoStylesWidth$ | async"
			[style.height]="appLogoStylesHeight$ | async"
		></div>
	</a>

	<button
		*ngIf="!showNavigationAlways"
		id="toggleSideBarBtn"
		(click)="toggleMenu()"
		[tippy]="'common.navPanel' | resx"
		[isEnabled]="!mobileView.isMobile"
		[placement]="tooltipPosition"
		class="vh-btn vh-btn-icon vh-btn--transparent toggle-sidebar-btn"
	>
		<mat-icon *ngIf="!isSidebarOpened()" svgIcon="vh-hamburger-menu-24"></mat-icon>
		<vh-icon *ngIf="isSidebarOpened()" name="arrow-left" size="md"></vh-icon>
	</button>

	<div class="app-nav-btn-container d-none d-md-flex">
		<button
			*ngIf="lentaVisible$ | async"
			[tippy]="'common.feed' | resx"
			[isEnabled]="!mobileView.isMobile"
			[placement]="tooltipPosition"
			(click)="openFeed($event)"
			[class.active]="isUrl('/feed')"
			class="vh-btn vh-btn-icon vh-btn--sidebar-mini"
		>
			<vh-badge
				*ngIf="unreadCommentsCountTickerValue$ | async"
				[value]="matBadgeUnreadCommentsCountTicker$ | async"
				[color]="badgeColor.info"
				[locale]="locale$ | async"
			></vh-badge>
			<mat-icon svgIcon="vh-feed-24"></mat-icon>
		</button>

		<button
			*ngIf="(chatsVisible$ | async) && chatTicker$ | async as chatTicker"
			(click)="openChats($event)"
			[class.active]="urlStartsWith('/chat')"
			[tippy]="'common.tickerTooltipChats' | resx"
			[isEnabled]="!mobileView.isMobile"
			[placement]="tooltipPosition"
			class="vh-btn vh-btn-icon vh-btn--sidebar-mini"
		>
			<vh-badge
				*ngIf="chatUnreadCount$ | async"
				[value]="chatUnreadCount$ | async"
				[color]="badgeColor.info"
				[locale]="locale$ | async"
			></vh-badge>
			<mat-icon svgIcon="vh-chats-24"></mat-icon>
		</button>

		<ng-container *ngIf="staffVisible$ | async">
			<button
				[tippy]="'common.employees' | resx"
				[isEnabled]="!mobileView.isMobile"
				[placement]="tooltipPosition"
				type="button"
				cdkOverlayOrigin
				#trigger="cdkOverlayOrigin"
				(click)="toggleOpenCoworkerPanel()"
				class="vh-btn vh-btn-icon vh-btn--sidebar-mini"
			>
				<mat-icon svgIcon="vh-contacts-24"></mat-icon>
			</button>

			<ng-template
				cdkConnectedOverlay
				(detach)="isCoworkerPanelOpen$.next(false)"
				[cdkConnectedOverlayOrigin]="trigger"
				[cdkConnectedOverlayOpen]="isCoworkerPanelOpen$ | async"
				[cdkConnectedOverlayPanelClass]="isLayoutVerticaleRight ? 'sidebar-right' : null"
			>
				<ng-container *ngTemplateOutlet="coworkerSearch"></ng-container>
			</ng-template>
		</ng-container>
	</div>
</ng-container>

<!-- sidebar top -->
<ng-template #sideBarBtnTop>
	<!-- menu -->
	<button
		*ngIf="!showNavigationAlways"
		id="toggleSideBarBtn"
		(click)="toggleMenu()"
		[tippy]="'common.navPanel' | resx"
		[isEnabled]="!mobileView.isMobile"
		[placement]="tooltipPosition"
		class="vh-btn vh-btn-icon vh-btn--transparent toggle-sidebar-btn"
	>
		<mat-icon *ngIf="!isSidebarOpened()" svgIcon="vh-hamburger-menu-24"></mat-icon>
		<vh-icon *ngIf="isSidebarOpened()" name="arrow-left" size="md"></vh-icon>
	</button>

	<!-- logo -->
	<a class="logo pointer" routerLink="/">
		<div
			*ngIf="!(isWideLogo$ | async)"
			class="logo-icon"
			[class.logo-icon--default]="isDefaultLogo$ | async"
			[class.logo-icon--wide]="isWideLogo$ | async"
			[style.background-image]="appLogoPathBgImageUrlCss$ | async"
			[style.width]="appLogoStylesWidth$ | async"
			[style.height]="appLogoStylesHeight$ | async"
		></div>

		<ng-container *ngIf="isWideLogo$ | async">
			<mat-icon
				class="d-none d-lg-block logo-icon--wide"
				[style.width]="appLogoStylesWidth$ | async"
				[style.height]="appLogoStylesHeight$ | async"
				[svgIcon]="(theme.currentTheme$ | async) === 'light' ? 'vh-1f_logo_1line_color' : 'vh-1f_logo_1line_color_dark'"
			></mat-icon>

			<mat-icon
				class="d-none d-md-block d-lg-none"
				[style.width]="appLogoStylesWidth$ | async"
				[style.height]="appLogoStylesHeight$ | async"
				[svgIcon]="(theme.currentTheme$ | async) === 'light' ? 'vh-Logo' : 'vh-Logo_dark'"
			></mat-icon>
		</ng-container>

		<span class="logo-text">
			{{ appLabelText$ | async }}
		</span>
	</a>

	<!-- buttons with text -->
	<div class="app-nav-btn-container d-none d-md-flex">
		<button
			*ngIf="lentaVisible$ | async"
			id="btn-toolbar-feed"
			[class.active]="isUrl('feeds/default')"
			(click)="openFeed($event)"
			class="vh-btn vh-btn--icon-left vh-btn--text vh-btn--sidebar-mini"
		>
			<vh-badge
				*ngIf="unreadCommentsCountTickerValue$ | async"
				[value]="matBadgeUnreadCommentsCountTicker$ | async"
				[color]="badgeColor.info"
				[locale]="locale$ | async"
			></vh-badge>
			<mat-icon svgIcon="vh-feed-24"></mat-icon>
			<span>{{ 'common.feed' | resx }}</span>
		</button>

		<button
			*ngIf="(chatsVisible$ | async) && chatTicker$ | async as chatTicker"
			class="vh-btn vh-btn--icon-left vh-btn--text vh-btn--sidebar-mini"
			(click)="openChats($event)"
			[class.active]="urlStartsWith('/chat')"
		>
			<vh-badge
				*ngIf="chatTicker.value"
				[value]="chatUnreadCount$ | async"
				[color]="badgeColor.info"
				[locale]="locale$ | async"
			></vh-badge>
			<mat-icon svgIcon="vh-chats-24"></mat-icon>
			<span>{{ 'common.tickerTooltipChats' | resx }}</span>
		</button>

		<ng-container *ngIf="staffVisible$ | async">
			<button
				class="vh-btn vh-btn--icon-left vh-btn--text vh-btn--sidebar-mini"
				cdkOverlayOrigin
				#trigger="cdkOverlayOrigin"
				(click)="toggleOpenCoworkerPanel()"
			>
				<mat-icon svgIcon="vh-contacts-24"></mat-icon>
				<span>{{ 'common.employees' | resx }}</span>
			</button>

			<ng-template
				cdkConnectedOverlay
				(detach)="isCoworkerPanelOpen$.next(false)"
				[cdkConnectedOverlayOrigin]="trigger"
				[cdkConnectedOverlayOpen]="isCoworkerPanelOpen$ | async"
			>
				<ng-container *ngTemplateOutlet="coworkerSearch"></ng-container>
			</ng-template>
		</ng-container>

		<vh-auto-open-menu
			*ngIf="createButtonVisible$ | async"
			matMenuClass="app-nav-btn--create-menu"
			[clickMode]="true"
			[closeOnClick]="true"
			style="margin-left: 0"
		>
			<button trigger class="vh-btn vh-btn--icon-left vh-btn--text vh-btn--sidebar-mini">
				<mat-icon svgIcon="vh-plus-24"></mat-icon>
				<span> {{ 'common.create' | resx }}</span>
			</button>
			<div content>
				<ng-container *ngTemplateOutlet="createTaskOptions"></ng-container>
			</div>
		</vh-auto-open-menu>

		<span *ngIf="auth.isReincarnateMode$ | async" class="reincarnation-buble">
			<span class="reincarnation-buble__user">
				<span>{{ sessionUserName$ | async }}</span>
			</span>
			<button class="reincarnation-buble__exit" (click)="exitReincarnation()">
				<mat-icon svgIcon="vh-cross_v1-24"></mat-icon>
			</button>
		</span>
	</div>
</ng-template>

<div #fluentPanel class="nav-bar-fluent-panel" *ngIf="isHorizontalLayout">
	<ng-container *ngIf="searchVisible$ | async">
		<button
			class="vh-btn vh-btn-icon vh-btn--sidebar-mini"
			[tippy]="'common.quickSearch' | resx"
			[isEnabled]="!mobileView.isMobile"
			[placement]="tooltipPosition"
			type="button"
			cdkOverlayOrigin
			#triggerSearch="cdkOverlayOrigin"
			(click)="toggleOpenSearchPanel($event)"
		>
			<mat-icon svgIcon="vh-search-24"></mat-icon>
		</button>
		<ng-container *ngIf="isRightPositionedOverlay$ | async">
			<ng-template
				cdkConnectedOverlay
				(detach)="isSearchPanelOpen$.next(false)"
				[cdkConnectedOverlayOrigin]="triggerSearch"
				[cdkConnectedOverlayOpen]="isSearchPanelOpen$ | async"
				[cdkConnectedOverlayPanelClass]="['right-positioned-overlay', 'commonSearchOverlay']"
			>
				<ng-container *ngTemplateOutlet="commonSearch"></ng-container>
			</ng-template>
		</ng-container>
		<ng-container *ngIf="(isRightPositionedOverlay$ | async) === false">
			<ng-template
				cdkConnectedOverlay
				(detach)="isSearchPanelOpen$.next(false)"
				[cdkConnectedOverlayOrigin]="triggerSearch"
				[cdkConnectedOverlayOpen]="isSearchPanelOpen$ | async"
				[cdkConnectedOverlayPanelClass]="['commonSearchOverlay']"
			>
				<ng-container *ngTemplateOutlet="commonSearch"></ng-container>
			</ng-template>
		</ng-container>
	</ng-container>

	<vh-toolbar-tickers
		[vertical]="isVerticalLayout"
		[secondaryColor]="false"
		[fluentBoxContainer]="fluentPanel"
		[fluentBoxBufferBasis]="fluentBoxBufferBasis"
		[exceptTickers]="exceptTickers"
		[alwaysAllTickers]="alwaysAllTickers"
		[config]="appSettingsAnonym$ | async"
	></vh-toolbar-tickers>

	<button
		*ngIf="calendarVisible$ | async"
		[tippy]="'common.calendar' | resx"
		[isEnabled]="!mobileView.isMobile"
		[placement]="tooltipPosition"
		(click)="openCalendar($event)"
		[class.active]="isUrl('/calendar')"
		class="vh-btn vh-btn-icon vh-btn--sidebar-mini ml-4"
	>
		<vh-badge
			*ngIf="unreadMessagesCount$ | async"
			[value]="unreadMessagesCount$ | async"
			[color]="badgeColor.info"
			[locale]="locale$ | async"
		></vh-badge>
		<mat-icon svgIcon="vh-calendar-24"></mat-icon>
	</button>

	<vh-auto-open-menu
		*ngIf="hasServices$ | async"
		matMenuClass="menu-services"
		[clickMode]="true"
		[closeOnClick]="true"
		class="ml-4"
	>
		<button
			trigger
			class="vh-btn vh-btn-icon vh-btn--sidebar-mini app-nav-btn--create"
			[tippy]="'common.create' | resx"
			[isEnabled]="!mobileView.isMobile"
			[placement]="tooltipPosition"
		>
			<vh-icon name="menu-services" size="md"></vh-icon>
		</button>
		<div content>
			<ng-container *ngTemplateOutlet="menuServices"></ng-container>
		</div>
	</vh-auto-open-menu>
</div>

<div *ngIf="isVerticalLayout" class="vertical-sidebar-section">
	<div *ngIf="auth.isReincarnateMode$ | async" class="reincarnation-vertilcal-buble">
		<mat-icon svgIcon="vh-reface-24"></mat-icon>

		<div class="reincarnation-vertilcal-buble__content">
			<div class="reincarnation-vertilcal-buble__content-container">
				<div class="reincarnation-vertilcal-buble__content-user">{{ sessionUserName$ | async }}</div>
				<button class="reincarnation-vertilcal-buble__content-exit" (click)="exitReincarnation()">
					<mat-icon svgIcon="vh-cross_v1-24"></mat-icon>
				</button>
			</div>
		</div>
	</div>

	<button
		[tippy]="'common.1flogo' | resx"
		[isEnabled]="!mobileView.isMobile"
		[placement]="tooltipPosition"
		(click)="openSearch()"
		[class.imp]="true"
		class="vh-btn vh-btn-icon vh-btn--sidebar-mini"
	>
		<mat-icon svgIcon="vh-search-24"></mat-icon>
	</button>

	<vh-auto-open-menu
		*ngIf="createButtonVisible$ | async"
		matMenuClass="app-nav-btn--create-menu app-nav-btn--create-menu-vertical"
		[clickMode]="true"
		[closeOnClick]="true"
		style="margin-left: 0"
	>
		<button
			trigger
			class="vh-btn vh-btn-icon vh-btn--sidebar-mini app-nav-btn--create"
			[tippy]="'common.create' | resx"
			[isEnabled]="!mobileView.isMobile"
			[placement]="tooltipPosition"
		>
			<mat-icon svgIcon="vh-plus-24"></mat-icon>
		</button>
		<div content>
			<ng-container *ngTemplateOutlet="createTaskOptions"></ng-container>
		</div>
	</vh-auto-open-menu>

	<vh-toolbar-tickers
		[vertical]="isVerticalLayout"
		[secondaryColor]="false"
		[exceptTickers]="exceptTickers"
		[alwaysAllTickers]="alwaysAllTickers"
		[config]="appSettingsAnonym$ | async"
	></vh-toolbar-tickers>

	<button
		*ngIf="calendarVisible$ | async"
		class="vh-btn vh-btn-icon vh-btn--sidebar-mini mt-4"
		[tippy]="'common.calendar' | resx"
		[isEnabled]="!mobileView.isMobile"
		[placement]="tooltipPosition"
		(click)="openCalendar()"
	>
		<vh-badge
			*ngIf="unreadMessagesCount$ | async"
			[value]="unreadMessagesCount$ | async"
			[color]="badgeColor.info"
			[locale]="locale$ | async"
		></vh-badge>
		<mat-icon svgIcon="vh-calendar-24"></mat-icon>
	</button>

	<vh-auto-open-menu
		*ngIf="hasServices$ | async"
		matMenuClass="menu-services"
		[clickMode]="true"
		[closeOnClick]="true"
		class="mt-4"
	>
		<button
			trigger
			class="vh-btn vh-btn-icon vh-btn--sidebar-mini app-nav-btn--create"
			[tippy]="'common.create' | resx"
			[isEnabled]="!mobileView.isMobile"
			[placement]="tooltipPosition"
		>
			<vh-icon name="menu-services" size="md"></vh-icon>
		</button>
		<div content>
			<ng-container *ngTemplateOutlet="menuServices"></ng-container>
		</div>
	</vh-auto-open-menu>
</div>

<button
	*ngIf="(changeLanguageInAppBar$ | async) && (chooseLanguageVisible$ | async)"
	class="vh-btn vh-btn--icon-left vh-btn--sidebar-mini"
	#languageMenuTrigger="matMenuTrigger"
	[matMenuTriggerData]="{ trigger: languageMenuTrigger }"
	[matMenuTriggerFor]="languageMenu"
>
	<mat-icon svgIcon="vh-Wiki-v1-24"></mat-icon>
	<span>
		{{ (currentLanguageCode$ | async) || 'Lang' }}
	</span>
</button>

<!-- profile -->
<div
	class="user-menu-wrapper"
	#userMenuTrigger="matMenuTrigger"
	[matMenuTriggerData]="{ trigger: userMenuTrigger }"
	[matMenuTriggerFor]="userMenu"
>
	<vh-badge
		*ngIf="missedCallCount$ | async"
		[value]="missedCallCount$ | async"
		[color]="badgeColor.danger"
		[locale]="locale$ | async"
	></vh-badge>

	<vh-chat-nav-avatar
		#sessionUserAvatar
		[tippy]="sessionUserName$ | async"
		[isEnabled]="!mobileView.isMobile"
		[placement]="tooltipPosition"
		data-id="app-bar-avatar"
		[preferColorText]="!(sessionUserHasAvatar$ | async)"
		[coloredText]="sessionUserName$ | async"
		[showColorText]="!(sessionUserHasAvatar$ | async)"
		[sideLengthPx]="36"
		[userData]="sessionUser?.userInfo$ | async"
	></vh-chat-nav-avatar>
</div>

<!-- menu and templates -->
<ng-template #search>
	<div class="search">
		<mat-icon class="s-22">search</mat-icon>
		<input type="text" placeholder="{{ 'common.quickSearch' | resx }}" />
		<button matSuffix aria-label="Clear" class="cursor-pointer search-btn-clear">
			<mat-icon class="s-18">close</mat-icon>
		</button>
	</div>
</ng-template>

<mat-menu class="user-menu" #userMenu="matMenu" [overlapTrigger]="false">
	<!-- ng-template fix for lazy rendering menu on iOS -->
	<ng-template matMenuContent let-trigger="trigger">
		<div (mouseleave)="onMenuLeave(trigger)">
			<button
				*ngIf="sessionUserProfileLink$ | async as userProfileLink"
				(click)="navLink([userProfileLink], null, $event)"
				type="button"
				mat-menu-item
				class="user-menu--action-profile"
			>
				<div class="user-menu--profile-wrapper">
					<vh-chat-nav-avatar
						#sessionUserAvatar
						data-id="app-bar-avatar"
						[preferColorText]="!(sessionUserHasAvatar$ | async)"
						[coloredText]="sessionUserName$ | async"
						[showColorText]="!(sessionUserHasAvatar$ | async)"
						[sideLengthPx]="36"
						[userData]="sessionUser?.userInfo$ | async"
					></vh-chat-nav-avatar>

					<div class="user-menu--action-profile-info">
						<div class="user-menu--action-profile-info-name">{{ sessionUserName$ | async }}</div>
						<div class="user-menu--action-profile-info-position">{{ userPosition$ | async }}</div>
					</div>

					<!-- <div class="custom-menu-ripple">
						<mat-icon svgIcon="vh-arrow-v1-S-right"></mat-icon>
					</div> -->
				</div>
			</button>

			<mat-divider></mat-divider>

			<div class="user-menu--action-videocall" *ngIf="jitsiServicePersonalRoomVisible$ | async">
				<button class="user-menu--action-videocall-start" mat-menu-item (click)="openJitsi($event)" type="button">
					<mat-icon svgIcon="vh-video-call-24"></mat-icon>
					<span>{{ 'common.startСonference' | resx }}</span>
				</button>

				<button mat-menu-item (click)="copyJitsi()" class="user-menu--action-videocall-copy">
					<mat-icon svgIcon="vh-copy-24"></mat-icon>
				</button>
			</div>

			<button
				*ngIf="isCallHistory$ | async"
				class="user-menu--action-call-history"
				mat-menu-item
				(click)="openCallHistory()"
			>
				<mat-icon svgIcon="vh-phone-call-24"></mat-icon>
				<span>{{ 'common.myCallsAndMeetings' | resx }}</span>

				<div *ngIf="(missedCallCount$ | async) > 0" class="call-history-counter">{{ missedCallCount$ | async }}</div>
			</button>

			<button
				*ngIf="personalMenuTimeSheet$ | async"
				(click)="navLink(['/user/time-sheet'], null, $event)"
				type="button"
				mat-menu-item
				class=""
			>
				<mat-icon svgIcon="vh-Time-Sources-24"></mat-icon>
				<span>{{ 'common.timesheet' | resx }}</span>
			</button>

			<button
				*ngIf="personalMenuKanban$ | async"
				(click)="navLink(['/kanban/user/performer'], null, $event)"
				type="button"
				mat-menu-item
				class=""
			>
				<mat-icon svgIcon="vh-kanban-24"></mat-icon>
				<span>{{ 'common.kanban' | resx }}</span>
			</button>

			<mat-divider></mat-divider>

			<button type="button" #settingsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="settingsMenu" mat-menu-item>
				<mat-icon svgIcon="vh-Settings-v2-24"></mat-icon>
				<span>{{ 'common.settings' | resx }}</span>

				<div class="custom-menu-ripple">
					<mat-icon svgIcon="vh-arrow-v1-S-right"></mat-icon>
				</div>
			</button>

			<button
				*ngIf="chooseLanguageVisible$ | async"
				type="button"
				mat-menu-item
				#langsMenuTrigger="matMenuTrigger"
				[matMenuTriggerFor]="langsMenu"
				class="user-menu--action-language"
			>
				<mat-icon svgIcon="vh-Wiki-v1-24"></mat-icon>
				<span class="user-menu--action-language-text">
					<span class="user-menu--action-language-label">{{ 'common.language' | resx }}</span>

					<span class="user-menu--action-language-current-lang" *ngIf="currentLanguageName$ | async as langName">{{
						langName
					}}</span>
				</span>

				<div class="custom-menu-ripple">
					<mat-icon svgIcon="vh-arrow-v1-S-right"></mat-icon>
				</div>
			</button>

			<button
				*ngIf="personalMenuOthers$ | async"
				type="button"
				mat-menu-item
				#otherMenuTrigger="matMenuTrigger"
				[matMenuTriggerFor]="otherMenu"
			>
				<mat-icon svgIcon="vh-settings-more-24"></mat-icon>
				<span>{{ 'common.other' | resx }}</span>

				<div class="custom-menu-ripple">
					<mat-icon svgIcon="vh-arrow-v1-S-right"></mat-icon>
				</div>
			</button>

			<button *ngIf="isAdmin$ | async" (click)="openAdminPanel()" type="button" mat-menu-item>
				<mat-icon svgIcon="vh-Settings-v2-24"></mat-icon>
				<span>{{ 'common.admin' | resx }}</span>
			</button>

			<button class="user-menu--action-logout" (click)="auth.logout()" type="button" mat-menu-item>
				<mat-icon svgIcon="vh-logout-24"></mat-icon>
				<span>{{ 'common.logOff' | resx }}</span>
			</button>
		</div>
	</ng-template>
</mat-menu>

<mat-menu #langsMenu="matMenu">
	<ng-template matMenuContent>
		<button mat-menu-item *ngFor="let lang of languages$ | async" (click)="setLanguage(lang)">
			{{ lang.name }}
		</button>
	</ng-template>
</mat-menu>

<mat-menu #languageMenu="matMenu">
	<ng-template matMenuContent let-trigger="trigger">
		<div (mouseleave)="onMenuLeave(trigger)">
			<button mat-menu-item *ngFor="let lang of languages$ | async" (click)="setLanguage(lang)">
				{{ lang.name }}
			</button>
		</div>
	</ng-template>
</mat-menu>

<mat-menu #otherMenu="matMenu">
	<ng-template matMenuContent>
		<button (click)="refreshApp()" type="button" mat-menu-item aria-label="Open help info">
			<mat-icon svgIcon="vh-Re-v1-24"></mat-icon>
			<span>{{ 'common.refresh' | resx }}</span>
		</button>

		<button
			(click)="navLink(['/infra/changelog'], null, $event)"
			type="button"
			mat-menu-item
			aria-label="Open help info"
		>
			<mat-icon svgIcon="vh-info-24"></mat-icon>
			<span>{{ 'common.clientVersion' | resx }}</span>
		</button>

		<button (click)="openWindow(helperLink, true)" type="button" mat-menu-item aria-label="Open help info">
			<mat-icon svgIcon="vh-question-24"></mat-icon>
			<span>{{ 'common.help' | resx }}</span>
		</button>

		<button (click)="toggleMobile()" *ngIf="isMobile" type="button" mat-menu-item aria-label="Toggle Mobile SPA">
			<mat-icon svgIcon="vh-settings-v1-24"></mat-icon>
			<span>Mobile SPA</span>
		</button>
	</ng-template>
</mat-menu>

<mat-menu #settingsMenu="matMenu">
	<ng-template matMenuContent>
		<button (click)="navLink(['/user/profile-edit'], null, $event)" type="button" mat-menu-item class="">
			<mat-icon svgIcon="vh-edit-text-24"></mat-icon>
			<span>{{ 'common.editProfile' | resx }}</span>
		</button>

		<button (click)="navLink(['/user/notification-settings'], null, $event)" type="button" mat-menu-item class="">
			<mat-icon svgIcon="vh-notifications-24"></mat-icon>
			<span>{{ 'common.notifications' | resx }}</span>
		</button>

		<button (click)="navLink(['/user/substitutes'], null, $event)" type="button" mat-menu-item class="">
			<mat-icon svgIcon="vh-Participants-24"></mat-icon>
			<span>{{ 'common.assistants' | resx }}</span>
		</button>

		<!-- <button (click)="navLink(['/user/mobile-apps'], null, $event)" type="button" mat-menu-item class="">
			<mat-icon svgIcon="vh-mobile-devices-24"></mat-icon>
			<span>{{ 'common.mobileApps' | resx }}</span>
		</button> -->

		<button
			*ngIf="showUiSettings$ | async"
			(click)="toggleSidebarOpen('themeOptionsPanel')"
			type="button"
			mat-menu-item
			class=""
		>
			<mat-icon svgIcon="vh-Settings-v2-24"></mat-icon>
			<span>{{ 'common.uisettings' | resx }}</span>
		</button>

		<button (click)="navLink(['/user/misc'], null, $event)" type="button" mat-menu-item class="">
			<mat-icon svgIcon="vh-settings-more-24"></mat-icon>
			<span>{{ 'common.otherSettings' | resx }}</span>
		</button>
	</ng-template>
</mat-menu>

<ng-template #coworkerSearch>
	<vh-contacts-search class="search-coworkers-panel-side mat-elevation-z1" (hide)="hidePanel()"></vh-contacts-search>
</ng-template>

<ng-template #commonSearch>
	<div class="search-coworkers-panel-side search-task-panel mat-elevation-z1" id="common-search-panel">
		<div class="search-coworkers-panel-side__search search-task-panel">
			<mat-form-field>
				<mat-label>{{ 'common.quickSearch' | resx }}</mat-label>
				<input
					(input)="commonSearchHandler($event.target.value)"
					matInput
					tabIndex="0"
					#inputSearch
					autocomplete="off"
					(keydown.enter)="searchQuery(inputSearch.value, $event)"
					(keydown.arrowdown)="onKeyDownArrowInTaskSearch($event)"
				/>
				<mat-icon
					*ngIf="commonSearchInputHasValue$ | async"
					(click)="inputSearch.value = ''; commonSearchHandler('')"
					matSuffix
					class="s-22 secondary-text"
					>close</mat-icon
				>
			</mat-form-field>
		</div>
		<div class="search-coworkers-panel-side__footer">
			<div class="search-actions mt-10">
				<a
					class="search-action"
					[routerLink]="['/search']"
					[queryParams]="{ mode: 'advanced', q: inputSearch.value }"
					(click)="hidePanel()"
					>{{ 'common.searchExtended' | resx }}</a
				>
				<!-- <a
					class="search-action"
					[routerLink]="['/search']"
					[queryParams]="{ mode: 'attachment' }"
					(click)="hidePanel()"
					>{{ 'common.searchByAttachments' | resx }}</a
				> -->

				<vh-control-toggle
					class="link-color"
					[label]="'common.completed' | resx"
					[formControl]="searchClosedTaskToggle"
				></vh-control-toggle>
			</div>
		</div>
		<div #userSearchResultContainer class="search-coworkers-panel-side__history" [class.mobile]="isMobile">
			<mat-selection-list [multiple]="false" (selectionChange)="selectItem($event)">
				<ng-container *ngFor="let task of tasksHistory$ | async; let isLast = last">
					<mat-list-option [value]="{ value: task, type: 'task' }">
						<vh-task-avatar mat-list-icon [task]="task" [sideLengthPx]="40"></vh-task-avatar>
						<div mat-line>{{ task.name | striphtml }}</div>
						<div *ngIf="task.key || task.taskId" mat-line class="secondary-text">
							{{ task.subcatDescription ? task.subcatDescription + '&nbsp;' : '' }}#{{ task.key || task.taskId }}
						</div>
					</mat-list-option>

					<mat-divider *ngIf="!isLast" class="ml-16 mr-16"></mat-divider>
				</ng-container>
			</mat-selection-list>
		</div>
	</div>
</ng-template>

<ng-template #createTaskOptions>
	<div class="vh-layout-vertical-sidebar-mini--create-task-options">
		<button mat-menu-item (click)="createWithId(privateSubcatId$)">
			<mat-icon svgIcon="vh-task-card-only-24"></mat-icon>
			<span>{{ 'common.createTaskPersonal' | resx }}</span>
		</button>
		<button mat-menu-item (click)="createMeeting()">
			<mat-icon svgIcon="vh-calendar-24"></mat-icon>
			<span>{{ 'common.createTaskMeeting' | resx }}</span>
		</button>
		<button mat-menu-item (click)="createNewGroupChat()">
			<mat-icon svgIcon="vh-chats-24"></mat-icon>
			<span>{{ 'common.createTaskGroupChat' | resx }}</span>
		</button>
		<button *ngIf="ownChatSubcatId$ | async" mat-menu-item (click)="createWithId(ownChatSubcatId$)">
			<mat-icon svgIcon="vh-Categories-24"></mat-icon>
			<span> {{ subcatForCalendar$ | async }}</span>
		</button>
		<button mat-menu-item (click)="openSubcatPick()">
			<mat-icon svgIcon="vh-Categories-24"></mat-icon>
			<span>{{ 'common.createTaskSelectCategory' | resx }}</span>
		</button>
	</div>
</ng-template>

<ng-template #menuServices>
	<div class="menu-services-wrapper">
		<button
			(click)="service.open($event)"
			[class.active]="service.isActive()"
			class="vh-btn vh-btn-icon vh-btn--sidebar-mini service-item"
			*ngFor="let service of services$ | async"
		>
			<vh-badge
				*ngIf="service.badgeCount$ | async"
				[value]="service.badgeCount$ | async"
				[color]="badgeColor.info"
				[locale]="locale$ | async"
				style="transform: translate(-22px, 2px)"
			></vh-badge>
			<div>
				<mat-icon *ngIf="service.matIcon; else vhIcon" [svgIcon]="service.matIcon"></mat-icon>
				<ng-template #vhIcon>
					<vh-icon [name]="service.vhIcon" size="md"></vh-icon>
				</ng-template>
				<div class="service-name ellipsis">{{ service.name | resx }}</div>
			</div>
		</button>
	</div>
</ng-template>
