<vh-modal-base [title]="'common.changeAvatar' | resx" (cancel)="onCancel()" (ok)="onOk()">
	<vh-common-progress-bar></vh-common-progress-bar>
	<div class="pt-16 flex flex-center-y">
		<vh-task-avatar
			[task]="dialogData?.task"
			[sideLengthPx]="69"
			[style.display]="'block'"
			[biggerAvatarUserOrId]="dialogData?.lastSender"
			[taskAvatarDataUrl]="(newAvatarBase64$ | async) || dialogData?.taskAvatarDataUrl"
			[subscribersMode]="subscribersMode$ | async"
			[coloredText]="dialogData?.coloredText"
			[chatAvatarDeleted]="chatAvatarDeleted$ | async"
			[taskAvatarDeleted]="taskAvatarDeleted$ | async"
		></vh-task-avatar>
	</div>
	<div
		class="mt-10 font-500 clamped-3 text-center w-max-100"
		*ngIf="dialogData?.task?.taskText || dialogData?.coloredText"
	>
		{{ dialogData?.task?.taskText || dialogData?.coloredText }}
	</div>
	<div class="clamped-1 pt-4 mb-4 text-center" [style.height.px]="20">
		<span *ngIf="error$ | async as err" class="base-danger-color">{{ err }}</span>
	</div>
	<div modal-base-before-ok>
		<button class="vh-btn vh-btn--warn mr-8" [disabled]="!canRemoveAvatar" (click)="onRemove()">
			{{ 'common.delete' | resx }}
		</button>
		<button class="vh-btn vh-btn--success mr-8" (click)="loadNewAvatar()">{{ 'common.download' | resx }}</button>
	</div>
</vh-modal-base>
