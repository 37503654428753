import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AuthService } from '../../auth';
import { CookieService, SystemCookies } from '../../cookie';
import { AbstractLogger, LoggerFactory } from '../../diagnostics';

@Injectable({
	providedIn: 'root',
})
export class IOSRestoreCookieGuard {
	constructor(
		protected readonly platform: Platform,
		protected readonly cookies: CookieService,
		protected readonly auth: AuthService,
		logger: LoggerFactory
	) {
		this._logger = logger.createLogger('IOSRestoreCookieGuard');
	}

	private _logger: AbstractLogger;

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (this.platform.IOS /*&& Boolean(navigator['standalone'])*/) {
			this.cookies.set(SystemCookies.auth1Forma, this.auth.token, undefined, '/');
			this._logger.forceLogInfo('auth cookie restored');
		}
		return of(true);
	}
}
