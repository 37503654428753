import { NgModule, ModuleWithProviders } from '@angular/core';

import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { FilterPipe } from './filter.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlainTextPipe, HtmlToPlainText } from './htmlToPlaintext.pipe';
import { KeysPipe } from './keys.pipe';
import { ModifyToDatePipe } from './modifyToDate.pipe';
import { UppercasePipe } from './uppercase.pipe';
import { VhShortTextPipe } from './vhShortText.pipe';
import { TimeAgoPipeModule } from './time-ago.pipe';
import { PropPipe } from './prop.pipe';
import { StringToHslColorPipe } from './string-to-hsl-color.pipe';
import { IntlFormatDatePipe, IsTodayDatePipe } from './intl-format-date.pipe';
import { UserStatusIsPipe } from './user-status-is.pipe';
import { UserStatusToCssClassesPipe } from './user-status-to-css-classes.pipe';
import { VhTimePipe } from './time.pipe';
import { FilesLinkPipesModule } from './file-link.pipe';
import { CommentViewersPipe } from './comment-viewers.pipe';
import { HexByBackgroundPipe } from './colorByBackground.pipe';
import { StripHtmlPipeModule } from './stripHtml.pipe';
import { ChatUserAvatarLinkPipeModule } from './user-avatar-link.pipe';
import { ChatAvatarLetterPipeModule } from './color-text.pipe';
import { PhoneFormatPipeModule } from './phone-format.pipe';

@NgModule({
	exports: [
		KeysPipe,
		GetByIdPipe,
		HtmlToPlainTextPipe,
		FilterPipe,
		CamelCaseToDashPipe,
		HexByBackgroundPipe,
		UppercasePipe,
		ModifyToDatePipe,
		VhShortTextPipe,
		TimeAgoPipeModule,
		PropPipe,
		StringToHslColorPipe,
		IntlFormatDatePipe,
		UserStatusIsPipe,
		UserStatusToCssClassesPipe,
		VhTimePipe,
		FilesLinkPipesModule,
		CommentViewersPipe,
		IsTodayDatePipe,
		StripHtmlPipeModule,
		ChatUserAvatarLinkPipeModule,
		ChatAvatarLetterPipeModule,
		PhoneFormatPipeModule,
	],
	declarations: [
		KeysPipe,
		GetByIdPipe,
		HtmlToPlainTextPipe,
		FilterPipe,
		CamelCaseToDashPipe,
		HexByBackgroundPipe,
		UppercasePipe,
		ModifyToDatePipe,
		VhShortTextPipe,
		PropPipe,
		StringToHslColorPipe,
		IntlFormatDatePipe,
		UserStatusIsPipe,
		UserStatusToCssClassesPipe,
		VhTimePipe,
		CommentViewersPipe,
		IsTodayDatePipe,
	],
})
export class PipesCommonModule {
	static forRoot(): ModuleWithProviders<PipesCommonModule> {
		return {
			ngModule: PipesCommonModule,
			providers: [
				CamelCaseToDashPipe,
				HexByBackgroundPipe,
				FilterPipe,
				GetByIdPipe,
				KeysPipe,
				{
					provide: HtmlToPlainText,
					useClass: HtmlToPlainTextPipe,
				},
			],
		};
	}
}
