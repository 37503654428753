import { Injectable } from '@angular/core';
// import { plainToClass } from 'class-transformer';
// import { ClassType } from 'class-transformer/ClassTransformer';
import { ClassTransformService } from './abstract';
import { NotImplementedError } from '../errors';

@Injectable()
export class ClassTransformServiceImpl implements ClassTransformService {
	// plainToClass<T, V>(type: ClassType<T>, plain: V) {
	// 	return plainToClass<T, V>(type, plain);
	// }
	plainToClass<T, V>(type: new (...args: any[]) => T, plain: V): T {
		throw new NotImplementedError();
	}
}
