import {
	AfterViewInit,
	Directive,
	ElementRef,
	EmbeddedViewRef,
	EventEmitter,
	Input,
	NgModule,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewContainerRef,
	inject,
} from '@angular/core';

@Directive({ selector: '[vhLazyRender]' })
export class LazyRenderDirective implements AfterViewInit, OnInit, OnDestroy {
	elRef: ElementRef<HTMLElement> = inject(ElementRef);
	template: TemplateRef<any> = inject(TemplateRef, { optional: true });
	vcr: ViewContainerRef = inject(ViewContainerRef);
	emb: EmbeddedViewRef<any>;
	observer: IntersectionObserver;

	// eslint-disable-next-line @angular-eslint/no-output-rename
	@Output('vhLazyRender')
	vhLazyRender = new EventEmitter();

	hidden = false;

	get el() {
		return this.elRef.nativeElement;
	}

	ngOnInit(): void {
		this.hide();
	}

	ngAfterViewInit(): void {
		this.observer = new IntersectionObserver(e => {
			if (e[0].intersectionRatio === 0) {
				// element is hidden
				this.hide();
			} else {
				// element is visible
				setTimeout(() => {
					this.show();
				});
			}
		});
		setTimeout(() => {
			this.observer.observe(this.el.parentElement);
		});
	}

	ngOnDestroy(): void {
		this.observer.disconnect();
	}

	hide() {
		this.hidden = true;
		if (this.emb) {
			this.emb.destroy();
			this.emb = null;
		}
	}

	show() {
		this.hidden = false;
		if (this.template) {
			this.emb = this.vcr.createEmbeddedView(this.template);
		}
		this.vhLazyRender.emit(this.elRef);
		this.observer.disconnect();
	}
}

@NgModule({
	exports: [LazyRenderDirective],
	declarations: [LazyRenderDirective],
})
export class LazyRenderDirectiveModule {}
