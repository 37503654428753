import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, NgZone, Input } from '@angular/core';
import { rxHandler } from '@valhalla/utils';
import { AbstractLogger, LoggerFactory, ViewDestroyStreamService } from '@valhalla/core';
import { from, Observable, of } from 'rxjs';
import { debounceTime, map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { IAppDefinition } from '../db';
import { ApplicationsManagerProvider } from '../manager';
import { AppCardCommand, AppCardCommandType, GroupView, ISearchGroupDefinition } from './group';

@Component({
	selector: 'vh-apps-search',
	templateUrl: './apps-search.component.html',
	styleUrls: ['./apps-search.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class AppsSearchComponent implements OnInit {
	constructor(
		protected destroy$: ViewDestroyStreamService,
		protected loggerFactory: LoggerFactory,
		protected appsManager: ApplicationsManagerProvider,
		protected zone: NgZone
	) {}

	@Input()
	ngZone: NgZone;

	protected logger: AbstractLogger = this.loggerFactory.createLogger('AppsSearchComponent');

	readonly onSearch = rxHandler<string>();
	readonly onSearch$ = from<string>(this.onSearch as any).pipe(debounceTime(300), takeUntil(this.destroy$));

	readonly searchResult$ = this.onSearch$.pipe(
		startWith(''),
		switchMap(textSearch => this.appsManager.selectAvailableApplications(...this.getWords(textSearch))),
		shareReplay({ refCount: true }),
		takeUntil(this.destroy$)
	);

	/** this have to get from config */
	readonly groups: ISearchGroupDefinition[] = [
		{
			order: 0,
			name: 'Приложения',
			/** pid's fro exclude */
			excludeAppPid: ['category', 'report', 'portal', 'task-hierarchy'],
			viewOptions: {
				view: GroupView.grid,
			},
		},
		{
			order: 1,
			name: 'Категории',
			includeAppPid: ['category'],
			viewOptions: {
				view: GroupView.list,
			},
		},
		{
			order: 2,
			name: 'Отчеты',
			includeAppPid: ['report'],
			viewOptions: {
				view: GroupView.list,
			},
		},
		{
			order: 3,
			name: 'Порталы',
			includeAppPid: ['portal'],
			viewOptions: {
				view: GroupView.list,
			},
		},
		{
			order: 4,
			name: 'Иерархии задач',
			includeAppPid: ['task-hierarchy'],
			viewOptions: {
				view: GroupView.list,
			},
		},
	];

	readonly groups$ = of(this.groups).pipe(map(gs => gs.sort((a, b) => a.order - b.order)));

	ngOnInit() {}

	getWords(text: string) {
		return String(text)
			.split(' ')
			.map(word => word.trim());
	}

	trackByGroupOrder(idx, group: ISearchGroupDefinition) {
		return group.order;
	}

	filterAppsByGroup(group: ISearchGroupDefinition): Observable<IAppDefinition[]> {
		const includePid =
			group.includeAppPid && group.includeAppPid.reduce((acc, cur) => ((acc[cur.toLowerCase()] = true), acc), {});
		const excludePid =
			group.excludeAppPid && group.excludeAppPid.reduce((acc, cur) => ((acc[cur.toLowerCase()] = true), acc), {});
		const filterByPid = ({ pid }: IAppDefinition) => {
			let result = false;
			pid = pid.toLowerCase();
			if (includePid) {
				result = includePid[pid];
			}
			if (excludePid) {
				result = !excludePid[pid];
			}
			return result;
		};
		return this.searchResult$.pipe(
			map(apps => apps.filter(filterByPid)),
			takeUntil(this.destroy$)
		);
	}

	runInZone(fn: () => any): any {
		const task = () => fn && fn();
		const zone = this.zone || this.ngZone;
		if (zone) {
			return zone.run(() => task());
		}
		return task();
	}

	onContextMenuClick(command: AppCardCommand) {
		this.runInZone(() => {
			switch (command.command) {
				case AppCardCommandType.addToQuickAppsPanel:
					this.appsManager.addToUserQuickApps({ appId: command.appInfo.id });
					break;
				case AppCardCommandType.addToFavoritesMenu:
					break;
				case AppCardCommandType.addToStartupPage:
					break;
				case AppCardCommandType.openApp:
					this.appsManager.openApplication(command.appInfo);
					break;
				default:
					break;
			}
		});
	}
}
