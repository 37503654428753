import { Injectable } from '@angular/core';
import { PlatformDetectorProvider } from '@spa/core';
import { DataHttpService } from '@spa/data/http';
import { BehaviorSubject, Subject, take } from 'rxjs';

/**
 * Stores value for displaying mobile view
 * task#1193023
 */
@Injectable({ providedIn: 'root' })
export class MobileViewService {
	constructor(readonly platform: PlatformDetectorProvider, readonly server: DataHttpService) {
		this.server.config.appSettingsAnonymousConfig$.pipe(take(1)).subscribe(config => {
			if (
				config.MobileAppSettings?.mspaByDefault === true &&
				this.platform.isMobile() &&
				!localStorage.getItem('mobileMode')
			) {
				this.mobileMode = true;
				return;
			}
			this.mobileMode = localStorage.getItem('mobileMode')
				? JSON.parse(localStorage.getItem('mobileMode'))?.value
				: false;
		});
	}

	set mobileMode(v) {
		const lsValue = { value: v };
		localStorage.setItem('mobileMode', JSON.stringify(lsValue));
		if (document.body.classList.contains('is-mobile-mode') && !v) {
			document.body.classList.remove('is-mobile-mode');
		}

		if (!document.body.classList.contains('is-mobile-mode') && v) {
			document.body.classList.add('is-mobile-mode');
		}
		if (this.platform.isMobile()) {
			this.mobileMode$.next(v);
		} else {
			this.mobileMode$.next(false);
		}
	}

	get mobileMode() {
		return this.mobileMode$.value;
	}

	set hideTabbar(v) {
		this.hideTabbar$.next(v);
	}

	get hideTabbar() {
		return this.hideTabbar$.value;
	}

	set pageTitle(v) {
		this.pageTitle$.next(v);
	}

	get pageTitle() {
		return this.pageTitle$.value;
	}

	get isMobile() {
		return this.platform.isMobile();
	}

	mobileMode$ = new BehaviorSubject<boolean>(null);
	hideTabbar$ = new BehaviorSubject<boolean>(false);
	pageTitle$ = new BehaviorSubject<string>('');
	backClick$ = new Subject<void>();
	hideMainMenuHamburgerBtn$ = new BehaviorSubject<boolean>(false);

	toggleMobile() {
		this.mobileMode = !this.mobileMode;
	}

	toggleTabbar() {
		this.hideTabbar = !this.hideTabbar;
	}

	backClick() {
		this.backClick$.next();
	}
}
