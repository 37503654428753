<ng-container *ngIf="!options?.openAsBlob">
	<video
		*ngIf="options?.isMeetingFile"
		[src]="(getFileMeetingLink(file) | urlSearch: 'fromPlayer':'true') + '#t=0.1'"
		preload="metadata"
		controls
		#video
	></video>

	<video
		#video
		*ngIf="options?.isEmailFile"
		[src]="(getFileEmailLink(file) | urlSearch: 'fromPlayer':'true') + '#t=0.1'"
		preload="metadata"
		controls
	></video>

	<video
		#video
		*ngIf="!options?.isMeetingFile && !options?.isEmailFile"
		[src]="hasFileId ? (file | fileLink | urlSearch: 'fromPlayer':'true') + '#t=0.1' : isLinkFileName ? fileName : null"
		preload="metadata"
		controls
	></video>
</ng-container>

<ng-container *ngIf="options?.openAsBlob">
	<video #video [src]="file?.src" preload="metadata" controls></video>
</ng-container>
