import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LocalizationModule } from '../../../localization';

import { AvatarModule } from '../chat-nav-avatar';
import { UserInfoPanelComponent } from './user-info-panel.component';

@NgModule({
	imports: [CommonModule, AvatarModule, MatIconModule, RouterModule, LocalizationModule],
	declarations: [UserInfoPanelComponent],
	exports: [UserInfoPanelComponent],
})
export class UserInfoPanelModule {}
