import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({ name: 'modifySize' })
export class ModifySizePipe implements PipeTransform {
	/**
	 * @param size in bytes
	 */
	transform(size: number): string {
		size = size || 0;
		const sizeGB = size / (1024 * 1024 * 1024);
		const sizeMB = size / (1024 * 1024);
		const sizeKB = size / 1024;

		if (Math.floor(sizeGB) > 0) {
			return `${sizeGB.toFixed(1)} ГБ`;
		}

		if (Math.floor(sizeMB) > 0) {
			return `${sizeMB.toFixed(0)} МБ`;
		}

		if (Math.floor(sizeKB) > 0) {
			return `${sizeKB.toFixed(0)} КБ`;
		}

		return `${size} Б`;
	}
}

@NgModule({
	declarations: [ModifySizePipe],
	exports: [ModifySizePipe],
})
export class ModifySizePipeModule {}
