<ng-container *ngIf="align === 'right' || align === 'bottom'; then more"></ng-container>

<ng-container #content></ng-container>

<ng-container *ngIf="align === 'left' || align === 'top'; then more"></ng-container>

<ng-template #more>
	<div class="vh-common-fluent-box__item vh-common-fluent-box__more-container" [class.hide]="allVisible$ | async">
		<ng-container #moreContent></ng-container>
	</div>
</ng-template>
