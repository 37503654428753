import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ModalContentAreaModule } from '@spa/common/modal-content-area';
import { ContentOverlayModule } from '../content-overlay';

import { ContentComponent } from './content.component';

@NgModule({
	imports: [CommonModule, RouterModule, ModalContentAreaModule, ContentOverlayModule],
	declarations: [ContentComponent],
	exports: [ContentComponent],
})
export class ContentModule {}
