<div class="app-card-icon">
	<img *ngIf="appInfo.iconUrl; else matIconTmpl" [src]="appInfo.iconUrl" [alt]="appInfo.name || ''" />
</div>
<div class="app-card-desc">
	<div class="app-card-desc--name">{{ appInfo.name }}</div>
	<div *ngIf="viewType === viewTypes.row" class="app-card-desc--description secondary-text">
		{{ appInfo.description || '' }}
	</div>
</div>

<ng-template #matIconTmpl>
	<mat-icon>{{ appInfo?.icon }}</mat-icon>
</ng-template>
