import { InjectionToken, inject, InjectFlags } from '@angular/core';
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';

export const RootAppUrlTestingDocumentBaseUrlInHtmlValue = new InjectionToken('imitation of document.baseURI value');

export const RootAppUrl = new InjectionToken('app root url relative domain', {
	providedIn: 'root',
	factory: () => {
		const baseUrl = inject(APP_BASE_HREF, InjectFlags.Optional);
		if (baseUrl) return baseUrl;

		const testingValue = inject(RootAppUrlTestingDocumentBaseUrlInHtmlValue, InjectFlags.Optional);
		if (testingValue) return testingValue;

		const doc = inject(DOCUMENT);
		const rootUrl = `${doc.location.protocol}//${doc.location.host}`;
		const relativeRoot = doc.baseURI ? doc.baseURI.replace(rootUrl, '') : '';
		return relativeRoot;
	},
});
