import moment, { isMoment } from 'moment';

export function formatDateTime(dateTime: Date, formatString: string): string;
export function formatDateTime(dateTime: string, formatString: string): string;
export function formatDateTime(dateTime: any, formatString: string): string {
	if (!dateTime) {
		return '';
	}
	if (isMoment(dateTime)) {
		dateTime = dateTime.toDate();
	}
	try {
		const d = dateTime instanceof Date ? dateTime : dateFromStringValhalla(dateTime);
		const isValid = isValidDate(d);
		if (isValid) {
			return formatString
				.replace(new RegExp('HH', 'g'), ('00' + d.getHours()).slice(-2))
				.replace(new RegExp('mm', 'g'), ('00' + d.getMinutes()).slice(-2))
				.replace(new RegExp('ss', 'g'), ('00' + d.getSeconds()).slice(-2))
				.replace(new RegExp('dd', 'g'), ('00' + d.getDate()).slice(-2))
				.replace(new RegExp('MM', 'g'), ('00' + (d.getMonth() + 1)).slice(-2))
				.replace(new RegExp('yyyy', 'g'), ('000' + d.getFullYear()).slice(-4))
				.replace(new RegExp('yy', 'g'), ('0' + d.getFullYear()).slice(-2));
		}
	} catch (ex) {
		return 'Invalid date';
	}

	return 'Invalid date';
}

export function getDateFromString(date: string | Date, format?: string) {
	if (!date) {
		return;
	}
	if (format) {
		return moment(date, format).toDate();
	}

	const momentFormats = [
		'DD.MM.YYYY HH:mm:ss',
		'DD.MM.YYYY HH:mm',
		'DD.MM.YYYYTHH:mm:ss.sss',
		'DD.MM.YYYYTHH:mm:ss',
		'DD.MM.YYYYTHH:mm',
		'DD.MM.YYYY',
	];

	const validFormat = momentFormats.find(f => moment(date, f).isValid());

	if (!validFormat) {
		const d = new Date(date);
		if (isValidDate(d)) {
			return d;
		} else {
			throw new Error('Invalid date format of ' + date + ' string');
		}
	}

	return moment(date, validFormat).toDate();
}

export function dateFromStringValhalla(dateStr: string) {
	dateStr = dateStr || '';
	if (!dateStr.trim()) {
		return;
	}
	let date: Date;
	if (typeof dateStr === 'string' && dateStr.indexOf('/Date(') !== -1) {
		const timeUtc = Number(dateStr.replace('/Date(', '').replace(')/', ''));
		date = new Date(timeUtc);
	} else {
		date = new Date(dateStr);
	}
	return date;
}

export function isValidDate(date: Date) {
	return date instanceof Date && !isNaN(+date);
}

export function getFirstDayOfMonth(date: Date) {
	let dateCopy;
	if (moment.isMoment(date)) {
		dateCopy = new Date(date.valueOf());
	} else {
		dateCopy = new Date(date.getTime());
	}

	dateCopy.setDate(1);
	dateCopy.setHours(0, 0, 0, 0);
	return dateCopy;
}

const optionsEqualsDateDefault = () => ({
	matchYear: true,
	matchMonth: true,
	matchDate: true,
	matchHour: true,
	matchMinutes: true,
	matchSeconds: true,
	matchMilliseconds: true,
});

export function isDateEquals(d1: Date, d2: Date, options?: Partial<typeof optionsEqualsDateDefault>) {
	if (!options) {
		return d1.getTime() === d2.getTime();
	}
	if (!d1 || !d2) {
		return false;
	}
	const matchOptions = Object.assign(optionsEqualsDateDefault(), options);
	let result = true;
	if (matchOptions.matchYear) {
		result = result && d1.getFullYear() === d2.getFullYear();
	}
	if (matchOptions.matchMonth) {
		result = result && d1.getMonth() === d2.getMonth();
	}
	if (matchOptions.matchDate) {
		result = result && d1.getDate() === d2.getDate();
	}
	if (matchOptions.matchHour) {
		result = result && d1.getHours() === d2.getHours();
	}
	if (matchOptions.matchMinutes) {
		result = result && d1.getMinutes() === d2.getMinutes();
	}
	if (matchOptions.matchSeconds) {
		result = result && d1.getSeconds() === d2.getSeconds();
	}
	if (matchOptions.matchMilliseconds) {
		result = result && d1.getMilliseconds() === d2.getMilliseconds();
	}
	return result;
}

export function isToday(date: Date) {
	if (!(date instanceof Date)) {
		return false;
	}
	const now = new Date();
	return (
		date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth() && date.getDate() === now.getDate()
	);
}

export function isTodayNew(date: Date | string) {
	const today = moment();
	const currenrDay = moment(date);

	return (
		today.date() === currenrDay.date() && today.month() === currenrDay.month() && today.year() === currenrDay.year()
	);
}

export function isTommorowNew(date: Date | string) {
	const tommorow = moment().add(1, 'days');
	const currenrDay = moment(date);

	return (
		tommorow.date() === currenrDay.date() &&
		tommorow.month() === currenrDay.month() &&
		tommorow.year() === currenrDay.year()
	);
}

export function isYesterday(date: Date | string) {
	const yesterday = moment().add(-1, 'days');
	const currenrDay = moment(date);

	return (
		yesterday.date() === currenrDay.date() &&
		yesterday.month() === currenrDay.month() &&
		yesterday.year() === currenrDay.year()
	);
}

export function isTomorrow(date: Date) {
	const tomorrow = new Date(Date.now() + 24 * 3600 * 1000);
	return isDateEquals(date, tomorrow, {
		matchMilliseconds: false,
		matchSeconds: false,
		matchMinutes: false,
		matchHour: false,
	});
}

export function isPast(date: Date) {
	return Date.now() > date.getTime();
}

export function isFuture(date: Date) {
	return Date.now() < date.getTime();
}

export function isYearEquals(y1: string | Date | number, y2: string | Date | number) {
	const d1 = typeof y1 === 'string' ? dateFromStringValhalla(y1) : new Date(y1);
	const d2 = typeof y2 === 'string' ? dateFromStringValhalla(y2) : new Date(y2);
	return isDateEquals(d1, d2, {
		matchMilliseconds: false,
		matchSeconds: false,
		matchMinutes: false,
		matchHour: false,
		matchDate: false,
		matchMonth: false,
	});
}

export function isCurrentYear(y1: string | Date | number) {
	return isYearEquals(y1, new Date());
}

export function formatToHumanDate(
	date: any,
	rsxData: Record<string, string>,
	needTime: boolean,
	userSettings: any,
	shortFormat = true,
	locale = 'ru'
) {
	if (((typeof date !== 'string' && !(date instanceof Date)) || !moment(date).isValid()) && date != null) {
		return 'Invalid date';
	}

	const humanDateFormat = userSettings.userUISettings.showDateInHumanFormat;
	if (humanDateFormat) {
		moment.locale('ru');
	}

	shortFormat = shortFormat && humanDateFormat;

	if (humanDateFormat) {
		moment.locale('ru');
	}

	const at = rsxData?.['at'] || 'В';

	const formatCurYear = needTime && !shortFormat ? 'D MMMM HH:mm' : 'D MMMM';

	const formatFullDate = needTime && !shortFormat ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';

	const yesterday = rsxData?.['yesterday'] || 'Вчера';
	const yesterdayFormat = moment(date).format('HH:mm');

	const todayFormat = moment(date).format('HH:mm');

	const tommorowFormat = moment(date).format('HH:mm');
	const tommorow = rsxData?.['tommorow'] || 'Завтра';
	const today = rsxData?.['today'] || 'Сегодня';
	const currentYearFormat = moment(date).locale(locale).format(formatCurYear);

	const fullDate = moment(date).format(formatFullDate);

	if (humanDateFormat) {
		if (isTodayNew(date)) {
			if (needTime) {
				return todayFormat === '00:00' ? today : `${today} ${at} ${todayFormat}`;
			} else {
				return `${today}`;
			}
		}

		if (isTommorowNew(date)) {
			if (needTime) {
				return tommorowFormat === '00:00' ? tommorow : `${tommorow} ${at} ${tommorowFormat}`;
			} else {
				return `${tommorow}`;
			}
		}

		if (isYesterday(date)) {
			if (needTime) {
				return yesterdayFormat === '00:00' ? yesterday : `${yesterday} ${at} ${yesterdayFormat}`;
			} else {
				return `${yesterday}`;
			}
		}

		if (isCurrentYear(date)) {
			return `${currentYearFormat}`;
		}

		return ` ${fullDate}`;
	}

	return ` ${fullDate}`;
}

export function dateToISO(d: Date) {
	return formatDateTime(d, 'yyyy-MM-ddTHH:mm:ss');
}
