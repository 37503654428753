import { IRxStore, IRxStoreConfig } from './rx-store';

export abstract class StoreManager {
	abstract setStore(store: IRxStore): IRxStore;
	abstract createStore(config: IRxStoreConfig): IRxStore;
	abstract getStore(name: string): IRxStore;
	abstract isStoreExist(name: string): boolean;
	abstract destroyStore(name: string);
	abstract destroy();
}
