import { P } from '@angular/cdk/keycodes';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector, NgZone, Type, StaticProvider, NgModuleRef, createNgModule } from '@angular/core';
import { removeDuplicate } from '@valhalla/utils';
import { BehaviorSubject, EMPTY, from, Observable, of, zip, forkJoin, fromEvent, race } from 'rxjs';
import { filter, first, map, mergeMap, mapTo, take } from 'rxjs/operators';

import { IS_PRODUCTION } from '../configuration';
import { AbstractLogger, LoggerFactory } from '../diagnostics/logger';
import { UrlProvider } from '../url';
import { UniversalLoaderProvider } from './abstract';
import { Scripts, ResourceLoadingStatus, Styles } from './resources.enum';

@Injectable()
export class UniversalLoaderProviderImpl implements UniversalLoaderProvider {
	constructor(
		private readonly _injector: Injector,
		private readonly _zone: NgZone,
		private readonly _url: UrlProvider,
		@Inject(DOCUMENT) private readonly _document: Document,
		logger: LoggerFactory,
		@Inject(IS_PRODUCTION) readonly isProd: boolean
	) {
		this._logger = logger.createLogger('UniversalLoaderService');
	}

	private _scriptsLoadedStatus: Map<Scripts, BehaviorSubject<ResourceLoadingStatus>> = new Map();
	private _logger: AbstractLogger;

	private _injected: string[] = [];

	readonly loadingStatuses = ResourceLoadingStatus;

	/**
	 * Загружает фабрику angular lazy модуля, указанного в angular.json
	 * @param modulePath путь вида: "{путь до файла модуля без расширения}#{имя класса модуля}"
	 * @returns Promise<NgModuleRef>
	 * @example loadModule('src/app/common/web-components/button/button.module#ButtonCommonModule')
	 */
	loadNgModule<T = any>(modulePath, injector?: Injector): any {
		throw new Error('loadNgModule is deprecated, use loadModule instead, lazyModules is deprecated, use import()!');
	}

	loadModule<T>(
		loader: Promise<T>,
		injector?: Injector,
		providers?: StaticProvider[],
		name?: string
	): Observable<NgModuleRef<T>> {
		return from(loader).pipe(
			map(mod => {
				const modInjector = Injector.create({
					providers,
					name,
					parent: injector,
				});
				return createNgModule(mod as Type<T>, modInjector);
			})
		);
	}

	injectScriptsOnce(...paths: string[]): Observable<any> {
		const toInject = paths.filter(x => this._injected.indexOf(x) == -1);

		if (toInject.length) {
			const obs = this.injectScripts(...toInject);
			paths.forEach(x => this._injected.push(x));
			return obs;
		} else {
			return new Observable(observer => {
				observer.next(0 as any);
				observer.complete();
			});
		}
	}

	injectScripts(...paths: string[]): Observable<any> {
		const streams = paths.map(path => {
			return new Observable(observer => {
				const tag = this._document.createElement('script');
				tag.type = 'text/javascript';
				tag.src = path;
				tag.onerror = err => {
					observer.error(err);
					observer.complete();
				};
				tag.onload = () => {
					observer.next(0 as any);
					observer.complete();
				};
				this._document.body.appendChild(tag);
			});
		});
		return zip(...streams);
	}

	loadStyles(...styles: Styles[]): Observable<boolean> {
		return forkJoin(styles.map(style => this.addStyle(style))).pipe(mapTo(true), take(1));
	}

	loadScripts(...scripts: Scripts[]): Observable<boolean> {
		return this._zone.runOutsideAngular(() => {
			const needLoad: Scripts[] = [];
			removeDuplicate(scripts).forEach(script => {
				const loaded$ = this._scriptsLoadedStatus.get(script);
				if (!loaded$) {
					needLoad.push(script);
				} else {
					const status = loaded$.getValue();
					if (status === ResourceLoadingStatus.wait) {
						needLoad.push(script);
					}
				}
			});
			if (needLoad.length === 0) return of(true);

			this._logger.info('request for scripts', needLoad.join());
			const waitForScriptLoadedObservables: Observable<ResourceLoadingStatus>[] = needLoad.reduce(
				(acc: Observable<ResourceLoadingStatus>[], script) => {
					let loaded$ = this._scriptsLoadedStatus.get(script);
					if (loaded$ === undefined) {
						loaded$ = new BehaviorSubject(ResourceLoadingStatus.wait);
						this._scriptsLoadedStatus.set(script, loaded$);
					}
					acc.push(
						loaded$.pipe(
							filter(status => status === ResourceLoadingStatus.loaded || status === ResourceLoadingStatus.error),
							first()
						)
					);
					return acc;
				},
				[]
			);
			this.checkLoadingQueue();
			return forkJoin(waitForScriptLoadedObservables).pipe(map(result => true));
		});
	}

	loadSignalR() {
		return this.loadScripts(Scripts.jquery).pipe(
			mergeMap(result => (result ? this.loadScripts(Scripts.signalR) : EMPTY)),
			mergeMap(result => (result ? this.loadScripts(Scripts.signalRHubs) : EMPTY))
			// mergeMap(result => (result ? this.loadScripts(Scripts.iwc) : EMPTY)),
			// mergeMap(result => (result ? this.loadScripts(Scripts.signalRIwcPatch) : EMPTY)),
			// mergeMap(result => (result ? this.loadScripts(Scripts.signalRIwc) : EMPTY))
		);
	}

	getScriptPath(script: Scripts) {
		const isProd = this.isProd,
			scriptsPath = 'assets/scripts';
		let bundleName;
		switch (script) {
			case Scripts.jquery:
				bundleName = isProd ? 'jquery/jquery-3.6.0.min.js' : 'jquery/jquery-3.6.0.js';
				return this._url.getUrl(this._url.joinWithSlash(scriptsPath, bundleName), isProd);
			case Scripts.signalR:
				bundleName = isProd ? 'signalr/jquery.signalR-2.3.0.min.js' : 'signalr/jquery.signalR-2.3.0.js';
				return this._url.getUrl(this._url.joinWithSlash(scriptsPath, bundleName), isProd);
			case Scripts.signalRHubs:
				return this._url.getUrl('/signalr/hubs');
			case Scripts.iwc:
				bundleName = isProd ? 'iwc/iwc-all.min.js' : 'iwc/iwc-all.js';
				return this._url.getUrl(this._url.joinWithSlash(scriptsPath, bundleName), isProd);
			case Scripts.signalRIwcPatch:
				bundleName = 'signalr/signalr-patch.js';
				return this._url.getUrl(this._url.joinWithSlash(scriptsPath, bundleName), isProd);
			case Scripts.signalRIwc:
				bundleName = 'signalr/iwc-signalr.js';
				return this._url.getUrl(this._url.joinWithSlash(scriptsPath, bundleName), isProd);
			default:
				throw new Error(`script ${script} is not registered`);
		}
	}

	protected checkLoadingQueue() {
		this._scriptsLoadedStatus.forEach((status$, script) => {
			if (status$.getValue() === ResourceLoadingStatus.wait) {
				this.addScript(status$, script);
			}
		});
	}

	protected addScript(status$: BehaviorSubject<ResourceLoadingStatus>, script: Scripts) {
		const tag = this._document.createElement('script');
		tag.type = 'text/javascript';
		tag.src = this.getScriptPath(script);
		tag.defer = true;
		tag.onload = () => {
			status$.next(ResourceLoadingStatus.loaded);
			this._logger.info(`🍻  script ${script} loaded  🚀`);
		};
		tag.onerror = err => {
			status$.next(ResourceLoadingStatus.error);
			this._logger.error(`script ${script} loading fail`, err);
		};
		this._document.body.appendChild(tag);
	}

	removeStyle(stylePath: string) {
		const id = `lazy-style-${stylePath}`;

		const el = this._document.getElementById(id);
		if (el) {
			el.parentElement.removeChild(el);
		}
	}

	protected addStyle(stylePath: string): Observable<boolean> {
		const id = `lazy-style-${stylePath}`;
		if (this._document.getElementById(id)) {
			return of(true);
		}
		return new Observable(observer => {
			const style = this._document.createElement('link');
			style.id = id;
			style.rel = 'stylesheet';
			style.href = stylePath;
			style.onload = () => {
				observer.next(true);
				observer.complete();
			};
			style.onerror = err => {
				observer.error(err);
			};
			this._document.head.appendChild(style);
		});
	}
}
