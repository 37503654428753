import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ReplacePipeModule } from '@spa/common/pipes/replace.pipe';
import { LocalizationModule } from '@spa/localization';
import { IFrameViewerCommonModule } from '@valhalla/common/components/iframe-viewer';

import { ModalWindowsService } from './abstract';
import { FacadeModalConfirmComponent } from './confirm/modal-confirm.component';
import { FacadeModalErrorComponent } from './error/modal-error.component';
import { FileViewerModule } from './file-viewer';
import { FacadeModalInfoComponent } from './info/modal-info.component';
import { ModalBaseModule } from './modal-base';
import { FacadeModalIframeComponent } from './modal-iframe/modal-iframe.component';
import { ModalWindowsServiceImpl } from './modal-windows.service';
import { FacadeModalPromptComponent } from './prompt/modal-prompt.component';
import { UiMonacoEditorModule } from '@spa/common/components/monaco-editor';

@NgModule({
	imports: [
		CommonModule,
		MatSnackBarModule,
		MatDialogModule,
		MatIconModule,
		MatToolbarModule,
		IFrameViewerCommonModule,
		MatInputModule,
		TextFieldModule,
		FileViewerModule,
		LocalizationModule,
		ModalBaseModule,
		ReplacePipeModule,
		UiMonacoEditorModule,
	],
	exports: [MatSnackBarModule, MatDialogModule],
	declarations: [
		FacadeModalIframeComponent,
		FacadeModalErrorComponent,
		FacadeModalInfoComponent,
		FacadeModalConfirmComponent,
		FacadeModalPromptComponent,
	],
	providers: [
		{
			provide: ModalWindowsService,
			useClass: ModalWindowsServiceImpl,
		},
	],
})
export class ModalsModule {}
