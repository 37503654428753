import { Database, IDatabaseDescription, IEntity, ITable } from '../db/indexed';

export interface IMobileViewItem<T = any> extends IEntity {
	key: string;
	value: T;
}

export class MobileViewDatabase extends Database {
	readonly containers: ITable<IMobileViewItem>;

	getDescription(): IDatabaseDescription {
		return {
			databaseName: 'mobile-view-store',
			versions: {
				1: {
					schema: {
						containers: '&key',
					},
				},
			},
		};
	}
}
