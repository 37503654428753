import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	ViewEncapsulation,
} from '@angular/core';

import { MatThemePaletteEnum } from '../../material';
import { MatProgressModeEnum } from './mat-progress-mode.enum';
import { ProgressBarCommonService } from './progress-bar.service';

@Component({
	selector: 'vh-common-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ProgressBarCommonService],
})
export class ProgressBarCommonComponent {
	constructor(
		readonly progress: ProgressBarCommonService,
		readonly cdr: ChangeDetectorRef,
		readonly elRef: ElementRef<HTMLElement>
	) {
		progress.change$.subscribe(() => cdr.detectChanges());
	}

	@Input()
	set bufferValue(value: number) {
		this.progress.setBufferValue(value);
	}
	@Input()
	set color(value: MatThemePaletteEnum) {
		this.progress.setColor(value);
	}
	@Input()
	set mode(value: MatProgressModeEnum) {
		this.progress.setMode(value);
	}
	@Input()
	set value(value: number) {
		this.progress.setValue(value);
	}
	@Input()
	set visible(value: boolean) {
		if (value) {
			this.progress.show();
		} else {
			this.progress.hide();
		}
	}
}
