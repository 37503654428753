import { Provider, Injector } from '@angular/core';
import { StoreManager, LoggerFactory, ConfigurationProvider, UrlProvider } from '@valhalla/core';

import { NavigationEffectDependencies } from './effects';
import { NavigationFeatureProvider, NavigationFeatureProviderImpl } from './feature-provider';
import { NavigationDataProvider, NavigationDataProviderService } from './providers';
import { getOrCreateNavigationStore } from './store-factory';
import { STORE_FACADE_NAVIGATION } from './ng-tokens';

export function facadeStoreFactory(
	manager: StoreManager,
	deps: NavigationEffectDependencies,
	config: ConfigurationProvider
) {
	return getOrCreateNavigationStore(manager, deps, !config.isProd);
}

export function effectDependencyFactory(
	dataProvider: NavigationDataProvider,
	urlProvider: UrlProvider,
	loggerFactory: LoggerFactory,
	injector: Injector
) {
	const effectDependency: NavigationEffectDependencies = {
		dataProvider: dataProvider,
		urlProvider: urlProvider,
		loggerFactory: loggerFactory,
		injector: injector,
	};
	return effectDependency;
}

export const FEATURE_FACADE_NAVIGATION_PROVIDERS: Provider[] = [
	NavigationDataProviderService,
	{
		provide: NavigationDataProvider,
		useExisting: NavigationDataProviderService,
	},
	{
		provide: NavigationEffectDependencies,
		useFactory: effectDependencyFactory,
		deps: [NavigationDataProvider, UrlProvider, LoggerFactory, Injector],
	},
	{
		provide: STORE_FACADE_NAVIGATION,
		useFactory: facadeStoreFactory,
		deps: [StoreManager, NavigationEffectDependencies, ConfigurationProvider],
	},
	NavigationFeatureProviderImpl,
	{
		provide: NavigationFeatureProvider,
		useExisting: NavigationFeatureProviderImpl,
	},
];
