import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, shareReplay } from 'rxjs/operators';
import { CACHE_REQUEST_CONFIG, ICacheRequestConfig } from './cache-request-config';

import { HttpRequestCache } from './http-request-cache';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
	constructor(
		protected cache: HttpRequestCache,
		@Optional() @Inject(CACHE_REQUEST_CONFIG) protected config: ICacheRequestConfig
	) {
		this.urlsToCache = Object.keys(config || {}).map(url => url.trim());
	}

	protected urlsToCache: string[] = [];

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// processing only GET requests
		if (request.method !== 'GET') {
			return next.handle(request);
		}

		// const needCache = this.needCache(request);
		// if (!needCache) {
		// 	return next.handle(request);
		// }

		// if the request is not cached yet
		if (!this.cache.has(request)) {
			// we should create a new request
			const response = next.handle(request).pipe(
				// when the request is completed we should clean cache
				finalize(() => this.cache.delete(request)),
				// and don't forget to share the Observable between subscribers
				shareReplay({ refCount: true, bufferSize: 1 })
			);
			// after that we put the request into the cache
			this.cache.set(request, response);
		}

		return this.cache.get(request);
	}

	needCache(request: HttpRequest<any>) {
		const need = this.urlsToCache.some(u => request.urlWithParams.indexOf(u) !== -1);
		return need;
	}
}
