import { map, Observable, of, take } from 'rxjs';
import { ExtParamBase } from './ext-param-base';

export type ExtParamSelectValueType = {
	value: any;
	text: string;
};

export class ExtParamCombobox extends ExtParamBase<ExtParamSelectValueType> {
	_saveImmidiateAfterValueChange = true;

	search(filter: string, skip = 0, take = 50) {
		return this.server.ep
			.getEpSelectValues({
				extParamId: this.id,
				isFromSubcatTasks: false,
				skip: skip,
				sourceBlockId: null,
				subcatId: this.ctxSubcatId,
				take: take,
				taskId: this.ctxTaskId,
				text: filter,
			})
			.pipe(map(res => res?.data));
	}

	equalsValue(a: ExtParamSelectValueType, b: ExtParamSelectValueType) {
		if (typeof a === 'string') {
			if (typeof b === 'string') {
				return a === b;
			}

			return a === b?.value;
		}

		return a?.value === b?.value;
	}

	convertForUpdateExtParamInTask() {
		return `#n${this.id}#v${this.value?.value || ''}`;
	}

	convertForUpdateExtParamInNewTask(): string {
		return this.value?.value;
	}

	getValueForCopy() {
		return this.value?.value ? String(this.value?.value) : String(this.value);
	}

	setCopiedValue(value) {
		this.setSearchContext(value);
	}

	convertForSaveInNewTaskAsync(): Observable<any> {
		if (typeof this.convertForSaveInNewTaskAsyncMiddleware === 'function') {
			return this.convertForSaveInNewTaskAsyncMiddleware(this).pipe(take(1));
		}
		return of(typeof this.value === 'string' ? this.value : this.value?.value || '');
	}
}
