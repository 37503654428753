import { CommonModule } from '@angular/common';
import { FeatherIconsModule } from '@spa/common/feather-icons';
import { NgModule } from '@angular/core';

import { NavPanelReportsComponent } from './nav-panel-reports.component';
import { FavoriteExpandListModule } from '../favorites-panel/favorite-expand-list';
import { MatIconModule } from '@angular/material/icon';
import { SidebarHeaderModule } from '../sidebar-header/sidebar-header.module';
import { LocalizationModule } from '../../../../localization';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ContextMenuModule } from '@spa/common/context-menu';

@NgModule({
	imports: [
		CommonModule,
		FavoriteExpandListModule,
		FeatherIconsModule,
		MatIconModule,
		SidebarHeaderModule,
		LocalizationModule,
		MatMenuModule,
		ContextMenuModule,
	],
	exports: [NavPanelReportsComponent],
	declarations: [NavPanelReportsComponent],
	providers: [],
})
export class NavPanelReportsModule {}
