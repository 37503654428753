<div class="panel-wrapper">
	<ng-container *ngIf="userInfo">
		<div class="panel-header">
			<button class="vh-btn vh-btn-icon vh-btn--transparent close-btn" (click)="close.emit()">
				<mat-icon class="s-20">close</mat-icon>
			</button>

			<div class="header-avatar">
				<vh-chat-nav-avatar
					mat-list-icon
					[userData]="userInfo"
					[coloredText]="userInfo?.displayName"
					[sideLengthPx]="80"
				></vh-chat-nav-avatar>
			</div>
			<div class="header-name">
				<a (click)="navigateCommand(['user/profile', userId || userInfo?.userId], $event)" class="grey-50-fg">{{
					userInfo?.displayName
				}}</a>
				<ng-container *ngIf="appointment">
					<div>{{ appointment }}</div>
				</ng-container>
			</div>
		</div>
		<div class="panel-body">
			<div class="user-info fuse-card">
				<table class="simple">
					<tbody>
						<tr *ngIf="userInfo?.cellPhone">
							<td><mat-icon color="accent" class="s-20">phone</mat-icon></td>
							<td>
								<a href="tel:+{{ userInfo?.cellPhone }}">
									<span>{{ userInfo?.cellPhone }}</span>
								</a>
							</td>
						</tr>

						<tr *ngIf="userInfo?.email">
							<td><mat-icon color="accent" class="s-20">mail</mat-icon></td>
							<td>
								<a href="mailto:{{ userInfo?.email }}">{{ userInfo?.email }}</a>
							</td>
						</tr>

						<tr *ngIf="userInfo?.homePhone">
							<td><mat-icon color="accent" class="s-20">phone</mat-icon></td>
							<td>
								<a href="tel:{{ userInfo?.homePhone }}">
									<span>{{ userInfo?.homePhone }}</span>
								</a>
								<span>{{ userInfo?.homePhoneAdditional ? ' доб. ' + userInfo?.homePhoneAdditional : '' }}</span>
							</td>
						</tr>

						<tr *ngIf="userInfo?.phone">
							<td><mat-icon color="accent" class="s-20">phone</mat-icon></td>
							<td>
								<a href="tel:{{ userInfo?.phone }}">
									<span>{{ userInfo?.phone }}</span>
								</a>
								<span>{{ userInfo?.phoneAdditional ? ' доб. ' + userInfo?.phoneAdditional : '' }}</span>
							</td>
						</tr>

						<tr *ngIf="userInfo?.phone2">
							<td><mat-icon color="accent" class="s-20">phone</mat-icon></td>
							<td>
								<a href="tel:{{ userInfo?.phone2 }}">
									<span>{{ userInfo?.phone2 }}</span>
								</a>
								<span>{{ userInfo?.phone2Additional ? ' доб. ' + userInfo?.phone2Additional : '' }}</span>
							</td>
						</tr>

						<tr *ngIf="userInfo?.phone3">
							<td><mat-icon color="accent" class="s-20">phone</mat-icon></td>
							<td>
								<a href="tel:{{ userInfo?.phone3 }}">
									<span>{{ userInfo?.phone3 }}</span>
								</a>
								<span>{{ userInfo?.phone3Additional ? ' доб. ' + userInfo?.phone3Additional : '' }}</span>
							</td>
						</tr>

						<!-- <tr *ngIf="userInfo?.fax">
							<td><mat-icon color="accent" class="s-20">print</mat-icon></td>
							<td>
								<a href="tel:{{ userInfo?.fax }}">
									<span>{{ userInfo?.fax }}</span>
								</a>
							</td>
						</tr> -->

						<tr *ngIf="orgUnit">
							<td><mat-icon color="accent" class="s-20">group</mat-icon></td>
							<td>{{ orgUnit.name }}</td>
						</tr>

						<tr *ngIf="userInfo?.directors?.length">
							<td><mat-icon color="accent" class="s-20">groups</mat-icon></td>
							<td>
								<ng-container *ngFor="let director of userInfo?.directors">
									<div>
										<a (click)="navigateCommand(['user/profile', director.userId], $event)">{{
											director.displayName
										}}</a>
									</div>
								</ng-container>
							</td>
						</tr>

						<tr *ngIf="spaBoot && !(isSessionUser$ | async)">
							<td><mat-icon class="s-20" color="accent" fontIcon="fa-comments-o" fontSet="fa"></mat-icon></td>
							<td>
								<a (click)="openChatWithUser()">Перейти в чат</a>
							</td>
						</tr>

						<tr *ngIf="getJitsiRoom()">
							<td><mat-icon svgIcon="vh-video-call-24" class="s-20" color="accent"></mat-icon></td>
							<td>
								<a href="{{ getJitsiRoom() }}" (click)="jitsiLinkHandler(); $event.preventDefault()" is="vh-link">
									{{ 'common.videoConference' | resx }}</a
								>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</ng-container>
</div>
