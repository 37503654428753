import { GroupGlobalEventHandler } from './type';

function tryRun(fn: Function) {
	try {
		fn();
	} catch (err) {
		console.error(err);
	}
}

export const groupHandler: GroupGlobalEventHandler = (...handlers) => {
	return injector => {
		const disposes = [];

		handlers.forEach(handler => {
			tryRun(() => {
				disposes.push(handler(injector));
			});
		});

		return () => {
			disposes.forEach(dispose => tryRun(() => dispose()));
		};
	};
};
