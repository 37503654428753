import { CdkPortalOutletAttachedRef, ComponentPortal, DomPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	Injector,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ViewDestroyStreamService } from '@valhalla/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mapTo, take, takeUntil } from 'rxjs/operators';

import { MODAL_CONTENT_CONTEXT } from './context-data-token';
import { ModalContentAreaRegisterService } from './modal-content-area-register.service';
import { IModalContentAreaComponent, IModalContentAreaOpenOptions } from './modal-content-area.component-base';
import { MODAL_CONTENT_REF } from './modal-ref-token';

@Component({
	selector: 'vh-modal-content-area',
	templateUrl: './modal-content-area.component.html',
	styleUrls: ['./modal-content-area.component.scss'],
	providers: [ViewDestroyStreamService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class ModalContentAreaComponent implements OnInit, AfterViewInit, IModalContentAreaComponent, OnDestroy {
	constructor(
		protected readonly destroy$: ViewDestroyStreamService,
		protected readonly reg: ModalContentAreaRegisterService,
		protected readonly elRef: ElementRef<HTMLElement>,
		protected readonly vcr: ViewContainerRef,
		protected readonly cdr: ChangeDetectorRef,
		protected readonly injector: Injector,
		protected readonly router: Router
	) {}

	@Input()
	name = 'default';

	@HostBinding('class.vh-modal-content-area')
	@HostBinding('class.mat-elevation-z16')
	@HostBinding('class.vh-widget-container')
	hostClassSelector = true;

	@ViewChild('portalContent')
	portalContent: ElementRef<HTMLElement>;

	@ViewChild('hiddenContainer')
	hiddenContainer: ElementRef<HTMLElement>;

	@HostBinding('attr.id')
	get id() {
		return this.prefixName(this.name);
	}

	get isOpen() {
		return this.visible$.value;
	}

	readonly visible$ = new BehaviorSubject(false);
	readonly options$ = new BehaviorSubject(null);
	readonly hasToolbar$ = this.options$.pipe(map(opts => Boolean(opts?.toolbar)));
	readonly toolbarTitle$ = this.options$.pipe(map(opts => opts?.toolbar?.title));

	currentPortal: Portal<any>;

	protected cdr$ = new Subject();
	protected routerNavStart$ = this.router.events.pipe(filter(e => e instanceof NavigationStart));
	protected switchVisibleOff$ = this.visible$.pipe(
		distinctUntilChanged(),
		filter(isVisible => !isVisible)
	);
	protected modalPercentHeight$ = new BehaviorSubject<number>(null);
	protected close$ = new Subject<any>();

	ngOnInit() {
		this.reg.register(this);

		this.cdr$.pipe(debounceTime(80), takeUntil(this.destroy$)).subscribe(() => this.cdr.detectChanges());

		this.close$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.visible$.next(false);
			this.modalPercentHeight$.next(null);
		});
	}

	ngAfterViewInit() {
		this.visible$.pipe(takeUntil(this.destroy$)).subscribe(visible => {
			const visibleClass = 'vh-modal-content-area--visible';
			if (visible) {
				this.elRef.nativeElement.classList.add(visibleClass);
			} else {
				this.elRef.nativeElement.classList.remove(visibleClass);
			}
		});
		this.modalPercentHeight$.pipe(takeUntil(this.destroy$)).subscribe(h => {
			this.elRef.nativeElement.style.height = h ? `${h}%` : null;
			if (h && h < 98) {
				this.elRef.nativeElement.classList.add('mat-elevation-z4');
			} else {
				this.elRef.nativeElement.classList.remove('mat-elevation-z4');
			}
		});
	}

	ngOnDestroy() {
		this.reg.unregister(this.name);
	}

	open(options: IModalContentAreaOpenOptions) {
		if (this.isOpen) {
			this.close();
		}

		this.options$.next(options.options);
		const ref = {
			afterClosed: () =>
				this.close$.pipe(
					take(1),
					map(res => res)
				),
			close: (result: any) => this.close(result),
		};

		this.currentPortal = this.createPortal(options, ref);
		this.redraw();
		this.visible$.next(true);
		this.subscribeRouteUntilNavStarted();

		if (options?.context?.modalClass) {
			this.elRef.nativeElement.classList.add(options?.context?.modalClass);
		}

		return ref;
	}

	close(result = null): void {
		this.close$.next(result);
		this.options$.next(null);

		if (this.currentPortal) {
			this.currentPortal.detach();
		}
		if (this.hiddenContainer.nativeElement.firstChild) {
			this.hiddenContainer.nativeElement.removeChild(this.hiddenContainer.nativeElement.firstChild);
		}
	}

	toggleVisible() {
		this.visible$.next(!this.visible$.value);
	}

	createPortal(
		{ content, context, injector, viewContainerRef, componentFactoryResolver }: Partial<IModalContentAreaOpenOptions>,
		ref: any
	): Portal<any> {
		if (content instanceof TemplateRef) {
			return new TemplatePortal(content, this.vcr, context);
		}
		if (content instanceof HTMLElement) {
			if (!content.parentNode) {
				this.hiddenContainer.nativeElement.appendChild(content);
			}
			return new DomPortal(content);
		}
		const portalInjector = this.createInjector(context, injector, ref);
		const portal = new ComponentPortal(content, viewContainerRef, portalInjector, componentFactoryResolver);
		return portal;
	}

	redraw() {
		this.cdr$.next(0 as any);
	}

	attached(e: CdkPortalOutletAttachedRef) {}

	setPercentHeight(x: number) {
		this.modalPercentHeight$.next(Math.min(100, Math.max(0, x)));
	}

	protected subscribeRouteUntilNavStarted() {
		this.routerNavStart$.pipe(take(1), takeUntil(this.switchVisibleOff$)).subscribe(() => this.close());
	}

	protected prefixName(name: string) {
		return `vh-modal-content-area-name__${name}`;
	}

	protected createInjector(context: any, parentInjector?: Injector, ref?: any): Injector {
		ref = ref || null;
		return Injector.create({
			parent: parentInjector || this.injector,
			providers: [
				{
					provide: MODAL_CONTENT_CONTEXT,
					useValue: context,
				},
				{
					provide: MODAL_CONTENT_REF,
					useValue: ref,
				},
			],
		});
	}
}
