import { Directive, Inject, Injectable, InjectionToken, OnDestroy, Optional, Provider, Type } from '@angular/core';
import { Draft, applyPatches, enablePatches, produce } from 'immer';
import { BehaviorSubject, Observable, distinctUntilChanged, map } from 'rxjs';

enablePatches();

const prefix = '_undo_redo_';
let instances = 0;

const UndoRedoStoreState = new InjectionToken('Undo redo initial state');
export function provideUndoRedoState(state: any): Provider {
	return {
		provide: UndoRedoStoreState,
		useValue: state,
	};
}

export function provideExistingUndoRedoStore(type: Type<any>): Provider {
	return {
		provide: UndoRedoStore,
		useExisting: type,
	};
}

@Injectable()
@Directive()
export class UndoRedoStore<S = any> implements OnDestroy {
	constructor(@Optional() @Inject(UndoRedoStoreState) initialState?: any) {
		this.#storeName = prefix + ++instances;
		this.#initialState = [null, undefined].some(i => i === initialState) ? this.getInitialState() : initialState;
		this.#state$.next(this.#initialState);
	}

	#storeName: string;
	#initialState: any;
	#stateBeforeTransaction: any;
	#actionsInTransactions = [];
	#inTransaction = false;
	#state$ = new BehaviorSubject<S>(undefined);
	readonly state$ = this.#state$.asObservable();
	protected changes = new Map();
	protected currentVersion = 0;

	protected getInitialState(): Partial<S> {
		return {} as S;
	}

	get state() {
		return this.#state$.value;
	}

	get inTransaction() {
		return this.#inTransaction;
	}

	get canUndo() {
		return this.currentVersion > 0;
	}

	get canRedo() {
		return this.currentVersion < this.changes.size;
	}

	select<R>(selector: (s: S) => R): Observable<R> {
		return this.state$.pipe(
			map(s => selector(s)),
			distinctUntilChanged()
		);
	}

	/**
	 * возвращается к предыдущей версии состояния
	 */
	undo() {
		if (!this.canUndo) {
			return;
		}
		if (this.inTransaction) {
			throw new Error(`can't undo, cause in transaction context, call commit() or rollback() before`);
		}
		const { undo } = this.changes.get(this.currentVersion--);
		const prevState = applyPatches(this.state, undo);
		this.#state$.next(prevState);
	}

	/**
	 * переход к следующей версии состояния
	 */
	redo() {
		if (!this.canRedo) {
			return;
		}
		if (this.inTransaction) {
			throw new Error(`can't redo, cause in transaction context, call commit() or rollback() before`);
		}
		const { redo } = this.changes.get(++this.currentVersion);
		const nextState = applyPatches(this.state, redo);
		this.#state$.next(nextState);
	}

	/**
	 * Фиксирует новые изменения в состоянии (предыдущая версия становится доступна через метод undo())
	 */
	setState(action: (state: Draft<S>) => void) {
		if (typeof action !== 'function') {
			return;
		}

		if (this.inTransaction) {
			const txState = produce(this.state, draft => {
				action(draft);
			});
			this.#actionsInTransactions.push(action);
			this.#state$.next(txState);
		} else {
			const nextState = produce(
				this.state,
				draft => {
					action(draft);
					return draft;
				},
				(patches, inversePatches) => {
					this.changes.set(++this.currentVersion, {
						redo: patches,
						undo: inversePatches,
						time: Date.now(),
					});
				}
			);
			this.changes = new Map(Array.from(this.changes).slice(0, this.currentVersion));
			this.#state$.next(nextState);
		}
	}

	/**
	 * возвращение к первоначальному состоянию и очистка истории изменения состояния (undo/redo начинают историю заново)
	 */
	flushChanges() {
		if (this.inTransaction) {
			throw new Error(`can't flush changes, cause in transaction context, call commit() or rollback() before`);
		}
		let state = this.state;
		while (this.currentVersion) {
			const { undo } = this.changes.get(this.currentVersion--);
			state = applyPatches(state, undo);
		}
		this.changes.clear();
		this.#state$.next(state);
	}

	/**
	 * Требует обязательности вызова rollback() или commit()
	 * все вызовы метода change() во время транзакции не фиксируют изменения в истории
	 */
	startTransaction() {
		this.#inTransaction = true;
		this.#stateBeforeTransaction = this.state;
	}

	/**
	 * завершает транзакицю с откатом изменений
	 */
	rollback() {
		try {
			this.#state$.next(this.#stateBeforeTransaction);
		} catch (error) {
			console.error(error);
		} finally {
			this.#stateBeforeTransaction = null;
			this.#inTransaction = false;
			this.#actionsInTransactions = [];
		}
	}

	/**
	 * записывает в историю изменений и завершает транзакцию
	 * @param flush очистить историю измнений, текущее состояние становится начальным
	 */
	commit(flush = false) {
		try {
			if (flush) {
				this.currentVersion = 0;
				this.changes.clear();
			} else {
				if (this.#actionsInTransactions.length > 0) {
					this.#inTransaction = false;
					this.setState(s => {
						for (let index = 0; index < this.#actionsInTransactions.length; index++) {
							this.#actionsInTransactions[index](s);
						}
					});
					const nextState = produce(
						this.#stateBeforeTransaction,
						draft => {
							for (let index = 0; index < this.#actionsInTransactions.length; index++) {
								this.#actionsInTransactions[index](draft);
							}
							return draft;
						},
						(patches, inversePatches) => {
							this.changes.set(++this.currentVersion, {
								redo: patches,
								undo: inversePatches,
								time: Date.now(),
							});
						}
					);
					this.#state$.next(nextState);
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			this.#stateBeforeTransaction = null;
			this.#inTransaction = false;
			this.#actionsInTransactions = [];
		}
	}

	destroy() {
		this.#state$.complete();
	}

	ngOnDestroy(): void {
		this.destroy();
	}
}
