import { ExtParamBase } from './ext-param-base';

export class ExtParamCheckbox extends ExtParamBase {
	_saveImmidiateAfterValueChange = true;

	setCopiedValue(value) {
		return;
	}

	isEmpty() {
		if (this.required) {
			return !this.value;
		}
		return typeof this.value !== 'boolean';
	}
}
