import { cloneDeep } from './clone';
import { isObject } from './is';

export function simpleCopy<T>(o: T): T {
	if (!isObject(o)) {
		return o;
	}
	const copy = JSON.parse(JSON.stringify(o));
	Object.setPrototypeOf(copy, Object.getPrototypeOf(o));
	return copy as T;
}

export function tryStructuredCopy<T>(data: T): T {
	try {
		return structuredClone(data);
	} catch (error) {}
	return cloneDeep(data);
}
