import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IReportDescription } from '@spa/data/entities';
import { MobileViewService } from '@spa/common/services/mobile-view.service';

@Component({
	selector: 'vh-profile-spa-reports',
	templateUrl: './profile-spa-reports.component.html',
	styleUrls: ['./profile-spa-reports.component.scss'],
})
export class ProfileSpaReportsComponent {
	constructor(readonly mobileService: MobileViewService) {}
	@Input() reportsGroup: IProfileReport[];
	@Output() openReportClicked = new EventEmitter<{ id: number; annotation: string }>();
}

export interface IProfileReport {
	groupName: string;
	reports: IReportDescription[];
}
