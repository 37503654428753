import * as common from './common';

export default {
	pink: {
		50: '#fce4ec',
		100: '#f8bbd0',
		200: '#f48fb1',
		300: '#f06292',
		400: '#ec407a',
		500: '#e91e63',
		600: '#d81b60',
		700: '#c2185b',
		800: '#ad1457',
		900: '#880e4f',
		A100: '#ff80ab',
		A200: '#ff4081',
		A400: '#f50057',
		A700: '#c51162',
		contrast: {
			50: common.black87,
			100: common.black87,
			200: common.black87,
			300: common.black87,
			400: common.black87,
			500: 'white',
			600: 'white',
			700: common.white87,
			800: common.white87,
			900: common.white87,
			A100: common.black87,
			A200: 'white',
			A400: 'white',
			A700: 'white',
		},
	},
};
