import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BadgeModule } from '@spa/common/components/badge';
import { MobileDynamicPanelPortalModule } from '@spa/facade/layout/components/toolbar/mobile-dynamic-panel-portal';

import { TabbarComponent } from './tabbar.component';

@NgModule({
	imports: [CommonModule, MatIconModule, MobileDynamicPanelPortalModule, BadgeModule],
	exports: [TabbarComponent],
	declarations: [TabbarComponent],
})
export class TabbarModule {}
