import { NgZone } from '@angular/core';
import { ModalContentAreaService } from '@spa/common/modal-content-area';
import { SpaCommandExecutorFactory } from '../spa-command.contract';

const createCommand: SpaCommandExecutorFactory = injector => {
	const contentArea = injector.get(ModalContentAreaService);
	const zone = injector.get(NgZone);

	return function openInContentArea(payload: { content: 'iframe' | HTMLElement; context: any; options: any }) {
		if (!payload) {
			return console.error(`openInContentArea commend require option { content: 'iframe' | HTMLElement }`);
		}
		return zone.runTask(() => {
			let result;
			if (payload.content === 'iframe') {
				result = contentArea.openIframe({ context: payload.context, options: payload.options });
			} else {
				result = contentArea.open({ content: payload.content, context: payload.context, options: payload.options });
			}
			return {
				...(result || {}),
				close: () => contentArea.close(),
				setHeight: (x: any) => contentArea.setPercentHeight(parseInt(x, 10)),
			};
		});
	};
};

export default createCommand;
