import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { PipesCommonModule } from '../../pipes';

import { MaterialColorPickerCommonComponent } from './material-color-picker.component';
import { TippyModule } from '@ngneat/helipopper';

@NgModule({
	imports: [CommonModule, MatIconModule, MatMenuModule, MatRippleModule, PipesCommonModule, TippyModule],
	declarations: [MaterialColorPickerCommonComponent],
	exports: [MaterialColorPickerCommonComponent],
})
export class MaterialColorPickerCommonModule {}
