import { NgModule, ModuleWithProviders } from '@angular/core';
import { ngEventLogProvider } from './eventlog.provider';

@NgModule()
export class EventLogModule {
	static forRoot(): ModuleWithProviders<EventLogModule> {
		return {
			ngModule: EventLogModule,
			providers: [ngEventLogProvider],
		};
	}
}
