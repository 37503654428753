<vh-sidebar-search
	[hideClearBtnIfEmpty]="true"
	(searchInput)="searchHandler($event.target.value)"
	(searchClear)="searchClear()"
>
</vh-sidebar-search>
<ng-container *ngIf="subcategoriesTree$ | async as catItems">
	<ng-container *ngIf="catItems?.length">
		<vh-category-item
			*ngFor="let item of catItems; trackBy: trackById"
			[categoryItem]="item"
			[allOpened]="searchInputHasValue$ | async"
			(clickItem)="onItemClick($event)"
		></vh-category-item>
	</ng-container>
</ng-container>
