import { Injector } from '@angular/core';
import { getAppTopInjector } from '@spa/api/injectors';
import { AbstractLogger, LoggerFactory } from '@spa/core';
import { JitsiService } from '@spa/facade/jitsi.service';
import { isExternalLink, isPwaMode } from '@valhalla/utils';
import { filter, fromEvent, map } from 'rxjs';
import { TaskViewActiveRegister } from '@spa/application/task/task-view-active-register';
import { domPatch } from './dom-patch';

const appTopInjector = () => getAppTopInjector();
let logger: AbstractLogger;
const taskViewActiveRegister = (injector?: Injector) => (injector || appTopInjector()).get(TaskViewActiveRegister);
const openPagesService = (injector?: Injector) =>
	import('@spa/common/services/open-pages.service').then(m => (injector || appTopInjector()).get(m.OpenPagesService));

export function extendsDomElementsWhenAppBootstrapped(injector: Injector) {
	logger = injector.get(LoggerFactory).createLogger('DOMElementsExtends');
	extendsLinks(injector);
	domPatch(injector);
}

function extendsLinks(injector: Injector) {
	targetTagEvent<HTMLAnchorElement | HTMLElement>('click', 'a').subscribe(({ event, target: el }) => {
		const target = el instanceof HTMLAnchorElement ? el : el.closest('a');
		const vhTaskLink = target.hasAttribute('vhTaskLink');
		if (isElementInModal(target) && isTaskLink(target) && !vhTaskLink) {
			const taskViewReg = taskViewActiveRegister();
			const taskId = getTaskIdFromLink(target);
			const taskViewActive = taskViewReg.findByElement(target);
			if (taskId && taskViewActive) {
				event.preventDefault();
				event.stopPropagation();
				taskViewActive?.changeComponentTaskId(taskId);
				// cant always open in new tab - cause electron / pwa mode
				// return window.open(target.href, '_blank');
				return;
			}
		}
		const vks = appTopInjector()?.get(JitsiService, null);
		if (vks?.isUrlVKSSync(target.href)) {
			event.preventDefault();
			event.stopPropagation();

			return openPagesService().then(openPages => {
				const room = vks.getRoomFromLink(target.href);
				openPages.openVKS({
					roomName: room,
					newTab: target.target === '_blank' || event.ctrlKey || event.metaKey,
				});
			});
		}
		if (isElectronMode() && isNoFrameLink(target) && target.target === '_blank') {
			target.href = target.href.replace('noframe/', '');
		}
		const hrefAttr = target.getAttribute('href') || '';
		const lcHrefAttr = hrefAttr.toLowerCase();
		if (
			hrefAttr?.includes('.') &&
			isExternalLink(hrefAttr) &&
			!hrefAttr?.includes('://') &&
			!lcHrefAttr.startsWith('javascript:void')
		) {
			target.href = `https://${hrefAttr}`;
		}

		// electron new window if external link
		if (isExternalLink(hrefAttr)) {
			if (isElectronMode()) {
				event.preventDefault();
				if (typeof window['electron']?.openUrl === 'function') {
					window['electron']?.openUrl(hrefAttr);
				} else {
					window.open(hrefAttr, '_blank');
				}
			} else if (isPwaMode()) {
				event.preventDefault();
				window.open(hrefAttr, '_blank');
			}
		}
	});
}

function targetTagEvent<
	T extends EventTarget = HTMLElement,
	E extends Event = MouseEvent,
	N extends keyof DocumentEventMap = keyof DocumentEventMap
>(type: N, tag: string, capture = true) {
	return fromEvent<E>(document, type, { capture }).pipe(
		filter(e => {
			const isLink = tag?.toLowerCase() === 'a';
			const target = e.target as HTMLElement;
			if (isLink) {
				const isTarget = target.tagName?.toLowerCase() === tag?.toLowerCase();
				if (isTarget) {
					return true;
				}
				return !!target.closest('a')?.href;
			}
			return target.tagName?.toLowerCase() === tag?.toLowerCase();
		}),
		map(e => {
			return {
				event: e,
				target: e.target as T,
			};
		})
	);
}

function isTaskLink(el: HTMLAnchorElement) {
	const spaRoute = !!el?.href?.includes('/tasks/');
	return spaRoute;
}

function isNoFrameLink(el: HTMLAnchorElement) {
	const spaRoute = !!el?.href?.includes('/noframe/');
	return spaRoute;
}

function getTaskIdFromLink(el: HTMLAnchorElement) {
	if (el?.dataset.taskId) {
		return +el.dataset.taskId;
	}
	const token = '/tasks/';
	if (el?.href?.includes(token)) {
		const id = +el.href
			.substring(el.href.indexOf(token) + token.length)
			.split('/')[0]
			.split('?')[0];
		return isNaN(id) ? undefined : id;
	}
}

function isElementInModal(el: HTMLElement) {
	return !!el?.closest('mat-dialog-container');
}

function isElectronMode() {
	return typeof window['electron'] !== 'undefined';
}
