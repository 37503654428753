import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
// import ResizeObserver from 'resize-observer-polyfill';

export interface IResizeEventArg {
	target: Element;
	size: Partial<{
		width: number;
		height: number;
		unit: string;
	}>;
	paddings: Partial<{
		top: number;
		bottom: number;
		right: number;
		left: number;
		unit: string;
	}>;
}

@Directive({ selector: '[vhResizeWatcher]' })
export class ResizeWatcherDirective implements OnInit, OnDestroy {
	constructor(protected elRef: ElementRef, protected zone: NgZone) {}

	@Input()
	watchOutsideAngularZone = false;

	@Input()
	useRequestAnimationFrame = true;

	@Output('vhResizeWatcher')
	sizeChange = new EventEmitter<Partial<IResizeEventArg>>();

	@Input('vhResizeWatcherActive')
	active = true;

	protected ro: ResizeObserver;

	ngOnInit() {
		if (this.watchOutsideAngularZone) {
			this.zone.runOutsideAngular(() => this.connect());
		} else {
			this.connect();
		}
	}

	ngOnDestroy() {
		if (this.ro) {
			this.elRef.nativeElement && this.ro.unobserve(this.elRef.nativeElement);
			this.ro.disconnect();
			this.ro = null;
		}
	}

	connect() {
		this.ro = new ResizeObserver(entries => {
			const onResize = () => {
				if (!this.active) {
					return;
				}
				if (!Array.isArray(entries) || !entries.length) {
					return;
				}
				for (const entry of entries) {
					const { left, top, width, height, bottom, right } = entry.contentRect,
						{ target } = entry;
					const arg: Partial<IResizeEventArg> = {
						target,
						size: {
							unit: 'px',
							width,
							height,
						},
						paddings: { top, bottom, right, left, unit: 'px' },
					};
					this.sizeChange.emit(arg);
				}
			};
			if (this.useRequestAnimationFrame) {
				// @see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#answer-58701523
				window.requestAnimationFrame(onResize);
			} else {
				onResize();
			}
		});
		this.ro.observe(this.elRef.nativeElement);
	}
}
