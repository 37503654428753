<ng-container *ngIf="showAppSearchPage$ | async">
	<vh-layout-content-portal>
		<vh-apps-search [ngZone]="zone"></vh-apps-search>
		<mat-icon (click)="showAppSearchPage$.next(false)" class="close-icon">close</mat-icon>
	</vh-layout-content-portal>
</ng-container>

<div class="shortcuts" [style.display]="'flex'">
	<div
		class="w-40 h-40 p-4"
		[style.display]="'flex'"
		[style.align-items]="'center'"
		[style.justify-content]="'center'"
		(click)="showAppSearch()"
	>
		<a tippy="Поиск приложений" [isEnabled]="!mobileService.isMobile">
			<mat-icon class="secondary-text">apps</mat-icon>
		</a>
	</div>
	<ng-container *ngFor="let app of userQuickApps$ | async; trackBy: trackByAppId">
		<div
			*ngIf="getAppRouterLink(app) as appRoute"
			class="w-40 h-40 p-4 pointer"
			[style.display]="'flex'"
			[style.align-items]="'center'"
			[style.justify-content]="'center'"
			[routerLink]="appRoute.url"
			[queryParams]="appRoute.query"
			routerLinkActive
			#rla="routerLinkActive"
			[routerLinkActiveOptions]="{ exact: true }"
			[vhContextMenu]="menu"
			[vhContextMenuData]="{ app: app }"
		>
			<ng-container
				*ngTemplateOutlet="rla?.isActive ? shortcutMatFabTmpl : shortcutMatIconTmpl; context: { $implicit: app }"
			>
			</ng-container>
		</div>
	</ng-container>
</div>

<ng-template #shortcutMatIconTmpl let-app>
	<a tippy="{{ app?.name || '' }}" [isEnabled]="!mobileService.isMobile">
		<ng-container *ngTemplateOutlet="shortcutItemIconTmpl; context: { $implicit: app, isActive: false }"></ng-container>
	</a>
</ng-template>

<ng-template #shortcutMatFabTmpl let-app>
	<a tippy="{{ app?.name || '' }}" [isEnabled]="!mobileService.isMobile" color="accent">
		<ng-container *ngTemplateOutlet="shortcutItemIconTmpl; context: { $implicit: app, isActive: true }"></ng-container>
	</a>
</ng-template>

<ng-template #shortcutItemIconTmpl let-app let-isActive="isActive">
	<mat-icon *ngIf="app?.icon; else noShortcutItemIconTmpl" svgIcon="{{ app?.svgIcon }}"></mat-icon>

	<ng-template #noShortcutItemIconTmpl let-isActive>
		<span class="h2" [class.text-bold]="isActive" [class.secondary-text]="!isActive">
			{{ app?.name?.substr(0, 1)?.toUpperCase() }}
		</span>
	</ng-template>
</ng-template>

<mat-menu #menu="matMenu">
	<ng-template matMenuContent let-app="app">
		<button mat-menu-item (click)="deleteQuickApp(app)">
			<mat-icon class="material-icons-outlined">delete_outline</mat-icon>
			<span>Убрать из QuickLinks</span>
		</button>
	</ng-template>
</mat-menu>
