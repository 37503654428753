import { AbstractMessage, AbstractMessageType } from '@spa/core';

const taskAvatarChangeMessageType: AbstractMessageType = Object.freeze({
	id: Symbol(),
	name: 'TaskAvatarChangeMessage',
});

export class TaskAvatarChangeMessage extends AbstractMessage {
	readonly type: AbstractMessageType = taskAvatarChangeMessageType;

	create(data: any, sender?: any) {
		return new TaskAvatarChangeMessage(data, sender);
	}
}
