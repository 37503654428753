import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, HostBinding, Input, NgZone, OnInit } from '@angular/core';
import { Router, UrlCreationOptions } from '@angular/router';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { UserSettingsFacadeProvider } from '@spa/facade/features/user-settings/providers';
import { LayoutFacade } from '@spa/facade/layout';
import { LayoutNavBarPosition } from '@spa/facade/layout/state';
import {
	MatchMediaService,
	MediaBreakpoint,
	PlatformDetectorProvider,
	UrlProvider,
	ViewDestroyStreamService,
} from '@valhalla/core';
import { TickerClickAction, TickerType } from '@valhalla/data/entities';
import { ConfigurationDataHttpService, IAppSettingsAnonymousDto } from '@valhalla/data/http';
import { badgeRoundValue, delayRunInZone, firstLetter, urlFrom } from '@valhalla/utils';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, take, takeUntil, tap } from 'rxjs/operators';
import { TickersFacadeProvider } from '../../providers/abstract';
import { SystemTicker } from '../../ticker-type.enum';
import { ITicker } from '../../ticker.model';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { IBadgeColor } from 'libs/core-components/src/lib/badge/badge.model';
import { CultureService } from '@spa/localization';

@Component({
	selector: 'vh-toolbar-tickers',
	templateUrl: './toolbar-tickers.component.html',
	styleUrls: ['./toolbar-tickers.component.scss'],
	//changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class ToolbarTickersComponent implements OnInit {
	constructor(
		readonly tickersFacade: TickersFacadeProvider,
		readonly destroyView$: ViewDestroyStreamService,
		readonly matchMedia: MatchMediaService,
		readonly router: Router,
		readonly urlBuilder: UrlProvider,
		readonly platform: PlatformDetectorProvider,
		readonly cdr: ChangeDetectorRef,
		readonly dataConfig: ConfigurationDataHttpService,
		readonly modals: ModalWindowsService,
		readonly layout: LayoutFacade,
		readonly zone: NgZone,
		readonly userSettingsStore: UserSettingsFacadeProvider,
		readonly mobileService: MobileViewService,
		readonly destroy$: ViewDestroyStreamService,
		readonly culture: CultureService
	) {
		this.resizeHandler$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.hideAllTickers = window.innerWidth < 960;
		});
	}

	static handleSystemTickerAction(
		ticker: ITicker,
		router: Router,
		urlBuilder: UrlProvider,
		layout: LayoutFacade,
		isOpenNewWindow?: boolean,
		config?: IAppSettingsAnonymousDto
	) {
		const host = window.location.origin;

		switch (ticker.name) {
			case 'default-feed': {
				const feedDefaultUrl = urlBuilder.getUrl('/feeds/default', true);
				isOpenNewWindow
					? window.open(Location.joinWithSlash(host, feedDefaultUrl))
					: router.navigate(['/feeds/default']);
				break;
			}
			case SystemTicker.unreadCommentsCount: {
				const unreadCommentsCountUrl = urlBuilder.getUrl('/feeds/unread', true);
				isOpenNewWindow
					? window.open(Location.joinWithSlash(host, unreadCommentsCountUrl))
					: router.navigate(['/feeds/unread']);
				break;
			}
			case SystemTicker.favoriteComments: {
				const favoriteCommentsUrl = urlBuilder.getUrl('/feeds/favorites', true);
				isOpenNewWindow
					? window.open(Location.joinWithSlash(host, favoriteCommentsUrl))
					: router.navigate(['/feeds/favorites']);
				break;
			}
			case SystemTicker.overDueTasksCount: {
				const taskOverdueUrl = urlBuilder.getUrl('/tasks/Overdue/grid', true);
				isOpenNewWindow
					? window.open(Location.joinWithSlash(host, taskOverdueUrl))
					: router.navigate(['/tasks/Overdue/grid']);
				break;
			}
			case SystemTicker.questionsCount: {
				const questionsCountUrl = urlBuilder.getUrl('/feeds/questions', true);
				isOpenNewWindow
					? window.open(Location.joinWithSlash(host, questionsCountUrl))
					: router.navigate(['/feeds/questions']);
				break;
			}
			case SystemTicker.myQuestionsCount: {
				const questionsCountUrl = urlBuilder.getUrl('/feeds/myquestions', true);
				isOpenNewWindow
					? window.open(Location.joinWithSlash(host, questionsCountUrl))
					: router.navigate(['/feeds/myquestions']);
				break;
			}
			case SystemTicker.milestones: {
				const urlMilestones = urlBuilder.getUrl(
					`/link?url=${encodeURIComponent('/spaex.aspx/agenda?hideFirstDayHeader=1&today=true')}`,
					true
				);
				isOpenNewWindow
					? window.open(Location.joinWithSlash(host, urlMilestones))
					: router.navigate([urlBuilder.getUrl('/link')], {
							queryParams: {
								url: '/spaex.aspx/agenda?hideFirstDayHeader=1&today=true',
							},
					  });
				break;
			}
			case SystemTicker.signaturesCount: {
				if (!!config?.CustomSettings?.IsOldSignTicker) {
					const urlForWindow = urlBuilder.getUrl(
						`/link?url=${encodeURIComponent('/NewCustomGrid.aspx?Type=ToSign')}`,
						false
					);
					isOpenNewWindow
						? window.open(Location.joinWithSlash(host, urlForWindow))
						: router.navigate([urlBuilder.getUrl('/link')], {
								queryParams: {
									url: '/NewCustomGrid.aspx?Type=ToSign',
								},
						  });
					return;
				} else {
					const urlForWindow = urlBuilder.getUrl(router.serializeUrl(router.createUrlTree(['/resolutions'])), true);
					isOpenNewWindow ? window.open(urlForWindow) : router.navigate(['/resolutions']);
				}
				break;
			}
			case SystemTicker.overdueSignatures: {
				const params: [any[], UrlCreationOptions] = [['/resolutions'], { queryParams: { onlyOverdue: true } }];
				const urlForWindow = urlBuilder.getUrl(router.serializeUrl(router.createUrlTree(...params)), true);
				isOpenNewWindow ? window.open(urlForWindow) : router.navigate(...params);
				break;
			}
			case SystemTicker.chatUnreadMessageCount: {
				const chatUnreadMessageCountUrl = urlBuilder.getUrl('/chat?mode=chat', true);
				isOpenNewWindow
					? window.open(Location.joinWithSlash(host, chatUnreadMessageCountUrl))
					: router.navigate(['/chat'], { queryParams: { mode: 'chat' } });
				break;
			}

			case SystemTicker.todayTasks:
			case SystemTicker.totalTasks:
			case SystemTicker.totalTasksAssistant:
			case SystemTicker.overdueNoPerformers:
			case SystemTicker.newTasks: {
				const name = firstLetter(ticker.name, 'up');
				const params: [any[], UrlCreationOptions] = [['/tasks', name, 'grid'], { queryParams: {} }];
				const urlForWindow = urlBuilder.getUrl(router.serializeUrl(router.createUrlTree(...params)), true);
				isOpenNewWindow ? window.open(urlForWindow) : router.navigate(...params);
				break;
			}
			default:
				break;
		}
	}

	@Input()
	@HostBinding('class.vertical-layout')
	vertical = false;

	@Input()
	secondaryColor = true;

	@Input()
	useOutlinedIcons = true;

	@Input()
	alwaysAllTickers = false;

	@Input()
	fluentBoxContainer: HTMLElement;

	@Input()
	fluentBoxBufferBasis = 0;

	@Input()
	hideAll: boolean;

	@Input()
	config: IAppSettingsAnonymousDto;

	@Input()
	set exceptTickers(value: SystemTicker[]) {
		this._exceptTickers$.next([...this.exceptTickers, ...(value || [])]);
	}
	get exceptTickers(): SystemTicker[] {
		return this._exceptTickers$.value;
	}

	private _exceptTickers = [
		SystemTicker.hasChatInvites,
		SystemTicker.wasOffline,
		SystemTicker.directorSignaturesCount,
		SystemTicker.overdueSigns,
	];
	private _exceptTickers$ = new BehaviorSubject(this._exceptTickers);
	private _isViewAllTickers$ = new BehaviorSubject(true);

	readonly roundN = badgeRoundValue;

	systemTickers$: Observable<ITicker[]>;
	allTickers$: Observable<ITicker[]>;
	sumTickersValue$: Observable<number>;
	sumTickersExclude = ['MyQuestionsCount'];
	isViewAllTickers$ = this._isViewAllTickers$.pipe(map(isViewAll => this.alwaysAllTickers || isViewAll));
	colorSumTickers$: Observable<string>;

	useNewGridForTickers: boolean;

	readonly resizeHandler$ = fromEvent(window, 'resize');
	hideAllTickers: boolean;

	readonly badgeClassByTickerName = {
		[SystemTicker.favoriteComments]: 'base-favourites',
	};

	get isMobile() {
		return this.platform.isMobile();
	}

	get tooltipPosition() {
		if (this.layout.state?.appNavBarPosition === LayoutNavBarPosition.top) {
			return 'bottom';
		}
		if (this.layout.state?.appNavBarPosition === LayoutNavBarPosition.left) {
			return 'right';
		}
		if (this.layout.state?.appNavBarPosition === LayoutNavBarPosition.right) {
			return 'left';
		}
	}

	readonly tickersUrl = [
		{
			name: SystemTicker.unreadCommentsCount,
			url: '/feeds/unread',
		},
		{
			name: SystemTicker.chatUnreadMessageCount,
			url: '/chat',
		},
		{
			name: SystemTicker.favoriteComments,
			url: '/feeds/favorites',
		},
		{
			name: SystemTicker.overDueTasksCount,
			url: '/tasks/Overdue/grid',
		},
		{
			name: SystemTicker.questionsCount,
			url: '/feeds/questions',
		},

		{
			name: SystemTicker.myQuestionsCount,
			url: '/feeds/myquestions',
		},
		{
			name: SystemTicker.milestones,
			url: '/spaex.aspx/agenda?hideFirstDayHeader=1&today=true',
		},
		{
			name: SystemTicker.overdueSignatures,
			url: '/resolutions?onlyOverdue=true',
			fullMath: true,
		},
		{
			name: SystemTicker.signaturesCount,
			url: '/resolutions',
			fullMath: true,
		},
		...[
			SystemTicker.todayTasks,
			SystemTicker.totalTasks,
			SystemTicker.totalTasksAssistant,
			SystemTicker.overdueNoPerformers,
			SystemTicker.newTasks,
		].map(tickerName => {
			const name = firstLetter(tickerName, 'up');
			return {
				name: tickerName,
				url: `/tasks/${name}/grid`,
			};
		}),
	];

	readonly badgeColor = IBadgeColor;
	readonly locale$ = this.culture.activeCulture$;

	ngOnInit() {
		this.systemTickers$ = combineLatest(this.tickersFacade.selectTickers$, this._exceptTickers$).pipe(
			map(([record, except]) =>
				Object.values(record)
					//.filter(ticker => ticker.id !== 'system/chatUnreadMessageCount')
					.filter(ticker => ticker.type === TickerType.system)
					.filter(ticker => !except.includes(<any>ticker.name))
					.filter(ticker => ticker.available)
					.sort((a, b) => b.order - a.order)
			),
			tap(() => delayRunInZone(this.zone, () => this.cdr.detectChanges())),
			takeUntil(this.destroyView$)
		);

		this.allTickers$ = combineLatest([this.tickersFacade.selectTickers$, this.systemTickers$]).pipe(
			map(([recordAll, systemTickers]) => {
				const customTickers = Object.values(recordAll)
					.filter(ticker => ticker.type === TickerType.custom)
					.sort((a, b) => b.order - a.order);
				return [...systemTickers, ...customTickers];
			}),
			shareReplay({ bufferSize: 1, refCount: true })
		);

		this.sumTickersValue$ = this.systemTickers$.pipe(
			map(tickers =>
				tickers.reduce((acc, cur) => {
					if (!this.sumTickersExclude.includes(cur.name)) {
						acc += cur.value;
					}
					return acc;
				}, 0)
			),
			takeUntil(this.destroyView$)
		);

		const mqAliasAllTickers = [MediaBreakpoint.xl, MediaBreakpoint.lg, MediaBreakpoint.md];
		this.matchMedia.media$
			.pipe(
				distinctUntilChanged(),
				map(media => mqAliasAllTickers.includes(media)),
				tap(this._isViewAllTickers$),
				takeUntil(this.destroyView$)
			)
			.subscribe();

		this.colorSumTickers$ = this.systemTickers$.pipe(
			map(tickers => tickers.filter(ticker => ticker.value > 0)),
			map(tickers => tickers.map(ticker => this.colorBadgeByTicker(ticker))),
			map(colors => {
				if (colors.includes(this.badgeColor.danger)) {
					return this.badgeColor.danger;
				}
				return this.badgeColor.info;
			}),
			takeUntil(this.destroyView$)
		);
		this.hideAllTickers = window.innerWidth < 960;
	}

	isFormaIcons(iconName: string) {
		return String(iconName)?.startsWith('vh-');
	}

	isUrl(ticker: ITicker) {
		const currentTicker = this.tickersUrl.find(t => t?.name === ticker.name);
		if (ticker.type === TickerType.custom && ticker.commands?.length) {
			const urlAction = ticker.commands.find(command => command.action === 'url');
			if (urlAction?.payload?.clickAction === TickerClickAction.openLink) {
				return false;
			}
			const matches = urlAction?.payload?.url?.match(/\d+/);
			if (!matches?.length) {
				return false;
			}
			const customTickerSubcatId = matches[0];
			return this.router.url.includes(customTickerSubcatId);
		}
		if (!currentTicker) {
			return false;
		}
		if (ticker.type === TickerType.system) {
			if (currentTicker.fullMath) {
				return this.router.url === currentTicker.url;
			}
			return this.router.url.startsWith(currentTicker.url);
		}
		return this.router.url.includes(currentTicker.url);
	}

	trackById(index: number, ticker: ITicker) {
		return ticker.id;
	}

	getResxKeyByTicker(ticker: ITicker) {
		if (ticker.id === 'system/chatUnreadMessageCount') {
			return 'common.tickerTooltipChats';
		}
		return ticker.title;
	}

	tickerClick(ticker: ITicker, e: Event) {
		if (!ticker) {
			return;
		}

		dispatchEvent(
			new CustomEvent('vh-toolbar-ticker-click', {
				detail: {
					ticker,
					event: e,
				},
			})
		);

		this.userSettingsStore.selectUserSettings$.pipe(take(1)).subscribe(s => {
			this.useNewGridForTickers = s.useNewGridSubcats?.tickers;

			try {
				switch (ticker.type) {
					case TickerType.system:
						this.systemTickerAction(ticker, e);
						break;
					case TickerType.custom:
						this.customTickerAction(ticker, e);
						break;
					default:
						break;
				}
			} catch (error) {
				console.error(error);
				this.modals.openError(String(error));
			}
		});
	}

	customTickerAction(ticker: ITicker, e: Event) {
		const command = ticker.commands && ticker.commands[0];
		if (!command || command.type !== 'click') {
			return;
		}

		const mouseEvent = e as MouseEvent;
		const openInNewTab = mouseEvent?.ctrlKey || mouseEvent?.metaKey;

		if (command.action === 'url') {
			let url: string = command.payload && command.payload.url;
			const lcUrl = url.toLowerCase();
			if (!url) {
				// tslint:disable-next-line:no-console
				console.warn('Can execute action click on ticker, url is not defined!', ticker);
			} else {
				const isExternalLink = url.includes('://');
				if (isExternalLink) {
					return window.open(url, '_blank');
				}

				const spaNoFrameLinkPart = 'spa/noframe';
				if (url.includes(spaNoFrameLinkPart)) {
					const spaLink = url.substr(url.indexOf(spaNoFrameLinkPart) + spaNoFrameLinkPart.length);
					if (openInNewTab) {
						let serializeUrl = this.router.serializeUrl(this.router.createUrlTree([spaLink]));
						serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
						return window.open(serializeUrl, '_blank');
					} else {
						return this.router.navigate([spaLink]);
					}
				}

				const spaLinkPart = 'spa/';
				if (url.includes(spaLinkPart)) {
					const spaLink = url.substr(url.indexOf(spaLinkPart) + spaLinkPart.length - 1);
					if (openInNewTab) {
						let serializeUrl = this.router.serializeUrl(this.router.createUrlTree([spaLink]));
						serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
						return window.open(serializeUrl, '_blank');
					} else {
						return this.router.navigate([spaLink]);
					}
				}

				const portalLink = '/portal/';
				if (url.includes(portalLink)) {
					const strPid = url.substr(url.indexOf(portalLink) + portalLink.length);
					const portalId = Number(strPid);
					if (!isNaN(portalId)) {
						const urlParts = [`/portal/`, portalId];
						if (openInNewTab) {
							let serializeUrl = this.router.serializeUrl(this.router.createUrlTree(urlParts));
							serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
							return window.open(serializeUrl, '_blank');
						} else {
							return this.router.navigate(urlParts);
						}
					}
				}

				const aspxLink = '.aspx';
				if (url.includes(aspxLink)) {
					if (url.includes('TickerID')) {
						if (this.useNewGridForTickers) {
							const urlRef = urlFrom(url);
							urlRef.searchParams.delete('TickerID');
							const tickerMatchSearch = 'TickerID=';
							const idxTickerId = url?.indexOf(tickerMatchSearch) + tickerMatchSearch?.length;
							const tickerId = url.slice(idxTickerId);
							const customTickerGridUrl = this.urlBuilder.getUrl(`/tasks/custom-ticker/${tickerId}/grid`);

							if (openInNewTab) {
								return window.open(customTickerGridUrl + urlRef.search, '_blank');
							}

							const queryParams = {};
							urlRef.searchParams.forEach((val, key) => {
								queryParams[key] = val;
							});

							return this.router.navigate([customTickerGridUrl], { queryParams });
						}
					}

					const hasSyndicate = lcUrl.includes('syndicate.aspx');
					url = hasSyndicate ? url : `/Syndicate.aspx?forceURL=${url}`;
					const urlParts = [`/link`];
					const urlExtras = {
						queryParams: {
							url: url,
						},
					};
					if (openInNewTab) {
						let serializeUrl = this.router.serializeUrl(this.router.createUrlTree(urlParts, urlExtras));
						serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
						return window.open(serializeUrl, '_blank');
					} else {
						return this.router.navigate(urlParts, urlExtras);
					}
				}

				if (openInNewTab) {
					let serializeUrl = this.router.serializeUrl(this.router.createUrlTree([url]));
					serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
					return window.open(serializeUrl, '_blank');
				} else {
					return this.router.navigate([url]);
				}
			}
		} else if (command.action === 'script') {
			const run = command.payload && command.payload.run;
			if (typeof run !== 'function') {
				// tslint:disable-next-line:no-console
				console.warn('Can execute action click on ticker, run function is not defined!', ticker);
			} else {
				try {
					run();
				} catch (err) {
					// tslint:disable-next-line:no-console
					console.error('Execute ticker action failed!', err, ticker);
				}
			}
		}
	}

	systemTickerAction(ticker: ITicker, e: Event) {
		const mouseEvent = e as MouseEvent;
		const openInNewTab = mouseEvent?.ctrlKey || mouseEvent?.metaKey;
		ToolbarTickersComponent.handleSystemTickerAction(
			ticker,
			this.router,
			this.urlBuilder,
			this.layout,
			openInNewTab,
			this.config
		);
	}

	colorBadgeByTicker(ticker: ITicker) {
		if (!ticker) {
			return;
		}

		if (ticker.badgeColor) {
			switch (ticker.badgeColor) {
				case 'accent':
					return this.badgeColor.primary;
				case 'warn':
					return this.badgeColor.danger;
				default:
					this.badgeColor.info;
			}
		}
		switch (ticker.name) {
			case SystemTicker.favoriteComments:
				return this.badgeColor.warning;
			case SystemTicker.overDueTasksCount:
			case SystemTicker.questionsCount:
			case SystemTicker.missedCalls:
			case SystemTicker.overdueSigns:
			case SystemTicker.signaturesCount:
			case SystemTicker.overdueSignatures:
			case SystemTicker.overdueNoPerformers:
				return this.badgeColor.danger;
			default:
				return this.badgeColor.info;
		}
	}

	// colorByTicker(ticker: ITicker) {
	// 	if (!ticker) {
	// 		return;
	// 	}
	// 	if (ticker.badgeColor) {
	// 		return ticker.badgeColor;
	// 	}
	// 	switch (ticker.name) {
	// 		case SystemTicker.overDueTasksCount:
	// 		case SystemTicker.questionsCount:
	// 		case SystemTicker.missedCalls:
	// 		case SystemTicker.overdueSigns:
	// 		case SystemTicker.signaturesCount:
	// 		case SystemTicker.overdueSignatures:
	// 		case SystemTicker.overdueNoPerformers:
	// 			return 'warn';
	// 		default:
	// 			return 'accent';
	// 	}
	// }

	// badgeClassByTicker(ticker: ITicker) {
	// 	return this.badgeClassByTickerName[ticker.name];
	// }
}
