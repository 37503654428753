import { Component, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ProgressBarCommonComponent } from '@spa/common/components/progress-bar/progress-bar.component';
import { getTaskId } from '@spa/data/entities';
import { DataHttpService } from '@valhalla/data/http';
import {
	base64ImgFromDataUrl,
	blobToDataUrl,
	bytesToMB,
	FileDialogAccept,
	openFileDialog,
	parseMessageFromError,
} from '@valhalla/utils';
import { BehaviorSubject, EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ViewDestroyStreamService } from '@spa/core';
import { ImageOptimizerService } from '@spa/common/services/image-optimizer';

@Component({
	selector: 'vh-task-avatar-edit-modal',
	templateUrl: './task-avatar-edit-modal.component.html',
	styleUrls: ['./task-avatar-edit-modal.component.scss'],
	providers: [ViewDestroyStreamService],
})
export class TaskAvatarEditModalComponent implements OnInit {
	constructor(
		readonly dialogRef: MatDialogRef<TaskAvatarEditModalComponent>,
		@Inject(MAT_DIALOG_DATA) readonly dialogData,
		readonly server: DataHttpService,
		readonly destroy$: ViewDestroyStreamService,
		readonly imgOptimizer: ImageOptimizerService
	) {}

	@ViewChild(ProgressBarCommonComponent)
	progressBar: ProgressBarCommonComponent;

	@HostBinding('class.vh-task-avatar-edit-modal--local-edit')
	get localEdit() {
		return this.dialogData.localEdit;
	}

	@HostBinding('class.vh-task-avatar-edit-modal--has-task')
	get hasTask() {
		return Boolean(this.dialogData.task);
	}

	newAvatar$ = new BehaviorSubject<File>(null);
	chatAvatarDeleted$ = new BehaviorSubject<boolean>(null);
	taskAvatarDeleted$ = new BehaviorSubject<boolean>(null);
	newAvatarBase64$ = this.newAvatar$.pipe(
		mergeMap(img => (img ? blobToDataUrl(img) : of(img))),
		catchError(err => {
			this.error$.next(parseMessageFromError(err));
			return EMPTY;
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);
	subscribersMode$ = new BehaviorSubject(false);
	saving$ = new BehaviorSubject(false);
	removed = false;
	error$ = new BehaviorSubject('');

	get canRemoveAvatar() {
		return this.dialogData?.task?.hasAvatar || this.newAvatar$.value || this.dialogData?.taskAvatarDataUrl;
	}

	ngOnInit(): void {
		this.saving$.pipe(takeUntil(this.destroy$)).subscribe(saving => {
			if (saving) {
				this.progressBar?.progress.show();
			} else {
				this.progressBar?.progress.hide();
			}
		});
	}

	onCancel() {
		if (this.saving$.value) {
			return;
		}
		this.dialogRef.close();
	}

	onOk() {
		this.error$.next(null);
		if (this.saving$.value) {
			return;
		}
		if (this.removed) {
			return this.removeTaskAvatar();
		} else if (this.newAvatar$.value) {
			return this.updateTaskAvatar();
		}
		return this.dialogRef.close();
	}

	onRemove() {
		if (this.saving$.value) {
			return;
		}
		this.error$.next(null);
		this.removed = true;
		this.subscribersMode$.next(true);
		this.newAvatar$.next(null);
		if (this.dialogData?.task?.isChat) {
			this.chatAvatarDeleted$.next(true);
		} else {
			this.taskAvatarDeleted$.next(true);
		}
	}

	loadNewAvatar() {
		if (this.saving$.value) {
			return;
		}
		this.error$.next(null);
		openFileDialog({
			multiple: false,
			accept: FileDialogAccept.images,
		})
			.pipe(
				map(({ files }) => files[0]),
				tap(file => {
					if (bytesToMB(file.size) >= 5) {
						throw new Error('Файл не должен превышать 5МБ');
					}
				}),
				// switchMap(img => this.imgOptimizer.optimize(img)),
				take(1)
			)
			.subscribe({
				next: img => {
					this.removed = false;
					this.subscribersMode$.next(false);
					this.newAvatar$.next(img);
				},
				error: err => {
					this.error$.next(parseMessageFromError(err));
				},
			});
	}

	updateTaskAvatar() {
		if (this.localEdit) {
			return this.dialogRef.close({
				avatar: this.newAvatar$.value,
			});
		}
		this.saving$.next(true);
		this.newAvatarBase64$
			.pipe(
				take(1),
				switchMap(newAvatarBase64 =>
					this.server.task.updateTaskAvatar({
						taskId: getTaskId(this.dialogData.task),
						fileName: this.newAvatar$.value.name,
						base64File: base64ImgFromDataUrl(newAvatarBase64 as string),
					})
				)
			)
			.subscribe({
				next: () => {
					this.dialogData.task.hasAvatar = true;
					this.dialogRef.close(true);
				},
				complete: () => this.saving$.next(false),
				error: err => {
					this.saving$.next(false);
					this.error$.next(parseMessageFromError(err));
				},
			});
	}

	removeTaskAvatar() {
		if (this.localEdit) {
			return this.dialogRef.close({
				remove: true,
			});
		}
		this.saving$.next(true);
		this.server.task
			.removeTaskAvatar({
				taskId: getTaskId(this.dialogData.task),
			})
			.subscribe({
				next: () => {
					this.dialogData.task.hasAvatar = false;
					this.dialogRef.close(true);
				},
				complete: () => {
					this.saving$.next(false);
				},
				error: err => {
					this.saving$.next(false);
					this.error$.next(parseMessageFromError(err));
				},
			});
	}
}
