import { map, Observable, of, take } from 'rxjs';
import { ExtParamBase } from './ext-param-base';

export type ExtParamSelectValueType = {
	id: any;
	value: any;
	text: string;
};

export class ExtParamSelect extends ExtParamBase<ExtParamSelectValueType> {
	get saveImmidiateAfterValueChange() {
		return !this.settings?.presentRadioButtons;
	}

	search(filter: string, skip = 0, take = 50, params = {}) {
		params = params || {};
		let parentId, parentValues, parentId2, parentValues2;
		const firstParentEpId = this.sourceConfig.dataSource?.filter?.firstParentEpId;
		if (firstParentEpId) {
			parentId = firstParentEpId;
			const parentEp = this.findEp(firstParentEpId);
			parentValues = parentEp?.value
				? Array.isArray(parentEp?.value)
					? parentEp.value.map(v => v.taskId || v.value)
					: [parentEp.value.taskId || parentEp.value.value]
				: [];
		}
		const secondParentEpId = this.sourceConfig.dataSource?.filter?.secondParentEpId;
		if (secondParentEpId) {
			parentId2 = secondParentEpId;
			const parentEp = this.findEp(secondParentEpId);
			parentValues2 = parentEp?.value
				? Array.isArray(parentEp?.value)
					? parentEp?.value.map(v => v.taskId)
					: [parentEp?.value.taskId]
				: [];
		}
		if (parentId) {
			params = {
				...params,
				parentId,
				parentValues,
			};
		}

		if (parentId2) {
			params = {
				...params,
				parentId2,
				parentValues2,
			};
		}

		return this.server.ep
			.getEpSelectValues({
				extParamId: this.id,
				isFromSubcatTasks: false,
				skip: skip,
				sourceBlockId: null,
				subcatId: this.ctxSubcatId,
				take: take,
				taskId: this.ctxTaskId,
				text: filter,
				...(params || {}),
			})
			.pipe(map(res => res?.data));
	}

	equalsValue(a: ExtParamSelectValueType, b: ExtParamSelectValueType) {
		return a?.value === b?.value;
	}

	convertForUpdateExtParamInTask() {
		if (!this.value?.value) {
			return `#n${this.id}#v${''}`;
		}
		return `#n${this.id}#v${this.value.value}`;
	}

	convertForUpdateExtParamInNewTask(): string {
		return this.value?.value;
	}

	getValueForCopy() {
		return String(this.value?.value);
	}

	setCopiedValue(value) {
		this.setSearchContext(value);
	}

	convertForSaveInNewTaskAsync(): Observable<any> {
		if (typeof this.convertForSaveInNewTaskAsyncMiddleware === 'function') {
			return this.convertForSaveInNewTaskAsyncMiddleware(this).pipe(take(1));
		}
		return of(String(this.value?.id || this.value?.value || ''));
	}

	get extParamQueryStringValue(): string {
		if (!this.value?.value) {
			return '';
		}
		return `$Ext${this.id}$${this.value.value}`;
	}
}
