import { IAbsence, IUserGroup, IUserShort } from '@valhalla/data/entities';

export interface IDeleteQuickRepliesReq {
	userId: number;
	text: string;
	id: number;
}
export interface IAddQuickRepliesReq {
	userId: number;
	text: string;
	id?: number;
}
export interface IQuickReplies {
	userId: number;
	text: string;
	id: number;
}

export interface IUpdateSettings {
	userId: number;
	removeFromSubscribers: boolean;
	sendCommentByEnter: boolean;
	notSetNeedAnswerCheckboxAutomatically: boolean;
	removeFinishedTasksFromFavourites: boolean;
	fileOnClickActionMode: MsOfficeClickActions;
}

export interface IUserOrgUnitsRes {
	isPrimary: boolean;
	id: number;
	name: string;
	parentId: number;
	hasChildren: boolean;
}

export interface IUserOrgUnitsReq {
	userId: number;
	doNotShowHidenInUserInfo: boolean;
	hideCommercialInfo: boolean;
}

export interface IUserShortInfo {
	userIds: number[];
}
export interface IRecipient {
	type?: IRecipientType;
	name: string;
	id: number;
}

export enum IRecipientType {
	unknown = 'Unknown',
	user = 'User',
	group = 'Group',
	orgunit = 'OrgUnit',
	email = 'Email',
	meetingGroup = 'MeetingGroup',
}

export interface IRecipientResponse {
	total: 0;
	recipients: IRecipient[];
}

export interface IRecipientResolve {
	target?: 'ExtParam' | 'Performers' | 'Subscribers' | 'UsersAndGroups' | string;
	search?: string;
	limit?: number;
	offset?: number;
	subcatId?: number;
	taskId?: number;
	extParamId?: number;
	recipientTypes?: string[];
	selectedRecipients?: {
		users?: number[];
		groups?: number[];
		orgUnits?: number[];
		mailBoxes?: number[];
	};
	makeOrgUnitsNamesHierarchical?: boolean;
	orgUnitId?: number;
	groupId?: number;
	orgStructureHierarchicalResponse?: boolean;
}

export interface IGetUserSessionInfoResult {
	userId: number;
	isAdmin: boolean;
	groups: IUserGroup[];
}

export interface IGetUserStatusCriteria {
	userIds: number[];
}

export interface IGetUserSettingsResult {
	id: number;
	startPage: string;
	enableDesktopNotifications: boolean;
	showUserTooltip: boolean;
	fileOnClickActionMode: MsOfficeClickActions;
	navigationPanelLogo?: string;
	calendarVisible?: string;
	categoryVisible?: string;
	chooseLanguageVisible?: string;
	createButtonVisible?: string;
	ownTaskSubcatId?: number;
	openConversationOnCommentClick?: boolean;
	favoriteVisible?: string;
	lentaVisible?: string;
	chatsVisible?: string;
	mailVisible?: string;
	personalVisible?: string;
	reportVisible?: string;
	searchVisible?: string;
	staffVisible?: string;
	spacesVisible?: string;
	orgChartVisible?: string;
	personalMenuTimeSheet: string;
	personalMenuKanban: string;
	personalMenuOthers: string;
	userProfileActivity: string;
	userProfileAgenda: string;
	userProfileCalendar: string;
	userProfileCustomer: string;
	userProfileJournal: string;
	showCreatePersonalTaskButton?: string;
	userProfileReport: string;
	userProfileLaborCost: string;
	userProfilePerformer: string;
	/** настройка показа мтф */
	mtfPreview?: string;
	useNewTaskCard?: boolean;
	commentsViewMode?: 'down' | 'right';
	accessToOtherUserProfiles?: boolean;
	jitsiServicePersonalRoomVisible: string;
	jitsiServicePersonalRoom: string;
	notAutoSetCommentAsQuestion?: boolean;
	sendCommentByEnter?: boolean;
	autoReadCommentsInTask?: boolean;
	userUISettingsConfig: IUserUIConfig;
	userUISettings: {
		allowChatsDesktopNotification: boolean;
		allowOverdueTasksDesktopNotification: boolean;
		allowQuestionsDesktopNotification: boolean;
		allowSignaturesDesktopNotification: boolean;
		allowUnreadCommentsDesktopNotification: boolean;
		apenCharts: boolean;
		colorTheme: string;
		contextModalWindows: boolean;
		filterCommentsDependByLentaSettings: boolean;
		fontSize: string;
		navigationPanelColor: string;
		navigationPanelPosition: string;
		panelsFontSize: string;
		popupChats: boolean;
		showCalendarInNavigationPanel: boolean;
		showIAndMeInsteadOfDisplayName: boolean;
		showStartupPageOnLogoClick: boolean;
		translateCommentsInFeed: boolean;
		useNewTaskCard: boolean;
		useStartUpPage: boolean;
		userNameMode: string;
		userStartPageType: string;
		viewCommentsInTaskState: string;
		viewTaskState: string;
		showNavigationAlways: boolean;
		showDateInHumanFormat: boolean;
		openFirstTaskInTableView: boolean;
	};
	useNewGridSubcats: IUseNewGridSubcat;
	useNewExtParamsAndTaskUsedSubcats: number[];
	click2call?: boolean;

	removeFromSubscribersAfterDecision?: boolean;
	notSetNeedAnswerCheckboxAutomatically?: boolean;
	removeFinishedTasksFromFavourites?: boolean;
	canChangePassword: boolean;
}

export interface IUserUIConfig {
	colorTheme: any;
	showTask: any;
	comments: any;
	general: any;
	commentsFeed: any;
	startPage: any;
	fontSize: any;
	fontPanelSize: any;
	navigationPanel: any;
	additionalProperties: any;
	isVisible: boolean;
}
export interface IUseNewGridSubcat {
	subcatIds?: number[];
	allSubcats: boolean;
	myTasks: boolean;
	tickers: boolean;
}

export interface IGetUserContactsCriteria {
	userName: string;
}

export enum MsOfficeClickActions {
	download = 'Download',

	webapps = 'ShowInOfficeOnlineEditor',

	webdav = 'EditInWebDav',

	default = 'Default',
}

export interface ICowokersCriteria {
	limit: number;
	offset: number;
	search?: string;
	FiltersData?: string;
}

export interface ICowokersFilter {
	column: string;
	type: CoworkersSearchTypes;
	value: string;
}

export enum CoworkersSearchTypes {
	includes = 'includes',
	excludes = 'excludes',
	equal = 'equal',
	unequal = 'unequal',
	startWith = 'startWith',
	endWith = 'endWith',
}

export enum MtfAreaView {
	right = 'right',
	bottom = 'bottom',
	inPlace = 'newWindow',
	popup = 'popupWindow',
}

export enum VisibleValue {
	noSet = 'NoSet',
	visible = 'Visible',
	invisible = 'Invisible',
}

export interface IUserSignatures {
	taskUserSignatures: ITaskUserSignatures[];
	activeTaskIds: number[];
}

export interface ITaskUserSignatures {
	taskId: number;
	taskText: string;
	taskSignatureId: number;
	isQueued: boolean;
	isAsyncAvailable: boolean;
	pcIsServerSign: boolean;
	canEditAcceptors: boolean;
	timeToSignCanBeChanged: boolean;
	subcat: {
		parentCategoryId: number;
		defaultViewType: any;
		isPortal: boolean;
		name: string;
		id: number;
	};
	guid: string;
	description: string;
	requestor: Partial<IUserShort>;
	extParams: any[];
	owner: Partial<IUserShort>;
	responsiblePerformer: Partial<IUserShort>;
	signatureResolutions: ITaskUserSignatureResolutions[];
	edsType: any;
	activeTransaction: boolean;
	taskSignatureOrderedTime: string;
	taskSignatureInitializeDate: string;
	signatureAcceptants: ISignatureAcceptants[];
	signatureRouteSigners: any;
	acceptorAssistants: any[];
	edsFiles: any[];
	requestReasonOnAccept: boolean;
	canBeEscalated: boolean;
	mobileTemplate: string;
	reason: string;
	isEds: boolean;
	colors?: ITaskUserSignaturesColors;
	commentsFromMeCount?: number;
	commentsToMeCount?: number;
	isAnyAnswered: boolean;
	isAnyUnAnswered: boolean;
}

export interface ITaskUserSignatureResolutions {
	resolutionTypeId: number;
	name: string;
	signatureAction: ISignatureActionType;
	reasonRequired: boolean;
	needConfirm: boolean;
	dialogHeader: any;
	isEds?: boolean;
	edsType?: IEdsType;
	taskId?: number;
	colorHex?: string;
}

export enum IEdsType {
	CryptoProRuToken = 'CryptoProRuToken',
	PayControl = 'PayControl',
	DSSCryptoPro = 'DSSCryptoPro',
}

export enum ISignatureActionType {
	accept = 'Accept',
	reject = 'Reject',
	delete = 'Delete',
	delegate = 'Delegate',
	journal = 'openJournal',
	escalate = 'Escalate',
}

export interface ISignatureAcceptants {
	isOnline: boolean;
	isLastOnlineFromMobileApp: boolean;
	lastOnlineTime: string;
	lastPersonalInfoUpdateTime: string;
	absences: IAbsence[];
	userState: {
		isOnline: boolean;
		absence: any;
		absenceTypeUserColor: any;
		isFired: boolean;
		isInMaternityLeave: boolean;
		isEmployee: boolean;
	};
	name: string;
	id: number;
}

export interface IUserSubstitute {
	userId: number;
	from: string;
	to: string;
	additionalPhone: string;
	displayName: string;
	phone: string;
	assistanceId: number;
}

export interface IUserSubstituteData {
	userId: number;
	from: string;
	to: string;
	assistantUserId: number;
}

export interface IUserProcessAssistant {
	userId: number;
	processAssistantUserId: number;
	processName: string;
	displayName?: string;
}

export interface ITaskUserSignaturesColors {
	stateText?: string;
	stateBackround?: string;
	taskTextText?: string;
	taskTextBackround?: string;
	dueDateText?: string;
	dueDateBackround?: string;
	ownerText?: string;
	ownerBackround?: string;
	performerText?: string;
	performerBackround?: string;
	planText?: string;
	planBackround?: string;
	factText?: string;
	factBackround?: string;
}

export interface IUserOrgStructureResponse {
	id: number;
	name: string;
	hierarchy: string;
}

export interface IUserChangePasswordRequest {
	userId: number;
	newPassword: string;
	factorKey?: string;
	factorCode?: string;
}

export interface IUserChangePasswordResponse {
	type: string;
	title: string;
	status: number;
	detail: string;
	instance: string;
	additionalProp1?: string;
	additionalProp2?: string;
	additionalProp3?: string;
}
