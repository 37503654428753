import { inject, Type } from '@angular/core';
import { Observable } from 'rxjs';

export abstract class ImageOptimizer {
	abstract optimize(image: File, options?: Record<any, any>): Observable<File>;
}

export class ImageOptimizeStrategy<T extends ImageOptimizer = ImageOptimizer> {
	constructor(readonly name: string, readonly optimizer: Type<T>) {}

	optimizerRef = inject(this.optimizer);

	optimize(image: File, options?: Record<any, any>): Observable<File> {
		return this.optimizerRef.optimize(image, options);
	}
}
