import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CategoryControlComponent } from './category-control.component';
import { CategoryItemModule } from './category-item/category-item.module';
import { SidebarSearchModule } from '@spa/facade/layout/components/sidebar-search/sidebar-search.module';

@NgModule({
	imports: [CommonModule, MatIconModule, CategoryItemModule, SidebarSearchModule],
	exports: [CategoryControlComponent],
	declarations: [CategoryControlComponent],
	providers: [],
})
export class CategoryControlModule {}
