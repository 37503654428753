import { Directive, ElementRef, HostBinding, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: '[vhFluentBoxItem]',
})
export class FluentBoxDirective {
	constructor(readonly elRef: ElementRef<HTMLElement>, readonly templateRef: TemplateRef<any>) {}

	@HostBinding('class.vh-common-fluent-box-item')
	hostClassSelector = true;

	@Input('vhFluentBoxItem')
	value: any;
}
