<vh-content-icon
	*ngIf="current$ | async as current"
	role="button"
	[tippy]="current.title || ''"
	[isEnabled]="!!current.title"
	(click)="onClick($event)"
	[name]="current.icon"
	[size]="current.iconSize"
	[color]="current.iconColor"
></vh-content-icon>
