import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DataHttpService, IGetUserSettingsResult } from '@valhalla/data/http';
import { Observable, from, lastValueFrom } from 'rxjs';
import { SessionUser } from '@valhalla/core';
import { map, switchMap, take } from 'rxjs/operators';
import { trimProps, lowerCaseMapper } from '@valhalla/utils';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { BlockId, IBlock } from '@spa/data/entities';

@Injectable({ providedIn: 'root' })
export class CanActivateDefaultPage {
	constructor(
		readonly router: Router,
		readonly route: ActivatedRoute,
		readonly httpData: DataHttpService,
		readonly sessionUser: SessionUser,
		readonly mobileView: MobileViewService
	) {}

	userSettings$ = this.httpData.users.sessionUserSettings$;

	startPage$ = this.userSettings$;

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const redirectByQueryParams$ = this.redirectByQueryParams(route);

		if (redirectByQueryParams$) {
			return redirectByQueryParams$;
		}

		return this.startPage$.pipe(
			switchMap(userSettings => {
				if (this.mobileView.mobileMode) {
					return this.openMobileStartPage();
				}

				return this.openStartPage(userSettings);
			})
		);
	}

	openStartPage(userSettings: IGetUserSettingsResult) {
		if (!userSettings.userUISettings.userStartPageType) {
			return this.router.navigate(['/feeds/default']);
		}

		if (userSettings.userUISettings.userStartPageType === 'Lenta') {
			return this.router.navigate(['feeds/default']);
		}

		if (userSettings.userUISettings.userStartPageType === 'Agenda') {
			return this.router.navigate(['/link'], {
				queryParams: {
					url: `/NewCustomGrid.aspx?force=grid&Type=FromYou&UserID=${this.sessionUser.userId}`,
				},
			});
		}

		if (userSettings.userUISettings.userStartPageType === 'Default') {
			const defaultPageLink: string = userSettings.startPage;

			const spaNoFrameLinkPart = 'spa/noframe';
			if (defaultPageLink.includes(spaNoFrameLinkPart)) {
				const spaLink = defaultPageLink.substr(defaultPageLink.indexOf(spaNoFrameLinkPart) + spaNoFrameLinkPart.length);
				return this.router.navigate([spaLink]);
			}

			const spaLinkPart = 'spa/';
			if (defaultPageLink.includes(spaLinkPart)) {
				const spaLink = defaultPageLink.substr(defaultPageLink.indexOf(spaLinkPart) + spaLinkPart.length - 1);
				return this.router.navigate([spaLink]);
			}

			const portalLink = '/portal/';
			if (defaultPageLink.includes(portalLink)) {
				const strPid = defaultPageLink.substr(defaultPageLink.indexOf(portalLink) + portalLink.length);
				const portalId = Number(strPid);
				if (!isNaN(portalId)) {
					return this.router.navigate([`/portal/${portalId}`]);
				}
			}

			const aspxLink = '.aspx';
			if (defaultPageLink.includes(aspxLink)) {
				return this.router.navigate(['/link'], {
					queryParams: {
						url: defaultPageLink,
					},
				});
			}

			return this.router.navigateByUrl(defaultPageLink);
		}
	}

	redirectByQueryParams(route: ActivatedRouteSnapshot): Observable<boolean> {
		const hasQueryParams = Object.keys(route.queryParams).length > 0;

		if (!hasQueryParams) {
			return;
		}

		const lowerCaseParams = trimProps({ ...route.queryParams }, lowerCaseMapper);
		const getParam = (par: string) => lowerCaseParams[par.toLowerCase()];

		const taskId = getParam('taskId');
		if (taskId) {
			return from(this.router.navigate(['/tasks', taskId]));
		}
	}

	openMobileStartPage() {
		return lastValueFrom(
			this.httpData.mobile.getTabbar().pipe(
				map(response => response.data[0].blocks),
				take(1)
			)
		).then(blocks => {
			const defaultBlock = blocks.find(b => (b.template.isDefault === '0' ? false : b.template.isDefault));
			const dashboardBlock = blocks.find(b => b.id === BlockId.dashboard);
			let navigationBlock: IBlock;

			if (defaultBlock) {
				navigationBlock = defaultBlock;
			} else if (dashboardBlock) {
				navigationBlock = dashboardBlock;
			} else {
				navigationBlock = blocks[0];
			}
			return this.navigateByBlock(navigationBlock);
		});
	}

	navigateByBlock(block: IBlock) {
		if (block?.id === BlockId.chats) {
			return this.router.navigate(['/chat']);
		}

		if (block?.id === BlockId.contacts) {
			return this.router.navigate(['/contacts-search']);
		}

		if (block?.id === BlockId.dashboard) {
			return this.router.navigate(['/mobile-dashboard']);
		}

		if (block?.id === BlockId.feed) {
			return this.router.navigate(['/feeds']);
		}

		if (block?.id === BlockId.tasksFeed) {
			return this.router.navigate(['/feeds/tasks']);
		}

		if (block?.id === BlockId.toSign) {
			this.mobileView.hideTabbar = false;
			return this.router.navigate(['/mobile-signatures']);
		}

		return this.router.navigate(['/mobile-dashboard']);
	}
}
