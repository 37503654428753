export enum MediaBreakpoint {
	/**
	 * 'screen and (max-width: 599px)'
	 */
	xs = 'xs',
	/**
	 * 'screen and (min-width: 600px) and (max-width: 959px)'
	 */
	sm = 'sm',
	/**
	 * 'screen and (min-width: 960px) and (max-width: 1279px)'
	 */
	md = 'md',
	/**
	 * 'screen and (min-width: 1280px) and (max-width: 1919px)'
	 */
	lg = 'lg',
	/**
	 * 'screen and (min-width: 1920px) and (max-width: 5000px)'
	 */
	xl = 'xl',
	/**
	 * 'screen and (max-width: 599px)'
	 */
	ltSm = 'lt-sm',
	/**
	 * 'screen and (max-width: 959px)'
	 */
	ltSd = 'lt-md',
	/**
	 * 'screen and (max-width: 1279px)'
	 */
	ltLg = 'lt-lg',
	/**
	 * 'screen and (max-width: 1919px)'
	 */
	ltXl = 'lt-xl',
	/**
	 * 'screen and (min-width: 600px)'
	 */
	gtXs = 'gt-xs',
	/**
	 * 'screen and (min-width: 960px)'
	 */
	gtSm = 'gt-sm',
	/**
	 * 'screen and (min-width: 1280px)'
	 */
	gtMd = 'gt-md',
	/**
	 * 'screen and (min-width: 1920px)'
	 */
	gtLg = 'gt-lg',
}
