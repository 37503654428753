import { Block$Type, INavigationMenuItem } from '@spa/common/components/navigation';
import { NavigationMenu } from '@spa/facade/features/navigation/state';
import { SubcategoryRepresentation } from '../../../../data/entities';

export enum BlockData$Type {
	title = 'TCClassLib.API.TitleData, TCClassLib',
	syndicateSubcatCounter = 'TCClassLib.API.SyndicateSubcatCounterBlockData, TCClassLib',
	syndicateCat = 'TCClassLib.API.SyndicateCatBlockData, TCClassLib',
	url = 'TCClassLib.API.UrlData, TCClassLib',
}

export enum ActionType {
	syndicate = 'Syndicate',
	link = 'Link',
	task = 'Task',
	report = 'Report',
	comment = 'Comment',
}

export interface IBlockData {
	tasks: any[];
	fullTasks?: any;
	id: number;
	type: string;
	title: string;
	url?: string;
	counter?: number;
	allCounter?: number;
	overdueCounter?: number;
	templateInfo?: any;
	templates: any[];
	taskId?: number;
	canCreate: boolean;
	tasksCounters?: Partial<{
		allTasksCount: number;
		newTasksCount: number;
		overdueTasksCount: number;
	}>;
	openType?: SubcatOpenType;
	customImageClass?: string;
	tooltip?: string;
}

export interface ITemplate {
	id: string;
	title?: string;
	align?: any;
}

export interface IBlock {
	data?: IBlockData;
	action: ActionType;
	type: Block$Type;
	id: string;
	template: ITemplate;
	blocks?: IBlock[];
	isGroupFavourite?: boolean;
}

export interface IMobileContainer {
	blocks: IBlock[];
	buttons: any;
	id: string;
	template: ITemplate;
}

export interface IMobileContainerResultDto {
	data: IMobileContainer[];
	total: number;
	aggregates?: any;
}

export interface IDataSourceDto<T> {
	data: T;
	total?: number;
	aggregates?: any;
}

export enum CategoryTreeNodeType {
	root = 'root',
	subcategory = 'Subcategory',
	category = 'Category',
}

export interface ICategoryDto {
	id: number;
	name: string;
	nodeType: CategoryTreeNodeType;
	parentId: number;
	tasksCounters: ITaskCounters;
	isDictionary?: boolean;
	canEdit?: boolean;
	canCreateTask?: boolean;
	openType?: string;
	details?: string;
	availableRepresentations?: SubcategoryRepresentation[];
}

export interface ITaskCounters {
	allTasksCount: number;
	newTasksCount: number;
	overdueTasksCount: number;
	allTasksUserOwns?: number;
	allTasksUserPerforms?: number;
}

export enum SubcatOpenType {
	/// <summary>
	/// Лента
	/// </summary>
	feed = 'Feed',
	/// <summary>
	/// Таблица
	/// </summary>
	grid = 'Grid',
	/// <summary>
	/// Календарь
	/// </summary>
	calendar = 'Calendar',
	/// <summary>
	/// Сводка
	/// </summary>
	summary = 'Summary',
	/// <summary>
	/// Гант
	/// </summary>
	gantt = 'Gantt',
	/// <summary>
	/// Переписка
	/// </summary>
	correspondence = 'Correspondence',
	/// <summary>
	/// Устаревшее хранилище файлов
	/// </summary>
	filestorage = 'Filestorage',
	/// <summary>
	/// Повестка дня
	/// </summary>
	agenda = 'Agenda',
}

export interface IFavoritesMenu {
	buttons: IFavoriteButton[];
	folders: IFavoriteFolder[];
	nodes: IFavoriteNode[];
	assistantFavoriteMenus?: IAssistantFavoriteMenus[];
	myTasksConfig?: IMyTasksConfig;
}

export interface IMyTasksConfig {
	ownerTasks: boolean;
	performerTasks: boolean;
	subscriberTasks: boolean;
	signatureTasks: boolean;
	subordinatesTasks: boolean;
	subordinatesSignatures: boolean;
}

export interface IAssistantFavoriteMenus {
	userId: number;
	userName: string;
	buttons: IFavoriteButton[];
	folders: IFavoriteFolder[];
	nodes: IFavoriteNode[];
}

export interface IAssistantFavoriteMenusState {
	menusAssistant: any[];
}

export interface IFavoritesMenuState extends IFavoritesMenu {
	id: NavigationMenu | string;
	expandedBlocks: number[];
}

export interface IFavoriteButton {
	blockId: number;
	customImageClass: string;
	customImagePath: string;
	folderId: number;
	groupId: number;
	groupOrder: number;
	href: string;
	id: number;
	isButton: boolean;
	isGroupFavourite: boolean;
	isJsFunction: boolean;
	linkedObjectId: number;
	order: number;
	realUserId: number;
	stats: any;
	title: string;
	toolTip: any;
	typeId: string;
	userId: number;
	availableRepresentations?: SubcategoryRepresentation[];
}

export interface IFavoriteFolder {
	groupId: number;
	id: number;
	isSystemFolder: boolean;
	isWorkGroupFolder: boolean;
	name: string;
	order: number;
	parentId: number;
	userId: number;
	isExpand?: boolean;
	subMenu?: IFavoriteNode[];
	subFolders?: IFavoriteFolder[];
	assistantId?: string;
	nameResx?: string;
}

export interface IFavoriteNode {
	blockId: number;
	canCreate: boolean;
	color: string;
	customImageClass: string;
	customImagePath: string;
	folderId: number;
	groupId: number;
	href: string;
	id: number;
	intColor: any;
	isButton: boolean;
	isJsFunction: boolean;
	isWorkGroupLink: boolean;
	linkedObjectId: number;
	openType: string;
	order: number;
	groupOrder: number;
	realUserId: number;
	stats: INodeStats;
	title: string;
	toolTip: any;
	typeId: any;
	unreadCount: any;
	userId: number;
	availableRepresentations?: SubcategoryRepresentation[];
}

export interface INodeStats {
	newTasksCount: number;
	overdueTasksCount: number;
	allTasksCount: number;
	subcatId: number;
}
