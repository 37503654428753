export interface IExtParam<T = any, TDataSource = any> {
	id: number;
	localizedEntityId?: number;
	name: string;
	type: string;
	order?: number;
	value: T;
	displayValue?: string;
	isHidden?: boolean;
	colsWidth?: number;
	hideLink?: boolean;
	isRequired?: boolean;
	hideIfEmpty?: boolean;
	canEdit?: boolean;
	placeholder?: string;
	regularExpression?: string;
	regularExpressionAlert?: any;
	dataSource?: TDataSource;
	settings?: Partial<{
		[key: string]: any;
		isThrough: boolean;
		realExtParamId: number;
		realTaskId: number;
	}>;
	setting?: any;
	isEncrypted?: boolean;
	colspan?: any;
	changeWithComment?: boolean;
	view?: 'Link' | 'Table';
}

export enum ExtParamTypeEnum {
	lookUpField = 'LookUpField',
	textArea = 'TextArea',
	textarea = 'Textarea',
	textareaWOFormat = 'TextareaWOFormat',
	textAreaWOFormat = 'TextAreaWOFormat',
	multiSlctSubcatTasks = 'MultiSlctSubcatTasks',
	datetime = 'Datetime',
	dateTime = 'DateTime',
	dateOnly = 'DateOnly',
	select = 'Select',
	table = 'Table',
	number = 'Number',
	numericValue = 'NumericValue',
	text = 'Text',
	checkbox = 'Checkbox',
	checkBox = 'CheckBox',
	url = 'URL',
	file = 'File',
	date = 'Date',
	address = 'Address',
	extParamAddressees = 'ExtParamAddressees',
	selectUsers = 'SelectUsers',
	combobox = 'Combobox',
	money = 'Money',
	numerator = 'Numerator',
	tree = 'Tree',
	multifile = 'Multifile',
	phone = 'Phone',
	maskedTextBox = 'MaskedTextBox',
	template = 'Template',
	buttons = 'Buttons',
	through = 'Through',
}

export interface IExtParamLookupDataSource {
	filter: Partial<{
		columnId: number;
		extParamId: number;
		firstParentColumnId: number;
		firstParentEpId: number;
		firstParentVals: any;
		multiSelect: boolean;
		secondParentColumnId: number;
		secondParentEpId: number;
		secondParentVals: any;
		selectedValuesInMultiEp: any;
		smartFilterId: number;
		taskId: number;
		taskSubcatId: number;
	}>;
	lookupId: number;
	subcatId: number;
}
