import { isString } from './is';

export function trimProps<T = Record<string, any>>(o: T, mapper?: (prop: string) => string): T {
	const props = Object.getOwnPropertyNames(o || {});
	for (const prop of props) {
		const val = o[prop];
		const trimmedProp = prop.trim();
		const mapped = mapper ? mapper(trimmedProp) : trimmedProp;
		if (mapped !== prop) {
			delete o[prop];
			o[mapped] = val;
		}
	}
	return o;
}

export function firstLetterLowerCaseMapper(str: string): string {
	if (!isString(str)) return str;
	if (str.trim().length === 0) return str;

	return str[0].toLowerCase() + str.substring(1);
}

export function firstLetterUpperCaseMapper(str: string): string {
	if (!isString(str)) return str;
	if (str.trim().length === 0) return str;

	return str[0].toUpperCase() + str.substring(1);
}

export function firstLetter(str: string, mode: 'up' | 'low') {
	if (mode === 'up') {
		return firstLetterUpperCaseMapper(str);
	}
	if (mode === 'low') {
		return firstLetterLowerCaseMapper(str);
	}
}

export function lowerCaseMapper(str: string): string {
	if (!isString(str)) {
		return str;
	}

	return str.toLowerCase();
}

export function createCaseInsensitiveGetter<T = any>(record: Record<string, T>) {
	const lowerCaseParams = trimProps(record, lowerCaseMapper);
	return (par: string) => lowerCaseParams[par.toLowerCase()];
}

export function firstLetterKeyLowerCase<R = any>(obj: Record<any, any>): R {
	if (!obj) {
		return;
	}
	const res: any = {};
	Object.keys(obj).forEach(k => {
		const p = k[0].toLowerCase() + k.substring(1);
		res[p] = obj[k];
		if (typeof res[p] === 'object') {
			firstLetterKeyLowerCase(res[p]);
		}
	});
	return res;
}
