import { InjectionToken } from '@angular/core';

export function loadKendoWithRuLocale() {
	// return () => import('@progress/kendo-angular-intl/locales/ru/all').then(() => import('@progress/kendo-angular-intl'));
	return () => Promise.resolve({} as any);
}

export type LazyKendoLocaleType = ReturnType<typeof loadKendoWithRuLocale>;

export const LazyKendoLocale = new InjectionToken<LazyKendoLocaleType>('Lazy kendo ru locale', {
	providedIn: 'root',
	factory: () => loadKendoWithRuLocale(),
});
