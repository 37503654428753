<div class="content-overlay-container">
	<ng-container *ngFor="let stackItem of stackItems$ | async">
		<!-- <ng-container
			*ngxComponentOutlet="stackItem.context.component; context: stackItem.context.bindings"
			(ngxComponentOutletActivate)="onOutletActivate(stackItem, $event)"
		></ng-container> -->
		<ng-temlate
			[ngxComponentOutlet]="stackItem.context.component"
			[ngxComponentOutletContext]="stackItem.context.bindings"
			(ngxComponentOutletActivate)="onOutletActivate(stackItem, $event)"
		></ng-temlate>
	</ng-container>
</div>
