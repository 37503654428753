import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
	MatLegacySnackBarRef as MatSnackBarRef,
	MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
} from '@angular/material/legacy-snack-bar';
import { isFunction } from '@valhalla/utils';

@Component({
	selector: 'vh-clipboard-snack-bar',
	templateUrl: './clipboard-snack-bar.component.html',
	styleUrls: ['./clipboard-snack-bar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ClipboardSnackBarComponent implements AfterViewInit {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) readonly data: any,
		readonly snackBarRef: MatSnackBarRef<ClipboardSnackBarComponent>,
		readonly cdr: ChangeDetectorRef
	) {}

	messageResx: string = this.data.messageResx || 'common.copied';

	actions = this.data.actions || [];

	ngAfterViewInit() {
		this.cdr.detectChanges();
	}

	onClickAction(action) {
		if (isFunction(action?.action)) {
			action?.action();
		}
		this.snackBarRef.dismissWithAction();
	}
}
