import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { DataHttpService } from '@valhalla/data/http';
import { BooleanFilter, rxHandler } from '@valhalla/utils';
import { BehaviorSubject, from, fromEvent, of, Subject } from 'rxjs';
import { delay, distinctUntilChanged, map, startWith, switchMap, takeUntil, shareReplay } from 'rxjs/operators';

import { SearchOverlayProgressBarService } from './progress-bar.service';
import { MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { Router } from '@angular/router';

@Component({
	selector: 'vh-layout-search-overlay',
	templateUrl: 'search-overlay.component.html',
	styleUrls: ['search-overlay.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class SearchOverlayComponent implements OnInit, AfterViewInit, OnDestroy {
	constructor(readonly server: DataHttpService, readonly router: Router) {}

	@HostBinding('class.vh-layout-search-overlay')
	hostClassSelector = true;

	@ViewChild('input', { static: true })
	inputEl: ElementRef<HTMLElement>;

	@ViewChild('searchWrapper')
	searchWrapper: ElementRef<HTMLElement>;

	@ViewChild('searchHistory')
	searchHistory: ElementRef<HTMLElement>;

	@Output()
	search = new EventEmitter<string>();

	@Output()
	outsideClick = new EventEmitter();

	readonly searchHandler = rxHandler<string>();
	readonly searchInput$ = from<string>(this.searchHandler as any).pipe(startWith(''));
	readonly searchInputHasValue$ = this.searchInput$.pipe(distinctUntilChanged(), map(BooleanFilter));

	readonly progress = new SearchOverlayProgressBarService();
	protected readonly loading$ = new BehaviorSubject(false);
	protected readonly destroy$ = new Subject();

	readonly linksHistory$ = this.server.history
		.getTasks({ count: 50 })
		.pipe(shareReplay({ refCount: true, bufferSize: 1 }));

	readonly tasksHistory$ = this.linksHistory$.pipe(map(links => links.filter(({ isTask }) => isTask)));

	readonly employeesHistory$ = this.server.history.getEmployees().pipe(shareReplay({ refCount: true, bufferSize: 1 }));

	readonly usersHistory$ = this.employeesHistory$.pipe(map(items => items.filter(({ isGroup }) => !isGroup)));

	ngOnInit() {
		this.loading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
			if (loading) {
				this.progress.show();
			} else {
				this.progress.hide();
			}
		});
	}

	ngOnDestroy() {
		this.progress.dispose();
	}

	ngAfterViewInit() {
		this.inputEl.nativeElement.focus();

		of(0)
			.pipe(
				delay(100),
				switchMap(_ => fromEvent(window, 'click')),
				takeUntil(this.destroy$)
			)
			.subscribe(({ target }) => {
				const el = target as HTMLElement;
				const isOutsideWrapperClick =
					!this.searchWrapper.nativeElement.contains(el) && !this.searchHistory.nativeElement.contains(el);
				if (isOutsideWrapperClick) {
					this.outsideClick.emit();
				}
			});
	}

	onKeyDownArrowInTaskSearch(e: Event) {
		e && e.preventDefault();
		const li = document.querySelector<HTMLElement>('.search-history .mat-list-item');
		li?.focus();
	}

	clearSearch() {
		this.searchHandler('');
	}

	loading(loading = true) {
		this.loading$.next(loading);
	}

	selectHistoryItem({ options }: MatSelectionListChange) {
		const option = options[0];
		const { type, value } = option.value;
		switch (type) {
			case 'user':
				this.openUserProfilePage(value.id);
				break;
			case 'task':
				this.openTask(value.key);
				break;
			default:
				break;
		}
		this.outsideClick.emit();
	}

	openUserProfilePage(userId: number) {
		return this.router.navigate(['/link'], {
			queryParams: {
				url: `/UserInfo.aspx?UserID=${userId}`,
			},
		});
	}

	openTask(taskId: number) {
		return this.router.navigate(['/tasks', taskId]);
	}
}
