import { IAttachment } from '@spa/data/entities';
import { guid } from '@valhalla/utils';
import { map, switchMap } from 'rxjs/operators';
import { ExtParamBase } from './ext-param-base';
import { of } from 'rxjs';

export type IExtParamFileValue = Partial<IAttachment>;

export class ExtParamFile extends ExtParamBase<IExtParamFileValue> {
	equalsValue(a: IExtParamFileValue, b: IExtParamFileValue) {
		if (!a && !b) {
			return true;
		}
		if (this.isNewTask) {
			return a?.file === b?.file;
		}
		return +a?.uploadId === +b?.uploadId;
	}

	getValueForCopy() {
		return '';
	}

	setCopiedValue(value) {
		return;
	}

	convertForSaveInNewTaskAsyncMiddleware = () =>
		this.value$.pipe(
			switchMap(attachment => {
				if (attachment?.fileId) {
					return of(`__UploadFromFileStorage:${attachment?.fileId}`);
				}

				if (!attachment?.file) {
					if (attachment?.uploadId) {
						return of(JSON.stringify(attachment));
					}
					return of('');
				}
				const data = new FormData();
				data.append(guid(), attachment.file, attachment.file.name);
				return this.server.files.ToPreUploadedFiles(data).pipe(
					map(r => r.data),
					map(value => (value.length > 0 ? `__PreuploadedFile:${value?.[0]?.preUploadFileId}` : ''))
				);
			})
		);

	get extParamQueryStringValue(): string {
		if (!this.value?.fileId) {
			return '';
		}
		return `$Ext${this.id}$${this.value.fileId}`;
	}
}
