import { spaCommand } from '@spa/api/spa-command';
import { IAttachment } from '@valhalla/data/entities';

import { GlobalEventHandler } from './type';

export const openFilePreview: GlobalEventHandler = injector => {
	function onOpenFilePreviewHandler(e: CustomEvent<{ file: IAttachment; taskId: number; previewLink?: string }>) {
		spaCommand('open-file-preview', {
			file: e?.detail?.file,
			taskId: e?.detail?.taskId,
			previewLink: e?.detail?.previewLink,
		});
	}

	window.addEventListener('spa:open-file-preview', onOpenFilePreviewHandler);

	return () => {
		// dispose
		window.removeEventListener('spa:open-file-preview', onOpenFilePreviewHandler);
	};
};
