import { Observable } from 'rxjs';

import { IUserSettingsState } from '../store/state';

export abstract class UserSettingsFacadeProvider {
	abstract updateUserSettings(settings: Partial<IUserSettingsState>): void;
	abstract loadUserSettings(): void;

	selectUserSettings$: Observable<IUserSettingsState>;
	selectUserSettings: IUserSettingsState;
}
