export function filterItems(items: any[], filter: string) {
	const filterLowerCase = filter?.toLowerCase();

	return items.reduce((acc, el) => {
		const name = el?.name?.toLowerCase();
		if (name.includes(filterLowerCase)) {
			const children = filterItems(el?.children, filterLowerCase);

			acc.push({
				...el,
				isOpen: true,
				children,
			});

			return acc;
		}

		if (el?.children?.length && !name.includes(filterLowerCase)) {
			const children = filterItems(el?.children, filterLowerCase);

			if (children?.length) {
				acc.push({
					...el,
					isOpen: true,
					children,
				});
			}

			return acc;
		}

		return acc;
	}, []);
}
