import { NgZone } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { jsonTryStringify } from '@valhalla/utils';
import { filter } from 'rxjs/operators';

import { helper } from './helper';

import type { WidgetPortalBase } from '@spa/components/widgets/base';
const portalBlocks: Record<number, Partial<IPortalBlockApiData>> = {};

type WidgetBase = WidgetPortalBase;

function unlink<T = any>(o: T): T {
	const json = jsonTryStringify(o);
	return JSON.parse(json);
}

let blockLoadedEmit: Record<
	number,
	{
		data: any;
	}
> = {};

let ngZone: NgZone = null;
let ngRouter: Router = null;

/**
 * Call from SPA only
 */
const internalApi = {
	addBlock(blockId: number) {
		if (!portalBlocks[blockId]) {
			portalBlocks[blockId] = {
				onLoadedCallbacks: [],
			};
		}
	},
	getBlock(blockId: number) {
		const block = portalBlocks[blockId];
		if (!block) {
			internalApi.addBlock(blockId);
		}
		return portalBlocks[blockId];
	},
	emitEventBlockLoaded(...widgets: WidgetBase[]) {
		(widgets || []).forEach(widget => {
			const block = internalApi.getBlock(widget.widgetId);

			block.widgetInstance = widget;

			const data = { blockId: widget.widgetId };
			blockLoadedEmit[widget.widgetId] = {
				...(blockLoadedEmit[widget.widgetId] || {}),
				data: data,
			};

			block.onLoadedCallbacks.forEach(cb => {
				ngZone?.runOutsideAngular(() => {
					helper.tryRun(() => {
						cb(data);
					}, data);
				});
			});
		});
	},
};

/**
 * Call from external code
 */
const publicApi = {
	block: (blockId: number) => {
		const get = () => {
			const block = internalApi.getBlock(blockId);
			return block?.widgetInstance;
		};

		const filterHelperService = async () => {
			const { FilterHelperService } = await import('@spa/components/filters/filter/filter-helper.service');
			const cmp = get();
			return cmp?.injector?.get(FilterHelperService);
		};

		const context = () => {
			const widget = get();
			if (widget) {
				return unlink(widget.context?.data);
			}
		};

		const refresh = () =>
			ngZone.runTask(() => {
				const cmp = get();
				return cmp?.refresh();
			});

		const resize = () =>
			ngZone.runTask(() => {
				const cmp = get();
				return cmp?.updateSize();
			});

		return {
			onLoaded: (cb: (...args: any[]) => void) => {
				const blockData = internalApi.getBlock(blockId);
				if (blockData && typeof cb === 'function') {
					blockData.onLoadedCallbacks.push(cb);
					// run if already emitted
					const data = blockLoadedEmit[blockId];
					if (data) {
						ngZone?.runOutsideAngular(() => {
							helper.tryRun(
								() => {
									cb(data);
								},
								'Error in JS include in context portal block',
								data
							);
						});
					}
				}
			},
			refresh() {
				return refresh();
			},
			resize() {
				return resize();
			},
			get context() {
				return context();
			},
			get filters() {
				const widget = get();
				if (!widget) {
					return [];
				}

				const filterRecords = widget.filter || {};
				const filters = unlink(Object.values<any>(filterRecords)).map(filterState => {
					return {
						get id() {
							return filterState.id;
						},
						get name() {
							return filterState.name;
						},
						get type() {
							return filterState.type;
						},
						get value() {
							return filterState.value;
						},
						get() {
							return filterState.value;
						},
						async set(value: any) {
							const helperService = await filterHelperService();
							await helperService.setFilters(widget, {
								[filterState.name]: value,
							});
							refresh();
						},
					};
				});

				return filters;
			},
			async setFilters(values: Record<string, any>) {
				const widget = get();
				if (!widget) {
					return;
				}
				const helperService = await filterHelperService();
				await helperService.setFilters(widget, values);
				refresh();
			},
		};
	},
};

interface IPortalBlockApiData {
	onLoadedCallbacks: ((...args: any[]) => void)[];
	widgetInstance: WidgetBase;
}

export const portalApi = {
	internalApi,
	publicApi,
};

export function setNgZone(zone: NgZone) {
	ngZone = zone;
}

export function setNgRouter(router: Router) {
	ngRouter = router;

	ngRouter.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(() => {
		blockLoadedEmit = {};
		Object.entries(portalBlocks).forEach(([blockId, desc]) => {
			desc.onLoadedCallbacks.length = 0;
		});
	});
}
