import { Injector } from '@angular/core';

import { groupHandler } from './group-handler';
import { openFilePreview } from './open-file-preview';
import { openTask } from './open-task';
import { profileAspxCreateChatButtonHandler } from './vh-frame-load-handlers/profile-aspx-create-chat';
import { openModalIframe } from './open-modal-iframe';
import { navigate } from './navigate';

export function initializeGlobalEventHandlers(rootInjector: Injector) {
	const disposeGroup = groupHandler(
		profileAspxCreateChatButtonHandler,
		openFilePreview,
		openTask,
		openModalIframe,
		navigate
	)(rootInjector);

	return function dispose() {
		disposeGroup();
	};
}
