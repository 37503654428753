import { Inject, Injectable } from '@angular/core';
import { isNumber } from '@valhalla/utils';
import { LoggerFactory } from '@valhalla/core';

import { ApplicationsDbProvider, IAppDefinition } from '../db';
import { Activator, ApplicationActivator } from './abstract';

@Injectable()
export class ApplicationActivatorImpl implements ApplicationActivator {
	constructor(
		@Inject(Activator) protected activators: Array<Activator>,
		protected loggerFactory: LoggerFactory,
		protected appDb: ApplicationsDbProvider
	) {
		this.activators = this.buildChainActivators(activators);
	}

	protected logger = this.loggerFactory.createLogger('ApplicationActivatorImpl');

	openApplication(appOrId: Partial<IAppDefinition> | number) {
		if (this.activators && this.activators.length > 0) {
			const appId = isNumber(appOrId) ? appOrId : appOrId.id;
			this.appDb.selectAppById(appId).subscribe(app => this.activators[0].activate(app));
		} else {
			this.riseNotFound();
		}
	}

	resolveAppRoute(app: IAppDefinition): { url: string; query: Record<string, string> } {
		if (this.activators && this.activators.length > 0) {
			return this.activators[0].resolveAppRoute(app);
		} else {
			this.riseNotFound();
		}
	}

	protected riseNotFound() {
		this.logger.warn('No activators registered');
	}

	protected buildChainActivators(activators: Array<Activator>) {
		for (let i = 0; i < activators.length; i++) {
			activators[i].successor = activators[i + 1];
		}
		// add last in chain to log warning
		activators[activators.length - 1].successor = new LastActivatorInChain(msg => this.logger.warn(msg));
		return activators;
	}
}

class LastActivatorInChain extends Activator {
	constructor(protected cb?: (msg: string) => void) {
		super();
	}
	activate(app: IAppDefinition) {
		this.cb && this.cb(`No activator found, to open app type ${app.type}`);
	}

	resolveAppRoute(app: IAppDefinition): never {
		throw new Error(`No activator found, to open app type ${app.type}`);
	}
}
