import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface IUiMonacoEditorOptions {
	monacoLoaderPath: string;
	monacoVsPath: string;
	loadScripts: (...paths: string[]) => Observable<any>;
}

export const UI_MONACO_EDITOR_OPTIONS = new InjectionToken<IUiMonacoEditorOptions>('Ui Monaco editor options');
