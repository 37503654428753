import { Observable } from 'rxjs';

export abstract class CopyService {
	/**
	 * Copy the text value to the clipboard
	 *
	 * @param {string} text
	 * @returns {Observable<boolean>}
	 */
	abstract copyText(text: string): Observable<boolean>;
}
