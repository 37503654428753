import { ModuleWithProviders, NgModule } from '@angular/core';

import { CopyService } from './abstract';
import { CopyServiceImpl } from './copy.service';

@NgModule()
export class CopyModule {
	static forRoot(): ModuleWithProviders<CopyModule> {
		return {
			ngModule: CopyModule,
			providers: [
				{
					provide: CopyService,
					useClass: CopyServiceImpl,
				},
			],
		};
	}
}
