import { Inject, Injectable, Injector, NgZone } from '@angular/core';
import { merge } from '@valhalla/utils';
import { BehaviorSubject, Observable } from 'rxjs';

import { LocalStorageProvider } from '../../db/local-storage';
import { EventLogProvider } from '../eventlog';
import { StackTraceService } from '../stack-trace';
import { AbstractLogger, ILoggerFactory } from './abstract';
import { Logger } from './logger';
import { defaultOptions, LOGGER_OPTIONS } from './logger-options';
import type { ILoggerOptions } from './logger-options';

@Injectable()
export class LoggerFactoryImpl implements ILoggerFactory {
	constructor(@Inject(LOGGER_OPTIONS) loggerOptions: ILoggerOptions, private readonly _injector: Injector) {
		this._loggerOptions = merge(defaultOptions, loggerOptions);
	}
	private _zone = this._injector.get(NgZone);
	private _loggerOptions: ILoggerOptions;
	private _loggersRef = new Map<string, Array<AbstractLogger>>();
	private _loggersEnable$ = new BehaviorSubject<Record<string, boolean>>({});
	private _loggersStorageKey = 'core/logger-enable';
	private _storage: LocalStorageProvider;

	get loggerNames(): string[] {
		return Array.from(this._loggersRef.keys());
	}

	get loggersEnable$(): Observable<Record<string, boolean>> {
		return this._loggersEnable$;
	}

	isLoggerEnable(name: string) {
		const state = this._loggersEnable$.value;
		return Boolean(state[name]);
	}

	getLoggersByName(name: string): AbstractLogger[] {
		return this._loggersRef.get(name) || [];
	}

	createLogger(name?: string): AbstractLogger {
		const logger = new Logger(
			name || 'Logger',
			() => this._loggerOptions,
			() => this._injector.get(StackTraceService),
			() => this._injector.get(EventLogProvider)
		);
		this.saveLoggerRef(logger);
		this._zone.runOutsideAngular(() => {
			setTimeout(() => {
				this.initLoggerFromStorage(logger);
			});
		});
		return logger;
	}

	enableLogger(name: string) {
		if (this.isLoggerEnable(name)) {
			return;
		}
		const loggers = this.getLoggersByName(name);
		loggers.forEach(logger => {
			logger.enable();
		});
		const loggerStates = this._storage.get<Record<string, boolean>>(this._loggersStorageKey) || {};
		loggerStates[name] = true;
		this._storage.set(this._loggersStorageKey, loggerStates);
		this.nextLoggerEnable(loggerStates);
	}
	disableLogger(name: string) {
		if (!this.isLoggerEnable(name)) {
			return;
		}
		const loggers = this.getLoggersByName(name);
		loggers.forEach(logger => {
			logger.disable();
		});
		const loggerStates = this._storage.get<Record<string, boolean>>(this._loggersStorageKey) || {};
		loggerStates[name] = false;
		this._storage.set(this._loggersStorageKey, loggerStates);
		this.nextLoggerEnable(loggerStates);
	}
	enableAllLoggers() {
		this.loggerNames.forEach(name => this.enableLogger(name));
	}
	disableAllLoggers() {
		this.loggerNames.forEach(name => this.disableLogger(name));
	}

	protected initLoggerFromStorage(logger: AbstractLogger) {
		if (!this._storage) {
			this._storage = this._injector.get(LocalStorageProvider);
		}
		const loggerStates = this._storage.get<Record<string, boolean>>(this._loggersStorageKey) || {};
		if (!(logger.name in loggerStates)) {
			// enable all by default
			loggerStates[logger.name] = false;
		} else if (loggerStates[logger.name]) {
			logger.enable();
		} else {
			logger.disable();
		}
		this._storage.set(this._loggersStorageKey, loggerStates);
		this.nextLoggerEnable(loggerStates);
	}

	protected nextLoggerEnable(loggerStates: Record<string, boolean>) {
		const currentLoggerStates = this.loggerNames.reduce((acc, cur) => {
			acc[cur] = loggerStates[cur];
			return acc;
		}, {});
		this._loggersEnable$.next(currentLoggerStates);
	}

	protected saveLoggerRef(logger: AbstractLogger) {
		if (!logger) {
			return;
		}
		if (!this._loggersRef.has(logger.name)) {
			this._loggersRef.set(logger.name, [logger]);
		} else {
			const loggers = this._loggersRef.get(logger.name);
			loggers.push(logger);
		}
	}
}
