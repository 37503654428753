import { searchInObj } from './search';

export function removeDuplicate<T>(arr: T[]) {
	return (arr || []).filter((item, index, self) => {
		return self.indexOf(item) === index;
	});
}

export function removeDuplicateByFn<T>(arr: T[], isItemsSame: (i1: T, i2: T) => boolean) {
	const result = arr.reduce((acc, cur) => {
		const alreadyHas = Boolean(acc.find(i => isItemsSame(i, cur)));
		if (!alreadyHas) {
			acc.push(cur);
		}
		return acc;
	}, []);
	return result;
}

export function removeDuplicateByKey<T>(arr: T[], key: keyof T): T[] {
	if (!Array.isArray(arr)) {
		return arr;
	}

	const result = [];
	const index = new Set<any>();

	arr.forEach(item => {
		const val = item[key];

		if (!index.has(val)) {
			index.add(val);
			result.push(item);
		}
	});

	index.clear();

	return result;
}

export type Selector<T> = (item: T) => string | number;
export type Mapper<T, R> = (item: T) => R;
export function arrayToObject<T>(items: T[], selector: Selector<T>): Record<string | number, T>;
export function arrayToObject<T, R>(
	items: T[],
	selector: Selector<T>,
	mapper?: Mapper<T, R>
): Record<string | number, R>;
export function arrayToObject<T, R>(
	items: T[],
	selector: Selector<T>,
	mapper?: Mapper<T, R>
): Record<string | number, R> {
	return (items || []).reduce((acc, cur) => ((acc[String(selector(cur))] = mapper ? mapper(cur) : cur), acc), {});
}

export function filterArrayByString(mainArr, searchText) {
	if (searchText === '') {
		return mainArr;
	}

	searchText = searchText.toLowerCase();

	return mainArr.filter(itemObj => {
		return searchInObj(itemObj, searchText);
	});
}

export function toggleInArray(item, array): void {
	if (array.indexOf(item) === -1) {
		array.push(item);
	} else {
		array.splice(array.indexOf(item), 1);
	}
}

export function arrayFlatMap<T, K>(arr: T[], selector?: (item: T) => K[]): K[] {
	const anyArr = arr as any;
	selector = selector || ((i => i) as any);
	if (typeof anyArr.flatMap === 'function') {
		return anyArr.flatMap(selector);
	}
	const flat = arr.reduce((acc, cur) => {
		const val = selector(cur) || [];
		acc.push(...val);
		return acc;
	}, []);
	return flat;
}

export function flatMap<R = any, T = any>(
	obj: T,
	selector: (i: T) => T[keyof T],
	nestedArrayPropSelector: (i: T) => T[]
): R[] {
	const arr = nestedArrayPropSelector(obj);
	return (
		arr &&
		(<any>arr).flatMap((i: any) => {
			const fn = (nested: T) =>
				nestedArrayPropSelector(nested)
					? [selector(nested), ...(<any>nestedArrayPropSelector(nested)).flatMap(fn)]
					: [selector(nested)];
			return fn(i);
		})
	);
}

export function isArraysHasTheSameValues<A1 = any, A2 = any>(
	arr1: A1[],
	arr2: A2[],
	selector1?: (item1: A1) => any,
	selector2?: (item1: A2) => any
): boolean {
	if ((arr1 && !arr2) || (!arr1 && arr2)) {
		return false;
	}
	if (<any>arr1 === <any>arr2) {
		return true;
	}
	if (arr1.length !== arr2.length) {
		return false;
	}

	// count each value entry in each array and compare
	// store values and count in Map

	const values1 = arr1.map(selector1 || (i => i));
	const values2 = arr2.map(selector2 || (i => i));
	const map1 = new Map<any, number>();
	const map2 = new Map<any, number>();

	function countEntry(map: Map<any, number>, val: any) {
		if (!map.has(val)) {
			map.set(val, 0);
		}
		const next = map.get(val) + 1;
		map.set(val, next);
	}

	values1.forEach((val1, idx) => {
		const val2 = values2[idx];
		countEntry(map1, val1);
		countEntry(map2, val2);
	});

	const entries1 = map1.entries();
	for (const [val1, count1] of entries1) {
		if (map2.get(val1) !== count1) {
			return false;
		}
	}

	return true;
}

export function arrayDistinct<T>(arr: Array<T>, selector?: (o: T) => T[keyof T]): T[] {
	if (!Array.isArray(arr)) {
		return;
	}
	if (!selector) {
		return removeDuplicate(arr);
	}
	const uniques: Array<{ key: any; value: any }> = [];
	arr.forEach(item => {
		const key = selector(item);
		const hasKey = Boolean(uniques.find(unique => unique.key === key));
		if (!hasKey) {
			uniques.push({ key, value: item });
		}
	});
	const result = uniques.map(i => i.value);
	return result;
}

export function sortByLocaleCompare<T>(arr: T[], getter: (o: T) => string, locales?: any, options?: any) {
	if (!Array.isArray(arr)) {
		return arr;
	}

	return arr.sort((a, b) => {
		const aVal = getter(a);
		const bVal = getter(b);

		if (typeof aVal !== 'string') return -1;
		if (typeof bVal !== 'string') return 1;

		return aVal.localeCompare(bVal, locales, options);
	});
}

export function boolGroupBy<T>(arr: T[], condition: (item: T) => boolean): { true: T[]; false: T[] } {
	const res = { true: [], false: [] };
	for (const val of arr) {
		if (condition(val)) res.true.push(val);
		else res.false.push(val);
	}
	return res;
}

export function arrayExclude<T>(fromArr: T[], values: T[], byKey?: (item: T) => any): T[] {
	return fromArr.filter(fa => !values.some(va => (byKey ? byKey(va) === byKey(fa) : va === fa)));
}

export type CreateArrayWalkerOptions<T> = {
	array: Array<T>;
	selectedIndex: number;
};
export type ArrayWalker<T = any> = {
	current: () => T;
	next: () => T;
	prev: () => T;
	hasNext: () => boolean;
	hasPrev: () => boolean;
};
export function createArrayWalker<T = any>(opt: CreateArrayWalkerOptions<T>): ArrayWalker<T> {
	const walker: ArrayWalker<T> = {
		next() {
			if (walker.hasNext) {
				opt.selectedIndex++;
				return opt.array[opt.selectedIndex];
			}
		},
		current() {
			return opt.array[opt.selectedIndex];
		},
		prev() {
			if (walker.hasPrev) {
				opt.selectedIndex--;
				return opt.array[opt.selectedIndex];
			}
		},
		hasNext() {
			return opt.selectedIndex + 1 < opt.array.length;
		},
		hasPrev() {
			return opt.selectedIndex - 1 >= 0;
		},
	};
	return walker;
}

export function groupBy<T, K = any>(arr: T[], key: (i: T) => K, sortKey?: (a: K, b: K) => number) {
	const map = new Map<K, T[]>();
	arr.forEach(i => {
		const k = key(i);
		const ma = map.get(k) || [];
		ma.push(i);
		map.set(k, ma);
	});
	if (sortKey) {
		return Array.from(map).sort((a, b) => sortKey(a[0], b[0]));
	}
	return Array.from(map);
}
