import { Inject, Injectable, Provider } from '@angular/core';
import { ConfigurationProvider, IRxStore } from '@valhalla/core';

import { STORE_USER_SETTINGS } from '../ng-tokens';
import * as actions from '../store/actions';
import { IUserSettingsState } from '../store/state';
import { UserSettingsFacadeProvider } from './abstract';

@Injectable()
export class UserSettingsFacadeProviderImpl implements UserSettingsFacadeProvider {
	constructor(
		@Inject(STORE_USER_SETTINGS) protected readonly store: IRxStore<IUserSettingsState>,
		protected readonly config: ConfigurationProvider
	) {}

	selectUserSettings$ = this.store.select(state => state);

	updateUserSettings(settings: Partial<IUserSettingsState>) {
		this.store.dispatch(actions.updateUserSettings(settings));
	}

	loadUserSettings(): void {
		this.store.dispatch(actions.loadUserSettings());
	}

	get selectUserSettings() {
		return this.store.getState<IUserSettingsState>();
	}
}

export const ngProviderUserSettingsFacade: Provider = {
	provide: UserSettingsFacadeProvider,
	useClass: UserSettingsFacadeProviderImpl,
};
