import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, mergeMap, shareReplay, switchMap, take } from 'rxjs/operators';

import { DesktopNotifications, IDesktopNotificationSendResult } from './abstract';
import { INotificationOptions } from './notification-options';
import { NotificationPermissions } from './notification-permissions';

@Injectable()
export class DesktopNotificationsImpl implements DesktopNotifications {
	readonly hasNotificationSupport$ = of('Notification' in window);

	readonly permission$ = this.hasNotificationSupport$.pipe<NotificationPermissions>(
		map(hasSupport => (hasSupport ? <NotificationPermissions>Notification.permission : NotificationPermissions.denied))
	);

	readonly requestPermission$ = this.permission$.pipe(
		mergeMap(permission => {
			if (permission === NotificationPermissions.granted) {
				return of(true);
			}

			return this.hasNotificationSupport$.pipe(
				switchMap(hasSupport => {
					if (!hasSupport) {
						return of(false);
					}

					return from(Notification.requestPermission()).pipe(
						map(requestResult => requestResult === NotificationPermissions.granted)
					);
				})
			);
		})
	);

	send<T = any>(options: INotificationOptions<T>): Observable<IDesktopNotificationSendResult<T>> {
		return this.requestPermission$.pipe(
			take(1),
			map(granted => {
				if (!granted) {
					return;
				}

				const defaultIcon = 'assets/icons/1f_logo.png'; //'assets/icons/brand/color/icon-72x72.png';
				const note = new Notification(options.title, {
					...options,
					icon: options.icon ? options.icon : defaultIcon,
				});

				note.onclick = function (e) {
					setTimeout(() => {
						if (typeof window !== 'undefined') {
							if (options.focusTabOnClick) {
								window.focus();
							}
						}
						if (options.closeOnClick) {
							this.close();
						}
					});

					if (options.onclick) {
						options.onclick(note, e);
					}
				};

				note.onclose = function () {
					if (note['closed']) {
						return;
					}
					if (options.onclose) {
						options.onclose(note);
					}
					note['closed'] = true;
					note.close();
				};

				return {
					notification: note,
					close: () => note.close(),
					get closed() {
						return note['closed'];
					},
					get data() {
						return options.data;
					},
				};
			})
		);
	}
}
