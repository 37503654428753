import { Injectable } from '@angular/core';
import { ApplicationService } from '@spa/application';
import { ResourceService } from '@spa/localization';
import { Subject, take } from 'rxjs';
import { ExtParamBase } from './ext-param/ext-param-base';

@Injectable({ providedIn: 'root' })
export class ExtParamValidationService {
	constructor(protected readonly app: ApplicationService, readonly resource: ResourceService) {}

	readonly openGroupById$ = new Subject<any>();

	setValidationMessageByEps(eps, message) {
		eps.forEach(ep => {
			ep?.setValidationMessage(message);
		});
	}

	setValidationMessageAndScrollToBlock(
		validationErrors: IValidationErrors[],
		taskId: number,
		eps: ExtParamBase[] = null,
		toFirstElemInContainer = null
	) {
		this.scrollToBlock(validationErrors, toFirstElemInContainer);
		this.setExtParamsValidationMessage(validationErrors, taskId, eps);
	}

	setExtParamsValidationMessage(validationErrors: IValidationErrors[], taskId: number, eps: ExtParamBase[] = null) {
		const defaultMessage = 'Поле заполнено не корректно';
		if (eps?.length) {
			validationErrors.forEach(epItem => {
				const currEp = eps?.find(ep => ep?.id === epItem?.extParamId);
				currEp?.setValidationMessage(epItem?.message || defaultMessage);
			});

			return;
		}

		validationErrors.forEach(epItem => {
			this.app.task
				.get(taskId, false)
				.extParams.get(epItem?.extParamId)
				.setValidationMessage(epItem?.message || defaultMessage);
		});
	}

	openGroupById(groupId: number) {
		if (!groupId) {
			return;
		}
		this.openGroupById$.next(groupId);
	}

	scrollToBlock(validationErrors: IValidationErrors[], toFirstElemInContainer = null) {
		let minOffset = Infinity;
		let targetEl = null;
		const groupsIds = new Set();
		const scrollContainerClass = '.vh-task-card__scroll-container';
		const scrollContainerEl = document.querySelector(scrollContainerClass);

		validationErrors.forEach(epItem => {
			const currEl = document.querySelector(`[vh-ext-param-id="${epItem?.extParamId}"]`);
			const currBlock = currEl?.closest('.vh-task-card-params-group');
			const groupId = currBlock?.getAttribute('group-id');

			if (!groupsIds.has(groupId)) {
				groupsIds.add(groupId);
				this.openGroupById(Number(groupId));
			}

			const currOffsetTop = (currEl as HTMLElement)?.offsetTop;
			if (currOffsetTop < minOffset) {
				targetEl = currEl;
			}
			const min = Math.min(minOffset, currOffsetTop);
			minOffset = min || minOffset;
		});

		if (toFirstElemInContainer) {
			const el = document.querySelector(toFirstElemInContainer);
			el.scrollTo({
				top: minOffset - 100,
				behavior: 'smooth',
			});
			return;
		}
		setTimeout(() => {
			scrollContainerEl.scrollTo({
				top: minOffset - 150,
				behavior: 'smooth',
			});
		}, 200);
	}

	clearAllExtParamsValidationMessage(taskId: number) {
		this.app.task.get(taskId, false).extParamsSet.forEach(epId => {
			this.app.task.get(taskId, false).extParams.get(epId).clearValidationMessage();
		});
	}
}

export interface IValidationErrors {
	extParamId: number;
	extParamName?: string;
	message?: string;
}
