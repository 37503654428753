import { ModuleWithProviders, NgModule } from '@angular/core';

import { ILocalizationOptions, LOCALIZATION_OPTIONS, defaultLocalizationOptions } from './localization-options';
import { ResourceAsyncPipe } from './resource-async.pipe';
import { ResourceDirective } from './resource.directive';
import { ResourcePipe } from './resource.pipe';

@NgModule({
	imports: [],
	exports: [ResourcePipe, ResourceDirective, ResourceAsyncPipe],
	declarations: [ResourcePipe, ResourceDirective, ResourceAsyncPipe],
})
export class LocalizationModule {
	static forRoot(options?: Partial<ILocalizationOptions>): ModuleWithProviders<LocalizationModule> {
		return {
			ngModule: LocalizationModule,
			providers: [
				{
					provide: LOCALIZATION_OPTIONS,
					useValue: Object.assign(defaultLocalizationOptions, options || {}),
				},
			],
		};
	}
}
