import { Inject, Injectable } from '@angular/core';
import { ASSET_INVALIDATE_KEY, UrlProvider } from '@spa/core';
import { dynamicImport } from '@valhalla/utils';
import { delay, from, map, mergeMap, Observable, of, shareReplay, take } from 'rxjs';
import { ImageOptimizer } from './image-optimizer';

@Injectable({ providedIn: 'root' })
export class SquooshImageOptimizer implements ImageOptimizer {
	constructor(
		@Inject(ASSET_INVALIDATE_KEY) protected readonly assetInvalidateKey: string,
		protected readonly urlBuilder: UrlProvider
	) {}

	protected optimizerModulePath = this.urlBuilder.getUrlRelativeToAssets(
		'scripts/image-optimizer/image-optimizer-squoosh.min.js'
	);
	protected readonly optimizer$ = from(dynamicImport(this.optimizerModulePath).then(m => m.default)).pipe(
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	optimize(image: File, options?: Record<any, any>): Observable<File> {
		return this.optimizer$.pipe(
			map(Compress => new Compress(image)),
			mergeMap(compress => from<Promise<File>>(compress.process())),
			take(1)
		);
	}

	optimizeInBackground(originalImage: File): { result$: Observable<File> } {
		const timeout$ = of(new Error('Timeout compression')).pipe(delay(20 * 1000));
		const result$ = new Observable<File>(observer => {
			timeout$.subscribe(timeoutError => {
				observer.error(timeoutError);
			});
			originalImage.arrayBuffer().then(originalImageBuffer => {
				const worker = new Worker(new URL('./squoosh-image-optimizer.worker', import.meta.url));
				worker.onmessage = () => {
					observer.next(originalImage);
					observer.complete();
				};
				const message = {
					squooshLibUrl: this.optimizerModulePath,
					originalImageBuffer: originalImageBuffer,
					originalImageType: originalImage.type,
				};
				worker.postMessage(message, [originalImageBuffer]);
			});
		});

		return {
			result$,
		};
	}
}
