import { Optional, Provider } from '@angular/core';
import { StoreManager } from '../../store';
import { IS_PRODUCTION } from '../abstract';
import * as tokens from './ng-tokens';
import { getOrCreateConfigurationStore } from './store-factory';

export function coreConfigurationStoreFactory(manager: StoreManager, isProd: boolean) {
	return getOrCreateConfigurationStore(manager, null, !isProd);
}

export const CORE_STORE_CONFIGURATION_PROVIDERS: Provider[] = [
	{
		provide: tokens.CORE_CONFIGURATION_STORE,
		useFactory: coreConfigurationStoreFactory,
		deps: [StoreManager, [new Optional(), IS_PRODUCTION]],
	},
];
