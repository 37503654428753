import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, Provider } from '@angular/core';
import { ConfigurationProvider, LoggerFactory, StoreManager } from '@valhalla/core';
import { SignalrProvider } from '@valhalla/data/signalr';

import { STORE_TICKERS } from './ng-tokens';
import { TickerProvider, ngProviderTickers, ngProviderTickersFacade } from './providers';
import { TickerEffectDependencies } from './store/effects';
import { getOrCreateTickersStore } from './store/store-factory';
import { UpdateTickersByRequestsInterceptor } from './update-tickers-by-requests-interceptor';

export function facadeStoreFactory(
	manager: StoreManager,
	deps: TickerEffectDependencies,
	config: ConfigurationProvider
) {
	return getOrCreateTickersStore(manager, deps, !config.isProd);
}

export function effectDependencyFactory(
	httpTickerProvider: TickerProvider,
	loggerFactory: LoggerFactory,
	injector: Injector,
	signal: SignalrProvider
) {
	const effectDependency: TickerEffectDependencies = {
		tickerProvider: httpTickerProvider,
		loggerFactory,
		injector,
		signal,
	};
	return effectDependency;
}

export const TICKERS_PROVIDERS: Provider[] = [
	ngProviderTickers,
	{
		provide: TickerEffectDependencies,
		useFactory: effectDependencyFactory,
		deps: [TickerProvider, LoggerFactory, Injector, SignalrProvider],
	},
	{
		provide: STORE_TICKERS,
		useFactory: facadeStoreFactory,
		deps: [StoreManager, TickerEffectDependencies, ConfigurationProvider],
	},
	ngProviderTickersFacade,
];
