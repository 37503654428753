import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataHttpCommonService } from '../data-http-common.service';
import { JobsDataHttpService, IJobDto, IJobServerState, JobSettingsDto } from './abstract';
import { Observable, map } from 'rxjs';
import { IApiResponse } from '../api-response';

@Injectable()
export class JobsDataHttpServiceImpl implements JobsDataHttpService {
	constructor(readonly http: HttpClient, readonly common: DataHttpCommonService) {}
	continueAll(): Observable<void> {
		const url = this.common.getApiUrl(`admin/jobs/continue-all`);
		return this.http.post<void>(url, {});
	}

	runJob(jobKey: string): Observable<void> {
		const url = this.common.getApiUrl(`admin/jobs/run`);
		return this.http.post<void>(url, { jobKey });
	}

	unlock(jobKey: string): Observable<void> {
		const url = this.common.getApiUrl(`admin/jobs/unlock`);
		return this.http.post<void>(url, { jobKey });
	}

	getJobsList(): Observable<IApiResponse<IJobDto[]>> {
		const url = `admin/jobs`;
		return this.http.get<IApiResponse<IJobDto[]>>(this.common.getApiUrl(url));
	}

	getServerStates(): Observable<IApiResponse<IJobServerState[]>> {
		const url = `admin/jobs/server-states`;
		return this.http.get<IApiResponse<IJobServerState[]>>(this.common.getApiUrl(url));
	}

	getJobSettings(jobKey: string): Observable<JobSettingsDto> {
		const url = `admin/jobs/settings/${jobKey} `;
		return this.http.get<IApiResponse<JobSettingsDto>>(this.common.getApiUrl(url)).pipe(map(res => res?.data));
	}

	saveJobSettings(jobKey: string, jobSettings: JobSettingsDto): Observable<JobSettingsDto> {
		const url = `admin/jobs/settings/${jobKey} `;
		return this.http
			.post<IApiResponse<JobSettingsDto>>(this.common.getApiUrl(url), jobSettings)
			.pipe(map(res => res?.data));
	}
}
