import { Component, OnInit } from '@angular/core';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { ViewDestroyStreamService } from '@spa/core';
import { combineLatest, map } from 'rxjs';
import { UserSettingsFacadeProvider } from '@spa/facade/features/user-settings';
import { isVisibleElement } from '@spa/data/http';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'vh-layout-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	constructor(
		readonly mobileView: MobileViewService,
		readonly destroy$: ViewDestroyStreamService,
		readonly userSettingsStore: UserSettingsFacadeProvider
	) {
		combineLatest([this.feedVisible$, this.chatsVisible$, this.staffVisible$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([feedVisible, chatsVisible, staffVisible]) => {
				this.feedVisible = feedVisible;
				this.chatsVisible = chatsVisible;
				this.staffVisible = staffVisible;
			});
	}
	feedVisible: boolean;
	chatsVisible: boolean;
	staffVisible: boolean;
	customItems = [];

	readonly feedVisible$ = this.userSettingsStore.selectUserSettings$.pipe(map(s => isVisibleElement(s.lentaVisible)));
	readonly chatsVisible$ = this.userSettingsStore.selectUserSettings$.pipe(map(s => isVisibleElement(s.chatsVisible)));
	readonly staffVisible$ = this.userSettingsStore.selectUserSettings$.pipe(map(s => isVisibleElement(s.staffVisible)));
	readonly mobileMode$ = this.mobileView.mobileMode$;
	readonly hideTabbar$ = combineLatest([this.mobileMode$, this.mobileView.hideTabbar$]).pipe(
		map(([mobileMode, hideTabbar]) => {
			return hideTabbar;
		})
	);

	ngOnInit() {
		this.customItems = [
			{
				id: 'Dashboard',
				visibility: true,
				url: '/',
				svgIcon: 'vh-home-24',
				svgIconFilled: 'vh-home-filled-24',
			},
			{
				id: 'Feed',
				visibility: this.feedVisible,
				url: '/feeds/default',
				svgIcon: 'vh-feed-24',
				svgIconFilled: 'vh-feed-filled-24',
			},
			{
				id: 'TasksFeed',
				visibility: this.feedVisible,
				url: '/feeds/tasks',
				svgIcon: 'vh-tasks-24',
				svgIconFilled: 'vh-tasks-filled-24',
			},
			{
				id: 'Chats',
				visibility: this.chatsVisible,
				url: '/chat',
				svgIcon: 'vh-chats-24',
				svgIconFilled: 'vh-chats-filled-24',
			},

			{
				id: 'Contacts',
				visibility: this.staffVisible,
				url: '/contacts-search',
				svgIcon: 'vh-contacts-24',
				svgIconFilled: 'vh-contacts-filled-24',
			},
		];
	}
}
