import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
	ViewEncapsulation,
	inject,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChatNavAvatarComponent } from '@spa/common/components/chat-nav-avatar';
import { OpenPagesService } from '@spa/common/services/open-pages.service';
import { SidebarService } from '@spa/common/services/sidebar.service';
import { SplashScreenService } from '@spa/common/services/splash-screen.service';
import { SyndicateFrameService } from '@spa/common/services/syndicate-frame.service';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { FacadeSearchProvider } from '@spa/facade/features';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { SystemTicker } from '@spa/facade/features/tickers/ticker-type.enum';
import { UserSettingsFacadeProvider } from '@spa/facade/features/user-settings';
import { LayoutNavBarPosition } from '@spa/facade/layout/state';
import {
	AuthService,
	EventBusService,
	PlatformDetectorProvider,
	SessionUser,
	UrlProvider,
	ViewDestroyStreamService,
} from '@valhalla/core';
import { ILanguageDescription, isUserOnline } from '@valhalla/data/entities';
import {
	ConfigurationDataHttpService,
	DataHttpService,
	IAppSettingsAnonymousDto,
	isVisibleElement,
	UsersDataHttpService,
} from '@valhalla/data/http';
import { CultureService, ResourceService } from '@valhalla/localization';
import {
	booleanFilter,
	BooleanFilter,
	copyToClipboard,
	isCurrentYear,
	isHashInTaskId,
	parseMessageFromError,
	rxHandler,
	rxSetInterval,
} from '@valhalla/utils';
import { BehaviorSubject, combineLatest, EMPTY, from, fromEvent, Observable, of } from 'rxjs';
import {
	catchError,
	debounceTime,
	delay,
	distinctUntilChanged,
	exhaustMap,
	filter,
	map,
	shareReplay,
	skip,
	startWith,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';
import { TickersFacadeProvider } from '../../../features/tickers';
import { SearchOverlayService } from '../../components/search-overlay';
import { LayoutFacade } from '@spa/facade/layout';
import { CallCounterService } from '@spa/common/components/call-history/call-counter.service';
import { UnreadMessagesService } from '@spa/facade/features/calendars/unread-messages.service';
import { ThemeService } from '@spa/common/services/theme-service';
import { ApplicationService } from '@spa/application';
import { CountersService } from '@spa/common/services/counters.service';
import { TreeNodeDataMapperService } from '@spa/common/components/select-tree-dialog/tree-node-data-mapper.service';
import { SelectTreeDialogPanelClasses } from '@spa/common/components/select-tree-dialog/abscract';
import { IBadgeColor } from 'libs/core-components/src/lib/badge/badge.model';
import { navigateByPointerEvent, openInNewTab } from '@vh/core-components';
import { NavigationMenu } from '@spa/facade/features/navigation';

// use dynamic
const NtfChatDialogComponent$ = from(
	import('@spa/components/new-task/ui/new-task-card/new-task-card-chat-modal/new-task-card-chat-modal.component').then(
		m => m.NtfDialogComponent
	)
);
const ChatAddDialogComponent$ = from(
	import('@spa/facade/layout/components/chat-add/chat-add.component').then(m => m.ChatAddHeaderDialogComponent)
);

const SelectTreeDialogComponent$ = from(
	import('@spa/common/components/select-tree-dialog/select-tree-dialog.component').then(
		m => m.SelectTreeDialogComponent
	)
);

const CallHistoryDialogComponent$ = from(
	import('@spa/common/components/call-history/call-history-dialog/call-history-dialog.component').then(
		m => m.CallHistoryDialogComponent
	)
);

export const selector = 'vh-layout-vertical-sidebar-mini';

@Component({
	selector: selector,
	templateUrl: 'sidebar-mini.component.html',
	styleUrls: ['sidebar-mini.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class VerticalSidebarMiniLayoutComponent implements OnInit, AfterViewInit {
	constructor(
		readonly sessionUser: SessionUser,
		readonly auth: AuthService,
		readonly urlBuilder: UrlProvider,
		readonly splashScreen: SplashScreenService,
		readonly searchOverlay: SearchOverlayService,
		readonly destroy$: ViewDestroyStreamService,
		readonly sideBar: SidebarService,
		readonly router: Router,
		readonly server: DataHttpService,
		readonly cdr: ChangeDetectorRef,
		readonly searchService: FacadeSearchProvider,
		readonly layoutService: LayoutFacade,
		readonly platform: PlatformDetectorProvider,
		readonly modal: ModalWindowsService,
		readonly activatedRoute: ActivatedRoute,
		readonly culture: CultureService,
		readonly syndicate: SyndicateFrameService,
		readonly resource: ResourceService,
		readonly userSettingsStore: UserSettingsFacadeProvider,
		readonly eventBus: EventBusService,
		readonly url: UrlProvider,
		readonly usersDataService: UsersDataHttpService,
		readonly configurationDataService: ConfigurationDataHttpService,
		readonly elRef: ElementRef<HTMLElement>,
		readonly dialog: MatDialog,
		readonly tickersFacade: TickersFacadeProvider,
		readonly mobileView: MobileViewService,
		readonly openPages: OpenPagesService,
		readonly callCounter: CallCounterService,
		readonly unreadMessagesService: UnreadMessagesService,
		readonly theme: ThemeService,
		readonly treeNodeDataMapperService: TreeNodeDataMapperService
	) {
		this.unreadMessagesCount$ = unreadMessagesService.unreadMessagesCount$;
		this.updateCommonSearchOverlayPosition();
	}

	@Input()
	showNavigationAlways: boolean;

	@HostBinding('class.vh-layout-vertical-sidebar-mini')
	hostSelectorClass = true;

	@Output()
	menuFoldClick = new EventEmitter();

	@Output()
	menuMouseOver = new EventEmitter();

	@Output()
	menuMouseLeave = new EventEmitter();

	@Output()
	pinFavorites = new EventEmitter();

	@ViewChild('langsMenuTrigger') langsMenuTrigger: MatMenuTrigger;

	@ViewChild('userMenuTrigger') userMenuTrigger: MatMenuTrigger;

	@ViewChild('otherMenuTrigger') otherMenuTrigger: MatMenuTrigger;

	@ViewChild('settingsMenuTrigger') settingsMenuTrigger: MatMenuTrigger;

	@ViewChild('languageMenuTrigger') languageMenuTrigger: MatMenuTrigger;

	@Input()
	showMenuIcon = true;

	@Input()
	alwaysAllTickers = true;

	@ViewChildren(CdkConnectedOverlay)
	cdkConnectedOverlays: QueryList<CdkConnectedOverlay>;

	@ViewChild('sessionUserAvatar')
	sessionUserAvatar: ChatNavAvatarComponent;

	@Input()
	@HostBinding('attr.layout')
	set layout(value: 'vertical' | 'horizontal') {
		this.layout$.next(value);
	}

	get layout() {
		return this.layout$.value;
	}

	@HostBinding('class.pb-8')
	get isVerticalLayout() {
		return this.layout === 'vertical';
	}

	get isHorizontalLayout() {
		return !this.isVerticalLayout;
	}

	get fluentBoxBufferBasis() {
		const btnCount = this.elRef.nativeElement.querySelectorAll('.nav-bar-fluent-panel > button').length;
		return -1 * (btnCount * 40 + 40 + 85);
	}

	get tooltipPosition() {
		if (this.layoutService.state?.appNavBarPosition === LayoutNavBarPosition.top) {
			return 'bottom';
		}
		if (this.layoutService.state?.appNavBarPosition === LayoutNavBarPosition.left) {
			return 'right';
		}
		if (this.layoutService.state?.appNavBarPosition === LayoutNavBarPosition.right) {
			return 'left';
		}
	}

	get isLayoutVerticaleRight() {
		return this.layoutService.state?.appNavBarPosition === LayoutNavBarPosition.right;
	}

	readonly countersService = inject(CountersService);
	readonly chatUnreadCount$ = this.countersService.chatCounters$.pipe(
		map(c => c.TotalChatCount)
		//map(v => (v < 99 ? v : '&#183;&#183;&#183;'))
	);
	readonly layout$ = new BehaviorSubject<'vertical' | 'horizontal'>('vertical');

	sessionUserId$ = this.sessionUser.userId$;
	sessionUserInfo$ = this.sessionUser.userInfo$;
	sessionUserHasAvatar$ = this.sessionUser.hasAvatar$;
	jitsiServicePersonalRoomVisible$ = this.usersDataService.sessionUserSettings$.pipe(
		map(userData => {
			const jitsiVisibility = userData.jitsiServicePersonalRoomVisible;
			if (jitsiVisibility === 'Invisible') {
				return false;
			} else {
				return true;
			}
		}),
		take(1),
		shareReplay({ bufferSize: 1, refCount: true })
	);

	readonly showUiSettings$ = this.usersDataService.sessionUserSettings$.pipe(
		map(settings => settings.userUISettingsConfig?.isVisible ?? true)
	);

	openSearchShortcut$ = fromEvent<KeyboardEvent>(window, 'keydown').pipe(
		// 83 is 'S' key
		filter(e => e.which === 83 && (e.ctrlKey || e.metaKey) && e.shiftKey),
		takeUntil(this.destroy$)
	);

	exceptTickers: SystemTicker[] = [
		// SystemTicker.questionsCount,
		SystemTicker.milestones,
		SystemTicker.missedCalls,
		// SystemTicker.unreadCommentsCount,
		'default-feed' as SystemTicker,
		SystemTicker.myQuestionsCount,
	];

	readonly appConfig$ = this.server.config.getAppConfiguration();

	readonly appLabelTextDefault$ = this.resource.resolveKey('common.1flogo');

	readonly privateSubcatId$ = this.appConfig$.pipe(map(config => config['privateSubcatId']));

	readonly isSearchPanelOpen$ = new BehaviorSubject(false);
	readonly isCoworkerPanelOpen$ = new BehaviorSubject(false);

	readonly refreshCommonHistory$ = new BehaviorSubject(0);

	readonly commonSearchHandler = rxHandler<string>();

	helperLink = 'http://help.1forma.ru/';

	readonly commonSearchInputHasValue$ = from<string>(this.commonSearchHandler as any).pipe(
		distinctUntilChanged(),
		map(Boolean)
	);

	readonly calendarSubcatId$ = this.appConfig$.pipe(map(config => config.calendarSubcatId));
	readonly groupChatSubcatId$ = this.appConfig$.pipe(map(config => config.groupChatSubCatId));

	readonly ownChatSubcatId$ = combineLatest([
		this.privateSubcatId$,
		this.calendarSubcatId$,
		this.groupChatSubcatId$,
		this.userSettingsStore.selectUserSettings$,
	]).pipe(
		filter(
			([privateSubcatId, calendarSubcatId, groupChatSubcatId, userSettings]) =>
				![privateSubcatId, calendarSubcatId, groupChatSubcatId].includes(userSettings.ownTaskSubcatId)
		),
		map(([privateSubcatId, calendarSubcatId, groupChatSubcatId, userSettings]) => {
			return userSettings.ownTaskSubcatId;
		})
	);

	readonly subcatForCalendar$ = this.ownChatSubcatId$.pipe(
		switchMap(subcatId =>
			this.server.category
				.storageSubCategory(subcatId)
				.pipe(map(subcat => subcat.subcategory.description || subcat.subcategory.entity))
		)
	);

	readonly searchClosedTaskToggle = new UntypedFormControl(false);
	readonly domParser = new DOMParser();

	readonly simpleSearchResult$ = combineLatest([
		this.searchClosedTaskToggle.valueChanges.pipe(startWith(this.searchClosedTaskToggle.value)),
		from<string>(this.commonSearchHandler as any).pipe(
			startWith(''),
			map(q =>
				isNaN(+q) &&
				q?.length >= Math.max(this.server.config.appSettingsAnonymousConfig.NumberCharactersForSearch || 0, 1)
					? q
					: null
			),
			map(q => {
				if (isHashInTaskId(q)) {
					return q?.slice(1);
				}

				return q;
			}),
			debounceTime(300),
			distinctUntilChanged()
		),
	]).pipe(
		switchMap(([searchClosedTask, query]) => {
			if (query) {
				return this.searchService.simpleSearch(query, searchClosedTask).pipe(
					map(r => {
						const items = r?.Tabs?.[0]?.Items;
						if (items) {
							return items.map(i => {
								const notesDom = this.domParser.parseFromString(i.Notes, 'text/html');
								const subcatParts = Array.from(notesDom.querySelectorAll('a')).map(i => i.textContent);
								return {
									...i,
									taskId: i.ItemId,
									name: i.Name,
									subcatParts: subcatParts?.length > 0 ? subcatParts : null,
								};
							});
						} else {
							return [];
						}
					})
				);
			}
			return of([]);
		})
	);

	readonly linksHistory$ = this.refreshCommonHistory$.pipe(
		exhaustMap(_ => this.server.history.getTasks({ count: 50 })),
		shareReplay({ refCount: false, bufferSize: 1 }),
		takeUntil(this.destroy$)
	);

	readonly tasksHistory$ = combineLatest([
		this.linksHistory$.pipe(map(links => links.filter(({ isTask }) => isTask))),
		this.simpleSearchResult$,
	]).pipe(
		map(([history, searched]) => {
			if (searched?.length > 0) {
				return searched;
			}
			return history;
		}),
		tap(() => {
			setTimeout(() => this.cdr.detectChanges());
		})
	);

	get isMobile() {
		return this.platform.isMobile();
	}

	readonly currentLanguage$ = this.culture.activeLanguage$;

	readonly currentLanguageName$ = this.currentLanguage$.pipe(map(({ name }) => name));

	readonly currentLanguageCode$ = this.currentLanguage$.pipe(map(({ code }) => code.toUpperCase()));

	readonly languages$ = this.server.config.getAppSettingsAnonymous().pipe(
		map(settings => {
			return settings.Languages.reduce((acc, lang) => {
				const objFirstKeyToLower = {};
				Object.keys(lang).map(key => {
					const keyToLower = this.firstLetterToLowerCase(key);
					return (objFirstKeyToLower[keyToLower] = lang[key]);
				});
				acc.push(objFirstKeyToLower);
				return acc;
			}, []);
		})
	);

	readonly isCallHistory$ = this.server.config.getAppSettingsAnonymous().pipe(
		map(s => {
			return s?.Services?.Conference?.Domain;
		})
	);

	readonly unreadMessagesCount$: Observable<number>;

	readonly sessionUserName$ = this.sessionUser.userName$;

	readonly showCalendarButton$ = this.layoutService.state$.pipe(map(s => s.toolbarButtons?.calendar));

	readonly isAdmin$ = this.auth.isAdmin$;

	readonly appSettingsAnonym$ = this.activatedRoute.parent.data.pipe(
		map(data => data.appSettingsAnonym as IAppSettingsAnonymousDto)
	);

	readonly userSettings$ = this.userSettingsStore.selectUserSettings$;

	readonly hardcodedAppLogo = 'assets/icons/forma-icons/Logo.svg';
	readonly hardcodedAppLogoDark = 'assets/icons/forma-icons/Logo_dark.svg';
	readonly hardcodedAppLogoWithLabel = 'assets/icons/forma-icons/1f_logo_1line_color.svg';
	readonly hardcodedAppLogoWithLabelDark = 'assets/icons/forma-icons/1f_logo_1line_color_dark.svg';
	readonly hardcodedAppLogoOld = 'img/1formalogo.r.png';
	readonly hardcodedAppLogo$ = of(this.hardcodedAppLogo);

	readonly appLabelText$ = combineLatest([
		this.appSettingsAnonym$.pipe(map(c => c.NavigationPanelLogo)),
		this.userSettings$.pipe(map(s => s.navigationPanelLogo)),
		this.appLabelTextDefault$,
	]).pipe(
		map(([configLabel, userSettingLabel]) => {
			if (userSettingLabel || userSettingLabel === '') {
				return userSettingLabel;
			} else if (configLabel) {
				return configLabel;
			} else {
				return '';
			}
		})
	);

	readonly isWideLogo$ = new BehaviorSubject<boolean>(false);

	readonly settingsAppLogo$ = combineLatest([
		this.layout$,
		this.appSettingsAnonym$,
		this.appLabelText$,
		this.theme.currentTheme$,
	]).pipe(
		map(([layout, settings, label, currentTheme]) => {
			let logoPath =
				layout === 'horizontal'
					? currentTheme === 'light'
						? settings.AppLogoPathSPAHorizontal
						: settings.AppLogoPathSPAHorizontalDark
					: currentTheme === 'light'
					? settings.AppLogoPathSPAVertical
					: settings.AppLogoPathSPAVerticalDark;

			if (!label) {
				logoPath = logoPath || this.getDefaultLogoPath(currentTheme, layout);
			} else {
				logoPath = logoPath || this.hardcodedAppLogo;
			}

			if (logoPath === this.hardcodedAppLogoOld && label) {
				logoPath = this.hardcodedAppLogo;
			}

			if (logoPath === this.hardcodedAppLogoOld && !label) {
				logoPath = this.getDefaultLogoPath(currentTheme, layout);
			}

			if (logoPath === this.hardcodedAppLogoWithLabel || logoPath === this.hardcodedAppLogoWithLabelDark) {
				this.isWideLogo$.next(true);
			}

			return logoPath;
		})
	);

	readonly changeLanguageInAppBar$ = this.appSettingsAnonym$.pipe(
		map(settings => {
			return settings.ChangeLanguageInAppBar;
		})
	);

	readonly isDefaultLogo$ = this.settingsAppLogo$.pipe(map(appLogo => appLogo === this.hardcodedAppLogo));

	readonly appLogoPath$ = this.settingsAppLogo$.pipe(
		map(logoPath => {
			if (
				logoPath === this.hardcodedAppLogo ||
				logoPath === this.hardcodedAppLogoWithLabel ||
				logoPath === this.hardcodedAppLogoDark ||
				logoPath === this.hardcodedAppLogoWithLabelDark
			) {
				return logoPath;
			}
			return this.urlBuilder.getUrl(logoPath);
		})
	);

	readonly appLogoPathBgImageUrlCss$ = this.appLogoPath$.pipe(map(path => `url(${path})`));

	readonly appLogoStylesSettings$ = combineLatest([this.layout$, this.appSettingsAnonym$]).pipe(
		map(([layout, settings]) => {
			return {
				width: this.toStyleValue(
					layout === 'horizontal' ? settings.AppLogoPathSPAHorizontalWidth : settings.AppLogoPathSPAVerticalWidth
				),
				height: this.toStyleValue(
					layout === 'horizontal' ? settings.AppLogoPathSPAHorizontalHeight : settings.AppLogoPathSPAVerticalHeight
				),
			};
		})
	);

	readonly appLogoStylesWidth$ = this.appLogoStylesSettings$.pipe(map(s => s.width));
	readonly appLogoStylesHeight$ = this.appLogoStylesSettings$.pipe(map(s => s.height));

	isHorizontalPosition = false;

	readonly sessionUserProfileLink$ = this.sessionUserId$.pipe(map(id => `/user/profile/${id}`));

	readonly calendarVisible$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.calendarVisible))
	);
	readonly lentaVisible$ = this.userSettingsStore.selectUserSettings$.pipe(map(s => isVisibleElement(s.lentaVisible)));
	readonly chatsVisible$ = this.userSettingsStore.selectUserSettings$.pipe(map(s => isVisibleElement(s.chatsVisible)));
	readonly staffVisible$ = this.userSettingsStore.selectUserSettings$.pipe(map(s => isVisibleElement(s.staffVisible)));

	readonly orgChartVisible$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.orgChartVisible))
	);

	readonly createButtonVisible$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.createButtonVisible))
	);
	readonly ownTaskSubcatId$ = this.userSettingsStore.selectUserSettings$.pipe(map(s => s.ownTaskSubcatId));
	readonly searchVisible$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.searchVisible))
	);

	readonly chooseLanguageVisible$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.chooseLanguageVisible))
	);
	readonly personalMenuTimeSheet$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.personalMenuTimeSheet))
	);
	readonly personalMenuKanban$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.personalMenuKanban))
	);
	readonly personalMenuOthers$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.personalMenuOthers))
	);

	readonly userPosition$ = this.sessionUser.userInfo$.pipe(map(userInfo => userInfo.positions[0]));

	readonly chatTicker$ = this.tickersFacade.selectTickers$.pipe(
		map(tickers => tickers['system/chatUnreadMessageCount'])
	);

	readonly unreadCommentsCountTickerValue$ = this.tickersFacade.selectTickers$.pipe(
		map(tickers => tickers['system/UnreadCommentsCount']?.value)
	);

	readonly matBadgeUnreadCommentsCountTicker$ = this.unreadCommentsCountTickerValue$.pipe(
		map(v => {
			return v; //< 99 ? v : '···';
		})
	);

	readonly missedCallCount$ = this.callCounter.missedCallCount$;
	readonly app = inject(ApplicationService);

	readonly badgeColor = IBadgeColor;
	readonly locale$ = this.culture.activeCulture$;

	readonly resizeHandler$ = fromEvent(window, 'resize').pipe(
		map(event => (event.target as Window).innerWidth),
		startWith(window.innerWidth),
		debounceTime(200)
	);

	readonly isRightPositionedOverlay$ = new BehaviorSubject<boolean>(window.innerWidth <= 600);

	readonly spaceSettings$ = this.appConfig$.pipe(map(config => config.spaceSettings));
	readonly spacesVisible$ = combineLatest([this.spaceSettings$, this.userSettingsStore.selectUserSettings$]).pipe(
		map(([spaceSettings, userSetting]) => spaceSettings?.isActive && isVisibleElement(userSetting.spacesVisible))
	);
	readonly mailVisible$ = this.userSettingsStore.selectUserSettings$.pipe(map(s => isVisibleElement(s.mailVisible)));
	readonly services$ = this.arrayServices();
	readonly hasServices$ = this.services$.pipe(map(s => s.length > 0));

	arrayServices() {
		// return of([
		// 	{
		// 		name: 'common.calendar',
		// 		matIcon: 'vh-calendar-24',
		// 		isActive: () => this.isUrl('/calendar'),
		// 		open: (e: PointerEvent) => {
		// 			this.openCalendar(e);
		// 		},
		// 		badgeCount$: this.unreadMessagesCount$,
		// 	},
		// ]);
		return combineLatest({
			calendar: this.calendarVisible$,
			mail: this.mailVisible$,
			orgChart: this.staffVisible$,
			employees: this.staffVisible$,
			spaces: this.spacesVisible$,
			chats: this.chatsVisible$,
		}).pipe(
			map(visible => {
				const items = [];
				if (visible.calendar) {
					items.push({
						name: 'common.calendar',
						matIcon: 'vh-calendar-24',
						isActive: () => this.isUrl('/calendar'),
						open: (e: PointerEvent) => {
							this.openCalendar(e);
						},
						badgeCount$: this.unreadMessagesCount$,
					});
				}
				if (visible.mail) {
					items.push({
						name: 'common.sideBarBtnMail',
						matIcon: 'vh-mail-24',
						isActive: () => this.isUrl('/email-client'),
						open: async (e: PointerEvent) => {
							await navigateByPointerEvent({
								event: e,
								router: this.router,
								commands: ['/email-client'],
							});
							if (!this.isSidebarOpened()) {
								this.toggleMenu();
							}
							window.dispatchEvent(
								new CustomEvent('select-nav-tab', {
									detail: {
										tabId: NavigationMenu.email,
									},
								})
							);
						},
						badgeCount$: of(0),
					});
				}

				items.push({
					name: 'common.disk',
					vhIcon: 'cloud-storage-blank',
					isActive: () => this.isUrl('/disk'),
					open: (e: PointerEvent) => {
						navigateByPointerEvent({
							event: e,
							router: this.router,
							commands: ['/disk'],
						});
					},
					badgeCount$: of(0),
				});

				if (visible.employees) {
					items.push({
						name: 'common.coworkerDictionary',
						vhIcon: 'contacts',
						isActive: () => this.isUrl('/org/list/workers'),
						open: (e: PointerEvent) => {
							navigateByPointerEvent({
								event: e,
								router: this.router,
								commands: ['/org/list/workers'],
							});
						},
						badgeCount$: of(0),
					});
				}

				if (visible.orgChart) {
					items.push({
						name: 'common.organizationalStructure',
						vhIcon: 'usergroup',
						isActive: () => this.isUrl('/org/chart'),
						open: (e: PointerEvent) => {
							navigateByPointerEvent({
								event: e,
								router: this.router,
								commands: ['/org/chart2'],
							});
						},
						badgeCount$: of(0),
					});
				}
				if (visible.spaces) {
					items.push({
						name: 'common.spaces',
						matIcon: 'vh-finder-browser',
						isActive: () => this.isUrl('/spaces'),
						open: (e: PointerEvent) => {
							navigateByPointerEvent({
								event: e,
								router: this.router,
								commands: ['/spaces'],
							});
							// if (!this.isSidebarOpened()) {
							// 	this.toggleMenu();
							// }
							// window.dispatchEvent(
							// 	new CustomEvent('select-nav-tab', {
							// 		detail: {
							// 			tabId: NavigationMenu.spaces,
							// 		},
							// 	})
							// );
						},
						badgeCount$: of(0),
					});
				}

				if (visible.chats) {
					items.push({
						name: 'common.chats',
						matIcon: 'vh-chats-24',
						isActive: () => this.isUrl('/chat'),
						open: (e: PointerEvent) => {
							navigateByPointerEvent({
								event: e,
								router: this.router,
								commands: ['/chat'],
								extras: {
									queryParams: {
										mode: 'chat',
									},
								},
							});
						},
						badgeCount$: of(0),
					});
				}

				return items;
			}),
			shareReplay(1)
		);
	}

	toStyleValue(value: number | string, defaultUnit = 'px') {
		if (!value) {
			return null;
		}
		if (typeof value === 'number') {
			return `${value}${defaultUnit}`;
		}
		return value;
	}

	ngOnInit() {
		this.openSearchShortcut$.pipe(takeUntil(this.destroy$)).subscribe(_ => {
			this.openSearch();
		});

		this.jitsiServicePersonalRoomVisible$.pipe(take(1)).subscribe(visible => {
			if (visible) {
				this.callCounter.init();
			}
		});

		this.languages$.pipe(take(1)).subscribe(() => {});
		this.currentLanguage$.pipe(take(1)).subscribe(() => {});

		this.router.events
			.pipe(
				filter(e => e instanceof NavigationEnd),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.cdr.markForCheck();
			});

		this.isSearchPanelOpen$.pipe(booleanFilter(), skip(1), takeUntil(this.destroy$)).subscribe(() => {
			this.refreshCommonHistory$.next(0);
		});

		combineLatest([this.isCoworkerPanelOpen$, this.isSearchPanelOpen$])
			.pipe(
				switchMap(([isOpenCoworkerSearch, isOpenSearch]) => {
					if (isOpenCoworkerSearch || isOpenSearch) {
						return fromEvent(window.document, 'click').pipe(skip(1));
					}
					return EMPTY;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(e => {
				const target = e.target as HTMLElement;
				const searchPanelEl = document.getElementById('common-search-panel');
				const coworkersPanelEl = document.getElementById('coworkers-search-panel');
				const isFromSearchPanel = searchPanelEl ? searchPanelEl.contains(target) : false;
				const isFromCoworkersSearchPanel = coworkersPanelEl ? coworkersPanelEl.contains(target) : false;
				if (!isFromSearchPanel) {
					this.isSearchPanelOpen$.next(false);
				}
				if (!isFromCoworkersSearchPanel) {
					this.isCoworkerPanelOpen$.next(false);
				}
			});

		this.isCoworkerPanelOpen$.pipe(filter(BooleanFilter), delay(100), takeUntil(this.destroy$)).subscribe(_ => {
			const input = document.querySelector<HTMLElement>('#coworkers-search-panel input');
			input && input.focus();
			this.cdr.detectChanges();
		});
		this.isSearchPanelOpen$.pipe(filter(BooleanFilter), delay(100), takeUntil(this.destroy$)).subscribe(_ => {
			const input = document.querySelector<HTMLElement>('#common-search-panel input');
			input && input.focus();
			this.cdr.detectChanges();
		});
		this.appSettingsAnonym$
			.pipe(
				filter(appSettings => !!appSettings.CustomSettings.HelperCustomLink),
				map(appSettings => appSettings.CustomSettings.HelperCustomLink as string),
				tap(customLink => (this.helperLink = customLink)),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	ngAfterViewInit() {
		this.layoutService.state$
			.pipe(
				map(({ appNavBarPosition }) => {
					this.isHorizontalPosition = [LayoutNavBarPosition.top, 'top'].includes(
						this.firstLetterToLowerCase(appNavBarPosition)
					);
					return appNavBarPosition;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(navBarPos => {
				if (this.isVerticalLayout) {
					this.cdkConnectedOverlays.forEach(overlay => {
						switch (navBarPos) {
							case 'left':
								overlay.offsetX = 40;
								overlay.offsetY = -54;
								break;
							case 'right':
								overlay.offsetX = 0;
								overlay.offsetY = 0;
								break;
							default:
								break;
						}
						if (this.isMobile) {
							overlay.offsetX = 40;
							overlay.offsetY = -54;
							overlay.panelClass = ['sidebar-overlay-mobile'];
						}
						if (overlay.overlayRef) {
							overlay.overlayRef.updatePosition();
						}
					});
				}
				this.cdr.detectChanges();
			});

		this.tasksHistory$.pipe(take(1)).subscribe({ error: console.error });

		fromEvent(window, 'vh-frame-load')
			.pipe(
				map((e: CustomEvent) => e.detail.contentWindow as Window),
				filter(win => Boolean(win) && win.location.href.includes('UserInfo.aspx')),
				switchMap(win =>
					rxSetInterval(500).pipe(
						map(() => {
							const el = win.document.querySelector<HTMLElement>('.toolBarImpersonateIcon');
							const searchParams = new URLSearchParams(win.location.search);
							const userId = searchParams.get('UserID');
							return { el, userId };
						})
					)
				),
				filter(({ el }) => Boolean(el)),
				distinctUntilChanged(),
				switchMap(({ el, userId }) =>
					fromEvent(el, 'click', { capture: true }).pipe(
						tap(e => {
							e.stopPropagation();
							e.preventDefault();
						}),
						map(_ => ({ userId }))
					)
				),
				switchMap(({ userId }) => this.impersonate(userId)),
				tap(_ => {
					location.reload();
				}),
				catchError(err => {
					this.modal.openError(err.message || err);
					return EMPTY;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	openCallHistory() {
		CallHistoryDialogComponent$.pipe(
			take(1),
			switchMap(component => {
				return this.modal
					.openDialog(component, {
						data: {
							userId: this.sessionUser.userId,
						},
					})
					.afterClosed();
			})
		).subscribe();
	}

	firstLetterToLowerCase(string: string): string {
		if (!string) {
			return null;
		}
		return string.charAt(0).toLowerCase() + string.slice(1);
	}

	impersonate(userId: number | string): Observable<any> {
		return this.auth.impersonate(userId);
	}

	setLanguage(lang: ILanguageDescription) {
		this.culture
			.setLanguage(lang.alias, true)
			.pipe(
				catchError(err => {
					this.modal.openError(String(err.message || err));
					return EMPTY;
				})
			)
			.subscribe();
	}

	addPersonalTask(e: MouseEvent) {
		const isOpenInNewTab = e?.metaKey || e?.ctrlKey;
		this.ownTaskSubcatId$.subscribe(subcatId => {
			this.privateSubcatId$.pipe(take(1)).subscribe(id => {
				const url = `/newtask/${subcatId || id}`;
				if (isOpenInNewTab) {
					window.open(url, '_blank');
				} else {
					this.router.navigate([url]);
				}
			});
		});
	}

	isCurrentYear(val: string) {
		return isCurrentYear(val);
	}

	isUserOnline(user) {
		return isUserOnline(user);
	}

	onKeyDownArrowInTaskSearch(e: Event) {
		e && e.preventDefault();
		const li = document.querySelector<HTMLElement>('.search-coworkers-panel__history .mat-list-item');
		li?.focus();
	}

	openCalendar(e?: MouseEvent) {
		const isOpenInNewTab = e?.metaKey || e?.ctrlKey;
		const urlPart = ['/calendar'];
		const navExtras = undefined;
		if (isOpenInNewTab) {
			let serializeUrl = this.router.serializeUrl(this.router.createUrlTree(urlPart, navExtras));
			serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
			window.open(serializeUrl, '_blank');
		} else {
			this.router.navigate(urlPart, navExtras);
		}
	}

	openWindow(url, otherDomain = false) {
		window.open(otherDomain ? url : this.urlBuilder.getUrl(url));
	}

	refreshApp() {
		this.splashScreen.show();
		window.location.reload();
	}

	openSearch() {
		this.searchOverlay.open();
	}

	openChats(e) {
		const mouseEvent = e as MouseEvent;
		const openInNewTab = mouseEvent?.ctrlKey || mouseEvent?.metaKey;
		const host = window.location.origin;
		const chatUnreadMessageCountUrl = this.urlBuilder.getUrl('/chat?mode=chat', true);

		openInNewTab
			? window.open(Location.joinWithSlash(host, chatUnreadMessageCountUrl))
			: this.router.navigate(['/chat'], { queryParams: { mode: 'chat' } });
	}

	toggleSidebarOpen(key: string) {
		const bar = this.sideBar.getSidebar(key);
		bar && bar.toggleOpen();
	}

	exitReincarnation() {
		this.auth.undoReincarnation().subscribe({
			next: _ => {
				window.location.reload();
			},
			error: err => {
				console.error(err);
			},
		});
	}

	isUrl(url: string) {
		return this.router.url.includes(url);
	}

	urlStartsWith(url: string) {
		return this.router.url.startsWith(url);
	}

	openFeed(e: MouseEvent) {
		const isOpenInNewTab = e?.metaKey || e?.ctrlKey;
		const urlPart = ['/feeds/default'];
		if (isOpenInNewTab) {
			let serializeUrl = this.router.serializeUrl(this.router.createUrlTree(urlPart));
			serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
			window.open(serializeUrl, '_blank');
		} else {
			this.router.navigate(urlPart);
		}
	}

	onMenuHover(trigger) {
		setTimeout(() => {
			trigger.openMenu();
		}, 100);
	}

	onMenuLeave(trigger) {
		if (this.langsMenuTrigger?.menuOpen || this.otherMenuTrigger?.menuOpen || this.settingsMenuTrigger?.menuOpen) {
			return;
		}
		setTimeout(() => {
			trigger.closeMenu();
		}, 300);
	}

	toggleOpenCoworkerPanel() {
		const isOpen = !this.isCoworkerPanelOpen$.value;
		this.isCoworkerPanelOpen$.next(isOpen);
		this.isSearchPanelOpen$.next(false);
	}

	toggleOpenSearchPanel(e?: MouseEvent) {
		const isOpen = !this.isSearchPanelOpen$.value;
		if (isOpen) {
			this.refreshCommonHistory$.next(0);
		}
		this.isCoworkerPanelOpen$.next(false);
		this.isSearchPanelOpen$.next(isOpen);
	}

	focusChanged(e) {
		if (!e) {
			this.isCoworkerPanelOpen$.next(false);
			this.isSearchPanelOpen$.next(false);
		}
	}

	selectItem(e: MatSelectionListChange) {
		const event = window.event as MouseEvent;
		const openInNewTab = event?.ctrlKey || event?.metaKey;
		const { type, value } = e.options?.[0]?.value || {};
		switch (type) {
			case 'task':
				this.openTask(value.key || value.taskId, openInNewTab);
				break;
			default:
				break;
		}
		if (!openInNewTab) {
			this.hidePanel();
		}
	}

	hidePanel() {
		this.isCoworkerPanelOpen$.next(false);
		this.isSearchPanelOpen$.next(false);
	}

	navLink(navParts: any[], navExtras: Record<any, any>, e?: Event) {
		const mouseEvent = e as MouseEvent;
		const openInNewTab = mouseEvent?.ctrlKey || mouseEvent?.metaKey;

		if (openInNewTab) {
			let serializeUrl = this.router.serializeUrl(this.router.createUrlTree(navParts, navExtras ?? undefined));
			serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
			return window.open(serializeUrl, '_blank');
		} else {
			return this.router.navigate(navParts, navExtras ?? undefined);
		}
	}

	openTask(taskId: number, newTab?: boolean) {
		this.app.task
			.checkExistAndAccess(taskId)
			.pipe(take(1))
			.subscribe(r => {
				const task = r.taskShortInfo;
				const isChat = this.server.config.isPrivateOrGroupChatSync(task);
				if (task.isSpace) {
					this.openPages.openSpace({
						spaceId: taskId,
						subcatId: task?.subcatId,
						newTab,
					});
				} else if (isChat) {
					this.openPages.openChat({
						taskId,
						newTab,
					});
				} else {
					this.openPages.openTask({
						taskId,
						newTab,
					});
				}
			});
	}

	searchQuery(query: string, e?: Event) {
		if (!query || !query.trim()) {
			return;
		}

		this.isSearchPanelOpen$.next(false);
		this.searchService
			.isTask(query)
			.pipe(take(1))
			.subscribe({
				next: isTask => {
					let queryChanged = query;

					if (isHashInTaskId(query)) {
						queryChanged = query.slice(1);
					}

					this.searchService.openPageDataSearchResult(queryChanged, isTask);
				},
				error: err => {
					this.modal.openError(parseMessageFromError(err));
				},
			});
	}

	log(o: any) {
		// tslint:disable-next-line:no-console
		console.log(o);
		return o;
	}

	openAdminPanel() {
		this.appSettingsAnonym$.pipe(take(1)).subscribe(settings => {
			let customAdminPath: string;
			if (settings?.CustomSettings?.AdminPath?.length) {
				customAdminPath = settings?.CustomSettings.AdminPath?.includes('https://')
					? settings.CustomSettings.AdminPath
					: `https://${settings.CustomSettings.AdminPath}`;
			}

			const urlForWindow = this.url.getUrl(
				this.router.serializeUrl(this.router.createUrlTree(['/administration'])),
				true
			);
			window.open(customAdminPath?.length ? customAdminPath : urlForWindow, '_blank');
		});
	}

	openJitsi(e?: MouseEvent) {
		this.usersDataService.sessionUserSettings$.pipe(take(1)).subscribe(user => {
			this.openPages.openVKS({
				roomName: user.jitsiServicePersonalRoom,
				newTab: true,
			});
		});
	}

	copyJitsi() {
		combineLatest([
			this.configurationDataService.appSettingsAnonymousConfig$,
			this.usersDataService.sessionUserSettings$,
		])
			.pipe(
				take(1),
				map(([settings, userData]) => {
					return `https://${settings?.Services?.Jitsi?.Domain}/${userData.jitsiServicePersonalRoom}`;
				})
			)
			.subscribe(jitsiUrl => {
				copyToClipboard(jitsiUrl);
			});
	}

	createNewGroupChat() {
		combineLatest([ChatAddDialogComponent$, this.groupChatSubcatId$])
			.pipe(take(1))
			.subscribe(([component, subcatId]) => {
				const data = {
					subcatId: subcatId,
				};
				const dialogRef = this.dialog.open(component, {
					data: data,
				});

				dialogRef.disableClose = true;

				let isDirty = false;
				dialogRef.componentInstance.isDirty$.pipe(takeUntil(dialogRef.afterClosed())).subscribe(data => {
					isDirty = data;
				});

				dialogRef.backdropClick().subscribe(_ => {
					if (isDirty) {
						this.closeDialog(dialogRef);
					} else {
						dialogRef.close();
					}
				});
			});
	}

	createMeeting() {
		combineLatest([NtfChatDialogComponent$, this.calendarSubcatId$])
			.pipe(take(1))
			.subscribe(([component, subcatId]) => {
				const data = {
					subcatId: subcatId,
					needRedirectAfterCreated: true,
					showOpenInNewWindowBtn: true,
				};
				const dialogRef = this.dialog.open(component, {
					data: data,
					hasBackdrop: true,
					width: '800px',
					maxWidth: '800px',
					panelClass: ['common-modal-side-spacing'],
				});

				dialogRef.disableClose = true;

				let isDirty = false;
				dialogRef.componentInstance.isDirty$.pipe(takeUntil(dialogRef.afterClosed())).subscribe(data => {
					isDirty = data;
				});

				dialogRef.backdropClick().subscribe(_ => {
					if (isDirty) {
						this.closeDialog(dialogRef);
					} else {
						dialogRef.close();
					}
				});
			});
	}

	createWithId(subcatId$: number | Observable<number>) {
		if (subcatId$ instanceof Observable) {
			combineLatest([NtfChatDialogComponent$, subcatId$])
				.pipe(take(1))
				.subscribe(([component, subcatId]) => {
					const data = {
						subcatId: subcatId,
						needRedirectAfterCreated: true,
						showOpenInNewWindowBtn: true,
						notConfirmClose: true,
					};
					const dialogRef = this.dialog.open(component, {
						data: data,
						hasBackdrop: true,
						width: '800px',
						maxWidth: '800px',
						panelClass: ['common-modal-side-spacing'],
					});

					dialogRef.disableClose = true;

					let isDirty = false;
					dialogRef.componentInstance.isDirty$.pipe(takeUntil(dialogRef.afterClosed())).subscribe(data => {
						isDirty = data;
					});

					dialogRef.backdropClick().subscribe(_ => {
						if (isDirty) {
							this.closeDialog(dialogRef);
						} else {
							dialogRef.close();
						}
					});
				});
		}
	}

	openSubcatPick() {
		combineLatest([
			NtfChatDialogComponent$,
			SelectTreeDialogComponent$,
			this.treeNodeDataMapperService.mapCategoriesTree(),
		])
			.pipe(take(1))
			.subscribe(([ntfComponent, selectTreeComponent, subcategoriesTreeData]) => {
				this.modal
					.openDialog(selectTreeComponent, {
						height: '90%',
						data: { initialTreeItems: subcategoriesTreeData, multiselect: false },
						panelClass: SelectTreeDialogPanelClasses,
					})
					.afterClosed()
					.pipe(
						take(1),
						filter(subcat => subcat && subcat.id),
						map(subcat => subcat.id)
					)
					.subscribe(subcatId => {
						const data = {
							subcatId: subcatId,
							showOpenInNewWindowBtn: true,
							notConfirmClose: true,
						};
						this.modal.openDialog(ntfComponent, {
							data: data,
							maxHeight: '90vh',
							height: '90vh',
							width: '800px',
							maxWidth: '800px',
							panelClass: ['common-modal-side-spacing'],
						});
					});
			});
	}

	closeDialog(dialogRef: MatDialogRef<any>) {
		this.modal
			.openConfirm('Вы уверены, что хотите закрыть окно ?', {}, 'common.confirmMessage')
			.afterClosed()
			.pipe(
				tap(answer => {
					if (answer) {
						dialogRef.close();
					}
				}),
				take(1)
			)
			.subscribe();
	}

	toggleMobile(): void {
		this.mobileView.toggleMobile();
		location.reload();
	}

	getDefaultLogoPath(currentTheme: 'light' | 'dark', layout: string): string {
		switch (currentTheme) {
			case 'light':
				if (layout === 'horizontal') {
					return this.hardcodedAppLogoWithLabel;
				} else {
					return this.hardcodedAppLogo;
				}
			case 'dark':
				if (layout === 'horizontal') {
					return this.hardcodedAppLogoWithLabelDark;
				} else {
					return this.hardcodedAppLogoDark;
				}
		}
	}

	toggleMenu(): void {
		if (this.isMobile) {
			this.toggleSidebarOpen('navbar');
		}
		this.pinFavorites.emit();
	}

	isSidebarOpened() {
		return document.body.getAttribute('is-pinned-sidebar') === 'true';
	}

	updateCommonSearchOverlayPosition(): void {
		this.resizeHandler$
			.pipe(
				filter(() => this.isSearchPanelOpen$.value),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.cdkConnectedOverlays.forEach(overlay => {
					if (overlay.panelClass?.includes('commonSearchOverlay')) {
						this.isRightPositionedOverlay$.next(window.innerWidth <= 600);
						overlay.overlayRef.updatePosition();
					}
				});
			});
	}
}
