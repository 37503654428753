import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UnsafeChangesGuard } from '../guards/unsave-changes.guard';

import { rootRoutes } from './root-routes';

const enableTracing = Boolean(localStorage.getItem('spa-router-enable-tracing'));

@NgModule({
	imports: [
		RouterModule.forRoot(rootRoutes, {
			onSameUrlNavigation: 'reload',
			paramsInheritanceStrategy: 'always',
			enableTracing: enableTracing,
		}),
	],
	exports: [RouterModule],
	providers: [UnsafeChangesGuard],
})
export class RoutingModule {}
