import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './icon.component';
import { SafeHtmlPipeModule } from '../pipes/safe-html';

@NgModule({
	imports: [CommonModule, SafeHtmlPipeModule],
	declarations: [IconComponent],
	exports: [IconComponent],
})
export class IconModule {}
