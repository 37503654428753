import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PortalHelperModule } from '@spa/common/components/portal-helper';
import { SearchBarCommonModule } from '@spa/common/components/search-bar';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';
import { LocalizationModule } from '@valhalla/localization';

import { TickersModule } from '../../../features/tickers';
import { QuickLinksSystemDockModule } from '../quick-links-system-dock';
import { QuickLinksUserDockModule } from '../quick-links-user-dock';
import { MobileDynamicPanelModule } from './mobile-dynamic-panel';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
	declarations: [ToolbarComponent],
	imports: [
		CommonModule,
		PortalModule,
		MatIconModule,
		MatMenuModule,
		MatToolbarModule,
		SearchBarCommonModule,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
		QuickLinksUserDockModule,
		QuickLinksSystemDockModule,
		MobileDynamicPanelModule,
		TickersModule,
		LocalizationModule,
		PortalHelperModule,
	],
	exports: [ToolbarComponent],
})
export class ToolbarModule {}
