export function getSelectionText() {
	const doc = document as any;
	if (window.getSelection) {
		try {
			const activeElement = document.activeElement as any;
			if (activeElement?.value) {
				// firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=85686
				return activeElement.value.substring(activeElement.selectionStart, activeElement.selectionEnd);
			} else {
				return window.getSelection().toString();
			}
		} catch (e) {}
	} else if (doc.selection?.type !== 'Control') {
		// For IE
		return doc.selection?.createRange?.().text;
	}
	return '';
}

export function hasActiveSelection() {
	return !!getSelectionText();
}
