import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IFrameViewerCommonComponent } from './iframe-viewer.component';

@NgModule({
	imports: [CommonModule],
	declarations: [IFrameViewerCommonComponent],
	exports: [IFrameViewerCommonComponent],
})
export class IFrameViewerCommonModule {}
