import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { VhSubscribeModule } from '@spa/common/directives/subscribe';

import { AppsSearchComponent } from './apps-search.component';
import { AppSearchGroupModule } from './group';

@NgModule({
	imports: [CommonModule, MatInputModule, MatIconModule, AppSearchGroupModule, VhSubscribeModule],
	exports: [AppsSearchComponent],
	declarations: [AppsSearchComponent],
	providers: [],
})
export class ApplicationsSearchModule {}
