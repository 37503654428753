import { Component, Input, Injector, HostBinding } from '@angular/core';
import type { IFileViewerFile } from '../file-viewer-file';
import { FileViewerService } from '../file-viewer.service';

/** Stub for preview of unsupported files */
@Component({
	selector: 'vh-file-view-not-supported',
	templateUrl: 'file-view-not-supported.component.html',
	styleUrls: ['file-view-not-supported.component.scss'],
})
export class FileViewNotSupportedComponent {
	constructor(readonly fileViewerService: FileViewerService) {}

	@Input() file: IFileViewerFile;

	get fileIconCss() {
		return this.fileViewerService.getFileIconCssClass(this.file);
	}
}
