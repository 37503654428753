import { Observable } from 'rxjs';
import { ITokenPayload } from './model';

export abstract class TokenService {
	abstract get payload(): Partial<ITokenPayload>;

	abstract getToken(): string;

	abstract setToken(token: string): void;

	abstract clearToken(): void;

	abstract isTokenExpired(token?: string): boolean;

	abstract decodeToken(token?: string): any;

	abstract getTokenExpirationDate(token?: string): Date;

	abstract urlBase64Decode(str: string): string;

	abstract isValid(token: string): boolean;

	updated$: Observable<any>;
}
