import { CommentType, IAttachment, IComment, INormalizable, IUser, IUserShort } from '@valhalla/data/entities';
import { Extendable } from '@valhalla/utils';
import { Observable } from 'rxjs';

export interface ISignalMessage<T = any> {
	name: string;
	data: T;
}

export abstract class SignalrProvider {
	abstract readonly events: typeof NotifyHubEvents;
	/**always on, without filtering by active tab */
	abstract readonly signal$: Observable<ISignalMessage>;
	/**only when tab active */
	abstract readonly messages$: Observable<ISignalMessage>;
	abstract readonly chatMessages$: Observable<IChatMessageNotifyData>;
	abstract readonly readComments$: Observable<ReadCommentsNotifyData>;
	abstract readonly newComments$: Observable<INewCommentNotifyData>;
	abstract readonly questionComment$: Observable<IQuestionCommentNotifyData>;
	abstract readonly updateFavorites$: Observable<any>;
	abstract readonly refreshMTF$: Observable<IRefreshMtfNotifyData>;
	abstract readonly deleteComment$: Observable<IDeleteCommentNotifyData>;
	abstract readonly editComment$: Observable<IEditCommentNotifyData>;
	abstract readonly editTaskText$: Observable<IEditTaskTextNotifyData>;
	abstract readonly userProfileChanged$: Observable<any>;
	abstract readonly newChatComment$: Observable<any>;
	abstract readonly impersonate$: Observable<any>;
	abstract readonly stopImpersonation$: Observable<any>;
	abstract readonly changeLanguage$: Observable<any>;
	abstract readonly addTaskToFavorites$: Observable<IAddTaskToFavorites>;
	abstract readonly changeFavorites$: Observable<any>;
	abstract readonly jitsiRoomJoin$: Observable<IJitsiSignal>;
	abstract readonly jitsiRoomUserJoined$: Observable<IJitsiSignal>;
	abstract readonly jitsiRoomInviteDeclined$: Observable<IJitsiSignal>;
	abstract readonly smartMessage$: Observable<ISmartMessage>;
	abstract readonly calendarEventsChanged$: Observable<ICalendarSignal>;
	abstract readonly commentReaction$: Observable<ICommentReactionNotifyData>;
	abstract readonly userIsOnline$: Observable<IUserIsOnlineNotifyData>;
	abstract readonly smartActionSnackbar$: Observable<Extendable<ISmartActionDialogNotifyData>>;
	abstract readonly smartActionDialogBox$: Observable<Extendable<ISmartActionDialogNotifyData>>;
	abstract readonly preuploadedFile$: Observable<Extendable<IPreuploadedFileNotifyData>>;

	abstract activate(): Observable<boolean>;
	abstract deactivate(): Observable<any>;
	abstract getState(): any;
}

export enum NotifyHubEvents {
	chatMessage = 'ChatMessage',
	editTaskText = 'EditTaskText',
	newComment = 'NewComment',
	readComments = 'ReadComments',
	questionComment = 'QuestionComment',
	updateFavorites = 'ChangeFavorites',
	refreshMTF = 'RefreshMTF',
	deleteComment = 'DeleteComment',
	editComment = 'EditComment',
	userProfileChanged = 'UserProfileChanged',
	newChatComment = 'NewChatComment',
	impersonate = 'Impersonate',
	stopImpersonation = 'StopImpersonation',
	changeLanguage = 'ChangeLanguage',
	likeComment = 'LikeComment',
	addTaskToFavorites = 'AddTaskToFavorites',
	changeFavorites = 'ChangeFavorites',
	jitsiRoomJoin = 'JitsiRoomJoin',
	jitsiRoomUserJoined = 'JitsiRoomUserJoined',
	jitsiRoomInviteDeclined = 'JitsiRoomInviteDeclined',
	conferenceRoomJoin = 'ConferenceRoomJoin',
	smartMessage = 'SmartMessage',
	calendar = 'Calendar',
	commentReaction = 'CommentReaction',
	userIsOnline = 'UserIsOnline',
	smartActionSnackbar = 'SmartActionSneckbar',
	smartActionDialogBox = 'SmartActionDialogBox',
	preuploadedFile = 'PreuploadedFile',
}

export interface ISmartActionDialogNotifyData {
	message: string;
	messageHeadline: string;
	notificationType: string;
	taskId: number;
	type: string;
	userIds: number[];
}

export interface IUserIsOnlineNotifyData {
	user: Partial<IUserShort>;
}

export interface ICalendarSignal {
	UserId: number;
	Events: ICalendarEventSignal[];
}

export interface ICalendarEventSignal {
	Type: ICalendarEventType;
	OldKey: string;
	Key: string;
}

export enum ICalendarEventType {
	created = 'Created',
	modified = 'Modified',
}
export interface IJitsiSignal {
	Message: string;
	Type: string;
}

export interface IAddTaskToFavorites {
	taskId: number;
	color: string;
	favsFolderId: number;
	userId: number;
	newUserLinkId: number;
}

export interface IEditCommentNotifyData {
	chatNotificationMode: string;
	guid: string;
	id: number;
	isChat: boolean;
	isPinnedToChat: boolean;
	taskId: number;
	text: string;
	unreadCount: number;
}

export interface IDeleteCommentNotifyData {
	taskId: number;
	commentIds: number[];
}

export interface IRefreshMtfNotifyData {
	taskId: number;
	inintiatorId?: number;
	smartEvent: any;
	commentId: number;
	commentType: number;
	eventTime: string;
	subcatId: number;
	initiatorUserId?: number;
	details: Partial<{
		[key: string]: any;
		extParamIds: number[];
	}>;
}

export interface IReadCommentNotifyData {
	commentIds: number[];
	unreadCount: number;
}

export type ReadCommentsNotifyData = Array<{
	taskid: number;
	comments: Array<Partial<IComment>>;
}>;

export interface IChatMessageNotifyData extends INormalizable {
	authorId: number;
	authorName: string;
	commentId: number;
	commentType: CommentType;
	isChatMuted: boolean;
	taskId: number;
	text: string;
	unreadChatCommentCount: number;
}

export interface IEditTaskTextNotifyData {
	taskText: string;
	guid: string;
	id: number;
	taskId: number;
	unreadCount: number;
	isChat: boolean;
	isPinnedToChat: boolean;
	chatNotificationMode: string;
}

export interface ISmartMessage {
	TaskId?: number;
	IsRadWindow: boolean;
	Type: string;
	Url?: string;
}

export interface INewCommentNotifyData extends Partial<IComment> {
	taskId: number;
	isChat?: boolean;
	chatNotificationMode?: string;
	isPinnedToChat?: boolean;
}
// tslint:disable-next-line: no-empty-interface
export interface IQuestionCommentNotifyData extends Partial<IComment> {}

export interface ICommentReactionNotifyData extends Partial<IComment> {
	reaction: {
		id: number;
		emoji: string;
		count: number;
		userReacted: Partial<IUser>;
		isSet: boolean;
	};
	actionUser: Partial<IUser>;
}

export interface IPreuploadedFileNotifyData {
	columnId: number;
	extParamId: number;
	file: IAttachment;
	rowId: number;
	taskId: number;
}
