/* eslint-disable no-restricted-syntax */
import { isObject, isString, jsonTryStringify } from '@valhalla/utils';
import { EventLogProvider } from '../eventlog';
import { StackTraceService } from '../stack-trace';
import { AbstractLogger, ILoggerOutputOptions, LogLevel } from './abstract';
import { defaultOptions } from './logger-options';
import type { ILoggerOptions } from './logger-options';

const optionsSymbol = Symbol();

export class Logger implements AbstractLogger {
	constructor(
		private _prefixLoggerName: string = '[Logger]',
		private _loggerOptions: () => ILoggerOptions,
		private _stack?: () => StackTraceService,
		private _eventLog?: () => EventLogProvider
	) {
		this._isReduxLog = this.isReduxLog(this._prefixLoggerName);
	}

	private _isInGroup = false;
	private _lastGroupData = [];
	private _isReduxLog = false;
	private _enabled = false;

	protected get options() {
		return (this._loggerOptions && this._loggerOptions()) || defaultOptions;
	}

	protected format(level: LogLevel, ...args) {
		let color = this.options.prefixColor;
		if (level === LogLevel.error) color = 'red';
		if (level === LogLevel.warn) color = 'hotpink';

		if (!this._isInGroup) {
			return [`%c${level}:${this._prefixLoggerName}`, `color:${color};`, ...args];
		}
		return [...args];
	}

	protected withStackInfo(write: (info?: { stack?: string; error?: string }) => void) {
		if (!this.options.withStack) {
			return write();
		}
		const getStack = this._stack ? this._stack().get(9) : Promise.resolve('');
		getStack
			.then(stackInfo => {
				write({ stack: stackInfo });
			})
			.catch(err => {
				write({ error: String(err) });
			});
	}

	get name() {
		return this._prefixLoggerName;
	}

	get enabled() {
		return this._enabled;
	}

	enable() {
		this._enabled = true;
	}

	disable() {
		this._enabled = false;
	}

	createOptions(options) {
		return {
			[optionsSymbol]: true,
			...(options || {}),
		};
	}

	isOptions(options) {
		return isObject(options) && Boolean(options[optionsSymbol]);
	}

	write(level: LogLevel, ...data) {
		switch (level) {
			case LogLevel.error:
				return this.error(...data);
				break;
			case LogLevel.info:
				return this.info(...data);
				break;
			case LogLevel.warn:
				return this.warn(...data);
				break;
			default:
				break;
		}
	}

	log(...args) {
		this.info(...args);
		return args;
	}

	protected isReduxLog(loggerName: string) {
		return (loggerName || '').indexOf('[store:') !== -1;
	}

	protected getLocation() {
		try {
			return decodeURIComponent(location.pathname + location.search);
		} catch (e) {
			return String(e);
		}
	}

	protected writeLogEntry(level: LogLevel, message: string, stack?: string, details?) {
		const eventLog = this._eventLog();
		if (eventLog) {
			eventLog
				.write({
					timestamp: Date.now(),
					url: this.getLocation(),
					level: level,
					name: this._prefixLoggerName,
					stack: stack,
					message: this._isReduxLog
						? 'Store state has changed'
						: isString(message)
						? message
						: jsonTryStringify(message, e => this.error(e)),
					details: this._isReduxLog ? null : jsonTryStringify(details, e => this.error(e)),
				})
				.subscribe();
		}
	}

	info(...args) {
		this.logInfo(false, ...args);
		return args;
	}

	trace(...args) {
		const loggerOptions = this._loggerOptions() || {};
		let outputLoggerOption: Partial<ILoggerOutputOptions> = {};
		const isLoggerOptions = this.isOptions(args[0]);
		if (isLoggerOptions) {
			outputLoggerOption = args[0];
			args = args.splice(1);
		}
		const formated = this.format(LogLevel.trace, ...args);
		if (this._enabled && console && console.trace) {
			console.trace(...formated);
		}
		if (this._isInGroup && outputLoggerOption.useEventLog) {
			this._lastGroupData.push(...args);
		} else {
			if (!isLoggerOptions || outputLoggerOption.useEventLog) {
				this.writeLogEntry(LogLevel.trace, (args || []).map(String).join('\n'));
			}
		}
	}

	warn(...args) {
		let options: Partial<ILoggerOutputOptions> = {};
		const isLoggerOptions = this.isOptions(args[0]);
		if (isLoggerOptions) {
			options = args[0];
			args = args.splice(1);
		}
		const formated = this.format(LogLevel.warn, ...args);
		this.withStackInfo(info => {
			if (this._enabled && console && console.warn) {
				const out = info ? [...formated, { stackInfo: info }] : formated;
				console.warn(...out);
			}
			if (this._isInGroup && options.useEventLog) {
				this._lastGroupData.push(...args);
			} else {
				if (!isLoggerOptions || options.useEventLog) {
					this.writeLogEntry(LogLevel.warn, String((args || [])[0]), info && info.stack ? info.stack : null, args);
				}
			}
		});
	}
	error(...args) {
		let options: Partial<ILoggerOutputOptions> = {};
		const isLoggerOptions = this.isOptions(args[0]);
		if (isLoggerOptions) {
			options = args[0];
			args = args.splice(1);
		}
		const formated = this.format(LogLevel.error, ...args);
		this.withStackInfo(info => {
			if (this._enabled && console && console.error) {
				const out = info ? [...formated, { stackInfo: info }] : formated;
				console.error(...out);
			}
			if (this._isInGroup && options.useEventLog) {
				this._lastGroupData.push(...args);
			} else {
				if (!isLoggerOptions || options.useEventLog) {
					this.writeLogEntry(LogLevel.error, String((args || [])[0]), info && info.stack ? info.stack : null, args);
				}
			}
		});
	}

	/**
	 * log to console even if production
	 */
	forceLogInfo(...args) {
		this.logInfo(true, ...args);
		return args;
	}

	protected logInfo(force: boolean, ...args) {
		let options: Partial<ILoggerOutputOptions> = {};
		const isLoggerOptions = this.isOptions(args[0]);
		if (isLoggerOptions) {
			options = args[0];
			args = args.splice(1);
		}
		const formated = this.format(LogLevel.info, ...args);
		this.withStackInfo(info => {
			if (console && console.log) {
				const out = info ? [...formated, { stackInfo: info }] : formated;
				if (force || this._enabled) {
					console.log(...out);
				}
			}
			if (this._isInGroup && options.useEventLog) {
				this._lastGroupData.push(...args);
			} else {
				if (!isLoggerOptions || options.useEventLog) {
					this.writeLogEntry(LogLevel.info, String((args || [])[0]), info && info.stack ? info.stack : null, args);
				}
			}
		});
	}

	group(label: string, ...args) {
		let options: Partial<ILoggerOutputOptions> = {};
		if (this.isOptions(label)) {
			options = label as any;
			label = args[0];
			args = args.splice(1);
		}
		this._isInGroup = true;
		if (!this._enabled) return;

		console && console.group && console.group(this.buildGroupLabel(label, true), ...args);
	}
	groupCollapsed(label: string, ...args) {
		let options: Partial<ILoggerOutputOptions> = {};
		if (this.isOptions(label)) {
			options = label as any;
			label = args[0];
			args = args.splice(1);
		}
		this._isInGroup = true;
		if (!this._enabled) return;

		console && console.groupCollapsed && console.groupCollapsed(this.buildGroupLabel(label, true), ...args);
	}
	groupEnd() {
		this._isInGroup = false;
		if (this._lastGroupData && this._lastGroupData.length > 0) {
			this.writeLogEntry(LogLevel.info, 'Grouped messages, see details array', null, this._lastGroupData);
		}
		this._lastGroupData = [];
		if (!this._enabled) return;

		console && console.groupEnd && console.groupEnd();
	}

	buildGroupLabel(label: string, withPrefix = false) {
		if (this._isInGroup && !withPrefix) {
			return label;
		}
		return String(label) ? `${this._prefixLoggerName} ${label}` : this._prefixLoggerName;
	}
}
