import { InjectionToken, Injector, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthModule } from './auth/auth.module';
import { ClassTransformModule } from './class-transform';
import { ConfigurationModule, IS_PRODUCTION } from './configuration';
import { CookieModule } from './cookie';
import { CopyModule } from './copy';
import { setCoreInjector } from './core-injector';
import { extConfigToken, ICoreConfig, isProductionTokenFactory, mergeDefaultConfig } from './core.config';
import { DatabaseModule } from './db/database.module';
import { DesktopNotificationsModule } from './desktop-notifications';
import { EventLogModule } from './diagnostics/eventlog/eventlog.module';
import { LoggerModule } from './diagnostics/logger/logger.module';
import { StackTraceModule } from './diagnostics/stack-trace/stack-trace.module';
import { WINDOW_PROVIDERS } from './dom';
import { DownloadModule } from './download';
import { DuplicateImportModuleError } from './errors';
import { ErrorHandlerModule } from './errors/error-handler.module';
import { EventBusModule } from './event-bus';
import { FormattingModule } from './formatting';
import { KeyValueIDBStorageModule } from './key-value-idb-storage';
import { UniversalLoaderModule } from './loader/loader.module';
import { MediaQueryModule } from './media';
import { MobileViewIDBStorageModule } from './mobile-view-idb-storage';
import { PlatformModule } from './platform';
import { ResolversModule } from './resolvers';
import { StoreModule } from './store/store.module';
import { UrlModule } from './url';
import { VersionModule } from './version';

@NgModule({
	imports: [
		PlatformModule.forRoot(),
		ResolversModule.forRoot(),
		ConfigurationModule.forRoot(),
		StackTraceModule.forRoot(),
		LoggerModule.forRoot(),
		UniversalLoaderModule.forRoot(),
		EventLogModule.forRoot(),
		DatabaseModule.forRoot(),
		AuthModule.forRoot(),
		EventBusModule.forRoot(),
		ErrorHandlerModule.forRoot(),
		UniversalLoaderModule.forRoot(),
		StoreModule.forRoot(),
		UrlModule.forRoot(),
		VersionModule.forRoot(),
		CookieModule.forRoot(),
		MediaQueryModule.forRoot(),
		FormattingModule.forRoot(),
		ClassTransformModule.forRoot(),
		CopyModule.forRoot(),
		DesktopNotificationsModule.forRoot(),
		KeyValueIDBStorageModule.forRoot(),
		DownloadModule.forRoot(),
		MobileViewIDBStorageModule.forRoot(),
	],
	providers: [...WINDOW_PROVIDERS],
})
export class CoreModule {
	constructor(
		@Optional()
		@SkipSelf()
		coreModule: CoreModule,
		injector: Injector
	) {
		if (coreModule) throw new DuplicateImportModuleError('CoreModule');
		setCoreInjector(injector);
	}

	static withConfig(config: ICoreConfig): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				{
					provide: extConfigToken,
					useValue: mergeDefaultConfig(config),
				},
				{
					provide: IS_PRODUCTION,
					useFactory: isProductionTokenFactory,
					deps: [extConfigToken],
				},
				...WINDOW_PROVIDERS,
			],
		};
	}
}
