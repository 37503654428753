import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IApiResponse } from '../api-response';
import { ApiVersion } from '../api-versions';
import { DataHttpCommonService } from '../data-http-common.service';
import { EndpointUrlConfig } from '../endpoint.config';
import type { IEndpointUrlConfig } from '../endpoint.config';
import { IGetReportsCriteria, IGetReportsFilterCriteria, ReportsDataHttpService } from './abstract';
import type {
	IReportExportData,
	IReportExportResult,
	IReportExportSettingsData,
	IReportImportCriteria,
} from './abstract';
import { excludeUndefinedProps } from '@valhalla/utils';
import { IFilterData, IReportDescription } from '../../entities';

@Injectable()
export class ReportsDataHttpServiceImpl implements ReportsDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService
	) {}

	getReports(criteria?: IGetReportsCriteria): Observable<IReportDescription[]> {
		const url = this.common.getApiUrl(`reports`, ApiVersion.v10);
		return this.http.get<IReportDescription[]>(url, {
			headers: {
				['background-request']: 'true',
			},
			params: excludeUndefinedProps({ contextType: criteria?.contextType }),
		});
		// .pipe(map(result => result.data));
	}

	getReportsFilter(criteria?: IGetReportsFilterCriteria) {
		const url = this.common.getApiCoreUrl(`report-filters/${criteria.reportId}`);
		return this.http.get<IFilterData>(url).pipe(map(result => result));
	}

	exportReport(criteria: string[]): Observable<IReportExportData> {
		const url = this.common.getApiUrl('admin/reportstats/export');
		return this.http.post<IReportExportResult>(url, criteria).pipe(map(res => res.data));
	}

	importReport(criteria: IReportImportCriteria): Observable<void> {
		const url = this.common.getApiUrl('admin/reportstats/import');

		const formData = new FormData();
		formData.append('file', criteria.file, criteria?.name);

		return this.http.post<void>(url, formData);
	}

	getExportSettings(reportId: number): Observable<IReportExportSettingsData> {
		const url = this.common.getApiUrl(`reports/${reportId}/exportSettings`, ApiVersion.v11);
		return this.http.get<IReportExportSettingsData>(url);
	}

	updateExportSettings(reportId: number, body: IReportExportSettingsData): Observable<any> {
		const url = this.common.getApiUrl(`reports/${reportId}/exportSettings/update`, ApiVersion.v11);
		return this.http.post(url, body);
	}
}
