import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { Router } from '@angular/router';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { AuthService, PlatformDetectorProvider, UrlProvider, ViewDestroyStreamService } from '@spa/core';
import { isUserOnline, IUser } from '@spa/data/entities';
import { DataHttpService, isVisibleElement } from '@spa/data/http';
import { UserSettingsFacadeProvider } from '@spa/facade/features/user-settings';
import { BooleanFilter, rxHandler } from '@valhalla/utils';
import {
	BehaviorSubject,
	debounceTime,
	distinctUntilChanged,
	exhaustMap,
	filter,
	from,
	map,
	shareReplay,
	startWith,
	switchMap,
	takeUntil,
	tap,
	take,
	Subject,
	combineLatest,
} from 'rxjs';

@Component({
	selector: 'vh-contacts-search',
	templateUrl: './contacts-search.component.html',
	styleUrls: ['./contacts-search.component.scss'],
})
export class ContactsSearchComponent implements OnInit {
	constructor(
		readonly destroy$: ViewDestroyStreamService,
		readonly server: DataHttpService,
		readonly cdr: ChangeDetectorRef,
		readonly auth: AuthService,
		readonly router: Router,
		readonly urlBuilder: UrlProvider,
		readonly userSettingsStore: UserSettingsFacadeProvider,
		readonly mobileView: MobileViewService,
		readonly platform: PlatformDetectorProvider
	) {}

	@ViewChild('userSearchResultContainer')
	userSearchResultContainer: ElementRef<HTMLElement>;

	@Output()
	hide = new EventEmitter<void>();

	readonly userSearchHandler = rxHandler<string>();
	readonly userSearchInput$ = from<string>(this.userSearchHandler as any).pipe(
		startWith(''),
		tap(searchValue => {
			if (searchValue && this.userSearchResultContainer) {
				this.userSearchResultContainer.nativeElement.scrollTop = 0;
			}
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	readonly userSearchInputHasValue$ = this.userSearchInput$.pipe(distinctUntilChanged(), map(BooleanFilter));

	readonly userSearchResult$ = combineLatest({
		query: this.userSearchInput$.pipe(map(query => String(query).trim())),
		minLen: this.server.config.appSettingsAnonymousConfig$.pipe(
			map(c => Math.max(c.NumberCharactersForSearch || 0, 1))
		),
	}).pipe(
		map(({ query, minLen }) => !!query && query.length >= minLen),
		distinctUntilChanged(),
		switchMap(isSearch => {
			return isSearch ? this.debouncedUserSearch$ : this.usersHistory$;
		}),
		tap(() => setTimeout(() => this.cdr.detectChanges()))
	);

	readonly debouncedUserSearch$ = this.userSearchInput$.pipe(
		filter<string>(BooleanFilter),
		debounceTime(300),
		switchMap(query =>
			this.server.users
				.searchContacts({ userName: query as string })
				.pipe(map(users => users.slice(0, 50).map(i => this.iUserToListItem(i))))
		),
		filter(users => users.length > 0),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	readonly refreshEmployeeHistory$ = new BehaviorSubject(0);

	readonly employeesHistory$ = this.refreshEmployeeHistory$.pipe(
		exhaustMap(_ => this.server.history.getEmployees()),
		shareReplay({ refCount: false, bufferSize: 1 }),
		takeUntil(this.destroy$)
	);
	readonly usersHistory$ = this.employeesHistory$.pipe(map(items => items.filter(({ isGroup }) => !isGroup)));

	readonly openUserProfilePageRequest$ = new Subject();

	readonly orgChartVisible$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.orgChartVisible))
	);

	ngOnInit(): void {
		this.mobileView.hideTabbar = false;
		this.userSearchResult$.pipe(take(1)).subscribe({ error: console.error });
	}

	onKeyDownArrowInUserSearch(e: Event): void {
		e && e.preventDefault();
		const li = document.querySelector<HTMLElement>('.search-coworkers-panel__history .mat-list-item');
		li?.focus();
	}

	onEnterUserSearch(): void {
		const firstUser$ = this.userSearchResult$.pipe(
			map(users => users[0]),
			filter(BooleanFilter)
		);

		firstUser$.pipe(take(1)).subscribe(user => {
			this.openUserProfilePage(user.id);
		});
	}

	iUserToListItem(user: IUser) {
		const isOnline = isUserOnline(user);
		return {
			id: user.userId,
			name: user.displayName,
			appointment: user.appointment,
			email: user.email,
			isOnline: isOnline,
			hasAvatar: user.hasAvatar,
			...user,
		};
	}

	openUserProfilePage(userId?: number, openInNewTab?: boolean): void {
		userId = userId || (this.auth.userId as number);
		this.hidePanel();
		// const url = this.platform.isMobile() ? '/mobile-profile/' : '/user/profile/';
		const url = '/user/profile/';
		if (openInNewTab) {
			let serializeUrl = this.router.serializeUrl(this.router.createUrlTree([url, userId]));
			serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
			window.open(serializeUrl, '_blank');
		} else {
			this.openUserProfilePageRequest$.next(0 as any);
			void this.router.navigate([url, userId]);
		}
	}

	selectItem(e: MatSelectionListChange): void {
		const event = window.event as MouseEvent;
		const openInNewTab = event?.ctrlKey || event?.metaKey;
		const { type, value } = e.options?.[0]?.value || {};
		this.openUserProfilePage(value?.id, openInNewTab);
	}

	hidePanel(): void {
		this.hide.emit();
	}

	isUserOnline(user): boolean {
		return isUserOnline(user);
	}
}
