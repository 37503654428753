import { Injectable, Optional, inject } from '@angular/core';
import { DataHttpService } from '@spa/data/http';
import { KeyValueIDBStorage, LocalStorageProvider, UrlProvider } from '@valhalla/core';
import { BehaviorSubject, merge, raceWith, take, tap } from 'rxjs';
import { ColorService } from './color.service';
import { ILayoutState } from '@spa/facade/layout/state';
import { booleanFilter, debug } from '@valhalla/utils';

export interface IThemeTokenValue {
	value: any;
	multiplier?: any;
	description?: any;
}

export interface IUIFonts {
	families: IUIFontFamily[];
	styles: IUIStyles;
}

export interface IUIFontFamily {
	fontFamily: string;
	weight?: string;
	style?: string;
	src: IUIFontSrc[];
}

export interface IUIFontSrc {
	url: string;
	format: string;
}

export interface IUIStyles {
	display: IUIStyleSizes;
	headline: IUIStyleSizes;
	title: IUIStyleSizes;
	label: IUIStyleSizes;
	body: IUIStyleSizes;
}

export interface IUIStyleSizes {
	xl?: IUIStyleSizeProps;
	lg: IUIStyleSizeProps;
	md: IUIStyleSizeProps;
	sm: IUIStyleSizeProps;
	xs?: IUIStyleSizeProps;
}

export interface IUIStyleSizeProps {
	size: string;
	lineHeight: string;
	fontFamily: string;
	weight: string;
	style: string;
}

@Injectable({ providedIn: 'root' })
export class ThemeService {
	protected server = inject(DataHttpService);
	protected url = inject(UrlProvider);
	protected colorService = inject(ColorService);
	protected localStorage = inject(LocalStorageProvider);

	protected readonly currentThemeState$ = new BehaviorSubject<'light' | 'dark'>('light');
	readonly currentTheme$ = this.currentThemeState$.asObservable();

	readonly designData$ = new BehaviorSubject(null);

	loadStyle() {
		const url = this.url.getUrl(`/ui.json?_=${Date.now()}`);
		const dbCacheKey = 'ui_json';
		const data$ = new BehaviorSubject(null);
		const lsData = this.localStorage.get(dbCacheKey);
		if (lsData) {
			data$.next(lsData);
		}
		const loadNow = !lsData;
		const load = () => {
			this.server.ngHttp
				.get(url)
				.pipe(take(1))
				.subscribe(res => {
					this.localStorage.set(dbCacheKey, res);
					data$.next(res);
				});
		};
		if (loadNow) {
			load();
		} else {
			setTimeout(() => load());
		}
		return data$.pipe(
			booleanFilter(),
			take(1),
			tap(json => {
				this.rebuildStyles(json);
			})
		);
	}

	rebuildStyles(json: any) {
		this.designData$.next(json);

		const spacingToken = this.designData$?.value?.tokens?.spacing;
		const radiusToken = this.designData$?.value?.tokens?.radius;
		const fontsToken = this.designData$?.value?.tokens?.fonts;
		this.setTokenVariable(spacingToken);
		this.setTokenVariable(radiusToken);
		this.buildTypography(fontsToken);

		const state: ILayoutState = this.localStorage.get('PersistentStorePlugin/facade/layout');
		if (state?.colorTheme?.includes('dark')) {
			this.setCurrentTheme('dark');
		} else {
			this.setCurrentTheme('light');
		}
	}

	setTokenVariable(token: Record<string, IThemeTokenValue>, el: HTMLElement = null) {
		if (!token) {
			return;
		}

		const rootEl = document.documentElement;

		Object.keys(token).forEach(key => {
			const tokenItemValue = token[key]?.value;

			if (el) {
				el?.style?.setProperty(`--${key}`, `${tokenItemValue}px`);
			} else {
				rootEl.style?.setProperty(`--${key}`, `${tokenItemValue}px`);
			}
		});
	}

	setCurrentTheme(theme: 'light' | 'dark', el: HTMLElement = null) {
		this.setColorVariables(theme, el);
		this.currentThemeState$.next(theme);
	}

	setColorVariables(theme: 'light' | 'dark', el: HTMLElement = null) {
		const designData = this.designData$.value;

		if (!designData) {
			return;
		}

		const palette = designData?.palette;
		const tokens = designData?.tokens;

		const lightColors = tokens?.colors?.light;
		const darkColors = tokens?.colors?.dark;

		const lightColorsStates = lightColors?.states;
		const darkColorsStates = darkColors?.states;

		const shadow = tokens?.shadowModes;

		const varData =
			theme === 'light'
				? this.buildColorsDataArr(lightColors, lightColorsStates, palette, shadow)
				: this.buildColorsDataArr(darkColors, darkColorsStates, palette, shadow);

		const rootEl = document.documentElement; //<HTMLElement>(':root');

		varData.forEach(v => {
			if (el) {
				this.setVariable(el, v);
			} else {
				this.setVariable(rootEl, v);
			}
		});

		// 2 цикл что бы переменные состояния оказались ниже в доме
		varData.forEach(v => {
			if (el) {
				this.setStateVariable(el, v);
			} else {
				this.setStateVariable(rootEl, v);
			}
		});
	}

	setVariable(el: HTMLElement, varItem) {
		if (varItem?.varName?.startsWith('--state')) {
			el?.style?.setProperty(`${varItem?.varName}-hex`, varItem?.hexValueWithoutTransparency);
			el?.style?.setProperty(`${varItem?.varName}-hex-alpha`, varItem?.hexValueWithTransparency);
		} else {
			el?.style?.setProperty(varItem?.varName, varItem?.hexValueWithTransparency);
		}

		if (varItem?.shadowValue && varItem?.shadowName) {
			el?.style?.setProperty(varItem?.shadowName, varItem?.shadowValue);
		}
	}

	setStateVariable(el: HTMLElement, varItem) {
		if (varItem?.hoverValue) {
			el?.style?.setProperty(`${varItem?.varName}-hover`, varItem?.hoverValue);
		}

		if (varItem?.clickValue) {
			el?.style?.setProperty(`${varItem?.varName}-click`, varItem?.clickValue);
		}

		if (varItem?.selectedValue) {
			el?.style?.setProperty(`${varItem?.varName}-selected`, varItem?.selectedValue);
		}
	}

	buildColorsDataArr(colors: any, states: any, palette: object, shadow: object, prefix = '', result = []): any[] {
		Object.keys(colors).forEach(key => {
			const colorData = colors[key];

			if (key === '_example') {
				return;
			}

			if (colorData?.value) {
				const currentPrefix = prefix ? `${prefix}-${key}` : `${key}`;

				const item = {
					varName: `--${currentPrefix}`,
					hexValueWithTransparency: this.getHEXValueWithTransparency(
						currentPrefix,
						colorData?.value,
						colorData?.transparency,
						palette
					),
					hexValueWithoutTransparency: this.getHEXValueWithoutTransparency(
						currentPrefix,
						colorData?.value,
						colorData?.transparency,
						palette
					),
					hoverValue: this.getStateValue(currentPrefix, 'hover', colorData?.value, states, palette),
					clickValue: this.getStateValue(currentPrefix, 'click', colorData?.value, states, palette),
					selectedValue: this.getStateValue(currentPrefix, 'selected', colorData?.value, states, palette),
				};

				// box-shadow
				if (colorData?.hasOwnProperty('shadowMode')) {
					item['shadowName'] = `--shadow-${currentPrefix}`;
					item['shadowValue'] = shadow[colorData?.shadowMode] ? this.getShadow(shadow[colorData?.shadowMode]) : 'none';
				}

				result.push(item);
				return;
			}

			const currentPrefix = prefix ? `${prefix}-${key}` : `${key}`;
			this.buildColorsDataArr(colors[key], states, palette, shadow, currentPrefix, result);
		});

		return result;
	}

	getStateValue(currentPrefix: string, state: 'hover' | 'click' | 'selected', value: string, statesData, palette: any) {
		if (currentPrefix.startsWith('states-')) {
			return null;
		}

		const colorValueHex = this.getHEXValue(value, palette);
		const currentState = statesData[state];

		return `color-mix(in oklab, ${colorValueHex}, var(--states-${state}-hex) ${currentState?.transparency}%)`;
	}

	getShadow(shadowMode: { x: number; y: number; blur: number; spread: number; transparency: number }) {
		if (!shadowMode) {
			return null;
		}

		const shadowColor = `#000000${this.colorService.getHexAlphaValue(shadowMode?.transparency)}`;
		return `${shadowMode?.x}px ${shadowMode?.y}px ${shadowMode?.blur}px ${shadowMode?.spread}px ${shadowColor}`;
	}

	getHEXValue(value: string, palette: object) {
		const paletteName = value.split('-')[0];

		const currentPalette = palette[paletteName];

		if (!currentPalette) {
			return;
		}

		const currentPaletteValue = currentPalette[value]?.value;

		if (currentPaletteValue) {
			return currentPaletteValue;
		}

		return null;
	}

	getHEXValueWithTransparency(currentPrefix: string, value: string, transparency: number, palette: object) {
		const hex = this.getHEXValue(value, palette);

		if (!transparency) {
			return hex;
		}

		return `${hex}${this.colorService.getHexAlphaValue(transparency)}`;
	}

	getHEXValueWithoutTransparency(currentPrefix: string, value: string, transparency: number, palette: object) {
		if (!currentPrefix.startsWith('states-')) {
			return null;
		}

		const hex = this.getHEXValue(value, palette);
		return `${hex}`;
	}

	buildTypography(fonts: IUIFonts) {
		if (!fonts) {
			return;
		}
		const styles = fonts.styles;
		const families = fonts.families;

		const getFontFamily = (family: string) => {
			const systemFontFamily =
				'-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Ubuntu,Oxygen,Fira Sans,Droid Sans,Helvetica Neue,sans-serif';
			if (family === 'system') {
				return systemFontFamily;
			}

			return `'${family}',${systemFontFamily}`;
		};

		const builtStyles = Object.keys(styles)
			.map(type => {
				return Object.keys(styles[type])
					.map(size => {
						return `.vh-${type}-${size} {
					font-family: ${getFontFamily(styles[type][size].fontFamily)};
					line-height: ${styles[type][size].lineHeight}px;
					font-size: ${styles[type][size].size}px;
					font-style:	${styles[type][size].style};
					font-weight: ${styles[type][size].weight};
				}`;
					})
					.join('');
			})
			.join('');

		const builtFamilies = families
			.map(family => {
				return `
			@font-face {
				font-family: '${family.fontFamily}';
				${family.src.reduce((prev, src) => {
					return `${prev}src: url('${src.url}') ${src.format ? `format('${src.format}')` : ''};`;
				}, '')}
				${family.style ? `font-style: ${family.style};` : ''}
				${family.weight ? `font-weight: ${family.weight};` : ''}
			}`;
			})
			.join('');

		const stylesStr = builtFamilies + builtStyles;

		const inline = document.createElement('style');
		inline.setAttribute('static', 'yes');
		inline.setAttribute('media', 'screen, print');
		inline.textContent = stylesStr;

		const rootEl = document.querySelector('head');
		rootEl.insertAdjacentElement('afterbegin', inline);
	}
}
