import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { LicenseManagerService } from './license-manager.service';
import { AuthService } from '@spa/core';

@Injectable()
export class LicenseBusyInterceptor implements HttpInterceptor {
	constructor(readonly licenseManager: LicenseManagerService, readonly auth: AuthService) {}

	readonly ignoreBusyHeaders = ['license-busy-check', 'ignore-license-busy'];

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.auth.authenticated) {
			return next.handle(req);
		}
		if (!this.licenseManager.licenseBusy) {
			return next.handle(req).pipe(
				tap(e => {
					// const test402 = localStorage.getItem('test402');
					// if (test402) {
					// 	this.licenseManager.licenseBusy = true;
					// }
					if (e instanceof HttpResponse && e.status === 402) {
						this.licenseManager.licenseBusy = true;
					}
				}),
				catchError((error: HttpErrorResponse) => {
					if (error.status === 402) {
						this.licenseManager.licenseBusy = true;
					}
					return throwError(() => error);
				})
			);
		}
		if (this.ignoreBusyHeaders.some(header => req.headers.has(header))) {
			return next.handle(req).pipe(
				tap(e => {
					if (req.headers.has('license-busy-check') && e instanceof HttpResponse && e.status !== 402) {
						this.licenseManager.licenseBusy = false;
						location.reload();
					}
				})
			);
		}
		this.licenseManager.showBusyBanner();
		return of(
			new HttpResponse({
				status: 402,
				statusText: 'License busy',
			})
		);
	}
}
