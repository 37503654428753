import { Location, LocationStrategy } from '@angular/common';
import { inject, Inject, Injectable, InjectFlags, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { ApiVersion } from '@valhalla/data/http';
import { ASSET_INVALIDATE_KEY } from '../asset-invalidate-key';

import { WINDOW } from '../dom';
import { UrlProvider } from './abstract';

const AIK_DEFAULT = Date.now();

@Injectable()
export class UrlProviderImpl implements UrlProvider {
	constructor(
		private readonly _location: LocationStrategy,
		private readonly _sanitizer: DomSanitizer,
		@Inject(WINDOW) private readonly _window: Window
	) {
		this.initBaseHref();
	}

	private _appBaseHref: string;

	get domSanitizer() {
		return this._sanitizer;
	}

	protected initBaseHref() {
		let baseHref = document.querySelector('base')?.getAttribute('href') || '/';
		if (!baseHref.endsWith('/')) {
			baseHref += '/';
		}
		this._appBaseHref = baseHref;
	}

	/** сейчас префикс /spa/, а для работы с API нужно обращение на /app/...
	 * // TODO: этот метод надо будет удалить как SPA будет доступно по пути / вместо /spa
	 *  для ядра недопустимы харкоды - нужно в конфигурацию выносить это
	 */
	protected removeSpaPrefix(url: string): string {
		return url && url.replace('/spa/', '');
	}

	get basePath() {
		return this._appBaseHref;
	}

	/** return right URL prefix with BASE path for API
	 * {basePath}/app/v1.0/api/{your url}
	 */
	getApiUrl(url: string, apiVer?: ApiVersion): string {
		let result: string;
		if (!apiVer) {
			result = this.joinWithSlash('/', this.removeSpaPrefix(this.basePath), `api`, url);
		} else {
			result = this.joinWithSlash('/', this.removeSpaPrefix(this.basePath), `/app/${apiVer}/api/`, url);
		}
		return result;
	}

	/**return whole path: baseHref + url without /spa by default
	 * @example
	 * // baseHref = '/app1/spa/'
	 * const path = '/my/path/';
	 * getUrl(path) // '/app1/my/path'
	 * getUrl(path, true) // '/app1/spa/my/path'
	 */
	getUrl(url: string, includeSpaPrefix = false): string {
		if (url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1)) {
			// if absolute then don't transform
			return url;
		}
		return this.joinWithSlash('/', includeSpaPrefix ? this.basePath : this.removeSpaPrefix(this.basePath), url);
	}

	getAbsoluteUrl(url: string, includeSpaPrefix?: boolean): string {
		if (!url || typeof url !== 'string') return;
		if (url.startsWith('http://') || url.startsWith('https://')) {
			return url;
		}
		url = this.getUrl(url, includeSpaPrefix);
		const absoluteUrl = this.joinWithSlash(location.origin, url);
		return absoluteUrl;
	}

	fileMeetingUrl(key: string) {
		let url = `/calendar/attachment?key=${encodeURIComponent(key)}`;
		url = this.getApiUrl(url);

		return url;
	}

	fileEmailUrl(key: string) {
		let url = `/mail/attachments/download?key=${key}`;
		url = this.getApiUrl(url);

		return url;
	}

	fileUrl(fileId: number | string, versionId?: number | string, options?: any): string {
		let url = `files/download/${fileId}`;
		versionId = typeof versionId === 'number' ? versionId : 1;
		url += `/${versionId}`;

		url = this.getApiUrl(url);

		if (options) {
			const params = [];
			for (const key in options) {
				params.push(`${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`);
			}
			url += `?${params.join('&')}`;
		} else {
			url += '?';
		}
		return url;
	}

	joinWithSlash(...urls: string[]) {
		return (urls || []).reduce((acc, curr) => Location.joinWithSlash(acc, curr), '');
	}

	currentUrlExceptBaseHref() {
		const pathname = this._location.path();
		if (this._appBaseHref === '/') {
			return pathname;
		}
		const baseHref = this._appBaseHref.endsWith('/')
			? this._appBaseHref.substring(0, this._appBaseHref.length - 1)
			: this._appBaseHref;
		const withoutBase = pathname.replace(baseHref, '');
		return this.joinWithSlash('/', withoutBase);
	}

	safeUrlFromBlob(blob: Blob) {
		return this._sanitizer.bypassSecurityTrustUrl(this.urlFromBlob(blob));
	}

	urlFromBlob(blob: Blob): string {
		return URL.createObjectURL(blob);
	}

	getSafeUrl(url: string): SafeValue {
		return this._sanitizer.bypassSecurityTrustUrl(url);
	}

	/**
	 * used DomSanitizer.bypassSecurityTrustResourceUrl
	 * for <script src>, or <iframe src>
	 * Bypass security and trust the given value to be a safe resource URL,
	 * location that may be used to load executable code from, like <script src>, or * <iframe src>
	 */
	getSafeResourceUrl(url: string, includeSpaPrefix = false) {
		return url && this._sanitizer.bypassSecurityTrustResourceUrl(this.getUrl(url, includeSpaPrefix));
	}

	decodeUriComponent(url) {
		return decodeURIComponent(url);
	}

	encodeUriComponent(url) {
		return encodeURIComponent(url);
	}

	buildUrlSearchFromObject(obj: object, omitEmpty: boolean = true): string {
		const search = new URLSearchParams();
		Object.entries(obj || {})
			.filter(([name, value]) => !omitEmpty || value)
			.forEach(([name, value]) => search.append(name, value));
		return search.toString();
	}

	getUrlRelativeToAssets(path: string, withInvalidateKey = false) {
		let url = this.getUrl(this.joinWithSlash('assets', path), true);
		if (withInvalidateKey) {
			url = this.withInvalidateCacheKey(url);
		}
		return url;
	}

	locationBack() {
		this._location.back();
	}

	locationFroward() {
		this._location.forward();
	}

	withInvalidateCacheKey(url: string): string {
		const aik =
			inject(ASSET_INVALIDATE_KEY, {
				optional: true,
			}) || AIK_DEFAULT;
		const q = `aik=${aik}`;
		url += url.includes('?') ? `&${q}` : `?${q}`;
		return url;
	}
}
