import {
	IResourceData,
	IResourcePlan,
	ISetFactParams,
	IPerformerDays,
	IChangePlanParams,
	ISetPlanParams,
	ICalendar,
	IShortPerformer,
} from '@spa/common/components/resource-table/resource-table.types';
import { Observable } from 'rxjs';

export abstract class ResourcesDataHttpService {
	abstract getResources(taskId: number): Observable<IResourceData>;
	abstract getPlanAndFact(taskId: number, performerId: number, resourceId: number): Observable<IResourcePlan>;
	abstract setFact(taskId: number, resourceId: number, params: ISetFactParams): Observable<any>;
	abstract getPossiblePerformers(taskId: number, resourceId: number): Observable<IPerformerDays[]>;
	abstract changePlans(taskId: number, params: IChangePlanParams): Observable<any>;
	abstract setPlan(taskId: number, params: ISetPlanParams): Observable<any>;
	abstract setPlanForPerrformer(params: any): Observable<any>;
	abstract getCalendar(taskId: number, userId: number, from: string, to: string): Observable<ICalendar[]>;
	abstract assignPerformers(taskId: number, users: IShortPerformer[]): Observable<any>;
}
