import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { UrlProvider } from '@spa/core';
import { booleanFilter, guid } from '@valhalla/utils';
import { BehaviorSubject, EMPTY, from, fromEvent, merge, of, Subject } from 'rxjs';
import {
	debounceTime,
	delay,
	distinctUntilChanged,
	filter,
	finalize,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs/operators';

@Component({
	selector: 'vh-common-iframe-viewer',
	templateUrl: './iframe-viewer.component.html',
	styleUrls: ['./iframe-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IFrameViewerCommonComponent implements OnInit, AfterViewInit, OnDestroy {
	constructor(
		readonly router: Router,
		readonly zone: NgZone,
		readonly urlBuilder: UrlProvider,
		readonly sanitizer: DomSanitizer,
		readonly elRef: ElementRef<HTMLElement>
	) {}

	get iframe(): HTMLIFrameElement {
		return this.elRef.nativeElement.querySelector('iframe');
	}

	@Input()
	set src(value: string) {
		this._src$.next(value);
	}

	get src() {
		return this._src$.value;
	}

	@Input()
	hideOnLoading = true;

	@Input()
	refreshOnRouterNavigate = false;

	@Input()
	sandbox: string;

	@Input()
	allow = '';

	@Input()
	scrolling = true;

	@Input()
	addBaseTargetBlank = false;

	@Input()
	iframeBaseHref: string;

	@Input()
	postData: Record<string, any>;

	@Input()
	windowProps: Record<string, any>;

	@Input()
	frameEvents = false;

	@Input()
	htmlClass: string;

	@Output()
	loaded = new EventEmitter<IFrameViewerCommonComponent>();

	@Output()
	scroll = new EventEmitter();

	@Output()
	refreshed = new EventEmitter();

	@ViewChild('iframe')
	set iframeElRef(elRef: ElementRef<HTMLIFrameElement>) {
		this._iframeElRef = elRef;
		if (this._iframeElRef) {
			this._iframeElRef.nativeElement.allow = this.allow;
		}
	}
	get iframeElRef() {
		return this._iframeElRef;
	}
	_iframeElRef: ElementRef<HTMLIFrameElement>;

	private readonly _src$ = new BehaviorSubject<string>(undefined);
	readonly loading$ = new BehaviorSubject(false);

	get contentWindow(): any {
		return this.iframe?.contentWindow;
	}

	get documentElement() {
		return this.iframe?.contentDocument?.documentElement;
	}

	get contentDocument() {
		return this.iframe?.contentDocument;
	}

	get postDataFields() {
		if (!this.postData) {
			return [];
		}
		return Object.entries(this.postData).map(([key, val]) => {
			return { key, val };
		});
	}

	readonly destroy$ = new Subject();
	frameName: string;
	protected previousUrl: string;
	protected readonly subscribeToScroll$ = new Subject();
	blankSrc = this.sanitizer.bypassSecurityTrustResourceUrl('about:blank');

	ngOnInit() {
		this.frameName = guid();
		this._src$
			.pipe(
				booleanFilter(),
				distinctUntilChanged(),
				tap(() => this.loading$.next(true)),
				switchMap(() => merge(this.loaded.asObservable(), of().pipe(delay(10 * 1000))).pipe(take(1))),
				tap(() => {
					this.zone.runTask(() => {
						this.loading$.next(false);
					});
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.destroy$.next(0 as any);
		this.destroy$.complete();
	}

	ngAfterViewInit() {
		if (this.postData) {
			this.loading$.next(true);
			this.loadPostFrame()
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					error: console.error,
					next: htmlPage => {
						if (this.iframeBaseHref) {
							const parser = new DOMParser();
							const doc = parser.parseFromString(htmlPage, 'text/html');
							const baseEl = doc.createElement('base');
							baseEl.href = this.iframeBaseHref;
							doc.head.insertBefore(baseEl, doc.head.firstChild);
							htmlPage = doc.documentElement.outerHTML;
						}
						const frameEl = this.iframe;
						if (frameEl) {
							frameEl.srcdoc = htmlPage;
						}
					},
					complete: () => {
						this.loading$.next(false);
					},
				});
		}
		this.previousUrl = this.router.url;
		if (this.refreshOnRouterNavigate) {
			this.router.events
				.pipe(
					filter(event => event instanceof NavigationEnd),
					takeUntil(this.destroy$)
				)
				.subscribe((event: NavigationEnd) => {
					if (this.previousUrl === this.router.url) {
						this.refresh();
					} else {
						this.previousUrl = this.router.url;
					}
				});
		}
		if (this.sandbox && this.iframe) {
			this.iframe.sandbox.value = this.sandbox;
		}
		if (!this.scrolling) {
			this.iframe?.setAttribute('scrolling', 'no');
		}
		this.zone.runOutsideAngular(() => {
			this.subscribeToScroll$
				.pipe(
					switchMap(() => this.fromScrollEvent().pipe(takeUntil(this.subscribeToScroll$))),
					takeUntil(this.destroy$)
				)
				.subscribe(e => {
					this.zone.run(() => {
						this.scroll.emit({
							event: e,
							scrollTop: this.documentElement?.scrollTop,
							scrollHeight: this.documentElement?.scrollHeight,
							offsetHeight: this.documentElement?.offsetHeight,
						});
					});
				});
		});
	}

	refresh() {
		const frameEl = this.iframe;
		if (!frameEl) {
			return;
		}

		const frameSrc =
			typeof this.src === 'object' && this.src ? this.src['changingThisBreaksApplicationSecurity'] : this.src;
		const isFrameSrcActual = frameEl.contentWindow.location.href.toLowerCase().indexOf(frameSrc.toLowerCase()) >= 0;
		if (isFrameSrcActual) {
			frameEl.contentWindow.location.reload();
		} else {
			frameEl.contentWindow.location.href = frameSrc;
		}
		this.refreshed.emit();
	}

	onLoad() {
		if (!this.iframe) {
			return;
		}
		try {
			// @see #989650
			const doc = this.iframe.contentDocument;
			if (doc) {
				const baseEl = doc.createElement('base');
				if (this.addBaseTargetBlank) {
					baseEl.target = '_blank';
				}
				if (this.iframeBaseHref) {
					baseEl.href = this.iframeBaseHref;
				}
				doc.head.insertBefore(baseEl, doc.head.firstChild);
				this.updateHtmlClass();
			}
			if (this.contentWindow && typeof this.windowProps === 'object') {
				for (const key of Object.keys(this.windowProps)) {
					// TODO: memory leaks, need cleanup
					this.contentWindow[key] = this.windowProps[key];
				}
			}
			this.contentWindow.opener = window;
		} catch (error) {
			console.error(error);
		}
		this.loaded.emit(this);
		this.subscribeToScroll$.next(0 as any);
	}

	loadPostFrame() {
		const formData = new FormData();
		for (const { key, val } of this.postDataFields) {
			formData.append(key, val);
		}
		const url = typeof this.src === 'string' ? this.src : this.src?.['changingThisBreaksApplicationSecurity'];
		return from(
			fetch(url, {
				method: 'POST',
				body: formData,
			}).then(r => r.text())
		);
	}

	protected fromScrollEvent() {
		// memory leaks
		if (this.frameEvents) {
			return fromEvent(this.contentWindow, 'scroll', { passive: true }).pipe(
				debounceTime(300),
				takeUntil(this.destroy$)
			);
		} else {
			return EMPTY;
		}
	}

	protected updateHtmlClass() {
		const classes = this.htmlClass?.split(' ').filter(Boolean) || [];
		if (classes.length) {
			this.iframe?.contentDocument?.documentElement?.classList?.add(...classes);
		}
	}
}
