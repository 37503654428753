import { Observable } from 'rxjs';
import { IAttachment } from '@valhalla/data/entities';

export abstract class FileDataHttpService {
	abstract mapFileToAttachment(file: File): Partial<IAttachment>;

	abstract getFileContent(fileId: number, versionId?: number, options?: any): Observable<ArrayBuffer>;
	abstract getFileEmailContent(key: string, versionId?: number, options?: any): Observable<ArrayBuffer>;
	abstract getFileMeetingContent(key: string, versionId?: number, options?: any): Observable<ArrayBuffer>;

	/** load to TEMP table */
	abstract preUploadFile(params: IAttachUploadParams): IAttachUploadResult;
	/** load to MAIN table */
	abstract uploadFile(params: IAttachUploadParams): IAttachUploadResult;

	abstract openFileDialog(options?: Partial<IFileDialogOptions>): Observable<IFileDialogResult>;
	abstract ToPreUploadedFiles(formData: FormData): Observable<IPreuploadedFilesResponse>;
	abstract fileUrl(fileId: number, versionId?: number, options?: any): string;
	abstract getFileInfo(fileId: number): Observable<IFileInfo>;
	abstract getNeighborFiles(fileId: number): Observable<IFileRef[]>;
	abstract uploadFileToTaskRest(req: IUploadFileToTask): Observable<IPreuploadedFilesResponse>;
	abstract uploadFileToEP(req: IUploadFileToEp): Observable<any>;
	abstract uploadFileToMultifile(req: IUploadFileToMultifile): Observable<any>;
	abstract updateFileBase64(req: IUpdateFileBase64Params): Observable<any>;
	abstract getFileVersion(fileId: number): Observable<IFileVersion[]>;
	abstract preUploadedFilesToTask(req: IPreUploadFileToTask): Observable<IPreuploadedFilesResponse>;
	abstract fileToEPTableLegacy(params: IFileToEPTableLegacy): Observable<IFileToEPTableLegacyResponse[]>;
	abstract fileToFileStorageFolder(req: IFileToFileStorageFolderReq): Observable<IFileLoaded[]>;

	abstract getFilestorage(params: IGetFilestorage): Observable<IFileStorageRes>;
	abstract getFolderInfo(params: IGetFolderInfo): Observable<IFolderInfoRes>;
	abstract getFileInfo2(
		fileId: number,
		viewType?: IFileStorageViewType,
		extraId?: number
	): Observable<IFileStorageFile>;
	abstract linkFileWithTask(fileId: number, taskId: number): Observable<any>;
	abstract createFolder(
		parentFolderType: IStorageFolderType,
		parentFolderId: number,
		params: IFileStorageCreateFolderReq
	): Observable<any>;
	abstract updateFolder(
		folderType: IStorageFolderType,
		folderId: number,
		params: IFileStorageCreateFolderReq
	): Observable<any>;
	abstract deleteFolder(folderType: IStorageFolderType, folderId: number, deleteChildren?: boolean): Observable<any>;
	abstract encryptFile(fileId: number): Observable<any>;
	abstract deleteFile(params: IFileStorageDeleteFileReq): Observable<any>;
	abstract fileStorageToEP(params: IFileStorageToEP): Observable<any>;
	abstract fileStorageToMultiFile(params: IFileStorageToMultiFile): Observable<any>;
	abstract searchFiles(req: ISearchFileReq): Observable<ISearchFileRes[]>;
}

export interface ISearchFileReq {
	query: string;
	abstractTypeId?: number;
	dontShowDeleted: boolean;
	resultRowsCount: number;
	resultSetOffset: number;
}

export interface ISearchFileRes {
	fileContentAbstractsId: number;
	abstractTypeId: number;
	fileId: number;
	versionId: number;
	size: number;
	name: string;
	ext: string;
	fileMime: string;
	isEncrypted: boolean;
	latestVersionId: number;
	ownerUserId: number;
}

export interface IFileStorageToMultiFile {
	files: {
		fileId: number;
		versionId: number;
	}[];
	taskId: number;
	extParamId: number;
	clearAllBeforeAdd: boolean;
}

export interface IFileStorageToEP {
	fileId: number;
	fileVersion: number;
	taskId: number;
	extParamId: number;
}

export interface IFileStorageDeleteFileReq {
	parentFolderType: IStorageFolderType;
	parentFolderId: number;
	fileId: number;
}

export enum IFileStorageViewType {
	Grid = 'Grid',
	Gallery = 'Gallery',
}

export interface IFileToFileStorageFolderReq {
	clientId: number | string;
	file?: Partial<IAttachment>;
}

export interface IFileLoaded {
	fileId: number;
	fileName: string;
	fileVersion: number;
	preUploadFileId: number;
}

export interface IFolderInfoRes extends IFileStorageFolder {}
export interface IGetFolderInfo {
	folderType: IStorageFolderType;
	folderId?: number;
	extraId?: number;
	viewType?: 'Grid' | 'Gallery';
}

export interface IFileStorageCreateFolderReq {
	id?: number;
	name: string;
	localizedNameId?: number;
}

export interface IFileStorageRes {
	subFolders: IFileStorageFolder[];
	files: IFileStorageFile[];
}

export interface IFileStorageFolder {
	id: number;
	clientId: string;
	parentFolderId: number;
	parentFolder: IFileStorageFolder;
	parentFolderClientId: string;
	name: string;
	icon: string;
	thumbnailFileId: number;
	typeName: string;
	actions: IFileStorageAction[];
	userPermissions: IFileStorageUserPermissions;
	allPermissions?: IFileStorageAllPermissions[];
	extraId: number;
	path: string;
	ownerUserId: number;
	type: IStorageFolderType;
	hasChildren: boolean;
	files: IFileStorageFile[];
	countFiles?: number;
	alias?: string;
}

export interface IFileStorageFile {
	id: number;
	clientId: string;
	parentFolderId: number;
	parentFolder: IFileStorageFolder;
	parentFolderClientId: string;
	name: string;
	icon: string;
	thumbnailFileId: number;
	typeName: string;
	actions: IFileStorageAction[];
	userPermissions: IFileStorageUserPermissions;
	allPermissions: IFileStorageAllPermissions[];
	extraId: number;
	translitName: string;
	latestVersionId: number;
	fileProviderId: number;
	isInSharepointFileProvider: boolean;
	versionCount: number;
	ext: string;
	mime: string;
	size: number;
	uploadDate: string;
	uploadDateStr: string;
	ownerUserId: number;
	uploadUserId: number;
	isEncrypted: boolean;
	isReadOnly: boolean;
	downloadPath: string;
	folderPermissions: IFileStorageFolderPermissions[];
	links: IFileStorageLink[];
}

export interface IFileStorageAction {
	name: string;
	icon: string;
	imageUrl: string;
	text: string;
	toolTip: string;
	clientFunction: string;
	isAdditional: boolean;
	orderValue: number;
	enabled: boolean;
	visibleIfReadOnly: boolean;
	type: string;
	actionType: IFileStorageActionType;
}

export enum IFileStorageActionType {
	OpenFolder = 'OpenFolder',
	CreateFolder = 'CreateFolder',
	UploadFile = 'UploadFile',
	Properties = 'Properties',
	DeleteFile = 'DeleteFile',
	DeleteFolder = 'DeleteFolder',
	ShowInOfficeOnlineEditor = 'ShowInOfficeOnlineEditor',
	EditOfficeWebDav = 'EditOfficeWebDav',
	Download = 'Download',
	ShowProtect = 'ShowProtect',
	EditSharepoint = 'EditSharepoint',
	ShowEditFileForm = 'ShowEditFileForm',
	Encrypt = 'Encrypt',
	ViewHistory = 'ViewHistory',
	ViewEdsHistory = 'ViewEdsHistory',
	CreateTask = 'CreateTask',
	ShowImage = 'ShowImage',
	ShowText = 'ShowText',
	ShowHtml = 'ShowHtml',
	ShowEml = 'ShowEml',
	ShowPdf = 'ShowPdf',
	OpenParent = 'OpenParent',
	OpenTask = 'OpenTask',
	Subscribe = 'Subscribe',
	Unsubscribe = 'Unsubscribe',
	Like = 'Like',
	Unlike = 'Unlike',
	DownloadArchiveFiles = 'DownloadArchiveFiles',
	CopyLinkToBuffer = 'CopyLinkToBuffer',
	LinkToTask = 'LinkToTask',
}

export interface IFileStorageAllPermissions {
	id: number;
	type: string;
	displayName: string;
	userId: number;
	groupId: number;
	readOnly: boolean;
	permissions: IFileStorageUserPermissions;
}

export interface IFileStorageLink {
	name: string;
	type: string;
	navLink: string;
	taskId?: number;
	extParamId?: number;
}

export interface IFileStorageUserPermissions {
	view: boolean;
	add: boolean;
	modify: boolean;
	delete: boolean;
	history: boolean;
	edsHistory: boolean;
	editInSharePoint: boolean;
	createTask: boolean;
}

export interface IFileStorageFolderPermissions {
	id: number;
	type: string;
	displayName: string;
	userId: number;
	groupId: number;
	readOnly: boolean;
	permissions: IFileStorageUserPermissions;
}

export interface IGetFilestorage {
	folderType: IStorageFolderType;
	folderId?: number;
	extraId?: number;
	withFiles?: boolean;
}

export enum IStorageFolderType {
	Root = 'Root',
	Folder = 'Folder',
	UserRoot = 'UserRoot',
	UserFolder = 'UserFolder',
	TaskRoot = 'TaskRoot',
	Category = 'Category',
	Subcategory = 'Subcategory',
	Task = 'Task',
	Shared = 'Shared',
	LinkedToTask = 'LinkedToTask',
	File = 'File',
	TaskExtParamsRootFolder = 'TaskExtParamsRootFolder',

	TaskTableExtParamFolder = 'TaskTableExtParamFolder',
	TaskExtParamFolder = 'TaskExtParamFolder',
	TaskFileExtParamFolder = 'TaskFileExtParamFolder',
	TaskTableCellExtParamFolder = 'TaskTableCellExtParamFolder',
	TaskTableColumnExtParamFolder = 'TaskTableColumnExtParamFolder',
	SubcategoryExtParamRootFolder = 'SubcategoryExtParamRootFolder',
	SubcategoryExtParamFolder = 'SubcategoryExtParamFolder',
}

export interface IFileToEPTableLegacy {
	taskId?: number;
	tableExtParamId: number;
	columnId: number;
	rowId: number;
	file: File;
}

export interface IFileToEPTableLegacyResponse {
	fileName: string;
	fileId: number;
	fileVersion: number;
	preUploadFileId: number;
}
export interface IFileVersion {
	description: string;
	fileId: number;
	isDecrypted: boolean;
	isEncrypted: boolean;
	providerId: number;
	providerKey: string;
	size: number;
	sizeInt: number;
	uploadDate: string;
	uploadUserId: number;
	uploadUserName: string;
	versionId: number;
}

export interface IUploadFileToMultifile {
	taskId: number;
	extParamId: number;
	files: any[];
	comment?: string;
}

export interface IUploadFileToEp {
	fileName: string;
	fileData: any;
	contentType?: string;
	taskId: number;
	extParamId: number;
	comment?: string;
}

export enum IUploadType {
	toPreUploadedFiles = 'ToPreUploadedFiles',
	fileToTask = 'FileToTask',
	preUploadedFilesToTask = 'PreUploadedFilesToTask',
	fileToEP = 'FileToEP',
	fileToMultiFile = 'FileToMultiFile',
}

export interface IPreUploadFileToTask {
	taskId: number;
	file?: Partial<IAttachment>;
	preUploadFileId?: number;
}

export interface IUploadFileToTask {
	taskId: number;
	attach: Partial<IAttachment>;
}

export interface IAttachUploadParams {
	taskId?: number | string;
	attach: Partial<IAttachment>;
	cancel$?: Observable<any>;
}

export interface IUpdateFileBase64Params {
	fileId: number;
	versionId?: number;
	base64Content: string;
}

export interface IUploadResult<T = any> {
	progressPercent$: Observable<number>;
	result$: Observable<T>;
	cancel: () => void;
}

export interface IAttachUploadResult extends IUploadResult<Partial<IAttachment>> {
	maxRequestLength?: string;
}

export interface IFileDialogOptions {
	multiple: boolean;
	onlyMediaFiles?: boolean;
	onlyEmails?: boolean;
}

export interface IFileDialogResult {
	files: File[];
}

export interface IPreuploadedFilesElem {
	fileId: number;
	fileName: string;
	fileVersion: number;
	preUploadFileId: number;
}

export interface IPreuploadedFilesResponse {
	data: IPreuploadedFilesElem[];
}

export interface IFileRef {
	fileId: number;
	versionId?: number;
	taskId?: number;
}

export interface IFileInfo {
	translitName: string;
	latestVersionId: number;
	fileProviderId: any;
	isInSharepointFileProvider: boolean;
	versionCount: number;
	ext: string;
	mime: string;
	size: number;
	uploadDate: string;
	uploadDateStr: string;
	ownerUserId: any;
	uploadUserId: number;
	isEncrypted: boolean;
	isReadOnly: boolean;
	downloadPath: any;
	folderPermissions: any[];
	links: ILink[];
	id: number;
	clientId: string;
	parentFolderId: any;
	parentFolder: any;
	parentFolderClientId: any;
	name: string;
	icon: string;
	thumbnailFileId: any;
	typeName: string;
	actions: IAction[];
	userPermissions: IUserPermissions;
	allPermissions: IAllPermission[];
	extraId: any;
}

export interface ILink {
	name: string;
	type: string;
	navLink: string;
}

export interface IAction {
	name: string;
	icon?: string;
	imageUrl: any;
	text: string;
	toolTip: any;
	clientFunction: string;
	isAdditional: boolean;
	orderValue: number;
	enabled: boolean;
	visibleIfReadOnly: boolean;
	type: string;
	actionType: IFileStorageActionType;
}

export interface IUserPermissions {
	view: boolean;
	add: boolean;
	modify: boolean;
	delete: boolean;
	history: boolean;
	edsHistory: boolean;
	editInSharePoint: boolean;
	createTask: boolean;
}

export interface IAllPermission {
	id: number;
	type: string;
	displayName: string;
	userId: number;
	groupId: any;
	readOnly: boolean;
	permissions: Permissions;
}

export interface Permissions {
	view: boolean;
	add: boolean;
	modify: boolean;
	delete: boolean;
	history: boolean;
	edsHistory: boolean;
	editInSharePoint: boolean;
	createTask: boolean;
}
