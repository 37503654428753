import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CategoryDataHttpService } from './category';
import { ChatDataHttpService } from './chat';
import { CommentsDataHttpService } from './comments';
import { ConfigurationDataHttpService } from './configuration';
import { DataSourceHttpService } from './data-source';
import { EmailDataHttpService } from './email';
import { EpDataHttpService } from './ep';
import { FavoritesDataHttpService } from './favorites';
import { FeedsDataHttpService } from './feeds';
import { FileDataHttpService } from './file';
import { HistoryDataHttpService } from './history';
import { LocalizationDataHttpService } from './localization';
import { PortalDataHttpService } from './portal';
import { PublicationsDataHttpService } from './publications';
import { SignaturesHttpService } from './signatures';
import { ReportsDataHttpService } from './reports';
import { SearchDataHttpService } from './search';
import { SurveyDataHttpService } from './survey';
import { SystemDataHttpService } from './system';
import { TaskDataHttpService } from './task';
import { TickersDataHttpService } from './tickers';
import { UsersDataHttpService } from './users';
import { TaskHierarhyHttpService } from './task-hierarhy';
import { AuthDataHttpService } from './auth';
import { SubtasksHttpService } from './subtasks';
import { LinkedTasksHttpService } from './linked-tasks';
import { FilterHttpService } from './filters';
import { PhoneDataHttpService } from './phone';
import { DataHttpCommonService } from './data-http-common.service';
import { AdminDataHttpServiceBase } from './admin/abstract';
import { ProviderCalendarDataHttpServiceBase } from './provider-calendars';
import { MobileDataHttpService } from './mobile';
import { SipDataHttpService } from './sip';
import { BatchProcessingDataHttpService } from './batch-processing';
import { ResourcesDataHttpService } from './resources';
import { CachesDataHttpService } from './caches';
import { JobsDataHttpService } from './jobs';

@Injectable()
export class DataHttpService {
	constructor(
		readonly common: DataHttpCommonService,
		readonly auth: AuthDataHttpService,
		readonly ngHttp: HttpClient,
		readonly chats: ChatDataHttpService,
		readonly files: FileDataHttpService,
		readonly task: TaskDataHttpService,
		readonly portal: PortalDataHttpService,
		readonly ep: EpDataHttpService,
		readonly users: UsersDataHttpService,
		readonly tickers: TickersDataHttpService,
		readonly favorites: FavoritesDataHttpService,
		readonly ds: DataSourceHttpService,
		readonly history: HistoryDataHttpService,
		readonly config: ConfigurationDataHttpService,
		readonly locale: LocalizationDataHttpService,
		readonly publications: PublicationsDataHttpService,
		readonly mail: EmailDataHttpService,
		readonly reports: ReportsDataHttpService,
		readonly comments: CommentsDataHttpService,
		readonly category: CategoryDataHttpService,
		readonly feeds: FeedsDataHttpService,
		readonly survey: SurveyDataHttpService,
		readonly system: SystemDataHttpService,
		readonly search: SearchDataHttpService,
		readonly signatures: SignaturesHttpService,
		readonly taskhierarhy: TaskHierarhyHttpService,
		readonly subtasks: SubtasksHttpService,
		readonly linkedtasks: LinkedTasksHttpService,
		readonly filters: FilterHttpService,
		readonly phone: PhoneDataHttpService,
		readonly admin: AdminDataHttpServiceBase,
		readonly providersCalendars: ProviderCalendarDataHttpServiceBase,
		readonly mobile: MobileDataHttpService,
		readonly sip: SipDataHttpService,
		readonly batchProcessing: BatchProcessingDataHttpService,
		readonly resources: ResourcesDataHttpService,
		readonly caches: CachesDataHttpService,
		readonly jobs: JobsDataHttpService
	) {}
}
