import { Observable } from 'rxjs';

import { IUserQuickAppsDefinition } from './store/state.contract';

export abstract class UserQuickAppsProvider {
	abstract selectUserApps(): Observable<Array<IUserQuickAppsDefinition>>;

	abstract addApps(...apps: Array<IUserQuickAppsDefinition>);
	abstract removeApps(...apps: Array<IUserQuickAppsDefinition | number>);
}
