import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { RouterModule } from '@angular/router';

import { NavPanelEmailComponent } from './nav-panel-email.component';

import { FavoriteExpandListModule } from '../favorites-panel/favorite-expand-list';
import { ContextMenuModule } from '@valhalla/common/context-menu';

import { LocalizationModule } from '../../../../localization';
import { SidebarHeaderModule } from '../sidebar-header/sidebar-header.module';
import { TippyModule } from '@ngneat/helipopper';
import { NavPanelEmailItemModule } from './nav-panel-email-item/nav-panel-email-item.module';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		MatListModule,
		MatIconModule,
		MatDividerModule,
		FavoriteExpandListModule,
		ContextMenuModule,
		LocalizationModule,
		SidebarHeaderModule,
		TippyModule,
		NavPanelEmailItemModule,
	],
	exports: [NavPanelEmailComponent],
	declarations: [NavPanelEmailComponent],
	providers: [],
})
export class NavPanelEmailModule {}
