import { IUserShort } from '@spa/data/entities';
import { Observable } from 'rxjs';

export abstract class SignaturesHttpService {
	abstract getQuickReplies(taskId: number | string): Observable<string[]>;
	abstract changeSignatureDueDate(obj: ISignatureDueDateChangeCriteria): Observable<any>;
	abstract addQuickReply(obj: IAddQuickReplyCriteria): Observable<any>;
	abstract getPossibleAcceptants(taskId: number, signatureId: number): Observable<IUserShort[]>;
	abstract revokeTaskSignatures(params: IRevokeTaskSignaturesParams): Observable<any>;
	abstract addUnrequiredSignatures(obj: IAddUnrequiredSignatures): Observable<any>;
	abstract changeTaskStepWithoutUnrequiredSignatures(taskId: number): Observable<any>;

	abstract getDynamicSignatures(req: IDynamicSignaturesReq): Observable<IDynamicSignature[]>;
	abstract reqDynamicSignature(req: IReqDynamicSignature): Observable<any>;
	abstract getEdsTypes(taskId: number): Observable<EdsPluginType>;
	abstract getEdsInfoFiles(taskSignatureId: number): Observable<IEpsSignature>;
	abstract getEdsFileHistory(fileId: number, versionId?: number): Observable<IEdsFileInfoHistory[]>;

	// DSS eds block
	abstract getDSSCertificates(criteria: IDSSUserCredentialsCriteria): Observable<any>;
	abstract processSignatureOperation(criteria: IDSSCertInfoCriteria): Observable<any>;
	abstract rejectTransaction(taskSignatureId: number): Observable<any>;
	abstract getDssDocumentsWithoutTransaction(criteria: IDSSCertInfoCriteria): Observable<any>;
	abstract getDssDocuments(criteria: IDSSCertInfoCriteria): Observable<any>;
	abstract getTransactionState(taskSignatureId: number): Observable<any>;
	abstract sendTransaction(criteria: IDSSCertInfoCriteria): Observable<any>;
	abstract getDssInfo(): Observable<IEdsDssInfo>;
	abstract confirmChoice(taskSignatureId: number, choice: string): Observable<any>;
}

export interface IReqDynamicSignature {
	taskId: number;
	due?: any;
	reason?: string;
	acceptors?: {
		users?: number[];
		groups?: number[];
		orgUnits?: number[];
	};
	signatureId?: number;
	separateForEach?: boolean;
}
export interface IDynamicSignaturesReq {
	taskId?: number;
	acceptantId?: number;
}

export interface IRevokeTaskSignaturesParams {
	reason: string;
	taskId: number;
	location?: any;
}

export interface ISignatureDueDateChangeCriteria {
	location?: any;
	newDate?: string;
	newDueDate?: string;
	reason: string;
	taskSignatureId: number;
}
export interface IAddQuickReplyCriteria {
	reason: string;
}

export interface IAcceptorsArr {
	Users: number[];
	Groups: number[];
	OrgUnits: number[];
	Unresolved: any[];
	IsEmpty: boolean;
}

export interface IRequestDynamicUserSignature {
	taskId: number;
	reason: string;
	due: number;
	acceptorsArr: IAcceptorsArr;
	separateForEach: boolean;
}

export interface IRequestDynamicSignature {
	signatureId: string;
	taskId: number;
	reason: string;
	due: number;
}

export interface IGetDynamicSignatureResultObj {
	__type: string;
	name: string;
	value: string;
	isDefaultValue: boolean;
	optionTitle?: any;
}

export interface IGetDynamicSignatureResult {
	d: IGetDynamicSignatureResultObj[];
}

export interface IDynamicSignature {
	timeToSignRequired: boolean;
	timeToSignMin: number;
	timeToSignDefault: number;
	reasonRequired: boolean;
	name: string;
	id: number;
}
export interface IGetDynamicSignaturesByUserD {
	signName: string;
	signatures: IGetDynamicSignatureResult[];
}
export interface IGetDynamicSignaturesByUserResult {
	d: IGetDynamicSignaturesByUserD;
}

export interface IAddUnrequiredSignatures {
	SignaturesList?: number[];
	signaturesList?: number[];
	taskId: number;
}

export interface IEpsSignature {
	taskId: number;
	taskSignatureName: string;
	signatureUserId?: number | null;
	signatureStepId?: number | null;
	statesRoutesSignatureId?: number | null;
	signatureId: number;
	stepId?: number | null;
	isEdsSignature: boolean;
	edsSessionId?: number | null;
	edsSignatureInfo: IEdsSignSetting[];
	edsINNInfo: string[] | null;
	name: string;
}

export interface IEdsSignSetting extends IEdsSignFile {
	signatureFileId?: number | null;
}

export interface IEdsSignFile extends IEdsSignFileShort {
	fileName: string;

	/// <summary>
	/// Версия файла
	/// </summary>
	versionId: string;
}

export interface IEdsSignFileShort {
	edsId: number;
	fileId: number;
	isInnerSign: boolean;
	signatureType?: EdsSignatureType | null;
	data: string;
	isCosign: boolean;
}

export interface IDSSUserCredentialsCriteria {
	login: string;
	password?: string;
}

export interface IEdsFileInfoHistory {
	signDate: string;
	signatureFileId: number;
	signatureFileName: string;
	taskSignatureName: string;
	isInnerSign: boolean;
	signedByUserId: number;
	signedByUserName: string;
	certificateSerialNumber: string;
	certificateOwnerName: string;
	isCosign: string;
	mchdXMLFileId: number;
	mchdXMLFileName: string;
	mchdSigFileId: number;
	mchdSigFileName: string;
}

export interface IDSSCertInfoCriteria {
	taskSignatureId: number;
	edsCertInfo: IEdsDSSCertificateInfo;
	passwordsDto: IEdsPassword;
	challengeValue: string;
}

export interface IEdsDSSCertificateInfo {
	certId?: number;
	certificateOwnerName: string;
	certificateSerialNumber: string;
	certificateIssueDate: string;
	certificateExpirationDate: string;
}

export interface IEdsPassword {
	password: string;
	certPassword: string;
}

export interface IEdsDssInfo {
	hasLogin: boolean;
	dssClient: boolean;
	needPassword: boolean;
	timeToWait: number;
}

export enum EdsSignatureType {
	GOST3410,
	CAdES,
	PDF,
	MSOffice,
	CMS,
}

export enum EdsPluginType {
	cryptoPro = 'CryptoPro',
	ruToken = 'RuToken',
	defineOnClient = 'DefineOnClient',
	none = 'None',
}
