import { ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ViewDestroyStreamService } from '@spa/core';
import { takeUntil } from 'rxjs';
import { ContentOverlayService, ContentOverlayStackItem } from './content-overlay.service';

@Component({
	selector: 'vh-content-overlay',
	templateUrl: './content-overlay.component.html',
	styleUrls: ['./content-overlay.component.scss'],
	providers: [ViewDestroyStreamService],
})
export class ContentOverlayComponent implements OnInit {
	constructor(
		readonly stack: ContentOverlayService,
		readonly cdr: ChangeDetectorRef,
		readonly destroy$: ViewDestroyStreamService
	) {}

	@Input()
	@HostBinding('style.left.px')
	left = 12;

	@Input()
	@HostBinding('style.top.px')
	top = 8;

	@Input()
	@HostBinding('style.right.px')
	right = 12;

	@Input()
	@HostBinding('style.bottom.px')
	bottom = 12;

	@HostBinding('attr.active')
	get active() {
		return !!this.stack.currentComponent;
	}

	readonly stackItems$ = this.stack.stackItems$;

	ngOnInit() {
		this.stack.change$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.cdr.markForCheck();
		});
	}

	onOutletActivate(stackItem: ContentOverlayStackItem, instance: any) {
		stackItem.instance = instance;
		stackItem.context.onCreate?.call(null, instance);
	}
}
