import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TabbarModule } from '../mobile-tabbar/tabbar.module';

import { FooterComponent } from './footer.component';

@NgModule({
	imports: [PortalModule, MatToolbarModule, CommonModule, TabbarModule],
	declarations: [FooterComponent],
	exports: [FooterComponent],
})
export class FooterModule {}
