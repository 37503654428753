import { Injectable, Provider } from '@angular/core';
import { DataHttpService, IGetAllTickersResult, IGetValueCustomTickerDto } from '@valhalla/data/http';
import { Observable, Subject, BehaviorSubject, of, EMPTY } from 'rxjs';
import { map, switchMap, startWith, exhaustMap, shareReplay, mapTo, catchError } from 'rxjs/operators';

import { chatTickerStateFactory } from '../store/state';
import { mapSystemTickersToRecord } from '../store/ticker-mapper';
import { ITicker, ITickerCommand } from '../ticker.model';
import { TickerProvider } from './abstract';
import { ITickerDto, TickerType, TickerIconSet, TickerClickAction } from '@valhalla/data/entities';
import { debounceTimeAfterFirst, debug, removeDuplicateByFn, safe } from '@valhalla/utils';

@Injectable()
export class TickerProviderImpl implements TickerProvider {
	constructor(readonly dataHttp: DataHttpService) {}

	readonly getAllRequest$ = new BehaviorSubject(-1);

	readonly tickerValues$ = this.getAllRequest$.pipe(
		exhaustMap(() => this.dataHttp.tickers.getAll().pipe(catchError(() => EMPTY))),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	///**@deprecated */
	// selectSystemTickers(options?): Observable<Record<string, ITicker>> {
	// 	return this.dataHttp.tickers.getSystemTickers(options).pipe(map(tickers => mapSystemTickersToRecord(tickers)));
	// }

	//**@deprecated */
	// selectChatTicker(options?): Observable<Record<string, ITicker>> {
	// 	return this.dataHttp.tickers
	// 		.getChatUnreadCountTicker(options)
	// 		.pipe(map(unreadCount => chatTickerStateFactory(unreadCount)));
	// }

	///**@deprecated */
	// selectCustomTickers(): Observable<Record<string, ITicker>> {
	// 	return this.dataHttp.tickers.getSchemaCustomTickers().pipe(
	// 		switchMap(tickers => {
	// 			const ids = tickers.map(ticker => ticker.id);
	// 			return this.dataHttp.tickers
	// 				.getValuesCustomTickers({ tickerIds: ids })
	// 				.pipe(map(values => customTickersMapper(tickers, values)));
	// 		})
	// 	);
	// }

	selectSystemTickers2(data?: IGetAllTickersResult): Observable<Record<string, ITicker>> {
		let source$: Observable<IGetAllTickersResult>;
		if (data) {
			source$ = of(data);
		} else {
			this.getAllRequest$.next(1);
			source$ = this.tickerValues$;
		}
		return source$.pipe(map(data => mapSystemTickersToRecord(data.systemTickers, data.systemTickersNames)));
	}

	// selectChatTicker2(options?): Observable<Record<string, ITicker>> {
	// 	this.getAllRequest$.next(1);
	// 	return this.dataHttp.tickers
	// 		.getChatUnreadCountTicker(options)
	// 		.pipe(map(unreadCount => chatTickerStateFactory(unreadCount)));
	// }

	selectCustomTickers2(data?: IGetAllTickersResult): Observable<Record<string, ITicker>> {
		let source$: Observable<IGetAllTickersResult>;
		if (data) {
			source$ = of(data);
		} else {
			this.getAllRequest$.next(1);
			source$ = this.tickerValues$;
		}
		return source$.pipe(
			map(data => {
				const hasAssistans = data.customAssistantTickers?.length > 0;
				if (hasAssistans) {
					const customTckers = [
						...(data.customTickers || []),
						...data.customAssistantTickers.flatMap(at => at.tickers),
					];
					const withAssistansTickers = removeDuplicateByFn(customTckers, (a, b) => a.id === b.id);
					return withAssistansTickers;
				} else {
					return data.customTickers;
				}
			}),
			map(customTickersMapper2)
		);
	}
}

export function customTickersMapper(
	tickers: ITickerDto[],
	values: IGetValueCustomTickerDto[]
): Record<string, ITicker> {
	const record = tickers.reduce((rec, tickerDesc, idx) => {
		const valueTicker = values.find(val => Number(val.First) === Number(tickerDesc.id)).Second;

		const ticker: ITicker = {
			id: `${TickerType.custom}/${tickerDesc.id}`,
			type: TickerType.custom,
			title: tickerDesc.name,
			name: tickerDesc.name,
			value: valueTicker,
			order: idx + 100,
			icon: {
				// material by default
				fontSet: tickerDesc.iconSet === TickerIconSet.material ? undefined : tickerDesc.iconSet,
				name: tickerDesc.iconFromSet || '',
			},
			commands: customTickerCommandMapper(tickerDesc),
			badgeColor: tickerDesc.color === 'red' ? 'warn' : 'accent',
		};

		rec[ticker.id] = ticker;

		return rec;
	}, {});

	return record;
}

export function customTickersMapper2(tickers: ITickerDto[]): Record<string, ITicker> {
	const record = (tickers || []).reduce((rec, tickerDesc, idx) => {
		const ticker: ITicker = {
			id: `${TickerType.custom}/${tickerDesc.id}`,
			type: TickerType.custom,
			title: tickerDesc.name,
			name: tickerDesc.name,
			value: tickerDesc.value,
			order: idx + 100,
			icon: {
				// material by default
				fontSet: tickerDesc.iconSet === TickerIconSet.material ? undefined : tickerDesc.iconSet,
				name: tickerDesc.iconFromSet || '',
			},
			commands: customTickerCommandMapper(tickerDesc),
			badgeColor: tickerDesc.color === 'red' ? 'warn' : 'accent',
		};

		rec[ticker.id] = ticker;

		return rec;
	}, {});

	return record;
}

export function customTickerCommandMapper(ticker: ITickerDto): ITickerCommand[] {
	const commands: ITickerCommand[] = [];
	if ([TickerClickAction.openLink, TickerClickAction.openTasksList].some(act => act === ticker.clickAction)) {
		commands.push({
			action: 'url',
			type: 'click',
			payload: { url: ticker.url || ticker.gridUrl, clickAction: ticker.clickAction },
		});
	}
	return commands;
}

export const ngProviderTickers: Provider[] = [
	{
		provide: TickerProvider,
		useClass: TickerProviderImpl,
	},
];
