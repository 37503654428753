import * as common from './common';

export default {
	purple: {
		50: '#f3e5f5',
		100: '#e1bee7',
		200: '#ce93d8',
		300: '#ba68c8',
		400: '#ab47bc',
		500: '#9c27b0',
		600: '#8e24aa',
		700: '#7b1fa2',
		800: '#6a1b9a',
		900: '#4a148c',
		A100: '#ea80fc',
		A200: '#e040fb',
		A400: '#d500f9',
		A700: '#aa00ff',
		contrast: {
			50: common.black87,
			100: common.black87,
			200: common.black87,
			300: 'white',
			400: 'white',
			500: common.white87,
			600: common.white87,
			700: common.white87,
			800: common.white87,
			900: common.white87,
			A100: common.black87,
			A200: 'white',
			A400: 'white',
			A700: 'white',
		},
	},
};
