import { DataHttpService } from '@valhalla/data/http';
import { registerResource } from '@valhalla/localization';

export const resourceProviders = [
	registerResource({
		culture: 'ru-RU',
		namespace: 'common',
		factory: () => import(/* webpackPreload: true */ '../resources/ru-RU').then(m => m.default),
	}),
	registerResource({
		culture: 'en-US',
		namespace: 'common',
		factory: () => import('../resources/en-US').then(m => m.default),
	}),
	registerResource({
		culture: 'zh-CN',
		namespace: 'common',
		factory: () => import('../resources/zh-CN').then(m => m.default),
	}),
	registerResource({
		culture: 'ja-JP',
		namespace: 'common',
		factory: () => import('../resources/ja-JP').then(m => m.default),
	}),
	registerResource({
		culture: 'da-DK',
		namespace: 'common',
		factory: () => import('../resources/da-DK').then(m => m.default),
	}),
	registerResource({
		culture: 'de-DE',
		namespace: 'common',
		factory: () => import('../resources/de-DE').then(m => m.default),
	}),
	registerResource({
		culture: 'es-ES',
		namespace: 'common',
		factory: () => import('../resources/es-ES').then(m => m.default),
	}),
	registerResource({
		culture: 'fr-FR',
		namespace: 'common',
		factory: () => import('../resources/fr-FR').then(m => m.default),
	}),
	registerResource({
		culture: 'it-IT',
		namespace: 'common',
		factory: () => import('../resources/it-IT').then(m => m.default),
	}),
	registerResource({
		culture: 'pl-PL',
		namespace: 'common',
		factory: () => import('../resources/pl-PL').then(m => m.default),
	}),
	registerResource({
		culture: 'uz-Latn-UZ',
		namespace: 'common',
		factory: () => import('../resources/uz-Latn').then(m => m.default),
	}),
	registerResource({
		culture: 'ru-RU',
		namespace: 'agGrid',
		factory: () => import('../resources/ag-grid-ru-RU').then(m => m.default),
	}),
	registerResource({
		culture: 'en-US',
		namespace: 'agGrid',
		factory: () => import('../resources/ag-grid-en-US').then(m => m.default),
	}),
	registerResource({
		culture: 'zh-CN',
		namespace: 'agGrid',
		factory: () => import('../resources/ag-grid-zh-CN').then(m => m.default),
	}),
	registerResource({
		culture: 'ja-JP',
		namespace: 'agGrid',
		factory: () => import('../resources/ag-grid-ja-JP').then(m => m.default),
	}),
	registerResource({
		culture: 'da-DK',
		namespace: 'agGrid',
		factory: () => import('../resources/ag-grid-da-DK').then(m => m.default),
	}),
	registerResource({
		culture: 'de-DE',
		namespace: 'agGrid',
		factory: () => import('../resources/ag-grid-de-DE').then(m => m.default),
	}),
	registerResource({
		culture: 'es-ES',
		namespace: 'agGrid',
		factory: () => import('../resources/ag-grid-es-ES').then(m => m.default),
	}),
	registerResource({
		culture: 'fr-FR',
		namespace: 'agGrid',
		factory: () => import('../resources/ag-grid-fr-FR').then(m => m.default),
	}),
	registerResource({
		culture: 'it-IT',
		namespace: 'agGrid',
		factory: () => import('../resources/ag-grid-it-IT').then(m => m.default),
	}),
	registerResource({
		culture: 'pl-PL',
		namespace: 'agGrid',
		factory: () => import('../resources/ag-grid-pl-PL').then(m => m.default),
	}),
	registerResource({
		culture: 'uz-Latn-UZ',
		namespace: 'agGrid',
		factory: () => import('../resources/ag-grid-uz-Latn').then(m => m.default),
	}),
	registerResource({
		culture: 'ru-RU',
		namespace: 'serverLocalization',
		factory: (injector, culture) => {
			const server = injector.get(DataHttpService);
			return server.config.getLocalizations(culture);
		},
	}),
];
