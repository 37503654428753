import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ContextMenuModule } from '@spa/common/context-menu';
import { TippyModule } from '@ngneat/helipopper';
import { LocalizationModule } from '@spa/localization';
import { BadgeModule } from '@vh/core-components';
import { NavPanelAdminItemComponent } from './nav-panel-admin-item.component';
import { AutoOpenMenuModule } from '@spa/common/components/auto-open-menu';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		ContextMenuModule,
		TippyModule,
		LocalizationModule,
		BadgeModule,
		ContextMenuModule,
		AutoOpenMenuModule,
		MatMenuModule,
	],
	exports: [NavPanelAdminItemComponent],
	declarations: [NavPanelAdminItemComponent],
	providers: [],
})
export class NavPanelAdminItemModule {}
