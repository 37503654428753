import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SpaCommandExecutorFactory } from '../spa-command.contract';

const createCommand: SpaCommandExecutorFactory = injector => {
	const router = injector.get(Router);
	const zone = injector.get(NgZone);

	return function navigate(payload: {
		url: string;
		queryParams: Record<string, string>;
		commands?: any[];
		extras?: any;
	}) {
		if (!payload?.url && !payload.commands) {
			// tslint:disable-next-line:no-console
			return console.warn(
				`Spa command 'navigate' must have payload.url or payload.commands parameter in payload object`
			);
		}

		const commands = Array.isArray(payload?.commands) ? payload?.commands : [payload?.url];
		const extras = payload.extras
			? payload.extras
			: payload.queryParams
			? { queryParams: payload.queryParams }
			: undefined;

		return zone.runTask(() => router.navigate(commands, extras));
	};
};

export default createCommand;
