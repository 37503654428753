export function isObject(value): value is object {
	return !Array.isArray(value) && value !== null && typeof value === 'object';
}

export function isArray(value): value is Array<any> {
	return Array.isArray(value);
}

export function isFunction(value: any): value is Function {
	return typeof value === 'function';
}

export function isNumber(value: any): value is number {
	const check = value && typeof value.valueOf === 'function' ? value.valueOf() : value;
	return typeof check === 'number' && Number.isFinite(check);
}

export function isString(value): value is string {
	if (isObject(value)) return value instanceof String;

	return typeof value === 'string';
}

export function isBool(value): value is boolean {
	return typeof value === 'boolean';
}

export function isNullOrUndefined(value): value is null | undefined {
	return value === null || value === undefined;
}

export const check = {
	isObject,
	isArray,
	isFunction,
	isNumber,
	isString,
	isNullOrUndefined,
};

export function numberOrUndefined(v: any): number | undefined {
	return isNaN(+v) ? undefined : +v;
}

export const cast = {
	numberOrUndefined,
};
