<vh-modal-base
	[showClose]="true"
	[title]="dialogData?.windowLabel | resx: 'Изменить значение'"
	[okTitle]="dialogData?.saveBtn | resx: 'Сохранить'"
	(close)="close()"
	(cancel)="close()"
	(ok)="close(true)"
>
	<ng-container *ngIf="templateRef; then custom; else default"></ng-container>
</vh-modal-base>

<ng-template #default>
	<mat-form-field *ngIf="isMultiline; else oneLine">
		<mat-label>{{ dialogData?.inputLabel || '' }}</mat-label>

		<textarea
			#inp
			matInput
			[value]="dialogData?.value"
			cdkTextareaAutosize
			#autosize="cdkTextareaAutosize"
			cdkAutosizeMinRows="1"
			cdkAutosizeMaxRows="5"
		></textarea>
	</mat-form-field>
</ng-template>

<ng-template #oneLine>
	<mat-form-field>
		<mat-label>{{ dialogData?.inputLabel || '' }}</mat-label>

		<input #inp matInput [value]="dialogData?.value" [type]="dialogData?.inputType || 'text'" />
	</mat-form-field>
</ng-template>

<ng-template #custom>
	<ng-container *ngTemplateOutlet="templateRef; context: templateRefContext"></ng-container>
</ng-template>
