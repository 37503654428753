import { CdkPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable } from '@angular/core';
import { debounceTime, map, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PortalTargetService {
	protected readonly targetsStore = new Set<IPortalTarget>();
	protected readonly notifyUpdate$ = new Subject<Set<IPortalTarget>>();
	readonly update$ = this.notifyUpdate$.pipe(
		debounceTime(20),
		map(() => this.targets())
	);

	targets(name: string | string[] = '', key?: any) {
		const names = (Array.isArray(name) ? name : [name]).map(n => n?.toLowerCase());
		if (name) {
			return Array.from(this.targetsStore).filter(t =>
				names.some(n => {
					const targetNames = (Array.isArray(t.name) ? t.name : [t.name]).map(n => n?.toLowerCase());
					if (key) {
						return targetNames.includes(n) && t.key === key;
					}
					return targetNames.includes(n);
				})
			);
		}
		return Array.from(this.targetsStore);
	}

	reg(target: IPortalTarget) {
		this.targetsStore.add(target);
		this.notifyUpdate();
	}

	unreg(target: IPortalTarget) {
		this.targetsStore.delete(target);
		this.notifyUpdate();
	}

	notifyUpdate() {
		this.notifyUpdate$.next(this.targetsStore);
	}

	existsIntersect(opt: { a1: string | string[]; a2: string | string[] }): boolean {
		const aa = (Array.isArray(opt.a1) ? opt.a1 : [opt.a1]).map(n => n?.toLowerCase());
		const bb = (Array.isArray(opt.a2) ? opt.a2 : [opt.a2]).map(n => n?.toLowerCase());
		for (const a of aa) {
			for (const b of bb) {
				if (a === b) {
					return true;
				}
			}
		}
		return false;
	}
}

export interface IPortalTarget {
	name: string | string[];
	name$: Observable<string | string[]>;
	portalTargetRef: CdkPortal;
	isAttached?: boolean;
	key?: any;
	elRef?: ElementRef<HTMLElement>;
}
