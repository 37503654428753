import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MtfIframeService {
	isMtfFrame(frameEl: HTMLIFrameElement | HTMLFrameElement) {
		const win = frameEl?.contentWindow as any;
		return Boolean(win?.Mtf || win?.mtfSave);
	}

	isSyndicateFrame(frameEl: HTMLIFrameElement | HTMLFrameElement) {
		const win = frameEl?.contentWindow as any;
		return Boolean(win?.Syndicate);
	}

	findMtfIframes(doc = document, searchInSyndicates = true) {
		const frames = Array.from(doc.querySelectorAll<HTMLIFrameElement>('frame') || []);
		const iframes = Array.from(doc.querySelectorAll<HTMLIFrameElement>('iframe') || []);
		const accessible = [...frames, ...iframes].filter(this.canAccessIFrame);
		const mtfFrames = accessible.filter(frame => this.isMtfFrame(frame));
		if (searchInSyndicates) {
			const syndicates = accessible.filter(frame => this.isSyndicateFrame(frame));
			syndicates.forEach(sf => {
				const mtfInSyndicateFrames = this.findMtfIframes(sf.contentDocument, false);
				mtfFrames.push(...mtfInSyndicateFrames);
			});
		}
		return mtfFrames;
	}

	isMtfDirty(frameEl: HTMLIFrameElement | HTMLFrameElement) {
		const globalContextFrame = frameEl?.contentWindow as any;
		let result = globalContextFrame?.PreventCloseOnDirty?._formIsDirty();
		if (typeof globalContextFrame?.PreventCloseOnDirty?._customCheckFunction === 'function') {
			result = result || globalContextFrame?.PreventCloseOnDirty?._customCheckFunction();
		}
		if (typeof globalContextFrame?.PreventCloseOnDirty?._radIsDirty === 'function') {
			result = result || globalContextFrame?.PreventCloseOnDirty?._radIsDirty();
		}
		return result;
	}

	canAccessIFrame(iframe: HTMLIFrameElement | HTMLFrameElement) {
		// @see https://stackoverflow.com/questions/12381334/foolproof-way-to-detect-if-iframe-is-cross-domain#answer-12381504
		let html = null;
		try {
			// deal with older browsers
			const doc = iframe.contentDocument || iframe.contentWindow.document;
			html = doc.body.innerHTML;
		} catch (err) {
			// do nothing
		}

		return html !== null;
	}
}
