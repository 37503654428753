import { Id } from '@valhalla/utils';
import { IAction, produce, ReducerBase } from '@valhalla/core';

import { ITicker } from '../../ticker.model';
import * as actions from '../actions';
import { ITickersState } from '../state';

export class UpdateTickersReducer implements ReducerBase {
	readonly actionType = actions.FacadeTickersAction.updateTickers;

	reduce(state: ITickersState, action: IAction<Record<Id, ITicker> | Array<ITicker>>) {
		if (!Boolean(action.payload)) {
			return state;
		}
		return produce(state, ds => {
			const tickers = action.payload;
			if (Array.isArray(tickers)) {
				tickers.forEach(ticker => {
					ds[ticker.id] = ticker;
				});
			} else {
				ds.tickers = {
					...ds.tickers,
					...tickers
				};
			}
		});
	}
}
