import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { SearchBarCommonComponent } from './search-bar.component';
import { AutoFocusModule } from '@spa/common/directives';
import { LocalizationModule } from '@spa/localization';
import { TippyModule } from '@ngneat/helipopper';

@NgModule({
	declarations: [SearchBarCommonComponent],
	imports: [CommonModule, RouterModule, MatIconModule, AutoFocusModule, LocalizationModule, TippyModule],
	exports: [SearchBarCommonComponent],
})
export class SearchBarCommonModule {}
