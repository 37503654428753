import EventEmitter from 'events';
import { Observable } from 'rxjs';

export abstract class ProviderCalendarDataHttpServiceBase {
	abstract userConfig(): Observable<IUserCalendarConfig>;
	abstract get<T = ICalendarEvent>(key: string, ownerUserId?: number): Observable<T>;
	abstract create<T = ICalendarEvent>(calendarEvent: T, ownerUserId?: number): Observable<string>;
	abstract update<T = ICalendarEvent>(calendarEvent: T, ownerUserId?: number): Observable<string>;
	abstract delete(key, ownerUserId?: number): Observable<unknown>;
	abstract executeAction(req: IActionReq): Observable<ICalendarEvent>;
	abstract resolveName(name: string, meetingKey?: string): Observable<ICalendarRecipient[]>;
	abstract getMessages(req: ICalendarMessagesReq): Observable<ICalendarMessagesRes[]>;
	abstract getUnreadMessagesCount(req: ICalendarMessagesCountReq): Observable<number>;
	abstract getAgenda(req: IGetAgendaReq): Observable<IAgendaRes[]>;
	onUnreadMessageCountChanged$: EventEmitter;
}

export interface IAgendaRes {
	day: string;
	events?: any[];
	providersEvents?: IProvidersEvents[];
	reminders?: any[];
	signatures?: any[];
	tasks?: any[];
}

export interface IUserCalendarConfig {
	providerName: string;
	enableRecurrence: boolean;
}

export interface IProvidersEvents {
	key: string;
	title: string;
	location: string;
	start: string;
	end: string;
	backgroundColor: string;
	textColor: string;
	isCanceled?: boolean;
	actions?: any;
	hasAttachments?: any;
	appointmentType?: any;
	organizer?: any;
	freeBusyState?: any;
	myResponseType?: any;
}
export type GetAgendaIncludes = 'ProvidersEvents' | 'Events' | 'Tasks';
export interface IGetAgendaReq {
	userId: number;
	from: string;
	to: string;
	includes?: Array<GetAgendaIncludes>;
	subcatIds?: number[];
	catIds?: number[];
	isPerformerOrdered?: boolean;
	isResponsibleOrdered?: boolean;
	isMailReminder?: boolean;
	isSignatureOrdered?: boolean;
	isPlannedStart?: boolean;
	isTaskCreation?: boolean;
}

export interface ICalendarMessagesCountReq {
	dateFrom?: Date;
	dateTo?: Date;
}
export interface ICalendarMessagesReq {
	dateFrom?: Date;
	dateTo?: Date;
	limit?: number;
	offset?: number;
	meetingKey?: string;
}

export interface ICalendarMessagesRes {
	proposedEnd: string | Date;
	proposedStart: string | Date;
	type: ICalendarMessagesType;
	meetingKey: string;
	receivedDate: string | Date;
	subject: string;
	from: {
		id: number;
		key: string;
		displayName: string;
		routingType: string;
		type: ICalendarEventRecipientType;
		userId: number;
		email: string;
		hasAvatar: boolean;
		userAvatarFile: {
			fileId: number;
			latestVersionId: number;
		};
	};
	messageKey: string;
	isRead: boolean;
	isNewTimeProposed?: boolean;
}

export enum ICalendarEventRecipientType {
	unknown = 'Unknown',
	email = 'Email',
	user = 'User',
	meetingGroup = 'MeetingGroup',
	oneOff = 'OneOff',
	mailbox = 'Mailbox',
	publicFolder = 'PublicFolder',
	publicGroup = 'PublicGroup',
	contactGroup = 'ContactGroup',
	contact = 'Contact',
	groupMailbox = 'GroupMailbox',
}

export enum ICalendarMessagesType {
	canceled = 'Canceled',
	request = 'Request',
	negative = 'Negative',
	positive = 'Positive',
	tentative = 'Tentative',
}

export interface IPublicationCriteria {
	alias: string;
	type: string;
	data?: any;
	queryParams?: Record<string, string>;
}

export interface IAvatar {
	fileId: number;
	latestVersionId: number;
}

export interface ICalendarRecipient {
	key: string;
	type: string;
	userId?: number;
	email?: string;
	displayName: string;
	hasAvatar: boolean;
	userAvatarFile?: IAvatar;
}

export interface ICalendarEvent {
	lastModified?: Date;
	type?: string;
	organizer?: ICalendarRecipient;
	attendees?: ICalendarRecipient[];
	requiredAttendees: ICalendarRecipient[];
	subject: string;
	textBody: string;
	bodyType?: string;
	start: Date | string;
	end: Date | string;
	isRecurring?: boolean;
	parentKey?: string;
	key: string;
	masterKey?: string;
	taskId?: number;
	linkedTaskId: number;
	isAllDayEvent: boolean;
	isReadonly?: boolean;
	location: string;
	state?: string;
	myResponseType?: IMyResponseType;
	freeBusyState: IFreeBusyState;
	actions?: ICalendarEventActions[];
	ownerUserId?: number;
	attachments?: ICalendarEventAttachment[];
	recurrence?: ICalendarEventRecurrence;
	parentId?: number;
	title?: string;
	repeat?: number;
	description?: string;
}

export interface ICalendarEventRecurrence {
	dayOfMonth: number;
	daysOfWeek: ICalendarEventDaysOfWeek[];
	endDate: Date | string;
	interval: number;
	month: ICalendarEventDayOfMonth | number;
	numberOfOccurrences: number;
	recurrenceType: ICalendarEventRecurrenceType;
	startDate: Date | string;
	week: ICalendarEventWeek;
}

export enum ICalendarEventDayOfMonth {
	January = 'January',
	February = 'February',
	March = 'March',
	April = 'April',
	May = 'May',
	June = 'June',
	July = 'July',
	August = 'August',
	September = 'September',
	October = 'October',
	November = 'November',
	December = 'December',
}

export enum ICalendarEventWeek {
	First = 'First',
	Second = 'Second',
	Third = 'Third',
	Fourth = 'Fourth',
	Last = 'Last',
}

export enum ICalendarEventDaysOfWeek {
	Sunday = 'Sunday',
	Monday = 'Monday',
	Tuesday = 'Tuesday',
	Wednesday = 'Wednesday',
	Thursday = 'Thursday',
	Friday = 'Friday',
	Saturday = 'Saturday',
}

export enum ICalendarEventRecurrenceType {
	DailyPattern = 'DailyPattern',
	YearlyPattern = 'YearlyPattern',
	RelativeYearlyPattern = 'RelativeYearlyPattern',
	RelativeMonthlyPattern = 'RelativeMonthlyPattern',
	MonthlyPattern = 'MonthlyPattern',
	WeeklyPattern = 'WeeklyPattern',
	never = 0,
}

export interface ICalendarEventAttachment {
	contentType: string;
	fileId: number;
	key: string;
	name: string;
	preUploadedFileId: number;
	size: number;
}

export interface IActionReq {
	key: string;
	action: ICalendarEventActions;
	args?: any;
}

export enum ICalendarEventActions {
	accept = 'Accept',
	cancel = 'Cancel',
	decline = 'Decline',
	acceptTentatively = 'AcceptTentatively',
	acceptTentativeWithNewTime = 'AcceptTentativeWithNewTime',
	declineWithNewTime = 'DeclineWithNewTime',
	read = 'Read',
}

export enum IFreeBusyState {
	free = 'Free',
	tentative = 'Tentative',
	busy = 'Busy',
	oof = 'OOF',
	workingElsewhere = 'WorkingElsewhere',
	noData = 'NoData',
}

export enum IMyResponseType {
	unknown = 'Unknown',
	organizer = 'Organizer',
	tentative = 'Tentative',
	accept = 'Accept',
	decline = 'Decline',
	noResponseReceived = 'NoResponseReceived',
}
