/* eslint-disable @typescript-eslint/member-ordering */
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	inject,
	Input,
	OnChanges,
	ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IIconColor, IIconColorMap, IIconSize, IIconSizeMap } from './icon.model';
import { uiIcons } from '@spa/bootstrap/register-icons';

@Component({
	selector: 'vh-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges, AfterViewInit {
	readonly elRef = inject<ElementRef<HTMLElement>>(ElementRef);

	@Input()
	set name(val: string) {
		this.name$.next(val);
	}

	get name() {
		return this.name$.value;
	}

	@Input()
	set size(val: IIconSize) {
		this.size$.next(val);
	}

	get size() {
		return this.size$.value;
	}

	@Input()
	set fill(val: 'filled' | 'lined') {
		this.fill$.next(val);
	}

	get fill() {
		return this.fill$.value;
	}

	@Input()
	color: IIconColor = IIconColor.onSurfacePrimary;

	@HostBinding('style.--icon-color')
	colorFromMap: string;

	@HostBinding('style.--icon-size')
	sizeFromMap: string;

	@ViewChild('UIIconWrapper') UIIconWrapper: ElementRef<HTMLDivElement>;

	svgData: string;

	readonly name$ = new BehaviorSubject<string>(null);
	readonly size$ = new BehaviorSubject<IIconSize>(IIconSize.md);
	readonly fill$ = new BehaviorSubject<'filled' | 'lined'>(null);

	ngOnChanges(): void {
		this.getIconColorFromMap();
		this.getIconSizeFromMap();
		this.svgData = this.getSvgData(this.name, this.size, this.fill);
		this.updateIconColor();
	}

	ngAfterViewInit(): void {
		this.updateIconColor();
	}

	updateIconColor(): void {
		if (this.UIIconWrapper?.nativeElement) {
			const el = this.UIIconWrapper.nativeElement;
			el.querySelectorAll('path').forEach(item => {
				if (item.hasAttribute('fill')) {
					item.setAttribute('fill', 'currentColor');
				}
				if (item.hasAttribute('stroke')) {
					item.setAttribute('stroke', 'currentColor');
				}
			});
		}
	}

	getSvgData(iconName: string, size: IIconSize, fill: 'filled' | 'lined'): string {
		const filledName = `${iconName}-${fill}`;
		const icon = uiIcons.find(i => filledName in i) || uiIcons.find(i => iconName in i);
		const placeholderIcon = uiIcons.find(i => 'cross' in i)['cross'];
		if (!icon) {
			return '';
		}
		if (icon[iconName]) {
			return icon[iconName][size].data || placeholderIcon[size].data;
		}
		return icon[filledName][size].data || placeholderIcon[size].data;
	}

	getIconColorFromMap(): void {
		this.colorFromMap = IIconColorMap[this.color];
	}

	getIconSizeFromMap(): void {
		this.sizeFromMap = IIconSizeMap[this.size];
	}
}
