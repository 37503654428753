// function colorMapToClass(hexColor: string): string {
// 	const colorMap = {
// 		'#3399CC': 'blue-50',
// 		'#666699': 'deep-purple-50',
// 		'#99CC33': 'teal-50',
// 		'#339966': 'green-50',
// 		'#FFCC33': 'orange-50',
// 		'#FF3333': 'red-50',
// 	};
// 	const clazz = (hexColor && colorMap[hexColor]) || '';
// 	return clazz;
// }

function colorMapToRGB(hexColor: string): string {
	// цвета, которые были в старом МТФ. Их мапим на переменные из дизайн системы
	const colorMap = {
		'#3399CC': 'var(--extramarkers-blue)',
		'#1B6CF8': 'var(--extramarkers-blue)',
		'#666699': 'var(--extramarkers-purple)',
		'#9539E5': 'var(--extramarkers-purple)',
		'#99CC33': 'var(--extramarkers-green)',
		'#21A04B': 'var(--extramarkers-green)',
		'#339966': 'var(--extramarkers-cyan)',
		'#0094AB': 'var(--extramarkers-cyan)',
		'#FFCC33': 'var(--extramarkers-yellow)',
		'#997706': 'var(--extramarkers-yellow)',
		'#FF3333': 'var(--extramarkers-red)',
		'#F31B2A': 'var(--extramarkers-red)',
	};
	const clazz = (hexColor && colorMap[hexColor]) || hexColor || '';
	return clazz;
}

export const vhColorClasses = {
	//colorMapToClass,
	colorMapToRGB,
};
