import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class RootRegistrationService {
	public services = new Map();

	register(service: any, name: string) {
		this.services.set(name, service);
	}

	get<T>(name: string): T {
		return this.services.get(name) as T;
	}
}
