import { IOrgUnits, IUserProfile } from '@valhalla/data/entities';

export type Id = string | number;

export interface ITreeItem {
	id: Id;
	name?: string;
	parentId?: Id;
	$id?: number;
	nodeType?: string;
	selected?: boolean;
	checked?: boolean;
	indeterminate?: boolean;
	data?: any;
	alreadyLoadedChildren?: boolean;
	hidden?: boolean;
	isSpace?: boolean;
	nodeIcon?: string;
	availableForSelect?: boolean;
}

export interface ITree<T extends ITreeItem = ITreeItem> extends ITreeItem {
	children?: ITree[];
}

export function recursiveFlat(treeIn: ITree): ITreeItem[] {
	const root = { ...treeIn };
	delete root.children;
	if (treeIn.children) {
		return [root, ...treeIn.children.reduce((acc, cur) => [...acc, ...recursiveFlat(cur)], [])];
	}
	return [root];
}

// TODO: need tests!
export function flatTrees(trees: ITree[]): ITreeItem[] {
	return trees?.reduce((acc, cur) => [...acc, ...recursiveFlat(cur)], []);
}

export function exceptItems<I = any>(
	except: ITreeItem[],
	items: ITreeItem[],
	idGetter?: (item: I) => Id,
	parentIdGetter?: (item: I) => Id
): ITreeItem[] {
	return items.filter(
		item =>
			!except.some(exc => (idGetter ? idGetter(exc as any) : exc.id) === (idGetter ? idGetter(item as any) : item.id))
	);
}

export function buildTree<I = any>(
	root: ITree,
	items: ITreeItem[],
	idGetter?: (item: I) => Id,
	parentIdGetter?: (item: I) => Id
): ITree {
	const filterChild = (item: ITreeItem) => {
		const parent = root;
		const itemParentId = parentIdGetter ? parentIdGetter(item as any) : item.parentId;
		const parentId = idGetter ? idGetter(parent as any) : parent.id;
		const isChild = itemParentId === parentId;
		return isChild;
	};
	root.children = items.filter(filterChild);
	const otherItems = exceptItems(root.children, items, idGetter, parentIdGetter);
	root.children.forEach(child => buildTree(child, otherItems, idGetter, parentIdGetter));
	return root;
}

export function buildTrees<I extends ITreeItem, T extends ITree>(
	items: I[],
	idGetter?: (item: I) => Id,
	parentIdGetter?: (item: I) => Id
): T[] {
	const roots = items.filter(item => (parentIdGetter ? !parentIdGetter(item) : !item.parentId));
	const notRoots = exceptItems(roots as any, items, idGetter, parentIdGetter);
	const trees = roots.reduce((acc, cur) => [...acc, buildTree(cur, notRoots, idGetter, parentIdGetter)], []);
	return trees;
}

export function buildTreeImmutable<I extends ITreeItem, M extends ITree<I>>(record: Record<string, I>): M[] {
	const menus: M[] = [];

	const createMenu: (i: I) => M = item => <any>{ id: item.id, parentId: item.parentId, children: [] };

	const items = Object.values(record);
	(items || []).forEach(item => {
		if (!Boolean(item.parentId)) {
			menus.push(createMenu(item));
		}
	});

	const fillMenu: (menu: M) => M = menu => {
		const children = items.filter(item => item.parentId === menu.id);
		const fillChildren = children.map(createMenu).map(fillMenu);
		menu.children = fillChildren;
		return menu;
	};

	return menus.map(menu => fillMenu(menu));
}

const sortComparator = (nodeA: ITreeItem, nodeB: ITreeItem) => nodeA.name.localeCompare(nodeB.name);

export function sortTreeRecursive(treeNode: ITree<ITree>[]) {
	const sortedTreeNode = treeNode.sort(sortComparator);
	sortedTreeNode.forEach(node => {
		if (node?.children?.length) {
			node.children = node.children.sort(sortComparator);
			sortTreeRecursive(node.children);
		}
	});
	return sortedTreeNode;
}

export function buildTreeFromOrgUnits(nodes: IOrgUnits[]): ITree[] {
	const nodesMap = Object.assign({}, ...nodes.map(t => ({ [t.id]: Object.assign(t, { children: [] }) })));

	const treeValues = sortOrgUnitsNodes(Object.values(nodesMap));
	const tree = treeValues.filter(t => {
		const parentId = t?.parentId;

		return !(parentId && nodesMap[parentId]?.children.push(t));
	});

	return tree;
}

export function sortOrgUnitsNodes(nodesTree: any[]) {
	const sortByOrder = nodesTree?.sort((a, b) => {
		if (a.order < b.order) {
			return -1;
		}
		if (a.order > b.order) {
			return 1;
		}
		return 0;
	});

	const sortByText = sortByOrder.sort((a, b) => {
		const aText = String(a?.text)?.toLowerCase();
		const bText = String(b?.text)?.toLowerCase();

		if (aText < bText) {
			return -1;
		}
		if (aText > bText) {
			return 1;
		}
		return 0;
	});

	return sortByText;
}

export function getTreePathStrings(tree: ITree[], parentPath = ''): string[] {
	let pathStrings: string[] = [];

	for (const node of tree) {
		const currentPath = `${parentPath} - ${node.name}`;

		if (node.children && node.children.length > 0) {
			const childPaths = getTreePathStrings(node.children, currentPath);
			pathStrings = pathStrings.concat(childPaths);
		} else {
			pathStrings.push(currentPath.replace(/ - /, ''));
		}
	}

	return pathStrings;
}

export function getPrimaryOrgUnit(userInfo: IUserProfile, getFullPath = false): string {
	if (!userInfo?.userOrgUnits?.length) {
		return '';
	}
	const primaryOrgUnit = userInfo?.userOrgUnits.find(i => i?.isPrimary);
	if (!primaryOrgUnit?.units?.length) {
		return '';
	}
	const orgUnitsTree = buildTreeFromOrgUnits(primaryOrgUnit.units);
	const paths = getTreePathStrings(orgUnitsTree);
	if (getFullPath) {
		return paths[0];
	}
	const primaryOrgUnitIndex = paths[0].split(' - ').length - 2;
	const primaryOrgUnitStr = paths[0].split(' - ')[primaryOrgUnitIndex];
	return primaryOrgUnitStr;
}

export function getOrgUnits(orgUnits: IOrgUnits[]): { job: string; orgUnit: string } {
	if (!orgUnits?.length) {
		return null;
	}
	const orgUnitsTree = buildTreeFromOrgUnits(orgUnits);
	const paths = getTreePathStrings(orgUnitsTree);
	const orgUnitIndex = paths[0].split(' - ').length - 1;
	const jobIndex = paths[0].split(' - ').length - 2;
	const orgUnit = paths[0].split(' - ')[orgUnitIndex];
	const job = paths[0].split(' - ')[jobIndex];
	return { job, orgUnit };
}

export function getOrgUnitsFullPathString(orgUnits: IOrgUnits[]): string {
	if (!orgUnits?.length) {
		return '';
	}
	const orgUnitsTree = buildTreeFromOrgUnits(orgUnits);
	const paths = getTreePathStrings(orgUnitsTree);
	return paths[0];
}
