import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IUserShort } from '@spa/data/entities';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IAddUnrequiredSignatures } from '.';
import { IApiResponse } from '../api-response';
import { ApiVersion } from '../api-versions';
import { DataHttpCommonService } from '../data-http-common.service';
import { EndpointUrlConfig } from '../endpoint.config';
import {
	EdsPluginType,
	IAddQuickReplyCriteria,
	IDSSCertInfoCriteria,
	IDSSUserCredentialsCriteria,
	IDynamicSignature,
	IDynamicSignaturesReq,
	IEdsDssInfo,
	IEpsSignature,
	IReqDynamicSignature,
	IRevokeTaskSignaturesParams,
	ISignatureDueDateChangeCriteria,
	SignaturesHttpService,
} from './abstract';

import type { IEndpointUrlConfig } from '../endpoint.config';
import moment from 'moment';
import { excludeUndefinedProps } from '@valhalla/utils';
@Injectable()
export class SignaturesHttpServiceImpl implements SignaturesHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService
	) {}
	getEdsTypes(taskId: number): Observable<EdsPluginType> {
		const url = this.common.getApiUrl(`tasks/Signature/edssettings/${taskId}`, ApiVersion.v10);
		return this.http.get<EdsPluginType>(url).pipe(
			map(result => result),
			catchError(err => {
				console.error(err);
				return of(null);
			})
		);
	}

	getEdsInfoFiles(taskSignatureId: number): Observable<IEpsSignature> {
		const url = this.common.getApiUrl(`tasks/Signature/${taskSignatureId}/eps/info`, ApiVersion.v10);
		return this.http.get<IEpsSignature>(url).pipe(map(result => result));
	}

	getQuickReplies(taskId: number | string): Observable<string[]> {
		const url = this.common.getApiUrl(`users/current/quickreplies?withDueReasonForTaskId=${taskId}`, ApiVersion.v10);
		return this.http.get<any>(url).pipe(
			map(result => result),
			catchError(err => {
				console.error(err);
				return of(null);
			})
		);
	}

	changeSignatureDueDate(obj: ISignatureDueDateChangeCriteria): Observable<any> {
		const url = this.common.getApiUrl(`tasks/signatures/${obj?.taskSignatureId}/change-duedate`);
		return this.http.post(url, {
			newDueDate: obj?.newDate ? moment(obj?.newDate, 'DD.MM.YYYY HH:mm') : obj?.newDueDate,
			reason: obj?.reason,
			location: obj?.location,
		});
	}

	addQuickReply(obj: IAddQuickReplyCriteria) {
		const url = this.common.getApiUrl(`tasks/signatures/add-quick-replay`);
		return this.http.post(url, obj);
	}

	getDynamicSignatures(req: IDynamicSignaturesReq): Observable<IDynamicSignature[]> {
		const url = this.common.getApiUrl(`signatures/dynamic`);
		return this.http
			.get<IApiResponse<IDynamicSignature[]>>(url, {
				params: excludeUndefinedProps({
					taskId: req?.taskId,
					acceptantId: req?.acceptantId,
				}),
			})
			.pipe(map(res => res.data));
	}

	reqDynamicSignature(req: IReqDynamicSignature): Observable<any> {
		const url = this.common.getApiUrl(`signatures/dynamic/request`);
		return this.http.post(url, req);
	}

	getPossibleAcceptants(taskId: number, signatureId: number): Observable<IUserShort[]> {
		const url = this.common.getApiUrl(`signatures/dynamic/${signatureId}/acceptors?taskId=${taskId}`);
		return this.http.get<IApiResponse<IUserShort[]>>(url).pipe(map(res => res.data));
	}

	revokeTaskSignatures(params: IRevokeTaskSignaturesParams): Observable<any> {
		const url = this.common.getApiUrl(`mobile/tasks/${params?.taskId}/revokeTaskSignatures`, ApiVersion.v12);
		return this.http.post(url, params);
	}

	addUnrequiredSignatures(obj: IAddUnrequiredSignatures) {
		const url = this.common.getApiUrl(`tasks/signatures/add-unrequired-signatures/${obj?.taskId}`);

		return this.http.post(url, {
			signaturesList: obj?.SignaturesList ? obj?.SignaturesList : obj?.signaturesList,
		});
	}

	changeTaskStepWithoutUnrequiredSignatures(taskId: number) {
		const url = this.common.getApiUrl(`tasks/${taskId}/change-step-without-unrequired-signatures`);
		return this.http.post(url, {
			taskId,
		});
	}

	getDSSCertificates(criteria: IDSSUserCredentialsCriteria): Observable<any> {
		const url = this.common.getApiUrl(`getCertificates`, ApiVersion.v12);

		return this.http.post(url, criteria);
	}

	processSignatureOperation(criteria: IDSSCertInfoCriteria): Observable<any> {
		const url = this.common.getApiUrl(`processsignatureoperation/${criteria.taskSignatureId}`, ApiVersion.v12);

		return this.http.post(url, criteria);
	}

	rejectTransaction(taskSignatureId: number): Observable<any> {
		const url = this.common.getApiUrl(`dsstransaction/rejecttransaction/${taskSignatureId}`, ApiVersion.v12);

		return this.http.get(url);
	}

	getDssDocumentsWithoutTransaction(criteria: IDSSCertInfoCriteria): Observable<any> {
		const url = this.common.getApiUrl(`getdocumentswithouttransaction/${criteria.taskSignatureId}`, ApiVersion.v12);

		return this.http.post(url, criteria);
	}

	getDssDocuments(criteria: IDSSCertInfoCriteria): Observable<any> {
		const url = this.common.getApiUrl(`dsstransaction/getdocuments/${criteria.taskSignatureId}`, ApiVersion.v12);

		return this.http.post(url, criteria);
	}

	getTransactionState(taskSignatureId: number): Observable<any> {
		const url = this.common.getApiUrl(`dsstransaction/getstate/${taskSignatureId}`, ApiVersion.v12);

		return this.http.get(url);
	}

	sendTransaction(criteria: IDSSCertInfoCriteria): Observable<any> {
		const url = this.common.getApiUrl(`dsstransaction/send/${criteria.taskSignatureId}`, ApiVersion.v12);

		return this.http.post(url, criteria);
	}

	getEdsFileHistory(fileId: number, versionId?: number): Observable<any> {
		const url = this.common.getApiUrl(`filestorage/file/${fileId}/version/${versionId || 1}/edsInfo`, ApiVersion.v12);

		return this.http.get(url);
	}

	getDssInfo(): Observable<IEdsDssInfo> {
		const url = this.common.getApiUrl(`getdssinfo`, ApiVersion.v12);

		return this.http.get<IEdsDssInfo>(url);
	}

	confirmChoice(taskSignatureId: number, choice: string): Observable<any> {
		const url = this.common.getApiUrl(`dss/confirm-choice/${taskSignatureId}`, ApiVersion.v12);

		return this.http.post<any>(url, { confirmedChoice: choice });
	}
}
