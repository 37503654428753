<div
	class="vh-category-item--name"
	[class.no-collapsable]="!isChildren(categoryItem)"
	[tippy]="mobileService.mobileMode ? null : categoryItem?.name"
	[delay]="300"
	(click)="onClickCategory(categoryItem)"
>
	<mat-icon
		*ngIf="isChildren(categoryItem)"
		class="collapsable-arrow-icon"
		[svgIcon]="(isOpen$ | async) ? 'vh-arrow-v1-S-down-24' : 'vh-arrow-v1-S-right-24'"
	></mat-icon>
	<span>{{ categoryItem?.name }}</span>
</div>

<div class="vh-category-item--children" *ngIf="(isOpen$ | async) && isChildren(categoryItem)">
	<div *ngFor="let childItem of sortChildren(categoryItem?.children)">
		<vh-category-item
			*ngIf="childItem.nodeType === CategoryTreeNodeType.category ? childItem?.children?.length : childItem"
			[categoryItem]="childItem"
			(clickItem)="clickItem.emit($event)"
			[allOpened]="allOpened"
		></vh-category-item>
	</div>
</div>
