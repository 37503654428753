import { ModuleWithProviders, NgModule } from '@angular/core';

import { USER_SETTINGS_PROVIDERS } from './ng-providers';

@NgModule()
export class UserSettingsModule {
	static forRoot(): ModuleWithProviders<UserSettingsModule> {
		return {
			ngModule: UserSettingsModule,
			providers: USER_SETTINGS_PROVIDERS,
		};
	}
}
