import { DOCUMENT, registerLocaleData } from '@angular/common';
import { ApplicationRef, Inject, Injectable, Injector, LOCALE_ID, NgZone, Optional } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatomoService } from '@spa/analytics';
import { initializeApi } from '@spa/api';
import { SpaApiEvents, spaDispatchEvent } from '@spa/api/events';
import { extendsDomElementsWhenAppBootstrapped } from '@spa/built-in-dom-elements-extend/when-app-boostrapped';
import { ReactionsService } from '@spa/common/components/feed-comments/comment-reactions/reactions.service';
import { IsDev } from '@spa/common/services/is-dev';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { MtfIframeService } from '@spa/common/services/mtf-iframes.service';
import { OpenNewsService } from '@spa/common/services/open-news.service';
import { OpenPortalService } from '@spa/common/services/open-portal.service';
import { TabActiveStateService } from '@spa/common/services/tab-active-state.service';
import { DataHttpService, TimeoutHttpInterceptorSettings } from '@spa/data/http';
import { UserSettingsFacadeProvider } from '@spa/facade/features/user-settings';
import { BeforeUnloadService } from '@spa/guards/before-unload.service';
import {
	AbstractLogger,
	AppBootstrapEvent,
	AppVersionService,
	ASSET_INVALIDATE_KEY,
	AuthService,
	ConfigurationProvider,
	CookieService,
	EventBusService,
	LoggerFactory,
	PlatformDetectorProvider,
	SessionUser,
	SystemCookies,
	UrlProvider,
	WINDOW,
} from '@valhalla/core';
import { SignalrProvider } from '@valhalla/data/signalr';
import { CultureService } from '@valhalla/localization';
import { booleanFilter } from '@valhalla/utils';
import { EMPTY, fromEvent, merge, Observable, of } from 'rxjs';
import {
	catchError,
	concatMap,
	debounceTime,
	distinctUntilChanged,
	exhaustMap,
	filter,
	mergeMap,
	switchMap,
	take,
} from 'rxjs/operators';
import * as packageJson from '../../../../../package.json';
import { patch } from '../app-patch';
import { initializeGlobalEventHandlers } from '../global-event-handlers/initialize';
import { UnsaveChangesService } from '../guards/unsave-changes.service';
import { LazyLocale, LazyLocaleType } from './lazy-ng-locale';
import { formaIcons } from './register-icons';
import { JitsiService } from '@spa/facade/jitsi.service';
import { CronService } from '@spa/cron';
import numeral from 'numeral';

/**
 * Service has one method 'run()' called after bootstrap application
 */
@Injectable()
export class PostBootstrapService {
	constructor(
		private readonly _events: EventBusService,
		private readonly _platformDetector: PlatformDetectorProvider,
		loggerService: LoggerFactory,
		@Inject(WINDOW) private readonly _window: Window,
		@Inject(DOCUMENT) private readonly _document: Document,
		readonly auth: AuthService,
		readonly config: ConfigurationProvider,
		@Optional() private readonly _animationModule: BrowserAnimationsModule,
		readonly cookies: CookieService,
		protected zone: NgZone,
		readonly versionService: AppVersionService,
		readonly iconRegistry: MatIconRegistry,
		readonly sanitizer: DomSanitizer,
		readonly url: UrlProvider,
		readonly injector: Injector,
		@Inject(LazyLocale) readonly lazyLocaleNgModule: LazyLocaleType,
		@Inject(LOCALE_ID) readonly localeId: string,
		readonly apiEvents: SpaApiEvents,
		readonly signal: SignalrProvider,
		readonly openNewsService: OpenNewsService,
		readonly openPortalService: OpenPortalService,
		readonly sessionUserSettings: UserSettingsFacadeProvider,
		readonly sessionUser: SessionUser,
		readonly mtfFrame: MtfIframeService,
		readonly unsavedChanges: UnsaveChangesService,
		readonly culture: CultureService,
		@Inject(IsDev) readonly isDev: boolean,
		@Inject(ASSET_INVALIDATE_KEY) readonly ASSET_INVALIDATE_KEY: string,
		readonly tabActiveStateService: TabActiveStateService,
		readonly appRef: ApplicationRef,
		readonly beforeUnload: BeforeUnloadService,
		readonly mobileView: MobileViewService,
		readonly reactionsService: ReactionsService,
		readonly matomo: MatomoService
	) {
		this._logger = loggerService.createLogger('AppBootstrapService');
		patch(appRef);
	}

	private _bootstrapped = false;
	private readonly _logger: AbstractLogger;

	run() {
		if (this._bootstrapped) throw new Error('duplicate call runAfterBootstrap method, this method must call only once');

		this._bootstrapped = true;
		Object.defineProperty(window, '__IS_1F_SPA_BOOT__', {
			get() {
				return true;
			},
		});

		// #region  logic after bootstrap
		this.applyMetaTags();
		this.applyHttpConfiguration();
		this.matomo.init();
		this.tabActiveStateService.init();
		this.setupLocale();
		this.addAppVersionInfo();
		this.addSpecificCookies();
		this.runPlatformSpecificCode(this._platformDetector);
		this.prepareConfiguration();
		// this.checkAuthentication();
		this.registerCustomSvgIcons();
		this.registerFormaIcons(this.iconRegistry);
		window['registerFormaIcons'] = this.registerFormaIcons.bind(this);
		extendsDomElementsWhenAppBootstrapped(this.injector);
		const disposeGlobalEventHandlers = initializeGlobalEventHandlers(this.injector);
		this.setupEvents();
		this.openNewsService.registerCommandOpenNews();
		this.openPortalService.registerCommandOpenPortal();
		// #endregion

		this._events.createAndSend(AppBootstrapEvent);

		this.removeMatIconsHackStyle();
		this.loadSessionUserSetting();
		this.updateSessionUserOnProfileChanged();
		this.checkMtfHasUnsavedChanges();
		this.updateTabsOnSignal();
		this.beforeUnload.subscribe();
		this.afterAuth();
		this.saveAndRestoreLastActiveInput();
	}

	loadSessionUserSetting() {
		this.auth.authenticated$.pipe(booleanFilter(), take(1)).subscribe(() => {
			this.sessionUser.update();
			this.sessionUserSettings.loadUserSettings();
			this.reactionsService.updateAllowedReactions();
		});
	}

	protected removeMatIconsHackStyle() {
		const removeHack = () => {
			try {
				document.querySelector('#mat-icons-initial-hack').remove();
			} catch (err) {
				console.error(err);
			}
		};
		// перешели на свои svg иконки - сразу убираем
		setTimeout(() => {
			removeHack();
		});
		// let tries = 0;
		// const fontsApi = (document as any)?.fonts;
		// const isMatIconsLoaded = () => fontsApi.check(`1rem 'Material Icons'`);
		// if (fontsApi && !isMatIconsLoaded()) {
		// 	const interval = setInterval(() => {
		// 		if (isMatIconsLoaded() || tries >= 40) {
		// 			removeHack();
		// 			clearInterval(interval);
		// 		}
		// 		tries++;
		// 	}, 100);
		// } else {
		// 	removeHack();
		// }
	}

	protected addSpecificCookies() {
		this.cookies.set(SystemCookies.forceHideHeaderAndTrees, '1', undefined, '/');
	}

	protected runPlatformSpecificCode(platformDetector: PlatformDetectorProvider) {
		if (!platformDetector) {
			return this._logger.warn(`platformDetector service is not defined'`);
		}
		if (platformDetector.isOneOfPlatform(platformDetector.types.android, platformDetector.types.ios)) {
			this._document.body.classList.add('is-mobile');
			this._document.documentElement.classList.add('is-mobile');
			if (this.mobileView.mobileMode) {
				this._document.documentElement.classList.add('is-mobile-mode');
				this._document.body.classList.add('is-mobile-mode');
			}
			// @see viewport on mobile https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
			// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
			let vh = this._window.innerHeight * 0.01;
			// Then we set the value in the --vh custom property to the root of the document
			this._document.documentElement.style.setProperty('--vh', `${vh}px`);
			const visualViewportHeight = this._window.visualViewport.height;
			this.zone.runOutsideAngular(() => {
				this._window.addEventListener('resize', () => {
					// We execute the same script as before
					vh = this._window.innerHeight * 0.01;
					this._document.documentElement.style.setProperty('--vh', `${vh}px`);
				});

				this._window.visualViewport.addEventListener('resize', e => {
					if (visualViewportHeight > this._window.visualViewport.height) {
						this._document.documentElement.classList.add('has-keyboard');
						this._document.body.classList.add('has-keyboard');
						this._document.body.setAttribute('height', this._window.visualViewport.height + 'px');
					} else {
						this._document.documentElement.classList.remove('has-keyboard');
						this._document.body.classList.remove('has-keyboard');
						this._document.body.setAttribute('height', '100%');
					}
				});
			});
		} else {
			this._document.documentElement.classList.remove('is-mobile-mode');
			this._document.body.classList.remove('is-mobile-mode');
		}

		const classes = platformDetector
			.detectPlatformTypes()
			.map(platform => platform.replace(' ', ''))
			.map(platform => `is-${platform.toLowerCase()}`);

		this._document.body.classList.add(...classes);

		if (platformDetector?.isMac()) {
			this._document.body.classList.add('is-mac');
		}
	}

	protected prepareConfiguration() {
		this.config.applyConfig({
			animation: !!this._animationModule,
		});
		this.injector.get(DataHttpService).config.appSettingsAnonymousConfig$.subscribe(cfg => {
			this.auth.providers = cfg.Providers;
		});
	}

	protected checkAuthentication() {
		this.auth.authenticated$.pipe(take(1)).subscribe(isAuth => {
			if (!isAuth && !this.auth.currentPageIsEntry) {
				this.zone.run(() => {
					this.auth.navigateToSignInPage(null, { initial: true }, true);
				});
			}
		});
	}

	protected addAppVersionInfo() {
		const pack: typeof packageJson = (<any>packageJson).default || <any>packageJson;
		this.versionService.update({
			name: pack.name,
			version: pack.version,
		});
	}

	protected registerFormaIcons(registry: MatIconRegistry, getSafeResourceUrl?: Function) {
		formaIcons.forEach(icon => {
			registry.addSvgIcon(
				`vh-${icon.iconName}`,
				typeof getSafeResourceUrl === 'function'
					? getSafeResourceUrl(
							this.url.getUrlRelativeToAssets(`icons/forma-icons/${icon.iconName}.svg?t=${this.ASSET_INVALIDATE_KEY}`)
					  )
					: this.url.getSafeResourceUrl(
							this.url.getUrlRelativeToAssets(`icons/forma-icons/${icon.iconName}.svg?t=${this.ASSET_INVALIDATE_KEY}`)
					  )
			);
		});
	}

	protected registerCustomSvgIcons() {
		this.iconRegistry.addSvgIcon(
			'pin',
			this.url.getSafeResourceUrl(
				this.url.getUrlRelativeToAssets(`icons/svg/pin-outline.svg?t=${this.ASSET_INVALIDATE_KEY}`)
			)
		);
		this.iconRegistry.addSvgIcon(
			'meeting',
			this.url.getSafeResourceUrl(
				this.url.getUrlRelativeToAssets(`icons/svg/meeting.svg?t=${this.ASSET_INVALIDATE_KEY}`)
			)
		);
		this.iconRegistry.addSvgIcon(
			'chat',
			this.url.getSafeResourceUrl(this.url.getUrlRelativeToAssets(`icons/svg/chat.svg?t=${this.ASSET_INVALIDATE_KEY}`))
		);
		this.iconRegistry.addSvgIcon(
			'star',
			this.url.getSafeResourceUrl(this.url.getUrlRelativeToAssets('icons/svg/star.svg'))
		);
		this.iconRegistry.addSvgIcon(
			'shevron-start',
			this.url.getSafeResourceUrl(this.url.getUrlRelativeToAssets('icons/svg/shevron-start.svg'))
		);
		this.iconRegistry.addSvgIcon(
			'shevron-end',
			this.url.getSafeResourceUrl(this.url.getUrlRelativeToAssets('icons/svg/shevron-end.svg'))
		);
	}

	protected setupLocale() {
		this.lazyLocaleNgModule
			.pipe(
				take(1),
				catchError(err => {
					console.error(err);
					return EMPTY;
				})
			)
			.subscribe(localeNg => {
				registerLocaleData(localeNg, this.localeId);
			});

		this.auth.authenticated$
			.pipe(
				distinctUntilChanged(),
				booleanFilter(),
				exhaustMap(() => this.culture.updateLanguageOnServer())
			)
			.subscribe();

		numeral.register('locale', 'ru-RU', {
			delimiters: {
				thousands: ' ',
				decimal: ',',
			},
			abbreviations: {
				thousand: 'тыс',
				million: 'млн',
				billion: 'млрд',
				trillion: 'трлн',
			},
			currency: {
				symbol: '₽',
			},
		});
	}

	protected setupEvents() {
		this.auth.authenticated$
			.pipe(
				booleanFilter(),
				take(1),
				concatMap(() => this.signal.activate())
			)
			.subscribe(() => {
				const se = (name: string) => `server-${name}`;

				this.signal.messages$.subscribe(({ data, name }) => {
					this.apiEvents.dispatchEvent(se(name), data);
					this.apiEvents.dispatchEvent(se('*'), data, se(name));
				});
			});

		this.auth.authenticated$
			.pipe(
				distinctUntilChanged(),
				switchMap(auth => {
					if (auth) {
						return this.auth.authInfo$;
					}
					return of(auth);
				})
			)
			.subscribe(auth => spaDispatchEvent('auth', { auth }));
	}

	protected updateSessionUserOnProfileChanged() {
		this.auth.authenticated$
			.pipe(
				booleanFilter(),
				switchMap(_ => this.signal.userProfileChanged$)
			)
			.subscribe(_ => {
				this.zone.runTask(() => {
					this.sessionUser.update();
				});
			});
	}

	protected updateTabsOnSignal() {
		this.auth.authenticated$
			.pipe(
				booleanFilter(),
				switchMap(_ => {
					const stopImpersonation$ = this.signal.stopImpersonation$.pipe(
						mergeMap(() => this.auth.isReincarnateMode$),
						booleanFilter()
					);
					return merge(this.signal.changeLanguage$, this.signal.impersonate$, stopImpersonation$);
				}),
				concatMap(() => fromEvent(document, 'visibilitychange').pipe(take(1))),
				filter(() => document.visibilityState === 'visible')
			)
			.subscribe(_ => {
				this.zone.runTask(() => {
					window.location.reload();
				});
			});
	}

	protected checkMtfHasUnsavedChanges() {
		const mtfUnsavedChangesChecker = () => {
			const result$ = new Observable<boolean>(observer => {
				const mtfFrames = this.mtfFrame.findMtfIframes();
				if (!mtfFrames.length) {
					observer.next(false);
				} else {
					const isDirty = Boolean(mtfFrames.find(frame => this.mtfFrame.isMtfDirty(frame)));
					observer.next(isDirty);
				}
				observer.complete();
			});

			return result$;
		};
		this.unsavedChanges.addRouterChecker(mtfUnsavedChangesChecker);
	}

	protected applyMetaTags() {
		const server = this.injector.get(DataHttpService);
		server.config.appSettingsAnonymousConfig$.pipe(take(1)).subscribe(cfg => {
			if (cfg.ApplicationNameShort) {
				const appleAppTitle = this._document.querySelector<HTMLElement>(`meta[name='apple-mobile-web-app-title']`);
				appleAppTitle?.setAttribute('content', cfg.ApplicationNameShort);
				const title = this._document.querySelector<HTMLTitleElement>(`title`);
				if (title) {
					title.textContent = cfg.ApplicationNameShort;
				}
			}
		});
	}

	protected applyHttpConfiguration() {
		const server = this.injector.get(DataHttpService);
		server.config.appSettingsAnonymousConfig$.subscribe(cfg => {
			const timeoutHttp = this.injector.get(TimeoutHttpInterceptorSettings);
			timeoutHttp.timeoutSeconds = cfg.CustomSettings?.requestTimeout;
			timeoutHttp.requestTimeoutExcludeUrls = cfg.CustomSettings?.requestTimeoutExcludeUrls;
		});
	}

	protected afterAuth() {
		this.auth.authenticated$.pipe(booleanFilter(), take(1)).subscribe(() => {
			const jitsi = this.injector.get(JitsiService);
			jitsi.init();
			const cronDisabled = !!+window.localStorage.getItem('cron-disabled');
			if (!cronDisabled) {
				const cron = this.injector.get(CronService);
				cron.start();
			}
		});
	}

	protected saveAndRestoreLastActiveInput() {
		let lastActive: HTMLElement;
		const focusElements = ['input', 'textarea'];
		this._document.addEventListener('focusin', e => {
			const el = e.target as HTMLElement;
			if (focusElements.includes(el.tagName.toLowerCase())) {
				lastActive = el;
			} else {
				lastActive = null;
			}
		});
		this._document.addEventListener('visibilitychange', () => {
			if (!document.hidden && lastActive && this._document.body.contains(lastActive)) {
				lastActive.focus();
			}
		});
		this._window.addEventListener('focus', () => {
			if (!document.hidden && lastActive && this._document.body.contains(lastActive)) {
				lastActive.focus();
			}
		});
	}
}
