import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MountedDirectiveModule } from '@spa/common/directives/mounted.directive';
import { LocalizationModule } from '@spa/localization';
import { IconModule } from '@vh/core-components';
import { SmartExpressionSelectionPipeModule } from '../smart-expressions-selection.pipe';
import { NavigationContextMenuComponent } from './navigation-context-menu.component';

@NgModule({
	imports: [
		CommonModule,
		MatMenuModule,
		LocalizationModule,
		MountedDirectiveModule,
		MatIconModule,
		MatDividerModule,
		SmartExpressionSelectionPipeModule,
		IconModule,
	],
	exports: [NavigationContextMenuComponent],
	declarations: [NavigationContextMenuComponent],
	providers: [],
})
export class NavigationContextMenuModule {}
