import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hexByBackground' })
export class HexByBackgroundPipe implements PipeTransform {
	constructor() {}

	transform(bg: string, args: any[]): string {
		return getColorByBg(bg);
	}
}

export function hexIsLight(color: string): boolean {
	const hex = color.replace('#', '');
	const c_r = parseInt(hex.substr(0, 2), 16);
	const c_g = parseInt(hex.substr(2, 2), 16);
	const c_b = parseInt(hex.substr(4, 2), 16);
	const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
	return brightness > 155;
}

export function getColorByBg(bg: string): string {
	if (!bg) {
		return 'currentColor';
	}
	if (hexIsLight(bg)) {
		return '#000';
	}
	return '#fff';
}
