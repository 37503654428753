import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { DuplicateImportModuleError } from '../errors';
import { pluginProviders } from './plugins';
import { StoreManagerImpl } from './store-manager.service';
import { StoreManager } from './store-manager.base';

@NgModule()
export class StoreModule {
	constructor(
		@Optional()
		@SkipSelf()
		configModule: StoreModule
	) {
		if (configModule) throw new DuplicateImportModuleError('StoreModule');
	}

	static forRoot(): ModuleWithProviders<StoreModule> {
		return {
			ngModule: StoreModule,
			providers: [
				{
					provide: StoreManager,
					useClass: StoreManagerImpl,
				},
				...pluginProviders,
			],
		};
	}

	static forChild(): ModuleWithProviders<StoreModule> {
		return {
			ngModule: StoreModule,
			providers: [],
		};
	}
}
