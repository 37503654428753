import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PortalHelperModule } from '@spa/common/components/portal-helper';
import { AutoFocusModule } from '@spa/common/directives';
import { LocalizationModule } from '@spa/localization';
import { ModalBaseModule } from '../modal-base';

import { ModalGenericComponent } from './modal-generic.component';

@NgModule({
	imports: [
		CommonModule,
		ModalBaseModule,
		LocalizationModule,
		MatIconModule,
		MatToolbarModule,
		AutoFocusModule,
		PortalHelperModule,
	],
	exports: [ModalGenericComponent],
	declarations: [ModalGenericComponent],
	providers: [],
})
export class ModalGenericModule {}
