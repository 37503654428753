import { Injectable } from '@angular/core';
import type { ExtParamBase } from '@spa/application/ext-params';

@Injectable({ providedIn: 'root' })
export class ExtParamRegisterService {
	protected eps = new Map<number, ExtParamBase>();
	protected epsByCardGuid = new Map<string, Map<number, ExtParamBase>>();

	all(guid: string) {
		const epst = this.getEpsByCardGuid(guid);
		return Array.from(epst.values());
	}

	get(id: number, guid: string) {
		const epst = this.getEpsByCardGuid(guid);
		return epst.get(id);
	}

	add(ep: ExtParamBase, guid: string) {
		if (ep) {
			const epst = this.getEpsByCardGuid(guid);
			epst.set(ep.id, ep);
		}
	}

	remove(ep: ExtParamBase | number, guid: string) {
		const epId = typeof ep === 'number' ? ep : ep?.id;
		if (epId) {
			const eps = this.getEpsByCardGuid(guid);
			eps.delete(epId);
		}
	}

	getDependentEp(id: number, guid: string) {
		const dependencies = this.all(guid).filter(ep => {
			const filter = ep.sourceConfig?.dataSource?.filter;
			if (!filter) {
				return false;
			}
			return [filter.firstParentEpId, filter.secondParentEpId].includes(id);
		});
		return dependencies;
	}

	protected getEpsByCardGuid(guid: string) {
		if (!guid) {
			return this.eps;
		}

		if (this.epsByCardGuid.has(guid)) {
			return this.epsByCardGuid.get(guid);
		}
		this.epsByCardGuid.set(guid, new Map());
		return this.epsByCardGuid.get(guid);
	}
}
