import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@valhalla/core';
import EventEmitter from 'events';
import { map, Observable, of } from 'rxjs';

import ApiVersion from '../api-versions';
import { DataHttpCommonService } from '../data-http-common.service';
import {
	IActionReq,
	IAgendaRes,
	ICalendarEvent,
	ICalendarMessagesCountReq,
	ICalendarMessagesReq,
	ICalendarMessagesRes,
	ICalendarRecipient,
	IGetAgendaReq,
	IUserCalendarConfig,
	ProviderCalendarDataHttpServiceBase,
} from './abstract';

const API_PATH = 'calendar';
let config: IUserCalendarConfig;
@Injectable()
export class ProviderCalendarDataHttpServiceImpl implements ProviderCalendarDataHttpServiceBase {
	constructor(readonly http: HttpClient, readonly common: DataHttpCommonService, readonly auth: AuthService) {}
	onUnreadMessageCountChanged$ = new EventEmitter();

	userConfig(): Observable<IUserCalendarConfig> {
		if (config) {
			return of(config);
		} else {
			const url = this.common.getApiUrl(`${API_PATH}/userConfig`);
			const request = this.http.get<IUserCalendarConfig>(url);
			request.subscribe(uc => {
				config = uc;
			});
			return request;
		}
	}

	get<T = ICalendarEvent>(key: string, ownerUserId?: number): Observable<T> {
		let url = this.common.getApiUrl(`${API_PATH}/get?key=${encodeURIComponent(key)}`);

		if (ownerUserId) {
			url += `&calendarOwnerUserId=${ownerUserId}`;
		}

		return this.http.get<T>(url);
	}
	create<T = ICalendarEvent>(calendarEvent: T, ownerUserId?: number): Observable<string> {
		let url = this.common.getApiUrl(`${API_PATH}/create`);

		if (ownerUserId) {
			url += `?calendarOwnerUserId=${ownerUserId}`;
		}

		return this.http.post<any>(url, calendarEvent);
	}
	update<T = ICalendarEvent>(calendarEvent: T, ownerUserId?: number): Observable<string> {
		let url = this.common.getApiUrl(`${API_PATH}/update`);

		if (ownerUserId) {
			url += `?calendarOwnerUserId=${ownerUserId}`;
		}

		return this.http.put<any>(url, calendarEvent);
	}
	delete(key: any, ownerUserId?: number): Observable<unknown> {
		let url = this.common.getApiUrl(`${API_PATH}/delete?key=${encodeURIComponent(key)}`);

		if (ownerUserId) {
			url += `&calendarOwnerUserId=${ownerUserId}`;
		}

		return this.http.delete(url);
	}

	executeAction(req: IActionReq): Observable<ICalendarEvent> {
		const url = this.common.getApiUrl(`${API_PATH}/action`);
		let result = this.http.post<any>(url, req);

		result = result.pipe(
			map(data => {
				this.onUnreadMessageCountChanged$.emit('changed');
				return data;
			})
		);

		return result;
	}

	resolveName(name: string, meetingKey?: string): Observable<ICalendarRecipient[]> {
		let url = this.common.getApiUrl(`${API_PATH}/resolveName?name=${encodeURIComponent(name)}`);

		if (meetingKey) {
			url += `&contextMeetingKey=${meetingKey}`;
		}

		return this.http.get<ICalendarRecipient[]>(url);
	}

	getMessages(req: ICalendarMessagesReq): Observable<ICalendarMessagesRes[]> {
		const url = this.common.getApiUrl(`${API_PATH}/messages`);

		req.limit = req.limit || 50;
		req.offset = req.offset || 0;

		let result = this.http.post<ICalendarMessagesRes[]>(url, req);

		result = result.pipe(
			map(data => {
				this.onUnreadMessageCountChanged$.emit('changedCount', data.filter(y => !y.isRead).length);
				return data;
			})
		);

		return result;
	}

	getUnreadMessagesCount(req: ICalendarMessagesCountReq): Observable<number> {
		const url = this.common.getApiUrl(`${API_PATH}/messages/unread/count`);

		return this.http.post<any>(url, req).pipe(map(x => x.data));
	}

	getAgenda(req: IGetAgendaReq): Observable<IAgendaRes[]> {
		if (Array.isArray(req.includes)) {
			const url = this.common.getApiUrl(`agenda`);
			return this.http
				.post<any>(url, {
					userId: req.userId,
					subcatIds: req.subcatIds,
					catIds: req.catIds,
					dateTo: req.to,
					dateFrom: req.from,
					includes: req.includes,
					isMailReminder: req.isMailReminder,
					isPerformerOrdered: req.isPerformerOrdered,
					isPlannedStart: req.isPlannedStart,
					isResponsibleOrdered: req.isResponsibleOrdered,
					isSignatureOrdered: req.isSignatureOrdered,
					isTaskCreation: req.isTaskCreation,
				})
				.pipe(map(r => r.data?.days));
		}

		const url = this.common.getApiUrl(`agenda/${req?.userId}`, ApiVersion.v10);

		return this.http.get<any>(url, {
			params: { from: req?.from, to: req?.to },
		});
	}
}
