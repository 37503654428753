import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AutoFocusModule } from '@spa/common/directives';
import { LocalizationModule } from '@valhalla/localization';

import { SidebarSearchComponent } from './sidebar-search.component';

@NgModule({
	declarations: [SidebarSearchComponent],
	imports: [CommonModule, MatIconModule, AutoFocusModule, LocalizationModule],
	exports: [SidebarSearchComponent],
})
export class SidebarSearchModule {}
