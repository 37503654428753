import { IExtParam } from './ext-param';
import { ISubcategoryData, ITaskParticipantsOptions } from './subcat';

// tslint:disable-next-line:no-empty-interface
export interface ICategoryShort extends ISubCategoryShort {}

export interface ISubCategoryShort {
	parentCategoryId: number;
	defaultViewType: string;
	isPortal: boolean;
	name: string;
	id: number;
	subcategorySettings: ISubcategorySettings;
	isExportProhibited?: boolean;
	isBatchUpdateAllowed?: boolean;
	gridPeriodicUpdateInterval?: number;
	isCsvUpdateAllowed?: boolean;
	isHierarchy?: boolean;
	permissions?: any;
}

export interface ICategoryInfo {
	id: number;
	template: {
		extParamsColsBlocks: number;
		extParamsBlocks: IExtParamsBlocks[];
		extParams: IExtParamWithBlockInfo[];
	};
}
export interface IExtParamsBlocks {
	id: number;
	order: number;
	name: string;
	isExpandable: boolean;
	isExpanded: boolean;
	color: string;
}

export interface IExtParamsBlocksFromCategory {
	id: number;
	name: string;
	subcatId: number;
	canFold: boolean;
	defaultMinimized: boolean;
	defaultBlock: boolean;
	guid: string;
	order: number;
	color: string;
	localizedNameId: number;
	extParamIds: number[];
	extParams?: any;
}

export interface IExtParamWithBlockInfo extends IExtParam {
	blockId: number;
	order: number;
	colsWidth: number;
}
export interface ISubCategories extends ISubcategoryData {}
export interface ICustomButtons {
	id: number;
	subcatId: number;
	name: string;
	memo: string;
	iconUrl: string;
	isVisible: boolean;
	actionsPackId: number;
	jsExpression: string;
	actionId: number;
	forOwner: boolean;
	forPerformer: boolean;
	forResponsiblePerformer: boolean;
	forAdmin: boolean;
	forAddRight: boolean;
	forViewer: boolean;
	visibilitySmartFilterId: number;
	urlSmartExpressionId: number;
	redirectActionTypeId: string;
	catSmartExpressionId: number;
	subcatSmartExpressionId: number;
	taskSmartExpressionId: number;
	taskSourceSmartExpressionId: number;
	sourceTaskSmartExpressionId: number;
	hideOnVisibilitySmartFilter: boolean;
	disabledReasonSmartExpressionId: number;
	disabledReason: string;
	guid: string;
}

export interface IStepSubTasks {
	stepId: number;
	subcatId: number;
	taskDescr: string;
	includeParentDescr: boolean;
	isLinked: boolean;
	copySubscribersFromParent: boolean;
	fromWhom: number;
	assignToParentTaskOwner: boolean;
	customerRoleToAssignID: number;
	copyPerformers: boolean;
	copyOnlyResponsiblePerformer: boolean;
	separateTaskForEachPerformer: boolean;
	performersFromGroupID: number;
	orderedTimeMode: number;
	orderedTimeExtParam: number;
	copyAttachmentsFromParent: boolean;
	guid: string;
	orderedTimeOffset: number;
	orderedTimeOffsetUnit: number;
	smartFilterID: number;
}

export interface ISubcategoryOptions {
	id: number;
	version: number;
	subcatTypeID: number;
	description: string;
	details: string;
	categoryId: number;
	guid: string;
	entity: string;
	sendButton: string;
	srokName: string;
	requireSubtaskFinish: boolean;
	defaultText: string;
	forMailAdministrators: string;
	responsibleGroupID: number;
	newTaskMessage: string;
	customerID: number;
	textToAppendToSubjects: string;
	useEPTemplate: boolean;
	ePTemplate: string;
	enableSms: boolean;
	taskStartTimeName: string;
	parentTaskDescriptionReference: string;
	forbidUploadFilesIntoClosedTasks: boolean;
	notifyResponsibleStateChange: boolean;
	isPortal: boolean;
	isNotice: boolean;
	forbidMovingTasks: boolean;
	memo: boolean;
	fullPath: string;
	docxTemplateProtectionLevel: number;
	fullPathIds: string;
	isForProjects: boolean;
	isForProjectTasks: boolean;
	projectTasksSubcatId?: number;
	giveRightsOnlyToADGroups: boolean;
	autoDeletePrevVersionsOfFiles: boolean;
	allowSignatureRevoke: boolean;
	allowSignatureRevokeByAcceptors: boolean;
	projectPlannedSumEpId?: number;
	projectFactSumEpId?: number;
	projectCurrencyName: string;
	projectShortDateView: boolean;
	additionalSyndicateLink: string;
	disableTasksMove: boolean;
	addSubscriberText: string;
	addSubscribersText: string;
	technicalWorksText?: string;
	isTechnicalWorksNow?: boolean;
	isSystemSubcat: boolean;
	isResourceReservation: boolean;
	isTasksLayoutSubcat: boolean;
	isForSurveys: boolean;
	taskLikesEnabled: boolean;
	enableTaskViewsCounter: boolean;
	enableTaskUserCommentsCounter: boolean;
	allowSignatureRevokeByOwner: boolean;
	allowSignatureRevokeByRequester: boolean;
	mobileTemplateID: number;
	showExtParamsAfterBlocks: boolean;
	severalDecompositions: boolean;
	isForProjectForceTasks: boolean;
	mtfDefaultExtParamTableColumns?: number;
	ntfDefaultExtParamTableColumns?: number;
	extParamsBlocksColor: string;
	mobileTaskTemplateId?: number;
	msOfficeInteractionSettingId?: number;
	isCalendar: boolean;
	tasksIsNotOverdue: boolean;
	mobileCellTemplateId?: number;
	isSaveUsersSettingsForGrid: boolean;
	isPinnedToChats: boolean;
	disableTasksCopyFromSubcategory: boolean;
	disableTasksCopyToSubcategory: boolean;
	toolbarConfig: any;
	localizedDescriptionId?: number;
	localizedEntityId?: number;
	dueOptions?: any;
	viewAndPermissions?: IViewPermissions;
	srokMode?: SubcategoryDueMode;
	taskParticipantsOptions?: ITaskParticipantsOptions;
}

export interface IViewPermissions {
	displayTaskStartDate?: boolean;
	showTasksInTerminalStatusInCalendar?: boolean;
}

export interface ISubcategorySettings {
	availableRepresentations?: SubcategoryRepresentation[];
}

export enum SubcategoryRepresentation {
	grid = 'Grid',
	feeds = 'Feeds',
	calendar = 'Calendar',
	gantt = 'Gantt',
	kanban = 'Kanban',
	channel = 'Channel',
	chat = 'Chat',
	files = 'Files',
}

export enum SubcategoryDueMode {
	required = 'Required',
	enabled = 'Enabled',
	disabled = 'Disabled',
}
