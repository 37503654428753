import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LicenseBusyBannerComponent } from './license-busy-banner/license-busy-banner.component';
import { LicenseBusyInterceptor } from './license-busy.interceptor';

@NgModule({
	imports: [CommonModule],
	exports: [LicenseBusyBannerComponent],
	declarations: [LicenseBusyBannerComponent],
	providers: [],
})
export class LicenseManagerModule {
	static forRoot(): ModuleWithProviders<LicenseManagerModule> {
		return {
			ngModule: LicenseManagerModule,
			providers: [
				{
					provide: HTTP_INTERCEPTORS,
					useClass: LicenseBusyInterceptor,
					multi: true,
				},
			],
		};
	}
}
