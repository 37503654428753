import { Injector } from '@angular/core';
import { LoggerFactory } from '@valhalla/core';
import { SignalrProvider } from '@valhalla/data/signalr';

import { TickerProvider } from '../../providers';

export abstract class TickerEffectDependencies {
	abstract readonly tickerProvider: Partial<TickerProvider>;
	abstract readonly loggerFactory: Partial<LoggerFactory>;
	abstract readonly injector: Injector;
	abstract readonly signal: SignalrProvider;
}
