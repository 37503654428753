import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PortalHostComponent } from './portal-host.component';
import { PortalTargetComponent } from './portal-target.component';

@NgModule({
	imports: [CommonModule, PortalModule],
	exports: [PortalModule, PortalTargetComponent, PortalHostComponent],
	declarations: [PortalTargetComponent, PortalHostComponent],
	providers: [],
})
export class PortalHelperModule {}
