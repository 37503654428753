import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TippyModule } from '@ngneat/helipopper';
import { FeatherIconsModule } from '@spa/common/feather-icons';

import { FavoriteExpandListItemIconDirective } from './favorite-expand-list-item-icon.directive';
import { FavoriteExpandListItemComponent } from './favorite-expand-list-item.component';
import { FavoriteExpandListComponent } from './favorite-expand-list.component';
import { BadgeModule } from '@vh/core-components';

@NgModule({
	imports: [CommonModule, FeatherIconsModule, MatIconModule, TippyModule, BadgeModule],
	exports: [FavoriteExpandListComponent, FavoriteExpandListItemIconDirective, FavoriteExpandListItemComponent],
	declarations: [FavoriteExpandListComponent, FavoriteExpandListItemIconDirective, FavoriteExpandListItemComponent],
	providers: [],
})
export class FavoriteExpandListModule {}
