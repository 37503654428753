import { inject, Injectable, InjectionToken, LOCALE_ID, Provider } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TokenService, UniversalLoaderProvider, UrlProvider } from '@valhalla/core';
import { map } from 'rxjs/operators';

export function loadLocale() {
	const localeId = inject(LOCALE_ID);
	const urlProvider: UrlProvider = inject(UrlProvider as any);
	const loader: UniversalLoaderProvider = inject(UniversalLoaderProvider as any);
	const { file, name } = mapLocaleIdToFileName(localeId);
	const pathToNgLocaleModule = urlProvider.getUrlRelativeToAssets(`scripts/angular/locales/global/${file}`);
	return loader.injectScripts(pathToNgLocaleModule).pipe(
		map(() => {
			const global = window as any;
			return global.ng?.common?.locales[name];
		})
	);
}

export function mapLocaleIdToFileName(localeId: string) {
	localeId = getNavigatorLanguage(localeId);
	const chunks = localeId.split('-');
	let name = '';
	chunks.forEach((c, idx) => {
		if (idx === chunks.length - 1) {
			return;
		}
		if (!name) {
			name = c;
		} else {
			name += `-${c}`;
		}
	});
	return { file: `${name}.js`, name: name.toLowerCase() };
}

export type LazyLocaleType = ReturnType<typeof loadLocale>;

export const LazyLocale = new InjectionToken<LazyLocaleType>('Lazy locale', {
	providedIn: 'root',
	factory: () => loadLocale(),
});

export function getLocale(token: TokenService) {
	const culture = getNavigatorLanguage(token.payload?.Culture);
	if (culture) {
		return culture;
	}

	let browserLang = getNavigatorLanguage(window.navigator.language);

	if (browserLang) {
		if (browserLang === 'ru') {
			browserLang = 'ru-RU';
		}
		if (browserLang === 'en') {
			browserLang = 'en-US';
		}
		if (browserLang === 'zh') {
			browserLang = 'zh-CN';
		}
		if (browserLang === 'de') {
			browserLang = 'de-DE';
		}
		if (browserLang === 'fr') {
			browserLang = 'fr-FR';
		}
		if (browserLang === 'it') {
			browserLang = 'it-IT';
		}
		if (browserLang === 'es') {
			browserLang = 'es-ES';
		}
		if (browserLang === 'da') {
			browserLang = 'da-DA';
		}
		if (browserLang === 'pl') {
			browserLang = 'pl-PL';
		}
		if (browserLang === 'ja') {
			browserLang = 'ja-JP';
		}

		return browserLang;
	}

	return 'en-US';
}

export function getNavigatorLanguage(lang: string) {
	const reg = new RegExp(/[a-z][a-z]-[a-z][a-z]/gm);
	if (reg.test(lang)) {
		const str = lang.split('-');
		return `${str[0]}-${str[1].toUpperCase()}`;
	}

	return lang;
}

export const DATE_FORMATS_RU: MatDateFormats = {
	parse: {
		dateInput: 'DD.MM.YYYY',
	},
	display: {
		dateInput: 'DD.MM.YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

export const DATE_FORMATS_EN: MatDateFormats = {
	parse: {
		dateInput: 'MM.DD.YYYY',
	},
	display: {
		dateInput: 'MM.DD.YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

const dateFormatFactory = () => {
	const localeId = inject(LOCALE_ID);
	if (localeId === 'ru-RU') {
		return DATE_FORMATS_RU;
	}
	return DATE_FORMATS_EN;
};

@Injectable()
export class SpaDateAdapter extends MomentDateAdapter {
	getFirstDayOfWeek() {
		return 1;
	}
}

export const LocaleProvider: Provider[] = [
	{
		provide: LOCALE_ID,
		useFactory: getLocale,
		deps: [TokenService],
	},
	{
		provide: DateAdapter,
		useClass: SpaDateAdapter,
		deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
	},
	{
		provide: MAT_DATE_FORMATS,
		useFactory: dateFormatFactory,
	},
];
