import { Injectable, inject } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ExtParamTableApiService {
	#reg = inject(ExtParamTableApiRegisterService);

	table(id: number): IExtParamTableExposeApi {
		return this.#reg.byId(id)?.exposedApi;
	}
}

@Injectable({
	providedIn: 'root',
})
export class ExtParamTableApiRegisterService {
	reg = new Set<IExtParamTableApi>();

	add(ref: IExtParamTableApi) {
		this.reg.add(ref);
	}

	remove(ref: IExtParamTableApi) {
		this.reg.delete(ref);
	}

	byId(id: number) {
		for (const ref of this.reg) {
			if (ref.extParamId === +id) {
				return ref;
			}
		}
	}
}

export interface IExtParamTableApi {
	get extParamId(): number;
	get exposedApi(): IExtParamTableExposeApi;
}

/**
 * @see https://help.1forma.ru/Admin_Manual/js_api_table.htm
 */
export interface IExtParamTableExposeApi {
	root: HTMLElement;
	savedRowsCount: number;
	filteredRows: IExtParamTableExposeApiRow[];
	multiWindow: {
		filteredRows: IExtParamTableExposeApiRow[];
	};
	onTableLoaded: (e?: any) => void;
	onTableRowAdded: (e?: any) => void;
	onTableRowChanged: (e?: any) => void;
	onTableSaved: (e?: any) => void;
	onTableMWLoaded: (e?: any) => void;
	onTableMWRowSelected: (e?: any) => void;
	onTableMWRowChanged: (e?: any) => void;
	onTableMWClosed: (e?: any) => void;
}

export interface IExtParamTableExposeApiRow {
	cells: IExtParamTableExposeApiCell[];
	id: number;
}

export interface IExtParamTableExposeApiCell {
	columnId: number;
	columnValue: any;
	tooltip: (str: string) => void;
}
