import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	Inject,
	ApplicationRef,
	HostBinding,
	ViewEncapsulation,
	Output,
	EventEmitter,
	ViewChild,
} from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ViewDestroyStreamService, LoggerFactory, AbstractLogger, UrlProvider } from '@valhalla/core';
import { IFrameViewerCommonComponent } from '@valhalla/common/components/iframe-viewer';

@Component({
	selector: 'vh-facade-modal-frame',
	templateUrl: 'modal-iframe.component.html',
	styleUrls: ['modal-iframe.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class FacadeModalIframeComponent implements OnInit {
	constructor(
		readonly destroy$: ViewDestroyStreamService,
		readonly dialogRef: MatDialogRef<FacadeModalIframeComponent>,
		@Inject(MAT_DIALOG_DATA) readonly dialogData: any,
		readonly lf: LoggerFactory,
		readonly urlBuilder: UrlProvider,
		readonly appRef: ApplicationRef
	) {}

	@ViewChild('frameViewer')
	frameViewer: IFrameViewerCommonComponent;

	@Output()
	frameLoaded = new EventEmitter<IFrameViewerCommonComponent>();

	@HostBinding('class.modal-dialog-window')
	hostClsModalDialogWindow = true;

	@HostBinding('class.vh-facade-modal-frame')
	hostClsSelector = true;

	logger: AbstractLogger = this.lf.createLogger('FacadeModalIframeComponent');
	url = this.dialogData.url;
	safeUrl = this.urlBuilder.getSafeResourceUrl(this.url);
	safeOpenInNewTabUrl = this.dialogData.openInNewTabUrl
		? this.urlBuilder.getSafeResourceUrl(this.dialogData.openInNewTabUrl)
		: null;
	title = this.dialogData.title;
	titleResx = this.dialogData.titleResx;
	frameClasses = this.dialogData.frameClasses;
	windowProps = this.dialogData.windowProps;
	hideOnLoading = true;

	get postData() {
		return this.dialogData?.postData;
	}

	get iframeBaseHref() {
		return this.dialogData?.iframeBaseHref;
	}

	ngOnInit() {
		if (typeof this.dialogData.hideOnLoading === 'boolean') {
			this.hideOnLoading = this.dialogData.hideOnLoading;
		}

		this.logger.info(this.dialogData);
	}

	close() {
		this.dialogRef.close(false);
		this.appRef.tick();
	}

	openInNewTab(e?: PointerEvent) {
		this.dialogRef.close(false);
		this.appRef.tick();
	}
}
