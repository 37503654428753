<vh-modal-base
	title="Добавить ссылку"
	(cancel)="close()"
	(close)="close()"
	(ok)="closeAndReturnModalData()"
	okTitle="Добавить"
>
	<mat-form-field>
		<mat-label>Ссылка</mat-label>
		<input matInput [formControl]="link" appearance="outline" />
	</mat-form-field>
	<mat-form-field>
		<mat-label>Отображать как</mat-label>
		<input matInput [formControl]="name" appearance="outline" />
	</mat-form-field>
</vh-modal-base>
