import { ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { commonAnimations } from '@spa/common/animations';
import { SidebarService } from '@spa/common/services/sidebar.service';
import { UserSettingsFacadeProvider } from '@spa/facade/features/user-settings';
import { LayoutFacade } from '@spa/facade/layout/layout-facade.service';
import { LayoutNavBarPosition } from '@spa/facade/layout/state';
import { CookieService, PlatformDetectorProvider, SessionUser } from '@valhalla/core';
import { ConfigurationDataHttpService, DataHttpService, isVisibleElement } from '@valhalla/data/http';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { ResourceService } from '@spa/localization';
import { mapThemeName } from '@spa/facade/layout';

@Component({
	selector: 'vh-common-theme-options',
	templateUrl: './theme-options.component.html',
	styleUrls: ['./theme-options.component.scss'],
	animations: commonAnimations,
})
export class ThemeOptionsCommonComponent implements OnInit, OnDestroy {
	constructor(
		private _formBuilder: UntypedFormBuilder,
		private readonly _layout: LayoutFacade,
		private _fuseSidebarService: SidebarService,
		readonly platform: PlatformDetectorProvider,
		readonly cdr: ChangeDetectorRef,
		readonly server: DataHttpService,
		readonly cookieService: CookieService,
		readonly userSettingsStore: UserSettingsFacadeProvider,
		readonly appSettings: ConfigurationDataHttpService,
		readonly sessionUser: SessionUser,
		readonly modals: ModalWindowsService,
		readonly resourceService: ResourceService
	) {
		// Set the defaults
		this.barClosed = true;

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	@HostBinding('class.bar-closed')
	barClosed: boolean;

	// Private
	private _unsubscribeAll: Subject<any>;

	fuseConfig: any;
	form: UntypedFormGroup;

	get isMobile() {
		return this.platform.isMobile();
	}

	readonly calendarVisible$ = this.userSettingsStore.selectUserSettings$.pipe(
		map(s => isVisibleElement(s.calendarVisible))
	);

	readonly showShadow$ = new BehaviorSubject(false);

	readonly allowThemes$ = this.appSettings
		.getAppSettingsAnonymous()
		.pipe(map(res => !(res?.CustomSettings?.AllowThemes === false)));

	readonly uiSettingsConfig$ = this.server.users.sessionUserSettings$.pipe(
		map(settings => settings.userUISettingsConfig)
	);

	readonly colorTheme$ = this.uiSettingsConfig$.pipe(map(config => config?.colorTheme?.isVisible));
	readonly showTask$ = this.uiSettingsConfig$.pipe(map(config => config?.showTask?.isVisible));
	readonly comments$ = this.uiSettingsConfig$.pipe(map(config => config?.comments?.isVisible));
	readonly general$ = this.uiSettingsConfig$.pipe(map(config => config?.general?.isVisible));
	readonly commentsFeed$ = this.uiSettingsConfig$.pipe(map(config => config?.commentsFeed?.isVisible));
	readonly navigationPanel$ = this.uiSettingsConfig$.pipe(map(config => config?.navigationPanel?.isVisible));
	readonly navigationPanelLocation$ = this.uiSettingsConfig$.pipe(
		map(config => config?.navigationPanel?.navigationPanelLocation?.isVisible)
	);
	readonly showAlways$ = this.uiSettingsConfig$.pipe(map(config => config?.navigationPanel?.showAlways?.isVisible));
	readonly colorPicker$ = this.uiSettingsConfig$.pipe(map(config => config?.navigationPanel?.colorPicker?.isVisible));
	readonly allowUsersChangeDisplayNameMode$ = this.server.admin
		.getUserSettings(this.sessionUser.userId)
		.pipe(map(userSettings => userSettings?.controlVisibilitySettings.allowUsersChangeDisplayNameMode));

	ngOnInit(): void {
		// Build the config form
		// noinspection TypeScriptValidateTypes
		this.form = this._formBuilder.group({
			colorTheme: new UntypedFormControl(),
			viewTaskState: new UntypedFormControl(),
			viewCommentsInTaskState: new UntypedFormControl(),
			userNameMode: new UntypedFormControl(),
			showIAndMeInsteadOfDisplayName: new UntypedFormControl(),
			showOwnDisplayName: new UntypedFormControl(),
			translateCommentsInFeed: new UntypedFormControl(),
			appNavBarPosition: new UntypedFormControl(),
			panelBackground: new UntypedFormControl(),
			customScrollbars: new UntypedFormControl(),
			apex: new UntypedFormControl(),
			chatBubbles: new UntypedFormControl(),
			showNavigationAlways: new UntypedFormControl(),
			showDateInHumanFormat: new UntypedFormControl(),
			openFirstTaskInTableView: new UntypedFormControl(),
			layout: this._formBuilder.group({
				style: new UntypedFormControl(),
				width: new UntypedFormControl(),
				navbar: this._formBuilder.group({
					primaryBackground: new UntypedFormControl(),
					secondaryBackground: new UntypedFormControl(),
					folded: new UntypedFormControl(),
					hidden: new UntypedFormControl(),
					position: new UntypedFormControl(),
					variant: new UntypedFormControl(),
				}),
				toolbar: this._formBuilder.group({
					background: new UntypedFormControl(),
					customBackgroundColor: new UntypedFormControl(),
					hidden: new UntypedFormControl(),
					position: new UntypedFormControl(),
				}),
				footer: this._formBuilder.group({
					background: new UntypedFormControl(),
					customBackgroundColor: new UntypedFormControl(),
					hidden: new UntypedFormControl(),
					position: new UntypedFormControl(),
				}),
				sidepanel: this._formBuilder.group({
					hidden: new UntypedFormControl(),
					position: new UntypedFormControl(),
				}),
			}),
			toolbarButtons: this._formBuilder.group({
				calendar: new UntypedFormControl(),
			}),
		});

		this.server.users.sessionUserSettings$.pipe(take(1)).subscribe(config => {
			const userUISettings = config.userUISettings;

			this.form.patchValue(
				{
					viewTaskState: userUISettings.viewTaskState,
					viewCommentsInTaskState: userUISettings.viewCommentsInTaskState,
					showDateInHumanFormat: userUISettings.showDateInHumanFormat,
					openFirstTaskInTableView: userUISettings.openFirstTaskInTableView,
					userNameMode: userUISettings.userNameMode,
					showIAndMeInsteadOfDisplayName: !userUISettings.showIAndMeInsteadOfDisplayName,
					translateCommentsInFeed: userUISettings.translateCommentsInFeed,
					showNavigationAlways: userUISettings.showNavigationAlways,
				},
				{
					emitEvent: false,
				}
			);
		});

		// Subscribe to the config changes
		combineLatest([this.server.users.sessionUserSettings$, this._layout.state$])
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(([, config]) => {
				// Update the stored config

				this.fuseConfig = {
					layout: config.layout,
					customScrollbars: config.customScrollbars,
					colorTheme: config.colorTheme,
					taskPane: config.taskPane,
					appNavBarPosition: config.appNavBarPosition || LayoutNavBarPosition.left,
					showNavigationAlways: config.showNavigationAlways && !this.isMobile,
					panelBackground: config.panelBackground,
					toolbarButtons: {
						calendar: config.toolbarButtons?.calendar,
					},
					apex: config?.apex,
					chatBubbles: config.chatBubbles,
				};

				// Set the config form values without emitting an event
				// so that we don't end up with an infinite loop
				this.form.patchValue(this.fuseConfig, { emitEvent: false });

				const lightTheme = 'theme-default';
				const darkTheme = 'theme-blue-gray-dark';
				const isThemeExist = config.colorTheme === darkTheme || config.colorTheme === lightTheme;

				if (!isThemeExist) {
					this.form.patchValue({
						colorTheme: lightTheme,
					});
				}

				setTimeout(() => this.cdr.detectChanges());
			});

		this.updateInitialLayoutState();

		// Subscribe to the specific form value changes (layout.style)
		this.form
			.get('layout.style')
			.valueChanges.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(value => {
				// Reset the form values based on the
				// selected layout style
				this._resetFormValues(value);
			});

		// Subscribe to the form value changes
		this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
			// Update the config
			if (config.appNavBarPosition === 'above' || config.appNavBarPosition === 'Above') {
				config.appNavBarPosition = 'top';
			}

			if (config.appNavBarPosition === 'Right') {
				config.appNavBarPosition = 'right';
			}

			if (config.appNavBarPosition === 'Left') {
				config.appNavBarPosition = 'left';
			}

			this._layout.update(config);

			const body = {
				filterCommentsDependByLentaSettings: true,
				showDateInHumanFormat: config.showDateInHumanFormat,
				openFirstTaskInTableView: config.openFirstTaskInTableView,
				showIAndMeInsteadOfDisplayName: !config.showIAndMeInsteadOfDisplayName,
				translateCommentsInFeed: config.translateCommentsInFeed,
				userNameMode: config.userNameMode,
				showStartupPageOnLogoClick: true,
				userStartPageType: 'Default',
				viewCommentsInTaskState: config.viewCommentsInTaskState,
				viewTaskState: config.viewTaskState,
				fontSize: 'Middle',
				useStartUpPage: true,
				showNavigationAlways: config.showNavigationAlways,
				chatBubbles: config.chatBubbles,
				navigationPanelPosition: this.getNavPosition(config?.appNavBarPosition),
				navigationPanelColor: config?.panelBackground,
				colorTheme: mapThemeName(config.colorTheme),
			};

			this.cookieService.set(
				'CommentsViewMode',
				config.viewCommentsInTaskState === 'Right' ? 'right' : 'down',
				365,
				'/'
			);

			this.cookieService.set(
				'SyndLayout',
				config.viewTaskState === 'PopupWindow'
					? 'popup'
					: config.viewTaskState === 'Bottom'
					? 'horizontal'
					: 'vertical',
				365,
				'/'
			);

			combineLatest([
				this.resourceService.resolveCurrentResource('common'),
				this.server.users.postUiSettings(this.sessionUser.userId, body),
			])
				.pipe(take(1))
				.subscribe(([commonResource]) => {
					this.userSettingsStore.loadUserSettings();
					/**@see https://ru.1forma.ru/spa/tasks/1242199 */
					if (
						body.translateCommentsInFeed &&
						!this.userSettingsStore.selectUserSettings.userUISettings.translateCommentsInFeed
					) {
						this.modals.openInfo(commonResource?.commentsTranslationServiceMessage);
						this.form.get('translateCommentsInFeed').setValue(false);
					}
				});
		});

		// Add customize nav item that opens the bar programmatically
		// const customFunctionNavItem = {
		// 	id: 'custom-function',
		// 	title: 'Custom Function',
		// 	type: 'group',
		// 	icon: 'settings',
		// 	children: [
		// 		{
		// 			id: 'customize',
		// 			title: 'Customize',
		// 			type: 'item',
		// 			icon: 'settings',
		// 			function: () => {
		// 				this.toggleSidebarOpen('themeOptionsPanel');
		// 			}
		// 		}
		// 	]
		// };

		// this._fuseNavigationService.addNavigationItem(customFunctionNavItem, 'end');
	}

	getNavPosition(appNavBarPosition) {
		if (appNavBarPosition === 'top') {
			return 2;
		}

		if (appNavBarPosition === 'right') {
			return 1;
		}

		if (appNavBarPosition === 'left') {
			return 0;
		}

		return 2;
	}

	updateInitialLayoutState(): void {
		combineLatest([this.server.users.sessionUserSettings$, this._layout.state$])
			.pipe(take(1))
			.subscribe(([sessionUserSettings, config]) => {
				const updatedConfig = {
					...config,
					panelBackground: sessionUserSettings.userUISettings.navigationPanelColor,
					showNavigationAlways: sessionUserSettings.userUISettings.showNavigationAlways,
				};
				if (
					sessionUserSettings.userUISettings.navigationPanelPosition === 'above' ||
					sessionUserSettings.userUISettings.navigationPanelPosition === 'Above'
				) {
					updatedConfig.appNavBarPosition = 'top';
				}

				if (sessionUserSettings.userUISettings.navigationPanelPosition === 'Right') {
					updatedConfig.appNavBarPosition = 'right';
				}

				if (sessionUserSettings.userUISettings.navigationPanelPosition === 'Left') {
					updatedConfig.appNavBarPosition = 'left';
				}

				updatedConfig.colorTheme =
					mapThemeName(sessionUserSettings.userUISettings?.colorTheme) || updatedConfig.colorTheme;

				this._layout.update(updatedConfig);
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(0 as any);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Reset the form values based on the
	 * selected layout style
	 *
	 * @param value
	 * @private
	 */
	private _resetFormValues(value): void {
		switch (value) {
			// Vertical Layout #1
			case 'vertical-layout-1': {
				this.form.patchValue({
					layout: {
						width: 'fullwidth',
						navbar: {
							primaryBackground: 'fuse-navy-700',
							secondaryBackground: 'fuse-navy-900',
							folded: false,
							hidden: false,
							position: 'left',
							variant: 'vertical-style-1',
						},
						toolbar: {
							background: 'fuse-white-500',
							customBackgroundColor: false,
							hidden: false,
							position: 'below-static',
						},
						footer: {
							background: 'fuse-navy-900',
							customBackgroundColor: true,
							hidden: false,
							position: 'below-static',
						},
						sidepanel: {
							hidden: false,
							position: 'right',
						},
					},
				});

				break;
			}

			// Vertical Layout #2
			case 'vertical-layout-2': {
				this.form.patchValue({
					layout: {
						width: 'fullwidth',
						navbar: {
							primaryBackground: 'fuse-navy-700',
							secondaryBackground: 'fuse-navy-900',
							folded: false,
							hidden: false,
							position: 'left',
							variant: 'vertical-style-1',
						},
						toolbar: {
							background: 'fuse-white-500',
							customBackgroundColor: false,
							hidden: false,
							position: 'below',
						},
						footer: {
							background: 'fuse-navy-900',
							customBackgroundColor: true,
							hidden: false,
							position: 'below',
						},
						sidepanel: {
							hidden: false,
							position: 'right',
						},
					},
				});

				break;
			}

			// Vertical Layout #3
			case 'vertical-layout-3': {
				this.form.patchValue({
					layout: {
						width: 'fullwidth',
						navbar: {
							primaryBackground: 'fuse-navy-700',
							secondaryBackground: 'fuse-navy-900',
							folded: false,
							hidden: false,
							position: 'left',
							layout: 'vertical-style-1',
						},
						toolbar: {
							background: 'fuse-white-500',
							customBackgroundColor: false,
							hidden: false,
							position: 'above-static',
						},
						footer: {
							background: 'fuse-navy-900',
							customBackgroundColor: true,
							hidden: false,
							position: 'above-static',
						},
						sidepanel: {
							hidden: false,
							position: 'right',
						},
					},
				});

				break;
			}

			// Horizontal Layout #1
			case 'horizontal-layout-1': {
				this.form.patchValue({
					layout: {
						width: 'fullwidth',
						navbar: {
							primaryBackground: 'fuse-navy-700',
							secondaryBackground: 'fuse-navy-900',
							folded: false,
							hidden: false,
							position: 'top',
							variant: 'vertical-style-1',
						},
						toolbar: {
							background: 'fuse-white-500',
							customBackgroundColor: false,
							hidden: false,
							position: 'above',
						},
						footer: {
							background: 'fuse-navy-900',
							customBackgroundColor: true,
							hidden: false,
							position: 'above-fixed',
						},
						sidepanel: {
							hidden: false,
							position: 'right',
						},
					},
				});

				break;
			}
		}
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
	toggleSidebarOpen(key): void {
		this._fuseSidebarService.getSidebar(key).toggleOpen();
	}

	showShadowHandler(event): void {
		if (event.target.scrollTop) {
			this.showShadow$.next(true);
			return;
		}

		this.showShadow$.next(false);
	}
}
