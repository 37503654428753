import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ExternalPageGuard {
	constructor(readonly router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const stateFromUrl = this.router.getCurrentNavigation().extras?.state?.fromUrl;
		const url = new URL(Location.joinWithSlash(location.origin, stateFromUrl || ''));
		let redirectUrl = url.searchParams.get('u');
		redirectUrl = redirectUrl || route.queryParams.u;
		location.href = redirectUrl;
		return true;
	}
}
