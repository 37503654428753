import { enumWithNs } from '@valhalla/utils';
import { ActionCreator } from '@valhalla/core';
import { FacadeStoreNames } from '@spa/facade/store-names.enum';
import { IUserSettingsState } from './state';

const Actions = Object.freeze({
	updateUserSettings: 'updateUserSettings',
	loadUserSettings: 'loadUserSettings',
});

export const FacadeUserSettingsAction = enumWithNs(Actions, FacadeStoreNames.userSettings);

export const updateUserSettings: ActionCreator<Partial<IUserSettingsState>> = payload => ({
	type: FacadeUserSettingsAction.updateUserSettings,
	payload,
});

export const loadUserSettings: ActionCreator = payload => ({
	type: FacadeUserSettingsAction.loadUserSettings,
	payload,
});
