import { BehaviorSubject, combineLatest } from 'rxjs';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	HostBinding,
	ViewEncapsulation,
	ElementRef,
} from '@angular/core';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { SessionUser, ViewDestroyStreamService } from '@valhalla/core';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { IUser } from '@valhalla/data/entities';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';

const selector = 'vh-chat-nav-avatar';
let _avatarSessionUserLinkTimestamp = Date.now();

@Component({
	selector: selector,
	templateUrl: './chat-nav-avatar.component.html',
	styleUrls: ['./chat-nav-avatar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class ChatNavAvatarComponent implements OnInit {
	constructor(
		readonly sessionUser: SessionUser,
		readonly destroy$: ViewDestroyStreamService,
		readonly elRef: ElementRef<HTMLElement>
	) {}

	@Input()
	set users(val: Partial<IUser>[]) {
		this.users$.next(val || []);
	}
	get users() {
		return this.users$.value;
	}

	@HostBinding('class.vh-chat-nav-avatar')
	hostClassSelector = true;

	@Input()
	set userData(val: Partial<IUser>) {
		this.userData$.next(val);
	}
	get userData() {
		return this.userData$.value;
	}

	@Input()
	userId: number | string;

	@Input()
	sessionUserId: number;

	@Input()
	imgLoading: 'eager' | 'lazy' = 'eager';

	@Input()
	@HostBinding('style.height.px')
	@HostBinding('style.max-height.px')
	@HostBinding('style.min-height.px')
	@HostBinding('style.width.px')
	@HostBinding('style.max-width.px')
	@HostBinding('style.min-width.px')
	set sideLengthPx(value: number) {
		this._sideLengthPx = value;
	}
	get sideLengthPx() {
		if (this.forceSetSideLength) {
			return this._sideLengthPx;
		}

		return null;
	}

	@Input()
	forceSetSideLength = true;

	@Input()
	coloredText: string;

	@Input()
	icon: string;

	@Input()
	iconColor: string;

	@Input()
	iconBackground;

	@Input()
	isTaskPlaceholderMode: boolean;

	@Input()
	isChatPlaceholderMode: boolean;

	@Input()
	isGroupPlaceholderMode: boolean;

	@Input()
	@HostBinding('attr.show-color-text')
	set showColorText(val: boolean) {
		this._showColorText = val;
	}
	get showColorText() {
		if (this.isSessionUserAvatar) {
			return !this.sessionUser.hasAvatar;
		}
		return this._showColorText;
	}

	@Input()
	set preferColorText(val: boolean) {
		this._preferColorText = val;
	}
	get preferColorText() {
		if (this.isSessionUserAvatar) {
			return !this.sessionUser.hasAvatar;
		}
		return this._preferColorText;
	}

	@Input()
	@HostBinding('attr.status')
	status: string;

	@Input()
	@HostBinding('class.show-status')
	showStatus: boolean;

	@Input()
	set includeMeIfMultiple(val: boolean) {
		this.includeMeIfMultiple$.next(val);
	}
	get includeMeIfMultiple() {
		return this.includeMeIfMultiple$.value;
	}

	@Input()
	set biggerAvatarUserOrId(val: number | Partial<IUser>) {
		this.biggerAvatarUserId$.next(val);
	}
	get biggerAvatarUserOrId() {
		return this.biggerAvatarUserId$.value;
	}
	readonly biggerAvatarUserId$ = new BehaviorSubject<number | Partial<IUser>>(null);

	get me() {
		return this.users$.value.find(u => u.userId === this.sessionUserId || u.userId === this.sessionUser.data.id);
	}

	private _sideLengthPxDefault = 48;
	private _sideLengthPx: number = this._sideLengthPxDefault;
	private _preferColorText = false;

	get isSessionUserAvatar() {
		return this.userId === this.sessionUser.userId;
	}

	private _showColorText = false;

	get avatarSessionUserLinkTimestamp() {
		if (this.userId === this.sessionUser.userId) {
			return _avatarSessionUserLinkTimestamp;
		}
	}

	readonly includeMeIfMultiple$ = new BehaviorSubject(false);
	hasSubscribers = true;
	public readonly taskAvatarPlaceholderSrc = 'assets/images/placeholders/task.png';
	public readonly chatAvatarPlaceholderSrc = 'assets/images/placeholders/chat.png';
	public readonly groupAvatarPlaceholderSrc = 'assets/images/placeholders/group.png';
	isBrokenImage = false;

	users$ = new BehaviorSubject<Partial<IUser>[]>([]);
	userData$ = new BehaviorSubject<Partial<IUser>>(null);
	usersExceptMe$ = combineLatest([this.users$, this.includeMeIfMultiple$, this.userData$]).pipe(
		map(([users, includeMeIfMultiple, userData]) => {
			const exceptMe = includeMeIfMultiple ? users : this.excludeMeFromUsers(users);

			this.hasSubscribers = exceptMe.length > 0 || Boolean(userData);

			if (exceptMe.length < 2) {
				const avatarUser = exceptMe[0] ?? this.me;
				if (!avatarUser?.userAvatarFile && avatarUser?.userName) {
					this.coloredText = avatarUser.userName;
					this.preferColorText = true;
					this.userId = avatarUser.userId;
				}
			}

			if (exceptMe?.length) {
				return exceptMe;
			}

			if (userData) {
				return [userData];
			}

			return [];
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);
	usersLenExceptMe$ = this.usersExceptMe$.pipe(map(usersExceptMe => usersExceptMe.length));
	chatOnlyMe$ = this.usersExceptMe$.pipe(map(usersExceptMe => usersExceptMe.length === 0));
	usersLenExceptMeOnAvatar$ = this.usersLenExceptMe$.pipe(map(len => (len > 3 ? 3 : len)));
	usersExceptMeOnAvatar$ = combineLatest([
		this.usersExceptMe$,
		this.usersLenExceptMeOnAvatar$,
		this.biggerAvatarUserId$.pipe(
			map(bigAvatarUserId => {
				if (typeof bigAvatarUserId === 'object') {
					return bigAvatarUserId;
				}
				return this.users.find(u => u.userId === bigAvatarUserId);
			})
		),
	]).pipe(
		map(([usersExceptMe, len, biggerAvatarUser]) => {
			if (biggerAvatarUser) {
				const userAvatars = usersExceptMe
					.filter(u => u.userId !== biggerAvatarUser.userId)
					.sort((a, b) => b.userId - a.userId);
				return [biggerAvatarUser, ...userAvatars].slice(0, len);
			}
			return usersExceptMe.slice(0, len);
		})
	);
	isOneUserExceptMeOnAvatar$ = this.usersLenExceptMe$.pipe(map(len => len === 1));
	usersExceptMeOnAvatarCoords$ = this.usersExceptMeOnAvatar$.pipe(
		map(users => {
			const len = users.length;
			return users.map((user, idx) => {
				let top = 0,
					left = 0;
				let width = this.sideLengthPx,
					height = this.sideLengthPx;
				if (len === 3) {
					left = idx === 0 ? 0 : this.sideLengthPx / 2;
					top = idx === 0 || idx === 1 ? 0 : this.sideLengthPx / 2;
					width = this.sideLengthPx / 2;
					height = idx === 1 || idx === 2 ? this.sideLengthPx / 2 : this.sideLengthPx;
				}
				if (len === 2) {
					left = idx === 0 ? 0 : this.sideLengthPx / 2;
					top = 0;
					width = this.sideLengthPx / 2;
					height = this.sideLengthPx;
				}
				return {
					user: user,
					top: top,
					left: left,
					width: width,
					height: height,
				};
			});
		})
	);

	readonly needReloadAvatarWithSessionUser$ = this.sessionUser.update$.pipe(
		filter(_ => this.userId === this.sessionUser.userId)
	);
	readonly visible$ = new BehaviorSubject(true);

	ngOnInit() {
		this.usersLenExceptMeOnAvatar$.pipe(takeUntil(this.destroy$)).subscribe(len => {
			this.elRef.nativeElement.classList.add(`vh-chat-nav-avatar__pic-count-${len}`);
		});
		this.needReloadAvatarWithSessionUser$.pipe(takeUntil(this.destroy$)).subscribe(_ => this.redraw());
	}

	excludeMeFromUsers(users: Partial<IUser>[]): Partial<IUser>[] {
		return (users || []).filter(user => user.userId !== (this.sessionUserId || this.sessionUser.data.id));
	}

	trackByUserId(idx: number, data: any) {
		return data.user.userId;
	}

	redraw() {
		_avatarSessionUserLinkTimestamp = Date.now();
		this.visible$.next(false);
		setTimeout(() => {
			this.visible$.next(true);
		}, 30);
	}
}
