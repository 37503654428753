import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	BlockType,
	IDashboardsResultDto,
	IMobileContainerResultDto,
	IMobileMainMenuResultDto,
	ITaskSourceCriteria,
} from '@spa/data/entities';
import { Observable } from 'rxjs';
import { IEndpointUrlConfig, ITaskFeedsResult } from '..';
import { ApiVersion } from '../api-versions';
import { DataHttpCommonService } from '@spa/data/http';
import { EndpointUrlConfig } from '../endpoint.config';
import { MobileDataHttpService } from './abstract';
import { Router, UrlSerializer } from '@angular/router';

@Injectable()
export class MobileDataHttpServiceImpl implements MobileDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService,
		private router: Router,
		private serializer: UrlSerializer
	) {}

	private containersUrl = this.common.getApiUrl('mobile/containers', ApiVersion.v12);

	getFavoritesMenu(): Observable<IMobileContainerResultDto> {
		return this.http.post<IMobileContainerResultDto>(this.containersUrl, ['FavouritesMenu']);
	}

	getTabbar(): Observable<IMobileContainerResultDto> {
		return this.http.post<IMobileContainerResultDto>(this.containersUrl, ['TabBar']);
	}

	getMainMenu(): Observable<IMobileMainMenuResultDto> {
		return this.http.post<IMobileMainMenuResultDto>(this.containersUrl, [BlockType.mainMenu]);
	}

	getDashboards(): Observable<IDashboardsResultDto> {
		return this.http.post<IDashboardsResultDto>(this.containersUrl, [BlockType.dashboard]);
	}

	getDashboardById(id: string): Observable<IDashboardsResultDto> {
		return this.http.post<IDashboardsResultDto>(this.containersUrl, [id]);
	}

	getTaskSource(criteria: ITaskSourceCriteria): Observable<ITaskFeedsResult> {
		const queryParams: any = {};

		if (typeof criteria?.showLastComment === 'boolean') {
			queryParams.showLastComment = criteria?.showLastComment;
		}

		if (typeof criteria?.take === 'number') {
			queryParams.Take = criteria.take;
		}

		if (typeof criteria?.skip === 'number') {
			queryParams.Skip = criteria.skip;
		}

		if (typeof criteria?.v === 'string') {
			queryParams.v = criteria.v;
		}

		const tree = this.router.createUrlTree([''], { queryParams });
		const params = this.serializer.serialize(tree).replace('/', '');
		const url = this.common.getApiUrl(`/tasks/feeds/tasksource/${criteria.sourceId}${params}`, ApiVersion.v10);
		if (criteria?.extras) {
			return this.http.post<ITaskFeedsResult>(url, criteria?.extras);
		}
		return this.http.get<ITaskFeedsResult>(url);
	}
}
