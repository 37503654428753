import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentIconModule } from '../content-icon/content-icon.module';
import { SkeletonImgLoaderModule } from '../directives/skeleton-loader';
import { AvatarColorTextPipeModule } from '../pipes/avatar-color-text';

import { AvatarComponent } from './avatar.component';

@NgModule({
	imports: [CommonModule, AvatarColorTextPipeModule, ContentIconModule, SkeletonImgLoaderModule],
	exports: [AvatarComponent],
	declarations: [AvatarComponent],
})
export class NewAvatarModule {}
