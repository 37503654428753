<div *ngIf="userInfo" class="vh-common-user-mini-profile--wrapper">
	<div class="vh-common-user-mini-profile--panel">
		<div class="vh-common-user-mini-profile--panel-avatar">
			<vh-chat-nav-avatar
				mat-list-icon
				[userData]="userInfo"
				[coloredText]="userInfo?.displayName"
				[sideLengthPx]="80"
				[isGroupPlaceholderMode]="groupId"
			>
			</vh-chat-nav-avatar>

			<span *ngIf="isOnline(userInfo)" class="online"></span>
		</div>

		<div class="vh-common-user-mini-profile--panel-info">
			<div class="vh-common-user-mini-profile--panel-info-header">
				<h4 class="header-name" (click)="openUserProfile(userId || userInfo?.userId, $event)">
					{{ userInfo?.displayName || userInfo?.description }}
				</h4>
				<div *ngIf="appointment" class="header-main-appointment">{{ appointment }}</div>
				<div *ngIf="groupId" class="header-group-description">
					{{ groupDescription ? groupDescription : ('common.group' | resx) }}
				</div>
				<ng-container *ngIf="userOrgUnitsString?.length">
					<div *ngFor="let orgUnit of userOrgUnitsString" class="header-appointment">{{ orgUnit }}</div>
				</ng-container>
				<div *ngIf="phone">
					<a class="user-profile-phone" href="tel:+{{ phone }}" [tippy]="'common.call' | resx">
						<span>{{ phone }}</span>
					</a>
				</div>
				<div *ngIf="cellPhone">
					<a class="user-profile-phone" href="tel:+{{ cellPhone }}" [tippy]="'common.call' | resx">
						<span>{{ cellPhone }}</span>
					</a>
				</div>
				<div *ngIf="groupId && groupUsersLength">
					<span class="group-profile-members">
						{{ 'common.inGroupMembers' | resx }}:&nbsp; {{ groupUsersLength }}
					</span>
				</div>
				<div *ngIf="loadingUserInfo$ | async" class="header-appointment">{{ 'common.loadingOoo' | resx }}</div>
			</div>

			<div
				*ngIf="absence?.dateStart && absence?.dateEnd"
				class="user-absence"
				[style.border-left-color]="absence?.userColor"
				[vhUserStatus]="userInfo"
			>
				{{ absence?.typeName }}
				&nbsp;{{ dateFromString(absence.dateStart) | date: absenceDateFormat(absence.dateStart, absence.dateEnd) }}
				-
				{{ dateFromString(absence.dateEnd) | date: absenceDateFormat(absence.dateStart, absence.dateEnd) }}
			</div>
		</div>
	</div>

	<div class="vh-common-user-mini-profile--toolbar">
		<ng-container *ngIf="!groupId">
			<button
				*ngIf="isChatEnable"
				type="button"
				class="vh-btn vh-btn--icon-left toolbar-btn"
				(click)="openChatWithUser()"
				[disabled]="!spaBoot || (isSessionUser$ | async)"
			>
				<mat-icon svgIcon="vh-chats-24"></mat-icon>
				<span>{{ 'common.chat' | resx }}</span>
			</button>
			<button
				*ngIf="isVksEnabled$ | async"
				type="button"
				class="vh-btn vh-btn--icon-left toolbar-btn"
				(click)="jitsiLinkHandler($event)"
			>
				<mat-icon svgIcon="vh-video-call-24"></mat-icon>
				<span>{{ 'common.call' | resx }}</span>
			</button>
		</ng-container>
		<ng-container *ngIf="groupId">
			<button type="button" class="vh-btn toolbar-btn" (click)="openGroupMembersList(); $event.preventDefault()">
				<span>{{ 'common.openList' | resx }}</span>
			</button>
		</ng-container>
	</div>
</div>
