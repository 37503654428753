import { LocationStrategy } from '@angular/common';
import { ErrorHandler, Inject, Injectable, Optional, NgZone } from '@angular/core';
import { WINDOW } from '../dom';
import { ConfigurationProvider } from '../configuration';
import { AbstractLogger, LoggerFactory, StackTraceService } from '../diagnostics';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(
		protected readonly logSrv: LoggerFactory,
		protected readonly location: LocationStrategy,
		protected readonly configSrv: ConfigurationProvider,
		@Optional() private readonly _zone?: NgZone,
		@Optional()
		@Inject(WINDOW)
		private readonly _window?: Window,
		@Optional() private readonly _stack?: StackTraceService
	) {
		this.logger = logSrv.createLogger('GlobalErrorHandler');
		if (this._window) {
			this._window.onerror = (e: ErrorEvent) => {
				this._zone.runOutsideAngular(() => {
					this.handleError(e.error || e);
				});
				return false;
			};
			this._window.addEventListener('unhandledrejection', e => {
				this._zone.runOutsideAngular(() => {
					this.handleError(e, false);
				});
				e.preventDefault();
			});
		}
	}

	protected logger: AbstractLogger;
	private _lastGlobalError;

	handleError(error: any, needStack = true): void {
		this._lastGlobalError = error;
		if (this.isSkippedError(error)) {
			return;
		}
		if (this.isChunkLoadError(error)) {
			window.location.reload();
		}
		const message =
				error && error.message ? error.message : (error && error.toString && error.toString()) || String(error),
			url = this.location.path(),
			config = this.configSrv.config && this.configSrv.config.exceptions,
			throwError = !this.configSrv.isProd || !(config && config.ignoreUnhandled);

		const workingWithErrorDetails = details => {
			console.error((details && details.message) || 'Unhandled error occurred! See details.', details);
			// if (details.message) {
			// 	console.log(`https://stackoverflow.com/search?q=${encodeURIComponent(details.message)}`);
			// }
			// eat exception bad practice
			if (throwError && this._lastGlobalError !== error) {
				throw error;
			}
		};

		const logMsg = { message, url, stack: null };
		if (this._stack && needStack) {
			this._stack
				.fromError(error)
				.then(stackMsg => {
					logMsg.stack = stackMsg;
					workingWithErrorDetails(logMsg);
				})
				.catch(err =>
					workingWithErrorDetails({
						...logMsg,
						getStackError: err,
					})
				);
		} else {
			workingWithErrorDetails(logMsg);
		}
	}

	protected isChunkLoadError(e: Error) {
		return e?.message?.toLowerCase().indexOf('ChunkLoadError'.toLowerCase()) >= 0;
	}

	protected isSkippedError(e: Error) {
		// elements knowing bug @see https://github.com/angular/angular/issues/25525
		if (e && e.stack && e.stack.indexOf('destroyNode') > -1) {
			this.logger.warn(`see angular elements issue https://github.com/angular/angular/issues/25525`, String(e));
			return true;
		}
		return false;
	}
}

export const ngProviderGlobalErrorHandler = {
	provide: ErrorHandler,
	useClass: GlobalErrorHandler,
};
