import { Injector } from '@angular/core';

import { registerCommand } from '../spa-command';
import closeActiveModal from './close-active-modal';
import createOpenSyndicateCommand from './open-syndicate';
import openInContentArea from './open-in-content-area';
import openPortal from './open-portal';
import openFilePreview from './open-file-preview';
import openTask from './open-task';
import openModalIframe from './open-modal-iframe';
import navigate from './navigate';
import setAuthToken from './set-auth-token';
import click2call from './click-to-call';

export function registerCommands(injector?: Injector) {
	registerCommand('openSyndicate', createOpenSyndicateCommand(injector));
	registerCommand('closeActiveModal', closeActiveModal(injector));
	registerCommand('openInContentArea', openInContentArea(injector));
	registerCommand('openPortal', openPortal(injector));
	registerCommand('open-file-preview', openFilePreview(injector));
	registerCommand('openTask', openTask(injector));
	registerCommand('openModalIframe', openModalIframe(injector));
	registerCommand('navigate', navigate(injector));
	registerCommand('setAuthToken', setAuthToken(injector));
	registerCommand('click2call', click2call(injector));
}
