/* eslint-disable @typescript-eslint/member-ordering */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	inject,
	Input,
} from '@angular/core';
import { BehaviorSubject, combineLatest, map } from 'rxjs';

import { IBadgeColor, IBadgeEmphasis, IBageContent } from './badge.model';

@Component({
	selector: 'vh-badge',
	templateUrl: './badge.component.html',
	styleUrls: ['./badge.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
	readonly elRef = inject<ElementRef<HTMLElement>>(ElementRef);
	readonly cdr = inject(ChangeDetectorRef);

	//@HostBinding('class.vh-badge')
	//hostClassSelector = true;

	@Input()
	set value(val: string | number) {
		this.value$.next(val);
	}

	get value() {
		return this.value$.value;
	}

	@Input()
	isTooltip = true;

	@Input()
	tooltipValue = '';

	@Input()
	set locale(val: string) {
		this.locale$.next(val);
	}

	get locale() {
		return this.locale$.value;
	}

	// EMPHASIS
	@Input()
	emphasis: IBadgeEmphasis = IBadgeEmphasis.accent;

	@HostBinding('class.vh-badge--emphasis-accent')
	get isEmphasisAccent() {
		return this.emphasis === IBadgeEmphasis.accent;
	}

	@HostBinding('class.vh-badge--emphasis-container')
	get isEmphasisContainer() {
		return this.emphasis === IBadgeEmphasis.container;
	}

	// CONTENT
	@Input()
	content: IBageContent = IBageContent.number;

	@HostBinding('class.vh-badge--text')
	get isContentText() {
		return this.content === IBageContent.text;
	}

	@HostBinding('class.vh-badge--number')
	get isContentNumber() {
		return this.content === IBageContent.number;
	}

	// COLOR
	@Input()
	color: IBadgeColor = IBadgeColor.primary;

	@HostBinding('class.vh-badge--primary')
	get isColorPrimary() {
		return this.color === IBadgeColor.primary;
	}

	@HostBinding('class.vh-badge--info')
	get isColorInfo() {
		return this.color === IBadgeColor.info || this.color === IBadgeColor.accent;
	}

	@HostBinding('class.vh-badge--success')
	get isColorSuccess() {
		return this.color === IBadgeColor.success;
	}

	@HostBinding('class.vh-badge--warning')
	get isColorWarning() {
		return this.color === IBadgeColor.warning || this.color === IBadgeColor.fav;
	}

	@HostBinding('class.vh-badge--danger')
	get isColorDanger() {
		return this.color === IBadgeColor.danger || this.color === IBadgeColor.error;
	}

	@HostBinding('class.vh-badge--default')
	get isColorDefault() {
		return this.color === IBadgeColor.default;
	}

	@HostBinding('class.vh-badge--blackWhite')
	get isColorBlackWhite() {
		return this.color === IBadgeColor.blackWhite;
	}

	readonly isTooltipVisible$ = new BehaviorSubject(false);

	get isTooltipVisible() {
		return this.isTooltipVisible$.value && this.isTooltip;
	}

	readonly value$ = new BehaviorSubject(null);
	readonly locale$ = new BehaviorSubject('ru-RU');

	readonly numberValue$ = combineLatest([this.value$, this.locale$]).pipe(
		map(([val, locale]) => {
			const num = Number(val);
			const isRusLocale = locale === 'ru-RU';

			if (!num) {
				return null;
			}

			const roundFloor = (a, b) => Math.floor(a / b) * b;

			if (num > 9999) {
				const roundNum = roundFloor(num, 1000) / 1000;
				const result = `${roundNum}k`;

				if (isRusLocale) {
					return result?.replace('.', ',');
				}

				return result;
			}

			if (num > 999) {
				const roundNum = roundFloor(num, 100) / 1000;
				const result = `${roundNum}k`;

				if (isRusLocale) {
					return result?.replace('.', ',');
				}

				return result;
			}

			if (isRusLocale) {
				return val ? String(val)?.replace('.', ',') : '';
			}

			return val;
		})
	);

	onResize(event) {
		const badgeWidth = this.elRef.nativeElement?.clientWidth;
		const isTooltipVisible = event?.target?.scrollWidth > badgeWidth;
		this.isTooltipVisible$.next(isTooltipVisible);
		this.cdr.detectChanges();
	}
}
