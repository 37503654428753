export interface TreeNodeDatum {
	id: number;
	parentId: number | string;
	nodeType: TreeNodeType;
	name: string;
	checked?: boolean;
	selected?: boolean;
	children?: TreeNodeDatum[];
	data?: any;
	hidden?: boolean;
	alreadyLoadedChildren?: boolean;
	nodeIcon?: string;
	nodeIconColor?: string;
}

export enum TreeNodeType {
	root = 'root',
	parent = 'parent',
	leaf = 'leaf',
}

export interface SelectTreeDialogData {
	modalTitile?: string;
	initialTreeItems: TreeNodeDatum[];
	multiselect: boolean;
	hideSearch?: boolean;
	needConfirm?: boolean;
	hideSelectionCount?: boolean;
	hideCheckboxes?: boolean;
	updatedNodeItems?: TreeNodeDatum[];
	loadChildrenByParentNodeClick?: boolean;
	canSelectParentNodes?: boolean;
	canSelectSelected?: boolean;
	needSort?: boolean;
	disableSelectedChange?: boolean;
	needEmitClickItemWithChildren?: boolean;
	lineClampedCount?: number;
	spaceTreeMode: SpaceTreeMode;
	showSpaceMoveBtn?: boolean;
	subcatName?: string;
	isSpaceTree?: boolean;
	openFromSubcatId?: number;
	searchFilter?: (items: TreeNodeDatum[], filter: string) => TreeNodeDatum[];
}

export enum SpaceTreeMode {
	selectSpacePage = 'selectSpacePage',
	selectSpace = 'selectSpace',
}

export const SelectTreeDialogPanelClasses = ['col-lg-4', 'col-md-5', 'col-sm-6'];
