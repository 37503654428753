const isAvailable = typeof document !== 'undefined';

let txtAreaEl: HTMLTextAreaElement;

export function decodeText(text: string) {
	if (!isAvailable) {
		return text;
	}
	if (!txtAreaEl) {
		txtAreaEl = document.createElement('textarea');
	}
	txtAreaEl.innerHTML = text;
	return txtAreaEl.value;
}
