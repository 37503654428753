import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UnreadMessagesService } from './unread-messages.service';

@NgModule({
	imports: [],
	exports: [],
	providers: [],
})
export class UnreadMessagesModule {}
