<div *ngIf="useIcon" class="info-icon-wrapper">
	<vh-icon [name]="iconName" [color]="iconColor"></vh-icon>
</div>

<div class="alert-massage-content">
	<div class="alert-massage-content-text">
		<span #alertTitle *ngIf="title" class="vh-title-sm alert-massage-content-text--title" [style.color]="titleColor">
			{{ truncateText(title, 200) }}</span
		>
		<div
			#alertContent
			*ngIf="!useHtmlText"
			class="alert-massage-content-text--body vh-body-md"
			[style.color]="titleColor"
		>
			{{ (isAlertContentReady$ | async) ? alertText : '' }}
		</div>
		<div
			#alertContent
			*ngIf="useHtmlText"
			class="alert-massage-content-text--body vh-body-md"
			[innerHtml]="(isAlertContentReady$ | async) ? getSanitizedHTMLAlertText() : ''"
			[style.color]="titleColor"
		></div>
	</div>
	<div #btnWrapper *ngIf="useActionButtons" class="alert-massage-content-actions">
		<button class="action-btn main-btn vh-btn" (click)="onMainBtnClick()">
			<span>{{ mainBtnText }}</span>
		</button>
		<button class="action-btn second-btn vh-btn vh-btn--transparent" (click)="onSecondBtnClick()">
			<span [style.color]="titleColor">{{ secondBtnText }}</span>
		</button>
	</div>
</div>
<div *ngIf="useCloseIcon" class="close-icon-wrapper">
	<vh-icon class="close-icon" name="cross" [color]="IIconColor.onSurfaceSecondary" (click)="onCloseClick()"></vh-icon>
</div>
