import { arrayToObject, isNumber, isString } from '@valhalla/utils';
import { Effect, IAction, ofType } from '@valhalla/core';
import { filter, map } from 'rxjs/operators';

import * as actions from '../actions';
import { INavigationMenuItem, INavigationMenuState, INavigationState } from '../state';
import { NavigationEffectDependencies } from './dependency';

export const setActiveNavigationItemEffect: Effect<
	IAction<actions.ISetActiveNavigationItemPayload>,
	IAction,
	INavigationState,
	NavigationEffectDependencies
> = (actions$, state$, deps) => {
	return actions$.pipe(
		ofType(actions.FacadeNavigationAction.setActiveMenuItem),
		map(action => {
			const state = state$.value;
			const { byUrl, item, menuId } = action.payload;
			const stateMenu = state.menus[menuId] as INavigationMenuState;
			const menuItemsArr = Object.values(stateMenu.menuItems);
			// get active by current URL
			// or set by item prop if byUrl === false
			let nextActive: INavigationMenuItem;
			if (byUrl) {
				nextActive = deps.dataProvider.getActiveMenuItemByCurrentUrl(menuItemsArr);
			} else {
				if (isNumber(item) || isString(item)) {
					nextActive = stateMenu.menuItems[String(item)];
				} else {
					nextActive = stateMenu.menuItems[String(item.id)];
				}
			}
			return {
				nextActive,
				payload: action.payload,
				menuItemsArr,
			};
		}),
		// update only if nextActiveItem found
		filter(({ nextActive }) => Boolean(nextActive)),
		// map to UpdateAction
		map(({ payload, nextActive, menuItemsArr }) => {
			const { collapseNotParent } = payload;
			const groupsArr = menuItemsArr.filter(menuItem => deps.dataProvider.isGroup(menuItem));
			const groupsIndexing = arrayToObject(groupsArr, group => group.id);
			// fin all parent menu items for open
			const parentActive = deps.dataProvider.getAllParents(nextActive, groupsIndexing).map(parent => {
				return {
					id: parent.id,
					isOpen: true,
				};
			});
			// if need collapse others not parent set isOpen to false
			const collapseItems = [];
			if (collapseNotParent) {
				const parentActiveRecords = arrayToObject(parentActive, p => p.id);
				collapseItems.push(
					...groupsArr
						.filter(menuItem => !parentActiveRecords[menuItem.id])
						.map(menuItem => {
							return {
								id: menuItem.id,
								isOpen: false,
							};
						})
				);
			}
			// return update action
			return actions.updateNavigationItemActionCreator({
				menuId: payload.menuId,
				active: nextActive,
				items: [...parentActive, ...collapseItems],
			});
		})
	);
};
