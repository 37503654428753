import { Observable } from 'rxjs';

export abstract class PublicationsDataHttpService {
	abstract get<T = any>(criteria: IPublicationCriteria): Observable<T>;
	abstract post<T = any>(criteria: IPublicationCriteria): Observable<T>;
}

export interface IPublicationCriteria {
	alias: string;
	type: string;
	data?: any;
	queryParams?: Record<string, string>;
}
