import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageProvider, SessionUser, UrlProvider } from '@spa/core';
import { IProvidersEvents } from '@spa/data/http/provider-calendars';
import moment from 'moment';
import { BehaviorSubject, map, Subject, take, tap } from 'rxjs';

import { DataHttpService } from '../data/http';

@Injectable({ providedIn: 'root' })
export class MeetingNotificationsService {
	constructor(
		readonly url: UrlProvider,
		readonly storage: LocalStorageProvider,
		readonly router: Router,
		readonly server: DataHttpService,
		readonly sessionUser: SessionUser
	) {}

	readonly storageKey = `meeting-notifications/${this.sessionUser.userId}`;
	readonly notificationsStore$ = new BehaviorSubject([]);
	readonly updatedNotify$ = new Subject();

	get notificationsStore() {
		return this.notificationsStore$.value;
	}

	init() {
		this.initNotificationsStore();
		this.update();
	}

	update(complete?: (data?: any) => void) {
		const currentDay = moment().format('YYYY-MM-DD');

		return this.server.providersCalendars
			.getAgenda({
				userId: this.sessionUser.userId,
				from: currentDay,
				to: currentDay,
				includes: ['ProvidersEvents'],
			})
			.pipe(
				map(res => {
					const events = res?.map(e => e?.providersEvents).filter(Boolean) || [];

					return events.reduce((acc, el) => {
						return [...acc, ...el];
					}, []);
				}),
				tap(events => {
					const providersEvents: IProvidersEvents[] = events || [];
					const eventsForStore: IEventNotifications[] = providersEvents.map(e => {
						return {
							id: `${IEventType.providerEvent}${e?.key}`,
							key: e?.key,
							hash: `${IEventType.providerEvent}${e?.key}${e?.start}${e?.end}${e?.location}${e?.isCanceled}`,
							type: IEventType.providerEvent,
							dateFrom: e?.start,
							dateTo: e?.end,
							body: {
								title: e?.title,
								location: e?.location,
							},
							reminderDate: this.getReminderDate(e?.start),
							isShow: true,
							isCanceled: e?.isCanceled,
						};
					});

					// из локального стора удаляются события которые не пришли с сервера
					const store = this.storage.get(this.storageKey);
					this.syncLocalAndServerStore(store, eventsForStore);

					eventsForStore.forEach(eventForStore => {
						const store = this.storage.get(this.storageKey);
						const existsEvent = store.find(ns => ns?.id === eventForStore?.id);

						if (!existsEvent) {
							this.addNotification(eventForStore);
						}

						if (existsEvent && existsEvent?.hash !== eventForStore?.hash) {
							this.updateNotification(existsEvent, eventForStore);
						}
					});
				}),
				take(1)
			)
			.subscribe({
				next: () => {
					complete?.();
					this.updatedNotify$.next(0 as any);
				},
				error: err => complete?.(err),
			});
	}

	syncLocalAndServerStore(localStore: IEventNotifications[], serverStore: IEventNotifications[]) {
		const ls = localStore || [];
		const updatedLocalStore = ls.filter(lse => serverStore?.some(sse => sse?.id === lse?.id));
		this.setNotificationsToStore(updatedLocalStore);
	}

	addNotification(event: IEventNotifications) {
		const existState = this.storage.get(this.storageKey);
		this.setNotificationsToStore([...existState, event]);
	}

	deleteNotification(event: IEventNotifications) {
		const existState = this.storage.get(this.storageKey);
		const existStateFiltered = existState.filter(exist => exist?.id !== event.id);
		this.setNotificationsToStore(existStateFiltered);
	}

	deactivateNotification(event: IEventNotifications) {
		const newEvent = {
			...event,
			isShow: false,
		};

		this.updateNotification(event, newEvent);
	}

	updateNotification(prevEvent: IEventNotifications, newEvent: IEventNotifications) {
		const existState = this.storage.get(this.storageKey);
		const existStateFiltered = existState.filter(exist => exist?.id !== prevEvent?.id);
		this.setNotificationsToStore([...existStateFiltered, newEvent]);
	}

	initNotificationsStore() {
		const storeEvents = this.storage.get(this.storageKey);

		if (storeEvents) {
			this.setNotificationsToStore(storeEvents);
			return this.notificationsStore$.next(storeEvents);
		}

		this.setNotificationsToStore([]);
	}

	setNotificationsToStore(val: IEventNotifications[]) {
		if (!val) {
			return;
		}

		this.storage.set(this.storageKey, val);
		this.notificationsStore$.next(val);
	}

	getEventsForShow() {
		const currentDate = moment();
		const storeNotifications = this.storage.get(this.storageKey);

		const activeNotifications = storeNotifications.filter(n => {
			const reminderDate = moment(n.reminderDate, 'YYYY-MM-DDTHH:mm:ss');
			const dateEnd = moment(n.dateTo, 'YYYY-MM-DDTHH:mm:ss');
			return reminderDate.isBefore(currentDate) && dateEnd.isAfter(currentDate) && n.isShow && !n?.isCanceled;
		});

		return activeNotifications;
	}

	getReminderDate(date) {
		const start = moment(date, 'YYYY-MM-DDTHH:mm:ss');
		return moment(start).subtract(15, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
	}

	changeReminderDate(event: IEventNotifications, minutes: number) {
		let newReminderDate = null;

		if (minutes === 0) {
			newReminderDate = moment(event.dateFrom, 'YYYY-MM-DDTHH:mm:ss')
				.subtract(1, 'minutes')
				.format('YYYY-MM-DDTHH:mm:ss');
		} else {
			newReminderDate = moment().add(minutes, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
		}

		this.updateNotification(event, {
			...event,
			reminderDate: newReminderDate,
		});
	}
}

export interface IEventNotifications {
	id: string;
	key: string;
	hash: string;
	type: string;
	dateFrom: string;
	dateTo: string;
	body: {
		title?: string;
		location?: string;
	};
	reminderDate: string;
	isShow: boolean;
	isCanceled?: boolean;
}

export enum IEventType {
	providerEvent = 'providerEvent',
}

export enum StorageMeetingEvents {
	updatedNotify = 'meeting/updatedNotify',
	closeNotify = 'meeting/closeNotify',
}
