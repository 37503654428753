export function isHashInTaskId(taskNumber: string) {
	const first = taskNumber?.slice(0, 1);
	const end = parseInt(taskNumber?.slice(1));

	if (first === '#' && !isNaN(end)) {
		return true;
	}

	return false;
}
