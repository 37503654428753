import {
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ElementRef,
	HostBinding,
	Injector,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLinkType } from '@spa/common/components/navigation';
import { SpacesService } from '@spa/common/components/spaces/spaces.service';
import { TaskFavoriteComponent } from '@spa/common/components/task-favorite';
import { ModalWindowsService } from '@spa/facade/features/modals';
import {
	INavigationMenu,
	INavigationMenuItem,
	NavigationFeatureProvider,
	NavigationMenu,
} from '@spa/facade/features/navigation';
import { CategoryTreeNodeType, ICategoryDto } from '@spa/facade/features/navigation/providers/data-provider.dto';
import { ResourceService } from '@spa/localization/resource.service';
import { LocalStorageProvider, SessionUser, UrlProvider, ViewDestroyStreamService } from '@valhalla/core';
import { DataHttpService, FavoritesDataHttpService } from '@valhalla/data/http';
import { BooleanFilter, buildTrees, copyToClipboard, rxHandler, sortByLocaleCompare } from '@valhalla/utils';
import {
	BehaviorSubject,
	catchError,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	EMPTY,
	from,
	map,
	Observable,
	of,
	shareReplay,
	startWith,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs';

import {
	createSpaceTree,
	createSpecialSectionsTree,
	createTaskTreeWithoutSpecialSection,
	filterSpaceItems,
	getItemsForBuildTree,
	searchSpaceItems,
} from './space-panel.helper';
import { ISpaceItem } from './spaces-panel-item/spaces-panel-item.component';
import { SpacePanelService } from './spaces-panel.service';
import { SpacePageService } from '@spa/pages/spaces/space-page/space-page.service';

@Component({
	selector: 'vh-layout-spaces-panel',
	templateUrl: 'spaces-panel.component.html',
	styleUrls: ['spaces-panel.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class SpacesPanelComponent implements OnInit {
	constructor(
		readonly destroy$: ViewDestroyStreamService,
		readonly cdr: ChangeDetectorRef,
		readonly router: Router,
		readonly route: ActivatedRoute,
		readonly modal: ModalWindowsService,
		readonly url: UrlProvider,
		readonly favoritesService: FavoritesDataHttpService,
		readonly resourceService: ResourceService,
		readonly localization: ResourceService,
		readonly localStorage: LocalStorageProvider,
		readonly server: DataHttpService,
		readonly elRef: ElementRef<HTMLElement>,
		readonly dialog: MatDialog,
		readonly cfr: ComponentFactoryResolver,
		readonly injector: Injector,
		readonly spacePanelService: SpacePanelService,
		readonly sessionUser: SessionUser,
		readonly spacesService: SpacesService,
		readonly navigationFeature: NavigationFeatureProvider,
		readonly spacePageService: SpacePageService
	) {}

	@HostBinding('class.vh-layout-spaces-panel')
	hostClassSelector = true;

	@Input()
	tabSpacesClickNotify$: Observable<any>;

	readonly appConfig$ = this.server.config.getAppConfiguration();
	readonly spaceSettings$ = this.appConfig$.pipe(map(config => config.spaceSettings));
	readonly rootCatId$ = this.spaceSettings$.pipe(map(ss => ss?.rootCatId));

	readonly searchHandler = rxHandler<string>();
	readonly searchInput$ = from<string>(this.searchHandler as any).pipe(
		startWith('')
		// tap(() => {
		// 	this.scrollToSearchInput();
		// })
	);
	readonly searchInputHasValue$ = this.searchInput$.pipe(distinctUntilChanged(), map(BooleanFilter));

	// readonly subcategoriesTree$ = combineLatest([this.server.category.subcategoriesTree(), this.rootCatId$]).pipe(
	// 	map(([subcategoriesTree, rootCatId]) => {
	// 		// Категории с флагом isSpace - пространства, но они могут быть вложены
	// 		// в другие категории которые не являются пространством, поэтому
	// 		// получаем список пространств со всеми вложенными категориями
	// 		const items = getItemsForBuildTree(subcategoriesTree?.data, rootCatId);
	// 		return items;

	// 		//const filteredSpaces = this.filterSpaces(subcategoriesTree?.data);
	// 		//const spacesWithoutSystemSection = this.getSpacesWithoutSystemSection(items, rootCatId);
	// 		//return spacesWithoutSystemSection;
	// 	})
	// );

	readonly categoryWithSpaces$ = combineLatest([
		this.spacePanelService.subcategoriesTree$,
		this.searchInput$.pipe(debounceTime(100)),
		this.spacePageService.subcatId$.pipe(startWith(null)),
	]).pipe(
		map(([data, filter, subcatId]) => {
			return {
				data: filter ? searchSpaceItems(data, filter.toLowerCase()) : data,
				subcatId,
			};
		}),
		map(({ data, subcatId }) => {
			const result = createSpaceTree(data);
			this.updateOpenedState(result, subcatId);

			return result?.children;
		}),
		tap(() => {
			setTimeout(() => this.cdr.detectChanges());
		})
	);

	readonly activeSpacesMenu$ = new BehaviorSubject(null);
	spaceSettings = null;

	readonly disableSpaceClick$ = new BehaviorSubject(false);
	get disableSpaceClick() {
		return this.disableSpaceClick$.value;
	}

	ngOnInit() {
		this.spaceSettings$.pipe(takeUntil(this.destroy$)).subscribe(settings => {
			this.spaceSettings = settings;
		});

		this.tabSpacesClickNotify$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.deactivateSpaceMenu();
		});
	}

	updateOpenedState(tree, openedId) {
		const searchTarget = (el, id, key) => {
			let result = null;
			if (el[key] === id) {
				result = el;
			} else if (el?.children?.length) {
				el.children.some(node => {
					result = searchTarget(node, id, key);
					return result;
				});
			}
			return result;
		};

		const updateParentOpenedState = (tree, parentId) => {
			if (!parentId || parentId === 'root') {
				return;
			}

			const parentEl = searchTarget(tree, parentId, 'id');
			if (parentEl) {
				parentEl['isOpenedState'] = true;
				updateParentOpenedState(tree, parentEl?.parentId);
			}
		};

		const targetEl = searchTarget(tree, openedId, 'id');
		updateParentOpenedState(tree, targetEl?.parentId);
	}

	// createTree(data) {
	// 	const rootId: any = 'root';

	// 	data.forEach(item => {
	// 		if (!item.parentId) {
	// 			item.parentId = rootId;
	// 		}
	// 	});

	// 	data.push({
	// 		id: rootId,
	// 		name: 'Категории',
	// 		nodeType: CategoryTreeNodeType.root,
	// 		parentId: null,
	// 		tasksCounters: null,
	// 	});

	// 	const parentIdGetter = (i: ICategoryDto) =>
	// 		i.parentId === rootId || !i.parentId ? i.parentId : `Category${i.parentId}`;
	// 	const idGetter = (i: ICategoryDto) => (i.id === rootId || !i.id ? i.id : `${i.nodeType}${i.id}`);
	// 	const result = buildTrees(data, idGetter, parentIdGetter);
	// 	return result[0];
	// }

	// searchItems(filter, items) {
	// 	const result = items.filter(e => {
	// 		return e.nodeType === CategoryTreeNodeType.subcategory && e.name.toLocaleLowerCase().includes(filter);
	// 	});

	// 	const searchRootElements = (parentId): void => {
	// 		if (parentId === CategoryTreeNodeType.root || result.find(ti => ti.id === parentId)) {
	// 			return;
	// 		}
	// 		const parents = items.filter(e => e.id === parentId);
	// 		result.push(...parents);
	// 		parents.forEach(p => {
	// 			searchRootElements(p.parentId);
	// 		});
	// 	};

	// 	result.forEach(subcat => {
	// 		searchRootElements(subcat.parentId);
	// 	});

	// 	return result;
	// }

	// getItemsForBuildTree(items: any[], rootCatId) {
	// 	const spasesItems = items.filter(i => i?.isSpace);
	// 	const parentsItems = [];

	// 	const getParent = parentId => {
	// 		const parent = items.find(
	// 			i => i?.id === parentId && (i.nodeType === 'Category' || i.nodeType === CategoryTreeNodeType.root)
	// 		);
	// 		const isAdded = parentsItems.some(pi => pi?.id === parent?.id);

	// 		if (parent && !isAdded) {
	// 			parentsItems.push(parent);
	// 		}

	// 		if (parent?.parentId) {
	// 			getParent(parent.parentId);
	// 		}
	// 	};

	// 	spasesItems.forEach(i => {
	// 		getParent(i.parentId);
	// 	});

	// 	// rootCatId - системная категория для пространств
	// 	// для всех parentId === rootCatId ставим root, что бы не отображать уровень этой системной категории
	// 	const parentsItemsMapped = parentsItems
	// 		.filter(i => i?.id !== rootCatId)
	// 		.map(i => {
	// 			if (i.parentId === rootCatId || !i?.parentId) {
	// 				i.parentId = 'root';
	// 			}
	// 			return i;
	// 		});

	// 	const resArr = [...spasesItems, ...parentsItemsMapped];

	// 	return sortByLocaleCompare(resArr, el => el.name);
	// }

	// filterSpaces(items: any[]) {
	// 	if (!items?.length) {
	// 		return [];
	// 	}

	// 	return items.reduce((acc, item) => {
	// 		if (item?.id === 'root') {
	// 			return acc;
	// 		}

	// 		if (item?.isSpace) {
	// 			const children = this.filterSpaces(item?.children);

	// 			acc.push({
	// 				...item,
	// 				children,
	// 			});
	// 		}

	// 		if (item?.children?.length && item?.hasOwnProperty('isSpace')) {
	// 			const children = this.filterSpaces(item?.children);

	// 			if (children?.length) {
	// 				acc.push({
	// 					...item,
	// 					children,
	// 				});
	// 			}

	// 			return acc;
	// 		}

	// 		return acc;
	// 	}, []);
	// }

	// scrollToSearchInput() {
	// 	const scrollContainer = this.elRef.nativeElement?.closest('.navbar-scroll-container');
	// 	//scrollContainer.scrollTop = 0;
	// }

	deactivateSpaceMenu() {
		this.activeSpacesMenu$.next(null);
	}

	onSpaceItemClick(event: INavigationMenuItem | any) {
		if (this.disableSpaceClick) {
			return;
		}
		this.activeSpacesMenu$.next(event);
		this.disableSpaceClick$.next(true);

		return this.spacePanelService
			.getSpacesTree(event?.id)
			.pipe(take(1))
			.subscribe({
				next: items => {
					this.disableSpaceClick$.next(false);
					const firstTaskId = this.spacePanelService.getFirstSpaceId(items);
					return this.openSpacePage(event?.id, firstTaskId);
				},
				error: () => {
					this.disableSpaceClick$.next(false);
				},
			});
	}

	onAddSpaceClick(space: ISpaceItem) {
		return this.router.navigate([`/spaces/${space?.subcatId}/new`], { queryParams: { parentId: space?.taskId } });
	}

	openFavoritesModal(space: ISpaceItem) {
		return this.modal
			.openDialog(TaskFavoriteComponent, {
				data: {
					taskId: space.taskId,
					sessionUserId: this.sessionUser.userId,
					linkedFolders: [],
				},
			})
			.afterClosed()
			.pipe(take(1))
			.subscribe(() => {
				this.spacesService.update();
			});
	}

	copyToClipboard(data: any) {
		copyToClipboard(String(data));
	}

	openSettingsClick(item: INavigationMenuItem) {
		const url = this.url.getUrl(`/admin/subcategories/EditSubcatFrameset.aspx?SubcatID=${item.id}`);
		window.open(url, '_blank');
	}

	firstLetterToLowerCase(string: string): string {
		if (!string) {
			return null;
		}
		return string.charAt(0).toLowerCase() + string.slice(1);
	}

	addItemToFavorites(item: INavigationMenu, type: string) {
		const itemId = item?.id;

		let isCat = '';
		if (item.nodeType === 'Category') {
			isCat = 'iscat=true&';
		}

		let requestParams = {
			customImageClass: 'subcatFolder',
			customImagePath: null,
			folderId: null,
			isJsFunction: true,
			link: 'javascript:void(TCLeftTrees.openSyndicate("?' + isCat + 'PID=' + itemId + '&force=' + type + '"))',
			linkedObjectId: itemId + '',
			name: item.name,
			type: UserLinkType[this.firstLetterToLowerCase(item.nodeType)],
		};

		if (type === 'kanban') {
			requestParams = {
				customImageClass: 'subcatFolder',
				customImagePath: null,
				folderId: null,
				isJsFunction: true,
				link: 'spa/noframe/tasks/subcat/' + itemId + '/kanban',
				linkedObjectId: itemId + '',
				name: item.name,
				type: UserLinkType[this.firstLetterToLowerCase(item.nodeType)],
			};
		}

		this.favoritesService.addToFavorites(requestParams).subscribe(() => {
			this.navigationFeature.load([NavigationMenu.favorites, NavigationMenu.favoritesNew]);
		});
	}

	openSpacesNewPageClick() {
		this.activeSpacesMenu$.pipe(take(1)).subscribe(asm => {
			return this.router.navigate([`/spaces/${asm?.id}/new`]);
		});
	}

	openSpacePage(subcatId: number, pageId?: number) {
		if (location?.href?.includes(`/spaces/subcat/${subcatId}`)) {
			return;
		}

		return this.router.navigate([`/spaces/subcat/${subcatId}`], { queryParams: { pageId } });
	}

	openSpacesNewPage() {
		this.activeSpacesMenu$.pipe(take(1)).subscribe(asm => {
			return this.router.navigate([`/spaces/${asm?.id}/new`]);
		});
	}

	trackById(index: number, nav: INavigationMenu) {
		return nav.id;
	}
}
