import { Effect, IAction, ofType } from '@valhalla/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as actions from '../actions';
import { INavigationState } from '../state';
import { NavigationEffectDependencies } from './dependency';

export const loadFavoritesCountersEffect: Effect<IAction, IAction, INavigationState, NavigationEffectDependencies> = (
	actions$,
	state$,
	deps
) => {
	const logger = deps.loggerFactory.createLogger('loadFavoritesCountersEffect');

	return actions$.pipe(
		ofType(actions.FacadeNavigationAction.loadFavoriteCounters),
		switchMap(action => {
			return deps.dataProvider.getFavoriteCounters().pipe(
				map(data => {
					return actions.updateFavoriteCounters(data);
				}),
				catchError(e => {
					logger.error(e.message, e);
					return of(actions.loadErrorActionCreator({ error: e }));
				})
			);
		})
	);
};
