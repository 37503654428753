import { dateFromStringValhalla } from '@valhalla/utils';
import { IRecipientType } from '../http';
import { IUserStatus } from './user-status';

export function getUserId(u: Partial<IUser>) {
	return u?.id || u?.userId;
}

export interface IUser extends IUserShort {
	appointment: string;
	cellPhone: string | { value: string; title: string };
	city: string;
	companyName: string;
	country: string;
	description: string;
	email: string | { value: string; title: string };
	externalDisplayName: string;
	externalEmail: string;
	firstName: string;
	homePhone: string;
	jobTitle: string;
	lastName: string;
	managerUserId: number;
	managerVipUserId: number;
	phone: string | { value: string; title: string };
	phone2: string | { value: string; title: string };
	phone3: string | { value: string; title: string };
	room: string;
	sip: string | { value: string; title: string };
	userGroups: Record<number, string>;
	hasAvatar: boolean;
}

export interface IUserContact extends IUser {
	fullName: string;
	externalName: string;
	businessFunctions: string;
	positions: string[];
	name: string;
	id: number;
}
export interface IUserShort {
	isLastOnlineFromMobileApp: boolean;
	isOnline: boolean;
	lastOnlineTime: string;
	lastPersonalInfoUpdateTime: string;
	userId: number;
	userName: string;
	name?: string;
	displayName: string;
	hasAvatar?: boolean;
	absences?: IAbsence[];
	userStatus?: IUserStatus;
	isEmployee?: boolean;
	userState?: any;
	userAvatarFile?: {
		fileId: number;
		latestVersionId: number;
	};
	isFired?: boolean;
	id?: number;
	position?: string;
}

export interface IRecepientShort {
	type: IRecipientType;
	id?: number;
	isLastOnlineFromMobileApp?: boolean;
	isOnline?: boolean;
	lastOnlineTime?: string;
	lastPersonalInfoUpdateTime?: string;
	userId?: number;
	userName?: string;
	name?: string;
	displayName?: string;
	hasAvatar?: boolean;
	absences?: IAbsence[];
	userStatus?: IUserStatus;
	userIds?: number[];
	cantDelete?: boolean;
}

export interface IUserProfile {
	processAssistantOf: IProcessAssistant[];
	phone3Additional: string;
	sipWeb: string;
	phoneAdditional: string;
	homePhoneAdditional: string;
	fax: string;
	skype: string;
	twitter: string;
	computerName: string;
	phone2Additional: string;
	icq: string;
	timeZone: string;
	currentTime: string;
	coWorkers: ICoWorkers[];
	userEvents: IUserEvents[][];
	birthDate: string;
	userText: string;
	englishDisplayName: string;
	liveJournal: string;
	orgUnits: IOrgUnits[];
	workStartDate: string;
	directors: IDirectors[];
	hasSubordinates: boolean;
	helpers: IUserMobile[];
	helperOf: IHelper[];
	processAssistants: IProcessAssistant[];
	assistants: any;
	assistantOf: any;
	userId: number;
	displayName: string;
	firstName: string;
	lastName: string;
	middleName: string;
	cellPhone: string;
	homePhone: string;
	phone2: string;
	phone3: string;
	phone: string;
	email: string;
	hideBirthYear: boolean;
	telegram: string;
	externalEmail: string;
	externalDisplayName: string;
	country: string;
	city: string;
	room: string;
	appointment: string;
	lastOnlineTime: string;
	isLastOnlineFromMobileApp: boolean;
	lastPersonalInfoUpdateTime: string;
	companyName: string;
	jobTitle: string;
	pinCode: string;
	rating: number;
	pinCodeIssued: string;
	linkedId: number;
	branch: string;
	extCrmId: string;
	extUserId: string;
	sip: string;
	absences: IAbsence[];
	absenceMessage: string;
	userGroups: any;
	managerUserId: string;
	managerVipUserId: string;
	noteTaskId: number;
	noteSubCatId: number;
	description: string;
	userInfoExtParams: IUserInfoExtParams[];
	services: IServices[];
	counters: ICounters[];
	voipTokens: IVoipTokens[];
	jitsiServicePersonalRoom: any;
	gender?: boolean;
	hasAvatar?: boolean;
	userAvatarFile?: {
		fileId: number;
		latestVersionId: number;
	};
	timeZoneOffset: string;
	userOrgUnits: { isPrimary: boolean; units: IOrgUnits[] }[];
}

export interface IProcessAssistant {
	userPhone: IUserMobile;
	processName: string;
}

export interface IVoipTokens {
	appName: string;
	voipToken: string;
	isSandBox: boolean;
	addDated: string;
	userID: number;
}

export interface ICounters {
	userId: number;
	counterType: any;
	type: string;
	exists: boolean;
	active: boolean;
	changed: boolean;
	counter: number;
}

export interface ICoWorkers {
	birthDaySoon: string;
	displayName: string;
	isOnline: boolean;
	userID: number;
	weight: number;
	lastOnlineTime: string;
	onlineNow?: boolean;
	to?: string;
}

export interface IUserEvents {
	duration: boolean;
	title: string;
	place: string;
	categories: string;
	startTime: string;
	endTime: string;
	date: string;
	dateNative: string;
	link: string;
	eventTime: string;
	length?: any;
}

export interface IOrgUnits {
	name: string;
	id: number;
}

export interface IServices {
	id: string;
	title: string;
	isCRM: boolean;
	isLiked: boolean;
}

export interface IUserInfoExtParams {
	userInfoExt: string;
	userInfoExtValue: string;
}

export interface IDirectors {
	userId: 0;
	phone: string;
	displayName: string;
	additionalPhone: string;
}

export interface IUserMobile {
	userId: number;
	phone: string;
	additionalPhone: string;
}

export interface IAbsence {
	typeName: string;
	message: string;
	nonWorkingAbsence: boolean;
	dateStart: string;
	dateEnd: string;
	taskId: number;
	participants: IParticipants[];
	userColor: string;
	absenceType?: {
		id: number;
		isNonWorkingAbsence: boolean;
		name: string;
		showInAgenda: boolean;
		userColor: string;
	};
	id?: number;
	freeBusyStatus?: {
		id: 3;
		name: string;
		userColor: string;
	};
	absenceTypeUserColor?: string;
}

export interface IParticipants {
	displayName: string;
	userId: number;
	lastPersonalInfoUpdateTime: string;
}

export interface IHelper {
	userId: 0;
	phone: string;
	additionalPhone: string;
}

export interface IProcessAssistant {
	userPhone: IUserMobile;
	processName: string;
}

/**
 * timeZoneOffset смещение тайм зоны сессионного юзера (настройка в профиле)
 * example timeZoneOffset: '+03:00'
 */
export function isUserOnline(user: Partial<IUserShort>, timeZoneOffset = '') {
	if (user?.isOnline) {
		return true;
	}
	// check by 3 min left
	if (user?.lastOnlineTime) {
		let lastOnlineTime: number;
		if (timeZoneOffset && user.lastOnlineTime.includes('T') && !user.lastOnlineTime.includes('+')) {
			const withOffset = user.lastOnlineTime + timeZoneOffset;
			lastOnlineTime = new Date(new Date(withOffset).toUTCString()).getTime();
		} else {
			lastOnlineTime = dateFromStringValhalla(user.lastOnlineTime).getTime();
		}
		const isOnline = Date.now() - lastOnlineTime < 3 * 60 * 1000;
		return isOnline;
	}

	return false;
}

export function getActualAbsence(user: Partial<IUserShort>): IAbsence {
	const absences = user?.absences || user?.userStatus?.absences || user?.userState;

	if (Array.isArray(absences)) {
		if (!absences?.length) {
			return;
		}
		const sortedByLongestDateEnd = absences.sort((a, b) => {
			const aTime = dateFromStringValhalla(a.dateEnd)?.getTime();
			const bTime = dateFromStringValhalla(b.dateEnd)?.getTime();
			return aTime - bTime;
		});
		return sortedByLongestDateEnd[0] as any;
	}

	// в signatures/dynamic/${signatureId}/acceptors?taskId=${taskId} возвращается объект в поле userState

	if (!Array.isArray(absences) && absences?.absence) {
		return absences;
	}
}
