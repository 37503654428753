import { Observable, shareReplay } from 'rxjs';

export type CallbackScale = {
	posX: any;
	posY: any;
	rotation: any;
	scale: any;
	transform: string;
};

export type ScaleConfig = {
	scroll: boolean;
	scale: boolean;
	move: boolean;
	rotation: boolean;
};

export function createScaler(
	cbOrElement: ((params: CallbackScale) => void) | HTMLElement,
	options?: Partial<ScaleConfig>
) {
	// @see https://kenneth.io/post/detecting-multi-touch-trackpad-gestures-in-javascript
	const defaultRotation = 0;
	const defaultGestureStartRotation = 0;
	const defaultGestureStartScale = 0;
	const defaultScale = 1;
	const defaultPosX = 0;
	const defaultPosY = 0;
	let rotation = defaultRotation;
	let gestureStartRotation = defaultGestureStartRotation;
	let gestureStartScale = defaultGestureStartScale;
	let scale = defaultScale;
	let posX = defaultPosX;
	let posY = defaultPosY;
	let startX;
	let startY;
	const config: ScaleConfig = Object.assign(
		{
			scroll: false,
			scale: true,
			move: true,
			rotation: false,
		},
		options || {}
	);

	const reset = () => {
		rotation = defaultRotation;
		gestureStartRotation = defaultGestureStartRotation;
		gestureStartScale = defaultGestureStartScale;
		scale = defaultScale;
		posX = defaultPosX;
		posY = defaultPosY;
	};

	const render = () => {
		window.requestAnimationFrame(() => {
			const transform = `translate3D(${posX}px, ${posY}px, 0px) rotate(${rotation}deg) scale(${scale})`;
			if (typeof cbOrElement === 'function') {
				cbOrElement({
					posX,
					posY,
					rotation,
					scale,
					transform,
				});
			} else if (cbOrElement instanceof HTMLElement) {
				cbOrElement.style.transform = transform;
			}
		});
	};

	window.addEventListener('wheel', onWheel, { passive: false });

	window.addEventListener('gesturestart', gestureStart);

	window.addEventListener('gesturechange', gestureChange);

	window.addEventListener('gestureend', gestureEnd);

	function onWheel(e: any) {
		const toScale = (e.ctrlKey || e.metaKey) && config.scale;
		if (toScale || config.scroll) {
			e.preventDefault();
		}

		if (toScale) {
			scale = Math.max(0, scale - e.deltaY * 0.001);
		} else if (config.scroll) {
			posX -= e.deltaX * 2;
			posY -= e.deltaY * 2;
		}

		render();
	}

	function gestureStart(e: any) {
		e.preventDefault();
		startX = e.pageX - posX;
		startY = e.pageY - posY;
		gestureStartRotation = rotation;
		gestureStartScale = scale;
	}

	function gestureChange(e: any) {
		e.preventDefault();

		if (config.rotation) {
			rotation = gestureStartRotation + e.rotation;
		}

		if (config.scale) {
			scale = gestureStartScale * e.scale;
		}

		if (config.move) {
			posX = e.pageX - startX;
			posY = e.pageY - startY;
		}

		render();
	}

	function gestureEnd(e) {
		e.preventDefault();
	}

	const el = cbOrElement instanceof HTMLElement ? cbOrElement : null;
	if (el) {
		el.addEventListener('mousedown', onMouseDown);
		if (options.move) {
			el.ondragstart = () => false;
		}
	}

	function onMouseDown(e: MouseEvent) {
		if (e.button !== 0) {
			return;
		}
		startX = e.pageX - posX;
		startY = e.pageY - posY;
		el?.addEventListener('mouseup', onMouseUp);
		document.addEventListener('mousemove', onMouseMove);
	}

	function onMouseUp(e: MouseEvent) {
		document.removeEventListener('mousemove', onMouseMove);
		el?.removeEventListener('mouseup', onMouseUp);
	}

	function onMouseMove(e: MouseEvent) {
		if (config.move) {
			posX = e.pageX - startX;
			posY = e.pageY - startY;
		}
		render();
	}

	return {
		dispose() {
			window.removeEventListener('wheel', onWheel);
			window.removeEventListener('gestureend', gestureEnd);
			window.removeEventListener('gesturechange', gestureChange);
			window.removeEventListener('gesturestart', gestureStart);
			if (el) {
				el.removeEventListener('mousedown', onMouseDown);
				el.ondragstart = null;
			}
		},
		reset() {
			reset();
			render();
		},
	};
}

export type ResetRef = {
	reset?: () => void;
};

export function fromScaler(config?: {
	resetRef?: ResetRef;
	options?: Partial<ScaleConfig>;
	el?: HTMLElement;
}): Observable<CallbackScale> {
	return new Observable(observer => {
		const scaler = createScaler(
			config?.el
				? config?.el
				: arg => {
						observer.next(arg);
				  },
			config?.options
		);
		if (config?.resetRef) {
			config.resetRef.reset = () => scaler.reset();
		}
		return () => {
			scaler.dispose();
		};
	});
}
