import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NavigationCommonModule } from '@spa/common/components/navigation';
import { FavoritesCommentsModule } from '@spa/facade/layout/components/favorites-comments/favorites-comments.module';
import { VerticalSidebarMiniLayoutModule } from '@spa/facade/layout/vertical/sidebar-mini/sidebar-mini.module';

import { FavoritesPanelModule } from '../../../favorites-panel/favorites-panel.module';
import { FavoritesTasksModule } from '../../../favorites-tasks/favorites-tasks.module';
import { MyTasksNavLayoutPanelModule } from '../../../my-tasks-panel';
import { NavPanelAdminModule } from '../../../nav-panel-admin';
import { NavPanelEmailModule } from '../../../nav-panel-email';
import { NavPanelReportsModule } from '../../../nav-panel-reports';
import { NavbarVerticalStyle2Component } from './style-2.component';
import { LocalizationModule } from '@valhalla/localization';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MobileMainMenuModule } from '@spa/facade/layout/components/mobile-main-menu/mobile-main-menu.module';
import { SpacesPanelModule } from '../../../spaces-panel/spaces-panel.module';
import { TippyModule } from '@ngneat/helipopper';
import { ControlToggleModule } from '@spa/common/components/controls/control-toggle/control-toggle.module';
import { BadgeModule } from '@vh/core-components';

@NgModule({
	imports: [
		CommonModule,
		MatMenuModule,
		RouterModule.forChild([]),
		MatIconModule,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
		NavigationCommonModule,
		FavoritesPanelModule,
		VerticalSidebarMiniLayoutModule,
		MyTasksNavLayoutPanelModule,
		NavPanelEmailModule,
		NavPanelReportsModule,
		NavPanelAdminModule,
		FavoritesCommentsModule,
		FavoritesTasksModule,
		LocalizationModule,
		ReactiveFormsModule,
		FormsModule,
		MatDividerModule,
		MobileMainMenuModule,
		SpacesPanelModule,
		TippyModule,
		ControlToggleModule,
		BadgeModule,
	],
	declarations: [NavbarVerticalStyle2Component],
	exports: [NavbarVerticalStyle2Component],
})
export class NavbarVerticalStyle2Module {}
