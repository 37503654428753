import { Type } from '@angular/core';
import { Observable } from 'rxjs';

import { DatabaseAdapter } from './adapter';
import type { IEntity, ITable } from './adapter';

export type { IEntity, ITable };

/**
 * Migration description
 * set new version of schema and upgrade data
 */
export interface IDbMigration {
	/**
	 * Tables description, indexes description
	 * @see https://dexie.org/docs/TableSchema
	 * @see https://dexie.org/docs/Typescript
	 * @example
	 * {
	 *	contacts: '++id, first, last',
	 *	emails: '++id, contactId, type, email',
	 *	phones: '++id, contactId, type, phone',
	 * }
	 */
	schema: Record<string, string>;
	/**
	 * if you’d need to change the data architecture after migration
	 * @see https://dexie.org/docs/Tutorial/Design#database-versioning
	 */
	upgrade?: () => Promise<any>;
}

/**
 * Database versions descriptions
 * Do not delete previous versions!!! Just add next versions schemas
 * @see https://dexie.org/docs/Tutorial/Design#database-versioning
 */
export type DatabaseVersionsDescription = Record<number, IDbMigration>;

export interface IDatabaseOptions {
	addons?: Array<(db) => void>;
	autoOpen?: boolean;
	indexedDB?: IDBFactory;
	IDBKeyRange?: new () => IDBKeyRange;
}

export interface IDatabaseDescription {
	databaseName: string;
	options?: IDatabaseOptions;
	versions: DatabaseVersionsDescription;
}

export interface IDatabaseWithDescription {
	getDescription(): IDatabaseDescription;
}

export abstract class Database extends DatabaseAdapter implements IDatabaseWithDescription {
	abstract getDescription(): IDatabaseDescription;
}

export abstract class DatabaseFactory {
	abstract createDatabase<T extends Database>(dbClass: Type<T>, dbName?: string): Observable<T>;
}
