import { ApplicationRef } from '@angular/core';
import { RootRegistrationService } from '../root-registration-service';
import type { ModalWindowsService } from '@spa/facade/features/modals';
import { getAppTopInjector } from '@spa/api/injectors';
import { SpaCommandExecutorFactory } from '../spa-command.contract';

const createCommand: SpaCommandExecutorFactory = injector => {
	return function closeActiveModal(payload: any) {
		const inj = getAppTopInjector();
		const rootServices = inj.get(RootRegistrationService);
		const appRef = inj.get(ApplicationRef);
		const modalService: ModalWindowsService = rootServices.get('ModalWindowsService');
		setTimeout(() => {
			if (payload?.callCommand) {
				(window as any).spaCommand?.(payload.callCommand.name, payload.callCommand.payload);
			}

			appRef.tick();
		});
		return modalService.closeLast(payload);
	};
};

export default createCommand;
