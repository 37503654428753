import { spaCommand } from '@spa/api/spa-command';

import { GlobalEventHandler } from './type';

export const navigate: GlobalEventHandler = injector => {
	const eventName = 'spa:navigate';

	function onNavigateHandler(e: CustomEvent<{ taskId: number }>) {
		spaCommand('navigate', e?.detail);
	}

	window.addEventListener(eventName, onNavigateHandler);

	return () => {
		// dispose
		window.removeEventListener(eventName, onNavigateHandler);
	};
};
