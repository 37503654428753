import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoggerFactory } from '../diagnostics';
import { AuthService } from './abstract';

@Injectable()
export class Auth401Interceptor implements HttpInterceptor {
	constructor(readonly auth: AuthService, readonly logFactory: LoggerFactory, readonly zone: NgZone) {}

	readonly logger = this.logFactory.createLogger('401 Interceptor');

	readonly ignoreUrls = ['/api/auth/token-by-invitation-code', '/api/auth/token/code/password-recovery'];

	wasAuthorized: boolean;

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		//req.url
		return next.handle(req).pipe(
			tap({
				next: () => {
					if (req.method === 'POST') {
						this.wasAuthorized = true;
					}
				},
				error: async err => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 401 && !this.allowUrl(req.url)) {
							if (err.headers.get('1F-Auth-Factor-Key') && err.headers.get('1F-Auth-Factor-Key') !== '') {
								this.auth.getSecondFactor(
									err.headers.get('1F-Auth-Factor-Key'),
									req.params.get('login') || req.body?.login,
									req.params.get('providerId') || req.body?.providerId || err.headers.get('1F-Auth-Provider-Id')
								);
								return;
							}
							this.auth.clearToken();
							this.logger.error(String(err.message));
							if (!this.isCurrentPageSignIn()) {
								const needReload = this.wasAuthorized === true;
								this.zone.runTask(async () => {
									await this.auth.navigateToSignInPage();
									// reset page state see task #838522
									if (needReload) {
										location.reload();
									}
								});
							}
						}
					}
				},
			})
		);
	}

	allowUrl(url) {
		let isAllow = false;

		this.ignoreUrls.forEach(u => {
			if (url.includes(u)) {
				isAllow = true;
			}
		});

		return isAllow;
	}

	isCurrentPageSignIn() {
		const url = new URL(location.href);
		const isSignIn = ['entry/signup', 'entry/signin', '/entry/password-recovery', '/entry/multifactor'].some(path =>
			url.pathname.includes(path)
		);
		if (['/entry/password-recovery/code-'].some(path => url.pathname.includes(path))) {
			return false;
		}
		return isSignIn;
	}

	/*
	when open ru.1forma.ru root is /
	or ru.1forma.ru/spa root is base href /spa/
	*/
	isCurrentPageRoot() {
		const roots = ['/'];
		const baseHref = document.querySelector('base')?.getAttribute('href');
		if (baseHref) {
			roots.push(baseHref);
			if (baseHref.endsWith('/')) {
				roots.push(baseHref.substring(0, baseHref.length - 1));
			}
		}
		return roots.includes(new URL(location.href).pathname);
	}
}
