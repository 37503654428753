import { NavigationDataProvider } from '../providers';
import { LoggerFactory, UrlProvider } from '@valhalla/core';
import { Injector } from '@angular/core';

export abstract class NavigationEffectDependencies {
	abstract readonly dataProvider?: Partial<NavigationDataProvider>;
	abstract readonly urlProvider?: Partial<UrlProvider>;
	abstract readonly loggerFactory?: Partial<LoggerFactory>;
	abstract readonly injector?: Injector;
}
