import { Directive, ElementRef, NgModule, OnDestroy, OnInit, inject } from '@angular/core';
import { Subject, fromEvent, takeUntil } from 'rxjs';

@Directive({ selector: '[vhIgnoreClickSelection]' })
export class IgnoreClickelectionDirective implements OnInit, OnDestroy {
	elRef: ElementRef<HTMLElement> = inject(ElementRef);
	destroy$ = new Subject();

	ngOnInit(): void {
		fromEvent<PointerEvent>(this.elRef.nativeElement, 'click', { capture: true })
			.pipe(takeUntil(this.destroy$))
			.subscribe(e => {
				const sel = window.getSelection().toString();
				if (sel && this.elRef.nativeElement.contains(e.target as HTMLElement)) {
					e.preventDefault();
					e.stopPropagation();
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next({});
		this.destroy$.complete();
	}
}

@NgModule({
	exports: [IgnoreClickelectionDirective],
	declarations: [IgnoreClickelectionDirective],
})
export class IgnoreClickelectionDirectiveModule {}
