import { Injectable, Injector } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ITokenPayload } from './model';
import { TokenService } from './abstract';
import { AbstractLogger, LoggerFactory } from '../../diagnostics';
import { Subject } from 'rxjs';

@Injectable()
export class TokenServiceImpl implements TokenService {
	constructor(private readonly _injector: Injector) {}
	private readonly _tokenKey = '1F_AUTH_TOKEN';
	private _token: string;

	private _helper: JwtHelperService;
	protected get helper() {
		if (!this._helper) this._helper = this._injector.get(JwtHelperService);

		return this._helper;
	}

	private _logger: AbstractLogger;
	protected get logger(): AbstractLogger {
		if (!this._logger) {
			this._logger = this._injector.get(LoggerFactory).createLogger('TokenService');
		}
		return this._logger;
	}
	#updated$ = new Subject<any>();
	updated$ = this.#updated$.asObservable();

	get payload(): Partial<ITokenPayload> {
		return this.decodeToken();
	}

	isValid(token: string): boolean {
		try {
			this.decodeToken(token);
			return true;
		} catch (err) {
			return false;
		}
	}

	getToken() {
		if (!this._token) {
			this._token = localStorage && localStorage.getItem(this._tokenKey);
			queueMicrotask(() => {
				this.#updated$.next(this);
			});
		}

		return this._token;
	}

	setToken(token: string) {
		if (localStorage) localStorage.setItem(this._tokenKey, token);
		this._token = token;
		this.#updated$.next(this);
	}

	clearToken() {
		if (localStorage) localStorage.removeItem(this._tokenKey);
		this._token = null;
		this.#updated$.next(this);
	}

	isTokenExpired(token = this.getToken()) {
		try {
			if (!token) {
				return true;
			}
			return this.helper.isTokenExpired(token);
		} catch (err) {
			this.logger.error(err);
			return true;
		}
	}

	decodeToken(token = this.getToken()) {
		return token && this.helper.decodeToken(token);
	}

	getTokenExpirationDate(token = this.getToken()) {
		return this.helper.getTokenExpirationDate(token);
	}

	urlBase64Decode(str: string) {
		return this.helper.urlBase64Decode(str);
	}
}
