import { InjectionToken } from '@angular/core';

/**key - substring of url request */
export interface ICacheRequestConfig {
	[key: string]: {
		/** remove from cache after time */
		time: number;
	};
}

export const CACHE_REQUEST_CONFIG = new InjectionToken<ICacheRequestConfig>('Cache requests config');
