import { AbstractMessage, AbstractMessageType } from '@valhalla/core';

export class OpenAdminPanelEventType extends AbstractMessageType {
	readonly id = Symbol();
	readonly name = 'Open admin panel event';
}
export class OpenAdminPanelEvent extends AbstractMessage<null> {
	readonly type: OpenAdminPanelEventType = new OpenAdminPanelEventType();

	create() {
		return new OpenAdminPanelEvent();
	}
}
