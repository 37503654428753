import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ElementType } from '../element-type';

@Component({
	selector: 'vh-rename-modal',
	templateUrl: './rename-modal.component.html',
	styleUrls: ['./rename-modal.component.scss'],
})
export class RenameModalComponent implements OnInit, AfterViewInit {
	constructor(
		readonly dialogRef: MatDialogRef<RenameModalComponent>,
		@Inject(MAT_DIALOG_DATA) readonly dialogData: any
	) {}

	@ViewChild('focusedElement', { read: ElementRef })
	focusedElement: ElementRef;

	public folderName: UntypedFormControl;
	public renameLabel: string;

	ngOnInit(): void {
		this.folderName = new UntypedFormControl(this.dialogData?.value || '');
		this.renameLabel = this.dialogData.type === ElementType.folder ? 'Имя папки' : 'Имя ссылки';
	}

	ngAfterViewInit() {
		this.focusedElement.nativeElement.focus();
	}

	closeAndReturnFolderName() {
		this.dialogRef.close({
			folderName: this.folderName.value,
		});
	}

	close() {
		this.dialogRef.close();
	}
}
