import { Component, HostBinding, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger, MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';

@Component({
	selector: 'vh-common-context-menu-fixed-container',
	template: `
		<div
			style="position: fixed"
			[style.left]="x"
			[style.top]="y"
			[matMenuTriggerFor]="menu"
			[matMenuTriggerData]="data"
		></div>
	`,
	styleUrls: ['context-menu.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ContextMenuFixedContainerCommonComponent {
	@Input()
	x: string;
	@Input()
	y: string;
	@Input()
	menu: MatMenu;
	@Input()
	data: any;

	@ViewChild(MatMenuTrigger, { static: true })
	matMenuTrigger: MatMenuTrigger;

	@HostBinding('id')
	get id() {
		return 'vh-common-context-menu-fixed-container';
	}
}
