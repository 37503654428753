export enum LayoutStyles {
	vertical1 = 'vertical-layout-1',
	vertical2 = 'vertical-layout-2',
	vertical3 = 'vertical-layout-3',
	horizontal1 = 'horizontal-layout-1',
}

export enum LayoutWidth {
	fullWidth = 'fullwidth',
	boxed = 'boxed',
}

export enum LayoutSidePanelPosition {
	left = 'left',
	right = 'right',
}

export enum LayoutNavBarPosition {
	left = 'left',
	right = 'right',
	top = 'top',
}

export enum LayoutToolbarPosition {
	above = 'above',
	aboveStatic = 'above-static',
	aboveFixed = 'above-fixed',
	below = 'below',
	belowStatic = 'below-static',
	belowFixed = 'below-fixed',
}

export interface ILayoutNavbarState {
	primaryBackground: string;
	secondaryBackground: string;
	hidden: boolean;
	folded: boolean;
	position: LayoutNavBarPosition;
	variant: string;
}

export interface ILayoutToolbarState {
	customBackgroundColor: boolean;
	background: string;
	hidden: boolean;
	position: LayoutToolbarPosition;
}

export interface ILayoutFooterState {
	customBackgroundColor: boolean;
	background: string;
	hidden: boolean;
	position: LayoutToolbarPosition;
}

export interface ILayoutSidePanelState {
	hidden: boolean;
	position: LayoutSidePanelPosition;
}

export interface ILayoutState {
	colorTheme: string;
	fontSize: string;
	taskPane: string;
	fontSizePanel: string;
	customScrollbars: boolean;
	panelBackground: string;
	appNavBarPosition: string;
	showNavigationAlways: boolean;
	apex: boolean;
	modalInPlace?: boolean;
	chatBubbles?: boolean;
	newResources?: boolean;
	newResolutionsInterface: boolean;
	layout: Partial<{
		style: string;
		width: Partial<LayoutWidth>;
		navbar: Partial<ILayoutNavbarState>;
		toolbar: Partial<ILayoutToolbarState>;
		footer: Partial<ILayoutFooterState>;
		sidepanel: Partial<ILayoutSidePanelState>;
	}>;
	toolbarButtons: Partial<{
		calendar: boolean;
	}>;
	desktopNotifications: Partial<{
		unreadMessages: boolean;
		overdueTasks: boolean;
		awaitingSignature: boolean;
		questions: boolean;
		chats: boolean;
	}>;
}

export function createDefaultLayoutConfig(): ILayoutState {
	return {
		colorTheme: 'theme-default',
		customScrollbars: true,
		fontSize: 'font-md',
		taskPane: 'header_viewsettings_disabled',
		fontSizePanel: 'font-md-panel',
		panelBackground: 'none',
		appNavBarPosition: LayoutNavBarPosition.top,
		modalInPlace: false,
		chatBubbles: true,
		newResources: false,
		newResolutionsInterface: false,
		apex: false,
		showNavigationAlways: true,
		layout: {
			style: 'vertical-layout-1',
			width: LayoutWidth.fullWidth,
			navbar: {
				primaryBackground: 'fuse-navy-700',
				secondaryBackground: 'fuse-navy-900',
				folded: false,
				hidden: false,
				position: LayoutNavBarPosition.left,
				variant: 'vertical-style-2',
			},
			toolbar: {
				customBackgroundColor: false,
				background: 'fuse-white-500',
				hidden: false,
				position: LayoutToolbarPosition.belowStatic,
			},
			footer: {
				customBackgroundColor: true,
				background: 'fuse-navy-900',
				hidden: false,
				position: LayoutToolbarPosition.belowFixed,
			},
			sidepanel: {
				hidden: false,
				position: LayoutSidePanelPosition.right,
			},
		},
		toolbarButtons: {
			calendar: true,
		},
		desktopNotifications: {
			unreadMessages: false,
			overdueTasks: false,
			awaitingSignature: true,
			questions: false,
			chats: true,
		},
	};
}
