import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, take, tap } from 'rxjs/operators';

export type HasUnsaveChangesChecker = () => Observable<boolean> | boolean;
export enum UnsavedState {
	idle = 'idle',
	checking = 'checking',
	waitConfirm = 'waitConfirm',
}

@Injectable({ providedIn: 'root' })
export class UnsaveChangesService {
	protected checkers$ = new BehaviorSubject<HasUnsaveChangesChecker[]>([]);
	protected checkingState: UnsavedState = UnsavedState.idle;
	protected currentUnSaveResult$ = new Subject<boolean>();
	protected currentConfirmUnSaveResult$ = new Subject<boolean>();

	readonly currentUnSave$ = this.currentUnSaveResult$.pipe();
	readonly currentConfirm$ = this.currentConfirmUnSaveResult$.pipe();

	get state() {
		return this.checkingState;
	}

	get checking() {
		return [UnsavedState.checking, UnsavedState.waitConfirm].includes(this.checkingState);
	}

	get routerCheckers() {
		return this.checkers$.value;
	}

	get hasRouterCheckers() {
		return this.routerCheckers.length > 0;
	}

	get confirmMessage() {
		return 'У вас есть несохраненные данные, вы уверены, что хотите их отменить?';
	}

	addRouterChecker(...checkers: HasUnsaveChangesChecker[]) {
		const nextCheckers = [...this.routerCheckers, ...checkers];
		this.checkers$.next(nextCheckers);
	}

	removeRouterChecker(...checkers: HasUnsaveChangesChecker[]) {
		const nextCheckers = this.routerCheckers.filter(checker => !checkers.includes(checker));
		this.checkers$.next(nextCheckers);
	}

	hasUnsavedChanges(): Observable<boolean> {
		if (!this.routerCheckers?.length) {
			return of(false);
		}
		this.checkingState = UnsavedState.checking;
		const checkers$ = this.routerCheckers.map(checker => {
			const result = checker();
			if (result instanceof Observable) {
				return result.pipe(take(1));
			} else {
				return of(result);
			}
		});
		const check$ = checkers$.reduce((prevChecker$, checker$) =>
			prevChecker$.pipe(
				concatMap(result => {
					if (result) {
						return of(true);
					}
					return checker$;
				})
			)
		);
		return check$.pipe(
			tap(hasUnsaved => {
				this.checkingState = UnsavedState.idle;
				this.currentUnSaveResult$.next(hasUnsaved);
			}),
			catchError(err => {
				this.checkingState = UnsavedState.idle;
				console.error(err);
				return EMPTY;
			})
		);
	}

	hasUnsavedChangesSync(): boolean {
		if (!this.routerCheckers?.length) {
			return false;
		}
		this.checkingState = UnsavedState.checking;
		const result = this.routerCheckers
			.map(check => check())
			.filter(result => typeof result === 'boolean')
			.some(Boolean);
		this.checkingState = UnsavedState.idle;
		this.currentUnSaveResult$.next(result);
		return result;
	}

	confirmUnsave() {
		this.checkingState = UnsavedState.waitConfirm;
		return new Observable<boolean>(observer => {
			const result = this.confirmUnSaveSync();
			this.checkingState = UnsavedState.idle;
			observer.next(result);
			observer.complete();
		});
	}

	confirmUnSaveSync() {
		// TODO: localize
		const result = confirm(this.confirmMessage);
		queueMicrotask(() => {
			this.currentConfirmUnSaveResult$.next(result);
		});
		return result;
	}

	checkAndConfirm() {
		return this.hasUnsavedChanges().pipe(
			concatMap(hasUnsaved => (hasUnsaved ? this.confirmUnsave() : of(true))),
			take(1)
		);
	}

	setCurrentConfirm(result: boolean) {
		this.currentConfirmUnSaveResult$.next(result);
	}
}
