import { IUser, IChat, IComment, ILocation, ChatNotificationMode, ITableDataSource } from '@valhalla/data/entities';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { IGetCommentsOffsetCriteria, IGetCommentsOffsetResult } from '../comments/abstract';

export interface IRequetOptions {
	headers?: HttpHeaders | { [header: string]: string | string[] };
	observe?: 'body';
	params?: HttpParams | { [param: string]: string | string[] };
	reportProgress?: boolean;
	responseType?: 'json';
	withCredentials?: boolean;
}

// TODO: need expose to CommentsDataHttpService some methods
export abstract class ChatDataHttpService {
	abstract getCounters(userId?: number): Observable<IGetGetCountersResult>;
	abstract getContacts(criteria?: Partial<IGetContactsCriteria>): Observable<IUser[]>;
	abstract getChats(
		criteria?: Partial<IGetChatsCriteria>,
		options?: Partial<IRequetOptions>
	): Observable<ITableDataSource<IChat>>;
	abstract getComments(
		criteria: IGetCommentsCriteria,
		options?: Partial<IRequetOptions>
	): Observable<IGetCommentsResult>;
	abstract getCommentsOffset(
		criteria: IGetCommentsOffsetCriteria,
		options?: Partial<IRequetOptions>
	): Observable<IGetCommentsOffsetResult>;
	abstract sendComment(data: Partial<ISendComment>): Observable<ISendCommentResult>;
	abstract postComment(data: Partial<IPostComment>): Observable<Partial<IComment>>;
	abstract chatUnsubscribe(data: Partial<IChatUnsubscribe>): Observable<any>;
	abstract chatClose(data: Partial<IChatClose>): Observable<any>;
	abstract changeTaskText(data: Partial<IChangeTaskText>): Observable<any>;
	abstract createChat(data: Partial<IChatCreate>): Observable<IChatCreateResult>;
	abstract setNotificationMode(criteria: IChatSetNotificationModeCriteria): Observable<any>;
	abstract close(taskId: number): Observable<any>;
	abstract leave(taskId: number): Observable<any>;
}

export interface IGetGetCountersResult
	extends Array<{
		value: number;
		key: 'SubscribeCount' | 'UserChatCount' | 'GroupChatCount' | 'TotalChatCount';
	}> {}

export interface IChatSetNotificationModeCriteria {
	taskId: number;
	mode: ChatNotificationMode;
}

export interface IMarkCommentAsNotMyQuestion {
	commentId: number;
}

export interface IMarkCommentAsAnswered {
	commentID: number;
	commentText?: string;
	isForAll?: boolean;
}

export interface IChatCreate {
	private?: boolean;
	chatGuid: string;
	subscribers: number[];
	messageGuid?: string;
	text?: string;
	location?: any;
	files?: number[];
	preuploadedFiles?: number[];
	ownerId?: number;
	subcatId?: number;
	subscribeGroupsIds?: number[];
}

export interface IChatCreateResult {
	chatId: number;
	commentId: number;
}

export interface IChangeTaskText {
	taskId: number;
	taskText: string;
}

export interface IChangeTaskAvatar {
	taskId: number;
	base64: string;
	fileName: string;
}

export interface IChatClose {
	taskId: number;
}

export interface IChatUnsubscribe {
	userId: number;
	taskId: number;
}

export interface IGetContactsCriteria {
	userName: string;
}

export interface IGetCommentsCriteria {
	taskId: number;
	maxCommentId: number;
	maxCommentsToReturn: number;
}

export interface IGetChatsCriteria {
	chatIds: number[];
	limit?: number;
	offset?: number;
	queryString?: string;
	subcatIds?: number[];
}

export interface IGetCommentsResult {
	comments: Array<Partial<IComment>>;
	hasMoreComments: boolean;
}

export interface ISendComment {
	comment: string;
	copyRecipientsIds: number[];
	fileIds: number[];
	inReplyToCommentId: number;
	isChat: boolean;
	needsAnswer: boolean;
	recipientIds: number[];
	taskId: number | string;
	guid: string;
	location?: ILocation;
}

export interface ISendCommentResult {
	commentId: number;
}

export interface IPostCommentResult {
	WasOffline: boolean;
	allsubscribers: string;
	isPrevious: boolean;
	isTaskClosed: boolean;
	isTaskExists: boolean;
	isUploadForbidden: boolean;
	isUserSubscriber: boolean;
	updatedComments: [{ id: number }, any];
	uploadForbidReason: string;
}

export interface IPostComment {
	guid: string;
	addToQuickReply: boolean;
	chatMode: boolean;
	commentText?: string;
	/**"6771;6776..." */
	copyRecipientGroups: string;
	/**"6771;6776..." */
	copyRecipientsList: string;
	/**"6771;6776..." */
	fileIDs: string;
	forTaskTree: boolean;
	forceEmail: boolean;
	forceRefreshRecipients: boolean;
	forwardedCommentID: number;
	inReplyToCommentID: number;
	location: ILocation;
	maxCommentId: number;
	needSubscribe: boolean;
	needSubscriberOthers: boolean;
	needsAnswer: boolean;
	/**"6771;6776..." */
	recipientGroups: string;
	/**"6771;6776..." */
	recipientsList: string;
	rootPath: string;
	sendSms: boolean;
	taskId: number | string;
	isAllCommentsFilterChecked?: boolean;
	SetAnsweredOnlyForMe?: boolean;
	commentRequest?: boolean;
	includeText?: boolean;
	forwardedCommentsIds?: number[];
	postFromCommentFeed?: boolean;
}

export interface IAddComment {
	guid?: string;
	addToQuickReply?: boolean;
	chatMode?: boolean;
	commentText?: string;
	copyRecipientGroupsIds?: number[];
	copyRecipientsIds?: number[];
	fileIds?: number[];
	forceEmail?: boolean;
	forwardedCommentId?: number;
	inReplyToCommentId?: number;
	location?: ILocation;
	maxCommentId?: number;
	needSubscribe?: boolean;
	needSubscriberOthers?: boolean;
	needsAnswer?: boolean;
	recipientGroupsIds?: number[];
	recipientIds?: number[];
	sendSms?: boolean;
	taskId?: number | string;
	silentComment?: boolean;
	SetAnsweredOnlyForMe?: boolean;
	commentRequest?: boolean;
	forwardedCommentsIds?: number[];
	includeText?: boolean;
	postFromCommentFeed?: boolean;
}
