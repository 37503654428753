import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'chatAvatarLetters' })
export class ChatAvatarLetterPipe implements PipeTransform {
	transform(str: string, len = 2): string {
		if (!str) {
			return '';
		}
		const arr = str.split(' ').filter(word => Boolean(word.trim()));
		const arrLettersOnly = arr.map(this.getLettersOnly);

		let result = '';
		if (arrLettersOnly.length === 1) {
			result = arr[0].substr(0, len);
		} else {
			result = arrLettersOnly
				.slice(0, len)
				.map(word => word && word[0])
				.filter(Boolean)
				.join('');
		}

		return (result && result.trim()) || 'AV';
	}

	getLettersOnly(word: string) {
		word = word && word.replace(/[&\/\\#,+()$~%.'":*?<>{}@]/g, '');
		return word;
	}
}

@NgModule({
	declarations: [ChatAvatarLetterPipe],
	exports: [ChatAvatarLetterPipe],
})
export class ChatAvatarLetterPipeModule {}
