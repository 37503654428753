import { Inject, Injectable } from '@angular/core';
import { ASSET_INVALIDATE_KEY, UrlProvider } from '@spa/core';
import { from, mergeMap, Observable, shareReplay, take } from 'rxjs';
import { ImageOptimizer } from './image-optimizer';

@Injectable({ providedIn: 'root' })
export class CompressorjsOptimizer implements ImageOptimizer {
	constructor(
		@Inject(ASSET_INVALIDATE_KEY) protected readonly assetInvalidateKey: string,
		protected readonly urlBuilder: UrlProvider
	) {}

	protected readonly optimizer$ = from(import('compressorjs').then(m => m.default)).pipe(
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	optimize(image: File, options?: Record<any, any>): Observable<File> {
		options = {
			quality: 0.6,
			mimeType: 'image/webp',
			...(options || {}),
		};
		return this.optimizer$.pipe(
			mergeMap(Compressor =>
				from<Promise<File>>(
					new Promise((res, rej) => {
						options.success = res;
						options.error = rej;
						const ref = new Compressor(image, options);
						return () => {
							ref.abort();
						};
					})
				)
			),
			take(1)
		);
	}
}
