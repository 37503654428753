import { isNumber, isObject } from '@valhalla/utils';
import { ExtractPayloadType, IAction, produce, ReducerBase } from '@valhalla/core';

import * as actions from '../actions';
import { IUserQuickAppsState } from '../state.contract';

type PayloadType = ExtractPayloadType<typeof actions.removeQuickAppsToUser>;

export class RemoveQuickAppToUserReducer implements ReducerBase {
	readonly actionType = actions.userQuickAppsAction.removeQuickAppsFromUser;

	reduce(state: IUserQuickAppsState, { payload }: IAction<PayloadType>) {
		return produce(state, ds => {
			for (const removeDesc of payload) {
				if (isNumber(removeDesc)) {
					ds.apps = ds.apps.filter(app => app.appId !== removeDesc);
				}
				if (isObject(removeDesc)) {
					ds.apps = ds.apps.filter(app => app.appId !== removeDesc.appId);
				}
			}
		});
	}
}
