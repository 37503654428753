import { ComponentType } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
	ApplicationRef,
	ComponentFactoryResolver,
	inject,
	Inject,
	Injectable,
	Injector,
	NgZone,
	Optional,
	Renderer2,
	RendererFactory2,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
	MatLegacySnackBar as MatSnackBar,
	MatLegacySnackBarConfig as MatSnackBarConfig,
	MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { getAppTopInjector } from '@spa/api/injectors';
import { RootRegistrationService } from '@spa/api/root-registration-service';
import { ModalContentAreaService } from '@spa/common/modal-content-area';
import { HotKeysService } from '@spa/common/services/hotkeys.service';
import { LayoutFacade } from '@spa/facade/layout';
import { UnsaveChangesService } from '@spa/guards/unsave-changes.service';
import { ConfirmModalCommonComponent } from '@valhalla/common/components/confirm-modal';
import {
	AbstractLogger,
	CoreComponentFactoryResolver,
	Downloader,
	LoggerFactory,
	PlatformDetectorProvider,
	SessionUser,
	UrlProvider,
} from '@valhalla/core';
import { MimeType } from '@valhalla/data/entities';
import { decodeText, isFunction, isString } from '@valhalla/utils';
import { firstValueFrom, forkJoin, from, fromEvent, Observable, of } from 'rxjs';
import { exhaustMap, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import {
	IDialogConfig,
	IModalRef,
	IOpenIFrame,
	MODAL_OPEN_TILL,
	ModalWindowsService,
	IModalOpenTask,
	IModalOpenGeneric,
	IModalOpenProject,
	IModalCreateTask,
	IModalOpenSpace,
} from './abstract';
import { IConfirmDialogClose } from './confirm-dialog-close';
import { FacadeModalConfirmComponent } from './confirm/modal-confirm.component';
import { FacadeModalErrorComponent } from './error/modal-error.component';
import { FileViewerComponent } from './file-viewer';
import { FacadeModalInfoComponent } from './info/modal-info.component';
import { ModalGenericComponent } from './modal-generic';
import { FacadeModalIframeComponent } from './modal-iframe/modal-iframe.component';
import { FacadeModalPromptComponent } from './prompt/modal-prompt.component';
import { ISnackRef } from './snack-bar.interface';
import { Router } from '@angular/router';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { UserSettingsFacadeProvider } from '../user-settings/providers/abstract';
import { ConfigurationDataHttpService } from '@spa/data/http';

let beenPopupOpenedBeforeEsc = false;
const hasPopupOpened = () => !!document.querySelector('.ag-popup');
fromEvent<KeyboardEvent>(window, 'keydown', { capture: true }).subscribe(e => {
	if (e.key === 'Escape') {
		beenPopupOpenedBeforeEsc = hasPopupOpened();
	}
});
const MatDialogRefCloseFn = MatDialogRef.prototype.close;
MatDialogRef.prototype.close = function (...args) {
	if (beenPopupOpenedBeforeEsc && (window.event as any)?.key === 'Escape') {
		beenPopupOpenedBeforeEsc = false;
		return;
	}
	const componentInstance: IConfirmDialogClose = (this as MatDialogRef<any, any>).componentInstance;
	if (typeof componentInstance?.confirmDialogClose === 'function') {
		const confirmClose = componentInstance.confirmDialogClose(...args);
		if (confirmClose) {
			const confirm = getAppTopInjector().get(UnsaveChangesService).confirmUnSaveSync();
			if (!confirm) {
				return;
			}
		}
	}
	return MatDialogRefCloseFn.call(this, ...args);
};

@Injectable()
export class ModalWindowsServiceImpl implements ModalWindowsService {
	constructor(
		readonly dialog: MatDialog,
		readonly snackBar: MatSnackBar,
		readonly cfr: CoreComponentFactoryResolver,
		readonly platformDetector: PlatformDetectorProvider,
		readonly lf: LoggerFactory,
		readonly rendererFactory: RendererFactory2,
		@Inject(DOCUMENT) readonly document: Document,
		readonly appRef: ApplicationRef,
		readonly urlProvider: UrlProvider,
		readonly registerService: RootRegistrationService,
		readonly modalContent: ModalContentAreaService,
		readonly layout: LayoutFacade,
		readonly ngZone: NgZone,
		readonly downloader: Downloader,
		readonly mobileView: MobileViewService,
		readonly configurationData: ConfigurationDataHttpService,
		readonly sessionUser: SessionUser,
		@Inject(MODAL_OPEN_TILL) @Optional() openTill$?: Observable<any>
	) {
		this.openTill$ = openTill$;
		registerService.register(this, 'ModalWindowsService');
		dialog.afterOpened
			.pipe(exhaustMap(() => this.hotkey.ctrl_e$.pipe(takeUntil(dialog.afterAllClosed))))
			.subscribe(() => {
				this.closeLast();
			});
	}

	readonly hotkey = inject(HotKeysService);
	readonly logger: AbstractLogger = this.lf.createLogger('ModalService');
	readonly renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);
	readonly cssClassMatDialogPad0 = 'mat-dialog-pad-0';
	protected openTill$: Observable<any>;
	protected TaskCardComponentClass: any;
	protected ProjectGanttComponentClass: any;
	protected readonly userSettingsStore = inject(UserSettingsFacadeProvider);
	readonly injector = inject(Injector);

	withOpenTill(openTill$: Observable<any>): ModalWindowsService {
		const modalService = new ModalWindowsServiceImpl(
			this.dialog,
			this.snackBar,
			this.cfr,
			this.platformDetector,
			this.lf,
			this.rendererFactory,
			this.document,
			this.appRef,
			this.urlProvider,
			this.registerService,
			this.modalContent,
			this.layout,
			this.ngZone,
			this.downloader,
			this.mobileView,
			this.configurationData,
			this.sessionUser
		);
		modalService.openTill$ = openTill$;
		return modalService;
	}

	openConfirmModal(data, settings = {}) {
		const dialogRef = this.openDialog(ConfirmModalCommonComponent, { data: { ...data }, ...settings });
		return dialogRef;
	}

	openDialog(
		template: ComponentType<any> | TemplateRef<any>,
		settings: IDialogConfig,
		componentFactoryResolver?: ComponentFactoryResolver,
		injector?: Injector
	): IModalRef {
		if (componentFactoryResolver) {
			this.cfr.addComponentFactoryResolver(componentFactoryResolver);
		}

		let dialogRef: any;
		settings = settings || {};
		settings.autoFocus = typeof settings.autoFocus === 'boolean' ? settings.autoFocus : false;

		if (injector) {
			settings.viewContainerRef = injector.get(ViewContainerRef, null);
		}
		if (componentFactoryResolver) {
			settings.componentFactoryResolver = componentFactoryResolver;
		}

		this.ngZone.runTask(() => {
			dialogRef = this.dialog.open(template, { ...settings });
			const openTill$ = this.openTill$ || settings?.openTill$ || injector?.get(MODAL_OPEN_TILL, null);
			if (openTill$) {
				openTill$.pipe(take(1), takeUntil(dialogRef.afterClosed())).subscribe(() => {
					dialogRef.close();
				});
			}
		});

		return <IModalRef>{
			componentRef: dialogRef,
			afterOpened: () => dialogRef.afterOpened(),
			afterClosed: () => dialogRef.afterClosed(),
			beforeClosed: () => dialogRef.beforeClosed(),
			backdropClick: () => dialogRef.backdropClick(),
			keydownEvents: () => dialogRef.keydownEvents(),
			close: (result: any) => {
				dialogRef.close(result);
				this.appRef.tick();
			},
			closeWhen: (source$: Observable<any>) => {
				if (source$) {
					source$.pipe(take(1), takeUntil(dialogRef.afterClosed())).subscribe(() => {
						dialogRef.close();
						this.appRef.tick();
					});
				}
				return dialogRef.afterClosed();
			},
		};
	}

	getActiveModal(): MatDialogRef<any> {
		return this.dialog.openDialogs[this.dialog.openDialogs.length - 1];
	}

	closeAll() {
		return this.dialog.closeAll();
	}

	closeLast(data?: any): boolean {
		const last = this.dialog.openDialogs[this.dialog.openDialogs.length - 1];
		if (last) {
			last.close(data);
			return true;
		}
		return false;
	}

	closeAllButLast(): void {
		const last = this.dialog.openDialogs[this.dialog.openDialogs.length - 1];
		for (const dialog of this.dialog.openDialogs) {
			if (dialog !== last) {
				dialog.close();
			}
		}
	}

	closeAllButFirst(): void {
		const first = this.dialog.openDialogs[0];
		for (const dialog of this.dialog.openDialogs) {
			if (dialog !== first) {
				dialog.close();
			}
		}
	}

	openSnackBar(template: ComponentType<any> | TemplateRef<any> | string, settings?: MatSnackBarConfig): ISnackRef {
		let snackBarRef: MatSnackBarRef<any> = null;
		if (template instanceof TemplateRef) {
			snackBarRef = this.snackBar.openFromTemplate(template, settings);
		}
		if (isString(template)) {
			const message = (settings && settings.data && settings.data.message) || template;
			const action = (settings && settings.data && settings.data.action) || 'Ok';
			snackBarRef = this.snackBar.open(message, action, settings);
		}
		if (isFunction(template)) {
			snackBarRef = this.snackBar.openFromComponent(<ComponentType<any>>template, settings);
		}
		this.appRef.tick();
		return {
			afterOpened: () => snackBarRef.afterOpened(),
			onAction: () => snackBarRef.onAction(),
			close: () => snackBarRef.dismiss(),
			closeWithAction: () => snackBarRef.closeWithAction(),
			afterDismissed: () => snackBarRef.afterDismissed(),
		};
	}

	openIFrame(
		urlOrOptions: string | IOpenIFrame,
		title?: string,
		titleResx?: string,
		dialogOptions?: IDialogConfig,
		frameClasses?: string | string[],
		componentFactoryResolver?: ComponentFactoryResolver,
		injector?: Injector,
		container?: HTMLElement,
		hideOnLoading?: boolean,
		windowProps?: Record<any, any>,
		openInNewTabUrl?: string
	): IModalRef {
		let url: string, postData: any, iframeBaseHref: string;

		if (typeof urlOrOptions === 'object') {
			url = urlOrOptions.url;
			title = urlOrOptions?.title;
			titleResx = urlOrOptions?.titleResx;
			dialogOptions = urlOrOptions?.dialogOptions;
			frameClasses = urlOrOptions?.frameClasses;
			componentFactoryResolver = urlOrOptions?.componentFactoryResolver;
			injector = urlOrOptions?.injector;
			container = urlOrOptions?.container;
			hideOnLoading = urlOrOptions?.hideOnLoading;
			postData = urlOrOptions?.postData;
			iframeBaseHref = urlOrOptions?.iframeBaseHref;
			windowProps = urlOrOptions?.windowProps;
			openInNewTabUrl = urlOrOptions?.openInNewTabUrl;
		} else {
			url = urlOrOptions;
		}

		const isMobile = this.platformDetector.isMobile();
		this.renderer.addClass(this.document.body, this.cssClassMatDialogPad0);
		title = title && decodeText(title);
		const ref = this.openDialog(
			FacadeModalIframeComponent,
			{
				data: {
					url,
					title,
					titleResx,
					frameClasses,
					hideOnLoading,
					postData,
					iframeBaseHref,
					windowProps,
					openInNewTabUrl,
				},
				autoFocus: false,
				hasBackdrop: true,
				...(dialogOptions || {}),
				width: isMobile ? '100vw' : dialogOptions?.width ? dialogOptions?.width : '96vw',
				height: isMobile ? '90vh' : dialogOptions?.height ? dialogOptions?.height : '96vh',
			},
			componentFactoryResolver,
			injector
		);
		ref.afterClosed().subscribe(_ => {
			this.renderer.removeClass(this.document.body, this.cssClassMatDialogPad0);
		});
		setTimeout(() => {
			this.appRef.tick();
		});
		return ref;
	}

	openFileViewer(data: {
		uploadId: number;
		mimeType?: MimeType;
		versionId?: number;
		taskId?: number;
		fileName?: string;
	}) {
		if (!data || !data.uploadId || !data.mimeType) {
			throw new Error(`openFileViewer has been called with invalid parameters! Need uploadId and mimeType`);
		}
		let url: string;
		switch (data.mimeType) {
			case MimeType.pdf:
				url = `/PDFPreview.aspx?id=${data.uploadId}&GetTrueMime=1`;
				break;
			case MimeType.word:
			case MimeType.word2:
			case MimeType.excel:
				url = this.downloader.createPreviewOfficeWebAppLink(data.uploadId, true); // `/component/WAFrame.aspx?fileId=${data.uploadId}&v=${Date.now()}`;
				break;
			default:
				url = `/FilePreview.aspx?id=${data.uploadId}`;
				if (data.taskId) {
					url += `&taskId=${data.taskId}`;
				}
				break;
		}
		url = this.urlProvider.getUrl(url);
		return this.openIFrame(url, `Просмотр файла${data.fileName ? ' ' + data.fileName : ''}`);
	}

	openError(message: string, title?: string, data?: { titleResx?: string; messageResx?: string }) {
		const isMobile = this.platformDetector.isMobile();
		this.renderer.addClass(this.document.body, this.cssClassMatDialogPad0);
		const ref = this.openDialog(FacadeModalErrorComponent, {
			data: { message, title, data },
			autoFocus: false,
			hasBackdrop: true,
		});
		ref.afterClosed().subscribe(_ => {
			this.renderer.removeClass(this.document.body, this.cssClassMatDialogPad0);
		});
		setTimeout(() => {
			this.appRef.tick();
		});
		return ref;
	}

	openInfo(message: string, data?: { resx?: string; json?: any; title?: string }): IModalRef {
		const isMobile = this.platformDetector.isMobile();
		this.renderer.addClass(this.document.body, this.cssClassMatDialogPad0);
		const ref = this.openDialog(FacadeModalInfoComponent, {
			data: { message, resx: data?.resx, json: data?.json, title: data?.title },
			autoFocus: false,
			hasBackdrop: true,
		});
		ref.afterClosed().subscribe(_ => {
			this.renderer.removeClass(this.document.body, this.cssClassMatDialogPad0);
		});
		setTimeout(() => {
			this.appRef.tick();
		});
		return ref;
	}

	openConfirm(message: string, data?: { confirmBtnColor?: string }, resx?: string): IModalRef {
		const isMobile = this.platformDetector.isMobile();
		this.renderer.addClass(this.document.body, this.cssClassMatDialogPad0);
		const ref = this.openDialog(FacadeModalConfirmComponent, {
			data: {
				message,
				resx,
				...(data || {}),
			},
			autoFocus: false,
			hasBackdrop: true,
		});
		ref.afterClosed().subscribe(_ => {
			this.renderer.removeClass(this.document.body, this.cssClassMatDialogPad0);
		});
		setTimeout(() => {
			this.appRef.tick();
		});
		return ref;
	}

	openPrompt(value: string, settings: any): IModalRef {
		const isMobile = this.platformDetector.isMobile();
		this.renderer.addClass(this.document.body, this.cssClassMatDialogPad0);
		settings = Object.assign({}, settings);
		const ref = this.openDialog(FacadeModalPromptComponent, {
			data: {
				value,
				...settings,
			},
			autoFocus: false,
			hasBackdrop: true,
			width: settings.width,
		});
		ref.afterClosed().subscribe(_ => {
			this.renderer.removeClass(this.document.body, this.cssClassMatDialogPad0);
		});
		setTimeout(() => {
			this.appRef.tick();
		});
		return ref;
	}

	openFileViewer2({ files, fileIndex }): IModalRef {
		const isMobile = this.platformDetector.isMobile();

		const ref = this.openDialog(FileViewerComponent, {
			data: { files, fileIndex },
			autoFocus: false,
			hasBackdrop: false,
		});
		ref
			.afterClosed()
			.pipe(take(1))
			.subscribe(_ => {});
		setTimeout(() => {
			this.appRef.tick();
		});
		return ref;
	}

	openIFrameInContent(url: string, title?: string, modalClass?: string): Partial<IModalRef> {
		return this.modalContent.openIframe({ context: { url, modalClass } });
	}

	openSpace(options: IModalOpenSpace): Observable<any> {
		const SpaceDialogComponent$ = from(
			import('@spa/common/components/spaces/space-dialog/space-dialog.component').then(m => {
				return m.SpaceDialogComponent;
			})
		);

		return SpaceDialogComponent$.pipe(
			take(1),
			switchMap(cmp => {
				return this.openDialog(cmp, {
					data: {
						...options,
					},
					maxHeight: '96vh',
					maxWidth: '96vw',
					width: '96vw',
					height: '96vh',
				}).afterClosed();
			})
		);
	}

	async openTask(options: IModalOpenTask): Promise<IModalRef> {
		const replaceModal = typeof options.replaceModal === 'boolean' ? options.replaceModal : true;
		const maxLevel = 2;
		if (this.TaskCardComponentClass && replaceModal) {
			const taskModals = this.dialog.openDialogs
				.filter(i => i.componentInstance?.componentRef?.componentType === this.TaskCardComponentClass)
				.slice(maxLevel);
			for (const taskModal of taskModals) {
				taskModal.close();
			}
		}
		const service = await import('@spa/components/task/can-activate-spa-task-card.service').then(
			m => m.CanActivateSpaTaskCardService
		);
		const cats = this.injector.get(service);
		const canUseNewMtf = await firstValueFrom(cats.canUseSpaTaskCard(options.taskId));
		if (!canUseNewMtf) {
			return this.openIFrame({
				url: `/maintaskform.aspx?taskId=${options.taskId}&spaContainer=popup`,
			});
		}
		const isMobile = this.mobileView.mobileMode;
		return this.openGeneric({
			componentGetter: () => {
				if (isMobile) {
					return import('@spa/components/task/ui/mobile-task-card/mobile-task-card.component').then(m => {
						this.TaskCardComponentClass = m.MobileTaskCardComponent;
						return m.MobileTaskCardComponent;
					});
				}
				return import('@spa/components/task/ui/task-card/task-card/task-card.component').then(m => {
					this.TaskCardComponentClass = m.TaskCardComponent;
					return m.TaskCardComponent;
				});
			},
			componentInputs: {
				...(options.props || {}),
				taskId: options.taskId,
				shouldShowTabbar: options.shouldShowTabbar,
				showDialogTitle: options.showDialogTitle,
				showToolbar: true,
				showClose: false,
				templateSettingsTaskTitle: isMobile ? options.customTaskTitle : null,
			},
			toolbarPortalKey: options.taskId,
			cfr: options.cfr,
			dialogConfig: {
				width: isMobile ? '100vw' : '96vw',
				height: isMobile ? '100vh' : '96vh',
				...(options.dialogConfig || {}),
			},
			injector: options.injector,
		});
	}

	openGeneric(options: IModalOpenGeneric): IModalRef {
		const data = {
			...(options?.dialogConfig?.data || {}),
			componentGetter: options.componentGetter,
			componentInputs: options.componentInputs,
			toolbarPortalKey: options.toolbarPortalKey,
			openInNewTabHandler: options.openInNewTabHandler,
			dialogConfig: options.dialogConfig,
			title: options.title,
		};

		const isMobile = this.platformDetector.isMobile();

		return this.openDialog(
			ModalGenericComponent,
			{
				maxHeight: isMobile ? '100%' : '96vh',
				maxWidth: isMobile ? '100%' : '96vw',
				closeOnNavigation: true,
				...(options?.dialogConfig || {}),
				data,
			},
			options?.cfr,
			options?.injector
		);
	}

	openProject(options: IModalOpenProject): IModalRef {
		const replaceModal = typeof options.replaceModal === 'boolean' ? options.replaceModal : true;
		const maxLevel = 2;
		if (this.ProjectGanttComponentClass && replaceModal) {
			const projectModals = this.dialog.openDialogs
				.filter(i => i.componentInstance?.componentRef?.componentType === this.ProjectGanttComponentClass)
				.slice(maxLevel);
			for (const projectModal of projectModals) {
				projectModal.close();
			}
		}
		const isMobile = this.platformDetector.isMobile();
		return this.openGeneric({
			componentGetter: () =>
				import('@spa/pages/project/project.component').then(m => {
					this.ProjectGanttComponentClass = m.ProjectPageComponent;
					return m.ProjectPageComponent;
				}),
			componentInputs: {
				projectId: options.projectId,
			},
			openInNewTabHandler: () => {
				const router = getAppTopInjector().get(Router);
				let url = router.serializeUrl(router.createUrlTree(['project', options.projectId]));
				url = this.urlProvider.getUrl(url, true);
				window.open(url, '_blank');
			},
			toolbarPortalKey: options.projectId,
			cfr: options.cfr,
			dialogConfig: {
				width: isMobile ? '100vw' : '96vw',
				height: isMobile ? '100vh' : '96vh',
				...(options.dialogConfig || {}),
			},
			injector: options.injector,
		});
	}

	createTask(options?: IModalCreateTask): Observable<any> {
		options = options || {};
		if (typeof options?.onlyCloseAfterCreate !== 'boolean') {
			options.onlyCloseAfterCreate = true;
		}
		return forkJoin([
			import('@spa/components/task/ui/task-card/select-category-dialog/select-category-dialog.component').then(
				m => m.SelectCategoryDialogComponent
			),
			import(
				'@spa/components/new-task/ui/new-task-card/new-task-card-chat-modal/new-task-card-chat-modal.component'
			).then(m => m.NtfDialogComponent),
		]).pipe(
			switchMap(([select, ntf]) => {
				if (!options?.subcatId) {
					return this.openDialog(select, {
						height: '90%',
					})
						.afterClosed()
						.pipe(
							take(1),
							map(subcat => [ntf, subcat?.catId])
						);
				}
				return of([ntf, options.subcatId]);
			}),
			filter(([, id]) => !!id),
			switchMap(([ntf, subcatId]) => {
				return this.openDialog(ntf, {
					data: {
						subcatId: subcatId,
						parentId: options.parentTaskId,
						action: options.action,
						linkedId: options.linkedTaskId,
						sourceTaskId: options.sourceTaskId,
						onlyCloseAfterCreate: options.onlyCloseAfterCreate,
						onlyEmitAfterCreate: options.onlyEmitAfterCreate,
						parentTaskText: options.parentTaskText,
						title: options.customTitle,
					},
					maxHeight: '90vh',
					height: '90vh',
				}).afterClosed();
			})
		);
	}
}
