/**
 * This file is created to avoid imports like: import * as _ from 'lodash'
 * to controlling output bundle size
 */

import _merge from 'lodash-es/merge';
import _cloneDeep from 'lodash-es/cloneDeep';
import _clone from 'lodash-es/clone';
import _debounce from 'lodash-es/debounce';
import _isEqual from 'lodash-es/isEqual';
import _find from 'lodash-es/find';
import _get from 'lodash-es/get';
import _set from 'lodash-es/set';
import _capitalize from 'lodash-es/capitalize';
import _uniqBy from 'lodash-es/uniqBy';

const merge = _merge,
	cloneDeep = _cloneDeep,
	clone = _clone,
	debounce = _debounce,
	isEqual = _isEqual,
	find = _find,
	get = _get,
	set = _set,
	capitalize = _capitalize,
	uniqBy = _uniqBy;

export { merge, cloneDeep, clone, debounce, isEqual, find, get, set, capitalize, uniqBy };
