import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { UrlProvider, ViewDestroyStreamService } from '@spa/core';
import { DataHttpService, IAddToFavoritesRequest } from '@spa/data/http';
import { map, shareReplay, take, takeUntil } from 'rxjs';
import { INavigationMenu, UserLinkType } from '../navigation.model';
import { NavigationCommonService } from '../navigation.service';

@Component({
	selector: 'vh-navigation-context-menu',
	templateUrl: './navigation-context-menu.component.html',
	styleUrls: ['./navigation-context-menu.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [ViewDestroyStreamService],
})
export class NavigationContextMenuComponent {
	constructor(
		protected router: Router,
		protected url: UrlProvider,
		protected readonly server: DataHttpService,
		readonly destroy$: ViewDestroyStreamService,
		readonly cdr: ChangeDetectorRef,
		readonly elRef: ElementRef<HTMLElement>,
		readonly navigationCommonService: NavigationCommonService
	) {}

	@ViewChild('controlContextMenu')
	matMenu: MatMenu;

	@HostBinding('class.vh-navigation-context-menu')
	hostClassSelector = true;

	@Output()
	contextMenuClick: EventEmitter<IAddToFavoritesRequest> = new EventEmitter();

	readonly appConfig$ = this.server.config.getAppConfiguration().pipe(shareReplay({ bufferSize: 1, refCount: true }));
	readonly calendarSubcatId$ = this.appConfig$.pipe(map(data => Number(data.calendarSubcatId)));

	createTask(item: INavigationMenu, e) {
		const url = item.isSpace ? `/spaces/${item.$id}/new` : `/newtask/${item.$id}`;
		this.server.category
			.storageSubCategory(item.$id)
			.pipe(
				map(data => data.subcategory.viewAndPermissions),
				take(1)
			)
			.subscribe(viewAndPermissions => {
				if (viewAndPermissions.redirectFromNewTask?.length) {
					this.navigationCommonService.redirectToIFrameLink(viewAndPermissions.redirectFromNewTask);
					return;
				}
				if (e?.ctrlKey || e?.metaKey) {
					window.open(this.url.getUrl(`${url}`, true), '_blank');
					return;
				}

				this.router.navigate([url]);
			});
	}

	openCategorySettings(item: INavigationMenu, newSettings = false) {
		if (newSettings) {
			const subcatId = item.$id;
			const url = this.url.getUrl(
				`/admin-ds/forms/subcategories/edit?SubcatID=${subcatId}&column=[SubcatID]&value=${subcatId}`,
				true
			);
			window.open(url, '_blank');
			return;
		}
		const url = this.url.getUrl(`/admin/subcategories/EditSubcatFrameset.aspx?SubcatID=${item.$id}`);
		window.open(url, '_blank');
	}

	addItemToFavorites(item: INavigationMenu, type: string) {
		let isCat = '';
		if (item.nodeType === 'Category') {
			isCat = 'iscat=true&';
		}

		let requestParams = {
			customImageClass: 'subcatFolder',
			customImagePath: null,
			folderId: null,
			isJsFunction: true,
			link: 'javascript:void(TCLeftTrees.openSyndicate("?' + isCat + 'PID=' + item.$id + '&force=' + type + '"))',
			linkedObjectId: item.$id + '',
			name: item.title,
			type: UserLinkType[this.firstLetterToLowerCase(item.nodeType)],
		};

		if (type === 'kanban') {
			requestParams = {
				customImageClass: 'subcatFolder',
				customImagePath: null,
				folderId: null,
				isJsFunction: true,
				link: 'spa/noframe/tasks/subcat/' + item.$id + '/kanban',
				linkedObjectId: item.$id + '',
				name: item.title,
				type: UserLinkType[this.firstLetterToLowerCase(item.nodeType)],
			};
		}

		this.contextMenuClick.emit(requestParams);
	}

	firstLetterToLowerCase(string: string): string {
		if (!string) {
			return null;
		}
		return string.charAt(0).toLowerCase() + string.slice(1);
	}

	openKanban(item: INavigationMenu, e) {
		if (e?.ctrlKey) {
			const url = this.url.getUrl(`/tasks/subcat/${item.$id}/kanban`, true);
			window.open(url, '_blank');
			return;
		}

		this.router.navigate([`/tasks/subcat/${item.$id}/kanban`]);
	}

	openTable(item: INavigationMenu, e) {
		const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.$id}/grid`;
		}

		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.$id}/grid`;
		}

		if (e?.ctrlKey) {
			const urlForWindow = this.url.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		this.router.navigate([url]);
	}

	openCalendar(item: INavigationMenu, e) {
		const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.$id}/calendar`;
		}

		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.$id}/calendar`;
			this.calendarSubcatId$.pipe(take(1), takeUntil(this.destroy$)).subscribe(subcatId => {
				if (item.$id === subcatId) {
					url = this.url.getUrl('/calendar');
				}
			});
		}

		if (e?.ctrlKey) {
			const urlForWindow = this.url.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		this.router.navigate([url]);
	}

	openGantt(item: INavigationMenu, e) {
		const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.$id}/gantt`;
		}

		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.$id}/gantt`;
		}

		if (e?.ctrlKey) {
			const urlForWindow = this.url.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		this.router.navigate([url]);
	}

	openFeed(item: INavigationMenu, e) {
		const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.$id}/feeds`;
		}

		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.$id}/feeds`;
		}

		if (e?.ctrlKey) {
			const urlForWindow = this.url.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		return this.router.navigate([url]);
	}

	openFiles(item: INavigationMenu, e) {
		const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.$id}/files`;
		}

		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.$id}/files`;
		}

		if (e?.ctrlKey) {
			const urlForWindow = this.url.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		return this.router.navigate([url]);
	}

	openChannel(item: INavigationMenu, e) {
		const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.$id}/channel`;
		}

		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.$id}/channel`;
		}

		if (e?.ctrlKey) {
			const urlForWindow = this.url.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		return this.router.navigate([url]);
	}

	openChat(item: INavigationMenu, e) {
		const linkType = item.nodeType === 'Subcategory' ? 'SubCatID' : 'CategoryId';
		let url;

		if (linkType === 'CategoryId') {
			url = `/tasks/category/${item.$id}/chat`;
		}

		if (linkType === 'SubCatID') {
			url = `/tasks/subcat/${item.$id}/chat`;
		}

		if (e?.ctrlKey) {
			const urlForWindow = this.url.getUrl(url, true);
			window.open(urlForWindow, '_blank');
			return;
		}

		return this.router.navigate([url], { queryParams: { mode: 'feed' } });
	}

	openGridWithSelection(subcatId: number, expressionId: number) {
		this.router.navigate([`/tasks/subcat/${subcatId}/grid`], { queryParams: { SmartQueryId: expressionId } });
	}
}
