import { merge } from '@valhalla/utils';
import { IAction, ReducerBase } from '@valhalla/core';

import * as actions from '../actions';
import { ILayoutState } from '../state';

export class UpdateLayoutReducer implements ReducerBase {
	readonly actionType = actions.FacadeLayoutAction.updateLayout;

	reduce(state: Readonly<ILayoutState>, action: IAction<Partial<ILayoutState>>) {
		if (!action.payload) {
			return state;
		}
		return merge(state, action.payload);
	}
}

export const crudReducers = [UpdateLayoutReducer];
