import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SpaCommandExecutorFactory } from '../spa-command.contract';

const createCommand: SpaCommandExecutorFactory = injector => {
	const router = injector.get(Router);
	const zone = injector.get(NgZone);

	return function openSyndicate(payload: any) {
		const queryParams = Object.entries(payload || {})
			.map(([param, value]) => `${param}=${value}`)
			.join('&');

		return zone.runTask(() =>
			router.navigate(['/link'], {
				queryParams: {
					url: `/Syndicate.aspx?${queryParams}`,
				},
			})
		);
	};
};

export default createCommand;
