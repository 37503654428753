import { InjectionToken, Provider, SkipSelf, Optional, NgZone } from '@angular/core';

import { IResourceDefinition } from './resource-types';
import delimiter from './special-key';

export const RESOURCE_DEFINITION = new InjectionToken<IResourceDefinition>('resource definition registration token');
export const RESOURCE_DEFINITION_ALL = new InjectionToken<IResourceDefinition[]>(
	'all resource definition up to injectors registration token'
);

function definitionKey({ culture, namespace }: IResourceDefinition) {
	return `${culture}${delimiter}${namespace}`;
}

function warnMultipleRegistration({ culture, namespace }: IResourceDefinition) {
	// tslint:disable-next-line: no-console
	console.warn(`Multiple resources registration with the same culture '${culture}' and namespace '${namespace}'`);
}

function checkDuplicateDefinitions(defs: IResourceDefinition[]) {
	defs.forEach((def, idx) => {
		setTimeout(() => {
			const curIdx = defs.findIndex(i => i === def);
			if (curIdx !== idx) {
				warnMultipleRegistration(def);
			}
		});
	});
}

export function registerResource(resourceDefinition: IResourceDefinition): Provider[] {
	return [
		{
			provide: RESOURCE_DEFINITION,
			multi: true,
			useValue: resourceDefinition,
		},
		{
			provide: RESOURCE_DEFINITION_ALL,
			useFactory: resourceDefinitionAllFactory,
			deps: [NgZone, RESOURCE_DEFINITION, [new SkipSelf(), new Optional(), RESOURCE_DEFINITION_ALL]],
		},
	];
}

export function resourceDefinitionAllFactory(
	zone: NgZone,
	defs: IResourceDefinition[],
	prevDefs?: IResourceDefinition[]
) {
	const all = prevDefs ? [...defs, ...prevDefs] : defs;
	zone.runOutsideAngular(() => {
		checkDuplicateDefinitions(all || []);
	});
	return all;
}
