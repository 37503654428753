import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IApiResponse } from '../api-response';

import { IRaiting, ISurveyDescription } from '@valhalla/data/entities';
import { DataHttpCommonService } from '../data-http-common.service';
import { EndpointUrlConfig } from '../endpoint.config';
import type { IEndpointUrlConfig } from '../endpoint.config';
import { SurveyDataHttpService } from './abstract';

@Injectable()
export class SurveyDataHttpServiceImpl implements SurveyDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService
	) {}

	getResult(taskId: number): Observable<ISurveyDescription> {
		const url = this.common.getApiUrl(`survey/assignment/${taskId}/result`);
		return this.http.get<IApiResponse<ISurveyDescription>>(url).pipe(map(result => result.data));
	}

	saveResult(taskId: number, data: any): Observable<any> {
		const url = this.common.getApiUrl(`survey/assignment/${taskId}/result`);
		return this.http.post<any>(url, data);
	}

	sendRating(taskId: number, points: Array<IRaiting>): Observable<any> {
		const url = this.common.getApiUrl(`survey/assignment/${taskId}/points`);
		return this.http.post(url, { points });
	}

	getRenderConfig(taskId: number): Observable<any> {
		const url = this.common.getApiUrl(`survey/assignment/${taskId}`);
		return this.http.get<any>(url);
	}

	getConfig(taskId: number): Observable<any> {
		const url = this.common.getApiUrl(`survey/config/${taskId}`);
		return this.http.get<any>(url);
	}

	saveConfig(taskId: number, config: any): Observable<any> {
		const url = this.common.getApiUrl(`survey/config/${taskId}`);
		return this.http.post<any>(url, { data: config });
	}
}
