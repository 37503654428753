import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';

import { DuplicateImportModuleError } from '../errors';
import { AuthService } from './abstract';
import { AuthAdminGuard } from './auth-admin.guard';
import { AuthGuard } from './auth.guard';
import { AuthServiceImpl } from './auth.service';
import { Auth401Interceptor } from './http-interceptor-401';
import { Auth403Interceptor } from './http-interceptor-403';
import { jwtConfigFactory } from './jwt-config.factory';
import { TOKEN_PROVIDERS, TokenService } from './token';
import { AuthTokenRefreshResponseInterceptor } from './http-interceptor-token-response';
import { SessionUser } from './session-user.service';

@NgModule({
	imports: [
		JwtModule.forRoot({
			jwtOptionsProvider: {
				provide: JWT_OPTIONS,
				useFactory: jwtConfigFactory,
				deps: [TokenService],
			},
		}),
	],
})
export class AuthModule {
	constructor(
		@Optional()
		@SkipSelf()
		authModule: AuthModule
	) {
		if (authModule) throw new DuplicateImportModuleError('AuthModule');
	}
	static forRoot(): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
			providers: [
				...TOKEN_PROVIDERS,
				{
					provide: AuthService,
					useClass: AuthServiceImpl,
				},
				AuthGuard,
				AuthAdminGuard,
				SessionUser,
				{
					provide: HTTP_INTERCEPTORS,
					multi: true,
					useClass: Auth401Interceptor,
				},
				{
					provide: HTTP_INTERCEPTORS,
					multi: true,
					useClass: Auth403Interceptor,
				},
				{
					provide: HTTP_INTERCEPTORS,
					multi: true,
					useClass: AuthTokenRefreshResponseInterceptor,
				},
			],
		};
	}
}
