import { TemplatePortal } from '@angular/cdk/portal';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core';
import { EMPTY, Subscription, catchError, of, take, from } from 'rxjs';
import { IAttachment } from '@spa/data/entities';
import { DataHttpService, IFileInfo } from '@spa/data/http';
import { UiMonacoEditorComponent } from '../../../monaco-editor';
import { IFileViewerDialogComponentDataOptions } from '../../file-viewer-dialog.component';
import { IFileViewer, IFileViewerSettings } from '../../file-viewer.component';
import { DEFAULT_TXT_LANGUAGE, ENCODINGS, TXT_EXT_TO_LANGUAGES } from '../../file-viewer.config';
import { FileViewerService } from '../../file-viewer.service';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { ClipboardSnackBarComponent } from '@spa/components/task/ui/task-card/clipboard-snack-bar/clipboard-snack-bar.component';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { UrlProvider } from '@spa/core';

const SELECTOR = 'vh-txt-viewer';

/**
 * Компонент для рендера PDF-файлов
 */
@Component({
	// tslint:disable-next-line:component-selector
	selector: SELECTOR,
	templateUrl: './txt-viewer.component.html',
	styleUrls: ['./txt-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class TxtViewerComponent implements IFileViewer, AfterViewInit {
	constructor(
		readonly cdr: ChangeDetectorRef,
		readonly server: DataHttpService,
		readonly viewContainerRef: ViewContainerRef,
		readonly fileViewerService: FileViewerService,
		readonly mobileView: MobileViewService,
		readonly modal: ModalWindowsService,
		readonly url: UrlProvider
	) {}

	@ViewChild('actionPanel')
	public actionPanel: TemplateRef<unknown>;

	@ViewChild('settingsPanel')
	public settingsPanel: TemplateRef<unknown>;

	@ViewChild('mobileActionPanel')
	public mobileActionPanel: TemplateRef<unknown>;

	@Input()
	public set file(value: Partial<IAttachment>) {
		this._file = value;
	}

	public get file(): Partial<IAttachment> {
		return this._file;
	}

	@Input()
	public fileInfo: IFileInfo;

	_file: Partial<IAttachment>;

	public openLatests = false;

	public get isDirty(): boolean {
		return this.changes > 0;
	}

	readonly dropdownEncodingsOptionsGetter = this.encodingsOptionsGetItems.bind(this);
	readonly dropdownItemTextGetter = this.itemTextGetter.bind(this);

	encodingsDropdownWidth: number;

	options?: IFileViewerDialogComponentDataOptions;
	public canEdit = false;
	public encodings = ENCODINGS;
	public selectedEncoding = ENCODINGS[0];
	public language = 'plaintext';
	public changes = 0;

	private buffer: Uint8Array;
	private monacoEditor: UiMonacoEditorComponent;
	private subscription: Subscription;
	public settings: IFileViewerSettings = {
		actionPanel: null,
		settingsPanel: null,
	};

	ngAfterViewInit() {
		this.settings.actionPanel = new TemplatePortal(this.actionPanel, this.viewContainerRef);
		this.settings.settingsPanel = new TemplatePortal(this.settingsPanel, this.viewContainerRef);
		this.settings.mobileActionPanel = new TemplatePortal(this.mobileActionPanel, this.viewContainerRef);
		this.cdr.detectChanges();
	}

	getFileEmailLink(file) {
		return this.url.fileEmailUrl(file?.key);
	}

	getFileMeetingLink(file) {
		return this.url.fileMeetingUrl(file?.key);
	}

	onCodeChange(data: string) {
		this.changes++;
		this.cdr.detectChanges();
	}

	selectAll() {
		this.monacoEditor.selectAll();
	}

	loadText(forced = false) {
		if (this.file) {
			if (!this.subscription || forced) {
				this.subscription = null;

				if (this.options?.openAsBlob) {
					this.file?.fileBlob?.arrayBuffer().then(buffer => {
						this.buffer = new Uint8Array(buffer);
						this.setTextFromBuffer();
					});
				} else if (this.options?.isEmailFile) {
					this.subscription = this.server.files
						.getFileEmailContent(this.file?.key, null, { r: new Date().getTime() })
						.subscribe(ab => {
							this.buffer = new Uint8Array(ab);
							this.setTextFromBuffer();
						});
				} else if (this.options?.isMeetingFile) {
					this.subscription = this.server.files
						.getFileMeetingContent(this.file?.key, null, { r: new Date().getTime() })
						.subscribe(ab => {
							this.buffer = new Uint8Array(ab);
							this.setTextFromBuffer();
						});
				} else {
					this.subscription = this.server.files
						.getFileContent(this.file.id, this.file.versionId, { r: new Date().getTime() })
						.subscribe(ab => {
							this.buffer = new Uint8Array(ab);
							this.setTextFromBuffer();
						});
				}
			} else {
				this.detectLanguage();
			}

			let readonly = !this.fileInfo.userPermissions.modify;
			if (
				this.file.isOldVersion ||
				(this.file.versionID && this.fileInfo.latestVersionId != this.file.versionID) ||
				(this.file.versionId && this.fileInfo.latestVersionId != this.file.versionId)
			) {
				readonly = true;
				this.openLatests = true;
			}

			this.monacoEditor.readOnly = readonly;
			this.canEdit = this.fileInfo.userPermissions.modify;
		}
	}

	openLatest() {
		this.fileViewerService.openLatestVersionFile(this.file.id);
	}

	showVersion(): void {
		this.fileViewerService.openVersionFile(this.file);
	}

	changeEncoding(newEncoding) {
		this.selectedEncoding = newEncoding;
		this.setTextFromBuffer();
		this.cdr.detectChanges();
	}

	setTextFromBuffer() {
		const decoder = new TextDecoder(this.selectedEncoding.value);
		const text = decoder.decode(this.buffer);

		this.changes = -1;
		this.monacoEditor.setValue(text);

		this.detectLanguage();
		this.cdr.detectChanges();
	}

	detectLanguage() {
		let fileName = '';
		if (this.file && this.file.name) {
			fileName = this.file.name;
		} else if (this.fileInfo && this.fileInfo.name) {
			fileName = this.fileInfo.name;
		}

		const ext = fileName.split('.').pop();
		const lang = TXT_EXT_TO_LANGUAGES[ext] || DEFAULT_TXT_LANGUAGE;

		this.language = lang;
		this.monacoEditor.lang = lang;
	}

	save(event) {
		this.changes = 0;
		this.cdr.detectChanges();
		const base64 = btoa(unescape(encodeURIComponent(this.monacoEditor.getValue())));

		this.server.files
			.updateFileBase64({
				fileId: this.file.id,
				versionId: this.file.versionId,
				base64Content: base64,
			})
			.pipe(
				catchError(err => {
					console.error(err);
					return EMPTY;
				})
			)
			.subscribe(() => {
				this.showFileCopiedMessage();
				this.cdr.detectChanges();
			});
	}

	onMonacoReady(editor: UiMonacoEditorComponent) {
		this.monacoEditor = editor;
		this.loadText();
	}

	showFileCopiedMessage(): void {
		let result: boolean;
		const snackBar = this.modal.openSnackBar(ClipboardSnackBarComponent, {
			data: {
				actions: [{ action: () => (result = true), name: 'Ок', nameResx: 'common.ok', color: 'accent' }],
				messageResx: 'common.fileSaved',
			},
		});

		snackBar
			.afterOpened()
			.pipe(take(1))
			.subscribe(() => {
				setTimeout(() => {
					snackBar.close();
				}, 1000);
			});
	}

	encodingsOptionsGetItems() {
		return of(ENCODINGS);
	}

	itemTextGetter(item: { id: number; name: string }) {
		return item?.name || '';
	}

	onResize(e) {
		this.encodingsDropdownWidth = e?.size?.width;
	}
}
