<div class="theme-options-panel" (scroll)="showShadowHandler($event)">
	<div class="header-wrapper">
		<div class="header" [class.show-shadow]="showShadow$ | async">
			<span class="title">{{ 'common.interfaceAppearance' | resx }}</span>

			<button
				class="vh-btn vh-btn-icon vh-btn--transparent close-button"
				(click)="toggleSidebarOpen('themeOptionsPanel')"
			>
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>

	<form [formGroup]="form">
		<!-- COLOR THEME -->
		<div *ngIf="(allowThemes$ | async) && (colorTheme$ | async)" class="group">
			<h2>{{ 'common.interfaceColorTheme' | resx }}</h2>

			<mat-radio-group formControlName="colorTheme">
				<mat-radio-button class="mb-12" value="theme-default">{{
					'common.interfaceThemeLightStandart' | resx
				}}</mat-radio-button>

				<mat-radio-button class="mb-12" value="theme-blue-gray-dark">{{
					'common.interfaceThemeDarkBlueGray' | resx
				}}</mat-radio-button>
			</mat-radio-group>
		</div>

		<!-- TASK PANE  -->
		<div class="group" *ngIf="showTask$ | async">
			<h2>{{ 'common.TaskPane' | resx }}</h2>

			<mat-radio-group formControlName="viewTaskState">
				<mat-radio-button class="mb-12" value="Bottom">{{ 'common.positionBottom' | resx }}</mat-radio-button>

				<mat-radio-button class="mb-12" value="Right">{{ 'common.positionRight' | resx }}</mat-radio-button>

				<!-- <mat-radio-button class="mb-12" value="NewWindow">{{ 'common.positionCurrentWindow' | resx }}</mat-radio-button> -->

				<mat-radio-button class="mb-12" value="PopupWindow">{{
					'common.positionPopUpwWindow' | resx
				}}</mat-radio-button>
			</mat-radio-group>
		</div>

		<!-- COMMENTS POSITION IN TASK  -->
		<div class="group" *ngIf="comments$ | async">
			<h2>{{ 'common.CommentsPositionInTask' | resx }}</h2>

			<mat-radio-group formControlName="viewCommentsInTaskState">
				<mat-radio-button class="mb-12" value="Bottom">{{ 'common.positionBottom' | resx }}</mat-radio-button>

				<mat-radio-button class="mb-12" value="Right">{{ 'common.positionRight' | resx }}</mat-radio-button>

				<!-- <mat-radio-button class="mb-12" value="left">{{ 'common.positionLeft' | resx }}</mat-radio-button> -->
			</mat-radio-group>
		</div>

		<!-- COMMON  -->
		<div class="group" *ngIf="general$ | async">
			<h2>{{ 'common.Common' | resx }}</h2>

			<mat-checkbox formControlName="showDateInHumanFormat">
				{{ 'common.showDateInHumanFormat' | resx }}
			</mat-checkbox>
			<mat-checkbox formControlName="openFirstTaskInTableView">
				{{ 'common.openFirstTaskInTableView' | resx }}
			</mat-checkbox>
		</div>

		<!-- COMMENTS FEED  -->
		<div class="group" *ngIf="commentsFeed$ | async">
			<h2>{{ 'common.CommentsFeed' | resx }}</h2>

			<mat-checkbox formControlName="showIAndMeInsteadOfDisplayName">{{
				'common.showOwnDisplayName' | resx
			}}</mat-checkbox>
			<mat-checkbox formControlName="translateCommentsInFeed">{{
				'common.TranslateCommentsInFeed' | resx
			}}</mat-checkbox>

			<label class="select-control" *ngIf="allowUsersChangeDisplayNameMode$ | async">
				<mat-label>{{ 'common.NameViewMode' | resx }}</mat-label>
				<mat-select formControlName="userNameMode">
					<mat-option value="SurName">{{ 'common.Surname' | resx }}</mat-option>
					<mat-option value="SurNameName">{{ 'common.SurnameName' | resx }}</mat-option>
					<mat-option value="SurNameNP">{{ 'common.SurnameInitials' | resx }}</mat-option>
					<mat-option value="Nick">{{ 'common.RussianNickname' | resx }}</mat-option>
					<mat-option value="Appointment">{{ 'common.tableHeaderNameJobPosition' | resx }}</mat-option>
					<mat-option value="ExternalName">{{ 'common.infoExternalDisplayName' | resx }}</mat-option>
					<mat-option value="Name">{{ 'common.Name' | resx }}</mat-option>
					<mat-option value="AppointmentName">{{ 'common.JobPositionName' | resx }}</mat-option>
					<mat-option value="NickName">{{ 'common.RussianNicknameName' | resx }}</mat-option>
					<mat-option value="NameParentName">{{ 'common.NameSecondName' | resx }}</mat-option>
					<mat-option value="AppointmentSurName">{{ 'common.JobPositionSurname' | resx }}</mat-option>
					<mat-option value="AppointmentNick">{{ 'common.JobPositionRussianNickname' | resx }}</mat-option>
					<mat-option value="SurnameNameParentName">{{ 'common.FullName' | resx }}</mat-option>
					<mat-option value="NameSurname">{{ 'common.NameSurname' | resx }}</mat-option>
					<mat-option value="EnglishNick">{{ 'common.EnglishNickname' | resx }}</mat-option>
					<mat-option value="MaidennameNameParentname">{{ 'common.FemaleSurnameNameSecondName' | resx }}</mat-option>
					<mat-option value="MaidennameName">{{ 'common.FemaleSurnameName' | resx }}</mat-option>
				</mat-select>
			</label>
		</div>

		<ng-container *ngIf="!isMobile && navigationPanel$ | async">
			<div class="group">
				<h2>{{ 'common.interfacePanelNav' | resx }}</h2>

				<mat-radio-group *ngIf="navigationPanelLocation$ | async" formControlName="appNavBarPosition">
					<mat-radio-button class="mb-12" value="left">{{ 'common.positionLeft' | resx }}</mat-radio-button>
					<mat-radio-button class="mb-12" value="right">{{ 'common.positionRight' | resx }}</mat-radio-button>
					<mat-radio-button class="mb-12" value="top">{{ 'common.positionTop' | resx }}</mat-radio-button>
				</mat-radio-group>

				<mat-checkbox *ngIf="showAlways$ | async" class="mb-12" formControlName="showNavigationAlways">
					{{ 'common.showAlways' | resx }}
				</mat-checkbox>
				<vh-common-material-color-picker
					*ngIf="colorPicker$ | async"
					class="mb-16"
					formControlName="panelBackground"
				></vh-common-material-color-picker>
			</div>
		</ng-container>

		<div class="group">
			<h2>{{ 'common.chatsView' | resx }}</h2>
			<vh-control-toggle formControlName="chatBubbles" [label]="'common.displayMessagesInBlocks' | resx" class="mb-12">
			</vh-control-toggle>
		</div>

		<!-- LAYOUT STYLES -->
		<ng-container *ngIf="false">
			<div formGroupName="layout">
				<!-- DIFFERENT FORMS BASED ON LAYOUT STYLES -->
				<ng-container [ngSwitch]="fuseConfig.layout.style">
					<!-- VERTICAL LAYOUT #1 -->
					<ng-container *ngSwitchCase="'vertical-layout-1'">
						<!-- LAYOUT WIDTH -->
						<div class="group mt-32">
							<h2>Раскладка по ширине</h2>
							<mat-radio-group formControlName="width">
								<mat-radio-button class="mb-12" value="fullwidth">По всей ширине</mat-radio-button>
								<mat-radio-button class="mb-12" value="boxed">Фиксированная</mat-radio-button>
							</mat-radio-group>
						</div>

						<!-- NAVBAR -->
						<div class="group" formGroupName="navbar">
							<h2>Панель навигации</h2>

							<vh-control-toggle [label]="'Скрыть'" formControlName="hidden"> </vh-control-toggle>

							<vh-control-toggle [label]="'Свернуть'" class="mt-24" formControlName="folded"></vh-control-toggle>

							<h3 class="mt-24">Расположение:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-16" value="left">Слева</mat-radio-button>
								<mat-radio-button class="mb-16" value="right">Справа</mat-radio-button>
							</mat-radio-group>

							<h3 class="mt-16 mb-8">Основной фон:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="primaryBackground"
							></vh-common-material-color-picker>

							<h3 class="mt-16 mb-8">Вторичный фон:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="secondaryBackground"
							></vh-common-material-color-picker>
						</div>

						<!-- TOOLBAR -->
						<div class="group" formGroupName="toolbar">
							<h2>Верхняя панель</h2>

							<vh-control-toggle [label]="'Скрыть'" formControlName="hidden"></vh-control-toggle>

							<h3 class="mt-24">Расположение:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-12" value="above">Сверху</mat-radio-button>
								<mat-radio-button class="mb-12" value="below-static">Статичный</mat-radio-button>
								<mat-radio-button class="mb-12" value="below-fixed">Фиксированный</mat-radio-button>
							</mat-radio-group>

							<mat-checkbox class="mt-24" formControlName="customBackgroundColor">
								Использовать другой фон
							</mat-checkbox>

							<h3 class="mt-24 mb-8">Цвет фона:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="background"
							></vh-common-material-color-picker>
						</div>

						<!-- FOOTER -->
						<div class="group" formGroupName="footer">
							<h2>Подвал</h2>

							<vh-control-toggle [label]="'Скрыть'" formControlName="hidden"></vh-control-toggle>

							<h3 class="mt-24">Расположение:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-12" value="above">Сверху</mat-radio-button>
								<mat-radio-button class="mb-12" value="below-static">Статичный</mat-radio-button>
								<mat-radio-button class="mb-12" value="below-fixed">Фиксированный</mat-radio-button>
							</mat-radio-group>

							<mat-checkbox class="mt-24" formControlName="customBackgroundColor">
								Использовать другой фон
							</mat-checkbox>

							<h3 class="mt-24 mb-8">Цвет:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="background"
							></vh-common-material-color-picker>
						</div>

						<!-- SIDE PANEL -->
						<div class="group" formGroupName="sidepanel">
							<h2>Боковая панель</h2>

							<vh-control-toggle [label]="'Скрыть'" formControlName="hidden"></vh-control-toggle>

							<h3 class="mt-24">Расположение:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-12" value="left">Селва</mat-radio-button>
								<mat-radio-button class="mb-12" value="right">Справа</mat-radio-button>
							</mat-radio-group>
						</div>
					</ng-container>

					<!-- VERTICAL LAYOUT #2 -->
					<ng-container *ngSwitchCase="'vertical-layout-2'">
						<!-- LAYOUT WIDTH -->
						<div class="group mt-32">
							<h2>Layout Width</h2>
							<mat-radio-group formControlName="width">
								<mat-radio-button class="mb-12" value="fullwidth">Fullwidth</mat-radio-button>
								<mat-radio-button class="mb-12" value="boxed">Boxed</mat-radio-button>
							</mat-radio-group>
						</div>

						<!-- NAVBAR -->
						<div class="group" formGroupName="navbar">
							<h2>Navbar</h2>

							<vh-control-toggle [label]="'Hide'" formControlName="hidden"></vh-control-toggle>

							<vh-control-toggle [label]="'Folded'" class="mt-24" formControlName="folded"></vh-control-toggle>

							<h3 class="mt-24">Position:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-16" value="left">Left</mat-radio-button>
								<mat-radio-button class="mb-16" value="right">Right</mat-radio-button>
							</mat-radio-group>

							<h3 class="mt-8">Variant:</h3>
							<mat-radio-group formControlName="variant">
								<mat-radio-button class="mb-16" value="vertical-style-1">Style 1</mat-radio-button>
								<mat-radio-button class="mb-16" value="vertical-style-2">Style 2</mat-radio-button>
							</mat-radio-group>

							<h3 class="mt-16 mb-8">Primary background:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="primaryBackground"
							></vh-common-material-color-picker>

							<h3 class="mt-16 mb-8">Secondary background:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="secondaryBackground"
							></vh-common-material-color-picker>
						</div>

						<!-- TOOLBAR -->
						<div class="group" formGroupName="toolbar">
							<h2>Toolbar</h2>

							<vh-control-toggle [label]="'Hide'" formControlName="hidden"></vh-control-toggle>

							<h3 class="mt-24">Position:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-12" value="above-static">Above Static</mat-radio-button>
								<mat-radio-button class="mb-12" value="above-fixed">Above Fixed</mat-radio-button>
								<mat-radio-button class="mb-12" value="below">Below</mat-radio-button>
							</mat-radio-group>

							<mat-checkbox class="mt-24" formControlName="customBackgroundColor">
								Use custom background color
							</mat-checkbox>

							<h3 class="mt-24 mb-8">Background color:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="background"
							></vh-common-material-color-picker>
						</div>

						<!-- FOOTER -->
						<div class="group" formGroupName="footer">
							<h2>Footer</h2>

							<vh-control-toggle [label]="'Hide'" formControlName="hidden"></vh-control-toggle>

							<h3 class="mt-24">Position:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-12" value="above-static">Above Static</mat-radio-button>
								<mat-radio-button class="mb-12" value="above-fixed">Above Fixed</mat-radio-button>
								<mat-radio-button class="mb-12" value="below">Below</mat-radio-button>
							</mat-radio-group>

							<mat-checkbox class="mt-24" formControlName="customBackgroundColor">
								Use custom background color
							</mat-checkbox>

							<h3 class="mt-24 mb-8">Background color:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="background"
							></vh-common-material-color-picker>
						</div>

						<!-- SIDE PANEL -->
						<div class="group" formGroupName="sidepanel">
							<h2>Side Panel</h2>

							<vh-control-toggle [label]="'hide'" formControlName="hidden"></vh-control-toggle>

							<h3 class="mt-24">Position:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-12" value="left">Left</mat-radio-button>
								<mat-radio-button class="mb-12" value="right">Right</mat-radio-button>
							</mat-radio-group>
						</div>
					</ng-container>

					<!-- VERTICAL LAYOUT #3 -->
					<ng-container *ngSwitchCase="'vertical-layout-3'">
						<!-- LAYOUT WIDTH -->
						<div class="group mt-32">
							<h2>Layout Width</h2>
							<mat-radio-group formControlName="width">
								<mat-radio-button class="mb-12" value="fullwidth">Fullwidth</mat-radio-button>
								<mat-radio-button class="mb-12" value="boxed">Boxed</mat-radio-button>
							</mat-radio-group>
						</div>

						<!-- NAVBAR -->
						<div class="group" formGroupName="navbar">
							<h2>Navbar</h2>

							<vh-control-toggle [label]="'Hide'" formControlName="hidden"></vh-control-toggle>

							<vh-control-toggle [label]="'Folded'" class="mt-24" formControlName="folded"></vh-control-toggle>

							<h3 class="mt-24">Position:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-16" value="left">Left</mat-radio-button>
								<mat-radio-button class="mb-16" value="right">Right</mat-radio-button>
							</mat-radio-group>

							<h3 class="mt-8">Variant:</h3>
							<mat-radio-group formControlName="variant">
								<mat-radio-button class="mb-16" value="vertical-style-1">Style 1</mat-radio-button>
								<mat-radio-button class="mb-16" value="vertical-style-2">Style 2</mat-radio-button>
							</mat-radio-group>

							<h3 class="mt-16 mb-8">Primary background:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="primaryBackground"
							></vh-common-material-color-picker>

							<h3 class="mt-16 mb-8">Secondary background:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="secondaryBackground"
							></vh-common-material-color-picker>
						</div>

						<!-- TOOLBAR -->
						<div class="group" formGroupName="toolbar">
							<h2>Toolbar</h2>

							<vh-control-toggle formControlName="hidden"> Hide </vh-control-toggle>

							<h3 class="mt-24">Position:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-12" value="above-static">Above Static</mat-radio-button>
								<mat-radio-button class="mb-12" value="above-fixed">Above Fixed</mat-radio-button>
							</mat-radio-group>

							<mat-checkbox class="mt-24" formControlName="customBackgroundColor">
								Use custom background color
							</mat-checkbox>

							<h3 class="mt-24 mb-8">Background color:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="background"
							></vh-common-material-color-picker>
						</div>

						<!-- FOOTER -->
						<div class="group" formGroupName="footer">
							<h2>Footer</h2>

							<vh-control-toggle [label]="'Hide'" formControlName="hidden"></vh-control-toggle>

							<h3 class="mt-24">Position:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-12" value="above-static">Above Static</mat-radio-button>
								<mat-radio-button class="mb-12" value="above-fixed">Above Fixed</mat-radio-button>
							</mat-radio-group>

							<mat-checkbox class="mt-24" formControlName="customBackgroundColor">
								Use custom background color
							</mat-checkbox>

							<h3 class="mt-24 mb-8">Background color:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="background"
							></vh-common-material-color-picker>
						</div>

						<!-- SIDE PANEL -->
						<div class="group" formGroupName="sidepanel">
							<h2>Side Panel</h2>

							<vh-control-toggle [label]="'Hide'" formControlName="hidden"></vh-control-toggle>

							<h3 class="mt-24">Position:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-12" value="left">Left</mat-radio-button>
								<mat-radio-button class="mb-12" value="right">Right</mat-radio-button>
							</mat-radio-group>
						</div>
					</ng-container>

					<!-- HORIZONTAL LAYOUT #1 -->
					<ng-container *ngSwitchCase="'horizontal-layout-1'">
						<!-- LAYOUT WIDTH -->
						<div class="group mt-32">
							<h2>Layout Width</h2>
							<mat-radio-group formControlName="width">
								<mat-radio-button class="mb-12" value="fullwidth">Fullwidth</mat-radio-button>
								<mat-radio-button class="mb-12" value="boxed">Boxed</mat-radio-button>
							</mat-radio-group>
						</div>

						<!-- NAVBAR -->
						<div class="group" formGroupName="navbar">
							<h2>Navbar</h2>

							<vh-control-toggle [label]="'Hide'" formControlName="hidden"></vh-control-toggle>

							<h3 class="mt-24">Position:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-16" value="top">Top</mat-radio-button>
							</mat-radio-group>

							<h3 class="mt-8">Variant (Vertical):</h3>
							<mat-radio-group formControlName="variant">
								<mat-radio-button class="mb-16" value="vertical-style-1">Style 1</mat-radio-button>
								<mat-radio-button class="mb-16" value="vertical-style-2">Style 2</mat-radio-button>
							</mat-radio-group>

							<h3 class="mt-16 mb-8">Primary background:</h3>
							<vh-common-material-color-picker class="mb-16" formControlName="primaryBackground">
							</vh-common-material-color-picker>

							<h3 class="mt-16 mb-8">Secondary background (Vertical):</h3>
							<vh-common-material-color-picker class="mb-16" formControlName="secondaryBackground">
							</vh-common-material-color-picker>
						</div>

						<!-- TOOLBAR -->
						<div class="group" formGroupName="toolbar">
							<h2>Toolbar</h2>

							<vh-control-toggle formControlName="hidden"> Hide </vh-control-toggle>

							<h3 class="mt-24">Position:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-12" value="above">Above</mat-radio-button>
								<mat-radio-button class="mb-12" value="below">Below</mat-radio-button>
							</mat-radio-group>

							<mat-checkbox class="mt-24" formControlName="customBackgroundColor">
								Use custom background color
							</mat-checkbox>

							<h3 class="mt-24 mb-8">Background color:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="background"
							></vh-common-material-color-picker>
						</div>

						<!-- FOOTER -->
						<div class="group" formGroupName="footer">
							<h2>Footer</h2>

							<vh-control-toggle formControlName="hidden"> Hide </vh-control-toggle>

							<h3 class="mt-24">Position:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-12" value="above-fixed">Above Fixed</mat-radio-button>
								<mat-radio-button class="mb-12" value="above-static">Above Static</mat-radio-button>
							</mat-radio-group>

							<mat-checkbox class="mt-24" formControlName="customBackgroundColor">
								Use custom background color
							</mat-checkbox>

							<h3 class="mt-24 mb-8">Background color:</h3>
							<vh-common-material-color-picker
								class="mb-16"
								formControlName="background"
							></vh-common-material-color-picker>
						</div>

						<!-- SIDE PANEL -->
						<div class="group" formGroupName="sidepanel">
							<h2>Side Panel</h2>

							<vh-control-toggle [label]="'Hide'" formControlName="hidden"></vh-control-toggle>

							<h3 class="mt-24">Position:</h3>
							<mat-radio-group formControlName="position">
								<mat-radio-button class="mb-12" value="left">Left</mat-radio-button>
								<mat-radio-button class="mb-12" value="right">Right</mat-radio-button>
							</mat-radio-group>
						</div>
					</ng-container>
				</ng-container>
			</div>

			<!-- CUSTOM SCROLLBARS -->
			<div class="group">
				<h2>Пользовательский скролл</h2>

				<vh-control-toggle [label]="'Включить'" class="mb-12" formControlName="customScrollbars"></vh-control-toggle>
			</div>
		</ng-container>
	</form>
</div>
