import { Injectable } from '@angular/core';
import { fromEvent, map } from 'rxjs';

import { spaDispatchEvent } from './dispatch';

@Injectable({ providedIn: 'root' })
export class SpaApiEvents {
	get currentWindowIsChild() {
		return window.top !== window;
	}

	get topWindowIsSpa() {
		return Boolean(window.top['__IS_1F_SPA_BOOT__']);
	}

	get currentWindowIsChildFrameOfSpa() {
		return this.currentWindowIsChild && this.topWindowIsSpa;
	}

	get isTopWindow() {
		return window.top === window;
	}

	readonly userActivatePage$ = this.fromEvent('user-activate-page');

	dispatchEvent(name: SpaApiEventsEnum | string, details?: any, payloadTypeEvent?: string, win?: Window) {
		spaDispatchEvent(name, details, payloadTypeEvent, win);
	}

	fromEvent<T = any>(name: string) {
		return fromEvent<CustomEvent>(window, name).pipe(map(e => e.detail as T));
	}
}

export enum SpaApiEventsEnum {
	spaOpenTask = 'spa:open-task',
	feedWidgetsStateChange = 'feed-widgets-state-change',
	spaNavigate = 'spa:navigate',
	spaCommentToSubtask = 'spa:comment-to-subtask',
	spaCommentToTask = 'spa:comment-to-task',
	spaOpenModalIframe = 'spa:open-modal-iframe',
	spaOpenFilePreview = 'spa:open-file-preview',
}
