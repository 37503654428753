import { Provider } from '@angular/core';

import { ApplicationsDbProvider } from './abstract';
import { ApplicationsDbProviderImpl } from './provider';

export const NGDI_CONF_APPS_DB: Provider[] = [
	{
		provide: ApplicationsDbProvider,
		useClass: ApplicationsDbProviderImpl
	}
];
