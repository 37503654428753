import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	inject,
	OnInit,
	Optional,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EditMailboxService } from '@spa/common/components/email/edit-mailbox/edit-mailbox.service';
import { CronClient } from '@spa/cron';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { ResourceService } from '@spa/localization';
import { LocalStorageProvider, SessionUser, UrlProvider, ViewDestroyStreamService } from '@valhalla/core';
import { IMailboxDescription, IMailboxFolder } from '@valhalla/data/entities';
import { DataHttpService } from '@valhalla/data/http';
import { parseMessageFromError } from '@valhalla/utils';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, from, merge, of } from 'rxjs';
import { catchError, debounceTime, filter, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';

import { NotificationService } from '../../../features/notification/notification.service';

import { NavPanelEmailService } from './nav-panel-email.service';
import { ISyncFolderType } from '@spa/data/http/email';
import { FacadeViewService } from '@spa/facade/facade.service';
import { EmailListGridService } from '@spa/common/components/email/email-list-grid/email-list-grid.service';

const CreateMailboxComponent$ = from(
	import('@spa/common/components/email/create-mailbox/create-mailbox.component').then(m => m.CreateMailboxComponent)
);

const EmailCardNewDialogComponent$ = from(
	import('@spa/common/components/email/email-card-new-dialog/email-card-new-dialog.component').then(
		m => m.EmailCardNewDialogComponent
	)
);

const EditMailboxDialogComponent$ = from(
	import('@spa/common/components/email/edit-mailbox-dialog/edit-mailbox-dialog.component').then(
		m => m.EditMailboxDialogComponent
	)
);

export const STATE_EXPAND_COLLAPSE_EMAIL = 'StateExpandCollapseEmail';

@Component({
	selector: 'vh-layout-nav-panel-email',
	templateUrl: 'nav-panel-email.component.html',
	styleUrls: ['nav-panel-email.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class NavPanelEmailComponent implements OnInit {
	constructor(
		readonly server: DataHttpService,
		readonly destroy$: ViewDestroyStreamService,
		readonly modal: ModalWindowsService,
		readonly router: Router,
		readonly route: ActivatedRoute,
		readonly urlBuilder: UrlProvider,
		readonly sessionUser: SessionUser,
		readonly notificationService: NotificationService,
		readonly navPanelEmailService: NavPanelEmailService,
		readonly editMailboxService: EditMailboxService,
		readonly resource: ResourceService,
		readonly storage: LocalStorageProvider,
		readonly emailListGridService: EmailListGridService,
		@Optional() readonly facade: FacadeViewService
	) {}

	@HostBinding('class.vh-layout-nav-panel-email')
	hostClassSelector = true;

	@Output()
	expandCollapseEvent = new EventEmitter();

	readonly cron = inject(CronClient);
	protected collapsedFolders = new Set();
	readonly mailboxFolders = new Map<number, Map<number, IMailboxFolder>>();
	readonly firstLoading$ = new BehaviorSubject(true);

	readonly excludeFolders = [
		'Контакты',
		'Дни рождения',
		'Задачи',
		'Заметки',
		'Журнал',
		'RSS-подписки',
		'Ошибки синхронизации',
		'Исходящие',
		'Outbox',
	];

	readonly qpForOpenFolder$ = new BehaviorSubject(null);
	get qpForOpenFolder() {
		return this.qpForOpenFolder$.value;
	}

	mailboxes$ = combineLatest([
		this.navPanelEmailService.mailboxes$,
		this.cron.from(this.cron.knownNames.mailboxes).pipe(
			startWith({ lastResult: null }),
			map(r => r?.lastResult)
		),
	]).pipe(
		switchMap(([mailboxes, lastResult]) => {
			const mbxs = lastResult || mailboxes || []; //?.filter(mb => !mb.isDisabled);
			const activeMailboxes = [...mbxs.filter(mb => !mb.isDisabled), ...mbxs.filter(mb => mb.isDisabled)];

			if (this.firstLoading$.value) {
				const mailboxesIds = activeMailboxes?.map(m => m?.mailBoxId);
				this.clearStorageExpandCollapse(mailboxesIds);
			}

			if (!activeMailboxes?.length) {
				return of([]);
			}

			return forkJoin(
				activeMailboxes.map(mailbox => {
					const isUnauthorized = this.navPanelEmailService.isUnauthorized(mailbox?.mailBoxId);
					let isError = false;

					const req$ = isUnauthorized
						? of([])
						: this.server.mail.getFolders({
								mailBoxId: mailbox?.mailBoxId,
								getWatchFoldersOnly: true,
						  });

					return req$.pipe(
						catchError(err => {
							console.error(err);
							if (err?.error?.Errors[0]?.ErrorType === 26) {
								this.navPanelEmailService.setUnauthorizedMailbox(mailbox);
							}
							isError = true;
							return of([]);
						}),
						map(folders => {
							return {
								...mailbox,
								isError: isError || isUnauthorized,
								folders: folders.map(f => {
									return {
										...f,
										mailBoxId: mailbox?.mailBoxId,
										isDisabled: mailbox?.isDisabled,
										serverPath: f?.path,
										folderName: f?.name,
										folderId: f?.id,
										parentFolderId: f?.parentId,
									};
								}),
							};
						})
					);
				})
			);
		}),
		switchMap(mailBoxes => {
			return this.resource.resolveCurrentResource('common').pipe(map(resx => ({ mailBoxes, resx })));
		}),
		map(({ mailBoxes, resx }) => {
			const mailboxes = mailBoxes as any;

			return mailboxes.map(box => {
				if (box.mailBoxId === this.qpForOpenFolder?.mailBoxId) {
					this.collapsedFolders.add(this.getStorageId({ ...box, isMailbox: true }));
				}

				box.folders = this.mapFolders(box.folders, resx);
				box.folders = this.folderTreeWithOpenedActive(box.folders, this.qpForOpenFolder?.folderPath);

				return {
					name: box?.mailBoxName,
					isMailbox: true,
					children: this.sortFolder(box?.folders),
					inboxUnreadCount: this.getInboxUnreadCount(box?.folders),
					isDisabled: box?.isDisabled,
					isOpen:
						this.isOpenFolderState(this.getStorageId({ ...box, isMailbox: true })) ||
						this.collapsedFolders.has(this.getStorageId({ ...box, isMailbox: true })),
					...box,
				};
			});
		}),
		tap(mailboxes => {
			this.updateCommonUnreadCounter(mailboxes);
			this.navPanelEmailService.mailboxesTree$.next(mailboxes);

			this.qpForOpenFolder$.next(null);
			this.firstLoading$.next(false);

			this.navPanelEmailService.updatedNotify();
		}),
		catchError(() => {
			this.navPanelEmailService.updatedNotify();
			return EMPTY;
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	noMailBoxes$ = this.mailboxes$.pipe(map(bx => !bx?.length));

	ngOnInit() {
		this.navPanelEmailService.openActiveFolderNotify$
			.pipe(
				take(1),
				tap(params => {
					this.qpForOpenFolder$.next(params);
					this.navPanelEmailService.update();
				})
			)
			.subscribe();
	}

	getInboxUnreadCount(folders) {
		const inboxFolder = folders.find(f => f?.folderType === ISyncFolderType.Inbox);

		if (!inboxFolder) {
			return null;
		}

		return inboxFolder?.unreadCount;
	}

	updateCommonUnreadCounter(mailboxes: any[]) {
		let counter = 0;

		if (!mailboxes?.length) {
			return this.navPanelEmailService.commonUnreadCounter$.next(counter);
		}

		mailboxes.forEach(mailbox => {
			if (mailbox?.showCounters) {
				counter = counter + mailbox?.inboxUnreadCount;
			}
		});

		this.navPanelEmailService.commonUnreadCounter$.next(counter);
	}

	mapFolders(folders, resx) {
		return folders
			.filter(bf => !bf.disabled && !this.excludeFolders.includes(bf?.folderName))
			.map(f => {
				const currentFolderName = f?.folderName?.toLowerCase();
				const currentFolderType = f?.folderType;

				const systemFolders = this.navPanelEmailService.systemFolders;
				const folder = systemFolders.find(sf => sf?.folderType === currentFolderType);

				if (folder) {
					return {
						...f,
						customFolderName: resx[folder?.resxKey],
						customOrder: folder?.order,
					};
				}

				return {
					...f,
					customOrder: 9999,
				};
			})
			.filter(f => {
				const currentFolderName = f?.folderName?.toLowerCase();
				const currentFolderType = f?.folderType;
				const systemFolders = this.navPanelEmailService.systemFolders;

				const folderWithSystemName = systemFolders.find(
					sf =>
						sf?.keys?.includes(currentFolderName) &&
						(currentFolderType === ISyncFolderType.Folder || !currentFolderName)
				);

				return !folderWithSystemName;
			});
	}

	sortFolder(folders: any[]) {
		return folders.sort((a, b) => a?.customOrder - b?.customOrder);
	}

	folderTreeWithOpenedActive(folders: IMailboxFolder[], activeFolderPath) {
		if (!activeFolderPath) {
			return this.createFolderTree2(folders);
		}

		const activeFolder = folders.find(f => f?.serverPath === activeFolderPath);

		if (activeFolder) {
			this.setOpenedFolder(activeFolder, folders);
		}

		return this.createFolderTree2(folders);
	}

	setOpenedFolder(activeFolder: IMailboxFolder, folders: IMailboxFolder[]) {
		this.collapsedFolders.add(this.getStorageId(activeFolder));

		if (!activeFolder?.parentFolderId) {
			return;
		}

		const parentFolder = folders.find(f => f?.folderId === activeFolder?.parentFolderId);

		if (parentFolder) {
			return this.setOpenedFolder(parentFolder, folders);
		}
	}

	createFolderTree2(arr: IMailboxFolderMapped[]) {
		const arrMap = arr.map(item => ({
			...item,
			name: item?.customFolderName || item?.folderName,
			isOpen: this.isOpenFolderState(this.getStorageId(item)) || this.collapsedFolders.has(this.getStorageId(item)),
			children: [],
		}));

		const res = arrMap.filter(item => {
			item.children = arrMap.filter(i => i.parentFolderId === item.folderId);

			const isRoot = !item?.parentFolderId || !arrMap.find(i => i?.folderId === item?.parentFolderId);
			return isRoot;
		});

		return res;
	}

	checkMailbox(box: IMailboxDescription) {
		const configurationUrl = `/spa/link?url=${encodeURIComponent(
			`/admin/Email/MailBoxEdit.aspx?FromFrontEnd=1&MailBoxId=${box.mailBoxId}`
		)}`;
		const timeout = 24 * 60 * 60 * 60;
		if (box.invalidCredentials) {
			this.notificationService.error(
				'Ошибка синхронизации почты',
				`Логин и пароль недействительны, вы можете перенастроить их по <a href='${configurationUrl}' target="_blank">ссылке</a>`,
				{
					timeout: timeout,
					frequencyOptions: {
						timeout: timeout,
						uniqueKey: 'mailbox-wrong-logopass',
					},
				}
			);
		}

		if (box.mailboxConnectFailsAttempts && box.mailboxConnectFailsAttempts >= 10) {
			this.notificationService.error(
				'Ошибка синхронизации почты',
				`Невозможно соединиться с почтовым сервером, вы можете перенастроить учетные данные по <a href='${configurationUrl}' target="_blank">ссылке</a>`,
				{
					timeout: timeout,
					frequencyOptions: {
						timeout: timeout,
						uniqueKey: 'mailbox-wrong-settings',
					},
				}
			);
		}
	}

	clearStorageExpandCollapse(mailboxesIds: any[]) {
		if (!mailboxesIds?.length) {
			this.storage.set(STATE_EXPAND_COLLAPSE_EMAIL, []);
		}

		const stateExpandCollapse = this.storage.get<IStateExpandCollapseEmail[]>(STATE_EXPAND_COLLAPSE_EMAIL) || [];
		const filteredState = stateExpandCollapse.filter(item => {
			const mailboxId = item?.id?.split('-')[0];
			return mailboxesIds.includes(Number(mailboxId));
		});

		this.storage.set(STATE_EXPAND_COLLAPSE_EMAIL, filteredState);
	}

	getStorageId(folderOrMailbox) {
		if (folderOrMailbox?.isMailbox) {
			return `${folderOrMailbox?.mailBoxId}-${folderOrMailbox?.mailServerId}`;
		}

		return `${folderOrMailbox?.mailBoxId}-${folderOrMailbox?.folderId}`;
	}

	expandCollapseEmailItem(folderOrMailbox) {
		const stateExpandCollapse = this.storage.get<IStateExpandCollapseEmail[]>(STATE_EXPAND_COLLAPSE_EMAIL) || [];
		const storageId = this.getStorageId(folderOrMailbox);
		const currItem = stateExpandCollapse.find(i => i?.id === storageId);

		if (currItem) {
			if (this.collapsedFolders.has(storageId)) {
				currItem['isOpen'] = false;
				this.collapsedFolders.delete(storageId);
				this.storage.set(STATE_EXPAND_COLLAPSE_EMAIL, stateExpandCollapse);
				return;
			} else {
				currItem['isOpen'] = !currItem['isOpen'];
				this.storage.set(STATE_EXPAND_COLLAPSE_EMAIL, stateExpandCollapse);
			}
		} else {
			if (this.collapsedFolders.has(storageId)) {
				this.collapsedFolders.delete(storageId);
				stateExpandCollapse.push({
					id: storageId,
					isOpen: false,
				});

				this.storage.set(STATE_EXPAND_COLLAPSE_EMAIL, stateExpandCollapse);
			} else {
				stateExpandCollapse.push({
					id: storageId,
					isOpen: true,
				});
				this.storage.set(STATE_EXPAND_COLLAPSE_EMAIL, stateExpandCollapse);
			}
		}
	}

	isOpenFolderState(id) {
		const stateExpandCollapse = this.storage.get<IStateExpandCollapseEmail[]>(STATE_EXPAND_COLLAPSE_EMAIL) || [];
		const currItem = stateExpandCollapse.find(i => i?.id === id);
		return currItem?.isOpen || false;
	}

	isFolderHasParentSync(folder: IMailboxFolder, mailboxId: number): boolean {
		const foldersRec = this.mailboxFolders.get(mailboxId);
		return foldersRec.has(folder.parentFolderId);
	}

	onFolderClick({ e, item }: { e: Event; item: IMailboxFolderMapped }) {
		const folderPath = item?.serverPath;
		const folderName = item?.customFolderName || item?.folderName;
		const folderType = item?.folderType;
		const mouseEvent = e as MouseEvent;
		const openInNewTab = mouseEvent?.ctrlKey || mouseEvent?.metaKey;

		const url = `/email-client/${item?.mailBoxId}/list`;

		if (openInNewTab) {
			const urlForNewWindow = this.urlBuilder.getUrl(url, true);
			return window.open(urlForNewWindow, '_blank');
		} else {
			const urlForNavigate = this.urlBuilder.getUrl(url);
			return this.router.navigate([urlForNavigate], { queryParams: { folderPath, folderName, folderType } });
		}
	}

	navLink(navParts: any[], navExtras: Record<any, any>, e?: Event) {
		const mouseEvent = e as MouseEvent;
		const openInNewTab = mouseEvent?.ctrlKey || mouseEvent?.metaKey;

		if (openInNewTab) {
			let serializeUrl = this.router.serializeUrl(this.router.createUrlTree(navParts, navExtras));
			serializeUrl = this.urlBuilder.getUrl(serializeUrl, true);
			return window.open(serializeUrl, '_blank');
		} else {
			return this.router.navigate(navParts, navExtras);
		}
	}

	refresh() {
		this.navPanelEmailService.update();
		this.editMailboxService.updateNotify();
	}

	trackByMailboxId(idx: number, box: IMailboxDescription) {
		return box.mailBoxId;
	}

	trackById(idx: number, item: any) {
		return item?.mailBoxId || item?.folderId || idx;
	}

	addNewMailBox() {
		return CreateMailboxComponent$.pipe(
			take(1),
			switchMap(component => {
				return this.modal.openDialog(component, { data: {}, maxWidth: '100vw' }).afterClosed();
			})
		).subscribe(res => {
			if (res) {
				this.refresh();
			}
		});
	}

	deleteMailBox(mailboxId: number) {
		this.modal
			.openConfirm('Удалить почтовый ящик?', null, 'common.deleteAMailBox')
			.afterClosed()
			.pipe(
				filter(isConfirm => Boolean(isConfirm)),
				tap(() => {
					this.facade?.showProgressBar();
				}),
				switchMap(_ => this.server.mail.deleteMailbox(mailboxId)),
				catchError(err => {
					this.facade?.hideProgressBar();
					this.modal.openError(parseMessageFromError(err));
					return EMPTY;
				})
			)
			.subscribe(_ => {
				this.facade?.hideProgressBar();
				const mailboxesTree = this.navPanelEmailService.mailboxesTree$.value;
				const mailboxes = mailboxesTree.filter(mb => mb?.mailBoxId !== mailboxId);
				const activeMailbox = mailboxes[0];
				const firstFolder = activeMailbox?.folders[0];

				if (firstFolder && location.href.includes(`/email-client/${mailboxId}/list`)) {
					const url = `/email-client/${firstFolder?.mailBoxId}/list`;
					this.router.navigate([url], {
						queryParams: { folderPath: firstFolder?.path, folderName: firstFolder?.folderName },
					});
					this.collapsedFolders.add(this.getStorageId({ ...activeMailbox, isMailbox: true }));
				}

				this.refresh();
			});
	}

	addToFavorites(folder, mailBoxId) {
		const emailUrl = this.router.serializeUrl(
			this.router.createUrlTree([`/email-client/${mailBoxId}/list`], {
				queryParams: {
					folderPath: folder?.folderPath || folder?.path,
					folderName: folder?.folderName,
				},
			})
		);
		this.server.mail
			.addToFavorites({
				folderName: folder?.customFolderName,
				link: emailUrl,
			})
			.pipe(
				catchError(err => {
					this.modal.openError(parseMessageFromError(err));
					return EMPTY;
				})
			)
			.subscribe();
	}

	setAllRead(folder) {
		return this.server.mail
			.setAllRead(folder?.mailBoxId, folder?.path)
			.pipe(take(1))
			.subscribe({
				next: () => {
					this.refresh();
					this.emailListGridService.updateEmailListGrid();
				},
				error: err => {
					this.modal.openError(parseMessageFromError(err));
				},
			});
	}

	deleteAllMessages(folder) {
		return this.server.mail
			.deleteAllMessages(folder?.mailBoxId, folder?.path)
			.pipe(take(1))
			.subscribe({
				next: () => {
					this.refresh();
					this.emailListGridService.updateEmailListGrid();
				},
				error: err => {
					this.modal.openError(parseMessageFromError(err));
				},
			});
	}

	clickNewEmail(id: number) {
		return EmailCardNewDialogComponent$.pipe(
			take(1),
			switchMap(component => {
				return this.modal.openDialog(component, { data: { mailboxId: id }, maxWidth: '100vw' }).afterClosed();
			})
		).subscribe();
	}

	clickSetting(id: number) {
		this.router.navigate(['/email-client/mailbox/edit'], {
			queryParams: {
				mailboxId: id,
			},
		});
	}

	onOpenEditMailboxDialog(item) {
		return EditMailboxDialogComponent$.pipe(
			take(1),
			switchMap(component => {
				return this.modal
					.openDialog(component, {
						data: { mailboxId: item?.mailBoxId, authorizeMode: true },
						width: '320px',
						minHeight: '280px',
					})
					.afterClosed();
			})
		).subscribe();
	}

	getFontsAndSignUrl(id: number) {
		const url = '/Emails/EditFontsAndSignature.aspx?MailBoxId=' + id;
		return { url };
	}

	getAddFolderSearchUrl(id: number) {
		const url = '/Emails/SearchFolders/SearchFolderCreate.aspx?action=new&mailboxId=' + id;
		return { url };
	}
}

export interface IStateExpandCollapseEmail {
	id: any;
	isOpen: boolean;
}

export interface IMailboxFolderMapped extends IMailboxFolder {
	customFolderName?: string;
	customOrder?: number;
}
