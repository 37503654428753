import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, isDevMode } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { combineLatest, fromEvent, Observable, of, Subject } from 'rxjs';

import { filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { rxSetInterval } from '../../../../../../libs/utils/src';
import { AbstractLogger, LoggerFactory } from '../diagnostics';
import { UrlProvider } from '../url';
import { AppVersionService } from './abstract';
import { IAppInfo } from './model';

@Injectable()
export class AppVersionServiceImpl implements AppVersionService {
	constructor(
		logger: LoggerFactory,
		readonly updates: SwUpdate,
		readonly zone: NgZone,
		readonly url: UrlProvider,
		readonly http: HttpClient
	) {
		this._logger = logger.createLogger('core/version');
		this._init();
		// setTimeout(() => {
		// 	this.testInvalidate = true;
		// }, 5000);
	}

	testInvalidate = false;
	private _logger: AbstractLogger;
	private _appInfo: Partial<IAppInfo> = {};
	private _keyStorageBuildHash = 'appInfo:storageBuildHash';
	private _newVersionAvailable = false;
	private _checkingNewVersion = false;

	private _updateStarted$ = new Subject();
	readonly updateStarted$: Observable<any> = this._updateStarted$.asObservable();
	readonly pageVisible$ = fromEvent(document, 'visibilitychange', { capture: false }).pipe(
		startWith(!document.hidden),
		map(_ => !document.hidden),
		shareReplay({ refCount: true, bufferSize: 1 })
	);
	readonly defaultRuntimeBuildTime = Date.now();

	get appInfo(): Partial<IAppInfo> {
		return this._appInfo;
	}

	private _init() {
		let buildHash = localStorage.getItem(this._keyStorageBuildHash) || null;
		this.update({ buildHash });
		if (!this.updates.isEnabled) {
			return this._logger.info('Service worker is not enabled');
		}
		this.updates.available.subscribe(event => {
			this._newVersionAvailable = true;
			this._logger.forceLogInfo(
				`Available new version client: ${event.available.hash}, current: ${event.current.hash}`,
				event
			);
			buildHash = event.available.hash;
			const ignoreRoutes = ['conference'];
			if (
				!ignoreRoutes.some(path => location.pathname.includes(path))
				// && confirm('Доступна новая версия приложения. Обновить?')
			) {
				this._updateStarted$.next(0 as any);
				this.updates.activateUpdate().then(() => {
					this._logger.info('Installing new version client...', this.appInfo);
					localStorage.setItem(this._keyStorageBuildHash, buildHash);
					this.update({ buildHash });
					this._newVersionAvailable = false;
					window.dispatchEvent(new CustomEvent('spa-update-available'));
				});
			}
		});
		this.updates.activated.subscribe(event => {
			this._logger.forceLogInfo(`New version activated: ${this.appInfo.version}.${event.current.hash}`, {
				event,
				appInfo: this.appInfo,
			});
		});
	}

	update(info: Partial<IAppInfo>) {
		this._appInfo = Object.assign(this._appInfo, info);
	}

	/**
	 * return object parsed from assets/release-info/app-info.json file
	 * that file auto generate after deploy
	 */
	getAppInfoFromAssetFile(withInvalidateKey = false) {
		// fixme: set url in config
		return this.http.get<Partial<IAppInfo>>(
			this.url.getUrlRelativeToAssets(`release-info/app-info.json`, withInvalidateKey),
			{ headers: { 'ignore-license-busy': 'true' } }
		);
	}

	hasRuntimeBuildNumber() {
		const info = window['@valhalla/spa-release-info'];
		return info?.build !== undefined;
	}

	getRuntimeBuildNumber() {
		const info = window['@valhalla/spa-release-info'];
		return info?.build;
	}

	getRuntimeBuildTime() {
		const info = window['@valhalla/spa-release-info'];
		return Number(info?.time || this.defaultRuntimeBuildTime);
	}

	getRuntimeGitHash() {
		const info = window['@valhalla/spa-release-info'];
		return info?.gitHash || '';
	}

	serverVersionMismatch() {
		if (this.testInvalidate) {
			return of(true);
		}
		return this.getAppInfoFromAssetFile().pipe(
			map(appInfo => this.needInvalidateCache(appInfo)),
			switchMap(needInvalidate => {
				// double chak with hash
				if (needInvalidate) {
					return this.getAppInfoFromAssetFile(true).pipe(map(appInfo => this.needInvalidateCache(appInfo)));
				}
				return of(needInvalidate);
			})
		);
	}

	/**
	 * 3.189.0 => 31890
	 * @param version value like 3.189.0
	 */
	protected getVersionNumber(version: string): number {
		return Number((version || '0').split('.').join(''));
	}

	needInvalidateCache(appInfo: Partial<IAppInfo>): boolean {
		const runtimeVersion = this.getVersionNumber(this.appInfo.version);
		const deployVersion = this.getVersionNumber(appInfo.version);
		let needInvalidateCache = runtimeVersion !== deployVersion;
		if (needInvalidateCache) {
			this._logger.forceLogInfo(
				`runtimeVersion: ${runtimeVersion}, deployVersion: ${deployVersion}, needInvalidateCache: ${needInvalidateCache}`
			);
			return needInvalidateCache;
		}

		const buildNumber = this.getRuntimeBuildNumber();
		needInvalidateCache = this.hasRuntimeBuildNumber() ? buildNumber !== Number(appInfo.build) : needInvalidateCache;

		if (needInvalidateCache) {
			this._logger.forceLogInfo(
				`runtimeBuild: ${buildNumber}, deployBuild: ${appInfo.build}, needInvalidateCache: ${needInvalidateCache}`
			);
			return needInvalidateCache;
		}

		const runtimeBuildTime = this.getRuntimeBuildTime();
		if (typeof runtimeBuildTime === 'number' && typeof appInfo.time === 'number') {
			needInvalidateCache = runtimeBuildTime !== appInfo.time;
			if (needInvalidateCache) {
				this._logger.forceLogInfo(
					`runtimeBuildTime: ${runtimeBuildTime}, deployBuildTime: ${appInfo.time}, needInvalidateCache: ${needInvalidateCache}`
				);
				return needInvalidateCache;
			}
		}

		return needInvalidateCache;
	}
}
