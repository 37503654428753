import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MobileDynamicPanelPortalComponent } from './mobile-dynamic-panel-portal.component';

@NgModule({
	declarations: [MobileDynamicPanelPortalComponent],
	exports: [MobileDynamicPanelPortalComponent],
	imports: [CommonModule, PortalModule]
})
export class MobileDynamicPanelPortalModule {}
