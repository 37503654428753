import { inject, InjectionToken } from '@angular/core';
import { ASSET_INVALIDATE_KEY, UniversalLoaderProvider, UrlProvider } from '@valhalla/core';
import { combineLatest, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

let loaded = false;

export function msgReaderScriptLoad() {
	if (loaded) {
		return of((window as any).MSGReader);
	}
	const urlProvider: UrlProvider = inject(UrlProvider as any);
	const loader: UniversalLoaderProvider = inject(UniversalLoaderProvider as any);
	const INVALIDATE_KEY = inject(ASSET_INVALIDATE_KEY);
	const jsInject = loader.injectScripts(
		urlProvider.getUrlRelativeToAssets(`scripts/msg-reader/msg.reader.js?t=${INVALIDATE_KEY}`)
	);
	return combineLatest([jsInject]).pipe(
		map(() => (window as any).MSGReader),
		tap(() => {
			loaded = true;
		})
	);
}

export type MsgReaderLoadResult = ReturnType<typeof msgReaderScriptLoad>;

export const MSG_READER_SCRIPT_LOADER = new InjectionToken<MsgReaderLoadResult>('MsgReader script loader', {
	providedIn: 'root',
	factory: msgReaderScriptLoad,
});
