<ng-container *ngIf="showNavBarMini; else navBarMainPanel">
	<ng-container *ngIf="!mobileView.mobileMode"
		><vh-layout-vertical-sidebar-mini
			class="font-size-panel"
			[ngClass]="layoutState?.panelBackground"
			[showMenuIcon]="false"
		></vh-layout-vertical-sidebar-mini>
		<div class="menu-wrapper--mini-bar-context">
			<ng-container *ngTemplateOutlet="navBarMainPanel"></ng-container></div
	></ng-container>

	<vh-mobile-main-menu *ngIf="mobileView.mobileMode"></vh-mobile-main-menu>
</ng-container>

<ng-template #navBarMainPanel>
	<div *ngIf="showHeader" class="navbar-header">
		<button class="vh-btn vh-btn-icon vh-btn--transparent folded-open-btn" (click)="toggleSidebarFolded()">
			<mat-icon>menu</mat-icon>
		</button>

		<div class="logo" [routerLink]="['/']">
			<div *ngIf="showLogo" class="logo-icon"></div>

			<span class="logo-text" [style.margin-top.px]="8" [style.margin-left.px]="showLogo ? -36 : 0">
				<ng-container *ngIf="platformDetector.isMobile()"> Первая форма </ng-container>
			</span>
		</div>

		<button
			class="vh-btn vh-btn-icon vh-btn--transparent toggle-sidebar-folded d-none d-lg-flex"
			(click)="toggleSidebarFolded()"
		>
			<mat-icon>menu</mat-icon>
		</button>

		<button
			class="vh-btn vh-btn-icon vh-btn--transparent toggle-sidebar-opened d-flex d-md-none"
			(click)="toggleSidebarOpened()"
			[style.margin-left]="'auto'"
		>
			<mat-icon>arrow_back</mat-icon>
		</button>
	</div>

	<div
		class="navbar-content navbar-scroll-container"
		#navScrollContainer
		[vhPerfectScrollbar]="activeMenuTabId !== NavigationMenu.favoritesNew"
		[vhPerfectScrollbarOptions]="perfectScrollBarOptions"
	>
		<div *ngIf="showUserHeader" class="user" [ngClass]="useCustomColors ? layoutState?.secondaryBackground : null">
			<div class="h3 username">{{ userInfo.userName$ | async }}</div>
			<div class="h5 email hint-text mt-8">{{ userInfo.userEmail$ | async }}</div>
			<div
				*ngIf="!!(backgroundDivImg$ | async)"
				class="avatar-container"
				[ngClass]="useCustomColors ? layoutState?.primaryBackground : null"
			>
				<div class="avatar" [style.background]="backgroundDivImg$ | async" [style.background-size]="'cover'"></div>
			</div>
		</div>

		<ng-container *vhSubscribe="navigation$ as navRecords">
			<ng-container *ngFor="let tab of menuTabs$ | async; trackBy: trackByTabId" [ngSwitch]="tab.id">
				<vh-common-navigation
					*ngSwitchCase="menuTabs.favorites"
					[class.pt-32]="showUserHeader"
					[style.display]="tab.id !== (activeMenuTabId$ | async) ? 'none' : 'block'"
					layout="vertical"
					(navigationClick)="onNavigationItemClick($event, tab.id)"
					[navigation]="navRecords[tab.id] | async"
				>
				</vh-common-navigation>

				<vh-common-navigation
					*ngSwitchCase="menuTabs.categories"
					[class.pt-32]="showUserHeader"
					[style.display]="tab.id !== (activeMenuTabId$ | async) ? 'none' : 'block'"
					layout="vertical"
					(navigationClick)="onNavigationItemClick($event, tab.id)"
					[navigation]="navRecords[tab.id] | async"
					[searchable]="true"
					(contextMenuClick)="onContextClick($event)"
				>
				</vh-common-navigation>

				<vh-layout-favorites-panel
					*ngSwitchCase="menuTabs.favoritesNew"
					[class.pt-32]="showUserHeader"
					[style.display]="tab.id !== (activeMenuTabId$ | async) ? 'none' : 'block'"
					(expandCollapseEvent)="clickIntoScrollContainer()"
				>
				</vh-layout-favorites-panel>

				<vh-layout-favorites-tasks-panel
					*ngSwitchCase="menuTabs.favoritesTasks"
					[class.pt-32]="showUserHeader"
					[style.display]="tab.id !== (activeMenuTabId$ | async) ? 'none' : 'block'"
				>
				</vh-layout-favorites-tasks-panel>

				<vh-layout-my-tasks-nav-panel
					*ngSwitchCase="menuTabs.personalCategories"
					[style.display]="tab.id !== (activeMenuTabId$ | async) ? 'none' : 'block'"
					(expandCollapseEvent)="clickIntoScrollContainer()"
				>
				</vh-layout-my-tasks-nav-panel>

				<vh-layout-nav-panel-email
					*ngSwitchCase="menuTabs.email"
					[style.display]="tab.id !== (activeMenuTabId$ | async) ? 'none' : 'block'"
					[vhContextMenu]="mailPanelContextMenu"
					(expandCollapseEvent)="clickIntoScrollContainer()"
				>
				</vh-layout-nav-panel-email>

				<vh-layout-favorites-comments-panel
					*ngSwitchCase="menuTabs.favoritesComments"
					[style.display]="tab.id !== (activeMenuTabId$ | async) ? 'none' : 'block'"
				>
				</vh-layout-favorites-comments-panel>

				<vh-layout-nav-panel-reports
					*ngSwitchCase="menuTabs.reports"
					[style.display]="tab.id !== (activeMenuTabId$ | async) ? 'none' : 'block'"
					(expandCollapseEvent)="clickIntoScrollContainer()"
				>
				</vh-layout-nav-panel-reports>

				<ng-container *ngIf="isAdmin$ | async">
					<vh-layout-nav-panel-admin
						*ngSwitchCase="menuTabs.admin"
						[style.display]="tab.id !== (activeMenuTabId$ | async) ? 'none' : 'block'"
						(expandCollapseEvent)="clickIntoScrollContainer()"
					>
					</vh-layout-nav-panel-admin>
				</ng-container>

				<!-- spaces panel -->
				<!-- <vh-layout-spaces-panel
					*ngSwitchCase="menuTabs.spaces"
					[style.display]="tab.id !== (activeMenuTabId$ | async) ? 'none' : 'block'"
					[tabSpacesClickNotify$]="tabSpacesClickNotify$"
				>
				</vh-layout-spaces-panel> -->
			</ng-container>
		</ng-container>
	</div>

	<div
		class="navbar-footer"
		*ngIf="showFooter$ | async"
		[class.is-shadow]="isNavbarFooterShadow$ | async"
		[class.is-mobile]="isMobile"
	>
		<div class="navbar-footer--buttons-container">
			<button
				*ngFor="let tab of menuTabsFiltered$ | async; trackBy: trackByTabId"
				class="vh-btn vh-btn--transparent navbar-footer--button"
				[class.navbar-footer--button-accent]="tab.id === (activeMenuTabId$ | async)"
				[tippy]="tab.title | resx"
				[isEnabled]="!mobileView.isMobile"
				(click)="onTabMenuClick(tab)"
			>
				<div class="navbar-footer--button-icon">
					<mat-icon [class.secondary-text]="tab.id !== (activeMenuTabId$ | async)" [svgIcon]="tab.icon"></mat-icon>

					<vh-badge
						*ngIf="(emailCounter$ | async) && tab?.id === NavigationMenu.email && !(isEmailError$ | async)"
						[value]="emailCounter$ | async"
						[color]="badgeColor.primary"
						[locale]="locale$ | async"
					></vh-badge>

					<span
						*ngIf="(isEmailError$ | async) && tab?.id === NavigationMenu.email"
						class="navbar-footer--button-error-badge"
					>
						i
					</span>
				</div>
			</button>
		</div>

		<div class="toggle-mat" *ngIf="!isMobile">
			<div class="toggle-line"></div>
			<div class="toggle-pin">
				<vh-control-toggle
					[tippy]="toolTipChecked ? ('common.unPinThePanel' | resx) : ('common.pinThePanel' | resx)"
					[disabled]="mobileView.isMobile"
					(inputValue)="onToggle($event.checked)"
					[checked]="showNavigationAlways$ | async"
				>
				</vh-control-toggle>
				<mat-icon class="s-16 pin-icon ml-4" svgIcon="vh-pin-16"> </mat-icon>
			</div>
		</div>
	</div>
</ng-template>

<mat-menu #mailPanelContextMenu="matMenu">
	<ng-template matMenuContent>
		<button mat-menu-item (click)="addNewMailBox()">{{ 'common.addNewMailBox' | resx }}</button>
	</ng-template>
</mat-menu>
