import { Injector, NgZone } from '@angular/core';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { AbstractLogger, LoggerFactory, SessionUser } from '@spa/core';
import { adjustTask, getTaskId, getTaskSubcatId, ITaskInfo } from '@spa/data/entities';
import { IStatus, ITaskMainRouteResult, ITaskUpdateExtParams, TaskInfoPart } from '@spa/data/http';
import { booleanFilter } from '@valhalla/utils';
import {
	BehaviorSubject,
	catchError,
	concatMap,
	EMPTY,
	filter,
	finalize,
	forkJoin,
	map,
	Observable,
	of,
	startWith,
	Subject,
	switchMap,
	take,
	takeUntil,
	tap,
	throttleTime,
	throwError,
} from 'rxjs';
import { CategoryApplicationService } from '../category/category-application.service';
import { ExtParamBase } from '../ext-params';
import { resolveServer } from '../server-resolver';
import { TaskExtParams } from './task-ext-params';

export class TaskApplication {
	constructor(
		protected readonly taskId: number,
		protected readonly injector: Injector,
		readonly mobileView: MobileViewService
	) {
		this.logger = injector.get(LoggerFactory).createLogger('TaskApplication');
		this.init();
	}

	static getTaskId = getTaskId;
	static getTaskSubcatId = getTaskSubcatId;
	#error$ = new BehaviorSubject<Error>(undefined);
	#lastUpdateTime: number;
	#updatingExtParamsInternal = 0;
	protected readonly updatingExtParamsChange$ = new Subject<boolean>();
	protected ignoreNextUpdateCount = 0;
	protected readonly logger: AbstractLogger;
	protected readonly taskSourceModel$ = new BehaviorSubject<Partial<ITaskInfo>>(null);
	protected readonly taskRouteStore$ = new BehaviorSubject<ITaskMainRouteResult>(null);
	protected taskRouteUpdating = false;
	protected readonly taskStatesStore$ = new BehaviorSubject<IStatus[]>([]);
	protected taskStatesUpdating = false;
	protected readonly logPrefix = `[TaskApplication:${this.taskId}]`;
	protected readonly loading$ = new BehaviorSubject(false);
	protected readonly loadReq$ = new Subject<any>();
	protected readonly destroy$ = new Subject<void>();
	readonly extParamsSet = new Set<number>();
	readonly extParams = new TaskExtParams([], this.injector);
	readonly sourceModel$ = this.taskSourceModel$.pipe(filter(task => this.filterMinimumTask(task)));
	readonly anySourceModel$ = this.taskSourceModel$;

	readonly taskRoute$ = this.taskRouteStore$.pipe(booleanFilter());
	readonly taskStates$ = this.taskStatesStore$.pipe(booleanFilter());
	readonly subcatId$ = this.sourceModel$.pipe(map(t => getTaskSubcatId(t)));
	readonly error$ = this.#error$.pipe(booleanFilter());

	get lastUpdateTime() {
		return this.#lastUpdateTime;
	}

	get sourceModel() {
		return this.taskSourceModel$.value;
	}

	get taskRoute() {
		return this.taskRouteStore$.value;
	}

	get taskStates() {
		return this.taskStatesStore$.value;
	}

	get subcatId() {
		return getTaskSubcatId(this.sourceModel);
	}

	get error() {
		return this.#error$.value;
	}

	get pendingSave() {
		return this.#updatingExtParams > 0;
	}

	set #updatingExtParams(val: number) {
		if (val < 0) {
			this.#updatingExtParamsInternal = 0;
		}
		this.#updatingExtParamsInternal = val;
		this.updatingExtParamsChange$.next(this.pendingSave);
	}

	get #updatingExtParams() {
		return this.#updatingExtParamsInternal;
	}

	isUpdating() {
		return this.updatingExtParamsChange$.pipe(startWith(this.pendingSave));
	}

	updateTaskRoute() {
		if (this.taskRouteUpdating) {
			return;
		}
		this.taskRouteUpdating = true;
		resolveServer(this.injector)
			.pipe(
				switchMap(srv => srv.task.getRoute({ taskId: this.taskId })),
				finalize(() => {
					this.taskRouteUpdating = false;
				})
			)
			.subscribe(taskRoute => {
				this.injector.get(NgZone).runTask(() => {
					this.taskRouteStore$.next(taskRoute);
				});
			});
	}

	updateTaskStates() {
		if (this.taskStatesUpdating) {
			return;
		}
		this.taskStatesUpdating = true;
		resolveServer(this.injector)
			.pipe(
				switchMap(srv => srv.task.getStatuses(this.taskId).pipe(map(res => res.states))),
				finalize(() => {
					this.taskStatesUpdating = false;
				})
			)
			.subscribe(states => {
				this.injector.get(NgZone).runTask(() => {
					this.taskStatesStore$.next(states);
				});
			});
	}

	unsubscribeUsers(...userIds: number[]) {
		return resolveServer(this.injector).pipe(
			switchMap(server =>
				server.task.changeSubscribers({
					taskId: this.taskId,
					change: {
						removeUsers: userIds,
					},
				})
			)
		);
	}

	unsubscribeGroups(...groupIds: number[]) {
		return resolveServer(this.injector).pipe(
			switchMap(server =>
				server.task.changeSubscribers({
					taskId: this.taskId,
					change: {
						removeGroups: groupIds,
					},
				})
			)
		);
	}

	subscribeUsers(...userIds: number[]) {
		return resolveServer(this.injector).pipe(
			switchMap(server =>
				server.task.changeSubscribers({
					taskId: this.taskId,
					change: {
						addUsers: userIds,
					},
				})
			)
		);
	}

	load(task?: Partial<ITaskInfo>, fullUpdate = false): Observable<Partial<ITaskInfo>> {
		if (typeof task === 'object') {
			const loadTaskId = getTaskId(task);
			if (loadTaskId !== this.taskId) {
				throw new Error(`${this.logPrefix} load task with incorrect id=${loadTaskId}`);
			}
			this.#error$.next(null);

			if (fullUpdate) {
				this.taskSourceModel$.next(task);
			} else {
				// update only undefined props
				this.taskSourceModel$.next({
					...task,
					...(this.taskSourceModel$.value || {}),
				});
			}
			return this.taskSourceModel$;
		}
		this.update();
		return this.loading$.pipe(
			filter(loading => !loading),
			take(1),
			switchMap(() => this.taskSourceModel$)
		);
	}

	update(context?: any) {
		if (this.ignoreNextUpdateCount > 0) {
			this.ignoreNextUpdateCount--;
			return this.taskSourceModel$.pipe(take(1));
		}
		this.log('task update request');
		this.loadReq$.next(context);
		return this.loading$.pipe(
			filter(loading => !loading),
			take(1),
			switchMap(() => this.taskSourceModel$),
			take(1)
		);
	}

	updateExtParams(params: ITaskUpdateExtParams, updateExtParams?: ExtParamBase[]) {
		this.#updatingExtParams++;
		this.ignoreNextUpdate();
		updateExtParams?.forEach(ep => {
			ep.pendingUpdateConfirm();
		});
		return resolveServer(this.injector).pipe(
			switchMap(server => server.task.updateExtParams(params)),
			catchError(err => {
				updateExtParams?.forEach(ep => {
					ep.pendingUpdateConfirm(false);
				});
				return throwError(() => err);
			}),
			finalize(() => {
				this.#updatingExtParams--;
				this.ignoreNextUpdate(false);
				if (!this.#updatingExtParams) {
					this.update();
				}
			})
		);
	}

	ignoreNextUpdate(incOrDec = true) {
		if (incOrDec) {
			this.ignoreNextUpdateCount++;
		} else {
			this.ignoreNextUpdateCount = Math.max(--this.ignoreNextUpdateCount, 0);
		}
		return this.ignoreNextUpdateCount;
	}

	destroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	protected log(...args: any[]) {
		if (this.taskId) {
			this.logger.log(`taskId: ${this.taskId}`, ...args);
		} else {
			this.logger.log(...args);
		}
	}

	protected init() {
		const zone = this.injector.get(NgZone);

		this.loadReq$
			.pipe(
				tap(() => {
					this.loading$.next(true);
					this.#error$.next(null);
					this.log('task loading...');
				}),
				throttleTime(3000, undefined, { leading: true, trailing: true }),
				switchMap(() => resolveServer(this.injector)),
				switchMap(server => {
					if (!this.sourceModel || !this.isFullTask()) {
						return server.task.checkExistAndAccess(this.taskId).pipe(
							map(access => {
								if (access.isTaskExists) {
									this.#error$.next(null);
									this.taskSourceModel$.next(adjustTask(access.taskShortInfo));
								}
								if (!access.isUserHasAccess) {
									throw new Error(access?.errorMessage || 'Access denied');
								}
								return server;
							})
						);
					}
					return of(server);
				}),
				concatMap(server => {
					// #1237145
					const feedCommentsOnly = location.pathname.includes('noframe/feeds/comments/');
					const taskParts = [TaskInfoPart.performers, TaskInfoPart.subscribers, TaskInfoPart.mainParams];
					if (!feedCommentsOnly) {
						taskParts.push(
							TaskInfoPart.toolbar,
							TaskInfoPart.actions,
							TaskInfoPart.optionalSignatures,
							TaskInfoPart.customTasksUsed,
							TaskInfoPart.extParams,
							TaskInfoPart.extParamBlocks,
							TaskInfoPart.files
						);
					}
					if (this.mobileView.mobileMode) {
						taskParts.push(TaskInfoPart.templates);
					}
					const reqs = {
						task: server.task
							.getInfoParts({
								taskId: this.taskId,
								parts: taskParts,
							})
							.pipe(
								catchError(err => {
									console.error(err);
									this.#error$.next(err);
									return of(undefined);
								})
							),
					};
					return forkJoin(reqs);
				}),
				catchError(err => {
					// this.taskSourceModel$.error(err);
					console.error(err);
					this.#error$.next(err);
					this.loading$.next(false);
					return EMPTY;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe({
				next: ({ task }) => {
					this.log('task loaded', task);
					zone.runTask(() => {
						this.#lastUpdateTime = Date.now();
						task && this.taskSourceModel$.next(task);
						this.loading$.next(false);
					});
				},
				error: err => {
					console.error(err);
					this.loading$.next(false);
				},
			});

		this.taskSourceModel$.pipe(takeUntil(this.destroy$)).subscribe(model => {
			if (!model?.extParams?.length) {
				this.extParams.clear();
			} else {
				model?.extParams.forEach(ep => this.extParams.add(ep));
			}
			this.extParamsSet.clear();
			model?.extParams?.forEach(ep => this.extParamsSet.add(ep.id));
		});
	}

	protected useNewExtparams() {
		const taskSubcatId = getTaskSubcatId(this.sourceModel);
		if (!taskSubcatId) {
			return of(true);
		}
		const sessionUser = this.injector.get(SessionUser);
		const appCategory = this.injector.get(CategoryApplicationService);
		return resolveServer(this.injector).pipe(
			switchMap(server =>
				forkJoin({
					appSettings: server.config.appSettingsAnonymousConfig$.pipe(take(1)),
					sessionUserId: sessionUser.userId$.pipe(take(1)),
					userSettings: server.users.sessionUserSettings$.pipe(take(1)),
					subcatConfig: appCategory.selectSubcatConfig(taskSubcatId).pipe(take(1)),
				})
			),
			map(({ appSettings, sessionUserId, userSettings, subcatConfig }) => {
				if (subcatConfig?.subcategory?.isCalendar) {
					return true;
				}
				const useNewEpAndTaskUsed = appSettings?.CustomSettings?.useNewEpAndTaskUsed || [];
				if (userSettings.useNewExtParamsAndTaskUsedSubcats.includes(taskSubcatId)) {
					return true;
				}
				if (!Array.isArray(useNewEpAndTaskUsed)) {
					return false;
				}
				return Boolean(
					useNewEpAndTaskUsed?.filter(
						item =>
							(item?.subcats?.includes(taskSubcatId) || !!item?.allSubcats) &&
							!item.excludeSubcatIds?.includes(taskSubcatId) &&
							(item?.users?.includes(sessionUserId) || !!item?.allUsers) &&
							!item.excludeUserIds?.includes(sessionUserId)
					)?.length
				);
			})
		);
	}

	isFullTask() {
		return this.filterMinimumTask(this.sourceModel);
	}

	protected filterMinimumTask(task: Partial<ITaskInfo>) {
		const isFull = typeof task?.mainParams !== 'undefined';
		if (task && !isFull) {
			// eslint-disable-next-line no-restricted-syntax
			console.debug('task DTO is not full, mainParams is not defined');
		}
		return isFull;
	}
}
