export function hexAToRGBA(h: string, opacity?: number): string {
	let r: string, g: string, b: string, a: string;

	if (h.length === 5) {
		r = '0x' + h[1] + h[1];
		g = '0x' + h[2] + h[2];
		b = '0x' + h[3] + h[3];
		a = '0.' + h[4];
	} else if (h.length === 9) {
		r = '0x' + h[1] + h[2];
		g = '0x' + h[3] + h[4];
		b = '0x' + h[5] + h[6];
		a = '0.' + h[7] + h[8];
	} else {
		return null;
	}

	return `rgba(${+r}, ${+g}, ${+b}, ${opacity !== undefined ? opacity : a})`;
}
