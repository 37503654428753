import { ModuleWithProviders, NgModule, Optional, SkipSelf, forwardRef } from '@angular/core';
import { DuplicateImportModuleError } from '../errors';

import { ConfigurationProvider } from './abstract';
import { ConfigurationProviderImpl } from './configuration.service';
import { CORE_STORE_CONFIGURATION_PROVIDERS, DEFAULT_CONFIGURATION, IConfigurationModel } from './store';

@NgModule()
export class ConfigurationModule {
	constructor(
		@Optional()
		@SkipSelf()
		configModule: ConfigurationModule
	) {
		if (configModule) throw new DuplicateImportModuleError('ConfigurationModule');
	}
	static forRoot(options?: IConfigurationModel): ModuleWithProviders<ConfigurationModule> {
		return {
			ngModule: ConfigurationModule,
			providers: [
				{
					provide: DEFAULT_CONFIGURATION,
					useValue: options,
				},
				...CORE_STORE_CONFIGURATION_PROVIDERS,
				{
					provide: ConfigurationProvider,
					useClass: ConfigurationProviderImpl,
				},
			],
		};
	}
}
