import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { InputSubject } from '@valhalla/utils';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { ViewDestroyStreamService } from '@spa/core';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { CategoryTreeNodeType } from '@spa/facade/features/navigation/providers/data-provider.dto';

@Component({
	selector: 'vh-category-item',
	templateUrl: './category-item.component.html',
	styleUrls: ['./category-item.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class CategoryItemComponent implements OnInit {
	constructor(readonly destroy$: ViewDestroyStreamService, readonly mobileService: MobileViewService) {}

	@Input()
	categoryItem: any;

	@Output()
	clickItem = new EventEmitter<any>();

	@HostBinding('class.vh-category-item')
	hostClassSelector = true;

	@Input()
	@InputSubject()
	allOpened;
	allOpened$;

	isOpen$ = new BehaviorSubject<boolean>(false);

	public readonly CategoryTreeNodeType = CategoryTreeNodeType;

	ngOnInit() {
		this.allOpened$
			.pipe(takeUntil(this.destroy$))
			.subscribe(val => (val ? this.isOpen$.next(true) : this.isOpen$.next(false)));
	}

	onClickCategory(categoryItem) {
		if (!this.isChildren(categoryItem)) {
			return this.clickItem.emit({ catId: categoryItem?.id });
		}

		this.isOpen$.next(!this.isOpen$.value);
	}

	isChildren(item) {
		return item?.children?.length;
	}

	sortChildren(children) {
		return children.sort((child1, child2) => {
			if (child1?.children?.length && !child2?.children?.length) {
				return -1;
			}

			if (!child1?.children?.length && child2?.children?.length) {
				return 1;
			}

			return 0;
		});
	}
}
