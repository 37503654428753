import { NgModule, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { UrlProvider } from '@valhalla/core';
import { IAttachment } from '@valhalla/data/entities';
import { isNumber, isObject, isString } from '@valhalla/utils';

@Pipe({ name: 'fileLink' })
export class FileLinkPipe implements PipeTransform, OnDestroy {
	constructor(readonly urlProvider: UrlProvider, readonly sanitizer: DomSanitizer) {}

	blobUrl: string;

	transform(fileOrId: string | number | IAttachment, size = 760): string | SafeUrl {
		if (this.blobUrl) {
			URL.revokeObjectURL(this.blobUrl);
		}

		if (!fileOrId) {
			return '';
		}

		if (typeof fileOrId === 'number' || typeof fileOrId === 'string') {
			return this.urlProvider.fileUrl(+fileOrId);
		}

		const id = fileOrId.id || fileOrId.uploadId || fileOrId.uploadID;

		if (id) {
			const url = this.urlProvider.fileUrl(id, fileOrId.versionId);
			return url;
		}

		if (fileOrId.file instanceof Blob) {
			this.blobUrl = URL.createObjectURL(fileOrId.file);
			return this.sanitizer.bypassSecurityTrustUrl(this.blobUrl);
		}

		return '';
	}

	ngOnDestroy(): void {
		if (this.blobUrl) {
			URL.revokeObjectURL(this.blobUrl);
		}
	}
}

@Pipe({ name: 'bgImageLink' })
export class BgImageLinkPipe extends FileLinkPipe {
	override transform(fileOrId: string | number | IAttachment): string {
		return `url(${super.transform(fileOrId)})`;
	}
}

@Pipe({ name: 'fileNoframeLink' })
export class FileNoframeLinkPipe implements PipeTransform {
	constructor(readonly urlProvider: UrlProvider) {}

	transform(fileOrId: string | number | IAttachment, size = 760): string {
		if (typeof fileOrId === 'number' || typeof fileOrId === 'string') {
			if (!fileOrId) {
				return '';
			}

			return this.urlProvider.fileUrl(+fileOrId);
		}

		const id = fileOrId.id || fileOrId.uploadId || fileOrId.uploadID;

		if (id) {
			let url = this.urlProvider.getUrl(`/noframe/file/${id}`, true);

			if (fileOrId.versionId) {
				url += `/${fileOrId.versionId}`;
			}

			return url;
		}

		return '';
	}
}

@Pipe({ name: 'safeResource' })
export class SafeResourcePipe implements PipeTransform {
	constructor(readonly sanitizer: DomSanitizer) {}

	transform(url: string): SafeUrl {
		const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
		return safeUrl;
	}
}

@Pipe({ name: 'urlSearch' })
export class UrlSearchPipe implements PipeTransform {
	transform(urlStr: string, name: string, value: string): string {
		if (typeof urlStr === 'object') {
			// maybe SafeValue after bypassSecurityTrustUrl
			return urlStr;
		}

		const url = new URL(urlStr, window.location.origin);

		if (url.searchParams.has(name)) {
			url.searchParams.set(name, value);
		} else {
			url.searchParams.append(name, value);
		}

		return url.toString();
	}
}

@Pipe({ name: 'thumbnailLink' })
export class ThumbnailLinkPipe implements PipeTransform {
	constructor(readonly url: UrlProvider) {}

	transform(fileOrId: string | number | IAttachment, size = 480, minSize = 20): string {
		const fileId =
			isNumber(fileOrId) || isString(fileOrId) ? fileOrId : fileOrId.id || fileOrId.uploadId || fileOrId.uploadID;
		const versionId = isObject(fileOrId) && fileOrId.versionId;

		if (!fileId) {
			return '';
		}

		size = size < minSize ? minSize : size;
		let url = this.url.getUrl(`/api/files/thumbnails/${fileId}?v=2.0&isSquare=false`);

		if (versionId) {
			url = this.url.getUrl(`/api/files/thumbnails/${fileId}/${versionId}?v=2.0&isSquare=false`);
		}

		if (size) {
			url += `&maxSize=${size}`;
		}

		return url;
	}
}

@NgModule({
	declarations: [
		ThumbnailLinkPipe,
		FileLinkPipe,
		FileNoframeLinkPipe,
		SafeResourcePipe,
		UrlSearchPipe,
		BgImageLinkPipe,
	],
	exports: [ThumbnailLinkPipe, FileLinkPipe, FileNoframeLinkPipe, SafeResourcePipe, UrlSearchPipe, BgImageLinkPipe],
})
export class FilesLinkPipesModule {}
