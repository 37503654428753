import { Component, Inject } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { SessionUser, UrlProvider, ViewDestroyStreamService } from '@spa/core';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { IReportDescription } from '@spa/data/entities';
import { sortByLocaleCompare } from '@valhalla/utils';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'vh-reports-dialog',
	templateUrl: './reports-dialog.component.html',
	styleUrls: ['./reports-dialog.component.scss'],
})
export class ReportsDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<any>,
		protected readonly sessionUser: SessionUser,
		readonly destroy$: ViewDestroyStreamService,
		readonly modal: ModalWindowsService,
		readonly http: HttpClient,
		private readonly _destroy$: ViewDestroyStreamService,
		private readonly _urlBuilder: UrlProvider
	) {}

	readonly reports$ = this.http.get<Array<IReportDescription>>('/app/v1.0/api/reports?contextType=3&v=1.0').pipe(
		map(reports => sortByLocaleCompare(reports, report => report.description)),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	readonly reportsGroup$ = this.reports$.pipe(
		map(reports => {
			const reportsGroupNames = [...new Set(reports.map(report => report?.block))];

			return reportsGroupNames.map(name => {
				return {
					groupName: name,
					reports: reports.filter(rep => rep.block === name),
				};
			});
		})
	);

	openReport(report: { id: number; annotation: string }): void {
		this.sessionUser.userId$.pipe(takeUntil(this._destroy$)).subscribe(userId => {
			const url = this._urlBuilder.getUrl(`/noframe/report/${report.id}?contextId=${userId}`, true);
			this.modal.openIFrame(url, report.annotation || '');
		});
	}

	closeModal(): void {
		this.dialogRef.close();
	}
}
