import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import {
	AutoFocusModule,
	DetectAutoFillModule,
	IfOnDomModule,
	PerfectScrollbarModule,
	ResizeWatcherModule,
	VhSubscribeModule,
} from '@spa/common/directives';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';
import { FeatherIconsModule } from '@spa/common/feather-icons';
import { ContextMenuModule } from '@spa/common/context-menu';

import { LocalizationModule } from '@valhalla/localization';

import { NavHorizontalCollapsibleCommonComponent } from './horizontal/collapsable/collapsable.component';
import { NavHorizontalItemCommonComponent } from './horizontal/item/item.component';
import { NavigationCommonComponent } from './navigation.component';
import { NavVerticalCollapsableCommonComponent } from './vertical/collapsable/collapsable.component';
import { NavVerticalGroupCommonComponent } from './vertical/group/group.component';
import { NavVerticalItemCommonComponent } from './vertical/item/item.component';
import { SidebarHeaderModule } from '../../../facade/layout/components/sidebar-header/sidebar-header.module';
import { SmartExpressionSelectionPipeModule } from './smart-expressions-selection.pipe';
import { TippyModule } from '@ngneat/helipopper';
import { BadgeModule, IconModule } from '@vh/core-components';
import { NavigationContextMenuModule } from './navigation-context-menu/navigation-context-menu.module';

@NgModule({
	imports: [
		CommonModule,
		ContextMenuModule,
		MatMenuModule,
		RouterModule,
		MatIconModule,
		MatRippleModule,
		MatInputModule,
		MatFormFieldModule,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
		FeatherIconsModule,
		LocalizationModule,
		SidebarHeaderModule,
		SmartExpressionSelectionPipeModule,
		TippyModule,
		BadgeModule,
		IconModule,
		NavigationContextMenuModule,
	],
	exports: [NavigationCommonComponent],
	declarations: [
		NavigationCommonComponent,
		NavVerticalGroupCommonComponent,
		NavVerticalItemCommonComponent,
		NavVerticalCollapsableCommonComponent,
		NavHorizontalItemCommonComponent,
		NavHorizontalCollapsibleCommonComponent,
	],
})
export class NavigationCommonModule {}
