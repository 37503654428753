import { Directive, AfterViewInit, Input, ElementRef, NgZone } from '@angular/core';

@Directive({ selector: '[vhAutoFocus]' })
export class AutoFocusDirective implements AfterViewInit {
	constructor(readonly host: ElementRef<HTMLElement>, readonly zone: NgZone) {}

	@Input('vhAutoFocus')
	enabled = false;

	@Input()
	focusTimeout = 0;

	@Input()
	scrollIntoView = false;

	ngAfterViewInit() {
		if (this.enabled) {
			if (!this.focusTimeout || this.focusTimeout < 0) {
				this.focus();
			} else {
				this.zone.runOutsideAngular(() =>
					setTimeout(() => {
						this.focus();
					}, this.focusTimeout)
				);
			}
		}
	}

	focus() {
		this.host.nativeElement?.focus();
		if (this.scrollIntoView) {
			this.host.nativeElement?.scrollIntoView();
		}
	}
}
