import { Selector } from 'reselect';
import { Observable } from 'rxjs';
import { IConfigurationModel } from './store/state';
import { InjectionToken } from '@angular/core';

export abstract class ConfigurationProvider {
	abstract get isProd(): boolean;
	abstract get config$(): Observable<IConfigurationModel>;
	abstract get config(): IConfigurationModel;
	abstract select<R>(selector?: Selector<IConfigurationModel, R>): Observable<R | IConfigurationModel>;
	abstract applyConfig(config: Partial<IConfigurationModel>);
	abstract getPath(path: string): string;
	abstract setPath(path: string, value);
}

export const IS_PRODUCTION = new InjectionToken<boolean>('IS PRODUCTION');
