import { Injectable, NgZone, Provider } from '@angular/core';
import { nameOf } from '@valhalla/utils';
import { IndexedDbProvider } from '../../db/indexed';
import { from, interval, Observable, zip } from 'rxjs';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { EventLogProvider } from './abstract';
import { EventLogDatabase } from './eventlog.database';
import { ILogEntry } from './eventlog.model';
import { LoggerFactory, AbstractLogger } from '../logger';

@Injectable()
export class EventLogProviderImpl implements EventLogProvider {
	constructor(
		protected readonly dbProvider: IndexedDbProvider,
		protected readonly zone: NgZone,
		loggerFactory: LoggerFactory
	) {
		this._logger = loggerFactory.createLogger('core/diagnostic/eventlog');
		this.initCleaner();
	}
	protected clearIntervalMs = 1000 * 60 * 60; // every 1 hour
	protected interval;
	protected maxLifeTimeLogEntry = 1000 * 60 * 60 * 24; // after 24 hours log messages clear from db
	protected deleting = false;
	protected db$: Observable<EventLogDatabase> = this.dbProvider.getDatabase(EventLogDatabase);
	private _logger: AbstractLogger;

	write(...logEntry: ILogEntry[]) {
		return this.db$.pipe(
			mergeMap(db => {
				return from(db.eventLog.bulkAdd(logEntry));
			})
		);
	}

	clear() {
		return this.db$.pipe(mergeMap(db => from(db.eventLog.clear()).pipe(tap(() => this._logger.info('clear table')))));
	}

	select(
		query: Partial<{
			skip: number;
			take: number;
			sort: {
				field: keyof ILogEntry;
				direction?: 'asc' | 'desc';
			};
		}>
	): Observable<{
		data: ILogEntry[];
		total: number;
	}> {
		query = query || {
			skip: 0,
			take: 10,
			sort: {
				field: 'id',
			},
		};
		return this.db$.pipe(
			mergeMap(db =>
				zip(
					from(db.eventLog.count()),
					from(db.eventLog.offset(query.skip).limit(query.take).reverse().sortBy(query.sort.field))
				).pipe(map(([total, data]) => ({ total, data })))
			)
		);
	}

	onUpdate(cb) {
		this.db$
			.pipe(
				tap(db => {
					db.eventLog.hook('updating', cb);
				})
			)
			.subscribe();
	}

	protected initCleaner() {
		this.deleteOldLogEntry().pipe(take(1)).subscribe();
		this.zone.runOutsideAngular(() => {
			setInterval(() => {
				this.deleteOldLogEntry().pipe(take(1)).subscribe();
			}, this.clearIntervalMs);
		});
	}

	protected deleteOldLogEntry() {
		return this.db$.pipe(
			mergeMap(db => {
				return from(
					db.eventLog
						.where(nameOf<ILogEntry>(e => e.timestamp))
						.below(Date.now() - this.maxLifeTimeLogEntry)
						.delete()
				).pipe(tap(() => this._logger.info('clear old log entries')));
			})
		);
	}
}

export const ngEventLogProvider: Provider[] = [
	{
		provide: EventLogProvider,
		useClass: EventLogProviderImpl,
	},
];
