<!-- SIDE PANEL -->
<ng-container *ngIf="!layoutState.layout.sidepanel.hidden && layoutState.layout.sidepanel.position === 'left'">
</ng-container>
<!-- / SIDE PANEL -->

<div id="main">
	<div
		*ngIf="
			!mobileView.mobileMode &&
			(isTopAppNavBarPosition$ | async) &&
			!router.url.includes('administration/start') &&
			!hideSidebar
		"
		class="vh-layout-vertical-sidebar-mini-wrapper"
	>
		<vh-layout-vertical-sidebar-mini
			class="font-size-panel"
			[class.vertical-sidebar-no-menu]="showNavigationAlways$ | async"
			(pinFavorites)="pinnedChange(!sideBarPinned)"
			layout="horizontal"
			[ngClass]="layoutState?.panelBackground"
			[isMobile]="isMobile"
			(menuMouseOver)="toggleFoldWhenMouseLeave(true)"
			(menuMouseLeave)="menuMouseLeaveHandler($event)"
			[showMenuIcon]="(showNavigationAlways$ | async) === false"
			[showNavigationAlways]="showNavigationAlways$ | async"
		></vh-layout-vertical-sidebar-mini>
	</div>
	<div id="container-1" class="layout-container">
		<!-- NAVBAR: Left -->
		<ng-container *ngIf="layoutState.layout.navbar.position === 'left'">
			<ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
		</ng-container>
		<!-- / NAVBAR: Left -->

		<div id="container-2" class="layout-container">
			<!-- TOOLBAR: Below fixed -->
			<ng-container *ngIf="layoutState.layout.toolbar.position === 'below-fixed'">
				<ng-container *ngTemplateOutlet="toolbar"></ng-container>
			</ng-container>
			<!-- / TOOLBAR: Below fixed -->

			<div
				id="container-3"
				class="layout-container"
				vhPerfectScrollbar
				[class.mobile-main-menu-opened]="
					fuseSidebarService.getSidebar(SidebarKeys.mobileMainMenu)?.opened && mobileView.mobileMode
				"
				[vhPerfectScrollbarOptions]="{ suppressScrollX: true, updateOnRouteChange: true }"
			>
				<!-- TOOLBAR: Below static -->
				<ng-container *ngIf="layoutState.layout.toolbar.position === 'below-static'">
					<ng-container *ngTemplateOutlet="toolbar"></ng-container>
				</ng-container>
				<!-- / TOOLBAR: Below static -->

				<!-- CONTENT -->
				<vh-layout-content id="vh-layout-main-content-area" cdkScrollable></vh-layout-content>
				<!-- / CONTENT -->

				<!-- FOOTER: Below static -->
				<ng-container *ngIf="layoutState.layout.footer.position === 'below-static'">
					<ng-container *ngTemplateOutlet="footer"></ng-container>
				</ng-container>
				<!-- / FOOTER: Below static -->
			</div>

			<!-- FOOTER: Below fixed -->
			<ng-container *ngIf="layoutState.layout.footer.position === 'below-fixed'">
				<ng-container *ngTemplateOutlet="footer"></ng-container>
			</ng-container>
			<!-- / FOOTER: Below fixed -->
		</div>

		<!-- NAVBAR: Right -->
		<ng-container *ngIf="layoutState.layout.navbar.position === 'right'">
			<ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
		</ng-container>
		<!-- / NAVBAR: Right -->
	</div>

	<!-- FOOTER: Above -->
	<ng-container *ngIf="layoutState.layout.footer.position === 'above'">
		<ng-container *ngTemplateOutlet="footer"></ng-container>
	</ng-container>
	<!-- FOOTER: Above -->
</div>

<!-- SIDE PANEL -->
<ng-container *ngIf="!layoutState.layout.sidepanel.hidden && layoutState.layout.sidepanel.position === 'right'">
</ng-container>
<!-- / SIDE PANEL -->

<!-- QUICK PANEL -->
<vh-common-sidebar name="quickPanel" position="right" class="quick-panel">
	<vh-layout-quick-panel></vh-layout-quick-panel>
</vh-common-sidebar>
<!-- / QUICK PANEL -->

<!-- TOOLBAR -->
<ng-template #toolbar>
	<vh-layout-toolbar
		*ngIf="mobileView.mobileMode"
		[ngClass]="[layoutState.layout.toolbar.position, layoutState.layout.toolbar.background]"
	>
	</vh-layout-toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
	<vh-layout-footer class="d-block d-md-none" *ngIf="showFooter" [ngClass]="[layoutState.layout.footer.position]">
	</vh-layout-footer>
	<vh-layout-footer *ngIf="mobileView.mobileMode" [ngClass]="[layoutState.layout.footer.position]"> </vh-layout-footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
	<!-- @see https://ru.1forma.ru/spa/tasks/1137562 -->
	<ng-container
		*ngIf="!layoutState.layout.navbar.hidden && !router.url.includes('administration/start') && !hideSidebar"
	>
		<ng-container *ngIf="!mobileView.mobileMode">
			<ng-container *ngTemplateOutlet="leftNavBarDesktop"></ng-container>
		</ng-container>

		<vh-common-sidebar
			*ngIf="mobileView.mobileMode"
			[name]="sideMenuName"
			class="navbar-fuse-sidebar vh-navigation-menu font-size-panel"
			[class.is-folded]="layoutState?.layout?.navbar?.folded"
			[folded]="layoutState?.layout?.navbar?.folded"
			[foldedAutoTriggerOnHover]="false"
			(foldedChanged)="onFoldedChanged($event)"
			[foldedWidth]="0"
			[showNavigationAlways]="showNavigationAlways$ | async"
		>
			<vh-layout-navbar
				[showUserHeader]="false"
				[showLogo]="false"
				[showNavBarMini]="true"
				[layoutState]="layoutState.layout.navbar"
				class="left-navbar"
				[ngClass]="layoutState.layout.navbar.background"
			></vh-layout-navbar>
		</vh-common-sidebar>
	</ng-container>
</ng-template>

<ng-template #leftNavBarDesktop>
	<ng-container *ngIf="(isLeftAppNavBarPosition$ | async) || (isRightAppNavBarPosition$ | async)">
		<div
			[class.vh-layout-vertical-sidebar-mini-wrapper--left]="isLeftAppNavBarPosition$ | async"
			[class.vh-layout-vertical-sidebar-mini-wrapper--right]="isRightAppNavBarPosition$ | async"
		>
			<vh-layout-vertical-sidebar-mini
				class="font-size-panel"
				[ngClass]="layoutState?.panelBackground"
				(pinFavorites)="pinnedChange(!sideBarPinned)"
				(menuMouseOver)="toggleFoldWhenMouseLeave(true)"
				(menuMouseLeave)="menuMouseLeaveHandler($event)"
				[showMenuIcon]="(showNavigationAlways$ | async) === false"
				[showNavigationAlways]="showNavigationAlways$ | async"
			></vh-layout-vertical-sidebar-mini>
		</div>
	</ng-container>

	<vh-common-sidebar
		#sidebarMenu
		name="navbar"
		class="no-shadow navbar-fuse-sidebar vh-navigation-menu vh-navigation-menu--desktop font-size-panel"
		[class.is-folded]="layoutState?.layout?.navbar?.folded"
		[foldedWidth]="0"
		[folded]="layoutState?.layout?.navbar?.folded"
		[foldedAutoTriggerOnHover]="false"
		[pinned]="sideBarPinned || (showNavigationAlways$ | async)"
		[showPinned]="true"
		(pinnedChange)="pinnedChange($event)"
		(foldedChanged)="onFoldedChanged($event, sidebarMenu.pinned)"
		(mouseleave)="onMouseLeaveOnSidebar()"
		(outsideClick)="onMouseLeaveOnSidebar()"
		(mouseenter)="onMouseEnterOnSidebar()"
		[class.pinned]="sidebarMenu.pinned"
		lockedOpen="gt-xs"
		[pinnedLeft]="miniBarPinnedLeft$ | async"
		[pinnedRight]="miniBarPinnedRight$ | async"
		[position]="miniBarPanelPosition$ | async"
		[showNavigationAlways]="showNavigationAlways$ | async"
	>
		<vh-layout-navbar
			[showHeader]="false"
			[showUserHeader]="false"
			[useCustomColors]="false"
			[layoutState]="layoutState.layout.navbar"
			class="left-navbar"
			[ngClass]="layoutState.layout.navbar.background"
		></vh-layout-navbar>
	</vh-common-sidebar>
	<!-- <mat-divider [vertical]="true"></mat-divider> -->
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
	<ng-container *ngIf="!layoutState.layout.navbar.hidden">
		<vh-common-sidebar
			name="navbar"
			position="right"
			class="navbar-fuse-sidebar vh-navigation-menu font-size-panel"
			[folded]="layoutState.layout.navbar.folded"
			lockedOpen="gt-md"
			[showNavigationAlways]="showNavigationAlways$ | async"
			(mouseleave)="onMouseLeaveOnSidebar()"
			(outsideClick)="onMouseLeaveOnSidebar()"
			(mouseenter)="onMouseEnterOnSidebar()"
			[pinned]="sideBarPinned || (showNavigationAlways$ | async)"
		>
			<vh-layout-navbar
				[layoutState]="layoutState.layout.navbar"
				class="right-navbar"
				[ngClass]="layoutState.layout.navbar.background"
			></vh-layout-navbar>
		</vh-common-sidebar>
	</ng-container>
</ng-template>
<!-- / RIGHT NAVBAR -->
