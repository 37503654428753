// tslint:disable:naming-convention

import { helper } from '../helper';
import { FacadeModalIframeComponent, IModalRef } from '@spa/facade/features/modals';
import { takeUntil } from 'rxjs/operators';
import { getAppTopInjector } from '../injectors';
import { UserSettingsFacadeProvider } from '@spa/facade/features/user-settings';
import { OpenPagesService } from '@spa/common/services/open-pages.service';

const hostWin = window;

let radCallback: (url: string, title?: string) => IModalRef;

export const configureRadopen = (config: { callback: (url: string, title?: string) => any }) => {
	if (!radCallback) radCallback = config.callback;
};

/** Wrapper for compatible telerik */
export const radopen = async function (url: string, title?: string) {
	if (!radCallback) {
		return helper.logger.warn('radopen is not supported');
	}
	const injector = getAppTopInjector();
	const userSettings = injector.get(UserSettingsFacadeProvider, null)?.selectUserSettings;

	const isFastReportPage = location.search.toLowerCase().includes('reportView'.toLowerCase());
	if (isFastReportPage) {
		// for old page FF filters
		return;
	}

	let modalRef: IModalRef;

	if (userSettings?.useNewTaskCard) {
		try {
			const ur = new URL(url.toLowerCase());
			if (ur.pathname.includes('/maintaskform.aspx')) {
				const taskId = +ur.searchParams.get('taskid');
				if (!isNaN(taskId)) {
					const op = injector.get(OpenPagesService);
					modalRef = (await op.openTask(
						{
							taskId,
							modal: true,
						},
						true
					)) as IModalRef;
				}
			}
		} catch (error) {}
	}

	modalRef = modalRef || radCallback(url, title);

	const modalCmp: FacadeModalIframeComponent = modalRef.componentRef.componentInstance;

	const frameLoaded$ = modalCmp.frameLoaded?.pipe(takeUntil(modalCmp.destroy$));

	let pageLoadCallbacks = [];
	let pageCloseCallbacks = [];

	function afterCloseCallbacksRun() {
		pageCloseCallbacks.forEach(cb => {
			try {
				cb(radWindow);
			} catch (err) {
				console.error(err);
			}
		});
		Object.keys(hostWin)
			.filter(k => k.startsWith('stl'))
			.forEach(stl => {
				delete hostWin[stl];
			});
	}

	modalRef.afterClosed().subscribe(() => {
		afterCloseCallbacksRun();
		pageLoadCallbacks.length = 0;
		pageCloseCallbacks.length = 0;
	});

	const radWindow = {
		setSize() {},
		moveTo() {},
		close() {
			modalRef.close(undefined);
		},
		set_width() {},
		set_height() {},
		center() {},
		add_pageLoad(pageLoadCb: (wnd: any) => void) {
			if (typeof pageLoadCb === 'function') {
				pageLoadCallbacks.push(pageLoadCb);
			}
		},
		remove_pageLoad(pageLoadCb: Function) {
			pageLoadCallbacks = pageLoadCallbacks.filter(cb => cb !== pageLoadCb);
		},
		get_contentFrame() {
			return modalCmp.frameViewer?.iframe;
		},
		GetContentFrame() {
			return hostWin;
		},
		add_close(onCloseCb: (wnd: any) => void) {
			if (typeof onCloseCb === 'function') {
				pageCloseCallbacks.push(onCloseCb);
			}
		},
		remove_close(onCloseCb: Function) {
			pageCloseCallbacks = pageCloseCallbacks.filter(cb => cb !== onCloseCb);
		},
		setUrl(urlSet: string) {},
	};

	modalCmp.destroy$.subscribe(() => {
		afterCloseCallbacksRun();
		pageLoadCallbacks.length = 0;
		pageCloseCallbacks.length = 0;
	});

	frameLoaded$?.subscribe(viewer => {
		if (viewer?.contentWindow) {
			viewer.contentWindow['radWindow'] = radWindow;
			viewer.contentWindow.frameElement['radWindow'] = radWindow;
		}

		//run callbacks
		pageLoadCallbacks.forEach(cb => {
			try {
				cb(radWindow);
			} catch (err) {
				console.error(err);
			}
		});
	});

	return radWindow;
};
