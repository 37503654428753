import { Component, HostBinding, Inject, Optional, ViewEncapsulation } from '@angular/core';
import {
	MatLegacyDialogRef as MatDialogRef,
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
	selector: 'vh-file-version-dialog',
	templateUrl: './file-version-dialog.component.html',
	styleUrls: ['./file-version-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FileVersionDialogComponent {
	constructor(
		@Optional() readonly dialogRef: MatDialogRef<any>,
		@Optional() @Inject(MAT_DIALOG_DATA) readonly data: any
	) {}

	@HostBinding('class.vh-file-version-dialog')
	hostClassSelector = true;

	closeModal() {
		this.dialogRef.close();
	}
}
