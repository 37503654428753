import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@valhalla/core';
import { ILanguageDescription } from '@valhalla/data/entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IApiResponse } from '../api-response';
import { DataHttpCommonService } from '../data-http-common.service';
import {
	ILocalizationEntity,
	ILocalizationEntityType,
	ILocalizationSetLanguageCriteria,
	ISaveLocalizationItem,
	LocalizationDataHttpService,
} from './abstract';

@Injectable()
export class LocalizationDataHttpServiceImpl implements LocalizationDataHttpService {
	constructor(readonly http: HttpClient, readonly common: DataHttpCommonService, readonly auth: AuthService) {}

	getLanguages(): Observable<Array<ILanguageDescription>> {
		const url = this.common.getApiUrl(`languages`);
		return this.http.get<IApiResponse<Array<ILanguageDescription>>>(url).pipe(map(({ data }) => data));
	}

	getCurrentLanguage(): Observable<ILanguageDescription> {
		const url = this.common.getApiUrl(`languages/user`);
		return this.http.get<IApiResponse<ILanguageDescription>>(url).pipe(map(({ data }) => data));
	}

	setLanguage({ alias }: ILocalizationSetLanguageCriteria, allowNotification?: boolean): Observable<any> {
		const url = this.common.getApiUrl(`user/${this.auth.userId}/language`);
		return this.http.post<any>(url, { language: alias, allowNotification: allowNotification });
	}

	getLocalizationEntity(
		entityType: ILocalizationEntityType,
		entityId: number,
		taskId: number,
		extParamId: number
	): Observable<ILocalizationEntity> {
		if (entityId === null) {
			const url = this.common.getApiUrl(`localization/${entityType}/create`);
			return this.http
				.post<IApiResponse<ILocalizationEntity>>(url, { taskId, extParamId })
				.pipe(map(({ data }) => data));
		}
		const url = this.common.getApiUrl(`localization/${entityType}/${entityId}`);
		return this.http.get<IApiResponse<ILocalizationEntity>>(url).pipe(map(({ data }) => data));
	}

	saveLocalizationEntity(
		entityType: ILocalizationEntityType,
		entityId: number,
		req: ISaveLocalizationItem[]
	): Observable<any> {
		req.forEach(item => {
			if (typeof item?.value === 'object') {
				item.value = JSON.stringify(item.value);
			}
		});
		const url = this.common.getApiUrl(`localization/${entityType}/${entityId}`);
		return this.http.post<any>(url, req);
	}
}
