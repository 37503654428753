import { Component, HostBinding, Inject, Optional, ViewEncapsulation } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { OpenPagesService } from '@spa/common/services/open-pages.service';
import { ViewDestroyStreamService } from '@spa/core';
import { IUserShort } from '@spa/data/entities';

@Component({
	selector: 'vh-group-members-dialog',
	templateUrl: './group-members-dialog.component.html',
	styleUrls: ['./group-members-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [ViewDestroyStreamService],
})
export class GroupMembersDialogComponent {
	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public data: { users: IUserShort[] },
		@Optional() public dialogRef: MatDialogRef<GroupMembersDialogComponent>,
		readonly openPages: OpenPagesService
	) {}

	@HostBinding('class.vh-group-members-dialog')
	hostClassSelector = true;

	openProfile(item: IUserShort): void {
		this.openPages.openProfile({
			userId: item.id,
			newTab: true,
		});
	}

	closeModal(): void {
		this.dialogRef.close();
	}
}
