import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { TippyModule } from '@ngneat/helipopper';
import { AvatarModule } from '@spa/common/components/chat-nav-avatar';
import { ControlToggleModule } from '@spa/common/components/controls/control-toggle/control-toggle.module';
import {
	AutoFocusModule,
	DetectAutoFillModule,
	IfOnDomModule,
	PerfectScrollbarModule,
	ResizeWatcherModule,
	UserStatusDirectiveModule,
	VhSubscribeModule,
} from '@spa/common/directives';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';
import { FeatherIconsModule } from '@spa/common/feather-icons';
import { PipesCommonModule } from '@spa/common/pipes';
import { LastOnlineWithGenderPipeModule } from '@spa/common/pipes/lastOnlineWithGender.pipe';
import { NeverSeenWithGenderPipeModule } from '@spa/common/pipes/neverSeenWithGender.pipe';
import { UnreadMessagesModule } from '@spa/facade/features/calendars/unread-messages.module';
import { TickersModule } from '@spa/facade/features/tickers';
import { LocalizationModule } from '@valhalla/localization';
import { AutoOpenMenuModule } from '../../../../common/components/auto-open-menu';
import { ContactsSearchModule } from '../../components/contacts-search/contacts-search.module';
import { SearchOverlayModule } from '../../components/search-overlay';
import { VerticalSidebarMiniLayoutComponent } from './sidebar-mini.component';
import { BadgeModule, IconModule } from '@vh/core-components';
import { TaskAvatarModule } from '@spa/common/components/task-avatar';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		MatIconModule,
		MatMenuModule,
		AvatarModule,
		TickersModule,
		SearchOverlayModule,
		MatFormFieldModule,
		MatInputModule,
		OverlayModule,
		A11yModule,
		MatListModule,
		MatDividerModule,
		LocalizationModule,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
		FeatherIconsModule,
		UserStatusDirectiveModule,
		LastOnlineWithGenderPipeModule,
		NeverSeenWithGenderPipeModule,
		MatDividerModule,
		ReactiveFormsModule,
		AutoOpenMenuModule,
		MatBadgeModule,
		PipesCommonModule,
		ContactsSearchModule,
		UnreadMessagesModule,
		TippyModule,
		ControlToggleModule,
		BadgeModule,
		TaskAvatarModule,
		IconModule,
	],
	exports: [VerticalSidebarMiniLayoutComponent],
	declarations: [VerticalSidebarMiniLayoutComponent],
	providers: [],
})
export class VerticalSidebarMiniLayoutModule {}
