import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { RouterModule } from '@angular/router';
import { AutoOpenMenuModule } from '@spa/common/components/auto-open-menu';
import { AvatarModule } from '@spa/common/components/chat-nav-avatar';
import { PortalHelperModule } from '@spa/common/components/portal-helper';
import { AutoFocusModule, UserStatusDirectiveModule } from '@spa/common/directives';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';
import { PipesCommonModule } from '@spa/common/pipes';
import { LastOnlineWithGenderPipeModule } from '@spa/common/pipes/lastOnlineWithGender.pipe';
import { NeverSeenWithGenderPipeModule } from '@spa/common/pipes/neverSeenWithGender.pipe';
import { LocalizationModule } from '@spa/localization';
import { ContactsSearchItemComponent } from './contacts-search-item.component';
import { TippyModule } from '@ngneat/helipopper';

@NgModule({
	imports: [
		CommonModule,
		MatFormFieldModule,
		AutoFocusModule,
		RouterModule,
		MatListModule,
		LocalizationModule,
		MatInputModule,
		PipesCommonModule,
		LastOnlineWithGenderPipeModule,
		AvatarModule,
		UserStatusDirectiveModule,
		MatIconModule,
		NeverSeenWithGenderPipeModule,
		UserPreviewPanelModule,

		AutoOpenMenuModule,
		PortalHelperModule,
		TippyModule,
	],
	exports: [ContactsSearchItemComponent],
	declarations: [ContactsSearchItemComponent],
	providers: [],
})
export class ContactsSearchItemModule {}
