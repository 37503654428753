import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	ViewChild,
} from '@angular/core';
import { UrlProvider } from '@spa/core';
import { IAttachment } from '@spa/data/entities';
import { IFileInfo } from '@spa/data/http';
import { IFileViewerDialogComponentDataOptions } from '../../file-viewer-dialog.component';
import { IFileViewer, IFileViewerSettings } from '../../file-viewer.component';

const selector = 'vh-video-viewer';

/**
 * Компонент для рендера Видео-файлов
 */
@Component({
	// tslint:disable-next-line:component-selector
	selector: selector,
	templateUrl: './video-viewer.component.html',
	styleUrls: ['./video-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [],
})
export class VideoViewerComponent implements IFileViewer, AfterViewInit {
	constructor(private cdr: ChangeDetectorRef, readonly url: UrlProvider) {}

	@Input()
	public file: Partial<IAttachment>;

	@Input()
	public fileInfo: IFileInfo;

	@Input()
	public options: IFileViewerDialogComponentDataOptions;

	@ViewChild('video')
	videoRef: ElementRef<HTMLVideoElement>;

	public isDirty: boolean;
	public settings: IFileViewerSettings;

	get fileId() {
		return this.file?.id || this.file?.uploadID || this.file?.uploadId;
	}

	get hasFileId() {
		return this.fileId > 0;
	}

	get fileName() {
		return this.file?.name || this.file?.fileName || '';
	}

	get isLinkFileName() {
		return this.fileName.includes('://');
	}

	ngAfterViewInit(): void {
		this.videoRef.nativeElement.muted = true;
		this.videoRef.nativeElement.volume = 0.5;
		this.videoRef.nativeElement.play();
	}

	getFileMeetingLink(file) {
		return this.url.fileMeetingUrl(file?.key);
	}

	getFileEmailLink(file) {
		return this.url.fileEmailUrl(file?.key);
	}
}
