import { HttpResponse } from '@angular/common/http';
import { ApplicationRef, ComponentRef, inject, Injectable } from '@angular/core';
import { DataHttpService } from '@spa/data/http';
import { BehaviorSubject, catchError, distinctUntilChanged, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LicenseManagerService {
	constructor() {
		this.#init();
	}

	get #server(): DataHttpService {
		return inject(DataHttpService);
	}
	get #appRef(): ApplicationRef {
		return inject(ApplicationRef);
	}
	#componentRef: ComponentRef<any>;
	#rootNode: HTMLElement;
	#licenseBusyState$ = new BehaviorSubject(false);
	readonly licenseBusy$ = this.#licenseBusyState$.asObservable();

	get licenseBusy() {
		return this.#licenseBusyState$.value;
	}
	set licenseBusy(value: boolean) {
		this.#licenseBusyState$.next(value);
	}

	showBusyBanner() {
		if (!this.#componentRef) {
			this.#componentRef = 'pending' as any;
			import('./license-busy-banner/license-busy-banner.component').then(({ LicenseBusyBannerComponent }) => {
				this.#rootNode = document.createElement('div');
				document.body.appendChild(this.#rootNode);
				this.#componentRef = this.#appRef.bootstrap(LicenseBusyBannerComponent, this.#rootNode);
			});
		}
	}

	closeBusyBanner() {
		if (this.#componentRef) {
			this.#componentRef.destroy();
			this.#appRef.detachView(this.#componentRef.hostView);
			document.body.removeChild(this.#rootNode);
			this.#componentRef = null;
		}
	}

	checkBusyStatus() {
		const url = this.#server.common.getApiUrl('auth/info');
		return this.#server.ngHttp
			.get(url, {
				headers: {
					'license-busy-check': 'true',
				},
			})
			.pipe(
				map(e => {
					if (e instanceof HttpResponse) {
						return e.status !== 402;
					}
					return false;
				}),
				catchError(err => {
					console.error(err);
					return of(false);
				})
			);
	}

	#init() {
		this.licenseBusy$.pipe(distinctUntilChanged()).subscribe(busy => {
			if (busy) {
				this.showBusyBanner();
			} else {
				this.closeBusyBanner();
			}
		});
	}
}
