import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';

import { LocalizationModule } from '@valhalla/localization';

import { QuickPanelComponent } from './quick-panel.component';

@NgModule({
	declarations: [QuickPanelComponent],
	imports: [
		CommonModule,
		MatDividerModule,
		MatListModule,
		MatSlideToggleModule,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
		FormsModule,
		LocalizationModule,
	],
	exports: [QuickPanelComponent],
})
export class QuickPanelModule {}
