import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { LocalizationModule } from '../../../localization';

import { AvatarModule } from '../chat-nav-avatar';
import { UserMiniProfileComponent } from './user-mini-profile.component';
import { TippyModule } from '@ngneat/helipopper';
import { GroupMembersDialogModule } from './group-members-dialog/group-members-dialog.module';

@NgModule({
	imports: [
		CommonModule,
		AvatarModule,
		MatIconModule,
		RouterModule,
		LocalizationModule,
		TippyModule,
		GroupMembersDialogModule,
	],
	declarations: [UserMiniProfileComponent],
	exports: [UserMiniProfileComponent],
})
export class UserMiniProfileModule {}
