import { CdkPortal } from '@angular/cdk/portal';
import { Component, ElementRef, Input, isDevMode, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ViewDestroyStreamService } from '@spa/core';
import { InputSubject } from '@valhalla/utils';
import { BehaviorSubject, combineLatest, distinctUntilChanged, Observable, takeUntil } from 'rxjs';
import { PortalHostService } from './portal-host.service';
import { IPortalTarget, PortalTargetService } from './portal-target.service';

@Component({
	selector: 'vh-portal-target',
	template: `
		<ng-container *cdkPortal>
			<ng-content></ng-content>
		</ng-container>
		<ng-container *ngIf="showSelfOutlet$ | async">
			<ng-template [cdkPortalOutlet]="portalOutletNotFound$ | async"></ng-template>
		</ng-container>
	`,
	providers: [ViewDestroyStreamService],
})
export class PortalTargetComponent implements IPortalTarget, OnInit, OnDestroy {
	constructor(
		protected targets: PortalTargetService,
		protected readonly destroy$: ViewDestroyStreamService,
		protected readonly hosts: PortalHostService,
		readonly elRef: ElementRef<HTMLElement>
	) {}

	@Input()
	showAsContentIfNotFoundHost = true;

	@ViewChild(CdkPortal, { static: true })
	portalTargetRef: CdkPortal;

	@Input()
	@InputSubject()
	name: string | string[];
	name$: Observable<string | string[]>;

	// eslint-disable-next-line @typescript-eslint/member-ordering
	@Input()
	@InputSubject()
	key: any;
	key$: Observable<any>;

	readonly portalOutletNotFound$ = new BehaviorSubject(null);
	readonly showSelfOutlet$ = new BehaviorSubject(false);

	get isAttached() {
		return !!this.portalTargetRef?.isAttached;
	}

	ngOnInit() {
		combineLatest([this.name$.pipe(distinctUntilChanged()), this.key$.pipe(distinctUntilChanged())])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([name, key]) => {
				const hosts = this.hosts.hosts(name, key);
				this.showSelfOutlet$.next(!hosts.length && this.showAsContentIfNotFoundHost);
				this.portalOutletNotFound$.next(this.portalTargetRef);
				if (!hosts.length && isDevMode()) {
					// eslint-disable-next-line no-restricted-syntax
					console.debug(`portal hosts with name ${name} not found`);
				}
				this.targets.notifyUpdate();
			});
		this.targets.reg(this);
	}

	ngOnDestroy(): void {
		this.targets.unreg(this);
	}
}
