<vh-chat-nav-avatar
	*ngIf="!isCustomAvatar"
	[sideLengthPx]="sideLengthPx"
	[biggerAvatarUserOrId]="biggerAvatarUserOrId"
	[coloredText]="coloredText"
	[users]="avatarUsers"
	[includeMeIfMultiple]="includeMeIfMultiple$ | async"
	[isTaskPlaceholderMode]="(isTaskPlaceholderMode$ | async) || taskAvatarDeleted"
	[isChatPlaceholderMode]="
		((isChatPlaceholderMode$ | async) && (isPrivateChat$ | async) === false) || chatAvatarDeleted
	"
></vh-chat-nav-avatar>

<ng-container *ngIf="isCustomAvatar">
	<img *ngIf="taskAvatarDataUrl" [src]="taskAvatarDataUrlSanitized" />

	<img *ngIf="!taskAvatarDataUrl" [src]="task?.key || task?.id || task?.taskId || task?.taskID | vhTaskAvatar" />
</ng-container>
