import { ModuleWithProviders, NgModule } from '@angular/core';

import { EventBusService } from './abstract';
import { EventBusServiceImpl } from './event-bus.service';

@NgModule()
export class EventBusModule {
	static forRoot(): ModuleWithProviders<EventBusModule> {
		return {
			ngModule: EventBusModule,
			providers: [
				{
					provide: EventBusService,
					useClass: EventBusServiceImpl,
				},
			],
		};
	}
}
