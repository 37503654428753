import { NgModule, ModuleWithProviders } from '@angular/core';
import { StackTraceService } from './stack-trace.service';

@NgModule()
export class StackTraceModule {
	static forRoot(): ModuleWithProviders<StackTraceModule> {
		return {
			ngModule: StackTraceModule,
			providers: [StackTraceService],
		};
	}
}
