import { IUser, IUserShort } from './user';
import { getMime, IAttachment, isImage } from './attachment';
import { INormalizable } from './common';
import { isString } from '@valhalla/utils';

export interface IComment {
	id: number;
	commentId?: number;
	commentID?: number;
	guid: string;
	/** format: 2019-05-24T18:47:51 or /Date(1558090894543)/ */
	date: string;
	timestamp?: number;
	typeId: CommentType;
	taskID?: number;
	taskId?: number;
	typeID?: number;
	userID?: number;
	text: string;
	/** text and content in other context returns - it is comment text */
	content: string;
	needsAnswer: boolean;
	notMeAnswerAvailable?: boolean;
	readStatus: CommentReadStatus;
	sender: Partial<IUser>;
	replyComment: Partial<IReplyComment>;
	forwardedComment: Partial<IForwardedComment>;
	isSentToAll: boolean;
	isAnswered: boolean;
	isUnread: boolean;
	recipients: Partial<ICommentRecipient>[];
	recipientGroups: Partial<ICommentRecipientGroup>[];
	copyRecipients: Partial<ICommentRecipient>[];
	copyRecipientGroups: Partial<ICommentRecipientGroup>[];
	userName: string;
	userId: number;
	files: Partial<IAttachment>[];
	location: any;
	inReplyToCommentId: number;
	inReplyToCommentText: string;
	inReplyToComment?: Partial<IReplyComment>;
	canBeRemoved?: boolean;
	isFavorite?: boolean;
	eventId?: number;
	isEdited?: boolean;
	reactions?: ICommentReaction[];
}

// новая логика пересланных сообщений
export function getIsNewForwardedComment(comment: Partial<IComment>): boolean {
	return (comment.forwardedComment && comment.forwardedComment.id === comment.id) || comment.eventId === 174;
}

export interface ICommentReaction {
	count: number;
	emoji: string;
	id: number;
	usersReacted: Partial<IUser>[];
}

export function adjustComment(comment: Partial<IComment>) {
	comment.id = comment.commentId || comment.id || comment.commentID;
	comment.commentId = comment.id;
	comment.commentID = comment.id;
	comment.taskId = comment.taskId || comment.taskID;
	comment.typeId = comment.typeId || comment.typeID;
	comment.userId = comment.userId || comment.userID;
	comment.content = comment.content || comment.text;
	return comment;
}

export function getCommentType(comment: Partial<IComment>): number {
	return comment?.typeID || comment?.typeId;
}

export function getCommentId(comment: Partial<IComment>): number {
	if (!comment) {
		return;
	}
	return comment.commentId || comment.id || comment.commentID;
}

export function getCommentText(comment: Partial<IComment> | string, removeExpander = false): string {
	if (!comment) {
		return '';
	}
	let text = typeof comment === 'string' ? comment : comment.text || comment.content;
	if (!text) {
		if (!isString(comment) && comment.files?.length > 0) {
			return comment.files.length === 1
				? isImage(getMime(comment.files[0]))
					? '📸 Вложено фото'
					: '📎 Вложен файл'
				: '📁 Вложены файлы';
		}
		return '';
	}
	if (removeExpander) {
		const token1 = 'New value: [+]';
		const token2 = 'Новое значение: [+]';
		const sepIdx = [text.indexOf(token1), text.indexOf(token2)].filter(r => r !== -1)[0];
		if (sepIdx >= 0) {
			text = text.substring(0, sepIdx);
		}
	}
	return text;
}

/**Статус прочтения комментария */
export enum CommentReadStatus {
	/** Никто не прочитал комментарий */
	none = 'none',
	/** Хотя бы один пользователь прочитал комментарий */
	some = 'some',
	/** Прочитано всеми*/
	all = 'all',
}

export enum CommentType {
	step = 1,

	sign = 2,

	user = 3,

	service = 5,

	subtasks = 6,

	extprms = 7,

	taskcreated = 8,

	tasktextaltered = 9,

	taskfileadded = 10,

	tasksubcatchanged = 11,

	taskduedatechanged = 12,

	taskperformeradded = 13,

	video = 14,

	subscribers = 15,

	overdue = 16,

	audio = 17,

	ownerchanged = 18,

	taskfileother = 19,

	workamount = 20,

	calendareventchange = 21,

	sharepointintegration = 22,
}

export interface ICommentRecipientGroup {
	id: number;
	name: string;
}

export interface IReplyComment extends INormalizable {
	copyRecipients: Partial<ICommentRecipient>[];
	date: string;
	files: Partial<IAttachment>[];
	forwardedComment: Partial<IForwardedComment>;
	guid: string;
	id: number;
	isAnswered: boolean;
	isSentToAll: boolean;
	isUnread: boolean;
	needsAnswer: boolean;
	readStatus: string;
	recipients: Partial<ICommentRecipient>[];
	replyComment: Partial<IReplyComment>;
	sender: Partial<IUser>;
	text: string;
	typeId: CommentType;
}

export interface ICommentRecipient extends IUserShort {
	hasToAnswer: boolean;
	isUnread: boolean;
	readDate: string;
}

// tslint:disable-next-line:no-empty-interface
export interface IForwardedComment extends IReplyComment {}
