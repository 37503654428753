import { Injectable, Injector } from '@angular/core';
import { ICategoryShort, ISubCategories, ISubCategoryShort } from '@spa/data/entities';
import { INtfTaskTemplate } from '@spa/data/http';
import { EMPTY, first, Observable, share, switchMap, take } from 'rxjs';
import { resolveServer } from '../server-resolver';
import { createValueStore, ValueStore } from '../value-store';

@Injectable({ providedIn: 'root' })
export class CategoryApplicationService {
	constructor(protected readonly injector: Injector) {}

	protected subcats = new Map<number, ValueStore>();
	protected shortSubcats = new Map<number, ValueStore>();
	protected cats = new Map<number, ValueStore>();
	protected newTaskTemplates = new Map<string, ValueStore>();
	protected server$ = resolveServer(this.injector).pipe(share());

	subcatConfig(subcatId: number) {
		const store = this.subcatStore(subcatId);
		return store.value$ as Observable<ISubCategories>;
	}

	selectSubcatConfig(subcatId: number) {
		return this.subcatConfig(subcatId).pipe(take(1));
	}

	getSubcatConfig(subcatId: number) {
		const store = this.subcatStore(subcatId);
		return store.value as ISubCategories;
	}

	catConfig(catId: number) {
		const store = this.catStore(catId);
		return store.value$ as Observable<ICategoryShort>;
	}

	selectCatConfig(catId: number) {
		return this.catConfig(catId).pipe(take(1));
	}

	getCatConfig(catId: number) {
		const store = this.catStore(catId);
		return store.value as ICategoryShort;
	}

	shortSubcatConfig(subcatId: number) {
		const store = this.shortSubcatStore(subcatId);
		return store.value$ as Observable<ISubCategoryShort>;
	}

	selectShortSubcatConfig(subcatId: number) {
		return this.shortSubcatConfig(subcatId).pipe(take(1));
	}

	getShortSubcatConfig(subcatId: number) {
		const store = this.subcatStore(subcatId);
		return store.value as ISubCategoryShort;
	}

	subcatStore(subcatId: number) {
		if (!subcatId) {
			return;
		}
		if (!this.subcats.has(subcatId)) {
			const updater = () => this.server$.pipe(switchMap(server => server.category.storageSubCategory(subcatId)));
			const subcatStore = createValueStore<ISubCategories>({
				initialValue: null,
				updater,
				truthyValue: true,
			});
			this.subcats.set(subcatId, subcatStore);
			return subcatStore;
		}
		return this.subcats.get(subcatId);
	}

	shortSubcatStore(subcatId: number) {
		if (!subcatId) {
			return;
		}
		if (!this.shortSubcats.has(subcatId)) {
			const updater = () =>
				this.server$.pipe(switchMap(server => server.task.getSubCategoryShortInfo({ subCategoryId: subcatId })));
			const subcatStore = createValueStore<ISubCategoryShort>({
				initialValue: null,
				updater,
				truthyValue: true,
			});
			this.shortSubcats.set(subcatId, subcatStore);
			return subcatStore;
		}
		return this.shortSubcats.get(subcatId);
	}

	catStore(catId: number) {
		if (!catId) {
			return;
		}
		if (!this.cats.has(catId)) {
			const updater = () => this.server$.pipe(switchMap(server => server.category.categoryShortInfo(catId)));
			const catStore = createValueStore<ICategoryShort>({
				initialValue: null,
				updater,
				truthyValue: true,
			});
			this.cats.set(catId, catStore);
			return catStore;
		}
		return this.cats.get(catId);
	}

	selectNewTaskTemplate(options: { subcatId: number; sourceTaskId?: number; action?: string }) {
		return this.newTaskTemplate(options).pipe(first());
	}

	newTaskTemplate(options: { subcatId: number; sourceTaskId?: number; action?: string }) {
		if (!options.subcatId) {
			return EMPTY;
		}
		const store = this.newTaskTemplateStore(options.subcatId, options.sourceTaskId, options.action);
		return store.value$ as Observable<INtfTaskTemplate>;
	}

	newTaskTemplateStore(subcatId: number, sourceTaskId?: number, action?: string) {
		if (!subcatId) {
			throw new Error('subcatId required for newTaskTemplateStore');
		}
		let key = String(subcatId);
		if (sourceTaskId > 0) {
			key += `:${sourceTaskId}`;
		}
		if (action) {
			key += `:${action}`;
		}
		if (!this.newTaskTemplates.has(key)) {
			const updater = () =>
				this.server$.pipe(switchMap(server => server.category.newTaskTemplate(subcatId, sourceTaskId, action)));
			const newTaskTemplateStore = createValueStore<INtfTaskTemplate>({
				initialValue: null,
				updater,
				truthyValue: true,
				invalidate: 5_000,
			});
			this.newTaskTemplates.set(key, newTaskTemplateStore);
			return newTaskTemplateStore;
		}
		return this.newTaskTemplates.get(key);
	}

	getExtParamInSubcat(subcatId: number, epId: number) {
		if (!subcatId) {
			return;
		}
		return this.getSubcatConfig(subcatId)?.extParams?.find(i => i.extParamId === epId);
	}
}
