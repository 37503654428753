import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import ApiVersion from '../api-versions';
import { DataHttpCommonService } from '../data-http-common.service';
import type { IEndpointUrlConfig } from '../endpoint.config';
import { EndpointUrlConfig } from '../endpoint.config';
import { FilterHttpService, IFilterParamDropdownValues, IGetChildParamDropdownValuesParams } from './abstract';

@Injectable()
export class FiltersDataHttpServiceImpl implements FilterHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService
	) {}

	getChildParamDropdownValues(
		params: IGetChildParamDropdownValuesParams
	): Observable<Array<IFilterParamDropdownValues>> {
		const url = this.common.getApiUrl(`filters/config/dropdown/items`, ApiVersion.v10);
		const otherValuesJson = {};
		if (params.parentId) {
			otherValuesJson[params.parentId] = {
				name: params.parentName,
				type: params.parentType,
				value: params.parentValue,
			};
		}
		const body = {
			smartId: params?.childSmartId,
			valueProperty: params.valueProperty || 'ChildID',
			titleProperty: params.titleProperty || 'ChildName',
			otherValuesJson: JSON.stringify(otherValuesJson),
			contextId: params.contextId,
			take: typeof params.take === 'number' ? params.take : 50,
			skip: typeof params.skip === 'number' ? params.skip : 0,
			filter: params.filter || '',
			selectedValues: params.selectedValues || null,
		};
		return this.http.post<any>(url, body);
	}
}
