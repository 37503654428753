import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	Block$Type,
	BlockButtonsType,
	INavigationItemBadge,
	INavigationMenuItem,
	MenuItemActionType,
	MenuItemType,
} from '@spa/common/components/navigation';
import { MatThemePaletteEnum } from '@spa/common/material';
import { HtmlToPlainText } from '@spa/common/pipes';
import { IFavoriteBlock } from '@spa/facade/features/navigation';
import { UrlProvider } from '@valhalla/core';
import { ApiVersion, DataHttpService, IGetFavoriteCountersDto } from '@valhalla/data/http';
import { arrayToObject, buildTrees, Id, ITree } from '@valhalla/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
	ActionType,
	CategoryTreeNodeType,
	IBlock,
	ICategoryDto,
	IDataSourceDto,
	IFavoritesMenu,
	IMobileContainer,
	IMobileContainerResultDto,
	ITaskCounters,
	SubcatOpenType,
} from './data-provider.dto';

export abstract class NavigationDataProvider {
	abstract getNavigationFavouritesMenu(): Observable<{
		menuView: ITree[];
		records: Record<Id, INavigationMenuItem>;
		buttons?: IFavoriteBlock[];
	}>;
	abstract getNavigationCategoriesMenu(): Observable<{
		menuView: ITree[];
		records: Record<Id, INavigationMenuItem>;
		allTasksUserOwns?: number;
		allTasksUserPerforms?: number;
	}>;

	abstract getFavoritesMenuNew(): Observable<IFavoritesMenu>;
	abstract getAllParents(
		item: INavigationMenuItem,
		entities: { [key: string]: INavigationMenuItem },
		that?: NavigationDataProvider
	): INavigationMenuItem[];
	abstract getActiveMenuItemByCurrentUrl(items: INavigationMenuItem[]): INavigationMenuItem;
	abstract isLeaf(item: INavigationMenuItem): boolean;
	abstract isGroup(item: INavigationMenuItem): boolean;
	abstract getFavoriteCounters(): Observable<IGetFavoriteCountersDto[]>;
}
// todo: tests
@Injectable()
export class NavigationDataProviderService implements NavigationDataProvider {
	constructor(
		private readonly _http: HttpClient,
		private readonly _urlBuilder: UrlProvider,
		private readonly _htmlToPlainText: HtmlToPlainText,
		readonly httpData: DataHttpService
	) {}

	protected allTasksUserPerforms$ = new BehaviorSubject(0);
	protected allTasksUserOwns$ = new BehaviorSubject(0);

	readonly aggregates: {
		readonly allTasksUserPerforms$: Observable<number>;
		readonly allTasksUserOwns$: Observable<number>;
	} = {
		allTasksUserOwns$: this.allTasksUserOwns$.asObservable(),
		allTasksUserPerforms$: this.allTasksUserPerforms$.asObservable(),
	};

	getFavoriteCounters() {
		return this.httpData.favorites.getCounters();
	}

	getNavigationFavouritesMenu() {
		const url = this._urlBuilder.getApiUrl('mobile/containers', ApiVersion.v12);
		const data = ['FavouritesMenu'];
		return this._http.post<IMobileContainerResultDto>(url, data).pipe(
			map(dto => dto.data.find(i => i.id === 'FavouritesMenu')),
			map(dto => {
				const menuItems = this.dataMapperNavigationFavouritesMenuDtoToMenuItems(dto);
				const records = arrayToObject(menuItems, item => item.id);
				const menuView = this.buildMenuViewFromFavorites(dto.blocks[0]);

				const fav = menuView.find(m => m.id === 'Favourites');
				const menuItemsWithChildren = (fav && fav.children) || [];
				const menuKeys = Object.getOwnPropertyNames(menuItems);
				for (const menuKey of menuKeys) {
					if (menuItems[menuKey].parentId === 'Favourites') {
						const currentMenuItem = menuItems[menuKey];
						const recordsArray = Object.keys(records).map(i => {
							return records[i];
						});
						const currentMenuChildren = menuItemsWithChildren.find(с => с.id === currentMenuItem.id);
						if (currentMenuChildren) {
							currentMenuItem.subMenu = recordsArray.filter(r => r.parentId === menuItems[menuKey].id);
						}
					}
				}

				return {
					menuView: menuView,
					records: records,
					buttons: dto.buttons || [],
				};
			})
		);
	}

	getBlockId(block: IBlock) {
		return block.data && block.data.openType ? `${block.id}/${block.data.openType}` : block.id;
	}

	buildMenuViewFromFavorites(rootBlock: IBlock): ITree[] {
		const buildChildren = (blocks: IBlock[], parentId: string) => {
			return this.filterBlocks(blocks).map(block => {
				return {
					id: this.getBlockId(block),
					parentId: parentId,
					subMenu: block.blocks,
					children: buildChildren(block.blocks || [], this.getBlockId(block)),
				};
			});
		};
		const tree: ITree = {
			id: this.getBlockId(rootBlock),
			parentId: null,
			children: buildChildren(rootBlock.blocks, this.getBlockId(rootBlock)),
		};
		return [tree];
	}

	addBlockTypeFolder(menuItem, acc, block) {
		menuItem.title = block.data && block.data.title;
		menuItem.menuType = MenuItemType.collapsible;
		menuItem.actionType = MenuItemActionType.execFunc;
		const itemId = `${block.id}-all`;
		if (!this.hasCommentBlock(block)) {
			acc.push({
				id: itemId,
				actionType: MenuItemActionType.openUrl,
				title: 'Все',
				menuType: MenuItemType.item,
				url: this._urlBuilder.getUrl(`/link`),
				queryParams: {
					url: `/Syndicate.aspx?forceURL=/NewCustomGrid.aspx?FavsFolderId=${block.id}`,
				},
				icon: 'list',
				order: -1,
				parentId: block.id,
			});
		}
	}

	addBlockTypeReport(menuItem, acc, block: IBlock) {
		menuItem.title = block.data && block.data.title;
		menuItem.menuType = MenuItemType.item;
		if (block.data && block.data.url && block.data.url.indexOf('OpenTask') > -1) {
			menuItem.url = this._urlBuilder.getUrl(`/tasks/${this.clearContainerUrl(block.data.url)}`);
		} else if (
			block.data &&
			block.data.url &&
			!this.isAbsoluteUrl(block.data.url) &&
			block.data.url.indexOf('NewCustomGrid.aspx') > -1
		) {
			menuItem.url = this._urlBuilder.getUrl(`/link`);
			const urlParam = block.data.url.includes('yndicate.aspx')
				? this.clearContainerUrl(block.data.url)
				: `/Syndicate.aspx?forceURL=${this.clearContainerUrl(block.data.url)}`;

			menuItem.queryParams = {
				url: urlParam,
			};
		} else {
			const url = this.clearContainerUrl(block.data.url);
			menuItem.url = this._urlBuilder.getUrl(`/link`);
			menuItem.queryParams = { url };
			if (this.isAbsoluteUrl(menuItem.queryParams.url)) {
				menuItem.actionType = MenuItemActionType.openExternalUrl;
				menuItem.openInNewTab = true;
				menuItem.url = url;
			}
		}
		if (block.data && block.data.url && block.data.url.indexOf('portal/new') > -1) {
			const prepare = this._urlBuilder.joinWithSlash('/', block.data.url.replace('@spa/', '').replace('spa/', ''));
			menuItem.url = this._urlBuilder.getUrl(prepare);
			menuItem.queryParams = null;
		}
		if (block.data && block.data.type) {
			menuItem.url = this._urlBuilder.getUrl(`/link`);
			let catParameter = `${block.data.type}Id`;
			if (block.data.type.includes('Subcat')) {
				catParameter = `subcatId`;
			}
			switch (block.data.openType) {
				case SubcatOpenType.gantt:
					menuItem.queryParams = {
						url: `/Syndicate.aspx?forceURL=/Gantt.aspx?${catParameter}=${block.data.id}`,
					};
					break;
				case SubcatOpenType.feed:
					menuItem.queryParams = {
						url: `/Syndicate.aspx?forceURL=/1FMain.aspx?${catParameter}=${block.data.id}`,
					};
					break;
				case SubcatOpenType.calendar:
					menuItem.queryParams = {
						url: `/Syndicate.aspx?forceURL=/Scheduler.aspx?${catParameter}=${block.data.id}`,
					};
					break;
				case SubcatOpenType.correspondence:
					menuItem.queryParams = {
						url: `/Syndicate.aspx?forceURL=/CommentsGrid.aspx?${catParameter}=${block.data.id}`,
					};
					break;
				case SubcatOpenType.summary:
					menuItem.queryParams = {
						url: `/Syndicate.aspx?forceURL=/TaskSubForm/SubcatInfo.aspx?${catParameter}=${block.data.id}`,
					};
					break;
				case SubcatOpenType.filestorage:
					menuItem.queryParams = {
						url: `/filestorage/default.aspx?${catParameter}=${block.data.id}`,
					};
					break;
				case SubcatOpenType.agenda:
					menuItem.queryParams = {
						url: `/spaex.aspx/agenda?hideFirstDayHeader=1&today=true`,
					};
					break;
				default:
					menuItem.queryParams = {
						url: `/Syndicate.aspx?forceURL=/NewCustomGrid.aspx?${catParameter}=${block.data.id}`,
					};
					break;
			}
		}
	}

	addBlockTypeTask(menuItem, acc, block) {
		menuItem.title = this._htmlToPlainText.transform(block.data && block.data.title);
		menuItem.menuType = MenuItemType.item;
		menuItem.url = this._urlBuilder.getUrl(`/tasks/${block.data.id}`);
	}

	addBlockTypeComment(menuItem, acc, block) {
		menuItem.title = this._htmlToPlainText.transform(block.data && block.data.title);
		menuItem.menuType = MenuItemType.item;
		menuItem.url = this._urlBuilder.getUrl(`/tasks/${block.data.taskId}`);
	}

	dataMapperNavigationFavouritesMenuDtoToMenuItems(container: IMobileContainer): INavigationMenuItem[] {
		const entities: INavigationMenuItem[] = [];
		// const favoriteMenuId = 'favorites-menu';
		const mainBlock = container.blocks[0];
		const favMenu: INavigationMenuItem = {
			// id: favoriteMenuId,
			id: mainBlock.id,
			title: ((mainBlock.template && mainBlock.template.title) || 'Избранное').toUpperCase(),
			menuType: MenuItemType.group,
			icon: 'apps',
			parentId: null,
			actionType: MenuItemActionType.execFunc,
		};
		entities.push(favMenu);

		const fillEntitiesFromBlock = (acc: INavigationMenuItem[], block: IBlock, parentId: number | string = null) => {
			const menuItem: INavigationMenuItem = {
				id: this.getBlockId(block),
				title: block.data?.title,
				menuType: MenuItemType.item,
				parentId: parentId,
				actionType: MenuItemActionType.openUrl,
				badges: this.getBadgesFromBlock(block),
				subMenu: [],
				canCreate: block.data.canCreate,
				type: block.type,
				isGroupFavourite: block.isGroupFavourite,
				customImageClass: block.data?.customImageClass,
				tooltip: block.data?.tooltip,
			};
			acc.push(menuItem);
			switch (block.type) {
				case Block$Type.syndicate:
				case Block$Type.link:
				case Block$Type.report:
					this.addBlockTypeReport(menuItem, acc, block);
					break;
				case Block$Type.task:
					this.addBlockTypeTask(menuItem, acc, block);
					break;
				case Block$Type.comment:
					this.addBlockTypeComment(menuItem, acc, block);
					break;
				default:
					break;
			}
			switch (block.action) {
				case ActionType.link:
					menuItem.icon = 'link';
					break;
				case ActionType.report:
					menuItem.icon = 'assessment';
					break;
				case ActionType.task:
					menuItem.icon = 'work';
					break;
				case ActionType.syndicate:
					menuItem.icon = 'grid_on';
					break;
				case ActionType.comment:
					menuItem.icon = 'comment';
					break;
				default:
					menuItem.icon = 'folder_special';
					break;
			}
			this.filterBlocks(block.blocks).forEach(bl => fillEntitiesFromBlock(acc, bl, block.id));
		};

		this.filterBlocks(mainBlock.blocks).forEach(b =>
			fillEntitiesFromBlock(entities, b, mainBlock.id /*favoriteMenuId*/)
		);

		return entities;
	}

	getAllParents(
		item: INavigationMenuItem,
		entities: { [key: string]: INavigationMenuItem },
		that: NavigationDataProviderService = this
	): INavigationMenuItem[] {
		if (!item || !item.parentId) return [];

		const parent: INavigationMenuItem = entities[item.parentId];
		if (!parent) {
			return [];
		}
		return [parent, ...that.getAllParents(parent, entities)];
	}

	getActiveMenuItemByCurrentUrl(items: INavigationMenuItem[]) {
		const currentUrl = this._urlBuilder.decodeUriComponent(this._urlBuilder.currentUrlExceptBaseHref());
		const urlItems = items.filter(i => i.actionType === MenuItemActionType.openUrl);
		let activeMenuItem: INavigationMenuItem;
		for (let i = 0; i < urlItems.length; i++) {
			const itemUrl = this.getUrlMenuItem(urlItems[i]);
			if (currentUrl === itemUrl) {
				activeMenuItem = urlItems[i];
				break;
			}
		}
		return activeMenuItem;
	}

	isLeaf(item: INavigationMenuItem) {
		return item.menuType === MenuItemType.item;
	}

	isGroup(item: INavigationMenuItem) {
		return item.menuType !== MenuItemType.item;
	}

	getUrlMenuItem(menuItem: INavigationMenuItem): string {
		if (!menuItem) {
			return null;
		}
		const search = this._urlBuilder.buildUrlSearchFromObject(menuItem.queryParams);
		const decodeSearch = this._urlBuilder.decodeUriComponent(search);
		return decodeSearch ? `${menuItem.url}?${decodeSearch}` : menuItem.url;
	}

	clearContainerUrl(url: string): string {
		const replaceToEmpty = [/javascript:void\(OpenTask\(null,/g, /\(/g, /\)/g, /encodeURIComponent/g, /'/g, / /g];
		return replaceToEmpty.reduce((acc: string, cur: RegExp) => {
			return acc && acc.replace(cur, '');
		}, url);
	}

	isSeparatorBlock(block: IBlock) {
		return block.id === 'separator';
	}

	filterBlocks(blocks: IBlock[]) {
		return (blocks || []).filter(block => !this.isSeparatorBlock(block));
	}

	isAbsoluteUrl(url: string) {
		return (url || '').indexOf('https://') === 0 || (url || '').indexOf('http://') === 0;
	}

	hasCommentBlock(block: IBlock) {
		if (!block) {
			return false;
		}
		return block.blocks.some(childBlock => childBlock.action === ActionType.comment);
	}

	getBadgesFromBlock(block: IBlock): Array<Partial<INavigationItemBadge>> {
		if (!(block && block.data && block.data.tasksCounters)) {
			return null;
		}
		const counters = block.data.tasksCounters;
		return this.buildBadges(counters);
	}

	buildBadges(counters: Partial<ITaskCounters>): Array<Partial<INavigationItemBadge>> {
		// prettier-ignore
		return !counters
			? null
			: [{
				value: counters.newTasksCount,
				order: 0,
				title: 'Новые',
				matBg: MatThemePaletteEnum.accent,
				counterName: 'newTasksCount'
			},
			{
				value: counters.overdueTasksCount,
				order: 1,
				title: 'Просроченые',
				matBg: MatThemePaletteEnum.warn,
				counterName: 'overdueTasksCount'
			},
			{
				value: counters.allTasksCount,
				order: 3,
				title: 'Всего',
				matBg: MatThemePaletteEnum.primary,
				counterName: 'allTasksCount'
			}
		];
	}

	getFavoritesMenuNew(): Observable<IFavoritesMenu> {
		const url = this._urlBuilder.getApiUrl(`/favorite/menu`);
		return this._http.post<IDataSourceDto<IFavoritesMenu>>(url, null).pipe(
			map(result => {
				return result.data;
			})
		);
	}

	getNavigationCategoriesMenu(): Observable<{
		menuView: ITree[];
		records: Record<Id, INavigationMenuItem>;
		allTasksUserOwns: number;
		allTasksUserPerforms: number;
	}> {
		const url = this._urlBuilder.getApiUrl(`subcategories/tree`, ApiVersion.v10);
		return this._http.get<IDataSourceDto<Array<ICategoryDto>>>(url).pipe(
			map(({ data }) => {
				const { allTasksUserOwns, allTasksUserPerforms } = data.reduce(
					(acc, cur) => {
						if (cur.nodeType === CategoryTreeNodeType.subcategory) {
							acc.allTasksUserOwns += cur.tasksCounters?.allTasksUserOwns || 0;
							acc.allTasksUserPerforms += cur.tasksCounters?.allTasksUserPerforms || 0;
						}
						return acc;
					},
					{
						allTasksUserPerforms: 0,
						allTasksUserOwns: 0,
					}
				);
				this.allTasksUserOwns$.next(allTasksUserOwns);
				this.allTasksUserPerforms$.next(allTasksUserPerforms);
				return { data, allTasksUserOwns, allTasksUserPerforms };
			}),
			map(({ data, allTasksUserOwns, allTasksUserPerforms }) => {
				const rootId: any = 'root';
				data.forEach(item => {
					if (!item.parentId) {
						item.parentId = rootId;
					}
				});
				data.push({
					id: rootId,
					name: 'Категории',
					nodeType: CategoryTreeNodeType.root,
					parentId: null,
					tasksCounters: null,
				});
				const parentIdGetter = (i: ICategoryDto) =>
					i.parentId === rootId || !i.parentId ? i.parentId : `Category${i.parentId}`;
				const idGetter = (i: ICategoryDto) => (i.id === rootId || !i.id ? i.id : `${i.nodeType}${i.id}`);
				const result = {
					allTasksUserOwns,
					allTasksUserPerforms,
					menuView: buildTrees(data, idGetter, parentIdGetter),
					records: arrayToObject<ICategoryDto, INavigationMenuItem>(
						data,
						item => idGetter(item),
						item => {
							const menuItem = {
								id: idGetter(item),
								$id: item.id,
								isHeaderHidden: item.nodeType === CategoryTreeNodeType.root,
								parentId: parentIdGetter(item),
								title: item.name,
								badges: item.tasksCounters ? this.buildBadges(item.tasksCounters) : null,
								subMenu: [],
								menuType:
									item.nodeType === CategoryTreeNodeType.root
										? MenuItemType.group
										: item.nodeType === CategoryTreeNodeType.category
										? MenuItemType.collapsible
										: MenuItemType.item,
								actionType:
									item.nodeType === CategoryTreeNodeType.subcategory
										? MenuItemActionType.openUrl
										: MenuItemActionType.execFunc,
								icon: this.getNavIcon(item),
								url: this._urlBuilder.getUrl(`/link`),
								queryParams: {
									url: `/Syndicate.aspx?forceURL=/NewCustomGrid.aspx?SubcatId=${item.id}`,
								},
								canEdit: item.canEdit,
								canCreateTask: item.canCreateTask,
								isDictionary: item.isDictionary,
								openType: item.openType,
								availableRepresentations: item.availableRepresentations,
							};

							const { url: itemUrl, queryParams } = this.createRouterUrlParts(item);

							if (itemUrl) {
								menuItem.url = itemUrl;
								menuItem.queryParams = queryParams as any;
							}

							return menuItem;
						}
					),
				};
				return result;
			})
		);
	}

	createRouterUrlParts(item: ICategoryDto) {
		let url: string, queryParams: Record<string, string>;
		const isSubcat = item.nodeType === 'Subcategory';
		const openType = String(item.openType).toLowerCase();
		const toLowerCase = data => String(data).toLowerCase();

		switch (openType) {
			case toLowerCase(BlockButtonsType.feed):
				url = `${isSubcat ? '/feeds/subcat' : '/feeds/category'}/${item.id}`;
				queryParams = null;
				break;

			case toLowerCase(BlockButtonsType.grid):
				url = '/link';
				queryParams = { url: `/Syndicate.aspx?forceURL=/NewCustomGrid.aspx?SubcatId=${item.id}` };
				break;

			case toLowerCase(BlockButtonsType.calendar):
				url = '/link';
				queryParams = { url: `/Syndicate.aspx?forceURL=/Scheduler.aspx?SubcatId=${item.id}` };
				break;

			case toLowerCase(BlockButtonsType.file):
			case toLowerCase(BlockButtonsType.fileBrowser):
			case toLowerCase(BlockButtonsType.fileBrowserTableView):
				url = '/link';
				queryParams = {
					url: `/Syndicate.aspx?forceURL=/spaex.aspx/file-storage/subcategory/${item.id}?showlefttree=1&onlySpaStyles=1`,
				};
				break;

			case toLowerCase(BlockButtonsType.filestorage):
				url = '/link';
				queryParams = {
					url: `/Syndicate.aspx?forceURL=/spaex.aspx/file-storage/subcategory/${item.id}?onlySpaStyles=1`,
				};
				break;

			case toLowerCase(BlockButtonsType.agenda):
				url = '/link';
				queryParams = {
					url: `/Syndicate.aspx?forceURL=/spaex.aspx/agenda?hideFirstDayHeader=1&today=true&SubcatId=${item.id}`,
				};
				break;

			case toLowerCase(BlockButtonsType.summary):
				url = '/link';
				queryParams = { url: `/Syndicate.aspx?forceURL=/TaskSubForm/SubcatInfo.aspx?SubcatId=${item.id}` };
				break;

			case toLowerCase(BlockButtonsType.gantt):
				url = '/link';
				queryParams = { url: `/Syndicate.aspx?forceURL=/Gantt.aspx?SubcatId=${item.id}` };
				break;

			case toLowerCase(BlockButtonsType.correspondence):
				url = '/link';
				queryParams = { url: `/Syndicate.aspx?forceURL=/CommentsGrid.aspx?SubcatId=${item.id}` };
				break;

			case toLowerCase(BlockButtonsType.hierarchy):
				url = '/link';
				queryParams = { url: `/Syndicate.aspx?forceURL=/Hierarchy.aspx?SubcatId=${item.id}` };
				break;

			case toLowerCase(BlockButtonsType.layoutPlan):
				url = '/link';
				queryParams = { url: `/Syndicate.aspx?forceURL=/TasksLayoutPlan.aspx?SubcatId=${item.id}` };
				break;

			case toLowerCase(BlockButtonsType.hierarchyDictionary):
				url = '/link';
				queryParams = { url: `/Syndicate.aspx?forceURL=/HierarchyDictionary.aspx?SubcatId=${item.id}` };
				break;

			default:
				break;
		}

		/*
		if (menuItem.openType === BlockButtonsType.subcategory) {
			this.openLink(`/Syndicate.aspx?forceURL=/NewCustomGrid.aspx?${type}=${menuItem.linkedObjectId}`, e);
			return;
		}

		if (menuItem.openType === BlockButtonsType.summary) {
			this.openLink(`/Syndicate.aspx?forceURL=/TaskSubForm/SubcatInfo.aspx?${type}=${menuItem.linkedObjectId}`, e);
			return;
		}


		if (menuItem.openType === BlockButtonsType.gantt) {
			this.openLink(`/Syndicate.aspx?forceURL=/Gantt.aspx?${type}=${menuItem.linkedObjectId}`, e);
			return;
		}

		if (menuItem.openType === BlockButtonsType.correspondence) {
			this.openLink(`/Syndicate.aspx?forceURL=/CommentsGrid.aspx?${type}=${menuItem.linkedObjectId}`, e);
			return;
		}

		*/

		return { url, queryParams };
	}

	getNavIcon(item: ICategoryDto) {
		if (item.nodeType === CategoryTreeNodeType.category) {
			return 'vh-folder-24';
		}

		if (item.isDictionary) {
			return 'vh-info-24';
		}

		return 'vh-feed-24';
	}
}
