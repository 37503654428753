import { ChangeDetectorRef, Component, HostBinding, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { ViewDestroyStreamService } from '@valhalla/core';
import { filter, take } from 'rxjs';
import { MobileViewService } from '@spa/common/services/mobile-view.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'vh-select-category-dialog',
	templateUrl: './select-category-dialog.component.html',
	styleUrls: ['./select-category-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SelectCategoryDialogComponent implements OnInit {
	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public data,
		@Optional() public dialogRef: MatDialogRef<any>,
		readonly destroy$: ViewDestroyStreamService,
		readonly modal: ModalWindowsService,
		readonly mobileService: MobileViewService,
		readonly cdRef: ChangeDetectorRef
	) {}

	@HostBinding('class.vh-select-category-dialog')
	hostClassSelector = true;

	ngOnInit(): void {
		// when opened through a modal window, sometimes the data is not displayed immediately
		this.mobileService.mobileMode$
			.pipe(
				filter(isMobile => !!isMobile),
				takeUntil(this.destroy$)
			)
			.subscribe(() => this.cdRef.detectChanges());
	}

	closeModal() {
		this.dialogRef.close();
	}

	onSelectCategory(e) {
		if (this.data?.needConfirm) {
			return this.modal
				.openConfirm('Вы уверены ?', {}, 'common.areYouSure')
				.afterClosed()
				.pipe(take(1))
				.subscribe(v => {
					if (v) {
						return this.dialogRef.close(e);
					}
					return;
				});
		}

		this.dialogRef.close(e);
	}
}
