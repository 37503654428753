import { NgZone } from '@angular/core';
import { AuthService, Effect, IAction, ofType } from '@valhalla/core';
import { NotifyHubEvents, SignalrProvider } from '@valhalla/data/signalr';
import { merge } from 'rxjs';
import { debounceTime, filter, map, mapTo, skip, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { TabActiveStateService } from '@spa/common/services/tab-active-state.service';
import { rxSetInterval } from '@valhalla/utils';
import * as actions from '../actions';
import { ITickersState } from '../state';
import { TickerEffectDependencies } from './dependency';
import { InternetConnectionService } from '@spa/common/services/internet-connection.service';
import { CronClient } from '@spa/cron';

export const periodicUpdateTickersEffect: Effect<IAction, IAction, ITickersState, TickerEffectDependencies> = (
	actions$,
	state$,
	deps
) => {
	const logger = deps.loggerFactory.createLogger('periodicUpdateTickersEffect');
	const turnOff$ = actions$.pipe(ofType(actions.FacadeTickersAction.turnOffPeriodicTickersUpdate));
	const zone = deps.injector.get(NgZone);
	const auth = deps.injector.get(AuthService);
	const activity = deps.injector.get(TabActiveStateService);
	const internet = deps.injector.get(InternetConnectionService);
	const signal = deps.injector.get(SignalrProvider);
	const signal$ = signal.signal$.pipe(filter(() => activity.isMasterTab));
	const readComments$ = signal$.pipe(filter(e => e.name === NotifyHubEvents.readComments));
	const newComments$ = signal$.pipe(filter(e => e.name === NotifyHubEvents.newComment));
	const questionComment$ = signal$.pipe(filter(e => e.name === NotifyHubEvents.questionComment));
	const refreshMTF$ = signal$.pipe(filter(e => e.name === NotifyHubEvents.refreshMTF));
	const cron = deps.injector.get(CronClient);
	const cronToken = Symbol();
	const intervalUpdate$ = cron.from('tickers').pipe(map(data => ({ cronToken, data })));

	// const updateBySignal$ = signal
	// 	.activate()
	// 	.pipe(switchMap(() => merge(readComments$, newComments$, questionComment$, refreshMTF$).pipe(debounceTime(500))));

	return actions$.pipe(
		ofType(actions.FacadeTickersAction.turnOnPeriodicTickersUpdate),
		switchMap(action => {
			const { updateIntervalMs } = action.payload;
			logger.info('run periodical update tickers');
			// const intervalUpdate$ = updateBySignal$.pipe(
			// 	startWith(0),
			// 	switchMap(() => rxSetInterval(updateIntervalMs).pipe(skip(1)))
			// );
			return zone.runOutsideAngular(() =>
				merge(/*updateBySignal$,*/ intervalUpdate$, internet.goOnline$).pipe(
					// whenTabVisible(document),
					activity.whenUserActive(true, false),
					map((data: any) => {
						if (data?.cronToken === cronToken) {
							return actions.loadTickers(data.data?.lastResult);
						}
						return actions.loadTickers(null);
					}),
					takeUntil(turnOff$)
				)
			);
		}),
		filter(() => auth.authenticated)
	);
};
