import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileSettingsComponent } from '@spa/components/mobile-settings/mobile-settings.component';
import { AvatarModule } from '@spa/common/components/chat-nav-avatar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { LocalizationModule } from '@spa/localization';
import { PortalHelperModule } from '@spa/common/components/portal-helper';

@NgModule({
	declarations: [MobileSettingsComponent],
	exports: [MobileSettingsComponent],
	imports: [CommonModule, AvatarModule, MatIconModule, MatMenuModule, LocalizationModule, PortalHelperModule],
})
export class MobileSettingsModule {}
