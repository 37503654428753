import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'trustHtml',
})
export class TrustHtmlPipe implements PipeTransform {
	constructor(readonly sanitizer: DomSanitizer) {}

	transform(value: string) {
		return this.sanitizer.bypassSecurityTrustHtml(value || '');
	}
}

@NgModule({
	exports: [TrustHtmlPipe],
	declarations: [TrustHtmlPipe],
})
export class SanitizePipesModule {}
