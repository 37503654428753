import { ExtParamBase } from './ext-param-base';
import { Observable } from 'rxjs';

export class ExtParamText extends ExtParamBase {
	get phoneMode() {
		return this.getSourceConfig()?.settings?.context === 'Phone';
	}

	get suggestMode() {
		return Boolean(this.getSourceConfig()?.setting?.suggestType);
	}

	clearValue() {
		this.userValue$.next('');
	}

	search(filter: string, skip?: number, take?: number, params?: any): Observable<any> {
		return this.server.ep.getEpTextSuggestValues(params.suggestType, filter || 'А');
	}

	equalsValue(a: any, b: any): boolean {
		if (typeof a === typeof b) {
			return a === b;
		}
		return !!a === !!b;
	}
}
