import { Injectable, OnDestroy } from '@angular/core';
import { SessionUser } from '@spa/core';
import { DataHttpService } from '@valhalla/data/http';
import { BehaviorSubject, interval, Subject, take, takeUntil } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CallCounterService implements OnDestroy {
	constructor(readonly server: DataHttpService, readonly sessionUser: SessionUser) {}

	readonly destroy$ = new Subject<void>();
	readonly missedCallCount$ = new BehaviorSubject(0);

	init() {
		this.update();

		interval(1 * 60000)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.update();
			});
	}

	update() {
		this.server.sip
			.getMissedCount(this.sessionUser.userId)
			.pipe(take(1))
			.subscribe(c => {
				this.missedCallCount$.next(c);
			});
	}

	setViewedMissedCall() {
		this.server.sip
			.setViewedMissedCall()
			.pipe(take(1))
			.subscribe(() => {
				this.update();
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
