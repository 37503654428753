import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ApplicationsManagerProvider, IAppDefinition } from '@spa/facade/features/applications';
import {
	AbstractLogger,
	ConfigurationProvider,
	LoggerFactory,
	PlatformDetectorProvider,
	UrlProvider,
	ViewDestroyStreamService,
} from '@valhalla/core';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { filter, takeUntil, tap, catchError, map } from 'rxjs/operators';
import { MobileViewService } from '@spa/common/services/mobile-view.service';

@Component({
	selector: 'vh-layout-quick-links-user-dock',
	templateUrl: './quick-links-user-dock.component.html',
	styleUrls: ['./quick-links-user-dock.component.scss'],
	providers: [ViewDestroyStreamService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickLinksUserDockComponent implements OnInit {
	constructor(
		protected platform: Platform,
		protected router: Router,
		protected destroy$: ViewDestroyStreamService,
		protected loggerFactory: LoggerFactory,
		protected config: ConfigurationProvider,
		protected appsManager: ApplicationsManagerProvider,
		protected cdr: ChangeDetectorRef,
		protected zone: NgZone,
		protected platformDetector: PlatformDetectorProvider,
		protected url: UrlProvider,
		protected mobileService: MobileViewService
	) {}

	protected readonly logger: AbstractLogger = this.loggerFactory.createLogger('QuickLinksUserDockComponent');
	userQuickApps$ = this.appsManager.selectUserQuickApps().pipe(
		map(apps => {
			return apps.map(e => {
				e.svgIcon = this.iconsMapper(e.pid, e.icon);
				return e;
			});
		}),
		catchError(err => {
			this.logger.error(err);
			return EMPTY;
		}),
		takeUntil(this.destroy$)
	);
	showAppSearchPage$ = new BehaviorSubject(false);

	isMobile = false;

	readonly svgIcons = [
		{
			pid: 'category',
			icon: '',
			svgIcon: 'vh-Categories-24',
		},
		{
			pid: 'report',
			icon: '',
			svgIcon: 'vh-reports-24',
		},
		{
			pid: 'portal',
			icon: '',
			svgIcon: 'vh-Wiki-v1-24',
		},
		{
			pid: 'task-hierarchy',
			icon: '',
			svgIcon: 'vh-tasks-transit-history-24',
		},
		{
			pid: 'agenda',
			icon: '',
			svgIcon: 'vh-grid-24',
		},
		{
			pid: 'calendar',
			icon: '',
			svgIcon: 'vh-calendar-24',
		},
		{
			pid: 'mail',
			icon: '',
			svgIcon: 'vh-mail-24',
		},
		{
			pid: 'file-storage',
			icon: '',
			svgIcon: 'vh-File-box-24',
		},
		{
			pid: 'help-1f',
			icon: '',
			svgIcon: 'vh-info-24',
		},
		{
			pid: 'my-tasks',
			icon: '',
			svgIcon: 'vh-tasks-24',
		},
		{
			pid: 'org-structure',
			icon: '',
			svgIcon: 'vh-users-collection-24',
		},
		{
			pid: 'link',
			icon: 'home',
			svgIcon: 'vh-1f-logo-outline',
		},
		{
			pid: 'link',
			icon: 'comment',
			svgIcon: 'vh-feed-24',
		},
		{
			pid: 'link',
			icon: 'assignment',
			svgIcon: 'vh-tasks-24',
		},
		{
			pid: 'link',
			icon: 'note_add',
			svgIcon: 'vh-tasks-add-24',
		},
		{
			pid: 'link',
			icon: 'vertical_split',
			svgIcon: 'vh-feed-24',
		},
		{
			pid: 'link',
			icon: 'chat',
			svgIcon: 'vh-chats-24',
		},
		{
			pid: 'link',
			icon: 'important_devices',
			svgIcon: 'vh-Wiki-v1-24',
		},
		{
			pid: 'link',
			icon: 'build',
			svgIcon: 'vh-api-24',
		},
		{
			pid: 'link',
			icon: 'event',
			svgIcon: 'vh-Time-Sources-24',
		},
	];

	ngOnInit() {
		this.isMobile = this.platformDetector.isOneOfPlatform(
			this.platformDetector.types.android,
			this.platformDetector.types.ios
		);
		this.appsManager
			.updateAvailableApplicationList()
			.pipe(
				tap(() => this.cdr.detectChanges()),
				takeUntil(this.destroy$)
			)
			.subscribe();
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationStart || event instanceof NavigationEnd),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.showAppSearchPage$.next(false);
				this.zone.runOutsideAngular(() => {
					setTimeout(() => {
						try {
							this.cdr.detectChanges();
						} catch {}
					});
				});
			});
	}

	locationHistoryBack() {
		this.url.locationBack();
	}

	trackByAppId(idx, app: IAppDefinition) {
		return app.id;
	}

	showAppSearch() {
		this.showAppSearchPage$.next(!this.showAppSearchPage$.getValue());
	}

	deleteQuickApp(app: IAppDefinition) {
		this.appsManager.removeFromUserQuickApps(app.id);
	}

	openApp(app: IAppDefinition) {
		this.appsManager.openApplication(app);
	}

	getAppRouterLink(app: IAppDefinition) {
		return this.appsManager.resolveAppRoute(app);
	}

	iconsMapper(pid: string, icon: string): string {
		if (pid !== 'link') {
			return this.svgIcons.find(e => e.pid === pid).svgIcon;
		}

		return this.svgIcons.find(e => e.icon === icon).svgIcon || 'vh-1f-logo-outline';
	}
}
