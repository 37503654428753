<ng-template #actionPanel>
	<button
		*ngIf="this.canEdit"
		class="vh-file-viewer-dialog--action"
		(click)="save($event)"
		[tippy]="'common.save' | resx"
	>
		<mat-icon svgIcon="vh-floppy-save-v1"></mat-icon>
	</button>
</ng-template>

<ng-template #mobileActionPanel>
	<button *ngIf="this.canEdit" class="vh-file-viewer-dialog--action" mat-menu-item (click)="save($event)">
		<mat-icon svgIcon="vh-floppy-save-v1"></mat-icon>
		<span>{{ 'common.save' | resx }}</span>
	</button>
</ng-template>
<ng-template #settingsPanel>
	<vh-control-dropdown
		class="encoding-dropdown"
		[showClear]="false"
		[itemsGetter]="dropdownEncodingsOptionsGetter"
		[itemTextGetter]="dropdownItemTextGetter"
		[value]="selectedEncoding"
		[dropdownWidth]="encodingsDropdownWidth"
		(selectValue)="changeEncoding($event)"
		(vhResizeWatcher)="onResize($event)"
	></vh-control-dropdown>
</ng-template>
<div *ngIf="openLatests" class="latest-file-info">
	<mat-icon svgIcon="vh-info-24" [class.s-32]="!mobileView.mobileMode"></mat-icon>
	<div class="latest-file-info-wrapper">
		<div class="latest-file-info-label row"><span>Вы смотрите старую версию файла</span></div>
		<div class="row">
			<span class="latest-file-info-action" (click)="showVersion()">{{ 'common.logVersions' | resx }}</span>
			<span class="row latest-file-info-action" (click)="openLatest()">Последняя версия</span>
		</div>
	</div>
</div>

<!-- <ng-container *ngIf="options?.isEmailFile">
	<iframe
		[src]="getFileEmailLink(file) | urlSearch: 'inline':'true' | safeResource"
		width="100%"
		height="100%"
		frameborder="0"
	></iframe>
</ng-container>

<ng-container *ngIf="options?.isMeetingFile">
	<iframe
		[src]="getFileMeetingLink(file) | urlSearch: 'inline':'true' | safeResource"
		width="100%"
		height="100%"
		frameborder="0"
	></iframe>
</ng-container> -->

<ng-container>
	<vh-ui-monaco-editor
		[lang]="language"
		(contentChange)="onCodeChange($event)"
		(ready)="onMonacoReady($event)"
		[readOnly]="true"
	></vh-ui-monaco-editor>
</ng-container>
