import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DataHttpCommonService } from '../data-http-common.service';
import type { IEndpointUrlConfig } from '../endpoint.config';
import { EndpointUrlConfig } from '../endpoint.config';
import {
	BatchProcessingDataHttpService,
	IProcessTaskCommentCriteria,
	IProcessTaskCopyCriteria,
	IProcessTaskDeleteCriteria,
	IProcessTaskDuedateCriteria,
	IProcessTaskExtParams,
	IProcessTaskFilesCriteria,
	IProcessTaskMoveCriteria,
	IProcessTaskOwnerCriteria,
	IProcessTaskPerformersCriteria,
	IProcessTaskResult,
	IProcessTaskSmartButtonCriteria,
	IProcessTaskStepCriteria,
	IProcessTaskSubscribersCriteria,
	ITaskBatchSmartButtonItem,
	ITaskBatchStepsItem,
	ITaskBatchStepsRequest,
} from './abstract';

@Injectable()
export class BatchProcessingDataHttpServiceImpl implements BatchProcessingDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService
	) {}

	processTaskStep(req: IProcessTaskStepCriteria): Observable<IProcessTaskResult> {
		const url = this.common.getApiUrl(`task/actions/batch/step`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	processTaskSmartButton(req: IProcessTaskSmartButtonCriteria): Observable<IProcessTaskResult> {
		const url = this.common.getApiUrl(`task/actions/batch/smartbutton`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	getBatchStepsByTaskIds(req: ITaskBatchStepsRequest): Observable<ITaskBatchStepsItem[]> {
		const url = this.common.getApiUrl(`task/actions/batch/step/steps`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	getBatchSmartButtonsByTaskIds(req: ITaskBatchStepsRequest): Observable<ITaskBatchSmartButtonItem[]> {
		const url = this.common.getApiUrl(`task/actions/batch/smartbutton/smartbuttons`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	processTaskPerformers(req: IProcessTaskPerformersCriteria): Observable<IProcessTaskResult> {
		const url = this.common.getApiUrl(`task/actions/batch/performers`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	processTaskOwner(req: IProcessTaskOwnerCriteria): Observable<IProcessTaskResult> {
		const url = this.common.getApiUrl(`task/actions/batch/owner`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	processTaskDuedate(req: IProcessTaskDuedateCriteria): Observable<IProcessTaskResult> {
		const url = this.common.getApiUrl(`task/actions/batch/due`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	processTaskDelete(req: IProcessTaskDeleteCriteria): Observable<IProcessTaskResult> {
		const url = this.common.getApiUrl(`task/actions/batch/delete`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	processTaskComment(req: IProcessTaskCommentCriteria): Observable<IProcessTaskResult> {
		const url = this.common.getApiUrl(`task/actions/batch/comment`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	processTaskSubscribers(req: IProcessTaskSubscribersCriteria): Observable<IProcessTaskResult> {
		const url = this.common.getApiUrl(`task/actions/batch/subscribers`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	processTaskMove(req: IProcessTaskMoveCriteria): Observable<IProcessTaskResult> {
		const url = this.common.getApiUrl(`task/actions/batch/move`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	processTaskCopy(req: IProcessTaskCopyCriteria): Observable<IProcessTaskResult> {
		const url = this.common.getApiUrl(`task/actions/batch/copy`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	processTaskExtParams(req: IProcessTaskExtParams): Observable<IProcessTaskResult> {
		const url = this.common.getApiUrl(`task/actions/batch/extparams`);
		return this.http.post<any>(url, req).pipe(map(result => result.data));
	}

	processTaskFiles(req: IProcessTaskFilesCriteria): Observable<Blob> {
		const url = this.common.getApiUrl(`task/actions/batch/files`);
		return this.http.post<any>(url, req, {
			responseType: 'blob' as any,
		});
	}
}
