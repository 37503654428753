import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidebarModule } from '@spa/common/components/sidebar';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';

import { ChatPanelModule } from '@spa/facade/layout/components/chat-panel/chat-panel.module';
import { ContentModule } from '@spa/facade/layout/components/content/content.module';
import { FooterModule } from '@spa/facade/layout/components/footer/footer.module';
import { NavbarModule } from '@spa/facade/layout/components/navbar/navbar.module';
import { QuickPanelModule } from '@spa/facade/layout/components/quick-panel/quick-panel.module';
import { ToolbarModule } from '@spa/facade/layout/components/toolbar/toolbar.module';
import { VerticalLayout2Component } from './layout-2.component';
import { MobileMainMenuModule } from '@spa/facade/layout/components/mobile-main-menu/mobile-main-menu.module';

@NgModule({
	declarations: [VerticalLayout2Component],
	imports: [
		RouterModule,
		CommonModule,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
		SidebarModule,
		ChatPanelModule,
		ContentModule,
		FooterModule,
		NavbarModule,
		QuickPanelModule,
		ToolbarModule,
		ScrollingModule,
		MobileMainMenuModule,
	],
	exports: [VerticalLayout2Component],
})
export class VerticalLayout2Module {}
