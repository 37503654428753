import { equalsObjectsByJson } from '@valhalla/utils';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ExtParamBase } from './ext-param-base';

export class ExtParamTable extends ExtParamBase {
	get dirty() {
		if (this.isNewTask) {
			if (Array.isArray(this.value?.values) && !this.value?.values?.length) {
				return false;
			}

			return this.value?.length;
			//return !!this.value?.rows?.length;
		}
		return super.dirty;
	}

	getValueForCopy() {
		return '';
	}

	setCopiedValue(value) {
		return;
	}

	equalsValue(a: any, b: any) {
		if (a?.values?.length !== b?.values?.length) {
			return false;
		}
		return equalsObjectsByJson(a?.values, b?.values);
	}

	convertForSaveInNewTaskAsync(): Observable<any> {
		if (typeof this.convertForSaveInNewTaskAsyncMiddleware === 'function') {
			return this.convertForSaveInNewTaskAsyncMiddleware(this).pipe(
				take(1),
				map(data => data && JSON.stringify(data))
			);
		}
		return of(this.convertForUpdateExtParamInNewTask());
	}

	canSave(source: any): boolean {
		if (source?.target instanceof HTMLElement) {
			if (source.key === 'Enter') {
				const canSave = !source.target.closest(
					'.ag-popup,.ag-grid-popup,vh-ext-param-grid,vh-ext-param-grid-new-row-modal,mat-dialog-container'
				);
				return canSave;
			}
		}
		return true;
	}
}
