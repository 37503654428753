import { Provider } from '@angular/core';

import { PlatformDetectorProvider } from './abstract';
import { PlatformDetectorProviderImpl } from './provider';

export const NGDI_PLATFORM_PROVIDERS: Provider[] = [
	{
		provide: PlatformDetectorProvider,
		useClass: PlatformDetectorProviderImpl,
	},
];
