import { Pipe } from '@angular/core';
import { getTaskId } from '@spa/data/entities';
import { DataHttpService } from '@spa/data/http';

@Pipe({
	name: 'vhTaskAvatar',
})
export class TaskAvatarPipe {
	constructor(readonly server: DataHttpService) {}

	transform(value: any): any {
		const taskId = typeof value === 'number' ? value : getTaskId(value);
		return this.server.task.getTaskAvatarUrl(taskId);
	}
}
