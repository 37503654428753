import { NgModule, ModuleWithProviders } from '@angular/core';

import { MatSidenavTogglerDirective } from './mat-sidenav-toggler.directive';
import { MatSidenavHelperDirective } from './mat-sidenav.directive';
import { MatSideNavHelperService } from './mat-sidenav.service';

@NgModule({
	exports: [MatSidenavTogglerDirective, MatSidenavHelperDirective],
	declarations: [MatSidenavTogglerDirective, MatSidenavHelperDirective],
})
export class MatSideNavHelperModule {
	forRoot(): ModuleWithProviders<MatSideNavHelperModule> {
		return {
			ngModule: MatSideNavHelperModule,
			providers: [MatSideNavHelperService],
		};
	}
}
