<vh-modal-base
	(close)="closeModal()"
	(cancel)="closeModal()"
	[showOk]="false"
	[showClose]="true"
	[showCancel]="false"
	[title]="'common.selectCategory' | resx"
>
	<vh-category-control (selectCategory)="onSelectCategory($event)"></vh-category-control>
</vh-modal-base>
