import { Effect, IAction, ofType } from '@valhalla/core';
import { of, zip } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as actions from '../actions';
import { ITickersState } from '../state';
import { TickerEffectDependencies } from './dependency';

export const loadTickersEffect: Effect<IAction, IAction, ITickersState, TickerEffectDependencies> = (
	actions$,
	state$,
	deps
) => {
	const logger = deps.loggerFactory.createLogger('loadTickersEffect');
	return actions$.pipe(
		ofType(actions.FacadeTickersAction.loadTickers),
		mergeMap(action => {
			// console.log(action.payload);
			return zip(
				deps.tickerProvider.selectSystemTickers2(action.payload),
				deps.tickerProvider.selectCustomTickers2(action.payload)
			).pipe(
				map(([tickers, customTickers]) =>
					actions.updateTickers({
						...tickers,
						...customTickers,
					})
				),
				catchError(e => {
					const errMsg = e.message || String(e);
					logger.error(errMsg, e.error || e);
					return of(actions.loadTickersError(e instanceof Error ? e : errMsg));
				})
			);
		})
	);
};
