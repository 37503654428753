<div class="nav-panel-admin">
	<ng-container *ngTemplateOutlet="buttonTemplate"> </ng-container>
	<ng-container *ngTemplateOutlet="systemInfoTemplate"> </ng-container>
	<ng-container *ngTemplateOutlet="popularSectionTemplate"> </ng-container>
	<ng-container *ngTemplateOutlet="menuTemplate"> </ng-container>
</div>

<ng-template #buttonTemplate>
	<div
		*ngIf="favoriteBtns?.length > 0"
		class="vh-common-sidebar-content py-8 mb-8"
		style="flex-grow: 0; border-radius: 8px"
	>
		<div class="drop-item node-item">
			<ng-container *ngFor="let btn of favoriteBtns">
				<vh-fv-expand-list-item
					(click)="onMenuLeafClick(btn, $event)"
					[label]="btn.name"
					[class.active]="isMenuLeafSelected(btn)"
					class="pointer"
					[counter]="btnCounter(btn).counter"
					[counterNew]="btnCounter(btn).counterNew"
					[counterNewColor]="btnCounter(btn).counterNewColor"
					[counterWarn]="btnCounter(btn).counterWarn"
				></vh-fv-expand-list-item>
			</ng-container>
		</div>
	</div>
</ng-template>

<ng-template #systemInfoTemplate>
	<div class="vh-fv-expand-list__items nav-panel-admin-system-info">
		<div class="system-info-wrapper">
			<!-- product version -->
			<div class="system-info-item" *ngIf="systemInfoElements?.productVersion">
				<span class="system-info-item-label">
					<div class="app-version">
						<span class="font-weight-600 app-version-number" style="min-width: auto"
							>{{ systemInfoElements.productVersion }}
						</span>
						<i
							vhFeatherIcon="copy"
							vhFeatherIconSize="16"
							(click)="copyVersion(systemInfoElements.productVersion, false)"
							class="pointer pl-4 copy-icon"
							id="copy-icon"
						></i>
					</div>
				</span>
			</div>
			<!-- back version -->
			<div class="system-info-item">
				<span class="system-info-item-label">
					<div class="app-version">
						<span class="font-weight-600 app-version-number"
							>{{ 'Back v' + systemInfoElements?.applicationVersion || '' }}
						</span>
						<span class="pl-4"
							>{{ '(от ' + (systemInfoElements?.refreshApplicationDate || '' | date: 'dd.MM.yyyy HH:mm') + ')' }}
						</span>
						<i
							vhFeatherIcon="copy"
							vhFeatherIconSize="16"
							(click)="copyVersion(systemInfoElements?.applicationVersion)"
							class="pointer pl-4 copy-icon"
							id="copy-icon"
						></i>
					</div>
				</span>
			</div>
			<!-- front version -->
			<div class="system-info-item">
				<span class="system-info-item-label">
					<div class="app-version">
						<span class="font-weight-600 app-version-number">{{ 'Front v' + frontVersion || '' }} </span>
						<span class="pl-4">{{ '(от ' + (frontBuildTime || '' | date: 'dd.MM.yyyy HH:mm') + ')' }} </span>
						<i
							vhFeatherIcon="copy"
							vhFeatherIconSize="16"
							(click)="copyVersion(frontVersion)"
							class="pointer pl-4 copy-icon"
						></i>
					</div>
				</span>
			</div>

			<!-- web version -->
			<div class="system-info-item">
				<span class="system-info-item-label">{{ 'Web: ' + systemInfoElements?.webServer }}</span>
			</div>

			<div class="system-info-item">
				<span class="system-info-item-label"
					>{{ 'common.serverTime' | resx }}: {{ systemInfoElements?.webServerTime | date: 'dd.MM.yyyy HH:mm' }}</span
				>
			</div>

			<!-- db version -->
			<div class="system-info-item">
				<span class="system-info-item-label">{{
					'DB: ' + systemInfoElements?.dbServer + '\\' + systemInfoElements?.dbName
				}}</span>
			</div>
			<!-- db version -->
			<div class="system-info-item">
				<span class="system-info-item-label">
					{{ 'common.dbSchemaModificationDate' | resx }}:
					{{ systemInfoElements?.dbSchemaModificationDate || '' | date: 'dd.MM.yyyy HH:mm' }}</span
				>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #popularSectionTemplate>
	<div class="popular-sections">
		<div
			*ngFor="let element of popularSections"
			class="popular-section-item"
			(click)="onMenuLeafClick(element, $event)"
			(hover)="onHover(element)"
			[tippy]="element.name"
			[isEnabled]="!mobileService.isMobile"
		>
			<mat-icon *ngIf="!element?.formaIcon" class="material-icons-outlined">{{ element.icon }}</mat-icon>
			<mat-icon *ngIf="element?.formaIcon" [svgIcon]="element.icon"></mat-icon>
		</div>

		<div
			class="popular-section-item"
			[tippy]="'Перезагрузить пул приложения'"
			[isEnabled]="!mobileService.isMobile"
			(click)="systemRestart()"
		>
			<mat-icon svgIcon="vh-re-v2-24"></mat-icon>
		</div>

		<div
			class="popular-section-item"
			[tippy]="'Денормализовать все категории'"
			[isEnabled]="!mobileService.isMobile"
			(click)="denormalize()"
		>
			<mat-icon svgIcon="vh-Denormalise-24"></mat-icon>
		</div>

		<div
			class="popular-section-item"
			[tippy]="'Обновить кеши'"
			[isEnabled]="!mobileService.isMobile"
			(click)="clearCaches()"
		>
			<mat-icon svgIcon="vh-Re-v1-24"></mat-icon>
		</div>
	</div>
</ng-template>

<ng-template #menuTemplate>
	<div class="vh-common-sidebar-content">
		<div class="vh-common-sidebar-content--search">
			<vh-sidebar-header
				[title]="'common.sideBarBtnAdmin' | resx"
				(searchInput)="searchHandler($event.target.value)"
				(searchClear)="searchHandler('')"
			>
			</vh-sidebar-header>
		</div>

		<!-- новое дерево -->
		<ng-container *ngIf="isNewAdminTree$ | async">
			<ng-container *ngFor="let item of adminTreeBySearch$ | async">
				<vh-nav-panel-admin-item
					[item]="item"
					[needContextMenu]="true"
					(contextItemClicked)="onAddAdminRow($event)"
					(clickItem)="onTreeItemClick($event)"
					(clickExpandCollapse)="expandCollapseTreeItem($event)"
				></vh-nav-panel-admin-item>
			</ng-container>
		</ng-container>

		<!-- старое дерево -->
		<ng-container *ngIf="!(isNewAdminTree$ | async)">
			<ng-container *ngFor="let menuGroup of menuElementsbySearch">
				<vh-fv-expand-list
					*ngIf="!menuGroup.parentId"
					[label]="menuGroup.name"
					[expanded]="isSearchActive ? true : isOpen(menuGroup.id)"
					(click)="expandGroup($event, menuGroup.id)"
					(expandCollapseEvent)="expandCollapseEvent.emit()"
				>
					<ng-container *ngFor="let element of menuElementsbySearch">
						<ng-container *ngIf="element.parentId">
							<vh-fv-expand-list-item
								*ngIf="menuGroup.id === element.parentId"
								(click)="onMenuLeafClick(element, $event)"
								[label]="element.name"
								[class.active]="isMenuLeafSelected(element)"
							></vh-fv-expand-list-item>
						</ng-container>
					</ng-container>
				</vh-fv-expand-list>
			</ng-container>
		</ng-container>
	</div>
</ng-template>
