import { InjectionToken, Provider, Type } from '@angular/core';

export const CRONS = new InjectionToken<ICronProvide<ICron>[]>('cron service');

export function provideCron<T extends ICron>(config: ICronProvide<T>) {
	if (!config?.name || !config?.type) {
		throw new Error(`provideCron need required params [name], [type]`);
	}
	const provider: Provider = {
		provide: CRONS,
		useValue: config,
		multi: true,
	};
	return provider;
}

export interface ICron {
	run: (context: ICronRunContext) => void;
}

export interface ICronRunContext {
	data?: any;
	complete: (result?: any) => void;
	fail: (err: any) => void;
	lastResult?: any;
}

export interface ICronSettings {
	interval: number;
	isEnabled?: boolean;
	data?: any;
}

export interface ICronProvide<T> {
	name: string;
	type: Type<T>;
	defaultSettings?: ICronSettings;
}
