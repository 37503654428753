import { map, Observable, of, take } from 'rxjs';
import { IGetEpSelectValuesParams } from '@spa/data/http/ep';

import { ExtParamBase } from './ext-param-base';
import { IExtParamLookupDataSource } from '@spa/data/entities';
import { Extendable } from '@valhalla/utils';

export type ExtParamLookupValueType = {
	taskId: number;
	taskText: string;
};

export class ExtParamMultiselectSubcatTasks extends ExtParamBase<ExtParamLookupValueType[], IExtParamLookupDataSource> {
	get isTableView() {
		return this.getSourceConfig()?.settings?.skin === 'Default';
	}

	search(
		filter: string,
		skip = 0,
		take = 50,
		opt?: Extendable<{ isHierarchyMode: boolean; parentTaskId: number; selected: boolean }>
	) {
		const params: IGetEpSelectValuesParams = {
			extParamId: this.id,
			isFromSubcatTasks: true,
			skip: skip,
			sourceBlockId: null,
			subcatId: this.ctxSubcatId,
			take: take,
			taskId: this.ctxTaskId,
			text: filter,
			countTotal: false,
		};
		const firstParentEpId = this.sourceConfig.dataSource?.filter?.firstParentEpId;
		if (firstParentEpId) {
			params.parentId = firstParentEpId;
			const parentEp = this.findEp(firstParentEpId);
			params.parentValues = this.calcParentValues(parentEp);
		}
		const secondParentEpId = this.sourceConfig.dataSource?.filter?.secondParentEpId;
		if (secondParentEpId) {
			params.parentId2 = secondParentEpId;
			const parentEp = this.findEp(secondParentEpId);
			params.parentValues2 = this.calcParentValues(parentEp);
		}
		if (opt?.isHierarchyMode) {
			params.selected = opt.selected;
			if (opt.parentTaskId) {
				params.parentTaskId = opt.parentTaskId;
			}
			return this.server.ep.getLookupHierarchyValues(params).pipe(map(res => res.data?.tasks));
		}
		return this.server.ep.getEpSelectValues(params);
	}

	calcParentValues(ep: ExtParamBase<any, any>) {
		return ep?.value
			? Array.isArray(ep?.value)
				? ep?.value.map(v => v.taskId || v.value)
				: [ep?.value.taskId || ep?.value.value]
			: [];
	}

	convertForUpdateExtParamInTask() {
		const div = ']||[]||[',
			multiDiv = '#';
		const prevIds = new Map(this.sourceValue?.map(i => [i.taskId, false]) || []);
		const nextIds = (this.value || []).map(i => i.taskId);
		const updateOps = [];
		for (const taskId of nextIds) {
			if (!prevIds.has(taskId)) {
				updateOps.push(taskId);
			} else {
				prevIds.set(taskId, true);
			}
		}
		prevIds.forEach((marked, taskId) => {
			if (!marked) {
				updateOps.push(`-${taskId}`);
			}
		});
		if (!updateOps.length) {
			return;
		}
		const ops = updateOps.join(multiDiv);
		return `#n${this.id}#v${ops}`;
	}

	convertForUpdateExtParamInNewTask() {
		const div = ']||[]||[';
		const prevIds = new Map(this.sourceValue?.map(i => [i.taskId, false]) || []);
		const nextIds = (this.value || []).map(i => i.taskId);
		const updateOps = [];
		for (const taskId of nextIds) {
			if (!prevIds.has(taskId)) {
				updateOps.push(taskId);
			} else {
				prevIds.set(taskId, true);
			}
		}
		prevIds.forEach((marked, taskId) => {
			if (!marked) {
				updateOps.push(`-${taskId}`);
			}
		});
		if (!updateOps.length) {
			return;
		}
		const ops = updateOps.join(div);
		return ops;
	}

	convertForSaveInNewTaskAsync(): Observable<any> {
		if (typeof this.convertForSaveInNewTaskAsyncMiddleware === 'function') {
			return this.convertForSaveInNewTaskAsyncMiddleware(this).pipe(take(1));
		}
		return of(JSON.stringify(this.value?.map(i => i.taskId) || []));
	}

	getValueForCopy() {
		const valueForCopy = this.value?.map(v => v?.taskText).join(';');
		return valueForCopy;
	}

	setCopiedValue(value) {
		this.setSearchContext(value);
	}

	equalsValue(a: ExtParamLookupValueType[], b: ExtParamLookupValueType[]) {
		if (this.isTableView) {
			// table update independent

			if ((!a && !b) || (!a && !b?.length) || (!a?.length && !b)) {
				return true;
			}

			if (a?.length !== b?.length) {
				return false;
			}

			return a?.every(first => b?.some(second => first?.taskId === second?.taskId));
		}

		if (!a && !b) {
			return true;
		}

		return a?.length === b?.length && a?.every(first => b?.some(second => first?.taskId === second?.taskId));
	}

	clearValue() {
		this.userValue$.next([]);
	}

	get extParamQueryStringValue(): string {
		if (!this.value?.length) {
			return '';
		}
		return `$Ext${this.id}$${this.value.map(i => i.taskId).join()}`;
	}

	setValueFromString(value: string) {
		try {
			const val = eval('(' + value + ')');
			if (Array.isArray(val)) {
				const validVal = val.map(i => {
					if (+i > 0) {
						const o: ExtParamLookupValueType = {
							taskId: +i,
							taskText: `#${+i}`,
						};
						return o;
					}
					return i;
				});
				this.setValue(validVal);
			}
		} catch {}
	}
}

export type ExtParamMultilookupDataSource = {
	lookupId: number;
	subcatId: number;
	filter: Partial<{
		columnId: number;
		extParamId: number;
		firstParentColumnId: number;
		firstParentEpId: number;
		firstParentVals: any;
		multiSelect: boolean;
		secondParentColumnId: number;
		secondParentEpId: number;
		secondParentVals: any;
		selectedValuesInMultiEp: any;
		smartFilterId: number;
		taskId: number;
		taskSubcatId: 8821;
	}>;
};
