import { Observable } from 'rxjs';
import { AdministrationImportConstraintsAction } from '@spa/pages/admin/migration/administration-migration-enum';
import { IApiResponse } from '@spa/data/http';
import { MigrationImportPreviewItem } from '@spa/pages/admin/migration/dto/migration-item.dto';

export abstract class AdminDataHttpServiceBase {
	abstract action(schemeName: string, value: string, model: IModel): Observable<IEntityModelActionResult>;
	abstract getEntitySchemeAndModel(schemeName: string, key: number): Observable<ISchemaWithModel>;
	abstract getEntityScheme(schemeName: string): Observable<IScheme>;
	abstract getEntityModel(schemeName: string, key: number): Observable<IModel>;
	abstract createEntityModel(schemeName: string, model: IModel): Observable<IModel>;
	abstract updateEntityModel(schemeName: string, model: IModel): Observable<IModel>;
	abstract mergeEntityModel(schemeName: string, model: IModel): Observable<IModel>;
	abstract deleteEntityModel(schemeName: string, key: number): Observable<boolean>;
	abstract getRepresentation(schemeName: string, representation: string): Observable<GetRepresentationDto>;

	abstract getUserSettings(userId: number): Observable<IProfileSettings>;
	abstract getUserSettingList(listOf: IListOf): Observable<IUserSettingList[]>;
	abstract updateUserSettings(userId: number, request: IUpdateUserSettingsReq): Observable<any>;

	abstract getNotificationSetting(userId: number): Observable<IUserNotificationSettings>;
	abstract updateNotificationSetting(userId: number, request: IUpdateNotificationSettingReq): Observable<any>;
	abstract uploadMigrationImportConfig(
		fileToUpload: File,
		withDenormalization: boolean,
		includeAllResolutions: boolean,
		importConstraintsAction: AdministrationImportConstraintsAction
	): Observable<IMigrationUtilityImportResponse[]>;
	abstract previewMigrationImport(
		fileToUpload: File,
		withDenormalization: boolean,
		importConstraintsAction: AdministrationImportConstraintsAction
	): Observable<IApiResponse<MigrationImportPreviewItem[]>>;

	abstract getAdminTree(): Observable<IAdminTreeItem[]>;

	abstract getApplicationSettingsConfig(): Observable<string>;
	abstract getApplicationConfigViaJson(): Observable<string>;
	abstract getNLogConfig(): Observable<string>;

	abstract getMigrationTreeRoot(): Observable<IMigrationTreeItem[]>;
	abstract getMigrationFetch(id: number, entityType: string): Observable<IMigrationTreeItem[]>;
	abstract exportMigrationConfig(
		saveLog: boolean,
		includeSubcat: boolean,
		exportItems: IExportMigrationConfigItem[]
	): Observable<any>;

	abstract getSmartExpression(
		id: number,
		additionalParams: Record<string, string>
	): Observable<ISmartExpressionResponse>;
	abstract saveSmartExpression(smartExpression: ISmartExpressionResponse): Observable<ISmartExpressionSaveResponse>;
	abstract executeSmartExpression(
		smartExpression: ISmartExpressionExecutePayload
	): Observable<ISmartExpressionExecuteResponse>;

	abstract getLuaSmartExpression(
		id: number,
		additionalParams: Record<string, string>
	): Observable<ISmartLuaScriptResponse>;
	abstract saveLuaSmartExpression(smartExpression: ISmartLuaScriptResponse): Observable<ISmartLuaScript>;
	abstract executeLuaSmartExpression(
		smartExpression: ISmartLuaScriptExecutePayload
	): Observable<ISmartLuaScriptExecuteResponse>;
	abstract getLuaActionParams(): Observable<ISmartLuaScriptActionParameters[]>;
	abstract deleteLuaSmartExpression(id: number, confirm?: boolean): Observable<void>;
	abstract getLuaSmartExpressionGrid(
		req: ISmartLuaScriptGridCriteria
	): Observable<IApiResponse<ISmartExpressionGridResponse>>;
	abstract getSmartExpressionsTestCases(req: ISmartExpressionTestCaseRequest): Observable<ISmartExpressionTestCase[]>;
	abstract saveSmartExpressionsTestCase(req: ISaveSmartExpressionTestCaseRequest): Observable<ISmartExpressionTestCase>;
	abstract createSmartExpressionsTestCase(
		req: ISaveSmartExpressionTestCaseRequest
	): Observable<ISmartExpressionTestCase>;
	abstract deleteSmartExpressionsTestCase(testCaseId: number): Observable<void>;
	abstract getSmartPackSchema(req: ISmartPackSchemaRequest): Observable<ISmartPackSchema>;
	abstract saveSmartPack(req: ISaveSmartPackRequest): Observable<INewSmartPack>;
	abstract redrawSmartPackSchema(req: IRedrawSmartPackSchemeRequest): Observable<ISmartPackAction>;
	abstract getSmartExpressionsList(req: ISmartPackExpressionRequest): Observable<ISmartExpressionList[]>;
	//todo add return type
	abstract saveSmartPackAction(
		req: IRedrawSmartPackSchemeRequest,
		packId: number,
		actionOrder: number
	): Observable<ISmartPackSchema>;
	abstract deleteSmartPackAction(
		req: IDeleteSmartPackActionRequest,
		packId: number,
		actionOrder: number
	): Observable<void>;
	abstract reorderSmartPackAction(
		req: IReorderSmartPackActionRequest,
		packId: number,
		actionOrder: number,
		offset: number
	): Observable<void>;
	abstract getGroupedSmartPackActions(req: Partial<IGetGroupedSmartPackActionRequest>): Observable<ISmartActionsList[]>;
	abstract expandSmartExpressionTreeNode(req: IExpandSmartExpressionTreeNode): Observable<ISmartExpressionTree>;

	abstract permissionsListByEntity(
		entityType: 'Subcategory' | 'Category' | 'Group'
	): Observable<IPermissionsListByEntityResponse>;
	abstract permissionsListByEntityId(
		entityType: 'Subcategory' | 'Category' | 'Group',
		entityId: number | string
	): Observable<IPermissionsListByEntityResponse>;
	abstract permissionsByEntityId(
		entityType: 'Subcategory' | 'Category' | 'Group',
		entityId: number | string
	): Observable<IPermissionsByEntityResponse>;
	abstract setPermissionsByEntityId(
		entityType: 'Subcategory' | 'Category' | 'Group',
		entityId: number | string,
		body: ISetPermissionsByEntityBody
	): Observable<void>;

	readonly notificationSettings$: Observable<IUserNotificationSettings>;
}

export interface IExpandSmartExpressionTreeNode {
	typeFullName: string;
	// todo add interface later
	node: any;
	context: {
		eventId: string;
		subcatId: number | string;
		isFilter: boolean;
		noContextMode: boolean;
		origin: string;
		isCycleContext: boolean;
		eventParameterValue: number | string;
		actionId: number;
		actionPackId: number;
	};
}

export interface IAdminTreeItem {
	nodeType: 'Form' | 'Folder';
	parentId: number;
	objectId: number;
	actions: any;
	rowActions: any;
	alias: string;
	allowAddF: boolean;
	allowDeleteF: boolean;
	notes: any;
	id: number;
	url?: string;
	name?: string;
}

export interface IEntityModelActionResult {
	type: EntityModelActionResultTypeEnum;
	data?: any;
	then?: IEntityModelActionResult;
}

export enum EntityModelActionResultTypeEnum {
	Error = 'Error',
	Alert = 'Alert',
	Saved = 'Saved',
}

export interface IUpdateNotificationSettingReq {
	extParamsBit: boolean;
	notifyStatusBit: boolean;
	serviceNotifyBit: boolean;
	notifyNewTaskBit: boolean;
	taskPerformerNotifyBit: boolean;
	subtasksNotifyBit: boolean;
	taskTextChangedNotifyBit: boolean;
	signatureNotifyBit: boolean;
	dueChangedNotifyBit: boolean;
	newCommentNotifyBit: boolean;
	overdueNotifyBit: boolean;
	ownerChangedNotifyBit: boolean;
	taskSubCatChangedNotifyBit: boolean;
	taskFileAddedNotifyBit: boolean;
	workAmountNotifyBit: boolean;
	taskFileDeletedNotifyBit: boolean;
	subscribersNotifyBit: boolean;
	autoReadCommentsInMTF: boolean;
	autoReadCommentWhenTaskCloses?: boolean;
	disableMail: boolean;
	disableMailWhenOnline: boolean;
	disableMailWhenWorkTime: boolean;
	disableMailWhenAbsence: boolean;
	disableSms: boolean;
	emailFilesAsAttachments: boolean;
	notNotifyAboutOverdueTasks: boolean;
	muteSound: boolean;
	extParamsBitLenta: boolean;
	notifyStatusBitLenta: boolean;
	serviceNotifyBitLenta: boolean;
	subtasksNotifyBitLenta: boolean;
	taskFileAddedNotifyBitLenta: boolean;
	taskSubCatChangedNotifyBitLenta: boolean;
	overdueNotifyBitLenta: boolean;
	taskTextChangedNotifyBitLenta: boolean;
	ownerChangedNotifyBitLenta: boolean;
	taskFileDeletedNotifyBitLenta: boolean;
	workAmountNotifyBitLenta: boolean;
	calendarNotifyBit: boolean;
	notifyNewTaskBitLenta: boolean;
	dueChangedNotifyBitLenta: boolean;
	newCommentNotifyBitLenta: boolean;
	taskPerformerNotifyBitLenta: boolean;
	signatureNotifyBitLenta: boolean;
	subscribersNotifyBitLenta: boolean;
	calendarNotifyBitLenta: boolean;
	hideTaskTextInChats: boolean;
	hideTaskTextInNonChats: boolean;
	unreadCommentsOnBadgeCounter: boolean;
	serviceNotifyBitPush: boolean;
	subtasksNotifyBitPush: boolean;
	taskTextChangedNotifyBitPush: boolean;
	taskFileAddedNotifyBitPush: boolean;
	taskSubCatChangedNotifyBitPush: boolean;
	overdueNotifyBitPush: boolean;
	ownerChangedNotifyBitPush: boolean;
	taskFileDeletedNotifyBitPush: boolean;
	workAmountNotifyBitPush: boolean;
	extParamsBitPush: boolean;
	notifyStatusBitPush: boolean;
	notifyNewTaskBitPush: boolean;
	dueChangedNotifyBitPush: boolean;
	newCommentNotifyBitPush: boolean;
	taskPerformerNotifyPush: boolean;
	signatureNotifyBitPush: boolean;
	subscribersNotifyBitPush: boolean;
	taskPerformerMyNotifyBitPush: boolean;
	calendarNotifyBitPush: boolean;
	sendPushForChats: ISendPush;
	sendPushForQuestions: ISendPush;
	sendPushForSignatures: ISendPush;
	pushSound: string;
	pushSoundAnswer: string;
	pushSoundQuestion: string;
	pushSoundChat: string;
	pushSoundLandlinePhone: string;
	sipSound: string;
}

export interface IUserNotificationSubcategoriesSettings {
	subcatId: number;
	subcategoryDescription: string;
	categoryId: number;
	categoryDescription: string;
	parentCategoryId: number;
	taskCreated: boolean;
	taskCreatedEnable: boolean;
	rejectedTask: boolean;
	rejectedTaskEnable: boolean;
	autoSubscribe: boolean;
	autoSubscribeEnable: boolean;
	changeDueDate: boolean;
	changeDueDateEnable: boolean;
	autoPerform: boolean;
	autoPerformEnable: boolean;
}

export enum ISendPush {
	doNotSend = 'DoNotSend',
	always = 'Always',
	onlyOffline = 'OnlyOffline',
}

export interface IUserNotificationSettings {
	subcategoriesSettings: IUserNotificationSubcategoriesSettings[];
	mobileSettings: {
		hideTaskTextInChats: boolean;
		hideTaskTextInNonChats: boolean;
		unreadCommentsOnBadgeCounter: boolean;
		sendPushForChats: ISendPush;
		sendPushForQuestions: ISendPush;
		sendPushForSignatures: ISendPush;
		pushSound: string;
		pushSoundChat: string;
		pushSoundQuestion: string;
		pushSoundAnswer: string;
		pushSoundLandlinePhone: string;
		sipSound: string;
	};
	emailFilesAsAttachments: boolean;
	notNotifyAboutOverdueTasks: boolean;
	disableMail: boolean;
	disableMailWhenAbsence: boolean;
	disableMailWhenWorkTime: boolean;
	disableMailWhenOnline: boolean;
	disableSms: boolean;
	autoReadCommentsInMTF: boolean;
	muteSound: boolean;
	serviceNotifyBit: boolean;
	serviceNotifyBitLenta: boolean;
	subtasksNotifyBit: boolean;
	subtasksNotifyBitLenta: boolean;
	taskTextChangedNotifyBit: boolean;
	taskTextChangedNotifyBitLenta: boolean;
	taskFileAddedNotifyBit: boolean;
	taskFileAddedNotifyBitLenta: boolean;
	taskSubCatChangedNotifyBit: boolean;
	taskSubCatChangedNotifyBitLenta: boolean;
	overdueNotifyBit: boolean;
	overdueNotifyBitLenta: boolean;
	ownerChangedNotifyBit: boolean;
	ownerChangedNotifyBitLenta: boolean;
	taskFileDeletedNotifyBit: boolean;
	taskFileDeletedNotifyBitLenta: boolean;
	workAmountNotifyBit: boolean;
	workAmountNotifyBitLenta: boolean;
	extParamsBit: boolean;
	extParamsBitLenta: boolean;
	notifyStatusBit: boolean;
	notifyStatusBitLenta: boolean;
	notifyNewTaskBit: boolean;
	notifyNewTaskBitLenta: boolean;
	dueChangedNotifyBit: boolean;
	dueChangedNotifyBitLenta: boolean;
	newCommentNotifyBit: boolean;
	newCommentNotifyBitLenta: boolean;
	taskPerformerNotifyBit: boolean;
	taskPerformerNotifyBitLenta: boolean;
	signatureNotifyBit: boolean;
	signatureNotifyBitLenta: boolean;
	subscribersNotifyBit: boolean;
	subscribersNotifyBitLenta: boolean;
	calendarNotifyBit: boolean;
	calendarNotifyBitLenta: boolean;
	serviceNotifyBitPush: boolean;
	subtasksNotifyBitPush: boolean;
	taskTextChangedNotifyBitPush: boolean;
	taskFileAddedNotifyBitPush: boolean;
	taskSubCatChangedNotifyBitPush: boolean;
	overdueNotifyBitPush: boolean;
	newCommentNotifyBitPush: boolean;
	ownerChangedNotifyBitPush: boolean;
	taskFileDeletedNotifyBitPush: boolean;
	workAmountNotifyBitBitPush: boolean;
	notifyStatusBitPush: boolean;
	notifyNewTaskBitPush: boolean;
	dueChangedNotifyBitPush: boolean;
	taskPerformerNotifyBitPush: boolean;
	taskPerformerMyNotifyBitPush: boolean;
	signatureNotifyBitPush: boolean;
	subscribersNotifyBitPush: boolean;
	extParamsPush: boolean;
	calendarNotifyPush: boolean;
}

export interface ITextValuePair {
	text: string;
	value: string;
}

export interface IUpdateUserSettingsReq {
	nick: string;
	lastName: string;
	firstName: string;
	middleName: string;
	maidenName: string;
	phone: string;
	phone2: string;
	phone3: string;
	phoneAdditional: string;
	phone2Additional: string;
	phone3Additional: string;
	homePhoneAdditional: string;
	languageId: number;
	roomId: number;
	email: string;
	externalEmail: string;
	cellPhone: string;
	homePhone: string;
	fax?: string;
	skype: string;
	icq: string;
	telegram: string;
	liveJournal: string;
	twitter: string;
	country: string;
	city: string;
	birthDate: string;
	workStartDate: string;
	authProviderId?: number;
	timeZoneId: string;
	sid?: string;
	externalDisplayName: string;
	displayName: string;
	englishDisplayName: string;
	personalInfoText: string;
	gender: number;
	isInMaternityLeave: boolean;
	businessFunctionsText?: string;
	signsAllOwnedTasks?: boolean;
	allowUsersChangeDisplayNameMode?: boolean;
	isEmployee?: boolean;
	oneCGuid?: string;
	userNameMode: IUserNameMode;
	userIPPhone: IUserIPPhone;
	canEditWorkInfo?: boolean;
	canEditGeoInfo?: boolean;
	canEditContactsInfo?: boolean;
	canEditPrivateInfo?: boolean;
	canEditAvatar?: boolean;
	canEditPassword?: boolean;
	notes?: string;
	restrictCellPhoneView: boolean;
	disableMail?: boolean;
	disableMailWhenOnline?: boolean;
	disableMailWhenWorkTime?: boolean;
	disableMailWhenAbsence?: boolean;
	disableSms?: boolean;
	doNotUseGeolocation: boolean;
	autoReadComments?: boolean;
	autoReadCommentsInMTF?: boolean;
	autoReadCommentWhenTaskCloses?: boolean;
	hideBirthYear: boolean;
	forceChangePassword?: boolean;
	mailBoxAccess?: boolean;
}

export interface IUserSettingList {
	id: string;
	name: string;
}

export enum IListOf {
	pushSipSounds = 'PushSipSounds',
	pushSounds = 'PushSounds',
	pushSendModes = 'PushSendModes',
	authProviders = 'AuthProviders',
	rooms = 'Rooms',
	genders = 'Genders',
	languages = 'Languages',
	timezones = 'Timezones',
	oneFLicenses = 'OneFLicenses',
}

export interface IProfileSettings {
	phoneVerificationSettings: {
		mobilePhoneVerificationStateVisible: boolean;
		mobilePhoneVerifyVisible: boolean;
		mobilePhoneVerificationCodeVisible: boolean;
		mobilePhoneEnterVerifyCodeVisible: boolean;
		mobilePhoneVerifyText: string;
		mobilePhoneVerified: boolean;
	};
	controlVisibilitySettings: {
		passChangeDialogBVisible: boolean;
		newPasswordVisible: boolean;
		newPasswordConfirmVisible: boolean;
		forceChangePasswordVisible: boolean;
		workInfoVisible: boolean;
		workInfoDisabled: boolean;
		enablePersonalText: boolean;
		passwordEnabled: boolean;
		newPasswordEnabled: boolean;
		newPasswordRepeatEnabled: boolean;
		restrictCellPhoneView: boolean;
		englishDisplayNameEnabled: boolean;
		displayNameEnabled: boolean;
		userNameModeEnabled: boolean;
		allowUsersChangeDisplayNameMode: boolean;
		allowUsersChangeDisplayNameModeEnabled: boolean;
		whiteInfoVisible: boolean;
		photoWrapVisible: boolean;
		inMaternityVisible: boolean;
		maindenNameVisible: boolean;
		currentPasswordValidDate: string;
		currentPasswordValidDateVisible: boolean;
		avatarEnabled: boolean;
		geoInfoEnabled: boolean;
		contactsInfoEnabled: boolean;
		privateInfoEnabled: boolean;
	};
	selfSettings: {
		canEditAvatar: boolean;
		canEditWorkInfo: boolean;
		canEditGeoInfo: boolean;
		canEditPrivateInfo: boolean;
		canEditContactsInfo: boolean;
		canEditPassword: boolean;
	};
	adminSettings: {
		signsAllOwnedTasks: boolean;
		isEmployee: boolean;
		canEditGeoInfo: boolean;
		canEditWorkInfo: boolean;
		canEditContactsInfo: boolean;
		canEditPrivateInfo: boolean;
		canEditAvatar: boolean;
		canEditPassword: boolean;
		authProviderId: number;
		mailBoxAccess: boolean;
		disableMail: boolean;
		disableMailWhenOnline: boolean;
		disableMailWhenAbsence: boolean;
		disableMailWhenWorkTime: boolean;
		disableSms: boolean;
		autoReadComments: boolean;
		autoReadCommentsInMTF: boolean;
		autoReadCommentWhenTaskCloses: boolean;
		forceChangePassword: boolean;
		unBlockAllow: boolean;
		guidFrom1C: string;
		notes: string;
	};
	userId: number;
	isFired: boolean;
	nick: string;
	firstName: string;
	middleName: string;
	maidenName: string;
	lastName: string;
	email: string;
	userNameMode: IUserNameMode;
	doNotUseGeolocation: boolean;
	phone: string;
	phone3: string;
	phoneAdditional: string;
	phone3Additional: string;
	phone2: string;
	homePhoneAdditional: string;
	phone2Additional: string;
	cellPhone: string;
	homePhone: string;
	fax: string;
	skype: string;
	icq: string;
	twitter: string;
	liveJournal: string;
	telegramUserName: string;
	city: string;
	country: string;
	externalEmail: string;
	birthDate: string;
	workStartDate: string;
	displayName: string;
	englishDisplayName: string;
	externalDisplayName: string;
	userText: string;
	businessFunctions: string;
	timeZoneId: string;
	languageId: number;
	sid: string;
	oneFLicense: string;
	gender: boolean;
	userIPPhone: IUserIPPhone;
	isInMaternityLeave: boolean;
	hideBirthYear: boolean;
	concurrentLicensesEnabled: boolean;
	roomId: number;
	createDate: string;
}

export enum IUserIPPhone {
	work1 = 'Work1',
	work2 = 'Work2',
	work3 = 'Work3',
	mobile = 'Mobile',
	additional = 'Additional',
}

export enum IUserNameMode {
	surName = 'SurName',
	surNameName = 'SurNameName',
	surNameNP = 'SurNameNP',
	nick = 'Nick',
	appointment = 'Appointment',
	externalName = 'ExternalName',
	name = 'Name',
	appointmentName = 'AppointmentName',
	nickName = 'NickName',
	nameParentName = 'NameParentName',
	appointmentSurName = 'AppointmentSurName',
	appointmentNick = 'AppointmentNick',
	surnameNameParentName = 'SurnameNameParentName',
	nameSurname = 'NameSurname',
	englishNick = 'EnglishNick',
	maidennameNameParentname = 'MaidennameNameParentname',
	maidennameName = 'MaidennameName',
}
export interface GetRepresentationDto {
	schemeName: string;
	skip: number;
	take: number;
	count: number;
	representation: Representation;
	items: any[];
	scheme: IScheme;
}

export interface IModelSettings {
	isNew?: boolean;
	deletionEnabled?: boolean;
	updateEnabled?: boolean;
	isReadonly?: boolean;
}

export interface IModel {
	entityModelSettings?: IModelSettings;
	entityModelKey?: number;
}

export interface Field {
	guid: string;
	name: string;
	description: string;
	options: IOption[];
}

export interface Representation {
	guid: string;
	as: string;
	fields: Field[];
}

export interface Tooltip {
	guid: string;
	lang: string;
	value: string;
}

export interface Rule {
	guid: string;
	type: string;
	value: any[];
}

export interface IOption {
	guid: string;
	name: string;
	value: any;
}

export interface ILocalization {
	guid: string;
	lang: string;
	value: string;
}

export interface IElement {
	alias: string;
	fieldPath: string[];
	systemType: string;
	isNulleable?: boolean;
	isPrimaryKey: boolean;
	type: string;
	jsType: string;
	tooltip: Tooltip[];
	rules: Rule[];
	options: IOption[];
	guid: string;
	description: ILocalization[];
}

export interface IBlock {
	elements: IElement[];
	guid: string;
	description: ILocalization[];
}

export interface ISection {
	blocks: IBlock[];
	guid: string;
	description: ILocalization[];
}

export interface IButton {
	guid: string;
	text: string;
	value: string;
}
export interface IScheme {
	schemeName: string;
	buttons?: IButton[];
	representations: Representation[];
	sourceTable: string;
	sections: ISection[];
	guid: string;
	description: ILocalization[];
}

export interface ISchemaWithModel {
	scheme: IScheme;
	model: IModel;
}

export enum VisualTypeEnum {
	Text = 'text',
	BigText = 'bigText',
	Wysiwyg = 'wysiwyg',
	Checkbox = 'checkbox',
	Dropdown = 'dropdown',
	Number = 'number',
	DateTime = 'dateTime',
	TimeOffset = 'timeOffset',
	Assotiation = 'association',
	Object = 'object',
	Empty = 'empty',
	Unknown = 'unknown',
	ReadOnly = 'readonly',
	Hidden = 'hidden',
	Code = 'code',
	Color = 'color',
	ResourceText = 'resourceText',
	File = 'file',
	ButtonPanel = 'buttonPanel',
	Groups = 'groups',
}

export enum JsTypeEnum {
	Number = 'number',
	Boolean = 'boolean',
	String = 'string',
	Enum = 'enum',
	Date = 'date',
	Object = 'object',
	Empty = 'empty',
	Unknown = 'unknown',
	Association = 'association',
}

export enum OpEnum {
	Equals = '=',
	NotEquals = '!=',
	Like = 'like',
	NotLike = 'not like',
	Regex = 'regex',
	More = '>',
	Less = '<',
	MoreOrEquals = '>=',
	LessOrEquals = '<=',
}

export enum RuleTypeEnum {
	IsActive = 'isActive',
	IsRequired = 'isRequired',
}

export interface IMigrationUtilityImportResponse {
	fileName: string;
	data: any[];
}

export interface IMigrationTreeItem {
	value: string;
	text: string;
	tag: IMigrationTreeItemTag;
	id: number;
	entityType: string;
	action: string;
	nodes: IMigrationTreeItem[];
	subcatParentItem?: {
		entityType: string;
		value: string;
	};
}

export interface IMigrationTreeItemTag {
	canHaveChildren: boolean;
	children: any;
	action: any;
	entityType: string;
	id: number;
	guid: string;
	title: string;
}

export interface IMigrationTreeRootResponse {
	data: IMigrationTreeItem[];
}

export interface IMigrationFetchResponse {
	data: IMigrationTreeItem[];
}

export interface IExportMigrationConfigItem {
	entityType: string;
	value: string[];
}

export interface ISmartExpressionResponse {
	expression: ISmartExpression;
	context: ISmartExpressionContext;
	eventParameters: ISmartExpressionEventParameter[];
	eventDescription: string;
	tree: ISmartExpressionTree;
	toolbar: ISmartExpressionToolbarResponse;
}

export interface ISmartExpressionTree {
	attributes: Record<string, string>;
	category: ISmartExpressionTreeNodeCategory;
	imageUrl: string;
	operatorInfo: Record<string, string>;
	propertyInfo: Record<string, string>;
	serverExpandMode: boolean;
	text: string;
	value: ISmartExpressionTreeNodeValue;
	nodes: ISmartExpressionTree[];
}

export enum ISmartExpressionTreeNodeValue {
	RootEntityParams = 'RootEntityParams',
	OtherTasks = 'OtherTasks',
	SummaryCats = 'SummaryCats',
	ExtParamId = 'extParamId',
	OperatorKey = 'operatorKey',
	OperatorValue = 'operatorValue',
}

export enum ISmartExpressionTreeNodeCategory {
	Grouping = 'Grouping',
	Property = 'Property',
	Operator = 'Operator',
	LinkToParentNode = 'LinkToParentNode',
	SelectEpValFromListOperator = 'SelectEpValFromListOperator',
	CollectionOperatorSelectComponent = 'CollectionOperatorSelectComponent',
}

export interface ISmartExpressionToolbarResponse {
	menuItems: ISmartExpressionToolbarItem[];
}

export interface ISmartExpressionToolbarItem {
	children: ISmartExpressionToolbarItem[];
	info: {
		key: string;
		text: string;
		opFuncType: 'Operator' | 'Function' | 'StaticOperator';
		functionDefaultParameterVals: string[];
		returnTypeFullName: string;
	};
	value: string;
	text: string;
}

export interface ISmartExpressionExecutePayload extends ISmartExpressionResponse {
	params: { contextId: number; eventParameters: ISmartExpressionEventParameter[] };
}

export interface ISmartExpressionExecuteResponse {
	filterResult: boolean;
	objectResult: Record<string, any>[];
	tSql: string;
}

export type ISmartExpressionSaveResponse = ISmartExpression & ISmartExpressionContext;
export interface ISmartExpression {
	id: number;
	name: string;
	isFilter: boolean;
	subcatId: number;
	noContextMode: boolean;
	tSql: string;
	description?: string;
	xml: string;
	xhtml?: string;
}

export interface ISmartExpressionTestCase {
	id: number;
	smartExpressionId: number;
	lastModifiedUserId: number;
	lastModifiedDate: string;
	description: string;
	isActive: boolean;
	expectedResult: { description: string; value: string };
	parameters: {
		params: { eventParameters: ISmartExpressionEventParameter[]; contextId?: number };
		context?: ISmartExpressionContext;
	};
}

export interface ISmartExpressionContext {
	eventId: string;
	isFilter: boolean;
	subcatId: number;
	noContextMode: boolean;
	origin: string;
	avalilableAsSmartSearch: boolean;
	isCycleContext: boolean;
	parameterValue: number;
}

export interface ISmartExpressionEventParameter {
	displayName: string;
	description: string;
	name: string;
	order: number;
	value: string;
}

export interface ISmartLuaScriptResponse {
	script: ISmartLuaScript;
	context: ISmartExpressionContext;
	eventParameters: ISmartExpressionEventParameter[];
	eventDescription: string;
	contextId: number;
}

export interface ISmartLuaScript {
	contextType: string;
	description: string;
	eventId: number;
	scriptCode: string;
	subcatId: number;
	id: number;
	isLibrary: boolean;
}

export interface ISmartLuaScriptExecutePayload extends ISmartLuaScriptResponse {
	eventParameters: ISmartExpressionEventParameter[];
}

export interface ISmartLuaScriptExecuteResponse {
	result: any;
	output: string;
}

export interface ISmartLuaScriptActionParametersResponse {
	actionsParameters: ISmartLuaScriptActionParameters[];
}

export interface ISmartLuaScriptActionParameters {
	actionId: string;
	actionDescription: string;
	actionResultType: string;
	actionParameters: { description: string; key: string; type: string; required: boolean }[];
}

export interface ISmartLuaScriptGridCriteria {
	subcatId: number;
	subcatIndependent: boolean;
	descriptionFilter: string;
	scriptFilter: string;
	isLibrary: boolean;
	skip: number;
	take: number;
	orderBy: string;
	order: 'Asc' | 'Desc';
	eventIndependent?: boolean;
	eventId?: number;
}

export interface ISmartExpressionGridResponse {
	scripts: ISmartExpression[];
	total: number;
}
export interface ISmartExpressionTestCasesResponse {
	testCases: ISmartExpressionTestCase[];
	total: number;
}

export interface ISmartExpressionTestCaseRequest {
	subcatIds: number[];
	expressionIds: number[];
	subcatIndependent: boolean;
	isFilter: boolean;
	activeOnly: boolean;
	descriptionFilter: string;
	expressionNameFilter: string;
	skip: number;
	take: number;
	orderBy: string;
	order: 'Asc' | 'Desc';
}

export interface ISaveSmartExpressionTestCaseRequest extends ISmartExpressionTestCase {
	executeAndSaveResult: boolean;
}

export interface ISmartPackSchemaRequest {
	packId: number;
	SubcatId: number;
	EventId?: string;
}

export interface ISaveSmartPackRequest {
	pack: {
		packId: number;
		subcatId: number;
		name: string;
		description: string;
		isCyclic: boolean;
		cycleIteratorExpressionId: number;
		cycleBreakFilterId: number;
	};
	editorContext: {
		noContextMode: boolean;
		eventId: number;
		subcatId: number;
		origin: string;
		cycleContext: boolean;
		isForMail: boolean;
		isForQueue: boolean;
	};
}

export interface IDeleteSmartPackActionRequest {
	noContextMode: boolean;
	eventId: number;
	subcatId: number;
	origin: string;
	cycleContext: boolean;
	isForMail: boolean;
	isForQueue: boolean;
}

export interface IRedrawSmartPackSchemeRequest {
	packAction: {
		actionId: string;
		id: number;
		actionName: string;
		actionDescription: string;
		customActionGuid: string;
		order: number;
		parameters: ISmartPackActionParameter[];
	};
	editorContext: {
		noContextMode: boolean;
		eventId: number;
		subcatId: number;
		origin: string;
		cycleContext: boolean;
		isForMail: boolean;
		isForQueue: boolean;
	};
}

export interface ISmartPackExpressionRequest {
	eventId: number;
	originId: number;
	subcatId: number;
	selectFilters: boolean;
	eventParameterValue?: number;
	onlyCurrentStep: boolean;
}

export interface IReorderSmartPackActionRequest {
	noContextMode: boolean;
	eventId: number;
	subcatId: number;
	origin: string;
	cycleContext: boolean;
	isForMail: boolean;
	isForQueue: boolean;
}

export interface ISmartPackSchema {
	pack: {
		cycleBreakFilterId: number;
		cycleIteratorExpressionId: number;
		description: string;
		isCyclic: boolean;
		name: string;
		packId: number;
		subcatId: number;
	};
	actions: ISmartPackAction[];
}
export interface INewSmartPack {
	cycleBreakFilterId: number;
	cycleIteratorExpressionId: number;
	description: string;
	isCyclic: boolean;
	name: string;
	packId: number;
	subcatId: number;
}

export interface ISmartPackAction {
	action: {
		actionName: string;
		actionDescription: string;
		actionId: string;
		customActionGuid: string;
		id: number;
		order: number;
		uiOrder: number;
		parameters: ISmartPackActionParameter[];
	};
	schema: ISmartPackActionSchema;
}

export interface ISmartPackActionSchema {
	parameters: ISmartPackActionSchemaParameter[];
	smartExpressionControl: {
		cycleContext: boolean;
		eventId: string;
		noContextMode: boolean;
		// origin: null;
		subcatId: number;
	};
	smartScriptControl: {
		contextType: string;
		eventId: string;
		subcatId: number;
	};
}

export interface ISmartPackActionParameter {
	expressionId: number;
	fixedValue: string;
	order: number;
	scriptId: number;
	showInPreview: boolean;
	valueFromAdditionalSource: string;
}

export interface ISmartPackActionSchemaParameter {
	// additionalValueSources: null;
	availableValueSources: SmartPackActionAvailableValueSources[];
	// todo add type
	defaultValue: any;
	dependentOnOrder: null;
	displayName: string;
	fixedValueControl: SmartPackActionFixedValueControl;
	order: number;
	required: boolean;
	uiOrder: number;
}

export interface ISmartPackActionAdditionalParameter {
	additionalValueSources: Record<number, string>;
	displayName: string;
	fixedValueControl: null;
	parameterId: string;
	required: boolean;
}

export interface ISmartExpressionList {
	groupName: string;
	smartExpressions: ISmartExpression[];
}

export interface ISmartActionsListResponse {
	groups: ISmartActionsList[];
}

export interface ISmartActionsList {
	groupName: string;
	actions: ISmartAction[];
}

export interface ISmartExpression {
	id: number;
	isEditable: boolean;
	name: string;
	subcatId: number;
}

export interface ISmartAction {
	key: { standardAction: string; customActionGuid: string };
	title: string;
}

export enum SmartPackActionAvailableValueSources {
	Empty = 'Empty',
	Fix = 'Fix',
	Smart = 'Smart',
	Script = 'Script',
}

export interface SmartPackActionFixedValueControl {
	controlType: SmartPackActionFixedValueControlType;
	// todo add type
	demoValue: any;
	singleUserMode?: boolean;
	values?: Record<number, string>;
	parameters?: ISmartPackActionAdditionalParameter[];
}

export interface IGetGroupedSmartPackActionRequest {
	NoContextMode: boolean;
	EventId: string;
	SubcatId: number;
	Origin: string;
	CycleContext: boolean;
	IsForMail: boolean;
	IsForQueue: boolean;
}

export enum SmartPackActionFixedValueControlType {
	Checkbox = 'CheckBox',
	TextInput = 'TextInput',
	DateTime = 'DateTime',
	DropDown = 'DropDown',
	SmartExpressionSelect = 'SmartExpressionSelect',
	SmartScriptSelect = 'SmartScriptSelect',
	FileConversion = 'FileConversion',
	SubcategorySelect = 'SubcategorySelect',
	UserSelect = 'UserSelect',
	FileFromLibrarySelect = 'FileFromLibrarySelect',
	ParameterSet = 'ParameterSet',
}

export interface IPermissionsListByEntityResponse {
	data: IPermissionsListByEntityData;
}

export interface IPermissionsListByEntityData {
	actions: IPermissionsListByEntityAction[];
	errors: IPermissionsListByEntityError[];
}
export interface IPermissionsListByEntityAction {
	id: number;
	mnemo: string;
	name: string;
	canEdit: boolean;
}

export interface IPermissionsListByEntityError {
	errorType: string;
	message: string;
	exceptionTitle: string;
}

export interface IPermissionsByEntityResponse {
	data: IPermissionsByEntityData;
	errors: IPermissionsByEntityError[];
}

export interface IPermissionsByEntityData {
	permissions: IPermissionsByEntityPermission[];
}

export interface IPermissionsByEntityPermission {
	groupId: number;
	actionIds: number[];
}

export interface IPermissionsByEntityError {
	errorType: string;
	message: string;
	exceptionTitle: string;
}

export interface ISetPermissionsByEntityBody {
	changes: ISetPermissionsByEntityChange[];
}

export interface ISetPermissionsByEntityChange {
	groupId: number;
	actionId: number;
	enable: boolean;
}
