import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@valhalla/core';
import { map } from 'rxjs/operators';

import ApiVersion from '../api-versions';
import { DataHttpCommonService } from '../data-http-common.service';
import { IPublicationCriteria, PublicationsDataHttpService } from './abstract';

@Injectable()
export class PublicationsDataHttpServiceImpl implements PublicationsDataHttpService {
	constructor(readonly http: HttpClient, readonly common: DataHttpCommonService, readonly auth: AuthService) {}

	get({ alias, type, queryParams }: IPublicationCriteria) {
		const url = this.common.getApiUrl(`publications/${type}/${alias}`, ApiVersion.v12);
		return this.http.get<any>(url, { params: queryParams });
	}

	post({ alias, type, data, queryParams }: IPublicationCriteria) {
		const url = this.common.getApiUrl(`publications/${type}/${alias}`, ApiVersion.v12);
		return this.http.post<any>(url, data, { params: queryParams });
	}
}
