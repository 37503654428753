import { Router } from '@angular/router';
import { GlobalSpaContextService } from '@spa/api/global-spa-context.service';
import { spaCommand } from '@spa/api/spa-command';
import type { IModalRef } from '@spa/facade/features/modals';
import { filter, take, takeUntil } from 'rxjs/operators';

import { GlobalEventHandler } from './type';

export const openModalIframe: GlobalEventHandler = injector => {
	const eventName = 'spa:open-modal-iframe';
	const router = injector.get(Router);

	async function onOpenModalIframeHandler(e: CustomEvent<{ url: string; title: string }>) {
		const modalRef = (await spaCommand('openModalIframe', e?.detail)) as IModalRef;
		router.events.pipe(take(1), takeUntil(modalRef.afterClosed())).subscribe(() => {
			modalRef.close(undefined);
		});
	}

	window.addEventListener(eventName, onOpenModalIframeHandler);

	return () => {
		// dispose
		window.removeEventListener(eventName, onOpenModalIframeHandler);
	};
};
