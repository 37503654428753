import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { IAttachment } from '@spa/data/entities';
import { DataHttpService, IFileInfo } from '@spa/data/http';
import { IFileViewer, IFileViewerSettings } from '../../file-viewer.component';
import { PlatformDetectorProvider, UrlProvider } from '@spa/core';
import { IFileViewerDialogComponentDataOptions } from '../../file-viewer-dialog.component';
import { BehaviorSubject, map, shareReplay, switchMap, take } from 'rxjs';
import { booleanFilter, InputSubject } from '@valhalla/utils';

const selector = 'vh-pdf-viewer';

/**
 * Компонент для рендера PDF-файлов
 */
@Component({
	// tslint:disable-next-line:component-selector
	selector: selector,
	templateUrl: './pdf-viewer.component.html',
	styleUrls: ['./pdf-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [],
})
export class PdfViewerComponent implements IFileViewer {
	constructor(readonly server: DataHttpService) {}

	@Input()
	@InputSubject()
	file: Partial<IAttachment>;
	file$!: BehaviorSubject<Partial<IAttachment>>;

	@Input()
	public fileInfo: IFileInfo;

	@Input()
	public options: IFileViewerDialogComponentDataOptions;

	readonly url = inject(UrlProvider);
	readonly platform = inject(PlatformDetectorProvider);
	readonly isSafari = this.platform.isOneOfPlatform(this.platform.types.safari);
	readonly isElectron = this.platform.isElectron();

	public settings: IFileViewerSettings;
	public isDirty: boolean;

	ngOnInit() {}

	pdfViwerUrl(url: string) {
		const href = new URL(url);
		const path = url.replace(href.origin + '/', '');
		const viewerUrl = this.url.getUrlRelativeToAssets('scripts/viewer/index.html#/');
		const correctUrl = viewerUrl + path;
		console.log(correctUrl);
		return correctUrl;
	}

	getFileMeetingLink(file) {
		return this.url.fileMeetingUrl(file?.key);
	}

	getFileEmailLink(file) {
		return this.url.fileEmailUrl(file?.key);
	}
}
