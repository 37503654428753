import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { adjustComment, adjustTask } from '@valhalla/data/entities';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IApiResponse } from '@spa/data/http';
import ApiVersion from '../api-versions';
import { DataHttpCommonService } from '@spa/data/http';
import {
	FeedCommentsType,
	FeedsDataHttpService,
	FeedTasksType,
	ICommentsFiltersBySubcat,
	IFeedReadAllCriteria,
	IGetFeedCommentsCriteria,
	IGetFeedCommentsResult,
	IGetFeedTasksCriteria,
	IGetFeedTasksResult,
} from './abstract';

@Injectable()
export class FeedsDataHttpServiceImpl implements FeedsDataHttpService {
	constructor(public readonly http: HttpClient, public readonly common: DataHttpCommonService) {}

	comments(criteria: IGetFeedCommentsCriteria): Observable<IGetFeedCommentsResult> {
		if (criteria.searchString && !criteria.searchType) {
			criteria.searchType = 'Contains';
		}
		const url = this.common.getApiUrl(`comments/lenta`);
		return this.http
			.post<IApiResponse<IGetFeedCommentsResult>>(url, {
				fromUserID: criteria.userId,
				limit: criteria.limit,
				offset: criteria.offset,
				subcatId: criteria.subcatId,
				catId: criteria.catId,
				lentaCommTypes: criteria.lentaCommTypes,
				commentId: criteria.commentId,
				limitBefore: criteria.limitBefore,
				limitAfter: criteria.limitAfter,
				searchString: criteria.searchString,
				searchType: criteria.searchType,
				includeReactions: typeof criteria.includeReactions === 'boolean' ? criteria.includeReactions : true,
				grouped: criteria.grouped,
			})
			.pipe(
				map(result => result.data),
				tap(data => {
					data.comments.forEach(adjustComment);
					for (let i = 0; i < data.tasks.length; i++) {
						const task = data.tasks[i];
						task.comments = task.comments || [data.comments[i]];
					}
				})
			);
	}

	tasks(criteria: IGetFeedTasksCriteria, options?: Record<string, any>): Observable<IGetFeedTasksResult> {
		switch (criteria.feedType) {
			case FeedTasksType.all:
				return this.allTasks(criteria, options);
			case FeedTasksType.allForKanban:
				return this.allTasksForKanban(criteria, options);
			case FeedTasksType.allForNewKanban:
				return this.allTasksForNewKanban(criteria, options);
			case FeedTasksType.favorites:
				return this.favoritesTasks(criteria, options);
			case FeedTasksType.new:
				return this.newTasks(criteria, options);
			case FeedTasksType.owner:
				return this.ownerTasks(criteria, options);
			case FeedTasksType.performer:
				return this.performerTasks(criteria, options);
			case FeedTasksType.stage:
				return this.stageTasks(criteria, options);
			case FeedTasksType.channel:
				return this.allTasksWithComments(criteria, options);
			case FeedTasksType.tasksByCategory:
				return this.allTasksByCategory(criteria, options);
			case FeedTasksType.lastCommented:
				return this.showLastComment(criteria, options);
			case FeedTasksType.created:
				return this.createdDate(criteria, options);
			default:
				// tslint:disable-next-line:no-console
				console.warn(`Fallback for feedType='${criteria.feedType}' redirect to tasks/feeds api`);
				break;
		}

		const url = this.common.getApiUrl(`tasks/feeds`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				limit: criteria.limit,
				offset: criteria.offset,
				feedTypes: [criteria.feedType],
				filters: [],
				sorters: [{ sorterType: 'OrderedTime' }],
				showClosed: Boolean(criteria.showClosed),
				showLastComment: Boolean(criteria.showLastComment),
			})
			.pipe(
				map(result => result.data),
				tap(data => {
					data.tasks.forEach(adjustTask);
				})
			);
	}

	tasksRawRequest(body: Record<string, any>): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http.post<IApiResponse<IGetFeedTasksResult>>(url, body).pipe(
			map(result => result.data),
			tap(data => {
				data.tasks.forEach(adjustTask);
			})
		);
	}

	tasksSearchRequest(body: Record<string, any>): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feeds/search`);
		return this.http.post<IApiResponse<IGetFeedTasksResult>>(url, body).pipe(
			map(result => result.data),
			tap(data => {
				data.tasks.forEach(adjustTask);
			})
		);
	}

	read(criteria: IFeedReadAllCriteria): Observable<any> {
		switch (criteria.type) {
			case FeedCommentsType.all:
				return this.readAll();
			case FeedCommentsType.unread:
				return this.readAllUnread();
			case FeedCommentsType.myQuestions:
				return this.readAllMyQuestions();
			default:
				break;
		}

		// tslint:disable-next-line: no-console
		console.warn(`readAll not implemented for feed type '${criteria.type}'`);
		return EMPTY;
	}

	readSupport(type: FeedCommentsType): boolean {
		return [FeedCommentsType.all, FeedCommentsType.unread, FeedCommentsType.myQuestions].indexOf(type) !== -1;
	}

	getCommentsFiltersBySubcat(subcatId: number): Observable<ICommentsFiltersBySubcat> {
		const url = this.common.getApiUrl(`subcategories/${subcatId}/commentFilters`, ApiVersion.v10);
		return this.http.get<ICommentsFiltersBySubcat>(url).pipe(catchError(_ => of(null)));
	}

	setCommentsFiltersBySubcat(subcatId: number, filters: ICommentsFiltersBySubcat): Observable<any> {
		const url = this.common.getApiUrl(`subcategories/${subcatId}/commentFilters`, ApiVersion.v10);
		return this.http.post<any>(url, filters);
	}

	feedBySP(
		{ limit, offset, catIds, subcatIds, taskIds, searchString }: IGetFeedTasksCriteria,
		options?: Record<string, any>
	): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				resultRowsCount: limit,
				resultSetOffset: offset,
				catIds,
				subcatIds,
				taskIds,
				searchString,
				searchType: searchString ? 'Contains' : undefined,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => {
					data.tasks.forEach(adjustTask);
				})
			);
	}

	protected readAllMyQuestions() {
		const url = this.common.getApiUrl('/comments/mark-all-my-comments-as-read');
		return this.http.post(url, {});
	}

	protected readAllUnread() {
		//const url = this.common.getEndpointUrl('/1FMain.aspx/ReadAll');
		const url = this.common.getApiUrl('/comments/read-all');
		return this.http.post(url, {
			catID: null,
			count: 100,
			favsFolderId: null,
			filterCommentsSignatures: false,
			filterOnlyNew: false,
			groupId: null,
			maxCommentID: null,
			maxTaskTextLength: 1000,
			minCommentID: null,
			onlyFromUsers: false,
			onlyMine: false,
			onlySubscribed: false,
			orgUnitId: null,
			personalsType: null,
			showSystem: false,
			subcatID: null,
			type: 'unread',
		});
	}

	protected readAll() {
		//const url = this.common.getEndpointUrl('/1FMain.aspx/ReadAll');
		const url = this.common.getApiUrl('/comments/read-all');
		return this.http.post(url, {
			catID: null,
			count: 100,
			favsFolderId: null,
			filterCommentsSignatures: false,
			filterOnlyNew: false,
			groupId: null,
			maxCommentID: null,
			maxTaskTextLength: 1000,
			minCommentID: null,
			onlyFromUsers: false,
			onlyMine: false,
			onlySubscribed: false,
			orgUnitId: null,
			personalsType: null,
			showSystem: false,
			subcatID: null,
			type: 'lenta',
		});
	}

	protected allTasks(
		{ limit, offset, catIds, subcatIds, searchString }: IGetFeedTasksCriteria,
		options?: Record<string, any>
	): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				showLastComment: false,
				resultRowsCount: limit,
				resultSetOffset: offset,
				openOnlyF: true,
				closedOnlyF: false,
				favoritesOnlyF: false,
				taskHelperOnlyF: false,
				subscriberOnlyF: true,
				myTasksOnlyF: false,
				oneFMainVisibilityModeF: true,
				workNotStartedOnlyF: false,
				reversedOrderF: false,
				orderByCreatedTaskF: false,
				orderByOrderedTimeF: true,
				outLastCommentF: true,
				searchString,
				searchType: searchString ? 'Contains' : undefined,
				catIds,
				subcatIds,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => {
					data.tasks.forEach(adjustTask);
				})
			);
	}

	protected allTasksForKanban(
		{ limit, offset, catIds, subcatIds, openOnlyF, closedOnlyF }: IGetFeedTasksCriteria,
		options?: Record<string, any>
	): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				showLastComment: false,
				resultRowsCount: limit,
				resultSetOffset: offset,

				openOnlyF: openOnlyF,
				closedOnlyF: closedOnlyF,

				favoritesOnlyF: false,
				taskHelperOnlyF: false,
				subscriberOnlyF: false,

				myTasksOnlyF: false,

				oneFMainVisibilityModeF: false,

				workNotStartedOnlyF: false,
				reversedOrderF: true,
				orderByCreatedTaskF: true,
				outLastCommentF: false,
				catIds,
				subcatIds,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => {
					data.tasks.forEach(adjustTask);
				})
			);
	}

	protected allTasksForNewKanban(
		{ limit, offset, catIds, subcatIds, myTasks, tasksState, smartExpressionIds }: IGetFeedTasksCriteria,
		options?: Record<string, any>
	): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				showLastComment: false,
				resultRowsCount: limit,
				resultSetOffset: offset,
				oneFMainVisibilityModeF: false,
				reversedOrderF: true,
				orderByCreatedTaskF: true,
				outLastCommentF: false,
				catIds,
				subcatIds,
				myTasks,
				tasksState,
				smartExpressionIds,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => {
					data.tasks.forEach(adjustTask);
				})
			);
	}

	protected ownerTasks(
		{ limit, offset, catIds, subcatIds, searchString }: IGetFeedTasksCriteria,
		options?: Record<string, any>
	): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				showLastComment: false,
				resultRowsCount: limit,
				resultSetOffset: offset,
				openOnlyF: true,
				closedOnlyF: false,
				favoritesOnlyF: false,
				taskHelperOnlyF: false,
				subscriberOnlyF: false,
				myTasksOnlyF: true,
				oneFMainVisibilityModeF: true,
				workNotStartedOnlyF: false,
				reversedOrderF: false,
				orderByOrderedTimeF: true,
				outLastCommentF: true,
				catIds,
				subcatIds,
				searchString,
				searchType: searchString ? 'Contains' : undefined,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => {
					data.tasks.forEach(adjustTask);
				})
			);
	}

	protected performerTasks(
		{ limit, offset, catIds, subcatIds, searchString }: IGetFeedTasksCriteria,
		options?: Record<string, any>
	): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				showLastComment: false,
				resultRowsCount: limit,
				resultSetOffset: offset,
				openOnlyF: true,
				closedOnlyF: false,
				favoritesOnlyF: false,
				taskHelperOnlyF: true,
				subscriberOnlyF: false,
				myTasksOnlyF: false,
				oneFMainVisibilityModeF: false,
				workNotStartedOnlyF: false,
				reversedOrderF: false,
				orderByCreatedTaskF: false,
				orderByOrderedTimeF: true,
				outLastCommentF: true,
				catIds,
				subcatIds,
				searchString,
				searchType: searchString ? 'Contains' : undefined,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => {
					data.tasks.forEach(adjustTask);
				})
			);
	}

	protected favoritesTasks(
		{ limit, offset, catIds, subcatIds, searchString }: IGetFeedTasksCriteria,
		options?: Record<string, any>
	): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				showLastComment: false,
				resultRowsCount: limit,
				resultSetOffset: offset,
				openOnlyF: false,
				closedOnlyF: false,
				favoritesOnlyF: true,
				taskHelperOnlyF: false,
				subscriberOnlyF: false,
				myTasksOnlyF: false,
				oneFMainVisibilityModeF: false,
				workNotStartedOnlyF: false,
				reversedOrderF: false,
				orderByCreatedTaskF: true,
				orderByOrderedTimeF: false,
				outLastCommentF: true,
				catIds,
				subcatIds,
				searchString,
				searchType: searchString ? 'Contains' : undefined,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => {
					data.tasks.forEach(adjustTask);
				})
			);
	}

	protected newTasks(
		{ limit, offset, catIds, subcatIds, searchString }: IGetFeedTasksCriteria,
		options?: Record<string, any>
	): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				showLastComment: false,
				resultRowsCount: limit,
				resultSetOffset: offset,
				openOnlyF: false,
				closedOnlyF: false,
				favoritesOnlyF: false,
				taskHelperOnlyF: false,
				subscriberOnlyF: false,
				myTasksOnlyF: true,
				oneFMainVisibilityModeF: false,
				workNotStartedOnlyF: true,
				reversedOrderF: false,
				orderByCreatedTaskF: true,
				orderByOrderedTimeF: false,
				outLastCommentF: true,
				catIds,
				subcatIds,
				searchString,
				searchType: searchString ? 'Contains' : undefined,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => {
					data.tasks.forEach(adjustTask);
				})
			);
	}

	protected stageTasks(
		{ limit, offset, catIds, subcatIds }: IGetFeedTasksCriteria,
		options?: Record<string, any>
	): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				resultRowsCount: limit,
				resultSetOffset: offset,
				closedOnlyF: false,
				favoritesOnlyF: false,
				myTasksOnlyF: false,
				oneFMainVisibilityModeF: false,
				openOnlyF: false,
				orderByCreatedTaskF: true,
				outLastCommentF: true,
				reversedOrderF: true,
				showLastComment: false,
				subscriberOnlyF: false,
				taskHelperOnlyF: false,
				workNotStartedOnlyF: false,
				catIds,
				subcatIds,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => {
					data.tasks.forEach(adjustTask);
				})
			);
	}

	protected allTasksWithComments(
		{ limit, offset, catIds, subcatIds }: IGetFeedTasksCriteria,
		options?: Record<string, any>
	): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				showLastComment: true,
				resultRowsCount: limit,
				resultSetOffset: offset,
				openOnlyF: true,
				closedOnlyF: false,
				favoritesOnlyF: false,
				taskHelperOnlyF: false,
				subscriberOnlyF: true,
				myTasksOnlyF: false,
				oneFMainVisibilityModeF: true,
				workNotStartedOnlyF: false,
				reversedOrderF: false,
				orderByCreatedTaskF: false,
				outLastCommentF: true,
				OrderByLastCommentIdF: true,
				catIds,
				subcatIds,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => {
					data.tasks.forEach(adjustTask);
				})
			);
	}

	protected allTasksByCategory(
		{ limit, offset, catIds, subcatIds, searchString }: IGetFeedTasksCriteria,
		options?: Record<string, any>
	): Observable<IGetFeedTasksResult> {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				resultRowsCount: limit,
				resultSetOffset: offset,
				closedOnlyF: false,
				favoritesOnlyF: false,
				myTasksOnlyF: false,
				oneFMainVisibilityModeF: true,
				openOnlyF: false,
				orderByCreatedTaskF: false,
				orderByOrderedTimeF: true,
				outLastCommentF: true,
				reversedOrderF: false,
				showLastComment: false,
				subscriberOnlyF: false,
				taskHelperOnlyF: false,
				workNotStartedOnlyF: false,
				searchType: searchString ? 'Contains' : undefined,
				searchString,
				catIds,
				subcatIds,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => data.tasks.forEach(adjustTask))
			);
	}

	protected showLastComment(
		{ limit, offset, catIds, subcatIds }: IGetFeedTasksCriteria,
		options?: Record<string, any>
	) {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				resultRowsCount: limit,
				resultSetOffset: offset,
				closedOnlyF: false,
				favoritesOnlyF: false,
				myTasksOnlyF: false,
				oneFMainVisibilityModeF: true,
				openOnlyF: false,
				orderByCreatedTaskF: false,
				orderByOrderedTimeF: true,
				outLastCommentF: true,
				reversedOrderF: false,
				showLastComment: true,
				subscriberOnlyF: false,
				taskHelperOnlyF: false,
				workNotStartedOnlyF: false,
				catIds,
				subcatIds,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => data.tasks.forEach(adjustTask))
			);
	}

	protected createdDate({ limit, offset, catIds, subcatIds }: IGetFeedTasksCriteria, options?: Record<string, any>) {
		const url = this.common.getApiUrl(`tasks/feedsBySP`);
		return this.http
			.post<IApiResponse<IGetFeedTasksResult>>(url, {
				resultRowsCount: limit,
				resultSetOffset: offset,
				closedOnlyF: false,
				favoritesOnlyF: false,
				myTasksOnlyF: false,
				oneFMainVisibilityModeF: true,
				openOnlyF: false,
				orderByCreatedTaskF: true,
				orderByOrderedTimeF: true,
				outLastCommentF: true,
				reversedOrderF: false,
				showLastComment: false,
				subscriberOnlyF: false,
				taskHelperOnlyF: false,
				workNotStartedOnlyF: false,
				catIds,
				subcatIds,
				...(options || {}),
			})
			.pipe(
				map(result => result.data),
				tap(data => data.tasks.forEach(adjustTask))
			);
	}
}
