import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NavigationCommonModule } from '@spa/common/components/navigation';
import {
	IfOnDomModule,
	PerfectScrollbarModule,
	VhSubscribeModule,
	DetectAutoFillModule,
	ResizeWatcherModule,
	AutoFocusModule,
} from '@spa/common/directives';
import { UserPreviewPanelModule } from '@spa/common/directives/user-preview-panel';

import { NavbarHorizontalStyle1Component } from './style-1.component';

@NgModule({
	declarations: [NavbarHorizontalStyle1Component],
	imports: [
		CommonModule,
		MatIconModule,
		NavigationCommonModule,
		IfOnDomModule,
		PerfectScrollbarModule,
		VhSubscribeModule,
		DetectAutoFillModule,
		ResizeWatcherModule,
		AutoFocusModule,
		UserPreviewPanelModule,
	],
	exports: [NavbarHorizontalStyle1Component],
})
export class NavbarHorizontalStyle1Module {}
