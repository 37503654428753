import * as common from './common';

export default {
	'deep-purple': {
		50: '#ede7f6',
		100: '#d1c4e9',
		200: '#b39ddb',
		300: '#9575cd',
		400: '#7e57c2',
		500: '#673ab7',
		600: '#5e35b1',
		700: '#512da8',
		800: '#4527a0',
		900: '#311b92',
		A100: '#b388ff',
		A200: '#7c4dff',
		A400: '#651fff',
		A700: '#6200ea',
		contrast: {
			50: common.black87,
			100: common.black87,
			200: common.black87,
			300: 'white',
			400: 'white',
			500: common.white87,
			600: common.white87,
			700: common.white87,
			800: common.white87,
			900: common.white87,
			A100: common.black87,
			A200: 'white',
			A400: common.white87,
			A700: common.white87,
		},
	},
};
