import { Injector } from '@angular/core';

/**
 * injector available after core module bootstrapped
 */
export let coreInjector: Injector;

export function setCoreInjector(value: Injector) {
	coreInjector = value;
}
