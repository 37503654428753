import { IUser, IUserShort } from './user';
import { IExtParam } from './ext-param';
import { IComment } from './comment';
import { IAttachment } from './attachment';
import { arrayFlatMap } from '@valhalla/utils';
import { IRecipient } from './recipient';
import { IFavoriteFolder } from '../../facade/features/navigation/providers/data-provider.dto';
import { inject } from '@angular/core';
import { DataHttpService } from '../http';
import { IExtParamBlock } from './subcat';

export interface ITaskInfo {
	taskId: number;
	taskID: number;
	taskText: string;
	dueDate: string;
	priority: number;
	subcatId: number;
	stateId: number;
	stateID?: number;
	isClosed: boolean;
	isOverdue: boolean;
	isWorkFinished: boolean;
	modifiedDate: string;
	creationDate: string;
	createdDate: string;
	startDate: string;
	subCatDescription: string;
	guid: string;
	responsiblePerformer: IUserShort;
	performers: IUserShort[];
	owner?: IUserShort;
	ownerID?: number;
	ownerId?: number;
	subcatID?: number;
	parentId?: number;
	stateDescription?: string;
	unreadCommentsCount: number;
	extParams: IExtParam[];
	extparams?: IExtParam[];
	comments: IComment[];
	counters: Array<
		Partial<{
			active: boolean;
			changed: boolean;
			changedDate: string;
			counter: number;
			counterType: string;
			exists: boolean;
			taskId: number;
			type: string;
		}>
	>;
	files: IAttachment[];
	allowHTMLTaskText: boolean;
	htmlTaskText: string;
	status: string;
	isChat: boolean;
	isPinnedToChat: boolean;
	hasMoreComments: boolean;
	hasRequestSignaturesRight: boolean;
	canBeRevoked: boolean;
	allowDynamicSignatureRequests: boolean;
	linkedTaskId: any;
	linkedTasks: Partial<ITaskInfo>[];
	color: string;
	subscribers: IUserShort[];
	avatarSubscribers?: IUserShort[];
	subscribersGroups?: IRecipient[];
	actions: ITaskAction[];
	templates: ITaskTemplate[];
	allowComments: boolean;
	chatNotificationMode: string;
	requestedSignatures: any;
	optionalSignatures: ITaskOptionalSignature[];
	favoriteComments: IComment[];
	customTasksUsed: ITaskGridBlock[];
	subTasks: ITaskInfo[];
	orderedTime: string;
	toolbar?: ITaskToolbar;
	viewTags?: IGroupTag[];
	subcatDescription?: string;
	canChangeState?: boolean;
	canLockDueDate?: boolean;
	isDueDateLocked?: boolean;
	taskProjectData?: any;
	canChangeTaskText?: boolean;
	canLocalizeTaskText?: boolean;
	isWaitingSign?: boolean;
	filesCount?: IFilesCount[];
	isEncrypted?: boolean;
	mainParams?: any[];
	emailsCount?: number;
	attachFilesPermission?: IAttachFilesPermission;
	hasAvatar?: boolean;
	allowNewComments: boolean;
	taskStartDate?: any;
	commentNotMeAnswerRequired?: boolean;
	commentQuestionsAllow?: boolean;
	templateInfo?: ITaskTemplateInfo;
	info?: any;
	commentCounters?: Partial<{
		commentsCount: number;
		inCommentsUnansweredCount: number;
		inCommentsUnreadCount: number;
		outCommentsUnansweredCount: number;
		// /**@deprecated */
		// commentsUnreadCount: number;
		// /**@deprecated */
		// allCommentsCount: number;
		// /**@deprecated */
		// inCommentsCount: number;
	}>;
	commenters?: IUser[];
	canEditPerformers?: boolean;
	linkedFolders?: IFavoriteFolder[];
	allowedReactions?: IReaction[];
	reactionsEnabled?: boolean;
	allowDeleteSelfFromPerformers?: boolean;
	isSpace?: boolean;
	extParamBlocks?: IExtParamBlock[];
	allowGroupSubcription?: boolean;
	linkedStorageFolder?: ILinkedStorageFolder;
	subcatType?: 'Tasks' | 'Media';
}

export interface ILinkedStorageFolder {
	id: number;
	name: string;
	parentId: number;
	type: string;
}

export interface IReaction {
	id: number;
	emoji: string;
}

export enum IAttachFilesPermission {
	allow = 'Allow',
	allowInCreatedTask = 'AllowInCreatedTask',
	disallow = 'Disallow',
	required = 'Required',
}

export interface IFilesCount {
	type?: string;
	count?: number;
}

export function flatTags(task: Partial<ITaskInfo>, server: DataHttpService) {
	const showDefaultTags = !server.config.appSettingsAnonymousConfig?.CustomSettings?.HideDefaultTags;
	const tags = arrayFlatMap(task?.viewTags || [], gt => gt.tags).filter(t => t.name) || [];
	if (!tags.length && showDefaultTags) {
		const desc = task.subcatDescription || task.subCatDescription;
		desc &&
			tags.push({
				name: desc,
			});
		const state = task?.stateDescription;
		state &&
			tags.push({
				name: state,
			});
	}
	return tags.filter(t => t.name);
}

export function getTaskId(task: Partial<ITaskInfo> | number | string): number {
	if (!task) {
		return;
	}
	if (typeof task === 'number' || typeof task === 'string') {
		return +task;
	}
	return task.taskID || task.taskId || (task as any).id;
}

export function getTaskSubcatId(task: Partial<ITaskInfo>): number {
	return task?.subcatId || task?.subcatID;
}

export function adjustTask(task: Partial<ITaskInfo>) {
	if (task) {
		task.taskId = task.taskId || task.taskID;
		task.stateId = task.stateId || task.stateID;
		task.ownerId = task.ownerId || task.ownerID;
		task.subcatId = task.subcatId || task.subcatID;
		task.subscribers = task.subscribers || [];
		task.subscribersGroups = task.subscribersGroups || [];
		task.optionalSignatures = task.optionalSignatures || [];
		task.comments = task.comments || [];
	}
	return task;
}

export interface IGroupTag {
	isUsersTags: boolean;
	name: string;
	tags: ITag[];
}

export interface ITag {
	name: string;
	isFilled?: boolean;
	textColor?: string;
	bgColor?: string;
	element?: {
		dataKey: number | string;
		type: string;
	};
}

export interface ITaskGridBlock {
	order: number;
	tables: IGridTasks[];
	name: string;
	id: number;
	isCollapsable?: boolean;
	isCollapsedByDefault?: boolean;
}

export interface ITaskOptionalSignature {
	id: number;
	description: string;
	reason: string;
}

export interface IGridTasks {
	order: number;
	subcatId: number;
	showCreateButton: boolean;
	name: string;
	id: number;
	createButtonURL: string;
	contentType: string;
	visibilityMode: string;
	showMassTaskAction: boolean;
	showOpenedOnly: boolean;
	showSelectionMode: boolean;
	openLinkAs: TaskUsedLinkOpen;
	getLinkFrom: string;
	leaveOpenAfterTaskCreate: boolean;
	dataSourceSettingId?: number;
	dataSourceSettingKey: string;
	isQuickCreateForm?: boolean;
	dataSourceType?: ITaskUsedDataSourceType;
}

export enum ITaskUsedDataSourceType {
	Subcategory = 'Subcategory',
	StoredProcedure = 'StoredProcedure',
	CustomDataSource = 'CustomDataSource',
	SmartFilter = 'SmartFilter',
}

export enum TaskUsedLinkOpen {
	default = 'Default',
	newTab = 'NewTab',
	modalWindow = 'ModalWindow',
}

export interface ITaskAction {
	id: string;
	description: string;
	disabled: boolean;
	forceComment: boolean;
	startTimeChangeConfirmText: string;
	stepTooltip: string;
	stateDescription: string;
	doSetPerformer: boolean;
	closeTask: boolean;
	finishWork: boolean;
	newDueDateRequired: boolean;
	overdueReasonRequired: boolean;
	signatures: any;
	otherActions?: boolean;
	alertText?: string;
	confirmText?: string;
	requireSubtaskFinish?: boolean;
	requireChangeDateBefore?: boolean;
	sigRequestReasonRequired?: boolean;
}

export enum TaskTemplateType {
	meeting = 'meeting',
}

export interface ITaskMeetingTemplateSettings {
	mappingExtParam: {
		participants: number;
		where: number;
		dateFrom: number;
		dateTo: number;
		meetingType: number;
	};
	showCalendar?: boolean;
	showEventLabel?: boolean;
}

export interface ITaskTemplate {
	description: string;
	subcatId: number;
	content: {
		settings: {
			groupUsedBlockTables?: boolean;
			specialType: TaskTemplateType;
			specialSettings: ITaskMeetingTemplateSettings;
			usedBlockTables: Record<number, ITaskTemplateUsedBlockTable>;
			title?: { type: string; dataKey: any; viewMode: string };
		};
		mapping?: Record<string, { id: number; type: string; field?: string; extParamId?: number }>;
		sections: [
			{
				name: string;
				blocks: [
					{
						elements: [
							{
								name: string;
								type: string;
								dataKey: string;
								viewMode: string;
								alias?: string;
							}
						];
					}
				];
			}
		];
	};
	guid: string;
	id: number;
	name: string;
	typeId: string;
	modified: string;
	complexContextName?: string;
}

export interface ITaskTemplateUsedBlockTable {
	fields: Array<ITaskTemplateUsedBlockTableField>;
	lineBreak?: boolean;
}

export interface ITaskTemplateUsedBlockTableField {
	name?: string;
	type: TaskTemplateUsedBlockTableFieldType;
	dataKey: string;
	options?: Partial<{
		width: string;
		horizontalAlign: string;
	}>;
}

export enum TaskTemplateUsedBlockTableFieldType {
	mainParam = 'mainParam',
	extParam = 'extParam',
}

export interface INewsResponse {
	categories: any[];
	tabs: any[];
	tasks: ITaskInfo[];
	templates: ITaskTemplate[];
}

export interface ITaskToolbar {
	items: ITaskToolbarItem[];
}

export interface ITaskToolbarItem {
	badge: ITaskToolbarItemBadge;
	checked: boolean;
	children?: ITaskToolbarItem[];
	hidden: boolean;
	label: string;
	tooltip: string;
	value: string;
}

export interface ITaskToolbarItemBadge {
	key: string;
	prefix: string;
	value: number;
}

export type TypeAfterActionEvent = {
	type: TaskCardActionEvent;
};

export enum TaskCardActionEvent {
	deleteTask = 'delete-task',
}

export interface ITaskTemplateInfo {
	cellId: number;
	cellLastModifiedDate: number;
	cellName: string;
	id: number;
	lastModifiedDate: number;
	name: string;
}
