import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import {
	AfterViewInit,
	ApplicationRef,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	Directive,
	ElementRef,
	EmbeddedViewRef,
	EventEmitter,
	Inject,
	Injector,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	ViewContainerRef,
} from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { LoggerFactory, ViewDestroyStreamService } from '@valhalla/core';
import { delayRunInZone, isNumber } from '@valhalla/utils';
import { BehaviorSubject, EMPTY, fromEvent, merge, Observable, Subject, timer } from 'rxjs';
import { concatMap, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ContextMenuFixedContainerCommonComponent } from './context-menu.component';
import { IContextMenuContext } from './context-menu.contracts';

// see #890554 fix for: Если быстро кликнуть два раза правой кнопкой, вызывая контекстное меню, то второй раз может вызваться браузерное
fromEvent<MouseEvent>(document, 'contextmenu').subscribe(e => {
	const target = e.target as HTMLElement;
	if (
		target?.classList.contains('cdk-overlay-pane') &&
		(target.firstChild as HTMLElement)?.classList.contains('mat-menu-panel')
	) {
		e.preventDefault();
	}
});

// tslint:disable-next-line:nx-enforce-module-boundaries
@Directive({
	selector: '[vhContextMenu]',
	providers: [ViewDestroyStreamService],
	exportAs: 'vhContextMenu',
})
export class ContextMenuDirective implements OnInit, AfterViewInit, OnDestroy {
	constructor(
		readonly elRef: ElementRef<HTMLElement>,
		readonly destroy$: ViewDestroyStreamService,
		readonly vcr: ViewContainerRef,
		readonly cfr: ComponentFactoryResolver,
		readonly injector: Injector,
		readonly appRef: ApplicationRef,
		readonly zone: NgZone,
		readonly platform: Platform,
		@Inject(DOCUMENT) readonly document: Document,
		@Optional() readonly loggerFactory?: LoggerFactory
	) {}

	// tslint:disable-next-line:no-input-rename
	@Input('vhContextMenu')
	context: MatMenu | IContextMenuContext;

	// tslint:disable-next-line:no-input-rename
	@Input('vhContextMenuData')
	contextData: any;

	// tslint:disable-next-line:no-input-rename
	@Input('vhContextMenuTapCount')
	contextTapCount: number;

	/** Recognized when the pointer is down for x ms without any movement. */
	// tslint:disable-next-line:no-input-rename
	@Input('vhContextMenuPressTime')
	contextPressTime = 300; // 251 - this is default from hammer doc

	// tslint:disable-next-line:no-input-rename
	@Input('vhContextMenuUsePress')
	contextUsePress = false;

	@Input('vhContextMenuTrapClick')
	trapClick = false;

	@Input('vhContextMenuTrapDblClick')
	trapDblClick = false;

	@Input('vhContextMenuCloseOnScroll')
	closeOnScroll = true;

	@Input('vhContextMenuIgnoreWhenSelection')
	ignoreWhenSelection = false;

	@Input('vhContextMenuDisabled')
	disabled = false;

	@Input('vhContextMenuExcludeFromTargetIn')
	excludeFromTargetIn: string[];

	@Input('vhContextMenuTriggerManually')
	triggerManually = false;

	@Input()
	ignoreLinks = true;

	@Output()
	readonly vhContextMenuOpened = new EventEmitter(true);
	@Output()
	readonly vhContextMenuClosed = new EventEmitter();

	protected fixedContainerFactory: ComponentFactory<ContextMenuFixedContainerCommonComponent>;
	protected cmpRef: ComponentRef<ContextMenuFixedContainerCommonComponent>;
	protected tapEvent$: Subject<any>;
	protected hammerManager: HammerManager;
	protected contextClick$: Observable<MouseEvent>;
	protected logger = this.loggerFactory && this.loggerFactory.createLogger('ContextMenuDirective');
	protected get el() {
		return this.elRef.nativeElement;
	}
	protected customTapEventName = 'custom-tap-event';
	protected currentMouseEvent: MouseEvent;
	protected triggerContextMenu$ = new Subject<MouseEvent>();

	readonly opened$ = new BehaviorSubject(false);
	private _touched = false;

	readonly touched$ = merge(
		fromEvent(this.document, 'touchmove', { passive: true }),
		fromEvent(this.document, 'touchstart', { passive: true }),
		fromEvent(this.document, 'touchend', { passive: true })
	).pipe(
		map(() => true),
		takeUntil(this.destroy$)
	);

	get isMobile() {
		return this.platform.ANDROID || this.platform.IOS;
	}

	get isSafari() {
		return this.platform.SAFARI;
	}

	get hasSelection() {
		const selection = window.getSelection();
		const { focusOffset, anchorOffset } = selection;
		return Math.abs(focusOffset) - Math.abs(anchorOffset) !== 0;
	}

	get valid() {
		return Boolean(this.context instanceof MatMenu || this.context?.menu instanceof MatMenu);
	}

	ngOnInit(): void {
		this.onHammerTap = this.onHammerTap.bind(this);
		this.touched$.subscribe(val => (this._touched = val));
		this.el.classList.add('vh-context-menu-anchor');
		if (this._touched || this.isMobile) {
			// @see task #890554
			this.el.classList.add('disable-select');
		}
	}

	ngAfterViewInit(): void {
		const hasSelection = () => window.getSelection()?.toString();
		this.zone.runOutsideAngular(() => {
			this.getContextClick$()
				.pipe(
					filter(() => !hasSelection()),
					concatMap(context => this.zone.run(() => this.openMenu(context)?.closed || EMPTY)),
					takeUntil(this.destroy$)
				)
				.subscribe();
		});
	}

	ngOnDestroy() {
		if (this.cmpRef) {
			this.appRef.detachView(this.cmpRef.hostView);
			this.cmpRef.destroy();
		}
		if (this.hammerManager) {
			this.hammerManager.off(this.customTapEventName, this.onHammerTap);
			this.hammerManager.destroy();
		}
	}

	triggerContextMenu(event: MouseEvent) {
		this.triggerContextMenu$.next(event);
	}

	openMenu(context: IContextMenuContext) {
		if (!context || !context.menu) return;
		this.currentMouseEvent = context.mouseEvent;
		const cmpRef = this.getOrCreateFixedMenuPanel(context);

		Object.assign(cmpRef.instance, context);
		cmpRef.instance.matMenuTrigger.menuData = context.data;
		cmpRef.instance.matMenuTrigger.menu = context.menu;
		/**@see #882877*/
		const hasBackdrop = typeof context.menu.hasBackdrop === 'boolean' ? context.menu.hasBackdrop : false;
		cmpRef.instance.matMenuTrigger.menu.hasBackdrop = this.isMobile ? true : hasBackdrop;
		cmpRef.changeDetectorRef.markForCheck();

		fromEvent(window, 'close-context-menu')
			.pipe(take(1), takeUntil(merge(this.destroy$, cmpRef.instance.matMenuTrigger.menuClosed)))
			.subscribe(() => {
				cmpRef.instance.matMenuTrigger.closeMenu();
				window.dispatchEvent(new CustomEvent('mat-menu-close'));
			});

		//закрытие по клику на бэграунд
		const openRef$ = cmpRef.instance.matMenuTrigger.menuOpened
			.pipe(
				switchMap(() => {
					return merge(
						fromEvent<MouseEvent>(document, 'click', { capture: true }),
						fromEvent<MouseEvent>(document, 'contextmenu', { capture: true }).pipe(
							tap(e => {
								e.preventDefault();
							})
						)
					).pipe(
						filter((e: MouseEvent) => {
							const el: HTMLElement = e.target as HTMLElement;
							const outsideClick = !el.closest('.mat-menu-panel,.mat-menu-panel--custom');
							if (outsideClick && e?.button === 0) {
								e.stopPropagation();
							}
							return outsideClick;
						}),
						take(1)
					);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				cmpRef.instance.matMenuTrigger.closeMenu();
				window.dispatchEvent(new CustomEvent('mat-menu-close'));
			});

		cmpRef.instance.matMenuTrigger.menuClosed.pipe(take(1)).subscribe(() => {
			openRef$.unsubscribe();
		});

		cmpRef.instance.menu.xPosition = 'before';
		cmpRef.instance.matMenuTrigger.openMenu();
		// fix after upgrade to angular 14
		window.dispatchEvent(new CustomEvent('resize'));

		return {
			opened: cmpRef.instance.matMenuTrigger.menuOpened.pipe(
				map(() => ({
					event: context.mouseEvent,
					data: context.data,
				})),
				take(1),
				takeUntil(this.destroy$)
			),
			closed: cmpRef.instance.matMenuTrigger.menuClosed.pipe(take(1), takeUntil(this.destroy$)),
		};
	}

	protected blurActiveElement() {
		// quickfix, need config
		const activeEl = this.document.activeElement as HTMLElement;
		if (activeEl && activeEl.blur) {
			activeEl.blur();
		}
	}

	protected isFromTargetExclude(e: Event) {
		const target = e.target as HTMLElement;
		if (this.ignoreLinks && target.tagName === 'A') {
			return true;
		}
		if (Array.isArray(this.excludeFromTargetIn) && this.excludeFromTargetIn.length > 0) {
			for (const selector of this.excludeFromTargetIn) {
				const container = this.el.querySelector(selector);
				const contains = container?.contains(target) || target === container;
				if (contains) {
					return true;
				}
			}
		}
		return false;
	}

	protected getContextClick$(): Observable<IContextMenuContext> {
		this.contextClick$ = fromEvent<MouseEvent>(this.el, 'contextmenu').pipe(
			filter(() => {
				return !(this.ignoreWhenSelection && this.hasSelection);
			}),
			filter(e => this.valid && !this.disabled && !this.isFromTargetExclude(e)),
			tap(ev => {
				ev.stopPropagation();
				ev.preventDefault();
			}),
			takeUntil(this.destroy$)
		);
		const click$ = fromEvent<MouseEvent>(this.el, 'click').pipe(
			filter(ev => {
				const target = ev.target as HTMLElement;
				return !(target && target.tagName.toLowerCase() === 'a');
			}),
			takeUntil(this.destroy$)
		);
		const dblclick$ = fromEvent<MouseEvent>(this.el, 'dblclick').pipe(takeUntil(this.destroy$));
		const touchStart$ = fromEvent<MouseEvent>(this.el, 'touchstart', { passive: true }).pipe(takeUntil(this.destroy$));
		const touchEnd$ = fromEvent<MouseEvent>(this.el, 'touchend').pipe(takeUntil(this.destroy$));
		const touchPress$ = touchStart$.pipe(
			filter(() => this.isSafari && !this.isMobile), //To work only on IPad
			switchMap(touchStart =>
				timer(this.contextPressTime).pipe(
					map(() => touchStart),
					takeUntil(touchEnd$)
				)
			),
			takeUntil(this.destroy$)
		);
		const mobileDoubleTaps$ = this.getMobileTouches();

		const actionStream$ = merge(
			this.contextClick$,
			mobileDoubleTaps$,
			click$.pipe(filter(() => this.trapClick)),
			dblclick$.pipe(filter(() => this.trapDblClick)),
			touchPress$
		).pipe(filter(() => !this.triggerManually));

		const stream$ = merge(this.triggerContextMenu$, actionStream$);
		return stream$.pipe(
			filter(e => !this.disabled && !this.isFromTargetExclude(e)),
			map(e => {
				if (!this.context) {
					this.warn('Context is not define! Set context [vhContextMenu]="context" in template');
					return null;
				}
				let menu: MatMenu, data: any, menuLMB: MatMenu, menuRMB: MatMenu;
				if (this.context instanceof MatMenu) {
					menu = this.context as any;
					data = this.contextData;
				} else {
					menu = this.context.menu;
					data = this.context.data || this.contextData;
					menuLMB = this.context.menuLMB;
					menuRMB = this.context.menuRMB;
					if (e.button === 0 && menuLMB) {
						menu = menuLMB;
					} else if (e.button === 2 && menuRMB) {
						menu = menuRMB;
					}
				}
				data = data ?? {};
				Object.assign(data, {
					$event: e,
				});
				const context: IContextMenuContext = {
					mouseEvent: e,
					x: `${Number(e.center?.x || e.clientX || e.pageX)}px`,
					y: `${Number(e.center?.y || e.clientY || e.pageY)}px`,
					menu,
					data,
					menuLMB,
					menuRMB,
				};
				return context;
			})
		);
	}

	protected getMobileTouches() {
		const hammerManager = this.getOrCreateHammerManager();
		if (!this.tapEvent$) {
			this.tapEvent$ = new Subject();
			if (hammerManager) {
				hammerManager.on(`${this.customTapEventName} press`, this.onHammerTap);
			}
		}
		return this.tapEvent$.pipe(takeUntil(this.destroy$));
	}

	protected getOrCreateHammerManager() {
		if (typeof Hammer === 'undefined') {
			return;
		}
		if (!this.hammerManager) {
			// @see http://hammerjs.github.io/require-failure/
			// @see http://hammerjs.github.io/api/
			// @see https://stackoverflow.com/questions/22535088/hammer-js-how-to-handle-set-tap-and-doubletap-on-same-elements#answer-27477573
			this.hammerManager = new Hammer.Manager(this.el, {});
			const plugins: PressRecognizer[] = [];
			// mobile default behavior contextMenu is press, so it might be avoided
			if (this.contextUsePress || this.isMobile) {
				const pressPlugin = new Hammer.Press({ time: this.contextPressTime });
				plugins.push(pressPlugin);
			}
			if (isNumber(this.contextTapCount) && this.contextTapCount >= 2) {
				const tapPlugin = new Hammer.Tap({
					event: this.customTapEventName,
					taps: this.contextTapCount,
				});
				plugins.push(tapPlugin);
			}
			this.hammerManager.add(plugins);
		}
		return this.hammerManager;
	}

	protected onHammerTap(event: any) {
		this.tapEvent$.next(event);
	}

	protected getOrCreateFixedMenuPanel(context: IContextMenuContext) {
		if (!this.cmpRef) {
			this.fixedContainerFactory = this.cfr.resolveComponentFactory(ContextMenuFixedContainerCommonComponent);
			this.cmpRef = this.fixedContainerFactory.create(this.injector);
			this.appRef.attachView(this.cmpRef.hostView);
			const domElem = (this.cmpRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
			this.document.body.appendChild(domElem);
			let opened = false;
			this.cmpRef.instance.matMenuTrigger.menuOpened
				.asObservable()
				.pipe(
					tap(() => {
						opened = true;
						this.document.body.classList.add('context-menu-opened');
						this.vhContextMenuOpened.emit({
							event: this.currentMouseEvent || context.mouseEvent,
							data: context.data,
						});
						const inputContext = this.context as IContextMenuContext;
						if (typeof inputContext.onOpen === 'function') {
							inputContext.onOpen.call(null, context.data);
						}
						this.opened$.next(true);
						if (domElem) {
							domElem.setAttribute('is-open', 'true');
						}
						delayRunInZone(this.zone, () => {
							this.blurActiveElement();
						});
					}),
					takeUntil(this.destroy$)
				)
				.subscribe(() => {
					window.dispatchEvent(new CustomEvent('mat-menu-open'));
				});
			this.cmpRef.instance.matMenuTrigger.menuClosed
				.asObservable()
				.pipe(
					tap(() => {
						opened = false;
						this.document.body.classList.remove('context-menu-opened');
						this.vhContextMenuClosed.emit();
						const inputContext = this.context as IContextMenuContext;
						if (typeof inputContext?.onClosed === 'function') {
							inputContext.onClosed?.call(null);
						}
						this.opened$.next(false);
						if (domElem) {
							domElem.removeAttribute('is-open');
						}
						this.appRef.detachView(this.cmpRef?.hostView);
						this.cmpRef?.destroy();
						this.cmpRef = null;
					}),
					takeUntil(this.destroy$)
				)
				.subscribe(() => {
					window.dispatchEvent(new CustomEvent('mat-menu-closed'));
				});
			if (this.closeOnScroll) {
				merge(
					fromEvent(this.document, 'wheel', { passive: true }),
					fromEvent(this.document, 'touchmove', { passive: true })
				)
					.pipe(takeUntil(this.destroy$))
					.subscribe(() => {
						if (opened) {
							this.cmpRef.instance.matMenuTrigger.closeMenu();
							window.dispatchEvent(new CustomEvent('mat-menu-close'));
							opened = false;
						}
					});
			}
		}
		return this.cmpRef;
	}

	protected log(...data) {
		if (this.logger) {
			this.logger.log(...data);
		}
	}

	protected warn(...data) {
		if (this.logger) {
			this.logger.warn(...data);
		}
	}

	protected error(...data) {
		if (this.logger) {
			this.logger.error(...data);
		}
	}
}
