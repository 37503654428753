import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataHttpCommonService } from '../data-http-common.service';
import { HistoryDataHttpService, IHistoryGetTasksCriteria, IHistoryGetEmployeesCriteria } from './abstract';
import { IGetHistoryTasksDto, IGetHistoryEmployeesDto } from './dto';
import { IApiResponse } from '../api-response';
import { map } from 'rxjs/operators';

@Injectable()
export class HistoryDataHttpServiceImpl implements HistoryDataHttpService {
	constructor(public readonly http: HttpClient, public readonly common: DataHttpCommonService) {}

	getTasks(criteria?: IHistoryGetTasksCriteria): Observable<IGetHistoryTasksDto[]> {
		const url = this.common.getApiUrl(`history/tasks`);
		const defaultCriteria: IHistoryGetTasksCriteria = {
			count: 15,
		};
		const params: Record<string, any> = Object.assign(defaultCriteria, criteria);
		return this.http.get<IApiResponse<IGetHistoryTasksDto[]>>(url, { params }).pipe(map(r => r.data));
	}

	getEmployees(criteria?: IHistoryGetEmployeesCriteria): Observable<IGetHistoryEmployeesDto[]> {
		const url = this.common.getApiUrl(`history/employees`);
		const defaultCriteria: IHistoryGetEmployeesCriteria = {
			coworkersCount: 10,
			groupsCount: 10,
		};
		const params: Record<string, any> = Object.assign(defaultCriteria, criteria);
		return this.http.get<IApiResponse<IGetHistoryEmployeesDto[]>>(url, { params }).pipe(
			map(r => r.data),
			map(arr => {
				if (criteria?.userOnly) {
					return arr.filter(i => !i.isGroup);
				}
				return arr;
			})
		);
	}
}
