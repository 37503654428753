import { booleanFilter } from '@valhalla/utils';
import { BehaviorSubject } from 'rxjs';

export function createButtonIconStateMachine(states: ButtonIconState[], change?: (e: any) => void) {
	const buttons = (states || []).sort((a, b) => a.order - b.order);
	let index = 0;
	const current$ = new BehaviorSubject(buttons[index]);

	return {
		current$: current$.pipe(booleanFilter()),
		get current() {
			return current$.value;
		},
		async action(e: Event) {
			const current = current$.value;
			const result = current.action?.({
				original: e,
				state: current,
			});
			if (result === false) {
				return;
			}
			if (result instanceof Promise) {
				const promiseResult = await result;
				if (promiseResult === false) {
					return;
				}
			}
			index = index === buttons.length - 1 ? 0 : index + 1;
			current$.next(buttons[index]);
			change?.({
				original: e,
				state: current$.value,
			});
		},
	};
}

export interface ButtonIconState {
	id: string | number | Symbol;
	icon: string;
	action: (e?: ButtonIconStateEvent) => void | boolean | Promise<boolean>;
	iconSize?: any;
	iconColor?: any;
	title?: string;
	order?: number;
	data?: any;
}

export interface ButtonIconStateEvent<T extends Event = Event> {
	original: T;
	state: ButtonIconState;
}

export type ButtonIconStateMachine = ReturnType<typeof createButtonIconStateMachine>;
