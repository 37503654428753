<vh-sidebar-search
	[class.active-search]="(isSearchActive$ | async) && showSearch"
	(searchInput)="onSearchInput($event)"
	(searchClear)="onSearchClear()"
	(closeSearch)="setInActiveSearch()"
	[searchFocus]="searchFocus$ | async"
>
</vh-sidebar-search>

<div *ngIf="!(isSearchActive$ | async)" class="vh-sidebar-header-wrapper">
	<button
		*ngIf="showBtnBack"
		(click)="clickBtnBack.emit()"
		[tippy]="tippyBtnBack"
		class="vh-btn vh-btn-icon vh-btn--transparent vh-sidebar-header--back-btn"
	>
		<mat-icon svgIcon="vh-arrow-S-left-24"></mat-icon>
	</button>

	<div class="vh-sidebar-header-title">{{ title }}</div>

	<div *ngIf="showSearch || showBtnAdd" class="vh-sidebar-header-actions">
		<span *ngIf="showSearch" (click)="setActiveSearch()" class="vh-sidebar-header-action">
			<mat-icon svgIcon="vh-search-24"></mat-icon>
		</span>

		<span *ngIf="showBtnAdd" (click)="onAddClick()" class="vh-sidebar-header-action">
			<mat-icon svgIcon="vh-plus-24"></mat-icon>
		</span>
	</div>
</div>
