import { JwtModuleOptions } from '@auth0/angular-jwt';
import { TokenService } from './token';

export function jwtConfigFactory(tokenService: TokenService): JwtModuleOptions['config'] {
	return {
		throwNoTokenError: false,
		tokenGetter: () => tokenService.getToken(),
		authScheme: '',
		// blacklistedRoutes: [/(.*)\/api\/auth\/(.*)/],
		headerName: '1FormaAuth',
	};
}
