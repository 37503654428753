import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'replace',
})
export class ReplacePipe implements PipeTransform {
	transform(value: string, rules: [string, string, boolean?][]): any {
		if (!value) {
			return value;
		}
		rules.forEach(([from, to, all]) => {
			if (typeof all === 'undefined' || all) {
				value = value.replaceAll(from, to);
			} else {
				value = value.replace(from, to);
			}
		});
		return value;
	}
}

@NgModule({
	exports: [ReplacePipe],
	declarations: [ReplacePipe],
})
export class ReplacePipeModule {}
