// tslint:disable:no-empty-interface
import { ICategoryShort, ISubCategories, ISubcategorySettings } from '@valhalla/data/entities';
import { Observable } from 'rxjs';
import { INtfTaskTemplate } from '../task';
import { IDataSourceGridConfigColumn } from '@spa/pages/data-source/data-source-grid/data-source-grid-options';

export abstract class CategoryDataHttpService {
	abstract canChat$: Observable<boolean>;
	abstract selectCatSubcat(settings: ISelectCategoryCriteria): Observable<ICategoryItem[]>;
	abstract storageSubCategory(subcatId: number): Observable<ISubCategories>;
	abstract subcategoriesTree(): Observable<ISubategoriesTree>;
	abstract subcategoryStates(subcatId: number): Observable<IGetSubcategoryStatesResult>;
	abstract smartExpressions(criteria: IDataSourceGetSmartExpressions): Observable<any[]>;
	abstract newTaskTemplate(subcatId: number, sourceTaskId?: number, action?: string): Observable<INtfTaskTemplate>;
	abstract categoryShortInfo(catId: number): Observable<ICategoryShort>;
	abstract updateSubcategorySettings(criteria: ISubcategorySettings, subcatId: number): Observable<any>;
	abstract subcategoryGraph(params: ISubcategoryGraphParams): Observable<ISubcategoryGraph>;
	abstract getHierarchy(params: ISubcategoryHierarchyParams): Observable<ISubcategoryHierarchy>;
	abstract getHierarchyConfig(params: ISubcategoryHierarchyParams): Observable<IDataSourceGridConfigColumn[]>;
}
export interface ISubcategoryGraphParams {
	subcatId: number;
	stateId?: number;
}

export interface IDataSourceGetSmartExpressions {
	subcatId?: number;
}

export interface ISubcategoryHierarchyParams {
	subcatId: number;
	parentTaskId?: number;
	showAll?: boolean;
	filter?: string;
}

export interface ISubcategoryHierarchy {
	tasks: ISubcategoryHierarchyTask[];
}

export interface ISubcategoryHierarchyTask {
	isFolder: boolean;
	isLeaf: boolean;
	parentTaskId?: number;
	taskText: string;
	taskId: number;
	extParams: any;
	parentIds?: number[];
}

export interface IGetSubcategoryStatesResult {
	data: Array<{
		isActive: boolean;
		name: string;
		id: number;
	}>;
	total: number;
	aggregates?: any;
}

export interface ISelectCategoryCriteria {
	criteria: ICriteria;
	displaySettings: IDisplaySettings;
}

export interface IDisplaySettings {
	allowMultipleSelect: boolean;
	readOnly: boolean;
}

export interface ICriteria {
	criteriaType: ICriteriaType;
}

export interface ICategoryItem {
	id: number;
	text: string;
	parentId?: number;
	type: ICategoryType;
	hasChildren?: boolean;
	items?: ICategoryItem[];
	state?: string;
	isExpand?: boolean;
	isHide?: boolean;
	accent?: boolean;
}

export enum ICategoryType {
	category = 'Category',
	subcategory = 'Subcategory',
}

export enum ICriteriaType {
	searchCriteria = 'SearchCriteria',
}

export interface ISubategoriesTreeTasksCounters {
	allTasksCount: number;
	newTasksCount: number;
	overdueTasksCount: number;
	allTasksUserOwns: number;
	allTasksUserPerforms: number;
}

export interface ISubategoriesTreeDatum {
	tasksCounters: ISubategoriesTreeTasksCounters;
	parentId: number;
	nodeType: string;
	isDictionary: boolean;
	canEdit: boolean;
	openType: string;
	portalId: number;
	canCreateTask: boolean;
	name: string;
	id: number;
	isSpace?: boolean;
}

export interface ISubategoriesTreeAggregates {}

export interface ISubategoriesTree {
	data: ISubategoriesTreeDatum[] | any;
	total: number;
	aggregates: ISubategoriesTreeAggregates | null;
}

export interface ISubcategoryGraph {
	steps: ISubcategoryGraphStep[];
	states: ISubcategoryGraphState[];
}

export interface ISubcategoryGraphStep {
	signatures: any[];
	stepId: number;
	stateId: number;
	stateNextId: number;
	isHidden: boolean;
	description: string;
	hasSignatures: boolean;
	hasSubTask: boolean;
	hasExtParamsRestrictions: boolean;
	hasAutoperformer: boolean;
	hasActionsWithExtParams: boolean;
	spName: string;
	smartInfo: string;
	isMainRoute: boolean;
	isActive: boolean;
	isSmartActive: boolean;
}

export interface ISubcategoryGraphState {
	description: string;
	stateId: number;
	closeTask: boolean;
	descriptionWithID: string;
	finishWork: boolean;
}
