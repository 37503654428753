import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, startWith } from 'rxjs/operators';

const dataToken = Symbol('MatSideNav instance data token property instance');

export function getDataStream(nav: MatSidenav): Observable<any> {
	return nav && nav[dataToken] && nav[dataToken].$;
}

@Injectable({
	providedIn: 'root',
})
export class MatSideNavHelperService {
	sideNavInstances: Map<string | number, MatSidenav> = new Map();

	setSidenav(id: string | number, instance: MatSidenav): void {
		const nav = instance as any;
		if (!nav[dataToken]) {
			const subject = new BehaviorSubject({});
			nav[dataToken] = {
				subject: subject,
				$: subject.pipe(shareReplay({ refCount: true, bufferSize: 1 })),
			};
		}
		this.sideNavInstances.set(id, instance);
	}

	getSidenav(id: string | number) {
		return this.sideNavInstances.get(id);
	}

	setSideNavData(id: string | number, data: any) {
		const nav = this.getSidenav(id);
		const navData = nav && nav[dataToken];
		if (navData) {
			const value = navData.subject.value;
			navData.subject.next({
				...value,
				...(typeof data === 'object' ? data : { data }),
			});
		}
	}

	open(id: string | number, params?: any) {
		this.setSideNavData(id, params);
		const nav = this.getSidenav(id);
		return nav && nav.open();
	}

	close(id: string | number) {
		const nav = this.getSidenav(id);
		return nav && nav.close();
	}

	toggle(id: string | number) {
		const nav = this.getSidenav(id);
		nav && nav.toggle();
		return nav && nav.opened;
	}

	opened$(id: string | number) {
		const nav = this.getSidenav(id);

		return nav && nav.openedChange.asObservable().pipe(startWith(nav.opened));
	}
}
