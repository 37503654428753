import { ModuleWithProviders, NgModule } from '@angular/core';
import { provideCron } from './cron';
import { TickersCronService } from './jobs/tickers-job.service';
import { CronNames } from './cron-names';
import { MailboxesCronService } from './jobs/mailboxes-job.service';
import { MeetingEventsCronService } from './jobs/events-job.service';
import { FavoriteMenuCronService } from './jobs/favorites-job.service';
import { ChatTickersCronService } from './jobs/chat-tickers-job.service';

@NgModule()
export class CronModule {
	static forRoot(): ModuleWithProviders<CronModule> {
		return {
			ngModule: CronModule,
			providers: [
				provideCron({
					name: CronNames.tickers,
					type: TickersCronService,
					defaultSettings: {
						interval: 60_000,
						isEnabled: true,
					},
				}),
				provideCron({
					name: CronNames.mailboxes,
					type: MailboxesCronService,
					defaultSettings: {
						interval: 60_000,
						isEnabled: true,
					},
				}),
				provideCron({
					name: CronNames.events,
					type: MeetingEventsCronService,
					defaultSettings: {
						interval: 60_000 * 2,
						isEnabled: true,
					},
				}),
				provideCron({
					name: CronNames.favourites,
					type: FavoriteMenuCronService,
					defaultSettings: {
						interval: 60_000 * 5,
						isEnabled: true,
					},
				}),
				provideCron({
					name: CronNames.chatTickers,
					type: ChatTickersCronService,
					defaultSettings: {
						interval: 60_000,
						isEnabled: true,
					},
				}),
			],
		};
	}
}
