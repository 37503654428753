<div class="vh-common-sidebar-content">
	<div class="vh-common-sidebar-content--search">
		<vh-sidebar-header
			[title]="'common.sideBarBtnReports' | resx"
			(searchInput)="searchHandler($event.target.value)"
			(searchClear)="searchHandler('')"
		>
		</vh-sidebar-header>
	</div>

	<div class="reports-group">
		<ng-container *ngFor="let reportGroup of groupReports$ | async">
			<vh-fv-expand-list
				*ngIf="reportGroup.blockName"
				[label]="reportGroup.blockName"
				[expanded]="reportGroup.expanded || false"
				(expandCollapseEvent)="expandCollapseEvent.emit()"
			>
				<vh-fv-expand-list-item
					*ngFor="let report of reportGroup.reports"
					[class.active]="isItemSelected(report.id)"
					[label]="report.description"
					(click)="onItemClick(report.id, $event)"
					[vhContextMenu]="reportsMenu"
					[vhContextMenuData]="{ $implicit: report }"
				></vh-fv-expand-list-item>
			</vh-fv-expand-list>
		</ng-container>
	</div>

	<div class="reports-root">
		<ng-container *ngFor="let reportGroup of groupReports$ | async">
			<ng-container *ngIf="!reportGroup.blockName">
				<vh-fv-expand-list-item
					*ngFor="let report of reportGroup.reports"
					[class.active]="isItemSelected(report.id)"
					[label]="report.description"
					(click)="onItemClick(report.id, $event)"
					[vhContextMenu]="reportsMenu"
					[vhContextMenuData]="{ $implicit: report }"
				></vh-fv-expand-list-item>
			</ng-container>
		</ng-container>
	</div>
</div>

<mat-menu #reportsMenu="matMenu">
	<ng-template matMenuContent let-report>
		<button *ngIf="!report.linkId" mat-menu-item (click)="addToFavorites(report)">
			<mat-icon svgIcon="vh-favourites-24"></mat-icon>
			<span>{{ 'common.contextMenuAddToFavorites' | resx }}</span>
		</button>

		<button *ngIf="report.linkId" mat-menu-item (click)="removeFromFavorites(report)">
			<mat-icon svgIcon="vh-favourites-filled-24"></mat-icon>
			<span>{{ 'common.chatContextMenuRemoveFromFavorites' | resx }}</span>
		</button>

		<button *ngIf="isAdmin$ | async" mat-menu-item (click)="editReport(report, $event)">
			<mat-icon svgIcon="vh-edit-text-24"></mat-icon>
			<span>{{ 'common.openOnlineEditor' | resx }}</span>
		</button>
	</ng-template>
</mat-menu>
