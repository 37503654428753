<ng-container *ngIf="!menuItem.hidden">
	<!-- menuItem.url -->
	<div
		*ngIf="menuItem.actionType === actionTypes.openUrl"
		class="nav-link"
		(click)="menuItemClickHandler($event, menuItem); prevent($event); menuItemClick.emit(menuItem)"
		[class.active]="isRouterActiveItem()"
		[ngClass]="menuItem.classes"
		[routerLinkActive]="['active', 'accent']"
		[routerLinkActiveOptions]="{ exact: menuItem.exactMatch || false }"
		[vhContextMenu]="{
			menu: contextMenu,
			data: {
				item: menuItem
			}
		}"
		matRipple
	>
		<ng-container *ngTemplateOutlet="itemContent"></ng-container>
	</div>

	<!-- menuItem.externalUrl -->
	<div
		*ngIf="menuItem.actionType === actionTypes.openExternalUrl"
		class="nav-link"
		(click)="menuItemClickHandler($event, menuItem); prevent($event); menuItemClick.emit(menuItem)"
		[ngClass]="menuItem.classes"
		[vhContextMenu]="{
			menu: contextMenu,
			data: {
				item: menuItem
			}
		}"
		matRipple
	>
		<ng-container *ngTemplateOutlet="itemContent"></ng-container>
	</div>

	<!-- no URL -->
	<div
		*ngIf="menuItem.actionType === actionTypes.execFunc"
		class="nav-link"
		(click)="prevent($event); menuItemClick.emit(menuItem)"
		[ngClass]="menuItem.classes"
		[vhContextMenu]="{
			menu: contextMenu,
			data: {
				item: menuItem
			}
		}"
		matRipple
	>
		<ng-container *ngTemplateOutlet="itemContent"></ng-container>
	</div>
</ng-container>

<ng-template #itemContent>
	<span class="nav-link-title" [title]="menuItem.details || ''">{{ menuItem.title }}</span>

	<div *ngIf="menuItem.badges" class="nav__badges">
		<ng-container *ngFor="let badge of orderedBadges(menuItem); trackBy: trackByBadgeValue">
			<vh-badge
				*ngIf="badge.value"
				[color]="getBadgeColor(badge.matBg)"
				[emphasis]="getBadgeEmphasis(badge.matBg)"
				[value]="badge.value"
				[tippy]="badge.title"
				[delay]="400"
			></vh-badge>
		</ng-container>
	</div>
</ng-template>
