import { Provider } from '@angular/core';

import { ApplicationsManagerProvider } from './abstract';
import { ApplicationsManagerProviderImpl } from './manager';

export const NGDI_CONF_APPS_MANAGER: Provider[] = [
	{
		provide: ApplicationsManagerProvider,
		useClass: ApplicationsManagerProviderImpl
	}
];
