import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ISubcatInfo } from '@spa/data/entities';
import { IApiResponse } from '@spa/data/http';
import { ResourceService } from '@spa/localization';
import {
	IAsmxDto,
	ICategoryShort,
	ICategoryStep,
	IComment,
	INewsResponse,
	ISubCategoryShort,
	ITaskInfo,
	ITaskStep,
	ITaskTemplate,
	adjustTask,
} from '@valhalla/data/entities';
import { excludeUndefinedProps } from '@valhalla/utils';
import { EMPTY, Observable, from, of, throwError, zip } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import ApiVersion from '../api-versions';
import { DataHttpCommonService } from '../data-http-common.service';
import type { IEndpointUrlConfig } from '../endpoint.config';
import { EndpointUrlConfig } from '../endpoint.config';
import { FeedTasksType } from '../feeds';
import {
	IAddSubtaskReq,
	IAllStatesInfo,
	ICategoryInfoCriteria,
	ICategoryStepsCriteria,
	ICategoryTreeCriteria,
	ICategoryTreeResult,
	IChangeTaskStatusCriteria,
	ICheckExistAndAccess,
	ICopyMoveTaskResponse,
	ICreateNewDocCriteria,
	ICreateTask,
	ICreateTaskResponse,
	IDeleteAllSubscribers,
	IExecuteSignatureResolutionCriteria,
	IExecuteSignatureResolutionResult,
	IExecuteTaskActionCriteria,
	IExecuteTaskActionResult,
	IExecuteTaskStepCriteria,
	IExecuteTaskStepResult,
	IExecuteTaskStepWithCommentCriteria,
	IExecuteTaskStepWithCommentResult,
	IFileExistanceDto,
	IGanttCategoryCriteria,
	IGanttSubcategoryCriteria,
	IGetAllTaskStatesParams,
	IGetTaskReportsResult,
	ILinkedTaskReq,
	INtfTaskTemplate,
	INtfTaskTemplateCriteria,
	IRequestedSignature,
	ISignaturesRes,
	IStateInfo,
	IStatusesResponse,
	ISubCategoryInfoCriteria,
	ITaskAddToFavoritesCriteria,
	ITaskAllFilesCriteria,
	ITaskChangeDueDate2Criteria,
	ITaskChangeDueDateCriteria,
	ITaskChangeOwnerCriteria,
	ITaskChangePriorityCriteria,
	ITaskChangeSignatureReasonCriteria,
	ITaskChangeSubscribers,
	ITaskClearDeletedFilesCriteria,
	ITaskDeleteFileCriteria,
	ITaskDeleteMultifileExtParams,
	ITaskDisableRecurrenceCriteria,
	ITaskEmailsCriteria,
	ITaskEmailsResponse,
	ITaskFeedsCriteria,
	ITaskFeedsResult,
	ITaskFilesCriteria,
	ITaskFormatExtParams,
	ITaskFullSnapshot,
	ITaskGridBlockDataCriteria,
	ITaskGridBlockDataResult,
	ITaskInfoCriteria,
	ITaskMainRouteResult,
	ITaskNewsCriteria,
	ITaskPinAsChatToUsers,
	ITaskPinCriteria,
	ITaskPinFileCriteria,
	ITaskPinResult,
	ITaskRecurrence,
	ITaskRecurrenceCriteria,
	ITaskReminderItem,
	ITaskRemindersReq,
	ITaskRemindersRes,
	ITaskRemoveFromFavoritesCriteria,
	ITaskReportResult,
	ITaskRouteCriteria,
	ITaskSearchCriteria,
	ITaskSetConfidential,
	ITaskStepsCriteria,
	ITaskSubscribeUserCriteria,
	ITaskUnPinCriteria,
	ITaskUnpinFileCriteria,
	ITaskUpdateExtParams,
	ITaskUpdateFavoritesCriteria,
	ITaskUpdateStartTimeCriteria,
	IUpdateTask,
	IUpdateTaskAvatarCriteria,
	SortType,
	TaskDataHttpService,
	TaskFeedInclude,
	TaskFeedsFilter,
	TaskFeedsFilterOperation,
	TaskFeedsSorter,
	TaskInfoPart,
	ITaskStepHistoryParams,
	ITaskFilesHistoryRes,
	ITaskAttchEmail,
} from './abstract';

@Injectable()
export class TaskDataHttpServiceImpl implements TaskDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService
	) {}

	attachEmail(params: ITaskAttchEmail): Observable<any> {
		const url = this.common.getApiUrl('task/files/attach-email');
		return this.http.post<IApiResponse>(url, params);
	}

	formatExtParams(params: ITaskFormatExtParams): Observable<any> {
		const url = this.common.getApiUrl('extParams/format');
		return this.http.post<IApiResponse>(url, params).pipe(map(r => r.data));
	}

	updateExtParams(arg: ITaskUpdateExtParams | ITaskUpdateExtParams[]): Observable<any> {
		const url = this.common.getApiUrl('extParams/update');

		let req = [arg];

		if (Array.isArray(arg)) {
			req = [...arg];
		}
		return this.http.post<any>(url, req);
	}

	deleteMultifileExtParams(req: ITaskDeleteMultifileExtParams): Observable<any> {
		const url = this.common.getApiUrl('extParams/multifile/remove');
		return this.http.post<any>(url, req);
	}

	feeds(criteria: ITaskFeedsCriteria): Observable<ITaskFeedsResult> {
		const url = this.common.getApiUrl('task/feeds', ApiVersion.v10);

		return this.http.post<ITaskFeedsResult>(url, criteria);
	}

	search(criteria: ITaskSearchCriteria): Observable<ITaskFeedsResult> {
		const url = this.common.getApiUrl('task/feeds', ApiVersion.v10);
		const body: ITaskFeedsCriteria = {
			limit: criteria.take,
			offset: criteria.skip,
			filters: [
				{
					filterType: TaskFeedsFilter.search,
					filterOperation: TaskFeedsFilterOperation.searchOperation,
					filterValues: Array.isArray(criteria.filter) ? criteria.filter : [criteria.filter],
				},
			],
			feedTypes: criteria.feedTypes ? criteria.feedTypes : [FeedTasksType.all],
			extendedSearch: true,
			sorters: [{ sorterType: TaskFeedsSorter.lastCommentDate, sortDir: SortType.desc }],
			include: [TaskFeedInclude.subscribers],
			showClosed: false,
			showLastComment: true,
			subcatId: null,
			catId: null,
			summaryId: null,
		};
		return this.http.post<ITaskFeedsResult>(url, body);
	}

	getInfo(criteria: ITaskInfoCriteria): Observable<ITaskInfo> {
		const url = this.common.getApiUrl(`mobile/tasks/${criteria.taskId}`, ApiVersion.v10);
		return this.http.get<ITaskInfo>(url).pipe(tap(ti => this.adjustTaskInfo(ti)));
	}

	getInfoParts({ taskId, parts }: ITaskInfoCriteria, all?: boolean, background = true): Observable<ITaskInfo> {
		const url = this.common.getApiUrl(`tasks`);
		const allParts = [
			TaskInfoPart.toolbar,
			TaskInfoPart.actions,
			TaskInfoPart.performers,
			TaskInfoPart.subscribers,
			TaskInfoPart.optionalSignatures,
			TaskInfoPart.extParams,
			TaskInfoPart.customTasksUsed,
			TaskInfoPart.mainParams,
			TaskInfoPart.files,
			TaskInfoPart.templates,
		];
		return this.http
			.post<ITaskInfo>(
				url,
				{ taskId, selectedInfoParts: all ? allParts : parts },
				{
					headers: {
						'Background-request': background ? 'true' : 'false',
					},
				}
			)
			.pipe(tap(ti => this.adjustTaskInfo(ti)));
	}

	getNews({ taskId }: ITaskNewsCriteria): Observable<INewsResponse> {
		const url = this.common.getApiUrl(`feeds/task/${taskId}?isCropHtml=false`, ApiVersion.v10);
		return this.http.get<INewsResponse>(url);
	}

	getGanttCategoryData({ categoryId }: IGanttCategoryCriteria): Observable<any> {
		const url = this.common.getEndpointUrl(`/Gantt.aspx/GetData?CategoryId=${categoryId}`);
		return this.http
			.post<IAsmxDto>(url, {
				parameters: { DateStart: null, DateEnd: null, All: null, CategoryId: categoryId },
				type: 5,
			})
			.pipe(map(res => res.d));
	}

	getGanttSubcategoryData({ subcategoryId }: IGanttSubcategoryCriteria): Observable<any> {
		const url = this.common.getApiUrl(`subcategories/${subcategoryId}/gantt`);
		return this.http.get<IApiResponse>(url).pipe(map(res => res.data));
	}

	setLike({ taskId }: ITaskNewsCriteria): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${taskId}/like`, ApiVersion.v10);
		return this.http.post<any>(url, { taskId });
	}

	setUnlike({ taskId }: ITaskNewsCriteria): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${taskId}/unlike`, ApiVersion.v10);
		return this.http.post<any>(url, { taskId });
	}

	getTasks({ subcatId, limit, offset, blockId, showClosed, showRejected, isCropHtml, search }) {
		const url = this.common.getApiUrl(`feeds/tasks/${subcatId}`, ApiVersion.v10);

		return this.http.get<any>(url, {
			params: excludeUndefinedProps({
				limit,
				offset,
				blockId,
				showClosed,
				showRejected,
				isCropHtml,
				search: search ? search : undefined,
			}),
		});
	}

	getTaskRecurrence({ taskId }: ITaskRecurrenceCriteria): Observable<ITaskRecurrence> {
		const url = this.common.getApiUrl(`tasks/${taskId}/recurrence`, ApiVersion.v12);
		return this.http.get<ITaskRecurrence>(url);
	}

	disableTaskRecurrence({ taskId }: ITaskDisableRecurrenceCriteria): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${taskId}/recurrence/delete`, ApiVersion.v12);
		return this.http.post<any>(url, { taskId });
	}

	saveTaskRecurrence(data: ITaskRecurrence): Observable<any> {
		const url = this.common.getApiUrl(`tasks/recurrence`, ApiVersion.v12);
		return this.http.post<any>(url, data);
	}

	findNewsTemplate(taskTemplates: ITaskTemplate[]): ITaskTemplate {
		if (!taskTemplates?.length) {
			return;
		}

		const isNewsTemplate = (template: ITaskTemplate) =>
			template && template?.typeId === 'static' && template?.name?.toLowerCase().trim() === 'новости';

		return taskTemplates.find(t => isNewsTemplate(t));
	}

	getColorFromVar(cssVar: string): string {
		const cssVars = {
			'var(--extramarkers-blue)': '#1b6cf8',
			'var(--extramarkers-purple)': '#9539e5',
			'var(--extramarkers-green)': '#21a04b',
			'var(--extramarkers-cyan)': '#0094ab',
			'var(--extramarkers-yellow)': '#997706',
			'var(--extramarkers-red)': '#f31b2a',
		};
		return cssVars[cssVar] || '#FFFFFE';
	}

	addToFavorites({ taskId, color, favsFolderId }: ITaskAddToFavoritesCriteria): Observable<any> {
		const url = this.common.getApiUrl('/tasks/set-color');
		return this.http.post<any>(url, {
			taskId: taskId,
			color: this.getColorFromVar(color),
			favsFolderId: favsFolderId || 1,
		});
	}

	removeFromFavorites({ taskId }: ITaskRemoveFromFavoritesCriteria): Observable<any> {
		const url = this.common.getApiUrl('/tasks/set-color');
		return this.http.post(url, { taskId: taskId, color: '#FFFFFF', favsFolderId: null });
	}

	updateFavorites({ taskId, color, favsFolderIds }: ITaskUpdateFavoritesCriteria): Observable<any> {
		const url = this.common.getApiUrl('/tasks/set-color-favorites');
		return this.http.post(url, {
			taskId: taskId,
			color: this.getColorFromVar(color),
			favsFolderIds: favsFolderIds,
		});
	}

	/**@deprecated use changeSubscribers used in the old version of mtf*/
	unsubscribeFromTask(criteria: ITaskInfoCriteria): Observable<any> {
		return this.http.post<any>('/TCWeb.asmx/UnsubscribeFromTask', criteria);
	}

	/**@deprecated use changeSubscribers*/
	unsubscribeFromTask2(taskId: number): Observable<any> {
		const url = this.common.getApiUrl(`tasks/unsubscribe/${taskId}`);
		return this.http.post(url, null);
	}

	getRoute(criteria: ITaskRouteCriteria): Observable<ITaskMainRouteResult> {
		const url = this.common.getApiUrl(`tasks/${criteria.taskId}/mainroute`, ApiVersion.v10);
		return this.http.get<ITaskMainRouteResult>(url);
	}

	getSteps(criteria: ITaskStepsCriteria): Observable<ITaskStep[]> {
		const url = this.common.getApiUrl(`tasks/${criteria.taskId}/steps`, ApiVersion.v10);
		return this.http.get<ITaskStep[]>(url);
	}

	getCategorySteps(criteria: ICategoryStepsCriteria): Observable<ICategoryStep> {
		const url = this.common.getApiUrl(`subcategories/${criteria.subcatId}/steps`, ApiVersion.v10);
		return this.http.get<ICategoryStep>(url);
	}

	getGridBlockData(criteria: ITaskGridBlockDataCriteria): Observable<ITaskGridBlockDataResult> {
		const url = this.common.getApiUrl(`tasks/feeds/usedinblock/${criteria.blockId}/${criteria.taskId}`, ApiVersion.v10);
		return this.http.get<ITaskGridBlockDataResult>(url).pipe(
			map(result => {
				// if no content - return safe object
				result = result || {
					categories: [],
					tabs: [],
					tasks: [],
					templates: [],
				};
				return result;
			})
		);
	}

	executeStep(criteria: IExecuteTaskStepCriteria): Observable<IExecuteTaskStepResult> {
		const url = this.common.getApiUrl(`task/step/${criteria.taskId}`, ApiVersion.v10);
		return this.http.post<IExecuteTaskStepResult>(url, {
			stepId: criteria.stepId,
			comment: criteria.comment || null,
			userConfirmedAction: criteria?.userConfirmedAction,
		});
	}

	executeStepWithComment(
		taskId: number,
		criteria: IExecuteTaskStepWithCommentCriteria
	): Observable<IExecuteTaskStepWithCommentResult> {
		const url = this.common.getApiUrl(`tasks/${taskId}/change-step`);
		return this.http.post<IExecuteTaskStepWithCommentResult>(url, criteria);
	}

	executeAction({
		actionId,
		taskId,
		comment,
		signatures,
	}: IExecuteTaskActionCriteria): Observable<IExecuteTaskActionResult> {
		const url = this.common.getApiUrl(`mobile/tasks/${taskId}/actions/${actionId}`, ApiVersion.v11);
		return this.http
			.post<IExecuteTaskActionResult>(url, {
				comment: comment,
				signatures: signatures,
			})
			.pipe(
				map(response => this.common.normalizeResponseDto(response)),
				tap(res => {
					if (typeof res.redirectResult === 'object') {
						res.redirectResult = this.common.normalizeResponseDto(res.redirectResult);
					}
				}),
				catchError(err => {
					const error = err.error;
					if (error && error.Message) {
						const data: IExecuteTaskActionResult = {
							success: error.Success,
							message: error.Message,
							redirectResult: error.RedirectResult,
						};
						return of(data);
					}
					return throwError(() => err);
				})
			);
	}

	executeSignatureResolution(
		criteria: IExecuteSignatureResolutionCriteria
	): Observable<IExecuteSignatureResolutionResult> {
		const { signatureId, signatureAction, params } = criteria;

		const url = this.common.getApiUrl(`tasks/signatures/${signatureId}/${signatureAction}`);

		return this.http.post<IApiResponse<IExecuteSignatureResolutionResult>>(url, params || {}).pipe(map(r => r.data));
	}

	getCategoryShortInfo(criteria: ICategoryInfoCriteria): Observable<ICategoryShort> {
		const url = this.common.getApiUrl(`categories/${criteria.categoryId}/short`, ApiVersion.v10);
		return this.http.get<ICategoryShort>(url);
	}
	getSubCategoryShortInfo(criteria: ISubCategoryInfoCriteria): Observable<ISubCategoryShort> {
		const url = this.common.getApiUrl(`subcategories/${criteria.subCategoryId}/short`, ApiVersion.v10);
		return this.http.get<ISubCategoryShort>(url);
	}

	getSubCategoryInfo(criteria: ISubCategoryInfoCriteria): Observable<ISubcatInfo> {
		const url = this.common.getApiUrl(`storage/subcategories/${criteria.subCategoryId}`);
		return this.http.get<ISubcatInfo>(url);
	}

	getSubcategoryTemplate(criteria: INtfTaskTemplateCriteria): Observable<INtfTaskTemplate> {
		const url = this.common.getApiUrl(`tasks/template`, ApiVersion.v10);
		const body = criteria;
		return this.http.post<INtfTaskTemplate>(url, body);
	}

	getCategoryTree(criteria: ICategoryTreeCriteria): Observable<ICategoryTreeResult> {
		return this.getCategoryTreeRecordsRecursive(criteria, {}).pipe(
			map(records => ({
				records: records,
			}))
		);
	}

	/**@deprecated use changeSubscribers*/
	subscribeUsers({ taskId, userIds }: ITaskSubscribeUserCriteria): Observable<any> {
		const urls = userIds.map(id => this.common.getApiUrl(`task/${taskId}/subscribe/user/${id}`, ApiVersion.v12));
		const requests$ = urls.map(url => this.http.post(url, null));
		return zip(...requests$);
	}

	/**@deprecated use changeSubscribers*/
	unsubscribeUsers({ taskId, userIds }: ITaskSubscribeUserCriteria): Observable<any> {
		const urls = userIds.map(id => this.common.getApiUrl(`task/${taskId}/unsubscribe/user/${id}`, ApiVersion.v12));
		const requests$ = urls.map(url => this.http.post(url, null));

		return zip(...requests$);
	}

	changeSubscribers(params: ITaskChangeSubscribers): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${params.taskId}/change-subscribers`);
		return this.http.post(url, params.change);
	}

	deleteAllSubscribers(params: IDeleteAllSubscribers): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${params.taskId}/remove-all-subscribers`);
		return this.http.post(url, {});
	}

	pinAsChatToUsers(params: ITaskPinAsChatToUsers): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${params?.taskId}/pin-as-chat-to-users`);
		return this.http.post(url, params?.userIds);
	}

	pinAsChatToAllUsers(taskId: number): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${taskId}/pin-as-chat-to-all-users`);
		return this.http.post(url, {});
	}

	protected adjustTaskInfo(ti: ITaskInfo): ITaskInfo {
		if (Array.isArray(ti.comments)) {
			ti.comments.forEach(this.adjustComment);
		}
		adjustTask(ti);
		return ti;
	}

	protected adjustComment(comment: Partial<IComment>): Partial<IComment> {
		comment.content = comment.text;
		comment.userId = comment.userId || (comment.sender && comment.sender.userId);
		comment.userName = comment.userName || (comment.sender && comment.sender.userName);
		comment.commentId = comment.id;
		comment.replyComment = comment.replyComment || comment.inReplyToComment;

		return comment;
	}

	protected getCategoryTreeRecordsRecursive(
		criteria: ICategoryTreeCriteria,
		records: Record<number, any>
	): Observable<Record<number, any>> {
		// later add from task id
		return of(criteria).pipe(
			mergeMap(({ subCategoryId, categoryId }) => {
				if (subCategoryId) {
					return this.getSubCategoryShortInfo({ subCategoryId: subCategoryId });
				}
				if (categoryId) {
					return this.getCategoryShortInfo({ categoryId: categoryId });
				}
				return throwError('invalid params for getCategoryTreeRecordsRecursive, subCategoryId, categoryId are empty');
			}),
			mergeMap(data => {
				records[data.id] = data;
				if (data.parentCategoryId) {
					return this.getCategoryTreeRecordsRecursive({ categoryId: data.parentCategoryId }, records);
				}
				return of(records);
			})
		);
	}

	getStatuses(taskId: number): Observable<IStatusesResponse> {
		const url = this.common.getApiUrl(`tasks/${taskId}/states`);
		return this.http.get<IApiResponse<IStatusesResponse>>(url).pipe(map(res => res.data));
	}

	changeTaskStatus({ taskId, newStateId }: IChangeTaskStatusCriteria): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${taskId}/changeState`);
		return this.http.post(url, { newStateId });
	}

	pin({ taskId }: ITaskPinCriteria): Observable<ITaskPinResult> {
		const url = this.common.getApiUrl(`mobile/tasks/${taskId}/pin`, ApiVersion.v12);
		return this.http.post(url, undefined);
	}

	unpin({ taskId }: ITaskUnPinCriteria): Observable<ITaskPinResult> {
		const url = this.common.getApiUrl(`mobile/tasks/${taskId}/unpin`, ApiVersion.v12);
		return this.http.post(url, undefined);
	}

	lockDueDate(taskId: number): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${taskId}/lock-due-date`);
		return this.http.post(url, undefined);
	}

	unlockDueDate(taskId: number): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${taskId}/unlock-due-date`);
		return this.http.post(url, undefined);
	}

	assignExecutor(taskId: number, body: { add: number[]; remove: number[] }): Observable<void> {
		return this.http.post<void>(this.common.getApiUrl(`tasks/${taskId}/change-performers`), body);
	}

	assignResponsible(taskId: number, performerId: number): Observable<void> {
		return this.http.post<void>(
			this.common.getApiUrl(`tasks/${taskId}/performers/${performerId}/isResponsible`, ApiVersion.v10),
			null
		);
	}

	changeOwner({ taskId, newOwnerId }: ITaskChangeOwnerCriteria): Observable<any> {
		const url = this.common.getApiUrl(`tasks/change-owner`);
		return this.http.post(url, { taskId, newOwnerId });
	}

	changePriority({ taskId, priority }: ITaskChangePriorityCriteria): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${taskId}/set-priority`);
		return this.http.post(url, { priority });
	}

	changeDueDate(criteria: ITaskChangeDueDateCriteria): Observable<any> {
		const url = this.common.getApiUrl(`tasks/duedate`, ApiVersion.v12);
		return this.http.post(url, criteria);
	}

	changeDueDate2({ taskId, newDate, reason, аddToQuickReply }: ITaskChangeDueDate2Criteria): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${taskId}/change-duedate`);
		return this.http.post(url, {
			newDate,
			reason,
			аddToQuickReply,
		});
	}

	changeSignatureReason(criteria: ITaskChangeSignatureReasonCriteria): Observable<any> {
		const url = this.common.getApiUrl(`tasks/signatures/${criteria?.taskSignatureId}/change-reason`);
		return this.http.post(url, {
			reason: criteria?.reason,
			location: criteria?.location,
		});
	}

	updateTaskStartTime(criteria: ITaskUpdateStartTimeCriteria): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${criteria.taskId}/change-start-time`);
		return this.http.post(url, {
			startTime: criteria?.newStartTime,
			reason: criteria?.reason,
		});
	}

	getFiles(criteria: ITaskFilesCriteria): Observable<any> {
		const url = this.common.getApiUrl(`task/files`);
		return this.http.post<IApiResponse<any>>(url, criteria).pipe(map(res => res.data));
	}

	getAllFiles(taskId: number, params: ITaskAllFilesCriteria): Observable<any> {
		const url = this.common.getApiUrl(`task/files/${taskId}/zip`);

		return this.http.get<any>(url, {
			params: {
				...params,
			},
		});
	}

	clearDeletedFiles(criteria: ITaskClearDeletedFilesCriteria): Observable<any> {
		const url = this.common.getApiUrl(`task/files/clear-deleted-files/${criteria.taskId}`);
		return this.http.post<IApiResponse<any>>(url, criteria).pipe(map(res => res));
	}

	pinFile(criteria: ITaskPinFileCriteria): Observable<any> {
		const url = this.common.getApiUrl(`task/files/pin/${criteria.taskId}/${criteria.fileId}`);
		return this.http.post<IApiResponse<any>>(url, criteria);
	}

	unpinFile(criteria: ITaskUnpinFileCriteria): Observable<any> {
		const url = this.common.getApiUrl(`task/files/unpin/${criteria.taskId}/${criteria.fileId}`);
		return this.http.post<IApiResponse<any>>(url, criteria);
	}

	deleteFile(criteria: ITaskDeleteFileCriteria): Observable<any> {
		const url = this.common.getApiUrl(
			`filestorage/${criteria.parentFolderType}/${criteria.parentFolderId}/file/${criteria.fileId}/delete`,
			ApiVersion.v12
		);
		return this.http.post(url, criteria);
	}

	deleteTask(criteria: number[]): Observable<any> {
		const url = this.common.getApiUrl(`tasks/delete`);
		return this.http.post<IApiResponse<any>>(url, criteria);
	}

	getUnreadNews(): Observable<any> {
		const url = this.common.getApiUrl(`leadin/contentId`, ApiVersion.v10);
		return this.http.post<IApiResponse<number>>(url, null);
	}

	getUnreadNewsContent(criteria: number): Observable<any> {
		const url = this.common.getApiUrl(`leadin/content/${criteria}`, ApiVersion.v10);
		return this.http.post<IApiResponse<any>>(url, null);
	}

	closeUnreadNewsContent(criteria: number): Observable<any> {
		const url = this.common.getApiUrl(`leadin/close/${criteria}`, ApiVersion.v10);
		return this.http.post<IApiResponse<any>>(url, null);
	}

	copyTask(taskId: number, req: ICreateTask): Observable<ICopyMoveTaskResponse> {
		const url = this.common.getApiUrl(`tasks/${taskId}/copy`, ApiVersion.v11);
		return this.http.post<ICopyMoveTaskResponse>(url, req);
	}

	moveTask(taskId: number, req: ICreateTask): Observable<ICopyMoveTaskResponse> {
		const url = this.common.getApiUrl(`tasks/${taskId}/move`, ApiVersion.v11);
		return this.http.post<ICopyMoveTaskResponse>(url, req);
	}

	createTask(criteria: ICreateTask): Observable<ICreateTaskResponse> {
		const url = this.common.getApiUrl(`tasks`, ApiVersion.v11);
		return this.http.post<ICreateTaskResponse>(url, criteria);
	}

	updateTask(criteria: IUpdateTask): Observable<any> {
		const url = this.common.getApiUrl(`tasks/params/update`);
		return this.http.post<IApiResponse<any>>(url, criteria);
	}

	createNewDoc(criteria: ICreateNewDocCriteria): Observable<any> {
		const url = this.common.getApiUrl(`tasks/${criteria.taskId}/new-doc/${criteria.docType}`);
		return this.http.post<IApiResponse<any>>(url, null);
	}

	encryptTask(taskId: number) {
		const url = this.common.getApiUrl(`tasks/${taskId}/encrypt`);
		return this.http.post<IApiResponse<any>>(url, null);
	}

	setConfidentialTask({ taskId, isConfidential }: ITaskSetConfidential) {
		const url = this.common.getApiUrl(`tasks/${taskId}/set-confidential/${isConfidential}`);
		return this.http.post<IApiResponse<any>>(url, null);
	}

	getReports(taskId: number): Observable<IGetTaskReportsResult> {
		const url = this.common.getApiUrl(`mobile/containers/task/ReportsList/${taskId}`, ApiVersion.v12);
		return this.http.post<any>(url, null).pipe(
			map(response => {
				const template = response?.data?.find(t => t.id === 'ReportsList');
				const reportsRecords: ITaskReportResult[] =
					template?.blocks?.find(b => b.action === 'ReportsList')?.data?.reports || [];
				const reports = reportsRecords
					.sort((a, b) => a.position - b.position)
					.map(report => ({ id: Number(report.id), name: String(report.description) }));
				const result: IGetTaskReportsResult = {
					reports,
				};
				return result;
			})
		);
	}

	checkExistAndAccess(taskId: number): Observable<ICheckExistAndAccess> {
		if (!taskId) {
			return EMPTY;
		}
		const url = this.common.getApiUrl(`tasks/check-exist-and-access/${taskId}?withPerformers=true`);
		const injector = this.common.injector;
		return this.http.get<IApiResponse<ICheckExistAndAccess>>(url).pipe(
			map(res => res.data),
			switchMap(access => {
				if (!access?.isUserHasAccess) {
					const resourceService = injector.get(ResourceService);
					return resourceService.resolveCurrentResource('common').pipe(
						take(1),
						map(resx => {
							if (access?.isTaskExists) {
								const taskInfo = access.taskShortInfo;
								let taskUsers = [taskInfo.owner];
								if (taskInfo?.performers?.length) {
									const performers = taskInfo.performers.filter(u => u.userId !== taskInfo.owner.userId);
									taskUsers = [...taskUsers, ...performers];
								}
								const userNames = taskUsers.reduce((prev, cur, i) => {
									return prev + (i !== 0 ? ', ' : '') + cur.displayName;
								}, '');

								access.errorMessage = `${resx['noAccessToTheTask']}. ${resx['accessDeniedMessage2']}: ${userNames}`;
							} else {
								access.errorMessage = resx['taskDoesntExist'];
							}

							return access;
						})
					);
				}

				return of(access);
			})
		);
	}

	getEmails(criteria: ITaskEmailsCriteria): Observable<ITaskEmailsResponse[]> {
		const url = this.common.getApiUrl(`task/emails`);
		return this.http.post<IApiResponse<ITaskEmailsResponse[]>>(url, criteria).pipe(map(res => res.data));
	}

	updateTaskAvatar({ taskId, fileName, base64File }: IUpdateTaskAvatarCriteria): Observable<any> {
		const url = this.common.getApiUrl(`task/avatar/${taskId}`);
		return this.http.post<any>(url, { fileName, base64File });
	}

	removeTaskAvatar({ taskId }: Pick<IUpdateTaskAvatarCriteria, 'taskId'>): Observable<any> {
		const url = this.common.getApiUrl(`task/avatar/${taskId}/delete`);
		return this.http.post<any>(url, undefined);
	}

	getTaskAvatar(taskId: number): Observable<any> {
		const url = this.getTaskAvatarUrl(taskId);
		return from(fetch(url).then(r => r.blob()));
	}

	getTaskAvatarUrl(taskId: number): string {
		const url = this.common.getApiUrl(`task/avatar/${taskId}`);
		return url;
	}

	getSignatureHistory(taskId: number, request: any): Observable<IRequestedSignature[]> {
		const url = this.common.getApiUrl(
			`tasks/Signature/history/${taskId}?hideDeletedSignatures=${request.hideClosed}&showFinalOnly=${request.showFinalOnly}`,
			ApiVersion.v10
		);
		return this.http.get<IApiResponse<IRequestedSignature[]>>(url).pipe(map(res => res.data));
	}

	getTaskSignatureSnapshot(taskSignatureId: number): Observable<ITaskFullSnapshot> {
		const url = this.common.getApiUrl(`tasks/snapshot/${taskSignatureId}`);
		return this.http.get<IApiResponse<ITaskFullSnapshot>>(url).pipe(map(res => res.data));
	}

	getSignatures(): Observable<ISignaturesRes> {
		const url = this.common.getEndpointUrl(`/iosclientservices/DataService.asmx/GetSignatureRequests`);
		return this.http.post<any>(url, {}).pipe(
			map(res => {
				return res?.d;
			})
		);
	}

	addSubtask(taskId: number, req: IAddSubtaskReq) {
		const url = this.common.getApiUrl(`tasks/${taskId}/add-subtask`);
		return this.http.post<any>(url, req);
	}

	addLinked(taskId: number, req: ILinkedTaskReq) {
		const url = this.common.getApiUrl(`tasks/${taskId}/add-task-link`);
		return this.http.post<any>(url, req);
	}

	changeText(taskId: number, text: string) {
		const url = this.common.getApiUrl(`tasks/${taskId}/changeText`);
		return this.http.post<any>(url, {
			value: text,
		});
	}

	getAllStates(params?: IGetAllTaskStatesParams): Observable<IStateInfo[]> {
		const qp: HttpParams = Object.assign(
			{
				skip: 0,
				take: 100000,
			},
			params || {}
		) as any;
		const url = this.common.getApiUrl('storage/taskstates');
		return this.http.get<IAllStatesInfo>(url, { params: qp }).pipe(map(res => res.data));
	}

	getExistanceFileByName(criteria: { taskId: number; fileNames: string[] }): Observable<IFileExistanceDto[]> {
		const url = this.common.getApiUrl(`task/files/existance`);
		return this.http.post<IApiResponse<any>>(url, criteria).pipe(map(res => res.data));
	}

	addToHistory(taskId: number): Observable<any> {
		const url = this.common.getApiUrl(`history/task`);
		return this.http.post<any>(url, { taskId }).pipe(catchError(() => of(false)));
	}

	taskFilesHistory(taskId: number, extParamId: number): Observable<ITaskFilesHistoryRes[]> {
		const url = this.common.getApiUrl(`task/files/history`);
		return this.http
			.post<IApiResponse<any>>(url, {
				taskId,
				extParamId,
			})
			.pipe(map(res => res.data));
	}

	getTasksReminders(req: ITaskRemindersReq): Observable<ITaskRemindersRes> {
		const url = this.common.getApiUrl(`tasks/reminders`);
		return this.http.post<IApiResponse<ITaskRemindersRes>>(url, req).pipe(map(res => res.data));
	}

	setTasksReminder(req: ITaskReminderItem[]): Observable<any> {
		const url = this.common.getApiUrl(`tasks/reminder`, ApiVersion.v12);
		return this.http.post<any>(url, req);
	}

	updateTasksReminder(req: ITaskReminderItem[]): Observable<any> {
		const url = this.common.getApiUrl(`tasks/reminder/update`, ApiVersion.v12);
		return this.http.post<any>(url, req);
	}

	deleteTasksReminder(req: number[]): Observable<any> {
		const url = this.common.getApiUrl(`tasks/reminder/delete`, ApiVersion.v12);
		return this.http.post<any>(url, req);
	}

	stepHistory(req: ITaskStepHistoryParams): Observable<any> {
		// const url = this.common.getApiUrl(`publications/action/stepHistory`, ApiVersion.v12);
		const url = this.common.getApiUrl(`tasks/${req.taskId}/stephistory`);
		return this.http.post<any>(url, req).pipe(map(r => r.data));
	}
}
