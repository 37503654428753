import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IApiResponse } from '../api-response';
import { DataHttpCommonService } from '../data-http-common.service';
import type { IEndpointUrlConfig } from '../endpoint.config';
import { EndpointUrlConfig } from '../endpoint.config';
import { ISystemInformation, SystemDataHttpService } from './abstract';

@Injectable()
export class SystemDataHttpServiceImpl implements SystemDataHttpService {
	constructor(
		@Inject(EndpointUrlConfig) public readonly config: IEndpointUrlConfig,
		public readonly http: HttpClient,
		public readonly common: DataHttpCommonService
	) {}

	getInfo(): Observable<ISystemInformation> {
		const url = this.common.getApiUrl(`system/info`);
		return this.http.get<IApiResponse<ISystemInformation>>(url).pipe(
			map(result => result.data),
			catchError(err => {
				console.error(err);
				return of(null);
			})
		);
	}

	restartApplicationPool() {
		const url = this.common.getApiUrl(`system/restart-application-pool`);
		return this.http.post(url, {});
	}

	denormalizeAllSubcats(): Observable<any> {
		const body = {
			updateStatistics: true,
			refreshExistingData: true,
			syncTableExtParams: true,
		};
		const url = this.common.getApiUrl(`system/denormalize-subcats/all`);
		return this.http.post(url, body);
	}

	denormalizeSubcats(subcats: number[]): Observable<any> {
		const body = {
			subcatIds: subcats,
			updateStatistics: true,
			refreshExistingData: true,
			syncTableExtParams: true,
		};
		const url = this.common.getApiUrl(`system/denormalize-subcats`);
		return this.http.post(url, body);
	}

	clearLocalCaches(): Observable<any> {
		const url = this.common.getApiUrl(`system/clear-local-caches`);
		return this.http.post(url, {});
	}
}
