import * as timeago from 'timeago.js';
import ruLocale from 'timeago.js/lib/lang/ru.js';

timeago.register('ru-RU', ruLocale);

/** @see https://timeago.org/ */
export default timeago;

export {
	/** @see https://timeago.org/ */
	timeago
};
