import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { DataHttpService } from '@valhalla/data/http';
import { SessionUser } from '@valhalla/core';
import { map, take } from 'rxjs';

@Component({
	selector: 'vh-modal-base',
	templateUrl: './modal-base.component.html',
	styleUrls: ['./modal-base.component.scss'],
})
export class ModalBaseComponent implements OnInit {
	constructor(readonly server: DataHttpService, readonly sessionUser: SessionUser) {}

	@Input()
	title: string;

	@Input()
	showClose = false;

	@Input()
	showOpenInNewTab = false;

	@Input()
	showToolbar = true;

	@Input()
	showOk = true;

	@Input()
	okDisabled = false;

	@Input()
	showCancel = true;

	@Input()
	cancelResx = 'common.cancel';

	@Input()
	okResx = 'common.ok';

	@Input()
	cancelTitle: string;

	@Input()
	okTitle: string;

	@Input()
	okAutofocus = false;

	@Input()
	keydownEsc = true;

	@Input()
	keydownEnter = true;

	@Input()
	cancelClass = '';

	@Input()
	okClass = '';

	@Output()
	ok = new EventEmitter();

	@Output()
	close = new EventEmitter();

	@Output()
	openInNewTab = new EventEmitter();

	@Output()
	cancel = new EventEmitter();

	@HostBinding('class.vh-modal-base')
	hostClassSelector = true;

	@HostListener('document:keydown.escape')
	closeEmit() {
		if (!this.keydownEsc) {
			return;
		}

		this.close.emit();
	}

	@HostListener('document:keydown', ['$event'])
	okEmit($event) {
		if (
			(($event.ctrlKey || $event.metaKey) && $event.key === 'Enter' && !this.sendCommentByEnter) ||
			(!($event.ctrlKey || $event.metaKey) && $event.key === 'Enter' && this.sendCommentByEnter)
		) {
			$event.stopPropagation();
			if (!this.keydownEnter) {
				return;
			}
			this.ok.emit();
		}
	}

	sendCommentByEnter = false;

	ngOnInit() {
		this.server.users.sessionUserSettings$
			.pipe(
				map(data => data.sendCommentByEnter),
				take(1)
			)
			.subscribe(sendCommentByEnter => {
				this.sendCommentByEnter = sendCommentByEnter;
			});
	}
}
