import { Inject, Injectable } from '@angular/core';
import { IRxStore } from '@valhalla/core';
import { Observable } from 'rxjs';

import { STORE_FACADE_LAYOUT } from './ng-tokens';
import { ILayoutState } from './state';
import * as actions from './actions';

export abstract class LayoutFacade {
	abstract readonly state$: Observable<ILayoutState>;
	abstract readonly state: ILayoutState;

	abstract update(layout: Partial<ILayoutState>);
}

@Injectable()
export class LayoutFacadeImpl implements LayoutFacade {
	constructor(@Inject(STORE_FACADE_LAYOUT) protected readonly store: IRxStore<ILayoutState>) {}

	readonly state$: Observable<ILayoutState> = this.store.select(state => state);

	get state() {
		return this.store.getState<ILayoutState>();
	}

	update(layout: Partial<ILayoutState>) {
		if (!layout) {
			return;
		}
		this.store.dispatch(actions.updateLayoutActionCreator(layout));
	}
}
