import { Directive, ElementRef, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { LocalStorageProvider, SessionUser, ViewDestroyStreamService } from '@valhalla/core';
import { IUserShort, isUserOnline, IAbsence, getActualAbsence } from '@valhalla/data/entities';
import { booleanFilter, removeDuplicate } from '@valhalla/utils';
import { BehaviorSubject, interval, timer } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { IRecipientType, IRecipient } from '@spa/data/http';
import { ISignalMessage, IUserIsOnlineNotifyData, SignalrProvider } from '@spa/data/signalr';

@Directive({
	selector: '[vhUserStatus]',
	providers: [ViewDestroyStreamService],
})
export class UserStatusDirective implements OnInit, OnDestroy {
	constructor(
		protected readonly elRef: ElementRef<HTMLElement>,
		protected readonly destroy$: ViewDestroyStreamService,
		protected readonly sessionUser: SessionUser
	) {
		UserStatusDirective.setSignal(this.signal);
	}

	static sessionUserProfileRequested = false;
	static timeZoneOffset: string;
	static readonly timeZoneOffsetKey = 'userTimeZoneOffset';
	static refs = new Set<UserStatusDirective>();
	static userCacheSignalData: Record<number, Partial<IUserShort>> = {};
	static signal: SignalrProvider;

	static {
		interval(10_000).subscribe(() => {
			for (const ref of UserStatusDirective.refs) {
				if (ref.userId !== ref.sessionUser.userId) {
					requestAnimationFrame(() => {
						ref.setupStyles(ref.user);
					});
				}
			}
		});
	}

	static setSignal(signal: SignalrProvider) {
		if (!UserStatusDirective.signal) {
			UserStatusDirective.signal = signal;
			// for cache updates
			signal.signal$
				.pipe(filter(s => s.name === this.signal.events.userIsOnline))
				.subscribe((s: ISignalMessage<IUserIsOnlineNotifyData>) => {
					const userId = s.data?.user?.userId;
					const inCache = UserStatusDirective.userCacheSignalData[userId];
					const userUpdate = {
						...(inCache || {}),
						...s.data.user,
					};
					UserStatusDirective.userCacheSignalData[userId] = userUpdate;
				});
		}
	}

	@Input('vhUserStatus')
	set user(val: Partial<IUserShort> | IRecipient) {
		const id = (val as IUserShort)?.userId || val?.id;
		if (this.isGroup(val) || !this.getCache(id)) {
			this.user$.next(val);
		} else {
			// check for cache may update from signal
			const cacheUser = this.getCache(id);
			const tCache = new Date(cacheUser.lastOnlineTime).getTime();
			const tCurrent = new Date((val as IUserShort).lastOnlineTime).getTime();
			if (tCache > tCurrent) {
				this.user$.next(cacheUser);
			} else {
				this.user$.next(val);
			}
		}
	}
	get user() {
		return this.user$.value;
	}

	@Input('vhUserStatusEnabled')
	enabled = true;

	@Input('vhUserShowColors')
	showColors = true;

	@Input()
	vhUserStatusBackground = false;

	protected readonly user$ = new BehaviorSubject<Partial<IUserShort> | IRecipient>(null);
	protected readonly classes = new Set<string>();
	protected readonly styles = new Map<string, { prevVal: string }>();
	protected readonly signal = inject(SignalrProvider);
	protected readonly storage = inject(LocalStorageProvider);

	get el() {
		return this.elRef?.nativeElement;
	}

	get userId() {
		if (this.isGroup(this.user)) {
			return;
		}
		return (this.user as IUserShort).userId || this.user.id;
	}

	ngOnInit() {
		UserStatusDirective.refs.add(this);
		UserStatusDirective.timeZoneOffset =
			UserStatusDirective.timeZoneOffset || this.storage.get(UserStatusDirective.timeZoneOffsetKey);
		if (!UserStatusDirective.sessionUserProfileRequested) {
			UserStatusDirective.sessionUserProfileRequested = true;
			this.sessionUser.profile$.pipe(take(1)).subscribe(profile => {
				UserStatusDirective.timeZoneOffset = profile.timeZoneOffset;
				this.storage.set(UserStatusDirective.timeZoneOffsetKey, profile.timeZoneOffset);
			});
		}
		if (this.enabled) {
			this.user$.pipe(booleanFilter(), takeUntil(this.destroy$)).subscribe(user => {
				requestAnimationFrame(() => {
					this.setupStyles(user);
				});
			});
		}
		this.signal.signal$
			.pipe(
				filter(s => s.name === this.signal.events.userIsOnline),
				takeUntil(this.destroy$)
			)
			.subscribe((s: ISignalMessage<IUserIsOnlineNotifyData>) => {
				if (this.isGroup(this.user)) {
					return;
				}
				const userId = this.userId;
				if (userId === s.data?.user?.userId) {
					const userUpdate = {
						...this.user,
						...s.data.user,
					};
					this.user$.next(userUpdate);
				}
			});
	}

	ngOnDestroy() {
		UserStatusDirective.refs.delete(this);
		this.user$.complete();
		this.classes.clear();
		this.styles.clear();
	}

	getCache(userId: number) {
		if (typeof userId !== 'number') {
			return;
		}
		return UserStatusDirective.userCacheSignalData[userId];
	}

	protected isGroup(recipient: Partial<IUserShort> | IRecipient) {
		if (
			(<IRecipient>recipient)?.type === IRecipientType.group ||
			(typeof (<Partial<IUserShort>>recipient)?.hasAvatar === 'undefined' &&
				!(<Partial<IUserShort>>recipient)?.userState)
		) {
			return true;
		}
		return false;
	}

	protected setupStyles(recipient: Partial<IUserShort> | IRecipient) {
		this.clearStyles();

		if (!this.showColors) {
			return;
		}

		if (this.isGroup(recipient)) {
			this.addClasses('user-status--group-recipient');
		} else {
			const user = recipient as Partial<IUserShort>;

			const onlineClass =
				isUserOnline(user, UserStatusDirective.timeZoneOffset) || user?.userId === this.sessionUser?.userId
					? 'user-status--online'
					: 'user-status--offline';
			this.addClasses('user-status', onlineClass);

			const isAbsence =
				user?.absences?.length > 0 || user?.userStatus?.absences?.length > 0 || user?.userState?.absence;

			if (user?.isFired) {
				this.addClasses('user-status--fired');
				return;
			}

			if (isAbsence) {
				this.addClasses('user-status--absence');
				const currentAbsence = this.getActualAbsence(user);
				const color =
					currentAbsence?.userColor || currentAbsence?.absenceType?.userColor || currentAbsence?.absenceTypeUserColor;
				if (color) {
					this.addStyles('color', color);
					if (this.vhUserStatusBackground) {
						this.addStyles('background-color', color);
					}
				}
			}
			if (user.isEmployee === null || user.isEmployee === false) {
				this.addClasses('user-status--not-employee');
			}
		}
	}

	protected clearStyles() {
		this.classes.forEach(className => {
			this.el.classList.remove(className);
		});
		this.styles.forEach(({ prevVal }, styleName) => {
			this.el.style[styleName] = prevVal || null;
		});
		this.classes.clear();
		this.styles.clear();
	}

	protected addClasses(...classNames: string[]) {
		classNames = removeDuplicate(classNames);
		this.el.classList.add(...classNames);
		classNames.forEach(className => this.classes.add(className));
	}

	protected addStyles(styleProp: string, styleVal: string) {
		const prev = this.styles.get(styleProp);
		if (!prev) {
			const prevVal = this.el.style[styleProp];
			this.styles.set(styleProp, { prevVal });
		}
		this.el.style[styleProp] = styleVal;
	}

	protected getActualAbsence(user: Partial<IUserShort>): IAbsence {
		const absence = getActualAbsence(user);
		return absence;
	}
}
