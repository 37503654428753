import { ApplicationRef, Injectable, Injector, NgZone } from '@angular/core';
import { getAppTopInjector } from '@spa/api/injectors';
import { OpenPagesService } from '@spa/common/services/open-pages.service';
import { SessionUser, UrlProvider } from '@spa/core';
import { DataHttpService } from '@spa/data/http';
import { ModalWindowsService } from '@spa/facade/features/modals';
import { ContentOverlayService } from '@spa/facade/layout/components/content-overlay';
import { map } from 'rxjs/operators';
import { ApplicationResolver } from './application-resolver';
import { CategoryApplicationService } from './category/category-application.service';
import { TaskApplicationService } from './task/task-application.service';
import { SignalrProvider } from '@spa/data/signalr';

@Injectable({ providedIn: 'root' })
export class ApplicationService {
	constructor(
		protected readonly injector: Injector,
		readonly category: CategoryApplicationService,
		readonly task: TaskApplicationService
	) {
		window['ApplicationService'] = this;
	}

	get appRef() {
		return this.injector.get(ApplicationRef);
	}

	get zone() {
		return this.injector.get(NgZone);
	}

	get server() {
		return this.injector.get(DataHttpService);
	}

	get sessionUser() {
		return this.injector.get(SessionUser);
	}

	get modals() {
		return getAppTopInjector().get(ModalWindowsService);
	}

	get url() {
		return this.injector.get(UrlProvider);
	}

	get contentOverlay() {
		return this.injector.get(ContentOverlayService);
	}

	get openPages() {
		return this.injector.get(OpenPagesService);
	}

	get signal() {
		return this.injector.get(SignalrProvider);
	}

	protected resolve<T>(resolver: ApplicationResolver<T>) {
		return resolver().pipe(map(type => this.injector.get<T>(type as any)));
	}
}
