export enum IAlertMessageColorMap {
	containerPrimary = 'var(--container-primary)',
	containerInfo = 'var(--container-info)',
	containerDanger = 'var(--container-danger)',
	containerWarning = 'var(--container-warning)',
	containerSuccess = 'var(--container-success)',
	containerDefault = 'var(--container-default)',
}

export enum IAlertMessageColor {
	containerPrimary = 'containerPrimary',
	containerInfo = 'containerInfo',
	containerDanger = 'containerDanger',
	containerWarning = 'containerWarning',
	containerSuccess = 'containerSuccess',
	containerDefault = 'containerDefault',
}

export enum IAlertMessageIconColorMap {
	containerPrimary = 'basePrimary',
	containerInfo = 'baseInfo',
	containerDanger = 'baseDanger',
	containerWarning = 'baseWarning',
	containerSuccess = 'baseSuccess',
	containerDefault = 'baseDefault',
}

export enum IAlertMessageTitleColorMap {
	containerPrimary = 'var(--oncontainer-primary)',
	containerInfo = 'var(--oncontainer-info)',
	containerDanger = 'var(--oncontainer-danger)',
	containerWarning = 'var(--oncontainer-warning)',
	containerSuccess = 'var(--oncontainer-success)',
	containerDefault = 'var(--oncontainer-default)',
}

export enum IAlertMessageBtnColorMap {
	containerPrimary = 'var(--base-primary)',
	containerInfo = 'var(--base-info)',
	containerDanger = 'var(--base-danger)',
	containerWarning = 'var(--base-warning)',
	containerSuccess = 'var(--base-success)',
	containerDefault = 'var(--base-default)',
}

export enum IAlertMessageBtnHoverColorMap {
	containerPrimary = 'var(--base-primary-hover)',
	containerInfo = 'var(--base-info-hover)',
	containerDanger = 'var(--base-danger-hover)',
	containerWarning = 'var(--base-warning-hover)',
	containerSuccess = 'var(--base-success-hover)',
	containerDefault = 'var(--base-default-hover)',
}

export enum AlertMessageBtnType {
	link = 'link',
	button = 'button',
}
