import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IApiResponse } from '../api-response';

import { DataHttpCommonService } from '../data-http-common.service';
import { ICallHistory, ICallHistoryReq, SipDataHttpService } from './abstract';

@Injectable()
export class SipDataHttpServiceImpl implements SipDataHttpService {
	constructor(readonly http: HttpClient, readonly common: DataHttpCommonService) {}

	getCallHistory(params: ICallHistoryReq): Observable<ICallHistory> {
		const url = this.common.getApiUrl(`sip/CallsHistory`);
		return this.http.get<IApiResponse<ICallHistory>>(url, { params: { ...params } }).pipe(map(res => res.data));
	}

	getMissedCount(userId: number): Observable<number> {
		const url = this.common.getApiUrl(`sip/missed-count`);
		return this.http.get<IApiResponse<number>>(url, { params: { userId } }).pipe(map(res => res.data));
	}

	setViewedMissedCall(): Observable<any> {
		const url = this.common.getApiUrl(`sip/missed-set-viewed`);
		return this.http.post<any>(url, {});
	}
}
