import { Inject, Injectable } from '@angular/core';
import { IRxStore, StoreSelector } from '@valhalla/core';
import { DataHttpService, IGetFavoriteCountersDto } from '@valhalla/data/http';
import { ITree, ITreeItem, merge, debug, cloneDeep } from '@valhalla/utils';
import { combineLatest, Observable, BehaviorSubject, Subject, take } from 'rxjs';
import { map, shareReplay, distinctUntilChanged, debounceTime, switchMap, startWith } from 'rxjs/operators';

import * as actions from './actions';
import { STORE_FACADE_NAVIGATION } from './ng-tokens';
import { INavigationMenu, INavigationMenuTab, INavigationState, NavigationMenu, INavigationMenuState } from './state';

export abstract class NavigationFeatureProvider {
	abstract favoritesNavigationMenu$: Observable<INavigationMenu[]>;
	abstract favoritesMenuItems$: Observable<INavigationState['menus']['favorites']['nodes']>;
	abstract tabsMenu$: Observable<INavigationMenuTab[]>;
	abstract tabsMenu: INavigationMenuTab[];
	abstract activeMenuId$: Observable<string>;
	abstract categories$: Observable<INavigationState['menus']['categories']>;
	abstract activeNavigation$: Observable<INavigationMenu[]>;
	abstract navigation$: Observable<Record<string, Observable<INavigationMenu[]>>>;
	abstract favoriteCounters$: Observable<Record<number, IGetFavoriteCountersDto>>;
	abstract readonly categoryAggregates$: Observable<{
		allTasksUserPerforms: number;
		allTasksUserOwns: number;
	}>;

	abstract get activeMenuId(): string;
	abstract select<R>(selector: StoreSelector<INavigationState, R>): Observable<R>;
	abstract init();
	abstract load(menus: Array<NavigationMenu | string>);
	abstract update(data: actions.IUpdateNavigationItemPayload);
	abstract updateFavorites(data: actions.IUpdateFavoritesPayload);
	abstract expandFavoriteFolder(id: number, collapseOthers?: boolean);
	abstract expandFavoriteAssistantFolder(id: number, collapseOthers?: boolean, userId?: number);
	abstract delete(data: actions.IDeleteNavigationItemPayload);
	abstract create(data: actions.ICreateNavigationItemPayload);
	abstract setActiveMenuItem(data: actions.ISetActiveNavigationItemPayload);
	abstract setActiveTabMenu(menuId: NavigationMenu | string);
	abstract turnOnPeriodicUpdateFavoriteCounters();
	abstract turnOnPeriodicFavoritesMenuUpdate(menus);
	abstract searchByQuery(query: string, tabId: string);
}

@Injectable()
export class NavigationFeatureProviderImpl implements NavigationFeatureProvider {
	constructor(
		@Inject(STORE_FACADE_NAVIGATION) protected readonly store: IRxStore<INavigationState>,
		readonly server: DataHttpService
	) {}

	readonly activeMenuId$ = this.store.select(state => state.activeTabMenuId);
	readonly tabsMenu$ = this.store
		.select(state => state.tabsMenu)
		.pipe(
			map(tabs => cloneDeep(tabs)),
			map(tabs => tabs.sort((a, b) => a.order - b.order))
		);
	readonly favoritesMenuItems$ = this.store.select(state => state.menus.favorites.nodes);
	readonly favoritesMenuView$ = this.store.select(state => state.menus.favorites.folders);
	readonly favoritesNavigationMenu$: Observable<INavigationMenu[]> = new Subject();
	readonly activeNavigation$: Observable<INavigationMenu[]> = combineLatest(
		this.activeMenuId$,
		this.store.select(state => state.menus)
	).pipe(
		map(([menuId, menus]) => {
			const navMenu = menus[menuId] as unknown as INavigationMenu;
			const records = (navMenu && navMenu[menuId].menuItems) || {};
			const menuView = (navMenu && navMenu[menuId].menuView) || [];
			// fixme: to tree util
			const fillMenu = (menu: ITree<ITreeItem>) => {
				menu = merge(menu, records[menu.id]);
				menu.children = menu.children.map(fillMenu);
				menu.children = [
					...menu.children.filter(i => i.children && i.children.length),
					...menu.children.filter(i => !(i.children && i.children.length)),
				];
				return menu as INavigationMenu;
			};
			return menuView.map(fillMenu);
		})
	);

	readonly navigation$: Observable<Record<string, Observable<INavigationMenu[]>>> = this.tabsMenu$.pipe(
		map(tabs => tabs.map(tab => tab.id)),
		map(menuIds =>
			menuIds.reduce((acc, menuId) => {
				acc[menuId] = this.selectNavigationByMenuId(menuId);
				return acc;
			}, {})
		),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	readonly favoriteCounters$ = this.store
		.select(state => state.favoriteCounters)
		.pipe(shareReplay({ refCount: true, bufferSize: 1 }));

	get activeMenuId() {
		return this.store.getState(s => s.activeTabMenuId);
	}

	readonly searchByTabId$ = new BehaviorSubject<Record<string, string>>({});

	readonly searchByTabIdCache = new Map<string, Map<string, INavigationMenu[]>>();

	readonly categories$ = this.store.select(s => s.menus.categories);
	readonly categoryAggregates$ = this.categories$.pipe(
		map(({ allTasksUserPerforms = 0, allTasksUserOwns = 0 }) => ({
			allTasksUserPerforms,
			allTasksUserOwns,
		}))
	);

	get tabsMenu() {
		return this.store.getState(state => state.tabsMenu);
	}

	select<R>(selector: (state: INavigationState) => R): Observable<R> {
		return this.store.select(selector);
	}

	init() {
		this.store.dispatch(actions.initNavigationMenuActionCreator({}));
	}

	load(menus: Array<NavigationMenu>) {
		this.store.dispatch(actions.loadRequestActionCreator(menus));
	}

	turnOnPeriodicUpdateFavoriteCounters() {
		this.store.dispatch(actions.turnOnPeriodicCounterUpdate({ updateIntervalMs: 2 * 60 * 1000 }));
	}

	turnOffPeriodicUpdateFavoriteCounters() {
		this.store.dispatch(actions.turnOffPeriodicCounterUpdate(null));
	}

	turnOnPeriodicFavoritesMenuUpdate(menus) {
		this.store.dispatch(actions.turnOnPeriodicFavoritesMenuUpdate({ updateIntervalMs: 5 * 60 * 1000, menus }));
	}

	turnOffPeriodicUpdateFavoriteMenu() {
		this.store.dispatch(actions.turnOffPeriodicFavoritesMenuUpdate(null));
	}

	update(data: actions.IUpdateNavigationItemPayload) {
		if (!data) {
			return;
		}
		this.store.dispatch(actions.updateNavigationItemActionCreator(data));
	}

	updateFavorites(data: actions.IUpdateFavoritesPayload) {
		if (!data) {
			return;
		}
		this.store.dispatch(actions.updateFavoritesItemsActionCreator(data));
	}

	updateFavoritesAssistant(data: actions.IUpdateFavoritesAssistantPayload) {
		if (!data) {
			return;
		}
		this.store.dispatch(actions.updateFavoritesItemsAssistantActionCreator(data));
	}

	expandFavoriteFolder(folderId: number, collapseOthers = true) {
		if (!folderId) {
			return;
		}
		this.store.dispatch(actions.expandFavoriteFolderActionCreator({ folderId, collapseOthers }));
	}

	expandFavoriteAssistantFolder(folderId: number, collapseOthers = true, userId: number) {
		if (!folderId) {
			return;
		}
		this.store.dispatch(actions.expandFavoriteFolderAssistantActionCreator({ folderId, collapseOthers, userId }));
	}

	delete(data: actions.IDeleteNavigationItemPayload) {
		if (!data) {
			return;
		}
		this.store.dispatch(actions.deleteNavigationItemActionCreator(data));
	}

	create(data: actions.ICreateNavigationItemPayload) {
		if (!data) {
			return;
		}
		this.store.dispatch(actions.createNavigationItemActionCreator(data));
	}

	setActiveMenuItem(data: actions.ISetActiveNavigationItemPayload) {
		if (!data) {
			return;
		}
		this.store.dispatch(actions.setActiveNavigationItemActionCreator(data));
	}

	setActiveTabMenu(menuId: NavigationMenu | string) {
		if (!menuId) {
			return;
		}
		this.store.dispatch(actions.setActiveMenuTab({ menuTabActiveId: menuId }));
	}

	searchByQuery(query: string, tabId: string) {
		const newValue = {
			...this.searchByTabId$.value,
			[tabId]: query,
		};
		this.searchByTabId$.next(newValue);
	}

	selectNavigationByMenuId(menuId: string): Observable<INavigationMenu[]> {
		return this.store
			.select(state => state.menus[menuId])
			.pipe(
				map(menu => {
					let navMenu = this.navMenuStateToNavMenu(menu as INavigationMenuState);
					if (menuId === 'categories') {
						navMenu = navMenu[0]?.children || ([] as any);
					}
					return navMenu;
				})
			);
	}

	navMenuStateToNavMenu(menu: INavigationMenuState): INavigationMenu[] {
		const records = (menu && menu.menuItems) || {};
		// if (menu && menu.menuItems) {
		// 	const subcatMenuItems = Object.keys(menu.menuItems).filter(item => item.includes('Subcategory'));
		// 	subcatMenuItems.forEach(item => {
		// 		this.server.task.getSubCategoryShortInfo({ subCategoryId: records[item].$id }).pipe(take(1)).subscribe(info => {
		// 			records[item].availableRepresentations = info?.subcategorySettings?.availableRepresentations;
		// 		})
		// 	})
		// }
		const menuView = (menu && menu.menuView) || [];
		const fillMenu = (tree: ITree<ITreeItem>) => {
			tree = merge(tree, records[`${(tree as any).nodeType}${tree.id}`]);
			tree.children = tree.children.filter(Boolean).map(fillMenu);
			tree.children = [
				...tree.children.filter(i => i.children && i.children.length),
				...tree.children.filter(i => !(i.children && i.children.length)),
			];
			return tree as INavigationMenu;
		};
		const result = menuView.map(fillMenu);
		return result;
	}

	navMenuStateToNavMenuWithCacheAndSearchFilter(
		menu: INavigationMenuState,
		menuId: string,
		query: string
	): INavigationMenu[] {
		if (!this.searchByTabIdCache.has(menuId)) {
			this.searchByTabIdCache.set(menuId, new Map());
		}
		query = query && String(query).trim();
		const searches = this.searchByTabIdCache.get(menuId);
		if (searches.has(query)) {
			return searches.get(query);
		}
		const searchResult = this.navMenuStateToNavMenuWithSearch(menu, query);
		searches.set(query, searchResult);
		return searchResult;
	}

	navMenuStateToNavMenuWithSearch(menu: INavigationMenuState, query: string): INavigationMenu[] {
		if (!query) {
			return this.navMenuStateToNavMenu(menu);
		}
		return [];
	}

	selectNavigationByMenuIdWithSearch(menuId: string) {
		const search$ = this.searchByTabId$.pipe(
			map(data => data[menuId]),
			distinctUntilChanged(),
			debounceTime(300)
		);

		const navState$ = this.store.select(state => state.menus[menuId]);

		return search$.pipe(
			switchMap(query =>
				navState$.pipe(
					map(state => this.navMenuStateToNavMenuWithCacheAndSearchFilter(state as INavigationMenuState, menuId, query))
				)
			)
		);
	}
}
