import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { ViewDestroyStreamService } from '@valhalla/core';
import { UserInfoPanelModule } from '../../components/user-info-panel/user-info-panel.module';
import { UserMiniProfileModule } from '../../components/user-mini-profile/user-mini-profile.module';

import { UserPreviewPanelDirective } from './user-preview-panel.directive';
import { UserPreviewPanelService } from './user-preview-panel.service';

@NgModule({
	imports: [OverlayModule, CommonModule, UserInfoPanelModule, UserMiniProfileModule],
	declarations: [UserPreviewPanelDirective],
	exports: [UserPreviewPanelDirective],
	providers: [UserPreviewPanelService, ViewDestroyStreamService],
})
export class UserPreviewPanelModule {}
