// tslint:disable:no-empty-interface
import {
	IComment,
	ITaskInfo,
	ITaskMainRoute,
	ITaskMainRouteStep,
	ITaskMainRouteTerminalState,
	ITaskStep,
	IUserShort,
	ICategoryShort,
	ISubCategoryShort,
	ICategoryStep,
	INewsResponse,
	ITaskTemplate,
	IExtParam,
	IExtParamBlock,
} from '@valhalla/data/entities';
import { Observable } from 'rxjs';
import { ISubcatInfo } from '@spa/data/entities';
import { FeedTasksType } from '../feeds';

export abstract class TaskDataHttpService {
	abstract feeds(criteria: ITaskFeedsCriteria): Observable<ITaskFeedsResult>;
	abstract getInfo(criteria: ITaskInfoCriteria): Observable<ITaskInfo>;
	abstract getInfoParts(criteria: ITaskInfoCriteria, all?: boolean, background?: boolean): Observable<ITaskInfo>;
	abstract getNews(criteria: ITaskNewsCriteria): Observable<INewsResponse>;
	abstract getGanttCategoryData(criteria: IGanttCategoryCriteria): Observable<any>;
	abstract getGanttSubcategoryData(criteria: IGanttSubcategoryCriteria): Observable<any>;
	abstract setLike(criteria: ITaskNewsCriteria): Observable<any>;
	abstract setUnlike(criteria: ITaskNewsCriteria): Observable<any>;
	abstract findNewsTemplate(taskTemplates: ITaskTemplate[]): ITaskTemplate;
	abstract addToFavorites(criteria: ITaskAddToFavoritesCriteria): Observable<any>;
	abstract removeFromFavorites(criteria: ITaskRemoveFromFavoritesCriteria): Observable<any>;
	abstract updateFavorites(criteria: ITaskUpdateFavoritesCriteria): Observable<any>;
	/**@deprecated use changeSubscribers used in the old version of mtf*/
	abstract unsubscribeFromTask(criteria: any): Observable<any>;
	/**@deprecated use changeSubscribers*/
	abstract unsubscribeFromTask2(taskId: number): Observable<any>;
	abstract changeSubscribers(params: ITaskChangeSubscribers): Observable<any>;

	abstract getRoute(criteria: ITaskRouteCriteria): Observable<ITaskMainRouteResult>;
	abstract getSteps(criteria: ITaskStepsCriteria): Observable<ITaskStep[]>;
	abstract getCategorySteps(criteria: ICategoryStepsCriteria): Observable<ICategoryStep>;
	abstract getGridBlockData(criteria: ITaskGridBlockDataCriteria): Observable<ITaskGridBlockDataResult>;
	abstract getCategoryShortInfo(criteria: ICategoryInfoCriteria): Observable<ICategoryShort>;
	abstract getSubCategoryShortInfo(criteria: ISubCategoryInfoCriteria): Observable<ISubCategoryShort>;
	abstract getSubCategoryInfo(criteria: ISubCategoryInfoCriteria): Observable<ISubcatInfo>;
	abstract getSubcategoryTemplate(criteria: INtfTaskTemplateCriteria): Observable<INtfTaskTemplate>;
	abstract getCategoryTree(criteria: ICategoryTreeCriteria): Observable<ICategoryTreeResult>;

	abstract executeStep(criteria: IExecuteTaskStepCriteria): Observable<IExecuteTaskStepResult>;
	abstract executeStepWithComment(
		taskId: number,
		criteria: IExecuteTaskStepWithCommentCriteria
	): Observable<IExecuteTaskStepWithCommentResult>;

	abstract pin(criteria: ITaskPinCriteria): Observable<ITaskPinResult>;
	abstract unpin(criteria: ITaskUnPinCriteria): Observable<ITaskPinResult>;

	abstract executeAction(criteria: IExecuteTaskActionCriteria): Observable<IExecuteTaskActionResult>;
	abstract executeSignatureResolution(
		criteria: IExecuteSignatureResolutionCriteria
	): Observable<IExecuteSignatureResolutionResult>;
	/**@deprecated use changeSubscribers*/
	abstract subscribeUsers(criteria: ITaskSubscribeUserCriteria): Observable<any>;
	/**@deprecated use changeSubscribers*/
	abstract unsubscribeUsers(criteria: ITaskSubscribeUserCriteria): Observable<any>;

	abstract getTasks(criteria: ITasksCriteria): Observable<any>;
	abstract getTaskRecurrence(criteria: ITaskRecurrenceCriteria): Observable<ITaskRecurrence>;
	abstract saveTaskRecurrence(data: ITaskRecurrence): Observable<any>;
	abstract disableTaskRecurrence(criteria: ITaskDisableRecurrenceCriteria): Observable<any>;
	abstract search(criteria: ITaskSearchCriteria): Observable<ITaskFeedsResult>;

	abstract getStatuses(taskId: number): Observable<IStatusesResponse>;
	abstract changeTaskStatus(criteria: IChangeTaskStatusCriteria): Observable<any>;

	abstract lockDueDate(taskId: number): Observable<any>;
	abstract unlockDueDate(taskId: number): Observable<any>;

	abstract changeOwner(criteria: ITaskChangeOwnerCriteria): Observable<any>;
	abstract assignExecutor(taskId: number, body: { add: number[]; remove: number[] }): Observable<void>;
	abstract assignResponsible(taskId: number, performerId: number): Observable<void>;

	abstract changePriority(criteria: ITaskChangePriorityCriteria): Observable<any>;
	abstract changeDueDate(criteria: ITaskChangeDueDateCriteria): Observable<any>;
	abstract changeDueDate2(criteria: ITaskChangeDueDate2Criteria): Observable<any>;
	abstract changeSignatureReason(criteria: ITaskChangeSignatureReasonCriteria): Observable<any>;
	abstract updateTaskStartTime(criteria: ITaskUpdateStartTimeCriteria): Observable<any>;

	abstract getFiles(criteria: ITaskFilesCriteria): Observable<any>;
	abstract clearDeletedFiles(criteria: ITaskClearDeletedFilesCriteria): Observable<any>;
	abstract pinFile(criteria: ITaskPinFileCriteria): Observable<any>;
	abstract unpinFile(criteria: ITaskUnpinFileCriteria): Observable<any>;
	abstract deleteFile(criteria: ITaskDeleteFileCriteria): Observable<any>;

	abstract deleteTask(criteria: number[]): Observable<any>;
	abstract getUnreadNews(): Observable<any>;
	abstract getUnreadNewsContent(criteria: number): Observable<any>;
	abstract closeUnreadNewsContent(criteria: number): Observable<any>;

	abstract createTask(criteria: ICreateTask): Observable<ICreateTaskResponse>;
	abstract createNewDoc(criteria: ICreateNewDocCriteria): Observable<any>;
	abstract encryptTask(taskId: number): Observable<any>;
	abstract setConfidentialTask(criteria: ITaskSetConfidential): Observable<any>;
	abstract getReports(taskId: number): Observable<IGetTaskReportsResult>;
	abstract checkExistAndAccess(taskId: number): Observable<ICheckExistAndAccess>;

	abstract getEmails(criteria: ITaskEmailsCriteria): Observable<ITaskEmailsResponse[]>;

	abstract updateTaskAvatar(criteria: IUpdateTaskAvatarCriteria): Observable<any>;
	abstract removeTaskAvatar(criteria: Pick<IUpdateTaskAvatarCriteria, 'taskId'>): Observable<any>;
	abstract getTaskAvatar(taskId: number): Observable<any>;
	abstract getTaskAvatarUrl(taskId: number): string;

	abstract getSignatureHistory(taskId: number, request: any): Observable<IRequestedSignature[]>;
	abstract getTaskSignatureSnapshot(taskSignatureId: number): Observable<ITaskFullSnapshot>;
	abstract getSignatures(): Observable<ISignaturesRes>;

	abstract addSubtask(taskId: number, req: IAddSubtaskReq): Observable<any>;
	abstract addLinked(taskId: number, req: ILinkedTaskReq): Observable<any>;

	abstract updateExtParams(arg: ITaskUpdateExtParams | ITaskUpdateExtParams[]): Observable<any>;
	abstract changeText(taskId: number, text: string): Observable<any>;
	abstract deleteMultifileExtParams(req: ITaskDeleteMultifileExtParams): Observable<any>;
	abstract getAllFiles(taskId: number, criteria: ITaskAllFilesCriteria): Observable<any>;

	abstract getAllStates(params?: IGetAllTaskStatesParams): Observable<IStateInfo[]>;
	abstract getExistanceFileByName(criteria: {
		taskId: number;
		fileNames: string[];
	}): Observable<IFileExistanceDto[] | Record<string, boolean>>;

	abstract addToHistory(taskId: number): Observable<any>;

	abstract copyTask(taskId: number, req: ICreateTask): Observable<ICopyMoveTaskResponse>;
	abstract moveTask(taskId: number, req: ICreateTask): Observable<ICopyMoveTaskResponse>;
	abstract updateTask(criteria: IUpdateTask): Observable<any>;

	abstract pinAsChatToUsers(params: ITaskPinAsChatToUsers): Observable<any>;
	abstract deleteAllSubscribers(params: IDeleteAllSubscribers): Observable<any>;
	abstract pinAsChatToAllUsers(taskId: number): Observable<any>;
	abstract formatExtParams(params: ITaskFormatExtParams): Observable<any>;

	abstract getTasksReminders(req: ITaskRemindersReq): Observable<ITaskRemindersRes>;
	abstract setTasksReminder(req: ITaskReminderItem[]): Observable<any>;
	abstract updateTasksReminder(req: ITaskReminderItem[]): Observable<any>;
	abstract deleteTasksReminder(req: number[]): Observable<any>;

	abstract stepHistory(req: ITaskStepHistoryParams): Observable<any>;
	abstract taskFilesHistory(taskId: number, extParamId: number): Observable<ITaskFilesHistoryRes[]>;

	abstract attachEmail(params: ITaskAttchEmail): Observable<any>;
}

export interface ITaskAttchEmail {
	taskId: number;
	findEmailQuery: {
		mailBoxId: number;
		mailFolderName: string;
		internetMessageId: string;
		includeAttachmentContent: boolean;
	};
}

export interface ITaskFilesHistoryRes {
	id: number;
	name: string;
	user: {
		userId: number;
		displayName: string;
	};
	uploadDate: string;
	size: number;
	isDeleted: boolean;
	versionId: number;
	extParamLinkId: number;
}

export interface ITaskStepHistoryParams {
	taskId: number;
}

export interface IUpdateTask {
	taskIds: number[];
	extParams: IUpdateTaskExtParam[];
	mainParams: any[];
}

export interface IUpdateTaskExtParam {
	id: number;
	value: any;
}

export interface ITaskReminderItem {
	comment: string | null;
	copyInOutlook: boolean;
	id?: number;
	recipientIds: number[];
	reminderDate: string;
	sendSms: boolean;
	taskId: number;
	userId: number;
}

export interface ITaskReminder {
	id: number;
	taskId: number;
	comment: string;
	reminderDate: string;
	sendSms: boolean;
	recipients: ITaskReminderRecepient[];
	user: ITaskReminderRecepient;
	commentRecipients: string;
	copyInOutlook: boolean;
}

export interface ITaskReminderRecepient {
	userId: number;
	displayName: string;
}

export interface ITaskRemindersRes {
	reminders: ITaskReminder[];
}

export interface ITaskRemindersReq {
	taskIds: number[];
	from: string;
	to: string;
	limit?: 0;
}

export interface IGetAllTaskStatesParams {
	filter?: string;
	skip?: number;
	take?: number;
}

export interface IDeleteAllSubscribers {
	taskId: number;
}

export interface ITaskPinAsChatToUsers {
	taskId: number;
	userIds: number[];
}

export interface ICopyMoveTaskResponse {
	newTaskIds: number[];
	redirectToTaskId: number;
	redirectToSubcatId: number;
	message: string;
	customCallbackJs: string;
}

export interface ICopyMoveTaskReq {
	subcatId: number;
	text: string;
	owner: number;
	createFromParentOwner: boolean;
	performers: number[];
	separateTaskForEachPerformer: boolean;
	subscribers: number[];
	subscribeGroups: number[];
	copySubscribers: boolean;
	notifyUsers: number[];
	orderedTime: string;
	lockOrderedTime: boolean;
	taskStartTime: string;
	plannedStartTime: string;
	plannedEndTime: string;
	priority: number;
	extParams: any[];
	parentTaskId?: number;
	linkedTaskId?: number;
	linkedEmailId?: number;
	parentCommentId?: number;
	extParamSourceTaskId: number;
	preuploadedFiles: number[];
	filesIds?: number[];
	linkFiles?: number[];
	parentFiles: string;
	parentEmails: string;
	isConfidential?: boolean;
	isConsisImplement?: boolean;
	location: string;
	guid?: string;
	userComment?: string;
	onSuccessParams?: any;
	taskRecurrence: any;
	taskReminder: any[];
	pinToChat: boolean;
	sourceBlockId: number;
	initiatorUserId?: number;
}

export interface ITaskAllFilesCriteria {
	files?: any;
	filename?: string;
	extParamId?: number;
	tableColumnId?: number;
	tableRowId?: number;
}

export interface ITaskDeleteMultifileExtParams {
	taskId: number;
	extParamId: number;
	fileIds: number[];
	clearAll: boolean;
	comment?: string;
}

export interface ITaskFormatExtParams {
	extParamStr: string;
	subcatId: number;
}

export interface ITaskUpdateExtParams {
	extParamStr: string;
	taskId: number;
	userId: number;
	writeComment: boolean;
	commentText?: string;
}

export interface ILinkedTaskReq {
	subtaskId: number;
	needCopySubscribers: boolean;
	needCopyFiles: boolean;
	isSilent?: boolean;
	linkType?: any;
}
export interface IAddSubtaskReq {
	subtaskId: number;
	needCopySubscribers: boolean;
	needCopyFiles: boolean;
	deletePreviousParent?: boolean;
}
export interface ISignaturesRes {
	tasksForAccept: {
		activeTaskIds: number[];
		myTasksForAccept: IMyTasksForAccept[];
	};
}

export interface IMyTasksForAccept {
	EdsFiles: any[];
	EdsType: string;
	IsAsyncAvailable: boolean;
	IsQueued: boolean;
	PcIsServerSign: boolean;
	canBeEscalated: boolean;
	description: string;
	extParams: any[];
	guid: string;
	mobileTemplate: any;
	owner: IUserShort;
	reason: string;
	requestReasonOnAccept: boolean;
	requestor: ISignatureRequestor;
	responsiblePerformer: IUserShort;
	signatureAcceptants: ISignaturesAcceptans[];
	signatureResolutions: ISignatureResolutions[];
	subcat: any;
	taskId: number;
	taskSignatureId: number;
	taskSignatureInitializeDate: string;
	taskSignatureOrderedTime: string;
	taskText: string;
}

export interface ISignaturesAcceptans {
	Absences: any;
	Id: number;
	IsLastOnlineFromMobileApp: boolean;
	IsOnline: boolean;
	LastOnlineTime: string;
	LastPersonalInfoUpdateTime: string;
	Name: string;
	UserState: any;
}

export interface ISignatureRequestor {
	Absences: any[];
	BirthDate: string;
	BirthDaySoon: boolean;
	DisplayName: string;
	Gender: number;
	HasAvatar: boolean;
	IsEmployee: boolean;
	IsLastOnlineFromMobileApp: boolean;
	LastOnlineTime: string;
	LastPersonalInfoUpdateTime: string;
	UserId: number;
	UserName: string;
}

export interface ISignatureResolutions {
	DialogHeader: string;
	Name: string;
	NeedConfirm: boolean;
	ReasonRequired: boolean;
	ResolutionTypeId: number;
	SignatureAction: ResolutionAction;
	ColorHex: string;
}

export enum ResolutionAction {
	accept = 'Accept',
	reject = 'Reject',
	delete = 'Delete',
	delegate = 'Delegate',
	journal = 'openJournal',
	escalate = 'Escalate',
}

export interface IUpdateTaskAvatarCriteria {
	taskId: number;
	fileName: string;
	base64File: string;
}

export interface ITaskClearDeletedFilesCriteria {
	taskId: number;
}
export interface ITaskClearDeletedFilesCriteria {
	taskId: number;
}

export interface ITaskEmailsResponse {
	emailId: number;
	subject: string;
	from: string;
	to: string;
	dateSent: string;
	cc: string;
	bcc: string;
}

export interface ITaskEmailsCriteria {
	taskId: number;
	limit: number;
	offset: number;
}

export interface IExecuteTaskStepWithCommentResult {
	ok: boolean;
	errorMessage: string;
	newStateId: number;
	redirectResult: {
		openLinkTarget: string;
	};
	requiredExtParams: number[];
}

export interface IExecuteTaskStepWithCommentReason {
	taskStepId: number;
	signatureId: number;
	reason: string;
}

export interface IExecuteTaskStepWithCommentCriteria {
	stepId?: number;
	comment?: string;
	reasons?: IExecuteTaskStepWithCommentReason[];
	forUserId?: number;
	userConfirmedTSTAction?: boolean;
	location?: {
		id: number;
		userId: number;
		country: string;
		city: string;
		street: string;
		streetNumber: string;
		created: string;
		latitude: number;
		longitude: number;
		accuracy: number;
		state: string;
		message: string;
	};
}

export interface ICheckExistAndAccess {
	isTaskExists: boolean;
	isUserHasAccess: boolean;
	taskShortInfo: ITaskShortInfo;
	errorMessage?: string;
}

export interface ITaskShortInfo {
	subcatId: number;
	ownerId: number;
	isEncrypted: boolean;
	isClosed: boolean;
	isWaitingSign: boolean;
	isConfidential: boolean;
	description?: any;
	priorityId: string;
	stateId: number;
	owner: IUserShort;
	id: number;
	taskText?: string;
	stateDescription?: string;
	subcatDescription?: string;
	performers?: IUserShort[];
	isSpace?: boolean;
}
export interface ITaskSetConfidential {
	taskId: number;
	isConfidential: boolean;
}

export interface ITaskChangeDueDate2Criteria {
	newDate: string;
	reason: string;
	аddToQuickReply: boolean;
	taskId: number;
}
export interface ICreateNewDocCriteria {
	taskId: number;
	docType: string;
}

export interface IGetTaskReportsResult {
	reports: {
		id: number;
		name: string;
	}[];
}

export interface ITaskReportResult {
	id: number;
	description: string;
	position?: number;
}

export interface ITaskDeleteFileCriteria {
	parentFolderType: number;
	parentFolderId: number;
	fileId: number;
}

export interface ITaskUnpinFileCriteria {
	taskId: number;
	fileId: number;
}
export interface ITaskPinFileCriteria {
	taskId: number;
	fileId: number;
}

export interface ITaskFilesCriteria {
	taskId: number;
	extFilterList?: string[];
	limit?: number;
	offset?: number;
	onlyPinned?: boolean;
	noPinned?: boolean;
	extExcludeList: string[];
	pinnedFirst?: boolean;
	showDeleted?: boolean;
}

export interface ITaskUpdateStartTimeCriteria {
	newStartTime: string | Date;
	reason: string;
	taskId: number;
}
export interface ILocation {
	Accuracy?: number;
	Latitude?: number;
	Longitude?: number;
}

export interface ITaskChangeSignatureReasonCriteria {
	taskSignatureId: number;
	reason: string;
	location: ILocation;
}

export interface ITaskChangeDueDateCriteria {
	reason: string;
	taskId: number;
	silentMode: boolean;
	dueDate: string | Date;
	id?: number;
}
export interface ITaskChangePriorityCriteria {
	taskId: number;
	priority: 'Low' | 'Normal' | 'High';
}
export interface ITaskChangeOwnerCriteria {
	taskId: number;
	newOwnerId: number;
}

export interface IChangeTaskStatusCriteria {
	taskId: number;
	newStateId: number;
}
export interface IStatusesResponse {
	states: IStatus[];
}

export interface IStatus {
	id: number;
	name: string;
}
export interface ITaskState {
	id: number;
	name: string;
}
export interface ITaskSearchCriteria {
	filter: string | string[];
	skip: number;
	take: number;
	feedTypes?: FeedTasksType[];
}

export interface IGanttCategoryCriteria {
	categoryId: number;
}

export interface IGanttSubcategoryCriteria {
	subcategoryId: number;
}
export interface ITasksCriteria {
	subcatId: number;
	limit: number;
	offset: number;
	blockId: any;
	showClosed?: boolean;
	showRejected?: boolean;
	isCropHtml?: boolean;
	search?: string;
}

export interface ITaskSubscribeUserCriteria {
	taskId: number;
	userIds: number[];
}

export interface IExecuteSignatureResolutionCriteria {
	signatureId: number;
	signatureAction: string;
	params: {
		reason?: string;
		resolutionTypeId?: number;
		options?: Partial<{
			taskStepIdAfterRejectDecision: number;
			acceptorsToDelegate: number[];
			replaceAcceptorsOnDelegate: boolean;
		}>;
		edsInfo?: IEdsInfo;
	};
}

export interface IEdsInfo {
	edsFileInfos?: IEdsFileInfo[];
	certificateInfo?: IEdsCertificateInfo;
}

export interface IEdsFileInfo {
	edsId: number;
	signedFileId: number;
	signedFileVersionId: number;
	edsContent: any;
}

export interface IEdsCertificateInfo {
	certificateOwnerName: string;
	certificateSerialNumber: string;
	certificateIssueDate: any;
	certificateExpirationDate: any;
}

export interface IExecuteSignatureResolutionResult {
	id: number;
	requestedTaskSignatures: number[];
}

export interface ITaskAddToFavoritesCriteria {
	taskId: number;
	color?: string;
	favsFolderId?: number;
}

export interface ITaskUpdateFavoritesCriteria {
	taskId: number;
	color?: string;
	favsFolderIds?: number[];
}

export interface ITaskRecurrenceCriteria {
	taskId: number;
}

export interface ITaskDisableRecurrenceCriteria {
	taskId: number;
}
export interface ITaskRecurrence {
	taskId: number;
	ended?: boolean;
	occurrenceCount?: number;
	lastRun?: string;
	copySubsсribers?: true;
	recurrenceDto: ITaskRecurrenceDto;
	orderedTimeTotalMinutes?: number;
	orderedTimeDays: number;
	orderedTimeHours: number;
	orderedTimeMinutes: number;
	canChangeRepeatExecutionTime?: boolean;
	orderedTimeMode?: string;
	orderedTimeDeadline?: string;
	id?: number;
}

export interface ITaskRecurrenceDto {
	start: string;
	endByDate?: string;
	type: number;
	weekDays: number;
	periodicity: number;
	minutesInterval?: number;
	weekOfMonth: number;
	dayNumber: number;
	month: number;
	range?: number;
	occurrenceCount?: number;
	onlyWorkTime?: boolean;
	parentFilesBehavior: string;
	id?: number;
	repeatDaysData?: any;
	isMonthlyDayNumber?: boolean;
}
export interface ITaskNewsCriteria {
	taskId: number;
}

export interface ITaskRemoveFromFavoritesCriteria {
	taskId: number;
}

export interface ITaskUnPinCriteria {
	taskId: number;
}

export interface ITaskPinCriteria {
	taskId: number;
}

export interface ITaskPinResult {}

export interface ITaskExistsCriteria {
	taskId: number;
}

export interface ITaskExistsResult {
	isExists: boolean;
}

export enum ExecuteTaskActionRedirectType {
	link = 'Link',
}

export interface IExecuteTaskActionSignatureCriteria {
	id: number;
	description: string;
	reason: string;
}

export interface IExecuteTaskActionResult {
	message?: string;
	redirectResult?: {
		redirectType: ExecuteTaskActionRedirectType;
		openLinkTarget: 1 | 2 | 3;
		action: string;
		link: string;
	};
	success: boolean;
}

export interface IExecuteTaskActionCriteria {
	taskId: number;
	actionId: number | string;
	comment?: string;
	signatures?: Array<IExecuteTaskActionSignatureCriteria>;
}

export interface IExecuteTaskStepResult {
	newStateId: number;
	requestedSignatures?: number[];
	redirect?: {
		redirectType: string;
	};
	errorMessage?: string;
}

export interface IExecuteTaskStepCriteria {
	taskId: number;
	stepId: number;
	comment?: string;
	userConfirmedAction?: boolean;
}

export interface ICategoryTreeCriteria {
	categoryId?: number;
	subCategoryId?: number;
	taskId?: number;
}

export interface ICategoryTreeResult {
	records: Record<number, ISubCategoryShort | ICategoryShort>;
}
export interface ICategoryInfoCriteria {
	categoryId: number;
}
export interface ISubCategoryInfoCriteria {
	subCategoryId: number;
}

export interface ITaskGridBlockDataCriteria {
	blockId: number;
	taskId: number;
}

export interface ITaskGridBlockDataResult {
	tasks: Array<Partial<ITaskInfo>>;
	tabs: any[];
	categories: any[];
	templates?: any[];
}

export interface ITaskInfoCriteria {
	taskId: number;
	parts?: TaskInfoPart[];
}
export enum TaskInfoPart {
	comments = 'comments',
	extParams = 'ExtParams',
	subscribers = 'subscribers',
	performers = 'performers',
	toolbar = 'toolbar',
	actions = 'Actions',
	requestedSignatures = 'RequestedSignatures',
	optionalSignatures = 'OptionalSignatures',
	customTasksUsed = 'CustomTasksUsed',
	counters = 'Counters',
	files = 'Files',
	subTasks = 'SubTasks',
	linkedTasks = 'LinkedTasks',
	favoriteComments = 'FavoriteComments',
	templates = 'Templates',
	extParamBlocks = 'ExtParamBlocks',
	directSubTasks = 'DirectSubTasks',
	mainParams = 'MainParams',
}

export interface ITaskRouteCriteria extends ITaskInfoCriteria {}
export interface ITaskStepsCriteria extends ITaskInfoCriteria {}
export interface ICategoryStepsCriteria {
	subcatId: number;
}
export interface ITaskChangeStateCriteria extends ITaskInfoCriteria {
	stateId: number;
	comment?: string;
}

export interface ITaskFeedsResult {
	categories: any[];
	tabs: Array<{
		hasMoreTasks: boolean;
		taskIds: number[];
		type: number;
	}>;
	tasks: Array<Partial<ITaskFeedDto>>;
	templates: any[];
}

export interface ITaskFeedsCriteria {
	limit: number;
	offset: number;
	feedTypes: Array<TaskFeedType | FeedTasksType>;
	subcatId?: number;
	extendedSearch: boolean;
	catId?: number;
	summaryId?: number;
	filters: Array<ITaskFeedsFilter>;
	sorters?: Array<ITaskFeedsSorter>;
	showClosed: boolean;
	showLastComment: boolean;
	include?: TaskFeedInclude[];
}

export interface ITaskFeedsFilter {
	filterType: TaskFeedsFilter;
	filterOperation: TaskFeedsFilterOperation;
	filterValues: any[];
	extParamValue?: any;
	extParamId?: number;
	sortType?: SortType;
	valueType?: number;
}

export interface ITaskFeedsSorter {
	sorterType: TaskFeedsSorter;
	sortDir: SortType;
}

export enum TaskFeedsSorter {
	none = 'None',
	lastCommentDate = 'LastCommentDate',
}

export enum SortType {
	desc = 'desc',
	asc = 'asc',
}

export enum TaskFeedsFilter {
	none = 'None',
	dateTimeTaskFilter = 'DateTimeTaskFilter',
	search = 4,
}

export enum TaskFeedsFilterOperation {
	unknown = 'Unknown',
	searchOperation = 64,
}

export enum TaskFeedType {
	all = 'All',
	favorites = 'Favorites',
}

export enum TaskFeedInclude {
	subscribers = 'Subscribers',
}

export interface ITaskFeedDto {
	color: any;
	comments: Array<Partial<IComment>>;
	counters: [];
	createdDate: string;
	dueDateChangeCount: number;
	extParams: any;
	extparams: any;
	files: [];
	guid: string;
	isClosed: boolean;
	isOverdue: boolean;
	isRejected: boolean;
	isWaitingSign: boolean;
	isWorkFinished: boolean;
	lastCommentID: number;
	lastCommentText: string;
	linkedTaskId: number;
	maxCommentsDate: string;
	modifiedDate: string;
	notShowChangeDueDateCount: boolean;
	orderedTime: any;
	owner: Partial<IUserShort>;
	ownerID: number;
	priority: number;
	questionCount: number;
	responsiblePerformer: any;
	stateDescription: string;
	stateID: number;
	subcatID: number;
	taskID: number;
	taskStartDate: string;
	taskText: string;
	templateInfo: any;
	unreadCount: number;
	viewTags?: any;
	performers?: any[];
	subcatId?: number;
}

export interface ITaskMainRouteResult {
	taskMainRoute: ITaskMainRoute;
	steps: ITaskMainRouteStep[];
	terminalStates: ITaskMainRouteTerminalState[];
}

export interface IFilesCount {
	type: IFilesCountType;
	count: number;
}

export enum IFilesCountType {
	overall = 'Overall',
	unknown = 'Unknown',
	images = 'Images',
	audio = 'Audio',
	video = 'Video',
	emails = 'Emails',
	documents = 'Documents',
}

export interface INtfTaskTemplateCriteria {
	subcatId: number;
	parentTaskId?: number;
	taskId?: number;
}

export interface INtfTaskTemplateSettings {
	singleChoice: boolean;
	allowDeleteSelf: boolean;
	allowGroups?: boolean;
}

export interface INtfTaskTemplateMainParam {
	type: string;
	value: any;
	isRequired: boolean;
	canEdit: boolean;
	placeholder: string;
	settings: INtfTaskTemplateSettings;
	name: string;
}

export interface INtfTaskTemplate {
	isChat: boolean;
	subcatDescription: string;
	subcatMobileTemplate?: any;
	epSourceTaskId?: any;
	pinToChat: boolean;
	extParams: IExtParam[];
	template?: any;
	mainParams: INtfTaskTemplateMainParam[];
	canPerform?: boolean;
	extParamBlocks?: IExtParamBlock[];
}

export interface ICreateTaskExtParam {
	id: number;
	value: any;
}

export interface ICreateTaskOnSuccessParams {
	onSuccessJs: string;
	openInSecFrame: string;
	returnUrl: string;
	returnJs: string;
	refreshRadWindowParent: string;
}

export interface ICreateTaskRecurrenceDto {
	start: Date;
	endByDate: Date;
	type: number;
	weekDays: number;
	periodicity: number;
	minutesInterval: number;
	weekOfMonth: number;
	dayNumber: number;
	month: number;
	range: number;
	occurrenceCount: number;
	onlyWorkTime: boolean;
	parentFilesBehavior: string;
	id: number;
}

export interface ICreateTaskTaskRecurrence {
	taskId: number;
	ended: boolean;
	occurrenceCount: number;
	lastRun: Date;
	copySubsсribers: boolean;
	recurrenceDto: ICreateTaskRecurrenceDto;
	orderedTimeTotalMinutes: number;
	orderedTimeDays: number;
	orderedTimeHours: number;
	orderedTimeMinutes: number;
	canChangeRepeatExecutionTime: boolean;
	orderedTimeMode: string;
	orderedTimeDeadline: Date;
	id: number;
}

export interface ICreateTaskTaskReminder {
	taskId?: number;
	comment: string;
	reminderDate: Date | string;
	sendSms: boolean;
	recipientIds: number[];
	userId?: number;
	commentRecipients: string;
	copyInOutlook: boolean;
	id: number;
}

export interface ICreateTask {
	subcatId: number;
	text: string;
	owner: number;
	createFromParentOwner?: boolean;
	performers?: number[];
	separateTaskForEachPerformer?: boolean;
	subscribers?: number[];
	subscribeGroups?: number[];
	copySubscribers?: boolean;
	notifyUsers?: number[];
	orderedTime?: Date | string;
	lockOrderedTime?: boolean;
	taskStartTime?: Date;
	plannedStartTime?: Date;
	plannedEndTime?: Date;
	priority?: number;
	extParams?: ICreateTaskExtParam[];
	parentTaskId?: number;
	linkedTaskId?: number;
	linkedEmailId?: number;
	parentCommentId?: number;
	extParamSourceTaskId?: number;
	preuploadedFiles?: number[];
	filesIds?: number[];
	linkFiles?: number[];
	parentFiles?: string;
	parentEmails?: string;
	isConfidential?: boolean;
	isConsisImplement?: boolean;
	location?: string;
	guid?: string;
	userComment?: string;
	onSuccessParams?: ICreateTaskOnSuccessParams;
	taskRecurrence?: ICreateTaskTaskRecurrence;
	taskReminder?: ICreateTaskTaskReminder[];
	pinToChat?: boolean;
	sourceBlockId?: number;
	copyFilesFromSoursTask?: boolean;
}

export enum IParentFiles {
	DoNotCopy = 'DoNotCopy',
	CreateCopy = 'CreateCopy',
	CreateLink = 'CreateLink',
}

export interface ICreateTaskResponse {
	customCallbackJs: string;
	message: string;
	newTaskIds: number[];
	redirectToSubcatId: number;
	redirectToTaskId: number;
}

export interface IRequestedSignature {
	id: number;
	finalAcceptorId: number | null;
	finalAcceptorName: string;
	initiatorId: number;
	initiatorName: string;
	initializeDate: string | null;
	signatureId: number;
	signatureDescription: string;
	signatureStateId: number;
	signatureStateDescription: string;
	reason: string;
	acceptDeclineReason: string;
	signatureDate: string | null;
	signatureResolutionName: string;
	isDynamic: boolean;
	isActive: boolean;
	possibleAcceptors: INamedEntityDto[];
	requestor: IUserShort;
	signatureStateRouteId: number | null;
	extParams: IExtParam[];
	isAsyncAvailable: boolean;
	isQueued: boolean;
	pcIsServerSign: boolean;
}

export interface INamedEntityDto {
	id: number;
	name: string;
}

export interface ITaskFullSnapshot {
	taskSnapshotHash: string;
	currentTaskSnapshotHash: string;
	taskSnapshot: ITaskSnapshot;
	taskSnapshotHTML: string;
	enableFileHashSaving: boolean;
	filesHashes: IFileHashes[];
}

export interface ITaskSnapshot {
	sysParams: SystemParameters;
	extParams: IExtParam[];
}

export interface SystemParameters {
	description: string;
	dueDate?: Date;
	owner: IUserShort;
	performers?: IUserShort[];
	subscribers: IUserShort[];
	startDate?: Date;
	stateId: number;
	stateDescription: string;
	subcatId: number;
	subcatDescription: string;
}

export interface IFileHashes {
	fileId: number;
	actualHash: string;
	savedHash: string;
	fileName: string;
	enableFileSaving: boolean;
	versionId?: number;
}

export interface IStateInfo {
	name: string;
	forMail: string;
	commentOnAttainment: string;
	doSetEndDate: boolean;
	doSetPerformer: boolean;
	globalStateSPName: string;
	closeTask: boolean;
	finishWork: boolean;
	parentComment: string;
	needCloseSubtasks: boolean;
	forMailCustomer: string;
	mayRequireOwnerSignature: boolean;
	customerID: number;
	workNotStarted: boolean;
	guid: string;
	commentOnDueChangeNotNeeded: boolean;
	disableEditOrRemoveFiles: boolean;
	note: string;
	isDeleted: boolean;
	localizedNameId: number;
	id: number;
	version: number;
}

export interface IAllStatesInfo {
	data: IStateInfo[];
}

export interface IFileExistanceDto {
	fileName: string;
	isExistInTask: boolean;
}

export interface ITaskChangeSubscribers {
	taskId: number;
	change: Partial<{
		addUsers: number[];
		removeUsers: number[];
		addGroups: number[];
		removeGroups: number[];
		initiatorUserId: number;
	}>;
}
